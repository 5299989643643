const IN_PROGRESS = 0
    , COMPLETED = 1
    , FAILED = 2

type ExportStatus = never
    | typeof IN_PROGRESS
    | typeof COMPLETED
    | typeof FAILED

export {
    IN_PROGRESS,
    COMPLETED,
    FAILED,
    ExportStatus,
}
