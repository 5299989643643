import getPlotly from '_/facade/plotly'
import type { Layout } from 'plotly.js'

import { imageDataToBlob } from '_/utils/blob'
import type TimeService from '_/services/time-service'

import { PLOT_NAME } from '../messages'

function exportChartHandler(
    root: React.RefObject<HTMLDivElement>,
    multipleGraphs: boolean,
    onExport: ((_: Blob) => Promise<void>) | undefined,
    printLayout: Partial<Layout>,
    layout: Partial<Layout>,
    exportOpts: Plotly.ToImgopts,
    timeService: TimeService
) {
    const exportDate = timeService.formatCtzDate(timeService.now())
        , downloadImgOpts = {...exportOpts, filename: `${PLOT_NAME} ${exportDate}` }

    return getPlotly().then(plotly => {
        if (root.current) {
            plotly.relayout(root.current, printLayout)

            if (multipleGraphs)
                plotly.downloadImage(root.current, downloadImgOpts)
            else
                plotly.toImage(root.current, downloadImgOpts)
                    .then(_ => {
                        const image = imageDataToBlob(_, 'image/png')
                        onExport?.(image)
                    })

            plotly.relayout(root.current, layout)
        }
    })
}

function getExportImage(
    root: React.RefObject<HTMLDivElement>,
    printLayout: Partial<Layout>,
    layout: Partial<Layout>,
    exportOpts: Plotly.ToImgopts
) {
    return getPlotly()
        .then(plotly => {
            if (root.current) {
                plotly.relayout(root.current, printLayout)

                const result = plotly.toImage(root.current, exportOpts)

                plotly.relayout(root.current, layout)

                return result
            }
        })
}

export {
    exportChartHandler,
    getExportImage,
}
