import * as luxon from 'luxon'
import type { DateTime } from '_/model/date-time'
import type { DayId } from '_/constants/weekday'
import type { TimezoneAwareTimeService, TimeStruct } from '../time-service'

function factory(): TimezoneAwareTimeService {
    const utcZone = 'UTC'
    let currentZone = 'UTC'

    return {
        init,
        now,
        ctz,
        utc,
        utcWeekDay,
        ctzWeekDay,
        ctzDayStart,
        ctzDayEnd,
        ctzWeekEnd,
        ctzWeekStart,
        utcWeekStart,
        addCtzDays,
        addCtzMonths,
        addCtzYears,
        ctzTimeStruct,
        combineCtzDateTime,
        splitCtzDateTime,
        formatCtzDateTime,
        formatCtzDate,
        formatCtzTime,
        formatCtzShortWeek,
        daysDifference,
        monthsDifference,
        calculateOffset,
        firstMondayOfMonth,
        daysInMonth,
        utcTimeStruct,
        addUtcDays,
        weeksDifference,
        formatUtcDate,
        addUtcMonths,
        utcDayStart,
        castUtcDayStartFromCtzDay,
        castUtcToCtzDate,
        addUtcMilliseconds,
        isoWithCtzOffset,
    }

    function now(): DateTime {
        return new Date().toISOString() as DateTime
    }

    function init(customTimeZone: string): void {
        currentZone = customTimeZone
    }

    function ctz(year: number, month: number, day: number, hour = 0, minute = 0, second = 0, millisecond = 0): DateTime {
        const l = luxon.DateTime.fromObject({ year, month, day, hour, minute, second, millisecond }, { zone: currentZone })
            , isValid = l.isValid
                && l.year === year
                && l.month === month
                && l.day === day
                && l.hour === hour
                && l.minute === minute
                && l.second === second
                && l.millisecond === millisecond

        if (isValid)
            return serialize(l)

        throw new Error('Date does not exist')
    }

    function utc(year: number, month: number, day: number, hour = 0, minute = 0, second = 0, milli = 0): DateTime {
        const l = luxon.DateTime.utc(year, month, day, hour, minute, second, milli)
        if (l.isValid)
            return serialize(l)

        throw new Error('Date does not exist')
    }

    function utcWeekDay(isoString: DateTime): DayId {
        const weekday = utcDateTime(isoString).weekday

        return weekday as DayId
    }

    function ctzWeekDay(isoString: DateTime): DayId {
        const weekday = ctzDateTime(isoString).weekday

        return weekday as DayId
    }

    function ctzDayStart(isoString: DateTime): DateTime {
        const l = ctzDateTime(isoString).startOf('day')
        return serialize(l)
    }

    function utcDayStart(isoString: DateTime): DateTime {
        const l = utcDateTime(isoString).startOf('day')
        return serialize(l)
    }

    function ctzDayEnd(isoString: DateTime): DateTime {
        const l = ctzDateTime(isoString).endOf('day')
        return serialize(l)
    }

    function ctzWeekEnd(isoString: DateTime): DateTime {
        const l = ctzDateTime(isoString).endOf('week')
        return serialize(l)
    }

    function ctzWeekStart(isoString: DateTime): DateTime {
        const l = ctzDateTime(isoString).startOf('week')
        return serialize(l)
    }

    function utcWeekStart(isoString: DateTime): DateTime {
        const l = utcDateTime(isoString).startOf('week')
        return serialize(l)
    }

    function addCtzDays(isoString: DateTime, days: number): DateTime {
        const ctzTime = ctzDateTime(isoString)
            , nextDay = ctzTime.plus({ days })

        return serialize(nextDay)
    }

    function addCtzMonths(isoString: DateTime, months: number): DateTime {
        const l = ctzDateTime(isoString).plus({ months })
        return serialize(l)
    }

    function addUtcMonths(isoString: DateTime, months: number): DateTime {
        const l = utcDateTime(isoString).plus({ months })
        return serialize(l)
    }

    function addCtzYears(isoString: DateTime, years: number): DateTime {
        const l = ctzDateTime(isoString).plus({ years })
        return serialize(l)
    }

    function ctzTimeStruct(isoString: DateTime): TimeStruct {
        const { year, month, day, hour, minute, second, millisecond: milli } = ctzDateTime(isoString)

        return { year, month, day, hour, minute, second, milli }
    }

    function combineCtzDateTime(localDate: DateTime | undefined, localTextTime: string | undefined): DateTime | undefined {
        if (!localDate)
            return undefined

        const timeRegex = /^(\d\d):(\d\d)$/g
            , timeGroups = timeRegex.exec(localTextTime ? localTextTime : '00:00')

        if (!timeGroups)
            return undefined

        const hour = parseInt(timeGroups[1], 10)
            , minute = parseInt(timeGroups[2], 10)
            , { year, month, day } = ctzTimeStruct(localDate)

        return ctz(year, month, day, hour, minute)
    }

    function splitCtzDateTime(isoString: DateTime | undefined): { date: DateTime | undefined, time: string | undefined } {
        if (isoString == null)
            return { date: undefined, time: undefined }

        const date = ctzDayStart(isoString)
            , { hour, minute } = ctzTimeStruct(isoString)
            , time = `${timeSection(hour)}:${timeSection(minute)}`

        return { date, time }
    }

    function timeSection(number: number): string {
        if (number === 0)
            return '00'

        if (number < 10)
            return '0' + number

        return number + ''
    }

    function formatCtzDateTime(isoString: DateTime, showSeconds?: boolean): string {
        return formatCtzDate(isoString) + ' ' + formatCtzTime(isoString, showSeconds)
    }

    function getFormatString(omitDay?: boolean, omitYear?: boolean) {
        if (omitDay && omitYear)
            return 'MMM'

        return omitDay
            ? 'MMM-yyyy'
            : omitYear
                ? 'dd-MMM'
                : 'dd-MMM-yyyy'
    }

    function formatCtzDate(isoString: DateTime, omitDay?: boolean, omitYear?: boolean): string
    function formatCtzDate(isoString: undefined, omitDay?: boolean, omitYear?: boolean): string
    function formatCtzDate(isoString: DateTime | undefined, omitDay?: boolean, omitYear?: boolean): string {
        if (isoString === undefined)
            return ''

        const date = ctzDateTime(isoString)
            , formatString = getFormatString(omitDay, omitYear)

        return date.toFormat(formatString)
    }

    function formatUtcDate(isoString: DateTime, omitDay?: boolean, omitYear?: boolean): string
    function formatUtcDate(isoString: undefined, omitDay?: boolean, omitYear?: boolean): string
    function formatUtcDate(isoString: DateTime | undefined, omitDay?: boolean, omitYear?: boolean): string {
        if (isoString === undefined)
            return ''

        const date = utcDateTime(isoString)
            , formatString = getFormatString(omitDay, omitYear)

        return date.toFormat(formatString)
    }

    function formatCtzShortWeek(isoString: DateTime, showNumericMonth?: boolean): string
    function formatCtzShortWeek(isoString: undefined, showNumericMonth?: boolean): string
    function formatCtzShortWeek(isoString: DateTime | undefined, showNumericMonth?: boolean): string {
        if (isoString === undefined)
            return ''

        const date = ctzDateTime(isoString)
        return date.toFormat(showNumericMonth ? 'EEE dd/MM' : 'EEE dd')
    }

    function formatCtzTime(isoString: DateTime, showSeconds?: boolean): string
    function formatCtzTime(isoString: undefined, showSeconds?: boolean): string
    function formatCtzTime(isoString: DateTime | undefined, showSeconds?: boolean): string {
        if (isoString === undefined)
            return ''

        const date = ctzDateTime(isoString)
        return date.toFormat(showSeconds ? 'HH:mm:ss' : 'HH:mm')
    }

    function daysDifference(startDate: DateTime | undefined, endDate: DateTime | undefined): number {
        if (startDate === undefined || endDate === undefined)
            return 0

        const ctzStartDate = ctzDateTime(startDate).startOf('day')
            , ctzEndDate = ctzDateTime(endDate).startOf('day')

        return ctzEndDate.diff(ctzStartDate, 'days').days
    }

    function weeksDifference(startDate: DateTime | undefined, endDate: DateTime | undefined): number {
        if (startDate === undefined || endDate === undefined)
            return 0

        const ctzStartDate = ctzDateTime(startDate)
            , ctzEndDate = ctzDateTime(endDate)
            , days = ctzEndDate.diff(ctzStartDate, 'days').days

        if (days < 7 && ctzStartDate.weekday <= ctzEndDate.weekday)
            return 0

        const ctzStartDateWeekDayOffset = 7 - ctzStartDate.weekday
            , ctzEndDateWeekDayOffset = ctzEndDate.weekday
            , fullWeeksAmount = (days - ctzStartDateWeekDayOffset - ctzEndDateWeekDayOffset) / 7
            , partialWeeksAmount = (ctzStartDateWeekDayOffset > 0 ? 1 : 0) + (ctzEndDateWeekDayOffset > 0 ? 1 : 0)

        return fullWeeksAmount + partialWeeksAmount - 1
    }

    function monthsDifference(startDate: DateTime | undefined, endDate: DateTime | undefined): number {
        if (startDate === undefined || endDate === undefined)
            return 0

        const ctzStartDate = ctzDateTime(startDate).startOf('month')
            , ctzEndDate = ctzDateTime(endDate).startOf('month')

        return ctzEndDate.diff(ctzStartDate, 'months').months
    }

    function calculateOffset(): string {
        const ctzNow = ctzDateTime(now())

        return ctzNow.toFormat("'(UTC'ZZ')'")
    }

    function utcDateTime(isoString: DateTime) {
        const isoGroups = isoString.split(/[-T:Z]/)
            , year = parseFloat(isoGroups[0])
            , month = parseFloat(isoGroups[1])
            , day = parseFloat(isoGroups[2])
            , hour = parseFloat(isoGroups[3])
            , minute = parseFloat(isoGroups[4])
            , secondGroup = isoGroups[5].split('.')
            , second = parseFloat(secondGroup[0])
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            , milli = secondGroup[1] !== undefined ? parseFloat(secondGroup[1].slice(0, 3)) : 0

        return luxon.DateTime.utc(year, month, day, hour, minute, second, milli)
    }

    function ctzDateTime(isoString: DateTime) {
        return utcDateTime(isoString).setZone(currentZone)
    }

    function serialize(dateTime: luxon.DateTime) {
        return dateTime.toUTC().toISO() as DateTime
    }

    function firstMondayOfMonth(year: number, month: number): number {
        const monday = 1 // luxon weekdays are encoded 1..7 starting from Monday
            , firstWeekDay = luxon.DateTime.utc(year, month, 1).weekday
            , dayOffset = (monday - firstWeekDay + 7) % 7

        return luxon.DateTime.utc(year, month, 1 + dayOffset).day
    }

    function utcTimeStruct(isoString: DateTime): TimeStruct {
        const utcTime = utcDateTime(isoString)
            , { year, month, day, hour, minute, second, millisecond: milli } = utcTime

        return { year, month, day, hour, minute, second, milli }
    }

    function daysInMonth(year: number, month: number) {
        return luxon.DateTime.utc(year, month).daysInMonth
    }

    function addUtcDays(isoString: DateTime, days: number): DateTime {
        const utcTime = utcDateTime(isoString)
            , nextDay = utcTime.plus({ days })

        return serialize(nextDay)
    }

    function addUtcMilliseconds(isoString: DateTime, milliseconds: number): DateTime {
        const utcTime = utcDateTime(isoString)
            , nextDay = utcTime.plus(luxon.Duration.fromObject({ milliseconds }))

        return serialize(nextDay)
    }

    function castUtcDayStartFromCtzDay(isoString: DateTime): DateTime {
        const utc = ctzDateTime(isoString).setZone(utcZone, { keepLocalTime: true })
        return utcDayStart(serialize(utc))
    }

    function castUtcToCtzDate(isoString: DateTime): DateTime {
        const l = utcDateTime(isoString).setZone(currentZone, { keepLocalTime: true })
        return serialize(l)
    }

    function isoWithCtzOffset(date: DateTime): string {
        return ctzDateTime(date).toISO()
    }
}

export default factory
