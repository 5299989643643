import { Field } from 'react-final-form'
import { classnames } from '_/facade/react'
import { showFieldError } from './helpers'

type InputType = 'text'

interface InputFieldProps {
    id?: string
    name: string
    type: InputType
    disabled?: boolean
    className?: string
    placeholder?: string
    autoComplete?: boolean
    autoFocus?: boolean
    inputRef?: React.RefObject<HTMLInputElement>
    calcName?: (entity: any) => string
    testId?: string
}

interface NewInputProps {
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

const InputField: React.FC<InputFieldProps & NewInputProps> = props =>
    <Field name={props.name} render={
        _ =>
            <div className={`mb-3 ${props.className || ''}`}>
                <label htmlFor={props.id} className='col-form-label' data-testid='field-label'>{props.children}</label>
                <input
                    id={props.id}
                    ref={props.inputRef}
                    type={props.type}
                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                    disabled={props.disabled}
                    {..._.input}
                    placeholder={props.placeholder}
                    autoComplete={props.autoComplete ? '' : 'off'}
                    autoFocus={props.autoFocus}
                    onKeyDown={props.onKeyDown}
                    value={props.calcName ? props.calcName(_.input.value) : _.input.value}
                    data-testid={props.testId}
                />
                {showFieldError(_.meta) && <span className='invalid-feedback' data-testid='validation-error'>{_.meta.error}</span>}
            </div>
    }/>

type ConcreteFieldProps = Pick<InputFieldProps, 'id' | 'name' | 'disabled' | 'className' | 'placeholder' | 'autoComplete' | 'autoFocus' | 'inputRef' | 'testId'>
type CalculatedFieldProps = Pick<InputFieldProps, 'calcName'>

const TextField: React.FC<ConcreteFieldProps & NewInputProps> = (props) =>
    <InputField {... props} type={'text'} />

const CalculatedTextField: React.FC<ConcreteFieldProps & NewInputProps & CalculatedFieldProps> = props =>
    <InputField {...props} type='text' />

export { TextField, CalculatedTextField }
