import { useAction, useState, useEffect, useSelector } from '_/facade/react'

import { Modal, ModalHeader, ModalBody } from '_/components/modal'
import Button, { Close } from '_/components/button'
import type { UserMembership } from '_/model/auth/types'

import * as actions from '_/features/auth/actions'
import { sortMemberships } from '_/model/users/helpers'

interface Props {
    isOpen: boolean
    values?: UserMembership[]
    onChange(value: UserMembership): Promise<void>
    onClose(): void
}

function ContextSwitcherInput(props: Props) {
    const [disabled, setDisabled] = useState(false)
        , memberships = useMemberships(props.values)

    function handleClick(membership: UserMembership) {
        setDisabled(true)
        props.onChange(membership)
            .then(() => {
                if (!props.values)
                    setDisabled(false)
            })
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalHeader>
                Choose site <Close className='float-end' onClick={props.onClose} testId='close-choose-site-modal' />
            </ModalHeader>
            <ModalBody>
                <div className='dropdown-menu d-block position-static float-none border-0 p-0'>
                    {memberships.map(membership =>
                        <Button
                            key={membership.id}
                            className='dropdown-item px-0'
                            onClick={() => handleClick(membership)}
                            disabled={disabled}
                            testId={`membership-${membership.description}-${membership.role.name}`}
                        >
                            {`${membership.description} (${membership.role.name})`}
                        </Button>
                    )}
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ContextSwitcherInput

function useMemberships(values: UserMembership[] | undefined) {
    const loadMemberships = useAction(actions.loadMemberships)
        , [memberships, setMemberships] = useState<UserMembership[]>([])
        , { token, user } = useSelector(_ => _.auth)

    useEffect(
        () => {
            if (values) {
                setMemberships(sortMemberships(values))
                return
            }

            if (token)
                loadMemberships()
                    .then(m => {
                        const unsortedMemberships = m.filter(_ => _.id !== user!.membership.id && !_.isSuspended)
                        setMemberships(sortMemberships(unsortedMemberships))
                    })
        },
        [values, token, user, loadMemberships]
    )
    return memberships
}
