import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { React, useSelector, useState, useEffect, useAction, useReducer } from '_/facade/react'

import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import { submitDisabled } from '_/components/form'
import { useCloseModal } from '_/components/modal'

import { CHANGES_SAVED } from '_/features/samples/messages'

import type { FloorPlanForm, FloorPlanEdit } from '_/model/floor-plan/editor/types'
import validate from '_/model/floor-plan/editor/validate'
import type FloorPlan from '_/model/floor-plan/floor-plan'

import * as warningActions from '_/features/unsaved-changes/actions'
import * as toastActions from '_/features/toasts/actions'
import * as a from '../actions'
import Plotter from './plotter'
import Fields from './fields'

function Editor() {
    const [floorPlan, initialValues] = useFloorPlan()
        , [focusedLocation, focusLocation] = useState<number>()
        , handleSubmit = useSubmitHandler(floorPlan)
        , plans = useSelector(_ => _.predefinedLists.floorPlans.plans)
        , locationFieldsRef = React.createRef<HTMLDivElement>()
        , locationMenuRef = React.createRef<HTMLDivElement>()
        , [imageLoadCompleted, handleImageLoadCompleted] = useReducer(() => true, false)

    useCloseLocationMenu([locationFieldsRef, locationMenuRef], () => focusLocation(undefined))

    return (
        <Form<FloorPlanForm>
            initialValues={initialValues}
            validate={_ => validate(_, plans, floorPlan?.id)}
            mutators={{ ...arrayMutators }}
            onSubmit={handleSubmit}
            render={form =>
                <form className='d-flex h-100' onSubmit={form.handleSubmit}>
                    <UnsavedChangesObserver form={form} />
                    <div className='border rounded position-relative flex-fill'>
                        {floorPlan &&
                            <Plotter
                                floorPlan={floorPlan}
                                focusedIndex={focusedLocation}
                                onFocus={focusLocation}
                                locationMenuRef={locationMenuRef}
                                imageLoadCompleted={imageLoadCompleted}
                                onImageLoadCompleted={handleImageLoadCompleted}
                            />
                        }
                        {!imageLoadCompleted &&
                            <i className='preview-spinner material-icons text-primary md-48'>sync</i>
                        }
                    </div>
                    <Fields
                        className='floor-plans-details ms-4'
                        submitDisabled={submitDisabled(form)}
                        floorPlanName={floorPlan?.name}
                        focusedIndex={focusedLocation}
                        onFocus={focusLocation}
                        locationFieldsRef={locationFieldsRef}
                    />
                </form>
            }
        />
    )
}

export default Editor

const emptyValues: FloorPlanForm = {
    locations: [],
}

function useFloorPlan() {
    const id = useSelector<string>(_ => _.router.route?.params.id)
        , getFloorPlan = useAction(a.getFloorPlan)
        , [plan, setPlan] = useState<FloorPlan>()
        , [initialValues, setInitialValues] = useState<FloorPlanForm>(emptyValues)

    useEffect(
        () => {
            let disposed = false

            if (id) {
                getFloorPlan(id).then(newPlan => {
                    if (!disposed) {
                        setPlan(newPlan)
                        setInitialValues(asFloorPlanEdit(newPlan))
                    }
                })
            }

            return () => {
                disposed = true
                setPlan(undefined)
            }
        },
        [id, getFloorPlan]
    )

    return [plan, initialValues] as const
}

function useSubmitHandler(floorPlan: FloorPlan | undefined) {
    const updateFloorPlan = useAction(a.updateFloorPlan)
        , addSuccessMessage = useAction(toastActions.addSuccess)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , closeModal = useCloseModal()

    function handleSubmit(value: FloorPlanForm) {
        return updateFloorPlan({
                id: floorPlan!.id,
                current: asFloorPlanEdit(floorPlan!),
                next: value as FloorPlanEdit, // must be ensured by validator
            })
            .then(() => hasUnsavedChanges(false))
            .then(() => addSuccessMessage(CHANGES_SAVED))
            .then(closeModal)
    }

    return handleSubmit
}

function useCloseLocationMenu(refs: React.RefObject<HTMLDivElement>[], focusLocation: () => void) {
    useEffect(
        () => {
            function handleCloseLocationMenu(event: FocusEvent) {
                const contains = (node: Node | null) => event.target instanceof Node ? node?.contains(event.target) : false

                if (refs.every(ref => !contains(ref.current)))
                    focusLocation()
            }

            document.addEventListener('focusin', handleCloseLocationMenu)

            return () => document.removeEventListener('focusin', handleCloseLocationMenu)
        }
    )
}

function asFloorPlanEdit(plan: FloorPlan): FloorPlanEdit {
    return {
        name: plan.name,
        description: plan.description,
        locations: plan.locations,
    }
}
