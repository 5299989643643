import { NOT_RECORDED, NOT_AVAILABLE } from '_/constants/common-messages'
import * as t from '_/model/text/text'
import * as fi from '_/constants/custom-field-index'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type TimeService from '_/services/time-service'
import { formatExposureDuration } from '../sample/format'
import { getParticleCount } from './booking/helpers'
import type { ParticleConcentrationView } from './types'

function formatParticleValue(particle: ParticleConcentrationView): t.Text {
    const particleCount = getParticleCount(particle)

    if (particle.notRecorded)
        return [t.systemTextNode(NOT_RECORDED)]

    if (particleCount === undefined)
        return [t.systemTextNode(NOT_AVAILABLE)]

    return [
        t.defaultTextNode(`${particle.count} in ${particle.volume}m³ `),
        t.systemTextNode(`(${parseFloat(particleCount.toFixed(3))}/m³)`)
    ]
}

function formatExposureDurationByFields(fields: FieldValues[], timeService: TimeService): t.Text {
    const startTimeField = fields.find(_ => _.index === fi.EXPOSURE_START_TIME)
        , startDateField = fields.find(_ => _.index === fi.EXPOSURE_START_DATE)
        , endTimeField = fields.find(_ => _.index === fi.EXPOSURE_END_TIME)
        , endDateField = fields.find(_ => _.index === fi.EXPOSURE_END_DATE)

    return formatExposureDuration(
        {
            time: startTimeField?.value,
            timeNotRecorded: startTimeField?.notRecorded,
            date: startDateField?.value
        },
        {
            time: endTimeField?.value,
            timeNotRecorded: endTimeField?.notRecorded,
            date: endDateField?.value,
        },
        timeService
    )
}

export {
    formatParticleValue,
    formatExposureDurationByFields,
    getParticleCount,
}
