const SETTLE_PLATE = 0
    , CONTACT_PLATE = 1
    , AIR_PLATE = 2
    , FINGERDAB_PLATE = 3
    , FINGERDAB_TWO_HANDS_PLATE = 4
    , OTHER = 5
    , SWAB = 6

type SampleName = never
   | typeof SETTLE_PLATE
   | typeof CONTACT_PLATE
   | typeof AIR_PLATE
   | typeof FINGERDAB_PLATE
   | typeof FINGERDAB_TWO_HANDS_PLATE
   | typeof OTHER
   | typeof SWAB

const PLATE_TYPE: { id: SampleName, name: string, shortName: string, order: number }[] = [
    {
        id: SETTLE_PLATE,
        name: 'Settle plate',
        shortName: 'S',
        order: 1,
    },
    {
        id: CONTACT_PLATE,
        name: 'Contact plate',
        shortName: 'C',
        order: 2,
    },
    {
        id: FINGERDAB_PLATE,
        name: 'Fingerdab (one hand)',
        shortName: 'F',
        order: 3,
    },
    {
        id: FINGERDAB_TWO_HANDS_PLATE,
        name: 'Fingerdab (two hands)',
        shortName: 'F',
        order: 4,
    },
    {
        id: AIR_PLATE,
        name: 'Air',
        shortName: 'A',
        order: 5,
    },
    {
        id: SWAB,
        name: 'Swab',
        shortName: 'SW',
        order: 6,
    },
    {
        id: OTHER,
        name: 'Other',
        shortName: 'O',
        order: 7,
    },
]

type PLATE_TYPE = typeof PLATE_TYPE

export {
    PLATE_TYPE as default,
    SETTLE_PLATE,
    CONTACT_PLATE,
    AIR_PLATE,
    FINGERDAB_PLATE,
    FINGERDAB_TWO_HANDS_PLATE,
    OTHER,
    SWAB,
    SampleName,
}
