import {
    React,
    useSelector,
    forwardRef,
    useImperativeHandle,
} from '_/facade/react'

import type TimeService from '_/services/time-service'

import type { AnalysisFilter } from '_/model/analysis/filter/types'
import { formatFloorPlanName } from '_/features/analysis/helpers'
import type SampleSearchFields from '_/model/sample/search'

import Select from '_/components/downshift-select'
import InlineCheckbox from '_/components/checkbox-inline'

import * as routes from '_/constants/routes'

import type { FloorPlanSubFilter } from '_/model/floor-plan/floor-plan'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import { disableCumulativeView, calculateStartDate, calculateExportSliderData } from '_/model/floor-plan/time-slider'
import { calculateTimeSliderDate } from '_/model/floor-plan/time-slider'
import { recalculateDynamicExposureDate } from '../filter/helpers'

import ActionButtons from './action-buttons'
import TimeSlider from './time-slider'
import FloorPlanKeys from './floor-plan-keys'

import type { FloorPlanChartMetadata } from '_/model/analysis/chart-metadata'
import { getFloorPlanSubtitle } from './helpers'
import { downloadBlob } from '_/utils/blob'
import { PLOT_NAME } from '../messages'
import { useExportGraphData, useImageTokensDownloader } from '../hooks'
import type { FloorPlanChartRef, ExportData } from '_/model/floor-plan/export'
import type { AggregationPeriod } from '_/model/analysis/aggregation-period'
import type { ImageExportInfo } from '_/model/sample/image/image'
import type { FloorPlanChart } from '_/model/analysis/chart-type'
import type { DateTime } from '_/model/date-time'
import * as t from '_/model/text/text'
import type { FloorPlanLocationPage } from '_/model/floor-plan/floor-plan-location-page'
import { ANALYSIS_GRAPH } from '_/model/floor-plan/floor-plan-location-page'
import { OPERATORS_IDS } from '_/constants/custom-field-index'
import type { SampleOperator } from '_/model/predefined-lists/operator/types'
import { noop } from '_/utils/function'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    floorPlans: FloorPlan[]
    chartType: FloorPlanChart
    filter: AnalysisFilter
    timeService: TimeService
    disableGoToSamples: boolean
    showActionButtons?: boolean | undefined
    showActionButtonsAsPrimaryButtons?: boolean
    multipleGraphs?: boolean | undefined
    exportDisabled: boolean
    sampleListRouterParams: SampleSearchFields
    floorPlanSubFilter: FloorPlanSubFilter
    metadata: FloorPlanChartMetadata[]
    hideTimeSliderBlock?: boolean
    hideAppliedFiltersBlock?: boolean
    floorPlanLocationPage: FloorPlanLocationPage
    renderFloorPlan(): React.ReactNode
    onExport(exportData: ExportData): Promise<ImageExportInfo>
    renderTabularView?: (() => React.ReactNode | null)
}

interface TimeSliderBlockProps {
    exposureStartDateFrom: DateTime
    exposureStartDateTo: DateTime
    floorPlans: FloorPlan[]
    floorPlanSubFilter: FloorPlanSubFilter
    routeName: string | undefined
    aggregationPeriod: AggregationPeriod | undefined
    subtitle: string
    disableCumulativeViewCheckbox: boolean
    hideTimeSliderBlock?: boolean
}

function renderTimeSliderBlock(props: TimeSliderBlockProps) {
    const floorPlan = props.floorPlans.find(_ => _.id === props.floorPlanSubFilter.value.floorPlanId)

    return (
        <div className='w-100 d-flex flex-column align-items-center'>
            <div className='position-relative d-flex w-100 mt-2 time-slider-title-block'>
                    <div className='text-center m-auto'>
                        <div className='fw-bold analysis__title'>
                            {floorPlan && t.plainText(formatFloorPlanName(floorPlan))}
                        </div>
                    </div>
                    {props.floorPlans.length > 1 &&
                        <div className='align-self-center position-absolute r-0'>
                            <Select
                                entities={props.floorPlans}
                                calcId={_ => _.id}
                                calcName={formatFloorPlanName}
                                className={'form-control'}
                                testId='floor-plans'
                                input={{
                                    value: props.floorPlanSubFilter.value.floorPlanId,
                                    onChange: _ => _ === ''
                                        ? noop
                                        : props.floorPlanSubFilter.onChange?.({
                                            floorPlanId: _,
                                            timeSliderPosition: props.floorPlanSubFilter.value.timeSliderPosition,
                                            cumulativeView: props.floorPlanSubFilter.value.cumulativeView,
                                            graphId: props.floorPlanSubFilter.value.graphId,
                                        }),
                                }}
                            />
                        </div>
                    }
                </div>
                {!props.hideTimeSliderBlock &&
                    <div className='d-flex flex-fill flex-column col-8'>
                        <TimeSlider
                            startDate={props.exposureStartDateFrom}
                            endDate={props.exposureStartDateTo}
                            range={props.aggregationPeriod}
                                cumulativeView={props.floorPlanSubFilter.value.cumulativeView}
                                value={props.floorPlanSubFilter.value.timeSliderPosition}
                                onChange={_ =>
                                    props.floorPlanSubFilter.onChange?.({
                                        floorPlanId: props.floorPlanSubFilter.value.floorPlanId,
                                        timeSliderPosition: _,
                                        cumulativeView: props.floorPlanSubFilter.value.cumulativeView,
                                        graphId: props.floorPlanSubFilter.value.graphId,
                                    })
                                }
                            disabled={props.routeName === routes.CUSTOM_REPORTS_VIEW}
                            description={props.subtitle}
                        />
                        <InlineCheckbox
                            id='cumulative-view'
                            name='cumulative-view'
                            data-testid = 'cumulative-view'
                            checked={props.floorPlanSubFilter.value.cumulativeView}
                            onChange={() =>
                                props.floorPlanSubFilter.onChange?.({
                                    floorPlanId: props.floorPlanSubFilter.value.floorPlanId,
                                    timeSliderPosition: props.floorPlanSubFilter.value.timeSliderPosition,
                                    cumulativeView: !props.floorPlanSubFilter.value.cumulativeView,
                                    graphId: props.floorPlanSubFilter.value.graphId,
                                })
                            }
                            disabled={props.disableCumulativeViewCheckbox}
                        >
                            Show data points cumulatively
                        </InlineCheckbox>
                    </div>
                }
        </div>
    )
}

const FloorPlanChartContainer = (props: Props, ref: React.ForwardedRef<FloorPlanChartRef>) => {
    const floorPlan = props.floorPlans.find(_ => _.id === props.floorPlanSubFilter.value.floorPlanId)
        , operator = useOperator(props.filter)
        , routeName = useSelector(_ => _.router.route?.name)
        , { exposureStartDateTo, exposureStartDateFrom, aggregationPeriod } = {
            ...props.filter,
            ...recalculateDynamicExposureDate(props.filter, props.timeService),
        }
        , disableCumulativeViewCheckbox = routeName === routes.CUSTOM_REPORTS_VIEW
            || disableCumulativeView(exposureStartDateFrom, exposureStartDateTo, aggregationPeriod, props.timeService)
        , timeSliderDate = calculateTimeSliderDate(exposureStartDateFrom, exposureStartDateTo, aggregationPeriod, props.timeService, props.floorPlanSubFilter.value.timeSliderPosition)
        , startDate = calculateStartDate(aggregationPeriod, exposureStartDateFrom, timeSliderDate, props.floorPlanSubFilter.value.cumulativeView, props.timeService)
        , subtitle = getFloorPlanSubtitle(props.timeService, startDate, timeSliderDate, aggregationPeriod, props.chartType)
        , tokensDownloader = useImageTokensDownloader()

        , predefinedLists = useSelector(_ => _.predefinedLists)
        , exportGraphData = useExportGraphData({...props.filter, ...props.floorPlanSubFilter.value, ...{exposureStartDateFrom: startDate, exposureStartDateTo: timeSliderDate}}, predefinedLists)

        , sliderData = calculateExportSliderData(props.filter, props.floorPlanSubFilter.value, props.timeService)
        , floorPlanMetadata = props.metadata.find(_ => _.id === props.floorPlanSubFilter.value.floorPlanId)?.metadata
        , timeSliderBlockProps = {
            exposureStartDateFrom,
            exposureStartDateTo,
            floorPlans: props.floorPlans,
            floorPlanSubFilter: props.floorPlanSubFilter,
            routeName,
            aggregationPeriod,
            subtitle,
            disableCumulativeViewCheckbox,
            hideTimeSliderBlock: props.hideTimeSliderBlock
        }

    useImperativeHandle(ref, () => ({
        exportImages: getExportImages,
    }))

    function handleExport() {
        getExportImages(floorPlan)
            .then(images => {
                const image = images[0]
                if (props.floorPlanLocationPage === ANALYSIS_GRAPH)
                    exportGraphData(image.blob)
                else
                    downloadBlob(image.blob, getExportFileName(operator, props.floorPlanLocationPage,  props.timeService))
            })
    }

    function getExportImages(floorPlan?: FloorPlan) {
        const floorPlans = !floorPlan ? props.floorPlans : [floorPlan]

        return tokensDownloader(floorPlans)
            .then(imageTokens =>
                Promise.all(
                    imageTokens.map(s => {
                        const floorPlan = props.floorPlans.find(f => f.id === s.floorPlanId)!
                            , metadata = props.metadata.find(m => m.id === s.floorPlanId)!.metadata
                            , exportData = { floorPlan, sliderData, src: s.src, metadata }

                        return props.onExport(exportData)
                    }))
            )
    }

    if (!floorPlan)
        return (
            <div className='text-center'>Your filters do not match any floor plans</div>
        )

    return (
        <div>
            <div className='d-flex flex-column align-items-center d-print-block border border-light analysis__floor-plan__min-width'>
                {renderTimeSliderBlock(timeSliderBlockProps)}

                <div className='position-relative mt-3'>
                    {props.renderFloorPlan()}
                </div>

                <div className='d-flex flex-column align-self-start container-fluid mb-4'>
                    <div className='analysis__floor-plan__footer'>
                        <div className='row'>
                            {!props.hideAppliedFiltersBlock &&
                                <div className='col-5'>
                                    <span>
                                        <b>Filters</b>
                                        <br />
                                    </span>
                                    <FormattedText text={floorPlanMetadata?.footer.slice(1)} className='user-formatted-text' />
                                </div>
                            }
                            <div className='col-7'>
                                <span>
                                    <b>Keys</b>
                                    <br />
                                </span>
                                <FloorPlanKeys chartType={props.chartType} floorPlanLocationPage={props.floorPlanLocationPage} />
                            </div>
                        </div>
                        {!props.hideAppliedFiltersBlock &&
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <span>{floorPlanMetadata?.author}</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {props.renderTabularView && props.renderTabularView()}
            <div className='ms-auto'>
                {props.showActionButtons &&
                    <ActionButtons
                        disabledGoToSamplesButton={props.disableGoToSamples}
                        onExport={handleExport}
                        sampleListRouterParams={props.sampleListRouterParams}
                        exportButtonDisabled={props.exportDisabled}
                        multipleGraphs={props.multipleGraphs}
                        showAsPrimaryButtons={props.showActionButtonsAsPrimaryButtons}
                    />
                }
            </div>
        </div>
    )
}

function useOperator(filter: AnalysisFilter) {
    const allOperators = useSelector(_ => _.predefinedLists.sampleOperators)
        , selectedOperators = filter.fields?.find(f => f.index == OPERATORS_IDS)?.value

    if (selectedOperators)
        return allOperators.find(o => o.id === selectedOperators[0])
}

function getExportFileName(operator: SampleOperator | undefined, floorPlanLocationPage: FloorPlanLocationPage, timeService: TimeService){
    const exportDate = timeService.formatCtzDate(timeService.now())

    return floorPlanLocationPage == ANALYSIS_GRAPH || !operator
            ? `${PLOT_NAME} ${exportDate}`
            : `SmartControl Graph for ${operator.name} downloaded on ${exportDate}`
}

export default forwardRef(FloorPlanChartContainer)
