import { classnames } from '_/facade/react'
import { useField } from 'react-final-form'
import { showFieldError } from './helpers'

interface TextAreaFieldProps {
    id?: string
    name: string
    disabled?: boolean
    autoComplete?: boolean
    className?: string
    textareaClassName?: string
    children?: React.ReactNode
    rows?: number
    testId?: string
}

const TextareaField = (props: TextAreaFieldProps) => {
    const field = useField(props.name)
    return (
        <div className={`mb-3 ${props.className || ''}`}>
            <label htmlFor={props.id} className='col-form-label'data-testid='field-label'>{props.children}</label>
            <textarea
                id={props.id}
                className={classnames(`form-control ${props.textareaClassName || ''}`, { 'is-invalid': showFieldError(field.meta) })}
                disabled={props.disabled}
                {...field.input}
                autoComplete={props.autoComplete ? '' : 'off'}
                rows={props.rows}
                data-testid={props.testId}
            />
            {showFieldError(field.meta) && <span className='invalid-feedback' data-testid='validation-error'>{field.meta.error}</span>}
        </div>
    )
}

export default TextareaField
