import { createStore, applyMiddleware, compose } from 'redux'
import { router5Middleware } from 'redux-router5'

import logMiddleware from '_/utils/middleware/log'
import syncStateMiddleware from '_/features/auth/syncState'
import toastHandlerMw from '_/features/toasts/middleware'
import routerFactory from '_/features/routing/router'

import { rootReducer, middlewares, persistedState, reduxServices } from './root'

let composeEnhancers = compose

if (process.env.NODE_ENV === 'development') {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose
}

const router = routerFactory()
    , enhancer = composeEnhancers(
        applyMiddleware(
            toastHandlerMw,
            ...middlewares,
            logMiddleware,
            router5Middleware(router),
            syncStateMiddleware,
        )
    )
    , store = createStore(rootReducer, persistedState || {}, enhancer)

reduxServices.forEach(_ => _.setStore(store))

export default store
export { router }
