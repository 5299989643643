import type Notification from '_/model/notification/types'
import * as notificationType from '_/model/notification/notification-type'
import NotificationBreach from './notification-breach'
import TaggedUserNotification from './tagged-user-notification'
import MissedMonitoringNotification from './missed-monitoring-notification'
import DataExportNotification from './data-export-notification'
import TrendIdentificationNotification from './trend-identification-notification'
import MissedMonitoringExpectationNotification from './missed-monitoring-expectation'

interface Props {
    notification: Notification
    onClick: () => void
    dropdownLayout: boolean
    testId?: string
}

function SingleNotification(props: Props) {
    const notification = props.notification

    switch (notification.type) {
        case notificationType.ACTION:
        case notificationType.ALERT:
        case notificationType.COMPROMISED:
            return <NotificationBreach notification={notification} dropdownLayout={props.dropdownLayout} onClick={props.onClick} testId={props.testId} />
        case notificationType.TAGGED_USER:
            return <TaggedUserNotification notification={notification} dropdownLayout={props.dropdownLayout} onClick={props.onClick} testId={props.testId} />
        case notificationType.MISSED_MONITORING:
            return <MissedMonitoringNotification notification={notification} dropdownLayout={props.dropdownLayout} onClick={props.onClick} testId={props.testId} />
        case notificationType.DATA_EXPORT:
            return <DataExportNotification notification={notification} dropdownLayout={props.dropdownLayout} onClick={props.onClick} testId={props.testId}/>
        case notificationType.TREND_IDENTIFIED:
            return <TrendIdentificationNotification notification={notification} dropdownLayout={props.dropdownLayout} onClick={props.onClick} testId={props.testId} />
        case notificationType.MISSED_MONITORING_EXPECTATION:
            return <MissedMonitoringExpectationNotification notification={notification} dropdownLayout={props.dropdownLayout} onClick={props.onClick}/>
        default:
            return null
    }
}

export default SingleNotification

