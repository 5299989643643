import { systemFontStack } from '_/model/analysis/system-font-stack'
import { splitStringIntoFewLines } from '_/features/analysis/helpers'
import GRAPH_TITLE_WIDTH from '_/model/reports/graph-title-width'

function isLegendElement(element: HTMLDivElement) {
    // note: classList is not supported by IE11 for svg elements, so getAttribute is used instead
    const className = element.getAttribute('class') ?? ''
    return className.indexOf('legendtoggle') !== -1
}

function getOrganismPieChartLayout(title: string, footer: string[], author: string): Partial<Plotly.Layout> {
    const splitTitle = splitStringIntoFewLines(title, GRAPH_TITLE_WIDTH)

    return {
        font: { family: systemFontStack },
        height: 450,
        margin: { b: footer.length > 0 ? 100 : 60 },
        annotations: [
            {
                text: '<b>' + splitTitle.join('<br>') + '</b>',
                font: { size: 18 },
                showarrow: false,
                align: 'center',
                y: 1.3,
                xref: 'paper',
                yref: 'paper',
            },
            {
                text: footer.join('<br>'),
                font: {
                    size: 14,
                },
                showarrow: false,
                x: 0,
                y: -0.2,
                xref: 'paper',
                yref: 'paper',
                align: 'left',
            },
            {
                text: author,
                font: {
                    size: 14,
                },
                showarrow: false,
                x: 0,
                y: -0.3,
                xref: 'paper',
                yref: 'paper',
            },
        ],
        showlegend: true,
    }
}

export {
    isLegendElement,
    getOrganismPieChartLayout,
}
