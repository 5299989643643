import type * as fi from '_/constants/custom-field-index'
import { NOT_EDITABLE_SHOW_WHEN_BOOKING_IN_FIELDS as REASON_REQUIRED_FIELDS } from '_/features/predefined-lists/custom-fields/helpers'
import type { NonViableSampleEditRequest } from '../booking/types'

function reasonRequired(editedFields: fi.FieldIndex[] | undefined, entity: Partial<NonViableSampleEditRequest>) {
    const editedRequiredFields =  entity.fields?.some(_ => REASON_REQUIRED_FIELDS.has(_.index))
        , editedCustomFields = editedFields?.some(editedField => entity.fields?.some(_ => _.index === editedField))
        , editedMonitoringState = 'monitoringState' in entity
        , editedParticles = 'lowerParticle' in entity || 'higherParticle' in entity

    return editedRequiredFields || editedCustomFields || editedMonitoringState || editedParticles
}

export default reasonRequired
