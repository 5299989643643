import type { ValidationResult} from '_/utils/form/validate'
import { maxLength } from '_/utils/form/validate'
import type { ApiKeyEdit } from '_/model/api-key/types'

function validate(entity: Partial<ApiKeyEdit>) {
    const descriptionLength = maxLength('Description', 250)(entity.description)
        , result: ValidationResult<ApiKeyEdit> = {}

    if (descriptionLength)
        result.description = descriptionLength

    return result
}

export default validate
