import { classnames, useState } from '_/facade/react'
import { VOID_ID } from '_/utils/tree'

import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import type Grade from '_/model/predefined-lists/grade/grade'
import LOCATION_TYPE from '_/model/predefined-lists/exposure-location/location-type'

import Link from '_/components/link'
import Button from '_/components/button'
import Menu from '_/components/overlay/menu'

import { SETTINGS_EXPOSURE_LOCATIONS_CREATE, SETTINGS_EXPOSURE_LOCATIONS_EDIT } from '_/constants/routes'

interface OwnProps {
    location: ExposureLocation
    grade?: Grade
    canEdit: boolean
    hasChildren: boolean
    expanded?: boolean
    nestingLevel: number
    onDragStart: (_: ExposureLocation) => void
    onDragEnd: () => void
    onDragDrop: (_: ExposureLocation) => void
    onTrigger: (_: ExposureLocation) => void
    canMoveTo: (destination: ExposureLocation) => boolean
}

function ExposureLocationsRow(props: OwnProps) {
    const triggerClasses = classnames('exposure-locations__trigger', {
            'exposure-locations__trigger--collapsed': !props.expanded,
            'exposure-locations__trigger--visible': props.hasChildren,
        })
        , [dragHover, setDragHover] = useState(false)
        , [showLocationActionButtons, setShowLocationActions] = useState(false)
        , [cellElement, setCellElement] = useState<HTMLElement | null>(null)
        , dragEvents = !props.canEdit
            ? undefined
            : {
                onDragEnd: handleDragEnd,
                onDragOver: handleDragOver,
                onDragEnter: handleDragEnter,
                onDragLeave: handleDragLeave,
            }

    const offsets = []
    for (let i = props.nestingLevel; i > 0; i--)
        offsets.push(<div key={i} className='exposure-locations__offset' />)

    function handleDragStart(e: React.DragEvent<HTMLTableRowElement>) {
        e.dataTransfer.effectAllowed = 'move'
        props.onDragStart(props.location)
    }

    function handleDragEnd() {
        props.onDragEnd()
    }

    function handleDragOver(e: React.DragEvent<HTMLTableRowElement>) {
        if (!props.canMoveTo(props.location))
            return

        e.preventDefault()
    }

    function handleDragEnter() {
        setDragHover(props.canMoveTo(props.location))
    }

    function handleDragLeave() {
        setDragHover(false)
    }

    function handleDrop() {
        setDragHover(false)
        props.onDragDrop(props.location)
    }

    function hideLocationActions() {
        document.removeEventListener('click', hideLocationActions)

        setShowLocationActions(false)
    }

    function showLocationActions() {
        setTimeout(() => document.addEventListener('click', hideLocationActions))

        setShowLocationActions(true)
    }

    return (
        <tr
            className={classnames('cursor-pointer', {
                'exposure-locations__drag-hover': dragHover,
            })}
            draggable={props.canEdit}
            onDragStart={props.canEdit && !props.location.inUse ? handleDragStart : undefined}
            {...dragEvents}
            onDrop={handleDrop}
        >
            <td>
                {offsets}
                {props.hasChildren
                    ? <a className={triggerClasses} href='.' onClick={e => {e.preventDefault(); props.onTrigger(props.location)} } data-testid={`${props.location.name}-expand`}/>
                    : <div className={triggerClasses} />
                }
                {props.location.name}
            </td>
            <td>
                {LOCATION_TYPE.find(_ => _.id === props.location.locationType)?.name ?? ''}
            </td>
            <td>
                {props.grade ? props.grade.name : 'Ungraded'}
            </td>
            <td>{props.location.isActive ? 'Active' : 'Inactive'}</td>
            <td className='text-end dropdown' ref={setCellElement}>
                {props.canEdit &&
                    <Button
                        onClick={!showLocationActionButtons ? showLocationActions : hideLocationActions}
                        className='py-0 px-1 bg-white'
                        testId={`${props.location.name}-show-actions`}
                    >
                        <i className='material-icons'>more_horiz</i>
                    </Button>
                }

                {showLocationActionButtons &&
                    <Menu element={cellElement} useParentWidth>
                        <div className='dropdown-menu dropdown-menu-right d-block'>
                            <Link
                                className='btn btn-link d-block text-start w-100'
                                routeName={SETTINGS_EXPOSURE_LOCATIONS_CREATE}
                                routeParams={{ parentId: props.location.id }}
                                disabled={!props.location.isActive}
                                testId={`${props.location.name}-add-new`}
                            >
                                Add new
                            </Link>
                            <Link
                                className='btn btn-link d-block text-start w-100'
                                routeName={SETTINGS_EXPOSURE_LOCATIONS_EDIT}
                                routeParams={{ id: props.location.id, parentId: props.location.parentId }}
                                disabled={props.location.parentId === VOID_ID}
                                testId={`${props.location.name}-edit`}
                            >
                                Edit
                            </Link>
                        </div>
                    </Menu>
                }
            </td>
        </tr>
    )
}

export default ExposureLocationsRow
