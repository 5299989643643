import { Field } from 'react-final-form'

import { classnames, useMemo } from '_/facade/react'
import Select from '_/components/downshift-select'
import { showFieldError } from '_/components/form/helpers'
import FormattedText from '_/features/text/formatted-text'

import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import * as fieldIndex from '_/constants/custom-field-index'
import type { SampleEditedInfo } from '_/model/sample/sample'
import EditedLabel from '../edited-label'

import FieldForm from '../field-form'
import Error from '_/components/form/field-error'
import InlineActions from '../inline-actions'
import { validateSampleType } from '../../validate'
import { findFieldValuePos, valuePath, getFieldValue } from '../../helpers'
import { formatActiveState } from '_/utils/format/common'

interface OwnProps {
    entity: SampleDetailsEdit
    field: CustomField
    onSubmit(sample: SampleDetailsEdit): Promise<void>
    sampleTypes: SampleType[]
    hasNoPermissions: boolean
    canChangeToSwab: boolean
    editedInfo: SampleEditedInfo | undefined
}

function SampleTypeForm(props: OwnProps) {
    const position = findFieldValuePos(props.entity.fields, fieldIndex.SAMPLE_TYPE_ID)

    function handleSubmit(subEntity: SampleDetailsEdit) {
        const fields = subEntity.fields
            , sampleEdit = { ...props.entity,  fields }

        return props.onSubmit(sampleEdit)
    }

    const sampleTypeField = props.entity.fields[position]
        , sampleType = props.sampleTypes.find(_ => _.id === sampleTypeField.value)
        , operators = getFieldValue(props.entity.fields, fieldIndex.OPERATORS_IDS)
        , initialValues = useMemo(
            () => ({
                fields: props.entity.fields,
            }),
            [props.entity.fields]
        )

    return(
        <FieldForm
            formId='sampleType'
            label={props.field.fieldName}
            testId={`sample-field-${props.field.index}`}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={_ => validateSampleType(_, props.field, props.sampleTypes, props.canChangeToSwab, operators)}
            hasNoPermissions={props.hasNoPermissions}
            renderView={() => sampleType &&
                <span className='form-control-plaintext'>
                    <span className='fw-bold' data-testid='sample-type-info'>
                        <FormattedText text={formatActiveState(sampleType.name, sampleType.isActive)} />
                        <EditedLabel editedInfo={props.editedInfo}/>
                    </span>
                </span>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div className='d-flex align-items-start'>
                    <div className='flex-fill'>
                        <Field name={valuePath(position)} render={_ =>
                            <>
                                <Select
                                    autocomplete
                                    entities={props.sampleTypes}
                                    calcId={_ => _.id}
                                    calcName={_ => formatActiveState(_.name, _.isActive)}
                                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                                    input={_.input}
                                    autoFocus
                                    testId={`field-${props.field.index}`}
                                />
                                <Error field={_} />
                            </>
                        } />
                    </div>
                    <InlineActions form={form} onSave={handleSave} onCancel={handleCancel} testId={`sample-field-${props.field.index}`} />
                </div>
            }
        />
    )
}

export default SampleTypeForm
