import type SortState from '_/model/sort-state'

const enum Order { None, Asc, Desc }

interface SortMediator {
    createSubject(name: string, onOrderChanged: OnOrderChanged, sortByDescending?: boolean): SortSubject
    removeSubject(subject: SortSubject): void
    updateSubject(name: string, onOrderChanged: OnOrderChanged, subject: SortSubject): SortSubject
    updateOnChange(onChange: (state: SortState) => void): void
}

interface SortSubjectInternal {
    readonly name: string
    setOrder(order: Order): void
    getOrder(): Order
}

interface SortSubject {
    flipOrder(): void
}

interface OnRequestOrder {
    (order: Order): void
}

interface OnOrderChanged {
    (sorted: boolean, ascending: boolean): void
}

function mediatorFactory(): SortMediator {
    let subjects: SortSubjectInternal[] = []
      , handleChange: (state: SortState) => void

    return {
        createSubject,
        removeSubject,
        updateOnChange,
        updateSubject,
    }

    function updateOnChange(onChange: (state: SortState) => void) {
        handleChange = onChange
    }

    function createSubject(name: string, onOrderChanged: OnOrderChanged, sortByDescending: boolean): SortSubject {
        const subject = subjectFactory(
                name,
                order => handleChangeOrder(subject, order),
                onOrderChanged,
            )

        subjects = subjects.concat(subject)
        subject.setOrder(sortByDescending ? Order.Desc : Order.None)

        return subject
    }

    function updateSubject(name: string, onOrderChanged: OnOrderChanged, oldSubject: SortSubject): SortSubject {
        const oldOrder = subjects.find(_ => _.name === name)?.getOrder()
            , order = oldOrder === undefined ? Order.None : oldOrder
            , subject = subjectFactory(
                name,
                order => handleChangeOrder(subject, order),
                onOrderChanged,
            )

        removeSubject(oldSubject)

        subjects = subjects.concat(subject)
        onOrderChanged(order !== Order.None, order === Order.Asc)

        return subject
    }

    function removeSubject(subject: SortSubject) {
        subjects = subjects.filter(_ => _ !== subject as any)
    }

    function handleChangeOrder(eventSubject: SortSubjectInternal, order: Order) {
        subjects.forEach(subject =>
            subject.setOrder(subject === eventSubject ? order : Order.None)
        )

        fireState()
    }

    function fireState() {
        const subject = subjects.find(_ => _.getOrder() !== Order.None)

        if (!subject) {
            handleChange({ sort: '' })
            return
        }

        const names = subject.name.split(',')
            , order = subject.getOrder() === Order.Asc ? 'asc' : 'desc'
            , sort = names.map(_ => `${_}:${order}`)

        handleChange({ sort: sort.join(',') })
    }
}

function subjectFactory(name: string, onRequestOrder: OnRequestOrder, onOrderChanged: OnOrderChanged): SortSubject & SortSubjectInternal {
    let lastOrder = Order.None

    return {
        name,
        setOrder,
        getOrder,

        flipOrder,
    }

    function setOrder(order: Order) {
        if (lastOrder !== order)
            onOrderChanged(order !== Order.None, order === Order.Asc)

        lastOrder = order
    }

    function getOrder() {
        return lastOrder
    }

    function flipOrder() {
        onRequestOrder(lastOrder === Order.Asc ? Order.Desc : Order.Asc)
    }
}

export { mediatorFactory, SortMediator, SortSubject }
