import type { IdentificationRows } from '_/model/sample/sample'
import type Confirmation from '_/model/confirmation/types'

import * as behaviours from '_/constants/objectionable-limit-behaviour'
import * as types from '_/constants/plate-type'
import * as breachTypes from '_/constants/sample-breach-type'
import type { FieldIndex } from '_/constants/custom-field-index'
import STANDARD_FIELD_INDEXES from '_/constants/custom-field-index'
import SAMPLE_STATUS, * as sampleStatuses from '_/constants/sample-status'
import * as growthsStatus from '_/constants/growth-status'
import PLATE_TYPE, { FINGERDAB_PLATE, FINGERDAB_TWO_HANDS_PLATE } from '_/constants/plate-type'
import * as f from '_/model/sample/format'
import * as t from '_/model/text/text'

import { diffObject } from '_/utils/object'
import { formatActiveState } from '_/utils/format/common'

import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { SampleOperator } from '_/model/predefined-lists/operator/types'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { SampleEditedInfo } from '_/model/sample/sample'
import type Sample from '_/model/sample/sample'
import * as fieldIndex from '_/constants/custom-field-index'
import * as flags from '_/constants/sample-flag'
import { NOT_RECORDED } from '_/constants/common-messages'
import type TimeService from '_/services/time-service'
import type SampleDetails from '_/model/sample/sample-details'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type { IdentificationRows as ReadingIdentificationRows } from '_/model/sample/reading/sample-reading'
import { NOT_VALID_VALUE } from '_/components/form/number-input'
import * as it from '_/model/sample/identification-type'
import type { FilterFieldValue } from '_/model/sample/search'
import { NOT_YET_IDENTIFIED_NAME } from '_/model/predefined-lists/identifications/not-yet-identified'
import * as confirmationType from '_/constants/confirmation-type'
import * as nvf from '_/model/non-viable-sample/format'

function getEditableSampleCustomFields(fields: CustomField[]): CustomField[] {
    return fields.map(field => {
        if (field.index === fieldIndex.EXPOSURE_DATE)
            return [
                {
                    ...field,
                    index: fieldIndex.EXPOSURE_START_DATE as FieldIndex,
                },
            ]

        if (field.index === fieldIndex.EXPOSURE_END_TIME)
            return [
                {
                    ...field,
                    index: fieldIndex.EXPOSURE_END_TIME as FieldIndex,
                },
                {
                    ...field,
                    index: fieldIndex.EXPOSURE_END_DATE as FieldIndex,
                },
            ]

        return [field]
    }).reduce((acc, val) => acc.concat(val), [])
}

function getSampleEditValuesFromCustomFields(fields: CustomField[]): FieldValues[] {
    // not recorded is false by default
    // value is absent (behavior of final form for empty values)
    return fields
        .map(_ =>
            ({
                index: _.index,
                notRecorded: false,
            })
        )
}

function findNotRecordedFilledValues(
    values: (FieldValues | FilterFieldValue)[],
) {
    return values
        .map((field, i) => field.notRecorded && field.value !== undefined ? i : -1)
        .filter(_ => _ !== -1)
}

function formatInlineExposureTime(sample: Pick<SampleDetailsEdit, 'fields'>, timeService: TimeService, fields: CustomField[], isViable = true): t.Text {
    const startDateField = sample.fields.find(_ => _.index === fieldIndex.EXPOSURE_START_DATE)
        , exposureDuration = isViable
            ? f.formatExposureDurationByFields(sample.fields, fields, timeService)
            : nvf.formatExposureDurationByFields(sample.fields, timeService)

    return startDateField
        ? [
            t.defaultTextNode(timeService.formatCtzDate(startDateField.value)),
            t.defaultTextNode(', '),
            ...exposureDuration,
        ]
        : [t.emptyTextNode()]
}

function filterInactiveEntities<T extends { isActive: boolean }>(entities: T[]) {
    return entities.filter(_ => _.isActive)
}

function filterNotShownOnBookingInFields(fields: CustomField[], isViable: boolean) {
    return fields.filter(_ => {
        const settings = isViable ? _.viableSettings : _.nonViableSettings

        return settings.isActive && (settings.showOnBookingIn || _.index === fieldIndex.EXPOSURE_START_DATE || _.index === fieldIndex.EXPOSURE_END_DATE)
    })
}

function convertToReadableBytes(bytes: number) {
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
        , sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    return (bytes / Math.pow(1024, i)).toFixed(1) + sizes[i]
}

function formatSampleOperators(operators: SampleOperator[] | undefined): t.Text {
    if (!operators || operators.length === 0)
        return [t.emptyTextNode()]

    return operators
        .map(_ => formatActiveState(_.name, _.isActive))
        .map((_, i) => i === 0 ? _ : [t.defaultTextNode('\n'), ..._])
        .flatMap(_ => _)
}

function formatSampleBatchNumbers(batchNumbers: string[] | undefined): t.Text {
    if (!batchNumbers || batchNumbers.length === 0)
        return [t.emptyTextNode()]

    return batchNumbers
        .map(_ => [t.defaultTextNode(_)])
        .map((_, i) => i === 0 ? _ : [t.defaultTextNode('\n'), ..._])
        .flat()
}

function formatSampleTypeField(sample: Pick<Sample, 'fields'>): string {
    const id = sample.fields.find(_ => _.index === fieldIndex.PLATE_TYPE)?.value

    return PLATE_TYPE.find(_ => _.id === id)?.name ?? ''
}

function getAlertLimitField(sample: SampleDetails) {
    const exposureLocationField = sample.fields.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_NAME)!

    if (exposureLocationField.notRecorded)
        return NOT_RECORDED

    return sample.fields.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ALERT_LIMIT)!.value
}

function getActionLimitField(sample: SampleDetails) {
    const exposureLocationField = sample.fields.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_NAME)!

    if (exposureLocationField.notRecorded)
        return NOT_RECORDED

    return sample.fields.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ACTION_LIMIT)!.value
}

function checkIfFingerdab(sampleTypeId: string | undefined, sampleTypes: SampleType[]) {
    const sampleType = sampleTypes.find(_ => _.id === sampleTypeId)

    return sampleType && (sampleType.sampleType === FINGERDAB_PLATE || sampleType.sampleType === FINGERDAB_TWO_HANDS_PLATE)
}

function findFieldValue(fields: Pick<FieldValues, 'index'>[], values: FieldValues[], index: number | undefined): FieldValues | undefined {
    return values[findFieldValuePos(fields, index)]
}

function findFieldValuePos(values: Pick<FieldValues, 'index'>[], index: number | undefined) {
    return values.findIndex(_ => _.index === index)
}

function valuePath(pos: number) {
    return `fields[${pos}].value`
}

function notRecordedPath(pos: number) {
    return `fields[${pos}].notRecorded`
}

function getFieldName(customFields: CustomField[], fieldIndex: fieldIndex.FieldIndex) {
    const field = customFields.find(_ => _.index === fieldIndex)

    return field && field.fieldName || ''
}

function getFieldValue(fieldValues: Pick<FieldValues, 'index' | 'value'>[] | undefined, index: fieldIndex.FieldIndex) {
    const field = fieldValues && fieldValues.find(_ => _.index === index)
    return field && field.value
}

function getFieldNotRecorded(fieldValues: (FieldValues | FilterFieldValue)[] | undefined, index: fieldIndex.FieldIndex) {
    const field = fieldValues && fieldValues.find(_ => _.index === index)
    return field && field.notRecorded
}

function getNotDefaultCustomFields(fields: CustomField[]) {
    return fields.filter(it => !STANDARD_FIELD_INDEXES.some(_ => _ === it.index))
}

function getAlwaysAvailableFlags() {
    return flags.default.filter(_ =>
        _.id !== flags.AWAITING_BOOK_IN_CONFIRMATION
        && _.id !== flags.AWAITING_CFU_COUNT_VERIFICATION)
}

function formatSampleStatus(sample: Sample | SampleDetails): string {
    const status = calculateSampleStatus(sample)
        , statusName = sampleStatus(status)
        , bookInConfirmationMessage = !sample.bookInConfirmed && !sample.nullified ? ' (awaiting book in confirmation)' : ''
        , cfuConfirmationMessage = sample.awaitingCfuConfirmation && !sample.nullified ? ' (awaiting CFU count verification)' : ''
        , compromisedMessage = sample.compromised ? ' (compromised)' : ''
        , forReviewMessage = sample.isForReview ? ' (for review)' : ''

    return `${statusName}${bookInConfirmationMessage}${cfuConfirmationMessage}${compromisedMessage}${forReviewMessage}`
}

function sampleStatus(status: number): string {
    return SAMPLE_STATUS.find(_ => _.id === status)!.name
}

function calculateSampleStatus(sample: Sample | SampleDetails) {
    if (sample.nullified)
        return sampleStatuses.NULLIFIED

    const plateTypeField = sample.fields.find(_ => _.index === fieldIndex.PLATE_TYPE)
        , plateTypeFieldValue = plateTypeField && plateTypeField.value
        , isFingerdabTwoHands = plateTypeFieldValue === types.FINGERDAB_TWO_HANDS_PLATE

    const status = growthStatusToSampleStatus(sample.identifications)
        , statuses = isFingerdabTwoHands
            ? [status, growthStatusToSampleStatus(sample.optionalIdentifications!)]
            : [status]
        , statusPriority = [sampleStatuses.GROWTHS_AWAITING_IDENTIFICATION, sampleStatuses.GROWTHS_ID_COMPLETE, sampleStatuses.NO_GROWTH, sampleStatuses.BOOKED_IN] as const
        , combinedStatus = statusPriority.find(_ => statuses.indexOf(_) !== -1)!

    return combinedStatus
}

function growthStatusToSampleStatus(growths: IdentificationRows): sampleStatuses.SampleStatus {
    const status = calcGrowthsStatus(growths)

    if (status === growthsStatus.NO_GROWTH_ID)
        return sampleStatuses.NO_GROWTH

    if (status === growthsStatus.GROWTH_ID)
        return growths.complete ? sampleStatuses.GROWTHS_ID_COMPLETE : sampleStatuses.GROWTHS_AWAITING_IDENTIFICATION

    return sampleStatuses.BOOKED_IN
}

function calcGrowthsStatus(identifications: ReadingIdentificationRows | undefined): growthsStatus.GrowthsStatus {
    if (identifications === undefined)
        return growthsStatus.UNIDENTIFIED_ID

    const rows = identifications.rows

    if (rows.length === 0)
        return growthsStatus.UNIDENTIFIED_ID

    if (rows.length > 1)
        return growthsStatus.GROWTH_ID

    if (rows[0].cfuCount === undefined)
        // case is applicable only for editing during reading
        return growthsStatus.UNIDENTIFIED_ID

    return rows[0].cfuCount === 0 ? growthsStatus.NO_GROWTH_ID : growthsStatus.GROWTH_ID
}

function breachLabelText(sample: Sample | SampleDetails) {
    if (sample.nullified)
        return 'Nullified'

    const sampleBreach = sample.breach
    if (sampleBreach === breachTypes.ALERT_LIMIT)
        return 'Alert'
    if (sampleBreach === breachTypes.ACTION_LIMIT)
        return `Action${sample.manuallyActionLimitBreached ? ' (manual)' : ''}`
    return ''
}

function calculateTotalCFU(growths?: IdentificationRows | ReadingIdentificationRows) {
    return growths
        ? growths.rows
            .filter(_ => _.cfuCount !== NOT_VALID_VALUE)
            .reduce((acc, v) => acc + (v.cfuCount || 0), 0)
        : 0
}

function isBreachedByTotalCfu(sample: Sample | SampleDetails, isOptionalGrowth: boolean) {
    const growths = sample.identifications.rows
        , optionalGrowths = sample.optionalIdentifications?.rows || []
        , totalCfu = growths.reduce((acc, _) => acc + _.cfuCount, 0)
        , optionalTotalCfu = optionalGrowths.reduce((acc, _) => acc + _.cfuCount, 0)
        , actionField = sample.fields.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ACTION_LIMIT)
        , alertField = sample.fields.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ALERT_LIMIT)
        , actionLimit = actionField && actionField.value
        , alertLimit = alertField && alertField.value
        , cfuCount = isOptionalGrowth ? optionalTotalCfu : totalCfu
        , actionLimitBreached = actionLimit && actionLimit <= cfuCount
        , alertLimitBreached = alertLimit && alertLimit <= cfuCount

    return {
        actionLimitBreached: actionLimitBreached,
        alertLimitBreached: !actionLimitBreached && alertLimitBreached,
    }
}

function getBreachTypeWithCompromised(sample: Sample): breachTypes.BreachTypeWithCompromised {
    return sample.compromised ? breachTypes.COMPROMISED : sample.breach
}

function isBreachedByBehaviour(behaviour: number | undefined) {
    return behaviour === behaviours.BREACH_ACTION_LIMIT
        || behaviour === behaviours.BREACH_ALERT_LIMIT
}

function getGeneratedBy(timeService: TimeService, userName: string, userEmail: string) {
    return `Generated by ${userName} (${userEmail}) at ${timeService.formatCtzTime(timeService.now())} on ${timeService.formatCtzDate(timeService.now())}`
}

function getEditedFieldHoverText(info: SampleEditedInfo, timeService: TimeService) {
    const editedBy = info.automatedAction
            ? 'Edited by automated action'
            : `Edited by ${info.lastEditorName}, ${info.lastEditorEmail}`
        , editedAt = `at ${timeService.formatCtzDateTime(info.lastEditedAt, true)}`

    return `${editedBy} ${editedAt}`
}

function getEditedFieldInfo(editedInfos: SampleEditedInfo[], fieldIndex: fieldIndex.FieldIndex) {
    return editedInfos.find(_ => _.fieldIndex === fieldIndex)
}

function getCustomFieldsDiff(sampleFieldsDiff: FieldValues[], oldFields: FieldValues[], newFields: FieldValues[]): FieldValues[] {
    const fields = sampleFieldsDiff.filter((_, index) => diffObject(oldFields[index], newFields[index]))

    fields.forEach(_ => {
        if (_.index === fieldIndex.EXPOSURE_START_TIME && !fieldExist(fields, fieldIndex.EXPOSURE_START_DATE)) {
            const startDatePos = findFieldValuePos(newFields, fieldIndex.EXPOSURE_START_DATE)
            fields.push(newFields[startDatePos])
        }
        if (_.index === fieldIndex.EXPOSURE_START_DATE && !fieldExist(fields, fieldIndex.EXPOSURE_START_TIME)) {
            const startTimePos = findFieldValuePos(newFields, fieldIndex.EXPOSURE_START_TIME)
            fields.push(newFields[startTimePos])
        }
        if (_.index === fieldIndex.EXPOSURE_END_TIME && !fieldExist(fields, fieldIndex.EXPOSURE_END_DATE)) {
            const endDatePos = findFieldValuePos(newFields, fieldIndex.EXPOSURE_END_DATE)
            fields.push(newFields[endDatePos])
        }
        if (_.index === fieldIndex.EXPOSURE_END_DATE && !fieldExist(fields, fieldIndex.EXPOSURE_END_TIME)) {
            const endTimePos = findFieldValuePos(newFields, fieldIndex.EXPOSURE_END_TIME)
            fields.push(newFields[endTimePos])
        }
    })

    return fields
}

function fieldExist(fields: FieldValues[], index: fieldIndex.FieldIndex) {
    return !!fields.find(f => f.index === index)
}

function formatFieldLabel(field: Pick<CustomField, 'fieldName' | 'numberMeasureUnit'>) {
    return field.fieldName + (field.numberMeasureUnit ? ` (${field.numberMeasureUnit})` : '')
}

function contaminations(identifications: IdentificationRows | undefined, showOnlyIdentification = false): { cfuCount: number, objectionable: boolean, types: t.Text[] }[] {
    const rows = identifications?.rows.filter(_ => _.cfuCount > 0) ?? []

    if (rows.length === 0)
        return []

    return rows.map(_ =>
        ({
            cfuCount: _.cfuCount,
            objectionable: _.objectionable,
            types: showOnlyIdentification ? mapIdentification(_.types) : mapTypes(_.types),
        })
    )

    function mapTypes(types: IdentificationRows['rows'][number]['types']): t.Text[] {
        const organism = types.find(_ => _.type === it.ORGANISM)
        if (organism)
            return [formatActiveState(organism.name!, organism.isActive)]

        return types.map(type => {
            if (!it.IDENTIFICATION_WITH_ARGUMENT.includes(type.type))
                return [t.defaultTextNode(it.IDENTIFICATION_TYPE.find(_ => _.id === type.type)!.name)]

            return [t.defaultTextNode(type.name!)]
        })
    }

    function mapIdentification(types: IdentificationRows['rows'][number]['types']): t.Text[] {
        const organism = types.find(_ => _.type === it.ORGANISM)
            , notYetIdentified = types.find(_ => _.type === it.NOT_YET_IDENTIFIED)

        if (organism)
            return [formatActiveState(organism.name!, organism.isActive)]

        if (notYetIdentified)
            return [[t.systemTextNode(NOT_YET_IDENTIFIED_NAME)]]

        return [[t.systemTextNode('No organism ID')]]
    }
}

function getDuplicatedBarcodeConfirmationInfo(): Confirmation {
    return {
        message: 'Warning, this barcode relates to a viable sample that has already been booked in. Would you like to view the viable sample?',
        type: confirmationType.CONFIRMATION_MODAL,
    }
}

export {
    getEditableSampleCustomFields,
    getSampleEditValuesFromCustomFields,
    findNotRecordedFilledValues,
    formatInlineExposureTime,
    filterInactiveEntities,
    convertToReadableBytes,
    formatSampleOperators,
    formatSampleBatchNumbers,
    formatSampleTypeField,
    getAlertLimitField,
    getActionLimitField,
    checkIfFingerdab,
    findFieldValue,
    findFieldValuePos,
    valuePath,
    notRecordedPath,
    getFieldName,
    getFieldValue,
    getFieldNotRecorded,
    getNotDefaultCustomFields,
    getAlwaysAvailableFlags,
    calculateSampleStatus,
    calcGrowthsStatus,
    sampleStatus,
    formatSampleStatus,
    breachLabelText,
    calculateTotalCFU,
    isBreachedByTotalCfu,
    isBreachedByBehaviour,
    getBreachTypeWithCompromised,
    getGeneratedBy,
    getCustomFieldsDiff,
    formatFieldLabel,
    contaminations,
    getEditedFieldHoverText,
    getEditedFieldInfo,
    filterNotShownOnBookingInFields,
    getDuplicatedBarcodeConfirmationInfo,
}
