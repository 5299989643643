import { downloadBlob } from '_/utils/blob'

import type { CustomReportGraphEdit, CustomReportGraphRemove, CustomReportCreate } from '_/model/analysis/custom-report/custom-report'

import type { ImageExportInfo } from '_/model/sample/image/image'

import type ApiService from '../api-service'
import type CustomReportService from '../custom-report-service'

import * as s from '_/model/context/electronic-signature-settings'

function factory(api: ApiService): CustomReportService {
    const resource = api.resource(['custom-reports'], s.EDITING_CUSTOM_REPORTS)
    return Object.assign(
        resource,
        {
            remove: resource.removeWithReason,
            createCustomReport,
            addGraphs,
            changeGraphs,
            removeGraph,
            changePosition,
            exportGraphs,
        },
    )

    function createCustomReport(customReport: CustomReportCreate): Promise<string> {
        return api.post(['custom-reports'], customReport)
    }

    function addGraphs(id: string, request: CustomReportGraphEdit): Promise<void> {
        return api.post(['custom-reports', id, 'add-graphs'], request)
    }

    function changeGraphs(id: string, request: CustomReportGraphEdit): Promise<void> {
        return api.postWithReason(['custom-reports', id, 'change-graphs'], s.EDITING_CUSTOM_REPORTS, request)
    }

    function removeGraph(id: string, request: CustomReportGraphRemove): Promise<void> {
        return api.postWithReason(['custom-reports', id, 'delete-graph'], s.EDITING_CUSTOM_REPORTS, request)
    }

    function changePosition(id: string, request: CustomReportGraphEdit): Promise<void> {
        return api.post(['custom-reports', id, 'change-position'], request)
    }

    function exportGraphs(graphs: ImageExportInfo[]): Promise<void> {
        const formData = new FormData()
        graphs.forEach(_ => formData.append('graphs', _.blob, _.fileName))

        return api.postFile(['custom-reports', 'export'], formData)
            .then(_ => downloadBlob(_.blob, _.filename))
    }
}

export default factory
