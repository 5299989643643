import * as invitationStates from '_/constants/context-invitation-state'

interface Props {
    invitationState?: number
    hasExpiredOrNotFound: boolean
    processedAt?: string
}

const ContextInviteMessage: React.FC<Props> = props =>
    <div>
        {props.invitationState === invitationStates.REJECTED && !props.hasExpiredOrNotFound &&
            <p className='text-danger text-start mt-4'>
                <b>You rejected this invitation on {props.processedAt}</b>
            </p>
        }
        {props.invitationState === invitationStates.ACCEPTED && !props.hasExpiredOrNotFound &&
            <p className='text-danger text-start mt-4'>
                <b>You accepted this invitation on {props.processedAt}</b>
            </p>
        }
        {props.hasExpiredOrNotFound &&
            <p className='text-danger text-start mt-4'>
                <b>Invitation was not found or had expired</b>
            </p>
        }
    </div>

export default ContextInviteMessage
