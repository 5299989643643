import { noop } from '_/utils/function'

import { Modal } from '_/components/modal'

const Spinner = () =>
    <Modal isOpen shouldCloseOnOutsideClick={false} onClose={noop}>
        <i className='preview-spinner material-icons text-white md-48'>sync</i>
    </Modal>

export default Spinner
