import * as bp from '_/constants/business-permission'
import * as tiers from '_/constants/tiers'

const parentChild = new Map<bp.BusinessPermission, bp.BusinessPermission[]>([
    [bp.USE_ANALYSIS_AND_REPORTS, [bp.EXPORT_DATA, bp.EXPORT_CUSTOM_REPORTS_DATA, bp.MANAGE_CUSTOM_REPORTS]],
    [bp.VIEW_SAMPLES, [bp.BOOK_IN_SAMPLES, bp.EDIT_SAMPLES, bp.EXPORT_SAMPLES, bp.IMPORT_SAMPLES, bp.READ_SAMPLES]],
    [bp.EDIT_SAMPLES, [bp.CONFIRM_BOOK_IN_FOR_SAMPLES, bp.VERIFY_CFU_COUNT]],
    [bp.VIEW_SCHEDULE, [bp.EDIT_SCHEDULE]],
    [bp.VIEW_MONITORING_OVERVIEW, [bp.EDIT_MONITORING_OVERVIEW]],
    [bp.VIEW_USERS, [bp.MANAGE_API_KEYS]],
    [bp.VIEW_TRENDS, [bp.EDIT_TREND_INVESTIGATION_REFS]],
    [bp.CC_VIEW_PLATES, [bp.CC_CREATE_AND_EDIT_PLATES, bp.CC_EXPORT_DATA]],
    [bp.CC_VIEW_USERS, [bp.CC_MANAGE_API_KEYS]],
])

const parentSecondaryChild = new Map<bp.BusinessPermission, bp.BusinessPermission[]>([
    [bp.VIEW_SAMPLES, [bp.USE_ANALYSIS_AND_REPORTS, bp.VIEW_THE_GLOBAL_AUDIT_TRAIL, bp.VIEW_INSIGHTS, bp.VIEW_SCHEDULE, bp.VIEW_MONITORING_OVERVIEW]],
    [bp.VIEW_USERS, [bp.MANAGE_CUSTOM_REPORTS, bp.VIEW_THE_GLOBAL_AUDIT_TRAIL]],
    [bp.USE_ANALYSIS_AND_REPORTS, [bp.VIEW_THE_GLOBAL_AUDIT_TRAIL]],
    [bp.READ_SAMPLES, [bp.VERIFY_CFU_COUNT]],
    [bp.VIEW_THE_DASHBOARD, [bp.VIEW_INSIGHTS]],
    [bp.CC_VIEW_PLATES, [bp.CC_VIEW_AUDIT_TRAIL]],
    [bp.CC_VIEW_USERS, [bp.CC_VIEW_AUDIT_TRAIL]],
])

const childParent = calcChildParent(parentChild)
    , secondaryChildParent = calcChildParent(parentSecondaryChild)

function calcChildParent(parentChild: Map<bp.BusinessPermission, bp.BusinessPermission[]>) {
    const childParent = new Map<bp.BusinessPermission, bp.BusinessPermission[]>()

    parentChild.forEach((children, parent) => {
        children.forEach(child => {
            const currentParents = childParent.get(child) || []
            childParent.set(child, currentParents.concat(parent))
        })
    })

    return childParent
}

function editDisabled(permission: bp.BusinessPermission, current: bp.BusinessPermission[]) {
    const currentSet = new Set(current)
        , dependentChildEnabled = (parentChild.get(permission) || []).some(_ => currentSet.has(_))
            || (parentSecondaryChild.get(permission) || []).some(_ => currentSet.has(_))
        , dependentParentDisabled = (childParent.get(permission) || []).some(_ => !currentSet.has(_))
            || (secondaryChildParent.get(permission) || []).some(_ => !currentSet.has(_))

    return dependentChildEnabled || dependentParentDisabled
}

function permissionLevel(permission: bp.BusinessPermission): number {
    let result = 0

    while (childParent.has(permission)) {
        permission = childParent.get(permission)![0]
        ++result
    }

    return result
}

function secondaryParents(permission: bp.BusinessPermission): bp.BusinessPermission[] {
    return secondaryChildParent.get(permission) || []
}

function hasNoTierPermission(permission: bp.BusinessPermission, tier: tiers.Tier) {
    switch (permission) {
        case bp.MANAGE_CUSTOM_REPORTS:
            return tier < tiers.INSIGHT

        case bp.EXPORT_CUSTOM_REPORTS_DATA:
            return tier < tiers.INSIGHT

        case bp.MANAGE_FLOOR_PLANS:
            return tier < tiers.INSIGHT

        case bp.VIEW_INSIGHTS:
            return tier < tiers.INSIGHT

        case bp.MANAGE_API_KEYS:
            return tier < tiers.ENTERPRISE

        case bp.VIEW_TRENDS:
        case bp.MANAGE_TRENDS:
            return tier < tiers.INSIGHT

        default:
            return false
    }
}

export {
    editDisabled,
    hasNoTierPermission,
    permissionLevel,
    secondaryParents,
}
