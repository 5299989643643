import type { SampleSession } from '_/model/predefined-lists/session/types'
import Select from '_/components/downshift-select'

import Menu from './menu-container'
import { VOID_ID } from '_/utils/tree'
import { defaultTextNode, systemTextNode } from '_/model/text/text'

interface CopyGroupMenuProps {
    button: HTMLButtonElement | null
    sessions: SampleSession[]
    onCopyGroups: (id: string) => void
    onClose: () => void
}

const CopyGroupMenu = (props: CopyGroupMenuProps) =>
    <Menu element={props.button} onClose={props.onClose} className='px-2'>
        <label htmlFor='sessionId' className='col-form-label py-0'>Copy all groups to session</label>
        <Select
            id='sessionId'
            className='form-control col'
            entities={props.sessions}
            calcId={_ => _.id}
            calcName={_ => _.id === VOID_ID ? [systemTextNode(_.name)] : [defaultTextNode(_.name)]}
            autocomplete
            input={{
                onChange: props.onCopyGroups,
            }}
        />
    </Menu>

export default CopyGroupMenu
