const ICON_WIDTH = 34
    , ICON_HEIGHT = 18
    , ICON_PADDING = 4
    , ICON_MARGIN = 2
    , INNER_FIGURE_SIZE = ICON_HEIGHT - 2 * ICON_PADDING
    , NO_LIMIT_BREACH_ICON_SIZE = 12
    , MAX_DISPLAYABLE_LIMIT_BREACH_NUMBER = 99

export {
    ICON_WIDTH,
    ICON_HEIGHT,
    ICON_PADDING,
    ICON_MARGIN,
    INNER_FIGURE_SIZE,
    NO_LIMIT_BREACH_ICON_SIZE,
    MAX_DISPLAYABLE_LIMIT_BREACH_NUMBER,
}
