const ANALYSIS_GRAPH = 0
    , CONTEXTUAL_REPORT = 1
    , CUSTOM_REPORT = 2

type FloorPlanLocationPage = never
    | typeof ANALYSIS_GRAPH
    | typeof CONTEXTUAL_REPORT
    | typeof CUSTOM_REPORT

export {
    ANALYSIS_GRAPH,
    CONTEXTUAL_REPORT,
    CUSTOM_REPORT,
    FloorPlanLocationPage,
}
