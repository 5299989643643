import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type MonitoringOverviewService from '_/services/monitoring-overview-service'

import * as actions from './actions'

const factory = (service: MonitoringOverviewService): EffectsFactory => () => [
    handler(actions.loadMonitoringOverview, service.getMonitoringOverview),
]

export default factory
