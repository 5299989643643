import { useEffect, useAction, useState } from '_/facade/react'
import BLANK_IMAGE from '_/constants/blank-image'
import type ImageTokens from '_/model/floor-plan/image-tokens'
import * as a from './actions'

function useImageTokens(imageId: string | undefined): ImageTokens | undefined {
    const getImageTokens = useAction(a.getImageTokens)
        , [tokens, setTokens] = useState<ImageTokens>()

    useEffect(
        () => {
            if (imageId === undefined) {
                setTokens(undefined)
                return
            }

            // getImageTokens performs side effect (ajax) so must be used inside use(Layout)Effect hook
            getImageTokens(imageId).then(setTokens)

            return () => setTokens(undefined)
        },
        [imageId, getImageTokens]
    )

    return tokens
}

function useImageSource(imageId: string | undefined, size: keyof Pick<ImageTokens, 'medium' | 'original'>) {
    const tokens = useImageTokens(imageId)

    return tokens ? tokens[size] : BLANK_IMAGE
}

export {
    useImageTokens,
    useImageSource,
}
