import { classnames, useSelector } from '_/facade/react'

import Button from '_/components/button'
import { LinkButton } from '_/components/link'

import * as routes from '_/constants/routes'
import type SampleSearchFields from '_/model/sample/search'
import { paramsFilter } from '_/model/filters/helpers'

interface Props {
    routeParams?: SampleSearchFields
    hideGoToSamples?: boolean
    disabledGoToSamplesButton?: boolean
    exportButtonDisabled?: boolean
    onExport: () => void
    className?: string
    testId?: string
}

function TableActionButtons(props: Props) {
    const canExportData = useSelector(_ => _.auth.permissions.exportData)

    return (
        <div className={classnames(props.className ?? '')}>
            {!props.hideGoToSamples && props.routeParams &&
                <LinkButton
                    className={classnames('btn-primary me-1', { disabled : props.disabledGoToSamplesButton })}
                    routeName={routes.SAMPLES}
                    routeParams={paramsFilter(props.routeParams)}
                    testId={`${props.testId}-go-to-samples`}
                >
                    Go to viable samples
                </LinkButton>
            }
            <Button
                className={classnames('btn-primary', {'cursor-default': props.exportButtonDisabled})}
                disabled={props.exportButtonDisabled}
                hasNoPermissions={!canExportData}
                onClick={props.onExport}
                testId={`${props.testId}-export`}
            >
                Export
            </Button>
        </div>
    )
}

export default TableActionButtons
