import { useState, useAction, useSelector, classnames, useEffect, useCallback } from '_/facade/react'
import { Table } from '_/components/table'
import PageHeader from '_/components/page-header'
import Button from '_/components/button'

import { SITE, ORGANISATION } from '_/constants/context-level'

import type User from '_/model/users/types'
import type { UserMembership, UserContext } from '_/model/auth/types'
import type Role from '_/model/roles/types'

import ChangeRole from './change-role'

import * as actions from '../actions'
import * as confirmationActions from '_/features/confirmation/actions'
import * as contextActions from '_/features/contexts/actions'
import { sortMemberships, isSystemAdmin } from '_/model/users/helpers'

interface ChangeRoleState {
    isOpen: boolean
    membership: UserMembership | undefined
    existingRoles: Role[]
}

function ManageMemberships() {
    const changeRoleEmptyState = { isOpen: false, membership: undefined, existingRoles: [] }
        , [changeRoleState, setChangeRoleState] = useState<ChangeRoleState>(changeRoleEmptyState)
        , [user, reload] = useUser()
        , authUser = useSelector(_ => _.auth.user)
        , handleSuspendUserMembership = useSuspend(user?.id, reload)
        , handleResumeUserMembership = useResume(user?.id, reload)
        , openChangeRoleModal = useChangeRoleModal(user, setChangeRoleState)

    function canManageUserInOrgContext(targetMembership: UserMembership) {
        return !(authUser
            && isSystemAdmin(authUser)
            && (targetMembership.type === ORGANISATION) && !targetMembership.role.isAdmin)
            && !isOwnMembership(authUser)
    }

    function canManageUserInSiteContext(targetMembership: UserMembership) {
        return !(authUser
            && isSystemAdmin(authUser)
            && targetMembership.type === SITE)
            && !isOwnMembership(authUser)
    }

    function isOwnMembership(authUser: UserContext | undefined): boolean {
        if (!authUser || !user)
            return false

        return authUser.id === user.id
    }

    return (
        <>
            <PageHeader sticky title='Memberships' />
            {changeRoleState.isOpen &&
                <div>
                    <ChangeRole
                        isOpen
                        onClose={() => setChangeRoleState(changeRoleEmptyState)}
                        user={user}
                        membership={changeRoleState.membership!}
                        existingRoles={changeRoleState.existingRoles}
                        onRoleChanged={reload}
                    />
                </div>
            }

            <div className='overflow-auto flex-fill'>
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Context</th>
                            <th>Role</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {user && sortMemberships(user.memberships).map(_ =>
                            <tr key={_.id} className={classnames({'text-secondary': _.isSuspended})}>
                                <td>{_.description}</td>
                                <td>{_.role.name}</td>
                                <td className='text-end'>
                                    {authUser && !authUser.membership.role.isAdmin && _.role.isAdmin ? null :
                                        <div>
                                            {!_.isSuspended
                                                ? <div>
                                                    <Button
                                                        className={classnames('btn-link text-danger', {
                                                        disabled: !canManageUserInSiteContext(_) || !canManageUserInOrgContext(_),
                                                        })}
                                                        onClick={() => handleSuspendUserMembership(_)}
                                                        hasNoPermissions={!canManageUserInSiteContext(_) || !canManageUserInOrgContext(_)}
                                                        testId={`memberships-${_.description}-${_.role.name}-suspend`}
                                                    >
                                                        Suspend
                                                    </Button>
                                                    <Button
                                                        className={classnames('btn-link', {
                                                        disabled: !canManageUserInSiteContext(_),
                                                        })}
                                                        onClick={() => openChangeRoleModal(_)}
                                                        hasNoPermissions={!canManageUserInSiteContext(_)}
                                                        testId={`memberships-${_.description}-${_.role.name}-change-role`}
                                                    >
                                                        Change role
                                                    </Button>
                                                </div>
                                                : <Button
                                                    className={classnames('btn-link text-success', {
                                                    disabled: !canManageUserInSiteContext(_) || !canManageUserInOrgContext(_),
                                                    })}
                                                    onClick={() => handleResumeUserMembership(_)}
                                                    hasNoPermissions={!canManageUserInSiteContext(_) || !canManageUserInOrgContext(_)}
                                                    testId={`memberships-${_.description}-${_.role.name}-resume`}
                                                >
                                                    Resume
                                                </Button>
                                            }
                                        </div>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ManageMemberships

function useUser() {
    const userId = useSelector(_ => _.router.route?.params.id)
        , loadUser = useAction(actions.loadUser)
        , [user, setUser] = useState<User | undefined>()
        , load = useCallback(
            () => {
                if (!userId)
                    return

                loadUser(userId).then(setUser)
            },
            [userId, loadUser]
        )

    useEffect(load, [load])
    return [user, load] as const
}

function useChangeRoleModal(user: User | undefined, setChangeRoleState: (state: ChangeRoleState) => void) {
    const doActionIfIpWhitelisted = useDoActionIfIpWhitelisted()

    function openChangeRoleModalInternal(membershipId: string) {
        const membership = user!.memberships.find(_ => _.id === membershipId)!
            , existingRoles = user!.memberships
                .filter(_ => _.contextId === membership.contextId && _.id !== membership.id)
                .map(_ => _.role)

        setChangeRoleState({
            isOpen: true,
            membership,
            existingRoles,
        })
    }

    function openChangeRoleModal(membership: UserMembership) {
        doActionIfIpWhitelisted(membership.context.id, () => openChangeRoleModalInternal(membership.id))
    }

    return openChangeRoleModal
}

function useSuspend(userId: string | undefined, reset: () => void) {
    const suspendUserMembership = useAction(actions.suspendUserMembership)
        , showConfirmationModal = useAction(confirmationActions.showConfirmationModal)
        , doActionIfIpWhitelisted = useDoActionIfIpWhitelisted()

    function handleSuspendUserMembershipInternal(membership: UserMembership) {
        if (!userId)
            return

        showConfirmationModal('Are you sure you want to suspend user membership?')
            .then(() => suspendUserMembership({
                userId,
                membershipId: membership.id,
                signatureSettings: membership.context.electronicSignatureSettings,
            }))
            .then(reset)
    }

    function handleSuspendUserMembership(membership: UserMembership) {
        doActionIfIpWhitelisted(membership.context.id, () => handleSuspendUserMembershipInternal(membership))
    }

    return handleSuspendUserMembership
}

function useResume(userId: string | undefined, reset: () => void) {
    const resumeUserMembership = useAction(actions.resumeUserMembership)
        , showConfirmationModal = useAction(confirmationActions.showConfirmationModal)
        , doActionIfIpWhitelisted = useDoActionIfIpWhitelisted()

    function handleResumeUserMembershipInternal(membership: UserMembership) {
        if (!userId)
            return

        showConfirmationModal('Are you sure you want to resume user membership?')
            .then(() => resumeUserMembership({
                userId,
                membershipId: membership.id,
                signatureSettings: membership.context.electronicSignatureSettings,
            }))
            .then(reset)
    }

    function handleResumeUserMembership(membership: UserMembership) {
        doActionIfIpWhitelisted(membership.context.id, () => handleResumeUserMembershipInternal(membership))
    }

    return handleResumeUserMembership
}

function useDoActionIfIpWhitelisted() {
    const validateIpContextInWhitelist = useAction(contextActions.validateIpInContextWhitelist)
        , showWarningModal = useAction(confirmationActions.showWarningModal)

    function doActionIfIpWhitelisted(contextId: string, onSuccess: () => void) {
        validateIpContextInWhitelist(contextId)
            .then(_ => {
                if (!_.isValid) {
                    showWarningModal(_.message)
                }
                else {
                    onSuccess()
                }
            })
    }

    return doActionIfIpWhitelisted
}
