import type { ValidationResult } from '_/utils/form/validate'
import { required } from '_/utils/form/validate'

import type { LocationsReport } from '_/model/reports/locations-report/types'
import { validateReportDate } from '_/model/reports/validate'

function validate(entity: Partial<LocationsReport>) {
    const result: ValidationResult<LocationsReport> = validateReportDate(entity)
        , locationId = required('Location')(entity.locationId)

    if (locationId)
        result.locationId = locationId

    return result
}

export default validate

