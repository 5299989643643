import { Form } from 'react-final-form'
import type { FormApi } from 'final-form'

import { useSelector, useAction, useEffect, useCallback, useState } from '_/facade/react'
import { UnitContainer } from '_/components/layout'
import { TextField, Submit, submitDisabled } from '_/components/form'
import { LinkButton } from '_/components/link'
import { useContextSwitchObserver } from '_/components/context-observer'
import FormChangesObserver from '_/components/form/form-changes-observer'

import { USER_CHANGE_PASSWORD } from '_/constants/routes'

import type User from '_/model/users/types'

import * as actions from '../actions'
import * as toastActions from '_/features/toasts/actions'
import { CHANGES_SAVED } from '_/features/samples/messages'

import validate from './validate'
import { convertToUserEdit } from '_/model/users/helpers'
import { ENTERPRISE } from '_/constants/tiers'

function UserAccount() {
    const auth = useSelector(_ => _.auth)
        , [user, reload] = useUser()
        , handleSaveChanges = useSaveHandler(user, reload)
        , isMemberOfEnterpriseTierContext = auth.memberships.some(_ => _.tier === ENTERPRISE)

    if (!user)
        return null

    return (
        <UnitContainer>
            <Form<User>
                onSubmit={(_, form) => handleSaveChanges(_, form)}
                initialValues={user}
                validate={validate}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <FormChangesObserver form={form} />
                        <div className='row'>
                            <div className='col-6'>
                                <fieldset>
                                    <h5 className='fw-normal mb-3' data-testid='account-header'>
                                        {user.name} [{auth.user && auth.user.membership.role.name}]
                                    </h5>
                                    <TextField name='name' id='user-name' testId='field-name'>Name</TextField>
                                    <TextField name='email' id='user-email' className='mb-0' testId='field-email'>Email</TextField>
                                    {isMemberOfEnterpriseTierContext && form.values.email !== user.email &&
                                        <div className='alert alert-warning user-formatted-text mt-3'>
                                            You are about to change your SmartControl login email address.
                                            If you use your Microsoft account to log in to SmartControl for any organisation,
                                            make sure the new email address matches your Microsoft email.
                                            If the email you set in SmartControl is different to the email you use to sign in with Microsoft,
                                            this will prevent you from signing in with Microsoft.
                                        </div>
                                    }
                                    {user.hasPendingEmailChangeRequest &&
                                        <div className='text-danger'>
                                            An email change request has been sent.<br/>
                                            Please check {user.notConfirmedEmail} and confirm it
                                        </div>
                                    }
                                </fieldset>
                            </div>
                        </div>
                        <br/>
                        <div className='row'>
                            <div className='col-6'>
                                <Submit disabled={submitDisabled(form)} testId='account-save'>Save</Submit>
                                <LinkButton
                                    className='ms-1 btn-secondary'
                                    routeName={USER_CHANGE_PASSWORD}
                                    testId='change-password'
                                >
                                    Change password
                                </LinkButton>
                            </div>
                        </div>
                    </form>
                }
            />
        </UnitContainer>
    )
}

export default UserAccount

function useUser() {
    const loadUser = useAction(actions.loadUser)
        , userId = useSelector(_ => _.auth.user?.id)
        , [user, setUser] = useState<User | undefined>()
        , contextSwitch = useContextSwitchObserver()
        , load = useCallback(
            () => {
                if (userId)
                    loadUser(userId).then(setUser)
            },
            [loadUser, userId]
        )

    useEffect(load, [load, contextSwitch])

    return [user, load] as const
}

function useSaveHandler(user: User | undefined, reload: () => void) {
    const saveChanges = useAction(actions.saveChanges)
        , addSuccess = useAction(toastActions.addSuccess)

        , handleSaveChanges = (updatedUser: User, form: FormApi<User>) => {
            const id = user?.id
                , oldUser = user && convertToUserEdit(user)
                , newUser = convertToUserEdit(updatedUser)

            if (id && oldUser)
                return saveChanges({ id, oldUser, newUser })
                    .then(() => {
                        setTimeout(() => form.reset(updatedUser))
                        addSuccess(CHANGES_SAVED)
                    })
                    .then(reload)
        }

    return handleSaveChanges
}
