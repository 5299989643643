import { useState, useAction, useSelector } from '_/facade/react'

import { Modal, ModalHeader, ModalBody } from '_/components/modal'
import Button, { Close } from '_/components/button'

import type { UserMembership } from '_/model/auth/types'
import { sortMemberships } from '_/model/users/helpers'
import type { NotificationChangeRoleMetadata } from '_/model/notification/types'
import * as authActions from '_/features/auth/actions'
import { getEntityTypeName } from '_/model/notification/helpers'
import { DATA_EXPORT } from '_/model/notification/notification-type'

interface Props {
    onClose: () => void
    data: NotificationChangeRoleMetadata
    onRoleChanged: () => void
}

function NotificationChangeRoleModal(props: Props) {
    const [disableButton, setDisableButton] = useState(false)
        , changeContext = useAction(authActions.changeContext)
        , entityTypeName = getEntityTypeName(props.data.notification)
        , currentMembership = useSelector(_ => _.auth.user?.membership)
        , exportNotificationParticle = props.data.notification.type === DATA_EXPORT ? ' export modal' : ''

    function handleClick(membership: UserMembership) {
        setDisableButton(true)
        changeContext(membership.id)
            .then(props.onRoleChanged)
            .then(props.onClose)
            .finally(() => setDisableButton(false))
    }

    return (
        <Modal isOpen onClose={props.onClose}>
            <ModalHeader className='border-bottom-0 pb-0'>
                <h4>Change role</h4>
                <Close onClick={props.onClose} testId='close-notification-change-role' />
            </ModalHeader>
            <ModalBody>
                <div data-testid='notification-change-role-body'>
                    You cannot view this {entityTypeName}{exportNotificationParticle} as {currentMembership?.role.name} in {currentMembership?.context.description}, as this role does not have the necessary permissions. Change roles to continue.
                </div>
                <div className='p-0 mt-2'>
                    {sortMemberships(props.data.accessibleMemberships).map(membership =>
                        <Button
                            key={membership.id}
                            className='btn-link px-0'
                            onClick={() => handleClick(membership)}
                            disabled={disableButton}
                            testId={`notification-change-role-${membership.description}-${membership.role.name}`}
                        >
                            Change to {membership.description} ({membership.role.name})
                        </Button>
                    )}
                </div>
            </ModalBody>
        </Modal>
    )
}

export default NotificationChangeRoleModal
