import type { SamplesStatusCount, SamplesFlagCount } from '_/model/sample/sample-search-result'
import SAMPLE_STATUS from '_/constants/sample-status'
import * as flags from '_/constants/sample-flag'
import { getAlwaysAvailableFlags } from '../helpers'

interface Props {
    totalCount: number
    totalPerStatus: SamplesStatusCount[]
    totalPerFlag: SamplesFlagCount[]
    className?: string
    itemsLimit?: number
    bookInConfirmationEnabled: boolean
    cfuCountVerificationEnabled: boolean
    isPrintMode: boolean
    selectedSamplesCount: number
}

function statusTotalCount(statusId: number, totalPerStatus: SamplesStatusCount[]) {
    const status = totalPerStatus.find(_ => _.status === statusId)
    return status ? status.count : 0
}

function flagTotalCount(flagId: number, totalPerFlag: SamplesFlagCount[]) {
    const flag = totalPerFlag.find(_ => _.flag ===  flagId)
    return flag ? flag.count : 0
}

const SamplesStatusTotal: React.FC<Props> = props =>
    <div className={props.className}>
        <div>
            <h5>
                <b>Your filters matched {props.totalCount} results </b>
                {props.selectedSamplesCount > 0 && !props.isPrintMode &&
                    <span className='bg-light text-muted sample-list__label--font-size ms-2 mb-2 p-1 fw-normal'>
                        {`${props.selectedSamplesCount} viable sample${props.selectedSamplesCount > 1 ? 's' : ''} selected`}
                    </span>
                }
                {props.itemsLimit && props.totalCount > props.itemsLimit && props.isPrintMode
                    ? <span>
                        <span> - </span> <span style={{ color: 'red' }}> Print mode list is limited to {props.itemsLimit} records! You may change search filters</span>
                    </span>
                    : ''}
            </h5>
            {props.bookInConfirmationEnabled &&
                <span className='text-muted'>
                    {`${flagTotalCount(flags.AWAITING_BOOK_IN_CONFIRMATION, props.totalPerFlag)} Awaiting book in confirmation ᛫ `}
                </span>
            }
            {props.cfuCountVerificationEnabled &&
                <span className='text-muted'>
                    {`${flagTotalCount(flags.AWAITING_CFU_COUNT_VERIFICATION, props.totalPerFlag)} Awaiting CFU count verification ᛫ `}
                </span>
            }
            <span className='text-muted'>
                {getAlwaysAvailableFlags()
                    .map(_ => flagTotalCount(_.id, props.totalPerFlag) + ' ' + _.name)
                    .join(' ᛫ ')
                }
            </span>
            <span className='text-muted'> ᛫ </span>
            <span className='text-muted'>
                {SAMPLE_STATUS
                    .map(_ => statusTotalCount(_.id, props.totalPerStatus) + ' ' + _.name)
                    .join(' ᛫ ')
                }
            </span>
        </div>
    </div>

export default SamplesStatusTotal
