import type Permissions from '_/model/permissions/type'
import routes from './routes'
import * as rn from '_/constants/routes'

function canAccessState(name: rn.RouteName, permissions: Permissions) {
    const route = routes.find(_ => _.name === name)
    return route && route.permissions.some(_ => _ === 'none' || permissions[_])
}

function mustRedirectOnContextSwitch(name: rn.RouteName, permissions: Permissions) {
    const canAccessCurrentRoute = canAccessState(name, permissions)
        , route = routes.find(_ => _.name === name)
        , redirectToDefaultPage = !(route && route.contextSwitchAware && canAccessCurrentRoute)

    return redirectToDefaultPage
}

function getDefaultRoute(permissions: Permissions): rn.RouteName {
    const defaultRoutes: rn.RouteName[] = [
            rn.DASHBOARD,
            ...(!permissions.preferSettingsOverPlatesReading ? [rn.PLATES_READING as rn.RouteName] : []),
            rn.SETTINGS_CONTEXTS,
        ]

    return defaultRoutes.find(_ => canAccessState(_, permissions)) ?? rn.LOG_IN
}

export {
    canAccessState,
    mustRedirectOnContextSwitch,
    getDefaultRoute,
}
