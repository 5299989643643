import * as customFieldType from '_/constants/custom-field-column-type'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'
import type { PredefinedLists } from '_/model/app-state'
import type { SampleEditedInfo } from '_/model/sample/sample'

import { findFieldValuePos, getEditedFieldInfo } from '_/features/samples/helpers'

import TextFieldForm from '../sample-information/text-field-form'
import NumberFieldForm from '../sample-information/number-field-form'
import SelectionFieldForm from '../sample-information/selection-field-form'

interface Props {
    entity: SampleDetailsEdit | NonViableSampleEdit
    field: CustomField
    predefinedLists: PredefinedLists
    hasNoPermissions: boolean
    onSubmit: any
    sampleEditedInfo: SampleEditedInfo[]
    isViable?: boolean
}

function NotDefaultSampleField(props: Props) {
    const {field, sampleEditedInfo, onSubmit, entity} = props
        , position = findFieldValuePos(entity.fields, field.index)
        , editedInfo = getEditedFieldInfo(sampleEditedInfo, field.index)

    switch (field.fieldType) {
        case customFieldType.TEXT: {
            return <div key={position}>
                <TextFieldForm
                    entity={entity}
                    field={field}
                    onSubmit={onSubmit}
                    hasNoPermissions={props.hasNoPermissions}
                    editedInfo={editedInfo}
                    isViable={props.isViable}
                />
            </div>
        }
        case customFieldType.NUMBER: {
            return <div key={position}>
                <NumberFieldForm
                    entity={entity}
                    field={field}
                    onSubmit={onSubmit}
                    hasNoPermissions={props.hasNoPermissions}
                    editedInfo={editedInfo}
                    isViable={props.isViable}
                />
            </div>
        }
        case customFieldType.SELECTION: {
            return <div key={position}>
                <SelectionFieldForm
                    entity={entity}
                    field={field}
                    onSubmit={onSubmit}
                    hasNoPermissions={props.hasNoPermissions}
                    editedInfo={editedInfo}
                    isViable={props.isViable}
                />
            </div>
        }
        default: {
            return null
        }
    }
}

export default NotDefaultSampleField
