import { useEffect, useAction, useSelector } from '_/facade/react'

import WEEK from '_/constants/weekday'
import { VOID_ID } from '_/utils/tree'

import * as sessionActions from '_/features/predefined-lists/sample-session/actions'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import type SampleSearchFields from '_/model/sample/search'
import { useTimeService } from '_/components/time'
import type { ExportGraphByDayAndSession, MissedMonitoringReport } from '_/model/reports/missed-monitoring-report/types'
import { exportGraphByDayAndSession } from '_/model/reports/export'
import TableActionButtons from '_/features/reports/table-action-buttons'
import { computeAuthorInfo } from '_/features/analysis/ui/helpers'
import TableFooter from '../table-footer'
import type { LocationsReport } from '_/model/reports/locations-report/types'
import type { OperatorsReport } from '_/model/reports/operators-report/types'
import { formatExposureDateRange } from '_/model/reports/helpers'
import * as t from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'
import NoDataAvailable from '../no-data-available'

interface Props {
    name: string
    title: t.Text
    maxValue: number
    printMode: boolean
    entityName?: string
    report: LocationsReport | OperatorsReport | MissedMonitoringReport
    noDataAvailable: boolean
    hideGoToSamples?: boolean
    routeParams?: SampleSearchFields
    exportGraphData: ExportGraphByDayAndSession[]
    hideOtherSessionHint?: boolean
    renderDayCell: (sessionId: string, dayId: number) => React.ReactNode
    showSpinner: boolean
    testId?: string
}

function SessionDayGraphContainer(props: Props) {
    const sampleSessions: Pick<SampleSession, 'name' | 'id'>[] = useSessions()
        , sessions = sampleSessions.concat({ name: 'Other viable samples', id: VOID_ID })
        , user = useSelector(_ => _.auth.user)
        , timeService = useTimeService()

    return (
        <div>
            <div className='my-4 text-center'>
                <h4 data-testid='locations-report-subtitle'><FormattedText text={props.title}/></h4>
            </div>
            {!props.noDataAvailable && !props.showSpinner
                ? <div className='m-2'>
                    {sessions.map(session =>
                        <div className='d-flex d-print-block justify-content-between mb-1' key={session.id} data-testid='session-breaches-row'>
                            <div
                                className='location-report-session-breaches__session-name d-flex d-print-inline-block align-bottom align-items-center text-center justify-content-center custom-legend'
                                title={session.id === VOID_ID && !props.hideOtherSessionHint ? 'Includes viable samples from inactive sessions' : ''}
                                data-testid='session-name'
                            >
                                <FormattedText text={session.id === VOID_ID ? [t.systemTextNode(session.name)] : session.name} modifier='table-header' />
                            </div>
                            {WEEK.map(_ => props.renderDayCell(session.id, _.id))}
                        </div>
                    )}
                    <div className='d-flex justify-content-between d-print-block'>
                        <div className='location-report-session-breaches__session-name d-print-inline-block'></div>
                        {WEEK.map(_ =>
                            <div
                                key={_.id}
                                className='location-report-session-breaches__breaches-container d-print-inline-block text-center custom-legend'
                                data-testid='day-column-title'
                            >
                                {_.shortName}
                            </div>
                        )}
                    </div>
                    {props.printMode
                        ? <TableFooter report={props.report} entityName={props.entityName} />
                        : <TableActionButtons
                            disabledGoToSamplesButton={props.noDataAvailable}
                            exportButtonDisabled={props.noDataAvailable}
                            hideGoToSamples={props.hideGoToSamples}
                            className='text-end me-3 mb-3'
                            routeParams={props.routeParams}
                            onExport={() => {
                                const exportDate = timeService.formatCtzDate(timeService.now())
                                    , graphName = `SmartControl Graph for ${props.name} downloaded on ${exportDate}`
                                    , footer = {entityName: props.entityName, dateRange: formatExposureDateRange(props.report, timeService), author: computeAuthorInfo(timeService, user)}
                                exportGraphByDayAndSession(props.exportGraphData, sessions, props.maxValue, graphName, t.plainText(props.title), footer)
                            }}
                            testId={props.testId}
                        />
                    }
                </div>
                : <div className='pb-3 text-center'>
                    <NoDataAvailable showSpinner={props.showSpinner}>
                        <span className='text-muted text-uppercase' data-testid={`${props.testId}-no-data-message`}>
                            No data available
                        </span>
                    </NoDataAvailable>
                </div>
            }
        </div>
    )
}

export default SessionDayGraphContainer

function useSessions() {
    const load = useAction(sessionActions.loadSampleSessionList)
        , sessions = useSelector(_ => _.predefinedLists.sampleSessions)

    useEffect(() => { load() }, [load])

    return sessions.filter(_ => _.isActive)
}
