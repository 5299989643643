import { useEffect, useState, useAction, useCallback, useMemo, useSelector } from '_/facade/react'
import * as hooks from '_/hooks/shared-hooks'

import { fullNameLocationList } from '_/utils/exposure-location'
import { VOID_ID } from '_/utils/tree'

import { EmptyTableMessage, Table } from '_/components/table'
import { useTimeService } from '_/components/time'
import Checkbox from '_/components/checkbox-inline'
import { useContextSwitchObserver } from '_/components/context-observer'

import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'

import * as h from '_/model/scheduling/monitoring-overview/helpers'

import { navigateTo } from '_/features/routing/actions'

import * as actions from './actions'
import SampleCellData from './sample-cell-data'

import type { MonitoringOverviewFilter, MonitoringOverviewItems, MonitoringOverviewPage, SelectedSample } from '_/model/scheduling/monitoring-overview/types'
import * as dayPlanActions from '../day-scheduler/actions'
import type { DateTime } from '_/model/date-time'
import type { Guid } from '_/model/guid'
import FormattedText from '_/features/text/formatted-text'
import * as t from '_/model/text/text'
import Filter from './filter'
import PageHeader from './page-header'

import * as groupAction from '_/features/scheduling/monitoring-groups/actions'
import * as sampleTypesActions from '_/features/predefined-lists/sample-type/actions'
import * as sessionActions from '_/features/predefined-lists/sample-session/actions'
import * as exposureLocationActions from '_/features/predefined-lists/exposure-locations/actions'
import * as r from '_/constants/routes'
import { ALL_STATUSES } from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'
import SelectModeBar from './select-mode-bar'
import type { MonitoringOverviewBulkOperation} from '_/model/scheduling/monitoring-overview/monitoring-overview-bulk-operation'
import { MARK_AS_NOT_IN_USE } from '_/model/scheduling/monitoring-overview/monitoring-overview-bulk-operation'
import ExpectationAuditTrailModal from './expectation-audit-trail'
import AuditTrail from '../day-scheduler/audit-trail'

function MonitoringOverview() {
    const [loaded, predefinedLists] = usePredefinedLists()

    if (!loaded)
        return null

    return (
        <MonitoringOverviewInternal predefinedLists={predefinedLists} />
    )
}

interface Props {
    predefinedLists: { locations: ListExposureLocation[], sampleTypes: SampleType[], allSessions: SampleSession[], sessions: SampleSession[], monitoringGroups: MonitoringGroup[] }
}

function MonitoringOverviewInternal(props: Props) {
    const timeService = useTimeService()
        , currentDate = timeService.castUtcDayStartFromCtzDay(timeService.now())
        , defaultFilter = { date: currentDate, statuses: ALL_STATUSES }
        , customFields = hooks.useCustomFields()
        , grades = hooks.useGrades()
        , [, adHocGroups] = hooks.useMonitoringGroups()
        , { sampleTypes, locations, allSessions, sessions, monitoringGroups } = props.predefinedLists
        , [filter, setFilter] = useFilter(defaultFilter, props.predefinedLists)
        , [monitoringOverview, totalCount, dayScheduleId, reset, showSpinner] = useMonitoringOverview(filter)
        , handleChangeNotInUse = useChangeNotInUse(filter.date!, reset)
        , handleChangeMissed = useChangeMissed(filter.date!, reset)
        , items = h.getMonitoringOverview(monitoringOverview, locations, sessions, sampleTypes)
        , showingSamplesCount = h.calculateSamplesCount(monitoringOverview)
        , [showFilter, setShowFilter] = useState(totalCount !== showingSamplesCount)
        , [selectMode, setSelectMode] = useState(false)
        , [
            selectedItems,
            handleSelectSample,
            handleSelectSamples,
            handleApplyBulkOperation,
            handleClearSelection,
            isSelected,
            bulkOperationInProgress,
        ] = useBulkOperations(items, filter.date!, reset)
        , [showSampleTrailModal, closeModal, auditTrailSampleId, auditTrailSessionId] = useModal(r.SCHEDULING_MONITORING_OVERVIEW_SAMPLE_TRAIL)
        , [showTrailModal, closeTrailModal] = useModal(r.SCHEDULING_MONITORING_OVERVIEW_TRAIL)

    function handleChangeDate(date: DateTime) {
        setFilter({...filter, date})
        handleClearSelection()
    }

    function handleChangeSelectMode() {
        setSelectMode(!selectMode)
        setShowFilter(false)
    }

    function handleContextSwitch() {
        setShowFilter(false)
        setFilter(defaultFilter)

        setSelectMode(false)
        handleClearSelection()
    }

    hooks.useResetOnContextSwitch(handleContextSwitch)

    return (
        <div className='position-relative d-flex flex-fill h-100'>
            <Filter
                showFilter={showFilter}
                onClose={() => setShowFilter(false)}
                onChange={setFilter}
                onClearFilter={() => setFilter({ date: filter.date, statuses: ALL_STATUSES })}
                filterState={filter}
                sessions={sessions}
                locations={locations}
                sampleTypes={sampleTypes}
                grades={grades}
                monitoringGroups={monitoringGroups}
            />

            {showSampleTrailModal &&
                <ExpectationAuditTrailModal
                    id={auditTrailSampleId}
                    date={filter.date!}
                    sessionId={auditTrailSessionId}
                    onClose={closeModal}
                />
            }

            {showTrailModal &&
                <AuditTrail
                    onClose={closeTrailModal}
                    dayScheduleId={dayScheduleId}
                    date={filter.date!}
                />
            }
            <div className='container-fluid main-block'>
                <div className='row justify-content-center h-100'>
                    <div className='col-9 h-100 d-flex flex-column'>
                        <PageHeader
                            date={filter.date!}
                            text={showingSamplesCount !== totalCount ? `Showing ${showingSamplesCount} of ${totalCount} samples` : ''}
                            selectMode={selectMode}
                            onChangeDate={handleChangeDate}
                            onChangeShowFilter={() => setShowFilter(!showFilter)}
                            onChangeSelectMode={handleChangeSelectMode}
                        />
                        {selectMode &&
                            <SelectModeBar
                                selectedSamples={h.findSamples(items, selectedItems)}
                                onApplyBulkOperation={handleApplyBulkOperation}
                                onClearSelection={handleClearSelection}
                                disableBulkOperation={bulkOperationInProgress}
                            />
                        }
                        <div className='overflow-auto'>
                            <Table>
                                <thead className='thead table-header--sticky'>
                                    <tr className='table-header-thin'>
                                        <th>Location</th>
                                        {sessions.map((_, index) =>
                                            <th key={_.id}>
                                                <div className='d-flex'>
                                                    {selectMode &&
                                                        <Checkbox
                                                            name={`column-${index}`}
                                                            id={`column-${index}`}
                                                            checked={isSelected(h.getCellSamples({column: index}, items))}
                                                            onChange={() => handleSelectSamples({column: index})}
                                                            disabled={h.getCellSamples({column: index}, items).length === 0}
                                                        />
                                                    }
                                                    <FormattedText
                                                        modifier='table-header'
                                                        text={_.id === VOID_ID
                                                            ? [t.systemTextNode(_.name)]
                                                            : [t.defaultTextNode(`Session ${ _.name}`)]
                                                        }
                                                    />
                                                </div>
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {showSpinner
                                        ? <tr>
                                            <td colSpan={sessions.length + 2}>
                                                <div className='position-relative py-3'>
                                                    <i className='preview-spinner material-icons md-48'>sync</i>
                                                </div>
                                            </td>
                                        </tr>
                                        : items.length === 0
                                            ? <EmptyTableMessage
                                                colSpan={sessions.length + 2}
                                                message='No data available'
                                                className='text-muted text-uppercase'
                                            />
                                            : items.map((item, rowIndex) =>
                                                <tr key={rowIndex}>
                                                    <td>
                                                        <div className='d-flex'>
                                                            {selectMode &&
                                                                <Checkbox
                                                                    name={`row-${rowIndex}`}
                                                                    id={`row-${rowIndex}`}
                                                                    checked={isSelected(h.getCellSamples({row: rowIndex}, items))}
                                                                    onChange={() => handleSelectSamples({row: rowIndex})}
                                                                    disabled={h.getCellSamples({row: rowIndex}, items).length === 0}
                                                                />
                                                            }
                                                            <FormattedText text={item.locationName}/>
                                                        </div>
                                                    </td>

                                                    {sessions.map((_, index) =>
                                                        <SampleCellData
                                                            key={index}
                                                            bookedSamples={item.bookedSamples[index]}
                                                            expectedSamples={item.expectedSamples[index]}
                                                            sampleTypesIncludingDeleted={sampleTypes}
                                                            date={filter.date!}
                                                            index={index}
                                                            rowIndex={rowIndex}
                                                            customFields={customFields}
                                                            locations={locations}
                                                            sessions={allSessions}
                                                            adHocGroups={adHocGroups}
                                                            monitoringGroups={monitoringGroups}
                                                            onChangeNotInUse={handleChangeNotInUse}
                                                            onChangeMissed={handleChangeMissed}
                                                            onSelectSample={handleSelectSample}
                                                            isSelected={isSelected}
                                                            selectMode={selectMode}
                                                            selected={isSelected(h.getCellSamples({row: rowIndex, column: index}, items))}
                                                            onSelectCell={() => handleSelectSamples({row: rowIndex, column: index})}
                                                        />
                                                    )}
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonitoringOverview

function usePredefinedLists() {
    const loadSampleTypes = useAction(sampleTypesActions.loadSampleTypeList)
        , loadSessions = useAction(sessionActions.loadSampleSessionList)
        , loadLocations = useAction(exposureLocationActions.loadExposureLocationList)
        , loadMonitoringGroups = useAction(groupAction.loadMonitoringGroupList)
        , contextSwitch = useContextSwitchObserver()
        , sampleTypes = useSelector(_ => _.predefinedLists.sampleTypes)
        , locations = useSelector(_ => _.predefinedLists.exposureLocations)
        , sessions = useSelector(_ => _.predefinedLists.sampleSessions)
        , [monitoringGroups, setMonitoringGroups] = useState<MonitoringGroup[]>([])
        , [loaded, setLoaded] = useState(false)
        , predefinedLists = useMemo(
            () => ({
                monitoringGroups,
                sampleTypes,
                locations: fullNameLocationList(locations),
                allSessions: sessions,
                sessions: sessions
                            .filter(_ => _.isActive)
                            .concat({ name: 'Other viable samples', id: VOID_ID, isActive: true } as SampleSession)
            }),
            [monitoringGroups, locations, sessions, sampleTypes]
        )

    useEffect(
        () => {
            Promise.all([loadMonitoringGroups(), loadSampleTypes(), loadLocations(), loadSessions()])
                .then(([groups]) => setMonitoringGroups(groups))
                .then(() => setLoaded(true))

            return () => setLoaded(false)
        },
        [contextSwitch, loadSessions, loadSampleTypes, loadLocations, loadMonitoringGroups]
    )
    return [loaded, predefinedLists] as const
}

function useFilter(
    defaultFilter: MonitoringOverviewFilter,
    predefinedLists: { locations: ListExposureLocation[], sampleTypes: SampleType[], sessions: SampleSession[], monitoringGroups: MonitoringGroup[]}
) {
    const routeParams = useSelector(_ => _.router.route?.params)
        , initialFilter = useMemo(
            () => ({
                date: routeParams?.date ?? defaultFilter.date,
                statuses: routeParams?.statuses ? [parseInt(routeParams.statuses, 10)] : defaultFilter.statuses
            }), // set initial filter from route params when navigate from email notification
            [routeParams, defaultFilter.date, defaultFilter.statuses]
        )
        , [filter, setFilter] = hooks.useFilter('monitoring-overview', (params = {}) => ({...initialFilter, ...params}))
        , normalizedFilter = useMemo(
            () =>  h.normalizeFilter(filter, predefinedLists),
            [filter, predefinedLists]
        )

    useEffect(
        () => {
            if (routeParams?.filter) {
                setFilter({...initialFilter, ...routeParams.filter})
            }
        },
        [routeParams, initialFilter, setFilter]
    )

    return [normalizedFilter, setFilter] as const
}

function useMonitoringOverview(filter: MonitoringOverviewFilter) {
    const loadMonitoringOverview = useAction(actions.loadMonitoringOverview)
        , [monitoringOverview, setMonitoringOverview] = useState<MonitoringOverviewPage>({items: [], totalCount: 0, dayScheduleId: VOID_ID})
        , [showSpinner, setShowSpinner] = useState(false)
        , reset = useCallback(
            () => {
                setShowSpinner(true)
                loadMonitoringOverview(filter)
                    .then(setMonitoringOverview)
                    .finally(() => setShowSpinner(false))
            },
            [filter, loadMonitoringOverview]
        )

    useEffect(reset, [reset])

    return [monitoringOverview.items, monitoringOverview.totalCount, monitoringOverview.dayScheduleId, reset, showSpinner] as const
}

function useChangeNotInUse(date: DateTime, reset: () => void) {
    const changeNotInUse = useAction(dayPlanActions.changeNotInUse)
        , [inProgress, setInProgress] = useState(false)

    function handleChangeNotInUse(id: Guid, sessionId: Guid, notInUse: boolean) {
        if (inProgress)
            return

        setInProgress(true)
        changeNotInUse({id, notInUse, sessionId, date})
            .then(reset)
            .finally(() => setInProgress(false))
    }

    return handleChangeNotInUse
}

function useChangeMissed(date: DateTime, reset: () => void) {
    const changeNotInUse = useAction(dayPlanActions.changeMissed)
        , [inProgress, setInProgress] = useState(false)

    function handleChangeMissed(id: Guid, sessionId: Guid, missed: boolean) {
        if (inProgress)
            return

        setInProgress(true)
        changeNotInUse({ id, missed, date, sessionId })
            .then(reset)
            .finally(() => setInProgress(false))
    }

    return handleChangeMissed
}

function useBulkOperations(items: MonitoringOverviewItems[], date: DateTime, reset: () => void ) {
    const [selectedSamples, setSelectedSamples] = useState<SelectedSample[]>([])
        , [inProgress, setInProgress] = useState(false)
        , bulkChangeNotInUse = useAction(dayPlanActions.bulkChangeNotInUse)

    function handleSampleSelect(sample: SelectedSample) {
        const updatedSamples = isSelected([sample])
            ? selectedSamples.filter(_ => !h.areSelectedSamplesEqual(_, sample))
            : [...selectedSamples, sample]
        setSelectedSamples(updatedSamples)
    }

    function isSelected(samples: SelectedSample[]) {
        return samples.length > 0
            && samples.every(_ => h.findSelectedSample(selectedSamples, _))
    }

    function handleSamplesSelect(cell: {row?: number, column?: number}) {
        const samples = h.getCellSamples(cell, items)
            , filteredOutAlreadySelectedSamples = selectedSamples.filter(_ => !h.findSelectedSample(samples, _))

        if (isSelected(samples))
            setSelectedSamples(filteredOutAlreadySelectedSamples)
        else
            setSelectedSamples([...filteredOutAlreadySelectedSamples, ...samples])

    }

    function handleClearSelection() {
        setSelectedSamples([])
    }

    function handleApplyBulkOperation(bulkOperation: MonitoringOverviewBulkOperation) {
        if (inProgress)
            return

        setInProgress(true)
        bulkChangeNotInUse({
            date,
            notInUse: bulkOperation === MARK_AS_NOT_IN_USE,
            expectedSamples: selectedSamples
        })
            .then(reset)
            .then(handleClearSelection)
            .finally(() => setInProgress(false))
    }

    return [
        selectedSamples,
        handleSampleSelect,
        handleSamplesSelect,
        handleApplyBulkOperation,
        handleClearSelection,
        isSelected,
        inProgress,
    ] as const
}

function useModal(routeName: r.RouteName) {
    const route = useSelector(_ => _.router.route)
        , showModal = route?.name === routeName
        , { id, date, sessionId } = route?.params ?? {}
        , navigate = useAction(navigateTo)
        , handleClose = useCallback(
            () => navigate(r.SCHEDULING_MONITORING_OVERVIEW, { date }),
            [navigate, date],
        )

    return [showModal, handleClose, id, sessionId] as const
}
