import type { FormRenderProps} from 'react-final-form'
import { Form, useField } from 'react-final-form'
import { classnames, useState } from '_/facade/react'

import { submitDisabled, Submit } from '_/components/form'
import Button, { Close } from '_/components/button'
import FormChangesObserver from '_/components/form/form-changes-observer'

import { isEmpty, maxLength, minLength } from '_/utils/form/validate'

import type { Trend, TrendEdit } from '_/model/trends/types'
import { areArraysEqual } from '_/utils/array'

interface Props {
    trend: Trend
    onSubmit: (_: TrendEdit) => Promise<void>
    onCancel: () => void
}

function TrendInvestigationRefsForm(props: Props) {
    return (
        <Form
            initialValues={{trendInvestigationReferences: props.trend.trendInvestigationReferences ?? []}}
            onSubmit={props.onSubmit}
            render={form =>
                <FormInternal
                    form={form}
                    trend={props.trend}
                    onSubmit={props.onSubmit}
                    onCancel={props.onCancel}
                />
            }
        />
    )
}

export default TrendInvestigationRefsForm

function FormInternal(props: Props & {form: FormRenderProps<TrendEdit>}) {
    const field = useField<string[]>('trendInvestigationReferences', { isEqual: areArraysEqual })
        , [investigationRefs, setInvestigationRefs] = useState(props.trend.trendInvestigationReferences ?? [])
        , [value, setValue] = useState('')
        , [validationResult, setValidationResult] = useState<string | undefined>(undefined)
        , [input, setInput] = useState<HTMLInputElement | null>(null)

    function validate(value: string, investigationRefs: string[]) {
        const fieldName = 'Trend investigation ref'
            , maxRefLength = maxLength(fieldName, 200)(value) || undefined
            , minRefLength = minLength(fieldName, 3)(value) || undefined
            , isEmptyRef = value.length > 0 && isEmpty(fieldName)(value) ? `${fieldName} should not be empty` : undefined
            , duplicate = investigationRefs.some(_ => _.trim() === value.trim())
                ? `${fieldName}' ${value} already exists`
                : undefined

        return isEmptyRef || maxRefLength || minRefLength || duplicate
    }

    function handleRemove(investigationRef: string) {
        const newValues = investigationRefs.filter(_ => _ !== investigationRef)
        setInvestigationRefs(newValues)
        setValidationResult(validate(value, newValues))
        field.input.onChange(newValues)
    }

    function handleAddRef() {
        if (validationResult)
            return

        const newValues = investigationRefs.concat(value)
        setInvestigationRefs(newValues)
        setValue('')
        field.input.onChange(newValues)
        input?.focus()
    }

    function handleInputChange(value: string) {
        setValue(value)
        setValidationResult(validate(value, investigationRefs))
    }

    return (
        <form onSubmit={props.form.handleSubmit}>
            <FormChangesObserver target={props.trend.id} form={props.form} />
            <div className='d-flex align-items-start'>
                <div className='flex-fill single-line-text'>
                    <input
                        ref={setInput}
                        id='investigationRef'
                        type='text'
                        className={classnames('form-control me-1', { 'is-invalid': validationResult })}
                        autoComplete='off'
                        autoFocus
                        value={value}
                        onChange={_ => handleInputChange(_.target.value)}
                    />
                    {validationResult && <span className='invalid-feedback user-formatted-text'>{validationResult}</span>}
                    {investigationRefs.map((entity, key) =>
                        <span key={key}>
                            {key > 0 && <br/>}
                            <Close
                                className='align-text-bottom text-danger'
                                aria-label='Close'
                                onClick={() => handleRemove(entity)}
                            />
                            <span title={entity}> {entity}</span>
                        </span>
                    )}
                </div>
                {value !== ''
                    ? <Submit disabled={!!validationResult} onClick={handleAddRef} className='ms-1 btn-primary'>Add</Submit>
                    : <Button disabled={submitDisabled(props.form)} className='ms-1 btn-primary' onClick={props.form.handleSubmit}>Save</Button>
                }
                <Button className='btn-secondary ms-1' onClick={props.onCancel}>
                    Cancel
                </Button>
            </div>
        </form>
    )
}
