import type { FloorPlanLocation } from '_/model/floor-plan/floor-plan'
import type LimitBreachFloorPlanSeries from '_/model/floor-plan/limit-breach-floor-plan-series'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import { Table } from '_/components/table'
import { formatActiveState } from '_/utils/format/common'
import { filteredLocationsExistsOnFloorPlan } from '../helpers'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    floorPlanLocations: FloorPlanLocation[]
    series: LimitBreachFloorPlanSeries[]
    locations: ListExposureLocation[]
    selectedLocations: string[]
}

function LimitBreachFloorPlanTabularView(props: Props) {
    const renderedLocation = props.locations.filter(_ => props.floorPlanLocations.some(fl => fl.locationId === _.id))

    return (
        <Table>
            <thead className='text-center'>
                <tr>
                    <th>Location</th>
                    <th>Action limit breach</th>
                    <th>Action limit breach with ref #</th>
                    <th>Alert limit breach</th>
                    <th>Alert limit breach with ref #</th>
                    <th>Compromised</th>
                    <th>Compromised with ref #</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {renderedLocation
                    .filter(_ => props.selectedLocations.length === 0 ? true : filteredLocationsExistsOnFloorPlan(props.selectedLocations, [{locationId: _.id} as FloorPlanLocation], props.locations))
                    .map((l, i) => {
                        const series = props.series.find(_ => _.locationId === l.id)

                        return <tr key={i}>
                            <td><FormattedText text={formatActiveState(l.pathName, l.isActive)} /></td>
                            <td>{series?.actionBreachCount || ''}</td>
                            <td>{series?.actionBreachWithRefCount || ''}</td>
                            <td>{series?.alertBreachCount || ''}</td>
                            <td>{series?.alertBreachWithRefCount || ''}</td>
                            <td>{series?.compromisedCount || ''}</td>
                            <td>{series?.compromisedWithRefCount || ''}</td>
                        </tr>
                    })
                }
            </tbody>
        </Table>
    )
}

export default LimitBreachFloorPlanTabularView
