import { useSelector, useAction, useCallback } from '_/facade/react'

import ExportModal from '_/features/export/export-modal'
import type { Filter } from '_/model/plate/plate-filter'

import * as routes from '_/constants/routes'
import * as routerActions from '_/features/routing/actions'

interface Props {
    totalCount: number
    query: Filter
}

function PlateExportModal(props: Props) {
    const navigateToList = useNavigateToList()
        , contextDescription = useSelector(_ => _.auth.user?.membership.description)

    return (
        <ExportModal
            totalCount={props.totalCount}
            query={props.query}
            entityType='plate'
            navigateToList={navigateToList}
            modalText={`You're about to export data for ${props.totalCount} plates from ${contextDescription}.`}
        />
    )
}

export default PlateExportModal

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.PLATES), [navigateTo])

    return navigateToList
}
