import { classnames } from '_/facade/react'
import type { FormRenderProps} from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'

import { useDebounce, useSyncRef } from '_/hooks/shared-hooks'

import { FilterMultiSelectField as MultiSelectField } from '_/components/form'
import Button, { Close } from '_/components/button'
import FilterActions from '_/components/filter-actions'
import CheckboxGroupField from '_/components/form/checkbox-group-field'

import { VOID_ID } from '_/utils/tree'
import { diffObject } from '_/utils/object'
import { formatActiveState } from '_/utils/format/common'

import type Grade from '_/model/predefined-lists/grade/grade'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type { MonitoringOverviewFilter } from '_/model/scheduling/monitoring-overview/types'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'

import { UNKNOWN_LOCATION } from '_/constants/system-words'
import { MONITORING_OVERVIEW_SAMPLE_STATUSES } from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'
import SampleIcon from '_/features/scheduling/monitoring-overview/sample-icon'
import * as t from '_/model/text/text'

interface Props {
    showFilter: boolean
    filterState: MonitoringOverviewFilter
    locations: ListExposureLocation[]
    grades: Grade[]
    monitoringGroups: MonitoringGroup[]
    sessions: Pick<SampleSession, 'id' | 'name' | 'isActive'>[]
    sampleTypes: SampleType[]
    onChange: (template: MonitoringOverviewFilter) => void
    onClose: () => void
    onClearFilter: () => void
}

function Filter(props: Props) {
    const previousFilter = useSyncRef(props.filterState)
        , submitDebounce = useDebounce()

    function handleChangeFormValues(values: MonitoringOverviewFilter, form: FormRenderProps<any>) {
        const prevValues = previousFilter.current
        previousFilter.current = values

        if (diffObject(prevValues, values)) {
            submitDebounce(form.form.submit)
        }
    }

    return (
        <Form<MonitoringOverviewFilter>
            onSubmit={props.onChange}
            initialValues={props.filterState}
            render={form =>
                <div className={classnames('position-absolute filters-popup d-flex flex-column side-filters', { 'filters-popup-hide': !props.showFilter })}>
                    <nav className='navbar mx-0'>
                        <span className='navbar-brand me-auto filters-header-text'>Filters</span>
                        <Close onClick={props.onClose} />
                    </nav>
                    <form className='mb-2 overflow-y-auto flex-fill' onSubmit={form.handleSubmit}>
                        <FormSpy
                            onChange={_ => handleChangeFormValues(_.values, form)}
                            subscription={{ values: true }}
                        />
                        <div className='px-3'>
                            <CheckboxGroupField
                                entities={MONITORING_OVERVIEW_SAMPLE_STATUSES}
                                calcLabel={_ => <span><SampleIcon sampleStatus={_.id} ></SampleIcon> {_.name}</span>}
                                name='statuses'
                            >
                                Status
                            </CheckboxGroupField>
                            <MultiSelectField
                                name='exposureLocationIds'
                                id='exposureLocationIds'
                                entities={props.locations.concat({ pathName: UNKNOWN_LOCATION, id: VOID_ID } as ListExposureLocation)}
                                calcId={_ => _.id}
                                calcName={_ => _.pathName === UNKNOWN_LOCATION ? [t.systemTextNode(_.pathName)] : formatActiveState(_.pathName, _.isActive)}
                                autocomplete
                            >
                                Exposure location
                            </MultiSelectField>
                            <MultiSelectField
                                name='sessionIds'
                                id='sessionIds'
                                entities={props.sessions}
                                calcId={_ => _.id}
                                calcName={_ => _.id === VOID_ID ? [t.systemTextNode(_.name)] : [t.defaultTextNode(_.name)]}
                                autocomplete
                            >
                                Session
                            </MultiSelectField>
                            <MultiSelectField
                                name='sampleTypeIds'
                                id='sampleTypeIds'
                                entities={props.sampleTypes}
                                calcId={_ => _.id}
                                calcName={_ => formatActiveState(_.name, _.isActive)}
                                autocomplete
                            >
                                Sample name
                            </MultiSelectField>
                            <MultiSelectField
                                name='gradeIds'
                                id='gradeIds'
                                entities={props.grades}
                                calcId={_ => _.id}
                                calcName={_ => _.name}
                                autocomplete
                            >
                                Grade
                            </MultiSelectField>
                            <MultiSelectField
                                name='monitoringGroupIds'
                                id='monitoringGroupIds'
                                entities={props.monitoringGroups}
                                calcId={_ => _.id}
                                calcName={_ => formatActiveState(_.name, _.isActive)}
                                autocomplete
                            >
                                Monitoring group
                            </MultiSelectField>
                        </div>

                        <div className='side-filters__spacer' />

                        <FilterActions className='d-flex side-filters__actions'>
                            <Button className='btn-link ms-auto' onClick={props.onClearFilter}>Clear</Button>
                        </FilterActions>
                    </form>
                </div>
            }
        />
    )
}

export default Filter
