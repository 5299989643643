import { actionFactory } from '_/utils/redux'
import type { CustomReportCreate, CustomReportEdit, CustomReportGraph } from '_/model/analysis/custom-report/custom-report'
import type CustomReport from '_/model/analysis/custom-report/custom-report'
import type { ImageExportInfo } from '_/model/sample/image/image'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.custom-reports')

export const loadCustomReports = action<void, Promise<CustomReport[]>>('loadCustomReports')
           , customReportsLoaded = action<CustomReport[]>('customReportsLoaded')
           , loadCustomReport = action<string, Promise<CustomReport>>('loadCustomReport')
           , createCustomReport = action<CustomReportCreate, Promise<string>>('createCustomReport')
           , saveCustomReport = action<{ id: string, oldCustomReport: CustomReportEdit, newCustomReport: CustomReportEdit }, Promise<void>>('saveCustomReport')
           , removeCustomReport = action<string, Promise<void>>('removeCustomReport')
           , loadCustomReportTrail = action<string, Promise<AuditTrail>>('loadCustomReportTrail')
           , addCustomReportGraphs = action<{ id: string, graphs: CustomReportGraph[] }, Promise<void>>('addCustomReportGraphs')
           , changeCustomReportGraphs = action<{ id: string, graphs: CustomReportGraph[] }, Promise<void>>('changeCustomReportGraphs')
           , removeCustomReportGraph = action<{ id: string, graphId: string }, Promise<void>>('removeCustomReportGraphs')
           , changeGraphsPosition = action<{ id: string, graphs: CustomReportGraph[] }, Promise<void>>('changeGraphsPosition')
           , exportCustomReportGraphs = action<ImageExportInfo[], Promise<void>>('exportCustomReportGraphs')
