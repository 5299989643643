import type { Store } from 'redux'
import type AppState from '_/model/app-state'
import type ReasonService from '_/model/reason/reason-service'
import * as actions from '_/features/critical-change-reason/actions'
import {} from '_/utils/redux/connect'
import type ReduxService from './redux-service'
import type { Signature } from '_/model/critical-change-reason/types'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import type * as s from '_/model/context/electronic-signature-settings'

function factory(): ReduxService & ReasonService {
    let store: Store<AppState>

    return {
        setStore,
        getStore,
        getReason,
        getSignature,
        electronicSignatureSettingsEnabled,
    }

    function setStore(newStore: Store<AppState>): void {
        store = newStore
    }

    function getStore() {
        return store
    }

    function getReason(signatureRequired: boolean, reason?: string, error?: string): Promise<ApprovalInfo> {
        const action = actions.getReason({ signatureRequired, reason, error })

        return store.dispatch(action) as any
    }

    function getSignature(error = ''): Promise<Signature> {
        const action = actions.getElectronicSignature(error)

        return store.dispatch(action) as any
    }

    function electronicSignatureSettingsEnabled(setting?: s.ElectronicSignatureSettings, electronicSignatureSettings?: s.ElectronicSignatureSettings[]) {
        const settings = electronicSignatureSettings || store.getState().auth.electronicSignatureSettings
        return settings.find(_ => _ === setting) !== undefined
    }
}

export default factory
