import type { NewGroup, CopyGroupsRequest, DaySchedulesRequest, DayScheduleStatistic, RemoveSeriesRequest, ChangeNotInUseRequest, Recurrence, Series, Schedule, AddAdHocGroupRequest, BulkChangeNotInUseRequest, ChangeMissedRequest } from '_/model/scheduling/day-scheduler/types'
import type DaySchedule from '_/model/scheduling/day-scheduler/types'
import { EDITING_SCHEDULING_GROUPS } from '_/model/context/electronic-signature-settings'

import * as ft from '_/model/scheduling/day-scheduler/frequency-type'

import type ApiService from '../api-service'
import type DayScheduleService from '../day-schedule-service'
import type { DateTime } from '_/model/date-time'
import type { Guid } from '_/model/guid'
import type { AuditTrail } from '_/model/audit-trail/types'

function factory(api: ApiService): DayScheduleService {
    return {
        ...api.resource(['day-schedules']),
        get,
        addGroup,
        deleteGroup,
        copyGroups,
        getDaySchedules,
        changeNotInUse,
        bulkChangeNotInUse,
        createSeries,
        getSeries,
        saveSeries,
        deleteSeries,
        addAdHocGroup,
        getTrail,
        getExpectationTrail,
        changeMissed,
    }

    function get(date: DateTime): Promise<DaySchedule> {
        return api.get(['day-schedules', date])
    }

    function addGroup(date: DateTime, group: NewGroup): Promise<void> {
        return api.post(['day-schedules', date, 'add-group'], group)
    }

    function deleteGroup(date: DateTime, monitoringGroupId: string, sessionId: string): Promise<void> {
        return api.postWithReason(['day-schedules', date, 'delete-group'], EDITING_SCHEDULING_GROUPS, { monitoringGroupId, sessionId })
    }

    function copyGroups(date: DateTime, request: CopyGroupsRequest): Promise<void> {
        return api.post(['day-schedules', date, 'copy-group'], request)
    }

    function getDaySchedules(request: DaySchedulesRequest): Promise<DayScheduleStatistic[]> {
        return api.get(['day-schedules'], request)
    }

    function changeNotInUse(request: ChangeNotInUseRequest): Promise<void> {
        return api.post(['day-schedules', request.date, 'change-not-in-use'], request)
    }

    function bulkChangeNotInUse(request: BulkChangeNotInUseRequest): Promise<void> {
        return api.post(['bulk-operations', request.date, 'change-not-in-use'], request)
    }

    function createSeries(recurrence: Recurrence): Promise<void> {
        switch (recurrence.type) {
            case ft.DAY: return api.post(['day-schedules', recurrence.startDate, 'create-daily-series'], recurrence)
            case ft.WEEK: return api.post(['day-schedules', recurrence.startDate, 'create-weekly-series'], recurrence)
            case ft.MONTH: return api.post(['day-schedules', recurrence.startDate, 'create-monthly-series'], recurrence)
        }
    }

    function getSeries(id: string): Promise<Series> {
        return api.get(['day-schedules', 'series', id])
    }

    function saveSeries(id: string, request: Schedule): Promise<void> {
        return api.patchWithReason(['day-schedules', 'series', id], EDITING_SCHEDULING_GROUPS, request)
    }

    function deleteSeries(id: string, request: RemoveSeriesRequest): Promise<void> {
        return api.removeWithReason(['day-schedules', 'series', id], EDITING_SCHEDULING_GROUPS, undefined, request)
    }

    function addAdHocGroup(date: DateTime, request: AddAdHocGroupRequest): Promise<void> {
        return api.post(['day-schedules', date, 'add-ad-hoc-group'], request)
    }

    function getTrail(dayScheduleId: string, date: DateTime): Promise<AuditTrail> {
        return api.get(['day-schedules', 'trail'], { dayScheduleId, date })
    }

    function getExpectationTrail(id: Guid, date: DateTime, sessionId: Guid): Promise<AuditTrail> {
        return api.get(['day-schedules', 'expectation', id, 'trail'], { sessionId, date })
    }

    function changeMissed(request: ChangeMissedRequest): Promise<void> {
        return api.postWithReason(['day-schedules', request.date, 'change-missed'], EDITING_SCHEDULING_GROUPS, request)
    }
}

export default factory
