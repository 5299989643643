import type Sample from '_/model/sample/sample-details'
import * as breachTypes from '_/constants/sample-breach-type'

interface SampleLabel {
    text: string
    className: string
}

function SampleLabel(props: SampleLabel) {
    return (
        <span className={'p-1 ms-2 rounded text-uppercase small fw-bold text-white ' + props.className} data-testid='sample-chip-label'>
            {props.text}
        </span>
    )
}

function SampleLabels(props: { sample: Sample }) {
    const sample = props.sample
        , showBreachLabel = !sample.compromised && !sample.nullified

    return (
        <div className='me-auto mb-1'>
            {sample.nullified && !sample.compromised && <SampleLabel text='Nullified' className='label-nullified--color'/>}
            {showBreachLabel && sample.breach === breachTypes.ACTION_LIMIT && <SampleLabel text={`Action${sample.manuallyActionLimitBreached ? ' (manual)' : ''}`} className='label-action--color' />}
            {showBreachLabel && sample.breach === breachTypes.ALERT_LIMIT && <SampleLabel text='Alert' className='label-alert--color' />}
            {sample.compromised && <SampleLabel text='Compromised' className='label-compromised--color'/>}
            {sample.isForReview && <SampleLabel text='For review' className='label-compromised--color'/>}
        </div>
    )
}

export default SampleLabels

export {
    SampleLabel,
}
