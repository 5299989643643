const EXPOSURE_DATE = 0
    , EXPOSURE_LOCATION_ID = 1
    , SESSION_ID = 2
    , BARCODE = 3
    , SAMPLE_TYPE_ID = 4
    , MONITORING_POSITION = 5
    , EXPOSURE_START_TIME = 6
    , EXPOSURE_END_TIME = 8
    , OPERATORS_IDS = 9
    , BATCH_NUMBER = 10

    , SESSION_NAME = 1000
    , EXPOSURE_LOCATION_ACTION_LIMIT = 1001
    , EXPOSURE_LOCATION_ALERT_LIMIT = 1002
    , EXPOSURE_LOCATION_NAME = 1003
    , EXPOSURE_LOCATION_GRADE_ID = 1004
    , EXPOSURE_LOCATION_GRADE_NAME = 1005
    , SAMPLE_TYPE_MANUFACTURER = 1006
    , SAMPLE_TYPE_NAME = 1007
    , PLATE_TYPE = 1008
    , MEDIUM_TYPE = 1009
    , OPERATORS_NAMES = 1010
    , ID = 1011
    , CFU = 1012
    , REFERENCE_NUMBERS = 1013
    , IDENTIFICATION = 1014
    , IDENTIFICATION_COMPLETE = 1015
    , OPTIONAL_IDENTIFICATION_COMPLETE = 1016
    , GROWTHS = 1017
    , OPTIONAL_GROWTHS = 1018
    , MONITORING_STATE = 1019

    // Used on backend
    // , STATUS = 1020
    // , PHOTOS = 1021

    , MONITORING_GROUP_ID = 1022
    , MONITORING_GROUP_NAME = 1023
    , MONITORING_LOCATION_ID = 1024
    , MONITORING_LOCATION_NAME = 1025
    , MONITORING_SESSION_ID = 1035
    , MONITORING_SESSION_NAME = 1036

    , LOWER_PARTICLE_COUNT = 1026
    , LOWER_PARTICLE_VOLUME = 1027
    , HIGHER_PARTICLE_COUNT = 1028
    , HIGHER_PARTICLE_VOLUME = 1029

    , LOWER_PARTICLE_ACTION_LIMIT = 1030
    , LOWER_PARTICLE_ALERT_LIMIT = 1031
    , HIGHER_PARTICLE_ACTION_LIMIT = 1032
    , HIGHER_PARTICLE_ALERT_LIMIT = 1033
    , PARTICLES_STATE = 1034

    , EXPOSURE_START_DATE = -6
    , EXPOSURE_END_DATE = 1037

type FieldIndex = never
    | typeof EXPOSURE_DATE
    | typeof EXPOSURE_LOCATION_ID
    | typeof SESSION_ID
    | typeof BARCODE
    | typeof SAMPLE_TYPE_ID
    | typeof MONITORING_POSITION
    | typeof EXPOSURE_START_TIME
    | typeof EXPOSURE_END_TIME
    | typeof OPERATORS_IDS
    | typeof BATCH_NUMBER

    | typeof SESSION_NAME
    | typeof EXPOSURE_LOCATION_ACTION_LIMIT
    | typeof EXPOSURE_LOCATION_ALERT_LIMIT
    | typeof EXPOSURE_LOCATION_NAME
    | typeof EXPOSURE_LOCATION_GRADE_ID
    | typeof EXPOSURE_LOCATION_GRADE_NAME
    | typeof SAMPLE_TYPE_MANUFACTURER
    | typeof SAMPLE_TYPE_NAME
    | typeof PLATE_TYPE
    | typeof MEDIUM_TYPE
    | typeof OPERATORS_NAMES
    | typeof ID
    | typeof CFU
    | typeof REFERENCE_NUMBERS
    | typeof IDENTIFICATION
    | typeof IDENTIFICATION_COMPLETE
    | typeof OPTIONAL_IDENTIFICATION_COMPLETE
    | typeof MONITORING_STATE
    | typeof GROWTHS
    | typeof OPTIONAL_GROWTHS

    | typeof MONITORING_GROUP_ID
    | typeof MONITORING_GROUP_NAME
    | typeof MONITORING_LOCATION_ID
    | typeof MONITORING_LOCATION_NAME
    | typeof MONITORING_SESSION_ID
    | typeof MONITORING_SESSION_NAME

    | typeof LOWER_PARTICLE_COUNT
    | typeof LOWER_PARTICLE_VOLUME
    | typeof HIGHER_PARTICLE_COUNT
    | typeof HIGHER_PARTICLE_VOLUME
    | typeof LOWER_PARTICLE_ACTION_LIMIT
    | typeof LOWER_PARTICLE_ALERT_LIMIT
    | typeof HIGHER_PARTICLE_ACTION_LIMIT
    | typeof HIGHER_PARTICLE_ALERT_LIMIT
    | typeof PARTICLES_STATE

    | typeof EXPOSURE_START_DATE
    | typeof EXPOSURE_END_DATE

const DEFAULT_FIELD_INDEX: FieldIndex[] = [
    EXPOSURE_DATE,
    EXPOSURE_LOCATION_ID,
    SESSION_ID,
    BARCODE,
    SAMPLE_TYPE_ID,
    MONITORING_POSITION,
    EXPOSURE_START_TIME,
    EXPOSURE_END_TIME,
    OPERATORS_IDS,
    BATCH_NUMBER,
    SESSION_NAME,
    EXPOSURE_LOCATION_ACTION_LIMIT,
    EXPOSURE_LOCATION_ALERT_LIMIT,
    EXPOSURE_LOCATION_NAME,
    EXPOSURE_LOCATION_GRADE_ID,
    EXPOSURE_LOCATION_GRADE_NAME,
    SAMPLE_TYPE_MANUFACTURER,
    SAMPLE_TYPE_NAME,
    PLATE_TYPE,
    MEDIUM_TYPE,
    OPERATORS_NAMES,
    ID,
    CFU,
    REFERENCE_NUMBERS,
    IDENTIFICATION,
    EXPOSURE_START_DATE,
    EXPOSURE_END_DATE,
    IDENTIFICATION_COMPLETE,
    OPTIONAL_IDENTIFICATION_COMPLETE,
    GROWTHS,
    OPTIONAL_GROWTHS,
    MONITORING_GROUP_ID,
    MONITORING_GROUP_NAME,
    MONITORING_LOCATION_ID,
    MONITORING_LOCATION_NAME,
]

export {
    DEFAULT_FIELD_INDEX as default,
    FieldIndex,
    EXPOSURE_DATE,
    EXPOSURE_LOCATION_ID,
    SESSION_ID,
    BARCODE,
    SAMPLE_TYPE_ID,
    MONITORING_POSITION,
    EXPOSURE_START_TIME,
    EXPOSURE_END_TIME,
    OPERATORS_IDS,
    BATCH_NUMBER,

    SESSION_NAME,
    EXPOSURE_LOCATION_ACTION_LIMIT,
    EXPOSURE_LOCATION_ALERT_LIMIT,
    EXPOSURE_LOCATION_NAME,
    EXPOSURE_LOCATION_GRADE_ID,
    EXPOSURE_LOCATION_GRADE_NAME,
    SAMPLE_TYPE_MANUFACTURER,
    SAMPLE_TYPE_NAME,
    PLATE_TYPE,
    MEDIUM_TYPE,
    OPERATORS_NAMES,
    ID,
    CFU,
    REFERENCE_NUMBERS,
    IDENTIFICATION,
    IDENTIFICATION_COMPLETE,
    OPTIONAL_IDENTIFICATION_COMPLETE,
    MONITORING_STATE,
    GROWTHS,
    OPTIONAL_GROWTHS,
    MONITORING_GROUP_ID,
    MONITORING_GROUP_NAME,
    MONITORING_LOCATION_ID,
    MONITORING_LOCATION_NAME,
    MONITORING_SESSION_ID,
    MONITORING_SESSION_NAME,

    LOWER_PARTICLE_COUNT,
    LOWER_PARTICLE_VOLUME,
    HIGHER_PARTICLE_COUNT,
    HIGHER_PARTICLE_VOLUME,
    LOWER_PARTICLE_ACTION_LIMIT,
    LOWER_PARTICLE_ALERT_LIMIT,
    HIGHER_PARTICLE_ACTION_LIMIT,
    HIGHER_PARTICLE_ALERT_LIMIT,
    PARTICLES_STATE,

    EXPOSURE_START_DATE,
    EXPOSURE_END_DATE,
}
