export const ORGANISM_TYPE = 2
           , CATALASE = 5
           , OXIDASE = 6
           , OXIDATION_FERMENTATION = 7
           , COAGULASE = 8
           , ORGANISM_GENUS = 10
           , ORGANISM_SPECIES = 11

import type * as self from './ranked-identification-type'

type RankedIdentificationType = Extract<(typeof self)[keyof typeof self], number>

const RANKED_IDENTIFICATION_TYPE: {id: RankedIdentificationType, name: string}[] = [
    {
        id: ORGANISM_TYPE,
        name: 'Organism type',
    },
    {
        id: CATALASE,
        name: 'Catalase',
    },
    {
        id: OXIDASE,
        name: 'Oxidase',
    },
    {
        id: OXIDATION_FERMENTATION,
        name: 'Oxidation Fermentation',
    },
    {
        id: COAGULASE,
        name: 'Coagulase',
    },
    {
        id: ORGANISM_GENUS,
        name: 'Organism - Genus'
    },
    {
        id: ORGANISM_SPECIES,
        name: 'Organism - Species'
    },
]

export {
    RankedIdentificationType,
    RANKED_IDENTIFICATION_TYPE
}
