import { NOT_VALID_VALUE } from '_/components/form/number-text-input'
import { SYSTEM_WORDS, SYSTEM_SUFFIXES } from '_/constants/system-words'
import type { DateTime} from '_/model/date-time'
import { greaterThan } from '_/model/date-time'

export type ValidationResult<T> = {
    [K in keyof T]?:
        [T[K]] extends [(infer X)[] | undefined] ? ValidationResult<X>[]
        : T[K] extends object ? ValidationResult<T[K]>
        : string
}

export function required(name: string) {
    return (value: any) => value || value === 0
        ? false
        : `${name} is required`
}

export function requiredValues(name: string) {
    return (values: any[] | undefined) => values && values.length > 0
        ? false
        : `${name} is required`
}

export function equalLength(name: string, length: number) {
    return (value: string | undefined | null) => !value || value.length === length ? false : `${name} must contain ${length} characters`
}

export function maxLength(name: string, maxLength: number) {
    return (value: string | undefined | null) => !value || value.length <= maxLength ? false : `${name} can be up to ${maxLength} characters`
}

export function minLength(name: string, minLength: number) {
    return (value: string | undefined | null) => !value || value.trim().length >= minLength ? false : `${name} can not be less than ${minLength} characters`
}

export function isEmpty(name: string) {
    return (value: string | undefined | null) => value && value.trim().length > 0 ? false : `${name} is required`
}

function notAllowedName(value: string | undefined, name: string, additionalProhibitedNames?: string[]) {
    return SYSTEM_WORDS.concat(additionalProhibitedNames ?? []).some(_ => _.toLowerCase() === value?.trim().toLowerCase())
        ? `${name} cannot be "${value?.trim()}"`
        : false
}

function notAllowedSuffixes(value: string | undefined, name: string) {
    if (!value)
        return false

    const includedSuffixes: string[] = []
    SYSTEM_SUFFIXES.forEach(_ => {
        if (value.toUpperCase().includes(_.toUpperCase()))
            includedSuffixes.push(_)
    })

    return includedSuffixes.length === 0
        ? false
        : `${name} cannot include ${includedSuffixes.join(', ')}`
}

export function allowedName(name: string, additionalProhibitedNames?: string[]) {
    return (value: string | undefined) => {
        const notAllowedNameError = notAllowedName(value, name, additionalProhibitedNames)
            , notAllowedSuffixesError = notAllowedSuffixes(value, name)

        return notAllowedNameError || notAllowedSuffixesError
    }
}

function isInRange(value: number | string | undefined, min: number, max: number) {
    if (value === undefined)
        return false

    const parsedValue = typeof value === 'number' ? value : parseFloat(value)

    return !(parsedValue < min || parsedValue > max)
}

export function isNumberStartedWithZeros(value: number | string) {
    if (typeof value === 'number')
        return false

    function integerPartLength(value: string) {
        const dotIndex =  value.indexOf('.')
            , integerPart = dotIndex === -1 ? value : value.slice(0, dotIndex)
        return integerPart.length
    }

    return integerPartLength(value) !== integerPartLength(parseFloat(value).toString())
}

export function isValidNumberInRange(value: number | string | undefined, min: number, max: number) {
    if (value === undefined)
        return false
    return value !== NOT_VALID_VALUE
        && isInRange(value, min, max)
        && !isNumberStartedWithZeros(value)
}

export function isValidFraction(value: string | number | undefined) {
    if (!value)
        return false

    const fractionalPart = value.toString().split('.')[1] || ''

    return fractionalPart.length <= 3
}

export function checkDateRangeValidity(dateFrom: DateTime | undefined, dateTo: DateTime | undefined, message?: string) {
    if (dateFrom == undefined || dateTo == undefined)
        return false

    if (greaterThan(dateTo, dateFrom))
        return false

    return message || 'Date to must be after Date from'
}

export function minLengthForSearch(minLength: number, name?: string) {
    return (value: string | undefined | null) => !value || value.trim().length >= minLength ? undefined : `Enter at least four characters to search ${name ? `for a ${name}` : ''}`
}

export function hasTrimableSpaces(name: string, value: string | undefined) {
    if (!value)
        return

    if (value.length === value.trim().length)
        return false

    return `Spaces before or after ${name} value are not allowed`
}
