import type { PredefinedLists as State } from '_/model/app-state'
import { commonReducer, handler } from '_/facade/reducer'
import { predefinedListLoaded } from '../redux/actions'
import * as actions from './actions'

const sampleSessionsDefaultState: State['sampleSessions'] = []
    , sampleSessions = commonReducer(
        sampleSessionsDefaultState,

        handler(actions.sampleSessionListLoaded, (_, payload) => payload),
        handler(actions.sampleSessionRemoved, (state, id) => state.filter(_ => _.id !== id)),
        handler(predefinedListLoaded, (_, payload) => payload.sampleSessions),
    )

export { sampleSessions }
