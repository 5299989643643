import { useAction, useEffect, useState } from '_/facade/react'
import { Modal, ModalHeader, ModalBody } from '_/components/modal'
import { loadLimitsTrail } from './actions'
import AuditTrails from '_/components/audit-trail-list'
import { Close } from '_/components/button'
import type { AuditTrail } from '_/model/audit-trail/types'

interface Props {
    onClose(): void
}

function LimitsTrailsModal(props: Props) {
    const trails = useTrails()

    return(
        <Modal isOpen onClose={props.onClose}>
            <ModalHeader className='border-bottom-0'>
                <h4>Audit trail</h4>
                <Close onClick={props.onClose}/>
            </ModalHeader>
            <ModalBody>
                <AuditTrails
                    trail={trails}
                    hideHeader
                />
            </ModalBody>
        </Modal>
    )
}

export default LimitsTrailsModal

function useTrails() {
    const load = useAction(loadLimitsTrail)
        , [trails, setTrails] = useState<AuditTrail>()

    useEffect(
        () => {
            load().then(setTrails)
        },
        [load]
    )
    return trails
}
