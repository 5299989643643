import type { ControllerStateAndHelpers } from 'downshift'
import Downshift from 'downshift'
import type { FieldRenderProps } from 'react-final-form'
import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'
import { classnames, useRef, useEffect } from '_/facade/react'
import FormattedText from '_/features/text/formatted-text'
import * as t from '_/model/text/text'
import { tokenizedSearch } from '_/utils/array'
import Menu from './overlay/menu'

interface SelectProps {
    id?: string
    entities: readonly any[]
    disabled: boolean
    hasNoPermissions?: boolean
    calcId(entity: any): any
    calcName(entity: any): string | t.Text
    calcDisabled?(entity: any): boolean
    calcDisabledTitle?(entity: any): string
    calcHasNoPermissions?(entity: any): boolean
    input: Partial<FieldRenderProps<any>['input']>
    className?: string
    autocomplete: boolean
    showEndOfText?: boolean
    onInputValueChange?: (value: string) => void
    shouldClearInput?: boolean
    placeholder?: string
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    autoFocus?: boolean
    isSearchField?: boolean
    defaultHighlightedIndex?: number
    // required for server side filtering (entity may be selected but filtered out)
    fallbackEntity?: any
    testId?: string
}

function Select(props: SelectProps) {
    const [inputRef, inputFocused] = useInput(props.showEndOfText)

    function handleChange(selectedItem: any, downshift: ControllerStateAndHelpers<any>): void {
        const id = selectedItem && props.calcId(selectedItem)

        if (props.input.onChange)
            props.input.onChange(id === null ? '' : id)

        if (props.shouldClearInput)
            downshift.setState({inputValue: ''})
    }

    function searchEntities(input: string | null): readonly any[] {
        if (!props.autocomplete)
            return props.entities

        return tokenizedSearch(input || '', props.entities, _ => t.plainText(props.calcName(_)))
    }

    function handleInputValueChange(value: string, downshift: ControllerStateAndHelpers<any>) {
        if (props.onInputValueChange)
            props.onInputValueChange(value)

        if (!value && downshift.selectedItem)
            downshift.setState({ selectedItem: null })
    }

    function calculateSelectedItem() {
        if (props.input.value === undefined || props.input.value === '')
            return null

        const entities = props.fallbackEntity
                ? [props.fallbackEntity].concat(props.entities)
                : props.entities
            , selected = entities.find(_ => props.calcId(_) === props.input.value) || null

        return selected
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>, downshift: ControllerStateAndHelpers<unknown>) {
        const menuEntities = searchEntities(downshift.inputValue)
        if (e.key === 'Tab' && downshift.isOpen && downshift.highlightedIndex != null) {
            const selectedItem = menuEntities[downshift.highlightedIndex]

            downshift.setState({ selectedItem })
        }

        if (props.onKeyDown)
            props.onKeyDown(e)
    }

    function getCursorStyle(item: any) {
        if (props.calcHasNoPermissions && props.calcHasNoPermissions(item))
            return 'not-allowed'

        if (props.calcDisabled && props.calcDisabled(item))
            return 'default'

        return 'pointer'
    }

    function getItemTitle(item: any) {
        if (props.calcHasNoPermissions?.(item))
            return NO_PERMISSION_MESSAGE

        if (props.calcDisabled?.(item))
            return props.calcDisabledTitle?.(item)

        return ''
    }

    return (
        <Downshift
            selectedItem={calculateSelectedItem()}
            onChange={handleChange}
            onInputValueChange={handleInputValueChange}
            itemToString={_ => _ == null ? '' : t.plainText(props.calcName(_))}
            defaultHighlightedIndex={props.defaultHighlightedIndex}
        >
            {downshift =>
                <div className={classnames('input-group flex-nowrap downshift-select', props.className, inputFocused && 'downshift-select--focus')}>
                    <input
                        ref={inputRef}
                        autoFocus={props.autoFocus}
                        {...downshift.getInputProps({
                            id: props.id,
                            className: classnames('flex-fill min-width-0', props.hasNoPermissions && 'no-permission-input'),
                            name: props.input.name,
                            onBlur: props.input.onBlur,
                            onFocus: props.input.onFocus,
                            onKeyDown: _ => handleKeyDown(_, downshift),
                            disabled: props.hasNoPermissions || props.disabled,
                            title: props.hasNoPermissions ? NO_PERMISSION_MESSAGE : downshift.inputValue || undefined,
                        })}
                        placeholder={props.placeholder}
                        data-testid={props.testId}
                    />
                    {!props.isSearchField &&
                        <button
                            {...downshift.getToggleButtonProps({
                                className: classnames(
                                    'btn dropdown-toggle dropdown-toggle-split d-print-none',
                                    'background-disabled',
                                    props.hasNoPermissions && 'no-permission',
                                    props.disabled && 'cursor-default',
                                ),
                                disabled: props.hasNoPermissions || props.disabled,
                                tabIndex: -1,
                                title: props.hasNoPermissions ? NO_PERMISSION_MESSAGE : '',
                            })}
                            data-testid={`${props.testId}-toggle`}
                        />
                    }

                    {props.isSearchField && downshift.inputValue &&
                        <button
                            type='button'
                            className={classnames(
                                'close downshift-select__clear-button btn d-print-none',
                                'pe-1 background-disabled',
                                props.hasNoPermissions && 'no-permission',
                            )}
                            disabled={props.hasNoPermissions || props.disabled}
                            tabIndex={-1}
                            title={props.hasNoPermissions ? NO_PERMISSION_MESSAGE : ''}
                            onClick={() => downshift.clearSelection()}
                        >
                            &times;
                        </button>
                    }

                    {props.entities.length > 0 && downshift.isOpen &&
                        <Menu element={inputRef.current}>
                            <div
                                {...downshift.getMenuProps(
                                    {
                                        className: 'dropdown-menu d-block',
                                        style: {
                                            overflowY: 'auto',
                                            maxHeight: '300px',
                                        },
                                    },
                                    { suppressRefError: true }
                                )}
                                data-testid='dropdown-menu'
                            >
                                {searchEntities(downshift.inputValue)
                                    .map((item, index) =>
                                        <div
                                            {...downshift.getItemProps({
                                                key: index,
                                                className: 'dropdown-item',
                                                item,
                                                style: {
                                                    cursor: getCursorStyle(item),
                                                    backgroundColor: downshift.highlightedIndex === index ? 'lightgray' : 'white',
                                                    fontWeight: downshift.selectedItem === item ? 'bold' : 'normal',
                                                    color: props.calcDisabled && props.calcDisabled(item) || props.calcHasNoPermissions && props.calcHasNoPermissions(item) ? 'grey' : 'black',
                                                },
                                                disabled: props.calcDisabled && props.calcDisabled(item) || props.calcHasNoPermissions && props.calcHasNoPermissions(item),
                                                title: getItemTitle(item),
                                            })}
                                        >
                                            <FormattedText text={props.calcName(item)} testId={`${props.testId}-dropdown-item`} />
                                        </div>
                                    )
                                }
                            </div>
                        </Menu>
                    }
                </div>
            }
        </Downshift>
    )
}

Select.defaultProps = {
    autocomplete: false,
    disabled: false,
    input: {},
}

export default Select

function useInput(showEndOfText: boolean | undefined) {
    const inputRef = useRef<HTMLInputElement>(null)
        , inputFocused = document.activeElement === inputRef.current

    useEffect(
        () => {
            if (inputFocused || !showEndOfText || !inputRef.current)
                return

            // make rightmost part of input text visible when it doesn't feat into input width
            const input = inputRef.current
            input.scrollLeft = input.scrollWidth - input.clientWidth
        }
    )

    return [inputRef, inputFocused] as const
}
