import { React, classnames, useAction, useEffect, useState, useSelector, useCallback, useMemo } from '_/facade/react'
import { fullNameLocationList } from '_/utils/exposure-location'
import { shallowUpdate } from '_/utils/object'
import { mergeAndReplaceEntity } from '_/utils/array'
import { imageDataToBlob } from '_/utils/blob'

import type CustomField from '_/model/predefined-lists/custom-field/types'

import CustomReportsTrailsModal from '_/components/audit-trail-modal'
import PageHeader from '_/components/page-header'
import { LinkButton } from '_/components/link'
import Button from '_/components/button'

import type { CustomReportGraphData, CustomReportGraph } from '_/model/analysis/custom-report/custom-report'
import type CustomReport from '_/model/analysis/custom-report/custom-report'
import type { AnalysisFilter } from '_/model/analysis/filter/types'
import * as chartType from '_/model/analysis/chart-type'

import * as predefinedListsActions from '_/features/predefined-lists/redux/actions'
import * as routes from '_/constants/routes'
import * as fieldIndex from '_/constants/custom-field-index'
import * as actions from '../actions'
import * as analysisActions from '../../actions'
import { loadFloorPlanList } from '_/features/predefined-lists/floor-plan/actions'

import AnalysisCharts from '../../ui/analysis-charts'
import FloorPlanCharts from '../../ui/analysis-floor-plan-charts'
import type { FloorPlanChartRef } from '_/model/floor-plan/export'
import type { ExportRef } from '../../ui/date-series-chart'

import * as h from '../../ui/helpers'
import { formatGraphType } from '../edit/helpers'
import type { ImageExportInfo } from '_/model/sample/image/image'
import type { FloorPlanSubFilterValue } from '_/model/floor-plan/floor-plan'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import { calculateStartDate, calculateTimeSliderDate } from '_/model/floor-plan/time-slider'
import type { FloorPlanChartMetadata } from '_/model/analysis/chart-metadata'
import { CUSTOM_REPORT } from '_/model/floor-plan/floor-plan-location-page'
import { recalculateDynamicExposureDate } from '../../filter/helpers'
import type { GraphData } from '_/model/analysis/types'
import { useTimeService } from '_/components/time'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import { useOrganisms } from '_/hooks/shared-hooks'

interface ExportImageData {
    image: Promise<string | undefined>
    name: string
}

function CustomReportsView() {
    const isLoaded = usePreload()

    if (!isLoaded)
        return null

    return <CustomReportsViewInternal />
}

function CustomReportsViewInternal() {
    const timeService = useTimeService()
        , [showTrailModal, setShowTrailModal] = useState(false)
        , user = useSelector(_ => _.auth.user)
        , permissions = useSelector(_ => _.auth.permissions)
        , id = useSelector(_ => _.router.route?.params.id)
        , predefinedLists = useSelector(_ => _.predefinedLists)
        , exposureLocationList = fullNameLocationList(predefinedLists.exposureLocations)

        , [
            customReport, customReportGraphData, showSpinner,
            pieChartRefs, dateSeriesChartRefs, floorPlanChartRefs,
            handleSplitLocation, handleChangeFloorPlanSubFilter,
        ] = useCustomReportGraphData(predefinedLists.customFields, exposureLocationList)
        , handleExportGraphs = useExportGraphs(pieChartRefs, dateSeriesChartRefs, floorPlanChartRefs, customReportGraphData, predefinedLists.customFields)

        , title = customReport?.name ?? ''
        , floorPlans = predefinedLists.floorPlans.plans
        , organismIds = useMemo(() => customReportGraphData.flatMap(_ => _.graph.organismIds ?? []), [customReportGraphData])
        , organisms = useOrganisms(organismIds)

    function exportAllButtonDisabled() {
        if (customReportGraphData.some(_ => h.isAnalysisChartData(_.graphData)))
            return !permissions.exportCustomReportsData

        const floorPlans = predefinedLists.floorPlans.plans

        return !permissions.exportCustomReportsData
            || floorPlans.length === 0
            || customReportGraphData.every(_ => {
                    if (!h.isFloorPlanChartData(_.graphData))
                        return

                    const floorPlanId = _.graph.floorPlanId ?? h.getFilteredFloorPlans(floorPlans, _.graph, exposureLocationList)[0]?.id

                    return !floorPlans.find(f => f.id === floorPlanId)
                }
            )
    }

    function floorPlanMetadata(analysisFilter: AnalysisFilter, floorPlans: FloorPlan[], title: string): FloorPlanChartMetadata[] {
        const filteredFloorPlans = h.getFilteredFloorPlans(floorPlans, analysisFilter, exposureLocationList)
            , chartMetadata = filteredFloorPlans.map(_ =>
                ({
                    id: _.id,
                    metadata: h.getFloorPlanMetadata({
                        analysisFilter,
                        timeService,
                        user,
                        predefinedLists,
                        organisms,
                        reportName: title,
                        floorPlan: _,
                    }),
                }))

        return chartMetadata
    }

    function getFloorPlanSubFilter(graphId: string, floorPlans: FloorPlan[]): FloorPlanSubFilterValue {
        const graph = customReport?.graphs.find(t => t.id === graphId)

        return {
            floorPlanId: graph?.graph.floorPlanId ?? floorPlans[0]?.id,
            cumulativeView: graph?.graph.cumulativeView ?? true,
            timeSliderPosition: graph?.graph.timeSliderPosition,
            graphId,
        }
    }

    function getFilterWithCurrentlyPickedFloorPlan(filter: AnalysisFilter, graphId: string): AnalysisFilter {
        return {...filter, floorPlanId: customReport?.graphs.find(f => f.id === graphId)?.graph.floorPlanId}
    }

    return (
        <div className='container-fluid'>
            <div className='row justify-content-center'>
                <div className='col-9'>
                    {customReport && showTrailModal &&
                        <CustomReportsTrailsModal
                            id={customReport.id}
                            onClose={() => setShowTrailModal(false)}
                            loadAuditTrailAction={actions.loadCustomReportTrail}
                        />
                    }
                    <PageHeader sticky title={title}>
                        <div>
                            <Button
                                onClick={() => setShowTrailModal(true)}
                                className='btn-link align-baseline p-0 me-4'
                                testId='view-report-audit-trail'
                            >
                                View audit trail
                            </Button>
                            <LinkButton
                                className='btn-link align-baseline p-0 me-4'
                                routeName={routes.CUSTOM_REPORTS_EDIT}
                                hasNoPermissions={!permissions.editCustomReports}
                                routeParams={{ id }}
                                testId='edit-report'
                            >
                                Edit
                            </LinkButton>
                            <Button
                                onClick={handleExportGraphs}
                                className={classnames('btn-link align-baseline p-0', {disabled: !permissions.exportCustomReportsData})}
                                disabled={exportAllButtonDisabled()}
                                hasNoPermissions={!permissions.exportCustomReportsData}
                                testId='export-all-report-graphs'
                            >
                                Export all graphs
                            </Button>
                        </div>
                    </PageHeader>
                    {showSpinner
                        ? <div className='position-relative py-5'>
                            <i className='preview-spinner material-icons md-48'>sync</i>
                        </div>
                        : customReportGraphData.map((_, index) =>
                            <div key={_.id}>
                                <div className='container-fluid'>
                                    <div className='d-flex justify-content-center align-items-stretch'>
                                        <div className='flex-fill'>
                                            {h.isAnalysisChartData(_.graphData) &&
                                                <AnalysisCharts
                                                    filter={_.graph}
                                                    thresholdLines={_.graph.thresholdLines}
                                                    onSplitLocation={l => handleSplitLocation(l, _)}
                                                    metadata={h.getAnalysisMetadata({
                                                        analysisFilter: _.graph,
                                                        timeService,
                                                        user,
                                                        predefinedLists,
                                                        organisms,
                                                        reportName: title,
                                                    })}
                                                    timeService={timeService}
                                                    graphData={_.graphData}
                                                    showActionButtons
                                                    multipleGraphs
                                                    exportDisabled={!permissions.exportCustomReportsData}
                                                    dateSeriesChartRef={dateSeriesChartRefs.get(_.id)}
                                                    pieChartRef={pieChartRefs.get(_.id)}
                                                    testId={`report-view-${index}`}
                                                />
                                            }
                                            {h.isFloorPlanChartData(_.graphData) && permissions.useFloorPlansCharts &&
                                                (floorPlans.length !== 0
                                                    ? <FloorPlanCharts
                                                        filter={getFilterWithCurrentlyPickedFloorPlan(_.graph, _.id)}
                                                        timeService={timeService}
                                                        graphData={_.graphData}
                                                        showActionButtons
                                                        multipleGraphs
                                                        exportDisabled={!permissions.exportCustomReportsData}
                                                        floorPlans={h.getFilteredFloorPlans(floorPlans, _.graph, exposureLocationList)}
                                                        floorPlanSubFilter={{
                                                            value: getFloorPlanSubFilter(_.id, h.getFilteredFloorPlans(floorPlans, _.graph, exposureLocationList)),
                                                            onChange: handleChangeFloorPlanSubFilter,
                                                        }}
                                                        metadata={floorPlanMetadata(_.graph, floorPlans, title)}
                                                        ref={floorPlanChartRefs.get(_.id)}
                                                        floorPlanLocationPage={CUSTOM_REPORT}
                                                        testId={`report-view-${index}`}
                                                    />
                                                    : <div className='text-center'>There are no floor plans to display</div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default CustomReportsView

function usePreload() {
    const loadPredefinedLists = useAction(predefinedListsActions.loadPredefinedLists)
        , loadFloorPlans = useAction(loadFloorPlanList)
        , permissions = useSelector(_ => _.auth.permissions)
        , [isLoaded, setIsLoaded] = useState(false)

    useEffect(
        () => {
            Promise.all([
                loadPredefinedLists(),
                permissions.useFloorPlansCharts ? loadFloorPlans() : undefined
            ]).then(() => setIsLoaded(true))
        },
        [permissions, loadFloorPlans, loadPredefinedLists]
    )
    return isLoaded
}

function useCustomReportGraphData(customFields: CustomField[], exposureLocationList: ListExposureLocation[]) {
    const [customReport, setCustomReport] = useState<CustomReport>()
        , [customReportGraphData, setCustomReportGraphData] = useState<CustomReportGraphData[]>([])
        , [showSpinner, setShowSpinner] = useState(false)

        , id = useSelector(_ => _.router.route?.params.id)
        , pieChartRefs = useMemo(() => new Map<string, React.RefObject<ExportRef>>(), [])
        , dateSeriesChartRefs = useMemo(() => new Map<string, React.RefObject<ExportRef>>(), [])
        , floorPlanChartRefs = useMemo(() => new Map<string, React.RefObject<FloorPlanChartRef>>(), [])

        , loadCustomReport = useAction(actions.loadCustomReport)
        , loadLinesMarkersChartSeries = useAction(analysisActions.loadLinesMarkersChartSeries)
        , loadOrganismsBreakdown = useAction(analysisActions.loadOrganismsBreakdown)
        , loadOrganismTypeBreakdown = useAction(analysisActions.loadOrganismTypeBreakdown)
        , loadStackedSampleName = useAction(analysisActions.loadStackedSampleName)
        , loadContaminationFloorPlan = useAction(analysisActions.loadContaminationFloorPlan)
        , loadLimitBreachFloorPlan = useAction( analysisActions.loadLimitBreachFloorPlan)
        , timeService = useTimeService()

    const initialize = useCallback(
        (_: CustomReport) => {
            setCustomReport(_)
            _.graphs.forEach(_ => {
                if (_.graph.chartType === chartType.ORGANISMS_BREAKDOWN_CHART)
                    pieChartRefs.set(_.id, React.createRef<ExportRef>())
                else if (_.graph.chartType === chartType.ORGANISM_TYPE_BREAKDOWN
                    || _.graph.chartType === chartType.STACKED_SAMPLE_NAME
                    || h.isLinesMarkersChart(_.graph.chartType)
                )
                    dateSeriesChartRefs.set(_.id, React.createRef<ExportRef>())
                else
                    floorPlanChartRefs.set(_.id, React.createRef<FloorPlanChartRef>())
            })
        },
        [pieChartRefs, dateSeriesChartRefs, floorPlanChartRefs]
    )

    useEffect(
        () => {
            if (!id)
               return

            setShowSpinner(true)
            loadCustomReport(id)
                .then(customReport => {
                    initialize(customReport)
                    const promises: Promise<GraphData>[] = customReport.graphs.map(_ => {
                        const fields = h.normalizeFields(_.graph.fields ?? [], customFields)
                            , {exposureStartDateFrom, exposureStartDateTo} = recalculateDynamicExposureDate(_.graph, timeService)
                            , dateTo = calculateTimeSliderDate(exposureStartDateFrom, exposureStartDateTo, _.graph.aggregationPeriod, timeService, _.graph.timeSliderPosition)
                            , dateFrom = calculateStartDate(_.graph.aggregationPeriod, exposureStartDateFrom, dateTo, _.graph.cumulativeView ?? true, timeService)
                            , newQuery = {
                                ..._.graph,
                                exposureStartDateFrom: h.isFloorPlanChart(_.graph.chartType) ? dateFrom : exposureStartDateFrom,
                                exposureStartDateTo: h.isFloorPlanChart(_.graph.chartType) ? dateTo : exposureStartDateTo,
                                fields
                            }

                        switch(_.graph.chartType!) {
                            case chartType.ORGANISMS_BREAKDOWN_CHART:
                                return loadOrganismsBreakdown(newQuery)
                            case chartType.ORGANISM_TYPE_BREAKDOWN:
                                return loadOrganismTypeBreakdown(newQuery)
                            case chartType.STACKED_SAMPLE_NAME:
                                return loadStackedSampleName(newQuery)
                            case chartType.ACTION_LIMIT_BREACHES:
                            case chartType.ALERT_LIMIT_BREACHES:
                            case chartType.AVERAGE_CFU_CHART:
                            case chartType.CONTAMINATED_SAMPLES:
                            case chartType.TOTAL_CFU_CHART:
                            case chartType.TOTAL_SAMPLES_READ:
                            case chartType.PARTICLE_COUNTS:
                                return loadLinesMarkersChartSeries(newQuery)
                            case chartType.CONTAMINATION_FLOOR_PLAN:
                                return loadContaminationFloorPlan(newQuery)
                            case chartType.LIMIT_BREACH_FLOOR_PLAN:
                                return loadLimitBreachFloorPlan(newQuery)
                        }
                    })

                    Promise.all(promises)
                        .then(graphData => {
                            const customReportGraphData = customReport.graphs.map((_, i) => ({
                                graphData: graphData[i],
                                graph: _.graph,
                                id: _.id,
                            }))
                            setCustomReportGraphData(customReportGraphData)
                            setShowSpinner(false)
                        })
                })
        },
        [
            id, customFields, initialize, timeService,
            loadCustomReport, loadLinesMarkersChartSeries, loadOrganismTypeBreakdown, loadStackedSampleName,
            loadLimitBreachFloorPlan, loadContaminationFloorPlan, loadOrganismsBreakdown
        ]
    )

    function handleSplitLocation(locationName: string, graphData: CustomReportGraphData) {
        const graph = graphData.graph
            , exposureLocations = h.getLocationChildIds(exposureLocationList, locationName)

        if (!exposureLocations || !h.isLinesMarkersChart(graph.chartType))
            return

        const newQuery = shallowUpdate(graph, {fields: [{index: fieldIndex.EXPOSURE_LOCATION_ID, value: exposureLocations }] })

        loadLinesMarkersChartSeries(newQuery)
            .then(graphData => {
                const customReportGraphData = { id, graph: newQuery, graphData }
                setCustomReportGraphData(_ => mergeAndReplaceEntity(_, customReportGraphData))
            })
    }

    function handleChangeFloorPlanSubFilter(filter: FloorPlanSubFilterValue) {
        if (!customReport)
            return

        const graphToUpdate = customReport.graphs.find(f => f.id === filter.graphId)!
            , updated = {...graphToUpdate, graph: filter} as CustomReportGraph
            , graphs = customReport.graphs.filter(f => f.id !== filter.graphId).concat(updated)

        setCustomReport({...customReport, graphs })
    }

    return [
        customReport, customReportGraphData, showSpinner,
        pieChartRefs, dateSeriesChartRefs, floorPlanChartRefs,
        handleSplitLocation, handleChangeFloorPlanSubFilter,
    ] as const
}

function useExportGraphs(
    pieChartRefs: Map<string, React.RefObject<ExportRef>>,
    dateSeriesChartRefs: Map<string, React.RefObject<ExportRef>>,
    floorPlanChartRefs: Map<string, React.RefObject<FloorPlanChartRef>>,
    customReportGraphData: CustomReportGraphData[],
    customFields: CustomField[]
) {
    const exportCustomReportGraphs = useAction(actions.exportCustomReportGraphs)

    function getExportImageName(graphId: string) {
        const selectedCustomReportGraphData = customReportGraphData.find(_ => _.id === graphId)
        return selectedCustomReportGraphData
            ? formatGraphType(selectedCustomReportGraphData.graph, customFields)
            : ''
    }

    function getImagesData(ref: React.RefObject<ExportRef>, graphId: string): ExportImageData | undefined {
        if (ref.current)
            return {
                image: ref.current.getExportImage(),
                name: getExportImageName(graphId),
            }
    }

    function handleExportGraphs() {
        const images: Promise<string | undefined>[] = []
            , imageNames: string[] = []
            , floorPlanGraphs: Promise<ImageExportInfo[]> [] = []
            , exportImages: ImageExportInfo[] = []

        dateSeriesChartRefs.forEach((value, key) => {
            const data = getImagesData(value, key)
            if (data) {
                images.push(data.image)
                imageNames.push(data.name)
            }
        })

        pieChartRefs.forEach((value, key) => {
            const data = getImagesData(value, key)
            if (data) {
                images.push(data.image)
                imageNames.push(data.name)
            }
        })

        floorPlanChartRefs.forEach((value) => {
            if (value.current)
                floorPlanGraphs.push(value.current.exportImages())
        })

        Promise.all(floorPlanGraphs)
            .then(results => {
                results.forEach(result => {
                    result.forEach(img => exportImages.push(img))
                })
            })
            .then(() => Promise.all(images)
                .then(results => {
                    results.forEach((imageData, i) => {
                        if (imageData) {
                            exportImages.push({ blob: imageDataToBlob(imageData, 'image/png'), fileName: imageNames[i] })
                        }
                    })

                    exportCustomReportGraphs(exportImages)
                })
            )
    }
    return handleExportGraphs
}
