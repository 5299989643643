import type ApiService from '../api-service'

import type MonitoringGroupService from '../monitoring-group-service'
import { EDITING_SCHEDULING_GROUPS } from '_/model/context/electronic-signature-settings'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'

function factory(api: ApiService): MonitoringGroupService {
    const resource = api.resource(['monitoring-groups'], EDITING_SCHEDULING_GROUPS)
    return {
        ...resource,
        save: resource.saveWithReason,
        remove: resource.removeWithReason,
        getAdHocGroups,
    }

    function getAdHocGroups() {
        return api.get<MonitoringGroup[]>(['monitoring-groups', 'ad-hoc'])
    }
}

export default factory
