import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type FingerdabOverviewByOperatorService from '_/services/fingerdab-overview-by-operator-service'

import * as actions from './actions'

const factory = (service: FingerdabOverviewByOperatorService): EffectsFactory => () => [
    handler(actions.loadFingerdabOverviewByOperator, service.getFingerdabOverviewByOperator),

    handler(actions.loadOperatorPerformance, service.getOperatorPerformance),

    handler(actions.exportOperatorPerformance, payload => {
        const query = { ...payload, start: 0, count: 1000, format: 'csv' }

        return service.downloadOperatorPerformance(query)
    }),
]

export default factory
