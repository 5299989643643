import { Field } from 'react-final-form'
import { classnames } from '_/facade/react'

import DateInput from './local-date-form-control'
import { showFieldError } from './helpers'

interface InputFieldProps {
    id?: string
    name: string
    autoFocus: boolean
    disabled: boolean
    useDayEndTime: boolean
    children: React.ReactNode
    testId?: string
    className?: string
    inline?: boolean
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

const LocalDateField = (props: InputFieldProps) =>
    <Field name={props.name} render={
        _ =>
            <div className={`${props.inline ? 'row g-2' : 'mb-3 mx-1'} ${props.className ?? ''}`}>
                <label htmlFor={props.id} className='col-form-label' data-testid='field-label'>{props.children}</label>
                <DateInput
                    id={props.id}
                    autoFocus={props.autoFocus}
                    {..._.input}
                    onChange={v => {
                        _.input.onBlur()
                        _.input.onChange(v)
                    }}
                    className={classnames({ 'is-invalid': showFieldError(_.meta) })}
                    disabled={props.disabled}
                    useDayEndTime={props.useDayEndTime}
                    testId={props.testId}
                    onKeyDown={props.onKeyDown}
                />
                {showFieldError(_.meta) && <span className='invalid-feedback' data-testid='validation-error'>{_.meta.error}</span>}
            </div>
    }/>

LocalDateField.defaultProps = {
    autoFocus: false,
    useDayEndTime: false,
    disabled: false,
}

export default LocalDateField
