import type { State as RouterState } from 'router5'

import { useSelector } from '_/facade/react'
import * as routes from '_/constants/routes'

import TabNavbar from '_/components/tab-navbar'
import Link from '_/components/link'
import PageHeader from '_/components/page-header'
import DailyFingerdabs from './daily-fingerdabs'
import OperatorPerformancePage from './operator-performance/operator-performance'

function Fingerdabs() {
    const route = useSelector(_ => _.router.route)
        , permissions = useSelector(_ => _.auth.permissions)
        , routePage = renderRoute(route)

    return (
        <div className = 'd-flex w-100 h-100'>
            <div className='flex-fill overflow-y-auto'>
                <div className='fingerdabs-header-container'>
                    <div className='page-header pt-3'>
                        <PageHeader
                            sticky
                            title={
                                <div data-testid='fingerdab-report-title'>
                                    Fingerdabs
                                </div>
                            }
                        />
                        <TabNavbar>
                            <Link
                                routeName={routes.FINGERDABS_DAILY_FINGERDABS}
                                className='navbar-tab me-4'
                                hasNoPermissions={!permissions.readReports}
                            >
                                Daily fingerdabs
                            </Link>
                            <Link
                                routeName={routes.FINGERDABS_OPERATOR_PERFORMANCE}
                                className='navbar-tab'
                                hasNoPermissions={!permissions.readOperatorPerformanceReport}
                            >
                                Operator performance
                            </Link>
                        </TabNavbar>
                    </div>
                </div>
                <div className='justify-content-center fingerdabs-content-container'>
                    {routePage}
                </div>
            </div>
        </div>
    )
}

export default Fingerdabs

function renderRoute(route: RouterState | null) {
    switch (route?.name) {
        case routes.FINGERDABS_DAILY_FINGERDABS:
            return <DailyFingerdabs />

        case routes.FINGERDABS_OPERATOR_PERFORMANCE:
            return <OperatorPerformancePage />

        default:
            return null
    }
}
