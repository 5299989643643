interface Header {
    header: string
    value: string
}

interface AjaxOptions {
    method: string
    url: string
    responseType: XMLHttpRequestResponseType
    headers?: Header[]
    body?: string | FormData
}

interface Ajax {
    <T>(options: AjaxOptions): Promise<T>
}

type HeaderHash = {
    [_: string]: string
}

interface AjaxResponse {
    response: any
    headers: HeaderHash
}

type AjaxPromise<T> = Promise<T> & { abort(): void }

function ajax(options: AjaxOptions): AjaxPromise<AjaxResponse> {
    const xhr = new XMLHttpRequest()

    const result = new Promise<AjaxResponse>((resolve, reject) => {
        xhr.open(options.method, options.url)

        xhr.responseType = options.responseType
        xhr.addEventListener('load', handleLoad)
        xhr.addEventListener('error', handleError)
        xhr.addEventListener('abort', handleAbort)

        if (options.headers)
            options.headers.forEach(_ => xhr.setRequestHeader(_.header, _.value))

        xhr.send(options.body)

        function handleLoad(this: XMLHttpRequest) {
            const status = this.status
            let statusText = this.statusText
            // todo: needs proper fix
            if (!statusText) {
                try {
                    statusText = this.responseText
                }
                catch (_) {
                    statusText = 'Unexpected server communication error occurred'
                }
            }

            if (status < 200 || status >= 300) {
                reject({ status, statusText })
                return
            }

            const headerLines = this.getAllResponseHeaders().trim().split(/[\r\n]+/)
                , splitRegex = /(.*?): (.*)/
                , headerArray = headerLines.map(line => {
                    const [, header = '', value = ''] = splitRegex.exec(line) || []
                    return { header, value }
                })
                , headers: HeaderHash = {}

            headerArray.forEach(_ => headers[_.header] = _.value)

            resolve({
                response: this.response,
                headers,
            })
        }

        function handleError() {
            // error argument is event that is pretty unusable
            reject({ message: 'Network error'})
        }

        function handleAbort() {
            reject('abort')
        }
    })

    const abort = () => xhr.abort()

    return Object.assign(result, { abort })
}

function buildUrl(params: { api: string, segments: string[], query?: object }): string {
    const path = '/' + params.segments.map(encodeURIComponent).join('/')
        , api = params.api.replace(/[/]*$/g, '')
        , url = api + path
        , query = params.query as any

    if (query == null)
        return url

    const queryStr = Object.keys(query)
        .reduce(
            (acc, key) => {
                const value = query[key]
                    , pairs = Array.isArray(value)
                        ? value.map(_ => makeQueryPair(key, _))
                        : [makeQueryPair(key, value)]

                return acc.concat(pairs)
            },
            [] as string[]
        )
        .join('&')

    return `${url}?${queryStr}`

}

function makeQueryPair(key: string, value: any): string {
    const result = (value === undefined) ? '' :
        encodeURIComponent(key)
        + '='
        + encodeURIComponent(value == null ? 'null' : value)
            .replace(/%3A/g, ':')

    return result
}

export { ajax, Ajax, buildUrl, makeQueryPair, Header, AjaxResponse, AjaxPromise }
