import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import FormChangesObserver from '_/components/form/form-changes-observer'
import { useRef, useMemo } from '_/facade/react'
import { CheckboxField } from '_/components/form'
import NumberTextField from '_/components/form/number-text-field'
import NumberInputField from '_/components/form/number-input-field'
import { NOT_RECORDED_NAME, NOT_AVAILABLE } from '_/constants/system-words'
import * as t from '_/model/text/text'
import * as fieldIndex from '_/constants/custom-field-index'

import FormattedText from '_/features/text/formatted-text'
import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'

import FieldForm, { useFieldFormAction } from '_/features/samples/sample-edit/shared/field-form'
import InlineActions from '_/features/samples/sample-edit/inline-actions'
import EditedLabel from '_/features/samples/sample-edit/edited-label'

import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'
import type { ParticleConcentrationView } from '_/model/non-viable-sample/types'
import { getParticleCount } from '_/model/non-viable-sample/format'
import { getFieldValue } from '_/features/samples/helpers'
import type { NonViableLimits } from '_/model/predefined-lists/action-alert-limit/non-viable-limits'
import * as ps from '_/model/predefined-lists/action-alert-limit/non-viable-limit-type'
import { AT_REST } from '_/model/predefined-lists/action-alert-limit/monitoring-state'
import { validateParticleCount, validateParticleVolume } from '../recording/validate'
import SampleLimitLabel from '../sample-limit-label'
import type NonViableSampleView from '_/model/non-viable-sample/types'
import { getParticleEditedInfo } from '_/model/non-viable-sample/edit/helpers'
import type { SampleEditedInfo } from '_/model/sample/sample'

interface Props {
    entity: NonViableSampleEdit
    sample: NonViableSampleView
    onSubmit: (sample: NonViableSampleEdit) => Promise<void>
    nonViableLimits: NonViableLimits[]
    editedInfo: SampleEditedInfo[]
    hasNoPermissions: boolean
    lowerParticle?: boolean
}

function ContaminationFieldForm(props: Props) {
    const name = props.lowerParticle ? 'lowerParticle' : 'higherParticle'
        , notRecordedRef = useRef<HTMLInputElement>(null)
        , actionLimit = getFieldValue(props.entity.fields, props.lowerParticle ? fieldIndex.LOWER_PARTICLE_ACTION_LIMIT : fieldIndex.HIGHER_PARTICLE_ACTION_LIMIT)
        , alertLimit = getFieldValue(props.entity.fields, props.lowerParticle ? fieldIndex.LOWER_PARTICLE_ALERT_LIMIT : fieldIndex.HIGHER_PARTICLE_ALERT_LIMIT)
        , grade =  getFieldValue(props.entity.fields, fieldIndex.EXPOSURE_LOCATION_GRADE_ID)
        , atRest = props.entity.monitoringState === AT_REST
        , particleState = props.lowerParticle ? ps.NON_VIABLE_LOWER_CONCENTRATION_STATE : ps.NON_VIABLE_HIGHER_CONCENTRATION_STATE
        , currentLimits = props.nonViableLimits.find(_ => _.gradeId === grade && _.particleState === particleState)
        , [handleSave, handleCancel] = useFieldFormAction()
        , particles = useMemo(
            () => props.entity[name],
            [props.entity, name]
        )

    function handleChange(values: Partial<ParticleConcentrationView>, form: FormRenderProps) {
        if (values.notRecorded && values.count !== undefined) {
            form.form.change('count', undefined)
            form.form.change('volume', undefined)
        }

        if (values.count === undefined && values.volume !== undefined)
            form.form.change('volume', undefined)
    }

    function handleSubmit(particles: ParticleConcentrationView) {
        return props.onSubmit({ ...props.entity, [name]: particles})
    }

    function formatLimit(limit: number | undefined, particles: Partial<ParticleConcentrationView>) {
        if (!limit)
            return ''

        const limitInVolume = !particles.volume || !!validateParticleVolume('Vol (m³)')(particles.count, particles.volume, particles.notRecorded)
            ? NOT_AVAILABLE
            : (limit * particles.volume).toFixed(3)

        return limitInVolume + ` (${limit} per m³)`
    }

    function validate(values: Partial<ParticleConcentrationView>) {
        return {
            count: validateParticleCount('Count')(values.count, values.notRecorded),
            volume: validateParticleVolume('Vol (m³)')(values.count, values.volume, values.notRecorded),
        }
    }

    return (
        <FieldForm
            formId={name}
            label={props.lowerParticle ? '0.5μm particles' : '5μm particles'}
            formClassName='col-6'
            hasNoPermissions={props.hasNoPermissions}
            editAvailable
            renderView={() =>
                <span className='form-control-plaintext'>
                    <span className='fw-bold'>
                        <ParticleView particle={particles}/>
                        <EditedLabel editedInfo={getParticleEditedInfo(props.editedInfo, props.lowerParticle)}/>
                        <SampleLimitLabel breach={props.lowerParticle ? props.sample.lowerParticleBreachType : props.sample.higherParticleBreachType} />
                    </span>
                    <br />
                    <span className='text-muted'>Alert limit {formatLimit(alertLimit, particles)}</span>
                    <br />
                    <span className='text-muted'>Action limit {formatLimit(actionLimit, particles)}</span>
                </span>
            }
            renderEditForm={() =>
                <Form
                    initialValues={particles}
                    validate={validate}
                    onSubmit={handleSubmit}
                    render={form =>
                        <form>
                            <FormChangesObserver form={form} target={name} onChange={handleChange} />
                            <div className='d-flex align-items-start'>
                                <div className='d-flex flex-fill'>
                                    <div>
                                        <NumberInputField
                                            id='count'
                                            name='count'
                                            className='me-1 mb-0'
                                            disabled={form.values.notRecorded}
                                            onKeyDown={_ => focusElementOnAltNInput(notRecordedRef, _)}
                                        >
                                            Count
                                        </NumberInputField>
                                        <CheckboxField
                                            id='notRecorded'
                                            name='notRecorded'
                                            className=''
                                            tabIndex={form.values.notRecorded ? 0 : -1}
                                            inputRef={notRecordedRef}
                                        >
                                            Not recorded
                                        </CheckboxField>
                                    </div>
                                    <NumberTextField
                                        id='volume'
                                        name='volume'
                                        disabled={form.values.count === undefined || form.values.notRecorded}
                                    >
                                        Vol (m³)
                                    </NumberTextField>
                                </div>
                                <div>
                                    <label className='col-form-label'>&nbsp;</label>
                                    <div className='d-flex'>
                                        <InlineActions form={form} onSave={() => handleSave(form)} onCancel={() => handleCancel(form)} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span className='text-muted'>Alert limit {formatLimit(atRest ? currentLimits?.atRestAlertLimit : currentLimits?.inOperationAlertLimit, form.values)}</span>
                                <br />
                                <span className='text-muted'>Action limit {formatLimit(atRest ? currentLimits?.atRestActionLimit : currentLimits?.inOperationActionLimit, form.values)}</span>
                            </div>

                        </form>
                    }
                />
            }
        />
    )
}

export default ContaminationFieldForm

function ParticleView(props: {particle: ParticleConcentrationView}) {
    const { particle } = props
        , particleCount = getParticleCount(particle)

    if (particle.notRecorded)
        return <FormattedText text={[t.systemTextNode(NOT_RECORDED_NAME)]} />

    if (particleCount === undefined)
        return null

    return <>
        <FormattedText text={[t.defaultTextNode(`${particle.count} in ${particle.volume}m³ `)]} className='fw-bold'/>
        <FormattedText text={[t.systemTextNode(`(${parseFloat(particleCount.toFixed(3))} per m³)`)]} className='fw-normal'/>
    </>
}
