import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import { updateTransaction } from '_/model/objectionable-organisms/editor/transaction'

import type PredefinedListsService from '_/services/predefined-lists-service'
import { noop } from '_/utils/function'
import * as a from './actions'

const factory = (api: PredefinedListsService): EffectsFactory => _ => [
    handler(a.loadObjectionableOrganismList, api.objectionableOrganism.list),
    handler(a.loadObjectionableOrganism, api.objectionableOrganism.get),
    handler(a.createObjectionableOrganism, _ => api.objectionableOrganism.create(_).then(noop)),
    handler(a.saveObjectionableOrganism, payload =>
        updateTransaction(payload.id, payload.oldObjectionableOrganism, payload.newObjectionableOrganism, api)
    ),
    handler(a.removeObjectionableOrganism, api.objectionableOrganism.remove),
    handler(a.loadObjectionableOrganismsTrail, api.objectionableOrganism.trail),
    handler(a.loadObjectionableOrganisms, api.objectionableOrganism.getAll),
    handler(a.loadObjectionableOrganismEffectiveList, api.objectionableOrganism.getEffectiveList),
]

export default factory
