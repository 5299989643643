import CONFIG from '_/constants/config'
import { noop } from '_/utils/function'

// NOTE: not bulletproof, covers just needs of help pages
function normalizePath(path: string) {
    if (!path.endsWith('/'))
        path += '/'

    const parts = path.split('/')
        , result: string[] = []

    parts.forEach(part => {
        if (part === '.' || part === '') {
            return
        }

        if (part === '..') {
            result.pop()
            return
        }

        result.push(part)
    })

    return result.join('/')
}

function preparePage(document: Document) {
    const page = stripHelpPage(document)
    colorTableCells(page)
    highlightActivePageOnLeftNav(page)
    replaceOnClick(page)
    replaceApiDocUrl(page)

    return page
}

function stripHelpPage(document: Document): HTMLElement {
    const page = document.querySelector('.doc-wrapper') as HTMLElement

    return page
}


// adapted script from help
function colorTableCells(page: HTMLElement) {
    // primarily for the user permissions matrix table
    const cells = Array.from(page.querySelectorAll('td'))

    cells.forEach(cell => {
        if (cell.innerHTML === 'Y')
            cell.className = 'docs-green-cell'

        if (cell.innerHTML === 'N')
            cell.className = 'docs-red-cell'
    })
}

// adapted script from help
function highlightActivePageOnLeftNav(page: HTMLElement) {
    const pathname = window.location.pathname
        , anchors = Array.from(page.querySelectorAll<HTMLAnchorElement>('.docs-nav a'))

    anchors.forEach(anchor => {
        if (!(anchor.pathname.replace('//', '/') === pathname && anchor.className === 'docs-left-menu-link')) {
            anchor.className = ''
            return
        }

        const grandParent = anchor.parentElement && anchor.parentElement.parentElement
            , grandGrandParent = grandParent && grandParent.parentElement
            , grandParentPrevSibling = grandParent && grandParent.previousElementSibling

        if (grandParentPrevSibling && grandParentPrevSibling.className === 'docs-expandable') {
            grandParentPrevSibling.className = 'docs-contractable'
            grandParent.className = 'docs-revealed-nav'
            anchor.className = 'docs-left-menu-link-active'
        }
        else if (grandGrandParent && grandGrandParent.previousElementSibling != null) {
            grandGrandParent.previousElementSibling.className = 'docs-contractable'
            grandGrandParent.className = 'docs-revealed-nav'
            anchor.className = 'docs-left-menu-link-active'
        }
    })
}

function toggleableSection(target: HTMLElement) {
    return target.classList.contains('docs-expandable') || target.classList.contains('docs-contractable')
}

function toggleSection(target: HTMLElement) {
    // expand and collapse sections in left nav
    if (target.className === 'docs-expandable') {
        target.className = 'docs-contractable'
        if (target.nextElementSibling)
            target.nextElementSibling.className = 'docs-revealed-nav'
    }
    else {
        target.className = 'docs-expandable'
        if (target.nextElementSibling)
            target.nextElementSibling.className = 'docs-hidden-nav'
    }
}

function replaceOnClick(page: HTMLElement) {
    const buttons = Array.from(page.getElementsByTagName('button'))
        , links = Array.from(page.getElementsByTagName('a'))

    buttons.forEach(btn => btn.onclick = noop)
    links.forEach(link => {
        if (link.getAttribute('onclick')?.includes('anchorLink') || link.getAttribute('onclick')?.includes('setAnchor'))
            link.onclick = noop
    })
}

function isAccordion(target: HTMLElement) {
    const parentElement = target.parentElement
    return parentElement?.tagName == 'button'.toUpperCase()
        && parentElement.hasAttribute('aria-expanded')
}

function accordionItemClick(target: HTMLElement, page: HTMLElement | null) {
    const parent = target.parentElement
        , targetItem = parent?.getAttribute('data-target')?.slice(1)

    handleAccordionCardClick(targetItem, page)
}

function handleAccordionCardClick(targetItem: string | undefined, page: HTMLElement | null) {
    const cards = page && Array.from(page.getElementsByClassName('card'))

    if (!cards)
        return

    cards.forEach(card => {
        const cardBodyElement = Array.from(card.children).find(c => c.hasAttribute('data-parent'))

        if (!cardBodyElement)
            return

        const cardBodyId = cardBodyElement.getAttribute('id') ?? ''

        if (cardBodyId === targetItem) {
            cardBodyElement.className = cardBodyElement.className.includes('show')
                ? cardBodyElement.className.replace('show', '')
                : 'show ' + cardBodyElement.className
        }
        else if (!targetItem?.includes(cardBodyId + '_')) { // do not hide if it's parent for current card
            cardBodyElement.className = cardBodyElement.className.replace('show', '')
        }
    })
}

function toggleableTab(target: HTMLElement) {
    const grandParent = target.parentElement?.parentElement
    return grandParent?.classList.contains('nav-tabs') && target.getAttribute('data-toggle') === 'tab'
}

function toggleTab(target: HTMLElement) {
    const targetItem = target.getAttribute('href')?.slice(1)
        , grandParent = target.parentElement!.parentElement!
        , tabContents = Array.from(grandParent.nextElementSibling!.children)

    const siblingTabs = Array.from(grandParent.children)
    siblingTabs.forEach(tab => {
        const link = tab.querySelector('.nav-link')!

        if (link === target)
            link.classList.add('active')
        else
            link.classList.remove('active')
    })

    handleShowTab(targetItem, tabContents)
}

function handleShowTab(targetItem: string | undefined, tabContents: Element[] | null | undefined, hideOtherTab = true) {
    if (!tabContents)
        return

    tabContents.forEach(tab => {
        if (targetItem === tab.getAttribute('id'))
            tab.classList.add('active', 'show')
        else if (hideOtherTab)
            tab.classList.remove('active', 'show')
    })
}

function linkToAccordionTab(target: HTMLElement) {
    return target.getAttribute('onclick')?.includes('anchorLink')
        && target.parentElement?.className !== 'breadcrumbs'
}

function openAccordionCard(target: HTMLElement, page: HTMLElement | null) {
    const targetItem = target.getAttribute('href')?.slice(1)
        , id = targetItem?.split('_', 2).join('_') // get card id from tab id
        , tabs = page && Array.from(page.getElementsByClassName('tab-pane'))

    handleAccordionCardClick(id, page)
    handleShowTab('tab-pane_' + targetItem, tabs, false)
}

function replaceApiDocUrl(page: HTMLElement) {
    const apiUrlElement = page.querySelector('[href*="smartcontrol.microgenetics.co.uk/api/index.html"]')

    if (!apiUrlElement)
        return

    apiUrlElement.setAttribute('href', CONFIG.api + '/api/index.html')
}

export {
    normalizePath,
    preparePage,
    toggleableSection,
    toggleSection,
    isAccordion,
    accordionItemClick,
    toggleableTab,
    toggleTab,
    linkToAccordionTab,
    openAccordionCard,
}
