const POSITIVE_AND_NEGATIVE_NUMBERS = 0
    , POSITIVE_NUMBERS = 1
    , NEGATIVE_NUMBERS = 2

type AllowedNumberValues = typeof POSITIVE_AND_NEGATIVE_NUMBERS
    | typeof POSITIVE_NUMBERS
    | typeof NEGATIVE_NUMBERS

const ALLOWED_NUMBER_VALUES: { id: AllowedNumberValues, name: string }[] = [
    {
        id: POSITIVE_AND_NEGATIVE_NUMBERS,
        name: 'Allow positive and negative numbers',
    },
    {
        id: POSITIVE_NUMBERS,
        name: 'Allow positive numbers only',
    },
    {
        id: NEGATIVE_NUMBERS,
        name: 'Allow negative numbers only'
    }
]

export {
    POSITIVE_AND_NEGATIVE_NUMBERS,
    POSITIVE_NUMBERS,
    NEGATIVE_NUMBERS,
    ALLOWED_NUMBER_VALUES,
    AllowedNumberValues,
}

