import { useState, useAction, useEffect, useCallback } from '_/facade/react'
import { Form } from 'react-final-form'

import { Submit } from '_/components/form'
import { Close } from '_/components/button'
import { Modal, ModalHeader, ModalFooter, ModalBody } from '_/components/modal'
import { useTimeService } from '_/components/time'
import RadioGroupField from '_/components/form/radio-group-field'
import ExportList from '_/features/export/list'
import type SampleListQuery from '_/model/sample/list-query'

import type { ExportFormat } from '_/model/export/types'
import type Export from '_/model/export/types'
import EXPORT_FORMAT, { CSV } from '_/model/export/export-format'
import type { Filter as PlateListQuery } from '_/model/plate/plate-filter'

import * as exportActions from '_/features/export/actions'
import * as sampleActions from '_/features/samples/actions'
import * as plateActions from '_/features/plates/actions'
import * as nonViableActions from '_/features/non-viable-samples/actions'

type EntityType = 'viable sample' | 'plate' | 'non-viable sample'

interface Props {
    entityType: EntityType
    totalCount: number
    query?: SampleListQuery | PlateListQuery
    modalText: string
    navigateToList: () => void
}

function ExportModal(props: Props) {
    const timeService = useTimeService()
        , [exports, setExports] = useExports(props.entityType)
        , handleExport = useExportHandler(setExports, props.entityType, props.query ?? {})
        , handleDownload = useDownloadHandler(props.entityType)

    return (
        <Modal isOpen onClose={props.navigateToList} noDefaultContentWidth contentClassName='overflow-hidden export-modal--max-height export-modal--width'>
            <ModalHeader className='px-0 pb-0 border-bottom-0'>
                <h4><span className='uppercase-first-letter d-inline-block'>{props.entityType}</span> data export</h4>
                <Close onClick={props.navigateToList} testId='close-export-modal'/>
            </ModalHeader>
            <ModalBody className='d-flex flex-column px-0' noDefaultHeight>
                {props.modalText} <br/>
                Once your export begins, you can leave this page. You'll be notified when this file is ready to download.
                <div className='fw-bold mt-3'>Export format</div>
                <Form
                    onSubmit={handleExport}
                    initialValues={{ format: CSV }}
                    render={form =>
                        <form onSubmit={form.handleSubmit} className='d-flex flex-column'>
                            <RadioGroupField
                                id='radio'
                                name='format'
                                entities={EXPORT_FORMAT}
                                calcValue={_ => _.id}
                                calcLabel={_ => _.name}
                                testId='export-format'
                            />
                            <Submit disabled={props.totalCount === 0} className='ms-auto mt-3' testId='start-new-export'>Start a new export</Submit>
                        </form>
                    }
                />
            </ModalBody>
            {exports.length > 0 &&
                <ModalFooter className='flex-column flex-nowrap align-items-start justify-content-start px-0 mb-4 export-modal-table--height'>
                    <h4 className='ms-0 mb-3'>Your recent {props.entityType} data exports</h4>
                    <div className='w-100 m-0 overflow-auto'>
                        <ExportList onDownload={handleDownload} exports={exports} timeService={timeService}/>
                    </div>
                </ModalFooter>
            }
        </Modal>
    )
}

export default ExportModal

function getActionsByEntityType(entityType: EntityType) {
    switch (entityType) {
        case 'viable sample':
            return {
                loadExports: sampleActions.loadSamplesExports,
                download: exportActions.downloadSamplesExport,
                export: sampleActions.exportSamples
            }
        case 'plate':
            return {
                loadExports: plateActions.loadPlatesExports,
                download: exportActions.downloadPlatesExport,
                export: plateActions.exportPlates
            }
        case 'non-viable sample':
            return {
                loadExports: nonViableActions.loadNonViableSamplesExports,
                download: exportActions.downloadNonViableSamplesExport,
                export: nonViableActions.exportNonViableSamples
            }
    }
}

function useExports(entityType: EntityType) {
    const  loadExports = useAction(getActionsByEntityType(entityType).loadExports)
        , [exports, setExports] = useState<Export[]>([])

    const load = useCallback(
        () => {
            loadExports()
                .then(setExports)
        },
        [loadExports]
    )

    useEffect(load, [load])

    useEffect(() => {
        const interval = setInterval(load, 3000)
        return () => clearInterval(interval)
    })

    return [exports, setExports] as const
}

function useDownloadHandler(entityType: EntityType) {
    const download = useAction(getActionsByEntityType(entityType).download)

    return (fileId: string) => download(fileId)
}

function useExportHandler(setExports: (_: React.SetStateAction<Export[]>) => void, entityType: EntityType, query: SampleListQuery | PlateListQuery) {
    const action = useAction(getActionsByEntityType(entityType).export)

    function request(values: ExportFormat) {
        switch (entityType) {
            case 'viable sample': return action({...query as SampleListQuery, ...values})
            case 'plate' : return action({...query as PlateListQuery, ...values})
            case 'non-viable sample': return action(values)
        }
    }

    function handleExport(values: ExportFormat) {
        request(values)
            .then(e => setExports(prevExports => [e].concat(prevExports)))
    }

    return handleExport
}
