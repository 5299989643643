import type { FormRenderProps } from 'react-final-form'
import { useMemo, useRef } from '_/facade/react'
import { CheckboxField } from '_/components/form'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import * as fieldIndex from '_/constants/custom-field-index'
import type { SampleEditedInfo } from '_/model/sample/sample'
import FormattedText from '_/features/text/formatted-text'
import * as t from '_/model/text/text'
import EditedLabel from '../edited-label'

import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'

import FieldForm from '../field-form'
import InlineActions from '../inline-actions'
import { findFieldValuePos, valuePath, notRecordedPath, formatSampleBatchNumbers } from '../../helpers'
import { validateBatchNumber } from '../../validate'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'
import BatchNumberField from '../../shared/batch-number-field'

interface OwnProps {
    entity: SampleDetailsEdit | NonViableSampleEdit
    field: CustomField
    onSubmit: (sample: SampleDetailsEdit | NonViableSampleEdit) => Promise<void>
    hasNoPermissions: boolean
    editedInfo: SampleEditedInfo | undefined
    isViable?: boolean
}

function BatchNumberForm(props: OwnProps) {
    const position = findFieldValuePos(props.entity.fields, fieldIndex.BATCH_NUMBER)
        , fieldSettings = props.isViable ? props.field.viableSettings : props.field.nonViableSettings

    function handleChange(sample: Partial<SampleDetailsEdit>, form: FormRenderProps) {
        const batchNumberField = sample.fields && sample.fields[position]

        if (batchNumberField && batchNumberField.notRecorded) {
            if (!fieldSettings.notRecorded) // in case when field was N\R but later admin changed settings and N/R is not allowed anymore
                form.form.change(notRecordedPath(position), false)

            if (fieldSettings.notRecorded && batchNumberField.value !== undefined)
                form.form.change(valuePath(position), undefined)
        }
    }

    function handleSubmit(subEntity: SampleDetailsEdit) {
        const fields = subEntity.fields
            , sampleEdit = { ...props.entity, fields }

        return props.onSubmit(sampleEdit)
    }

    function isNotRecorded(form: FormRenderProps, pos: number) {
        const notRecordedState = form.form.getFieldState(notRecordedPath(pos))
        return !!(notRecordedState && notRecordedState.value)
    }

    const batchNumberField = props.entity.fields[position]
        , batchNumberFieldValue = batchNumberField.value
        , batchNumberNotRecorded = batchNumberField.notRecorded
        , initialValues = useMemo(
            () => ({
                fields: props.entity.fields,
            }),
            [props.entity.fields]
        )
        , batchNumberNotRecordedRef = useRef<HTMLInputElement>(null)

    return (
        <FieldForm
            formId='batch-numbers'
            label={props.field.fieldName}
            testId={`sample-field-${props.field.index}`}
            initialValues={initialValues}
            validate={_ => validateBatchNumber(_, props.field, props.isViable)}
            onSubmit={handleSubmit}
            onChange={handleChange}
            hasNoPermissions={props.hasNoPermissions}
            renderView={() =>
                <span className='form-control-plaintext'>
                    <span className='fw-bold user-formatted-text'>
                        <FormattedText text={batchNumberNotRecorded
                            ? [t.systemTextNode('Not recorded')]
                            : formatSampleBatchNumbers(batchNumberFieldValue)
                        } />
                        <EditedLabel editedInfo={props.editedInfo} breakToNewLine />
                    </span>
                </span>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div className='d-flex align-items-start'>
                    <div className='flex-fill'>
                        <BatchNumberField
                            id={`field-${props.field.index}`}
                            name={valuePath(position)}
                            disabled={isNotRecorded(form, position)}
                            editing
                            hasLabel={false}
                            autoFocus
                            isViable={props.isViable}
                            onKeyDown={_ => focusElementOnAltNInput(batchNumberNotRecordedRef, _)}
                        />
                        {fieldSettings.notRecorded &&
                            <CheckboxField
                                id={notRecordedPath(position)}
                                name={notRecordedPath(position)}
                                tabIndex={isNotRecorded(form, position) ? 0 : -1}
                                inputRef={batchNumberNotRecordedRef}
                            >
                                Not recorded
                            </CheckboxField>
                        }
                    </div>
                    <InlineActions form={form} onSave={handleSave} onCancel={handleCancel} testId={`sample-field-${props.field.index}`} />
                </div>
            }
        />
    )
}

export default BatchNumberForm
