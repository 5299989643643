import type NonViableSampleView from '_/model/non-viable-sample/types'
import type { ExistedComment } from '_/model/non-viable-sample/comment'
import type { SampleEditedInfo } from '_/model/sample/sample'
import type { NonViableSampleCreateRequest, NonViableSampleEditRequest } from '_/model/non-viable-sample/booking/types'

import { convertFromServerFields, convertToServerFields, convertToServiceNonViableSample } from './helper'
import type TimeService from '_/services/time-service'

import type ApiService from '../api-service'
import type NonViableSampleService from '../non-viable-sample-service'
import * as s from '_/model/context/electronic-signature-settings'
import type { Guid } from '_/model/guid'
import type { NonViableSampleFilter } from '_/model/non-viable-sample/list/types'
import type Page from '_/model/page'

function factory(api: ApiService, time: TimeService): NonViableSampleService {
    const resource = api.resource(['non-viable-samples'], s.EDITING_SAMPLES)
    return {
        ...resource,
        createNonViableSample,
        getByBarcode,
        addComments,
        loadComments,
        editNonViableSampleWithReason,
        editNonViableSample,
        loadEditedInfo,
        list,
        get,
    }

    function createNonViableSample(entity: Partial<NonViableSampleCreateRequest>): Promise<Guid> {
        const newFields = convertToServerFields(time, entity.fields)
            , newEntity = { ...entity, fields: newFields ?? [] }

        return api.post(['non-viable-samples'], newEntity)
    }

    function editNonViableSampleWithReason(id: string, entity: Partial<NonViableSampleEditRequest>) {
        const newFields = convertToServerFields(time, entity.fields)
            , newEntity = { ...convertToServiceNonViableSample(entity), fields: newFields ?? [] }

        return api.patchWithReason(['non-viable-samples', id], s.EDITING_SAMPLES, newEntity)
    }

    function editNonViableSample(id: string, entity: Partial<NonViableSampleEditRequest>) {
        const newFields = convertToServerFields(time, entity.fields)
            , newEntity = { ...convertToServiceNonViableSample(entity), fields: newFields ?? [] }

        return api.patch(['non-viable-samples', id], newEntity)
    }

    function get(id: Guid): Promise<NonViableSampleView> {
        return api.get(['non-viable-samples', id])
            .then(entity => convertFromServerFields(time, entity) as NonViableSampleView)
    }

    function getByBarcode(barcode: string): Promise<NonViableSampleView> {
        return api.post(['non-viable-samples', 'barcode'], { barcode })
            .then(entity => convertFromServerFields(time, entity) as NonViableSampleView)
    }

    function addComments(id: string, comments: string[]) {
        return api.post(['non-viable-samples', id, 'comments'], { comments })
    }

    function loadComments(id: string) {
        return api.get<ExistedComment[]>(['non-viable-samples', id, 'comments'])
    }

    function loadEditedInfo(id: Guid) {
        return api.get<SampleEditedInfo[]>(['non-viable-samples', id, 'edited-info'])
    }

    function list(query: NonViableSampleFilter): Promise<Page<NonViableSampleView>> {
        return api.post<Page<NonViableSampleView>>(['non-viable-samples', 'search'], query)
            .then(_ => {
                const items = _.items.map(r => convertFromServerFields(time, r))
                return {..._, items}
            })
    }
}

export default factory
