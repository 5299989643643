import { useAction, useEffect, useRef, useSelector, useState } from '_/facade/react'
import Button from '_/components/button'
import Select from '_/components/downshift-select'
import { isPreDemoEnvironment } from '_/model/environment/helpers'
import type ImageProvider from '_/model/smart-check/image-provider'
import * as ds from '_/model/predefined-lists/devices/status'
import type { Device } from '_/model/predefined-lists/devices/types'
import type WebcamState from '_/model/smart-check/webcam-state'
import * as sca from '_/features/smart-check/actions'
import * as da from '_/features/predefined-lists/devices/actions'
import { id } from '_/utils/function'
import { useContextSwitchObserver } from '_/components/context-observer'

interface Props {
    webcam: WebcamState
    isImageSelected: boolean
    inProgress: boolean
    hasNoPermission?: boolean
    onImageCapture: (provider: ImageProvider) => void
    onShowLiveFeed: () => void
}

function CaptureControls(props: Props) {
    const devices = useDevices()
        , hasWebcam = props.webcam.type === 'ready' || props.webcam.type === 'error'
        , [deviceInput, deviceReady, providers, calcDeviceName] = useDeviceInput(devices, props.webcam)
        , captureDisabled = props.inProgress || !deviceReady

    return (
        <div className='d-print-none d-flex py-4'>
            <div className='row g-2'>
                <div className='col-auto'>
                    <Select
                        className='form-control'
                        calcId={id}
                        calcName={calcDeviceName}
                        entities={providers}
                        input={deviceInput}
                        hasNoPermissions={props.hasNoPermission}
                    />
                </div>
                <div className='col-auto'>
                    {!hasWebcam || !props.isImageSelected
                        ? <Button
                            className='btn-primary'
                            onClick={() => props.onImageCapture(deviceInput.value!)}
                            disabled={captureDisabled}
                            hasNoPermissions={props.hasNoPermission}
                        >
                            {props.inProgress
                                ? 'Capture in progress...'
                                : 'Capture with photo booth'
                            }
                        </Button>
                        : <Button
                            className='btn-primary'
                            onClick={props.onShowLiveFeed}
                            disabled={captureDisabled}
                            hasNoPermissions={props.hasNoPermission}
                        >
                            {props.inProgress
                                ? 'Capture in progress...'
                                : 'Capture another'
                            }
                        </Button>
                    }
                </div>
            </div>

            {isPreDemoEnvironment() &&
                <div className='ms-auto'>
                    <label htmlFor='image-upload' className='me-1 mb-0 cursor-pointer btn btn-link'>
                        Upload a photo
                    </label>
                    <input
                        onChange={e => {
                            if (!e.target.files)
                                return Promise.resolve(undefined)

                            props.onImageCapture({ type: 'upload', image: e.target.files[0] })
                        }}
                        accept='image/jpg, image/jpeg, image/png'
                        value=''
                        name='image'
                        type='file'
                        className='d-none'
                        id='image-upload'
                        disabled={captureDisabled || props.hasNoPermission}
                        data-testid='field-image-upload'
                    />
                </div>
            }
        </div>
    )
}

export default CaptureControls

type ProdImageProvider = Exclude<ImageProvider, { type: 'upload' }>

function useDeviceInput(devices: Device[] | undefined, webcam: WebcamState) {
    const currentDeviceId = useSelector(_ => _.smartCheck.currentReadingDeviceId)
        , currentDeviceInitialized = useRef(false)
        , deviceChanged = useAction(sca.readingDeviceChanged)
        , webcamState = webcam.type
        , providers = (devices || [])
            .map<ProdImageProvider>(_ => ({ type: 'remote', deviceId: _.id }))
            .concat(webcamState === 'ready' || webcamState === 'error' ? { type: 'webcam' } : [])

    useEffect(
        () => {
            if (currentDeviceInitialized.current)
                return

            if (!devices || webcamState === 'unknown')
                return

            currentDeviceInitialized.current = true

            if (currentDeviceId)
                return

            const onlineProviders = providers
                    .filter((_): _ is Extract<ImageProvider, { type: 'remote' }> => _.type === 'remote')
                    .filter(p => devices.find(d => d.id === p.deviceId)?.status === ds.ONLINE)

            if (onlineProviders.length > 1)
                return

            if (onlineProviders.length === 1) {
                deviceChanged(onlineProviders[0].deviceId)
                return
            }

            const webcamDevice = providers.find(_ => _.type === 'webcam')
            if (webcamDevice) {
                deviceChanged('webcam')
                return
            }
        },
        [webcamState, currentDeviceId, providers, devices, deviceChanged]
    )

    function calcName(provider: ProdImageProvider | undefined) {
        if (!provider)
            return ''

        if (provider.type === 'webcam')
            return 'Webcam'

        const device = devices?.find(_ => _.id === provider.deviceId)
        if (!device)
            return ''

        const status = device.status === ds.OFFLINE
                ? ' (offline)'
                : device.status === ds.INACTIVE
                    ? ' (inactive)'
                    : ''

        return device.name + status
    }

    const input = {
            value: providers.find(
                _ => _.type === 'remote'
                    ? _.deviceId === currentDeviceId
                    : 'webcam' === currentDeviceId
            ),
            onChange: (_: ProdImageProvider | undefined) =>
                deviceChanged(
                    _?.type === 'webcam'
                        ? 'webcam'
                        : _?.type === 'remote'
                            ? _.deviceId
                            : undefined
                ),
        }

    const device = getDevice(input.value, devices)
        , deviceReady = input.value?.type === 'webcam' && webcam.type === 'ready'
            || device && !device.busy && device.status === ds.ONLINE

    return [input, deviceReady, providers, calcName] as const
}

function useDevices() {
    const getDevices = useAction(da.getDevices)
        , [devices, setDevices] = useState<Device[]>()
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            const cleanup = getDevices()
                .then(observable => observable(setDevices))

            return () => {
                cleanup.then(unsubscribe => unsubscribe())
            }
        },
        [contextSwitch, getDevices]
    )

    return devices
}

function getDevice(provider: ProdImageProvider | undefined, devices: Device[] = []): Device | undefined {
    const id = provider?.type === 'remote' ? provider.deviceId : undefined

    return devices.find(_ => _.id === id)
}
