import { diffObject } from '_/utils/object'
import type Api from '_/services/trend-service'
import type { Trend, TrendEdit} from './types'

function updateTransaction(
    id: string,
    current: Trend,
    next: TrendEdit,
    api: Api,
): Promise<void> {
    const oldTrend: TrendEdit = { trendInvestigationReferences: current.trendInvestigationReferences }
        , diff = diffObject(oldTrend, next)

    if (!diff)
        return Promise.resolve()

    return api.saveTrendInvestigationRef(id, diff)
}

export {
    updateTransaction,
}
