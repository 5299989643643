import type { FormRenderProps } from 'react-final-form'

import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { NonViableSampleForm } from '_/model/non-viable-sample/booking/types'
import { IN_OPERATION } from '_/model/predefined-lists/action-alert-limit/monitoring-state'

function getDefaultFormValues(customFields: CustomField[]): NonViableSampleForm {
    return {
        fields: customFields.map(_ => ({ index: _.index, value: undefined, notRecorded: false })),
        monitoringState: IN_OPERATION,
        lowerParticle: {
            count: undefined,
            volume: undefined,
            notRecorded: false,
        },
        higherParticle: {
            count: undefined,
            volume: undefined,
            notRecorded: false,
        },
    }
}

function formFieldHasValue(form: FormRenderProps, path: string) {
    return form.form.getFieldState(path)?.value !== undefined
}

function isFormFieldNotRecorded(form: FormRenderProps, notRecordedPath: string) {
    return !!form.form.getFieldState(notRecordedPath)?.value
}

export {
    formFieldHasValue,
    isFormFieldNotRecorded,
    getDefaultFormValues,
}
