import { compare } from '_/utils/string'

export const SAMPLE = 0
           , USER = 1
           , SAMPLE_TYPE = 4
           , EXPOSURE_LOCATION = 5
           , ORGANISM_IDENTIFICATION = 6
           , MEMBERSHIP = 10
           , USER_SESSION = 11
           , CONTEXT = 12
           , SESSION = 13
           , OPERATOR = 14
           // Not used entity type
           // , ORGANISM_OBJECTIONABLE = 15
           , SAMPLE_SEARCH_FILTER = 16
           , LIMIT = 17
           , GRADE = 18
           , SITE_SETTING = 19
           , ANALYSIS_SEARCH_FILTER = 20
           , CUSTOM_FIELD = 21
           , CUSTOM_REPORT = 22
           , USER_ROLE = 23
           , REPORT_REMINDER = 24
           , FLOOR_PLAN = 25
           , MONITORING_GROUP = 26
           , DAY_SCHEDULE = 27
           , OBJECTIONABLE_ORGANISM = 28
           , MONITORING_OVERVIEW = 29
           , PHOTO_REQUIREMENT_OVERRIDE = 30
           , API_KEY = 31
           , MONITORING_GROUP_SERIES = 32
           , TREND = 33
           // , TREND_INSTANCE = 34 Not used yet
           , PLATE = 35
           , NON_VIABLE_SAMPLE = 36

import type * as self from './entity-type'
type EntityId = Extract<(typeof self)[keyof typeof self], number>

const ENTITY_TYPE: {id: EntityId, name: string}[] = ([
        [SAMPLE, 'Viable sample'],
        [USER, 'User'],
        [SAMPLE_TYPE, 'Sample name'],
        [EXPOSURE_LOCATION, 'Exposure location'],
        [ORGANISM_IDENTIFICATION, 'Organism identification'],
        [MEMBERSHIP, 'Membership'],
        [USER_SESSION, 'User session'],
        [CONTEXT, 'Context'],
        [OPERATOR, 'Operator'],
        [SESSION, 'Session'],
        // Not used entity type
        // [ORGANISM_OBJECTIONABLE, 'Objectionable organism'],
        [SAMPLE_SEARCH_FILTER, 'Viable sample search filter'],
        [LIMIT, 'Limit'],
        [GRADE, 'Grade'],
        [SITE_SETTING, 'Site setting'],
        [ANALYSIS_SEARCH_FILTER, 'Analysis search filter'],
        [CUSTOM_FIELD, 'Custom field'],
        [CUSTOM_REPORT, 'Custom report'],
        [USER_ROLE, 'User role'],
        [REPORT_REMINDER, 'Custom report reminder'],
        [FLOOR_PLAN, 'Floor plan'],
        [MONITORING_GROUP, 'Monitoring group'],
        [DAY_SCHEDULE, 'Day schedule'],
        [OBJECTIONABLE_ORGANISM, 'Objectionable identification'],
        [MONITORING_OVERVIEW, 'Monitoring overview'],
        [PHOTO_REQUIREMENT_OVERRIDE, 'Photo requirement override'],
        [API_KEY, 'Api key'],
        [MONITORING_GROUP_SERIES, 'Monitoring group series'],
        [TREND, 'Trend'],
        [PLATE, 'Plate'],
        [NON_VIABLE_SAMPLE, 'Non-viable sample'],
    ] as const)
    .map(([id, name]) => ({ id, name }))
    .slice()
    .sort((a, b) => compare(a.name, b.name))

export {
    ENTITY_TYPE,
}
