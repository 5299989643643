const CONTEXT_EXPORT = 0
    , SAMPLES_EXPORT = 1
    , PLATES_EXPORT = 2
    , NON_VIABLE_SAMPLES_EXPORT = 3

export {
    CONTEXT_EXPORT,
    SAMPLES_EXPORT,
    PLATES_EXPORT,
    NON_VIABLE_SAMPLES_EXPORT,
}
