import type TimeService from '_/services/time-service'

import * as ranges from '_/constants/date-ranges'
import { EXPOSURE_DATE_START } from '_/constants/search-date-type'
import * as breachTypes from '_/constants/sample-breach-type'
import { GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION, NO_GROWTH } from '_/constants/sample-status'

import { getLast28DaysDate, splitStringIntoFewLines } from '_/features/analysis/helpers'
import { recalculateDynamicExposureDate } from '_/features/analysis/filter/helpers'

import type { OperatorsReport } from './operators-report/types'
import type { LocationsReport } from './locations-report/types'
import type { MissedMonitoringReport } from './missed-monitoring-report/types'
import type { ReportDateValues } from './types'

function getInitialDateValues(timeService: TimeService): ReportDateValues {
    const exposureStartDateTo = timeService.ctzDayStart(timeService.now())
        , exposureStartDateFrom = getLast28DaysDate(timeService, exposureStartDateTo)
        , exposureDateRange = ranges.MONTH

    return {
        exposureStartDateFrom,
        exposureStartDateTo: timeService.ctzDayEnd(exposureStartDateTo),
        exposureDateRange,
    }
}

function sampleListRouteParams(report: LocationsReport | OperatorsReport, timeService: TimeService, includeBreaches?: boolean) {
    const dateRange = getDateRange(timeService, report, false)
    return {
        dateToFilter: EXPOSURE_DATE_START,
        dateFrom: dateRange.exposureStartDateFrom,
        dateTo: dateRange.exposureStartDateTo,
        includeCompromised: false,
        sampleBreachTypes: includeBreaches ? [breachTypes.ACTION_LIMIT, breachTypes.ALERT_LIMIT] : undefined,
        statuses: [GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION, NO_GROWTH],
        sort: 'exposureStartTime:desc',
    }
}

function getDateRange(timeService: TimeService, filter: MissedMonitoringReport | LocationsReport | OperatorsReport, changeToDate = true) {
    const recalculatedFilterState = recalculateDynamicExposureDate(filter, timeService)

    return {
        exposureStartDateFrom: recalculatedFilterState.exposureStartDateFrom,
        exposureStartDateTo: changeToDate
            ? timeService.ctzDayStart(timeService.addCtzDays(recalculatedFilterState.exposureStartDateTo, 1))
            : recalculatedFilterState.exposureStartDateTo
    }
}

function formatExposureDateRange(report: LocationsReport | OperatorsReport | MissedMonitoringReport, timeService: TimeService) {
    const dateRange = getDateRange(timeService, report, false)
        , dateFrom = timeService.formatCtzDate(dateRange.exposureStartDateFrom)
        , dateTo = timeService.formatCtzDate(dateRange.exposureStartDateTo)

    return 'Exposure date range: ' +  dateFrom + ' - ' + dateTo
}

const LINE_WIDTH = 90

function getGraphFooter(locationReport: LocationsReport | OperatorsReport | MissedMonitoringReport, entityName: string | undefined, timeService: TimeService) {
    return ['<b>' + 'Filters' + '</b>'].concat(
        entityName ? splitStringIntoFewLines(entityName, LINE_WIDTH) : [],
        splitStringIntoFewLines(formatExposureDateRange(locationReport, timeService), LINE_WIDTH),
    )
}

export {
    getInitialDateValues,
    sampleListRouteParams,
    getGraphFooter,
    formatExposureDateRange,
    getDateRange,
}
