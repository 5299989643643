const NOT_YET_IDENTIFIED = 0
    , ORGANISMS = 1
    , OTHER = 2

type AnalysisIdentificationType = never
    | typeof NOT_YET_IDENTIFIED
    | typeof ORGANISMS
    | typeof OTHER

interface Identification {
    type: AnalysisIdentificationType
    entityId: string | undefined
    name: string
}

interface OrganismsBreakdownSeries {
    series: {
        identification: Identification
        count: number
    }[]
}

export {
    OrganismsBreakdownSeries as default,
    AnalysisIdentificationType,
    NOT_YET_IDENTIFIED,
    ORGANISMS,
    OTHER,
}
