import type { Analysis as State } from '_/model/app-state'
import { commonReducer, handler, shallowUpdate } from '_/facade/reducer'

import * as actions from './actions'

const defaultState: State = {
    analysisSearchFilters: [],
}

export default commonReducer(
    defaultState,
    handler(actions.analysisSearchFilterListLoaded, (state, analysisSearchFilters) => shallowUpdate(state, { analysisSearchFilters })),
)
