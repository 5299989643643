import Button from '_/components/button'
import type { FormRenderProps } from 'react-final-form'
import { Submit, submitDisabled } from '_/components/form'

interface Props<T> {
    form: FormRenderProps<T>
    onSave: () => Promise<void>
    onCancel: () => void
    testId?: string
}

function InlineActions<T>(props: Props<T>) {
    return (
        <>
            <Submit
                className='btn-primary ms-1'
                disabled={(submitDisabled(props.form))}
                onClick={props.onSave}
                testId={`${props.testId}-save`}
            >
                Save
            </Submit>
            <Button
                className='btn-secondary ms-1'
                onClick={props.onCancel}
                testId={`${props.testId}-cancel`}
            >
                Cancel
            </Button>
        </>
    )
}

export default InlineActions
