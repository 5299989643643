import { useAction, useState, useCallback, useEffect, useSelector, classnames } from '_/facade/react'
import { useExposureLocations, useFilter } from '_/hooks/shared-hooks'

import type PaginationState from '_/model/pagination-state'
import type SortState from '_/model/sort-state'
import type Page from '_/model/page'

import { useContextSwitchObserver } from '_/components/context-observer'
import { LinkButton } from '_/components/link'

import { ITEMS_PER_PAGE } from '_/constants'

import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { NonViableSampleFilter } from '_/model/non-viable-sample/list/types'
import type NonViableSampleView from '_/model/non-viable-sample/types'

import NonViableSampleTable from './table'
import ExportModal from './export-modal'
import * as r from '_/constants/routes'
import * as customFieldsActions from '_/features/predefined-lists/custom-fields/actions'
import * as actions from '../actions'


function NonViableSampleList() {
    const customFields = useActiveNonViableCustomFields()
        , exposureLocations = useExposureLocations()
        , permissions = useSelector(_ => _.auth.permissions)
        , route = useSelector(_ => _.router.route)

        , [filter, handleSortChange, handlePaginationChange] = useFilterState()
        , [samples, totalCount, showSpinner] = useNonViableSamples(filter)
        , exportMode = route?.name === r.NON_VIABLE_SAMPLES_EXPORT

    return (
        <div className='d-flex flex-fill overflow-auto justify-content-center'>
            <div className='col-9 h-100 d-flex flex-column'>
                <div className='navbar page-header py-3 page-header--sticky'>
                    <h5 className='fw-bold'>{`Your filters matched ${totalCount} results`}</h5>
                    <div>
                        <LinkButton
                            className={classnames('btn-link me-1 mt-1', {
                                disabled: !permissions.exportSamples,
                            })}
                            routeName={r.NON_VIABLE_SAMPLES_EXPORT}
                            hasNoPermissions={!permissions.exportSamples}
                        >
                            <i className='material-icons align-middle md-18 mb-1 me-1'>get_app</i>
                            <span>Export</span>
                        </LinkButton>
                        <LinkButton
                            className={classnames('btn-primary me-1', { disabled: !permissions.bookSamples })}
                            routeName={r.NON_VIABLE_SAMPLES_RECORDING}
                            hasNoPermissions={!permissions.bookSamples}
                        >
                            Record a non-viable sample
                        </LinkButton>
                    </div>
                </div>
                {exportMode &&
                    <ExportModal totalCount={totalCount} />
                }
                {showSpinner &&
                    <div className='position-relative py-5'>
                        <i className='preview-spinner material-icons md-48'>sync</i>
                    </div>
                }
                {/* table headers use context from SortableHead to track sorting state, so table should not be unmounted during reload */}
                <div className={classnames('overflow-auto', { 'd-none': showSpinner })}>
                    <NonViableSampleTable
                        samples={samples}
                        customFields={customFields}
                        exposureLocations={exposureLocations}
                        totalCount={totalCount}
                        filter={filter}
                        onSortChange={handleSortChange}
                        onPaginationChange={handlePaginationChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default NonViableSampleList

function useFilterState() {
    const [filter, setFilter] = useFilter<NonViableSampleFilter>('non-viable-sample', _ => ({ sort: 'createdAt:desc', start: 0, count: ITEMS_PER_PAGE, ..._ }))

    function handleSortChange(sortState: SortState) {
        setFilter({...filter, ...sortState})
    }

    function handlePaginationChange(pagination: PaginationState) {
        setFilter({...filter, ...pagination})
    }

    return [filter, handleSortChange, handlePaginationChange] as const
}

function useNonViableSamples(filter: NonViableSampleFilter) {
    const loadNonViableSamples = useAction(actions.loadNonViableSamples)
        , [samples, setSamples] = useState<Page<NonViableSampleView>>({ items: [], totalCount: 0 })
        , contextSwitch = useContextSwitchObserver()
        , [showSpinner, setShowSpinner] = useState(false)

        , load = useCallback(
            () => {
                setShowSpinner(true)
                loadNonViableSamples(filter)
                    .then(setSamples)
                    .finally(() => setShowSpinner(false))
            },
            [filter, loadNonViableSamples]
        )

    useEffect(() => { load() }, [contextSwitch, load])
    return [samples.items, samples.totalCount, showSpinner] as const
}

function useActiveNonViableCustomFields() {
    const load = useAction(customFieldsActions.loadCustomFields)
        , [fields, setFields] = useState<CustomField[]>([])
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            load()
                .then(_ =>
                    setFields(_.filter(f => f.nonViableSettings.isActive))
                )
        },
        [contextSwitch, load]
    )

    return fields
}
