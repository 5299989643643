import { compose } from 'redux'
import type { DispatchHash, DispatchProp, ActionType } from './types'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

type SimpleDispatchProps<T extends DispatchHash> = {
    [K in keyof T]: DispatchProp<T[K]>
}

function dispatchPropsMapper<T extends DispatchHash>(dispatchProps: T): (dispatch: any) => SimpleDispatchProps<T> {
    return function mapDispatchToProps(dispatch: any): SimpleDispatchProps<T> {
        const result = {} as any

        Object.keys(dispatchProps).forEach(_ => result[_] = compose(dispatch, dispatchProps[_]))

        return result
    }
}

type ActionCreator<T extends any[], R> = (...args: T) => { type: ActionType<any, R> }
function useAction<T extends ActionCreator<any, any>>(action: T): DispatchProp<T> {
    const dispatch = useDispatch()
        , composedDispatch = useCallback(
            (...args) => dispatch(action(...args)),
            [dispatch, action]
        )

    return composedDispatch as any
}

export {
    dispatchPropsMapper,
    useAction,
}
