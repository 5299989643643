import type { SampleEditedInfo } from '_/model/sample/sample'
import type Sample from '_/model/sample/sample'
import type SampleReading from '_/model/sample/reading/sample-reading'
import type { SampleSearchByBarcodeResult, SampleSearchStatistics } from '_/model/sample/sample-search-result'
import type SampleSearchResult from '_/model/sample/sample-search-result'
import type { ExportSampleListQuery } from '_/model/sample/list-query'
import type SampleListQuery from '_/model/sample/list-query'
import type { NewSampleComment, ExistedSampleComment } from '_/model/sample/comment'
import type { BookedInSampleRequest, SampleCreateRequest } from '_/model/sample/booking/types'
import type Page from '_/model/page'

import type { FieldsDiff } from '_/utils/object'

import { downloadBlob } from '_/utils/blob'
import { convertFromServerFields, convertToServerFields } from './helper'
import type TimeService from '_/services/time-service'

import type ApiService from '../api-service'
import { shallowUpdate } from '_/utils/object'
import type SampleService from '../sample-service'
import type { ApprovalInfoProps } from '_/model/critical-change-reason/types'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import * as s from '_/model/context/electronic-signature-settings'
import type SampleDetails from '_/model/sample/sample-details'
import type ColumnEdit from '_/model/sample/list/column-edit'
import type { SampleTrend, SampleTrendsQuery } from '_/model/sample/sample-trends'
import type { Guid } from '_/model/guid'
import type { VacantExpectation, VacantExpectationQuery } from '_/model/sample/expectation'
import type { SearchBatchNumbers } from '_/model/sample/batch-number'
import type { ChangeCompromisedData } from '_/model/sample/reading/transaction'

function factory(api: ApiService, time: TimeService): SampleService {
    return Object.assign(
        api.resource(['samples'], s.EDITING_SAMPLES),
        {
            get,
            save,
            read,
            getSamplesWithTotal,
            createSample,
            getByBarcode,
            searchSamplesByBarcode,
            addComments,
            loadComments,
            getVacantExpectations,
            downloadReportByLimitBreachTrends,
            downloadReportByLimitBreaches,
            downloadOperatorReportByLimitBreaches,
            downloadReportByLocation,
            downloadReportByOperator,
            downloadReportByOrganism,
            downloadReportByOperatorFingerdabs,
            getPrintSamples,
            import: importSamples,
            confirmBookIn,
            verifyCFUCount,
            bulkConfirmBookIn,
            bulkVerifyCFUCount,
            editBookedInSample,
            changeCompromised,
            changeNullified,
            changeManualActionBreach,
            getSamplesStatistics,
            overridePhotoRequirement,
            importStatus,
            loadListColumns,
            saveListColumns,
            getSampleEditedInfo,
            getSampleTrends,
            attachImages,
            searchBatchNumbers
        }
    )

    function getSamplesWithTotal(query: SampleListQuery): Promise<SampleSearchResult> {
        return api.post<SampleSearchResult>(['samples', 'search'], query)
            .then(list => convertExposureDateTimeForListItems(time, list))
    }

    function getSamplesStatistics(query: SampleListQuery): Promise<SampleSearchStatistics> {
        return api.post<SampleSearchStatistics>(['samples', 'statistics'], query)
    }

    function get(id: string): Promise<SampleDetails> {
        return api.get(['samples', id])
            .then(sample => convertFromServerFields(time, sample) as SampleDetails)
    }

    function save(id: string, sample: Partial<Sample>) {
        const newFields = convertToServerFields(time, sample.fields)
            , newSample = { ...sample, fields: newFields ?? [] }

        return api.patch(['samples', id], newSample)
    }

    function read(id: string, reading: FieldsDiff<Omit<SampleReading, 'detachedImages'>> & ApprovalInfoProps & ChangeCompromisedData): Promise<void> {
        const data: any = { ...reading }

        if (reading.identifications) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (reading.identifications.rows !== undefined)
                data.identifications = reading.identifications.rows
            else
                delete data.identifications

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (reading.identifications.complete !== undefined)
                data.identificationComplete = reading.identifications.complete
        }

        if (reading.optionalIdentifications) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (reading.optionalIdentifications.rows !== undefined)
                data.optionalIdentifications = reading.optionalIdentifications.rows
            else
                delete data.optionalIdentifications

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (reading.optionalIdentifications.complete !== undefined)
                data.optionalIdentificationComplete = reading.optionalIdentifications.complete
        }

        return api.post(['samples', id, 'read'], data)
    }

    function createSample(sample: Partial<SampleCreateRequest>): Promise<Guid> {
        const newFields = convertToServerFields(time, sample.fields)
            , newSample = { ...sample, fields: newFields ?? [] }

        return api.post(['samples'], newSample)
    }

    function editBookedInSample(id: string, sample: Partial<BookedInSampleRequest>) {
        const newFields = convertToServerFields(time, sample.fields)
            , newSample = { ...sample, fields: newFields ?? [] }

        return api.patchWithReason(['samples', id, 'edit-booked-in'], s.EDITING_SAMPLES, newSample)
    }

    function getByBarcode(barcode: string, includeNullified: boolean): Promise<Sample> {
        return api.post(['samples', 'barcode'], { barcode, includeNullified })
            .then(sample => convertFromServerFields(time, sample) as Sample)
    }

    function searchSamplesByBarcode(barcode: string, includeNullified: boolean, matchExactly: boolean): Promise<SampleSearchByBarcodeResult> {
        return api.post<SampleSearchByBarcodeResult>(['samples', 'barcode', 'search'], { barcode, includeNullified, matchExactly })
            .then(result => {
                const exactMatch = result.exactMatch && convertFromServerFields(time, result.exactMatch) as Sample
                return shallowUpdate(result, {exactMatch})
            })
    }

    function addComments(id: string, comments: NewSampleComment[]) {
        return api.post(['samples', id, 'comments'], { comments })
    }

    function loadComments(id: string) {
        return api.get<ExistedSampleComment[]>(['samples', id, 'comments'])
    }

    function getVacantExpectations(query: VacantExpectationQuery): Promise<VacantExpectation[]> {
        return api.get<VacantExpectation[]>(['samples-expectations'], query)
    }

    function downloadReportByLimitBreachTrends(query: ExportSampleListQuery): Promise<void> {
        return api.getFileForLongQuery(['samples', 'export-report-by-location-limit-breach-trends'], query).then(
            _ => downloadBlob(_.blob, _.filename)
        )
    }

    function downloadReportByLimitBreaches(query: ExportSampleListQuery): Promise<void> {
        return api.getFileForLongQuery(['samples', 'export-report-by-location-limit-breaches'], query).then(
            _ => downloadBlob(_.blob, _.filename)
        )
    }

    function downloadOperatorReportByLimitBreaches(query: ExportSampleListQuery): Promise<void> {
        return api.getFileForLongQuery(['samples', 'export-report-by-operator-limit-breaches'], query).then(
            _ => downloadBlob(_.blob, _.filename)
        )
    }

    function downloadReportByLocation(query: ExportSampleListQuery): Promise<void> {
        return api.getFileForLongQuery(['samples', 'export-report-by-location'], query).then(
            _ => downloadBlob(_.blob, _.filename)
        )
    }

    function downloadReportByOperator(query: ExportSampleListQuery): Promise<void> {
        return api.getFileForLongQuery(['samples', 'export-report-by-operator'], query).then(
            _ => downloadBlob(_.blob, _.filename)
        )
    }

    function downloadReportByOrganism(query: SampleListQuery): Promise<void> {
        return api.getFileForLongQuery(['samples', 'export-report-by-organism'], query).then(
            _ => downloadBlob(_.blob, _.filename)
        )
    }

    function downloadReportByOperatorFingerdabs(query: ExportSampleListQuery): Promise<void> {
        return api.getFileForLongQuery(['samples', 'export-report-by-operator-fingerdabs'], query)
            .then(_ => downloadBlob(_.blob, _.filename))
    }

    function getPrintSamples(query: SampleListQuery): Promise<Sample[]> {
        return api.post<Sample[]>(['samples', 'print'], query)
            .then(list => convertExposureDateTimeForPrintItems(time, list))
    }

    function importSamples(files: File[]): Promise<void> {
        const formData = new FormData()
        files.forEach(_ => formData.append('samples[]', _, _.name))

        return api.post(['samples', 'import'], formData)
    }

    function bulkConfirmBookIn(sampleIds: string[]): Promise<void> {
        return api.post(['bulk-operations', 'confirm-book-in'], { sampleIds })
    }

    function bulkVerifyCFUCount(sampleIds: string[]): Promise<void> {
        return api.postWithSignature(['bulk-operations', 'confirm-cfu'], s.VERIFYING_CFU_COUNTS, { sampleIds })
    }

    function verifyCFUCount(id: string, approvalInfo: ApprovalInfo) {
        return api.postWithSignature(['samples', id, 'confirm-cfu'], s.VERIFYING_CFU_COUNTS, undefined, approvalInfo)
    }

    function confirmBookIn(id: string): Promise<void> {
        return api.post(['samples', id, 'confirm-book-in'])
    }

    function changeCompromised(id: string, compromised: boolean, checkBreach: boolean, approvalInfo: ApprovalInfo): Promise<void> {
        return api.postWithSignature(['samples', id, 'change-compromised'], s.EDITING_SAMPLES, {compromised, checkBreach}, approvalInfo)
    }

    function changeNullified(id: string, sample: Partial<Sample>, approvalInfo: ApprovalInfo): Promise<void> {
        return api.postWithSignature(['samples', id, 'change-nullified'], s.EDITING_SAMPLES, sample, approvalInfo)
    }

    function changeManualActionBreach(id: string, sample: {breached: boolean}, approvalInfo: ApprovalInfo): Promise<void> {
        return api.postWithSignature(['samples', id, 'change-manual-action-breach'], s.EDITING_SAMPLES, sample, approvalInfo)
    }

    function overridePhotoRequirement(id: string, override: boolean, approvalInfo: ApprovalInfo): Promise<void> {
        return api.postWithSignature(['samples', id, 'override-photo-requirement'], s.OVERRIDE_PHOTO_REQUIREMENT, {override}, approvalInfo)
    }

    function importStatus(): Promise<{inProgress: boolean}> {
        return api.get(['samples', 'import'])
    }

    function loadListColumns(): Promise<ColumnEdit> {
        return api.get(['samples', 'columns'])
    }

    function saveListColumns(columns: ColumnEdit): Promise<void> {
        return api.post(['samples', 'columns'], columns)
    }

    function getSampleEditedInfo(id: string): Promise<SampleEditedInfo[]> {
        return api.get(['samples', id, 'edited-info'])
    }

    function getSampleTrends(query: SampleTrendsQuery): Promise<Page<SampleTrend>> {
        return api.get(['samples', query.id, 'trends'], query.pagination)
    }

    function attachImages(sampleId: string, images: string[]) {
        return api.post(['samples', sampleId, 'attach-images'], { imageIds: images })
    }

    function searchBatchNumbers(query: SearchBatchNumbers): Promise<string[]> {
        return api.post<string[]>(['batch-number-search'], query)
    }
}

function convertExposureDateTimeForListItems(time: TimeService, list: SampleSearchResult) {
    const items = list.items.map(_ => convertFromServerFields(time, _ as any) as Sample)

    return shallowUpdate(list, { items })
}

function convertExposureDateTimeForPrintItems(time: TimeService, list: Sample[]) {
    const items = list.map(_ => convertFromServerFields(time, _ as any) as Sample)

    return items
}

export default factory
