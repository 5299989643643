import { useAction, useEffect, useState, useSelector, classnames } from '_/facade/react'
import type { FormRenderProps} from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'
import { actions as routerActions } from 'redux-router5'

import Button from '_/components/button'
import { LocalDateField, MultiSelectField, SelectField } from '_/components/form'
import { PaginatedFooter, SortableHead, SortableTh, Table } from '_/components/table'
import { useTimeService } from '_/components/time'
import { OPERATORS_IDS, SAMPLE_TYPE_ID } from '_/constants/custom-field-index'
import DEFAULT_DATE_RANGES, * as ranges from '_/constants/date-ranges'
import { FINGERDAB_PLATE, FINGERDAB_TWO_HANDS_PLATE } from '_/constants/plate-type'
import * as routes from '_/constants/routes'
import { ACTION_LIMIT } from '_/constants/sample-breach-type'
import { GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION, NO_GROWTH } from '_/constants/sample-status'
import { EXPOSURE_DATE_START } from '_/constants/search-date-type'
import type PaginationState from '_/model/pagination-state'
import type SampleSearchFields from '_/model/sample/search'
import type SortState from '_/model/sort-state'
import type TimeService from '_/services/time-service'
import { useFilter, useOperators, useSampleTypes } from '_/hooks/shared-hooks'
import { recalculateDynamicExposureDate } from '_/features/analysis/filter/helpers'
import TableActionButtons from '_/features/reports/table-action-buttons'
import type { OperatorPerformance, OperatorPerformanceFilter } from '_/model/fingerdab-overview-by-operator/types'
import { FINGERDAB_ACTION_LIMIT_BREACH } from '_/model/reports/locations-report/trend-type'
import { getInitialDateValues } from '_/model/reports/helpers'
import { formatActiveState } from '_/utils/format/common'
import { validateReportDate } from '_/model/reports/validate'
import { paramsFilter } from '_/model/filters/helpers'

import * as actions from '../actions'
import LimitBreachRate from './limit-breach-rate'
import { ANY_ID } from '_/constants/system-words'
import { noop } from '_/utils/function'
import { diffObject } from '_/utils/object'
import { ITEMS_PER_PAGE } from '_/constants'
import { useContextSwitchObserver } from '_/components/context-observer'
import type { DateTime } from '_/model/date-time'
import FormattedText from '_/features/text/formatted-text'
import { emptyTextNode } from '_/model/text/text'
import NoDataAvailable from '_/features/reports/no-data-available'

function OperatorPerformancePage() {
    const operators = useOperators()
        , routeParams = useSelector(_ => _.router.route?.params)
        , [highlightOperatorId, setHighlightOperatorId] = useState<string | undefined>(routeParams?.filter?.operatorId ?? undefined)
        , [filterState, handleSortChange, handlePaginationChange, handleFilterChange, resetFilterState] = useFilterState(setHighlightOperatorId)
        , downloadOperatorPerformance = useAction(actions.exportOperatorPerformance)
        , navigateToOperatorReport = useNavigateToSampleOperatorReport(filterState)
        , [sampleSearchParams, navigateToSampleList] = useSampleListNavigation(filterState)
        , timeService = useTimeService()
        , [operatorPerformance, showSpinner] = usePerformanceData(filterState, timeService)
        , isTableEmpty = operatorPerformance.items.length === 0

    useResetPerformanceData(resetFilterState)

    function getOperatorName(id: string) {
        const operator = operators.find(_ => _.id === id)
        return operator
            ? formatActiveState(operator.name, operator.isActive)
            : [emptyTextNode()]
    }

    return (
        <div className='d-flex h-100'>
            <Form
                onSubmit={noop}
                initialValues={filterState}
                validate={validateReportDate}
                render={form =>
                    <div className='d-flex flex-column bg-light side-filters operator-performance__filter overflow-y-auto h-100'>
                        <form onSubmit={form.handleSubmit}>
                            <FormSpy
                                onChange={_ => handleFilterChange(_.values, form)}
                                subscription={{ values: true }}
                            />
                            <div className='px-3'>
                                <SelectField
                                    name='exposureDateRange'
                                    id='exposureDateRange'
                                    entities={DEFAULT_DATE_RANGES}
                                    calcId={_ => _.id}
                                    calcName={_ => _.name}
                                    placeholder='Select'
                                >
                                    Exposure date range
                                </SelectField>
                                {form.values.exposureDateRange === ranges.CUSTOM &&
                                    <div>
                                        <LocalDateField id='exposureStartDateFrom' name='exposureStartDateFrom'>From exposure date</LocalDateField>
                                        <LocalDateField id='exposureStartDateTo' name='exposureStartDateTo' useDayEndTime>To exposure date</LocalDateField>
                                    </div>
                                }
                                <MultiSelectField
                                    name='operatorIds'
                                    id='operatorIds'
                                    entities={operators}
                                    calcId={_ => _.id}
                                    calcName={_ => formatActiveState(_.name, _.isActive)}
                                    placeholder='Select'
                                >
                                    Operator
                                </MultiSelectField>
                            </div>
                        </form>
                    </div>
                }
            />
            <div className='d-flex flex-fill align-items-start overflow-y-auto'>
                <Table>
                    <SortableHead onChange={handleSortChange} sticky className='operator-performance-table'>
                        <th>#</th>
                        <SortableTh name='operatorName'>Operator</SortableTh>
                        <SortableTh name='actionLimitBreaches'>Fingerdab action limit breaches</SortableTh>
                        <SortableTh name='fingerdabs'>Total fingerdabs</SortableTh>
                        <SortableTh name='actionLimitBreachRate'>Fingerdab action limit breach rate</SortableTh>
                    </SortableHead>
                    <tbody>
                        {operatorPerformance.items.length > 0 && !showSpinner
                            ? operatorPerformance.items.map((_, i) =>
                                <tr key={_.operatorId} className={classnames({'table-active': highlightOperatorId === _.operatorId})}>
                                    <td className='align-middle'>{(filterState.start ?? 0) + i + 1}</td>
                                    <td className='align-middle'>
                                        <Button className='btn-link text-start px-0' onClick={() => navigateToOperatorReport(_.operatorId)}>
                                            <FormattedText text={getOperatorName(_.operatorId)} />
                                        </Button>
                                    </td>
                                    <td className='align-middle'>
                                        {_.fingerdabActionLimitBreaches
                                            ? <Button className='btn-link px-0' onClick={() => navigateToSampleList(_.operatorId, [ACTION_LIMIT])}>
                                                {_.fingerdabActionLimitBreaches}
                                            </Button>
                                            : <div>{_.fingerdabActionLimitBreaches}</div>
                                        }
                                    </td>
                                    <td className='align-middle'>
                                        {_.totalFingerdabs
                                            ? <Button className='btn-link px-0' onClick={() => navigateToSampleList(_.operatorId)}>
                                                {_.totalFingerdabs}
                                            </Button>
                                            : <div>{_.totalFingerdabs}</div>
                                        }
                                    </td>
                                    <td className='align-middle'>
                                        <LimitBreachRate
                                            kpiBlockInfo={_.trend}
                                            trendType={FINGERDAB_ACTION_LIMIT_BREACH}
                                            limitBreachRate={_.fingerdabActionLimitBreachRate}
                                        />
                                    </td>
                                </tr>
                            )
                            : <tr>
                                <td className='text-center' colSpan={5}>
                                    <NoDataAvailable showSpinner={showSpinner}>
                                        <span className='text-center text-muted text-uppercase'>No data available</span>
                                    </NoDataAvailable>
                                </td>
                            </tr>
                        }
                    </tbody>
                    {!isTableEmpty && !showSpinner &&
                        <PaginatedFooter
                            colSpan={5}
                            state={{start: filterState.start ?? 0, count: filterState.count ?? 20}}
                            onChange={handlePaginationChange}
                            totalCount={operatorPerformance.count}
                            actionButtons={
                                <TableActionButtons
                                    routeParams={sampleSearchParams}
                                    onExport={() => {
                                        const exposureStartDateTo = calculateExposureStartDateTo(filterState.exposureStartDateTo!, timeService)
                                        downloadOperatorPerformance({...filterState, exposureStartDateTo})
                                    }}
                                    exportButtonDisabled={isTableEmpty}
                                    disabledGoToSamplesButton={isTableEmpty}
                                />
                            }
                        />
                    }
                </Table>
            </div>
        </div>
    )
}

export default OperatorPerformancePage

function initialFilter(timeService: TimeService): OperatorPerformanceFilter {
    return {
        operatorIds: undefined,
        sort: 'actionLimitBreaches:desc',
        start: 0,
        count: ITEMS_PER_PAGE,
        ...getInitialDateValues(timeService),
    }
}

function useFilterState(setOperatorId: ((_: string | undefined) => void)) {
    const timeService = useTimeService()
        , [filterState, setFilterState] = useFilter<OperatorPerformanceFilter>('operator-performance', _ => ({ ...initialFilter(timeService), ..._ }))

    function handleFilterChange(filter: OperatorPerformanceFilter, form: FormRenderProps) {
        const newDateRange = recalculateDynamicExposureDate(filter, timeService)
            , newFilter = {
                ...filter,
                exposureStartDateFrom: newDateRange.exposureStartDateFrom,
                exposureStartDateTo: newDateRange.exposureStartDateTo,
                start: 0,
            }
            , isValidFilter = Object.keys(validateReportDate(newFilter)).length === 0

        if (!isValidFilter)
            form.form.blur('exposureStartDateTo')

        if (!diffObject(filterState, filter) || !isValidFilter)
            return

        setFilterState(newFilter)

        if (filterState.operatorIds !== filter.operatorIds)
            setOperatorId(undefined)
    }

    function handleSortChange(sortState: SortState) {
        setFilterState({...filterState, ...sortState})
    }

    function handlePaginationChange(pagination: PaginationState) {
        setFilterState({...filterState, ...pagination})
    }

    return [filterState, handleSortChange, handlePaginationChange, handleFilterChange, setFilterState] as const
}

function calculateExposureStartDateTo(exposureStartDateTo: DateTime, timeService: TimeService) {
    return timeService.ctzDayStart(timeService.addCtzDays(exposureStartDateTo, 1))
}

function usePerformanceData(filterState: OperatorPerformanceFilter, timeService: TimeService) {
    const loadData = useAction(actions.loadOperatorPerformance)
        , [operatorPerformance, setOperatorPerformance] = useState<OperatorPerformance>({ items: [], count: 0 })
        , [showSpinner, setShowSpinner] = useState(false)

    useEffect(
        () => {
            setShowSpinner(true)
            loadData({...filterState, exposureStartDateTo: calculateExposureStartDateTo(filterState.exposureStartDateTo!, timeService)})
                .then(operatorReportResult => {
                    setOperatorPerformance(operatorReportResult)
                })
                .finally(() => setShowSpinner(false))
        },
        [filterState, timeService, loadData, setOperatorPerformance]
    )

    return [operatorPerformance, showSpinner] as const
}

function useResetPerformanceData(onFilterChange: (_: OperatorPerformanceFilter) => void) {
    const contextSwitch = useContextSwitchObserver()
        , timeService = useTimeService()

    useEffect(
        () => {
            if (contextSwitch === 0)
                return

            onFilterChange({
                operatorIds: undefined,
                sort: 'actionLimitBreaches:desc',
                start: 0,
                count: ITEMS_PER_PAGE,
                ...getInitialDateValues(timeService),
            })
        },
        [contextSwitch, onFilterChange, timeService]
    )
}

function useNavigateToSampleOperatorReport(filterState: OperatorPerformanceFilter) {
    const navigateToAction = useAction(routerActions.navigateTo)

    return (operatorId: string) => navigateToAction(
        routes.OPERATORS_REPORT, paramsFilter({
            ...filterState,
            operatorId
        }))
}

function useSampleListNavigation(filterState: OperatorPerformanceFilter) {
    const navigateToAction = useAction(routerActions.navigateTo)
        , sampleTypes = useSampleTypes()
            .filter(_ => _.sampleType == FINGERDAB_PLATE || _.sampleType == FINGERDAB_TWO_HANDS_PLATE)
            .map(_ => _.id)
        , sampleTypeValue = sampleTypes.length === 0 ? undefined : sampleTypes
        , sampleSearchParams: SampleSearchFields & SortState = {
            fields: [
                { index: OPERATORS_IDS, value: filterState.operatorIds ?? [ANY_ID] },
                { index: SAMPLE_TYPE_ID, value: sampleTypeValue }
            ],
            dateToFilter: EXPOSURE_DATE_START,
            dateFrom: filterState.exposureStartDateFrom,
            dateTo: filterState.exposureStartDateTo,
            statuses: [NO_GROWTH, GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION],
            includeCompromised: false,
            sort: 'exposureStartTime:desc',
        }

    function navigateToSampleList(operatorId: string, sampleBreachTypes?: number[]) {
        const fields = [
            { index: OPERATORS_IDS, value: [operatorId] },
            { index: SAMPLE_TYPE_ID, value: sampleTypeValue }
        ]

        navigateToAction(routes.SAMPLES, paramsFilter({...sampleSearchParams, sampleBreachTypes, fields }))
    }

    return [sampleSearchParams, navigateToSampleList] as const
}
