import { useCallback, useAction, useEffect, useState, useSelector } from '_/facade/react'

import Button from '_/components/button'
import { useTimeService } from '_/components/time'

import * as routes from '_/constants/routes'
import * as invitationStates from '_/constants/context-invitation-state'

import { navigateTo } from '_/features/routing/actions'

import type { ContextInvite } from '_/model/context/invite/types'
import * as actions from '../actions'
import ContextInviteMessage from './context-invite-message'

interface Props {
    assign(token: string): void
}

type State = { tag: 'info', info: ContextInvite } | { tag: 'loading' } | { tag: 'invitationNotFound' }

function ContextConfirm(props: Props) {
    const timeService = useTimeService()
        , [state, setState] = useState<State>({tag: 'loading'})
        , [invitationAccepting, setInvitationAccepting] = useState(false)
        , route = useSelector(_ => _.router.route)!

        , navigateToAction = useAction(navigateTo)
        , getContextInvite = useAction(actions.loadContextInvite)
        , rejectContextInvitation = useAction(actions.rejectContextInvite)
        , load = useCallback(
            () => {
                getContextInvite(route.params.token)
                    .then(
                        info => info.userExists ? setState({ tag: 'info', info }) : navigateToAction(routes.USERS_SIGN_UP, { token: route.params.token }),
                        () => setState({ tag: 'invitationNotFound' })
                    )
            },
            [route, getContextInvite, navigateToAction]
        )

    useEffect(
        () => {
            load()
        },
        [load]
    )

    function handleReject() {
        rejectContextInvitation(route.params.token)
            .then(load)
    }

    function handleAcceptInvitation() {
        setInvitationAccepting(true)
        props.assign(route.params.token)
    }

    if (state.tag === 'loading')
        return (
            <Container>
                <i className='preview-spinner material-icons md-48'>sync</i>
            </Container>
        )

    if (state.tag === 'invitationNotFound')
        return (
            <Container>
                <ContextInviteMessage hasExpiredOrNotFound />
            </Container>
        )

    const info = state.info
        , buttonDisabled = info.invitationState !== invitationStates.PENDING || info.hasExpired
        , processedAt = timeService.formatCtzDate(info.processedAt)
        , roles = info.roles.map(_ => _.name)

    return (
        <Container>
            <p>
                You have been invited to join the <b>{info.contextDescription}</b> with the role of <b>{roles.join(', ')}</b>.<br/>
                Do you want to accept this invitation?
            </p>

            <Button
                className='btn-primary fw-bold me-3 mt-3 px-4'
                onClick={handleAcceptInvitation}
                disabled={buttonDisabled || invitationAccepting}
                testId='accept-invitation'
            >
                Accept invitation
            </Button>
            <Button
                className='btn-light text-primary fw-bold mt-3 px-4'
                onClick={handleReject}
                disabled={buttonDisabled}
            >
                Reject invitation
            </Button>

            <ContextInviteMessage
                invitationState={info.invitationState}
                hasExpiredOrNotFound={info.hasExpired}
                processedAt={processedAt}
            />
        </Container>
    )
}

export default ContextConfirm

interface ContainerProps {
    children: React.ReactNode
}

const Container = (props: ContainerProps) =>
    <div className='d-flex justify-content-center'>
        <div className='auth-form text-start'>
            <div className='auth-form-header-text text-nowrap mb-3'>Invitation to join a new site</div>
            {props.children}
        </div>
    </div>
