import type { MissedMonitoringReportView } from '_/model/reports/missed-monitoring-report/types'
import type { ReportRequest } from '_/model/reports/types'
import { downloadBlob } from '_/utils/blob'

import type ApiService from '../api-service'
import type MissedMonitoringReportService from '../missed-monitoring-report-service'

function factory(api: ApiService): MissedMonitoringReportService {
    return {
        getMissedMonitoringReportData,
        downloadMissedMonitoringTable,
    }

    function getMissedMonitoringReportData(request: ReportRequest): Promise<MissedMonitoringReportView> {
        return api.get<MissedMonitoringReportView>(['missed-monitoring-report'], request)
    }

    function downloadMissedMonitoringTable(request: ReportRequest): Promise<void> {
        return api.getFileForLongQuery(['export-missed-monitoring-table'], request)
            .then(_ => downloadBlob(_.blob, _.filename))
    }
}

export default factory
