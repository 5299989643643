import type User from '_/model/users/types'
import * as context from '_/constants/context-level'
import { compare } from '_/utils/string'
import type { UserMembership, UserContext } from '_/model/auth/types'

function convertToUserEdit(user: User) {
    return {
        name: user.name,
        notConfirmedEmail: user.email,
    }
}

function isSystemAdmin(user: UserContext) {
    return user.membership.type === context.SYSTEM && user.membership.role.isAdmin
}

function isSiteAdmin(user: UserContext | undefined) {
    return user?.membership.type === context.SITE && user.membership.role.isAdmin
}

function sortMemberships(memberships: UserMembership[]): UserMembership[] {
    return memberships.slice()
        .sort((one, two) => {
            if (one.type === context.SYSTEM && two.type !== context.SYSTEM)
                return -1

            if (two.type === context.SYSTEM && one.type !== context.SYSTEM)
                return 1

            return compare(one.description, two.description)
        })
}

export {
    convertToUserEdit,
    sortMemberships,
    isSystemAdmin,
    isSiteAdmin,
}
