import type { NotificationQuery, NotificationInfo } from '_/model/notification/types'
import type Notification from '_/model/notification/types'
import type ApiService from '../api-service'
import type NotificationService from '../notification-service'

function factory(api: ApiService): NotificationService {
    return {
        getNotifications,
        markAsRead,
        markAsUnread,
        bulkMarkAsRead,
        getNotificationInfo,
    }

    function getNotifications(query: NotificationQuery): Promise<Notification[]> {
        return api.get(['notifications'], query)
    }

    function markAsRead(id: string): Promise<void> {
        return api.post(['notifications', id, 'mark-as-read'])
    }

    function markAsUnread(id: string): Promise<void> {
        return api.post(['notifications', id, 'mark-as-unread'])
    }

    function bulkMarkAsRead(ids: string[]): Promise<void> {
        return api.post(['notifications', 'mark-as-read'], { ids })
    }

    function getNotificationInfo(): Promise<NotificationInfo> {
        return api.get(['notifications', 'info'])
    }
}

export default factory
