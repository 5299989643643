import type { ValidationResult} from '_/utils/form/validate'
import { isEmpty, maxLength } from '_/utils/form/validate'
import type { UserSignUp } from '_/model/auth/types'

export default function validate(entity: Partial<UserSignUp>) {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 128)(entity.name)
        , email = validateEmail(entity.email) || isEmpty('Email')(entity.email)
        , emailLength = maxLength('Email', 128)(entity.email)
        , password = validatePassword(entity.password)
        , confirmPassword = isEqual('Passwords')(entity.password, entity.confirmPassword)
        , result: ValidationResult<UserSignUp> = {}

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (email)
        result.email = email

    if (emailLength)
        result.email = emailLength
    if (password)
        result.password = password

    if (confirmPassword)
        result.confirmPassword = confirmPassword

    return result
}

export function validateEmail(email: any) {
    const regex = /\S+@\S+\.\S+/
    return regex.test(email)
        ? false
        : `Invalid email address`
}

export function validatePassword(password: any) {
    return password && password.trim().length >= 8
        ? false
        : `Should be greater than or equal to 8 characters`
}

export function isEqual(name: string) {
    return (valueA: any, valueB: any) => valueA === valueB
                                ? false
                                : `${name} do not match`
}
