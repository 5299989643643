import { useSelector } from '_/facade/react'

import * as routes from '_/constants/routes'

import { isBaseRoute } from '_/utils/router/common'

import MonitoringOverview from './monitoring-overview/monitoring-overview'
import MonitoringGroups from './monitoring-groups/monitoring-groups'
import MonthlyScheduler from './monthly-scheduler/monthly-scheduler'
import DayScheduler from './day-scheduler/day-scheduler'

function SchedulingContainer() {
    const route = useSelector(_ => _.router.route!)

    if (isBaseRoute(routes.SCHEDULING_MONITORING_GROUPS, route)) {
        return (
            <InternalContainer>
                <MonitoringGroups />
            </InternalContainer>
        )
    }

    if (isBaseRoute(routes.SCHEDULING_DAY_SCHEDULER, route)) {
        return (
            <InternalContainer>
                <DayScheduler />
            </InternalContainer>
        )
    }

    switch (route.name) {
        case routes.SCHEDULING_MONTHLY_SCHEDULER:
            return (
                <InternalContainer>
                    <MonthlyScheduler />
                </InternalContainer>
            )

        case routes.SCHEDULING_MONITORING_OVERVIEW:
        case routes.SCHEDULING_MONITORING_OVERVIEW_TRAIL:
        case routes.SCHEDULING_MONITORING_OVERVIEW_SAMPLE_TRAIL:
            return <MonitoringOverview />

        default:
            return (
                <InternalContainer>
                    <MonthlyScheduler />
                </InternalContainer>
            )

    }
}

export default SchedulingContainer

interface Props {
    children: React.ReactNode
}

function InternalContainer(props: Props) {
    return (
        <div className='container-fluid main-block'>
            <div className='row justify-content-center h-100'>
                <div className='col-9 h-100 d-flex flex-column'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}
