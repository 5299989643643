const CREATED_DATE = 0
    , EXPOSURE_DATE_START = 1
    , EXPOSURE_DATE_END = 2
    , SAMPLE_BREACH_DATE = 5
    , FIRST_READ_DATE = 6

const SEARCH_DATE_TYPE = [
    {
        id: CREATED_DATE,
        name: 'Date booked in',
    },
    {
        id: EXPOSURE_DATE_START,
        name: 'Exposure date start',
    },
    {
        id: EXPOSURE_DATE_END,
        name: 'Exposure date end',
    },
    {
        id: SAMPLE_BREACH_DATE,
        name: 'Limit breach date',
    },
    {
        id: FIRST_READ_DATE,
        name: 'First read date',
    },
]

type SEARCH_DATE_TYPE = typeof SEARCH_DATE_TYPE

export {
    SEARCH_DATE_TYPE as default,
    CREATED_DATE,
    EXPOSURE_DATE_START,
    EXPOSURE_DATE_END,
    SAMPLE_BREACH_DATE,
}
