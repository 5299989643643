import { Form, Field } from 'react-final-form'

import { classnames, useState, useAction } from '_/facade/react'

import { showFieldError } from '_/components/form/helpers'
import { submitDisabled, Submit } from '_/components/form'
import Error from '_/components/form/field-error'

import Button from '_/components/button'
import FormChangesObserver from '_/components/form/form-changes-observer'

import type { GradeEdit } from '_/model/predefined-lists/grade/grade'
import type Grade from '_/model/predefined-lists/grade/grade'

import validate from '_/model/predefined-lists/grade/grade-validate'

import * as actions from './actions'
import * as toastActions from '_/features/toasts/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import { CHANGES_SAVED } from '_/features/samples/messages'

interface Props {
    grade: Grade
    grades: Grade[]
    hasNoPermission: boolean
    testId?: string
}

function GradeForm(props: Props) {
    const [editMode, setEditMode] = useState(false)
        , handleSave = useSubmit(props.grade, setEditMode)

    return (
        <div className='px-2 mt-2'>
            {editMode
                ? <Form<GradeEdit>
                    initialValues={props.grade}
                    onSubmit={handleSave}
                    validate={_ => validate(_, props.grades)}
                    render={form =>
                        <form onSubmit={form.handleSubmit}>
                            <FormChangesObserver target={props.grade.id} form={form} />
                            <div className='d-flex align-items-start'>
                                <div className='flex-fill'>
                                    <Field name='name' render={_ =>
                                        <>
                                            <input
                                                type='text'
                                                className={classnames('form-control me-1', { 'is-invalid': showFieldError(_.meta) })}
                                                autoComplete='off'
                                                {..._.input}
                                                data-testid={`${props.testId}-field-name`}
                                            />
                                            <Error field={_} />
                                        </>
                                    } />
                                </div>
                                <Submit disabled={submitDisabled(form)} className='ms-1' testId={`${props.testId}-save`}>Save</Submit>
                                <Button
                                    className='btn-secondary ms-1'
                                    onClick={() => setEditMode(false)}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    }
                />
                : <div>
                    <span className='fw-bold' data-testid='grade-name'>{props.grade.name}</span>
                    <Button onClick={() => setEditMode(true)} hasNoPermissions={props.hasNoPermission} className='float-end p-0 border-0' testId={`${props.testId}-edit`}>
                        <i className='sample-edit__edit-icon material-icons md-18 text-primary'>create</i>
                    </Button>
                    <hr className='grade-border-top mt-0'/>
                </div>
            }
        </div>
    )
}

export default GradeForm

function useSubmit(oldGrade: Grade, setEditMode: (_: boolean) => void) {
    const saveChanges = useAction(actions.saveGrade)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , addSuccess = useAction(toastActions.addSuccess)

    function handleSubmit(grade: GradeEdit) {
        saveChanges({ id: oldGrade.id, oldGrade, newGrade: grade })
            .then(() => setEditMode(false))
            .then(() => addSuccess(CHANGES_SAVED))
            .then(() => hasUnsavedChanges(false, oldGrade.id))
    }

    return handleSubmit
}
