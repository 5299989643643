import type { ValidationResult } from '_/utils/form/validate'
import type { SetPassword } from '_/model/auth/types'
import { isEqual, validatePassword } from '_/model/auth/sign-up/validate'

export default function validate(entity: Partial<SetPassword>) {
    const password = validatePassword(entity.password)
        , confirmPassword = isEqual('Passwords')(entity.password, entity.confirmPassword)
        , result: ValidationResult<SetPassword> = {}

    if (password)
        result.password = password

    if (confirmPassword)
        result.confirmPassword = confirmPassword

    return result
}
