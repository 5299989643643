import type { GradeEdit } from '_/model/predefined-lists/grade/grade'
import type Grade from '_/model/predefined-lists/grade/grade'

import { searchDuplicateName } from '_/model/common/helpers'

import type { ValidationResult} from '_/utils/form/validate'
import { isEmpty, maxLength, allowedName } from '_/utils/form/validate'

export default function validate(entity: Partial<GradeEdit>, grades: Grade[]) {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 50)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , duplicateName = entity.name && searchDuplicateName(entity, grades)
        , result: ValidationResult<GradeEdit> = {}

    if (name)
        result.name = name

    if (notAllowedNames)
        result.name = notAllowedNames

    if (nameLength)
        result.name = nameLength

    if (duplicateName)
        result.name = `Grade with name '${entity.name!.trim()}' already exists`

    return result
}
