import { useGrades } from '_/hooks/shared-hooks'
import type { OrganismInsight } from '_/model/insight/types'
import InsightsWidget from './insights-widget'

interface Props {
    insight: OrganismInsight
    dismiss: () => void
}

function OrganismTrend(props: Props) {
    const data = props.insight.data
        , grades = useGrades()
        , grade = grades.find(_ => _.id === props.insight.data.gradeId)

    return (
        <InsightsWidget
            dismiss={props.dismiss}
            header={`Did you know that there were ${data.breaches} breaches involving organism ${data.organismName} in your Grade ${grade?.name} environment, over the last ${data.days} days?`}
        >
            {/* Organism report is not ready, all span elements bellow needs to be changed to Button */}
            <span className='btn-link d-block text-start cursor-pointer'>See an organism report for {data.organismName}</span>
            <span className='btn-link d-block text-start cursor-pointer'>See which operators were involved in the breaches related to organism {data.organismName}</span>
            <span className='btn-link d-block text-start cursor-pointer'>See at which locations breaches occurred</span>
        </InsightsWidget>
    )
}

export default OrganismTrend
