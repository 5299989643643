import { useSelector } from '_/facade/react'

import { useTimeService } from '_/components/time'

import type { MissedMonitoringReport } from '_/model/reports/missed-monitoring-report/types'
import type { OperatorsReport } from '_/model/reports/operators-report/types'
import type { LocationsReport } from '_/model/reports/locations-report/types'
import { computeAuthorInfo } from '_/features/analysis/ui/helpers'
import { formatExposureDateRange } from '_/model/reports/helpers'


interface Props {
    report: LocationsReport | OperatorsReport | MissedMonitoringReport
    entityName?: string
}

function TableFooter(props: Props) {
    const timeService = useTimeService()
        , user = useSelector(_ => _.auth.user)

    return (
        <div className='my-2' data-testid='print-mode-filters-section'>
            <div className='fw-bold'>Filters</div>
            <div style={{lineHeight: 1.2}}>
                <div>{props.entityName}</div>
                {formatExposureDateRange(props.report, timeService)}
            </div>
            <div className='mt-2'>{computeAuthorInfo(timeService, user)}</div>
        </div>
    )
}

export default TableFooter
