import Button from '_/components/button'
import { Link } from '_/utils/router'
import { paramsFilter } from '_/model/filters/helpers'
import * as routes from '_/constants/routes'

function CreateRecordBtn(props: {onCreate: () => void}) {
    return (
        <Button
            className='p-0 mt-n1 mx-1 btn-link'
            onClick={props.onCreate}
        >
            Create record
        </Button>
    )
}

function PossibleMatchesLink(props: {barcode: string, text?: string}) {
    return (
        <Link routeName={routes.PLATES} routeParams={paramsFilter({ barcode: props.barcode })} className='ms-1'>
            {props.text ?? 'Check possible matches'}
        </Link>
    )
}

function GoToRecordBtn(props: {onLoad: () => void, text?: string}) {
    return (
        <Button
            className='p-0 mt-n1 mx-1 btn-link'
            onClick={props.onLoad}
        >
            {props.text ?? 'Go to record'}
        </Button>
    )
}


export {
    CreateRecordBtn,
    PossibleMatchesLink,
    GoToRecordBtn,
}
