import type { ValidationResult} from '_/utils/form/validate'
import { checkDateRangeValidity, required } from '_/utils/form/validate'
import { isDefaultCustomField } from '_/model/predefined-lists/custom-field/custom-field'
import { validateCustomFieldNumberRange } from '_/features/samples/filter/validate'
import { findFieldValuePos } from '_/features/samples/helpers'

import type { AnalysisFilter } from '_/model/analysis/filter/types'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import { CUSTOM } from '_/constants/date-ranges'
import type TimeService from '_/services/time-service'
import { PARTICLE_COUNTS } from '_/model/analysis/chart-type'

function validate(entity: Partial<AnalysisFilter>, customFields: CustomField[], timeService: TimeService) {
    const result: ValidationResult<AnalysisFilter> = {}
        , exposureDateRange = required('Exposure date range')(entity.exposureDateRange)
        , aggregationPeriod = entity.chartType !== PARTICLE_COUNTS
            ? required('Aggregation period')(entity.aggregationPeriod)
            : undefined
        , chartType = required('Graph type')(entity.chartType)
        , values = entity.fields || []
        , fields: Partial<FieldValues>[] = []
        , customValuesOnly = values.filter(f => !isDefaultCustomField(f))
        , dateFromTo = validateCustomDateRange(entity, timeService)

    customValuesOnly.forEach(customValue => {
        const number = validateCustomFieldNumberRange(customValue, customFields)
        if (number) {
            fields[findFieldValuePos(values, customValue.index)] = { value: number }
        }
    })

    const overlaidFields = values.map((_, index) => fields[index])

    if (exposureDateRange)
        result.exposureDateRange = exposureDateRange

    if (aggregationPeriod)
        result.aggregationPeriod = aggregationPeriod

    if (chartType)
        result.chartType = chartType

    return { ...result, fields: overlaidFields, ...dateFromTo }
}

type ValidationCustomDateRange = Pick<ValidationResult<AnalysisFilter>, 'exposureStartDateFrom' | 'exposureStartDateTo'>

function validateCustomDateRange(entity: Partial<AnalysisFilter>, timeService: TimeService): ValidationCustomDateRange {
    const exposureStartDateFrom = entity.exposureDateRange === CUSTOM ? required('From exposure date')(entity.exposureStartDateFrom) || undefined : undefined
        , dateToRequired = entity.exposureDateRange === CUSTOM ? required('To exposure date')(entity.exposureStartDateTo) : undefined
        , rangeValidity = checkDateRangeValidity(entity.exposureStartDateFrom, entity.exposureStartDateTo)
        , particleCountsRangeValidity = validateParticleCountsDateRange(entity, timeService)
        , exposureStartDateTo = dateToRequired || rangeValidity || particleCountsRangeValidity || undefined

    return { exposureStartDateFrom, exposureStartDateTo }
}

function validateParticleCountsDateRange(entity: Partial<AnalysisFilter>, timeService: TimeService) {
    if (!entity.exposureStartDateFrom || !entity.exposureStartDateTo)
        return

    const monthsDifference = timeService.monthsDifference(entity.exposureStartDateFrom, entity.exposureStartDateTo)
        , dayFromLessThanDayTo = timeService.ctzTimeStruct(entity.exposureStartDateFrom).day < timeService.ctzTimeStruct(entity.exposureStartDateTo).day

    return entity.chartType === PARTICLE_COUNTS
            && (monthsDifference === 3 && dayFromLessThanDayTo || monthsDifference > 3)
                ? 'Maximum date range is 3 months'
                : undefined
}

export {
    validate,
    validateParticleCountsDateRange,
}
