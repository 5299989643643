import { useAction, useState, useCallback, useEffect } from '_/facade/react'

import * as toastActions from '_/features/toasts/actions'
import * as actions from '../actions'

interface Props {
    resetList: () => void
}
function ImportButton(props: Props) {
    const [isImportInProgress, setIsImportInProgress] = useState(true)
        , importSamples = useImportSamples(setIsImportInProgress, props.resetList)

    useImportStatus(setIsImportInProgress)

    return (
        <div>
            {isImportInProgress
                ? <span className='cursor-default text-secondary px-3' title='There is already an import running for this site'>
                    Import
                </span>
                : <label
                    htmlFor='import-samples'
                    className='me-1 mb-0 cursor-pointer btn btn-link'
                >
                    Import
                </label>
            }
            <input
                onChange={importSamples}
                accept='.json'
                value=''
                name='myFile'
                type='file'
                className='d-none'
                id='import-samples'
                disabled={isImportInProgress}
                data-testid='import-samples'
            />
        </div>
    )
}

export default ImportButton

function useImportStatus(setIsImportInProgress: (_: boolean) => void) {
    const importStatus = useAction(actions.importStatus)
        , load = useCallback(
            () => {
                importStatus()
                    .then(_ => setIsImportInProgress(_.inProgress))
            },
            [importStatus, setIsImportInProgress]
        )

    useEffect(load, [load])

    useEffect(() => {
        const interval = setInterval(load, 5000)
        return () => clearInterval(interval)
    })
}

function useImportSamples(setIsImportInProgress: (_: boolean) => void, reset: () => void) {
    const importSamples = useAction(actions.importSamples)
        , addSuccess = useAction(toastActions.addSuccess)

    function handleImportSamples(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files)
            return

        setIsImportInProgress(true)

        importSamples(Array.from(e.target.files))
            .then(reset)
            .then(() => addSuccess('Import successfully completed'))
            .finally(() => setIsImportInProgress(false))
    }

    return handleImportSamples
}
