import type { AuditTrail } from '_/model/audit-trail/types'
import type { MonitoringGroup, MonitoringGroupCreate, MonitoringGroupEdit } from '_/model/scheduling/monitoring-groups/types'
import { actionFactory } from '_/utils/redux'

const action = actionFactory('app.monitoring-groups')

export const loadMonitoringGroupList = action<void, Promise<MonitoringGroup[]>>('loadMonitoringGroupList')
    , loadMonitoringGroupTrail = action<string, Promise<AuditTrail>>('loadMonitoringGroupTrail')
    , loadMonitoringGroup = action<string, Promise<MonitoringGroup>>('loadMonitoringGroup')
    , createMonitoringGroup = action<MonitoringGroupCreate, Promise<void>>('createMonitoringGroup')
    , editMonitoringGroup = action<{ id: string, oldMonitoringGroup: MonitoringGroupEdit, newMonitoringGroup: MonitoringGroupEdit }, Promise<void>>('editMonitoringGroup')
    , removeMonitoringGroup = action<string, Promise<void>>('removeMonitoringGroup')
    , loadAdHocGroupList = action<void, Promise<MonitoringGroup[]>>('loadAdHocGroupList')
