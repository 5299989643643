import { diffObject } from '_/utils/object'
import type Api from '_/services/predefined-lists-service'
import type { ObjectionableOrganismEdit } from './types'

function updateTransaction(
    id: string,
    current: ObjectionableOrganismEdit,
    next: ObjectionableOrganismEdit,
    api: Api,
): Promise<void> {
    const diff = diffObject(current, next)
        , identificationDiff = diff?.identification
            ? { ...diff, identification: { ...diff.identification, type: next.identification.type } } as ObjectionableOrganismEdit
            : diff

    return identificationDiff ? api.objectionableOrganism.save(id, identificationDiff) : Promise.resolve()
}

export {
    updateTransaction,
}
