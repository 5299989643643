import * as tree from '_/utils/tree'
import type { KpiData, SessionBreaches } from '_/model/reports/types'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import type { LocationsReport } from '_/model/reports/locations-report/types'
import type { LocationsReportSearchFields } from '_/model/sample/search'
import type TimeService from '_/services/time-service'

import * as fieldIndex from '_/constants/custom-field-index'
import { sampleListRouteParams } from '../helpers'

function formatLocationName(locations: tree.Tree<ExposureLocation>, location: ExposureLocation) {
    if (locations.ids.length === 0)
        return location.name

    const pathName = tree.path(locations, location)
        .slice(1)
        .map(_ => _.name)
        .join(' / ')

    if (!pathName)
        return location.name

    return pathName
}

function calculateKpiBlockRate(block: KpiData) {
    const samplesWithCfuCount = block.currentPeriodInfo.samplesWithCfuCount
        , readSamples = block.currentPeriodInfo.readSamplesCount
        , rate = samplesWithCfuCount / readSamples

    return Math.round(rate * 100)
}

function calculateTrend(block: KpiData | undefined) {
    if (!block?.previousPeriodInfo || block.previousPeriodInfo.readSamplesCount === 0)
        return undefined

    const currentPeriod = block.currentPeriodInfo.readSamplesCount === 0
            ? 0
            : block.currentPeriodInfo.samplesWithCfuCount / block.currentPeriodInfo.readSamplesCount
        , lastPeriod = block.previousPeriodInfo.samplesWithCfuCount / block.previousPeriodInfo.readSamplesCount

    if (currentPeriod > lastPeriod)
        return 1

    if (currentPeriod < lastPeriod)
        return -1

    return 0
}

function calculateMaxBreach(sessionBreaches: SessionBreaches[]) {
    const sessionMaxBreaches = sessionBreaches.map(_ =>
        _.dayBreaches.reduce((acc, breaches) => Math.max(breaches.averageLimitCount, acc), 0)
    )

    return Math.max(...sessionMaxBreaches)
}

function dayBreaches(sessionsBreaches: SessionBreaches[], dayId: number, sessionId: string) {
    const sessionBreaches = sessionsBreaches.find(_ => _.sessionId === sessionId)
    return sessionBreaches?.dayBreaches.find(_ => _.dayOfWeek === dayId)
}

function calculateColor(maxBreach: number, breach: number) {
    return `HSL(211, 100%, ${100 - (50 * (breach / (maxBreach || 1)))}%)`
}

function getSampleListParams(
    locationReport: LocationsReport,
    includeBreaches: boolean,
    timeService: TimeService,
    locations?: tree.Tree<ExposureLocation>,
): LocationsReportSearchFields {
    const locationIds = getLocationIds(locationReport.locationId!, locations)

    return {
        ...sampleListRouteParams(locationReport, timeService, includeBreaches),
        fields: [{ index: fieldIndex.EXPOSURE_LOCATION_ID as fieldIndex.FieldIndex, value: locationIds }],
        reportLocationId: locationReport.locationId!,
    }
}

function getLocationIds(locationId: string, locations?: tree.Tree<ExposureLocation>): string[] {
    if (!locations)
        return [locationId]

    const rootLocationId = tree.roots(locations)[0]?.id
    return locationId === rootLocationId
        ? locations.childrenHash[rootLocationId]
        : [locationId]
}

export {
    formatLocationName,
    calculateKpiBlockRate,
    calculateTrend,
    calculateMaxBreach,
    calculateColor,
    dayBreaches,
    getSampleListParams,
}
