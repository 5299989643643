const NON_VIABLE_LOWER_CONCENTRATION_STATE = 0
    , NON_VIABLE_HIGHER_CONCENTRATION_STATE = 1

type NON_VIABLE_LOWER_CONCENTRATION_STATE = typeof NON_VIABLE_LOWER_CONCENTRATION_STATE
type NON_VIABLE_HIGHER_CONCENTRATION_STATE = typeof NON_VIABLE_HIGHER_CONCENTRATION_STATE

type ParticleState = NON_VIABLE_HIGHER_CONCENTRATION_STATE | NON_VIABLE_LOWER_CONCENTRATION_STATE

const PARTICLE_STATE: {id: ParticleState, name: string}[] = [
    {
        id: NON_VIABLE_LOWER_CONCENTRATION_STATE,
        name: '0.5μm particles per m³'
    },
    {
        id: NON_VIABLE_HIGHER_CONCENTRATION_STATE,
        name: '5μm particles per m³'
    }
]

const PARTICLE_ANALYSIS_STATE: {id: ParticleState, name: string }[] = [
    {
        id: NON_VIABLE_LOWER_CONCENTRATION_STATE,
        name: '0.5μm'
    },
    {
        id: NON_VIABLE_HIGHER_CONCENTRATION_STATE,
        name: '5μm'
    }
]

export {
    PARTICLE_STATE,
    ParticleState,
    PARTICLE_ANALYSIS_STATE,
    NON_VIABLE_HIGHER_CONCENTRATION_STATE,
    NON_VIABLE_LOWER_CONCENTRATION_STATE,
}
