import { handler, commonReducer } from '_/facade/reducer'
import type { Reason as State } from '_/model/app-state'
import { shallowUpdate } from '_/utils/object'
import * as actions from './actions'

const defaultState: State = {
    showReasonModal: false,
    signatureRequired: false,
    isSettlePlateChanged: false,
    isPlateTypeChangedToSettlePlate: false,
    isFingerdabTwoHandsPlateChanged: false,
    showElectronicSignatureModal: false,
    reason: '',
    error: '',
}

export default commonReducer(
    defaultState,
    handler(actions.reasonModalOpened, (state, payload) =>  shallowUpdate(state, { showReasonModal: true }, { ...payload })),
    handler(actions.reasonModalClosed, _ => shallowUpdate(_, { showReasonModal: false, isSettlePlateChanged: false, isPlateTypeChangedToSettlePlate: false, isFingerdabTwoHandsPlateChanged: false })),
    handler(actions.sampleTypeChanged, (state, payload) =>
        shallowUpdate(state, {
            isSettlePlateChanged: payload.isSettlePlateChanged,
            isPlateTypeChangedToSettlePlate: payload.isPlateTypeChangedToSettlePlate,
            isFingerdabTwoHandsPlateChanged: !!payload.isFingerdabTwoHandsPlateChanged,
        })
    ),

    handler(actions.electronicSignatureModalOpened, (state, error) =>  shallowUpdate(state, { showElectronicSignatureModal: true }, {error})),
    handler(actions.electronicSignatureModalClosed, _ => shallowUpdate(_, { showElectronicSignatureModal: false })),
)
