import { Form } from 'react-final-form'

import { classnames, useAction, useState, useEffect, useSelector, useCallback, useMemo } from '_/facade/react'

import { resetForm, Submit, submitDisabled, TextField } from '_/components/form'
import { Modal, ModalBody, ModalHeader, ModalFooter } from '_/components/modal'
import AuditTrails from '_/components/audit-trail-list'
import Button, { Close } from '_/components/button'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import TabNavbar from '_/components/tab-navbar'

import * as tabs from '_/constants/modal-tab'
import * as routes from '_/constants/routes'

import type { SampleSessionEdit, SampleSession } from '_/model/predefined-lists/session/types'

import validate from './validate'
import * as actions from '../actions'
import * as deletionActions from '_/features/confirmation/actions'
import * as routerActions from '_/features/routing/actions'
import type { FormApi } from 'final-form'
import ActiveField from '../../shared/active-field'
import type { AuditTrail } from '_/model/audit-trail/types'

const defaultSampleSessionEdit: Partial<SampleSessionEdit> = {
        name: '',
        isActive: false,
    }

function SampleSessionModal() {
    const sampleSessionId = useSelector<string | undefined>(_ => _.router.route!.params.id)
        , sampleSessions = useSelector(_ => _.predefinedLists.sampleSessions)
        , navigateToList = useNavigateToList()
        , [sampleSession, sampleSessionsTrail] = useSession()
        , initialValue = useMemo(
            () => (
                sampleSession
                    ? {
                        name: sampleSession.name,
                        isActive: sampleSession.isActive,
                    }
                    : defaultSampleSessionEdit
                ),
            [sampleSession]
        )
        , submit = useSubmitModal(initialValue)
        , remove = useDeletionModal(sampleSession)
        , [activeTab, setActiveTab] = useState(tabs.DATA)

    return (
        <Modal isOpen onClose={navigateToList}>
            <Form<SampleSessionEdit>
                onSubmit={submit}
                validate={_ => validate(_, sampleSessions.filter(_ => sampleSession ? _.id !== sampleSession.id : true))}
                initialValues={initialValue}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form}/>

                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4 data-testid='session-modal-title'>{sampleSessionId ? 'Edit session' : 'New session'}</h4>
                                    <Close onClick={navigateToList} />
                                </div>
                                <TabNavbar>
                                    <Button
                                        onClick={() => setActiveTab(tabs.DATA)}
                                        className={classnames('btn-link navbar-tab me-4', { active: activeTab === tabs.DATA })}
                                    >
                                        Session data
                                    </Button>
                                    <Button
                                        onClick={() => setActiveTab(tabs.AUDIT_TRAIL)}
                                        className={classnames('btn-link navbar-tab', { active: activeTab === tabs.AUDIT_TRAIL })}
                                        disabled={!sampleSessionId}
                                    >
                                        Audit trail
                                    </Button>
                                </TabNavbar>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='predefined-list-modal-body-height'>
                                { activeTab === tabs.DATA && <TextField name='name' id='name' testId='field-name'>Name</TextField> }
                                { activeTab === tabs.AUDIT_TRAIL && <AuditTrails trail={sampleSessionsTrail} /> }
                            </div>
                        </ModalBody>
                        <ModalFooter className='border-top-0 modal-footer-height'>
                            {activeTab !== tabs.AUDIT_TRAIL &&
                                <div
                                    className={`d-flex flex-fill ${sampleSessionId && activeTab === tabs.DATA
                                                                        ? 'justify-content-between'
                                                                        : 'justify-content-end'}`}>
                                    {sampleSession && activeTab === tabs.DATA &&
                                        <div>
                                            <Button
                                                className='text-danger bg-transparent border-0 me-2'
                                                onClick={() => remove(form.form)}
                                                disabled={sampleSession.inUse}
                                            >
                                                Remove
                                            </Button>
                                            <ActiveField inUse={sampleSession.inUse} isActive={sampleSession.isActive} />
                                        </div>
                                    }
                                    <div>
                                        <Button className='text-muted bg-transparent border-0 me-2' onClick={navigateToList}>Cancel</Button>
                                        <Submit disabled={submitDisabled(form)} testId='session-save'>{sampleSessionId ? 'Save changes' : 'Create'}</Submit>
                                    </div>
                                </div>
                            }
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default SampleSessionModal

function useSession() {
    const [sampleSession, setSampleSession] = useState<SampleSession>()
        , [trail, setTrail] = useState<AuditTrail>()
        , sampleSessionId = useSelector<string | undefined>(_ => _.router.route!.params.id)
        , loadSampleSession = useAction(actions.loadSampleSession)
        , loadSampleSessionTrail = useAction(actions.loadSampleSessionTrail)

    useEffect(
        () => {
            if (!sampleSessionId)
                return

            loadSampleSession(sampleSessionId).then(setSampleSession)
            loadSampleSessionTrail(sampleSessionId).then(setTrail)
        },
        [sampleSessionId, setSampleSession, loadSampleSession, loadSampleSessionTrail]
    )

    return [sampleSession, trail] as const
}

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SETTINGS_SESSIONS), [navigateTo])

    return navigateToList
}

function useSubmitModal(initialValue: Partial<SampleSessionEdit>) {
    const sampleSessionId = useSelector<string | undefined>(_ => _.router.route!.params.id)
        , createSampleSession = useAction(actions.createSampleSession)
        , saveSampleSession = useAction(actions.saveSampleSession)
        , navigateToList = useNavigateToList()

    const submit = (newSampleSession: SampleSessionEdit, form: FormApi<SampleSessionEdit, Partial<SampleSessionEdit>>) => {
        const result = sampleSessionId
            ? saveSampleSession({
                id: sampleSessionId,
                oldSampleSession: initialValue as SampleSessionEdit,
                newSampleSession,
            })
            : createSampleSession(newSampleSession)

        return result
            .then(() => resetForm(form, !sampleSessionId))
            .then(navigateToList)
    }

    return submit
}

function useDeletionModal(sampleSession: SampleSession | undefined) {
    const removeSampleSession = useAction(actions.removeSampleSession)
        , confirmDeletion = useAction(deletionActions.showDeletionConfirmationModal)
        , navigateToList = useNavigateToList()
        , remove = (form: FormApi<SampleSessionEdit, Partial<SampleSessionEdit>>) => {
            confirmDeletion(`Are you sure you want to delete ${sampleSession!.name}?`)
                .then(_ => removeSampleSession(sampleSession!.id))
                .then(() => resetForm(form))
                .then(navigateToList)
        }

    return remove
}
