interface Point {
    x: number
    y: number
}

interface Area {
    // top left point
    x: number
    y: number
    width: number
    height: number
}

function isPointInsideArea(point: Point, area: Area) {
    return point.x >= area.x
        && point.x <= area.x + area.width
        && point.y >= area.y
        && point.y <= area.y + area.height
}

function overlapArea(one: Area, two: Area) {
    const x_diff = Math.max(two.x, one.x) - Math.min(two.x + two.width, one.x + one.width)
        , y_diff = Math.max(two.y, one.y) - Math.min(two.y + two.height, one.y + one.height)
        , x_overlapping = x_diff < 0
        , y_overlapping = y_diff < 0

    if (!x_overlapping || !y_overlapping)
        return undefined

    return {
        x: Math.max(two.x, one.x),
        y: Math.max(two.y, one.y),
        width: Math.abs(x_diff),
        height: Math.abs(y_diff),
    }
}

export {
    Point,
    Area,
    isPointInsideArea,
    overlapArea,
}
