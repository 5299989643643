import { useAction, useEffect, useSelector } from '_/facade/react'
import { EmptyTableMessage, Table } from '_/components/table'

import Link, { LinkButton } from '_/components/link'
import { useContextSwitchObserver } from '_/components/context-observer'
import PageHeader from '_/components/page-header'
import SampleTypeModal from './modal/sample-type-modal'

import { SETTINGS_SAMPLE_TYPES_CREATE, SETTINGS_SAMPLE_TYPES_EDIT } from '_/constants/routes'
import PLATE_TYPE from '_/constants/plate-type'

import * as actions from './actions'

function SampleTypeList() {
    const permissions = useSelector(_ => _.auth.permissions)
        , editMode = useSelector(_ => [SETTINGS_SAMPLE_TYPES_CREATE, SETTINGS_SAMPLE_TYPES_EDIT].some(it => _.router.route!.name === it))
        , sampleTypes = useSampleTypes()

    return (
        <>
            {editMode && permissions.editSampleType && <SampleTypeModal />}

            <PageHeader title='Sample types'>
                <LinkButton
                    className='btn-primary'
                    routeName={SETTINGS_SAMPLE_TYPES_CREATE}
                    hasNoPermissions={!permissions.editSampleType}
                    testId='add-new-sample-type'
                >
                    New sample type
                </LinkButton>
            </PageHeader>

            <div className="overflow-auto flex-fill">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Sample name</th>
                            <th>Sample type</th>
                            <th>Medium type</th>
                            <th>Manufacturer</th>
                            <th>Status</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {sampleTypes.length === 0
                            ? <EmptyTableMessage colSpan={6} message='There are no sample types at the moment' />
                            : sampleTypes.map(sampleType =>
                                <tr key={sampleType.name}>
                                    <td>{sampleType.name}</td>
                                    <td>{PLATE_TYPE.find(_ => _.id === sampleType.sampleType)?.name}</td>
                                    <td>{sampleType.mediumType}</td>
                                    <td>{sampleType.manufacturer}</td>
                                    <td>{sampleType.isActive ? 'Active' : 'Inactive'}</td>
                                    <td className='text-end'>
                                        {permissions.editSampleType &&
                                            <Link
                                                className='btn-link'
                                                routeName={SETTINGS_SAMPLE_TYPES_EDIT}
                                                routeParams={{ id: sampleType.id }}
                                                testId={`sample-type-${sampleType.name}-edit`}
                                            >
                                                Edit
                                            </Link>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

function useSampleTypes() {
    const loadSampleTypeList = useAction(actions.loadSampleTypeList)
        , contextSwitch = useContextSwitchObserver()
        , sampleTypes = useSelector(_ => _.predefinedLists.sampleTypes)

    useEffect(
        () => {
            loadSampleTypeList()
        },
        [contextSwitch, loadSampleTypeList]
    )

    return sampleTypes
}

export default SampleTypeList
