import { useSelector } from '_/facade/react'

import * as routes from '_/constants/routes'
import LimitBreachReport from '_/features/limit-breach-report/limits-breach-report'
import Fingerdabs from '_/features/monitoring-overview/by-operator/fingerdabs'
import CustomReports from '_/features/analysis/custom-reports/custom-reports'
import CustomReportsEdit from '_/features/analysis/custom-reports/edit/custom-reports-edit'
import CustomReportsView from '_/features/analysis/custom-reports/view/custom-reports-view'
import LocationsReport from './locations-report/locations-report'
import OperatorsReport from './operators-report/operators-report'
import MissedMonitoringReport from './missed-monitoring-report/missed-monitoring-report'
import { isBaseRoute } from '_/utils/router/common'

function ReportContainer() {
    const route = useSelector(_ => _.router.route)

    if (route && isBaseRoute(routes.FINGERDABS, route))
        return <Fingerdabs />

    switch (route?.name){
        case routes.CUSTOM_REPORTS:
            return <CustomReports />
        case routes.LIMIT_BREACH_REPORT:
            return <LimitBreachReport />
        case routes.CUSTOM_REPORTS_EDIT:
        case routes.CUSTOM_REPORTS_CREATE:
            return <CustomReportsEdit />
        case routes.CUSTOM_REPORTS_VIEW:
            return <CustomReportsView />
        case routes.LOCATIONS_REPORT:
            return <LocationsReport />
        case routes.OPERATORS_REPORT:
            return <OperatorsReport />
        case routes.MISSED_MONITORING_REPORT:
            return <MissedMonitoringReport />
        default:
            return <CustomReports />
    }
}

export default ReportContainer
