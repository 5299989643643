import { useRef, classnames, useState, useAction } from '_/facade/react'
import Button, { Close } from '_/components/button'
import Legend from '_/components/legend'
import type { NewSelectionFieldValue, SelectionFieldValue } from '_/model/predefined-lists/custom-field/types'
import { allowedName, maxLength } from '_/utils/form/validate'
import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'
import * as actions from '../custom-fields/actions'
import * as spinnerActions from '_/features/spinner/actions'
import type { FieldRenderProps} from 'react-final-form'
import { useField } from 'react-final-form'
import { compare } from '_/utils/string'
import { replace } from '_/utils/array'

interface Props {
    id?: string
    name: string
    hasNoPermissions?: boolean
    disabled?: boolean
}

function SelectionItemsForm(props: React.PropsWithChildren<Props>) {
    const dropdownValuesField = useField<(SelectionFieldValue | NewSelectionFieldValue)[]>(props.name)
        , [validationError, setValidationError] = useState<string | boolean>(false)
        , [submitDisabled, setSubmitDisabled] = useState(true)
        , addHandler = useAddHandler(dropdownValuesField, setValidationError, setSubmitDisabled)
        , importHandler = useImportHandler(dropdownValuesField)
        , inputRef = useRef<HTMLInputElement>(null)

    return(
        <div id={props.id} className='overflow-y-auto mb-2'>
            <Legend>Selection items</Legend>
            <hr className='my-0 fw-bold'/>
            {getInputValue(dropdownValuesField).map(
                (value, index) =>
                    <div key={index}>
                        <DropdownItemField
                            value={value}
                            onRemove={() => {
                                const next = dropdownValuesField.input.value.filter(_ => _.name !== value.name)
                                dropdownValuesField.input.onChange(next)
                            }}
                            onActivate={() => {
                                const next = replace(dropdownValuesField.input.value, _ => _.name == value.name, _ => { return { ..._, isActive: true } })
                                dropdownValuesField.input.onChange(next)
                            }}
                            onDeactivate={() => {
                                const next = replace(dropdownValuesField.input.value, _ => _.name == value.name, _ => { return { ..._, isActive: false } })
                                dropdownValuesField.input.onChange(next)
                            }}
                        />
                        {validationError && <span className='invalid-feedback' data-testid='validation-error'>{validationError}</span>}
                        <hr className='my-0'/>
                    </div>
            )}
            <div className='d-flex mb-3 flex-row p-0' title={props.hasNoPermissions ? NO_PERMISSION_MESSAGE : ''}>
                <div className='col-8 px-0 mt-2'>
                    <input
                        disabled={props.disabled}
                        className={classnames('form-control', { 'is-invalid': validationError})}
                        onBlur={() => validate(inputRef, dropdownValuesField, setValidationError, setSubmitDisabled)}
                        onChange={() => validate(inputRef, dropdownValuesField, setValidationError, setSubmitDisabled)}
                        ref={inputRef}
                    />
                    {validationError && <span className='invalid-feedback' data-testid='validation-error'>{validationError}</span>}
                </div>
                <div className='col-4 px-0 mt-2 text-end'>
                    <Button
                        onClick={() => addHandler(inputRef, validationError)}
                        className='btn-primary'
                        hasNoPermissions={props.hasNoPermissions}
                        disabled={submitDisabled}
                    >
                        Add
                    </Button>
                    <label
                        htmlFor='import-dropdown-value'
                        className={classnames('btn btn-secondary ms-2 my-0', { disabled: props.disabled })}
                    >
                        Import
                    </label>
                    <input
                        onChange={importHandler}
                        accept='.csv'
                        value=''
                        type='file'
                        className='d-none'
                        autoComplete='off'
                        id='import-dropdown-value'
                        disabled={props.disabled}
                    />
                </div>
            </div>
        </div>
    )
}

export default SelectionItemsForm

interface SelectionItemFieldProps {
    value: SelectionFieldValue | NewSelectionFieldValue
    onRemove: () => void
    onActivate: () => void
    onDeactivate: () => void
}

function DropdownItemField(props: SelectionItemFieldProps) {
        const inUse =  'inUse' in props.value ? props.value.inUse : false
            , isActive = 'isActive' in props.value ? props.value.isActive : true

    function handleCloseClick() {
        if (isActive) {
            if (inUse)
                props.onDeactivate()
            else
                props.onRemove()
        }
    }

    return (
        <div className='d-flex flex-wrap p-2 justify-content-between'>
            <span className='text-break'>
                {props.value.name}
            </span>
            {isActive
                ? <Close className='py-0 col-auto ms-auto text-danger' onClick={handleCloseClick} />
                : <Button className='material-icons close' onClick={props.onActivate}>refresh</Button>}
        </div>
    )
}

function isUnique(existing: NewSelectionFieldValue[] | undefined, newValue: string) {
    const existingValues = existing ? existing : []

    return existingValues.some(_ => _.name.toUpperCase() === newValue.trim().toUpperCase())
        ? 'Selection item must be unique'
        : false
}

function isEmpty(name: string) {
    return (value: string | undefined | null) => value && value.trim().length > 0 ? false : `${name} should not be empty`
}

function validate(inputRef: React.RefObject<HTMLInputElement>, fields: FieldRenderProps<NewSelectionFieldValue[]>, setValidationResult: (_: string | boolean) => void, setSubmitDisabled: (_: boolean) => void) {
    if (inputRef.current) {
        const validationResult = isEmpty('Selection item')(inputRef.current.value)
            || allowedName('Selection item')(inputRef.current.value)
            || maxLength('Selection item', 100)(inputRef.current.value)
            || isUnique(getInputValue(fields), inputRef.current.value)
        setValidationResult(validationResult)
        setSubmitDisabled(!!validationResult)
    }
}

function useAddHandler(fields: FieldRenderProps<(SelectionFieldValue | NewSelectionFieldValue)[]>, setValidationResult: (_: string | boolean) => void, setSubmitDisabled: (_: boolean) => void) {

    function handleAddSelectionItem(inputRef: React.RefObject<HTMLInputElement>, validationError: string | boolean) {
        validate(inputRef, fields, setValidationResult, setSubmitDisabled)
        if (inputRef.current && !validationError) {
            const concatenated = getInputValue(fields)
                .concat({ name: inputRef.current.value })
                .sort((one, two) => compare(one.name, two.name))

            fields.input.onChange(concatenated)
            setSubmitDisabled(true)
            inputRef.current.value = ''
        }
    }

    return handleAddSelectionItem
}

function useImportHandler(fields: FieldRenderProps<(SelectionFieldValue | NewSelectionFieldValue)[]>) {
    const parseImportFile = useAction(actions.parseImportFile)
        , showSpinner = useAction(spinnerActions.showSpinner)
        , hideSpinner = useAction(spinnerActions.hideSpinner)

    function handleImportSelectionItem(values: string[]) {
        const existingValues = getInputValue(fields)
            , newUniqueValues = values
                .map(_ => _.trim())
                .filter(_ => existingValues.every(e => e.name.toLowerCase() !== _.toLocaleLowerCase()))
                .filter((value, index, self) => self.indexOf(value) === index)
                .map(_ => ({ name: _ } as NewSelectionFieldValue))
            , concatenated = newUniqueValues
                .concat(existingValues)
                .sort((one, two) => compare(one.name, two.name))

        fields.input.onChange(concatenated)
    }

    function handleFilesChanged(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files)
            return

        const file = e.target.files[0]

        Promise.resolve()
            .then(showSpinner)
            .then(() => parseImportFile(file))
            .then(handleImportSelectionItem)
            .finally(hideSpinner)
    }

    return handleFilesChanged
}

function getInputValue(fields: FieldRenderProps<(SelectionFieldValue | NewSelectionFieldValue)[]>) {
    // If there are no items, fields.input.value will be ''
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return fields.input.value ? fields.input.value : []
}
