import Button from './button'

import { noop } from '_/utils/function'
import type { Value } from './form/number-input'
import NumberInput from './form/number-input'

interface InputFieldProps {
    id: string
    name: string
    sampleTypeName: string
    onChange: (_: Value) => void
    value: number
    canEdit: boolean
}

function GroupsNumberField(props: InputFieldProps) {
    return (
        <div className='d-flex'>
            <Button onClick={() => props.onChange(props.value - 1)} className='btn-link p-0' disabled={props.value === 0 || !props.canEdit}>
                <i className='material-icons align-middle text-white md-24'>remove_circle_outline</i>
            </Button>
            <NumberInput id={props.id} className='form-control mx-1' onChange={props.onChange} value={props.value} testId={`${props.sampleTypeName}-number`} disabled={!props.canEdit}/>
            <Button onClick={() => props.onChange(props.value + 1)} className='btn-link p-0' disabled={!props.canEdit}>
                <i className='material-icons align-middle text-white md-24'>control_point</i>
            </Button>
        </div>
    )
}

function GroupsNumberFieldDisabled(props: InputFieldProps) {
    return (
        <div className='d-flex justify-content-end'>
            <Button onClick={noop} className='btn-link p-0 invisible'>
                <i className='material-icons align-middle text-white md-24'>highlight_off</i>
            </Button>
            <NumberInput id={props.id} className='form-control mx-1' onChange={_ => _} value={props.value} disabled />
            <Button onClick={() => props.onChange(0)} className='btn-link p-0' disabled={!props.canEdit}>
                <i className='material-icons align-middle text-white md-24'>highlight_off</i>
            </Button>
        </div>
    )
}

export {
    GroupsNumberField as default,
    GroupsNumberFieldDisabled,
}
