import type { SampleDetailsEdit as SampleEdit} from '_/model/sample/edit/types'

import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, minLength, required, checkDateRangeValidity, hasTrimableSpaces, requiredValues } from '_/utils/form/validate'
import type { NewSampleComment } from '_/model/sample/comment'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type TimeService from '_/services/time-service'
import { checkIfFingerdab, findFieldValuePos, getFieldValue, getFieldNotRecorded, getFieldName } from './helpers'
import * as fieldType from '_/constants/custom-field-column-type'
import * as fieldIndex from '_/constants/custom-field-index'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import { SETTLE_PLATE } from '_/constants/plate-type'
import { NEGATIVE_NUMBERS, POSITIVE_AND_NEGATIVE_NUMBERS, POSITIVE_NUMBERS } from '_/model/predefined-lists/custom-field/allowed-number-values'
import * as h from '_/model/predefined-lists/sample-type/helpers'
import * as dt from '_/model/date-time'
import type { NonViableSampleForm } from '_/model/non-viable-sample/booking/types'
import type { ViableSampleBookingForm } from '_/model/sample/booking/types'
import { validateCustomFieldNumberInRange } from './filter/validate'

function validateBarcode(entity: Partial<SampleEdit | ViableSampleBookingForm>, fieldName: string) {
    const existedFields = entity.fields || []
        , resultFields: Partial<FieldValues>[] = []
        , result: ValidationResult<SampleEdit | ViableSampleBookingForm> = {}
        , barcode = getFieldValue(existedFields, fieldIndex.BARCODE)

    const mediumBarCode = required(fieldName)(barcode)
        , barcodeLength = minLength(fieldName, 4)(barcode) || maxLength(fieldName, 100)(barcode)
        , barcodeHasTrimableSpaces = hasTrimableSpaces(fieldName, barcode)

    if (mediumBarCode)
        resultFields[findFieldValuePos(existedFields, fieldIndex.BARCODE)] = { value: mediumBarCode }

    if (barcodeLength)
        resultFields[findFieldValuePos(existedFields, fieldIndex.BARCODE)] = { value: barcodeLength }

    if (barcodeHasTrimableSpaces)
        resultFields[findFieldValuePos(existedFields, fieldIndex.BARCODE)] = { value: barcodeHasTrimableSpaces }

    return { ...result, fields: resultFields }
}

function validateBatchNumber(entity: Partial<SampleEdit | ViableSampleBookingForm>, field: CustomField | undefined, isViable?: boolean) {
    const existedFields = entity.fields || []
        , resultFields: Partial<FieldValues>[] = []
        , result: ValidationResult<SampleEdit | ViableSampleBookingForm> = {}
        , fieldValue = getFieldValue(existedFields, fieldIndex.BATCH_NUMBER)
        , batchNumberFieldValue = Array.isArray(fieldValue) ? fieldValue : []
        , batchNumberNotRecorded = getFieldNotRecorded(existedFields, fieldIndex.BATCH_NUMBER)
        , settings = isViable ? field?.viableSettings : field?.nonViableSettings

    const batchNumber = (field && settings?.required && !batchNumberNotRecorded && requiredValues(field.fieldName)(batchNumberFieldValue))
            || (field && batchNumberFieldValue.length > 50 ? 'Batch number can contain up to 50 numbers' : undefined)

    if (batchNumber)
        resultFields[findFieldValuePos(existedFields, fieldIndex.BATCH_NUMBER)] = { value: batchNumber }

    return { ...result, fields: resultFields }
}

function validateSampleType(entity: Partial<SampleEdit | ViableSampleBookingForm>, field: CustomField | undefined, sampleTypes: SampleType[] | undefined, canChangeToSwab = true, operatorIds?: string[]) {
    const existedFields = entity.fields || []
        , resultFields: Partial<FieldValues>[] = []
        , result: ValidationResult<SampleEdit | ViableSampleBookingForm> = {}
        , sampleTypeId = getFieldValue(existedFields, fieldIndex.SAMPLE_TYPE_ID)
        , isSwabSampleType = h.isSwabSampleType(sampleTypeId, sampleTypes ?? [])

    const sampleType = (field && field.viableSettings.required && required(field.fieldName)(sampleTypeId))
            || (sampleTypeId && operatorIds && sampleTypes && isFingerdabWithMultipleOperators(sampleTypeId, sampleTypes, operatorIds))
            || (isSwabSampleType && !canChangeToSwab && 'Unable to save changes, at least one contamination row has CFU count greater than 1')

    if (sampleType)
        resultFields[findFieldValuePos(existedFields, fieldIndex.SAMPLE_TYPE_ID)] = { value: sampleType }

    return { ...result, fields: resultFields }
}

function validateViableOperators(fields: FieldValues[] | undefined, field: CustomField | undefined, sampleTypeId?: string, sampleTypes?: SampleType[] | undefined) {
    const result: ValidationResult<SampleEdit | ViableSampleBookingForm> = {}
        , resultFields = validateOperators(fields, field, true, sampleTypeId, sampleTypes)

    return { ...result, fields: resultFields }
}

function validateNonViableOperators(fields: FieldValues[] | undefined, field: CustomField | undefined) {
    const result: ValidationResult<NonViableSampleForm> = {}
        , resultFields = validateOperators(fields, field, false)

    return { ...result, fields: resultFields }
}

function validateOperators(fields: FieldValues[] | undefined, field: CustomField | undefined, isViable: boolean, sampleTypeId?: string, sampleTypes?: SampleType[] | undefined) {
    const existedFields = fields ?? []
        , resultFields: Partial<FieldValues>[] = []
        , operators = getFieldValue(existedFields, fieldIndex.OPERATORS_IDS)
        , operatorsNotRecorded = getFieldNotRecorded(existedFields, fieldIndex.OPERATORS_IDS)
        , settings = isViable ? field?.viableSettings : field?.nonViableSettings

    const operatorIds = (field && settings?.required && !operatorsNotRecorded && requiredValues(field.fieldName)(operators))
                        || (sampleTypeId && operators && sampleTypes && isFingerdabWithMultipleOperators(sampleTypeId, sampleTypes, operators))

    if (operatorIds)
        resultFields[findFieldValuePos(existedFields, fieldIndex.OPERATORS_IDS)] = { value: operatorIds }

    return resultFields
}

function validateViableSession(fields: FieldValues[] | undefined, field: CustomField | undefined) {
    const result: ValidationResult<SampleEdit | ViableSampleBookingForm> = {}
        , resultFields = validateSession(fields, field, true)

    return { ...result, fields: resultFields }
}

function validateNonViableSession(fields: FieldValues[] | undefined, field: CustomField | undefined) {
    const result: ValidationResult<NonViableSampleForm> = {}
        , resultFields = validateSession(fields, field, false)

    return { ...result, fields: resultFields }
}

function validateSession(fields: FieldValues[] | undefined, field: CustomField | undefined, isViable: boolean) {
    const existedFields = fields ?? []
        , resultFields: Partial<FieldValues>[] = []
        , sessionFieldValue = getFieldValue(existedFields, fieldIndex.SESSION_ID)
        , sessionNotRecorded = getFieldNotRecorded(existedFields, fieldIndex.SESSION_ID)
        , settings = isViable ? field?.viableSettings : field?.nonViableSettings

    const session = (field && settings?.required && !sessionNotRecorded && required(field.fieldName)(sessionFieldValue))

    if (session)
        resultFields[findFieldValuePos(existedFields, fieldIndex.SESSION_ID)] = { value: session }

    return resultFields
}

function validateViableDates(timeService: TimeService, existedFields: FieldValues[], customFields: CustomField[], sampleTypes: SampleType[]) {
    const result: ValidationResult<SampleEdit | ViableSampleBookingForm> = {}
        , resultFields = validateDates(timeService, existedFields, customFields, true, sampleTypes)

    return { ...result, fields: resultFields }
}

function validateNonViableDates(timeService: TimeService, existedFields: FieldValues[], customFields: CustomField[]) {
    const result: ValidationResult<NonViableSampleForm> = {}
        , resultFields = validateDates(timeService, existedFields, customFields, false)

    return { ...result, fields: resultFields }
}

function validateDates(timeService: TimeService, existedFields: FieldValues[], customFields: CustomField[], isViable: boolean, sampleTypes?: SampleType[]) {
    const resultFields: Partial<FieldValues>[] = []
        , startDate = getFieldValue(existedFields, fieldIndex.EXPOSURE_START_DATE)
        , startTime = getFieldValue(existedFields, fieldIndex.EXPOSURE_START_TIME)
        , startTimeNotRecorded = getFieldNotRecorded(existedFields, fieldIndex.EXPOSURE_START_TIME)
        , endTime = getFieldValue(existedFields, fieldIndex.EXPOSURE_END_TIME)
        , endTimeNotRecorded = getFieldNotRecorded(existedFields, fieldIndex.EXPOSURE_END_TIME)

        , startTimeField = customFields.find(f => f.index == fieldIndex.EXPOSURE_START_TIME)
        , startTimeSettings = isViable ? startTimeField?.viableSettings : startTimeField?.nonViableSettings

        , endTimeField = customFields.find(f => f.index == fieldIndex.EXPOSURE_END_TIME)
        , endTimeSettings = isViable ? endTimeField?.viableSettings : endTimeField?.nonViableSettings
        , endTimeRequired = endTimeField && endTimeSettings?.required

    const exposureEndDate = isValidExposureEndDate(existedFields)
        , exposureEndTime = isValidExposureEndTime(timeService, existedFields, !!endTimeRequired, sampleTypes, isViable)
                            || (endTimeRequired && !endTimeNotRecorded && required('End time')(endTime))
        , exposureStartDate = isValidDateWrapper(existedFields, fieldIndex.EXPOSURE_START_DATE)
                            || isNotInTheFutureWrapper(timeService, existedFields, fieldIndex.EXPOSURE_START_DATE)
        , exposureStartTime = isExistingTimeWrapper(timeService, existedFields, fieldIndex.EXPOSURE_START_DATE, fieldIndex.EXPOSURE_START_TIME)
                            || (startTimeField && startTimeSettings?.required && !startTimeNotRecorded && required(startTimeField.fieldName)(startTime))
        , isStartTimeInTheFuture = isNotInTheFuture(timeService, timeService.combineCtzDateTime(startDate, startTime), 'Start time')

    if (exposureStartDate)
        resultFields[findFieldValuePos(existedFields, fieldIndex.EXPOSURE_START_DATE)] = { value: exposureStartDate }

    if (exposureStartTime)
        resultFields[findFieldValuePos(existedFields, fieldIndex.EXPOSURE_START_TIME)] = { value: exposureStartTime }

    if (isStartTimeInTheFuture)
        resultFields[findFieldValuePos(existedFields, fieldIndex.EXPOSURE_START_TIME)] = { value: isStartTimeInTheFuture }

    if (exposureEndDate)
        resultFields[findFieldValuePos(existedFields, fieldIndex.EXPOSURE_END_DATE)] = { value: [exposureEndDate] }

    if (exposureEndTime)
        resultFields[findFieldValuePos(existedFields, fieldIndex.EXPOSURE_END_TIME)] = { value: [exposureEndTime] }

    return resultFields
}

function validateComment(comment: NewSampleComment, compromisedStatusChanged?: boolean) {
    const commentLength = maxLength('Comment', 250)(comment.message)

    if (commentLength)
        return commentLength

    if (!comment.message.trim())
        return compromisedStatusChanged
            ? 'Comment required'
            : comment.message.length !== 0 ? 'Need to type some text to send a comment' : undefined
}

function isValidDate(date: dt.DateTime | null | undefined) {
    return date === undefined || date === null
        ? 'Date is required'
        : undefined
}

function isNotInTheFuture(timeService: TimeService, date: dt.DateTime | null | undefined, fieldName?: string) {
    return date != null && dt.greaterThan(date, timeService.now())
            ? `${fieldName || 'Exposure date'} must not be in the future`
            : undefined
}

function isExistingTime(timeService: TimeService, date: dt.DateTime | undefined, time: string | undefined) {
    try {
        timeService.combineCtzDateTime(date, time)
    }
    catch (_) {
        return 'Not valid time'
    }
}

function isValidDateWrapper(values: FieldValues[], index: fieldIndex.FieldIndex) {
    const field = getFieldValue(values, index)
    return isValidDate(field)
}

function isExposureEndTimeAfterStartTime(timeService: TimeService, values: FieldValues[]) {
    const startDate = getFieldValue(values, fieldIndex.EXPOSURE_START_DATE)
        , startTime = getFieldValue(values, fieldIndex.EXPOSURE_START_TIME)
        , endDate = getFieldValue(values, fieldIndex.EXPOSURE_END_DATE)
        , endTime = getFieldValue(values, fieldIndex.EXPOSURE_END_TIME)
        , startTimeNotRecorded = getFieldNotRecorded(values, fieldIndex.EXPOSURE_START_TIME)
        , endTimeNotRecorded = getFieldNotRecorded(values, fieldIndex.EXPOSURE_END_TIME)

    return !startTimeNotRecorded && !endTimeNotRecorded
        && startTime !== undefined
        && endTime !== undefined
        && dt.greaterThanOrEqual(timeService.combineCtzDateTime(startDate, startTime)!, timeService.combineCtzDateTime(endDate, endTime)!)
            ? 'End time should be later than Start time'
            : undefined
}

function isNotInTheFutureWrapper(timeService: TimeService, values: FieldValues[], index: fieldIndex.FieldIndex, fieldName?: string) {
    const field = getFieldValue(values, index)
    return isNotInTheFuture(timeService, field, fieldName)
}

function isValidExposureEndTime(timeService: TimeService, values: FieldValues[], endTimeRequired: boolean, sampleTypes: SampleType[] | undefined, isViable: boolean) {
    return isViable ? validateViableExposureEndTime(timeService, values, sampleTypes ?? [], endTimeRequired) : validateNonViableExposureEndTime(timeService, values)
}

function validateViableExposureEndTime(timeService: TimeService, values: FieldValues[], sampleTypes: SampleType[], endTimeRequired: boolean) {
    const sampleType = getFieldValue(values, fieldIndex.SAMPLE_TYPE_ID)
        , endDate = getFieldValue(values, fieldIndex.EXPOSURE_END_DATE)
        , endTime = getFieldValue(values, fieldIndex.EXPOSURE_END_TIME)
        , endTimeNotRecorded = getFieldNotRecorded(values, fieldIndex.EXPOSURE_END_TIME)
        , currentSampleType = sampleType && sampleTypes.find(_ => _.id === sampleType)
        , isSettlePlate = currentSampleType && currentSampleType.sampleType === SETTLE_PLATE

    if (!isSettlePlate && !endTimeRequired || endTimeNotRecorded)
        return false

    return required('End time')(endTime)
        || isExposureEndTimeAfterStartTime(timeService, values)
        || isExistingTime(timeService, endDate, endTime)
}

function validateNonViableExposureEndTime(timeService: TimeService, values: FieldValues[]) {
    const endDate = getFieldValue(values, fieldIndex.EXPOSURE_END_DATE)
        , endTime = getFieldValue(values, fieldIndex.EXPOSURE_END_TIME)
        , endTimeNotRecorded = getFieldNotRecorded(values, fieldIndex.EXPOSURE_END_TIME)

    if (endTimeNotRecorded)
        return false

    return isExposureEndTimeAfterStartTime(timeService, values)
        || isExistingTime(timeService, endDate, endTime)
}

function isValidExposureEndDate(values: FieldValues[]) {
    const message = 'End date should be later than Start date'
        , endDate = getFieldValue(values, fieldIndex.EXPOSURE_END_DATE)
        , startDate = getFieldValue(values, fieldIndex.EXPOSURE_START_DATE)
        , endTimeNotRecorded = getFieldNotRecorded(values, fieldIndex.EXPOSURE_END_TIME)

    if (endTimeNotRecorded)
        return false

    return isValidDate(endDate)
        || !dt.equals(endDate, startDate)
        && checkDateRangeValidity(startDate, endDate, message)
}

function isExistingTimeWrapper(timeService: TimeService, values: FieldValues[], dateIndex: fieldIndex.FieldIndex, timeIndex: fieldIndex.FieldIndex) {
    const dateField = getFieldValue(values, dateIndex)
        , timeField = getFieldValue(values, timeIndex)

    return isExistingTime(timeService, dateField, timeField)
}

function isFingerdabWithMultipleOperators(sampleTypeId: string, sampleTypes: SampleType[], operatorIds: string[]) {
    const sampleType = sampleTypes.find(_ => _.id === sampleTypeId)

    return sampleType && checkIfFingerdab(sampleTypeId, sampleTypes) && operatorIds.length > 1
        ? `Multiple operators are not allowed for type ${sampleType.name}`
        :  undefined
}

function validateViableCustomFieldRequired(value: FieldValues | undefined, customFields: CustomField[]) {
    return validateCustomFieldRequired(value, customFields, true)
}

function validateNonViableCustomFieldRequired(value: FieldValues | undefined, customFields: CustomField[]) {
    return validateCustomFieldRequired(value, customFields, false)
}

function validateCustomFieldRequired(value: FieldValues | undefined, customFields: CustomField[], isViable: boolean) {
    const customField = customFields.find(f => f.index === value!.index)
    , settings = isViable ? customField?.viableSettings : customField?.nonViableSettings

    if (value && customField && settings?.required && !value.notRecorded) {
        const fieldName = customField.fieldName
            , fieldValue = value.value

        return required(fieldName)(fieldValue)
    }
}

function validateCustomFieldTextMaxLength(value: FieldValues | undefined, customFields: CustomField[]) {
    const customField = customFields.find(f => f.index === value!.index)

    if (customField && customField.fieldType === fieldType.TEXT && value) {
        return maxLength(customField.fieldName, 250)(value.value)
    }
}

function validateCustomFieldTextMinLength(value: FieldValues | undefined, customFields: CustomField[]) {
    const customField = customFields.find(f => f.index === value!.index)

    if (customField && customField.fieldType === fieldType.TEXT && value) {
        return minLength(customField.fieldName, 4)(value.value)
    }
}

function validateCustomFieldTextHasTrimableSpaces(value: FieldValues | undefined, customFields: CustomField[]) {
    const customField = customFields.find(f => f.index === value!.index)

    if (customField && customField.fieldType === fieldType.TEXT && value) {
        return hasTrimableSpaces(customField.fieldName, value.value)
    }
}

function validateCustomFieldNumber(value: FieldValues | undefined, customFields: CustomField[]) {
    const customField = customFields.find(f => f.index === value!.index)

    if (customField && customField.fieldType === fieldType.NUMBER && value) {
        if (!value.value)
            return

        const [ min, max ] = getFieldRangeEdges(customField)
        return validateCustomFieldNumberInRange(min, max)(customField.fieldName, value.value)
    }


    function getFieldRangeEdges(field: CustomField) {
        switch(field.allowedValues!) {
            case POSITIVE_AND_NEGATIVE_NUMBERS: return [ -99999.999, 99999.999 ]
            case POSITIVE_NUMBERS: return [ 0, 99999.999 ]
            case NEGATIVE_NUMBERS: return [ -99999.999, 0 ]
        }
    }
}

function validateViableCustomFields(customFields: CustomField[], values: FieldValues[]) {
    return validateCustomFields(customFields, values, true)
}

function validateNonViableCustomFields(customFields: CustomField[], values: FieldValues[]) {
    return validateCustomFields(customFields, values, false)
}

function validateCustomFields(customFields: CustomField[], values: FieldValues[], isViable: boolean) {
    const fields: Partial<FieldValues>[] = []
        , customValuesOnly = values.filter(f => fieldIndex.default.indexOf(f.index) === -1)

    customValuesOnly.forEach(customValue => {
        const required = validateCustomFieldRequired(customValue, customFields, isViable)
            , textFieldMaxLength = validateCustomFieldTextMaxLength(customValue, customFields)
            , textFieldMinLength = validateCustomFieldTextMinLength(customValue, customFields)
            , textFieldHasTrimableSpaces = validateCustomFieldTextHasTrimableSpaces(customValue, customFields)
            , numberValidation = validateCustomFieldNumber(customValue, customFields)

        if (required)
            fields[findFieldValuePos(values, customValue.index)] = { value: required }

        if (textFieldMaxLength)
            fields[findFieldValuePos(values, customValue.index)] = { value: textFieldMaxLength }

        if (textFieldMinLength)
            fields[findFieldValuePos(values, customValue.index)] = { value: textFieldMinLength }

        if (textFieldHasTrimableSpaces)
            fields[findFieldValuePos(values, customValue.index)] = { value: textFieldHasTrimableSpaces}

        if (numberValidation)
            fields[findFieldValuePos(values, customValue.index)] = { value: numberValidation }
    })

    return fields
}

function validateLocation(name: string) {
    return (values: FieldValues[], index: fieldIndex.FieldIndex) => {
        const field = values.find(_ => _.index === index)
        return !field
            ? `${name} is required`
            : !field.notRecorded && required(name)(field.value)
    }
}

function validateLocations(customFields: CustomField[], values: FieldValues[]) {
    const fields: Partial<FieldValues>[] = []
        , exposureLocation = validateLocation(getFieldName(customFields, fieldIndex.EXPOSURE_LOCATION_ID))(values, fieldIndex.EXPOSURE_LOCATION_ID)
        , monitoringPosition = validateLocation(getFieldName(customFields, fieldIndex.MONITORING_POSITION))(values, fieldIndex.MONITORING_POSITION)

    if (exposureLocation)
        fields[findFieldValuePos(values, fieldIndex.EXPOSURE_LOCATION_ID)] = { value: exposureLocation }

    if (monitoringPosition)
        fields[findFieldValuePos(values, fieldIndex.MONITORING_POSITION)] = { value: monitoringPosition }

    return fields
}

export {
    validateBarcode,
    validateBatchNumber,
    validateSampleType,
    validateViableOperators,
    validateNonViableOperators,
    validateComment,
    validateViableDates,
    validateNonViableDates,
    isExistingTime,
    validateViableCustomFieldRequired,
    validateNonViableCustomFieldRequired,
    validateViableSession,
    validateNonViableSession,
    validateCustomFieldTextMaxLength,
    validateCustomFieldTextMinLength,
    validateCustomFieldTextHasTrimableSpaces,
    validateCustomFieldNumber,
    validateViableCustomFields,
    validateNonViableCustomFields,
    validateLocations,
}
