import type { State as RouterState } from 'router5'

import { connect } from '_/facade/react'
import type AppState from '_/model/app-state'

import Auth from '_/features/auth/log-in/auth'
import AuthConfirm from '_/features/auth/confirm/auth-confirm'
import AuthConfirmEmail from '_/features/auth/confirm/auth-confirm-email'
import ConfirmChangedPassword from '_/features/auth/confirm/confirm-changed-password'
import ForgotPassword from '_/features/auth/restore/forgot-password'
import ForgotPasswordSent from '_/features/auth/restore/forgot-password-sent'
import SetPasswordPage from '_/features/auth/restore/set-password-page'
import Reading from '_/features/plates/reading/reading'
import Samples from '_/features/samples/list/samples'
import Booking from '_/features/samples/booking/booking'
import SampleEditPage from '_/features/samples/sample-edit/sample-edit'
import SampleReading from '_/features/samples/reading/sample-reading'
import SampleReportPage from '_/features/samples/sample-report/sample-report'
import Toasts from '_/features/toasts/toasts'
import SignUp from '_/features/auth/sign-up/sign-up'
import UserAccount from '_/features/users/account/user-account'
import PredefinedLists from '_/features/predefined-lists/predefined-lists'
import Dashboard from '_/features/dashboard/dashboard'
import Analysis from '_/features/analysis/ui/analysis'
import ContextInviteConfirm  from '_/features/contexts/invite/context-invite-confirm'
import ContextInviteConfirmed from '_/features/contexts/invite/context-invite-confirmed'
import ContextAssignConfirm from '_/features/contexts/invite/context-assign-confirm'
import DiscardChangesConfirmation from '_/features/unsaved-changes/discard-changes-confirmation'
import GenericConfirmationModal from '_/features/confirmation/generic-confirmation-modal'
import Scheduling from '_/features/scheduling/scheduling'
import Reports from '_/features/reports/reports'
import Trends from '_/features/trends/trends'

import AuditTrailList from '../audit-trails/list/list'
import Help from '_/features/help/help'
import Navbar from './navbar'
import * as Routes from '_/constants/routes'
import Maintenance from '../backend-status/maintenance'
import ReasonForChange from '_/features/critical-change-reason/reason-for-change'
import ElectronicSignatureModal from '_/features/critical-change-reason/electronic-signature-modal'
import { isBaseRoute } from '_/utils/router/common'
import Spinner from '_/features/spinner/spinner'
import IpRestricted from './ip-restricted'
import BlankPage from './blank-page'
import SsoLogin from '_/features/auth/log-in/sso-login'
import SsoSignUp from '_/features/auth/sign-up/sso-sign-up'
import Notifications from '_/features/notifications/ui/list'
import PlateList from '_/features/plates/list/list'
import NonViableSampleEditPage from '../non-viable-samples/edit/non-viable-edit'

const Shell: React.FC<ConnectedProps> =
    props => {
        if (props.route?.name === Routes.LOG_IN && props.route.params.ipAccessRestricted) {
            return (
                <BlankPage>
                    <IpRestricted message={props.route.params.message} />
                </BlankPage>
            )
        }

        return (
            <div className='d-flex flex-column full-screen-height d-print-block'>
                {props.route && props.route.name !== Routes.MAINTENANCE && <Navbar/>}
                <Toasts/>
                <div className='main-block' style={{ height: 0 }}>
                    {props.route && renderRoute(props.route)}
                </div>
                {props.showWarning && <DiscardChangesConfirmation/>}
                <GenericConfirmationModal/>
                {props.showSpinner && <Spinner/>}
                {props.showReasonModal && <ReasonForChange />}
                {props.showElectronicSignatureModal && <ElectronicSignatureModal />}
            </div>
        )
    }

function renderRoute(route: RouterState): React.ReactNode {
    switch (route.name) {
        case Routes.LOG_IN:
            return <Auth/>

        case Routes.SSO_LOG_IN:
            return <SsoLogin />

        case Routes.SSO_SIGN_UP:
            return <SsoSignUp />

        case Routes.AUTH_CONFIRM:
            return <AuthConfirm/>

        case Routes.AUTH_CONFIRM_EMAIL:
            return <AuthConfirmEmail/>

        case Routes.CONFIRM_CHANGED_PASSWORD:
            return <ConfirmChangedPassword/>

        case Routes.FORGOT_PASSWORD:
            return <ForgotPassword/>

        case Routes.FORGOT_PASSWORD_SENT:
            return <ForgotPasswordSent/>

        case Routes.SET_PASSWORD_PAGE:
            return <SetPasswordPage/>

        case Routes.PLATES_READING:
            return <Reading />

        case Routes.PLATES:
            return <PlateList />

        case Routes.NON_VIABLE_SAMPLES:
        case Routes.NON_VIABLE_SAMPLES_EXPORT:
        case Routes.SAMPLES:
        case Routes.SAMPLES_COLUMNS:
        case Routes.SAMPLES_EXPORT:
            return <Samples/>

        case Routes.SAMPLES_EDIT:
            return <SampleEditPage/>

        case Routes.SAMPLES_BOOKING:
        case Routes.NON_VIABLE_SAMPLES_RECORDING:
            return <Booking />

        case Routes.SAMPLES_READING:
            return <SampleReading/>

        case Routes.SAMPLES_REPORT:
            return <SampleReportPage/>

        case Routes.USERS_SIGN_UP:
            return <SignUp />

        case Routes.USER_CHANGE_PASSWORD:
            return <SetPasswordPage/>

        case Routes.DASHBOARD:
            return <Dashboard />

        case Routes.ANALYSIS:
            return <Analysis />

        case Routes.CONTEXTS_INVITE_CONFIRM:
            return <ContextInviteConfirm/>

        case Routes.CONTEXTS_INVITE_CONFIRMED:
            return <ContextInviteConfirmed />

        case Routes.CONTEXTS_ASSIGN_CONFIRM:
            return <ContextAssignConfirm />

        case Routes.TRAILS:
            return <AuditTrailList />

        case Routes.HELP:
            return <Help />

        case Routes.MAINTENANCE:
            return <Maintenance/>

        case Routes.NOTIFICATIONS:
            return <Notifications />

        case Routes.PLATES:
        case Routes.PLATES_EXPORT:
            return <PlateList />

        case Routes.NON_VIABLE_SAMPLES_EDIT:
            return <NonViableSampleEditPage />

        default:
            if (isBaseRoute(Routes.USER_ACCOUNT, route))
                return <UserAccount/>

            if (route.name.startsWith(Routes.SETTINGS))
                return <PredefinedLists/>

            if (route.name.startsWith(Routes.SCHEDULING))
                return <Scheduling />

            if (route.name.startsWith(Routes.REPORTS))
                return <Reports />

            if (route.name.startsWith(Routes.TRENDS))
                return <Trends />

            return null
    }
}

function mapStateToProps(state: AppState) {
    return {
        route: state.router.route,
        showWarning: state.unsavedChange.showConfirmationModal,
        showReasonModal: state.reason.showReasonModal,
        showSpinner: state.spinner.showSpinner,
        showElectronicSignatureModal: state.reason.showElectronicSignatureModal,
    }
}

type ConnectedProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Shell)
