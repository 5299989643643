import type Page from '_/model/page'
import type { NewPlateComment } from '_/model/plate/comments'
import type * as t  from '_/model/plate/plate'
import type Plate from '_/model/plate/plate'
import type Export from '_/model/export/types'
import { actionFactory } from '_/utils/redux'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.colony-counter')

export const createPlate = action<t.PlateCreate, Promise<void>>('createPlate')
           , savePlate = action<{ id: string, oldPlate: t.PlateEdit, newPlate: t.PlateEdit }, Promise<void>>('savePlate')
           , loadPlate = action<string, Promise<Plate>>('loadPlate')
           , attachImage = action<t.PlateImageAttach, Promise<void>>('attachImage')
           , loadPlates = action<t.PlateListRequest, Promise<Page<Plate>>>('loadPlates')
           , searchPlatesByBarcode = action<string, Promise<t.PlateSearchByBarcodeResult>>('searchPlatesByBarcode')
           , loadPlateTrail = action<string, Promise<AuditTrail>>('loadPlateTrail')
           , downloadPlateImages = action<string, Promise<void>>('getPlateImages')
           , exportPlates = action<t.PlateExportQuery, Promise<Export>>('exportPlates')
           , loadPlatesExports = action<void, Promise<Export[]>>('loadPlatesExports')
           , addPlateComment = action<{ plateId: string, plateComment: NewPlateComment }, Promise<void>>('addPlateComment')
