import { useAction, useEffect, useState } from '_/facade/react'
import * as m from '_/components/modal'
import AuditTrails from '_/components/audit-trail-list'
import { Close } from '_/components/button'
import type { Guid } from '_/model/guid'
import type { DateTime } from '_/model/date-time'

import * as actions from '../day-scheduler/actions'
import type { AuditTrail } from '_/model/audit-trail/types'

interface Props {
    onClose(): void
    sessionId: Guid
    date: DateTime
    id: Guid
}

function AuditTrailModal(props: Props) {
    const trails = useAuditTrail(props.id, props.date, props.sessionId)

    return(
        <m.Modal isOpen onClose={props.onClose}>
            <m.ModalHeader className='pb-0 border-bottom-0'>
                <h4 className='ps-1'>Audit trail</h4>
                <Close onClick={props.onClose} testId='close-audit-trail-modal' />
            </m.ModalHeader>
            <m.ModalBody>
                <AuditTrails
                    trail={trails}
                    hideHeader
                    emptyMessage='There are no records'
                />
            </m.ModalBody>
        </m.Modal>
    )
}

function useAuditTrail(id: Guid, date: DateTime, sessionId: Guid) {
    const load = useAction(actions.loadExpectationTrail)
        , [trails, setTrails] = useState<AuditTrail>()

    useEffect(
        () => {
            load({ id, date, sessionId })
                .then(setTrails)
        },
        [id, date, sessionId, load]
    )

    return trails
}

export default AuditTrailModal
