import type ApiService from '../api-service'
import type TrailService from '../audit-trail-service'
import type Page from '_/model/page'
import type { EntityInfo, GlobalAuditTrails, TrailListQuery } from '_/model/audit-trail/types'

function factory(api: ApiService): TrailService {
    return Object.assign(
        api.resource(['audit-trails']),
        {
            getTrails,
            getDeletedInfo,
        },
    )

    function getTrails(query: TrailListQuery): Promise<Page<GlobalAuditTrails>> {
        return api.post(['audit-trails'], query)
    }

    function getDeletedInfo(info: EntityInfo[]): Promise<string[]> {
        return api.post(['deleted-entities'], { entities:info })
    }
}

export default factory
