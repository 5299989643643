import type { MonitoringOverviewPage, MonitoringOverviewFilter, BookedInSampleData } from '_/model/scheduling/monitoring-overview/types'
import type ApiService from '../api-service'
import type MonitoringOverviewService from '../monitoring-overview-service'

import type TimeService from '_/services/time-service'

import { convertFromServerFields } from './helper'

function factory(api: ApiService, time: TimeService): MonitoringOverviewService {
    return { getMonitoringOverview }

    function getMonitoringOverview(request: MonitoringOverviewFilter): Promise<MonitoringOverviewPage> {
        return api.get<MonitoringOverviewPage>(['monitoring-overview'], request)
            .then(_ => {
                const convertedItems = _.items.map(_ => {
                    const items: BookedInSampleData[] = _.bookedSamples.map((_: any) => convertFromServerFields(time, _))
                    return {..._, bookedSamples: items }
                })
                return { ..._, items: convertedItems }
            })
    }
}

export default factory
