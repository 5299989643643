import { React, useState } from '_/facade/react'

import type { Value as TextValue } from './number-text-input'
import NumberTextInput, { NOT_VALID_VALUE } from './number-text-input'

type Value = number | '' | NOT_VALID_VALUE

interface Props {
    id?: string
    name?: string
    title?: string
    className?: string
    disabled?: boolean
    autoFocus?: boolean
    testId?: string

    value: Value
    onChange: (value: Value) => void
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const NumberInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const [lastChangeValue, setLastChangeValue] = useState<{ parsedValue: Value, textValue: TextValue }>({ parsedValue: '', textValue: '' })
        , textValue = props.value === NOT_VALID_VALUE
            ? lastChangeValue.textValue
            : props.value.toString()

    function handleChange(textValue: TextValue): void {
        const parsedValue = textValue === NOT_VALID_VALUE ? textValue : parseText(textValue as string)

        setLastChangeValue({ textValue, parsedValue })

        props.onChange(parsedValue)
    }

    return (
        <NumberTextInput
            ref={ref}
            {...props}
            value={textValue}
            onChange={handleChange}
            onKeyDown={props.onKeyDown}
            testId={props.testId}
        />
    )
})

export default NumberInput
export {
    NOT_VALID_VALUE,
}
export type {
    Value
}

function parseText(text: string): Value {
    if (text === '')
        return ''

    // greater numbers lose precision when converted with parseFloat
    const isValidNumber = text.replace(/\D/g, '').length <= 15

    return isValidNumber ? Number(text) : NOT_VALID_VALUE
}
