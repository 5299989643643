import { actionFactory } from '_/utils/redux'
import type { Device } from '_/model/predefined-lists/devices/types'
import type { ElectronicSignatureSettings } from '_/model/context/electronic-signature-settings'
import type { Observable } from '_/model/observable'

const action = actionFactory('app.devices')

export const loadDevices = action<string, Promise<Device[]>>('loadDevices')
           , createDevice = action<{id: string, device: Device}, Promise<void>>('createDevice')
           , changeDeviceName = action<{id: string, name: string}, Promise<void>>('changeName')
           , saveDeviceChanges = action<{id: string, oldDevice: Device, newDevice: Device, signatureSettings: ElectronicSignatureSettings[]}, Promise<void>>('saveDeviceChanges')
           , removeDevice = action<{ id: string, signatureSettings: ElectronicSignatureSettings[] }, Promise<void>>('removeDevice')
           , getDevices = action<void, Promise<Observable<Device[]>>>('getDevices')
