import type { ValidationResult} from '_/utils/form/validate'
import { checkDateRangeValidity, required } from '_/utils/form/validate'

import { CUSTOM } from '_/constants/date-ranges'

import type { LocationsReport } from '_/model/reports/locations-report/types'
import type { OperatorsReport } from '_/model/reports/operators-report/types'
import type { MissedMonitoringReport } from './missed-monitoring-report/types'

function validateReportDate(entity: Partial<LocationsReport | OperatorsReport | MissedMonitoringReport>) {
    const result: ValidationResult<LocationsReport | OperatorsReport | MissedMonitoringReport> = {}
        , dateTo = checkDateRangeValidity(entity.exposureStartDateFrom, entity.exposureStartDateTo)
        , exposureDateRange = required('Exposure date range')(entity.exposureDateRange)

    if (dateTo)
        result.exposureStartDateTo = dateTo

    if (exposureDateRange)
        result.exposureDateRange = exposureDateRange

    if (entity.exposureDateRange === CUSTOM) {
        const exposureStartDateFrom = required('From exposure date')(entity.exposureStartDateFrom)
            , exposureStartDateTo = required('To exposure date')(entity.exposureStartDateTo)

        if (exposureStartDateFrom)
            result.exposureStartDateFrom = exposureStartDateFrom

        if (exposureStartDateTo)
            result.exposureStartDateTo = exposureStartDateTo
    }

    return result
}

export {
    validateReportDate,
}
