const BOOKED_IN = 0
    , NO_GROWTH = 1
    , GROWTHS_ID_COMPLETE = 2
    , NULLIFIED = 3
    , GROWTHS_AWAITING_IDENTIFICATION = 4

type SampleStatus = never
    | typeof BOOKED_IN
    | typeof NO_GROWTH
    | typeof GROWTHS_ID_COMPLETE
    | typeof GROWTHS_AWAITING_IDENTIFICATION
    | typeof NULLIFIED

const SAMPLE_STATUS: { id: SampleStatus, name: string }[] = [
    {
        id: BOOKED_IN,
        name: 'Booked in',
    },
    {
        id: NO_GROWTH,
        name: 'No growth',
    },
    {
        id: GROWTHS_AWAITING_IDENTIFICATION,
        name: 'Growths (awaiting identification)',
    },
    {
        id: GROWTHS_ID_COMPLETE,
        name: 'Growths (ID complete)',
    },
    {
        id: NULLIFIED,
        name: 'Nullified',
    },
]

const ALL_STATUS_IDS = SAMPLE_STATUS.map(_ => _.id)
    , DEFAULT_STATUS_IDS = ALL_STATUS_IDS.filter(_ => _ !== NULLIFIED)
    , READ_STATUS_IDS: SampleStatus[] = [GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION, NO_GROWTH]
    , GROWTH_STATUS_IDS: SampleStatus[] = [GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION]

export {
    SAMPLE_STATUS as default,
    BOOKED_IN,
    NO_GROWTH,
    GROWTHS_ID_COMPLETE,
    GROWTHS_AWAITING_IDENTIFICATION,
    NULLIFIED,
    SampleStatus,
    ALL_STATUS_IDS,
    DEFAULT_STATUS_IDS,
    READ_STATUS_IDS,
    GROWTH_STATUS_IDS,
}
