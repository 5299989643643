import type { FormRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { getIn } from 'final-form'
import { React, classnames, useMemo } from '_/facade/react'
import { showFieldError } from '_/components/form/helpers'
import { CheckboxField } from '_/components/form'
import OptionalDate from '_/components/optional-date'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'
import DateInput from '_/components/form/local-date-form-control'
import { useTimeService } from '_/components/time'
import FormattedText from '_/features/text/formatted-text'
import { formatInlineExposureTime } from '../../helpers'
import { updateEndTimeEndDateFields } from '_/features/samples/sample-edit/helpers'
import { SETTLE_PLATE } from '_/constants/plate-type'
import * as fieldIndex from '_/constants/custom-field-index'
import type { SampleEditedInfo } from '_/model/sample/sample'
import EditedLabel from '../edited-label'
import { greaterThan } from '_/model/date-time'

import { validateViableDates, validateNonViableDates } from '../../validate'
import { findFieldValuePos, valuePath, notRecordedPath, getFieldValue } from '../../helpers'

import FieldForm from '../field-form'
import Error from '_/components/form/field-error'
import InlineActions from '../inline-actions'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'

interface OwnProps {
    entity: SampleDetailsEdit | NonViableSampleEdit
    fields: CustomField[]
    onSubmit: (sample: SampleDetailsEdit | NonViableSampleEdit) => Promise<void>
    hasNoPermissions: boolean
    sampleTypes: SampleType[]
    startTimeEditedInfo: SampleEditedInfo | undefined
    endTimeEditedInfo: SampleEditedInfo | undefined
    isViable?: boolean
}

function ExposureTimeForm(props: OwnProps) {
    const startDatePosition = findFieldValuePos(props.entity.fields, fieldIndex.EXPOSURE_START_DATE)
        , startTimePosition = findFieldValuePos(props.entity.fields, fieldIndex.EXPOSURE_START_TIME)
        , endDatePosition = findFieldValuePos(props.entity.fields, fieldIndex.EXPOSURE_END_DATE)
        , endTimePosition = findFieldValuePos(props.entity.fields, fieldIndex.EXPOSURE_END_TIME)
        , endTimeCustomField = props.fields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)
        , endTimeCustomFieldSettings = props.isViable ? endTimeCustomField?.viableSettings : endTimeCustomField?.nonViableSettings
        , startTimeCustomField = props.fields.find(_ => _.index === fieldIndex.EXPOSURE_START_TIME)
        , startTimeCustomFieldSettings = props.isViable ? startTimeCustomField?.viableSettings : startTimeCustomField?.nonViableSettings
        , timeService = useTimeService()
        , differentDateName = 'differentDateName'
        , fieldsRefs = useMemo(
            () => {
                const result = new Map<string, React.RefObject<HTMLInputElement>>()

                const startTimeField = props.fields.find(_ => _.index === fieldIndex.EXPOSURE_START_TIME)
                    , endTimeField = props.fields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)

                if (startTimeField && startTimeField.viableSettings.notRecorded)
                    result.set(notRecordedPath(startTimePosition), React.createRef<HTMLInputElement>())

                if (endTimeField && endTimeField.viableSettings.notRecorded) {
                    result.set(notRecordedPath(endTimePosition), React.createRef<HTMLInputElement>())
                    result.set(valuePath(endTimePosition), React.createRef<HTMLInputElement>())
                }

                result.set(differentDateName, React.createRef<HTMLInputElement>())

                return result
            },
            [props.fields, startTimePosition, endTimePosition]
        )

    function getEditedInfo() {
        if (props.startTimeEditedInfo === undefined || props.endTimeEditedInfo === undefined)
            return props.startTimeEditedInfo ?? props.endTimeEditedInfo

        return greaterThan(props.startTimeEditedInfo.lastEditedAt, props.endTimeEditedInfo.lastEditedAt)
            ? props.startTimeEditedInfo
            : props.endTimeEditedInfo
    }

    function handleChange(sample: Partial<SampleDetailsEdit>, form: FormRenderProps) {
        const fields = sample.fields
            , startTimeField = fields && fields[startTimePosition]
            , startTimeFieldValue = startTimeField && startTimeField.value
            , startTimeNotRecorded = startTimeField && startTimeField.notRecorded
            , endTimeField = fields && fields[endTimePosition]
            , endTimeFieldValue = endTimeField && endTimeField.value
            , endTimeNotRecorded = endTimeField && endTimeField.notRecorded
            , startDateField = fields && fields[startDatePosition]
            , startDateFieldValue = startDateField && startDateField.value

        if (startTimeField && startTimeNotRecorded && startTimeCustomField) {
            if (!startTimeCustomFieldSettings?.notRecorded) // in case when field was N/R but later admin changed settings and N/R is not allowed anymore
                form.form.change(notRecordedPath(startTimePosition), false)

            if (startTimeCustomFieldSettings?.notRecorded && startTimeFieldValue !== undefined)
                form.form.change(valuePath(startTimePosition), undefined)
        }

        if (endTimeField && endTimeNotRecorded && endTimeCustomField) {
            if (!endTimeCustomFieldSettings?.notRecorded) // in case when field was N/R but later admin changed settings and N/R is not allowed anymore
                form.form.change(notRecordedPath(endTimePosition), false)

            if (endTimeCustomFieldSettings?.notRecorded && endTimeFieldValue !== undefined) {
                form.form.change(valuePath(endTimePosition), undefined)
                form.form.change(valuePath(endDatePosition), startDateFieldValue)
            }
        }
    }

    function handleSubmit(subEntity: SampleDetailsEdit) {
        const fields = subEntity.fields
            , sampleEdit = { ...props.entity,  fields }

        return props.onSubmit(sampleEdit)
    }

    function isNotRecorded(form: FormRenderProps, pos: number) {
        const notRecordedState = form.form.getFieldState(notRecordedPath(pos))
        return !!(notRecordedState && notRecordedState.value)
    }

    function getRef(pos: number, notRecorded: boolean) {
        const path = notRecorded
            ? notRecordedPath(pos)
            : valuePath(pos)

        return fieldsRefs.get(path)
    }

    function getStartDate(form: FormRenderProps) {
        const startDatePos = form.values.fields.findIndex((_: any) => _ && _.index === fieldIndex.EXPOSURE_START_DATE)
            , startDateName = valuePath(startDatePos)
            , startDate = getIn(form.values, startDateName)

        return startDate
    }

    const isSettlePlate = getFieldValue(props.entity.fields, fieldIndex.PLATE_TYPE) === SETTLE_PLATE
        , canEditEndTime = !props.isViable || isSettlePlate || endTimeCustomFieldSettings?.required
        , initialValues = useMemo(
            () => {
                const sampleTypePosition = findFieldValuePos(props.entity.fields, fieldIndex.SAMPLE_TYPE_ID)
                    , subFields = [
                        startDatePosition,
                        startTimePosition,
                        endDatePosition,
                        endTimePosition,
                        sampleTypePosition,
                    ]

                return {
                    fields: updateEndTimeEndDateFields(props.entity.fields, props.fields, isSettlePlate, !!props.isViable), subFields,
                }
            },
            [props.entity.fields, isSettlePlate, props.fields, props.isViable, startDatePosition, startTimePosition, endDatePosition, endTimePosition]
        )

    return (
        <FieldForm
            formId='exposure-time'
            label='Exposure time'
            testId={`sample-field-${fieldIndex.EXPOSURE_DATE}`}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onChange={handleChange}
            validate={_ => props.isViable
                ? validateViableDates(timeService, _.fields ?? [], props.fields, props.sampleTypes)
                : validateNonViableDates(timeService, _.fields ?? [], props.fields)
            }
            hasNoPermissions={props.hasNoPermissions}
            renderView={() =>
                <span className='form-control-plaintext'>
                    <span className='fw-bold'>
                        <FormattedText text={formatInlineExposureTime(props.entity, timeService, props.fields, !!props.isViable)} />
                        <EditedLabel editedInfo={getEditedInfo()}/>
                    </span>
                </span>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div>
                    <div className='d-flex mb-3'>
                        <Field name={valuePath(startDatePosition)} render={_ =>
                            <div className='flex-fill'>
                                <DateInput
                                    id={`field${fieldIndex.EXPOSURE_END_DATE}`}
                                    {..._.input}
                                    className={classnames({ 'is-invalid': showFieldError(_.meta) })}
                                    disabled={false}
                                    useDayEndTime={false}
                                    autoFocus
                                    testId={`field-${fieldIndex.EXPOSURE_START_DATE}`}
                                />
                                <Error field={_} />
                            </div>
                        }/>
                    </div>
                    <div className='d-flex'>
                        <div className='flex-fill'>
                            <Field name={valuePath(startTimePosition)} type='time' render={_ =>
                                <>
                                <input
                                    id={`field${fieldIndex.EXPOSURE_START_TIME}`}
                                    type='time'
                                    disabled={isNotRecorded(form, startTimePosition)}
                                    className={classnames('form-control', {
                                        'is-invalid': showFieldError(_.meta),
                                    })}
                                    {..._.input}
                                    onKeyDown={_ => focusElementOnAltNInput(getRef(startTimePosition, true), _)}
                                    data-testid={`field-${fieldIndex.EXPOSURE_START_TIME}`}
                                />
                                    <Error field={_} />
                                </>
                            }/>
                            {startTimeCustomFieldSettings?.notRecorded &&
                                <CheckboxField
                                    id={notRecordedPath(startTimePosition)}
                                    name={notRecordedPath(startTimePosition)}
                                    tabIndex={isNotRecorded(form, startTimePosition) ? 0 : -1}
                                    inputRef={getRef(startTimePosition, true)}
                                    testId={`field-${fieldIndex.EXPOSURE_START_TIME}-not-recorded`}
                                >
                                    Not recorded
                                </CheckboxField>
                            }
                        </div>
                        <label htmlFor={props.fields[endTimePosition].fieldName} className='col-form-label px-1 text-secondary text-nowrap'>to</label>
                        <div className='flex-fill'>
                            <Field name={valuePath(endTimePosition)} type='time' render={_ =>
                                <>
                                    <input
                                        id={`field${fieldIndex.EXPOSURE_END_TIME}`}
                                        type='time'
                                        disabled={isNotRecorded(form, endTimePosition) || !canEditEndTime}
                                        className={classnames('form-control', {
                                            'is-invalid': showFieldError(_.meta),
                                        })}
                                        {..._.input}
                                        onKeyDown={_ => focusElementOnAltNInput(fieldsRefs.get(differentDateName), _)}
                                        ref={getRef(endTimePosition, false)}
                                        data-testid={`field-${fieldIndex.EXPOSURE_END_TIME}`}
                                    />
                                    <Error field={_} />
                                </>
                            }/>
                            <OptionalDate
                                disabled={isNotRecorded(form, endTimePosition) || !canEditEndTime}
                                dateFieldId={`field${fieldIndex.EXPOSURE_END_DATE}`}
                                dateFieldName={valuePath(endDatePosition)}
                                form={form}
                                endTimeRef={getRef(endTimePosition, false)!}
                                startDate={getStartDate(form)}
                                forceHideDateField={!!(isNotRecorded(form, endTimePosition) || !canEditEndTime)}
                                testId={`field-${fieldIndex.EXPOSURE_END_DATE}`}
                                differentDateRef={fieldsRefs.get(differentDateName)}
                                onKeyDown={_ => focusElementOnAltNInput(getRef(endTimePosition, true), _)}
                            />
                            {endTimeCustomFieldSettings?.notRecorded &&
                                <CheckboxField
                                    id={notRecordedPath(endTimePosition)}
                                    name={notRecordedPath(endTimePosition)}
                                    tabIndex={isNotRecorded(form, endTimePosition) ? 0 : -1}
                                    inputRef={getRef(endTimePosition, true)}
                                    disabled={!canEditEndTime}
                                    testId={`field-${fieldIndex.EXPOSURE_END_TIME}-not-recorded`}
                                    onKeyDown={_ => focusElementOnAltNInput(fieldsRefs.get(differentDateName), _)}
                                >
                                    Not recorded
                                </CheckboxField>
                            }
                        </div>
                    </div>
                    <div className='float-end'>
                        <InlineActions form={form} onSave={handleSave} onCancel={handleCancel} testId={`sample-field-${fieldIndex.EXPOSURE_DATE}`} />
                    </div>
                </div>
            }
        />
    )
}

export default ExposureTimeForm
