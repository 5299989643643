import { commonReducer, handler } from '_/facade/reducer'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import { predefinedListLoaded } from '../redux/actions'
import * as tree from '_/utils/tree'
import * as actions from './actions'

import { allChildrenIds } from '../helpers'

const exposureLocationsDefaultState = tree.emptyTree<ExposureLocation>()
    , exposureLocations = commonReducer(
        exposureLocationsDefaultState,

        handler(actions.exposureLocationListLoaded, (_, payload) => tree.tree(payload)),
        handler(
            actions.exposureLocationRemoved,
            (state, id) => {
                const childrenIds = allChildrenIds(state, id)
                    , list = tree.list(state).filter(_ => _.id !== id && !childrenIds.some(children => children === _.id))
                    , exposureLocations = tree.tree(list)
                return exposureLocations
            }
        ),
        handler(predefinedListLoaded, (_, payload) => payload.exposureLocations),
    )

export {
    exposureLocations,
}
