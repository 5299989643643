import { classnames } from '_/facade/react'
import * as bt from '_/constants/sample-breach-type'

interface Props {
    count?: number
    withRef?: boolean
    breachType: Exclude<bt.BreachTypeWithCompromised, typeof bt.NONE>
    isNonViable?: boolean
}

function LimitBreachIcon(props: Props) {
    const hasCount = props.count !== undefined && props.count > 0
    return (
        <div
            className={classnames(
                'mb-1 limit-breach-timeline__chip',
                {'limit-breach-timeline__chip--with-count': hasCount},
                getStyle(props.breachType, props.withRef)
            )}
        >
            <span>{formatSymbol(props.breachType, props.isNonViable)}</span>
            {hasCount && <span> {formatCount(props.count ?? 0)}</span>}
        </div>
    )
}

export default LimitBreachIcon

function getStyle(breachType: Exclude<bt.BreachTypeWithCompromised, typeof bt.NONE>, withRef?: boolean) {
    switch (breachType) {
        case bt.ACTION_LIMIT:
            return withRef ? 'limit-breach-timeline__chip--action-outline' : 'limit-breach-timeline__chip--action'
        case bt.ALERT_LIMIT:
            return withRef ? 'limit-breach-timeline__chip--alert-outline' : 'limit-breach-timeline__chip--alert'
        case bt.COMPROMISED:
            return withRef ? 'limit-breach-timeline__chip--compromised-outline' : 'limit-breach-timeline__chip--compromised'
    }
}

function formatSymbol(breachType: bt.BreachTypeWithCompromised, isNonViable?: boolean) {
    const entityTypeSymbol = isNonViable ? 'N' : 'V'

    return breachType === bt.COMPROMISED
        ? 'C'
        : `${entityTypeSymbol}${breachType === bt.ACTION_LIMIT ? '!' : ''}`
}

function formatCount(count: number) {
    const thousands = Math.trunc(count / 1000)

    return thousands >= 1
        ? `${thousands}k`
        : count
}
