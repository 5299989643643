import { useAction } from '_/facade/react'
import * as imageActions from '_/features/predefined-lists/floor-plan/actions'
import * as analysisActions from '_/features/analysis/actions'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import type { AnalysisFilter } from '_/model/analysis/filter/types'
import { PLOT_NAME } from './messages'
import { useTimeService } from '_/components/time'
import type { PredefinedLists } from '_/model/app-state'
import { normalizeFilteredFields } from './ui/helpers'

function useImageTokensDownloader() {
    const getImageTokens = useAction(imageActions.getImageTokens)

    function downloader(floorPlans: FloorPlan[]) {
        const promises = floorPlans.map(f => getImageTokens(f.imageId)
            .then(tokens =>
                ({
                    src: tokens['original'],
                    floorPlanId: floorPlans.find(f => f.imageId === tokens.imageId)!.id,
                })
            )
        )

        return Promise.all(promises)
    }

    return downloader
}

function useExportGraphData(filter: AnalysisFilter, predefinedLists: PredefinedLists) {
    const exportCsv = useAction(analysisActions.exportGraphCsv)
        , exportGraphData = useAction(analysisActions.exportAnalysisGraphData)
        , timeService = useTimeService()

    return (graph: Blob) => exportCsv(normalizeFilteredFields(filter, predefinedLists))
        .then(_ => exportGraphData([_, {blob: graph, filename: `${PLOT_NAME} ${timeService.formatCtzDate(timeService.now())}.png`}]))
}

export {
    useImageTokensDownloader,
    useExportGraphData,
}
