import type { FieldArrayRenderProps} from 'react-final-form-arrays'
import { useFieldArray } from 'react-final-form-arrays'
import { useState, useEffect, useReducer } from '_/facade/react'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import type { FloorPlanLocationForm } from '_/model/floor-plan/editor/types'
import * as g from '_/model/floor-plan/geometry'
import { drawLocation } from '_/model/floor-plan/draw'
import BLANK_IMAGE from '_/constants/blank-image'

import LocationMenu from './location-menu'

import { useImageSource } from '../hooks'

interface Props {
    floorPlan: FloorPlan
    focusedIndex: number | undefined
    locationMenuRef: React.RefObject<HTMLDivElement>
    onFocus: (_: number | undefined) => void
    imageLoadCompleted: boolean
    onImageLoadCompleted: () => void
}

function Plotter(props: Props) {
    const plan = props.floorPlan
        , fields = useFieldArray<FloorPlanLocationForm>('locations', { isEqual: areLengthsEqual })
        , src = useImageSource(plan.imageId, 'original')
        , [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
        , redraw = useLocationRenderer(canvas, fields.fields.value, props.focusedIndex)
        , handleClick = useClickHandler(fields, props.onFocus)

    function handleImageLoad() {
        redraw()

        if (src !== BLANK_IMAGE)
            props.onImageLoadCompleted()
    }

    function handleImageError() {
        props.onImageLoadCompleted()
    }

    return(
        <>
            <img className='floor-plans-image' src={src} onLoad={handleImageLoad} onError={handleImageError} />

            {props.imageLoadCompleted &&
                <canvas
                    ref={setCanvas}
                    className='floor-plans-image'
                    width={plan.width}
                    height={plan.height}
                    onClick={handleClick}
                    data-testid='floor-plan-image-plotter'
                />
            }

            {canvas && props.focusedIndex !== undefined &&
                <LocationMenu
                    canvas={canvas}
                    focusedIndex={props.focusedIndex}
                    onFocus={props.onFocus}
                    locationsField={fields}
                    locationMenuRef={props.locationMenuRef}
                />
            }
        </>
    )
}

export default Plotter

function useLocationRenderer(canvas: HTMLCanvasElement | null, locations: FloorPlanLocationForm[], focusedIndex: number | undefined) {
    const [, redraw] = useReducer(() => ({}), {})

    useEffect(
        () => {
            if (!canvas)
                return

            const ctx = canvas.getContext('2d')!
                , ratio = g.plotScaleRatio(canvas)

            locations.forEach((location, idx) => {
                const p = location.position

                drawLocation(
                    ctx,
                    [p.x, p.y],
                    ratio,
                    idx === focusedIndex
                        // color picked from border of selected input
                        ? 'rgb(128, 189, 255)'
                        : undefined
                )
            })

            return () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height)
            }
        }
    )

    return redraw
}

function useClickHandler(fields: FieldArrayRenderProps<FloorPlanLocationForm, HTMLElement>, onFocus: (_: number | undefined) => void) {
    const locations = fields.fields.value

    function handleClick(event: React.MouseEvent<HTMLCanvasElement>): void {
        const xy = g.calcCanvasCoordinates(event)
            , ratio = g.plotScaleRatio(event.target as HTMLCanvasElement)
            , location = g.findLocation(xy, locations, ratio)

        if (location) {
            onFocus(locations.indexOf(location))
            return
        }

        fields.fields.push({
            position: {
                x: xy[0],
                y: xy[1],
            },
        })
        onFocus(locations.length)
    }

    return handleClick
}

function areLengthsEqual(one: any[] | undefined, two: any[] | undefined): boolean {
    return one?.length === two?.length
}
