import { useState } from '_/facade/react'

import Button from '_/components/button'
import Menu from '_/components/overlay/menu'

interface ApiKeysCellDataProps {
    isActive: boolean
    onDelete: () => void
    onSubmit: () => void
    hasNoPermissions: boolean
    testId?: string
}

function ApiKeysCellData(props: ApiKeysCellDataProps) {
    const [showApiKeyActionButtons, setShowApiKeyActions] = useState(false)
        , [buttonElement, setButtonElement] = useState<HTMLButtonElement | null>(null)

    function hideApiKeyActions() {
        document.removeEventListener('click', hideApiKeyActions)

        setShowApiKeyActions(false)
    }

    function showApiKeyActions() {
        setTimeout(() => document.addEventListener('click', hideApiKeyActions))

        setShowApiKeyActions(true)
    }

    return (
        <td className='text-end dropdown'>
            <Button
                ref={setButtonElement}
                onClick={!showApiKeyActionButtons ? showApiKeyActions : hideApiKeyActions}
                className='py-0 px-1 bg-white'
                hasNoPermissions={props.hasNoPermissions}
                testId={`${props.testId}-show-menu`}
            >
                <i className='material-icons'>more_horiz</i>
            </Button>

            {showApiKeyActionButtons &&
                <Menu element={buttonElement} useParentWidth>
                    <div className='dropdown-menu dropdown-menu-right d-block'>
                        <Button
                            className='btn-link d-block text-end'
                            onClick={props.onSubmit}
                            testId={`${props.testId}-change-active`}
                        >
                            {props.isActive ? 'Deactivate' : 'Reactivate'}
                        </Button>
                        <Button
                            className='btn-link d-block text-end'
                            onClick={props.onDelete}
                            testId={`${props.testId}-delete`}
                        >
                            Delete
                        </Button>
                    </div>
                </Menu>
            }
        </td>
    )
}

export default ApiKeysCellData
