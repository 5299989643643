import createRouter from 'router5'
import browserPlugin from 'router5-plugin-browser'
import * as Routes from '_/constants/routes'

import appRoutes from './routes'
import middleware from './router-middleware'
import type { Params } from 'router5/dist/types/base'
import { LINK_CONTEXT } from './transition-interceptor/link-context-interceptor'

const routes = appRoutes.map(_ => ({
    name: _.name,
    path: _.path,
    forwardTo: _.forwardTo,
    decodeParams: _.parameterTypes
        ? (p: Params) => {
            let params = { ...p }

            _.parameterTypes?.forEach(([prop]) => {
                if (params[prop] === undefined)
                    return
            })

            return params
        }
        : undefined
}))

function factory() {
    const router = createRouter(routes, { defaultRoute: Routes.LOG_IN })

    router.usePlugin(browserPlugin())
    router.useMiddleware(middleware)

    router.setRootPath(`?${LINK_CONTEXT}`)

    return router
}

export default factory
