import type { ValidationResult } from '_/utils/form/validate'
import { required } from '_/utils/form/validate'

import  generalValidate from '../validate'
import type { FloorPlanForm } from './types'
import type FloorPlan from '../floor-plan'

export default function validate(value: FloorPlanForm, plans: FloorPlan[]): ValidationResult<FloorPlanForm> {
    const generalValidationResult = generalValidate(value, plans)
        , imageRequired = required('Floor plan')(value.image) || undefined
        , imageUploading = value.image && !value.image.uploaded
            ? 'Floor plan is being uploaded'
            : undefined
        , image = imageRequired || imageUploading

    return { ...generalValidationResult, image }
}
