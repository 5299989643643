import { actionFactory } from '_/utils/redux'
import type { ExposureLocationEdit } from '_/model/predefined-lists/exposure-location/exposure-location'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.exposure-locations')

export const loadExposureLocationList = action<void, Promise<void>>('loadExposureLocationList')
           , exposureLocationListLoaded = action<ExposureLocation[]>('exposureLocationListLoaded')
           , loadExposureLocationTrail = action<string, Promise<AuditTrail>>('loadExposureLocationTrail')
           , removeExposureLocation = action<string, Promise<void>>('removeExposureLocation')
           , exposureLocationRemoved = action<string>('exposureLocationRemoved')
           , createExposureLocation = action<ExposureLocationEdit, Promise<void>>('createExposureLocation')
           , saveExposureLocation = action<{ id: string, oldExposureLocation: Partial<ExposureLocationEdit>, newExposureLocation: Partial<ExposureLocationEdit> }, Promise<void>>('saveExposureLocation')
           , loadExposureLocation = action<string, Promise<ExposureLocation>>('loadExposureLocation')
