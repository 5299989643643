import { actionFactory } from '_/utils/redux'
import * as confirmationType from '_/constants/confirmation-type'
import type Confirmation from '_/model/confirmation/types'
import type { Text } from '_/model/text/text'

const action = actionFactory('app.confirmation')

export const showGenericConfirmationModal = action<Confirmation, Promise<void>>('showGenericConfirmationModal')
           , confirmationModalOpened = action<Confirmation, Promise<void>>('confirmationModalOpened')
           , confirmationModalClosed = action<void>('confirmationModalClosed')
           , confirmAction = action<void, void>('confirmAction')
           , cancelAction = action<void, void>('cancelAction')

export function showConfirmationModal(message: string, warning?: string) {
    return showGenericConfirmationModal({message, type: confirmationType.CONFIRMATION_MODAL, warning})
}
showConfirmationModal.type = showGenericConfirmationModal.type

export function showDeletionConfirmationModal(message: string | Text, warning?: string) {
    return showGenericConfirmationModal({message, type: confirmationType.DELETION_CONFIRMATION_MODAL, warning})
}
showDeletionConfirmationModal.type = showGenericConfirmationModal.type

export function showDisableConfirmationModal(message: string) {
    return showGenericConfirmationModal({ message, type: confirmationType.DISABLE_CONFIRMATION_MODAL })
}
showDisableConfirmationModal.type = showGenericConfirmationModal.type

export function showWarningModal(message: string) {
    return showGenericConfirmationModal({ message, type: confirmationType.WARNING_MODAL })
}
showDisableConfirmationModal.type = showGenericConfirmationModal.type
