import type { ObjectionableOrganismForm, ObjectionableOrganismEdit } from '_/model/objectionable-organisms/editor/types'

function convertObjectionableOrganismFormToEdit(entity: ObjectionableOrganismForm): ObjectionableOrganismEdit {
    return {
        identification: {
            type: entity.identificationType!,
            value: entity.identificationValue!,
        },
        gradeIds: entity.gradeIds!,
    }
}

export {
    convertObjectionableOrganismFormToEdit,
}