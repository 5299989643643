import { useAction } from '_/facade/react'
import { actions as routerActions } from 'redux-router5'

import type * as routes from '_/constants/routes'
import * as insightActions from '_/features/insights/actions'

function useNavigateTo(id: string) {
    const markAsSeen = useAction(insightActions.markAsSeen)
        , navigateToAction = useAction(routerActions.navigateTo)

    return (routeName: routes.RouteName, routeParams: any) =>
        markAsSeen(id).then(() => navigateToAction(routeName, routeParams))
}

export {
    useNavigateTo,
}
