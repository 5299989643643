import { classnames } from '_/facade/react'
import { useField } from 'react-final-form'

import DateInput from './utc-date-form-control'
import { showFieldError } from './helpers'

interface InputFieldProps {
    id?: string
    name: string
    useDayEndTime?: boolean
    children?: React.ReactNode
    disabled?: boolean
}

function UtcDateField(props: InputFieldProps) {
    const field = useField(props.name)
    return (
        <div>
            <label htmlFor={props.id} className='col-form-label'>{props.children}</label>
            <DateInput
                id={props.id}
                {...field.input}
                onChange={v => {
                    field.input.onBlur()
                    field.input.onChange(v)
                }}
                className={classnames({ 'is-invalid': showFieldError(field.meta) })}
                useDayEndTime={props.useDayEndTime}
                disabled={props.disabled}
            />
            {showFieldError(field.meta) && <span className='invalid-feedback' data-testid='validation-error'>{field.meta.error}</span>}
        </div>
    )
}

export default UtcDateField
