import { Form } from 'react-final-form'

import { useAction } from '_/facade/react'
import * as emailNotificationFrequency from '_/model/notification-settings/email-notification-frequency'
import RadioGroupField from '_/components/form/radio-group-field'
import FormChangesObserver from '_/components/form/form-changes-observer'

import { CHANGES_SAVED } from '_/features/samples/messages'

import * as actions from './actions'
import * as toastActions from '_/features/toasts/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import type { ChangeEmailNotificationFrequency } from '_/model/users/types'
import { memoize } from '_/utils/function'

interface Props {
    userId: string
    emailNotificationFrequency: emailNotificationFrequency.EmailNotificationFrequency
}

const calcInitialValues = memoize(
    (userId: string, emailNotificationFrequency: emailNotificationFrequency.EmailNotificationFrequency) => ({
        id: userId,
        emailNotificationFrequency: emailNotificationFrequency,
    })
)

function EmailNotificationFrequencyBlock(props: Props) {
    const initialValues = calcInitialValues(props.userId, props.emailNotificationFrequency)
        , changeEmailNotificationFrequency = useAction(actions.changeEmailNotificationFrequency)
        , addSuccess = useAction(toastActions.addSuccess)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , emailNotificationOptions = [
            { id: emailNotificationFrequency.INDIVIDUAL, name: 'Receive notifications in individual emails' },
            { id: emailNotificationFrequency.DAILY_SUMMARY, name: 'Receive a daily summary email' },
        ]


    function handleSave(_: ChangeEmailNotificationFrequency) {
        return changeEmailNotificationFrequency(_)
            .then(() => addSuccess(CHANGES_SAVED))
            .then(() => hasUnsavedChanges(false, 'email-notification-frequency'))
    }

    return (
        <div className='mb-3'>
            <p className='fw-bold' data-testid='email-frequency-section'>Email notification frequency</p>
            <p className='text-muted'>This setting applies to any notifications that you choose to receive by email.</p>
            <Form
                onSubmit={handleSave as any} // not used, but required
                initialValues={initialValues}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <FormChangesObserver form={form} onChange={handleSave} target={'email-notification-frequency'} />
                        <RadioGroupField
                            id='radio'
                            name='emailNotificationFrequency'
                            entities={emailNotificationOptions}
                            calcValue={_ => _.id}
                            calcLabel={_ => _.name}
                            testId='email-notification-frequency'
                        />
                    </form>
                }
            />
        </div>
    )
}

export default EmailNotificationFrequencyBlock
