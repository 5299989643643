import { commonReducer, handler } from '_/facade/reducer'
import type { PredefinedLists as State } from '_/model/app-state'

import { predefinedListLoaded } from '../redux/actions'
import * as actions from './actions'

const customFieldsDefaultState: State['customFields'] = []
    , customFields = commonReducer(
        customFieldsDefaultState,

        handler(actions.customFieldsLoaded, (_, payload) => payload.filter(_ => _.viableSettings.isActive)),
        handler(predefinedListLoaded, (_, payload) =>
            payload.includeInactiveCustomFields
                ? payload.customFields
                : payload.customFields.filter(_ => _.viableSettings.isActive)
        ),
    )

export {
    customFields,
}
