import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'

import type Api from '_/services/insight-service'
import * as a from './actions'

const factory = (api: Api): EffectsFactory => _ => [
    handler(a.loadTopInsight, api.getTopInsight),
    handler(a.dismiss, api.dismiss),
    handler(a.markAsSeen, api.markAsSeen),
    handler(a.markAsShown, api.markAsShown),
]

export default factory
