const SMART_CONTROL = 0
    , SSO = 1

type UserCredentialsType = typeof SMART_CONTROL | typeof SSO

export {
    UserCredentialsType,
    SMART_CONTROL,
    SSO,
}
