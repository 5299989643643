import type { ValidationResult} from '_/utils/form/validate'
import { required, maxLength, isEmpty, allowedName } from '_/utils/form/validate'
import type { FormExposureLocation } from './exposure-location'
import type ExposureLocation from './exposure-location'

export default function validate(entity: Partial<FormExposureLocation>, usedNames: string[]) {
    const result: ValidationResult<ExposureLocation> = {}
        , name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 100)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , duplicateName = entity.name && searchDuplicateName(entity, usedNames)
        , grade = !entity.ungraded && required('Grade')(entity.gradeId)

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (notAllowedNames)
        result.name = notAllowedNames

    if (duplicateName)
        result.name = `Location with name '${entity.name?.trim()}' already exists`

    if (grade)
        result.gradeId = grade

    return result
}

export function searchDuplicateName(entity: Partial<ExposureLocation>, sameParentLocationNames: string[]) {
    return sameParentLocationNames.some(_ => _.trim().toUpperCase() === entity.name?.trim().toUpperCase())
}
