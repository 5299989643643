import { useField } from 'react-final-form'
import { classnames, useState } from '_/facade/react'
import { generateArray } from '_/utils/array'

import { LinkButton } from '_/components/link'
import { CheckboxField, showFieldError } from '_/components/form'
import * as routes from '_/constants/routes'

interface Props {
    disabled: boolean
    whitelistDisabled: boolean
    hasNoPermissions: boolean
}

function IpWhitelist(props: Props) {
    const [divElement, setDivElement] = useState<HTMLDivElement | null>(null)
        , startPosition = 1
        , maxRows = 4
        , defaultVisibleRowsAmount = maxRows + 1
        , [whitelistRowsCount, setWhitelistRowsCount] = useState(defaultVisibleRowsAmount)
        , textareaColumnNumbersArray = generateArray(whitelistRowsCount, i => startPosition + i)
        , textareaField = useField<string | undefined>('ipRestriction')

    function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        setWhitelistRowsCount(Math.max(event.target.value.length, defaultVisibleRowsAmount))
        textareaField.input.onChange(event)
    }

    return (
        <div className='mt-2'>
            <h5>IP whitelist</h5>

            <p>
                SmartControl can limit users to a whitelist of internet Protocol (IP) addresses.
                Users will only be allowed to sign in if they are connecting from a whitelisted IP address.
            </p>

            <div className='position-relative'>
                <div
                    ref={setDivElement}
                    className={classnames(
                        'position-absolute text-end text-muted overflow-hidden ip-restriction__side-number-area py-1',
                        { 'border-danger': showFieldError(textareaField.meta) },
                    )}
                >
                    {textareaColumnNumbersArray
                        .map(i => <div key={i} className={classnames('px-1', { 'text-danger': showFieldError(textareaField.meta) })}>{i}</div>)
                    }
                </div>
                <textarea
                    onScroll={_ => divElement!.scrollTop = _.currentTarget.scrollTop}
                    rows={maxRows}
                    className={classnames('form-control ip-restriction__text-area py-1', { 'border-danger is-invalid': showFieldError(textareaField.meta) })}
                    disabled={props.hasNoPermissions || props.disabled || props.whitelistDisabled}
                    {...textareaField.input}
                    onChange={handleChange}
                    data-testid='field-whitelist'
                />
                {showFieldError(textareaField.meta)
                    ? <span className='invalid-feedback' data-testid='validation-error'>{textareaField.meta.error}</span>
                    : <div className='invisible mb-4'/>
                }
            </div>

            <CheckboxField
                name='ipWhitelistEnabled'
                id='ipWhitelistEnabled'
                disabled={props.disabled}
                hasNoPermissions={props.hasNoPermissions}
                testId='field-restrict-access'
            >
                Restrict SmartControl access using IP whitelist
            </CheckboxField>

            <p className='mt-2 mb-0 text-secondary'>
                Add one allowed IP address or IP address range per line. IPv4 and IPv6 addresses are supported.
                <LinkButton
                    routeName={routes.HELP}
                    routeParams={{ page: '/tutorials-sc/site_admin/manage_sites/' }}
                    className='btn-link ps-1 py-0 align-baseline'
                >
                    Learn more
                </LinkButton>
            </p>
        </div>
    )
}

export default IpWhitelist
