import { addError } from '_/features/toasts/actions'
import { dropFields } from '_/utils/object'
import { getDefaultRoute } from '../helpers'
import type TransitionInterceptor from './transition-interceptor'

const LINK_CONTEXT = 'linkContext'

const linkContextInterceptor: TransitionInterceptor = (_, store, toState) => {
    const auth = store.getState().auth
        , currentContext = auth.user?.membership.contextId
        , permissions = auth.permissions
        , linkContext = toState.params[LINK_CONTEXT]

    if (linkContext === undefined || currentContext === undefined)
        return true

    if (currentContext !== linkContext) {
        store.dispatch(addError('You are not in the context this link is for'))
        return Promise.reject({
            redirect: { name: getDefaultRoute(permissions) }
        })
    }

    return Promise.reject({
        redirect: {
            name: toState.name,
            params: dropFields(toState.params, LINK_CONTEXT),
        }
    })
}

export default linkContextInterceptor

export { LINK_CONTEXT }
