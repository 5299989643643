import type { FingerdabOverviewByOperator } from '_/model/fingerdab-overview-by-operator/types'
import type { SampleSession } from '_/model/predefined-lists/session/types'

import { getSampleData, isSamplesWithActiveSession } from '_/features/monitoring-overview/helpers'
import { formatActiveState } from '_/utils/format/common'
import { getFieldValue } from '_/features/samples/helpers'
import * as fieldIndex from '_/constants/custom-field-index'
import type { SampleData } from '_/model/fingerdab-overview-by-operator/types'


function getFingerdabOverviewByOperator(items: FingerdabOverviewByOperator[], sampleSession: SampleSession[]) {
    return items.map(item => {
        const samples = getSampleData(item.samples, sampleSession, isSampleSessionApplicable, areOtherSampleSessionApplicable)
            , operatorName = item.operatorName ? formatActiveState(item.operatorName, item.isActive) : undefined

        return { operatorName, samples }
    })
}

function isSampleSessionApplicable<T extends SampleData>(sample: T, session: SampleSession) {
    return getFieldValue(sample.fields, fieldIndex.SESSION_NAME) === session.name
}

function areOtherSampleSessionApplicable<T extends SampleData>(sample: T, allSessions: SampleSession[]) {
    return !isSamplesWithActiveSession(allSessions, getFieldValue(sample.fields, fieldIndex.SESSION_NAME))
}

export {
    getFingerdabOverviewByOperator,
}
