import { useSelector, useAction, useCallback, useEffect, useState, classnames } from '_/facade/react'

import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import type { FormApi } from 'final-form'

import * as routes from '_/constants/routes'
import * as tabs from '_/constants/modal-tab'

import * as M from '_/components/modal'
import TabNavbar from '_/components/tab-navbar'
import Button, { Close } from '_/components/button'
import AuditTrails from '_/components/audit-trail-list'
import { Submit, submitDisabled, resetForm, TextField } from '_/components/form'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'

import ActiveField from '../../shared/active-field'
import Filters from './filters'

import * as deletionActions from '_/features/confirmation/actions'
import * as routerActions from '_/features/routing/actions'
import * as actions from '../actions'

import Timeframe from './timeframe'
import Rule from './rule'
import type { TrendSettings, TrendSettingsEdit } from '_/model/predefined-lists/trend-settings/types'
import validate from '_/model/predefined-lists/trend-settings/validate'
import * as ft from '_/model/predefined-lists/trend-settings/filter-type'
import * as ct from '_/constants/custom-field-column-type'
import * as vt from '_/model/predefined-lists/trend-settings/value-type'
import * as tt from '_/model/predefined-lists/trend-settings/timeframe-type'
import { BATCH_NUMBER, EXPOSURE_LOCATION_GRADE_ID } from '_/constants/custom-field-index'
import { dropFields } from '_/utils/object'
import { isDefaultCustomFieldIndex } from '_/model/predefined-lists/custom-field/custom-field'
import { isIncreasingCfuRule, isLimitRule, toIncreasingCfuRuleEdit } from '_/model/predefined-lists/trend-settings/helpers'
import { NON_STRICTLY_INCREASING_CFU_COUNT, STRICTLY_INCREASING_CFU_COUNT } from '_/model/predefined-lists/trend-settings/rule-type'
import type { AuditTrail } from '_/model/audit-trail/types'

interface Props {
    trends: TrendSettings[]
    onReloadList: () => void
}

function TrendModal(props: Props) {
    const id = useSelector(_ => _.router.route?.params)?.id
        , handleClose = useNavigateToList()
        , [trend, trails] = useTrend(id)
        , handleSubmit = useSubmitHandler(id, trend as any, handleClose, props.onReloadList)
        , handleRemove = useRemoveHandler(trend, handleClose, props.onReloadList)
        , [activeTab, setActiveTab] = useState(tabs.DATA)
        , customFields = useSelector(_ => _.predefinedLists.customFields)
        , editMode = id !== undefined
        , initialTrend: TrendSettingsEdit = trend
            ? { ...trend, rule: isIncreasingCfuRule(trend.rule)
                ? toIncreasingCfuRuleEdit(trend.rule)
                : trend.rule
            }
            : { filters: [] }

    let prevValues: TrendSettingsEdit = initialTrend

    function onChange(values: TrendSettingsEdit, form: FormRenderProps) {
        if (values.filters && prevValues.filters && values.filters.length === prevValues.filters.length) {
            values.filters.forEach((_, i) => {
                const prevFilter = prevValues.filters![i]
                    , changedFromHas = _.type === ft.HAS && prevFilter.type === ft.HAS && prevFilter.dataField !== _.dataField
                    , changedFromHasIdentification = _.type === ft.HAS_IDENTIFICATION && prevFilter.type === ft.HAS_IDENTIFICATION && prevFilter.identificationType !== _.identificationType

                if (changedFromHas || changedFromHasIdentification) {
                    form.form.change(`filters.${i}.value`, undefined)
                    form.form.blur(`filters.${i}.value`)
                }
            })
        }

        if (prevValues.timeframe?.type === tt.WITHIN && values.timeframe?.type === tt.FOR_CONSECUTIVE_SAMPLES) {
            form.form.change('timeframe.period', undefined)
        }

        if (isIncreasingCfuRule(prevValues.rule) && values.timeframe?.type === tt.WITHIN) {
            form.form.change('rule', undefined)
        }

        if (isIncreasingCfuRule(values.rule) && isIncreasingCfuRule(prevValues.rule) && prevValues.rule.mode !== values.rule.mode) {
            const type = values.rule.mode ? STRICTLY_INCREASING_CFU_COUNT : NON_STRICTLY_INCREASING_CFU_COUNT
            form.form.change('rule.type', type)
        }

        if (isIncreasingCfuRule(prevValues.rule) && !isIncreasingCfuRule(values.rule)) {
            form.form.change('rule.mode', undefined)
        }

        if (isLimitRule(prevValues.rule) && !isLimitRule(values.rule)) {
            form.form.change('rule.count', undefined)
        }

        prevValues = values
    }

    return (
        <M.Modal isOpen onClose={handleClose} contentClassName='planning-window'>
            <Form<TrendSettingsEdit>
                initialValues={initialTrend}
                validate={_ => validate(_, props.trends, customFields)}
                onSubmit={handleSubmit}
                mutators={{...arrayMutators}}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form} onChange={onChange} />

                        <M.ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4>{editMode ? 'Edit trend setting' : 'Create a new trend setting'}</h4>
                                    <Close onClick={handleClose} />
                                </div>
                                <TabNavbar>
                                    <Button
                                        onClick={() => setActiveTab(tabs.DATA)}
                                        className={classnames('btn-link navbar-tab me-4', { active: activeTab === tabs.DATA })}
                                    >
                                        Trend settings data
                                    </Button>
                                    <Button
                                        onClick={() => setActiveTab(tabs.AUDIT_TRAIL)}
                                        className={classnames('btn-link navbar-tab', { active: activeTab === tabs.AUDIT_TRAIL })}
                                        disabled={!editMode}
                                    >
                                        Audit trail
                                    </Button>
                                </TabNavbar>
                            </div>
                        </M.ModalHeader>
                        <M.ModalBody>
                            <div>
                                {activeTab === tabs.DATA &&
                                    <>
                                        <div className='mb-3'>
                                            Be notified when the conditions of your defined trend settings are met. You can set a timeframe and filters to restrict which viable samples to monitor, and provide a rule to look for within those viable samples.
                                        </div>

                                        <TextField name='name' id='name' testId='field-name'>Trend setting name</TextField>
                                        <Filters editMode={editMode} />
                                        <Timeframe disabled={editMode} />
                                        <Rule disabled={editMode} />
                                    </>
                                }
                                {activeTab === tabs.AUDIT_TRAIL && <AuditTrails trail={trails} />}
                            </div>
                        </M.ModalBody>
                        <M.ModalFooter className='border-top-0 modal-footer-height'>
                            {activeTab === tabs.DATA &&
                                <div className={`d-flex flex-fill ${trend ? 'justify-content-between' : 'justify-content-end'}`}>
                                    {trend &&
                                        <div>
                                            <Button
                                                className='text-danger bg-transparent border-0 me-2'
                                                onClick={() => handleRemove(form.form)}
                                                disabled={trend.inUse}
                                            >
                                                Remove
                                            </Button>
                                            <ActiveField inUse isActive={trend.isActive} />
                                        </div>
                                    }
                                    <div>
                                        <Button className='text-muted bg-transparent border-0 me-2' onClick={handleClose}>Cancel</Button>
                                        <Submit disabled={submitDisabled(form)}>{editMode ? 'Save changes' : 'Create'}</Submit>
                                    </div>
                                </div>
                            }
                        </M.ModalFooter>
                    </form>
                }
            />
        </M.Modal>
    )
}

export default TrendModal

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SETTINGS_TRENDS), [navigateTo])

    return navigateToList
}

function useSubmitHandler(id: string | undefined, oldValue: TrendSettings, onClose: () => void, onReloadList: () => void) {
    const create = useAction(actions.createTrendSettings)
        , save = useAction(actions.saveTrendSettings)
        , customFields = useSelector(_ => _.predefinedLists.customFields)

    function addValueType(value: TrendSettingsEdit) {
        const filters = (value.filters ?? []).map(_ => {
            if (_.type === ft.HAS) {
                const allFields = [{ index: EXPOSURE_LOCATION_GRADE_ID, fieldType: ct.SELECTION}].concat(customFields)
                    , field = allFields.find(f => f.index === _.dataField)

                switch(field?.fieldType) {
                    case ct.SELECTION:
                        return {..._, valueType: vt.GUID}
                    case ct.TIME:
                        return {..._, valueType: vt.TIME}
                    case ct.NUMBER:
                        return {..._, valueType: vt.NUMBER}
                    case ct.TEXT: {
                        if (isDefaultCustomFieldIndex(_.dataField!) && field.index !== BATCH_NUMBER)
                            return {..._, value: [_.value], valueType: vt.STRING}

                        return {..._, valueType: vt.STRING}
                    }
                    default:
                        return _
                }
            }
            return _
        })
        return {...value, filters}
    }

    function handleSubmit(newValue: TrendSettingsEdit, form: FormApi<TrendSettingsEdit>) {
        const normalizedFilters = newValue.filters?.map(_ => _.type === ft.IN || _.type === ft.IN_THE_SAME ? _ : dropFields(_, 'includeAllChildren'))
            , normalizedRule = isIncreasingCfuRule(newValue.rule) ? dropFields(newValue.rule, 'mode') : newValue.rule
            , newTrendSettings: TrendSettingsEdit = {...newValue, filters: normalizedFilters, rule: normalizedRule}
            , request = id === undefined
                ? create(addValueType(newTrendSettings) as TrendSettingsEdit)
                : save({ id, oldTrendSettings: oldValue, newTrendSettings: newTrendSettings })

        return request
            .then(() => resetForm(form))
            .then(onClose)
            .then(onReloadList)
    }

    return handleSubmit
}

function useTrend(id: string | undefined) {
    const load = useAction(actions.loadTrendSettings)
        , loadTrail = useAction(actions.loadTrendSettingsTrail)
        , [trend, setTrend] = useState<TrendSettings | undefined>()
        , [trails, setTrails] = useState<AuditTrail>()

    useEffect(
        () => {
            if (!id) {
                setTrend(undefined)
                return
            }

            load(id).then(setTrend)
            loadTrail(id).then(setTrails)
        },
        [id, load, loadTrail]
    )

    return [trend, trails] as const
}

function useRemoveHandler(trend: TrendSettings | undefined, onClose: () => void, onReloadList: () => void) {
    const remove = useAction(actions.removeTrendSettings)
        , confirmDeletion = useAction(deletionActions.showDeletionConfirmationModal)

    function handleRemove(form: FormApi<TrendSettingsEdit>) {
        if (!trend)
            return Promise.resolve()

        return confirmDeletion(`Are you sure you want to delete ${trend.name}?`)
            .then(() => remove(trend.id))
            .then(() =>
                resetForm(form).then(onClose)
            )
            .then(onReloadList)
    }

    return handleRemove
}
