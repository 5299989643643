import { React, useState, classnames } from '_/facade/react'

import Menu, { useCloseMenu } from '_/components/overlay/menu'

interface Props {
    children: React.ReactNode
    element: HTMLButtonElement | null
    onClose: () => void
    className?: string
}

const MenuContainer = (props: Props) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)

    useCloseMenu(containerRef, props.onClose, props.element)

    return (
        <Menu element={props.element} containerRef={setContainerRef} showTriangle>
            <div className={classnames('rounded text-light position-relative legend-bubble py-1', props.className)}>
                <div>
                    {props.children}
                </div>
            </div>
        </Menu>
    )
}

export default MenuContainer
