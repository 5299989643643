import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type DayScheduleService from '_/services/day-schedule-service'

import * as actions from './actions'

const factory = (service: DayScheduleService): EffectsFactory => () => [
    handler(actions.loadDaySchedule, service.get),
    handler(actions.addGroup, data => service.addGroup(data.date, data.monitoringGroup)),
    handler(actions.deleteGroup, data => service.deleteGroup(data.date, data.monitoringGroupId, data.sessionId)),
    handler(actions.copyGroups, data => service.copyGroups(data.date, data.request)),
    handler(actions.loadDayScheduleTrail, data => service.getTrail(data.dayScheduleId, data.date)),
    handler(actions.loadDaySchedules, service.getDaySchedules),
    handler(actions.changeNotInUse, service.changeNotInUse),
    handler(actions.bulkChangeNotInUse, service.bulkChangeNotInUse),
    handler(actions.createSeries, service.createSeries),
    handler(actions.loadSeries, service.getSeries),
    handler(actions.saveSeries, data => service.saveSeries(data.id, data.recurrence)),
    handler(actions.deleteSeries, data => service.deleteSeries(data.id, data.request)),
    handler(actions.addAdHocGroup, data => service.addAdHocGroup(data.date, data.request)),
    handler(actions.changeMissed, service.changeMissed),
    handler(actions.loadExpectationTrail, data => service.getExpectationTrail(data.id, data.date, data.sessionId))
]

export default factory
