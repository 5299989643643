import { diffObject } from '_/utils/object'
import type Api from '_/services/monitoring-group-service'
import type { MonitoringGroupEdit } from '_/model/scheduling/monitoring-groups/types'

function updateTransaction(
    id: string,
    current: MonitoringGroupEdit,
    next: MonitoringGroupEdit,
    api: Api,
): Promise<void> {
    const diff = diffObject(current, next)

    if (!diff)
        return Promise.resolve()

    return api.save(id, diff)
}

export {
    updateTransaction,
}
