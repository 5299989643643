import { maxLength } from '_/utils/form/validate'

function validateComment(comment: string) {
    const commentLength = maxLength('Comment', 250)(comment)

    if (commentLength)
        return commentLength

    if (!comment.trim())
        return comment.length !== 0 ? 'Need to type some text to send a comment' : undefined
}

export {
    validateComment,
}
