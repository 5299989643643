import type { Middleware} from '_/utils/redux'
import { actionHasType } from '_/utils/redux'
import type AppState from '_/model/app-state'
import { exportCanvasToBlob } from '_/utils/image'
import * as a from '../actions'
import { isLocalhostEnvironment } from '_/model/environment/helpers'

const DISABLED = 0
    , ACTIVATED = 1
    , INTERRUPTED = 2

type WebcamState =
    | typeof DISABLED
    | typeof ACTIVATED
    | typeof INTERRUPTED

interface WebcamSingleton {
    state: WebcamState
    stream?: MediaStream
    video?: HTMLVideoElement
    canvas?: HTMLCanvasElement
}

const WebcamSingleton: WebcamSingleton = { state: DISABLED }

function createCanvas(width: number, height: number) {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    return canvas
}

function createVideo(stream: MediaStream) {
    const video = document.createElement('video')
    video.setAttribute('preload', 'auto')
    video.srcObject = stream
    return video
}

function captureImage() {
    const canvas = WebcamSingleton.canvas!
        , player = WebcamSingleton.video!
        , ctx = canvas.getContext('2d')!

    ctx.drawImage(player, 0, 0, canvas.width, canvas.height)

    return exportCanvasToBlob(canvas)
}

function deactivateWebcam() {
    const intervalId = window.setInterval(
        () => {
            if (WebcamSingleton.state === ACTIVATED) {
                WebcamSingleton.stream!.getVideoTracks().forEach(_ => _.stop())
                WebcamSingleton.video!.srcObject = null
            }

            if (WebcamSingleton.state === ACTIVATED || WebcamSingleton.state === INTERRUPTED) {
                WebcamSingleton.state = DISABLED
                clearInterval(intervalId)
            }
        },
        100
    )
}

function activateWebcam() {
    // If the current document isn't loaded securely, navigator.mediaDevices will be undefined, and you cannot use getUserMedia()
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!navigator.mediaDevices)
        return Promise.resolve('Your browser does not support streaming video')

    const minWidth = isLocalhostEnvironment() ? 640 : 4096
        , minHeight = isLocalhostEnvironment() ? 480 : 2160

    return navigator.mediaDevices.getUserMedia({
            video: {
                width: { min: minWidth, ideal: 4096, max: 4096 },
                height: { min: minHeight, ideal: 2160, max: 2160 },
            },
            audio: false,
        })
        .then(stream => {
            const video = createVideo(stream)
                , init = video.play().then(() => {
                    const canvas = createCanvas(video.videoWidth, video.videoHeight)

                    WebcamSingleton.stream = stream
                    WebcamSingleton.canvas = canvas
                    WebcamSingleton.video = video
                    WebcamSingleton.state = ACTIVATED
                })

            init.catch(() => {
                video.srcObject = null
            })

            return init.then(_ => stream)
        })
        .catch(_ => {
            WebcamSingleton.state = INTERRUPTED
            if (_.name === 'NotReadableError' || _.name === 'TrackStartError' || _.name === 'SourceUnavailableError')
                return 'Your camera is being used by another application'
            if (_.name === 'NotFoundError')
                return 'No camera found'
            if (_.name === 'NotAllowedError' || _.name === 'PermissionDeniedError')
                return 'Camera access was denied'
            if (_.name === 'OverconstrainedError')
                return 'Resolution of your camera is not supported'

            return 'Unknown camera error'
        })
}

const webcamMiddlewareFactory =
    (): Middleware<AppState> =>
        _api => next => action => {
            if (actionHasType(action, a.activateWebcam.type))
                return activateWebcam()

            if (actionHasType(action, a.deactivateWebcam.type)) {
                deactivateWebcam()
                return
            }

            if (actionHasType(action, a.captureWebcam.type))
                return captureImage()

            return next(action)
        }

export default webcamMiddlewareFactory
