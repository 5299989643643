import type { ReactDatePickerProps } from 'react-datepicker'
import DatePicker from 'react-datepicker'
import Overlay from '_/components/overlay/overlay'
import { React, bindComponent, connect, dispatchPropsMapper, useEffect, useCallback, useState } from '_/facade/react'
import * as timeActions from '_/features/time/actions'
import { outOfViewport, verticalScrollParent } from '_/components/overlay/menu'
import type { DateTime } from '_/model/date-time'

type WrapperTime = DateTime | ''
type PickerTime = Date | null

type ForwardedReactDatePickerProps = Pick<
        ReactDatePickerProps,
        'id' | 'className' | 'onFocus' | 'onBlur' | 'disabled' | 'autoFocus' | 'isClearable' | 'customInput' | 'onKeyDown'
    >

interface DatePickerWrapperProps extends ForwardedReactDatePickerProps {
    useDayEndTime?: boolean
    value: WrapperTime
    onChange?: (_: WrapperTime) => void
    min?: DateTime
    max?: DateTime
    isMonthPicker?: boolean
    calendarContainerWidth?: number
}

class DatePickerWrapper extends React.Component<DatePickerWrapperProps & ConnectedProps> {
    private timeService = this.props.getTimeService()

    constructor(props: DatePickerWrapperProps & ConnectedProps) {
        super(props)
        bindComponent(this)
    }

    private handleChange(value: PickerTime) {
        if (this.props.onChange)
            this.props.onChange(this.convertFromPickerValue(value))
    }

    private convertToPickerValue(value: WrapperTime): PickerTime {
        if (value === '')
            return null

        const { year, month, day } = this.timeService.ctzTimeStruct(value)
        return new Date(year, month - 1, day)
    }

    private convertFromPickerValue(value: PickerTime): WrapperTime {
        if (value == null)
            return ''

        const year = value.getFullYear()
            , month = value.getMonth() + 1
            , day = value.getDate()
            , [hour, minute, second, milli] = this.props.useDayEndTime ? [23, 59, 59, 999] : [0, 0, 0, 0]

        const result = this.timeService.ctz(year, month, day, hour, minute, second, milli)

        return result
    }

    private getCtzDayStart() {
        const ctzDayStart = this.timeService.ctzDayStart(this.timeService.now())

        const { year, month, day } = this.timeService.ctzTimeStruct(ctzDayStart)
            , date = new Date(year, month - 1, day)
        return date
    }

    private InternalCalendarContainer = (props: { children: React.ReactNode[] }) =>
        <CalendarContainer width={this.props.calendarContainerWidth} elementId={this.props.id}>
            {props.children}
        </CalendarContainer>

    render() {
        const props = this.props

        return <DatePicker
            id={props.id}
            className={props.className}
            selected={this.convertToPickerValue(props.value)}
            openToDate={props.value === '' ? this.getCtzDayStart() : undefined}
            dateFormat='dd-MMM-yyyy'
            onChange={this.handleChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            maxDate={this.convertToPickerValue(props.max == undefined ? '' : props.max)}
            minDate={this.convertToPickerValue(props.min == undefined ? '' : props.min)}
            isClearable={props.isClearable}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            customInput={props.customInput}
            showMonthYearPicker={props.isMonthPicker}
            autoComplete='off'
            popperContainer={this.InternalCalendarContainer}
            onKeyDown={props.onKeyDown}
        />
    }

    static defaultProps = {
        isClearable: true,
    }
}

const mapDispatchToProps = dispatchPropsMapper({
    getTimeService: timeActions.getTimeService,
})

type ConnectedProps = ReturnType<typeof mapDispatchToProps>

export default connect(null, mapDispatchToProps)(DatePickerWrapper)
export { DatePickerWrapperProps }

function CalendarContainer(props: { children: React.ReactNode[], width?: number, elementId?: string }) {
    const [elementVisible, setElementVisible] = useState(true)
        , updatePosition = useCallback(
            () => {
                if (!props.elementId) {
                    setElementVisible(true)
                    return
                }

                const element = document.getElementById(props.elementId)
                if (!element) {
                    setElementVisible(true)
                    return
                }

                const scrollParent = verticalScrollParent(element)
                    , visible = !outOfViewport(element, scrollParent)

                setElementVisible(visible)
            },
            [props.elementId]
        )


    useEffect(
        () => {
            let active = true

            function next() {
                if (!active)
                    return

                updatePosition()
                window.requestAnimationFrame(next)
            }

            next()

            return () => {
                active = false
            }
        },
        [updatePosition]
    )

    if (!elementVisible)
        return null

    return (
        <Overlay top={0} left={0} width={props.width}>
            {props.children}
        </Overlay>
    )
}
