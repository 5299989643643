import { React, useState } from '_/facade/react'

const NOT_VALID_VALUE = {} as const
type NOT_VALID_VALUE = typeof NOT_VALID_VALUE

type Value = string | NOT_VALID_VALUE

interface Props {
    id?: string
    name?: string
    title?: string
    placeholder?: string
    className?: string
    disabled?: boolean
    autoFocus?: boolean
    testId?: string

    value: Value
    onChange: (value: Value) => void
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const NumberTextInput = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const [lastChangeValue, setLastChangeValue] = useState<{ parsedValue: Value, textValue: string }>({ parsedValue: '', textValue: '' })
        , textValue = typeof props.value === 'string' && parseText(props.value) !== NOT_VALID_VALUE
            ? props.value
            : lastChangeValue.textValue

    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const textValue = event.target.value
            , parsedValue = parseText(textValue)

        setLastChangeValue({ textValue, parsedValue })

        props.onChange(parsedValue)
    }

    return (
        <input
            type='text'
            inputMode='numeric'
            ref={ref}
            id={props.id}
            name={props.name}
            title={props.title}
            placeholder={props.placeholder}
            className={props.className}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            autoComplete='off'
            value={textValue}
            onChange={handleChange}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            onKeyDown={props.onKeyDown}
            data-testid={props.testId}
        />
    )
})

export default NumberTextInput
export {
    NOT_VALID_VALUE,
}
export type {
    Value
}

function parseText(text: string): Value {
    if (text === '')
        return ''

    const isValidNumber = /^-?\d+(?:\.\d+)?$/.test(text)
        && isFinite(Number(text))

    return isValidNumber ? text : NOT_VALID_VALUE
}
