const SMART_CONTROL = 0
    , SSO = 1

type UserAccountType = typeof SMART_CONTROL | typeof SSO

export {
    UserAccountType,
    SMART_CONTROL,
    SSO,
}
