function noop(): void {}

function id<T>(value: T): T {
    return value
}

function debounce<F extends any[]>(fn: (...args: F) => void, delay: number) {
    let timeoutId: number | null = null

    return function (...args: F) {
        const onComplete = () => {
            fn(...args)
            timeoutId = null
        }

        if (timeoutId)
            clearTimeout(timeoutId)

        timeoutId = window.setTimeout(onComplete, delay)
    }
}

// memoize last call
function memoize<T extends any[], R>(f: (...args: T) => R): (...args: T) => R {
    let lastArgs: T, lastResult: R

    return (...args) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const sameArgs = lastArgs
                && lastArgs.length === args.length
                && lastArgs.every((v, i) => v === args[i])

        if (sameArgs)
            return lastResult

        lastResult = f(...args)
        lastArgs = args
        return lastResult
    }
}

type Func<P extends any[], R> = (...params: P) => R

function buffer<T, R>(zip: Func<[T[]], Promise<R[]>>): Func<[T], Promise<R>> {
    const DELAY = 0

    let lastTimeout: number | undefined,
        requests: { arg: T, resolve: Func<[R], void>, reject: Func<[any], void> }[] = []

    function scheduleNextRequests() {
        if (lastTimeout !== undefined || requests.length === 0)
            return

        lastTimeout = window.setTimeout(
            function () {
                // buffer accumulated requests during timeout
                const bufferedRequests = requests
                requests = []

                zip(bufferedRequests.map(_ => _.arg)).then(
                    results => {
                        bufferedRequests.forEach((_, i) => _.resolve(results[i]))
                        lastTimeout = undefined
                        // new requests might appear
                        scheduleNextRequests()
                    },
                    error => {
                        bufferedRequests.forEach(_ => _.reject(error))
                        lastTimeout = undefined
                        // new requests might appear
                        scheduleNextRequests()
                    }
                )
            },
            DELAY
        )
    }

    return function (arg: T) {
        const promise = new Promise<R>((resolve, reject) => {
            requests.push({ arg, resolve, reject })
        })

        scheduleNextRequests()

        return promise
    }
}

export { noop, id, debounce, memoize, buffer }
