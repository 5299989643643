import type { State as RouterState } from 'router5'

import { useSelector,  classnames } from '_/facade/react'
import * as routes from '_/constants/routes'

import TabNavbar from '_/components/tab-navbar'
import Link from '_/components/link'
import OrganismIdentifications from './organism-identification'
import OrganismIdentificationsTrail from './audit-trail'

function OrganismIdentificationContainer() {
    const permissions = useSelector(_ => _.auth.permissions)
        , route = useSelector(_ => _.router.route)

    return (
        <>
            <div className='page-header mt-3 organism-identifications__header--height'>
                <TabNavbar>
                    <Link
                        routeName={routes.SETTINGS_ORGANISM_IDENTIFICATION_IDENTIFICATIONS}
                        className={classnames('navbar-tab me-4', { active: route?.name === routes.SETTINGS_ORGANISM_IDENTIFICATION_IDENTIFICATIONS })}
                        hasNoPermissions={!permissions.editOrganismIdentification}
                    >
                        Organism identifications
                    </Link>
                    <Link
                        routeName={routes.SETTINGS_ORGANISM_IDENTIFICATION_TRAIL}
                        className={classnames('navbar-tab', { active: route?.name === routes.SETTINGS_ORGANISM_IDENTIFICATION_TRAIL })}
                        hasNoPermissions={!permissions.editOrganismIdentification}
                    >
                        Audit trail
                    </Link>
                </TabNavbar>
            </div>
            {useRenderRoute(route)}
        </>
    )
}

export default OrganismIdentificationContainer

function useRenderRoute(route: RouterState | null) {
    switch (route?.name) {
        case routes.SETTINGS_ORGANISM_IDENTIFICATION_TRAIL:
            return <OrganismIdentificationsTrail />
        default:
            return <OrganismIdentifications />
    }
}
