import { useState, useRef, useMemo, useEffect, createContext } from 'react'
import * as React from 'react'

import type SortState from '_/model/sort-state'

import type { SortMediator, SortSubject } from './sort'
import { mediatorFactory } from './sort'
import { useUpdateEffect } from '_/hooks/shared-hooks'

const { Provider, Consumer } = createContext<SortMediator>(undefined as any)

interface SortContainerProps {
    onChange: (state: SortState) => void
    children: React.ReactNode
}

function SortContainer(props: SortContainerProps) {
    const mediator = useMemo(mediatorFactory, [])

    useUpdateEffect(
        () => {
            mediator.updateOnChange(props.onChange)
        }
    )

    return <Provider value={mediator} children={props.children}/>
}

interface TargetState {
    sorted: boolean
    ascending: boolean
}

interface TargetProps {
    name: string
    mediator: SortMediator
    sortByDescending?: boolean
    children: (value: TargetState & { onClick: React.MouseEventHandler<HTMLElement> }) => React.ReactNode
}

function SortTargetInternal(props: TargetProps) {
    const [sorted, setSorted] = useState(false)
        , [ascending, setAscending] = useState(false)
        , sortSubject = useRef<SortSubject>(
            props.mediator.createSubject(
                props.name,
                handleOrderChange,
                props.sortByDescending
            )
        )

    useEffect(
        () => {
            return () => {
                props.mediator.removeSubject(sortSubject.current)
            }
        },
        [props.mediator]
    )

    useUpdateEffect(
        () => {
            sortSubject.current = props.mediator.updateSubject(
                props.name,
                handleOrderChange,
                sortSubject.current,
            )
        },
        [props.name, props.mediator]
    )

    function handleClick() {
        sortSubject.current.flipOrder()
    }

    function handleOrderChange(sorted: boolean, ascending: boolean) {
        setSorted(sorted)
        setAscending(ascending)
    }

    return (
        <>
            {props.children({ onClick: handleClick, sorted, ascending })}
        </>
    )
}

interface SortTargetProps {
    name: string
    sortByDescending?: boolean
    children: (value: { sorted: boolean, ascending: boolean, onClick: React.MouseEventHandler<HTMLElement> }) => React.ReactNode
}

const SortTarget = (props: SortTargetProps) =>
    <Consumer>
        {_ => <SortTargetInternal mediator={_} name={props.name} sortByDescending={props.sortByDescending}>{props.children}</SortTargetInternal>}
    </Consumer>

export { SortContainer, SortTarget }
