import type ApiService from '../api-service'
import type SmartCheckService from '../smart-check-service'
import { noop, buffer } from '_/utils/function'
import type {
    ImageTokens,
    ImageUploadRequest,
    ImageInfoEdit,
    ImageInfo,
    ValidationResult,
} from '_/model/smart-check/image'
import * as httpStatusCodes from '_/model/error/http-status-code'
import type { SampleName } from '_/constants/plate-type'
import type ReduxService from './redux/redux-service'
import type { Guid } from '_/model/guid'
import type { Device } from '_/model/predefined-lists/devices/types'
import { ONLINE } from '_/model/predefined-lists/devices/status'

function factory(api: ApiService, reduxService: ReduxService): SmartCheckService {
    const getDownloadTokens = buffer<string, ImageTokens>(
        (ids: string[]) => api.post(['images', 'download-tokens'], { imageIds: ids })
    )

    return {
        removeImage: remove,
        validateImage: validate,
        getUploadTokens,
        getDownloadTokens,
        uploadImage: upload,
        saveImageInfo: saveInfo,
        getImageInfo: getInfo,
        captureRemoteImage,
    }

    function remove(id: string): Promise<void> {
        return api.delete(['images', id])
    }

    function validate(imageId: string, image: Blob, previousImageId: string | undefined, plateType: SampleName, is5K: boolean): Promise<ValidationResult> {
        const formData = new FormData()
        formData.append('plateType', plateType.toString())
        formData.append('imageId', imageId)
        formData.append('is5k', is5K.toString())
        if (previousImageId)
            formData.append('previousImageId', previousImageId)

        formData.append('images', image)

        return api.post<ValidationResult>(['images', 'validate-image'], formData)
    }

    function getUploadTokens(): Promise<ImageTokens> {
        return api.post(['images', 'upload-tokens'])
    }

    function upload(images: ImageUploadRequest[]): Promise<void> {
        return Promise.all(
                images.map(imageRequest => api.rawPut(imageRequest.uploadUrl, imageRequest.image)
            ))
            .then(noop)
    }

    function saveInfo(imageInfo: ImageInfoEdit, entityId: Guid): Promise<void> {
        const entityType = reduxService.getStore().getState().auth.permissions.smartControlMode ? 0 : 1

        return api.post(['images'], { ...imageInfo, entityId, entityType }).then(noop)
    }

    function getInfo(imageId: string): Promise<ImageInfo> {
        return api.get<ImageInfo>(['images', imageId])
            .then(info => {
                if (info.growthCountResult) {
                    info.growthCountResult.growths.forEach(growth => {
                        // there might be historical data with unknown CFU count
                        // check https://microgenetics.atlassian.net/browse/SC-1983?focusedCommentId=11297
                        const isUnknown = growth.cfu === -1
                        if (isUnknown)
                            growth.cfu = 1
                    })
                }

                return info
            })
    }

    function captureRemoteImage(deviceId: string): Promise<Blob> {
        const TIMEOUT = 120000

        return api.post<{ imageUrl: string }>(['devices', deviceId, 'take-photo'])
                .then(_ => tryGetPhoto(_.imageUrl, Date.now()))

        function tryGetPhoto(url: string, startTime: number): Promise<Blob> {
            const checkStatus = api.get<Device>(['devices', deviceId])
                .then(_ =>
                    _.status === ONLINE
                        ? Promise.resolve()
                        : Promise.reject({ message: 'Error getting image' })
                )

            return checkStatus
                .then(() => api.rawGetFile(url))
                .catch(reason => {
                    const retry = (reason.status === httpStatusCodes.NOT_FOUND || reason.status === httpStatusCodes.FORBIDDEN)
                            && ((Date.now() - startTime) < TIMEOUT)

                    return retry ? tryGetPhoto(url, startTime) : Promise.reject(reason)
                })
        }
    }
}

export default factory
