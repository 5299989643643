export const CSV = 0
           , JSON = 1

type ExportFormatId = never
    | typeof JSON
    | typeof CSV

import type * as self from './export-format'
export type ExportFormat = Extract<(typeof self)[keyof typeof self], number>

const EXPORT_FORMAT: { id: ExportFormat, name: string }[] = [
    {
        id: CSV,
        name: 'CSV format (for Excel)',
    },
    {
        id: JSON,
        name: 'JSON format',
    },
]

export {
    EXPORT_FORMAT as default,
    ExportFormatId
}
