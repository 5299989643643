import type { NavigationOptions } from 'router5'
import { actions, actionTypes } from 'redux-router5'

import type { ActionType } from '_/facade/react'

import type { RouteName } from '_/constants/routes'

type ActionNavigateTo = ReturnType<typeof actions.navigateTo>

function navigateTo(routeName: RouteName, routeParams?: any, opts?: NavigationOptions): { type: ActionType<void, ActionNavigateTo> } {
    return actions.navigateTo(routeName, routeParams, opts)
}

navigateTo.type = actionTypes.NAVIGATE_TO as ActionType<void, ActionNavigateTo>

export { navigateTo }
