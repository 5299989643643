import { exportCanvasToBlob } from '_/utils/image'
import { downloadBlob } from '_/utils/blob'
import type { SessionBreaches } from './types'
import type { ExportGraphByDayAndSession, MissedMonitoringByDayAndSession } from './missed-monitoring-report/types'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import WEEK from '_/constants/weekday'
import { calculateColor } from './locations-report/helpers'
import { RECT_HEIGHT, RECT_WIDTH } from './session-breaches-rect'
import { systemFontStack } from '_/model/analysis/system-font-stack'
import { drawText } from '_/model/canvas/draw'
import { splitStringIntoFewLines } from '_/features/analysis/helpers'

interface Footer {
    entityName: string | undefined
    dateRange: string
    author: string
}

const GRAY_COLOR = '#757575'
    , OFFSET = 3
    , LINE_HEIGHT = 15

function exportGraphByDayAndSession(
    graphData: ExportGraphByDayAndSession[],
    sessions: Pick<SampleSession, 'id' | 'name'>[],
    maxValue: number,
    graphName: string,
    graphTitle: string,
    footer: Footer
) {
    const exportCanvas = document.createElement('canvas')
        , splitTitle = splitStringIntoFewLines(graphTitle, 75)
        , titleOffset = splitTitle.length * LINE_HEIGHT + 20
        , footerOffset = LINE_HEIGHT * 5 + 40

    exportCanvas.height = sessions.length * (RECT_HEIGHT + OFFSET) + titleOffset + footerOffset
    exportCanvas.width = 1000

    drawExportGraphByDayAndSession(exportCanvas, graphData, sessions, maxValue, splitTitle, titleOffset, footer)

    const blob = exportCanvasToBlob(exportCanvas, 'image/png')
    downloadBlob(blob, graphName)
}

function drawExportGraphByDayAndSession(
    exportCanvas: HTMLCanvasElement,
    graphData: ExportGraphByDayAndSession[],
    sessions: Pick<SampleSession, 'id' | 'name'>[],
    maxBreach: number,
    graphTitle: string[],
    titleOffset: number,
    footer: Footer
) {
    const ctx = exportCanvas.getContext('2d')!
        , textFont = `bold 10px ${systemFontStack}`

    drawTitle(ctx, graphTitle)

    let dx = 0
      , dy = titleOffset

    sessions.forEach(session => {
        drawRectWithText(ctx, dx, dy, session.name, 'white', GRAY_COLOR, textFont)
        dx += RECT_WIDTH + OFFSET
        WEEK.forEach(day => {
            const sessionData = graphData.find(_ => _.sessionId === session.id)
                , averageCount = sessionData?.dayData.find(_ => _.dayId === day.id)?.averageCount
                , color = averageCount !== undefined ? calculateColor(maxBreach, averageCount) : undefined
            drawRectWithText(ctx, dx, dy, averageCount?.toString() ?? '', color)
            dx += RECT_WIDTH + OFFSET
        })
        dy += RECT_HEIGHT + OFFSET
        dx = 0
    })

    drawRectWithText(ctx, dx, dy, '', 'white')
    dx += RECT_WIDTH + OFFSET
    WEEK.forEach(_ => {
        drawRectWithText(ctx, dx, dy, _.shortName, 'white', GRAY_COLOR, textFont)
        dx += RECT_WIDTH + OFFSET
    })
    dy += RECT_HEIGHT + OFFSET * 2

    drawFooter(ctx, dy, footer)
}

function drawFooter(ctx: CanvasRenderingContext2D, dy: number, footer: Footer) {
    const headerFont = `bold 11px ${systemFontStack}`
        , font = `11px ${systemFontStack}`

    drawText(ctx, [20, dy], 'Filters', headerFont, 'black', 'left')

    if (footer.entityName)
        drawText(ctx, [20, dy + LINE_HEIGHT], footer.entityName, font, 'black', 'left')

    const lineCount = footer.entityName ? 2 : 1
    drawText(ctx, [20, dy + LINE_HEIGHT * lineCount], footer.dateRange, font, 'black', 'left')
    drawText(ctx, [20, dy + LINE_HEIGHT * (lineCount + 1) + OFFSET], footer.author, font, 'black', 'left')
}

function drawTitle(ctx: CanvasRenderingContext2D, graphTitle: string[]) {
    const font = `bold 15px ${systemFontStack}`

    graphTitle.forEach((text, i) => drawText(ctx, [350, 15 * (i + 1)], text, font, 'black', 'center'))
}

function drawRectWithText(
    ctx: CanvasRenderingContext2D,
    dx: number,
    dy: number,
    text: string,
    color = '#f8f9fa',
    textColor = 'black',
    font = `10px ${systemFontStack}`
) {
    ctx.fillStyle = color
    ctx.fillRect(dx, dy, RECT_WIDTH, RECT_HEIGHT)

    ctx.fillStyle = textColor
    ctx.font = font
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'

    const splitStrings = splitStringWithoutSpaces(splitStringIntoFewLines(text, 10), 10)
        , LINE_HEIGHT = 10


    function splitStringWithoutSpaces(text: string[], length: number) {
        const result: string[] = []
        text.forEach(_ => {
            if (_.length > length)
                splitString(_, length).forEach(t => result.push(t))
            else result.push(_)
        })
        return result
    }

    function splitString(text: string, length: number): string[] {
        const result = []
        let remaining = text

        while (remaining) {
            let subString = remaining.slice(0, length)
            remaining = remaining.slice(subString.length)
            result.push(subString)
        }

        return result
    }

    splitStrings.forEach((_, i) => {
        const y = splitStrings.length === 1
            ? dy + RECT_HEIGHT / 2
            : dy + (i + 1) * LINE_HEIGHT

        ctx.fillText(_, dx + RECT_WIDTH / 2, y)
    })
}

function convertSessionBreachToExportFormat(sessionBreaches: SessionBreaches[]) {
    return sessionBreaches.map(_ => ({
        sessionId: _.sessionId,
        dayData: _.dayBreaches.map(_ => ({ dayId: _.dayOfWeek, averageCount: _.averageLimitCount }))
    }))
}

function convertMissedMonitoringToExportFormat(missedMonitoring: MissedMonitoringByDayAndSession[]) {
    return missedMonitoring.map(_ => ({
        sessionId: _.sessionId,
        dayData: _.missedMonitoring.map(_ => ({ dayId: _.dayOfWeek, averageCount: _.averageMissedSamplesCount }))
    }))
}

export {
    exportGraphByDayAndSession,
    convertSessionBreachToExportFormat,
    convertMissedMonitoringToExportFormat,
}
