import type TimeService from '_/services/time-service'
import type { AnalysisFilter, ThresholdLine } from '_/model/analysis/filter/types'
import type { LocationsReport } from '_/model/reports/locations-report/types'
import SUB_CHART_TYPE, * as subChartType from '_/model/analysis/sub-chart-type'
import * as chartType from '_/model/analysis/chart-type'

import { ANY_ID } from '_/constants/system-words'
import * as dateRanges from '_/constants/date-ranges'
import * as fieldIndex from '_/constants/custom-field-index'

import { getLast28DaysDate } from '../helpers'
import { isFloorPlanChart, isLinesMarkersChart } from '../ui/helpers'
import type { DateTime } from '_/model/date-time'
import type { ParticleState} from '_/model/predefined-lists/action-alert-limit/non-viable-limit-type'
import { PARTICLE_ANALYSIS_STATE } from '_/model/predefined-lists/action-alert-limit/non-viable-limit-type'
import { areArraysEqual } from '_/utils/array'
import type customField from '_/model/predefined-lists/custom-field/types'

function recalculateDynamicExposureDate(_: AnalysisFilter | LocationsReport, timeService: TimeService) {
    const now = timeService.now()

    let dateTo = now
      , dateFrom: DateTime

    switch (_.exposureDateRange) {
        case dateRanges.WEEK:
            dateFrom = timeService.addCtzDays(now, -6)
            break
        case dateRanges.MONTH:
            dateFrom = getLast28DaysDate(timeService, now)
            break
        case dateRanges.QUARTER:
            dateFrom = timeService.addCtzMonths(now, -3)
            break
        case dateRanges.YEAR:
            dateFrom = timeService.addCtzYears(now, -1)
            break
        case dateRanges.CUSTOM:
            dateFrom = _.exposureStartDateFrom ?? getLast28DaysDate(timeService, now)
            dateTo = _.exposureStartDateTo ?? now
            break
        default:
            dateFrom = now
    }

    return {
        exposureStartDateFrom: timeService.ctzDayStart(dateFrom),
        exposureStartDateTo: timeService.ctzDayEnd(dateTo),
        exposureDateRange: _.exposureDateRange,
    }
}

function getSubChartTypes(c: chartType.ChartTypeWithSubCharts) {
    switch (c) {
        case chartType.ACTION_LIMIT_BREACHES:
            return [
                SUB_CHART_TYPE[subChartType.NUMBER_OF_ACTION_LIMIT_BREACHES],
                SUB_CHART_TYPE[subChartType.ACTION_LIMIT_BREACH_RATE],
            ]
        case chartType.CONTAMINATED_SAMPLES:
            return [
                SUB_CHART_TYPE[subChartType.NUMBER_OF_CONTAMINATED_SAMPLES],
                SUB_CHART_TYPE[subChartType.CONTAMINATION_RATE],
            ]
        case chartType.ALERT_LIMIT_BREACHES:
            return [
                SUB_CHART_TYPE[subChartType.NUMBER_OF_ALERT_LIMIT_BREACHES],
                SUB_CHART_TYPE[subChartType.ALERT_LIMIT_BREACH_RATE],
            ]
    }
}

function totalSamplesBarAvailable(chartTypeId: chartType.ChartType | undefined) {
    return isLinesMarkersChart(chartTypeId)
        && chartTypeId !== chartType.TOTAL_SAMPLES_READ
        && chartTypeId !== chartType.PARTICLE_COUNTS
}

function isAnySelected(value: any) {
    const normalizedValue = Array.isArray(value) ? value : [value]
    return normalizedValue.some(_ => _ === ANY_ID)
}

function allParticleStatesAreSelected(particleStates: ParticleState[] | undefined){
    return !particleStates
        || particleStates.length === 0
        || areArraysEqual(PARTICLE_ANALYSIS_STATE.map(_ => _.id), particleStates)
}

function formatThresholdLineName(line: ThresholdLine) {
    return `${line.name} (${line.value} on ${line.axisName} axis)`
}

function isThresholdLineAvaible(chartTypeId: chartType.ChartType | undefined) {
    return !isFloorPlanChart(chartTypeId)
        && chartTypeId !== chartType.ORGANISMS_BREAKDOWN_CHART
}

function isActiveCustomField(field: customField, isNonViable: boolean) {
    return isNonViable ? field.nonViableSettings.isActive : field.viableSettings.isActive
}

const USED_FIELDS = [
    fieldIndex.EXPOSURE_LOCATION_ID,
    fieldIndex.SAMPLE_TYPE_ID,
    fieldIndex.SESSION_ID,
    fieldIndex.OPERATORS_IDS,
    fieldIndex.BATCH_NUMBER,
]

export {
    recalculateDynamicExposureDate,
    getSubChartTypes,
    totalSamplesBarAvailable,
    isAnySelected,
    allParticleStatesAreSelected,
    isActiveCustomField,
    USED_FIELDS,
    formatThresholdLineName,
    isThresholdLineAvaible,
}
