import { useAction, useEffect, useSelector } from '_/facade/react'

import { DASHBOARD } from '_/constants/routes'

import * as actions from './actions'
import { actions as routerActions  } from 'redux-router5'

function Maintenance() {
    const info = useSelector(_ => _.backendStatus.backendStatus.progressInfo)

    useCheckStatus()

    return (
        <div className='d-flex flex-column justify-content-center align-items-center h-100'>
            <h1 className='maintenance-page_header'>Maintenance</h1>
            <div className='maintenance-page_message'>
                <p>Sorry for the inconvenience but we're performing some maintenance at the moment.</p>
                <p>We'll be back up and running as fast as possible.</p>
                {info !== undefined && !info.error && <p>Progress: {info.progress}% ({info.step}/{info.totalSteps})</p>}
                <br/>
                <p>&mdash; The Team</p>
            </div>
        </div>
    )
}

export default Maintenance

function useCheckStatus() {
    const checkStatus = useAction(actions.checkStatus)
        , navigateTo = useAction(routerActions.navigateTo)
        , status = useSelector(_ => _.backendStatus.backendStatus)

    useEffect(
        () => {
            const intervalId = window.setInterval(
                () => {
                    checkStatus()
                        .then(() => {
                            if (status.serviceAvailable)
                                navigateTo(DASHBOARD)
                        })
                },
                2000
            )

            return () => clearInterval(intervalId)
        }
    )
}
