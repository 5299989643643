import { actionFactory } from '_/utils/redux'
import type { FormSampleType } from '_/model/predefined-lists/sample-type/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.sample-type')

export const loadSampleTypeList = action<void, Promise<void>>('loadSampleTypeList')
           , sampleTypeListLoaded = action<SampleType[]>('sampleTypeListLoaded')
           , loadSampleTypeTrail = action<string, Promise<AuditTrail>>('loadSampleTypeTrail')
           , loadSampleType = action<string, Promise<SampleType>>('loadSampleType')
           , createSampleType = action<FormSampleType, Promise<void>>('createSampleType')
           , saveSampleType = action<{ id: string, oldSampleType: FormSampleType, newSampleType: FormSampleType }, Promise<void>>('saveSampleType')
           , removeSampleType = action<string, Promise<void>>('removeSampleType')
           , sampleTypeRemoved = action<string>('sampleTypeRemoved')
