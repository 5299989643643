import type { ValidationResult} from '_/utils/form/validate'
import { required, maxLength, isEmpty, allowedName } from '_/utils/form/validate'
import type Context from '_/model/context/context'
import * as contexts from '_/constants/context-level'

import type { ContextForm } from './types'
import type { IpRestrictionValidationResponse } from './ip-restriction'

export default function validate(entity: Partial<ContextForm>, siblingContexts: Context[], ipRestrictionValidator: (ipRestriction: string) => Promise<IpRestrictionValidationResponse>) {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 256)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , timeZone = isEmpty('TimeZone')(entity.timeZoneName)
        , tier = required('Tier')(entity.tier)
        , ipRestriction = isEmpty('IP whitelist')(entity.ipRestriction)
        , smartCheckEnabled = entity.zeroGrowthVerificationEnabled && !entity.smartCheckEnabled
        , zeroGrowthVerificationEnabled = !entity.smartCheckEnabled && entity.zeroGrowthVerificationEnabled
        , result: ValidationResult<ContextForm> = {}
        , ipRestrictionValidationResult = entity.ipWhitelistEnabled && entity.ipRestriction
            ? ipRestrictionValidator(entity.ipRestriction)
                .then(_ => ({ ipRestriction: _.whitelistValid ? undefined : _.errorMessage }))
            : Promise.resolve({})

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (notAllowedNames)
        result.name = notAllowedNames

    if (entity.name && siblingContexts.find(_ => _.name.trim().toLowerCase() === entity.name?.trim().toLowerCase()))
        result.name = `${entity.level === contexts.SITE ? 'Site' : 'Organisation'} with name '${entity.name.trim()}' already exists`

    if (timeZone)
        result.timeZoneName = timeZone

    if (entity.level === contexts.ORGANISATION && tier)
        result.tier = tier

    if (smartCheckEnabled)
        result.smartCheckEnabled = `Can't disable Smart check when Zero growth verification is enabled`

    if (zeroGrowthVerificationEnabled)
        result.zeroGrowthVerificationEnabled = `Can't enable Zero growth verification when Smart check is enabled`

    if (entity.ipWhitelistEnabled && ipRestriction)
        result.ipRestriction = ipRestriction

    return ipRestrictionValidationResult
        .then(ipRestrictionResult => ({...result, ...ipRestrictionResult}))
}
