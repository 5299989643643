import { useField } from 'react-final-form'
import Button from '_/components/button'

interface Props {
    inUse: boolean
    isActive: boolean
}

function ActiveField(props: Props) {
    const field = useField<boolean>('isActive')

    return (
        <Button
            className='bg-transparent border-0 me-2'
            onClick={() => field.input.onChange(!field.input.value)}
            disabled={!props.inUse && props.isActive}
            testId={field.input.value ? 'mark-inactive' : 'mark-active'}
        >
            {field.input.value ? 'Mark as inactive' : 'Mark as active' }
        </Button>
    )
}

export default ActiveField
