import type { GlobalAuditTrailsFilter } from './types'
import type { ValidationResult} from '_/utils/form/validate'
import { checkDateRangeValidity } from '_/utils/form/validate'

function validate(entity: Partial<GlobalAuditTrailsFilter>) {
    const result: ValidationResult<GlobalAuditTrailsFilter> = {}

    const dateTo = checkDateRangeValidity(entity.dateFrom, entity.dateTo)

    if (dateTo)
        result.dateTo = dateTo

    return result
}

export {
    validate,
}
