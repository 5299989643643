import * as fi from '_/constants/custom-field-index'
import * as tt from '_/model/predefined-lists/trend-settings/timeframe-type'
import * as ft from '_/model/predefined-lists/trend-settings/filter-type'
import * as rt from '_/model/predefined-lists/trend-settings/rule-type'

import { NUMBER, SELECTION, TEXT, TIME } from '_/constants/custom-field-column-type'

import type { PredefinedLists } from '_/model/app-state'
import type * as t from '_/model/predefined-lists/trend-settings/types'
import { isSystemId } from '_/model/predefined-lists/custom-field/custom-field'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import { concatTexts, formatActiveState } from '_/utils/format/common'

import { TREND_TIME_PERIOD } from './time-period'
import { fullNameLocationList } from '_/utils/exposure-location'
import { NOT_RECORDED, NOT_RECORDED_NAME } from '_/constants/system-words'
import { NO_SESSION } from '../session/no-session'
import type { Text } from '_/model/text/text'
import { defaultTextNode, emptyTextNode, systemTextNode } from '_/model/text/text'
import { formatFieldLabel } from '_/features/samples/helpers'
import type { LocationType } from '../exposure-location/location-type'
import LOCATION_TYPE from '../exposure-location/location-type'
import type { TheSameFiltersInfo } from '_/model/trends/types'
import type { RankedIdentificationType} from './ranked-identification-type'
import { RANKED_IDENTIFICATION_TYPE } from './ranked-identification-type'
import type { IdentificationType} from '_/model/sample/identification-type'
import { IDENTIFICATION_TYPE } from '_/model/sample/identification-type'

interface TrendSettingCustomField {
    index: fi.FieldIndex
    fieldName: string
    numberMeasureUnit?: string
    isActive: boolean
}

function getFieldInfo(index: number | undefined, predefinedLists: PredefinedLists): TrendSettingCustomField | undefined {
    return predefinedLists.customFields
        .map<TrendSettingCustomField>(_ => ({
            index: _.index,
            fieldName: _.fieldName,
            numberMeasureUnit: _.numberMeasureUnit,
            isActive: _.viableSettings.isActive
        }))
        .concat({index: fi.EXPOSURE_LOCATION_GRADE_ID, fieldName: 'Grade', isActive: true})
        .find(_ => _.index === index)
}

function getLocationTypeName(type: LocationType | undefined) {
    return LOCATION_TYPE.find(_ => _.id === type)?.name
}

function getIdentificationTypeName(type: IdentificationType | undefined) {
    return IDENTIFICATION_TYPE.find(_ => _.id === type)?.name
}

function getRankedIdentificationTypeName(type: RankedIdentificationType | undefined) {
    return RANKED_IDENTIFICATION_TYPE.find(_ => _.id === type)?.name
}

function formatIncludeAllChildren(includeAllChildren: boolean | undefined) {
    return includeAllChildren
        ? systemTextNode(' (including all child locations)')
        : emptyTextNode()
}

function filterFormat(filter: t.Filter | t.FilterEdit, predefinedLists: PredefinedLists) {
    switch (filter.type) {
        case ft.HAS: {
            const fieldInfo = getFieldInfo(filter.dataField, predefinedLists)
            return [
                defaultTextNode(`Has ${fieldInfo ? formatFieldLabel(fieldInfo) : ''} `),
                ...formatFieldValues(filter.dataField, predefinedLists, filter.value)
            ]
        }
        case ft.IN:
            return [
                defaultTextNode('In location '),
                ...formatFieldValues(fi.EXPOSURE_LOCATION_ID, predefinedLists, filter.exposureLocation),
                formatIncludeAllChildren(filter.includeAllChildren)
            ]
        case ft.HAS_THE_SAME: {
            const fields = filter.dataFields!.map(_ => getFieldInfo(_, predefinedLists)).filter(_ => _) as TrendSettingCustomField[]
                , convertedFields = fields.map(_ => formatActiveState(formatFieldLabel(_), _.isActive))

            return [defaultTextNode('Has the same '), ...concatTexts(convertedFields)]
        }
        case ft.IN_THE_SAME: {
            const locationTypesNames = filter.locationTypes!.map(getLocationTypeName).filter(_ => _) as string[]
                , convertedLocationTypesNames = locationTypesNames.map(_ => formatActiveState(_, true))
            return [defaultTextNode('In the same '), ...concatTexts(convertedLocationTypesNames), formatIncludeAllChildren(filter.includeAllChildren)]
        }
        case ft.HAS_IDENTIFICATION: {
            const identificationTypeName = getIdentificationTypeName(filter.identificationType)
                , convertedIdentificationNames = filter.names!.map(_ => formatActiveState(_.name, _.isActive))
            return [
                defaultTextNode('Has identification '),
                ...(identificationTypeName
                    ? [
                        defaultTextNode(`${identificationTypeName}: `),
                        ...concatTexts(convertedIdentificationNames)
                    ]
                    : [emptyTextNode()]
                )
            ]
        }
        case ft.HAS_THE_SAME_IDENTIFICATION: {
            const identificationTypeNames = filter.identificationTypes!.map(_ => getRankedIdentificationTypeName(_)).filter(_ => _) as string[]
                , convertedIdentificationTypeNames = identificationTypeNames.map(_ => [defaultTextNode(_)] )
            return [
                defaultTextNode('Has the same identification '),
                ...concatTexts(convertedIdentificationTypeNames)
            ]
        }
        default: return
    }
}

function formatTheSameValues(theSameFiltersInfo: TheSameFiltersInfo, predefinedLists: PredefinedLists) {
    switch(theSameFiltersInfo.type) {
        case ft.HAS_THE_SAME: {
            const namesValuesTexts = theSameFiltersInfo.filters
                .filter(_ => getFieldInfo(_.dataField, predefinedLists))
                .map(_ => [
                    ...formatActiveState(formatFieldLabel(getFieldInfo(_.dataField, predefinedLists)!), true),
                    defaultTextNode(' '),
                    ...formatFieldValue(_.dataField, predefinedLists, _.value)
                ])

            return [defaultTextNode('Has the same '), ...concatTexts(namesValuesTexts, ' and ')]
        }
        case ft.IN_THE_SAME: {
            const namesValuesTexts = theSameFiltersInfo.filters
                .filter(_ => getLocationTypeName(_.locationType))
                .map(_ => [
                    defaultTextNode(getLocationTypeName(_.locationType)!),
                    defaultTextNode(' '),
                    ...formatFieldValue(fi.EXPOSURE_LOCATION_ID, predefinedLists, _.value),
                    formatIncludeAllChildren(_.includeAllChildren),
                ])

            return [defaultTextNode('In the same '), ...concatTexts(namesValuesTexts, ' and ')]
        }
        case ft.HAS_THE_SAME_IDENTIFICATION: {
            const namesValuesTexts = theSameFiltersInfo.filters
                .filter(_ => getRankedIdentificationTypeName(_.identificationType))
                .map(_ => [
                    defaultTextNode(`${getRankedIdentificationTypeName(_.identificationType)!} ${_.name}`)
                ])

            return [defaultTextNode('Has the same identification '), ...concatTexts(namesValuesTexts, ' and ')]
        }
    }
}


function formatFieldValues(fieldIndex: fi.FieldIndex | undefined, predefinedLists: PredefinedLists, values: any): Text {
    const field = predefinedLists.customFields.find(_ => _.index === fieldIndex)

    if (field?.fieldType === NUMBER && values)
        return [defaultTextNode(`from ${values.from} to ${values.to}`)]

    const normalizedValues = Array.isArray(values) ? values : [values]
        , result: Text[] = normalizedValues.reduce((res, _) => [...res, formatFieldValue(fieldIndex, predefinedLists, _)], [])

    return concatTexts(result)
}

function formatFieldValue(fieldIndex: fi.FieldIndex | undefined, predefinedLists: PredefinedLists, value: any): Text {
    if (!fieldIndex)
        return [emptyTextNode()]

    const locations = fullNameLocationList(predefinedLists.exposureLocations)
        , location = locations.find(_ => _.id === value)
        , field = predefinedLists.customFields.find(_ => _.index === fieldIndex)
        , getName = (_: any[]) => getEntityName(_, value)

    switch (fieldIndex) {
        case fi.EXPOSURE_LOCATION_ID:
            return value === NOT_RECORDED.id
                ? [systemTextNode(NOT_RECORDED.name)]
                : formatActiveState(location?.pathName ?? '', location?.isActive)
        case fi.SESSION_ID:
            return getName([...predefinedLists.sampleSessions, NOT_RECORDED, NO_SESSION])
        case fi.SAMPLE_TYPE_ID:
            return getName(predefinedLists.sampleTypes)
        case fi.MONITORING_POSITION:
            return getName(locations)
        case fi.OPERATORS_IDS:
            return getName([...predefinedLists.sampleOperators, NOT_RECORDED])
        case fi.EXPOSURE_LOCATION_GRADE_ID:
            return getName(predefinedLists.grades)
        case fi.BATCH_NUMBER:
            return value === NOT_RECORDED_NAME ? [systemTextNode(value)] : [defaultTextNode(value)]
        default:
            return value === NOT_RECORDED.id
                ? [systemTextNode(NOT_RECORDED.name)]
                : getFiledValue(field, value)
    }
}

function getEntityName(list: any[], id: string | number) {
    const entity = list.find(_ => _.id === id)

    if (!entity)
        return [emptyTextNode()]

    return isSystemId(id)
        ? [systemTextNode(entity.name)]
        : formatActiveState(entity.name, entity.isActive)
}

function getFiledValue(field: CustomField | undefined, value: any) {
    if (!field)
        return [emptyTextNode()]

    if (field.fieldType === NUMBER || field.fieldType === TEXT || field.fieldType === TIME) {
        return [defaultTextNode(value)]
    }

    if (field.fieldType === SELECTION) {
        const selectedValue = (field.selectionFieldValues ?? []).find(_ => _.id === value)

        return formatActiveState(selectedValue?.name ?? '', selectedValue?.isActive)
    }

    return [emptyTextNode()]
}

function timeframeFormat(timeframe: t.Timeframe) {
    switch (timeframe.type) {
        case tt.WITHIN: {
            const timePeriod = TREND_TIME_PERIOD.find(_ => _.id === timeframe.period)
            return `Within ${timeframe.count} ${timeframe.count === 1 ? timePeriod?.nameSingularForm : timePeriod?.name.toLowerCase()}`
        }
        case tt.FOR_CONSECUTIVE_SAMPLES:
            return `For ${timeframe.count} consecutive viable samples`
    }
}

function ruleFormat(rule: t.Rule) {
    if (isLimitRule(rule)) {
        function getLimitName(rule: t.LimitRule) {
            switch (rule.type) {
                case rt.ACTION_LIMIT_BREACHES:
                    return 'action limit'
                case rt.ALERT_LIMIT_BREACHES:
                    return 'alert limit'
                case rt.ACTION_OR_ALERT_LIMIT_BREACHES:
                    return 'alert or action limit'
            }
        }
        return `${rule.count} ${getLimitName(rule)} ${rule.count === 1 ? 'breach' : 'breaches'}`
    }
    else if (isIncreasingCfuRule(rule)) {
        switch (rule.type) {
            case rt.STRICTLY_INCREASING_CFU_COUNT:
                return 'Strictly increasing CFU count'
            case rt.NON_STRICTLY_INCREASING_CFU_COUNT:
                return 'Non-strictly increasing CFU count'
        }
    }
}

function isLimitRule(rule: t.Rule | undefined): rule is t.LimitRule {
    return [rt.ALERT_LIMIT_BREACHES, rt.ACTION_LIMIT_BREACHES, rt.ACTION_OR_ALERT_LIMIT_BREACHES].some(_ => _ === rule?.type)
}

function isIncreasingCfuRule(rule: t.Rule | undefined): rule is t.IncreasingCfuRule {
    return [rt.STRICTLY_INCREASING_CFU_COUNT, rt.NON_STRICTLY_INCREASING_CFU_COUNT].some(_ => _ === rule?.type)
}

function toIncreasingCfuRuleEdit(rule: t.IncreasingCfuRule) {
    return { ...rule, mode: rule.type === rt.STRICTLY_INCREASING_CFU_COUNT }
}

function isRestrictedFilterForConsecutiveSamples(filterType: ft.TrendFilter | undefined) {
    return [ft.HAS_THE_SAME, ft.IN_THE_SAME, ft.HAS_THE_SAME_IDENTIFICATION].some(_ => _ === filterType)
}

export {
    filterFormat,
    formatTheSameValues,
    timeframeFormat,
    ruleFormat,
    isLimitRule,
    isIncreasingCfuRule,
    toIncreasingCfuRuleEdit,
    isRestrictedFilterForConsecutiveSamples,
}
