const INDIVIDUAL = 0
    , DAILY_SUMMARY = 1

type EmailNotificationFrequency = never
    | typeof INDIVIDUAL
    | typeof DAILY_SUMMARY

export {
    INDIVIDUAL,
    DAILY_SUMMARY,
    EmailNotificationFrequency,
}
