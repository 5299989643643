import type ImageTokens from '../image-tokens'
import { noop } from '_/utils/function'
import type FloorPlanService from '_/services/floor-plan-service'
import { downscaleImage } from '_/utils/image'

function factory(api: FloorPlanService) {
    const uploads = new Map<string, { url: string, promise: Promise<void>}>()

    return {
        startUpload,
        getTempTokens,
    }

    function startUpload(blob: Blob): Promise<{ id: string, promise: Promise<void> }> {
        return api.getUploadTokens().then(_ => startUploadInternal(_, blob))
    }

    function getTempTokens(imageId: string): ImageTokens | undefined {
        const upload = uploads.get(imageId)

        return upload && {
            imageId,
            original: upload.url,
            medium: upload.url,
        }
    }

    function startUploadInternal(tokens: ImageTokens, blob: Blob): { id: string, url: string, promise: Promise<void> } {
        const promise = downscaleImage(blob, 300)
            .then(mediumBlob =>
                Promise.all([
                    api.uploadImage(tokens.original, blob),
                    api.uploadImage(tokens.medium, mediumBlob),
                ]))
            .then(noop)
            .finally(() => {
                const url = uploads.get(tokens.imageId)?.url
                if (url)
                    URL.revokeObjectURL(url)

                uploads.delete(tokens.imageId)
            })

        const url = URL.createObjectURL(blob)

        uploads.set(tokens.imageId, { url, promise })

        return {
            id: tokens.imageId,
            url,
            promise,
        }
    }
}

export type FloorPlanUploadService = ReturnType<typeof factory>

export default factory
