import { useState, useAction, useEffect } from '_/facade/react'
import type { UserMembership, MsSsoResponse, AvailableMemberships } from '_/model/auth/types'
import * as a from '../actions'
import { Form } from 'react-final-form'
import { noop } from '_/utils/function'
import { navigateTo } from '_/features/routing/actions'
import * as routes from '_/constants/routes'

import ContextSwitcherInput from './context-switcher'
import { useCredentialExtractor } from '../hooks'

function SsoLogin(){
    const ssoLogIn = useAction(a.ssoLogIn)
        , ssoToken = useCredentialExtractor()
        , [toggleContextSwitcher, switchingContext] = useContextSwitcher()
        , [handleMembershipLoad, inProgress] = useMembershipLoader()
        , handleContextChange = useContextChangeHandler()
        , navigateToAction = useAction(navigateTo)

    useEffect(
        () => {
            if (!inProgress)
                handleMembershipLoad(ssoToken)
                    .then(response => {
                        if (response && response.memberships.length === 1 && ssoToken.type === 'success') {
                            ssoLogIn({idToken: ssoToken.idToken, sessionState: ssoToken.state, membershipId: response.memberships[0].id})
                            return
                        }

                        if (response)
                            toggleContextSwitcher(true, response.memberships)
                    })
        },
        [handleMembershipLoad, toggleContextSwitcher, ssoLogIn, inProgress, ssoToken]
    )

    function handleClose() {
        toggleContextSwitcher()
        navigateToAction(routes.LOG_IN)
    }

    return (
        <div className='auth h-100'>
            <div className='d-flex justify-content-center'>
                <div className='auth-form'>
                    <div className='auth-form-header-text text-white text-center'>Log in</div>
                    <Form
                        onSubmit={handleMembershipLoad}
                        render={form =>
                            <form onSubmit={form.handleSubmit}>
                                <ContextSwitcherInput
                                    isOpen={switchingContext.isOpen}
                                    onClose={handleClose}
                                    onChange={_ => handleContextChange(_, ssoToken)}
                                    values={switchingContext.values}
                                />
                            </form>
                        }
                    />
                </div>
            </div>
        </div>
    )
}

function useMembershipLoader() {
    const getAvailableMemberships = useAction(a.ssoAvailableMemberships)
        , [inProgress, setInProgress] = useState(false)
        , navigateToAction = useAction(navigateTo)

    function handleMembershipsLoad(response: MsSsoResponse): Promise<AvailableMemberships | undefined> {
        if (response.type === 'success') {
            setInProgress(true)
            return getAvailableMemberships({ idToken: response.idToken, sessionState: response.state })
        }

        navigateToAction(routes.LOG_IN)
        return Promise.resolve(undefined)
    }

    return [handleMembershipsLoad, inProgress] as const
}

function useContextSwitcher() {
    const [switchingContext, setSwitchingContext] = useState<{ isOpen: boolean, values: UserMembership[] }>({ isOpen: false, values: [] })

    function toggleContextSwitcher(isOpen = false, values: UserMembership[] = []) {
        setSwitchingContext({isOpen, values})
    }

    return [toggleContextSwitcher, switchingContext] as const
}

function useContextChangeHandler() {
    const ssoLogIn = useAction(a.ssoLogIn)
        , navigateToAction = useAction(navigateTo)

    return (context: UserMembership, response: MsSsoResponse) => {
        if (response.type === 'success') {
            return ssoLogIn({ idToken: response.idToken, sessionState: response.state, membershipId: context.id })
                .then(noop)
        }

        navigateToAction(routes.LOG_IN)
        return Promise.resolve()
    }
}

export default SsoLogin
