import { React } from '_/facade/react'
import { Table } from '_/components/table'
import type { DateSeries, DateValue } from '_/model/analysis/date-series'
import { useTimeService } from '_/components/time'
import type TimeService from '_/services/time-service'
import type { AggregationPeriod } from '_/model/analysis/aggregation-period'
import type SampleSearchFields from '_/model/sample/search'
import { getBucketStart, getDateRange, getNextDate } from '../helpers'
import type { DateTime} from '_/model/date-time'
import { greaterThanOrEqual } from '_/model/date-time'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    series: DateSeries[]
    aggregationPeriod: AggregationPeriod | undefined
    sampleListRouterParams: SampleSearchFields
}

function DateSeriesChartTabularView(props: Props) {
    const timeService = useTimeService()
        , dateRanges = generateTabularDateRanges(timeService, props.aggregationPeriod, props.sampleListRouterParams)

    if (props.series[0] && props.series[0].points.length !== dateRanges.length)
        return null

    return (
        <div className='d-flex overflow-auto'>
            <Table>
                <thead className='text-center'>
                    <tr className='analysis__tabular-view__min-col-width'>
                        <th className='align-middle'>Start date</th>
                        <th className='align-middle'>End date</th>
                        {props.series.map((v, i) =>
                            <th key={i} className='align-middle text-break'>
                                <FormattedText text={v.name ?? 'Values'} />
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {dateRanges.map((range, row) =>
                        <tr key={row}>
                            <td>{timeService.formatCtzDate(range.dateFrom)}</td>
                            <td>{timeService.formatCtzDate(range.dateTo)}</td>
                            {props.series.map((s, i) =>
                                <td key={i}>
                                    {formatTabularDataValue(s.points[row])}
                                </td>
                            )}
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    )
}

export default DateSeriesChartTabularView

function formatTabularDataValue(value: DateValue) {
    if (value.value === undefined)
        return ''

    const number = value.value.toFixed(Number.isInteger(value.value) ? 0 : 2)

    return value.unitOfMeasure ? `${number}${value.unitOfMeasure}` : number
}

function generateTabularDateRanges(timeService: TimeService, aggregationPeriod: AggregationPeriod | undefined, sampleListRouterParams: SampleSearchFields) {
    const result: {dateFrom: DateTime, dateTo: DateTime}[] = []
        , dateFrom = getBucketStart(sampleListRouterParams.dateFrom ?? timeService.now(), timeService, aggregationPeriod)
        , dateTo = sampleListRouterParams.dateTo ?? timeService.now()

    if (!aggregationPeriod)
        return [{ dateFrom, dateTo }]

    let currentDate = dateFrom

    while(greaterThanOrEqual(dateTo, currentDate)) {
        const nextDate = getNextDate(currentDate, timeService, aggregationPeriod)
            , dateRange = getDateRange(currentDate, nextDate, timeService)

        result.push(dateRange)
        currentDate = nextDate
    }

    result[0].dateFrom = sampleListRouterParams.dateFrom ?? timeService.now()
    result[result.length - 1].dateTo = sampleListRouterParams.dateTo ?? timeService.now()

    return result
}
