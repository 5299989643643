import type SampleDetails from '_/model/sample/sample-details'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type NonViableSampleView from '_/model/non-viable-sample/types'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'

import * as fieldIndex from '_/constants/custom-field-index'
import type { PredefinedLists } from '_/model/app-state'
import type { SampleEditedInfo } from '_/model/sample/sample'

import { getEditedFieldInfo } from '_/features/samples/helpers'

import SessionForm from '../sample-information/session-form'
import OperatorForm from '../sample-information/operator-form'
import LocationForm from '../sample-information/location-form'
import BatchNumberForm from '../sample-information/batch-number-form'
import BarcodeForm from '../sample-information/barcode-form'
import ExposureTimeForm from '../sample-information/exposure-time-form'

interface Props {
    position: number
    sample: SampleDetails | NonViableSampleView
    entity: SampleDetailsEdit | NonViableSampleEdit
    predefinedLists: PredefinedLists
    hasNoPermissions: boolean
    onSubmit: any
    sampleEditedInfo: SampleEditedInfo[]
    isViable?: boolean
}

function SampleField(props: Props) {
    const entity = props.entity
        , pos = props.position
        , handleSubmit = props.onSubmit
        , userCanEditSamples = !props.hasNoPermissions
        , { predefinedLists, sampleEditedInfo } = props
        , field = props.predefinedLists.customFields[pos]

    switch (field.index) {
        case fieldIndex.EXPOSURE_START_TIME: {
            return <div key={pos}>
                <ExposureTimeForm
                    entity={entity}
                    fields={predefinedLists.customFields}
                    onSubmit={handleSubmit}
                    hasNoPermissions={!userCanEditSamples}
                    sampleTypes={predefinedLists.sampleTypes}
                    startTimeEditedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.EXPOSURE_START_TIME)}
                    endTimeEditedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.EXPOSURE_END_TIME)}
                    isViable={props.isViable}
                />
            </div>
        }
        case fieldIndex.EXPOSURE_LOCATION_ID: {
            return <div key={pos}>
                <LocationForm
                    field={field}
                    sample={props.sample}
                    entity={entity}
                    onSubmit={handleSubmit}
                    predefinedLists={predefinedLists}
                    hasNoPermissions={!userCanEditSamples}
                    editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.EXPOSURE_LOCATION_ID)}
                    isViable={props.isViable}
                />
            </div>
        }
        case fieldIndex.SESSION_ID: {
            return <div key={pos}>
                <SessionForm
                    entity={entity}
                    field={field}
                    sessions={predefinedLists.sampleSessions}
                    onSubmit={handleSubmit}
                    hasNoPermissions={!userCanEditSamples}
                    editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.SESSION_ID)}
                    isViable={props.isViable}
                />
            </div>
        }
        case fieldIndex.OPERATORS_IDS: {
            return <div key={pos}>
                <OperatorForm
                    entity={entity}
                    field={field}
                    operators={predefinedLists.sampleOperators}
                    sampleTypes={predefinedLists.sampleTypes}
                    onSubmit={handleSubmit}
                    hasNoPermissions={!userCanEditSamples}
                    editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.OPERATORS_IDS)}
                    isViable={props.isViable}
                />
            </div>
        }
        case fieldIndex.BARCODE: {
            return <div key={pos}>
                <BarcodeForm
                    entity={entity}
                    field={field}
                    onSubmit={handleSubmit}
                    hasNoPermissions={!userCanEditSamples}
                    editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.BARCODE)}
                    isViable={props.isViable}
                />
            </div>
        }
        case fieldIndex.BATCH_NUMBER: {
            return <div key={pos}>
                <BatchNumberForm
                    entity={entity}
                    field={field}
                    onSubmit={handleSubmit}
                    hasNoPermissions={!userCanEditSamples}
                    editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.BATCH_NUMBER)}
                    isViable={props.isViable}
                />
            </div>
        }
        default: {
            return null
        }
    }
}

export default SampleField
