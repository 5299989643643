import { classnames, useState, useAction, useEffect, useSelector, useCallback } from '_/facade/react'

import Button from '_/components/button'
import { LinkButton } from '_/components/link'
import PageHeader from '_/components/page-header'
import { useContextSwitchObserver } from '_/components/context-observer'

import type { NonViableLimits } from '_/model/predefined-lists/action-alert-limit/non-viable-limits'
import type * as t from '_/model/predefined-lists/action-alert-limit/types'

import * as routes from '_/constants/routes'
import { useSampleTypes } from '_/hooks/shared-hooks'

import * as actions from './actions'
import * as contextActions from '_/features/contexts/actions'

import Limits from './limits'
import TrailsModal from './limit-trails-modal'
import AddNewGradeModal from './new-grade-modal'

function Grades() {
    const [showTrails, setShowTrails] = useState(false)
        , [showAddNewGradeModal, setShowAddNewGradeModal] = useState(false)
        , grades = useSelector(_ => _.predefinedLists.grades)
        , sampleTypes = useSampleTypes()
        , permissions = useSelector(_ => _.auth.permissions)
        , [actionAlertLimits, nonViableLimits, reload] = useLimits()

    useLoadDependentData()

    return (
        <>
            {showTrails && <TrailsModal onClose={() => setShowTrails(false)}/>}
            {showAddNewGradeModal && <AddNewGradeModal onClose={() => setShowAddNewGradeModal(false)} grades={grades}/>}
            <PageHeader title='Grades'>
                <div>
                    <Button
                        onClick={() => setShowTrails(true)}
                        disabled={!permissions.editActionAlertLimits}
                        className={classnames('btn-link', { disabled: !permissions.editActionAlertLimits })}
                        hasNoPermissions={!permissions.editActionAlertLimits}
                    >
                        View audit trails
                    </Button>
                    <Button
                        onClick={() => setShowAddNewGradeModal(true)}
                        className='btn-primary'
                        hasNoPermissions={!permissions.editActionAlertLimits}
                        testId='add-new-grade'
                    >
                        Add a new grade
                    </Button>
                </div>
            </PageHeader>
            <div className='p-3 mb-0'>
                On this page you can:
                <br />
                Set the threshold for triggering a limit breach
                <br />
                Choose whether marking an organism as objectionable triggers a limit breach
                <br />
                <LinkButton
                    routeName={routes.HELP}
                    routeParams={{ page: '/tutorials-sc/site_admin/predefined_lists/grades/' }}
                    className='btn-link ps-0'
                >
                    More information
                </LinkButton>
            </div>

            <div className='row m-0 overflow-auto'>
                {grades.map(grade =>
                    <Limits
                        key={grade.id}
                        grade={grade}
                        grades={grades}
                        sampleTypes={sampleTypes}
                        canEditLimits={permissions.editActionAlertLimits}
                        changeCfuConfirmationSetting={permissions.changeCfuConfirmationSetting}
                        limits={actionAlertLimits.filter(limits => limits.gradeId === grade.id)}
                        nonViableLimits={nonViableLimits.filter(limits => limits.gradeId === grade.id)}
                        onReloadLimits={reload}
                        testId={`grade-${grade.name}`}
                    />
                )}
            </div>
        </>
    )
}

export default Grades

function useLoadDependentData() {
    const loadGrades = useAction(actions.loadGradesList)
        , loadContexts = useAction(contextActions.loadContexts)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadGrades()
            loadContexts()
        },
        [contextSwitch, loadGrades, loadContexts]
    )
}

function useLimits() {
    const loadLimitsList = useAction(actions.loadLimitsList)
        , loadNonViableLimits = useAction(actions.loadNonViableLimitsList)
        , contextSwitch = useContextSwitchObserver()
        , [nonViableLimits, setNonViableLimits] = useState<NonViableLimits[]>([])
        , [viableLimits, setViableLimits] = useState<t.Limits[]>([])

        , load = useCallback(
            () => {
                Promise.all([loadLimitsList(), loadNonViableLimits()])
                    .then(([viableLimitsList, nonViableLimitsList]) => {
                        setViableLimits(viableLimitsList)
                        setNonViableLimits(nonViableLimitsList)
                    })
            },
            [loadLimitsList, loadNonViableLimits]
        )

    useEffect(() => { load() }, [contextSwitch, load])

    return [viableLimits, nonViableLimits, load] as const
}
