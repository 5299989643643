import type { Spinner as State } from '_/model/app-state'

import { handler, shallowUpdate, commonReducer } from '_/facade/reducer'

import * as actions  from './actions'

const defaultState: State = {
    showSpinner: false,
}

export default commonReducer(
    defaultState,
    handler(actions.showSpinner, state => shallowUpdate(state, { showSpinner: true })),
    handler(actions.hideSpinner, state => shallowUpdate(state, { showSpinner: false })),
)
