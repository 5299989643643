import { classnames, useState, useSelector } from '_/facade/react'

import type SampleDetails from '_/model/sample/sample-details'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'

import SampleLabels from '_/components/sample-labels'
import PageHeader from '_/components/page-header'
import { LinkButton } from '_/components/link'
import Button from '_/components/button'

import * as routes from '_/constants/routes'
import * as fieldIndex from '_/constants/custom-field-index'

import RaiseIssueModal from './sample-information/raise-issue-modal'
import type Permissions from '_/model/permissions/type'
import { paramsFilter } from '_/model/filters/helpers'

import { getFieldValue, getFieldNotRecorded } from '../helpers'

interface Props {
    sample: SampleDetails
    entity: SampleDetailsEdit
    onSubmit: (sample: SampleDetailsEdit) => Promise<void>
    onShowAuditTrail: () => void
    permissions: Permissions
}

function SampleDetailsPageHeader(props: Props) {
    const [showRaiseIssueModal, setShowRiseIssueModal] = useState(false)
        , hasNoPermissionToRiseIssue = !props.permissions.editSamples
        , editedField = useSelector(_ => _.samples.currentEditedSampleField)

    return (
        <PageHeader sticky title='Viable sample details' testId='sample'>
            <SampleLabels sample={props.sample}/>
            <div className='d-print-none'>
                <LinkButton
                    routeName={routes.SAMPLES_REPORT}
                    className='btn-primary'
                    routeParams={{ id: props.sample.id }}
                    hasNoPermissions={!props.permissions.readSampleReport}
                    testId='go-to-sample-report'
                >
                    Viable sample report
                </LinkButton>
                <LinkButton
                    routeName={routes.LOCATIONS_REPORT}
                    className='btn-primary ms-2'
                    routeParams={paramsFilter({ locationId: getFieldValue(props.entity.fields, fieldIndex.EXPOSURE_LOCATION_ID) })}
                    disabled={getFieldNotRecorded(props.entity.fields, fieldIndex.EXPOSURE_LOCATION_ID)}
                    hasNoPermissions={!props.permissions.readLocationsReport}
                    testId='go-to-location-report'
                >
                    Location report
                </LinkButton>
                <LinkButton
                    routeName={routes.SAMPLES}
                    className='btn-link'
                    testId='go-to-sample-list'
                >
                    Go back to viable sample list
                </LinkButton>
                <Button
                    onClick={props.onShowAuditTrail}
                    className='btn-link'
                    testId='sample-audit-trail'
                >
                    View audit trail
                </Button>
                <Button
                    onClick={() => setShowRiseIssueModal(true)}
                    className={classnames('btn-link', { disabled: hasNoPermissionToRiseIssue })}
                    hasNoPermissions={hasNoPermissionToRiseIssue}
                    disabled={hasNoPermissionToRiseIssue || editedField !== ''}
                    testId='raise-issue-modal'
                >
                    Raise an issue
                </Button>
            </div>

            {showRaiseIssueModal &&
                <RaiseIssueModal
                    onSubmit={props.onSubmit}
                    onClose={() => setShowRiseIssueModal(false)}
                    isRead={props.sample.firstReadAt !== undefined}
                    entity={props.entity}
                    sampleId={props.sample.id}
                />
            }
        </PageHeader>
    )
}

export default SampleDetailsPageHeader
