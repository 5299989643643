import type { IdentificationRows } from '_/model/sample/sample'
import { contaminations } from '_/features/samples/helpers'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    identifications: IdentificationRows | undefined
    showOnlyIdentification?: boolean
}

function Contaminations(props: Props) {
    if (!props.identifications)
        return null

    return (
        <span className='user-formatted-text'>
            {contaminations(props.identifications, props.showOnlyIdentification).map((row, rowIdx) => {
                return row.types.map((type, typeIdx) =>
                    <span key={`${rowIdx} ${typeIdx}`} data-testid='contamination-list-item'>
                        <span className={typeIdx !== 0 ? 'invisible' : undefined}>{row.cfuCount} x </span>
                        <FormattedText text={type} />
                        <br />
                    </span>
                )
            }).flatMap(_ => _)}
        </span>
    )
}

export default Contaminations
