import type AppState from '_/model/app-state'
import type { Middleware } from '_/utils/redux'
import * as actions from './actions'

const sessionMw: Middleware<AppState> = store => {
    let hasUnhandledActivity = false

    function handleActivity() {
        hasUnhandledActivity = true
    }

    function checkActivity() {
        const hasSession = store.getState().auth.user !== undefined
            , keepSession = hasSession && hasUnhandledActivity

        hasUnhandledActivity = false

        if (keepSession)
            store.dispatch(actions.keepAlive())
    }

    window.addEventListener('pointerdown', handleActivity, { passive: true })
    window.addEventListener('keydown', handleActivity, { passive: true })
    window.setInterval(checkActivity, 30000)

    return next => action => next(action)
}

export default sessionMw
