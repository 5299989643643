import { useState, useSelector, useCallback, useAction, useEffect } from '_/facade/react'
import { useNonViableLimits } from '_/hooks/shared-hooks'

import PlainText from '_/features/samples/sample-edit/plain-text'
import { useContextSwitchObserver } from '_/components/context-observer'
import AuditTrailModal from '_/components/audit-trail-modal'
import { useTimeService } from '_/components/time'

import type CustomField from '_/model/predefined-lists/custom-field/types'

import { memoize, noop } from '_/utils/function'

import { getGeneratedBy, getNotDefaultCustomFields } from '_/features/samples/helpers'
import { usePredefinedLists } from '_/features/samples/sample-edit/shared/shared-sample-hooks'

import type { Guid } from '_/model/guid'
import type NonViableSampleView from '_/model/non-viable-sample/types'
import * as h from '_/model/non-viable-sample/edit/helpers'

import * as customFieldsActions from '_/features/predefined-lists/custom-fields/actions'
import * as predefinedListsActions from '_/features/predefined-lists/redux/actions'
import * as sampleActions from '_/features/samples/actions'
import * as toastActions from '_/features/toasts/actions'
import * as actions from '../actions'

import PageHeader from './page-header'
import SampleField from '_/features/samples/sample-edit/shared/sample-fields'
import NotDefaultSampleField from '_/features/samples/sample-edit/shared/not-default-sample-fields'
import ContaminationFieldForm from './contamination-field'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'
import type { SampleEditedInfo } from '_/model/sample/sample'
import { CHANGES_SAVED } from '_/features/samples/messages'
import type { FieldIndex } from '_/constants/custom-field-index'

function NonViableSampleEditPage() {
    const [id, nonViableSample, customFields, editedInfo, reset] = useNonViableSample()
        , predefinedLists = usePredefinedLists(nonViableSample, customFields)
        , nonViableSampleEdit = useNonViableSampleEdit(nonViableSample, predefinedLists.customFields)
        , [showAuditTrail, setShowAuditTrail] = useState(false)
        , timeService = useTimeService()
        , user = useSelector(_ => _.auth.user)
        , nonViableLimits = useNonViableLimits()
        , handleSubmit = useSubmitHandler(id, nonViableSampleEdit, nonViableSample?.editedFields ?? [], reset)
        , permissions = useSelector(_ => _.auth.permissions)

    return (
        <div className='container-fluid'>
            {showAuditTrail &&
                <AuditTrailModal
                    id={id}
                    onClose={() => setShowAuditTrail(false)}
                    loadAuditTrailAction={actions.loadNonViableSampleTrail}
                />
            }
            <div className='row justify-content-center d-print-block'>
                {nonViableSample && nonViableSampleEdit &&
                    <div className='col-9 width-print-100'>
                        <PageHeader id={id} breachType={nonViableSample.breachType} showAuditTrail={() => setShowAuditTrail(true)} />
                        <div className='row align-items-start d-print-block sample-edit__word_wrap'>
                            {user &&
                                <span className='d-none d-print-block ps-4'>
                                    {getGeneratedBy(timeService, user.name, user.email)}
                                </span>
                            }
                            <div className='col-5 width-print-100'>

                                <div className='block-border p-3 mb-3'>
                                    <fieldset>
                                        <div className='custom-legend'>Non-viable sample information</div>
                                        <div className='d-none d-print-block'>
                                            <PlainText text={h.breachLabelText(nonViableSample.breachType)} label='Limit breach' />
                                        </div>
                                        {predefinedLists.customFields.map((_, i) =>
                                            <SampleField
                                                key={i}
                                                position={i}
                                                sample={nonViableSample}
                                                entity={nonViableSampleEdit}
                                                predefinedLists={predefinedLists}
                                                onSubmit={handleSubmit}
                                                sampleEditedInfo={editedInfo}
                                                hasNoPermissions={!permissions.editSamples}
                                            />
                                        )}
                                        {getNotDefaultCustomFields(predefinedLists.customFields).map(field =>
                                            <NotDefaultSampleField
                                                key={field.id}
                                                entity={nonViableSampleEdit}
                                                field={field}
                                                sampleEditedInfo={editedInfo}
                                                predefinedLists={predefinedLists}
                                                hasNoPermissions={!permissions.editSamples}
                                                onSubmit={handleSubmit}
                                            />
                                        )}

                                        <PlainText
                                            text={nonViableSample.personBookingIn.name}
                                            label='Booked in by'
                                            title={nonViableSample.personBookingIn.email}
                                        />

                                        <PlainText
                                            text={
                                                <span>
                                                    {nonViableSample.editedBy.map(_ =>
                                                        <span key={_.id} title={_.email}>
                                                            {_.name}
                                                            <br />
                                                        </span>
                                                    )}
                                                </span>
                                            }
                                            label='Edited by'
                                        />
                                    </fieldset>
                                </div>

                                <div className='block-border p-3 mb-3'>
                                    <fieldset>
                                        <div className='custom-legend'>Contamination</div>
                                        <ContaminationFieldForm
                                            lowerParticle
                                            sample={nonViableSample}
                                            entity={nonViableSampleEdit}
                                            nonViableLimits={nonViableLimits}
                                            editedInfo={editedInfo}
                                            hasNoPermissions={!permissions.editSamples}
                                            onSubmit={handleSubmit}
                                        />
                                        <ContaminationFieldForm
                                            sample={nonViableSample}
                                            entity={nonViableSampleEdit}
                                            nonViableLimits={nonViableLimits}
                                            editedInfo={editedInfo}
                                            hasNoPermissions={!permissions.editSamples}
                                            onSubmit={handleSubmit}
                                        />
                                    </fieldset>
                                </div>

                            </div>


                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default NonViableSampleEditPage

function useNonViableSample() {
    const id = useSelector(_ => _.router.route!.params.id as Guid)
        , loadNonViableSample = useAction(actions.loadNonViableSample)

        , loadNonViableCustomFields = useAction(customFieldsActions.loadCustomFields)
        , loadPredefinedLists = useAction(predefinedListsActions.loadPredefinedLists)
        , loadEditedInfo = useAction(actions.loadNonViableSampleEditedInfo)
        , editSampleField = useAction(sampleActions.editSampleField)
        , contextSwitch = useContextSwitchObserver()
        , [fields, setFields] = useState<CustomField[]>([])
        , [nonViableSample, setNonViableSample] = useState<NonViableSampleView>()
        , [editedInfo, setEditedInfo] = useState<SampleEditedInfo[]>([])

    const load = useCallback(
        () => Promise.all([
                loadNonViableSample(id),
                loadNonViableCustomFields(),
                loadEditedInfo(id),
                loadPredefinedLists()
            ]).then(([nonViableSample, customFields, editedInfo]) => {
                setNonViableSample(nonViableSample as any)
                setFields(customFields.filter(_ => _.nonViableSettings.isActive))
                setEditedInfo(editedInfo)
            }
        ),
        [id, loadNonViableSample, loadEditedInfo, loadPredefinedLists, loadNonViableCustomFields]
    )

    useEffect(
        () => {
            load()
            return () => {
                editSampleField('')
            }
        },
        [contextSwitch, load, editSampleField]
    )

    return [id, nonViableSample, fields, editedInfo, load] as const
}

const memConvertSampleToSampleEdit = memoize(h.convertSampleToSampleEdit)

function useNonViableSampleEdit(sample: NonViableSampleView | undefined, editableFields: CustomField[]) {
    return sample && memConvertSampleToSampleEdit(sample, editableFields)
}

function useSubmitHandler(
    id: Guid,
    oldNonViableSample: NonViableSampleEdit | undefined,
    editedFields: FieldIndex[],
    load: () => Promise<void>
) {
    const saveChanges = useAction(actions.editReadNonViableSample)
        , addSuccess = useAction(toastActions.addSuccess)

    if (!oldNonViableSample)
        return () => Promise.resolve()

    return function handleSubmit(newNonViableSample: NonViableSampleEdit) {
        return saveChanges({ id, oldNonViableSample, newNonViableSample, editedFields })
            .then(load)
            .then(_ => addSuccess(CHANGES_SAVED))
            .then(noop)
    }
}
