import type { FormRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { getIn } from 'final-form'

import { SelectField, LocalDateField, TextTimeField, CheckboxField, MultiSelectField } from '_/components/form'
import { TextField } from '_/components/form/text-field'
import OptionalDate from '_/components/optional-date'
import NumberTextField from '_/components/form/number-text-field'
import MonitoringStateField from '_/components/monitoring-state-field'

import type { ViableSampleBookingForm } from '_/model/sample/booking/types'
import type { PredefinedLists } from '_/model/app-state'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'

import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'
import * as fieldTypes from '_/constants/custom-field-column-type'
import * as fieldIndex from '_/constants/custom-field-index'
import { formatFieldLabel, valuePath } from '../helpers'
import type { subLocationList } from './helpers'
import { canEditExposureTime, getEntities } from './helpers'
import BatchNumberField from '../shared/batch-number-field'

interface Props {
    pos: number
    form: FormRenderProps<Partial<ViableSampleBookingForm>>
    initialValue: ViableSampleBookingForm
    exposureLocations: ListExposureLocation[]
    predefinedLists: PredefinedLists
    fieldPrefix: string
    locationChildren: ReturnType<typeof subLocationList>
    hideOptionalDate: boolean
    getRef: (key: string) => React.RefObject<HTMLInputElement> | undefined
    setRef: (_: string) => void
}

function SampleBookingCustomFields(props: Props) {
    const customField = props.predefinedLists.customFields[props.pos]
        , fieldId = `${props.fieldPrefix}field-${customField.index}`
        , notRecordedFieldId = `${fieldId}.notRecorded`
        , testId = `${props.fieldPrefix}field-${customField.index}` //added for selenium tests, do not change
        , notRecordedTestId = `${testId}-notRecorded` //added for selenium tests, do not change
        , namePrefix = `fields[${props.pos}]`
        , valueName = `${namePrefix}.value`
        , notRecordedName = `${namePrefix}.notRecorded`
        , notRecordedState = props.form.form.getFieldState(notRecordedName as any)
        , isNotRecorded = !!(notRecordedState && notRecordedState.value)
        , label = formatFieldLabel(customField)
        , atRestName = fieldId + 'atRest'
        , differentDateName = 'show-' + `${props.fieldPrefix}field-${fieldIndex.EXPOSURE_END_DATE}`
        , isLocationField = customField.index === fieldIndex.EXPOSURE_LOCATION_ID
        , scheduledSampleId = `${props.fieldPrefix}scheduled-sample`

    if (customField.viableSettings.notRecorded)
        props.setRef(notRecordedName)

    if (customField.index === fieldIndex.BARCODE) {
        props.setRef(fieldId)
        props.setRef(scheduledSampleId)
    }

    if (customField.index === fieldIndex.EXPOSURE_END_TIME)
        props.setRef(fieldId)

    if (customField.index === fieldIndex.EXPOSURE_END_TIME)
        props.setRef(differentDateName)

    if (isLocationField)
        props.setRef(atRestName)

    if (customField.index === fieldIndex.EXPOSURE_END_DATE)
        return null

    switch (customField.fieldType) {
        case fieldTypes.DATE: {
            return <div key={namePrefix}>
                <LocalDateField
                    autoFocus
                    id={fieldId}
                    name={valueName}
                    testId={testId}
                >
                    {label}
                </LocalDateField>
            </div>
        }
        case fieldTypes.TEXT: {
            return <div key={namePrefix}>
                {customField.index === fieldIndex.BATCH_NUMBER
                    ? <BatchNumberField
                        id={fieldId}
                        name={valueName}
                        disabled={isNotRecorded}
                        editing
                        hasLabel
                        isViable
                        onKeyDown={_ => customField.viableSettings.notRecorded && focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                        className='me-1'
                    />
                    : <TextField
                        id={fieldId}
                        name={`${namePrefix}.value`}
                        className='me-1'
                        inputRef={props.getRef(fieldId)}
                        disabled={isNotRecorded}
                        onKeyDown={_ => (customField.viableSettings.notRecorded || customField.index === fieldIndex.BARCODE) && focusElementOnAltNInput(props.getRef(customField.index === fieldIndex.BARCODE ? scheduledSampleId : notRecordedName), _)}
                        testId={testId}
                    >
                        {label}
                    </TextField>
                }
                {customField.viableSettings.notRecorded &&
                    <CheckboxField
                        id={notRecordedFieldId}
                        name={notRecordedName}
                        className='mx-1'
                        tabIndex={isNotRecorded ? 0 : -1}
                        inputRef={props.getRef(notRecordedName)}
                        testId={notRecordedTestId}
                    >
                        Not recorded
                    </CheckboxField>
                }
                {customField.index === fieldIndex.BARCODE &&
                    <CheckboxField
                        id={scheduledSampleId}
                        name='scheduledSample'
                        className='mx-1'
                        tabIndex={-1}
                        inputRef={props.getRef(scheduledSampleId)}
                    >
                        Scheduled sample
                    </CheckboxField>
                }
            </div>
        }
        case fieldTypes.NUMBER: {
            return <div key={namePrefix}>
                <NumberTextField
                    id={fieldId}
                    name={`${namePrefix}.value`}
                    className='me-1'
                    inputRef={props.getRef(fieldId)}
                    disabled={isNotRecorded}
                    onKeyDown={_ => customField.viableSettings.notRecorded && focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                    testId={testId}
                >
                    {label}
                </NumberTextField>
                {customField.viableSettings.notRecorded &&
                    <CheckboxField
                        id={notRecordedFieldId}
                        name={notRecordedName}
                        className='mx-1'
                        tabIndex={isNotRecorded ? 0 : -1}
                        inputRef={props.getRef(notRecordedName)}
                        testId={notRecordedTestId}
                    >
                        Not recorded
                    </CheckboxField>
                }
            </div>
        }
        case fieldTypes.TIME: {
            const endDatePos = props.predefinedLists.customFields.findIndex(_ => _.index === fieldIndex.EXPOSURE_END_DATE)
                , endDateName = valuePath(endDatePos)
                , startDatePos = props.initialValue.fields.findIndex(_ => _.index === fieldIndex.EXPOSURE_START_DATE)
                , startDateName = valuePath(startDatePos)
                , startDate = getIn(props.form.values, startDateName)
                , endDateId = `${props.fieldPrefix}field-${fieldIndex.EXPOSURE_END_DATE}`
                , endDateTestId = `${props.fieldPrefix}field-${fieldIndex.EXPOSURE_END_DATE}`

            function irrelevant() {
                if (customField.index !== fieldIndex.EXPOSURE_END_TIME)
                    return false

                const endTimeField = props.predefinedLists.customFields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)
                    , fields = props.form.values.fields as FieldValues[]
                    , sampleType = fields.find(_ => _.index === fieldIndex.SAMPLE_TYPE_ID)
                    , canEditEndTime = !!sampleType && canEditExposureTime(sampleType.value, props.predefinedLists.sampleTypes, endTimeField!.viableSettings.required)

                return !canEditEndTime
            }

            return <div style={{width: '11.5em'}} key={namePrefix}>
                <TextTimeField
                    id={fieldId}
                    name={valueName}
                    className='mx-1'
                    disabled={isNotRecorded || irrelevant()}
                    onKeyDown={_ => customField.index === fieldIndex.EXPOSURE_END_TIME
                        ? focusElementOnAltNInput(props.getRef(differentDateName), _)
                        : customField.viableSettings.notRecorded && focusElementOnAltNInput(props.getRef(notRecordedName), _)
                    }
                    inputRef={props.getRef(fieldId)}
                    testId={testId}
                >
                    {label}
                </TextTimeField>
                {customField.index === fieldIndex.EXPOSURE_END_TIME &&
                    <OptionalDate
                        className='mx-1'
                        disabled={isNotRecorded || irrelevant()}
                        forceHideDateField={irrelevant() || isNotRecorded || props.hideOptionalDate}
                        showDateFieldId={'show-' + endDateId}
                        dateFieldId={endDateId}
                        dateFieldName={endDateName}
                        form={props.form}
                        endTimeRef={props.getRef(fieldId)!}
                        differentDateRef={props.getRef(differentDateName)}
                        startDate={startDate}
                        onKeyDown={_ => customField.viableSettings.notRecorded && focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                        testId={endDateTestId}
                    />
                }
                {customField.viableSettings.notRecorded &&
                    <CheckboxField
                        id={notRecordedFieldId}
                        name={notRecordedName}
                        className='mx-1'
                        tabIndex={isNotRecorded ? 0 : -1}
                        disabled={irrelevant()}
                        inputRef={props.getRef(notRecordedName)}
                        testId={notRecordedTestId}
                        onKeyDown={_ => customField.index === fieldIndex.EXPOSURE_END_TIME && focusElementOnAltNInput(props.getRef(differentDateName), _)}
                    >
                        Not recorded
                    </CheckboxField>
                }
            </div>
        }
        case fieldTypes.SELECTION: {
            const operatorPos =  props.predefinedLists.customFields.findIndex(_ => _.index === fieldIndex.OPERATORS_IDS)
                , operatorsPath = valuePath(operatorPos)
                , operatorsValue = getIn(props.form.values, operatorsPath)

            return <div className='me-1' style={{width: '25em'}} key={namePrefix}>
                {customField.index === fieldIndex.OPERATORS_IDS
                    && <MultiSelectField
                        id={fieldId}
                        name={valueName}
                        entities={props.predefinedLists.sampleOperators}
                        calcId={_ => _.id}
                        calcName={_ => _.name}
                        autocomplete
                        disabled={isNotRecorded}
                        onKeyDown={_ => focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                    >
                        {label}
                    </MultiSelectField>
                }
                {customField.index !== fieldIndex.OPERATORS_IDS
                    && <SelectField
                        id={fieldId}
                        name={valueName}
                        entities={getEntities(customField.index, props.exposureLocations, props.locationChildren, props.predefinedLists)}
                        calcId={_ => _.id}
                        calcName={_ => {
                            if (customField.index === fieldIndex.EXPOSURE_LOCATION_ID)
                                return _.pathName

                            if (customField.index === fieldIndex.MONITORING_POSITION)
                                return _.shortName

                            return _.name
                        }}
                        autocomplete
                        showEndOfText
                        disabled={isNotRecorded}
                        onKeyDown={_ => focusElementOnAltNInput(props.getRef(isLocationField ? atRestName : notRecordedName), _)}
                        testId={testId}
                    >
                        {label}
                    </SelectField>
                }
                {isLocationField &&
                    <MonitoringStateField
                        id={atRestName}
                        monitoringState={props.form.values.monitoringState!}
                        className='mx-1'
                        inputRef={props.getRef(atRestName)}
                        onKeyDown={_ => focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                    />
                }
                {customField.viableSettings.notRecorded && customField.index !== fieldIndex.MONITORING_POSITION && (customField.index !== fieldIndex.OPERATORS_IDS || !operatorsValue)
                    ? <CheckboxField
                        id={notRecordedFieldId}
                        name={notRecordedName}
                        className='mx-1'
                        tabIndex={isNotRecorded ? 0 : -1}
                        inputRef={props.getRef(notRecordedName)}
                        testId={notRecordedTestId}
                        onKeyDown={_ => isLocationField && focusElementOnAltNInput(props.getRef(atRestName), _)}
                    >
                        Not recorded
                    </CheckboxField>
                    // required for final form field to be registered with given path
                    // otherwise MONITORING_POSITION will not be disabled (due to specific logic of this method)
                    : <Field name={notRecordedName} render={_ => null}/>
                }
            </div>
        }
        default: {
            return null
        }
    }
}

export default SampleBookingCustomFields
