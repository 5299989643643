import { useState, useAction, useEffect } from '_/facade/react'
import { debounce, noop } from '_/utils/function'

import MultipleFieldValueSearchField from '_/components/form/multi-values-search-field'

import * as actions from './actions'

interface Props {
    id: string
    name: string
    className?: string
    testId?: string
}

function MultipleTrendInvestigationRefSearch(props: Props) {
    const [entities, setEntities] = useState<string[]>([])
        , handleInputValueChange = useSearchTrendInvestigationRef(setEntities)

    return (
        <MultipleFieldValueSearchField
            id={props.id}
            name={props.name}
            label='Trend investigation refs'
            className={props.className}
            entities={entities}
            onChangeEntities={setEntities}
            onInputValueChange={handleInputValueChange}
            testId={props.testId}
        />
    )
}

function useSearchTrendInvestigationRef(setEntities: (_: string[]) => void) {
    const [searchHandler, setSearchHandler] = useState<(value: string) => void>(() => noop)
        , searchTrendInvestigationRef = useAction(actions.searchTrendInvestigationRef)

    useEffect(
        () => {
            function search(query: string) {
                const result = query.length >= 3
                    ? searchTrendInvestigationRef(query)
                    : Promise.resolve([])

                result.then(setEntities)
            }

            setSearchHandler(() => debounce(search, 300))
        },
        [searchTrendInvestigationRef, setEntities]
    )

    return searchHandler
}

export default MultipleTrendInvestigationRefSearch
