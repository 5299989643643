import { useRef, useAction, useSelector, useState, useCallback } from '_/facade/react'

import type { ViableSampleBookingFormSubmitProps, ViableSampleBooking } from '_/model/sample/booking/types'
import type { PredefinedLists } from '_/model/app-state'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import * as actions from '../actions'
import * as toastActions from '_/features/toasts/actions'
import * as reasonActions from '_/features/critical-change-reason/actions'
import * as spinnerActions from '_/features/spinner/actions'

import type { ResetFocusFormRef } from './sample-booking-form'
import  SampleBookingFormComponent from './sample-booking-form'
import SampleList from './list'
import SampleCommentsModal from './sample-comments-modal'
import SampleEditModal from './sample-edit-modal'
import * as h from '../helpers'
import * as sampleMessages from '../messages'
import { isSettleType, getDefaultFormValues } from './helpers'

import { memoize, noop } from '_/utils/function'

import * as fieldIndex from '_/constants/custom-field-index'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import { useDialog, useUnsavedChangesTracker } from '_/hooks/shared-hooks'
import { diffObject, dropFields } from '_/utils/object'
import type { MonitoringGroupProps, VacantExpectationDialogResult } from '../shared/vacant-expectation-dialog'
import VacantExpectationDialog, { getExpectationQueryProps, getMonitoringGroupId, updateExpectationFields } from '../shared/vacant-expectation-dialog'
import { useBookInDependentData } from '../shared/hooks'

const BOOKING_FORM_ID = 'booking-form'
    , BOOKING_EDIT_FORM_ID = 'booking-edit-form'

function SampleBookingComponent() {
    const formRef = useRef<ResetFocusFormRef>(null)
        , [predefinedLists, exposureLocations] = useBookInDependentData(true)
        , initialBookingValue = useInitialData(predefinedLists.customFields)
        , [bookedSamples, setBookedSamples] = useState(new Map<string, ViableSampleBooking[]>())
        , [editedSample, setEditedSample] = useState<ViableSampleBooking | undefined>()
        , [commentedSample, setCommentedSample] = useState<ViableSampleBooking | undefined>()
        , [comments, setComments] = useState<string[]>([])
        , loadComments = useLoadComments(setComments)
        , handleAddComment = useAddCommentHandler(bookedSamples, setBookedSamples, comments, setComments)
        , [getVacantExpectation, vacantExpectationDialogProps] = useDialog<VacantExpectationDialogResult, MonitoringGroupProps>()
        , handleBookSample = useBookNewSampleHandler(bookedSamples, setBookedSamples, getVacantExpectation)
        , sampleCount = Array.from(bookedSamples.values()).flat().length
        , handleOpenModal = useOpenCommentsModalHandler(setCommentedSample, loadComments)
        , reset = useCallback(
            () => {
                setCommentedSample(undefined)
                setEditedSample(undefined)
                formRef.current?.resetFocus()
            },
            []
        )
        , handleEditSample = useEditSampleHandler(predefinedLists, bookedSamples, setBookedSamples, reset, getVacantExpectation)

    useUnsavedChangesTracker(reset)

    return (
        <>
            <div
                style={{
                    // lets calendar to popup over table sticky headings but stay entire header a modal
                    zIndex: 5,
                }}
            >
                {vacantExpectationDialogProps &&
                    <VacantExpectationDialog {...vacantExpectationDialogProps} autoSelect />
                }
                <p className='fw-bold ms-3'>Add a new viable sample </p>
                {initialBookingValue.fields.length > 0 &&
                    <SampleBookingFormComponent
                        initialValue={initialBookingValue}
                        predefinedLists={predefinedLists}
                        exposureLocations={exposureLocations}
                        onSubmit={handleBookSample}
                        ref={formRef}
                        formTarget={BOOKING_FORM_ID}
                    />
                }
                <p className='fw-bold ms-3 py-4 mb-0' data-testid='added-samples-count'>Viable samples added ({sampleCount})</p>
            </div>
            <div className='flex-fill overflow-y-auto mb-3'>
                <SampleList
                    onOpenCommentsModal={handleOpenModal}
                    samples={bookedSamples}
                    exposureLocations={exposureLocations}
                    onOpenSampleEditModal={setEditedSample}
                    predefinedLists={predefinedLists}
                />
            </div>
            {commentedSample &&
                <SampleCommentsModal
                    onClose={reset}
                    onAddComment={(comment, compromised) => handleAddComment(commentedSample, comment, compromised)}
                    comments={comments}
                    compromised={commentedSample.compromised}
                />
            }
            {editedSample && editedSample.fields.length > 0 &&
                <SampleEditModal
                    onSubmit={_ => handleEditSample(editedSample, _)}
                    onClose={reset}
                    sample={{ fields: editedSample.fields, monitoringState: editedSample.monitoringState, scheduledSample: editedSample.scheduledSample }}
                    predefinedLists={predefinedLists}
                    exposureLocations={exposureLocations}
                    formTarget={BOOKING_EDIT_FORM_ID}
                />
            }
        </>
    )
}

const memGetDefaultFormValues = memoize(getDefaultFormValues)

function useInitialData(editableFields: CustomField[]) {
    const fieldValues = useSelector<FieldValues[]>(_ => _.router.route?.params.fields)
    return memGetDefaultFormValues(editableFields, fieldValues)
}

function useAddCommentHandler(
    bookedSamples: Map<string, ViableSampleBooking[]>,
    setBookedSamples: (_: Map<string, ViableSampleBooking[]>) => void,
    comments: string[],
    setComments: (_: string[]) => void
) {
    const addSampleComments = useAction(actions.addSampleComments)
        , addSuccess = useAction(toastActions.addSuccess)
        , changeCompromised = useAction(actions.changeCompromised)

    function handleAddComment(sample: ViableSampleBooking, comment: string, compromised: boolean) {
        const id = sample.id
            , changeCompromisedPromise = sample.compromised !== compromised
                ? changeCompromised({ id, compromised, checkBreach: false, approvalInfo: { reason: comment } })
                : Promise.resolve()

        changeCompromisedPromise
            .then(() => addSampleComments({ sampleId: id, sampleComments: [{ message: comment }] }))
            .then(() => setComments([comment].concat(comments)))
            .then(() => {
                const locationId = h.getFieldValue(sample.fields, fieldIndex.EXPOSURE_LOCATION_ID)
                    , newSamples = (bookedSamples.get(locationId) ?? []).map(updateBookedSample)

                setBookedSamples(bookedSamples.set(locationId, newSamples))
            })
            .then(() => addSuccess(sampleMessages.CHANGES_SAVED))

        function updateBookedSample(bookedSample: ViableSampleBooking) {
            return bookedSample.id !== id
                ? bookedSample
                : { ...bookedSample, comments: bookedSample.comments.concat(comment), compromised }
        }
    }

    return handleAddComment
}

function useLoadComments(setComments: (_: string[]) => void) {
    const showSpinner = useAction(spinnerActions.showSpinner)
        , hideSpinner = useAction(spinnerActions.hideSpinner)
        , load = useAction(actions.loadSampleComments)

    function loadComments(sampleId: string) {
        return Promise.resolve()
            .then(showSpinner)
            .then(() => load(sampleId))
            .then(_ => setComments(_.map(c => c.message).reverse()))
            .finally(hideSpinner)
    }

    return loadComments
}

function useOpenCommentsModalHandler(
    setCommentedSample: (_: ViableSampleBooking | undefined) => void,
    loadComments: (sampleId: string) => Promise<void>
) {
    function handleOpenCommentsModal(sample: ViableSampleBooking) {
        loadComments(sample.id)
            .then(() => setCommentedSample(sample))
    }

    return handleOpenCommentsModal
}

function useBookNewSampleHandler(
    bookedSamples: Map<string, ViableSampleBooking[]>,
    setBookedSamples: (_: Map<string, ViableSampleBooking[]>) => void,
    getVacantExpectation: (_: MonitoringGroupProps) => Promise<VacantExpectationDialogResult>,
) {
    const create = useAction(actions.createSample)

    function handleBookSample(sample: Partial<ViableSampleBookingFormSubmitProps>) {
        const expectationPromise = sample.scheduledSample
                ? getVacantExpectation({ fields: sample.fields! })
                : Promise.resolve({ fields: updateExpectationFields(sample.fields!, { groupId: undefined, locationId: undefined, sessionId: undefined }), isMonitoringExpectationChosenBySystem: false })

            , comments = sample.comments?.map(_ => ({message: _})) ?? []

        return expectationPromise
            .then(_ => {
                return create({
                    fields: _.fields,
                    monitoringState: sample.monitoringState,
                    comments,
                    compromised: sample.compromised,
                    isMonitoringExpectationChosenBySystem: !!_.isMonitoringExpectationChosenBySystem
                })
                .then(id => ({ ...sample, id, fields: _.fields } as ViableSampleBooking))
            })
            .then(bookedSample => {
                const locationId = h.getFieldValue(sample.fields, fieldIndex.EXPOSURE_LOCATION_ID)
                    , samples = bookedSamples.get(locationId) ?? []
                    , newBookedSamples = samples.concat(bookedSample)

                setBookedSamples(bookedSamples.set(locationId, newBookedSamples))
            })
            .then(noop)
    }

    return handleBookSample
}

function useEditSampleHandler(
    predefinedLists: PredefinedLists,
    bookedSamples: Map<string, ViableSampleBooking[]>,
    setBookedSamples: (_: Map<string, ViableSampleBooking[]>) => void,
    onEditModalClose: (_: boolean) => void,
    getVacantExpectation: (_: MonitoringGroupProps) => Promise<VacantExpectationDialogResult>,
) {
    const saveChanges = useAction(actions.editBookedInSample)
        , addSuccess = useAction(toastActions.addSuccess)
        , sampleTypeChanged = useAction(reasonActions.sampleTypeChanged)

    function handleEditSample(sample: ViableSampleBooking, editedSample: Partial<ViableSampleBookingFormSubmitProps>) {
        const newEditedSample = { ...sample, ...editedSample }
            , oldSample = dropFields(sample, 'comments', 'compromised')
            , newSample = dropFields(newEditedSample, 'comments', 'compromised')
            , newSampleTypeId = h.getFieldValue(newSample.fields, fieldIndex.SAMPLE_TYPE_ID)
            , oldSampleTypeId = h.getFieldValue(oldSample.fields, fieldIndex.SAMPLE_TYPE_ID)
            , endTimeNotRecorded = h.getFieldNotRecorded(newSample.fields, fieldIndex.EXPOSURE_END_TIME)

            , isSettlePlateChanged = isSettleType(predefinedLists.sampleTypes, oldSampleTypeId) && !isSettleType(predefinedLists.sampleTypes, newSampleTypeId)
            , isPlateTypeChangedToSettlePlate = !isSettleType(predefinedLists.sampleTypes, oldSampleTypeId) && isSettleType(predefinedLists.sampleTypes, newSampleTypeId)
            , newSettlePlateWithoutTime = endTimeNotRecorded && isPlateTypeChangedToSettlePlate
            , endTimeRequired = predefinedLists.customFields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)?.viableSettings.required

        if (!endTimeRequired)
            sampleTypeChanged({ isSettlePlateChanged: !!isSettlePlateChanged, isPlateTypeChangedToSettlePlate: !!newSettlePlateWithoutTime })

        const expectationFieldsChanged =
                !!diffObject(getExpectationQueryProps(oldSample.fields), getExpectationQueryProps(newSample.fields))
                || oldSample.scheduledSample !== newSample.scheduledSample
            , oldMonitoringGroupId = getMonitoringGroupId(oldSample.fields)
            , expectationPromise = expectationFieldsChanged
                ? newSample.scheduledSample
                    ? getVacantExpectation({ fields: newSample.fields, sampleId: sample.id, id: oldMonitoringGroupId })
                    : Promise.resolve({ fields: updateExpectationFields(newSample.fields, { groupId: undefined, locationId: undefined, sessionId: undefined }) })
                : Promise.resolve({ fields: newSample.fields })


        return expectationPromise
            .then(_ => {
                const { fields } = _
                return saveChanges({ id: sample.id, oldSample, newSample: { ...newSample, fields } })
                    .then(() => ({ ...newEditedSample, fields}))
            })
            .then(newEditedSample => {
                const oldLocationId = h.getFieldValue(oldSample.fields, fieldIndex.EXPOSURE_LOCATION_ID)
                    , newLocationId = h.getFieldValue(newSample.fields, fieldIndex.EXPOSURE_LOCATION_ID)

                handleEditedSample(newLocationId, newEditedSample, oldLocationId)
            })
            .then(() => addSuccess(sampleMessages.SAMPLE_SAVED))
            .then(() => onEditModalClose(true))
            .then(noop)
    }

    function handleEditedSample(locationId: string, sample: ViableSampleBooking, oldLocationId: string) {
        const newLocationSamples = bookedSamples.get(locationId) ?? []
        if (oldLocationId !== locationId) {
            const oldLocationSamples = bookedSamples.get(oldLocationId) ?? []
                , newSample = { ...oldLocationSamples.find(_ => _.id === sample.id), ...sample }
                , cleanedSamples = oldLocationSamples.filter(_ => _.id !== sample.id)
                , newSamples = bookedSamples
                    .set(oldLocationId, cleanedSamples)
                    .set(locationId, newLocationSamples.concat(newSample))

            setBookedSamples(newSamples)
        }
        else {
            const newSamples = newLocationSamples.map(_ => _.id === sample.id ? { ..._, ...sample} : _)

            setBookedSamples(bookedSamples.set(locationId, newSamples))
        }
    }

    return handleEditSample
}

export default SampleBookingComponent
