import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'

import * as confirmationActions from './actions'

let resolveAction: () => void
let rejectAction: (errorData: {isHandled: boolean}) => void

const factory = (): EffectsFactory => dispatch => [
    handler(confirmationActions.showGenericConfirmationModal, payload => {
        dispatch(confirmationActions.confirmationModalOpened(payload))

        return new Promise<void>((resolve, reject) => {
            resolveAction = resolve
            rejectAction = reject
        })
    }),

    handler(confirmationActions.confirmAction, () =>
        Promise.resolve()
            .then(() => dispatch(confirmationActions.confirmationModalClosed()))
            .then(() => resolveAction())
    ),

    handler(confirmationActions.cancelAction, () =>
        Promise.resolve()
            .then(() => dispatch(confirmationActions.confirmationModalClosed()))
            .then(() => rejectAction({isHandled: true}))
    ),
]

export default factory
