import { useAction, useSelector } from '_/facade/react'
import type { NonViableSampleForm } from '_/model/non-viable-sample/booking/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type { PredefinedLists } from '_/model/app-state'

import { Modal, ModalBody, ModalHeader } from '_/components/modal'
import { Close } from '_/components/button'

import NonViableSampleEditForm from './non-viable-sample-form'
import * as warningActions from '_/features/unsaved-changes/actions'

interface Props {
    onClose(): void
    nonViableSample: NonViableSampleForm
    predefinedLists: PredefinedLists
    exposureLocations: ListExposureLocation[]
    onSubmit: (nonViableSample: Partial<NonViableSampleForm>) => Promise<void>
    formTarget: string
}

function NonViableSampleEditModal(props: Props) {
    const handleClose = useClose(props.onClose, props.formTarget)

    return (
        <Modal isOpen onClose={handleClose} noDefaultContentWidth contentClassName='overflow-auto'>
            <ModalHeader className='sample-booking-edit-modal--width'>
                Edit non-viable sample
                <Close onClick={handleClose}/>
            </ModalHeader>
            <ModalBody noDefaultHeight className='sample-booking-edit-modal--width'>
                <NonViableSampleEditForm
                    exposureLocations={props.exposureLocations}
                    onSubmit={props.onSubmit}
                    initialValue={props.nonViableSample}
                    predefinedLists={props.predefinedLists}
                    formTarget={props.formTarget}
                    isEditScreen
                />
            </ModalBody>
        </Modal>
    )
}

function useClose(onClose: () => void, formTarget: string) {
    const unsavedChangeTargets = useSelector(_ => _.unsavedChange.unsavedChangeTargets)
        , showWarning = useAction(warningActions.showWarning)

    function handleClose() {
        if (unsavedChangeTargets.some(_ => _ === formTarget))
            showWarning({ showWarning: true })
        else
            onClose()
    }

    return handleClose
}

export default NonViableSampleEditModal
