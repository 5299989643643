import { classnames } from '_/facade/react'
import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'
import { dropFields } from '_/utils/object'
import { shallowUpdate } from '_/utils/object'
import type { LinkProps } from '_/utils/router/link'
import RouterLink from '_/utils/router/link'

interface ExtendedLinkProps extends LinkProps {
    hasNoPermissions?: boolean
    disabled?: boolean
    testId?: string
}

const Link: React.FC<ExtendedLinkProps> = props => {
    if (props.hasNoPermissions || props.disabled) {
        const forwardedProps = dropFields(props, 'routeName', 'routeParams', 'onClick', 'ignoredRoutes')

        return <span
            className={classnames('disabled', {'no-permission': props.hasNoPermissions}, forwardedProps.className)}
            title={forwardedProps.hasNoPermissions ? NO_PERMISSION_MESSAGE : forwardedProps.title}
            data-testid={props.testId}
        >
            {forwardedProps.children}
        </span>
    }
    else {
        const forwardedProps = dropFields(props, 'hasNoPermissions', 'disabled')
        return <RouterLink {...forwardedProps}/>
    }
}

const LinkButton: React.FC<ExtendedLinkProps> = props => {
        const forwardedProps = shallowUpdate(props, {className: 'btn ' + props.className || ''})

        return <Link {...forwardedProps}/>
}

export default Link
export { LinkButton }
