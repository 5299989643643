import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type LocationsReportService from '_/services/locations-report-service'

import * as actions from './actions'

const factory = (service: LocationsReportService): EffectsFactory => () => [
    handler(actions.loadLocationsReportData, payload =>
        Promise.resolve()
            .then(_ => service.getLocationsReportData(payload.id, payload.request))
    ),
]

export default factory
