import ClippedShape from './clipped-shape'

interface Props {
    size: number
}

function Rectangle(props: Props) {
    const cornerSize = props.size * 0.25
        , lineSize = props.size - cornerSize * 2
        , path: string[] = [
            `M -${lineSize / 2},-${props.size / 2}`,
            `h ${lineSize}`,
            `s ${cornerSize},0 ${cornerSize},${cornerSize}`,
            `v ${lineSize}`,
            `s 0,${cornerSize} -${cornerSize},${cornerSize}`,
            `h -${lineSize}`,
            `s -${cornerSize},0 -${cornerSize},-${cornerSize}`,
            `v -${lineSize}`,
            `s -0,-${cornerSize} ${cornerSize},-${cornerSize}`,
            `z`,
        ]

    return <ClippedShape path={path.join(' ')} size={props.size} />
}

export default Rectangle
