import { Form } from 'react-final-form'
import type { FormApi } from 'final-form'
import CONTEXT_LEVEL, * as contextLevel from '_/constants/context-level'

import { classnames, useState, useEffect, useAction, useSelector, useCallback } from '_/facade/react'

import type { RoleEdit } from '_/model/roles/types'
import type Role from '_/model/roles/types'

import * as routes from '_/constants/routes'
import * as tabs from '_/constants/modal-tab'

import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import { Submit, submitDisabled, TextField, CalculatedTextField, resetForm } from '_/components/form'
import Button, { Close } from '_/components/button'
import { Modal, ModalBody, ModalHeader, ModalFooter } from '_/components/modal'
import AuditTrails from '_/components/audit-trail-list'
import TabNavbar from '_/components/tab-navbar'

import * as routerActions from '_/features/routing/actions'
import * as deletionActions from '_/features/confirmation/actions'
import * as actions from '../../actions'
import PermissionField from './permission-field'
import validate from './validate'
import type { AuditTrail } from '_/model/audit-trail/types'

const defaultRoleEdit: Partial<RoleEdit> = {
        permissions: [],
        contextLevel: contextLevel.SITE,
    }
    , defaultTrail: AuditTrail = {
        events: [],
    }

interface Props {
    onUpdate: () => void
    allRoles: Role[]
}

function useEditModal(onUpdate: () => void) {
    const [initialValue, setInitialValue] = useState(defaultRoleEdit)
        , [role, setRole] = useState<Role>()
        , [trail, setTrail] = useState(defaultTrail)
        , roleId = useSelector<string | undefined>(_ => _.router.route!.params.id)
        , contextId = useSelector(_ => _.auth.user && _.auth.user.membership.contextId)
        , loadRole = useAction(actions.loadRole)
        , loadRoleTrail = useAction(actions.loadRoleTrail)
        , createRole = useAction(actions.createRole)
        , saveRole = useAction(actions.saveRole)
        , navigateTo = useAction(routerActions.navigateTo)

    useEffect(
        () => {
            setInitialValue(defaultRoleEdit)

            if (!roleId)
                return

            loadRole(roleId).then(role => {
                setRole(role)
                setInitialValue({
                    name: role.name,
                    contextLevel: role.contextLevel,
                    permissions: role.permissions.slice().sort((one, two) => one - two),
                })
            })

            loadRoleTrail(roleId).then(setTrail)
        },
        [roleId, loadRole, loadRoleTrail]
    )

    const navigateToList = useCallback(() => navigateTo(routes.SETTINGS_ROLES), [navigateTo])
        , submit = useCallback(
            (newRole: RoleEdit, form: FormApi<RoleEdit>) => {
                if (!contextId)
                    return

                const result = roleId
                        ? saveRole({
                            id: roleId,
                            oldRole: initialValue as RoleEdit,
                            newRole,
                        })
                        : createRole({ contextId, role: newRole })

                return result
                    .then(() => resetForm(form))
                    .then(() => {
                        navigateToList()
                        onUpdate()
                    })
            },
            [roleId, contextId, initialValue, createRole, saveRole, onUpdate, navigateToList]
        )
        , removeRole = useAction(actions.removeRole)
        , confirmDeletion = useAction(deletionActions.showDeletionConfirmationModal)
        , remove = useCallback(
            (form: FormApi<RoleEdit, Partial<RoleEdit>>) => {
                confirmDeletion(`Are you sure you want to delete ${role!.name}?`)
                    .then(_ => removeRole(role!.id))
                    .then(() => resetForm(form))
                    .then(navigateToList)
                    .then(onUpdate)
            },
            [role, removeRole, confirmDeletion, navigateToList, onUpdate]
        )

    return [roleId, role, trail, initialValue, navigateToList, remove, submit] as const
}

function EditModal(props: Props) {
    const [roleId, role, trail, initialValue, navigateToList, remove, submit] = useEditModal(props.onUpdate)
        , [activeTab, setActiveTab] = useState(tabs.DATA)
        , disabled = role ? !role.isEditable : false

    return (
        <Modal isOpen onClose={navigateToList}>
            <Form<RoleEdit>
                onSubmit={submit}
                validate={_ => validate(_, props.allRoles.filter(_ => role ? _.name !== role.name : true))}
                initialValues={initialValue}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form} />

                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4 data-testid='role-modal-title'>{roleId ? 'Edit role' : 'New role'}</h4>
                                    <Close onClick={navigateToList} testId='role-close' />
                                </div>
                                <TabNavbar>
                                    <Button
                                        onClick={() => setActiveTab(tabs.DATA)}
                                        className={classnames('btn-link navbar-tab me-4', { active: activeTab === tabs.DATA })}
                                    >
                                        Role data
                                    </Button>
                                    <Button
                                        onClick={() => setActiveTab(tabs.AUDIT_TRAIL)}
                                        className={classnames('btn-link navbar-tab', { active: activeTab === tabs.AUDIT_TRAIL })}
                                        disabled={!roleId}
                                        testId='role-audit-trails'
                                    >
                                        Audit trail
                                    </Button>
                                </TabNavbar>
                            </div>
                        </ModalHeader>

                        <ModalBody noDefaultHeight className='role-edit-modal'>
                            { activeTab === tabs.DATA &&
                                <>
                                    <TextField id='name' name='name' disabled={disabled} testId='field-name'>Name</TextField>
                                    <CalculatedTextField
                                        id='contextLevel'
                                        name='contextLevel'
                                        calcName={_ => CONTEXT_LEVEL.find(l => l.id === _)!.name}
                                        disabled
                                    >
                                        Context
                                    </CalculatedTextField>
                                    <PermissionField disabled={disabled} />
                                </>
                            }
                            { activeTab === tabs.AUDIT_TRAIL && <AuditTrails trail={trail} /> }
                        </ModalBody>

                        <ModalFooter className='border-top-0 modal-footer-height'>
                            {activeTab === tabs.DATA &&
                                <div className='d-flex flex-fill'>
                                    {roleId && !disabled &&
                                        <div>
                                            <Button
                                                className='text-danger bg-transparent border-0 me-2'
                                                onClick={() => remove(form.form)}
                                                disabled={role === undefined || role.inUse || !role.isEditable}
                                                testId='role-remove'
                                            >
                                                Remove
                                            </Button>
                                        </div>
                                    }
                                    <div className='ms-auto'>
                                        <Button className='text-muted bg-transparent border-0 me-2' onClick={navigateToList} testId='role-cancel'>
                                            Cancel
                                        </Button>
                                        <Submit
                                            hasNoPermissions={disabled}
                                            disabled={!!(roleId && !role) || submitDisabled(form)}
                                            testId='save-role'
                                        >
                                            {roleId ? 'Save changes' : 'Create'}
                                        </Submit>
                                    </div>
                                </div>
                            }
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default EditModal
