import Button from '_/components/button'

interface AddCommentsIconProps {
    onClick(): void
    numberOfComments?: number
    className?: string
    hasNoPermissions?: boolean
}

const AddCommentsIcon: React.FC<AddCommentsIconProps> = props =>
    <span className={`d-flex cursor-pointer ${props.className || ''}`}>
        <Button
            className='bg-transparent blue-focus p-0'
            onClick={props.onClick}
            hasNoPermissions={props.hasNoPermissions}
            testId='comments-modal-button'
        >
            <i className='material-icons'>chat_bubble_outline</i>
        </Button>
        {props.numberOfComments !== undefined && props.numberOfComments > 0 &&
            <span data-testid='comments-modal-amount'>{props.numberOfComments}</span>
        }
    </span>

export default AddCommentsIcon
