export const LOG_IN = 'log-in'
    , AVAILABLE_MEMBERSHIPS = 'available-memberships'
    , SSO_LOG_IN = 'sso-log-in'
    , SSO_AVAILABLE_MEMBERSHIPS = 'sso-available-memberships'
    , SSO_SIGN_UP = 'sso-sign-up'
    , AUTH_CONFIRM = 'confirm'
    , AUTH_CONFIRM_EMAIL = 'confirm-email'
    , CONFIRM_CHANGED_PASSWORD = 'confirm-changed-password'
    , FORGOT_PASSWORD = 'forgot-password'
    , FORGOT_PASSWORD_SENT = 'forgot-password-sent'
    , SET_PASSWORD_PAGE = 'set-password-page'
    , PLATES = 'plates'
    , PLATES_EXPORT = 'plates.export'
    , PLATES_READING = 'plates.reading'
    , SAMPLES = 'viable-samples'
    , SAMPLES_COLUMNS = 'viable-samples.columns'
    , SAMPLES_BOOKING = 'viable-samples.booking'
    , SAMPLES_EDIT = 'viable-samples.edit'
    , SAMPLES_READING = 'viable-samples.reading'
    , SAMPLES_REPORT = 'viable-samples.report'
    , SAMPLES_EXPORT = 'viable-samples.export'
    , NON_VIABLE_SAMPLES = 'non-viable-samples'
    , NON_VIABLE_SAMPLES_RECORDING = 'non-viable-samples.recording'
    , NON_VIABLE_SAMPLES_EXPORT = 'non-viable-samples.export'
    , NON_VIABLE_SAMPLES_EDIT = 'non-viable-samples.edit'
    , USERS_SIGN_UP = 'sign-up'
    , USER_ACCOUNT = 'account'
    , USER_ACCOUNT_GENERAL_SETTINGS = 'account.general-settings'
    , USER_NOTIFICATIONS = 'account.notifications'
    , USER_CHANGE_PASSWORD = 'account.change-password'
    , SETTINGS = 'settings'
    , SETTINGS_CONTEXTS = 'settings.contexts'
    , SETTINGS_CONTEXTS_CREATE = 'settings.contexts.create'
    , SETTINGS_CONTEXTS_EDIT = 'settings.contexts.edit'
    , SETTINGS_CONTEXTS_EXPORT = 'settings.contexts.export'
    , SETTINGS_USERS = 'settings.users'
    , SETTINGS_USERS_CREATE = 'settings.users.create'
    , SETTINGS_USERS_USER = 'settings.users.user'
    , SETTINGS_USERS_MEMBERSHIPS = 'settings.users.user.memberships'
    , SETTINGS_USERS_API_KEYS = 'settings.users.user.api-keys'
    , SETTINGS_USERS_API_KEYS_CREATE = 'settings.users.user.api-keys.create-api-key'
    , SETTINGS_USERS_AUDIT_TRAILS = 'settings.users.user.audit-trails'
    , SETTINGS_ROLES = 'settings.roles'
    , SETTINGS_ROLES_CREATE = 'settings.roles.create'
    , SETTINGS_ROLES_EDIT = 'settings.roles.edit'
    , SETTINGS_ORGANISM_IDENTIFICATION = 'settings.organism-identification'
    , SETTINGS_ORGANISM_IDENTIFICATION_IDENTIFICATIONS = 'settings.organism-identification.identifications'
    , SETTINGS_ORGANISM_IDENTIFICATION_TRAIL = 'settings.organism-identification.trail'
    , SETTINGS_OBJECTIONABLE_IDENTIFICATIONS = 'settings.objectionable-identifications'
    , SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_CREATE = 'settings.objectionable-identifications.create'
    , SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_EDIT = 'settings.objectionable-identifications.edit'
    , SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_TRAIL = 'settings.objectionable-identifications.trail'
    , SETTINGS_SAMPLE_TYPES = 'settings.sample-types'
    , SETTINGS_SAMPLE_TYPES_CREATE = 'settings.sample-types.create'
    , SETTINGS_SAMPLE_TYPES_EDIT = 'settings.sample-types.edit'
    , SETTINGS_LOCATIONS = 'settings.locations'
    , SETTINGS_EXPOSURE_LOCATIONS = 'settings.locations.exposure-locations'
    , SETTINGS_EXPOSURE_LOCATIONS_CREATE = 'settings.locations.exposure-locations.create-exposure-locations'
    , SETTINGS_EXPOSURE_LOCATIONS_EDIT = 'settings.locations.exposure-locations.edit-exposure-locations'
    , SETTINGS_FLOOR_PLANS = 'settings.locations.floor-plans'
    , SETTINGS_FLOOR_PLANS_ADD = 'settings.locations.floor-plans.add-floor-plan'
    , SETTINGS_FLOOR_PLANS_EDIT = 'settings.locations.floor-plans.edit-floor-plan'
    , SETTINGS_ACTION_ALERT_LIMITS = 'settings.action-alert-limits'
    , SETTINGS_OPERATORS = 'settings.operators'
    , SETTINGS_OPERATORS_CREATE = 'settings.operators.create'
    , SETTINGS_OPERATORS_EDIT = 'settings.operators.edit'
    , SETTINGS_SESSIONS = 'settings.sessions'
    , SETTINGS_SESSIONS_CREATE = 'settings.sessions.create'
    , SETTINGS_SESSIONS_EDIT = 'settings.sessions.edit'
    , SETTINGS_SAMPLE_FIELDS = 'settings.sample-fields'
    , SETTINGS_SAMPLE_FIELDS_CREATE = 'settings.sample-fields.create'
    , SETTINGS_SAMPLE_FIELDS_EDIT = 'settings.sample-fields.edit'
    , SETTINGS_TRENDS = 'settings.trends'
    , SETTINGS_TRENDS_CREATE = 'settings.trends.create'
    , SETTINGS_TRENDS_EDIT = 'settings.trends.edit'
    , DASHBOARD = 'dashboard'
    , ANALYSIS = 'analysis'
    , CONTEXTS_INVITE_CONFIRM = 'invite-confirm'
    , CONTEXTS_INVITE_CONFIRMED = 'invite-confirmed'
    , CONTEXTS_ASSIGN_CONFIRM = 'assign-confirm'
    , TRAILS = 'trails'
    , REPORTS = 'reports'
    , LIMIT_BREACH_REPORT = 'reports.limit-breach-report'
    , FINGERDABS = 'reports.fingerdabs'
    , FINGERDABS_DAILY_FINGERDABS = 'reports.fingerdabs.daily-fingerdabs'
    , FINGERDABS_OPERATOR_PERFORMANCE = 'reports.fingerdabs.operator-performance'
    , LOCATIONS_REPORT = 'reports.locations-report'
    , OPERATORS_REPORT = 'reports.operators-report'
    , MISSED_MONITORING_REPORT = 'reports.missed-monitoring-report'
    , HELP = 'help'
    , MAINTENANCE = 'maintenance'
    , CUSTOM_REPORTS = 'reports.custom-reports'
    , CUSTOM_REPORTS_CREATE = 'reports.custom-reports.create'
    , CUSTOM_REPORTS_EDIT = 'reports.custom-reports.edit'
    , CUSTOM_REPORTS_VIEW = 'reports.custom-reports.view'
    , SCHEDULING = 'scheduling'
    , SCHEDULING_MONTHLY_SCHEDULER = 'scheduling.monthly-scheduler'
    , SCHEDULING_MONITORING_GROUPS = 'scheduling.monitoring-groups'
    , SCHEDULING_MONITORING_GROUPS_CREATE = 'scheduling.monitoring-groups.create'
    , SCHEDULING_MONITORING_GROUPS_EDIT = 'scheduling.monitoring-groups.edit'
    , SCHEDULING_DAY_SCHEDULER = 'scheduling.day-scheduler'
    , SCHEDULING_DAY_SCHEDULER_TRAILS = 'scheduling.day-scheduler.trails'
    , SCHEDULING_DAY_SCHEDULER_CREATE_SERIES = 'scheduling.day-scheduler.create-series'
    , SCHEDULING_DAY_SCHEDULER_CREATE_AD_HOC_GROUP = 'scheduling.day-scheduler.create-ad-hoc-group'
    , SCHEDULING_MONITORING_OVERVIEW = 'scheduling.monitoring-overview'
    , SCHEDULING_MONITORING_OVERVIEW_TRAIL = 'scheduling.monitoring-overview.trail'
    , SCHEDULING_MONITORING_OVERVIEW_SAMPLE_TRAIL = 'scheduling.monitoring-overview.sample-trail'
    , NOTIFICATIONS = 'notifications'
    , TRENDS = 'trends'
    , TRENDS_ALL_TRENDS = 'trends.all-trends'
    , TRENDS_WITH_REF_TRENDS = 'trends.with-ref-trends'
    , TRENDS_WITHOUT_REF_TRENDS = 'trends.without-ref-trends'

import type * as routes from './routes'
type RouteName = (typeof routes)[keyof typeof routes]

export {
    RouteName,
}
