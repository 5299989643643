import { useTimeService } from '_/components/time'
import FormattedText from '_/features/text/formatted-text'
import type { PlateEditInfo } from '_/model/plate/plate'
import * as t from '_/model/text/text'

interface Props {
    info: PlateEditInfo | undefined
}

function EditInfo(props: Props) {
    const timeService = useTimeService()

    if (!props.info)
        return null

    const info = props.info

    function getDescription() {
        const time = timeService.formatCtzDateTime(info.lastEditedAt, true)

        if (info.updatedByMl)
            return `Edited by count model at ${time}`

        return `Edited by ${info.lastEditorName}, ${info.lastEditorEmail} at ${time}`
    }

    return (
        <span title={getDescription()}>
            <FormattedText text={[t.systemTextNode(' (edited)')]} />
        </span>
    )
}

export default EditInfo

