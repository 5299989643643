import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, isEmpty  } from '_/utils/form/validate'
import type { ContextInviteRequest } from '_/model/context/invite/types'
import { validateEmail } from '_/model/auth/sign-up/validate'

export default function validate(entity: Partial<ContextInviteRequest>, ownEmail: string | undefined): ValidationResult<ContextInviteRequest> {
    const email = validateEmail(entity.email) || isEmpty('Email')(entity.email)
        , emailLength = maxLength('Email', 128)(entity.email)
        , result: ValidationResult<ContextInviteRequest> = {}

    if (email)
        result.email = email

    if (emailLength)
        result.email = emailLength

    if (ownEmail?.trim() === entity.email?.trim())
        result.email = 'You can\'t invite yourself to the context '

    if (!entity.roleIds)
        result.roleIds = ['Roles is required'] as any

    return result
}
