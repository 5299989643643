import type NonViableSampleView from '_/model/non-viable-sample/types'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import { normalizeFields } from '_/features/samples/sample-edit/helpers'
import type { SampleEditedInfo } from '_/model/sample/sample'
import { greaterThan } from '_/model/date-time'
import { getEditedFieldInfo } from '_/features/samples/helpers'
import * as fi from '_/constants/custom-field-index'
import * as bt from '_/constants/sample-breach-type'

function convertSampleToSampleEdit(sample: NonViableSampleView, customFields: CustomField[]): NonViableSampleEdit {
    return {
        fields: normalizeFields(sample.fields, customFields),
        monitoringState: sample.monitoringState,
        higherParticle: sample.higherParticle,
        lowerParticle: sample.lowerParticle
    }
}

function getParticleEditedInfo(editedInfo: SampleEditedInfo[], isLowerParticles?: boolean) {
    const countEditedInfo = getEditedFieldInfo(editedInfo, isLowerParticles ? fi.LOWER_PARTICLE_COUNT : fi.HIGHER_PARTICLE_COUNT)
        , volumeEditedInfo = getEditedFieldInfo(editedInfo, isLowerParticles ? fi.LOWER_PARTICLE_VOLUME : fi.HIGHER_PARTICLE_VOLUME)

    if (!countEditedInfo || !volumeEditedInfo)
        return countEditedInfo ?? volumeEditedInfo

    return greaterThan(countEditedInfo.lastEditedAt, volumeEditedInfo.lastEditedAt)
        ? countEditedInfo
        : volumeEditedInfo
}

function breachLabelText(breachType: bt.BreachType) {
    switch (breachType) {
        case bt.ACTION_LIMIT:
            return 'Action'
        case bt.ALERT_LIMIT:
            return 'Alert'
        default:
            return ''
    }
}

export {
    convertSampleToSampleEdit,
    getParticleEditedInfo,
    breachLabelText,
}
