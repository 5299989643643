import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import type { FormApi } from 'final-form'

import { classnames, useAction, useCallback, useEffect, useSelector, useState, useRef } from '_/facade/react'

import { Submit, submitDisabled, CheckboxField, resetForm } from '_/components/form'
import { Modal, ModalBody, ModalHeader, ModalFooter } from '_/components/modal'
import { TextField, SelectField } from '_/components/form'
import AuditTrails from '_/components/audit-trail-list'
import Button, { Close } from '_/components/button'
import TabNavbar from '_/components/tab-navbar'

import PLATE_TYPE, { AIR_PLATE } from '_/constants/plate-type'
import * as tabs from '_/constants/modal-tab'
import * as routes from '_/constants/routes'
import type { FormSampleType } from '_/model/predefined-lists/sample-type/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'

import validate from '../validate'

import * as actions from '../actions'
import * as deletionActions from '_/features/confirmation/actions'
import * as routerActions from '_/features/routing/actions'

import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import { memoize } from '_/utils/function'
import ActiveField from '../../shared/active-field'
import type { Guid } from '_/model/guid'
import type { AuditTrail } from '_/model/audit-trail/types'

const calcInitialValues = memoize(
    (sampleType: Partial<SampleType> | undefined) => ({
        name: sampleType?.name,
        manufacturer: sampleType?.manufacturer,
        mediumType: sampleType?.mediumType,
        sampleType: sampleType?.sampleType,
        isActive: !!sampleType?.isActive,
        allowCorrectionalMpnValueIdType: !!sampleType?.allowCorrectionalMpnValueIdType,
    })
)

function SampleTypeModal() {
    const sampleTypes = useSelector(_ => _.predefinedLists.sampleTypes)
        , navigateToList = useNavigateToList()
        , [sampleTypeId, sampleType, sampleTypesTrail] = useSampleType()
        , initialValue = calcInitialValues(sampleType)
        , submit = useSubmitModal(initialValue)
        , remove = useDeletionModal(sampleType)
        , [activeTab, setActiveTab] = useState(tabs.DATA)
        , handleChange = useChangeHandler()

    return (
        <Modal isOpen onClose={navigateToList}>
            <Form<FormSampleType>
                onSubmit={submit}
                validate={_ => validate(_, sampleTypes, sampleTypeId)}
                initialValues={initialValue}
                render={form =>
                    <form onSubmit={form.handleSubmit} >
                        <UnsavedChangesObserver form={form} onChange={handleChange as any}/>

                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4 data-testid='sample-type-modal-title'>{sampleTypeId ? 'Edit sample type' : 'New sample type'}</h4>
                                    <Close onClick={navigateToList} />
                                </div>
                                <TabNavbar>
                                    <Button
                                        onClick={() => setActiveTab(tabs.DATA)}
                                        className={classnames('btn-link navbar-tab me-4', { active: activeTab === tabs.DATA })}
                                    >
                                        Sample type data
                                    </Button>
                                    <Button
                                        onClick={() => setActiveTab(tabs.AUDIT_TRAIL)}
                                        className={classnames('btn-link navbar-tab', { active: activeTab === tabs.AUDIT_TRAIL })}
                                        disabled={!sampleTypeId}
                                    >
                                        Audit trail
                                    </Button>
                                </TabNavbar>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='predefined-list-modal-body-height'>
                                {activeTab === tabs.DATA &&
                                    <div>
                                        <TextField name='name' id='name' testId='field-name'>Sample name</TextField>
                                        <SelectField
                                            id='sampleType'
                                            name='sampleType'
                                            entities={PLATE_TYPE}
                                            calcId={_ => _.id}
                                            calcName={_ => _.name}
                                            disabled={!!sampleTypeId}
                                            testId='field-sample-type'
                                        >
                                            Sample type
                                        </SelectField>
                                        {form.values.sampleType === AIR_PLATE &&
                                            <CheckboxField name='allowCorrectionalMpnValueIdType'>
                                                Allow Correctional MPN value identification type
                                            </CheckboxField>
                                        }
                                        <TextField name='mediumType' id='mediumType' testId='field-medium-type'>Medium type</TextField>
                                        <TextField name='manufacturer' id='manufacturer' testId='field-manufacturer'>Manufacturer</TextField>
                                    </div>
                                }
                                {activeTab === tabs.AUDIT_TRAIL && <AuditTrails trail={sampleTypesTrail} /> }
                            </div>
                        </ModalBody>
                        <ModalFooter className='border-top-0 modal-footer-height'>
                            {activeTab !== tabs.AUDIT_TRAIL &&
                                <div
                                    className={`d-flex flex-fill ${sampleTypeId && activeTab === tabs.DATA
                                                                        ? 'justify-content-between'
                                                                        : 'justify-content-end'}`}>
                                    {sampleType && activeTab === tabs.DATA &&
                                        <div>
                                            <Button
                                                className='text-danger bg-transparent border-0 me-2'
                                                onClick={() => remove(form.form)}
                                                disabled={sampleType.inUse}
                                                testId='sample-type-remove'
                                            >
                                                Remove
                                            </Button>
                                            <ActiveField inUse={sampleType.inUse} isActive={sampleType.isActive} />
                                        </div>
                                    }
                                    <div>
                                        <Button className='text-muted bg-transparent border-0 me-2' onClick={navigateToList}>Cancel</Button>
                                        <Submit disabled={submitDisabled(form)} testId='sample-type-save'>{sampleTypeId ? 'Save changes' : 'Create'}</Submit>
                                    </div>
                                </div>
                            }
                        </ModalFooter>

                    </form>
                }
            />
        </Modal>
    )
}

function useSampleType() {
    const [sampleType, setSampleType] = useState<SampleType>()
        , [sampleTypesTrail, setSampleTypesTrail] = useState<AuditTrail | undefined>()
        , sampleTypeId = useSelector<Guid | undefined>(_ => _.router.route!.params.id)
        , loadSampleType = useAction(actions.loadSampleType)
        , loadTrail = useAction(actions.loadSampleTypeTrail)

    useEffect(
        () => {
            if (!sampleTypeId)
                return

            loadSampleType(sampleTypeId).then(setSampleType)
            loadTrail(sampleTypeId).then(setSampleTypesTrail)
        },
        [sampleTypeId, setSampleType, loadSampleType, loadTrail]
    )

    return [sampleTypeId, sampleType, sampleTypesTrail] as const
}

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SETTINGS_SAMPLE_TYPES), [navigateTo])

    return navigateToList
}

function useSubmitModal(initialValue: Partial<FormSampleType>) {
    const sampleTypeId = useSelector<Guid | undefined>(_ => _.router.route!.params.id)
        , createSampleType = useAction(actions.createSampleType)
        , saveSampleType = useAction(actions.saveSampleType)
        , navigateToList = useNavigateToList()

    const submit = (newSampleType: FormSampleType, form: FormApi<FormSampleType, Partial<FormSampleType>>) => {
        const result = sampleTypeId
            ? saveSampleType({
                id: sampleTypeId,
                oldSampleType: initialValue as FormSampleType,
                newSampleType,
            })
            : createSampleType(newSampleType)

        return result
            .then(() => resetForm(form, !sampleTypeId))
            .then(navigateToList)
    }

    return submit
}

function useDeletionModal(sampleType: SampleType | undefined) {
    const removeSampleType = useAction(actions.removeSampleType)
        , confirmDeletion = useAction(deletionActions.showDeletionConfirmationModal)
        , navigateToList = useNavigateToList()
        , remove = (form: FormApi<FormSampleType, Partial<FormSampleType>>) => {
            confirmDeletion(`Are you sure you want to delete ${sampleType!.name}?`)
                .then(_ => removeSampleType(sampleType!.id))
                .then(() => resetForm(form))
                .then(navigateToList)
        }

    return remove
}

function useChangeHandler() {
    const prevValue = useRef<ReturnType<typeof calcInitialValues>>()

    function handleChange(value: FormSampleType, form: FormRenderProps<FormSampleType>) {
        if (prevValue.current === undefined)
            prevValue.current = calcInitialValues(form.initialValues)

        prevValue.current = value
    }

    return handleChange
}

export default SampleTypeModal
