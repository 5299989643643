import * as sw from '_/constants/system-words'
import type * as ct from '_/model/analysis/chart-type'
import type { DateTime} from '_/model/date-time'
import { equals, greaterThan } from '_/model/date-time'
import { formatActiveState } from '_/utils/format/common'
import type { Text } from '../text/text'
import { plainText, systemTextNode } from '../text/text'
import type { GetColor} from './series-color'
import { getDefaultColor } from './series-color'
import type { SeriesTitle } from './types'

type InfoAndDate<T> = [T | undefined, DateTime]
function mergeWithDates<T extends { utcDateTime: DateTime }>(series: T[], dates: DateTime[]): InfoAndDate<T>[] {
    return dates
        .map(
            date => {
                const foundSeries = series.filter(_ => equals(_.utcDateTime, date))
                    , items = foundSeries.length > 0 ? foundSeries : [undefined]
                return items.map<InfoAndDate<T>>(s => [s, date])
            }
        )
        .flat()
}

function mergeDates(a: DateTime[], b: DateTime[]): DateTime[] {
    return [...new Set(a.concat(b))].sort((a, b) => greaterThan(a, b) ? 1 : -1)
}

interface GraphSeries {
    series: {
        utcDateTime: DateTime
        count?: number
        cfuCount?: number
        totalSamples?: number
    }[]
    title: SeriesTitle
}

interface SeriesData {
    series: GraphSeries[]
    textFormat?: (_: GraphSeries, count: number | undefined, cfuCount?: number, date?: DateTime) => string
    nameFormat: (_: GraphSeries) => Text | undefined
    unitOfMeasure?: string
    type: 'line' | 'bar'
    mode?: 'lines+markers' | 'markers'
    hoverTemplate?: string
}

function convertToDateSeriesGraphData(
    mainSeriesData: SeriesData,
    additionalSeriesData: SeriesData,
    dates: DateTime[],
    includeAdditionalGraph: boolean | undefined,
    chartType: typeof ct.STACKED_SAMPLE_NAME | typeof ct.ORGANISM_TYPE_BREAKDOWN | ct.LinesMarkersChart,
    yAxes: [string, ...string[]],
    disabledAggregation?: boolean,
    getColor: GetColor = getDefaultColor,
) {
    function getSeries(seriesData: SeriesData, isMainSeries: boolean) {
        return seriesData.series.map((series, index) => {

            const datesOnAxis = !!disabledAggregation
                ? mergeDates(series.series.map(_ => _.utcDateTime), dates)
                : dates

            const points = mergeWithDates(series.series, datesOnAxis).map(([_, date]) => ({
                date,
                value: _?.count,
                unitOfMeasure: seriesData.unitOfMeasure,
                text: seriesData.textFormat?.(series, _?.count, _?.cfuCount, date)
            }))

            return ({
                name: seriesData.nameFormat(series),
                yAxis: isMainSeries ? 0 : 1,
                type: seriesData.type,
                mode: seriesData.mode,
                points,
                hoverTemplate: seriesData.hoverTemplate,
                color: getColor(index, plainText(seriesData.nameFormat(series) ?? ''), isMainSeries)
            })
        })
    }
    const mainSeries = getSeries(mainSeriesData, true)
        , additionalSeries = includeAdditionalGraph ? getSeries(additionalSeriesData, false) : []

    return {
        chartType,
        yAxes,
        series: mainSeries.concat(additionalSeries),
    }
}

function formatTitle(title: SeriesTitle) {
    if (!title.name)
        return

    return [sw.ANY, sw.NO_SESSION_NAME, sw.NOT_RECORDED_NAME].some(_ => _ === title.name)
        ? [systemTextNode(title.name)]
        : formatActiveState(title.name, title.isActive)
}

export {
    convertToDateSeriesGraphData,
    SeriesData,
    formatTitle,
}
