const UNIDENTIFIED_ID = 0
    , GROWTH_ID = 1
    , NO_GROWTH_ID = 2

type GrowthsStatus = never
    | typeof GROWTH_ID
    | typeof NO_GROWTH_ID
    | typeof UNIDENTIFIED_ID

const GROWTHS_STATUS: { id: GrowthsStatus, name: string }[] = [
    {
        id: UNIDENTIFIED_ID,
        name: 'Unidentified',
    },
    {
        id: GROWTH_ID,
        name: 'Growth',
    },
    {
        id: NO_GROWTH_ID,
        name: 'No growth',
    },
]

export {
    GROWTHS_STATUS as default,
    NO_GROWTH_ID,
    GROWTH_ID,
    UNIDENTIFIED_ID,
    GrowthsStatus,
}
