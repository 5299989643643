import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type NotificationSettingsService from '_/services/notification-settings-service'

import * as notificationSettingsActions from './actions'

const factory = (service: NotificationSettingsService): EffectsFactory => (_) => [
    handler(notificationSettingsActions.loadNotificationSettings, service.getNotificationSettings),

    handler(notificationSettingsActions.changeNotificationSettings, service.changeNotificationSettings),

    handler(notificationSettingsActions.changeEmailNotificationFrequency, service.changeEmailNotificationFrequency),
]

export default factory
