import { React, useState, classnames } from '_/facade/react'

import HoverMenu from '_/components/overlay/hover-menu'

import { KpiBlockHintFormat } from '_/features/reports/kpi-block'

import type * as rt from '_/model/reports/types'
import type { TrendType} from '_/model/reports/locations-report/trend-type'
import { CONTAMINATION } from  '_/model/reports/locations-report/trend-type'
import { calculateKpiBlockRate } from '_/model/reports/locations-report/helpers'
import { calculateTrend } from '_/model/reports/operators-report/helpers'
import { useTimeService } from '_/components/time'
import { areDatesInCurrentYear } from '_/model/reports/missed-monitoring-report/helpers'

interface Props {
    kpiData: rt.KpiData | undefined
    trendType: TrendType
}

function KpiData(props: Props) {
    const [element, setElement] = useState<HTMLDivElement | null>(null)
        , hintContainerRef = React.createRef<HTMLDivElement>()
        , trend = calculateTrend(props.kpiData)
        , emptyMessage = 'No data available'
        , emptyHintMessage = props.trendType === CONTAMINATION
            ? 'No contamination recorded'
            : 'No limit breaches recorded'
        , timeService = useTimeService()
        , areDateRangesOutOfYear = areDatesInCurrentYear(props.kpiData?.currentPeriodInfo.dateRange, timeService)
            && areDatesInCurrentYear(props.kpiData?.previousPeriodInfo.dateRange, timeService)

    return <td className='text-end'>
        <div ref={setElement}>
            {!props.kpiData?.currentPeriodInfo || props.kpiData.currentPeriodInfo.readSamplesCount === 0
                ? <span className='text-muted text-uppercase'>{emptyMessage}</span>
                : <div>
                    <span>{calculateKpiBlockRate(props.kpiData)}%</span>
                    {trend !== undefined &&
                        <i className={
                            classnames(
                                'material-icons md-24 text-secondary align-bottom',
                                {'text-danger rotate-315': trend > 0},
                                {'text-success rotate-45 ps-1': trend < 0},
                            )}
                        >
                            arrow_forward
                        </i>
                    }
                </div>
            }
        </div>
        <HoverMenu element={element} position='right' showTriangle>
            <div ref={hintContainerRef} className='legend-bubble text-white py-2 px-4 ms-1'>
                <KpiBlockHintFormat
                    info={props.kpiData?.currentPeriodInfo}
                    trendType={props.trendType}
                    emptyMessage={emptyHintMessage}
                    currentPeriod={true}
                    timeService={timeService}
                    omitYear={areDateRangesOutOfYear}
                />
                <KpiBlockHintFormat
                    info={props.kpiData?.previousPeriodInfo}
                    trendType={props.trendType}
                    emptyMessage={emptyHintMessage}
                    currentPeriod={false}
                    timeService={timeService}
                    omitYear={areDateRangesOutOfYear}
                />
                <div className='fst-italic mt-3'>
                    Excludes compromised viable samples
                </div>
            </div>
        </HoverMenu>
    </td>
}

export default KpiData
