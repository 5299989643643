import { Modal, ModalHeader, ModalBody } from '_/components/modal'
import { Close } from '_/components/button'

import type { NotificationWarningMetadata } from '_/model/notification/types'
import { getEntityTypeName } from '_/model/notification/helpers'
import { DATA_EXPORT } from '_/model/notification/notification-type'

interface Props {
    onClose: () => void
    metadata: NotificationWarningMetadata
}

function NotificationPermissionsWarningModal(props: Props) {
    const entityTypeName = getEntityTypeName(props.metadata.notification)
        , exportParticle = props.metadata.notification.type === DATA_EXPORT ? ' export modal' : ''

    return (
        <Modal isOpen onClose={props.onClose}>
            <ModalHeader className='border-bottom-0 pb-0'>
                <h4>Permissions</h4>
                <Close onClick={props.onClose} testId='close-notification-permissions-warning' />
            </ModalHeader>
            <ModalBody>
                <div data-testid='notification-permissions-warning-body'>
                    {props.metadata.reason === 'no-membership'
                        ? `You cannot view this ${entityTypeName}${exportParticle} because you are not a member of ${props.metadata.notification.contextName}. Contact your site administrator to request access`
                        : `You do not have permission to view this ${entityTypeName}${exportParticle}. Contact your site administrator to request access`
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

export default NotificationPermissionsWarningModal
