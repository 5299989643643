const VOID_ID = '00000000-0000-0000-0000-000000000000'

interface TreeItem {
    id: string
    parentId: string
}

interface Tree<T> {
    ids: string[]
    idHash: {
        [id: string]: T
    }
    childrenHash: {
        [id: string]: string[]
    }
}

interface ExpandedItems {
    [id: string]: boolean
}

function tree<T extends TreeItem>(list: T[]): Tree<T> {
    const result: Tree<T> = {
        ids: [],
        idHash: {},
        childrenHash: {},
    }

    list.forEach(location => {
        result.ids.push(location.id)
        result.idHash[location.id] = location

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (result.childrenHash[location.id] === undefined)
            result.childrenHash[location.id] = []

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (result.childrenHash[location.parentId] === undefined)
            result.childrenHash[location.parentId] = []

        result.childrenHash[location.parentId].push(location.id)
    })

    return result
}

function list<T extends TreeItem>(tree: Tree<T>) {
    return tree.ids.map(_ => tree.idHash[_])
}

function flatTree<T extends TreeItem>(items: Tree<T>, expandedItems: ExpandedItems, parentId = VOID_ID): T[] {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const currentItems = (items.childrenHash[parentId] || [])
            .map(_ => items.idHash[_])
            .filter(i => i.parentId === parentId)

    return currentItems.reduce(
        (acc, item) => {
            const nodes = [item]
                , children = expandedItems[item.id] ? flatTree(items, expandedItems, item.id) : []
                , subTree = nodes.concat(children)

            return acc.concat(subTree)
        },
        [] as T[]
    )
}

function nestingLevel<T extends TreeItem>(items: Tree<T>, item: T): number {
    return path(items, item).length - 1
}

// Doesn't relies on VOID_ID. Checks if parent is present in items.
function looseFlatTree<T extends TreeItem>(items: Tree<T>, parentId?: string): T[] {
    const currentItems = parentId != null
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            ? (items.childrenHash[parentId] || [])
                .map(_ => items.idHash[_])
                .filter(i => i.parentId === parentId)
            : list(items).filter(_ => !(_.parentId in items.idHash))

    return currentItems.reduce(
        (acc, item) => {
            const nodes = [item]
                , children = looseFlatTree(items, item.id)
                , subTree = nodes.concat(children)

            return acc.concat(subTree)
        },
        [] as T[]
    )
}

// Doesn't relies on VOID_ID. Checks if parent is present in items.
function looseNestingLevel<T extends TreeItem>(items: Tree<T>, item: T): number {
    const result = [] as T[]

    while (true) {
        result.push(item)

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (item === undefined)
            break

        item = items.idHash[item.parentId]
    }

    return result.length - 2
}

function hasChildren<T extends TreeItem>(items: Tree<T>, item: T): boolean {
    return childrenCount(items, item) > 0
}

function hasSibling<T extends TreeItem>(items: Tree<T>, item: T): boolean {
    return items.childrenHash[item.parentId].length > 1
}

function childrenCount<T extends TreeItem>(items: Tree<T>, item: T): number {
    return items.childrenHash[item.id].length
}

function isAncestorOf<T extends TreeItem>(items: Tree<T>, item: T, parent: T): boolean {
    while (item.parentId !== VOID_ID) {
        if (item.parentId === parent.id)
            return true

        item = items.idHash[item.parentId]
    }

    return false
}

function canMove<T extends TreeItem>(items: Tree<T>, item: T, destination: T): boolean {
    return item.parentId !== destination.id
        && item.id !== destination.id
        && !isAncestorOf(items, destination, item)
}

function emptyTree<T extends TreeItem>(): Tree<T> {
    return {
        ids: [],
        idHash: {},
        childrenHash: {
            [VOID_ID]: [],
        },
    }
}

function roots<T extends TreeItem>(items: Tree<T>): T[] {
    return items.ids
        .map(_ => items.idHash[_])
        .filter(_ => _.parentId === VOID_ID)
}

function path<T extends TreeItem>(items: Tree<T>, item: T, terminalParentId = VOID_ID): T[] {
    const result = [item]

    while (item.parentId !== terminalParentId) {
        item = items.idHash[item.parentId]
        result.push(item)
    }

    return result.reverse()
}

export {
    VOID_ID,
    tree,
    list,
    flatTree,
    nestingLevel,
    looseFlatTree,
    looseNestingLevel,
    hasChildren,
    hasSibling,
    childrenCount,
    emptyTree,
    isAncestorOf,
    canMove,
    roots,
    path,
    Tree,
    TreeItem,
    ExpandedItems,
}
