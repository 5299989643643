const MONDAY = 1
    , TUESDAY = 2
    , WEDNESDAY = 3
    , THURSDAY = 4
    , FRIDAY = 5
    , SATURDAY = 6
    , SUNDAY = 7

type DayId = never
    | typeof MONDAY
    | typeof TUESDAY
    | typeof WEDNESDAY
    | typeof THURSDAY
    | typeof FRIDAY
    | typeof SATURDAY
    | typeof SUNDAY

const WEEK: { id: DayId, name: string, shortName: string }[] = [
    {
        id: MONDAY,
        name: 'Monday',
        shortName: 'Mon',
    },
    {
        id: TUESDAY,
        name: 'Tuesday',
        shortName: 'Tue',
    },
    {
        id: WEDNESDAY,
        name: 'Wednesday',
        shortName: 'Wed',
    },
    {
        id: THURSDAY,
        name: 'Thursday',
        shortName: 'Thu',
    },
    {
        id: FRIDAY,
        name: 'Friday',
        shortName: 'Fri',
    },
    {
        id: SATURDAY,
        name: 'Saturday',
        shortName: 'Sat',
    },
    {
        id: SUNDAY,
        name: 'Sunday',
        shortName: 'Sun',
    },
]

export {
    WEEK as default,
    DayId,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY,
}
