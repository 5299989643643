import { useState } from '_/facade/react'

import type * as rt from '_/model/reports/types'
import { calculateMaxBreach, dayBreaches } from '_/model/reports/locations-report/helpers'
import type SampleSearchFields from '_/model/sample/search'
import { useScrollTo } from '_/features/reports/hooks'
import LimitBreachData from './limit-breach-data'
import SessionDayGraphContainer from './session-day-graph-container'
import { convertSessionBreachToExportFormat } from '_/model/reports/export'
import type { LocationsReport } from '_/model/reports/locations-report/types'
import type { OperatorsReport } from '_/model/reports/operators-report/types'
import type * as t from '_/model/text/text'

interface Props {
    name: string
    routeParams: SampleSearchFields
    sessionBreaches: rt.SessionBreaches[]
    printMode: boolean
    title: t.Text
    entityName: string
    report: LocationsReport | OperatorsReport
    showSpinner: boolean
    testId?: string
}

function SessionBreaches(props: Props) {
    const [divElement, setDivElement] = useState<HTMLDivElement | null>(null)
        , maxBreach = calculateMaxBreach(props.sessionBreaches)

    useScrollTo(divElement, 'session-breaches')

    return (
        <div className='block-border px-3' ref={setDivElement}>
            <SessionDayGraphContainer
                title={props.title}
                name={props.name}
                routeParams={props.routeParams}
                noDataAvailable={props.sessionBreaches.length === 0}
                printMode={props.printMode}
                exportGraphData={convertSessionBreachToExportFormat(props.sessionBreaches)}
                maxValue={maxBreach}
                entityName={props.entityName}
                report={props.report}
                showSpinner={props.showSpinner}
                renderDayCell={(sessionId, dayId) =>
                    <LimitBreachData
                        limitBreaches={dayBreaches(props.sessionBreaches, dayId, sessionId)}
                        key={dayId}
                        maxBreach={maxBreach}
                    />
                }
                testId={props.testId}
            />
        </div>
    )
}

export default SessionBreaches
