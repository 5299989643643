import type * as ap from '_/model/analysis/aggregation-period'
import type { DateTime } from '_/model/date-time'
import TimeSliderInput from '_/utils/input/time-slider-input'

interface Props {
    disabled: boolean
    startDate: DateTime
    endDate: DateTime
    range: ap.AggregationPeriod | undefined
    cumulativeView: boolean
    value: number | undefined
    description: string
    onChange?: (position: number) => void
}

const TimeSlider = (props: Props) =>
    (
        <>
            <div className='analysis__subtitle m-auto'>
                {props.description}
            </div>
            <TimeSliderInput
                startDate={props.startDate}
                endDate={props.endDate}
                range={props.range}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
            />
        </>
    )

export default TimeSlider
