import type { UnsavedChange as State } from '_/model/app-state'

import { reducer, handler, shallowUpdate } from '_/facade/reducer'

import * as actions  from './actions'
import * as authActions from '_/features/auth/actions'

const defaultState: State = {
    unsavedChangeTargets: [],
    showConfirmationModal: false,
    targetState: undefined,
}

export default reducer(
    defaultState,
    handler(authActions.loggedOut, () => defaultState),
    handler(actions.hasUnsavedChanges, (state, { hasChanges, changeTarget }) => {
        const currentTargets = state.unsavedChangeTargets
            , containsTarget = currentTargets.indexOf(changeTarget) !== -1

        if (hasChanges && !containsTarget)
            return shallowUpdate(state, {
                unsavedChangeTargets: currentTargets.concat(changeTarget),
            })

        if (!hasChanges && containsTarget)
            return shallowUpdate(state, {
                unsavedChangeTargets: currentTargets.filter((_: string) => _ !== changeTarget),
            })

        return state
    }),
    handler(actions.showWarning, (state, payload) => {
        const targetState = payload.targetState && {
            name: payload.targetState.name,
            params: payload.targetState.params,
        } as const
        return shallowUpdate(state, { showConfirmationModal: payload.showWarning }, { targetState })
    }),
    handler(actions.discardChanges, state =>
        shallowUpdate(state, { unsavedChangeTargets: [], showConfirmationModal: false })
    ),
)
