import { classnames } from '_/facade/react'
import { Link } from '_/utils/router'
import * as routes from '_/constants/routes'
import type { MissedMonitoringNotification as MissedMonitoringData } from '_/model/notification/types'
import { useTimeService } from '_/components/time'
import { paramsFilter } from '_/model/filters/helpers'
import { SCHEDULED_NOT_BOOKED_IN } from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'

interface Props {
    notification: MissedMonitoringData
    onClick: () => void
    dropdownLayout: boolean
    testId?: string
}

function MissedMonitoringNotification(props: Props) {
    const data = props.notification.data
        , timeService = useTimeService()

    function handleClick(e: React.MouseEvent) {
        e.preventDefault()
        props.onClick()
    }

    return (
        <>
            <Link
                routeName={routes.SCHEDULING_MONITORING_OVERVIEW}
                routeParams={paramsFilter({ date: data.missDate, statuses: [SCHEDULED_NOT_BOOKED_IN] })}
                className={classnames('cursor-pointer', { 'text-white': props.dropdownLayout })}
                onClick={handleClick}
                testId={`${props.testId}-link`}
            >
                <div className='fw-bold' data-testid={`${props.testId}-missed-sample-info`}>
                    {data.count} {data.count === 1 ? 'sample that was' : 'samples that were'} scheduled for exposure yesterday ({timeService.formatUtcDate(data.missDate)}) have not been booked in
                </div>
            </Link>
            <div className='text-secondary' data-testid={`${props.testId}-context-info`}>{props.notification.contextName}</div>
        </>
    )
}

export default MissedMonitoringNotification
