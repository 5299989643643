import type { State as RouterState } from 'router5'

import { classnames, useSelector } from '_/facade/react'
import * as routes from '_/constants/routes'
import Link from '_/components/link'
import TabNavbar from '_/components/tab-navbar'
import GeneralSettings from './general-settings'
import PageHeader from '_/components/page-header'
import BreachNotificationSettings from '../notification-settings/notification-settings'


function UserAccount() {
    const route = useSelector(_ => _.router.route)
        , permissions = useSelector(_ => _.auth.permissions)

    return (
        <div className='d-flex flex-column h-100'>
            <div className='container-fluid main-block'>
                <div className='row justify-content-center'>
                    <div className='col-5'>
                        <div className='page-header pt-2 pb-5'>
                            <PageHeader sticky title='My account' className='ps-0'/>
                            <TabNavbar>
                                <Link
                                    routeName={routes.USER_ACCOUNT_GENERAL_SETTINGS}
                                    className={classnames('navbar-tab', { active: route?.name === routes.USER_ACCOUNT_GENERAL_SETTINGS })}
                                >
                                    General settings
                                </Link>
                                <Link
                                    routeName={routes.USER_NOTIFICATIONS}
                                    className={classnames('navbar-tab ms-4', { active: route?.name === routes.USER_NOTIFICATIONS })}
                                    hasNoPermissions={!permissions.readNotificationPreferences}
                                    testId='notification-preferences'
                                >
                                    Notification preferences
                                </Link>
                            </TabNavbar>
                        </div>
                        {renderRoute(route)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAccount

function renderRoute(route: RouterState | null) {
    switch (route?.name) {
        case routes.USER_NOTIFICATIONS:
            return <BreachNotificationSettings />

        case routes.USER_ACCOUNT:
        case routes.USER_ACCOUNT_GENERAL_SETTINGS:
            return <GeneralSettings />

        default:
            return null
    }
}
