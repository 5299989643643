import { actionFactory } from '_/utils/redux'
import type { NotificationQuery, NotificationInfo } from '_/model/notification/types'
import type Notification from '_/model/notification/types'

const action = actionFactory('app.notification')

export const loadNotifications = action<NotificationQuery, Promise<Notification[]>>('loadNotifications')
           , markAsRead = action<string, Promise<void>>('markAsRead')
           , markAsUnread = action<string, Promise<void>>('markAsUnread')
           , bulkMarkAsRead = action<string[], Promise<void>>('bulkMarkAsRead')
           , loadNotificationInfo = action<void, Promise<NotificationInfo>>('loadNotificationInfo')
