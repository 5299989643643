import * as s from '_/model/context/electronic-signature-settings'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import type ReasonService from '_/model/reason/reason-service'
import type Api from '_/services/predefined-lists-service'

import { noop } from '_/utils/function'
import { diffObject } from '_/utils/object'

import { INACTIVE } from './status'
import type { Device } from './types'

function updateDeviceTransaction(
    id: string,
    current: Device,
    next: Device,
    signatureSettings: s.ElectronicSignatureSettings[],
    api: Api,
    reasonService: ReasonService
): Promise<void> {
    const diff = diffObject(current, next)

    if (!diff)
        return Promise.resolve()

    function getChangeStatusPromise(approvalInfo: ApprovalInfo) {
        return next.status === INACTIVE
            ? api.devices.markAsInactive(id, { approvalInfo })
            : api.devices.markAsActive(id, { approvalInfo })
    }

    return reasonService.getReason(reasonService.electronicSignatureSettingsEnabled(s.CHANGING_SETTINGS, signatureSettings))
        .then(approvalInfo =>
            Promise.all([
                diff.status !== undefined ? getChangeStatusPromise(approvalInfo) : undefined,
                diff.deviceId ? api.devices.changeDeviceId(id, next.deviceId, { approvalInfo }) : undefined
            ])
        )
        .then(noop)
}

export {
    updateDeviceTransaction,
}
