const SAMPLE_LOCATION_REPORT = 0
    , LIMIT_BREACH_LOCATION_REPORT = 1
    , LIMIT_BREACH_OPERATOR_REPORT = 2

type SampleReportType = never
    | typeof SAMPLE_LOCATION_REPORT
    | typeof LIMIT_BREACH_LOCATION_REPORT
    | typeof LIMIT_BREACH_OPERATOR_REPORT

export {
    SAMPLE_LOCATION_REPORT,
    LIMIT_BREACH_LOCATION_REPORT,
    LIMIT_BREACH_OPERATOR_REPORT,
    SampleReportType,
}
