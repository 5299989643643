import type * as router5 from 'router5'
import { actionFactory } from '_/utils/redux'
import type {
    Auth,
    Credentials,
    SetPassword,
    UserMembership,
    ChangeContext,
    ConfirmEmailRequest,
    EmailConfirmation,
    AzureAdConfig,
    SsoLoginCredentials,
    SsoSignUpCredentials,
    AvailableMemberships,
    AvailableMembershipsRequest,
    SsoAvailableMembershipsRequest
} from '_/model/auth/types'
import type { ResetPassword } from '_/model/users/types'

const action = actionFactory('app.auth')

export const logIn = action<Credentials, Promise<void>>('logIn')
           , availableMemberships = action<AvailableMembershipsRequest, Promise<AvailableMemberships>>('availableMemberships')
           , ssoLogIn = action<SsoLoginCredentials, Promise<void>>('ssoLogIn')
           , ssoAvailableMemberships = action<SsoAvailableMembershipsRequest, Promise<AvailableMemberships>>('ssoAvailableMemberships')
           , ssoSignUp = action<SsoSignUpCredentials, Promise<void>>('ssoSignUp')
           , loggedIn = action<Auth>('loggedIn')
           , logOut = action<void, Promise<void>>('logOut')
           , loggedOut = action<void>('loggedOut')
           , keepAlive = action<void, Promise<void>>('keepAlive')
           , confirmEmail = action<ConfirmEmailRequest, Promise<void>>('confirmEmail')
           , forgotPassword = action<string, Promise<void>>('forgotPassword')
           , setPassword = action<{ token: string, password: SetPassword }, Promise<void>>('setPassword')
           , changeContext = action<string, Promise<void>>('changeContext')
           , contextChanged = action<{ id: string, context: ChangeContext }>('contextChanged')
           , loadMemberships = action<void, Promise<UserMembership[]>>('loadMemberships')
           , membershipsLoaded = action<UserMembership[]>('membershipsLoaded')
           , confirmChangedPassword = action<string, Promise<void>>('confirmChangedPassword')
           , loadUserMembership = action<string, Promise<void>>('loadUserMembership')
           , userMembershipLoaded = action<UserMembership>('userMembershipLoaded')
           , unauthenticatedPageRequest = action<router5.State, void>('unauthenticatedPageRequest')
           , loadResetPassword = action<string, Promise<ResetPassword>>('loadResetPassword')
           , loadEmailConfirmation = action<string, Promise<EmailConfirmation>>('loadEmailConfirmation')
           , getAzureAdConfig = action<void, Promise<AzureAdConfig>>('getAzureAdConfig')
