import { useState } from '_/facade/react'
import * as bulkOperations from '_/model/scheduling/monitoring-overview/monitoring-overview-bulk-operation'
import Select from '_/components/downshift-select'
import Button from '_/components/button'
import type { MonitoringOverviewExpectedSample } from '_/model/scheduling/monitoring-overview/types'
import { NOT_IN_USE, SCHEDULED_NOT_BOOKED_IN } from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'

interface Props {
    selectedSamples: MonitoringOverviewExpectedSample[]
    disableBulkOperation: boolean
    onClearSelection: () => void
    onApplyBulkOperation: (bulkOperation: bulkOperations.MonitoringOverviewBulkOperation) => void
}

function SelectModeBar(props: Props) {
    const [bulkOperation, setBulkOperation] = useState<bulkOperations.MonitoringOverviewBulkOperation | undefined>()
        , bulkOperationName = bulkOperations.MONITORING_OVERVIEW_BULK_OPERATION.find(_ => _.id === bulkOperation)?.name ?? ''
        , title = bulkOperation !== undefined && validateBulkOperation() && props.selectedSamples.length > 0
            ? `Bulk operation ${bulkOperationName} cannot be applied to selected expectations. Please check the selected expectations and try again.`
            : undefined

    function validateBulkOperation() {
        const requiredStatus = bulkOperation === bulkOperations.MARK_AS_NOT_IN_USE ? SCHEDULED_NOT_BOOKED_IN : NOT_IN_USE
        return props.selectedSamples.length === 0
            || props.disableBulkOperation
            || props.selectedSamples.some(_ => _.status !== requiredStatus)
    }

    function handleChangeBulkOperation(bulkOperationId: bulkOperations.MonitoringOverviewBulkOperation | undefined | '') {
        const bulkOperation = bulkOperationId === undefined || bulkOperationId === '' ? undefined : bulkOperationId
        setBulkOperation(bulkOperation)
    }

    return (
        <div className='d-flex justify-content-between p-3 bg-light border rounded me-3 mx-3'>
            <div>
                <span>{props.selectedSamples.length} viable samples selected.</span>
                <Button
                    className='btn-link align-baseline'
                    onClick={props.onClearSelection}
                >
                    Clear selection
                </Button>
            </div>
            <div className='d-flex'>
                <Select
                    id='bulk'
                    entities={bulkOperations.MONITORING_OVERVIEW_BULK_OPERATION}
                    calcName={_ => _.name}
                    calcId={_ => _.id}
                    placeholder='Select a bulk operation'
                    input={{value: bulkOperation, onChange: handleChangeBulkOperation}}
                    className='form-control me-1'
                />
                <Button
                    className='btn-primary'
                    disabled={bulkOperation === undefined || validateBulkOperation()}
                    onClick={() => props.onApplyBulkOperation(bulkOperation!)}
                    title={title}
                >
                    Apply
                </Button>
            </div>
        </div>
    )
}

export default SelectModeBar
