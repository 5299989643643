import type { DashboardDailySamplesStatistic } from '_/model/dashboard/types'
import type TimeService from '_/services/time-service'
import type * as sampleStatuses from '_/constants/sample-status'
import { EXPOSURE_DATE_START } from '_/constants/search-date-type'
import type { DateTime} from '_/model/date-time'
import { equals } from '_/model/date-time'

function generatePastPeriod(timeService: TimeService, isoTime: DateTime, daysCount: number): DateTime[] {
    const result: DateTime[] = []

    for (let i = 1; i <= daysCount; i++) {
        result.push(timeService.addCtzDays(isoTime, -(i - 1)))
    }

    return result.reverse()
}

function generatePastDate(timeService: TimeService, pastDaysCount: number, startFromDate?: DateTime) {
    const date = startFromDate != undefined ? startFromDate : timeService.ctzDayStart(timeService.now())
    return timeService.addCtzDays(date, -pastDaysCount)
}

function getSamplesStats(sampleStatuses: DashboardDailySamplesStatistic[], range: DateTime[]) {
    return range.map(exposureDate => {
        const dayStats: DashboardDailySamplesStatistic = sampleStatuses.find(_ => equals(_.exposureDate, exposureDate)) || {
            exposureDate,
            bookedInSamplesCount: 0,
            noGrowthSamplesCount: 0,
            awaitingIdentificationSamplesCount: 0,
            growthsIdentifiedSamplesCount: 0,
            processedSamplesCount: 0,
        }

        return dayStats
    })
}

function calculateMaxTotal(stats: DashboardDailySamplesStatistic[]) {
    return stats.reduce(
        (acc, stat) => {
            const total =
                stat.bookedInSamplesCount +
                stat.awaitingIdentificationSamplesCount +
                stat.processedSamplesCount

            return Math.max(total, acc)
        },
        0
    )
}

function calculateHeight(stats: DashboardDailySamplesStatistic[]) {
    const maxTotal = calculateMaxTotal(stats)

    return function (count: number) {
        const height = (maxTotal === 0 ? 0 : count / maxTotal * 100)
        return {
            height: height > 0 ? height + '%' : 0,
            marginTop: height > 0 ? '2px' : 0,
        }
    }
}

function sampleListRouteParams(status: sampleStatuses.SampleStatus, exposureDate: DateTime, timeService: TimeService) {
    return {
        statuses: [status],
        dateToFilter: EXPOSURE_DATE_START,
        dateFrom: timeService.ctzDayStart(exposureDate),
        dateTo: timeService.ctzDayEnd(exposureDate),
        sort: 'exposureStartTime:desc',
    }
}

export {
    getSamplesStats,
    calculateHeight,
    generatePastDate,
    generatePastPeriod,
    calculateMaxTotal,
    sampleListRouteParams,
}
