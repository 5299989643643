import { classnames, useSelector } from '_/facade/react'
import Button from '_/components/button'
import type * as t from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'

interface OwnProps {
    fieldName: string
    titleInfo: string | t.Text
    dateFrom: string
    dateTo: string
    children?: React.ReactNode
    onExport(): void
    testId?: string
}

function RelatedInformationContainer(props: OwnProps) {
    const permissions = useSelector(_ => _.auth.permissions)
    return (
        <div className='mb-4'>
            <div className='py-5 px-2'>
                <h2 data-testid={`${props.testId}-title`}>
                    Information about <FormattedText text={props.titleInfo} />
                </h2>
            </div>
            <div className='sample-report-border'>
                <div className='py-5 px-4'>
                    <h4 className='text-center'>
                        {`Limit breaches involving this ${props.fieldName} from 28 days before to 7 days after this sample's exposure`}
                    </h4>
                    <div className='text-center'>
                        {`${props.dateFrom} to ${props.dateTo}`}
                    </div>
                </div>
                <div className='px-4'>
                    {props.children}
                </div>
                <div className='bg-light d-print-none sample-report-border-top py-2 px-3 text-end'>
                    <Button
                        className={classnames('btn-link', { disabled: !permissions.exportData })}
                        hasNoPermissions={!permissions.exportData}
                        onClick={props.onExport}
                        testId={`${props.testId}-export`}
                    >
                        Export
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default RelatedInformationContainer
