import { actionFactory } from '_/utils/redux'
import type { Signature } from '_/model/critical-change-reason/types'
import type ApprovalInfo from '_/model/critical-change-reason/types'

const action = actionFactory('app.reason-for-change')

export interface ShowWarning {
    isSettlePlateChanged: boolean
    isPlateTypeChangedToSettlePlate: boolean
    isFingerdabTwoHandsPlateChanged?: boolean
}

export const getReason = action<{signatureRequired: boolean, reason?: string, error?: string}, Promise<ApprovalInfo>>('getReason')
           , sampleTypeChanged = action<ShowWarning, void>('sampleTypeChanged')
           , reasonModalOpened = action<{signatureRequired: boolean}, Promise<void>>('reasonModalOpened')
           , reasonModalClosed = action<void>('reasonModalClosed')
           , cancelAction = action<void, void>('cancelAction')
           , allowAction = action<ApprovalInfo, void>('allowAction')

           , getElectronicSignature = action<string, Promise<Signature>>('getElectronicSignature')
           , electronicSignatureModalOpened = action<string, Promise<void>>('electronicSignatureModalOpened')
           , electronicSignatureModalClosed = action<void>('electronicSignatureModalClosed')
           , allowActionWithSignature = action<Signature, void>('allowActionWithSignature')
           , cancelActionWithSignature = action<void, void>('cancelActionWithSignature')
