import { useSelector } from '_/facade/react'

import Button from '_/components/button'
import Link, { LinkButton } from '_/components/link'
import { useTimeService } from '_/components/time'
import SampleIcon from '_/components/sample-icon/sample-icon'

import * as fieldIndex from '_/constants/custom-field-index'
import * as routes from '_/constants/routes'

import type { MonitoringOverviewExpectedSample, MonitoringOverviewSample, MonitoringOverviewSampleData } from '_/model/scheduling/monitoring-overview/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import { VOID_ID } from '_/utils/tree'
import { formatSampleTypeName } from '_/model/scheduling/monitoring-groups/helpers'

import MenuContainer from '../day-scheduler/menu/menu-container'
import type { DateTime} from '_/model/date-time'
import { greaterThan } from '_/model/date-time'
import FormattedText from '_/features/text/formatted-text'
import type { Text } from '_/model/text/text'
import { emptyTextNode, systemTextNode } from '_/model/text/text'
import * as h from '_/model/scheduling/monitoring-overview/helpers'
import { paramsFilter } from '_/model/filters/helpers'
import { EXPOSURE_DATE_START } from '_/constants/search-date-type'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import { NOT_RECORDED_ID } from '_/constants/system-words'
import { NO_SESSION_ID } from '_/model/predefined-lists/session/no-session'
import { BOOKED_IN, MISSING, NOT_IN_USE, SCHEDULED_NOT_BOOKED_IN } from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'
import { getFieldValue } from '_/features/samples/helpers'
import type { Guid } from '_/model/guid'
import type TimeService from '_/services/time-service'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'

interface Props {
    date: DateTime
    locations: ListExposureLocation[]
    sessions: SampleSession[]
    sampleTypes: SampleType[]
    monitoringGroups: MonitoringGroup[]
    sample: MonitoringOverviewSampleData | MonitoringOverviewExpectedSample
    groupName: Text | undefined
    element: HTMLButtonElement | null
    onChangeNotInUse: (id: Guid, sessionId: Guid, notInUse: boolean) => void
    onChangeMissed: (id: Guid, sessionId: Guid, missed: boolean) => void
    onClose: () => void
}

function ClickMenu(props: Props) {
    const timeService = useTimeService()
        , { locationId, sampleTypeId, sessionId } = h.sampleData(props.sample)
        , sampleStatus = props.sample.status
        , isActiveLocation = props.locations.find(_ => _.id === locationId)?.isActive
        , sampleType =  props.sampleTypes.find(_ => _.id === sampleTypeId)!
        , monitoringGroup = props.monitoringGroups.find(_ => _.id === props.sample.groupId)
        , bookedInSample = sampleStatus === BOOKED_IN
        , hasGroup = props.sample.groupId !== VOID_ID
        , scheduled = hasGroup && (bookedInSample || sampleStatus === SCHEDULED_NOT_BOOKED_IN)
        , unscheduled = !hasGroup && bookedInSample
        , notInUse = h.notInUse(sampleStatus)
        , missed = h.missed(sampleStatus)
        , disableBookInButton = greaterThan(props.date, timeService.castUtcDayStartFromCtzDay(timeService.now()))
            || !isActiveLocation
            || !sampleType.isActive
            || notInUse
            || monitoringGroup && !monitoringGroup.isActive
        , permissions = useSelector(_ => _.auth.permissions)
        , date = timeService.castUtcToCtzDate(props.date)
        , sampleTypeName = formatSampleTypeName(sampleType.id, props.sampleTypes)
            .concat(unscheduled ? systemTextNode(' (unscheduled)') : emptyTextNode())
            .concat(scheduled ? systemTextNode(' (scheduled)') : emptyTextNode())
            .concat(missed ? systemTextNode(' (missed)') : emptyTextNode())

    function handleChangeMissed() {
        props.onChangeMissed(props.sample.id, sessionId, !missed)
        props.onClose()
    }

    return (
        <MenuContainer element={props.element} onClose={props.onClose}>
            <div className='my-2 d-flex flex-column flex-wrap'>
                <div className='d-flex px-2'>
                    <div className='me-2 d-flex align-items-center'>
                        <SampleIcon
                            type={sampleType.sampleType}
                            status={props.sample.status}
                            darkBackground
                        />
                    </div>
                    <div>
                        <FormattedText text={sampleTypeName} data-testid='monitoring-overview-sample-info' />
                        <br/>
                        Inv ref: N/A
                    </div>
                </div>
                <hr className='my-1'/>
                <div className='d-flex'>
                    {props.sample.status === BOOKED_IN
                        ? <Link
                            routeName={routes.SAMPLES_EDIT}
                            routeParams={{id: getFieldValue(props.sample.fields, fieldIndex.ID)}}
                            className='text-light no-underline flex-fill px-2'
                        >
                            <MenuItem>Go to viable sample</MenuItem>
                        </Link>
                        : <LinkButton
                            routeName={routes.SAMPLES_BOOKING}
                            routeParams={routeParams(props.sample, props.locations, props.sessions, date, timeService)}
                            disabled={disableBookInButton}
                            hasNoPermissions={!permissions.bookSamples}
                            className='text-light btn-link no-underline p-0 flex-fill mx-2'
                            testId='monitoring-overview-book-in'
                        >
                            <MenuItem>Book In</MenuItem>
                        </LinkButton>
                    }
                </div>
                {!bookedInSample &&
                    <>
                        <hr className='my-1'/>
                        <LinkButton
                            routeName={routes.SAMPLES}
                            routeParams={paramsFilter(routeParams(props.sample, props.locations, props.sessions, date, timeService, false))}
                            disabled={notInUse}
                            className='text-light btn-link no-underline p-0 flex-fill mx-2'
                        >
                            <MenuItem>Show matching viable samples</MenuItem>
                        </LinkButton>
                    </>
                }
                <hr className='my-1'/>
                <Button
                    onClick={() => props.onChangeNotInUse(props.sample.id, sessionId, !notInUse)}
                    disabled={bookedInSample || missed}
                    hasNoPermissions={!permissions.editMonitoringOverview}
                    className='text-light btn-link no-underline p-0 flex-fill mx-2'
                    testId='monitoring-overview-mark-not-in-use'
                >
                    <MenuItem>
                        {notInUse ? 'Mark as in use' : 'Mark as not in use'}
                    </MenuItem>
                </Button>
                <hr className='my-1'/>
                <Button
                    onClick={handleChangeMissed}
                    disabled={bookedInSample || notInUse}
                    hasNoPermissions={!permissions.editMonitoringOverview}
                    className='text-light btn-link no-underline p-0 flex-fill mx-2'
                >
                    <MenuItem>
                        {missed ? 'Mark as not missed' : 'Mark as missed'}
                    </MenuItem>
                </Button>
                {!bookedInSample &&
                    <>
                        <hr className='my-1'/>
                        <div className='d-flex'>
                            <LinkButton
                                routeName={routes.SCHEDULING_MONITORING_OVERVIEW_SAMPLE_TRAIL}
                                routeParams={{ id: props.sample.id, date: props.date, sessionId }}
                                onClick={props.onClose}
                                className='text-light btn-link no-underline p-0 flex-fill mx-2'
                            >
                                <MenuItem>Audit trail</MenuItem>
                            </LinkButton>
                        </div>
                    </>
                }
                {props.groupName &&
                    <>
                        <hr className='my-1'/>
                        <div className='px-2'>
                            Monitoring group: {' '}
                            <FormattedText text={props.groupName} />
                        </div>
                    </>
                }
            </div>
        </MenuContainer>
    )
}

function MenuItem(props: {children: string}) {
    return (
        <span className='d-flex'>
            {props.children}
            <i className='material-icons md-18 ms-auto'>keyboard_arrow_right</i>
        </span>
    )
}

function routeParams(
    sample: MonitoringOverviewSample,
    locations: ListExposureLocation[],
    sessions: SampleSession[],
    date: DateTime,
    timeService: TimeService,
    bookInScreen = true,
) {
    if (sample.status === MISSING || sample.status === NOT_IN_USE || sample.status === SCHEDULED_NOT_BOOKED_IN) {
        const location = locations.find(_ => _.id === sample.locationId)
            , isParentLocation = locations.some(_ => _.parentId === location?.id && _.isActive)
            , isRootChildLocation = locations.find(_ => _.id === location?.parentId)?.parentId === VOID_ID
            , exposureLocationsId = isParentLocation || isRootChildLocation ? sample.locationId : location?.parentId
            , sessionId = sample.sessionId === VOID_ID ? undefined : sample.sessionId
            , sessionIds = sample.sessionId === VOID_ID
                        ? sessions
                            .filter(_ => !_.isActive)
                            .map(_ => _.id)
                            .concat(NOT_RECORDED_ID, NO_SESSION_ID)
                        : [sessionId]
            , monitoringPosition = isParentLocation ? undefined : sample.locationId

        const fields = [
            { index: fieldIndex.EXPOSURE_START_DATE, value: date },
            { index: fieldIndex.EXPOSURE_END_DATE, value: date },
            { index: fieldIndex.SESSION_ID, value: sessionId },
            { index: fieldIndex.SAMPLE_TYPE_ID, value: sample.sampleTypeId },
            { index: fieldIndex.EXPOSURE_LOCATION_ID, value: exposureLocationsId },
            { index: fieldIndex.MONITORING_POSITION, value: monitoringPosition },
        ]

        return bookInScreen
            ? { fields }
            : {
                fields: [
                    { index: fieldIndex.SESSION_ID, value: sessionIds },
                    { index: fieldIndex.SAMPLE_TYPE_ID, value: [sample.sampleTypeId] },
                    { index: fieldIndex.EXPOSURE_LOCATION_ID, value: [sample.locationId] },
                ],
                dateFrom: timeService.ctzDayStart(date),
                dateTo: timeService.ctzDayEnd(date),
                dateToFilter: EXPOSURE_DATE_START,
            }
    }
}

export default ClickMenu
