import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type PredefinedListsService from '_/services/predefined-lists-service'
import { noop } from '_/utils/function'
import * as actions from './actions'
import * as spinnerActions from '_/features/spinner/actions'
import * as t from '_/model/predefined-lists/locations/transaction'

const factory = (service: PredefinedListsService): EffectsFactory => dispatch => [
    handler(actions.loadExposureLocationList, () =>
        Promise.resolve()
            .then(() => service.exposureLocation.list())
            .then(_ => dispatch(actions.exposureLocationListLoaded(_)))
            .then(noop)
    ),

    handler(actions.loadExposureLocationTrail, service.exposureLocation.getTrail),

    handler(actions.loadExposureLocation, id =>
        service.exposureLocation.get(id)
    ),

    handler(actions.createExposureLocation, exposureLocationCreate =>
        Promise.resolve()
            .then(_ => service.exposureLocation.create(exposureLocationCreate))
            .then(() => service.exposureLocation.list())
            .then(_ => dispatch(actions.exposureLocationListLoaded(_)))
            .then(noop)
    ),

    handler(actions.removeExposureLocation, id =>
        service.exposureLocation.remove(id)
            .then(_ => dispatch(actions.exposureLocationRemoved(id)))
            .then(noop)
    ),

    handler(actions.saveExposureLocation, exposureLocationData => {
        const result = Promise.resolve()
                .then(() => dispatch(spinnerActions.showSpinner()))
                .then(_ => t.updateTransaction(
                    exposureLocationData.id,
                    exposureLocationData.oldExposureLocation,
                    exposureLocationData.newExposureLocation,
                    service,
                ))
                .then(_ => dispatch(actions.loadExposureLocationList()))
                .then(noop)

        result.finally(() => dispatch(spinnerActions.hideSpinner()))
        return result
    }),
]

export default factory
