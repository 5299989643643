import { useSelector } from '_/facade/react'

interface Props {
    message: string
}

function UserTagMessage(props: Props) {
    const tagUsers = useSelector(_ => _.auth.permissions.tagUsers)
        , ast = tagUsers ? parse(props.message) : [{ tag: 'text', value: props.message }]

    return (
        <span>
            {ast.map((node, index) =>
                node.tag === 'tag'
                    ? <b key={index} data-testid='tagged-user'>{node.value}</b>
                    : <span key={index} data-testid='comment-message'>{node.value}</span>
            )}
        </span>
    )
}

export default UserTagMessage

type AST = Array<{ tag: 'text', value: string } | { tag: 'tag', value: string }>

function parse(rawText: string): AST {
    const tagPattern = /(?:^|\s)@[\S]*/
        , ast: AST = []

    let remainder = rawText

    while (remainder !== '') {
        const result = tagPattern.exec(remainder)

        if (result === null) {
            ast.push({ tag: 'text', value: remainder })
            break
        }

        const tag = result[0]
            , precedingText = remainder.substring(0, result.index)

        remainder = remainder.substr(tag.length + precedingText.length)

        if (precedingText)
            ast.push({ tag: 'text', value: precedingText })

        ast.push({ tag: 'tag', value: tag })
    }

    return ast
}
