import {useState, useSelector, useAction, useEffect } from '_/facade/react'

import { UnitContainer } from '_/components/layout'
import { useTimeService } from '_/components/time'
import Button from '_/components/button'

import type { ResetPassword } from '_/model/users/types'

import RestorePasswordMessage from '_/features/auth/restore/restore-password-message'
import * as actions from '../actions'

function ConfirmChangedPassword() {
    const timeService = useTimeService()
        , route = useSelector(_ => _.router.route)
        , info = usePasswordResetInfo(route?.params.token)
        , confirmChangePassword = useAction(actions.confirmChangedPassword)
        , expired = info?.expired
        , accepted = info?.accepted
        , inactive = info?.inactive
        , disableFields = accepted || expired || inactive
        , processedAt = info && timeService.formatCtzDate(info.processedAt)

    return (
        <UnitContainer>
            <h4>Password change confirmation page</h4>
            <Button
                className='btn-primary'
                onClick={() => confirmChangePassword(route?.params.token)}
                disabled={disableFields}
                testId='complete-password-change'
            >
                Press to complete password change
            </Button>
            {disableFields &&
                <RestorePasswordMessage hasExpired={expired} hasAccepted={accepted} processedAt={processedAt} isInactive={inactive} />
            }
        </UnitContainer>
    )
}

export default ConfirmChangedPassword

function usePasswordResetInfo(token: string | undefined) {
    const loadResetPassword = useAction(actions.loadResetPassword)
        , [passwordResetInfo, setPasswordResetInfo] = useState<ResetPassword>()

    useEffect(
        () => {
            if (token) {
                loadResetPassword(token)
                    .then(setPasswordResetInfo)
                    .catch(() => setPasswordResetInfo({ expired: true } as ResetPassword))
            }
        },
        [token, loadResetPassword]
    )

    return passwordResetInfo
}
