import type AppState from '_/model/app-state'
import { commonReducer, handler } from '_/facade/reducer'
import { shallowUpdate } from '_/utils/object'
import { mergeAndReplaceEntityById } from '_/utils/array'
import * as a from './actions'

const defaultState: AppState['predefinedLists']['floorPlans'] = {
        imageUploads: [],
        plans: [],
    }
    , floorPlans = commonReducer(
        defaultState,

        handler(a.floorPlanListLoaded, (state, plans) => shallowUpdate(state, { plans })),

        handler(a.uploadStateChanged, (state, image) => {
            const imageUploads = state.imageUploads.some(_ => _.id === image.id)
                    ? mergeAndReplaceEntityById(state.imageUploads, image, image.id)
                    : state.imageUploads.concat(image)

            return shallowUpdate(state, { imageUploads })
        }),

        handler(a.clearedImageUploads, state => shallowUpdate(state, { imageUploads: [] })),
    )

export { floorPlans }
