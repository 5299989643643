import { useSelector } from '_/facade/react'

import type { GradeGraphSeries, MissedMonitoringReport } from '_/model/reports/missed-monitoring-report/types'
import { formatExposureDateRange, computeAuthorInfo } from '_/features/analysis/ui/helpers'

import { useTimeService } from '_/components/time'

import PieChart from './pie-chart'
import { getGraphFooter, getDateRange } from '_/model/reports/helpers'
import NoDataAvailable from '../../no-data-available'

interface Props {
    series: GradeGraphSeries[]
    printMode: boolean
    report: MissedMonitoringReport
    showSpinner: boolean
}

function GradePieChart(props: Props) {
    const title = 'Missed monitoring by grade'
        , timeService = useTimeService()
        , exportDate = timeService.formatCtzDate(timeService.now())
        , user = useSelector(_ => _.auth.user)
        , dateRange = getDateRange(timeService, props.report)
        , formattedDateRange = formatExposureDateRange(timeService, dateRange.exposureStartDateFrom, timeService.addCtzDays(dateRange.exposureStartDateTo, -1))

    return (
        <div className='col-6 px-3 break-block width-print-100'>
            <div className='block-border text-center py-3'>
                {props.series.length === 0 || props.showSpinner
                    ? <div className='text-center'>
                        <h4 className='mb-4'>{title}</h4>
                        <NoDataAvailable showSpinner={props.showSpinner}>
                            <span className='text-muted text-uppercase'>No data available</span>
                        </NoDataAvailable>
                    </div>
                    : <PieChart
                        series={props.series}
                        printMode={props.printMode}
                        title={title}
                        author={computeAuthorInfo(timeService, user)}
                        footer={getGraphFooter(props.report, undefined, timeService)}
                        exportFileName={`SmartControl Graph for Missed monitoring by grade for date range ${formattedDateRange} downloaded on ${exportDate}`}
                    />
                }
            </div>
        </div>
    )
}

export default GradePieChart
