import { Form } from 'react-final-form'
import type { FormApi } from 'final-form'

import type { ValidationResult} from '_/utils/form/validate'
import { isEmpty, isValidNumberInRange, isValidFraction, required } from '_/utils/form/validate'

import * as m from '_/components/modal'
import { Close } from '_/components/button'
import { SelectField, Submit, submitDisabled, TextField } from '_/components/form'
import NumberTextField from '_/components/form/number-text-field'
import type { ThresholdLine } from '_/model/analysis/filter/types'
import type { ChartType } from '_/model/analysis/chart-type'
import { CHART_AXIS } from '_/model/analysis/chart-axis'

interface Props {
    chartType: ChartType
    thresholdLines: ThresholdLine[]
    onClose: () => void
    onAddThresholdLine: (_: ThresholdLine) => void
}

function AddThresholdLineModal(props: Props) {
    const axis = CHART_AXIS.find(_ => _.id === props.chartType)?.axis ?? []

    function handleAdd(_: ThresholdLine, form: FormApi<ThresholdLine>) {
        props.onAddThresholdLine({
            ..._,
            name: _.name.trim(),
            axisName: axis.find(a => a.id === _.axis)?.name ?? ''
        })
        form.reset({})
        props.onClose()
    }

    function validate(values: Partial<ThresholdLine>) {
        const result: ValidationResult<ThresholdLine> = {}
        result.value = !isValidNumberInRange(values.value, 0, 99999.999) || !isValidFraction(values.value)
            ? 'Must be value between 0 and 99999.999, maximum 3 decimal places'
            : undefined
        result.axis = required('Threshold axis')(values.axis) || undefined

        if (isEmpty('Threshold name')(values.name))
            result.name = 'Threshold name is required'

        if (props.thresholdLines.find(_ => _.name.trim().toLowerCase() === values.name?.trim().toLowerCase()))
            result.name = `Threshold line with name '${values.name?.trim()}' already exists`

        if (values.name && values.name.length > 100)
            result.name = 'Threshold name can be up to 100 characters'

        return result
    }

    return (
        <m.Modal isOpen onClose={props.onClose} contentClassName='recurrence-modal--width'>
            <Form<ThresholdLine>
                onSubmit={handleAdd}
                initialValues={{ axis: 0 }}
                validate={validate}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <m.ModalHeader className='border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4>Add a threshold line</h4>
                                    <Close onClick={props.onClose} />
                                </div>
                            </div>
                        </m.ModalHeader>
                        <m.ModalBody className='py-0'>
                            <div className='container px-0'>
                                <div className='row mb-3'>
                                    <div className='col-12'>
                                        A threshold line is a horizontal line plotted on the graph as a series
                                    </div>
                                </div>
                                <div className='row'>
                                    <TextField name='name' className='col-12'>
                                        Threshold name
                                    </TextField>
                                </div>
                                <div className='row'>
                                    <SelectField
                                        name='axis'
                                        calcId={_ => _.id}
                                        calcName={_ => _.name + ' axis'}
                                        entities={axis}
                                        className='col-12'
                                    >
                                        Threshold axis
                                    </SelectField>
                                </div>
                                <div className='row'>
                                    <NumberTextField name='value' className='col-5'>
                                        Threshold value
                                    </NumberTextField>
                                </div>
                            </div>
                        </m.ModalBody>
                        <m.ModalFooter className='border-top-0'>
                            <Submit disabled={submitDisabled(form)}>
                                Add threshold line
                            </Submit>
                        </m.ModalFooter>
                    </form>
                }
            />
        </m.Modal>
    )
}

export default AddThresholdLineModal
