import { useAction } from '_/facade/react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '_/components/modal'
import Button, { Close } from '_/components/button'
import Link from '_/components/link'

import * as timeActions from '_/features/time/actions'

import * as routes from '_/constants/routes'
import * as fieldIndex from '_/constants/custom-field-index'
import * as breachType from '_/constants/sample-breach-type'
import * as objectionableBehaviour from '_/constants/objectionable-limit-behaviour'

import type Sample from '_/model/sample/sample'
import type SampleReading from '_/model/sample/reading/sample-reading'
import type Grade from '_/model/predefined-lists/grade/grade'
import type { ActionAlertLimits } from '_/model/predefined-lists/action-alert-limit/types'
import type { PredefinedLists } from '_/model/app-state'
import * as f from '_/model/sample/format'
import FormattedText from '_/features/text/formatted-text'

import { getFieldValue, calculateTotalCFU } from '../helpers'
import { hasObjectionalOrganisms, calculateSampleBreachType, isTwoHandsPlate } from '_/model/sample/reading/helpers'

interface OwnProps {
    isOpen: boolean
    onClose: () => void
    sample: Sample
    reading: SampleReading
    predefinedLists: PredefinedLists
    limits: ActionAlertLimits | undefined
    grade: Grade | undefined
}

function calculateBreachByBehaviourText(grade?: Grade) {
    switch (grade?.behaviour) {
        case objectionableBehaviour.BREACH_ALERT_LIMIT:
            return 'alert limit'

        case objectionableBehaviour.BREACH_ACTION_LIMIT:
            return 'action limit'

        default:
            return undefined
    }
}

function calculateBreachByCount(limits: ActionAlertLimits | undefined, totalCFU: number, totalOptionalCFU: number) {
    if (!limits)
        return
    if (totalCFU >= limits.actionLimit! || totalOptionalCFU >= limits.actionLimit!)
        return { text: 'action limit', breachType: breachType.ACTION_LIMIT }
    if (totalCFU >= limits.alertLimit! || totalOptionalCFU >= limits.alertLimit!)
        return { text: 'alert limit', breachType: breachType.ALERT_LIMIT }
}

const LimitBreachWarning = (props: OwnProps) => {
    const location = f.formatExposureLocation(props.sample.monitoringState, props.sample.fields, props.predefinedLists)
        , exposureDate = getFieldValue(props.sample.fields, fieldIndex.EXPOSURE_START_DATE)
        , getTimeService = useAction(timeActions.getTimeService)
        , timeService = getTimeService()
        , growths = props.reading.identifications
        , optionalGrowths = props.reading.optionalIdentifications
        , totalCFU = calculateTotalCFU(growths)
        , totalOptionalCFU = calculateTotalCFU(optionalGrowths)
        , breachedByCount = calculateBreachByCount(props.limits, totalCFU, totalOptionalCFU)
        , limitBreachTypeText = calculateSampleBreachType(props.reading, props.grade, props.limits) === breachType.ALERT_LIMIT ? 'Alert limit' : 'Action limit'
        , existObjectionalOrganisms = hasObjectionalOrganisms(growths, optionalGrowths)
        , breachByBehaviourText = calculateBreachByBehaviourText(props.grade)
        , breachByBehaviour = existObjectionalOrganisms && breachByBehaviourText

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalHeader>
                <h4 data-testid='read-limit-breach-warning'>{limitBreachTypeText} breached</h4>
                <Close onClick={props.onClose} testId='read-limit-breach-warning-close'/>
            </ModalHeader>
            <ModalBody>
                {breachedByCount &&
                    <span>The number of CFUs read on this sample exceeds the {breachedByCount.text} for the location in which it was exposed{breachByBehaviour && '. '}</span>
                }
                {breachByBehaviour &&
                    <span>Objectionable organisms have been identified, breaching the {breachByBehaviourText} for the location in which it was exposed</span>
                }
                <div className='mt-1'>
                    Exposure location: <FormattedText text={location} />
                    <br/>
                    Exposure time: {timeService.formatCtzDate(exposureDate)} {
                        <FormattedText text={f.formatExposureDurationByFields(props.sample.fields, props.predefinedLists.customFields, timeService)} />
                    }
                    <br/>
                    {breachedByCount &&
                        <div>
                            {isTwoHandsPlate(props.sample)
                                ? <span>
                                    {`CFUs read (left hand): ${totalCFU}`} <br/>
                                    {`CFUs read (right hand): ${totalOptionalCFU}`} <br/>
                                </span>
                                : <span>{`CFUs read: ${totalCFU}`} <br/></span>
                            }
                            {props.limits &&
                                <span>
                                    {breachedByCount.breachType === breachType.ALERT_LIMIT
                                        ? `Alert limit: ${props.limits.alertLimit}`
                                        : `Action limit: ${props.limits.actionLimit}`
                                    }
                                </span>
                            }
                        </div>
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className='btn-primary' onClick={props.onClose} testId='read-sample'>
                    Read another sample
                </Button>
                <Link
                    className='btn btn-secondary'
                    routeName={routes.SAMPLES_EDIT}
                    routeParams={{ id: props.sample.id }}
                    testId='read-go-to-sample'
                >
                    Go to viable sample
                </Link>
            </ModalFooter>
        </Modal>
    )
}

export default LimitBreachWarning
