import { SAMPLES_EDIT } from '_/constants/routes'
import type Sample from '_/model/sample/sample'
import { dispatchPropsMapper, connect, React } from '_/facade/react'
import { loadSampleByBarcode } from '_/features/samples/actions'
import { actions as routerActions } from 'redux-router5'

import { observeBarcodeScanner } from './helpers'

interface OwnProps {
    onSampleLoaded?: (sample: Sample) => void
    onSampleNotFound?: (barcode: string) => void
    suppressInput: boolean
}

class Scanner extends React.Component<ConnectedProps & OwnProps> {

    unsubscribe = observeBarcodeScanner(
        (barcode) => this.handleLoadSampleByBarcode(barcode),
        this.props.suppressInput
    )

    componentWillUnmount() {
        this.unsubscribe()
    }

    handleLoadSampleByBarcode(barcode: string) {
        if (barcode) {
            this.props.loadSampleByBarcode({ barcode, includeNullified: false, matchExactly: true })
                .then(sample => {
                    if (sample) {
                        if (this.props.onSampleLoaded)
                            this.props.onSampleLoaded(sample)
                        else
                            this.props.navigateToSampleEdit(sample.id)
                    }
                    else {
                        if (this.props.onSampleNotFound)
                            this.props.onSampleNotFound(barcode)
                    }
                })
        }
    }

    render() {
        return null
    }

    static defaultProps = {
        suppressInput: false,
    }
}

const mapDispatchToProps = dispatchPropsMapper({
        loadSampleByBarcode: loadSampleByBarcode,
        navigateToSampleEdit: (id: string) => routerActions.navigateTo(SAMPLES_EDIT, { id }),
})

type ConnectedProps = ReturnType<typeof mapDispatchToProps>

export default connect(undefined, mapDispatchToProps)(Scanner)
