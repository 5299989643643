import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'

import type CustomReportService from '_/services/custom-report-service'
import { diffObject } from '_/utils/object'
import { noop } from '_/utils/function'

import * as actions from './actions'

const factory = (service: CustomReportService): EffectsFactory => dispatch => [
    handler(actions.loadCustomReports, () =>
        service.list()
            .then(_ => {
                dispatch(actions.customReportsLoaded(_))
                return _
            })
    ),

    handler(actions.loadCustomReport, id => service.get(id)),

    handler(actions.loadCustomReportTrail, id => service.getTrail(id)),

    handler(actions.createCustomReport, data =>
        service.createCustomReport(data)
    ),

    handler(actions.removeCustomReport, service.remove),

    handler(actions.saveCustomReport, data =>
        Promise.resolve()
            .then(_ => {
                const diff = diffObject(data.oldCustomReport, data.newCustomReport)
                if (diff)
                    return service.save(data.id, diff)
            })
            .then(_ => dispatch(actions.loadCustomReports()))
            .then(noop)
    ),

    handler(actions.addCustomReportGraphs, data =>
        service.addGraphs(data.id, { graphs: data.graphs })
            .then(_ => dispatch(actions.loadCustomReports()))
            .then(noop)
    ),

    handler(actions.changeCustomReportGraphs, data =>
        service.changeGraphs(data.id, { graphs: data.graphs })
            .then(_ => dispatch(actions.loadCustomReports()))
            .then(noop)
    ),

    handler(actions.removeCustomReportGraph, data =>
        service.removeGraph(data.id, { graphId: data.graphId })
            .then(_ => dispatch(actions.loadCustomReports()))
            .then(noop)
    ),

    handler(actions.changeGraphsPosition, data =>
        service.changePosition(data.id, { graphs: data.graphs })
            .then(_ => dispatch(actions.loadCustomReports()))
            .then(noop)
    ),

    handler(actions.exportCustomReportGraphs, graphs =>
        service.exportGraphs(graphs)
            .then(noop)
    ),
]

export default factory
