import type ApiService from '../api-service'
import type SampleFilesService from '../sample-files-service'
import type { AttachedFile, InitUploadMeta, InitUploadResult } from '_/model/sample/files/files'
import { downloadBlob } from '_/utils/blob'
import { EDITING_SAMPLES } from '_/model/context/electronic-signature-settings'

function factory(api: ApiService): SampleFilesService {
    return {
        initUpload,
        completeUpload,
        upload,
        download,
        remove,
        getFiles,
    }

    function initUpload(sampleId: string, uploadMeta: InitUploadMeta): Promise<InitUploadResult> {
        return api.post<InitUploadResult>(['samples', sampleId, 'files'], uploadMeta)
    }

    function completeUpload(sampleId: string, fileId: string): Promise<void> {
        return api.post(['samples', sampleId, 'attach-files', fileId])
    }

    function upload(data: Blob, uploadToken: string): Promise<void> {
        return api.rawPut(uploadToken, data)
    }

    function download(fileId: string, name: string): Promise<void> {
        const tokenPromise = api.post<string>(['sample-files', fileId, 'download-token'])

        return tokenPromise.then(token => api.rawGetFile(token)).then(_ => downloadBlob(_, name))
    }

    function remove(fileId: string): Promise<void> {
        const removeWithReason = api.resource(['sample-files'], EDITING_SAMPLES).removeWithReason

        return removeWithReason(fileId)
    }

    function getFiles(sampleId: string): Promise<AttachedFile[]> {
        return api.get<AttachedFile[]>(['samples', sampleId, 'files'])
    }
}

export default factory
