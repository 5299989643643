import { Submit } from '_/components/form'
import Button from '_/components/button'

interface Props {
    onDelete: () => void
    submitDisabled: boolean
    removeDisabled?: boolean
}

const ActionButtons = (props: Props) =>
    <div className='d-flex justify-content-between mt-auto'>
        <Button className='text-danger' disabled={props.removeDisabled} onClick={props.onDelete} testId='delete-floor-plan'>Delete</Button>
        <Submit disabled={props.submitDisabled} testId='save-floor-plan'>Save floor plan</Submit>
    </div>

export default ActionButtons
