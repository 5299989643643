import type { ValidationResult} from '_/utils/form/validate'
import { required } from '_/utils/form/validate'

import type { OperatorsReport } from '_/model/reports/operators-report/types'
import { validateReportDate } from '../validate'

function validate(entity: Partial<OperatorsReport>) {
    const result: ValidationResult<OperatorsReport> = validateReportDate(entity)
        , operatorId = required('Operator')(entity.operatorId)

    if (operatorId)
        result.operatorId = operatorId

    return result
}

export default validate
