import type { ChangeUserRoleApi } from '_/model/users/types'
import type { UserMembership } from '_/model/auth/types'
import * as s from '_/model/context/electronic-signature-settings'

import type ApiService from '../api-service'
import type MembershipService from '../membership-service'

function factory(api: ApiService): MembershipService {
    return Object.assign(
        api.resource(['memberships']),
        {
            changeRole,
            suspend,
            resume,
            getMembership,
        },
    )

    function changeRole(request: ChangeUserRoleApi, settings: s.ElectronicSignatureSettings[]): Promise<void> {
        return api.postWithReason(['memberships', request.membershipId, 'change-role'], s.CHANGING_SETTINGS, request, settings)
    }

    function suspend(id: string, settings: s.ElectronicSignatureSettings[]): Promise<void> {
        return api.postWithReason(['memberships', id, 'suspend'], s.CHANGING_SETTINGS, undefined, settings)
    }

    function resume(id: string, settings: s.ElectronicSignatureSettings[]): Promise<void> {
        return api.postWithReason(['memberships', id, 'resume'], s.CHANGING_SETTINGS, undefined, settings)
    }

    function getMembership(id: string): Promise<UserMembership> {
        return api.get(['memberships', id])
    }
}

export default factory
