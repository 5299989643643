import { React, classnames } from '_/facade/react'
import Legend from './legend'
import AUTOMATED_ACTION_USER_ID from '_/constants/users'

import Time from '_/components/time'

import TrailMessages from '_/components/trail-message'
import type { AuditTrail } from '_/model/audit-trail/types'

interface Props {
    trail: AuditTrail
}

function SampleAuditTrails(props: Props) {
    return (
        <fieldset>
            <Legend>Audit trail</Legend>
            {props.trail.events.map((event, index) =>
                <div key={index} className={classnames({'text-secondary': event.userId === AUTOMATED_ACTION_USER_ID }, 'mb-3')}>
                    <div>
                        <b title={event.userId === AUTOMATED_ACTION_USER_ID ? '' : event.userEmail}>{event.user} ({event.userRoleName})</b>&nbsp;
                        <span className='text-secondary small'>
                            <Time render={timeService =>
                                `${timeService.formatCtzDateTime(event.issued, true)}`
                            } />
                        </span>
                    </div>
                    <div className='word-wrap-break-word'>
                        <TrailMessages event={event}/>
                    </div>
                </div>
            )}
        </fieldset>
    )
}

export default React.memo(SampleAuditTrails)
