import type FloorPlan from './floor-plan'
import * as g from './geometry'

import * as icon from '_/model/floor-plan/icon-size'
import type { FloorPlanChart} from '_/model/analysis/chart-type'
import CHART_TYPE, { CONTAMINATION_FLOOR_PLAN, LIMIT_BREACH_FLOOR_PLAN } from '_/model/analysis/chart-type'
import type LimitBreachFloorPlanSeries from '_/model/floor-plan/limit-breach-floor-plan-series'
import type ContaminationFloorPlanSeries from './contamination-floor-plan-series'
import type { ChartMetadata } from '../analysis/chart-metadata'
import type SliderExportData from './slider-export-data'

import { systemFontStack } from '../analysis/system-font-stack'

import { loadImage, exportCanvasToBlob } from '_/utils/image'
import { renderLocations, renderLimitBreachFloorPlan, getLocationCircleRatio, renderIcon } from './draw'
import { drawText, drawCycle, drawLine, drawRoundedRectangle, Fill, Stroke } from '_/model/canvas/draw'
import type FloorPlanSeries from '_/model/floor-plan/limit-breach-floor-plan-series'
import type { ImageExportInfo } from '_/model/sample/image/image'
import type { Point } from './area'
import * as iconType from './icon-type'

import type { FloorPlanLocationPage} from '_/model/floor-plan/floor-plan-location-page'
import { ANALYSIS_GRAPH, CUSTOM_REPORT, CONTEXTUAL_REPORT} from '_/model/floor-plan/floor-plan-location-page'
import type { ListExposureLocation } from '../predefined-lists/exposure-location/exposure-location'

export interface FloorPlanChartRef {
    exportImages(): Promise<ImageExportInfo[]>
}

export interface ExportData {
    floorPlan: FloorPlan
    src: string
    metadata: ChartMetadata
    sliderData: SliderExportData
}

export interface FloorPlanImageData {
    exportData: ExportData
    series: (ContaminationFloorPlanSeries | FloorPlanSeries)[]
    aggregatePeriodName: string
    chartType: typeof CONTAMINATION_FLOOR_PLAN | typeof LIMIT_BREACH_FLOOR_PLAN
    value?: any
}

const OFFSET_X = 85
    , CONTAMINATION_KEYS_AMOUNT = 3
    , LIMIT_BREACH_KEYS_AMOUNT = 8
    , OPERATOR_LIMIT_BREACH_KEYS_AMOUNT = 5

function measureFooterTextHeight(footerPlainText: string[], chartType: FloorPlanChart, ratio = 1, floorPlanLocationPage: FloorPlanLocationPage) {
    // 15 is a base line height
    // 75 is the height of 'Filters' line + one blank line after all filters + 'Keys' line + one blank line after all keys + mini audit trail line
    return (75 + 15 * (footerPlainText.length + getKeysAmount(chartType, floorPlanLocationPage) * 2)) * ratio
}

function getKeysAmount(chartType: FloorPlanChart, floorPlanLocationPage: FloorPlanLocationPage) {
    if (chartType === CONTAMINATION_FLOOR_PLAN)
        return CONTAMINATION_KEYS_AMOUNT

    return floorPlanLocationPage !== CONTEXTUAL_REPORT
        ? LIMIT_BREACH_KEYS_AMOUNT
        : OPERATOR_LIMIT_BREACH_KEYS_AMOUNT
}

function drawTitle(ctx: CanvasRenderingContext2D, title: string, customReportTitle?: string, ratio = 1) {
    const dx = OFFSET_X * ratio
        , lineHeight = 20 * ratio
        , font = `bold ${18 * ratio}px ${systemFontStack}`

    if (customReportTitle) {
        drawText(ctx, [dx, lineHeight], customReportTitle, font, 'rgb(0, 0, 0)')
    }

    drawText(ctx, [dx, lineHeight * 2], title, font, 'rgb(0, 0, 0)')
}

function drawSubtitle(ctx: CanvasRenderingContext2D, subtitle: string, ratio = 1) {
    const font = `${13 * ratio}px ${systemFontStack}`
    drawText(ctx, [OFFSET_X * ratio, 60 * ratio], subtitle, font, 'rgb(0, 0, 0)')
}

function drawFooter(ctx: CanvasRenderingContext2D, metadata: ChartMetadata, chartType: FloorPlanChart, footerOffset: number, ratio = 1, floorPlanLocationPage: FloorPlanLocationPage) {
    const dx = OFFSET_X * ratio
        , lineHeight = 15 * ratio
        , font = `${14 * ratio}px ${systemFontStack}`

    drawFilters(ctx, metadata, dx, footerOffset, lineHeight, font)
    let dy = footerOffset + lineHeight * (metadata.footerPlainText.length - 1)

    drawKeys(ctx, chartType, dx, dy, lineHeight, font, ratio, floorPlanLocationPage)
    dy += lineHeight * getKeysAmount(chartType, floorPlanLocationPage) * 2

    drawText(ctx, [dx, dy + lineHeight * 5], metadata.author, font, 'rgb(0, 0, 0)')
}

function drawFilters(ctx: CanvasRenderingContext2D, metadata: ChartMetadata, dx: number, offset: number, lineHeight: number, font: string) {
    let dy = offset + lineHeight
    drawText(ctx, [dx, dy], 'Filters', font, 'rgb(0, 0, 0)')

    metadata.footerPlainText.slice(1).forEach(f => {
        dy += lineHeight
        drawText(ctx, [dx, dy], f, font, 'rgb(0, 0, 0)')
    })
}

function drawKeys(ctx: CanvasRenderingContext2D, chartType: FloorPlanChart, dx: number, offset: number, lineHeight: number, font: string, ratio: number, floorPlanLocationPage: FloorPlanLocationPage) {
    const totalCfu = 3
        , limitBreaches = 3
        , activeLocationRatio = getLocationCircleRatio(ratio, false, totalCfu)
        , dimension = Math.floor(g.PLOT_RADIUS * activeLocationRatio)
        , inactiveLocationRatio = getLocationCircleRatio(ratio, true, undefined)
        , inactiveLocationDimension = Math.floor(g.PLOT_RADIUS * inactiveLocationRatio)
        , textOffset = dx + dimension * 3
        , smallIconOffsetX = 4
        , showAdditionalData = floorPlanLocationPage !== CONTEXTUAL_REPORT

    let dy = offset + lineHeight * 3
    drawText(ctx, [dx, dy], 'Keys', font, 'rgb(0, 0, 0)')

    if (chartType === CONTAMINATION_FLOOR_PLAN) {
        dy += lineHeight * 2

        drawCycle(ctx, { x: dx + dimension, y: dy }, dimension, 'rgb(48,65,122)')
        drawText(ctx, [dx + dimension, dy], `${totalCfu}`, font, 'rgb(255,255,255)', 'center')
        drawText(ctx, [textOffset, dy], 'Total CFUs for this location', font, 'rgb(0, 0, 0)')
    }

    dy += lineHeight * 2
    drawCycle(ctx, { x: dx + smallIconOffsetX + inactiveLocationDimension, y: dy }, inactiveLocationDimension, 'rgb(128,128,128)')
    drawText(ctx, [textOffset, dy], chartType === CONTAMINATION_FLOOR_PLAN ? 'Location with no CFUs' : 'Location with no limit breaches or compromised samples', font, 'rgb(0, 0, 0)')

    if (showAdditionalData) {
        dy += lineHeight * 2
        drawRoundedRectangle(ctx, { x: dx + smallIconOffsetX + inactiveLocationDimension / 2, y: dy - inactiveLocationDimension / 2 }, inactiveLocationDimension, inactiveLocationDimension, 'rgb(128,128,128)')
        drawText(ctx, [textOffset, dy], 'Location outside of filter scope', font, 'rgb(0, 0, 0)')
    }

    if (chartType === LIMIT_BREACH_FLOOR_PLAN) {
        dy += lineHeight * 2
        renderIcon(ctx, { x: dx, y: dy - icon.ICON_HEIGHT / 2 }, iconType.ACTION_LIMIT, ratio, limitBreaches, undefined, undefined)
        drawText(ctx, [textOffset, dy], 'Action limit breach', font, 'rgb(0, 0, 0)')

        dy += lineHeight * 2
        renderIcon(ctx, { x: dx, y: dy - icon.ICON_HEIGHT / 2 }, iconType.ACTION_LIMIT_WITH_REF, ratio, limitBreaches, undefined, undefined)
        drawText(ctx, [textOffset, dy], 'Action limit breach with ref #', font, 'rgb(0, 0, 0)')

        dy += lineHeight * 2
        renderIcon(ctx, { x: dx, y: dy - icon.ICON_HEIGHT / 2 }, iconType.ALERT_LIMIT, ratio, limitBreaches, undefined, undefined)
        drawText(ctx, [textOffset, dy], 'Alert limit breach', font, 'rgb(0, 0, 0)')

        dy += lineHeight * 2
        renderIcon(ctx, { x: dx, y: dy - icon.ICON_HEIGHT / 2 }, iconType.ALERT_LIMIT_WITH_REF, ratio, limitBreaches, undefined, undefined)
        drawText(ctx, [textOffset, dy], 'Alert limit breach with ref #', font, 'rgb(0, 0, 0)')

        if (showAdditionalData) {
            dy += lineHeight * 2
            renderIcon(ctx, { x: dx, y: dy - icon.ICON_HEIGHT / 2 }, iconType.COMPROMISED, ratio, limitBreaches, undefined, undefined)
            drawText(ctx, [textOffset, dy], 'Compromised', font, 'rgb(0, 0, 0)')

            dy += lineHeight * 2
            renderIcon(ctx, { x: dx, y: dy - icon.ICON_HEIGHT / 2 }, iconType.COMPROMISED_WITH_REF, ratio, limitBreaches, undefined, undefined)
            drawText(ctx, [textOffset, dy], 'Compromised with ref #', font, 'rgb(0, 0, 0)')
        }
    }
}

function drawTitles(ctx: CanvasRenderingContext2D, metadata: ChartMetadata, ratio = 1) {
    drawTitle(ctx, metadata.title.join(' '), metadata.reportName, ratio)
    drawSubtitle(ctx, metadata.subtitle, ratio)
}

function drawSlider(ctx: CanvasRenderingContext2D, sliderExportData: SliderExportData, offsetY: number, ratio: number) {
    const timeLineOffset = offsetY + 10 * ratio
        , cumulativeOffset = offsetY + 20 * ratio

    drawTicks(ctx, sliderExportData.ticks, offsetY, ratio)
    drawTimeLine(ctx, timeLineOffset, ratio)
    drawPosition(ctx, sliderExportData, timeLineOffset, ratio)
    drawCumulativeCheckbox(ctx, sliderExportData.cumulativeView, cumulativeOffset, ratio)
}

function drawTicks(ctx: CanvasRenderingContext2D, ticks: number, y: number, ratio: number) {
    const tickLength = 5 * ratio
        , tickWidth = 1 * ratio
        , tickColor = 'rgb(128,128,128)'
        , edges = calculateTimeLineEdges(ctx)

    let tickPosition = edges.start
        , tickStart: Point
        , tickEnd: Point

    for (let i = 0; i < ticks; i++) {
        tickStart = { x: tickPosition, y }
        tickEnd = { x: tickPosition, y: y + tickLength }

        drawLine(ctx, tickStart, tickEnd, tickWidth, tickColor)

        tickPosition += (edges.end - edges.start) / (ticks - 1)
    }
}

function drawTimeLine(ctx: CanvasRenderingContext2D, y: number, ratio: number) {
    const edges = calculateTimeLineEdges(ctx)
        , startPoint = { x: edges.start, y }
        , endPoint = { x: edges.end, y }
        , lineWidth = 6 * ratio

    drawLine(ctx, startPoint, endPoint, lineWidth, 'rgb(135,193,255)')
}

function drawPosition(ctx: CanvasRenderingContext2D, sliderExportData: SliderExportData, offsetY: number, ratio: number) {
    const timeLineEdges = calculateTimeLineEdges(ctx)
        , location = timeLineEdges.start + (timeLineEdges.end - timeLineEdges.start) * sliderExportData.position / (sliderExportData.ticks - 1)
        , radius = 7 * ratio

    drawCycle(ctx, { x: location, y: offsetY }, radius, 'rgb(0,123,255)')
}

function drawCumulativeCheckbox(ctx: CanvasRenderingContext2D, cumulativeView: boolean, offsetY: number, ratio: number) {
    const point = {x: calculateTimeLineEdges(ctx).start, y: offsetY}
        , font = `${13 * ratio}px ${systemFontStack}`

    drawCheckBox(ctx, cumulativeView, point, ratio)
    drawText(ctx, [point.x + 35 * ratio, point.y + 12 * ratio], 'Show data points cumulatively', font, 'rgb(0,0,0)')
}

function drawCheckBox(ctx: CanvasRenderingContext2D, isChecked: boolean, point: Point, ratio: number) {
    const color = isChecked ? 'rgb(0,123,255)' : 'rgb(128,128,128)'
        , drawType = isChecked ? Fill : Stroke
        , size = 18 * ratio

    drawRoundedRectangle(ctx, point, size, size, color, drawType)

    if (isChecked) {
        const point1 = { x: point.x + size / 3, y: point.y + size / 2 }
            , point2 = { x: point.x + size / 2, y: point.y + size * 2 / 3 }
            , point3 = { x: point.x + size * 3 / 4, y: point.y + size / 3 }
            , lineWidth = 2 * ratio

        drawLine(ctx, point1, point2, lineWidth, 'rgb(255,255,255)')
        drawLine(ctx, point2, point3, lineWidth, 'rgb(255,255,255)')
    }
}

function calculateTimeLineEdges(ctx: CanvasRenderingContext2D) {
    const relativeWidth = 0.7 // 70% of total width
        , totalWidth = ctx.canvas.width
        , padding = totalWidth * (1 - relativeWidth) / 2

    return {
        start: padding,
        end: totalWidth - padding,
    }
}

function exportFloorPlanToImageInternal(exportData: ExportData, chartType: FloorPlanChart, renderData: (canvas: HTMLCanvasElement, canvasContext: CanvasRenderingContext2D, ratio: number) => void, floorPlanLocationPage: FloorPlanLocationPage) {
    const { floorPlan, src, metadata, sliderData } = exportData
        , exportCanvas = document.createElement('canvas')
        , positionsCanvas = document.createElement('canvas')
        , ctx = exportCanvas.getContext('2d')!
        , positionsCtx = positionsCanvas.getContext('2d')!
        , defaultExportWidth = 1300
        , ratio = Math.max(floorPlan.width, defaultExportWidth) / defaultExportWidth
        , margin = 7 * ratio
        , headerHeight = 70 * ratio
        , sliderHeight = 40 * ratio
        , sliderOffset = headerHeight + margin
        , floorPlanOffset = sliderOffset + sliderHeight + margin
        , footerOffset = floorPlanOffset + floorPlan.height + margin
        , footerHeight = measureFooterTextHeight(metadata.footerPlainText, chartType, ratio, floorPlanLocationPage)

    exportCanvas.height = footerOffset + footerHeight
    exportCanvas.width = Math.max(floorPlan.width, defaultExportWidth)

    return loadImage(src)
        .then(floorPlanImage => {
            ctx.fillStyle = 'white'
            ctx.fillRect(0, 0, exportCanvas.width, exportCanvas.height)
            positionsCanvas.height = floorPlan.height
            positionsCanvas.width = floorPlan.width

            renderData(positionsCanvas, positionsCtx, ratio)

            return loadImage(positionsCanvas.toDataURL('image/png'))
                .then(positionsImage => {
                    drawTitles(ctx, metadata, ratio)
                    if (floorPlanLocationPage === ANALYSIS_GRAPH || floorPlanLocationPage === CUSTOM_REPORT)
                        drawSlider(ctx, sliderData, sliderOffset, ratio)

                    // place image in the middle of canvas if it's smaller than default export height
                    const dx = floorPlan.width < defaultExportWidth ? (exportCanvas.width / 2 - floorPlan.width / 2) : 0

                    ctx.drawImage(floorPlanImage, dx, floorPlanOffset)
                    ctx.drawImage(positionsImage, dx, floorPlanOffset)

                    drawFooter(ctx, metadata, chartType, footerOffset, ratio, floorPlanLocationPage)

                    return exportCanvasToBlob(exportCanvas, 'image/png')
                })
        })
}

function exportFloorPlanToImage(imageData: FloorPlanImageData, floorPlanLocationPage: FloorPlanLocationPage, allLocations: ListExposureLocation[]): Promise<ImageExportInfo> {
    const { exportData, chartType, aggregatePeriodName, series, value } = imageData
        , chartTypeName = CHART_TYPE[chartType].name
        , fileName = getGraphImageName(exportData.floorPlan, chartTypeName, aggregatePeriodName)

    switch (chartType) {
        case CONTAMINATION_FLOOR_PLAN:
            return exportContaminationFloorPlanToImage(exportData, chartType, series as ContaminationFloorPlanSeries[], value ?? [], floorPlanLocationPage, allLocations)
                .then(_ => ({ blob: _, fileName}))

        case LIMIT_BREACH_FLOOR_PLAN:
            return exportLimitBreachFloorPlanToImage(exportData, chartType, series as LimitBreachFloorPlanSeries[], value ?? [], floorPlanLocationPage, allLocations)
                .then(_ => ({ blob: _, fileName}))
    }
}

function exportContaminationFloorPlanToImage(exportData: ExportData, chartType: FloorPlanChart, series: ContaminationFloorPlanSeries[], selectedLocations: string[], floorPlanLocationPage: FloorPlanLocationPage, allLocations: ListExposureLocation[]) {
    const renderData = (canvas: HTMLCanvasElement, canvasContext: CanvasRenderingContext2D, ratio: number) => renderLocations(canvas, canvasContext, exportData.floorPlan.locations, series, selectedLocations, allLocations, ratio)
    return exportFloorPlanToImageInternal(exportData, chartType, renderData, floorPlanLocationPage)
}

function exportLimitBreachFloorPlanToImage(exportData: ExportData, chartType: FloorPlanChart, series: LimitBreachFloorPlanSeries[], selectedLocations: string[], floorPlanLocationPage: FloorPlanLocationPage, allLocations: ListExposureLocation[]) {
    const renderData = (canvas: HTMLCanvasElement, canvasContext: CanvasRenderingContext2D, ratio: number) => renderLimitBreachFloorPlan(canvas, canvasContext, exportData.floorPlan.locations, series, selectedLocations, allLocations, ratio)
    return exportFloorPlanToImageInternal(exportData, chartType, renderData, floorPlanLocationPage)
}

function getGraphImageName(plan: FloorPlan, chartTypeName: string, aggregatePeriodName: string) {
    const locationSuffix = plan.locations.length === 1 ? 'location' : 'locations'

    return `${chartTypeName} - ${plan.name} (${plan.locations.length} ${locationSuffix}),${aggregatePeriodName}`
}

export {
    exportFloorPlanToImage,
    getGraphImageName,
}
