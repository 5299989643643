import { actionFactory } from '_/utils/redux'
import type { ImageTokens, ImageInfo } from '_/model/smart-check/image'
import type ImageProvider from '_/model/smart-check/image-provider'
import type { SampleName } from '_/constants/plate-type'
import type { TakePhotoPromise } from '_/model/smart-check/image'
import type { Guid } from '_/model/guid'

const action = actionFactory('app.smart-image')

export const readingDeviceChanged = action<Guid | 'webcam' | undefined>('readingDeviceChanged')
           , showSuggestedGrowthRegionsAndCountsChanged = action<boolean>('showSuggestedGrowthRegionsAndCountsChanged')

           , takePhoto = action<{ provider: ImageProvider, previousImageId: Guid | undefined, plateType: SampleName, entityId: Guid }, TakePhotoPromise>('takePhoto')
           , removeImage = action<string, Promise<void>>('removeImage')
           , getDownloadTokens = action<string, Promise<ImageTokens>>('getDownloadTokens')
           , getInfo = action<string, Promise<ImageInfo>>('getImageInfo')

           , activateWebcam = action<void, Promise<MediaStream | string>>('activateWebcam')
           , deactivateWebcam = action<void>('deactivateWebcam')
           , captureWebcam = action<void, Blob>('captureWebcam')
