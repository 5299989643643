import { actionFactory } from '_/utils/redux'
import type { RoleEdit } from '_/model/roles/types'
import type Role from '_/model/roles/types'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.roles')

export const loadContextRoles = action<string, Promise<Role[]>>('loadContextRoles')
           , loadRole = action<string, Promise<Role>>('loadRole')
           , loadRoleTrail = action<string, Promise<AuditTrail>>('loadRoleTrail')
           , createRole = action<{ contextId: string, role: RoleEdit }, Promise<void>>('createRole')
           , saveRole = action<{ id: string, oldRole: RoleEdit, newRole: RoleEdit }, Promise<void>>('saveRole')
           , removeRole = action<string, Promise<void>>('removeRole')
