import type { EffectsFactory} from '_/facade/effect'
import { handler } from '_/facade/effect'
import * as actions from './actions'
import type { Signature } from '_/model/critical-change-reason/types'
import type Reason from '_/model/critical-change-reason/types'

let allowAction: (reason: Reason) => void
let cancelAction: (errorData: { isHandled: boolean }) => void

let allowActionWithSignature: (signature: Signature) => void

const factory = (): EffectsFactory => (dispatch) => [
    handler(actions.getReason, _ => {
        dispatch(actions.reasonModalOpened(_))

        return new Promise<Reason>((resolve: (reason: Reason) => void, reject) => {
            allowAction = resolve
            cancelAction = reject
        })
    }),

    handler(actions.getElectronicSignature, _ => {
        dispatch(actions.electronicSignatureModalOpened(_))

        return new Promise<Signature>((resolve: (signature: Signature) => void, reject) => {
            allowActionWithSignature = resolve
            cancelAction = reject
        })
    }),

    handler(actions.allowActionWithSignature, (payload) =>
        Promise.resolve()
            .then(() => dispatch(actions.electronicSignatureModalClosed()))
            .then(() => allowActionWithSignature(payload))
    ),

    handler(actions.cancelActionWithSignature, () =>
        Promise.resolve()
            .then(() => dispatch(actions.electronicSignatureModalClosed()))
            .then(() => cancelAction({ isHandled: true }))
    ),

    handler(actions.allowAction, payload =>
        Promise.resolve()
            .then(() => dispatch(actions.reasonModalClosed()))
            .then(() => allowAction(payload))
    ),

    handler(actions.cancelAction, () =>
        Promise.resolve()
            .then(() => dispatch(actions.reasonModalClosed()))
            .then(() => cancelAction({isHandled: true}))
    ),
]

export default factory
