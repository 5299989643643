import { React, useEffect, useState } from '_/facade/react'
import getPlotly from '_/facade/plotly'

import HoverMenu from '_/components/overlay/hover-menu'
import Button from '_/components/button'

import { getFilterLength, getPrintLayout } from '_/features/analysis/helpers'

import type { OrganismGraphSeries } from '_/model/reports/types'
import { GRAPH_HEIGHT } from '_/model/analysis/graph-height'
import { isLegendElement, getOrganismPieChartLayout } from './helpers'
import { OTHER } from '_/model/analysis/organisms-breakdown-series'

interface Props {
    title: string
    series: OrganismGraphSeries[]
    footer: string[]
    author: string
    renderActionButtons(onExport: (fileName: string, footer: string[]) => void): React.ReactNode
    onLegendItemClick: () => void
}

function PieChart(props: Props) {
    const root = React.createRef<HTMLDivElement>()
        , [lowPercentElement, setLowPercentElement] = useState<HTMLElement | null>(null)
        , LOWER_PERCENT = 0.05
        , lowerPercentLabel = `Organisms less than ${LOWER_PERCENT * 100}%`
        , totalCfu = (series: OrganismGraphSeries[]) => series.reduce((acc, v) => acc + v.count, 0)
        , series = props.series
        , total = totalCfu(series)
        , mainSeries = series.filter(_ => _.type !== OTHER &&  _.count / total >= LOWER_PERCENT)
        , lowPercentSeries = series.filter(_ => _.type !== OTHER && _.count / total < LOWER_PERCENT)
        , otherSeries = series.filter(_ => _.type === OTHER)
        , layout = getOrganismPieChartLayout(props.title, props.footer, props.author)

    useEffect(
        () => {
            getPlotly().then(plotly => {
                if (root.current) {
                    plotly.newPlot(root.current, chartData(), layout, { displayModeBar: false })

                    handleSetLowPercentElement()
                }
            })
        },
        // should be executed only if series changed
        // eslint-disable-next-line
        [series, props.footer]
    )

    function handleSetLowPercentElement() {
        const identifications = root.current?.querySelectorAll('.legendtext')
        identifications?.forEach(_ => {
            if (_.textContent === lowerPercentLabel)
                setLowPercentElement(_.parentElement)
        })
    }

    function chartData(): Plotly.Data[] {

        function concatSeries<T>(mapMainSeries: (_: OrganismGraphSeries) => T, mapLowPercentSeries: (_: OrganismGraphSeries[]) => T): T[] {
            return mainSeries.concat(otherSeries).map(mapMainSeries).concat(
                lowPercentSeries.length === 0 ? [] : [mapLowPercentSeries(lowPercentSeries)]
            )
        }
        const data = {
            values: concatSeries(_ => _.count, totalCfu),
            text: concatSeries(_ => _.count, totalCfu).map(_ => _ + ' CFUs'),
            labels: mainSeries.concat(otherSeries).map(_ => _.identification)
                .concat(
                    lowPercentSeries.length > 0 ? lowerPercentLabel : []
                ),
            hovertext: mainSeries.map(_ => formatData(_.identification, _.count, total))
                .concat(
                    otherSeries.length > 0 ? formatData('No organism ID', totalCfu(otherSeries), total) : []
                )
                .concat(
                    lowPercentSeries.length > 0
                        ? lowPercentSeries.map(_ => formatData(_.identification, _.count, total)).join('<br>')
                        : []
                ),
            hoverinfo: 'text',
            type: 'pie',
            hole: .5,
            sort: false,
        } as any as Plotly.Data

        return [data]
    }

    function handleExport(filename: string, footer: string[]) {
        getPlotly().then(plotly => {
            if (root.current) {
                const legendLength = series.length
                    , height = GRAPH_HEIGHT + getFilterLength(footer.length, legendLength)
                    , printLayout = getPrintLayout({title: [props.title], subtitle: '', author: props.author, footer: [], footerPlainText: footer}, legendLength)

                plotly.relayout(root.current, printLayout)

                const imgOpts = { format: 'png', width: 1000, height } as Plotly.ToImgopts
                plotly.downloadImage(root.current, {...imgOpts, filename })

                plotly.relayout(root.current, {...getOrganismPieChartLayout(props.title, [], ''), legend: {orientation: 'v'} })
            }
        })
    }

    return (
        <div>
            <div className='border border-light'>
                <div ref={root} onMouseUpCapture={e => handleMouseUp(e, props.onLegendItemClick)} />
            </div>
            <HoverMenu element={lowPercentElement}>
                <div className='ms-3 border bg-white location-report-organism-graph__hover-menu overflow-auto'>
                    {lowPercentSeries.map(_ =>
                        <Button
                            key={_.identificationId}
                            onClick={props.onLegendItemClick}
                            className={'d-block btn-link text-start text-dark btn-sm py-0'}
                        >
                            {_.identification}
                        </Button>
                    )}
                </div>
            </HoverMenu>
            {props.renderActionButtons(handleExport)}
        </div>
    )
}

/*
    Plotly has no built in means to track clicks on legend or to customize it.
    So there is check if legend is clicked and then organism name is searched.
*/
function handleMouseUp(e: React.MouseEvent<HTMLDivElement>, onLegendItemClick: () => void) {
    const target = e.target as HTMLDivElement
        , isLegendItemElement = isLegendElement(target)

    if (!isLegendItemElement)
        return

    e.stopPropagation()

    const identification = target.parentElement?.querySelector('.legendtext')?.textContent ?? ''

    if (identification === 'Other')
        return

    onLegendItemClick()
}

function formatData(label: string, count: number, total: number): string {
    const percent = total === 0 ? 0 : count / total * 100
    return `${label} ${percent.toFixed(2)}% (${count})`
}

export default PieChart
