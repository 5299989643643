import CONFIG from '_/constants/config'

function isPreDemoEnvironment() {
    return CONFIG.api === 'http://localhost:4443'
        || CONFIG.api === 'https://backend.dev.smartcontrol.microgenetics.co.uk'
        || CONFIG.api === 'https://backend.test.smartcontrol.microgenetics.co.uk'
        || CONFIG.api === 'http://backend:4443' //selenium
}

function isLocalhostEnvironment() {
    return CONFIG.api === 'http://localhost:4443'
}

function getApiBaseUrl() {
    return CONFIG.api + '/api/'
}

function isProdEnvironment() {
    return CONFIG.api === 'https://smartcontrol.microgenetics.co.uk'
}

export {
    isLocalhostEnvironment,
    isPreDemoEnvironment,
    getApiBaseUrl,
    isProdEnvironment,
}
