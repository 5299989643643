import { required, requiredValues } from '_/utils/form/validate'
import type { ObjectionableOrganismForm, ObjectionableOrganismValidation } from './types'
import type ObjectionableOrganism from '../objectionable-organism'
import * as it from '_/model/sample/identification-type'

function validate(form: ObjectionableOrganismForm, organisms: ObjectionableOrganism[]): ObjectionableOrganismValidation {
    const result: ObjectionableOrganismValidation = {}
        , fieldLabel = it.IDENTIFICATION_TYPE.find(_ => _.id === form.identificationType)?.name
        , duplicateOrganism = form.identificationType !== undefined && form.identificationValue !== undefined
            ? organisms.find(_ => _.identification.type === form.identificationType &&  _.identification.value === form.identificationValue)
            : undefined

    result.identificationType = required('Identification type')(form.identificationType) || undefined
    result.identificationValue = required(fieldLabel!)(form.identificationValue) || undefined
    result.gradeIds = requiredValues('Grades where objectionable')(form.gradeIds) || undefined

    if (duplicateOrganism)
        result.identificationValue = `Objectionable identification '${duplicateOrganism.name}' already exists`

    return result
}

export default validate
