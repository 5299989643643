import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type DashboardService from '_/services/dashboard-service'

import * as actions from './actions'

const factory = (service: DashboardService): EffectsFactory => () => [
    handler(actions.loadSamplesDailyStatistic, service.getDailySamplesStatistic),
    handler(actions.loadSamplesStatisticTotals, service.getSamplesStatistic),
    handler(actions.loadBreachLimitStatistic, service.getLimitsStatistic),
]

export default factory
