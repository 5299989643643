import { useEffect, useState } from '_/facade/react'

interface Props {
    stream: MediaStream
}

function LiveFeed(props: Props) {
    const [video, setVideo] = useState<HTMLVideoElement | null>(null)

    useEffect(
        () => {
            if (!video)
                return

            video.srcObject = props.stream

            return () => {
                video.srcObject = null
            }
        },
        [video, props.stream]
    )

    return (
        <video
            ref={setVideo}
            className='flex-fill h-0 d-print-none'
            autoPlay
            preload='auto'
        />
    )
}

export default LiveFeed
