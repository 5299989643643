import CONFIG from '_/constants/config'
import type { Header, AjaxResponse } from '_/utils/ajax'
import { ajax, buildUrl } from '_/utils/ajax'
import { noop } from '_/utils/function'
import type { AuthService, Resource } from '../api-service'
import type ApiService from '../api-service'
import type {
    Credentials,
    LogInResponse,
    ChangeContext,
    SsoLoginCredentials,
    SsoSignUpCredentials,
    AvailableMembershipsRequest,
    AvailableMemberships,
    SsoAvailableMembershipsRequest
} from '_/model/auth/types'
import { replaceNullWithUndefined } from './null-replacer'
import type ReasonService from '_/model/reason/reason-service'
import type { ElectronicSignatureSettings } from '_/model/context/electronic-signature-settings'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import { signatureFailure } from '_/model/error/error'
import { diffObject } from '_/utils/object'
import type { HubConnection} from '@microsoft/signalr'
import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr'
import type { Listener, Observable } from '_/model/observable'

function apiServiceFactory(reasonService: ReasonService, token?: string): ApiService & AuthService {
    let authHeader: Header | undefined
    let tokenReady = Promise.resolve()

    if (token !== undefined)
        authHeader = { header: 'Authorization', value: token }

    const get = commonAjax.bind(null, 'GET')
        , post = commonAjax.bind(null, 'POST')
        , remove = commonAjax.bind(null, 'DELETE')
        , patch = commonAjax.bind(null, 'PATCH')
        , getMemberships = get.bind(null, ['memberships'])
        , patchWithReason = commonAjaxWithReason.bind(null, 'PATCH')
        , removeWithReason = (
            resource: string[],
            signatureSetting: ElectronicSignatureSettings,
            signatureSettings?: ElectronicSignatureSettings[],
            data?: any,
        ) => commonAjaxWithReason('DELETE', resource, signatureSetting, data, signatureSettings)
        , postWithReason = commonAjaxWithReason.bind(null, 'POST')
        , postWithSignature = commonAjaxWithSignature.bind(null, 'POST')

    return {
        get,
        getFile,
        getFileForLongQuery,
        rawPut,
        rawGetFile,
        post,
        delete: remove,
        logIn,
        availableMemberships,
        ssoLogIn,
        ssoAvailableMemberships,
        ssoSignUp,
        logOut,
        keepAlive,
        patch,
        resource,
        getMemberships,
        changeContext,
        getDocument,
        postFile,
        postWithReason,
        patchWithReason,
        postWithSignature,
        removeWithReason,
        prepareAndStartConnection,
    }

    function getFile(resource: string[], query?: any): Promise<{ blob: Blob, filename: string }> {
        const url = apiUrl(resource, query)
            , method = 'GET'
            , headers = getHeaders()

        return jsonAjax({ url, method, headers, responseType: 'arraybuffer' }, fileParser)
    }

    function getFileForLongQuery(resource: string[], data?: any): Promise<{ blob: Blob, filename: string }> {
        const url = apiUrl(resource)
            , method = 'POST'
            , headers = getHeaders()
            , body = prepareBody(data)

        return jsonAjax({ url, method, headers, body, responseType: 'arraybuffer' }, fileParser)
    }

    function postFile(resource: string[], body: any): Promise<{ blob: Blob, filename: string }> {
        const url = apiUrl(resource)
            , method = 'POST'
            , headers = getHeaders()

        return jsonAjax({ url, method, body, headers, responseType: 'arraybuffer' }, fileParser)
    }

    function rawPut(url: string, body: any): Promise<void> {
        const method = 'PUT'

        return jsonAjax({ url, method, headers: [], body, responseType: 'arraybuffer' }, noop)
    }

    function rawGetFile(url: string): Promise<Blob> {
        const method = 'GET'

        return jsonAjax({ url, method, headers: [], responseType: 'arraybuffer' }, response => new Blob([response.response]))
    }

    function getDocument(resource: string[], query?: any): Promise<Document> {
        const url = apiUrl(resource, query)
            , method = 'GET'
            , headers = getHeaders()

        return jsonAjax({ url, method, headers, responseType: 'document' }, documentParser)
    }

    function logIn(credentials: Credentials): Promise<LogInResponse> {
        return post(['login'], credentials).then(auth => {
            authHeader = { header: 'Authorization', value: auth.token }
            return auth
        })
    }

    function availableMemberships(credentials: AvailableMembershipsRequest): Promise<AvailableMemberships> {
        return post(['available-memberships'], credentials)
    }

    function ssoLogIn(credentials: SsoLoginCredentials): Promise<LogInResponse> {
        return post(['sso-login'], credentials).then(auth => {
            authHeader = { header: 'Authorization', value: auth.token }
            return auth
        })
    }

    function ssoAvailableMemberships(credentials: SsoAvailableMembershipsRequest): Promise<AvailableMemberships> {
        return post(['sso-available-memberships'], credentials)
    }

    function ssoSignUp(credentials: SsoSignUpCredentials): Promise<void> {
        return post(['sso-sign-up'], credentials)
    }

    function logOut() {
        return post(['logout']).then(_ => {
            authHeader = undefined
        })
    }

    function keepAlive() {
        return tokenReady.then(_ => post(['keep-alive']))
    }

    function changeContext(membershipId: string): Promise<ChangeContext> {
        const result = post(['change-context'], { membershipId })
            .then(ctx => {
                authHeader = { header: 'Authorization', value: ctx.token }
                return ctx
            })

        tokenReady = new Promise(resolve => result.finally(resolve))

        return result
    }

    function getHeaders(...headers: Header[]): Header[] {
        const internalHeaders =  authHeader ? [authHeader] : []
        return internalHeaders.concat(headers)
    }

    function resource(resource: string[], signatureSetting?: ElectronicSignatureSettings): Resource {
        return {
            list: query => get(resource, query),
            create: entity => post(resource, entity),
            get: id => get(resource.concat(id)),
            remove: (id, entity) => remove(resource.concat(id), entity),
            save: (id, entity) => patch(resource.concat(id), entity),
            getTrail: id => get(resource.concat([id, 'trail'])),
            saveWithReason: (id, entity, signatureSettings) => patchWithReason(resource.concat(id), signatureSetting, entity, signatureSettings),
            removeWithReason: (id, signatureSettings) => removeWithReason(resource.concat(id), signatureSetting!, signatureSettings),
        }
    }

    function commonAjax(method: 'GET' | 'POST' | 'PATCH' | 'DELETE', resource: string[], data?: any) {
        const url = apiUrl(resource, method === 'GET' ? data : undefined)
            , headers = getHeaders()
            , body = method === 'POST' || method === 'PATCH' || method === 'DELETE' ? prepareBody(data) : undefined

        return tokenReady.then(_ => jsonAjax({ url, method, headers, body }))
    }

    function commonAjaxWithReason(
        method: 'POST' | 'PATCH' | 'DELETE',
        resource: string[],
        signatureSetting?: ElectronicSignatureSettings,
        data?: any,
        signatureSettings?: ElectronicSignatureSettings[],
    ) {
        const url = apiUrl(resource)
            , headers = getHeaders()
            , signatureRequired = reasonService.electronicSignatureSettingsEnabled(signatureSetting, signatureSettings)

        function queryWithReasonPromise(reason?: string, error?: string): Promise<void> {
            return reasonService.getReason(signatureRequired, reason, error)
                .then(approvalInfo =>
                    jsonAjax({ url, method, headers, body: prepareBody({approvalInfo, ...data}) })
                        .then(
                            noop,
                            _ => signatureFailure(_) ? queryWithReasonPromise(approvalInfo.reason, _.statusText) : Promise.reject(_)
                        )
                )
        }
        return queryWithReasonPromise()
    }

    function commonAjaxWithSignature(method: 'POST', resource: string[], signatureSetting: ElectronicSignatureSettings, data?: any, approvalInfo?: ApprovalInfo) {
        const url = apiUrl(resource)
            , headers = getHeaders()
            , reason = approvalInfo?.reason ?? ''
            , electronicSignatureSettingsEnabled = reasonService.electronicSignatureSettingsEnabled(signatureSetting)
            , signatureRequired = electronicSignatureSettingsEnabled && !approvalInfo?.password && (!approvalInfo?.idToken && !approvalInfo?.sessionState)
            , promise = signatureRequired
                ? (error?: string) => reasonService.getSignature(error)
                : () => Promise.resolve(approvalInfo ?? {})

        function queryWithSignaturePromise(error?: string): Promise<void> {
            return promise(error)
                .then(signature => {
                    const newApprovalInfo = {...signature, reason}
                    return jsonAjax({ url, method, headers, body: prepareBody({approvalInfo: newApprovalInfo, ...data}) })
                        .then(
                            noop,
                            //diffObject to avoid calling queryWithSignaturePromise in infinite loop
                            _ => signatureFailure(_) && diffObject(approvalInfo, newApprovalInfo) ? queryWithSignaturePromise(_.statusText) : Promise.reject(_)
                        )
                    }
                )
        }
        return queryWithSignaturePromise()
    }

    function buildConnection(url: string): HubConnection {
        return new HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: () => tokenReady.then(_ => authHeader?.value ?? ''),
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build()
    }

    function prepareAndStartConnection<T>(resource: string[]): Promise<Observable<T>> {
        const connection = buildConnection(apiUrl(resource))

        return connection.start()
            .then(_ =>
                (onChange: Listener<T>) => {
                    connection.on('onUpdated', onChange)

                    return () => {
                        connection.off('onUpdated', onChange)
                        connection.stop()
                    }
                }
            )
    }
}

function apiUrl(segments: string[], query?: any) {
    return buildUrl({ api: CONFIG.api, segments, query })
}

function prepareBody(object?: any): string | FormData | undefined {
    if (object === undefined)
        return undefined

    return object instanceof FormData ? object : JSON.stringify(object, (_, v) => v !== undefined ? v : null)
}

interface JsonAjaxOptions {
    method: string
    url: string
    responseType?: XMLHttpRequestResponseType
    headers?: Header[]
    body?: string | FormData
}

function jsonAjax(options: JsonAjaxOptions, parser = jsonParser) {
    const headers = (options.headers || []).concat()
    if (typeof options.body === 'string')
        headers.push({
            header: 'content-type',
            value: 'application/json; charset=utf-8',
        })

    const responseType: XMLHttpRequestResponseType = options.responseType || 'text'
        , ajaxOptions = Object.assign(
            {},
            options,
            { responseType, headers }
        )
        , ajaxPromise = ajax(ajaxOptions)
        , abort = ajaxPromise.abort

    return Object.assign(ajaxPromise.then(parser), { abort })
}

function jsonParser(response: AjaxResponse): any {
    return response.response
        ? replaceNullWithUndefined(JSON.parse(response.response))
        : undefined
}

function fileParser(response: AjaxResponse): any {
    const errorMessage = 'Unsupported file response'
        , contentDisposition = response.headers['content-disposition']
        , contentType = response.headers['content-type']

    if (!contentDisposition || !contentType)
        return Promise.reject(errorMessage)

    //https://stackoverflow.com/questions/23054475/javascript-regex-for-extracting-filename-from-content-disposition-header
    const filenameRegex = /attachment; filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/
        , groups = filenameRegex.exec(contentDisposition)

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (groups == null || groups[1] == null)
        return Promise.reject(errorMessage)

    const result = {
            blob: new Blob([response.response], { type: contentType }),
            filename: decodeURIComponent(groups[1]),
        }

    return Promise.resolve(result)
}

function documentParser(response: AjaxResponse): any {
    return response.response
        ? Promise.resolve(response.response)
        : Promise.reject()
}

export default apiServiceFactory
