import DEFAULT_FIELD_INDEX from '_/constants/custom-field-index'
import * as fieldIndex from '_/constants/custom-field-index'
import { NOT_RECORDED_ID, NOT_RECORDED_NAME, NOT_RECORDED as NOT_RECORDED_ENTITY, ANY_ID } from '_/constants/system-words'

import { VOID_ID } from '_/utils/tree'

import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { FilterFieldValue } from '_/model/sample/search'
import type { PredefinedLists } from '_/model/app-state'

import { fullNameLocationList } from '_/utils/exposure-location'
import { NO_SESSION, NO_SESSION_ID } from '../session/no-session'

function customFieldName(fieldIndex: number | fieldIndex.FieldIndex, fields: CustomField[]) {
    const field = fields.find(_ => _.index === fieldIndex)
    return field && field.fieldName
}

function isDefaultCustomField(field: FilterFieldValue | FieldValues) {
    return DEFAULT_FIELD_INDEX.indexOf(field.index) !== -1
}

function isDefaultCustomFieldIndex(index: fieldIndex.FieldIndex) {
    return DEFAULT_FIELD_INDEX.indexOf(index) !== -1
}

function getEntitiesForFilter(relatedEntity: fieldIndex.FieldIndex, predefinedLists: Pick<PredefinedLists, 'exposureLocations' | 'sampleOperators' | 'sampleSessions' | 'sampleTypes' | 'customFields'>) {
    if (relatedEntity === fieldIndex.EXPOSURE_LOCATION_ID)
        return [{id: NOT_RECORDED_ID, pathName: NOT_RECORDED_NAME}].concat(
            fullNameLocationList(predefinedLists.exposureLocations).filter(_ => _.parentId !== VOID_ID)
        )

    if (relatedEntity === fieldIndex.SAMPLE_TYPE_ID) // sample type can not be 'N/R'
        return predefinedLists.sampleTypes

    function getEntities(relatedEntity: fieldIndex.FieldIndex, predefinedLists: Pick<PredefinedLists, 'exposureLocations' | 'sampleOperators' | 'sampleSessions' | 'sampleTypes' | 'customFields'>): {id: string, name: string}[] {
        if (relatedEntity === fieldIndex.SESSION_ID)
            return [NO_SESSION].concat(predefinedLists.sampleSessions)

        if (relatedEntity === fieldIndex.OPERATORS_IDS)
            return predefinedLists.sampleOperators

        if (!isDefaultCustomFieldIndex(relatedEntity))
            return predefinedLists.customFields.find(_ => _.index === relatedEntity)?.selectionFieldValues ?? []
        return []
    }

    return [NOT_RECORDED_ENTITY].concat(getEntities(relatedEntity, predefinedLists))
}

function isSystemId(id: string | number) {
    return [
        NOT_RECORDED_ID,
        NO_SESSION_ID,
        ANY_ID,
    ].some(_ => _ === id)
}

export {
    customFieldName,
    isDefaultCustomField,
    isDefaultCustomFieldIndex,
    getEntitiesForFilter,
    isSystemId,
}
