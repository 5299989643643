import type { FieldRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import SAMPLE_STATUS from '_/constants/sample-status'
import SAMPLE_FLAG from '_/constants/sample-flag'

interface Props {
    flag?: number
}

function SampleStatusInput(props: Props) {
    function handleChangeStatus(fieldProps: FieldRenderProps<number[]>, status: number) {
        const currentStatuses: number[] = fieldProps.input.value
            , selectedStatus = currentStatuses.find(_ => _ === status)
            , newStatuses = selectedStatus === undefined
                ? currentStatuses.concat(status)
                : currentStatuses.filter(_ => _ !== status)

        fieldProps.input.onChange(newStatuses)
    }

    function disabledStatus(statusId: number) {
        const activeFlag = SAMPLE_FLAG.find(_ => _.id === props.flag)
        return activeFlag
            && activeFlag.availableForStatus.find(_ => _ === statusId) === undefined
    }

    return(
        <Field name='statuses' render={_ =>
            <div className='mb-3'>
                <label className='col-form-label'>Status</label>
                {SAMPLE_STATUS.map(s =>
                    <div key={s.id} className='form-check'>
                        <input
                            className='form-check-input'
                            name={s.name}
                            id={s.name}
                            type='checkbox'
                            checked={(_.input.value || []).find((_: number) => _ === s.id) !== undefined}
                            onChange={() => handleChangeStatus(_, s.id)}
                            disabled={disabledStatus(s.id)}
                            data-testid={`sample-status-${s.id}`}
                        />
                        <label
                            className='form-check-label'
                            htmlFor={s.name}
                        >
                            {s.name}
                        </label>
                    </div>
                )}
            </div>
        }/>
    )
}

export default SampleStatusInput
