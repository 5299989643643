import { diffObject } from '_/utils/object'
import type Api from '_/services/predefined-lists-service'
import type { SampleSessionEdit } from '_/model/predefined-lists/session/types'

function updateTransaction(
    id: string,
    current: SampleSessionEdit,
    next: SampleSessionEdit,
    api: Api,
): Promise<void> {
    const diff = diffObject(current, next)

    if (!diff)
        return Promise.resolve()

    return api.sampleSession.save(id, diff)
}

export {
    updateTransaction,
}
