import { useAction, useEffect, useState } from '_/facade/react'
import { PaginatedFooter, Table } from '_/components/table'
import { useTimeService } from '_/components/time'
import Link from '_/components/link'

import type PaginationState from '_/model/pagination-state'
import type Page from '_/model/page'

import type { SampleTrend } from '_/model/sample/sample-trends'
import { paramsFilter } from '_/model/filters/helpers'

import { ALL_STATUS_IDS } from '_/constants/sample-status'
import { SAMPLES } from '_/constants/routes'

import * as a from '../actions'

const ITEMS_PER_PAGE = 5

interface Props {
    sampleId: string
}

function SampleTrends(props: Props) {
    const [pagination, setPagination] = useState({ start: 0, count: ITEMS_PER_PAGE })
        , trends = useTrends(props.sampleId, pagination)
        , timeService = useTimeService()

    return(
        <div>
            <fieldset>
                <div className='custom-legend'>Trends</div>
            </fieldset>
            <Table className='table-layout-fixed mb-0'>
                <thead className='thead'>
                    <tr className='table-header-thin'>
                        <th style={{width: '30%'}}>Date</th>
                        <th style={{width: '40%'}}>Trend name</th>
                        <th style={{width: '30%'}}>Trend inv refs</th>
                    </tr>
                </thead>
                <tbody>
                    {trends.items.map(_ =>
                        <tr key={_.trendId}>
                            <td>
                                <Link routeName={SAMPLES} routeParams={paramsFilter({ trendIds: [_.trendId], statuses: ALL_STATUS_IDS })}>{_.trendId}</Link>
                                <div>{timeService.formatCtzDate(_.createdAt)}</div>
                            </td>
                            <td>{_.name}</td>
                            <td>
                                {_.trendInvestigationReferences.map((_, i) =>
                                    <div key={i} title={_} className='single-line-text'>{_}</div>
                                )}
                            </td>
                        </tr>
                    )}
                </tbody>
                {trends.totalCount > ITEMS_PER_PAGE &&
                    <PaginatedFooter
                        colSpan={3}
                        state={pagination}
                        onChange={setPagination}
                        totalCount={trends.totalCount}
                    />
                }
            </Table>
        </div>
    )
}

export default SampleTrends

function useTrends(id: string, pagination: PaginationState) {
    const loadSampleTrends = useAction(a.loadSampleTrends)
        , [trends, setTrends] = useState<Page<SampleTrend>>({ items: [], totalCount: 0 })

    useEffect(
        () => {
            loadSampleTrends({ id, pagination })
                .then(setTrends)
        },
        [id, pagination, loadSampleTrends]
    )

    return trends
}
