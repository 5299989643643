import { actions as routerActions } from 'redux-router5'

import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type ContextService from '_/services/context-service'
import type { TimezoneAwareTimeService } from '_/services/time-service'
import type ExportService from '_/services/export-service'
import { noop } from '_/utils/function'
import * as routes from '_/constants/routes'
import * as authActions from '_/features/auth/actions'
import * as spinnerActions from '_/features/spinner/actions'

import { updateTransaction } from '_/model/context/transaction'

import * as actions from './actions'

const factory = (service: ContextService, timeService: TimezoneAwareTimeService, exportService: ExportService): EffectsFactory => (dispatch, getState) => [
    handler(actions.loadContexts, () =>
        service.getContexts()
            .then(_ => dispatch(actions.contextsLoaded(_)))
            .then(noop)
    ),

    handler(actions.loadContext, id => {
        dispatch(actions.clearContexts())

        return service.get(id)
            .then(_ => {
                dispatch(actions.contextLoaded(_))
                return _
            })
    }),

    handler(actions.createContext, context =>
        service.create(context)
            .then(() => dispatch(actions.loadContexts()))
            .then(() => dispatch(authActions.loadMemberships()))
            .then(noop)
    ),

    handler(actions.saveChanges, data =>
        Promise.resolve()
            .then(() => dispatch(spinnerActions.showSpinner()))
            .then(() => updateTransaction(data.id, data.oldContext, data.newContext, data.signatureSettings, service))
            .then(() => dispatch(actions.loadContexts()))
            .then(() => {
                const editedContext = getState().contexts.list.idHash[data.id]
                dispatch(actions.contextEdited(editedContext))
                if (data.oldContext.timeZoneName !== data.newContext.timeZoneName)
                    timeService.init(data.newContext.timeZoneName)
            })
            .finally(() => dispatch(spinnerActions.hideSpinner()))
            .then(noop)
    ),

    handler(actions.loadContextInvite, token => service.getContextInvite(token)),

    handler(actions.invite, data =>
        service.invite(data)
            .then(noop)
    ),

    handler(actions.assign, token =>
        service.assign(token)
            .then(() => dispatch(routerActions.navigateTo(routes.LOG_IN)))
            .then(noop)
    ),

    handler(actions.rejectContextInvite, service.rejectContextInvitation),

    handler(actions.joinContext, data =>
        service.joinContext(data.token, data.user)
            .then(_ => dispatch(routerActions.navigateTo(routes.CONTEXTS_INVITE_CONFIRMED)))
            .then(noop)
    ),

    handler(actions.disableContext, payload =>
        Promise.resolve()
            .then(() => dispatch(spinnerActions.showSpinner()))
            .then(() => service.remove(payload.id, payload.signatureSettings))
            .then(() => dispatch(actions.loadContexts()))
            .then(() => dispatch(authActions.loadMemberships()))
            .finally(() => dispatch(spinnerActions.hideSpinner()))
            .then(noop)
    ),

    handler(actions.resumeContext, payload =>
        Promise.resolve()
            .then(() => dispatch(spinnerActions.showSpinner()))
            .then(() => service.resume(payload.id, payload.signatureSettings))
            .then(() => dispatch(actions.loadContexts()))
            .then(() => dispatch(authActions.loadMemberships()))
            .finally(() => dispatch(spinnerActions.hideSpinner()))
            .then(noop)
    ),

    handler(actions.exportContext, exportService.exportContext),
    handler(actions.loadContextExports, exportService.getAllContextExports),

    handler(actions.loadContextTrail, service.getTrail),

    handler(actions.validateIpRestriction, service.validateIpRestriction),
    handler(actions.validateIpInWhitelist, service.validateIpInWhitelist),
    handler(actions.validateIpInContextWhitelist, service.validateIpInContextWhitelist),
]

export default factory
