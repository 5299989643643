import { useAction, useEffect, useState } from '_/facade/react'
import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import type { FormApi } from 'final-form'

import { Close } from '_/components/button'
import { useTimeService } from '_/components/time'
import { resetForm, SelectField, Submit, submitDisabled } from '_/components/form'

import FormChangesObserver from '_/components/form/form-changes-observer'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '_/components/modal'

import validate from '_/model/scheduling/day-scheduler/validate'
import * as h from '_/model/scheduling/day-scheduler/helpers'
import type { Group, RecurrenceForm, Schedule, Recurrence } from '_/model/scheduling/day-scheduler/types'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import UtcDateField from '_/components/form/utc-date-field'
import { NumberFieldInline } from '_/components/form'

import * as ft from '_/model/scheduling/day-scheduler/frequency-type'

import * as dayPlanActions from '../actions'
import type { DateTime } from '_/model/date-time'
import FormattedText from '_/features/text/formatted-text'
import { MONTH_RECURRENCE_ON_DATE } from '_/model/scheduling/day-scheduler/month-recurrence-type'

import RecurrenceFields from './recurrence-fields'

interface Props {
    group: Group
    session: Pick<SampleSession, 'id' | 'name' | 'isActive'>
    date: DateTime
    onClose: () => void
    onReloadDayPlan: () => void
}

type EditMode = 'edit' | 'create'

function RecurrenceModal(props: Props) {
    const timeService = useTimeService()
        , seriesId = props.group.seriesId
        , initialValues = useSeries(seriesId)
        , startDate = initialValues.startDate ?? props.date
        , mode = seriesId ? 'edit' : 'create'
        , handleSubmit = useSubmit(props.date, props.session.id, props.group.id, seriesId, mode, props.onClose, props.onReloadDayPlan)
        , startDateWeekDayId = timeService.utcWeekDay(startDate)

    let previousValues: RecurrenceForm = initialValues

    function handleChange(values: RecurrenceForm, form: FormRenderProps) {
        function getNewValues(): RecurrenceForm {
            const { endDate, recurrenceFrequency, type } = values
                , persistedValues = { endDate, recurrenceFrequency, type: type ?? ft.DAY }
                , numberOfWeekDay = h.calculateNumberOfWeekDay(timeService, startDate)

            switch (values.type) {
                case ft.WEEK:
                    return { recurOnDays: [ startDateWeekDayId ], ...persistedValues }
                case ft.MONTH:
                    return {
                        ...persistedValues,
                        type: ft.MONTH,
                        weekNumber: numberOfWeekDay > 4 ? 0 : numberOfWeekDay,
                        weekDayId: startDateWeekDayId,
                        dayNumber: timeService.utcTimeStruct(startDate).day,
                        recurOnType: MONTH_RECURRENCE_ON_DATE
                    }
                default:
                    return persistedValues
            }
        }

        if (previousValues.type !== values.type) {
            const newValues = getNewValues()

            form.form.batch(() => {
                Object.entries(newValues).forEach(([key, value]) => {
                    form.form.change(key, value)
                })

                Object.entries(values).forEach(([key]) => {
                    if (key in newValues)
                        return
                    form.form.change(key, undefined)
                })
            })
            previousValues = newValues
            return
        }

        previousValues = values
    }

    return (
        <Modal
            isOpen
            noDefaultContentWidth
            onClose={props.onClose}
            contentClassName='recurrence-modal--width'
        >
            <Form<RecurrenceForm>
                onSubmit={(values, form) => handleSubmit(values, form)}
                initialValues={initialValues}
                validate={_ => validate(_, props.date, timeService)}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <FormChangesObserver form={form} onChange={handleChange}/>
                        <ModalHeader className='pb-0 border-bottom-0'>
                            <h4>Recurrence</h4>
                            <Close onClick={props.onClose} />
                        </ModalHeader>
                        <ModalBody className='px-1'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-3'>Group</div>
                                    <div className='col-9 fw-bold ps-0'>
                                        <FormattedText text={h.formatGroupName(props.group.name, props.group.isActive, props.group.isAdHoc)} />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-3'>Session</div>
                                    <div className='col-9 fw-bold ps-0'>{props.session.name}</div>
                                </div>
                                <div className='row'>
                                    <div className='col-3'>Start date</div>
                                    <div className='col-9 fw-bold ps-0'>{h.formatStartDate(timeService, startDate)}</div>
                                </div>

                                <hr className='my-4'/>

                                <div className='row g-2 mt-4'>
                                    <div className='col-3 col-form-label'>Recur every</div>
                                    <div className='col-2'>
                                        <NumberFieldInline name='recurrenceFrequency' />
                                    </div>
                                    <div className='col-7'>
                                        <SelectField
                                            name='type'
                                            entities={ft.FREQUENCY_TYPE}
                                            calcId={_ => _.id}
                                            calcName={_ => _.name}
                                        />
                                    </div>
                                </div>
                                <div className='recurrence-modal__body--spacer'>
                                    <RecurrenceFields values={form.values} />
                                </div>
                                <div className='row g-1'>
                                    <div className='col-3 col-form-label'>End date</div>
                                    <div className='col-9 ps-0'><UtcDateField name='endDate' /></div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className='border-top-0 modal-footer-height'>
                            <div className={'d-flex flex-fill justify-content-end'}>
                                <Submit disabled={submitDisabled(form)}>{mode === 'edit' ? 'Edit current series' : 'Save changes'}</Submit>
                            </div>
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default RecurrenceModal

function useSubmit(
    startDate: DateTime,
    sessionId: string,
    groupId: string,
    seriesId: string | undefined,
    mode: EditMode,
    closeModal: () => void,
    onReloadDayPlan: () => void
) {
    const create = useAction(dayPlanActions.createSeries)
        , save = useAction(dayPlanActions.saveSeries)

    function handleSubmit(newValue: RecurrenceForm, form: FormApi<RecurrenceForm>) {
        const request = mode === 'edit'
                ? save({ id: seriesId!, recurrence: {...newValue, startDate} as Schedule })
                : create({ ...newValue, sessionId, groupId, startDate } as Recurrence)

        return request
            .then(() => {
                onReloadDayPlan()
                resetForm(form).then(closeModal)
            })
    }

    return handleSubmit
}

function useSeries(id: string | undefined) {
    const loadSeries = useAction(dayPlanActions.loadSeries)
        , [series, setSeries] = useState<RecurrenceForm>({})

    useEffect(
        () => {
            if (!id) {
                setSeries({ type: ft.DAY, recurrenceFrequency: 1 })
                return
            }

            loadSeries(id)
                .then(_ => setSeries(_.schedule))
        },
        [id, loadSeries]
    )

    return series
}

