function SmartControlLinks() {
    return (
        <div className='mt-auto d-flex justify-content-between'>
            <a href='https://microgenetics.co.uk/smartcontrol' className='text-white text-decoration-underline' data-testid='what-is-smartcontrol'>What is SmartControl?</a>
            <a href='https://microgenetics.co.uk' className='text-white text-decoration-underline' data-testid='microgenetics-home'>Microgenetics homepage</a>
        </div>
    )
}

export default SmartControlLinks
