import { useSelector, useAction, useState, useEffect, useReducer } from '_/facade/react'
import { compare } from '_/utils/string'
import * as r from '_/constants/routes'
import { navigateTo } from '_/features/routing/actions'
import * as h from '_/hooks/shared-hooks'
import type ObjectionableOrganism from '_/model/objectionable-organisms/objectionable-organism'
import { Table, PaginatedFooter, EmptyTableMessage } from '_/components/table'
import Link, { LinkButton } from '_/components/link'
import PageHeader from '_/components/page-header'
import FormattedText from '_/features/text/formatted-text'
import ObjectionableOrganismEditor from './editor'

import * as a from '../actions'
import type Page from '_/model/page'
import { useContextSwitchObserver } from '_/components/context-observer'
import type PaginationState from '_/model/pagination-state'
import ITEMS_PER_PAGE from '_/constants/items-per-page'
import AuditTrail from './audit-trail'
import { formatActiveState } from '_/utils/format/common'
import { IDENTIFICATION_TYPE } from '_/model/sample/identification-type'

function ObjectionableOrganismList() {
    const [filter, handlePaginationChange] = useFilter()
        , [organisms, reload] = useObjectionableOrganisms(filter)
        , [editMode, handleCloseEditor] = useEditor(reload)
        , [showTrail, handleCloseTrail] = useTrail()
        , [grades, formatGrades] = useGrades()
        , permissions = useSelector(_ => _.auth.permissions)

    return (
        <>
            {editMode.tag !== 'none' &&
                <ObjectionableOrganismEditor
                    grades={grades}
                    onClose={handleCloseEditor}
                />
            }

            {showTrail &&
                <AuditTrail onClose={handleCloseTrail} />
            }

            <PageHeader title='Identifications'>
                <Link
                    routeName={r.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_TRAIL}
                    title='Audit trail'
                    className='ms-auto me-2 '
                >
                    <i className='material-icons text-secondary'>
                        history
                    </i>
                </Link>

                <LinkButton
                    className='btn-primary'
                    routeName={r.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_CREATE}
                    hasNoPermissions={!permissions.editObjectionableOrganisms}
                >
                    Add an objectionable identification
                </LinkButton>
            </PageHeader>
            <div className='p-3 mb-0'>
                This page lists identification types that SmartControl<br />
                can automatically mark as objectionable. You can list any level of identification:<br />
                Organism (by genus and species), Organism types (Gram stain, Mould or Yeast)<br />
                or test identifications (Catalase, Oxidase, Oxidase fermentation, Coagulase)
            </div>

            <div className="overflow-auto flex-fill">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Identification type</th>
                            <th>Identification</th>
                            <th>Grades where objectionable</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {organisms.items.length === 0
                            ? <EmptyTableMessage colSpan={4} message='There are no identifications at the moment' />
                            : organisms.items.map(organism =>
                                <tr key={organism.id}>
                                    <td>
                                        {IDENTIFICATION_TYPE.find(_ => _.id === organism.identification.type)?.name}
                                    </td>
                                    <td>
                                        <FormattedText text={formatActiveState(organism.name, organism.isActive)} />
                                    </td>
                                    <td>
                                        {formatGrades(organism.gradeIds)}
                                    </td>
                                    <td className='text-end'>
                                        <Link
                                            className='py-0 px-1 bg-white'
                                            routeName={r.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_EDIT}
                                            routeParams={{ id: organism.id }}
                                            hasNoPermissions={!permissions.editObjectionableOrganisms}
                                        >
                                            <i className='material-icons md-18 text-primary'>create</i>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                    <PaginatedFooter
                        colSpan={4}
                        state={filter.pagination}
                        onChange={handlePaginationChange}
                        totalCount={organisms.totalCount}
                    />
                </Table>
            </div>
        </>
    )
}

interface Filter {
    pagination: PaginationState
}

function useFilter() {
    const DEFAULT_PAGINATION = { start: 0, count: ITEMS_PER_PAGE }
        , [filter, setFilter] = useState<Filter>({ pagination: DEFAULT_PAGINATION })

    function handlePaginationChange(pagination: PaginationState) {
        setFilter(_ => ({ ..._, pagination }))
    }

    return [filter, handlePaginationChange] as const
}

function useObjectionableOrganisms(filter: Filter) {
    const load = useAction(a.loadObjectionableOrganismList)
        , contextSwitch = useContextSwitchObserver()
        , [list, setList] = useState<Page<ObjectionableOrganism>>({ items: [], totalCount: 0 })
        , [reloadCounter, reload] = useReducer(_ => _ + 1, 0)

    useEffect(
        () => {
            load(filter.pagination).then(setList)
        },
        [filter, load, contextSwitch, reloadCounter]
    )

    return [list, reload] as const
}

function useGrades() {
    const grades = h.useGrades()

    function formatGrades(ids: string[]) {
        const loaded = ids.every(id => grades.some(_ => _.id === id))

        if (!loaded)
            return '...'

        return ids.map(id => grades.find(_ => _.id === id)!.name).sort((a, b) => compare(a, b)).join(', ')
    }

    return [grades, formatGrades] as const
}

type EditMode = { tag: 'none' } | { tag: 'create', id: undefined } | { tag: 'edit', id: string }

function useEditor(reload: () => void) {
    const route = useSelector(_ => _.router.route)
        , mode: EditMode =
            route?.name === r.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_CREATE
                ? { tag: 'create', id: undefined }
                : route?.name === r.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_EDIT
                    ? { tag: 'edit', id: route.params.id }
                    : { tag: 'none' }
        , navigate = useAction(navigateTo)

    function handleCloseEditor() {
        navigate(r.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS)
        reload()
    }

    return [mode, handleCloseEditor] as const
}

function useTrail() {
    const navigate = useAction(navigateTo)
        , showTrail = useSelector(_ => _.router.route?.name === r.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_TRAIL)

    function handleCloseTrail() {
        navigate(r.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS)
    }

    return [showTrail, handleCloseTrail] as const
}

export default ObjectionableOrganismList
