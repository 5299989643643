import type { ValidationResult } from '_/utils/form/validate'
import { isValidNumberInRange } from '_/utils/form/validate'
import * as v from '_/features/samples/validate'
import type TimeService from '_/services/time-service'
import * as fieldIndex from '_/constants/custom-field-index'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import { getFieldName } from '_/features/samples/helpers'
import type { NonViableSampleForm } from '_/model/non-viable-sample/booking/types'
import type { ParticleConcentrationView } from '_/model/non-viable-sample/types'

function validateParticleCount(name: string) {
    return (count: number | undefined, notRecorded: boolean | undefined) => {
        if (notRecorded || count === undefined)
            return undefined

        if (!Number.isInteger(count) || !isValidNumberInRange(count, 0, 1000000000))
            return `${name} should be integer between 0 and 1 000 000 000`

        return undefined
    }
}

function validateParticleVolume(name: string) {
    return (count: number | undefined, volume: number | undefined, notRecorded: boolean | undefined) => {
        if (notRecorded || count === undefined && volume === undefined)
            return undefined

        if (count !== undefined && volume === undefined)
            return `${name} is required`

        if (count !== undefined && volume !== undefined && !isValidNumberInRange(volume, Number.MIN_VALUE, 1000000000))
            return `${name} must be value between 0 and 1 000 000 000, 0 not included`

        return undefined
    }
}

function validate(timeService: TimeService, entity: Partial<NonViableSampleForm>, customFields: CustomField[]) {
    const values = entity.fields ?? []
        , result: ValidationResult<Partial<NonViableSampleForm>> = {}
        , lowerParticle: ValidationResult<Partial<ParticleConcentrationView>> = {}
        , higherParticle: ValidationResult<Partial<ParticleConcentrationView>> = {}

    const customFieldsValidation = v.validateNonViableCustomFields(customFields, values)
        , barcodeValidation = v.validateBarcode(entity, getFieldName(customFields, fieldIndex.BARCODE))
        , batchNumberValidation = v.validateBatchNumber(entity, customFields.find(f => f.index === fieldIndex.BATCH_NUMBER))
        , operatorsValidation = v.validateNonViableOperators(entity.fields, customFields.find(f => f.index === fieldIndex.OPERATORS_IDS))
        , sessionValidation = v.validateNonViableSession(entity.fields, customFields.find(f => f.index === fieldIndex.SESSION_ID))
        , datesValidation = v.validateNonViableDates(timeService, entity.fields ?? [], customFields)
        , locationsValidation = v.validateLocations(customFields, values)
        , lowerParticleCountValidation = validateParticleCount('0.5μm count')(entity.lowerParticle?.count, entity.lowerParticle?.notRecorded)
        , lowerParticleVolumeValidation = validateParticleVolume('0.5μm vol. (m³)')(entity.lowerParticle?.count, entity.lowerParticle?.volume, entity.lowerParticle?.notRecorded)
        , higherParticleCountValidation = validateParticleCount('5μm count')(entity.higherParticle?.count, entity.higherParticle?.notRecorded)
        , higherParticleVolumeValidation = validateParticleVolume('5μm vol. (m³)')(entity.higherParticle?.count, entity.higherParticle?.volume, entity.higherParticle?.notRecorded)
        , overlaidFields = values.map((_, index) =>
            customFieldsValidation[index] ||
            barcodeValidation.fields[index] ||
            batchNumberValidation.fields[index] ||
            operatorsValidation.fields[index] ||
            sessionValidation.fields[index] ||
            datesValidation.fields[index] ||
            locationsValidation[index]
        )

    if (lowerParticleCountValidation)
        lowerParticle.count = lowerParticleCountValidation

    if (lowerParticleVolumeValidation)
        lowerParticle.volume = lowerParticleVolumeValidation

    if (higherParticleCountValidation)
        higherParticle.count = higherParticleCountValidation

    if (higherParticleVolumeValidation)
        higherParticle.volume = higherParticleVolumeValidation

    return { ...result, fields: overlaidFields, lowerParticle, higherParticle }
}

export {
    validate as default,
    validateParticleCount,
    validateParticleVolume,
}
