import type { Observable } from '_/model/observable'
import type { Device } from '_/model/predefined-lists/devices/types'
import type ApiService from '../api-service'
import type DeviceService from '../device-service'

function factory(api: ApiService): DeviceService {
    return {
        getDevices,
    }

    function getDevices(): Promise<Observable<Device[]>> {
        return api.prepareAndStartConnection(['devices-ui-hub'])
    }
}

export default factory
