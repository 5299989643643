import { actionFactory } from '_/utils/redux'
import type { SampleSession, SampleSessionEdit } from '_/model/predefined-lists/session/types'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.sample-sessions')

export const loadSampleSessionList = action<void, Promise<void>>('loadSampleSessionList')
           , sampleSessionListLoaded = action<SampleSession[]>('sampleSessionListLoaded')
           , loadSampleSessionTrail = action<string, Promise<AuditTrail>>('loadSampleSessionTrail')
           , loadSampleSession = action<string, Promise<SampleSession>>('loadSampleSession')
           , createSampleSession = action<SampleSessionEdit, Promise<void>>('createSampleSession')
           , saveSampleSession = action<{ id: string, oldSampleSession: SampleSessionEdit, newSampleSession: SampleSessionEdit }, Promise<void>>('saveSampleSession')
           , removeSampleSession = action<string, Promise<void>>('removeSampleSession')
           , sampleSessionRemoved = action<string>('sampleSessionRemoved')
           , changePosition = action<{ id: string, position: number }, Promise<void>>('changePosition')
