import * as ft from '_/model/scheduling/day-scheduler/frequency-type'

import CheckboxGroupField from '_/components/form/checkbox-group-field'
import RadioField from '_/components/form/radio-field'
import { SelectField } from '_/components/form'

import WEEK from '_/constants/weekday'

import { MONTH_RECURRENCE_ON_DATE, MONTH_RECURRENCE_ON_WEEK_DAY } from '_/model/scheduling/day-scheduler/month-recurrence-type'
import { ordinaryNumbers } from '_/model/scheduling/day-scheduler/helpers'
import type { RecurrenceForm } from '_/model/scheduling/day-scheduler/types'

function RecurrenceFields(props: { values: RecurrenceForm }) {
    const type = props.values.type

    if (type === ft.WEEK) {
        return (
            <CheckboxGroupField
                name='recurOnDays'
                entities={WEEK}
                calcLabel={_ => _.shortName}
                inline
            />
        )
    }

    if (type === ft.MONTH) {
        return (
            <>
                <div className='row g-2'>
                    <div className='col-3'>
                        <RadioField
                            id='recurOnDate'
                            name='recurOnType'
                            value={MONTH_RECURRENCE_ON_DATE}
                            label='On the'
                            className='col-form-label'
                        />
                    </div>
                    <div className='col-3 px-0'>
                        <SelectField
                            name='dayNumber'
                            entities={ordinaryNumbers()}
                            calcName={_ => _.name}
                            calcId={_ => _.id}
                            disabled={props.values.recurOnType === MONTH_RECURRENCE_ON_WEEK_DAY}
                        />
                    </div>
                    <div className='col-3 col-form-label'>day</div>
                </div>
                <div className='row g-2'>
                    <div className='col-3'>
                        <RadioField
                            id='recurOnWeekDay'
                            name='recurOnType'
                            value={MONTH_RECURRENCE_ON_WEEK_DAY}
                            label='On the'
                            className='col-form-label'
                        />
                    </div>
                    <div className='col-3 px-0'>
                        <SelectField
                            name='weekNumber'
                            entities={ordinaryNumbers(4)}
                            calcName={_ => _.name}
                            calcId={_ => _.id}
                            disabled={props.values.recurOnType === MONTH_RECURRENCE_ON_DATE}
                        />
                    </div>
                    <div className='col-6'>
                        <SelectField
                            name='weekDayId'
                            entities={WEEK}
                            calcName={_ => _.name}
                            calcId={_ => _.id}
                            disabled={props.values.recurOnType === MONTH_RECURRENCE_ON_DATE}
                        />
                    </div>
                </div>
            </>
        )
    }
    return null
}

export default RecurrenceFields
