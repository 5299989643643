import { Form } from 'react-final-form'

import { useState, classnames, useAction } from '_/facade/react'
import { Link } from '_/utils/router'
import { noop } from '_/utils/function'
import { FORGOT_PASSWORD } from '_/constants/routes'
import { TextField, PasswordField, Submit, submitDisabled } from '_/components/form'
import type { UserMembership, Credentials, AvailableMembershipsRequest } from '_/model/auth/types'
import logo from '_/../assets/ms-sign-in-light.svg'

import validate from '_/model/auth/log-in/auth-form-validate'
import ContextSwitcherInput from './context-switcher'

import { useSsoUrl } from '../hooks'
import { LOG_IN } from '_/model/auth/sso-request-source'
import * as a from '../actions'
import SmartControlLinks from '../links'

function AuthForm() {
    const [switchingContext, setSwitchingContext] = useState<{ isOpen: boolean, values: UserMembership[] }>({ isOpen: false, values: [] })
        , ssoUrl = useSsoUrl(LOG_IN)
        , getAvailableMemberships = useAction(a.availableMemberships)
        , logInHandler = useLogInHandler()

    function handleOpenContextSwitcher(values: UserMembership[], credentials: AvailableMembershipsRequest) {
        if (values.length === 1)
            return logInHandler(values[0], credentials)

        setSwitchingContext({ isOpen: true, values: values})
    }

    function handleCloseContextSwitcher() {
        setSwitchingContext({ isOpen: false, values: [] })
    }

    function handleSubmit(creds: AvailableMembershipsRequest) {
        return getAvailableMemberships(creds)
            .then(_ => handleOpenContextSwitcher(_.memberships, creds))
            .then(noop)
    }

    return(
        <div className='auth h-100 d-flex justify-content-center'>
            <div className='d-flex flex-column mb-4'>
                <div className='auth-form'>
                    <div className='auth-form-header-text text-white text-center'>Microgenetics SmartControl</div>
                    <div className='text-white text-center mt-3'>
                        Log in with your SmartControl account, or use your work Microsoft account
                    </div>
                    <Form
                        onSubmit={handleSubmit as any}
                        validate={validate}
                        render={form =>
                            <form onSubmit={form.handleSubmit}>
                                <ContextSwitcherInput
                                    isOpen={switchingContext.isOpen}
                                    onClose={handleCloseContextSwitcher}
                                    onChange={_ => logInHandler(_, form.values as Credentials)}
                                    values={switchingContext.values}
                                />
                                <TextField testId='field-email' id='auth-email' name='email' placeholder='Email' autoComplete/>
                                <PasswordField testId='field-password' id='auth-password' name='password' placeholder='Password' />
                                <div className='d-flex justify-content-between align-items-center'>
                                    <Submit className='auth-form-submit w-100' disabled={submitDisabled(form)} testId='log-in'>Log in to SmartControl</Submit>
                                    <div className='form-control-plaintext text-end mt-2'>
                                        <Link className='btn btn-link btn-sm auth-form-link-text text-white' routeName={FORGOT_PASSWORD} testId='restore-password'>Restore password</Link>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between align-items-center text-white'>
                                    <div className='auth-form__horizontal-line'></div>
                                    <span className='mx-3'>or</span>
                                    <div className='auth-form__horizontal-line'></div>
                                </div>
                                <div className={classnames('form-control-plaintext text-center w-100', { 'no-permission': !ssoUrl })}>
                                    <a className={classnames({ 'link-disabled text-secondary': !ssoUrl })} href={ssoUrl} data-testid='log-in-with-sso'>
                                        <img src={logo}/>
                                    </a>
                                </div>
                                <div className='text-center text-white mt-3'>
                                    Sign in with Microsoft is only available for enterprise tier accounts. Refer to <a target='_blank' href='https://microgenetics.co.uk/smartcontrol/#pricing'>link</a> for more information
                                </div>
                            </form>
                        }
                    />
                </div>
                <SmartControlLinks />
            </div>
        </div>
    )
}

function useLogInHandler() {
    const logIn = useAction(a.logIn)

    return (membership: UserMembership, credentials: AvailableMembershipsRequest | Credentials) =>
        logIn({...credentials, membershipId: membership.id })
            .then(noop)
}

export default AuthForm
