import type AppState from '_/model/app-state'
import { commonReducer, handler, mergeAndReplaceEntityById, shallowUpdate } from '_/facade/reducer'
import * as actions from './actions'

const state: AppState['apiKeys'] = {
    list: [],
}

export default commonReducer(
    state,

    handler(actions.apiKeyListLoaded, (state, list) => shallowUpdate(state, { list })),

    handler(actions.apiKeyChanged, (state, { id, apiKey }) => {
        const items = mergeAndReplaceEntityById(state.list, apiKey, id)

        return shallowUpdate(state, { list: items })
    }),

    handler(actions.clearApiKeys, state => shallowUpdate(state, { list: [] })),

    handler(actions.apiKeyRemoved, (state, id) => shallowUpdate(state, { list: state.list.filter(_ => _.id !== id) }))
)
