import  * as contextLevel from '_/constants/context-level'
import { useSelector, useEffect, useState, useAction } from '_/facade/react'
import * as authActions from '_/features/auth/actions'
import type { UserNotificationSettings, UserMembership } from '_/model/auth/types'
import { compare } from '_/utils/string'

import * as actions from './actions'
import EmailNotificationFrequencyBlock from './email-notification-frequency'
import NotificationSetting from './notification-setting'

function BreachNotificationSettings() {
    const userNotificationSettings = useUserNotificationSettings()
        , memberships = useMemberships()
        , notificationSettingsWithName = userNotificationSettings && getNotificationSettings(userNotificationSettings, memberships)

    return (
        <div className='d-flex flex-wrap'>
            { userNotificationSettings &&
                <EmailNotificationFrequencyBlock
                    userId = {userNotificationSettings.id}
                    emailNotificationFrequency={userNotificationSettings.emailNotificationFrequency}
                />
            }
            {userNotificationSettings && notificationSettingsWithName?.map(membershipNotificationSetting =>
                <NotificationSetting
                    key={membershipNotificationSetting.membership.id}
                    userId = {userNotificationSettings.id}
                    notificationSettings={membershipNotificationSetting.notificationSettings}
                    membership={membershipNotificationSetting.membership}
                />
            )}
        </div>
    )
}

export default BreachNotificationSettings

function useUserNotificationSettings() {
    const [notificationSettings, setNotificationSettings] = useState<UserNotificationSettings>()
        , userId = useSelector(_ => _.auth.user?.id)
        , loadNotificationSettings = useAction(actions.loadNotificationSettings)

    useEffect(
        () => {
            if (!userId)
                return

            loadNotificationSettings(userId).then(setNotificationSettings)
        },
        [userId, setNotificationSettings, loadNotificationSettings]
    )

    return notificationSettings
}

function useMemberships() {
    const loadMemberships = useAction(authActions.loadMemberships)
        , memberships = useSelector(_ => _.auth.memberships)

    useEffect(
        () => {
            loadMemberships()
        },
        [loadMemberships]
    )

    return memberships
}

function getNotificationSettings(userNotificationSettings: UserNotificationSettings, memberships: UserMembership[]) {
    const siteMemberships = memberships
            .filter(_ => !_.isSuspended && _.context.level === contextLevel.SITE)
        , uniqueSiteMemberships = [...new Set(siteMemberships.map(_ => _.description))]
            .map(_ => siteMemberships.find(it => it.description === _)!)
        , notificationSettings = uniqueSiteMemberships
            .slice()
            .sort((one, two) => compare(one.description, two.description))
            .map(membership => {
                const notificationSettings = userNotificationSettings.contextNotificationSettings
                    .find(it => it.contextId === membership.contextId)?.notificationSettings
                    || []

                return {
                    membership,
                    notificationSettings,
                }
            })

    return notificationSettings
}
