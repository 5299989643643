import { useSelector, useAction, useCallback } from '_/facade/react'

import type SampleListQuery from '_/model/sample/list-query'

import ExportModal from '_/features/export/export-modal'

import * as routes from '_/constants/routes'
import * as routerActions from '_/features/routing/actions'

interface Props {
    totalCount: number
    nullifiedIncluded: boolean
    query: SampleListQuery
}

function SampleExportModal(props: Props) {
    const navigateToList = useNavigateToList()
        , contextDescription = useSelector(_ => _.auth.user?.membership.description)

    return (
        <ExportModal
            totalCount={props.totalCount}
            query={props.query}
            entityType='viable sample'
            modalText={`You're about to export data for ${props.totalCount} viable samples ${props.nullifiedIncluded ? '(including nullified)' : ''} from ${contextDescription}.`}
            navigateToList={navigateToList}
        />
    )
}

export default SampleExportModal

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SAMPLES), [navigateTo])

    return navigateToList
}
