// fixes https://trello.com/c/uffI5Uj6/2727-on-analysis-and-samples-filters-field-name-is-not-valid-number-warning-is-not-shown-for-some-not-valid-values

// fixes something like 2+4
window.addEventListener('focusout', event => {
    const target = event.target
    if (!isNumberInput(target))
        return

    if (!target.checkValidity())
        target.value = ''
})

// prevents entering numbers in scientific notation
window.addEventListener('keydown', event => {
    const target = event.target
    if (!isNumberInput(target))
        return

    if (event.key === 'e' || event.key === 'E')
        event.preventDefault()
})

function isNumberInput(target: any): target is HTMLInputElement {
    if (!(target instanceof HTMLInputElement))
        return false

    return target.type === 'number'
}
