import { useAction, useSelector } from '_/facade/react'
import { actions as routerActions  } from 'redux-router5'

import { Modal, ModalBody, ModalFooter } from '_/components/modal'
import Button from '_/components/button'

import * as warningActions from '_/features/unsaved-changes/actions'
import * as reasonActions from '_/features/critical-change-reason/actions'
import type { RouteName } from '_/constants/routes'

function DiscardChangesConfirmation() {
    const targetState = useSelector(_ => _.unsavedChange.targetState)
        , discardChanges = useAction(warningActions.discardChanges)
        , showWarning = useAction(warningActions.showWarning)
        , closeReason = useAction(reasonActions.reasonModalClosed)
        , navigate = useNavigateTo()

    function handleDiscardChanges() {
        discardChanges()
        closeReason()
        if (targetState)
            navigate(targetState.name,  targetState.params)
    }

    function handleClose() {
       showWarning({showWarning: false})
    }

    return (
        <Modal isOpen onClose={handleClose}>
            <ModalBody>
                You have unsaved changes on this page. Are you sure you want to leave?
            </ModalBody>
            <ModalFooter>
                <Button
                    onClick={handleDiscardChanges}
                    testId='discard-changes'
                >
                    Discard changes
                </Button>
                <Button className='btn-primary' onClick={handleClose} testId='stay-on-page'>
                    Stay on page
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default DiscardChangesConfirmation

function useNavigateTo() {
    const navigateToAction = useAction(routerActions.navigateTo)

    return (routeName: RouteName, params: any) => navigateToAction(routeName, params)
}
