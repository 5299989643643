import { useState, classnames } from '_/facade/react'

import Button, { Close } from '_/components/button'
import type { Text } from '_/model/text/text'
import FormattedText from '../text/formatted-text'

interface Props {
    header: string | Text
    children: React.ReactNode
    dismiss: () => void
}

export default function InsightsWidget(props: Props) {
    const [like, setLike] = useState<boolean | undefined>(undefined)

    return (
        <div className='px-3'>
            <div className='d-flex pt-1'>
                <Close onClick={props.dismiss} className='ms-auto' />
            </div>
            <div className='pb-3'><FormattedText text={props.header} /></div>
            {props.children}
            <div className='d-flex justify-content-between my-3'>
                <div className='insights-widget-like--color d-flex col-6 ps-0'>
                    This feature is not intended to replace your own trend detection
                </div>
                <div className='insights-widget-like--color d-flex justify-content-end align-self-end col-6 px-0'>
                    Was this useful?
                    <Button className='btn-link py-0' onClick={() => setLike(like ? undefined : true)}>
                        <i
                            className={classnames(
                                'material-icons md-18 cursor-pointer insights-widget-like--color',
                                { active: like },
                            )}
                        >
                            thumb_up
                        </i>
                    </Button>
                    <Button className='btn-link py-0' onClick={() => setLike(like === false ? undefined : false)}>
                        <i
                            className={classnames(
                                'material-icons md-18 cursor-pointer insights-widget-like--color',
                                { active: like === false },
                            )}
                        >
                            thumb_down
                        </i>
                    </Button>
                </div>
            </div>
        </div>
    )
}
