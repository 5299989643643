const ANY = 'Any'
    , ANY_ID = '5b063a66-bff2-4d06-b018-17fc7171b339'
    , NOT_RECORDED_ID = '5b063a66-bff2-4d06-b018-17fc7171b340'
    , ALL = 'All'
    , NOT_RECORDED_SHORT = 'N/R'
    , NOT_AVAILABLE = 'N/A'
    , NOT_RECORDED_NAME = 'Not recorded'
    , NO_OPERATOR = 'No operator'
    , UNKNOWN_LOCATION = 'Unknown location'
    , OTHER_VIABLE_SAMPLES = 'Other viable samples'
    , OTHER_SAMPLES = 'Other samples'
    , UNCLASSIFIED = 'Unclassified'
    , PLUS = '+'
    , VERTICAL_BAR = '|'
    , NO_SESSION_NAME = 'No session'
    , NOT_YET_IDENTIFIED = 'Not yet identified'
    , INCONCLUSIVE = 'Inconclusive'
    , NOT_FILTERED = 'Not filtered'
    , NO_ID_REQUIRED = 'No id required'
    , CORRECTIONAL_MPN_VALUE = 'Correctional MPN value'
    , NOT_RECORDED = {
        id: NOT_RECORDED_ID,
        name: NOT_RECORDED_NAME,
    }

    , SYSTEM_WORDS = [
        ANY,
        ALL,
        NO_SESSION_NAME,
        NOT_AVAILABLE,
        NOT_RECORDED_SHORT,
        NOT_RECORDED_NAME,
        NO_OPERATOR,
        UNKNOWN_LOCATION,
        OTHER_SAMPLES,
        OTHER_VIABLE_SAMPLES,
        UNCLASSIFIED,
        NOT_YET_IDENTIFIED,
        NOT_FILTERED,
        INCONCLUSIVE,
        NO_ID_REQUIRED,
        CORRECTIONAL_MPN_VALUE,
    ]

    , GENERATED = '(generated)'
    , INACTIVE = '(Inactive)'
    , AD_HOC = '(ad-hoc)'
    , EMPTY = '(empty)'
    , OBJECTIONABLE = '(objectionable)'

    , SYSTEM_SUFFIXES = [
        GENERATED,
        INACTIVE,
        AD_HOC,
        EMPTY,
        PLUS,
        VERTICAL_BAR,
        OBJECTIONABLE,
    ]

export {
    ANY,
    ANY_ID,
    NOT_RECORDED_ID,
    ALL,
    NOT_AVAILABLE,
    NOT_RECORDED_SHORT,
    NOT_RECORDED_NAME,
    NOT_RECORDED,
    NO_SESSION_NAME,
    NO_OPERATOR,
    SYSTEM_WORDS,
    SYSTEM_SUFFIXES,
    NOT_YET_IDENTIFIED,
    NOT_FILTERED,
    UNKNOWN_LOCATION,
}
