import { useField } from 'react-final-form'

import { dropFields } from '_/utils/object'
import * as ms from '_/model/predefined-lists/action-alert-limit/monitoring-state'

interface Props {
    id: string
    monitoringState: ms.MonitoringState
    className?: string
    disabled?: boolean
    inputRef?: React.RefObject<HTMLInputElement>
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

function MonitoringStateField(props: Props) {
    const field = useField<number[]>('monitoringState')
        , inputProps = field.input
        , checked = props.monitoringState === ms.AT_REST
        , forwardedProps = dropFields(inputProps, 'type', 'value', 'checked', 'onChange')

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const checked = event.target.checked

        inputProps.onChange(checked ? ms.AT_REST : ms.IN_OPERATION)
    }

    return (
        <div className={`form-check d-print-none ${props.className || ''}`}>
            <input
                id={props.id}
                type='checkbox'
                className='form-check-input'
                checked={checked}
                onChange={handleChange}
                {...forwardedProps}
                disabled={props.disabled}
                data-testid='field-at-rest'
                ref={props.inputRef}
                onKeyDown={props.onKeyDown}
                tabIndex={-1}
            />
            <label className='form-check-label' htmlFor={props.id}>
                At rest
            </label>
        </div>
    )
}

export default MonitoringStateField
