import { useState, useLayoutEffect } from '_/facade/react'
import { systemFontStack } from '_/model/analysis/system-font-stack'

interface Props {
    className?: string
    count?: number
    outsideOfScope?: boolean
}

function FloorPlanLocationIcon(props: Props) {
    const [capHeight, textRef, textBaselineRef, textCentralRef] = useMetrics(props.count)

    const fontSize = 13
        , padding = capHeight * 0.39 * (props.count ? 2 : 1)
        , size = capHeight + padding * 2
        , dimension = size / 2
        // Math.floor is used because capHeight tends to be a pixel higher than actual size of a capital letter
        // so vertical centring is a bit better that way
        , textY = capHeight + Math.floor(padding)
        , locationIconColor = props.count ? '#30417A' : '#808080'
        , rectOffset = `calc(50% - ${dimension / 2}px)`

    return (
        <>
            <svg
                className={props.className}
                width={size} height={size} viewBox={`0 0 ${size} ${size}`}
                version='1.1' xmlns='http://www.w3.org/2000/svg'
                fill='white' stroke='none' strokeWidth='0'
                style={{
                    verticalAlign: -padding,
                }}
            >
                <g fill='none' stroke='none'>
                    <text ref={textBaselineRef} dominantBaseline='baseline'>0</text>

                    <text ref={textCentralRef} dominantBaseline='central'>0</text>
                </g>

                {props.outsideOfScope
                    ? <rect width={dimension} height={dimension} rx={dimension * 0.2} x={rectOffset} y={rectOffset} fill={locationIconColor} stroke='none' />
                    : <circle cx={dimension} cy={dimension} r={dimension} fill={locationIconColor} />
                }

                {props.count &&
                    <text ref={textRef} x={dimension} y={textY} fontFamily={systemFontStack} fontSize={fontSize} fill='white' textAnchor='middle' dominantBaseline='baseline'>
                        {props.count}
                    </text>
                }
            </svg>
        </>
    )
}

export default FloorPlanLocationIcon

function useMetrics(count: number | undefined) {
    const [textElem, textElemRef] = useState<SVGTextElement | null>(null)

    const [textBaseline, textBaselineRef] = useState<SVGTextElement | null>(null)
        , [textCentral, textCentralRef] = useState<SVGTextElement | null>(null)
        , [capHeight, setCapHeight] = useState<number>(0)

    useLayoutEffect(
        () => {
            if (textCentral && textBaseline) {
                const centralY = textCentral.getBBox().y
                    , baselineY = textBaseline.getBBox().y
                    , nextCapHeight = (centralY - baselineY) * 2

                setCapHeight(nextCapHeight)
            }
        },
        [textElem, textBaseline, textCentral, count]
    )

    return [capHeight, textElemRef, textBaselineRef, textCentralRef] as const
}
