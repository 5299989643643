import { classnames, useSelector, useEffect, useAction, useState, useMemo, useReducer, useCallback } from '_/facade/react'
import { diffObject, dropFields, pickFields } from '_/utils/object'
import { useAppliedFilterRef, useSyncRef, useChanged } from '_/hooks/shared-hooks'

import { ITEMS_PER_PAGE } from '_/constants'
import Button from '_/components/button'
import { useContextSwitchObserver } from '_/components/context-observer'
import { LinkButton } from '_/components/link'
import Select from '_/components/downshift-select'
import SamplesFilter from '../filter/search-filter'
import * as fieldIndex from '_/constants/custom-field-index'

import type { PredefinedLists } from '_/model/app-state'
import type PaginationState from '_/model/pagination-state'
import type { SampleSearchStatistics } from '_/model/sample/sample-search-result'
import type SampleSearchResult from '_/model/sample/sample-search-result'
import type SortState from '_/model/sort-state'
import type { FilterFieldValue } from '_/model/sample/search'
import type SampleSearchFields from '_/model/sample/search'
import type Sample from '_/model/sample/sample'
import type SampleListQuery from '_/model/sample/list-query'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type ColumnEdit from '_/model/sample/list/column-edit'

import * as actions from '../actions'
import * as contextActions from '_/features/contexts/actions'
import * as confirmationActions from '_/features/confirmation/actions'
import * as pa from '_/features/predefined-lists/redux/actions'
import * as ph from '_/features/predefined-lists/helpers'

import { SAMPLES_BOOKING, SAMPLES_COLUMNS, SAMPLES_EXPORT } from '_/constants/routes'

import BULK_OPERATION from '_/constants/bulk-operation'
import * as bulkOperations from '_/constants/bulk-operation'

import SamplesStatusTotal from './samples-status-total'
import SampleFlagInput from './sample-flag-input'
import Scanner from '../scanner/scanner'
import { bulkOperationConfirmationMessage } from './bulk-operation-helpers'
import * as sampleStatuses from '_/constants/sample-status'
import * as sampleFlags from '_/constants/sample-flag'
import ExportModal from './export-modal'
import { NOT_USED_FIELDS, sortCustomFields } from '../filter/helpers'

import { memoize } from '_/utils/function'
import * as fh from '_/model/filters/helpers'
import { getFieldValue } from '../helpers'
import { normalizeFields } from '_/features/analysis/ui/helpers'
import ImportButton from './import-btn'

import ColumnsEditModal from './columns-edit'
import SampleTable from './table'

function SampleList() {
    const [preloadToken, forcePreload] = useReducer(_ => _ + 1, 0)
        , [loaded, fieldsForFilter, predefinedLists] = usePreload(preloadToken)

    if (!loaded)
        return null

    return (
        <SampleListInternal
            fieldsForFilter={fieldsForFilter}
            predefinedLists={predefinedLists}
            forcePreload={forcePreload}
            usePersistedFilter={preloadToken === 0}
        />
    )
}

interface Props {
    fieldsForFilter: CustomField[]
    predefinedLists: PredefinedLists
    forcePreload: () => void
    usePersistedFilter: boolean
}

function SampleListInternal(props: Props) {
    const [isSpinnerShown, showSpinner] = useSpinner()
        , [listColumns, showColumnEdit] = useSampleListColumns(showSpinner)
        , permissions = useSelector(_ => _.auth.permissions)
        , { predefinedLists, fieldsForFilter } = props
        , defaultSearchFields = useDefaultSearchFields(fieldsForFilter)
        , [
            sampleSearchFields, setSampleSearchFields,
            pagination, setPagination,
            sorting, setSorting,
        ] = useFilters(defaultSearchFields, fieldsForFilter, props.usePersistedFilter)
        , [showFilter, setShowFilter] = useFilter(sampleSearchFields)
        , [reloadToken, handleReload] = useReducer(_ => _ + 1, 0)
        , [isPrintMode, setIsPrintMode] = useState(false)
        , [samples, listStatistics] = useSamples(
            sampleSearchFields,
            pagination,
            sorting,
            reloadToken,
            isPrintMode,
            showSpinner,
        )
        , [exportMode, setExportMode] = useState(false)
        , route = useSelector(_ => _.router.route!)

    useEffect(() => {
        const mode = route.name === SAMPLES_EXPORT
        if(mode !== exportMode)
            setExportMode(mode)
    }, [exportMode, route.name])

    function handleSearchOnlyByBarcode(sampleSearchFields: SampleSearchFields) {
        handleSampleSearch({...defaultSearchFields, ...sampleSearchFields})
        setShowFilter(true)
    }

    useBarcodeSearchObserver(handleSearchOnlyByBarcode)

    function handleSampleSearch(filter: SampleSearchFields) {
        const pagination = { start: 0, count: ITEMS_PER_PAGE }

        setPagination(pagination)
        setSampleSearchFields(filter)
    }

    function handleClearFilter() {
        const flag = sampleSearchFields.flag

        setSampleSearchFields({
            flag,
            statuses: calculateStatus(flag),
            includeCompromised: true,
        })
    }

    function handleChangeSampleFlag(flag?: number) {
        const statuses = calculateStatus(flag)

        handleSampleSearch({
            ...sampleSearchFields,
            flag,
            statuses,
        })
    }

    function bulkOperationHasNoPermissions(bulkOperation: {id: bulkOperations.BulkOperationId}) {
        switch (bulkOperation.id){
            case bulkOperations.VERIFY_CFU_COUNT:
                return !permissions.confirmCfuCount
            case bulkOperations.CONFIRM_BOOK_IN:
                return !permissions.confirmBookInForSample
            default:
                return false
        }
    }

    function renderFiltersButton() {
        return <Button
            className='btn-link mt-1'
            onClick={() => setShowFilter(_ => !_)}
            testId='sample-filters'
        >
            <i className='material-icons align-middle mb-1 me-2'>tune</i>
            <span>Filters</span>
        </Button>
    }

    const [bookInConfirmationEnabled,
            cfuCountVerificationEnabled,
            availableBulkOperations,
            bulkOperationId,
            handleChangeBulkOperation,
        ] = useBulkOperations()
        , [selectedItems, handleToggleSample, handleToggleAllSamples] = useSelectedSamples(samples.items)
        , handleBulkOperation = useBulkOperationHandler(bulkOperationId, selectedItems, handleReload)
        , disableBulkOperation = bulkOperationId === undefined || selectedItems.length === 0
        , bulkOperationsHasNoPermissions = !permissions.applyBulkOperations || availableBulkOperations.length > 0 && availableBulkOperations.every(bulkOperationHasNoPermissions)
        , bulkOperationsDisabled = !permissions.editSamples || availableBulkOperations.length === 0 || bulkOperationsHasNoPermissions
        , exportQuery = useExportQuery(sampleSearchFields, pagination, sorting)

    return (
        <div className={classnames('d-flex flex-column h-100 d-print-block', !isPrintMode && 'overflow-auto')}>
            {showColumnEdit && <ColumnsEditModal columns={listColumns} />}
            <Scanner />
            {exportMode &&
                <ExportModal
                    totalCount={listStatistics.totalCount}
                    nullifiedIncluded={listStatistics.totalPerStatus.some(_ => _.status === sampleStatuses.NULLIFIED)}
                    query={exportQuery}
                />
            }

            <div className='d-flex align-items-stretch flex-fill overflow-auto'>
                <div className='position-relative'>
                    <SamplesFilter
                        initialFilter={sampleSearchFields}
                        onSearch={handleSampleSearch}
                        onClearFilter={handleClearFilter}
                        showFilter={showFilter}
                        onSearchTemplateLoaded={setSampleSearchFields}
                        onClose={() => setShowFilter(false)}
                        fields={fieldsForFilter}
                    />
                </div>

                <div className='container-fluid d-flex align-items-stretch flex-fill'>
                    <div className='row justify-content-center align-items-stretch flex-fill d-print-block'>
                        {!sampleSearchFields.includeCompromised && sampleSearchFields.flag === sampleFlags.COMPROMISED
                            ? <div className='col-9 width-print-100'>
                                <nav className='navbar page-header mx-0'>
                                    {renderFiltersButton()}
                                </nav>
                                <div className='text-center mt-5'>
                                    <span className='align-middle'>Compromised samples are excluded from the filter. Do you want to </span>
                                    <Button
                                        onClick={() => handleSampleSearch({ ...sampleSearchFields, includeCompromised: true })}
                                        className='btn-link p-0 border-0'
                                    >
                                        include them?
                                    </Button>
                                </div>
                            </div>
                            : <div className='col-9 width-print-100 d-flex flex-column h-100'>
                                <SampleFlagInput
                                    onChange={handleChangeSampleFlag}
                                    value={sampleSearchFields.flag}
                                    bookInConfirmationEnabled={bookInConfirmationEnabled}
                                    cfuCountVerificationEnabled={cfuCountVerificationEnabled}
                                />
                                <nav className='navbar page-header justify-content-end flex-shrink-0 mx-0'>
                                    <div className='d-flex w-100 sample-list__status-total--height'>
                                        {renderFiltersButton()}
                                        <SamplesStatusTotal
                                            className='me-auto ms-60'
                                            totalPerStatus={listStatistics.totalPerStatus}
                                            totalPerFlag={listStatistics.totalPerFlag}
                                            totalCount={listStatistics.totalCount}
                                            itemsLimit={samples.itemsLimit}
                                            bookInConfirmationEnabled={bookInConfirmationEnabled}
                                            cfuCountVerificationEnabled={cfuCountVerificationEnabled}
                                            isPrintMode={isPrintMode}
                                            selectedSamplesCount={selectedItems.length}
                                        />
                                    </div>
                                    <LinkButton className='btn-link me-1' routeName={SAMPLES_COLUMNS}>
                                        Edit columns
                                    </LinkButton>
                                    <Button
                                        className={classnames('btn-link me-1', {
                                            disabled: !permissions.readSamples,
                                        })}
                                        onClick={() => setIsPrintMode(_ => !_)}
                                        hasNoPermissions={!permissions.readSamples}
                                        testId='print-mode'
                                    >
                                        {isPrintMode ? 'List mode' : 'Print mode'}
                                    </Button>
                                    {permissions.importSamples && <ImportButton resetList={props.forcePreload}/>}
                                    <LinkButton
                                        className={classnames('btn-link me-1 mt-1', {
                                            disabled: !permissions.exportSamples,
                                        })}
                                        routeName={SAMPLES_EXPORT}
                                        hasNoPermissions={!permissions.exportSamples}
                                        testId='samples-export'
                                    >
                                        <i className='material-icons align-middle md-18 mb-1 me-1'>get_app</i>
                                        <span>Export</span>
                                    </LinkButton>
                                    {!isPrintMode &&
                                        <>
                                            <div className='col-2 me-1'>
                                                <Select
                                                    id='bulk'
                                                    entities={availableBulkOperations}
                                                    calcName={_ => _.name}
                                                    calcId={_ => _.id}
                                                    calcHasNoPermissions={bulkOperationHasNoPermissions}
                                                    placeholder='Select a bulk operation'
                                                    disabled={bulkOperationsDisabled}
                                                    input={{value: bulkOperationId, onChange: handleChangeBulkOperation}}
                                                    hasNoPermissions={bulkOperationsHasNoPermissions}
                                                    className='form-control'
                                                    testId='field-bulk-operation'
                                                />
                                            </div>
                                            <Button
                                                className={classnames('btn-primary me-1 ', {
                                                    disabled: disableBulkOperation,
                                                })}
                                                disabled={disableBulkOperation}
                                                hasNoPermissions={bulkOperationsHasNoPermissions}
                                                onClick={handleBulkOperation}
                                                testId='apply-bulk-operation'
                                            >
                                                Apply a bulk operation
                                            </Button>
                                        </>
                                    }
                                    <LinkButton
                                        routeName={SAMPLES_BOOKING}
                                        className='btn-primary'
                                        hasNoPermissions={!permissions.bookSamples}
                                    >
                                        Book in a viable sample
                                    </LinkButton>
                                </nav>

                                <SampleTable
                                    list={samples.items}
                                    listStatistics={listStatistics}
                                    predefinedLists={predefinedLists}
                                    printMode={isPrintMode}
                                    showSpinner={isSpinnerShown}
                                    handleSortingChange={setSorting}
                                    pagination={pagination}
                                    handlePaginationChange={setPagination}
                                    selectedItems={selectedItems}
                                    handleSelectSample={handleToggleSample}
                                    handleSelectAll={handleToggleAllSamples}
                                    bulkOperationsDisabled={bulkOperationsDisabled}
                                    bulkOperationsHasNoPermissions={bulkOperationsHasNoPermissions}
                                    columns={listColumns.columns}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SampleList

function usePreload(preloadToken: number) {
    const [memFilterFields] = useState(() =>
            memoize(
                (fields: CustomField[]) =>
                    sortCustomFields(fields).filter(_ => !NOT_USED_FIELDS.some(index => index === _.index))
            )
        )

    const loadPredefinedLists = useAction(pa.loadPredefinedLists)
        , loadSampleSearchFilterList = useAction(actions.loadSampleSearchFilterList)
        , permissions = useSelector(_ => _.auth.permissions)
        , contextSwitch = useContextSwitchObserver()
        , predefinedLists = useSelector(_ => _.predefinedLists)

    const [loaded, setLoaded] = useState(false)
    useEffect(
        () => {
            if (!permissions.readPredefinedLists || !permissions.readSamples)
                return

            Promise.all([
                loadPredefinedLists(),
                loadSampleSearchFilterList(),
            ]).then(() => setLoaded(true))

            return () => setLoaded(false)
        },
        [preloadToken, contextSwitch, permissions, loadPredefinedLists, loadSampleSearchFilterList]
    )

    const fieldsForFilter = memFilterFields(predefinedLists.customFields)

    return [loaded, fieldsForFilter, predefinedLists] as const
}

function useFilters(defaultSearchFields: SampleSearchFields, fieldsForFilter: CustomField[], usePersistedFilter: boolean) {
    const route = useSelector(_ => _.router.route!)
        , latestFilters = useSelector(_ => _.filters.filters)

    function calcFilter(recover: (params: any) => any): SampleListQuery {
        if (!usePersistedFilter)
            return recover({})

        return fh.calcFilter(route, latestFilters.find(_ => _.name === 'samples')?.value, (params = {}) => {
            return recover(params)
        })
    }

    function getPagination(): PaginationState {
        const recoveredFilter = calcFilter(params => {
                return {
                    start: params.start ?? 0,
                    count: params.count ?? ITEMS_PER_PAGE,
                }
            })

        return pickFields(recoveredFilter, 'start', 'count')
    }

    function getSortState(): SortState {
        const recoveredFilter = calcFilter(params => {
                return {
                    sort: params.sort ?? 'createdAt:desc',
                }
            })

        return pickFields(recoveredFilter, 'sort')
    }

    function getSearchFields(): SampleSearchFields {
        const historyQuery = calcFilter(params => {
            return {
                flag: params.flag && parseInt(params.flag, 10),
                statuses: calculateStatus(params.flag && parseInt(params.flag, 10), params.statuses),
                dateToFilter: params.dateToFilter && parseInt(params.dateToFilter, 10),
                dateFrom: params.dateFrom,
                dateTo: params.dateTo,
                gradeIds: params.gradeIds,
                sampleInvestigationReferences: params.sampleInvestigationReferences,
                sampleBreachTypes: params.sampleBreachTypes,
                includeCompromised: params.includeCompromised === undefined ? true : params.includeCompromised,
                organismIds: params.organismIds,
                organismTypeIds: params.organismTypeIds,
                catalaseIds: params.catalaseIds,
                oxidaseIds: params.oxidaseIds,
                oxidationFermentationIds: params.oxidationFermentationIds,
                coagulaseIds: params.coagulaseIds,
                showOnlyObjectionable: params.showOnlyObjectionable === undefined ? false : params.showOnlyObjectionable,
                fields: params.fields,
                sampleInvestigationReferenceFlag: params.sampleInvestigationReferenceFlag,
                monitoringStates: params.monitoringStates,
                trendInvestigationReferences: params.trendInvestigationReferences,
                trendIds: params.trendIds,
            }
        })

        return {
            ...defaultSearchFields,
            ...dropFields(historyQuery, 'sort', 'start', 'count')
        }
    }

    const [sampleSearchFields, setSampleSearchFields] = useState(getSearchFields)
        , [pagination, setPagination] = useState(getPagination)
        , [sorting, setSorting] = useState(getSortState)
        , fieldsForFilterRef = useSyncRef(fieldsForFilter)
        , setNormalizedSampleSearchFields = useCallback(
            function setNormalizedSampleSearchFields(value: SampleSearchFields) {
                const normalizedValue = {
                    ...value,
                    fields: normalizeFields(value.fields || [], fieldsForFilterRef.current)
                }
                setSampleSearchFields(normalizedValue)
            },
            [fieldsForFilterRef]
        )

    return [
        sampleSearchFields, setNormalizedSampleSearchFields,
        pagination, setPagination,
        sorting, setSorting,
    ] as const
}

function useSamples(
    sampleSearchFields: SampleSearchFields,
    pagination: PaginationState,
    sorting: SortState,
    reloadToken: boolean,
    isPrintMode: boolean,
    showSpinner: ShowSpinner
) {
    const getSampleList = useAction(actions.getSampleList)
        , loadPrintSamples = useAction(actions.loadPrintSamples)
        , getSampleListStatistics = useAction(actions.getSampleListStatistics)
        , permissions = useSelector(_ => _.auth.permissions)
        , [samples, setSamples] = useState<SampleSearchResult>({
            items: [],
            itemsLimit: undefined,
        })
        , [stats, setStats] = useState<SampleSearchStatistics>({
            totalCount: 0,
            totalPerStatus: [],
            totalPerFlag: [],
        })
        , latestAppliedFilter = useAppliedFilterRef<SampleListQuery>('samples')

    useEffect(
        () => {
            if (!permissions.readSamples)
                return

            let disposed = false

            const query: SampleListQuery = { ...sampleSearchFields, ...pagination, ...sorting, includeChildOrganisms: true }
                , hideSpinner = showSpinner()

            const loadSamplesPromise = isPrintMode
                    ? loadPrintSamples(query).then(items => {
                        return {
                            items,
                            itemsLimit: items.length,
                        }
                    })
                    : getSampleList(query).then(_ => {
                        return {
                            items: _.items,
                            itemsLimit: undefined,
                        }
                    })

            Promise
                .all([loadSamplesPromise, getSampleListStatistics(query)])
                .then(([samples, listStatistics]) => {
                    if (disposed)
                        return

                    setSamples(samples)
                    setStats(listStatistics)
                    latestAppliedFilter.current = query
                })
                .finally(hideSpinner)

            return () => {
                disposed = true
            }
        },
        [
            sampleSearchFields,
            pagination,
            sorting,
            reloadToken,
            isPrintMode,
            permissions.readSamples,
            latestAppliedFilter,
            getSampleList,
            getSampleListStatistics,
            loadPrintSamples,
            showSpinner,
        ]
    )

    return [samples, stats] as const
}

function useSelectedSamples(samplesOnPage: Sample[]) {
    const [selectedItems, setSelectedItems] = useState<Sample[]>([])

    // invalidate selection when sample list is reloaded
    useEffect(
        () => {
            setSelectedItems([])
        },
        [samplesOnPage]
    )

    function handleToggleSample(sample: Sample) {
        setSelectedItems(
            s => s.some(_ => _.id === sample.id)
                ? s.filter(_ => _.id !== sample.id)
                : s.concat(sample)
        )
    }

    function handleToggleAllSamples() {
        setSelectedItems(
            s => samplesOnPage.length === s.length ? [] : samplesOnPage
        )
    }

    return [selectedItems, handleToggleSample, handleToggleAllSamples] as const
}

function useBulkOperations() {
    const contextId = useSelector(_ => _.auth.user && _.auth.user.membership.contextId)
        , context = useSelector(_ => _.contexts.contexts.find(c => c.id === contextId))
        , loadContext = useAction(contextActions.loadContext)
        , bookInConfirmationEnabled = !!(context?.bookInConfirmationEnabled)
        , cfuCountVerificationEnabled = useSelector(_ => ph.cfuCountVerificationEnabled(_.predefinedLists.grades))
        , availableBulkOperations = useMemo(
            () => {
                const result: BULK_OPERATION = []

                if (bookInConfirmationEnabled)
                    result.push(BULK_OPERATION.find(_ => _.id === bulkOperations.CONFIRM_BOOK_IN)!)

                if (cfuCountVerificationEnabled)
                    result.push(BULK_OPERATION.find(_ => _.id === bulkOperations.VERIFY_CFU_COUNT)!)

                return result
            },
            [bookInConfirmationEnabled, cfuCountVerificationEnabled]
        )

    useEffect(
        () => {
            if (contextId)
                loadContext(contextId)
        },
        [contextId, loadContext]
    )

    const [bulkOperation, setBulkOperation] = useState<bulkOperations.BulkOperationId>()
    function handleChangeBulkOperation(operationId: bulkOperations.BulkOperationId | undefined | '') {
        const bulkOperation = operationId === undefined || operationId === '' ? undefined : operationId
        setBulkOperation(bulkOperation)
    }

    return [
        bookInConfirmationEnabled,
        cfuCountVerificationEnabled,
        availableBulkOperations,
        bulkOperation,
        handleChangeBulkOperation,
    ] as const
}

function useBulkOperationHandler(bulkOperationId: bulkOperations.BulkOperationId | undefined, selectedSamples: Sample[], onReload: () => void) {
    const userId = useSelector(_ => _.auth.user?.id)
        , bulkOperation = useAction(actions.bulkOperation)
        , showConfirmationModal = useAction(confirmationActions.showConfirmationModal)
        , showWarningModal = useAction(confirmationActions.showWarningModal)


    function handleBulkOperation(operationId: bulkOperations.BulkOperationId, samplesWithActionByCurrentUserCount: number, samplesWithoutFlagCount: number, validSampleCount: number) {
        const selectedSamplesCount = selectedSamples.length
            , sampleId = operationId === bulkOperations.VERIFY_CFU_COUNT
                ? selectedSamples.filter(_ => _.lastReadBy !== userId).map(_ => _.id)
                : selectedSamples.map(_ => _.id)
            , message = bulkOperationConfirmationMessage(selectedSamplesCount, samplesWithActionByCurrentUserCount, samplesWithoutFlagCount, validSampleCount, operationId)

        if (validSampleCount === 0) {
            showWarningModal(message)
            return
        }

        showConfirmationModal(message)
            .then(() => bulkOperation({ operationId, sampleId }))
            .then(() => onReload())
    }

    function handleConfirmBookIn() {
        const samplesWithoutFlagCount = selectedSamples.filter(_ => _.bookInConfirmed || _.nullified).length
            , samplesBookedInByCurrentUser = selectedSamples.filter(_ => _.createdByUserId === userId).length
            , validSampleCount = selectedSamples.filter(_ => !_.bookInConfirmed && !_.nullified && _.createdByUserId !== userId).length

        handleBulkOperation(bulkOperations.CONFIRM_BOOK_IN, samplesBookedInByCurrentUser, samplesWithoutFlagCount, validSampleCount)
    }

    function handleVerifyCFUCount() {
        const samplesWithoutFlag = selectedSamples.filter(_ => !_.awaitingCfuConfirmation || _.nullified).length
            , lastReadByCurrentUserCount = selectedSamples.filter(_ => _.lastReadBy === userId).length
            , validSampleCount = selectedSamples.filter(_ => _.awaitingCfuConfirmation && !_.nullified && _.lastReadBy !== userId).length

        handleBulkOperation(bulkOperations.VERIFY_CFU_COUNT, lastReadByCurrentUserCount, samplesWithoutFlag, validSampleCount)
    }

    return bulkOperationId === bulkOperations.CONFIRM_BOOK_IN ? handleConfirmBookIn : handleVerifyCFUCount
}

function useSampleListColumns(showSpinner: ShowSpinner) {
    const [listColumns, setListColumns] = useState<ColumnEdit>({ columns: [] })
        , showColumnEdit = useSelector(_ => _.router.route!.name === SAMPLES_COLUMNS)
        , canReadSamples = useSelector(_ => _.auth.permissions.readSamples)
        , contextSwitch = useContextSwitchObserver()
        , loadListColumns = useAction(actions.loadListColumns)

    useEffect(
        () => {
            if (!canReadSamples)
                return

            const hideSpinner = showSpinner()

            loadListColumns()
                .then(setListColumns)
                .finally(hideSpinner)
        },
        [showColumnEdit, contextSwitch, canReadSamples, showSpinner, loadListColumns]
    )

    return [listColumns, showColumnEdit] as const
}

function useExportQuery(filter: SampleSearchFields, pagination: PaginationState, sorting: SortState) {
    return {
        ...pagination,
        ...sorting,
        ...filter
    }
}

function useDefaultSearchFields(fieldsForFilter: CustomField[]) {
    const defaultSearchFields = useMemo<SampleSearchFields>(
        () => {
            return {
                includeCompromised: true,
                fields: fieldsForFilter.map(_ => ({ index: _.index, value: undefined, notRecorded: false })),
                statuses: calculateStatus(),
            }
        },
        [fieldsForFilter]
    )

    return defaultSearchFields
}

function useFilter(sampleSearchFields: SampleSearchFields) {
    const [showFilter, setShowFilter] = useState(() => !isEmpty(sampleSearchFields))

    function isEmpty(searchFields: SampleSearchFields): boolean {
        return (searchFields.fields || []).every(_ => _.value === undefined)
            && searchFields.dateFrom === undefined
            && searchFields.dateTo === undefined
            && searchFields.dateToFilter === undefined
            && searchFields.gradeIds === undefined
            && searchFields.sampleInvestigationReferences === undefined
            && searchFields.sampleBreachTypes === undefined
            && searchFields.organismIds === undefined
            && searchFields.organismTypeIds === undefined
            && searchFields.catalaseIds === undefined
            && searchFields.oxidaseIds === undefined
            && searchFields.oxidationFermentationIds === undefined
            && searchFields.coagulaseIds === undefined
            && searchFields.showOnlyObjectionable === false
            && searchFields.includeCompromised === true
            && !diffObject(searchFields.statuses, calculateStatus())
            && searchFields.trendInvestigationReferences === undefined
            && searchFields.trendIds === undefined
    }

    return [showFilter, setShowFilter] as const
}

function useBarcodeSearchObserver(onSearchByBarcode: (searchFields: SampleSearchFields) => void) {
    const filter = useSelector(_ => _.router.route?.params.filter)
        , filterChanged = useChanged(filter)
        , handleNotify = useSyncRef(onSearchByBarcode)

    useEffect(
        () => {
            if (!(filterChanged && filter))
                return

            const barcode = getFieldValue(filter.fields, fieldIndex.BARCODE)
                , statuses = filter.statuses
                , fields: FilterFieldValue[] = [{ index: fieldIndex.BARCODE, value: barcode }]

            handleNotify.current({ fields, statuses })
        },
        [filterChanged, filter, handleNotify]
    )
}

interface HideSpinner {
    (): void
}
interface ShowSpinner {
    (): HideSpinner
}
function useSpinner(): [boolean, ShowSpinner] {
    const [requestCount, setRequestCount] = useState(0)
        , [hideSpinner] = useState(() => () => setRequestCount(_ => _ - 1))
        , [showSpinner] = useState(() =>
            () => {
                setRequestCount(_ => _ + 1)
                return hideSpinner
            }
        )

    return [requestCount > 0, showSpinner]
}

function calculateStatus(flag?: number, status?: string[]) {
    if (flag !== undefined) {
        const selectedFlag = sampleFlags.default.find(_ => _.id === flag)
        return selectedFlag && selectedFlag.availableForStatus
    }

    if (status !== undefined)
        return status.map(_ => parseInt(_, 10))

    return sampleStatuses.DEFAULT_STATUS_IDS
}
