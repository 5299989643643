import type { FieldState } from 'final-form'

import { isDefaultCustomField } from '_/model/predefined-lists/custom-field/custom-field'

import type { AnalysisFilter, ThresholdLine } from '_/model/analysis/filter/types'
import AGGREGATION_PERIOD from '_/model/analysis/aggregation-period'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { PredefinedLists } from '_/model/app-state'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type Permissions from '_/model/permissions/type'

import { ANY, ANY_ID } from '_/constants/system-words'
import * as fieldIndex from '_/constants/custom-field-index'
import * as fieldTypes from '_/constants/custom-field-column-type'
import DEFAULT_DATE_RANGES, { CUSTOM, DATE_RANGES, YEAR } from '_/constants/date-ranges'
import type { ChartType} from '_/model/analysis/chart-type'
import CHART_TYPE, { ORGANISM_TYPE_BREAKDOWN, PARTICLE_COUNTS, STACKED_SAMPLE_NAME } from '_/model/analysis/chart-type'
import MONITORING_STATE from '_/model/predefined-lists/action-alert-limit/monitoring-state'
import MultiSelectWrapper from '../ui/multi-select-wrapper'

import { formatEntity } from '_/utils/format/common'

import MultipleOrganismSearch from '_/features/predefined-lists/organism-identification/multiple-organism-search'
import MultipleSearchByFieldValue from '_/features/predefined-lists/custom-fields/custom-field-search'
import { LocalDateField, FilterMultiSelectField, SelectField, CheckboxField, NumericRangeField } from '_/components/form'
import RadioGroupField from '_/components/form/radio-group-field'

import { getEntitiesForFilter } from '_/model/predefined-lists/custom-field/custom-field'
import { isFloorPlanChart, getAllAvailableFloorPlanLocations, isChartTypeWithSubCharts, isLinesMarkersChart } from '../ui/helpers'

import * as h from './helpers'
import { formatFieldLabel } from '_/features/samples/helpers'
import { PARTICLE_ANALYSIS_STATE } from '_/model/predefined-lists/action-alert-limit/non-viable-limit-type'
import BatchNumberField from '_/features/samples/shared/batch-number-field'
import Button, { Close } from '_/components/button'
import { noop } from '_/utils/function'

interface Props {
    values: AnalysisFilter
    showCustomDateRangeInputs: boolean
    showAggregationPeriod: boolean
    excludeCustomDateRange?: boolean | undefined
    predefinedLists: PredefinedLists
    fields: CustomField[]
    chartType: ChartType | undefined
    permissions: Permissions
    getFieldState: (_: keyof AnalysisFilter) => FieldState<any> | undefined
    showModal: () => void
    onRemoveThresholdLine: (_: number) => void
    thresholdLines: ThresholdLine[]
}

function Filter(props: Props) {
    function buildField(customField: CustomField, showSeriesPicker: boolean, seriesDisableMessage: string | undefined ): React.ReactNode {
        const fieldId = `${'field-' + customField.index}`
            , valuePos = props.values.fields?.findIndex(_ => _.index === customField.index)
            , namePrefix = `fields[${valuePos}]`
            , valueName = `${namePrefix}.value`
            , notRecordedName = `${namePrefix}.notRecorded`
            , notRecordedState = props.getFieldState(notRecordedName as any)
            , isNotRecorded = !!(notRecordedState && notRecordedState.value)
            , label = customField.fieldName
            , seriesChecked = props.values.seriesFieldIndex === customField.index

        if (valuePos === -1)
            return null

        if (customField.index === fieldIndex.EXPOSURE_END_DATE)
            return null

        if (props.chartType === PARTICLE_COUNTS && customField.index === fieldIndex.SAMPLE_TYPE_ID)
            return null

        if (customField.index === fieldIndex.EXPOSURE_LOCATION_ID) {
            let entities = (getEntitiesForFilter(customField.index, props.predefinedLists)) as ListExposureLocation[]
            if (isFloorPlanChart(props.chartType)) {
                const selectedLocations = props.values.fields?.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ID)?.value ?? []
                entities = getAllAvailableFloorPlanLocations(entities, selectedLocations, props.predefinedLists.floorPlans.plans)
            }

            return <div key={namePrefix}>
                <MultiSelectWrapper
                    onSeriesSelect={_ => _.input.onChange(!seriesChecked ? customField.index : undefined)}
                    checked={seriesChecked}
                    showSeriesPicker={showSeriesPicker}
                    testId={`series-${customField.index}`}
                    disableMessage={seriesDisableMessage}
                    render={() =>
                        <FilterMultiSelectField
                            id={fieldId}
                            name={valueName}
                            entities={entities}
                            calcId={_ => _.id}
                            calcName={_ => formatEntity(_, _ => _.pathName)}
                            autocomplete
                            fillParentWidth
                            testId={`field-${customField.index}`}
                        >
                            {label}
                        </FilterMultiSelectField>
                    }
                />
            </div>
        }

        if (customField.index === fieldIndex.OPERATORS_IDS) {
            const anyOption = !seriesChecked ? { id: ANY_ID, name: ANY, isActive: true } : undefined

            return (
                <div key={namePrefix}>
                    <MultiSelectWrapper
                        onSeriesSelect={_ => _.input.onChange(!seriesChecked ? customField.index : undefined)}
                        checked={seriesChecked}
                        showSeriesPicker={showSeriesPicker}
                        testId={`series-${customField.index}`}
                        disableMessage={seriesDisableMessage}
                        render={() =>
                            <FilterMultiSelectField
                                id={fieldId}
                                name={valueName}
                                entities={getEntitiesForFilter(customField.index, props.predefinedLists)}
                                calcId={_ => _.id}
                                calcName={formatEntity}
                                autocomplete
                                fillParentWidth
                                anyOption={anyOption}
                                testId={`field-${customField.index}`}
                            >
                                {label}
                            </FilterMultiSelectField>
                        }
                    />
                </div>
            )
        }

        if (customField.index === fieldIndex.BATCH_NUMBER) {
            return (
                <div key={namePrefix}>
                    <MultiSelectWrapper
                        onSeriesSelect={_ => _.input.onChange(!seriesChecked ? customField.index : undefined)}
                        checked={seriesChecked}
                        showSeriesPicker={showSeriesPicker}
                        testId={`series-${customField.index}`}
                        disableMessage={seriesDisableMessage}
                        render={() =>
                            <BatchNumberField
                                id={fieldId}
                                name={valueName}
                                className='flex-fill'
                                editing={false}
                                hasLabel
                                isViable={props.chartType !== PARTICLE_COUNTS}
                            />
                        }
                    />
                </div>
            )
        }

        switch (customField.fieldType) {
            case fieldTypes.SELECTION: {
                const disableSampleName = customField.index === fieldIndex.SAMPLE_TYPE_ID
                        && props.values.chartType === STACKED_SAMPLE_NAME

                return <div key={namePrefix}>
                    <MultiSelectWrapper
                        onSeriesSelect={_ => _.input.onChange(!seriesChecked ? customField.index : undefined)}
                        checked={seriesChecked}
                        showSeriesPicker={showSeriesPicker}
                        testId={`series-${customField.index}`}
                        disableMessage={seriesDisableMessage}
                        render={() =>
                            <FilterMultiSelectField
                                id={fieldId}
                                name={valueName}
                                entities={getEntitiesForFilter(customField.index, props.predefinedLists)}
                                calcId={_ => _.id}
                                calcName={formatEntity}
                                autocomplete
                                fillParentWidth
                                disabled={disableSampleName}
                                testId={`field-${customField.index}`}
                            >
                                {label}
                            </FilterMultiSelectField>
                        }
                    />
                </div>
            }
            case fieldTypes.NUMBER: {
                return <div key={namePrefix}>
                    <NumericRangeField
                        id={fieldId}
                        name={valueName}
                        label={formatFieldLabel(customField)}
                        disabled={isNotRecorded}
                        className='mb-0'
                        testId={`field-${customField.index}`}
                    />
                    <CheckboxField
                        id={notRecordedName}
                        name={notRecordedName}
                        className='mx-1 mb-3'
                    >
                        Not recorded
                    </CheckboxField>
                </div>
            }
            case fieldTypes.TEXT: {
                return <div key={namePrefix}>
                    <MultiSelectWrapper
                        onSeriesSelect={_ => _.input.onChange(!seriesChecked ? customField.index : undefined)}
                        checked={seriesChecked}
                        showSeriesPicker={showSeriesPicker}
                        testId={`series-${customField.index}`}
                        disableMessage={seriesDisableMessage}
                        render={() =>
                            <MultipleSearchByFieldValue
                                id={fieldId}
                                name={valueName}
                                className='flex-fill'
                                customField={customField}
                                testId={`field-${customField.index}`}
                                isNonViable={props.chartType === PARTICLE_COUNTS}
                            />
                        }
                    />
                </div>
            }
            default: {
                return null
            }
        }
    }

    const chartTypeEntities = props.permissions.useFloorPlansCharts
            ? CHART_TYPE
            : CHART_TYPE.filter(_ => !isFloorPlanChart(_.id))
        , isOrganismTypeGraph = props.values.chartType === ORGANISM_TYPE_BREAKDOWN
        , isTotalSamplesBarAvailable = h.totalSamplesBarAvailable(props.values.chartType)
        , isSeriesPickerAvailable = isLinesMarkersChart(props.values.chartType)
        , dateRanges = props.values.chartType === PARTICLE_COUNTS ? DATE_RANGES.filter(_ => _.id !== YEAR) : DEFAULT_DATE_RANGES
        , seriesDisableMessage = props.values.chartType === PARTICLE_COUNTS && h.allParticleStatesAreSelected(props.values.particleStates)
            ? 'Not allowed unless single option for Particle size is selected'
            : undefined

    return (
        <div className='px-3'>
            <p className='fw-bold mt-2'>Graph setup</p>
            <SelectField
                name='chartType'
                id='chartType'
                entities={chartTypeEntities}
                calcId={_ => _.id}
                calcName={_ => _.name}
                placeholder='Select'
                testId='chart-type'
            >
                Graph type
            </SelectField>
            {isChartTypeWithSubCharts(props.values.chartType) &&
                <RadioGroupField
                    id='radio'
                    name='subChartType'
                    entities={h.getSubChartTypes(props.values.chartType)}
                    calcValue={_ => _.id}
                    calcLabel={_ => _.name}
                    testId='sub-chart-type'
                />
            }
            {(props.values.chartType === STACKED_SAMPLE_NAME || props.values.chartType === ORGANISM_TYPE_BREAKDOWN) &&
                <CheckboxField id='include-contamination-rates' name='includeContaminationRates'>
                    Show contamination rates
                </CheckboxField>
            }
            {props.values.chartType === PARTICLE_COUNTS &&
                <div className='alert alert-warning'>
                    Warning: data points without a time recorded will be plotted at 00:00
                </div>
            }
            <SelectField
                name='exposureDateRange'
                id='exposureDateRange'
                entities={props.excludeCustomDateRange ? dateRanges.filter(_ => _.id !== CUSTOM) : dateRanges}
                calcId={_ => _.id}
                calcName={_ => _.name}
                placeholder='Select'
                testId='exposure-date-range'
            >
                Exposure date range
            </SelectField>
            {props.showCustomDateRangeInputs && !props.excludeCustomDateRange &&
                <div>
                    <LocalDateField id='exposureStartDateFrom' name='exposureStartDateFrom' testId='field-from-date'>From exposure date</LocalDateField>
                    <LocalDateField id='exposureStartDateTo' name='exposureStartDateTo' useDayEndTime testId='field-to-date'>To exposure date</LocalDateField>
                </div>
            }
            {props.showAggregationPeriod &&
                <SelectField
                    name='aggregationPeriod'
                    id='aggregationPeriod'
                    entities={AGGREGATION_PERIOD}
                    calcId={_ => _.id}
                    calcName={_ => _.name}
                    placeholder='Select'
                    testId='aggregation-period'
                >
                    Show results
                </SelectField>
            }

            <div className='mb-3'>
                <CheckboxField id='include-total-samples' name='includeTotalSamples' disabled={!isTotalSamplesBarAvailable}>
                    Include total samples bar axis
                </CheckboxField>
                {!isTotalSamplesBarAvailable && <span>Filter selection not available for this graph type</span>}
            </div>

            {props.chartType === PARTICLE_COUNTS &&
                <CheckboxField id='join-points' name='joinPoints' className='mb-3'>
                    Join points
                </CheckboxField>
            }

            {h.isThresholdLineAvaible(props.values.chartType) &&
                <>
                    {props.thresholdLines.length > 0 &&
                        <div>
                            <p className='fw-bold mb-1'>Threshold lines</p>
                            {props.thresholdLines.map((line, i) =>
                                <div key={i} className='d-flex'>
                                    <Close className='align-self-start text-danger me-2' onClick={() => props.onRemoveThresholdLine(i)}/>
                                    <div className='break-word'>{h.formatThresholdLineName(line)}</div>
                                </div>
                            )}
                        </div>
                    }

                    <Button
                        onClick={props.showModal}
                        className='btn-link'
                    >
                        Add a threshold line
                    </Button>
                </>
            }

            <hr className='mb-3' />
            <p className='fw-bold'>Data selection</p>
                {props.values.chartType === PARTICLE_COUNTS
                    && <MultiSelectWrapper
                        onSeriesSelect={noop}
                        checked={props.values.seriesFieldIndex === fieldIndex.PARTICLES_STATE}
                        showSeriesPicker={isSeriesPickerAvailable}
                        testId={`series-${fieldIndex.PARTICLES_STATE}`}
                        disableMessage={!h.allParticleStatesAreSelected(props.values.particleStates) ? 'Only 0.5μm and 5μm allowed to be the series' : undefined}
                        render={() =>
                            <FilterMultiSelectField
                                id='particleStates'
                                name='particleStates'
                                entities={PARTICLE_ANALYSIS_STATE}
                                calcId={_ => _.id}
                                calcName={_ => _.name}
                                autocomplete
                                fillParentWidth
                                testId={`field-${fieldIndex.PARTICLES_STATE}`}
                            >
                                Particle size
                            </FilterMultiSelectField>
                        }
                    />
                }

            {props.fields.map(_ => isDefaultCustomField(_) && buildField(_, isSeriesPickerAvailable, seriesDisableMessage))}

            <MultiSelectWrapper
                onSeriesSelect={_ => _.input.onChange(props.values.seriesFieldIndex !== fieldIndex.EXPOSURE_LOCATION_GRADE_ID ? fieldIndex.EXPOSURE_LOCATION_GRADE_ID : undefined)}
                checked={props.values.seriesFieldIndex === fieldIndex.EXPOSURE_LOCATION_GRADE_ID}
                showSeriesPicker={isSeriesPickerAvailable}
                testId={`series-${fieldIndex.EXPOSURE_LOCATION_GRADE_ID}`}
                disableMessage={seriesDisableMessage}
                render={() =>
                    <FilterMultiSelectField
                        name='gradeIds'
                        id='gradeIds'
                        entities={props.predefinedLists.grades}
                        calcId={_ => _.id}
                        calcName={formatEntity}
                        autocomplete
                        fillParentWidth
                        anyOption={props.values.seriesFieldIndex !== fieldIndex.EXPOSURE_LOCATION_GRADE_ID ? { id: ANY_ID, name: ANY, isActive: true } : undefined}
                        testId={`field-${fieldIndex.EXPOSURE_LOCATION_GRADE_ID}`}
                    >
                        Exposure location grade
                    </FilterMultiSelectField>
                }
            />

            {props.chartType !== PARTICLE_COUNTS
                && <>
                    <MultiSelectWrapper
                        onSeriesSelect={_ => _.input.onChange(props.values.seriesFieldIndex !== fieldIndex.IDENTIFICATION ? fieldIndex.IDENTIFICATION : undefined)}
                        checked={props.values.seriesFieldIndex === fieldIndex.IDENTIFICATION}
                        showSeriesPicker={isSeriesPickerAvailable}
                        render={() => <MultipleOrganismSearch
                            id='organismIds'
                            name='organismIds'
                            className='flex-fill'
                            disabled={isOrganismTypeGraph}
                            testId={`field-${fieldIndex.IDENTIFICATION}`}
                        />}
                        testId={`series-${fieldIndex.IDENTIFICATION}`}
                    />

                    <div className='d-flex'>
                        {isSeriesPickerAvailable && <div className='analysis-filter__series-btn-spacer'/>}
                        <FilterMultiSelectField
                            name='organismTypeIds'
                            id='organismTypeIds'
                            entities={props.predefinedLists.identifications.organismType}
                            calcId={_ => _.id}
                            calcName={formatEntity}
                            autocomplete
                            fillParentWidth
                            anyOption={({ id: ANY_ID, name: ANY })}
                        >
                            Organism type
                        </FilterMultiSelectField>
                    </div>

                    <div className='d-flex'>
                        {isSeriesPickerAvailable && <div className='analysis-filter__series-btn-spacer'/>}
                        <FilterMultiSelectField
                            name='catalaseIds'
                            id='catalaseIds'
                            entities={props.predefinedLists.identifications.catalase}
                            calcId={_ => _.id}
                            calcName={formatEntity}
                            autocomplete
                            fillParentWidth
                            anyOption={({ id: ANY_ID, name: ANY })}
                            disabled={isOrganismTypeGraph}
                        >
                            Catalase
                        </FilterMultiSelectField>
                    </div>

                    <div className='d-flex'>
                        {isSeriesPickerAvailable && <div className='analysis-filter__series-btn-spacer'/>}
                        <FilterMultiSelectField
                            name='oxidaseIds'
                            id='oxidaseIds'
                            entities={props.predefinedLists.identifications.oxidase}
                            calcId={_ => _.id}
                            calcName={formatEntity}
                            autocomplete
                            fillParentWidth
                            anyOption={({ id: ANY_ID, name: ANY })}
                            disabled={isOrganismTypeGraph}
                        >
                            Oxidase
                        </FilterMultiSelectField>
                    </div>

                    <div className='d-flex'>
                        {isSeriesPickerAvailable && <div className='analysis-filter__series-btn-spacer'/>}
                        <FilterMultiSelectField
                            name='oxidationFermentationIds'
                            id='oxidationFermentationIds'
                            entities={props.predefinedLists.identifications.oxidationFermentation}
                            calcId={_ => _.id}
                            calcName={formatEntity}
                            autocomplete
                            fillParentWidth
                            anyOption={({ id: ANY_ID, name: ANY })}
                            disabled={isOrganismTypeGraph}
                        >
                            Oxidation fermentation
                        </FilterMultiSelectField>
                    </div>

                    <div className='d-flex'>
                        {isSeriesPickerAvailable && <div className='analysis-filter__series-btn-spacer'/>}

                        <FilterMultiSelectField
                            name='coagulaseIds'
                            id='coagulaseIds'
                            entities={props.predefinedLists.identifications.coagulase}
                            calcId={_ => _.id}
                            calcName={formatEntity}
                            autocomplete
                            fillParentWidth
                            anyOption={({ id: ANY_ID, name: ANY })}
                            disabled={isOrganismTypeGraph}
                        >
                            Coagulase
                        </FilterMultiSelectField>
                    </div>

                    <div className='d-flex'>
                        {isSeriesPickerAvailable && <div className='analysis-filter__series-btn-spacer'/>}
                        <CheckboxField id='showOnlyObjectionable' name='showOnlyObjectionable' className='mb-2' testId='show-only-objectionable'>
                            Show only objectionable
                        </CheckboxField>
                    </div>
                </>
            }

            <MultiSelectWrapper
                onSeriesSelect={_ => _.input.onChange(props.values.seriesFieldIndex !== fieldIndex.MONITORING_STATE ? fieldIndex.MONITORING_STATE : undefined)}
                checked={props.values.seriesFieldIndex === fieldIndex.MONITORING_STATE}
                showSeriesPicker={isSeriesPickerAvailable}
                testId={`series-${fieldIndex.MONITORING_STATE}`}
                disableMessage={seriesDisableMessage}
                render={() =>
                    <FilterMultiSelectField
                        id='monitoringStates'
                        name='monitoringStates'
                        entities={MONITORING_STATE}
                        calcId={_ => _.id}
                        calcName={_ => _.name}
                        autocomplete
                        fillParentWidth
                        testId={`field-${fieldIndex.MONITORING_STATE}`}
                    >
                        Monitoring state
                    </FilterMultiSelectField>
                }
            />

            {props.fields.map(_ => !isDefaultCustomField(_) && h.isActiveCustomField(_, props.chartType === PARTICLE_COUNTS) && buildField(_, isSeriesPickerAvailable, seriesDisableMessage))}

            <CheckboxField id='include-compromised' name='includeCompromised' testId='include-compromised'>Include compromised</CheckboxField>
        </div>
    )
}

export default Filter
