import type { ValidationResult } from '_/utils/form/validate'
import { required } from '_/utils/form/validate'

export default function validate(entity: Partial<{ roleId: string }>) {
    const roleId = required('Role')(entity.roleId)
        , result: ValidationResult<{ roleId: string }> = {}

    if (roleId)
        result.roleId = roleId

    return result
}
