import { React, useState } from '_/facade/react'
import HoverMenu from '_/components/overlay/hover-menu'

import type { LeagueTablePositionKpiBox, OperatorsReport } from '_/model/reports/operators-report/types'
import { Link } from '_/utils/router'
import { dropFields } from '_/utils/object'
import { FINGERDABS_OPERATOR_PERFORMANCE } from '_/constants/routes'
import { ITEMS_PER_PAGE } from '_/constants'
import { paramsFilter } from '_/model/filters/helpers'
import { getDateRange } from '_/model/reports/helpers'
import { useTimeService } from '_/components/time'
import NoDataAvailable from '../no-data-available'

interface Props {
    kpiBlockInfo: LeagueTablePositionKpiBox | undefined
    operatorTotalCount: number
    operatorName: string
    showSpinner: boolean
    operatorReport: OperatorsReport
}

function LeagueTablePositionKpiBlock(props: Props) {
    const hintContainerRef = React.createRef<HTMLDivElement>()
        , timeService = useTimeService()
        , [containerElement, setContainerElement] = useState<HTMLElement | null>(null)
        , [blockElement, setBlockElement] = useState<HTMLElement | null>(null)

    if (!props.kpiBlockInfo || props.showSpinner) {
        return (
            <div className='col-3'>
                <div className='block-border text-center py-3'>
                    <div className='d-flex justify-content-center'>
                        <div className='pb-2'>
                            <div className='text-center'>
                                <h4 className='mb-4'>Operator performance</h4>
                            </div>
                            <NoDataAvailable showSpinner={props.showSpinner}>
                                <span className='text-muted text-uppercase'>No data available</span>
                            </NoDataAvailable>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const reportParams = dropFields(props.operatorReport, 'floorPlanId')
        , { fingerdabActionLimitBreaches, fingerdabActionLimitBreachRate } = props.kpiBlockInfo
        , routeParams = {
            ...reportParams,
            ...getDateRange(timeService, props.operatorReport, false),
            start: Math.trunc(props.kpiBlockInfo.position / ITEMS_PER_PAGE) * ITEMS_PER_PAGE,
            sort: 'actionLimitBreachRate:desc',
        }

    return (
        <div className='col-3 px-3'>
            <div className='block-border text-center py-3'>
                <div className='d-flex justify-content-center' ref={setContainerElement}>
                    <div ref={setBlockElement} className='pb-2'>
                            <div className='d-flex flex-column justify-content-center'>
                                <h4>{props.kpiBlockInfo.position}</h4>
                                <div className='text-secondary'>
                                    {props.operatorName} position is
                                    <Link
                                        routeName={FINGERDABS_OPERATOR_PERFORMANCE}
                                        routeParams={paramsFilter(routeParams)}
                                        className='mx-1 text-decoration-underline'
                                    >
                                        {props.kpiBlockInfo.position}
                                    </Link>
                                    out of {props.operatorTotalCount}
                                </div>
                            </div>
                    </div>
                    {blockElement &&
                        <HoverMenu element={containerElement} alignmentElement={blockElement} showTriangle>
                            <div ref={hintContainerRef} className='legend-bubble bg-dark'>
                                <div className='text-white p-3'>
                                    <div>{fingerdabActionLimitBreaches} fingerdab action limit breach{fingerdabActionLimitBreaches !== 1 && 'es'}</div>
                                    <div>{fingerdabActionLimitBreachRate}% fingerdab action limit breach rate</div>
                                </div>
                            </div>
                        </HoverMenu>
                    }
                </div>
            </div>
        </div>
    )
}

export default LeagueTablePositionKpiBlock
