import { useAction, useState, useLayoutEffect } from '_/facade/react'
import { Form } from 'react-final-form'

import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import { Table } from '_/components/table'
import * as notificationType from '_/model/notification/notification-type'

import type { UserMembership, NotificationSettings } from '_/model/auth/types'

import { Submit, CheckboxField, submitDisabled } from '_/components/form'
import type { ChangeUserNotifications } from '_/model/users/types'

import { CHANGES_SAVED } from '_/features/samples/messages'

import * as actions from './actions'
import * as toastActions from '_/features/toasts/actions'
import type { FormApi } from 'final-form'
import { calcPermissions } from '_/model/permissions/helpers'

interface Props {
    userId: string
    notificationSettings: NotificationSettings[]
    membership: UserMembership
}

const calcInitialValue = (userId: string, contextId: string, notificationSettings: NotificationSettings[]) => ({
        id: userId,
        contextNotificationSettings: {
            contextId,
            notificationSettings
        }
    })

function NotificationSetting(props: Props) {
    const saveNotifications = useAction(actions.changeNotificationSettings)
        , addSuccess = useAction(toastActions.addSuccess)
        , membership = props.membership
        , userId = props.userId
        , contextId = props.membership.context.id
        , notificationSettings = props.notificationSettings
        , [initialValue, setInitialValue] = useState(calcInitialValue(userId, contextId, notificationSettings))

    useLayoutEffect(
        () => {
            setInitialValue(calcInitialValue(userId, contextId, notificationSettings))
        },
        [userId, contextId, notificationSettings]
    )

    function handleSave(_: ChangeUserNotifications, form: FormApi<ChangeUserNotifications>) {
        return saveNotifications(_)
            .then(() => form.reset(_))
            .then(() => addSuccess(CHANGES_SAVED))
    }

    function hasPermissionEditSetting(type: notificationType.NotificationType){
        return type != notificationType.TAGGED_USER || calcPermissions(props.membership).tagUsers
    }

    if (props.notificationSettings.length === 0) {
        return (
            <div className='mt-3 w-100'>
                <div className='d-flex flex-fill justify-content-between'>
                    <span className='fw-bold'>
                        {membership.description}
                    </span>
                    <Submit
                        className='btn-primary'
                        disabled={true}
                    >
                        Save all changes
                    </Submit>
                </div>
                <div className='pb-3 text-muted'>
                    No notifications are available to you for this site.
                </div>
            </div>
        )
    }

    return (
        <div className='mt-3'>
            <Form<ChangeUserNotifications>
                // callback here, because form.reset() does not work in onSubmit
                onSubmit={(value, form) => { handleSave(value, form) }}
                initialValues={initialValue}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver
                            target={`notifications=${contextId}`}
                            form={form}
                        />

                        <div className='clearfix mb-2 mt-1'>
                            <span className='fw-bold' data-testid={`notification-section-${membership.description}`}>
                                {membership.description}
                            </span>
                            <Submit
                                className='btn-primary float-end'
                                disabled={submitDisabled(form)}
                                testId={`save-changes-${props.membership.description}`}
                            >
                                Save all changes
                            </Submit>
                        </div>
                        <Table className='table-layout-fixed' testId={`notification-preferences-table-${membership.description}`}>
                            <thead>
                                <tr className='limits-table-header pt-1'>
                                    <th style={{ width: 'auto' }} data-testid={`notification-table-header-${membership.description}`}>Notification type</th>
                                    <th style={{ width: '20%' }} data-testid={`notification-table-header-${membership.description}`}>In-app</th>
                                    <th style={{ width: '20%' }} data-testid={`notification-table-header-${membership.description}`}>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.notificationSettings.map((notificationSettings, i) =>
                                    <tr key={i}>
                                        <td>
                                            <span data-testid={`notification-type-${membership.description}`}>
                                                {getNotificationBreachDescription(notificationSettings.notificationType)}
                                            </span>
                                        </td>
                                        <td>
                                            <CheckboxField
                                                id={`contextNotificationSettings[${contextId}].notificationSettings[${i}].sendInAppNotifications`}
                                                name={`contextNotificationSettings.notificationSettings[${i}].sendInAppNotifications`}
                                                hasNoPermissions={!hasPermissionEditSetting(notificationSettings.notificationType)}
                                                testId={`in-app-${props.membership.context.description}-${notificationSettings.notificationType}`}
                                            />
                                        </td>
                                        <td>
                                            <CheckboxField
                                                id={`contextNotificationSettings[${contextId}].notificationSettings[${i}].sendEmailNotifications`}
                                                name={`contextNotificationSettings.notificationSettings[${i}].sendEmailNotifications`}
                                                hasNoPermissions={!hasPermissionEditSetting(notificationSettings.notificationType)}
                                                testId={`email-${props.membership.context.description}-${notificationSettings.notificationType}`}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </form>
                }
            />
        </div>
    )
}

export default NotificationSetting

function getNotificationBreachDescription(type: notificationType.NotificationType) {
    switch(type) {
        case notificationType.ALERT:
            return 'Alert limit breaches'
        case notificationType.ACTION:
            return 'Action limit breaches'
        case notificationType.COMPROMISED:
            return 'Compromised'
        case notificationType.TAGGED_USER:
            return 'Tag in comments'
        case notificationType.MISSED_MONITORING:
            return 'Missed monitoring (daily summary only)'
        case notificationType.DATA_EXPORT:
            return 'My data exports (individual notifications only)'
        case notificationType.TREND_IDENTIFIED:
            return 'Trends'
        case notificationType.MISSED_MONITORING_EXPECTATION:
            return 'Missed monitoring'
    }
}
