import { UnitContainer } from '_/components/layout'
import { Link } from '_/utils/router'
import { LOG_IN } from '_/constants/routes'

const ForgotPasswordSent: React.FC = () =>
    <UnitContainer>
        <h4>Password reset email sent</h4>
        <div className='row'>
            <fieldset className='col-10'>
                <legend>If email exists we'll send an email with reset link</legend>
                <Link
                    className='btn btn-link btn-sm'
                    routeName={LOG_IN}
                    testId='go-to-login'
                >
                    Back to login page
                </Link>
            </fieldset>
        </div>
    </UnitContainer>

export default ForgotPasswordSent
