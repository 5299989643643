import { useState } from '_/facade/react'

import HoverMenu from '_/components/overlay/hover-menu'

import type { DayMissedMonitoring } from '_/model/reports/missed-monitoring-report/types'
import { calculateColor } from '_/model/reports/locations-report/helpers'
import WEEK from '_/constants/weekday'

import Cell from '../../graph-by-day-session/cell'

interface Props {
    missedMonitoring: DayMissedMonitoring | undefined
    maxMissedSamplesCount: number
}

function MissedSamplesData(props: Props) {
    const [element, setElement] = useState<SVGTextElement | null>(null)

    if (!props.missedMonitoring)
        return <Cell backgroundColor='#f8f9fa' />

    const { daysCount, averageMissedSamplesCount, missedSamplesCount } = props.missedMonitoring
        , dayName = WEEK.find(_ => _.id === props.missedMonitoring?.dayOfWeek)?.name
        , backgroundColor = calculateColor(props.maxMissedSamplesCount, averageMissedSamplesCount)

    return (
        <>
            <Cell backgroundColor={backgroundColor} count={averageMissedSamplesCount} setRef={setElement} />

            <HoverMenu element={element} position='right'>
                <div className='legend-bubble-triangle triangle-left'>
                    <div className='legend-bubble text-white py-2 px-4 ms-1'>
                        <span>
                            {missedSamplesCount} missing viable samples across {daysCount} {dayName}{daysCount > 1 ? 's' : ''}
                        </span>
                    </div>
                </div>
            </HoverMenu>
        </>
    )
}

export default MissedSamplesData


