import { classnames, useSelector, useState } from '_/facade/react'
import { showFieldError } from '_/components/form'
import { Modal, ModalBody, ModalHeader } from '_/components/modal'
import validate from '_/model/critical-change-reason/validate'
import { Field, Form } from 'react-final-form'
import Error from '_/components/form/field-error'
import SignatureFields from '_/features/critical-change-reason/signature-fields'
import ReasonModalFooter from '_/features/critical-change-reason/modal-footer'
import { Close } from '_/components/button'
import type { SignatureMethod } from '_/model/critical-change-reason/types'
import type ApprovalInfo from '_/model/critical-change-reason/types'

interface Props {
    reason: string
    isSignatureRequired: boolean
    onSubmit: (_: ApprovalInfo) => void
    onCancel: () => void
}

function OverridePhotoRequiredReasonModal(props: Props) {
    const ssoESignatureAllowed = useSelector(_ => _.auth.permissions).useSsoESignature
        , [signatureMethod, setSignatureMethod] = useState<SignatureMethod | undefined>(ssoESignatureAllowed && props.isSignatureRequired ? undefined : 'smartControl')

    return (
        <Modal
            isOpen
            shouldCloseOnOutsideClick={false}
            onClose={props.onCancel}
            contentClassName='overflow-auto'
        >
            <Form
                initialValues={{ reason: props.reason }}
                onSubmit={props.onSubmit}
                validate={(_: Partial<ApprovalInfo>) => validate(_, props.isSignatureRequired)}
                render={form =>
                    <div>
                        <ModalHeader className='border-bottom-0 pb-0'>
                            <h4 data-testid='reason-for-photo-requirement-override-modal'>Reason for overriding photo requirement</h4>
                            <Close onClick={props.onCancel} />
                        </ModalHeader>
                        <ModalBody noDefaultHeight>
                            <form className='d-print-none mt-3' onSubmit={form.handleSubmit}>
                                <div className='d-flex'>
                                    <div className='flex-fill'>
                                        <Field name='reason' render={_ =>
                                            <>
                                                {props.isSignatureRequired && <div className='mb-2'>Please provide a reason for overriding photo requirement</div>}
                                                <textarea
                                                    className={classnames('form-control reason-for-change__text-area', { 'is-invalid': showFieldError(_.meta) })}
                                                    data-testid='field-reason-for-overriding-photo'
                                                    {..._.input}
                                                />
                                                <Error field={_} />


                                            </>
                                        } />

                                        {props.isSignatureRequired &&
                                            <SignatureFields
                                                signatureMethod={signatureMethod}
                                                ssoESignatureAllowed={ssoESignatureAllowed}
                                                setSignatureMethod={setSignatureMethod}
                                            />
                                        }
                                    </div>
                                </div>
                            </form>
                        </ModalBody>
                        <ReasonModalFooter
                            form={form}
                            cancel={props.onCancel}
                            signatureRequired={props.isSignatureRequired}
                            ssoESignatureAllowed={ssoESignatureAllowed}
                            setSignatureMethod={setSignatureMethod}
                            signatureMethod={signatureMethod}
                        />
                    </div>
                }
            />
        </Modal>
    )
}

export default OverridePhotoRequiredReasonModal
