import type { ValidationResult } from '_/utils/form/validate'
import { isValidNumberInRange, required } from '_/utils/form/validate'
import type Grade from '_/model/predefined-lists/grade/grade'
import type { Limits } from '_/model/predefined-lists/action-alert-limit/types'
import type Sample from '_/model/sample/sample'
import type { IdentificationRows } from '_/model/sample/reading/sample-reading'
import type SampleReading from '_/model/sample/reading/sample-reading'

import * as breachType from '_/constants/sample-breach-type'
import * as photoBehaviour from '_/constants/photograph-behaviour'
import * as growthsStatus from '_/constants/growth-status'

import * as sh from '_/features/samples/helpers'
import * as h from './helpers'
import { CORRECTIONAL_MPN_VALUE, IDENTIFICATION_WITH_ARGUMENT } from '../identification-type'

export function validateIdentifications(value: IdentificationRows, isSwabPlateType: boolean) {
    const status = sh.calcGrowthsStatus(value)

    if (status === growthsStatus.NO_GROWTH_ID) {
        return { rows: value.rows.map(_ => ({})) }
    }

    const rows = value.rows.map(row => {
        const [ minCfuCount, maxCfuCount ] = [ value.rows.length === 1 ? 0 : 1, isSwabPlateType ? 1 : 1000 ]
            , cfuCount: string | undefined =
                row.cfuCount === undefined
                || !Number.isInteger(row.cfuCount)
                || !isValidNumberInRange(row.cfuCount, minCfuCount, maxCfuCount)
                    ? value.rows.length === 1
                        ? isSwabPlateType ? 'CFUs must be integer and 0 or 1' : 'CFUs must be integer between 0 and 1000'
                        : isSwabPlateType ? 'CFUs must be integer and equal to 1' : 'CFUs must be integer between 1 and 1000'
                    : undefined

            , types = row.types.map(type => {
                const requiresIdentification = value.rows.length > 1 || (row.cfuCount ?? 0) > 0

                return {
                    type: requiresIdentification ? required('Identification type')(type.type) || undefined : undefined,
                    value: type.type !== undefined && IDENTIFICATION_WITH_ARGUMENT.includes(type.type)
                        ? required('Identification argument')(type.value) || undefined : undefined,
                }
            })

        return {
            cfuCount,
            types,
        }
    })

    return { rows }
}

function checkPhotoRequiredByGrowthStatusChange(prevGrowths: IdentificationRows, nextGrowths: IdentificationRows | undefined, grade: Grade | undefined) {
    if (!nextGrowths)
        return false

    const growthsStatusChanged = sh.calcGrowthsStatus(prevGrowths) !== sh.calcGrowthsStatus(nextGrowths)

    return (!grade || grade.photoBehaviour === photoBehaviour.ALWAYS) && growthsStatusChanged
}

function checkPhotoRequiredByBehavior(sample: Sample, grade: Grade | undefined, sampleBreachType: breachType.BreachType, compromised: boolean) {
    if (!grade || compromised || grade.photoBehaviour === photoBehaviour.NEVER || grade.photoBehaviour === photoBehaviour.ALWAYS)
        return false

    const breachStateChanged = h.isBreachStateChanged(sample, grade, sampleBreachType)

    switch (grade.photoBehaviour) {
        case photoBehaviour.FOR_ALERT_LIMIT_BREACHES:
        {
            if (sampleBreachType === breachType.ALERT_LIMIT || sampleBreachType === breachType.ACTION_LIMIT)
                return breachStateChanged

            return false
        }
        case photoBehaviour.FOR_ACTION_LIMIT_BREACHES:
        {
            if (sampleBreachType === breachType.ACTION_LIMIT)
                return breachStateChanged

            return false
        }
        default:
            return false
    }
}

export function isImageRequiredError(sample: Sample, reading: SampleReading, grades: Grade[], allLimits: Limits[], compromised: boolean): boolean {
    const identificationsDiff = h.identificationsDiff(sample, reading)
    if (reading.detachedImages && reading.detachedImages.length > 0 || !identificationsDiff)
        return false

    const { grade, limits } = h.getGradeLimitsValue(sample, grades, allLimits)
        , sampleBreachType = h.calculateSampleBreachType(reading, grade, limits)
        , photoRequireByBehaviour = checkPhotoRequiredByBehavior(sample, grade, sampleBreachType, compromised)
        , photoRequireByGrowthStatusChange =
            checkPhotoRequiredByGrowthStatusChange(sample.identifications, reading.identifications, grade)
        , photoRequireByOptionalGrowthStatusChange = h.isTwoHandsPlate(sample)
            && checkPhotoRequiredByGrowthStatusChange(sample.optionalIdentifications!, reading.optionalIdentifications, grade)

    return photoRequireByBehaviour || photoRequireByGrowthStatusChange || photoRequireByOptionalGrowthStatusChange
}

export function onlyCorrectionMpnValueSelected(identifications: IdentificationRows) {
    return identifications.rows.every(_ => _.types[0].type === CORRECTIONAL_MPN_VALUE)
}

export default function validate(entity: SampleReading, sample: Sample | undefined) {
    const isSwabPlateType = sample && h.isSwabPlateType(sample)
        , identifications = validateIdentifications(entity.identifications, !!isSwabPlateType)
        , optionalIdentifications = sample && h.isTwoHandsPlate(sample)
            ? validateIdentifications(entity.optionalIdentifications!, false) as any
            : undefined
        , result: Omit<ValidationResult<SampleReading>, 'detachedImages'> & { detachedImages?: string } = {}

    result.identifications = identifications
    result.optionalIdentifications = optionalIdentifications

    return result
}
