// JSON supports null, false, true, string, object and array
// so only those cases are handled
function replaceNullWithUndefined(input: any): any {
    if (input === null)
        return undefined

    if (typeof input !== 'object')
        return input

    if (Array.isArray(input))
        return input.map(replaceNullWithUndefined)

    const result: any = {}
    Object.keys(input).forEach(
        key => result[key] = replaceNullWithUndefined(input[key])
    )

    return result
}

export {
    replaceNullWithUndefined,
}
