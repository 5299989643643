const VIABLE = 0
    , NON_VIABLE = 1

type EntityType = typeof VIABLE | typeof NON_VIABLE

export {
    VIABLE,
    NON_VIABLE,
    EntityType,
}
