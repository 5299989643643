import * as fieldIndex from '_/constants/custom-field-index'
import { TEXT } from '_/constants/custom-field-column-type'
import { isDefaultDataField } from '_/features/predefined-lists/custom-fields/helpers'

import type CustomField from '_/model/predefined-lists/custom-field/types'

function getNotNullQuery(query: any): any {
    const queryAny: any = query
    const notNullQuery: any = {}

    Object.keys(query).forEach(key => {
        if (queryAny[key] !== undefined && queryAny[key] !== null)
            notNullQuery[key] = queryAny[key]
    })

    return notNullQuery
}

function sortCustomFields(fields: CustomField[]) {
    function compareByFieldType(one: CustomField, two: CustomField) {
        if (one.fieldType === TEXT)
            return -1

        if (two.fieldType === TEXT)
            return 1

        return one.fieldType - two.fieldType
    }

    function compareByPosition(one: CustomField, two: CustomField) {
        if (one.position < two.position)
            return -1

        if (one.position > two.position)
            return 1

        return 0
    }

    const defaultFields = fields.filter(_ => isDefaultDataField(_.index)).sort(compareByFieldType)
        , customFields = fields.filter(_ => !isDefaultDataField(_.index)).sort(compareByPosition)

    return defaultFields.concat(customFields)
}

const NOT_USED_FIELDS = [
    fieldIndex.EXPOSURE_DATE,
    fieldIndex.EXPOSURE_END_TIME,
    fieldIndex.EXPOSURE_START_TIME,
    fieldIndex.MONITORING_POSITION,
]

export {
    getNotNullQuery,
    NOT_USED_FIELDS,
    sortCustomFields,
}
