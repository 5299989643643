const LOCATION_TREND = 0
    , OPERATOR_TREND = 1
    , ORGANISM_TREND = 2
    , LIMIT_BREACH_TREND = 3
    , DISTRIBUTION_OF_BREACHES_ACROSS_SESSION = 4
    , DISTRIBUTION_OF_BREACHES_ACROSS_DAY = 5

type InsightType = never
    | typeof LOCATION_TREND
    | typeof OPERATOR_TREND
    | typeof ORGANISM_TREND
    | typeof LIMIT_BREACH_TREND
    | typeof DISTRIBUTION_OF_BREACHES_ACROSS_DAY
    | typeof DISTRIBUTION_OF_BREACHES_ACROSS_SESSION

export {
    LOCATION_TREND,
    OPERATOR_TREND,
    ORGANISM_TREND,
    LIMIT_BREACH_TREND,
    DISTRIBUTION_OF_BREACHES_ACROSS_SESSION,
    DISTRIBUTION_OF_BREACHES_ACROSS_DAY,
    InsightType,
}
