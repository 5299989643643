import { useField } from 'react-final-form'
import { useAction, useState, classnames, useEffect, useLayoutEffect } from '_/facade/react'

import { debounce, noop } from '_/utils/function'
import { formatActiveState } from '_/utils/format/common'

import Select from '_/components/downshift-select'
import { showFieldError } from '_/components/form/helpers'

import type { OrganismIdentification } from '_/model/predefined-lists/organism-identification/types'

import * as a from './actions'

interface Props {
    id: string
    hasNoPermissions: boolean
    name: string
    disabled?: boolean
    hasLabel?: boolean
    excludeInactive?: boolean
    includeInconclusive?: boolean
    labelText?: string
}

function OrganismSearch(props: Props) {
    const { input, meta } = useField<string>(props.name)
        , [organisms, handleSearch] = useOrganisms(props.hasNoPermissions, !!props.includeInconclusive)
        , [selectedOrganism, isLoading] = useSelectedOrganism(input.value, organisms)

    return (
        <div className={props.hasLabel ? 'mb-3' : 'row g-2'}>
            {props.hasLabel &&
                <label htmlFor={props.id} className='col-form-label'>{props.labelText ?? 'Identification'}</label>
            }
            <Select
                id='organismIdentifications'
                entities={props.excludeInactive ? organisms.filter(_ => _.isActive) : organisms}
                fallbackEntity={selectedOrganism}
                calcId={calcId}
                calcName={_ => formatActiveState(_.name, _.isActive)}
                input={input}
                onInputValueChange={handleSearch}
                hasNoPermissions={props.hasNoPermissions}
                disabled={props.disabled || isLoading}
                className={classnames('form-control', { 'is-invalid': showFieldError(meta) })}
                placeholder='Search'
                isSearchField
                testId='field-organism-identifications'
            />
            {showFieldError(meta) && <span className='invalid-feedback' data-testid='validation-error'>{meta.error}</span>}
        </div>
    )
}

export default OrganismSearch

function useSelectedOrganism(id: string, organisms: OrganismIdentification[]) {
    const [organism, setOrganism] = useState<OrganismIdentification>()
        , loadOrganism = useAction(a.loadOrganismIdentification)
        , isLoading = !!id && !organism

    useLayoutEffect(
        () => {
            setOrganism(_ => getOrganism(id, organisms, _))
        },
        [organisms, id]
    )

    useEffect(
        () => {
            let disposed = false

            if (organism || !id || organisms.find(_ => calcId(_) === id))
                return

            // we have organismId but no organism itself
            loadOrganism(id).then(_ => {
                if (!disposed)
                    setOrganism(_)
            })

            return () => {
                disposed = true
            }
        },
        [id, organism, organisms, loadOrganism]
    )

    return [organism, isLoading] as const
}

function useOrganisms(hasNoPermissions: boolean, includeInconclusive: boolean) {
    const [organisms, setOrganisms] = useState<OrganismIdentification[]>([])
        , [searchHandler, setSearchHandler] = useState<(value: string) => void>(() => noop)
        , searchOrganismIdentification = useAction(a.searchOrganismIdentification)

    useEffect(
        () => {
            if (hasNoPermissions) {
                setOrganisms([])
                setSearchHandler(() => noop)
                return
            }

            function search(query: string) {
                const result = query.length >= 3 ? searchOrganismIdentification({name: query, includeInconclusive }) : Promise.resolve([])

                result.then(setOrganisms)
            }

            setSearchHandler(() => debounce(search, 300))
        },
        [hasNoPermissions, includeInconclusive, searchOrganismIdentification]
    )

    return [organisms, searchHandler] as const
}

// used for referential equality of organisms
// so input is not reset during typing/searching
function getOrganism(id: string, organisms: OrganismIdentification[], prevOrganism: OrganismIdentification | undefined): OrganismIdentification | undefined {
    if (!id)
        return undefined

    // maintain referential equality
    if (prevOrganism && calcId(prevOrganism) === id)
        return prevOrganism

    return organisms.find(_ => calcId(_) === id)
}

function calcId(organism: OrganismIdentification) {
    return organism.id
}
