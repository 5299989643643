import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, isEmpty, required, allowedName } from '_/utils/form/validate'
import { searchDuplicateName } from '_/model/common/helpers'
import type { RoleEdit } from '_/model/roles/types'
import type Role from '_/model/roles/types'

export default function validate(entity: Partial<RoleEdit>, duplicateRoles: Role[]): ValidationResult<RoleEdit> {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 50)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , duplicate = entity.name && searchDuplicateName(entity, duplicateRoles)
        , contextLevel = required('Context')(entity.contextLevel)
        , result: ValidationResult<RoleEdit> = {}

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (notAllowedNames)
        result.name = notAllowedNames

    if (duplicate)
        result.name = `Role with name '${entity.name!.trim()}' already exists`

    if (contextLevel)
        result.contextLevel = contextLevel

    return result
}
