import { React, classnames, useState } from '_/facade/react'
import HoverMenu from '_/components/overlay/hover-menu'

import type { KpiData } from '_/model/reports/types'
import type { TrendType } from '_/model/reports/locations-report/trend-type'

import { areDatesInCurrentYear } from '_/model/reports/missed-monitoring-report/helpers'
import { calculateTrend } from '_/model/reports/locations-report/helpers'
import { KpiBlockHintFormat } from '_/features/reports/kpi-block'
import { useTimeService } from '_/components/time'

interface OwnProps {
    kpiBlockInfo: KpiData | undefined
    trendType: TrendType
    limitBreachRate: number | undefined
}

function LimitBreachRate(props: OwnProps) {
    const hintContainerRef = React.createRef<HTMLDivElement>()
        , [blockElement, setBlockElement] = useState<HTMLElement | null>(null)
        , emptyMessage = 'No fingerdab action limit breaches recorded'
        , timeService = useTimeService()
        , omitYear = areDatesInCurrentYear(props.kpiBlockInfo?.currentPeriodInfo.dateRange, timeService)
            && areDatesInCurrentYear(props.kpiBlockInfo?.previousPeriodInfo.dateRange, timeService)

    return (
        <div className='d-inline-block' ref={setBlockElement}>
            <span>{props.limitBreachRate} %</span>
            <Trend kpiBlockInfo={props.kpiBlockInfo} />

            {blockElement &&
                <HoverMenu element={blockElement} position='right' showTriangle>
                    <div ref={hintContainerRef} className='legend-bubble text-white py-2 px-4 ms-1'>
                        <KpiBlockHintFormat
                            info={props.kpiBlockInfo?.currentPeriodInfo}
                            trendType={props.trendType}
                            emptyMessage={emptyMessage}
                            currentPeriod={true}
                            timeService={timeService}
                            omitYear={omitYear}
                        />
                        <KpiBlockHintFormat
                            info={props.kpiBlockInfo?.previousPeriodInfo}
                            trendType={props.trendType}
                            emptyMessage={emptyMessage}
                            currentPeriod={false}
                            timeService={timeService}
                            omitYear={omitYear}
                        />
                        <div className='fst-italic mt-3'>
                            Excludes compromised viable samples
                        </div>
                    </div>
                </HoverMenu>
            }
        </div>
    )
}

export default LimitBreachRate

interface TrendProps {
    kpiBlockInfo: KpiData | undefined
}

function Trend(props: TrendProps) {
    const trend = calculateTrend(props.kpiBlockInfo)
        , hideTrend = !props.kpiBlockInfo
            || props.kpiBlockInfo.currentPeriodInfo.readSamplesCount === 0
            || props.kpiBlockInfo.previousPeriodInfo.readSamplesCount === 0

    if (trend === undefined || hideTrend)
        return null

    return (
        <i className={
            classnames(
                'material-icons md-24 text-secondary align-bottom',
                {'text-danger rotate-315': trend > 0},
                {'text-success rotate-45 ps-1': trend < 0},
            )}
        >
            arrow_forward
        </i>
    )
}
