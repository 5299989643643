import { useSelector, useAction, useState, useCallback, useEffect, classnames, React } from '_/facade/react'

import Link, { LinkButton } from '_/components/link'
import { Table } from '_/components/table'
import { useContextSwitchObserver } from '_/components/context-observer'

import type CustomField from '_/model/predefined-lists/custom-field/types'

import FIELD_TYPE from '_/constants/custom-field-column-type'
import * as routes from '_/constants/routes'

import { formatFieldLabel } from '_/features/samples/helpers'
import * as helpers from './helpers'

import * as customFieldsActions from './actions'
import CustomFieldModal from './custom-field-modal'
import * as dom from '_/model/dom'
import * as actions from './actions'
import * as spinnerAction from '_/features/spinner/actions'
import TabNavbar from '_/components/tab-navbar'
import Button from '_/components/button'
import type * as fi from '_/constants/custom-field-index'

function CustomFieldList() {
    function fieldTypeName(field: CustomField) {
        const fieldType = FIELD_TYPE.find(_ => _.id === field.fieldType)
        return fieldType ? fieldType.name : ''
    }

    function fieldSettingFormat(isEditable: boolean, isEnabled: boolean) {
        if (!isEditable && isEnabled)
            return <span className='text-muted'>Always</span>

        if (!isEditable && !isEnabled)
            return <span className='text-muted'>Never</span>

        return isEnabled && <i className='material-icons md-24 text-success'>check_circle</i>
    }

    function isIrrelevantField(index: fi.FieldIndex) {
        return tab === 'nonViableSettings' && helpers.isViableOnlyDataField(index)
    }

    const [customFields, reload] = useCustomFields()
        , [tab, setTab] = useState<keyof Pick<CustomField, 'viableSettings' | 'nonViableSettings'>>('viableSettings')
        , isViable = tab === 'viableSettings'
        , isNonViable = tab === 'nonViableSettings'
        , permissions = useSelector(_ => _.auth.permissions)
        , editMode = useSelector(
            _ => [routes.SETTINGS_SAMPLE_FIELDS_CREATE, routes.SETTINGS_SAMPLE_FIELDS_EDIT].some(it => _.router.route!.name === it)
        )
        , [dragEvents, dropField, dragDirection] = useDragAndDrop(reload)

    return (
        <>
            <TabNavbar className='mt-3'>
                <Button
                    className={classnames('btn-link navbar-tab me-4', isViable && 'active')}
                    onClick={() => setTab('viableSettings')}
                >
                    Settings for viable samples
                </Button>
                <Button
                    className={classnames('btn-link navbar-tab', isNonViable && 'active')}
                    onClick={() => setTab('nonViableSettings')}
                >
                    Settings for non-viable samples
                </Button>

                <Link
                    className='btn btn-primary align-self-baseline ms-auto'
                    routeName={routes.SETTINGS_SAMPLE_FIELDS_CREATE}
                    hasNoPermissions={!permissions.editCustomFields}
                    testId='add-custom-field'
                >
                    Add a custom field
                </Link>
            </TabNavbar>
            <div className='mt-3'>
                You can create, edit and reorder data fields. Data fields apply to
                <br />
                both viable and non-viable samples.
                {' '}
                <LinkButton
                    routeName={routes.HELP}
                    routeParams={{ page: '/tutorials-sc/site_admin/predefined_lists/data_fields/' }}
                    className='btn-link align-baseline p-0'
                >
                    View help 🡒
                </LinkButton>
            </div>
            <h4 className='mt-3'>{tab === 'viableSettings' ? 'Settings for viable samples' : 'Settings for non-viable samples'}</h4>
            {editMode && <CustomFieldModal customFields={customFields} onReload={reload}/>}
            <div className="overflow-auto flex-fill mt-3">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Field name</th>
                            <th>Field type</th>
                            <th>Required</th>
                            <th>Allow N/R</th>
                            <th className='text-center'>Show when booking in</th>
                            <th className='text-center'>Persistent when booking in</th>
                            <th>Status</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {customFields.map(_ =>
                            <tr
                                key={_.id}
                                className={classnames(
                                    dropField?.position !== _.position ? '' : dom.getDragDirectionClass('custom-fields__drag-hover', dragDirection),
                                    !helpers.isDefaultDataField(_.index) && permissions.editCustomFields && 'cursor-pointer',
                                    helpers.isDefaultDataField(_.index) && 'bg-light',
                                    !_[tab].isActive && 'text-muted'
                                )}
                                draggable={permissions.editCustomFields && !helpers.isDefaultDataField(_.index)}
                                onDragStart={e => dragEvents?.onDragStart(e, _)}
                                onDragEnd={dragEvents?.onDragEnd}
                                onDragOver={e => dragEvents?.onDragOver(e, _)}
                                onDragLeave={dragEvents?.onDragLeave}
                                onDrop={dragEvents?.onDrop}
                            >
                                <td>{formatFieldLabel(_)}</td>
                                <td>{fieldTypeName(_)}</td>
                                {isIrrelevantField(_.index)
                                    ? <td colSpan={5} />
                                    : <>
                                        <td>{fieldSettingFormat(helpers.isEditableRequiredField(_.index, isViable), _[tab].required)}</td>
                                        <td>{fieldSettingFormat(helpers.isEditableAllowNotRecordedField(_.index), _[tab].notRecorded)}</td>
                                        <td className='text-center'>
                                            {fieldSettingFormat(helpers.isEditableShowWhenBookingInField(_.index), _[tab].showOnBookingIn)}
                                        </td>
                                        <td className='text-center'>{fieldSettingFormat(helpers.isEditablePersistentField(_.index), _[tab].persisted)}</td>
                                        <td>{_[tab].isActive ? 'Active' : 'Inactive'}</td>
                                    </>
                                }

                                <td className='text-end'>
                                    <Link
                                        className={classnames('py-0 px-1', isIrrelevantField(_.index) ? 'text-muted' : 'text-primary')}
                                        title={isIrrelevantField(_.index) ? 'Cannot be a data field for a non-viable sample' : undefined}
                                        routeName={routes.SETTINGS_SAMPLE_FIELDS_EDIT}
                                        routeParams={{ id: _.id }}
                                        disabled={isIrrelevantField(_.index)}
                                        hasNoPermissions={!permissions.editCustomFields}
                                        testId={`custom-field-${_.fieldName}-edit`}
                                    >
                                        <i className='material-icons md-18'>create</i>
                                    </Link>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default CustomFieldList

function useCustomFields() {
    const load = useAction(customFieldsActions.loadCustomFieldsIncludingInactive)
        , [fields, setFields] = useState<CustomField[]>([])
        , contextSwitch = useContextSwitchObserver()
        , reload = useCallback(
            () => {
                load().then(setFields)
            },
            [load, setFields]
        )

    useEffect(() => { reload() }, [reload, contextSwitch])

    return [fields, reload] as const
}

function useDragAndDrop(reload: () => void) {
    const [dragDirection, setDragDirection] = useState<dom.DragDirection>(dom.NONE)
        , [draggedField, setDraggedField] = useState<CustomField>()
        , [dropField, setDropField] = useState<CustomField>()
        , saveCustomField = useAction(actions.saveCustomField)
        , showSpinner = useAction(spinnerAction.showSpinner)
        , hideSpinner = useAction(spinnerAction.hideSpinner)
        , permissions = useSelector(_ => _.auth.permissions)
        , dragEvents = !permissions.editCustomFields
            ? undefined
            : {
                onDragStart: handleDragStart,
                onDragEnd: handleDragEnd,
                onDragOver: handleDragOver,
                onDragLeave: handleDragLeave,
                onDrop: handleDragDrop,
            }

    function handleDragStart(e: React.DragEvent<HTMLTableRowElement>, field: CustomField) {
        e.dataTransfer.effectAllowed = 'move'

        setDraggedField(field)
    }

    function handleDragEnd() {
        setDraggedField(undefined)
        setDragDirection(dom.NONE)
    }

    function handleDragOver(e: React.DragEvent<HTMLTableRowElement>, field: CustomField) {
        if (draggedField === undefined || field === draggedField || helpers.isDefaultDataField(field.index))
            return

        const direction = dom.getElementDragDirection(e.currentTarget, e)

        if (direction === dom.TOP && draggedField.position !== (field.position - 1)) {
            setDragDirection(direction)
        }
        if (direction === dom.BOTTOM && draggedField.position !== (field.position + 1)) {
            setDragDirection(direction)
        }

        setDropField(field)
        e.preventDefault()
    }

    function handleDragDrop(e: React.DragEvent<HTMLTableRowElement>) {
        if (draggedField === undefined || dropField === undefined)
            return

        handleMove(draggedField, dropField)
        setDragDirection(dom.NONE)

        e.preventDefault()
    }

    function handleDragLeave() {
        setDragDirection(dom.NONE)
    }

    function handleMove(draggedField: CustomField, dropField: CustomField) {
        const newCustomField = { ...draggedField, position: dropField.position }

        showSpinner()
        saveCustomField({ id: draggedField.id, oldCustomField: draggedField, newCustomField })
            .then(reload)
            .finally(hideSpinner)
    }

    return [
        dragEvents,
        dropField,
        dragDirection,
    ] as const
}
