import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type ReasonService from '_/model/reason/reason-service'
import type PredefinedListsService from '_/services/predefined-lists-service'
import type DeviceService from '_/services/device-service'

import { updateDeviceTransaction  } from '_/model/predefined-lists/devices/transaction'

import * as actions from './actions'

const factory = (service: PredefinedListsService, reasonService: ReasonService, deviceService: DeviceService): EffectsFactory => () => [
    handler(actions.loadDevices, service.devices.contextList),
    handler(actions.createDevice, payload => service.devices.createDevice(payload.id, payload.device)),
    handler(actions.changeDeviceName, payload => service.devices.changeDeviceName(payload.id, payload.name)),
    handler(actions.removeDevice, payload => service.devices.removeDevice(payload.id, payload.signatureSettings)),
    handler(actions.saveDeviceChanges, payload => updateDeviceTransaction(payload.id, payload.oldDevice, payload.newDevice, payload.signatureSettings, service, reasonService)),
    handler(actions.getDevices, deviceService.getDevices)
]

export default factory
