import * as s from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'

interface Props {
    sampleStatus: s.MonitoringOverviewSampleStatuses
}

function FilterSampleIcon(props: Props) {
    const size = 11
        , radius = size / 2
        , innerRadius = radius * 0.7
        , lineWidth = radius - innerRadius
        , primaryColor = '#6c757d'
        , innerColor = '#FAFAFA'
        , fill = props.sampleStatus === s.SCHEDULED_NOT_BOOKED_IN || props.sampleStatus === s.MISSING ? primaryColor : innerColor
        , strokeDasharray = props.sampleStatus === s.NOT_IN_USE ? 2 : undefined

    return (
        <svg
            className='align-baseline'
            width={size} height={size} viewBox={`0 0 ${size} ${size}`}
            version='1.1' xmlns='http://www.w3.org/2000/svg'
            fill='white' stroke='none' strokeWidth='0'
        >
            <circle cx={radius} cy={radius} r={radius - lineWidth/2} fill={fill} strokeDasharray={strokeDasharray} stroke={primaryColor} strokeWidth={lineWidth} />

            {props.sampleStatus === s.MISSING &&
                <g transform={`translate(${radius} ${radius}) rotate(-45)`}>
                    <path d={`M ${-innerRadius} 0 h ${innerRadius * 2}`} stroke={primaryColor} strokeWidth={lineWidth * 2} />
                    <path d={`M ${-radius} 0 h ${radius * 2}`} stroke={innerColor} strokeWidth={lineWidth} />
                </g>
            }
        </svg>
    )
}

export default FilterSampleIcon
