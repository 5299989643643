import type { FormRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { getIn } from 'final-form'

import { SelectField, LocalDateField, TextTimeField, CheckboxField, MultiSelectField } from '_/components/form'
import { TextField } from '_/components/form/text-field'
import OptionalDate from '_/components/optional-date'
import NumberTextField from '_/components/form/number-text-field'
import MonitoringStateField from '_/components/monitoring-state-field'

import type { NonViableSampleForm } from '_/model/non-viable-sample/booking/types'
import type { PredefinedLists } from '_/model/app-state'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'

import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'
import type { subLocationList } from '_/features/samples/booking/helpers'
import { getEntities } from '_/features/samples/booking/helpers'
import * as fieldTypes from '_/constants/custom-field-column-type'
import * as fieldIndex from '_/constants/custom-field-index'
import * as h from '_/features/samples/helpers'
import BatchNumberField from '_/features/samples/shared/batch-number-field'

interface Props {
    pos: number
    form: FormRenderProps<Partial<NonViableSampleForm>>
    initialValue: NonViableSampleForm
    exposureLocations: ListExposureLocation[]
    predefinedLists: PredefinedLists
    fieldPrefix: string
    locationChildren: ReturnType<typeof subLocationList>
    hideOptionalDate: boolean
    getRef(key: string): React.RefObject<HTMLInputElement> | undefined
    setRef: (_: string) => void
}

function NonViableSampleCustomFields(props: Props) {
    const customField = props.predefinedLists.customFields[props.pos]
        , fieldId = `${props.fieldPrefix}field-${customField.index}`
        , notRecordedFieldId = `${fieldId}.notRecorded`
        , namePrefix = `fields[${props.pos}]`
        , valueName = `${namePrefix}.value`
        , notRecordedName = `${namePrefix}.notRecorded`
        , notRecordedState = props.form.form.getFieldState(notRecordedName as any)
        , formFieldHasValue = !!(notRecordedState && notRecordedState.value)
        , label = h.formatFieldLabel(customField)
        , atRestName = fieldId + 'atRest'
        , differentDateName = 'show-' + `${props.fieldPrefix}field-${fieldIndex.EXPOSURE_END_DATE}`
        , isLocationField = customField.index === fieldIndex.EXPOSURE_LOCATION_ID

    if (customField.nonViableSettings.notRecorded)
        props.setRef(notRecordedName)

    if (customField.index === fieldIndex.BARCODE || customField.index === fieldIndex.EXPOSURE_END_TIME)
        props.setRef(fieldId)

    if (customField.index === fieldIndex.EXPOSURE_END_TIME)
        props.setRef(differentDateName)

    if (isLocationField)
        props.setRef(atRestName)

    if (customField.index === fieldIndex.EXPOSURE_END_DATE)
        return null

    switch (customField.fieldType) {
        case fieldTypes.DATE: {
            return <div key={namePrefix}>
                <LocalDateField autoFocus id={fieldId} name={valueName}>{label}</LocalDateField>
            </div>
        }
        case fieldTypes.TEXT: {
            return <div key={namePrefix}>
                {customField.index === fieldIndex.BATCH_NUMBER
                    ? <BatchNumberField
                        id={fieldId}
                        name={valueName}
                        disabled={formFieldHasValue}
                        editing
                        hasLabel
                        className='me-1'
                        onKeyDown={_ => customField.viableSettings.notRecorded && focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                    />
                    : <TextField
                        id={fieldId}
                        name={`${namePrefix}.value`}
                        className='me-1'
                        inputRef={props.getRef(fieldId)}
                        disabled={formFieldHasValue}
                        onKeyDown={_ => customField.nonViableSettings.notRecorded && focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                    >
                        {label}
                    </TextField>
                }
                {customField.nonViableSettings.notRecorded &&
                    <CheckboxField
                        id={notRecordedFieldId}
                        name={notRecordedName}
                        className='mx-1'
                        tabIndex={formFieldHasValue ? 0 : -1}
                        inputRef={props.getRef(notRecordedName)}
                    >
                        Not recorded
                    </CheckboxField>
                }
            </div>
        }
        case fieldTypes.NUMBER: {
            return <div key={namePrefix}>
                <NumberTextField
                    id={fieldId}
                    name={`${namePrefix}.value`}
                    className='me-1'
                    inputRef={props.getRef(fieldId)}
                    disabled={formFieldHasValue}
                    onKeyDown={_ => customField.nonViableSettings.notRecorded && focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                >
                    {label}
                </NumberTextField>
                {customField.nonViableSettings.notRecorded &&
                    <CheckboxField
                        id={notRecordedFieldId}
                        name={notRecordedName}
                        className='mx-1'
                        tabIndex={formFieldHasValue ? 0 : -1}
                        inputRef={props.getRef(notRecordedName)}
                    >
                        Not recorded
                    </CheckboxField>
                }
            </div>
        }
        case fieldTypes.TIME: {
            const endDatePos = props.predefinedLists.customFields.findIndex(_ => _.index === fieldIndex.EXPOSURE_END_DATE)
                , endDateName = h.valuePath(endDatePos)
                , startDatePos = props.initialValue.fields.findIndex(_ => _.index === fieldIndex.EXPOSURE_START_DATE)
                , startDateName = h.valuePath(startDatePos)
                , startDate = getIn(props.form.values, startDateName)
                , endDateId = `${props.fieldPrefix}field-${fieldIndex.EXPOSURE_END_DATE}`

            return <div style={{width: '11.5em'}} key={namePrefix}>
                <TextTimeField
                    id={fieldId}
                    name={valueName}
                    className='mx-1'
                    disabled={formFieldHasValue}
                    onKeyDown={_ => customField.index === fieldIndex.EXPOSURE_END_TIME
                        ? focusElementOnAltNInput(props.getRef(differentDateName), _)
                        : customField.nonViableSettings.notRecorded && focusElementOnAltNInput(props.getRef(notRecordedName), _)
                    }
                    inputRef={props.getRef(fieldId)}
                >
                    {label}
                </TextTimeField>
                {customField.index === fieldIndex.EXPOSURE_END_TIME &&
                    <OptionalDate
                        className='mx-1'
                        disabled={formFieldHasValue}
                        forceHideDateField={formFieldHasValue || props.hideOptionalDate}
                        showDateFieldId={'show-' + endDateId}
                        dateFieldId={endDateId}
                        dateFieldName={endDateName}
                        form={props.form}
                        endTimeRef={props.getRef(fieldId)!}
                        differentDateRef={props.getRef(differentDateName)}
                        startDate={startDate}
                        onKeyDown={_ => customField.nonViableSettings.notRecorded && focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                    />
                }
                {customField.nonViableSettings.notRecorded &&
                    <CheckboxField
                        id={notRecordedFieldId}
                        name={notRecordedName}
                        className='mx-1'
                        tabIndex={formFieldHasValue ? 0 : -1}
                        inputRef={props.getRef(notRecordedName)}
                        onKeyDown={_ => customField.index === fieldIndex.EXPOSURE_END_TIME && focusElementOnAltNInput(props.getRef(differentDateName), _)}
                    >
                        Not recorded
                    </CheckboxField>
                }
            </div>
        }
        case fieldTypes.SELECTION: {
            const operatorPos =  props.predefinedLists.customFields.findIndex(_ => _.index === fieldIndex.OPERATORS_IDS)
                , operatorsPath = h.valuePath(operatorPos)
                , operatorsValue = getIn(props.form.values, operatorsPath)

            return <div className='me-1' style={{width: '25em'}} key={namePrefix}>
                {customField.index === fieldIndex.OPERATORS_IDS
                    && <MultiSelectField
                        id={fieldId}
                        name={valueName}
                        entities={props.predefinedLists.sampleOperators}
                        calcId={_ => _.id}
                        calcName={_ => _.name}
                        autocomplete
                        disabled={formFieldHasValue}
                        onKeyDown={_ => focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                    >
                        {label}
                    </MultiSelectField>
                }
                {customField.index !== fieldIndex.OPERATORS_IDS
                    && <SelectField
                        id={fieldId}
                        name={valueName}
                        entities={getEntities(customField.index, props.exposureLocations, props.locationChildren, props.predefinedLists)}
                        calcId={_ => _.id}
                        calcName={_ => {
                            if (customField.index === fieldIndex.EXPOSURE_LOCATION_ID)
                                return _.pathName

                            if (customField.index === fieldIndex.MONITORING_POSITION)
                                return _.shortName

                            return _.name
                        }}
                        autocomplete
                        showEndOfText
                        disabled={formFieldHasValue}
                        onKeyDown={_ => focusElementOnAltNInput(props.getRef(isLocationField ? atRestName : notRecordedName), _)}
                    >
                        {label}
                    </SelectField>
                }
                {isLocationField &&
                    <MonitoringStateField
                        id={atRestName}
                        monitoringState={props.form.values.monitoringState!}
                        className='mx-1'
                        inputRef={props.getRef(atRestName)}
                        onKeyDown={_ => focusElementOnAltNInput(props.getRef(notRecordedName), _)}
                    />
                }
                {customField.nonViableSettings.notRecorded && customField.index !== fieldIndex.MONITORING_POSITION && (customField.index !== fieldIndex.OPERATORS_IDS || !operatorsValue)
                    ? <CheckboxField
                        id={notRecordedFieldId}
                        name={notRecordedName}
                        className='mx-1'
                        tabIndex={formFieldHasValue ? 0 : -1}
                        inputRef={props.getRef(notRecordedName)}
                        onKeyDown={_ => isLocationField && focusElementOnAltNInput(props.getRef(atRestName), _)}
                    >
                        Not recorded
                    </CheckboxField>
                    // required for final form field to be registered with given path
                    // otherwise MONITORING_POSITION will not be disabled (due to specific logic of this method)
                    : <Field name={notRecordedName} render={_ => null}/>
                }
            </div>
        }
        default: {
            return null
        }
    }
}

export default NonViableSampleCustomFields
