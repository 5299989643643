import { formatFieldLabel, formatSampleTypeField } from '../helpers'

import { NOT_AVAILABLE } from '_/constants/common-messages'
import * as fieldIndex from '_/constants/custom-field-index'
import * as routes from '_/constants/routes'

import type { PredefinedLists } from '_/model/app-state'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type Sample from '_/model/sample/sample'
import * as h from '_/model/sample/reading/helpers'
import * as f from '_/model/sample/format'
import * as t from '_/model/text/text'

import FormattedText from '_/features/text/formatted-text'
import Legend from '_/components/legend'
import Link from '_/components/link'
import Barcode from '_/components/barcode'

import { customFieldName } from '_/model/predefined-lists/custom-field/custom-field'

import {
    getFieldValue,
    getNotDefaultCustomFields,
} from '../helpers'
import { useTimeService } from '_/components/time'
import { useMonitoringGroups } from '_/hooks/shared-hooks'

interface Props {
    sample: Sample
    predefinedLists: PredefinedLists
}

function SampleInformation(props: Props) {
    const timeService = useTimeService()
        , [monitoringGroups, adHocGroups] = useMonitoringGroups()

    function buildNotDefaultField(field: CustomField) {
        return <div className='row' key={field.id}>
            <div className='col-4 word-wrap-break-word'>{formatFieldLabel(field)}</div>
            <div className='col-8 sample-reading__word-wrap'>
                <FormattedText text={f.formatByFieldIndex(field.index, props.sample.fields, props.predefinedLists)} />
            </div>
        </div>
    }

    function getFieldName(fieldIndex: fieldIndex.FieldIndex) {
        return customFieldName(fieldIndex, props.predefinedLists.customFields)
    }

    const gradeLimitsValue = h.getGradeLimitsValue(props.sample, props.predefinedLists.grades, props.predefinedLists.limits)
        , sessionName = f.formatByFieldIndex(fieldIndex.SESSION_ID, props.sample.fields, props.predefinedLists)
        , sessionNameOrNA = t.plainText(sessionName) === '' ? [t.systemTextNode(NOT_AVAILABLE)] : sessionName

    return (
        <fieldset>
            <Legend>Viable sample information</Legend>
            <div className='row'>
                <div className='col-4'>{getFieldName(fieldIndex.BARCODE)}</div>
                <Barcode className='col-8 sample-reading__word-wrap'>
                    <FormattedText text={f.formatByFieldIndex(fieldIndex.BARCODE, props.sample.fields, props.predefinedLists)} />
                </Barcode>
            </div>
            <div className='row'>
                <div className='col-4'>{getFieldName(fieldIndex.EXPOSURE_START_DATE)}</div>
                <div className='col-8 sample-reading__word-wrap'>{timeService.formatCtzDate(getFieldValue(props.sample.fields, fieldIndex.EXPOSURE_START_DATE))}</div>
            </div>
            <div className='row'>
                <div className='col-4'>Exposure time</div>
                <div className='col-8 sample-reading__word-wrap'>
                    <FormattedText text={f.formatExposureDurationByFields(props.sample.fields, props.predefinedLists.customFields, timeService)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4'>Monitoring group</div>
                <div className='col-8 sample-reading__word-wrap'>
                    <FormattedText text={f.formatSampleMonitoringGroup(props.sample.fields, monitoringGroups, adHocGroups, props.predefinedLists.exposureLocations)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4'>{getFieldName(fieldIndex.EXPOSURE_LOCATION_ID)}</div>
                <div className='col-8 sample-reading__word-wrap'>
                    <FormattedText text={f.formatExposureLocation(props.sample.monitoringState, props.sample.fields, props.predefinedLists)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4'>Sample type</div>
                <div className='col-8'>
                    {formatSampleTypeField(props.sample)}
                </div>
            </div>
            <div className='row'>
                <div className='col-4'>{getFieldName(fieldIndex.SESSION_ID)}</div>
                <div className='col-8 sample-reading__word-wrap'>
                    <FormattedText text={sessionNameOrNA} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4'>{getFieldName(fieldIndex.OPERATORS_IDS)}</div>
                <div className='col-8 sample-reading__word-wrap user-formatted-text'>
                    <FormattedText text={f.formatOperators(props.sample.fields, props.predefinedLists)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4'>Grade</div>
                <div className='col-8 sample-reading__word-wrap'>
                    <FormattedText text={f.formatExposureLocationGrade(props.sample.monitoringState, props.sample.fields, props.predefinedLists)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4'>Current alert limit</div>
                <div className='col-8 sample-reading__word-wrap' data-testid='current-alert-limit'>{gradeLimitsValue.limits.alertLimit}</div>
            </div>
            <div className='row'>
                <div className='col-4'>Current action limit</div>
                <div className='col-8 sample-reading__word-wrap' data-testid='current-action-limit'>{gradeLimitsValue.limits.actionLimit}</div>
            </div>
            {getNotDefaultCustomFields(props.predefinedLists.customFields).map(_ => buildNotDefaultField(_))}

            <Link
                className='d-print-none mt-2 d-block'
                routeName={routes.SAMPLES_EDIT}
                routeParams={{ id: props.sample.id }}
                testId='read-go-to-full-record'
            >
                Go to this viable sample’s full record
            </Link>
        </fieldset>
    )
}

export default SampleInformation
