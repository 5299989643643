import { Form } from 'react-final-form'

import { useAction } from '_/facade/react'
import { TextField, Submit, submitDisabled } from '_/components/form'
import { noop } from '_/utils/function'

import * as a from '../actions'
import validate from '_/model/auth/restore/forgot-password-validate'

function ForgotPassword() {
    const forgotPassword = useAction(a.forgotPassword)

    return (
        <div className='auth h-100'>
            <div className='d-flex justify-content-center'>
                <div className='auth-form'>
                    <div className='auth-form-header-text text-white text-center'>Restore password</div>
                        <Form onSubmit={_ => forgotPassword(_ as any).then(noop)}
                            validate={validate}
                            render={form =>
                                <form onSubmit={form.handleSubmit}>
                                    <TextField testId='field-email' id='auth-email' name='email' placeholder='Email' />
                                    <Submit className='auth-form-submit w-100' disabled={submitDisabled(form)} testId='reset-password'>Reset password</Submit>
                                </form>
                            }
                        />
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
