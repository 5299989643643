import { actionFactory } from '_/utils/redux'
import type { OrganismIdentification, SearchOrganisms, OrganismIdentificationListQuery } from '_/model/predefined-lists/organism-identification/types'
import type Page from '_/model/page'
import type PaginationState from '_/model/pagination-state'
import type { Events } from '_/model/audit-trail/types'

const action = actionFactory('app.organism-identification')

export const loadOrganismIdentificationList = action<OrganismIdentificationListQuery, Promise<Page<OrganismIdentification>>>('loadOrganismIdentificationList')
           , loadOrganismIdentificationTrail = action<PaginationState, Promise<Page<Events>>>('loadOrganismIdentificationTrail')
           , createOrganismIdentification = action<OrganismIdentification, Promise<void>>('createOrganismIdentification')
           , removeOrganismIdentification = action<string, Promise<void>>('removeOrganismIdentification')
           , importOrganismIdentification = action<File[], Promise<void>>('importOrganismIdentification')
           , searchOrganismIdentification = action<SearchOrganisms, Promise<OrganismIdentification[]>>('searchOrganismIdentification')
           , loadOrganismIdentification = action<string, Promise<OrganismIdentification>>('loadOrganismIdentification')
           , saveOrganismIdentification = action<{ id: string, isActive: boolean }, Promise<void>>('saveOrganismIdentification')
