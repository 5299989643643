import { useAction, useSelector, useState } from '_/facade/react'
import { Form } from 'react-final-form'

import { required } from '_/utils/form/validate'

import { Modal, ModalBody, ModalHeader } from '_/components/modal'
import { Close } from '_/components/button'
import type { Signature, SignatureMethod } from '_/model/critical-change-reason/types'


import { useError } from './helpers'
import * as actions from './actions'
import SignatureFields from './signature-fields'
import ReasonModalFooter from './modal-footer'

function ElectronicSignatureModal() {
    const cancel = useAction(actions.cancelActionWithSignature)
        , handleSubmit = useSubmit()
        , ssoESignatureAllowed = useSelector(_ => _.auth.permissions).useSsoESignature
        , [signatureMethod, setSignatureMethod] = useState<SignatureMethod | undefined>(ssoESignatureAllowed ? undefined : 'smartControl')

    function validate(entity: Signature) {
        if (entity.type === 'smartControl') {
            const  password = required('Password')(entity.password)
            return password ? { password } : {}
        }
    }

    useError()

    return (
        <Modal
            isOpen
            shouldCloseOnOutsideClick={false}
            onClose={cancel}
            contentClassName='overflow-auto'
        >
            <Form
                initialValues={{ type: signatureMethod }}
                onSubmit={handleSubmit}
                validate={validate}
                render={form =>
                    <div>
                        <ModalHeader className='border-bottom-0 pb-0'>
                            <h4>Confirm your password</h4>
                            <Close onClick={cancel} />
                        </ModalHeader>
                        <ModalBody noDefaultHeight>
                            <form onSubmit={form.handleSubmit}>
                                <SignatureFields
                                    signatureMethod={signatureMethod}
                                    ssoESignatureAllowed={ssoESignatureAllowed}
                                    setSignatureMethod={setSignatureMethod}
                                />
                            </form>
                        </ModalBody>
                        <ReasonModalFooter
                            form={form}
                            signatureRequired
                            cancel={cancel}
                            ssoESignatureAllowed={ssoESignatureAllowed}
                            setSignatureMethod={setSignatureMethod}
                            signatureMethod={signatureMethod}
                        />
                    </div>
                }
            />
        </Modal>
    )
}

export default ElectronicSignatureModal

function useSubmit() {
    const allowActionWithSignature = useAction(actions.allowActionWithSignature)

    function handleSubmit(entity: Signature) {
        return allowActionWithSignature(entity)
    }

    return handleSubmit
}
