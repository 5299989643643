import { useField } from 'react-final-form'
import { showFieldError } from './helpers'
import { classnames } from '_/facade/react'
import NumberInput from './number-input'

interface InputFieldProps {
    id?: string
    name: string
    className?: string
    disabled?: boolean
    inputRef?: React.RefObject<HTMLInputElement>
    children?: React.ReactNode
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    testId?: string
}

function NumberInputField(props: InputFieldProps) {
    const field = useField(props.name)

    return (
        <div className={`mb-3 ${props.className ?? ''}`}>
            <label htmlFor={props.id} className='col-form-label' data-testid='field-label'>{props.children}</label>
            <NumberInput
                id={props.id}
                ref={props.inputRef}
                className={classnames('form-control', { 'is-invalid': showFieldError(field.meta) })}
                {...field.input}
                disabled={props.disabled}
                onKeyDown={props.onKeyDown}
                testId={props.testId}
            />
            {showFieldError(field.meta) && <span className='invalid-feedback' data-testid='validation-error'>{field.meta.error || field.meta.submitError}</span>}
        </div>
    )
}

export default NumberInputField
