const NO_ID_REQUIRED_NAME = 'No ID required'
    , NO_ID_REQUIRED_ID = '09fdce8f-1495-4228-a32e-bb04e067aa30'

    , NO_ID_REQUIRED = {
        id: NO_ID_REQUIRED_ID,
        name: NO_ID_REQUIRED_NAME,
        isGenus: false,
        inUse: true,
        isActive: true,
    }
export {
    NO_ID_REQUIRED_NAME,
    NO_ID_REQUIRED_ID,
    NO_ID_REQUIRED,
}
