const DATETIME = 'dateTime'
const DATEONLY = 'dateOnly'
const TIMEONLY = 'timeOnly'
const DATETIMERANGE = 'dateTimeRange'

import type TimeService from '_/services/time-service'
import { SETTLE_PLATE } from '_/constants/plate-type'
import type { DateTime } from '_/model/date-time'
import * as f from '_/model/sample/format'
import * as t from '_/model/text/text'
import { SAMPLE } from '_/constants/entity-type'

function preprocessTrailMessage(timeService: TimeService, messageNode: t.TextNode, entityType: number) {
    const message = messageNode.value
        , placeholders = message.match(/@(.+?)@/g)

    if (!placeholders)
        return messageNode

    return placeholders.flatMap(_ => {
        const splitValues = _.replace(/@/g, '').split('|')

        return splitValues.length === 2
            ? replace(timeService, splitValues[0], splitValues[1] as DateTime, entityType)
            : t.emptyTextNode()
    })
}

function replace(timeService: TimeService, type: string, value: DateTime, entityType: number): t.TextNode | t.TextNode[] {
    switch (type) {
        case DATETIME:
            return t.defaultTextNode(' ' + timeService.formatCtzDateTime(value))
        case DATEONLY:
            return t.defaultTextNode(' ' + timeService.formatCtzDate(value))
        case TIMEONLY:
            return t.defaultTextNode(' ' + timeService.formatCtzTime(value))
        case DATETIMERANGE:
            const parsed = parse(value, timeService)
            return [t.defaultTextNode(' ' + timeService.formatCtzDate(parsed.exposureStartDate) + ' '), ...formatExposureDuration(timeService, parsed, entityType)]
        default:
            return t.defaultTextNode(value)
    }
}

type ExposureDurationType = {
    exposureStartTime?: string | undefined
    exposureStartDate: DateTime
    exposureStartTimeAvailable: boolean
    exposureStartTimeNotRecorded: boolean
    exposureEndTime?: string | undefined
    exposureEndDate: DateTime | undefined
    exposureEndTimeNotRecorded: boolean
    exposureEndTimeAvailable?: boolean | undefined
    plateType?: number
}

function parse(value: string, timeService: TimeService) {
    const dateTimeRange = JSON.parse(value)
    return convertExposureDateTime(timeService, dateTimeRange) as ExposureDurationType
}

function convertExposureDateTime(timeService: TimeService, _: any) {
    const startTime = timeService.splitCtzDateTime(_.exposureStartTime)
        , endTime = timeService.splitCtzDateTime(_.exposureEndTime)
        , sample = Object.assign({}, _, {
            exposureStartTime: _.exposureStartTimeNotRecorded || !_.exposureStartTimeAvailable ? undefined : startTime.time,
            exposureEndTime: _.exposureEndTimeNotRecorded || !_.exposureEndTimeAvailable ? undefined : endTime.time,
            exposureStartDate: startTime.date!,
            exposureEndDate: endTime.date || startTime.date!,
        })

    return sample
}

function formatExposureDuration(timeService: TimeService, sample: ExposureDurationType, entityType: number): t.Text {
    return entityType === SAMPLE
        ? formatViableExposureDuration(timeService, sample)
        : formatNonViableExposureDuration(timeService, sample)
}

function getExposureTime(time: string | undefined, date: DateTime | undefined, timeNotRecorded: boolean) {
    return { time, timeNotRecorded, date }
}

function formatViableExposureDuration(timeService: TimeService, sample: ExposureDurationType): t.Text {
    const canHaveEnd = sample.plateType !== undefined ? sample.plateType === SETTLE_PLATE : true
        , hasEndTime = sample.exposureEndDate && canHaveEnd

    return f.formatExposureDuration(
        getExposureTime(sample.exposureStartTime, sample.exposureStartDate, sample.exposureStartTimeNotRecorded),
        hasEndTime && getExposureTime(sample.exposureEndTime, sample.exposureEndDate, sample.exposureEndTimeNotRecorded),
        timeService
    )
}

function formatNonViableExposureDuration(timeService: TimeService, sample: ExposureDurationType): t.Text {
    return f.formatExposureDuration(
        getExposureTime(sample.exposureStartTime, sample.exposureStartDate, sample.exposureStartTimeNotRecorded),
        getExposureTime(sample.exposureEndTime, sample.exposureEndDate, sample.exposureEndTimeNotRecorded),
        timeService
    )
}

export default preprocessTrailMessage
