import type Context from '_/model/context/context'
import type { ContextInviteRequest, ContextInvite } from '_/model/context/invite/types'
import type { UserSignUp } from '_/model/auth/types'

import type ApiService from '../api-service'
import type ContextService from '../context-service'
import * as s from '_/model/context/electronic-signature-settings'
import type { IpRestrictionValidationResponse, IpInWhitelistValidationResponse, IpInContextWhitelistValidationResponse } from '_/model/context/edit/ip-restriction'

function factory(api: ApiService): ContextService {
    const resource = api.resource(['contexts'], s.CHANGING_SETTINGS)
    return Object.assign(
        resource,
        {
            save: resource.saveWithReason,
            remove: resource.removeWithReason,
            getContexts,
            invite,
            getContextInvite,
            assign,
            rejectContextInvitation,
            joinContext,
            resume,
            validateIpRestriction,
            validateIpInWhitelist,
            validateIpInContextWhitelist,
        },
    )

    function getContexts(): Promise<Context[]> {
        return api.get<Context[]>(['contexts'])
    }

    function invite(invite: ContextInviteRequest): Promise<void> {
        return api.post(['contexts', invite.contextId, 'invite'], invite)
    }

    function getContextInvite(token: string): Promise<ContextInvite> {
        return api.get<ContextInvite>(['invite', token])
    }

    function assign(token: string): Promise<void> {
        return api.post(['assign', token])
    }

    function rejectContextInvitation(token: string): Promise<void> {
        return api.post(['invite', token, 'reject'])
    }

    function joinContext(token: string, user: UserSignUp): Promise<void> {
        return api.post(['invite', token], user)
    }

    function resume(id: string, signatureSettings: s.ElectronicSignatureSettings[]): Promise<void> {
        return api.postWithReason(['contexts', id, 'resume'], s.CHANGING_SETTINGS, undefined, signatureSettings)
    }

    function validateIpRestriction(whitelist: string): Promise<IpRestrictionValidationResponse> {
        return api.post(['validations', 'ip-address'], { whitelist })
    }

    function validateIpInWhitelist(whitelist: string): Promise<IpInWhitelistValidationResponse> {
        return api.post(['validations', 'ip-in-whitelist'], { whitelist })
    }

    function validateIpInContextWhitelist(id: string): Promise<IpInContextWhitelistValidationResponse> {
        return api.post(['validations', 'ip-in-context-whitelist', id])
    }
}

export default factory
