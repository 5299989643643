import { diffObject } from '_/utils/object'
import type Api from '_/services/role-service'
import type { RoleEdit } from '_/model/roles/types'

function updateTransaction(
    id: string,
    current: RoleEdit,
    next: RoleEdit,
    api: Api,
): Promise<void> {
    const diff = diffObject(current, next)
    if (!diff)
        return Promise.resolve()

    return api.save(id, diff)
}

export {
    updateTransaction,
}
