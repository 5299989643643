import { diffObject } from '_/utils/object'
import type Api from '_/services/context-service'
import type { ContextEdit } from '_/model/context/edit/types'

import type * as s from '_/model/context/electronic-signature-settings'

function updateTransaction(
    id: string,
    current: ContextEdit,
    next: ContextEdit,
    signatureSettings: s.ElectronicSignatureSettings[],
    api: Api,
): Promise<void> {
    const diff = diffObject(current, next)

    if (!diff)
        return Promise.resolve()

    return api.save(id, diff, signatureSettings)
}

export {
    updateTransaction,
}
