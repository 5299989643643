import { replaceNullWithUndefined } from '_/services/impl/null-replacer'

/**
 * Overwrites and adds fields to Target from Augmentation
 * and removes specific fields from Drop
 */
type FieldsDiff<T> = {
    [P in keyof T]?: T[P] | undefined
}

type Redefine<Target extends object, Augmentation extends object, Drop extends keyof Target = never> =
    Augmentation & Pick<Target, Exclude<keyof Target, keyof Augmentation | Drop>>

function dropFields<T extends object, F extends keyof T>(target: T, ...fields: F[]): Omit<T, F> {
    const result = Object.assign({}, target)
    fields.forEach(_ => delete result[_])
    return result
}

function pickFields<T extends object, F extends keyof T>(target: T, ...fields: F[]): Pick<T, F> {
    const result: any = {}
    fields.forEach(_ => result[_] = target[_])
    return result
}

function shallowUpdate<T>(object: T, ...update: Partial<T>[]): T {
    return Object.assign({}, object, ...update)
}

function squash<T>(...objects: Partial<T>[]): T {
    return Object.assign({}, ...objects)
}

/**
 * Only plain objects with primitive field values and arrays are supported.
 * Objects with cyclic references are not supported
 * @param object Object to freeze
 */
function deepFreeze<T>(object: T): T {
    if (typeof object !== 'object' || object === null)
        return object

    if (Array.isArray(object)) {
        object.forEach(deepFreeze)
    }
    else {
        Object.keys(object)
            .map(key => (object as any)[key])
            .forEach(deepFreeze)
    }

    Object.freeze(object)
    return object
}

function deepCopy<T>(value: T): T {
    const parsedValue = JSON.parse(JSON.stringify(value, (_, v) => v !== undefined ? v : null))
    return replaceNullWithUndefined(parsedValue)
}

function diffObject<T>(oldObj: T, newObj: T): FieldsDiff<T> | undefined {
    if (oldObj === newObj)
        return undefined

    if (newObj == null)
        newObj = {} as T

    if (oldObj == null)
        oldObj = {} as T

    let haveDiff = false

    const oldObjAny = oldObj as any
    const newObjAny = newObj as any
    const resultAny = {} as any

    const oldIsObject = oldObj instanceof Object
        , newIsObject = newObj instanceof Object

    if (!oldIsObject && !newIsObject && oldObj !== newObj)
        return deepCopy(newObj as Partial<T>)

    const allNewObjKeys = Object.keys(newObj).filter(i => (newObjAny)[i] !== 'function')
    const allOldObjKeys = Object.keys(oldObj).filter(i => (oldObjAny)[i] !== 'function')

    const addedKeys = allNewObjKeys.filter(i => allOldObjKeys.indexOf(i) === -1)
    if (addedKeys.length > 0) {
        for (let i = 0; i < addedKeys.length; ++i) {
            if (newObjAny[addedKeys[i]] !== undefined)
                resultAny[addedKeys[i]] = newObjAny[addedKeys[i]]
        }

        if (Object.keys(resultAny).length > 0)
            haveDiff = true
    }

    const removedKeys = allOldObjKeys.filter(i => allNewObjKeys.indexOf(i) === -1)
    if (removedKeys.length > 0) {
        haveDiff = true
        for (let i = 0; i < removedKeys.length; ++i) {
            resultAny[removedKeys[i]] = undefined
        }
    }

    const sameKeys = allNewObjKeys.filter(i => allOldObjKeys.indexOf(i) !== -1)

    for (const i of sameKeys) {
        if (typeof newObjAny[i] === 'object' && newObjAny[i] !== undefined) {
            if (Array.isArray(newObjAny[i]) && Array.isArray(oldObjAny[i])) {
                if (areArraysDifferent(oldObjAny[i], newObjAny[i])) {
                    haveDiff = true
                    resultAny[i] = newObjAny[i]
                }
            }
            else if (Array.isArray(newObjAny[i]) || Array.isArray(oldObjAny[i])) {
                haveDiff = true
                resultAny[i] = newObjAny[i]
            }
            else {
                const nestedObjDiff = diffObject(oldObjAny[i], newObjAny[i])
                if (nestedObjDiff) {
                    haveDiff = true
                    resultAny[i] = nestedObjDiff
                }
            }
        }
        else {
            if (newObjAny[i] !== oldObjAny[i]) {
                haveDiff = true

                if (newObjAny[i] !== undefined) // need this check as 'undefined' type is not JSON serialized while 'null' is
                    resultAny[i] = newObjAny[i]
                else resultAny[i] = undefined
            }
        }
    }

    if (!haveDiff)
        return undefined
    else {
        return deepCopy(resultAny as Partial<T>)
    }
}

function areArraysDifferent(oldArray: any, newArray: any): boolean {
    if (oldArray.length != newArray.length) {
        return true
    }
    else {
        for (let i = 0; i < newArray.length; i++) {
            if (diffObject(oldArray[i], newArray[i]) !== undefined) {
                return true
            }
        }
    }

    return false
}

function setAllFields<T, V>(object: T, value: V): { [_ in keyof T]: V } {
    const result = {} as any

    Object.keys(object).forEach(
        key => result[key] = value
    )

    return result
}

// https://github.com/Microsoft/TypeScript/issues/14829#issuecomment-322267089
type NoInfer<T> = T & {[K in keyof T]: T[K]}

function getFields<T extends object, F extends keyof T>(from: T, predicate: (value: any) => boolean): F[] {
    return getAllFields(from).filter(_ => predicate(from[_])) as F[]
}

function getAllFields<T extends object, F extends keyof T>(from: T): F[] {
    return Object.keys(from) as F[]
}

export {
    shallowUpdate,
    squash,
    dropFields,
    pickFields,
    Redefine,
    deepFreeze,
    diffObject,
    FieldsDiff,
    setAllFields,
    NoInfer,
    getFields,
    getAllFields,
}
