import type { Users as State } from '_/model/app-state'
import { commonReducer, handler, shallowUpdate } from '_/facade/reducer'
import { mergeAndReplaceEntity } from '_/utils/array'
import * as actions from './actions'

const defaultState: State = {
    list: {
        items: [],
        totalCount: 0,
    },
    users: [],
}

export default commonReducer(
    defaultState,

    handler(actions.userListLoaded, (state, list) => shallowUpdate(state, { list })),

    handler(actions.clearUsers, state => shallowUpdate(state, { users: [] })),

    handler(actions.userChanged, (state, request) => shallowUpdate(state, { users: mergeAndReplaceEntity(state.users, request)})),
)
