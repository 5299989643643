import * as fieldIndex from '_/constants/custom-field-index'
import type Sample from '_/model/sample/sample'
import type { IdentificationRows } from '_/model/sample/reading/sample-reading'
import type SampleReading from '_/model/sample/reading/sample-reading'
import type { FieldsDiff } from '_/utils/object'
import { dropFields } from '_/utils/object'

export default function reasonRule(sample: Sample, diff: FieldsDiff<SampleReading> | undefined) {
    return !reasonNotRequired(sample, diff)
}

function reasonNotRequired(sample: Sample, diff: FieldsDiff<SampleReading> | undefined): boolean {
    const trackableDiff = dropFields(diff ?? {}, 'detachedImages', 'totalCfuAtLatestMismatch', 'identificationHighestReference')

    if (!sample.firstReadAt || Object.keys(trackableDiff).length === 0)
        return true

    if (trackableDiff.identifications?.rows || trackableDiff.optionalIdentifications?.rows)
        return false

    function idCompleteUpdated(idRows: IdentificationRows | undefined, field: number): boolean {
        return idRows?.complete !== undefined && sample.editedFields.some(_ => _ === field)
    }

    if (idCompleteUpdated(trackableDiff.identifications, fieldIndex.IDENTIFICATION_COMPLETE))
        return false

    if (idCompleteUpdated(trackableDiff.optionalIdentifications, fieldIndex.OPTIONAL_IDENTIFICATION_COMPLETE))
        return false

    return true
}
