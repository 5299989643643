import { SelectField, NumberFieldInline } from '_/components/form'
import type { TrendTimeframe} from '_/model/predefined-lists/trend-settings/timeframe-type'
import { FOR_CONSECUTIVE_SAMPLES, TREND_TIMEFRAME, WITHIN } from '_/model/predefined-lists/trend-settings/timeframe-type'
import { TREND_TIME_PERIOD } from '_/model/predefined-lists/trend-settings/time-period'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import type { FilterEdit } from '_/model/predefined-lists/trend-settings/types'
import type * as st from '_/model/predefined-lists/trend-settings/types'
import * as ft from '_/model/predefined-lists/trend-settings/filter-type'
import { isRestrictedFilterForConsecutiveSamples } from '_/model/predefined-lists/trend-settings/helpers'

interface Props {
    disabled: boolean | undefined
}

function Timeframe(props: Props) {
    const timeframe = useField<st.Timeframe>('timeframe').input.value
        , filter = useFieldArray<FilterEdit>('filters').fields.value
        , restrictedFilters = filter
            .filter(_ => isRestrictedFilterForConsecutiveSamples(_.type))
            .map(_ => `'${ft.TREND_FILTER.find(f => f.id === _.type)?.name.toLowerCase()}'`)

    function calcDisabled(type: TrendTimeframe) {
        return type === FOR_CONSECUTIVE_SAMPLES
            && restrictedFilters.length > 0
    }

    return (
        <div className='mt-3'>
            <h5>Timeframe</h5>
            <div className='row g-2'>
                <SelectField
                    name='timeframe.type'
                    entities={TREND_TIMEFRAME}
                    calcId={_ => _.id}
                    calcName={_ => _.name}
                    calcDisabled={_ => calcDisabled(_.id)}
                    calcDisabledTitle={_ => `${restrictedFilters.join(', ')} not allowed with 'for consecutive samples' timeframe`}
                    className='col-5'
                    disabled={props.disabled}
                >
                    Timeframe
                </SelectField>
                <NumberFieldInline
                    name='timeframe.count'
                    disabled={props.disabled}
                    className='col-2'
                    formGroup
                >
                    Number
                </NumberFieldInline>
                {timeframe.type === WITHIN &&
                    <SelectField
                        name='timeframe.period'
                        entities={TREND_TIME_PERIOD}
                        calcId={_ => _.id}
                        calcName={_ => _.name}
                        className='col-5'
                        disabled={props.disabled}
                    >
                        Time period
                    </SelectField>
                }
            </div>
            {timeframe.type === FOR_CONSECUTIVE_SAMPLES &&
                <div className='alert alert-warning'>
                    Note. Samples with n/r time will not be counted for 'consecutive viable samples' timeframe
                </div>
            }
        </div>
    )
}

export default Timeframe
