
import type { RoleEdit } from '_/model/roles/types'
import type Role from '_/model/roles/types'
import { CHANGING_SETTINGS } from '_/model/context/electronic-signature-settings'

import type ApiService from '../api-service'
import type RoleService from '../role-service'

function factory(api: ApiService): RoleService {
    const resource = api.resource(['user-roles'], CHANGING_SETTINGS)
    return {
        ...resource,
        save: resource.saveWithReason,
        remove: resource.removeWithReason,
        getContextRoles,
        createInContext,
    }

    function getContextRoles(contextId: string): Promise<Role[]> {
        return api.get(['context', contextId, 'user-roles'])
    }

    function createInContext(contextId: string, role: RoleEdit): Promise<void> {
        return api.post(['context', contextId, 'user-roles'], role)
    }
}

export default factory
