import { classnames, useEffect, useState } from '_/facade/react'

import Button from '_/components/button'
import Menu from '_/components/overlay/menu'

import { minLengthForSearch } from '_/utils/form/validate'
import { useDebounce, useSyncRef } from '_/hooks/shared-hooks'

type SearchMenu<T> =
    | { tag: 'no-menu' }
    | { tag: 'not-valid-menu', error: string }
    | { tag: 'search-menu', searchResult: T }

type LoadByBarcode<T> = (barcode: string) => Promise<T>
type HasExactMatch<T> = (searchResult: T) => boolean
type GetTotalMatches<T> = (searchResult: T) => number
type NavigateToSampleDetails<T> = (barcode: string, searchResult: T) => void
type NavigateToSampleList<T> = (barcode: string, searchResult: T) => void

interface Props<T> {
    readPermission: boolean
    loadByBarcode: LoadByBarcode<T>
    hasExactMatch: HasExactMatch<T>
    getTotalMatches: GetTotalMatches<T>
    navigateToSampleDetails: NavigateToSampleDetails<T>
    navigateToSampleList: NavigateToSampleList<T>
    renderMenu: (props: { barcode: string, searchResult: T, onClose: () => void }) => React.ReactNode
}

function SampleSearchCommon<T>(props: Props<T>) {
    const [barcode, setBarcode] = useState('')
        , [menu, setMenu] = useState<SearchMenu<T>>({ tag: 'no-menu' })

    useInputValueChange(barcode, setMenu, props)

    const [input, inputRef] = useState<HTMLInputElement | null>(null)
        , [searchBtn, searchBtnRef] = useState<HTMLButtonElement | null>(null)
        , [showInput, setShowInput] = useState(false)

    function handleClose() {
        setBarcode('')
        setMenu({ tag: 'no-menu' })
        setShowInput(false)
    }

    useHideInput(input, handleClose, searchBtn)

    function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter')
            e.preventDefault()

        if (e.key === 'Tab' && (e.target as any).value !== '')
            e.preventDefault()
    }

    return (
        <form>
            <Button
                ref={searchBtnRef}
                className={classnames('text-light blue-focus pe-0 mx-2', showInput && 'd-none')}
                onClick={() => setShowInput(true)}
                hasNoPermissions={!props.readPermission}
            >
                <i className='material-icons'>search</i>
            </Button>
            {showInput &&
                <div className='position-relative'>
                    <input
                        ref={inputRef}
                        className='form-control'
                        type='search'
                        placeholder='Barcode search...'
                        aria-label='Search'
                        value={barcode}
                        onChange={e => setBarcode(e.target.value)}
                        onKeyDown={handleKeyPress}
                        autoFocus
                    />
                </div>
            }

            {menu.tag !== 'no-menu' &&
                <Menu element={input}>
                    <div className='bg-dark p-3 mt-2 search-popup text-light'>
                        {menu.tag === 'not-valid-menu'
                            ? <p>{menu.error}</p>
                            : props.renderMenu({ barcode, searchResult: menu.searchResult, onClose: handleClose })
                        }
                    </div>
                </Menu>
            }
        </form>
    )
}

export default SampleSearchCommon

function useHideInput(input: HTMLInputElement | null, hideInput: () => void, searchBtn: HTMLButtonElement | null) {
    useEffect(
        () => {
            function handleHideInput(event: FocusEvent) {
                function contains(node: Node | null) {
                    if (!(event.target instanceof Node))
                        return

                    return searchBtn?.contains(event.target)
                        || node?.contains(event.target)
                }

                if (!contains(input))
                    hideInput()
            }

            if (input)
                document.addEventListener('click', handleHideInput)

            return () => {
                if (input)
                    document.removeEventListener('click', handleHideInput)
            }
        },
        [input, searchBtn, hideInput]
    )
}

function useInputValueChange<T>(
    barcode: string,
    setMenu: (_: SearchMenu<T>) => void,
    props: Props<T>,
) {
    const debounce = useDebounce()
        , callbacksRef = useSyncRef(props)

    useEffect(
        () => {
            let disposed = false
            const callbacks = callbacksRef.current

            debounce(() => {
                const validationResult = minLengthForSearch(4, 'barcode')(barcode)

                if (validationResult) {
                    setMenu({ tag: 'not-valid-menu', error: 'Enter at least four characters to search for a barcode' })
                    return
                }

                if (barcode === '') {
                    setMenu({ tag: 'no-menu' })
                    return
                }

                callbacks.loadByBarcode(barcode).then(searchResult => {
                    if (disposed)
                        return

                    if (callbacks.hasExactMatch(searchResult) && callbacks.getTotalMatches(searchResult) === 1) {
                        setMenu({ tag: 'no-menu' })
                        callbacks.navigateToSampleDetails(barcode, searchResult)
                    }
                    else if (!callbacks.hasExactMatch(searchResult) && callbacks.getTotalMatches(searchResult) >= 1) {
                        setMenu({ tag: 'no-menu' })
                        callbacks.navigateToSampleList(barcode, searchResult)
                    }
                    else {
                        setMenu({ tag: 'search-menu', searchResult })
                    }
                })
            })

            return () => { disposed = true }
        },
        [barcode, callbacksRef, debounce, setMenu]
    )
}
