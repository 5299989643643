const CONFIRM_BOOK_IN = 0
    , VERIFY_CFU_COUNT = 1

type BulkOperationId = never
    | typeof CONFIRM_BOOK_IN
    | typeof VERIFY_CFU_COUNT

const BULK_OPERATION: { id: BulkOperationId, name: string }[] = [
    {
        id: CONFIRM_BOOK_IN,
        name: 'Book in confirmation',
    },
    {
        id: VERIFY_CFU_COUNT,
        name: 'Verify CFU counts',
    },
]

type BULK_OPERATION = typeof BULK_OPERATION

export {
    BULK_OPERATION as default,
    CONFIRM_BOOK_IN,
    VERIFY_CFU_COUNT,
    BulkOperationId,
}