import * as breachTypes from '_/constants/sample-breach-type'
import type Sample from '_/model/sample/sample'

interface OwnProps {
    sample: Sample
}

const LimitInfo = (props: OwnProps) => {
    const limit = getLimitName()

    function getLimitName() {
        switch(props.sample.breach) {
            case breachTypes.ALERT_LIMIT: return 'Alert limit'
            case breachTypes.ACTION_LIMIT: return 'Action limit'
            default: return undefined
        }
    }

    return <div data-testid='limit-breach-info-text'>
        { limit && <div>{limit} {props.sample.sampleInvestigationReferences && `with ref# ${props.sample.sampleInvestigationReferences}`}</div> }
    </div>
}

export default LimitInfo
