import type Role from '_/model/roles/types'

import { BUSINESS_PERMISSION } from '_/constants/business-permission'
import * as routes from '_/constants/routes'
import CONTEXT_LEVEL from '_/constants/context-level'

import Link from '_/components/link'

interface Props {
    role: Role
    canEdit: boolean
}

function ListRow({ role, canEdit }: Props) {
    const permissionText = role.permissions.map((p, i) => {
        const permission = BUSINESS_PERMISSION.find(bp => bp.id === p)!

        return i !== 0 ? permission.name.charAt(0).toLowerCase() + permission.name.slice(1) : permission.name
    }).join(', ')

    return (
        <tr>
            <td>{role.defaultName}</td>
            <td>{role.name}</td>
            <td>{CONTEXT_LEVEL.find(_ => _.id === role.contextLevel)!.name}</td>
            <td>{permissionText}</td>
            <td className='text-end'>
                <Link
                    className='p-0 border-0 d-print-none'
                    routeName={routes.SETTINGS_ROLES_EDIT}
                    routeParams={{ id: role.id }}
                    hasNoPermissions={!canEdit}
                    testId={`role-${role.name}-edit`}
                >
                    <i className='material-icons text-primary md-18'>create</i>
                </Link>
            </td>
        </tr>
    )
}

export default ListRow
