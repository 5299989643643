function formatHintTitle(title: string) {
    if (title === 'Other')
        return 'No organism type ID'

    if (title === 'Inconclusive')
        return 'Inconclusive ID'

    return title
}

export {
    formatHintTitle,
}
