import { useState, useEffect, useSelector, useAction } from '_/facade/react'
import { cfuCountVerificationEnabled } from '_/features/predefined-lists/helpers'

import type { DashboardDailySamplesStatistic, DashboardBreachLimitsStatistic } from '_/model/dashboard/types'

import { useContextSwitchObserver } from '_/components/context-observer'
import { useTimeService } from '_/components/time'

import { DASHBOARD_DATE_RANGE, DASHBOARD_BOOKED_IN_MORE_THAN_PERIOD } from '_/constants/dashboard'

import * as actions from './actions'

import { useGrades, useContext } from '_/hooks/shared-hooks'

import Limits from './limits'
import Graph from './ui/graph'
import Totals from './ui/totals'
import { getSamplesStats, generatePastPeriod } from './helpers'
import InsightsWidgetContainer from '../insights/insight-widget-container'
import type { DateTime } from '_/model/date-time'


function Dashboard() {
    const timeService = useTimeService()
        , week = generatePastPeriod(timeService, timeService.ctzDayStart(timeService.now()), DASHBOARD_DATE_RANGE)
        , [sampleStatisticTotals, dailySamplesStatistic, limitsStatistic] = useDashboardInfo(week)
        , grades = useGrades()
        , context = useContext()
        , isCfuCountVerificationEnabled = cfuCountVerificationEnabled(grades)
        , bookInConfirmationEnabled = !!context?.bookInConfirmationEnabled
        , dailyStats = getSamplesStats(dailySamplesStatistic, week)
        , permissions = useSelector(_ => _.auth.permissions)

    return (
        <div>
            {!permissions.readDashboard ? null :
                <div>
                    <div className='row justify-content-center dashboard'>
                        <div className='col-9'>
                            <div className='d-flex'>
                                <Totals
                                    totals={sampleStatisticTotals}
                                    className='col-3'
                                    bookInConfirmationEnabled={bookInConfirmationEnabled}
                                    cfuCountVerificationEnabled={isCfuCountVerificationEnabled}
                                />
                                <div className='d-flex align-items-center dashboard-vertical-separator-container'>
                                    <div className='dashboard-vertical-separator'></div>
                                </div>
                                <Graph stats={dailyStats} />
                            </div>
                        </div>
                    </div>
                    <div className='container-fluid'>
                        <div className='row justify-content-between'>
                            <div className='col-4'>
                                <div className='m-4 float-start'>
                                    <Limits limitsStatistic={limitsStatistic} permissions={permissions} />
                                </div>
                            </div>
                            {permissions.readInsights && <InsightsWidgetContainer />}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Dashboard

function useDashboardInfo(week: DateTime[]) {
    const permissions = useSelector(_ => _.auth.permissions)
        , [sampleStatisticTotals, setSampleStatisticTotals] = useState({
            awaitingBookInConfirmationSamplesCount: 0,
            awaitingCfuConfirmationSamplesCount: 0,
            awaitingIdentificationSamplesCount: 0,
            bookedInSamplesCount: 0,
            bookedInMoreThan7DaysSamplesCount: 0,
            forReviewSamplesCount: 0,
        })
        , [dailySampleStatistic, setDailySampleStatistic] = useState<DashboardDailySamplesStatistic[]>([])
        , [limitsStatistic, setLimitsStatistic] = useState<DashboardBreachLimitsStatistic[]>([])
        , timeService = useTimeService()
        , dateFrom = week[0]
        , dateTo = timeService.ctzDayEnd(week[week.length - 1])
        , contextSwitch = useContextSwitchObserver()
        , loadSamplesDailyStatistic = useAction(actions.loadSamplesDailyStatistic)
        , loadSamplesStatisticTotals = useAction(actions.loadSamplesStatisticTotals)
        , loadLimitsStatistic = useAction(actions.loadBreachLimitStatistic)

    useEffect(
        () => {
            if (permissions.readDashboard) {
                const widgetDateTo = timeService.now()
                    , last24HoursStart = timeService.addCtzDays(widgetDateTo, -1)
                    , lastWeekStart = timeService.addCtzDays(widgetDateTo, -7)
                    , last28DaysStart = timeService.addCtzDays(widgetDateTo, -28)

                loadSamplesDailyStatistic({ dateFrom, dateTo })
                    .then(setDailySampleStatistic)
                loadSamplesStatisticTotals({ dateTo, bookedInMoreThanDaysCount: DASHBOARD_BOOKED_IN_MORE_THAN_PERIOD })
                    .then(setSampleStatisticTotals)
                loadLimitsStatistic({ last24HoursStart, lastWeekStart, last28DaysStart })
                    .then(setLimitsStatistic)
            }
        },
        [
            contextSwitch, permissions.readDashboard, timeService, dateFrom, dateTo,
            loadLimitsStatistic, loadSamplesDailyStatistic, loadSamplesStatisticTotals
        ]
    )
    return [sampleStatisticTotals, dailySampleStatistic, limitsStatistic] as const
}
