import type { Middleware } from 'redux'
import { actions, actionTypes } from 'redux-router5'
import { deleteToast, addError, addToast } from './actions'
import * as authActions from '../auth/actions'
import * as backendStatusActions from '../backend-status/actions'
import * as routes from '_/constants/routes'
import type AppState from '_/model/app-state'
import * as httpStatusCodes from '_/model/error/http-status-code'

import { canAccessState } from '_/features/routing/helpers'
import PASSWORD_EXPIRATION_MESSAGE from '_/constants/password-expiration-message'

const voidActions = new Set<string>(
        Object.keys(actionTypes).map(_ => (actionTypes as any)[_])
    )

const errorHandlerMw: Middleware = store => {
    const dispatchError = (error: any, log = true) => {
        if (error.isHandled)
            return

        if (log)
            // eslint-disable-next-line no-console
            console.error(error)

        const state = store.getState() as AppState

        if (state.toasts.list.find(_ => _.message === reasonMessage(error)))
            return

        return store.dispatch(addError(reasonMessage(error)))
    }

    window.addEventListener('error', (event: ErrorEvent) => {
        dispatchError(event.error, false)
    })

    return next => action => {
        if (action.type === addToast.type) {
            setTimeout(
                () => store.dispatch(deleteToast(action.payload)),
                5000
            )
        }

        try {
            const result = next(action)

            if (result == null) {
                if (!voidActions.has(action.type))
                    // eslint-disable-next-line no-console
                    console.error(`action ${action.type} doesn't return value`, action)

                return result
            }

            if (result.catch) {
                result.catch((reason: any) => {
                    const state = store.getState() as AppState
                        , userAuthenticated = !!state.auth.user

                    if (reason === 'abort')
                        return

                    if (reason.status !== httpStatusCodes.SERVICE_UNAVAILABLE && reason.status !== httpStatusCodes.LOCKED)
                        dispatchError(reason)

                    if (reason.status === httpStatusCodes.LOCKED) {
                        if (userAuthenticated)
                            store.dispatch(authActions.loggedOut())

                        store.dispatch(actions.navigateTo(routes.LOG_IN, { message: reasonMessage(reason), ipAccessRestricted: true }))
                    }

                    if (isApiError(reason) && reason.status === httpStatusCodes.NOT_FOUND) {
                        const routeToRedirect = getRouteToRedirectOnNotFoundError(state)

                        store.dispatch(actions.navigateTo(routeToRedirect))
                    }

                    if (isApiError(reason) && (reason.status === httpStatusCodes.UNAUTHORIZED || reason.status === httpStatusCodes.FORBIDDEN)) {
                        if (reason.statusText === PASSWORD_EXPIRATION_MESSAGE && !userAuthenticated) {
                            store.dispatch(actions.navigateTo(routes.FORGOT_PASSWORD))
                        }
                        else {
                            if (userAuthenticated)
                                store.dispatch(authActions.loggedOut())

                            if (state.router.route?.name !== routes.LOG_IN)
                                store.dispatch(actions.navigateTo(routes.LOG_IN))
                        }
                    }

                    if (isApiError(reason) && reason.status === httpStatusCodes.SERVICE_UNAVAILABLE) {
                        store.dispatch(backendStatusActions.checkStatus())
                        store.dispatch(actions.navigateTo(routes.MAINTENANCE))
                        return
                    }
                })
            }

            return result
        }
        catch (error) {
            dispatchError(error)
        }
    }
}

function reasonMessage(reason: any): string {
    const unexpectedError = 'Unexpected error occurred'

    if (reason == null)
        return unexpectedError

    if (isApiError(reason))
        return reason.statusText

    if (isMessageError(reason))
        return reason.message

    return unexpectedError
}

function isApiError(reason: any): reason is { status: number, statusText: string } {
    return typeof reason.status === 'number' && typeof reason.statusText === 'string'
}

function isMessageError(reason: any): reason is { message: string } {
    return typeof reason.message === 'string'
}

function getRouteToRedirectOnNotFoundError(state: AppState) {
    const permissions = state.auth.permissions
        , routeName = state.router.route?.name

    if (routeName?.startsWith(routes.CUSTOM_REPORTS) && canAccessState(routes.CUSTOM_REPORTS, permissions))
        return routes.CUSTOM_REPORTS

    if (routeName?.startsWith(routes.SAMPLES) && canAccessState(routes.SAMPLES, permissions))
        return routes.SAMPLES

    if (routeName?.startsWith(routes.SCHEDULING_MONITORING_GROUPS) && canAccessState(routes.SCHEDULING_MONITORING_GROUPS, permissions))
        return routes.SCHEDULING_MONITORING_GROUPS

    if (routeName?.startsWith(routes.SETTINGS_ROLES) && canAccessState(routes.SETTINGS_ROLES, permissions))
        return routes.SETTINGS_ROLES

    if (routeName?.startsWith(routes.SETTINGS_USERS) && canAccessState(routes.SETTINGS_USERS, permissions))
        return routes.SETTINGS_USERS

    if (canAccessState(routes.DASHBOARD, permissions))
        return routes.DASHBOARD

    if (canAccessState(routes.SETTINGS_CONTEXTS, permissions))
        return routes.SETTINGS_CONTEXTS

    return routes.LOG_IN
}

export default errorHandlerMw
