const CORRECTIONAL_MPN_VALUE_NAME = 'Correctional MPN value'
    , CORRECTIONAL_MPN_VALUE_ID = 'beac92c9-0532-49e4-8b29-e48e928272ea'

    , CORRECTIONAL_MPN_VALUE = {
        id: CORRECTIONAL_MPN_VALUE_ID,
        name: CORRECTIONAL_MPN_VALUE_NAME,
        isGenus: false,
        inUse: true,
        isActive: true,
    }

export {
    CORRECTIONAL_MPN_VALUE_NAME,
    CORRECTIONAL_MPN_VALUE_ID,
    CORRECTIONAL_MPN_VALUE,
}
