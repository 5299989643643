import { actions } from 'redux-router5'

import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type UserService from '_/services/user-service'
import type MembershipService from '_/services/membership-service'
import { noop } from '_/utils/function'

import * as t from '_/model/users/transaction'

import * as toastActions from '_/features/toasts/actions'
import * as userActions from './actions'

import * as routes from '_/constants/routes'
import { CHANGES_SAVED } from '_/features/samples/messages'

const factory = (service: UserService, membershipService: MembershipService): EffectsFactory => (dispatch, getState) => [
    handler(userActions.loadUserList, query =>
        service.list(query)
            .then(list => {
                dispatch(userActions.userListLoaded(list))
                return list
            })
    ),

    handler(userActions.loadUserTrail, service.getTrail),

    handler(userActions.signUpUser, user =>
        Promise.resolve()
            .then(_ => service.signUp(user))
            .then(_ => dispatch(actions.navigateTo(routes.AUTH_CONFIRM)))
            .then(noop)
    ),

    handler(userActions.loadUser, service.get),

    handler(userActions.saveChanges, userData =>
        Promise.resolve()
            .then(_ =>
                t.updateTransaction(userData.id, userData.oldUser, userData.newUser, service)
            )
            .then(newUser => {
                if (userData.id === getState().auth.user!.id)
                    dispatch(userActions.userChanged(Object.assign({}, newUser, { id: userData.id })))
            })
            .then(noop)
    ),

    handler(userActions.changeRole, request =>
        t.changeRoleTransaction(request.role, request.signatureSettings, membershipService)
    ),

    handler(userActions.changePassword, userData =>
        Promise.resolve()
            .then(_ => service.changePassword(userData))
            .then(_ => dispatch(actions.navigateTo(routes.USER_ACCOUNT)))
            .then(noop)
    ),

    handler(userActions.searchContextUsersByEmail, query =>
        service.searchContextUsersByEmail(query)
            .then(_ => _.items)
    ),

    handler(userActions.suspendUserMembership, request =>
        membershipService
            .suspend(request.membershipId, request.signatureSettings)
            .then(() => dispatch(toastActions.addSuccess(CHANGES_SAVED)))
            .then(noop)
    ),

    handler(userActions.resumeUserMembership, request =>
        membershipService
            .resume(request.membershipId, request.signatureSettings)
            .then(() => dispatch(toastActions.addSuccess(CHANGES_SAVED)))
            .then(noop)
    ),
]

export default factory
