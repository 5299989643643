import * as fi from '_/constants/custom-field-index'
import * as breachType from '_/constants/sample-breach-type'

import { getFieldValue } from '_/features/samples/helpers'
import { IN_OPERATION } from '_/model/predefined-lists/action-alert-limit/monitoring-state'
import type { NonViableLimits } from '_/model/predefined-lists/action-alert-limit/non-viable-limits'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import * as ps from '_/model/predefined-lists/action-alert-limit/non-viable-limit-type'

import type { NonViableSample } from './types'
import type { ParticleConcentrationView } from '../types'

function getParticleCount(particle: ParticleConcentrationView) {
    if (!particle.notRecorded && particle.count !== undefined && particle.volume !== undefined && particle.volume !== 0)
        return particle.count / particle.volume
}

function calculateBreachType(
    sample: NonViableSample,
    locations: ListExposureLocation[],
    nonViableLimits: NonViableLimits[],
    higherParticle?: boolean
) {
    const particle = higherParticle ? sample.higherParticle : sample.lowerParticle
        , particleState = higherParticle ? ps.NON_VIABLE_HIGHER_CONCENTRATION_STATE : ps.NON_VIABLE_LOWER_CONCENTRATION_STATE
        , locationId = getFieldValue(sample.fields, fi.MONITORING_POSITION)
        , gradeId = locations.find(_ => _.id === locationId)?.gradeId
        , limits = nonViableLimits.find(_ => _.gradeId === gradeId && _.particleState === particleState)
        , inOperationMonitoringState = sample.monitoringState === IN_OPERATION
        , actionLimit = inOperationMonitoringState ? limits?.inOperationActionLimit : limits?.atRestActionLimit
        , alertLimit = inOperationMonitoringState ? limits?.inOperationAlertLimit : limits?.atRestAlertLimit
        , particleCount = getParticleCount(particle)

    if (!particleCount || !limits)
        return breachType.NONE

    if (actionLimit && particleCount >= actionLimit)
        return breachType.ACTION_LIMIT

    if (alertLimit && particleCount >= alertLimit)
        return breachType.ALERT_LIMIT

    return breachType.NONE
}

export {
    getParticleCount,
    calculateBreachType,
}
