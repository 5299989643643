const ALERT_LIMIT = 1
    , ALERT_LIMIT_WITH_REF = 2
    , ACTION_LIMIT = 3
    , ACTION_LIMIT_WITH_REF = 4
    , COMPROMISED = 5
    , COMPROMISED_WITH_REF = 6

type ICON_TYPE = never
    | typeof ACTION_LIMIT
    | typeof ACTION_LIMIT_WITH_REF
    | typeof ALERT_LIMIT
    | typeof ALERT_LIMIT_WITH_REF
    | typeof COMPROMISED
    | typeof COMPROMISED_WITH_REF

export {
    ICON_TYPE as default,
    ALERT_LIMIT,
    ALERT_LIMIT_WITH_REF,
    ACTION_LIMIT,
    ACTION_LIMIT_WITH_REF,
    COMPROMISED,
    COMPROMISED_WITH_REF,
}
