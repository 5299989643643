import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type OperatorsReportService from '_/services/operators-report-service'

import * as actions from './actions'

const factory = (service: OperatorsReportService): EffectsFactory => () => [
    handler(actions.loadOperatorsReportData, payload =>
        service.getOperatorsReportData(payload.id, payload.request)
    ),
    handler(actions.exportOperatorContaminationBreachRates, payload =>
        service.exportOperatorContaminationBreachRates(payload.id, payload.request))
]

export default factory
