import type { ValidationResult } from '_/utils/form/validate'
import * as v from '../validate'
import type { ViableSampleBookingForm } from '_/model/sample/booking/types'
import type TimeService from '_/services/time-service'
import * as fieldIndex from '_/constants/custom-field-index'
import { getFieldValue, getFieldName } from '../helpers'
import type { PredefinedLists } from '_/model/app-state'

function validate(timeService: TimeService, entity: Partial<ViableSampleBookingForm>, predefinedLists: PredefinedLists) {
    const values = entity.fields ?? []
        , result: ValidationResult<Partial<ViableSampleBookingForm>> = {}
        , sampleTypeId = getFieldValue(values, fieldIndex.SAMPLE_TYPE_ID)

    const customFieldsValidation = v.validateViableCustomFields(predefinedLists.customFields, values)
        , barcodeValidation = v.validateBarcode(entity, getFieldName(predefinedLists.customFields, fieldIndex.BARCODE))
        , batchNumberValidation = v.validateBatchNumber(entity, predefinedLists.customFields.find(f => f.index === fieldIndex.BATCH_NUMBER), true)
        , sampleTypeValidation = v.validateSampleType(entity, predefinedLists.customFields.find(f => f.index === fieldIndex.SAMPLE_TYPE_ID), predefinedLists.sampleTypes)
        , operatorsValidation = v.validateViableOperators(entity.fields, predefinedLists.customFields.find(f => f.index === fieldIndex.OPERATORS_IDS), sampleTypeId, predefinedLists.sampleTypes)
        , sessionValidation = v.validateViableSession(entity.fields, predefinedLists.customFields.find(f => f.index === fieldIndex.SESSION_ID))
        , datesValidation = v.validateViableDates(timeService, entity.fields ?? [], predefinedLists.customFields, predefinedLists.sampleTypes)
        , locationsValidation = v.validateLocations(predefinedLists.customFields, values)
        , overlaidFields = values.map((_, index) =>
            customFieldsValidation[index] ||
            barcodeValidation.fields[index] ||
            batchNumberValidation.fields[index] ||
            sampleTypeValidation.fields[index] ||
            operatorsValidation.fields[index] ||
            sessionValidation.fields[index] ||
            datesValidation.fields[index] ||
            locationsValidation[index]
        )

    return { ...result, fields: overlaidFields }
}

export default validate
