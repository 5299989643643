import { useAction, useState, useEffect, useSelector, useCallback } from '_/facade/react'

import { useContextSwitchObserver } from '_/components/context-observer'
import PageHeader from '_/components/page-header'
import SiteTimeZoneDateFilter from '_/components/site-time-zone-date-filter'
import Link, { LinkButton } from '_/components/link'

import * as r from '_/constants/routes'

import type { SampleSession } from '_/model/predefined-lists/session/types'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'

import { VOID_ID } from '_/utils/tree'

import { navigateTo } from '_/features/routing/actions'
import * as timeActions from '_/features/time/actions'
import * as sessionActions from '_/features/predefined-lists/sample-session/actions'
import * as monitoringGroupActions from '_/features/scheduling/monitoring-groups/actions'

import * as dayScheduleActions from './actions'
import AuditTrailModal from './audit-trail'
import SessionScheduler from './session-scheduler'
import type { GroupData } from '_/model/scheduling/day-scheduler/types'
import type DaySchedule from '_/model/scheduling/day-scheduler/types'
import type { DateTime } from '_/model/date-time'

function DayScheduler() {
    const getTimeService = useAction(timeActions.getTimeService)
        , timeService = getTimeService()
        , sessions = useSessions()
        , dateFromProps = useSelector<DateTime | undefined>(_ => _.router.route?.params.date)
        , [date, setDate] = useState(timeService.utcDayStart(dateFromProps ?? timeService.now()))
        , [groupModalId, setGroupModalId] = useState<string>()
        , [copyToModalId, setCopyToModalId] = useState<string>()
        , [daySchedule, reload] = useDayPlan(date)

        , [showAuditTrail, closeAuditTrail] = useModal(r.SCHEDULING_DAY_SCHEDULER_TRAILS)
        , groups = useGroups()
        , [adHocGroups, reloadAdHocGroups] = useAdHocGroups()
        , [selectedGroup, setSelectedGroup] = useState<GroupData>({ groupId: undefined, sessionId: undefined, index: undefined })
        , [adHocGroupSessionId, setAdHocGroupSessionId] = useState<string>()

    return (
        <div className='d-flex flex-column h-100'>
            <PageHeader
                sticky
                title={
                    <>
                        Schedule for {timeService.formatUtcDate(date)}
                        {daySchedule &&
                            <LinkButton
                                routeName={r.SCHEDULING_DAY_SCHEDULER_TRAILS}
                                routeParams={{id: daySchedule.id}}
                                className='btn-link'
                            >
                                Audit trail
                            </LinkButton>
                        }
                    </>
                }
            >
                <div className='d-inline-flex'>
                    <Link className='me-2 mt-2 text-primary fw-bold' routeName={r.SCHEDULING_MONTHLY_SCHEDULER} routeParams={{ date }} testId='back-to-monthly-view'>
                        Go back to {timeService.formatUtcDate(date, true)}
                    </Link>
                    <SiteTimeZoneDateFilter
                        initialFilter={date}
                        onChange={_ => setDate(timeService.utcDayStart(_!))}
                    />
                </div>
            </PageHeader>
            <div className='d-flex h-100 flex-fill overflow-auto'>
                {sessions.map((session, index) =>
                    <SessionScheduler
                        key={index}
                        date={date}
                        sampleSession={session}
                        sampleSessions={sessions}
                        sessions={daySchedule?.sessions ?? []}
                        monitoringGroups={groups}
                        adHocGroups={adHocGroups}
                        onReloadAdHocGroups={reloadAdHocGroups}
                        onChangeGroupModalId={setGroupModalId}
                        groupModalId={groupModalId}
                        onChangeCopyToModalId={setCopyToModalId}
                        copyToModalId={copyToModalId}
                        selectedGroup={selectedGroup}
                        onChangeSelectedGroup={setSelectedGroup}
                        adHocGroupSessionId={adHocGroupSessionId}
                        onChangeAdHocGroupSessionId={setAdHocGroupSessionId}
                        onReloadDayPlan={reload}
                    />
                )}
            </div>

            {showAuditTrail &&
                <AuditTrailModal
                    onClose={closeAuditTrail}
                    dayScheduleId={daySchedule?.id}
                    date={date}
                />
            }
        </div>
    )
}

function useModal(name: r.RouteName) {
    const stateName = useSelector(_ => _.router.route?.name)
        , navigate = useAction(navigateTo)
        , handleClose = useCallback(
            () => navigate(r.SCHEDULING_DAY_SCHEDULER),
            [navigate],
        )

    return [stateName === name, handleClose] as const
}

function useSessions() {
    const load = useAction(sessionActions.loadSampleSessionList)
        , contextSwitch = useContextSwitchObserver()
        , sessions: Pick<SampleSession, 'id' | 'name' | 'isActive'>[] = useSelector(_ => _.predefinedLists.sampleSessions)

    useEffect(
        () => {
            load()
        },
        [load, contextSwitch]
    )

    return sessions.concat({ name: 'Other viable samples', id: VOID_ID, isActive: true })
}

function useDayPlan(date: DateTime) {
    const load = useAction(dayScheduleActions.loadDaySchedule)
        , [plan, setPlan] = useState<DaySchedule | undefined>()
        , contextSwitch = useContextSwitchObserver()
        , reload = useCallback(
            () => {
                load(date).then(setPlan)
            },
            [load, date]
        )

    useEffect(reload, [reload, contextSwitch])

    return [plan, reload] as const
}

function useGroups() {
    const load = useAction(monitoringGroupActions.loadMonitoringGroupList)
        , [monitoringGroups, setMonitoringGroups] = useState<MonitoringGroup[]>([])
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            load().then(setMonitoringGroups)
        },
        [load, contextSwitch]
    )

    return monitoringGroups
}

function useAdHocGroups() {
    const loadAdHocGroupList = useAction(monitoringGroupActions.loadAdHocGroupList)
        , [adHocGroups, setAdHocGroups] = useState<MonitoringGroup[]>([])
        , contextSwitch = useContextSwitchObserver()
        , load = useCallback(
            () => {
                loadAdHocGroupList().then(setAdHocGroups)
            },
            [loadAdHocGroupList]
        )

    useEffect(load, [load, contextSwitch])

    return [adHocGroups, load] as const
}

export default DayScheduler
