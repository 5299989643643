import { useAction, useSelector, useState, useCallback, useEffect } from '_/facade/react'

import type Role from '_/model/roles/types'

import * as routes from '_/constants/routes'

import { Table } from '_/components/table'
import PageHeader from '_/components/page-header'
import Link from '_/components/link'

import * as actions from '../actions'
import ListRow from './list-row'
import EditModal from './edit/modal'

function useRoleList() {
    const [roles, setRoles] = useState<Role[]>([])
        , contextId = useSelector(_ => _.auth.user && _.auth.user.membership.contextId)
        , loadContextRoles = useAction(actions.loadContextRoles)
        , reset = useCallback(
            () => {
                setRoles([])
                if (contextId)
                    loadContextRoles(contextId).then(setRoles)
            },
            [contextId, loadContextRoles]
        )

    useEffect(reset, [reset])

    return [roles, reset] as const
}

function RoleList() {
    const [roles, reset] = useRoleList()
        , manageRoles = useSelector(_ => _.auth.permissions.manageRoles)
        , editMode = useSelector(
            _ => [routes.SETTINGS_ROLES_CREATE, routes.SETTINGS_ROLES_EDIT].includes(_.router.route!.name)
        )

    return (
        <>
            {editMode && <EditModal allRoles={roles} onUpdate={reset} />}

            <PageHeader title='User roles'>
                <Link
                    className='btn btn-primary'
                    routeName={routes.SETTINGS_ROLES_CREATE}
                    hasNoPermissions={!manageRoles}
                    testId='add-new-role'
                >
                    New user role
                </Link>
            </PageHeader>

            <div className="overflow-auto flex-fill">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Default role name</th>
                            <th>Custom role name</th>
                            <th>Context</th>
                            <th>Permissions</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {roles.map(
                            _ => <ListRow key={_.id} role={_} canEdit={manageRoles} />
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default RoleList
