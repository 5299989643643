import type { FingerdabOverviewByOperator, FingerdabOverviewRequest, OperatorPerformance, OperatorPerformanceFilter } from '_/model/fingerdab-overview-by-operator/types'
import type { OperatorsReport } from '_/model/reports/operators-report/types'
import type TimeService from '_/services/time-service'
import { downloadBlob } from '_/utils/blob'
import { shallowUpdate } from '_/utils/object'

import { convertFromServerFields } from './helper'
import type ApiService from '../api-service'
import type FingerdabOverviewByOperatorService from '../fingerdab-overview-by-operator-service'
import type { BookedInSampleData } from '_/model/scheduling/monitoring-overview/types'

function factory(api: ApiService, time: TimeService): FingerdabOverviewByOperatorService {
    return {
        getFingerdabOverviewByOperator,
        getOperatorPerformance,
        downloadOperatorPerformance
    }

    function getFingerdabOverviewByOperator(request: FingerdabOverviewRequest): Promise<FingerdabOverviewByOperator[]> {
        return api.get<FingerdabOverviewByOperator[]>(['fingerdab-overview-by-operator'], request)
            .then(list => list.map(_ => {
                const items = _.samples.map((_: any) => convertFromServerFields(time, _))
                return shallowUpdate(_, { samples: items as BookedInSampleData[] })
            }))
    }

    function getOperatorPerformance(request: OperatorsReport): Promise<OperatorPerformance> {
        return api.get<OperatorPerformance>(['operator-performance'], request)
    }

    function downloadOperatorPerformance(query: OperatorPerformanceFilter): Promise<void> {
        return api.getFileForLongQuery(['export-operator-performance'], query)
            .then(_ => downloadBlob(_.blob, _.filename))
    }
}

export default factory
