import type { Samples as State } from '_/model/app-state'
import { commonReducer, handler, shallowUpdate } from '_/facade/reducer'
import * as actions from './actions'

const defaultState: State = {
    samples: [],
    list: {
        items: [],
        itemsLimit: undefined,
    },
    listStatistics: {
        totalCount: 0,
        totalPerStatus: [],
        totalPerFlag: [],
    },
    trail: {
        events: [],
    },
    sampleComments: [],
    sampleSearchFilters: [],
    currentEditedSampleField: '',
}

export default commonReducer(
    defaultState,

    handler(actions.sampleListLoaded, (state, payload) => shallowUpdate(state, { list: payload })),

    handler(actions.sampleListStatisticsLoaded, (state, payload) => shallowUpdate(state, { listStatistics: payload })),

    handler(actions.sampleLoaded, (state, sample) => {
        const samples = state.samples.concat(sample)

        return shallowUpdate(state, { samples })
    }),

    handler(actions.sampleTrailLoaded, (state, payload) => shallowUpdate(state, { trail: payload })),

    handler(actions.sampleCommentsLoaded, (state, payload) => shallowUpdate(state, { sampleComments: payload })),

    handler(actions.sampleSearchFilterListLoaded, (state, sampleSearchFilters) =>
        shallowUpdate(state, { sampleSearchFilters })
    ),

    handler(actions.editSampleField, (state, currentEditedSampleField) => shallowUpdate(state, { currentEditedSampleField })),
)
