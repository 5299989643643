import Analysis from '_/features/analysis/ui/analysis'
import { Modal, ModalBody } from '_/components/modal'
import Button, { Close } from '_/components/button'

import type { AnalysisFilter } from '_/model/analysis/filter/types'

interface OwnProps {
    reportName?: string | undefined
    onClose: () => void
    filter?: AnalysisFilter | undefined
    onAddToReport: (filter: AnalysisFilter) => void
    onSaveChanges: (filter: AnalysisFilter) => void
    formId: string
}

const CustomReportGraphModal: React.FC<OwnProps> = (props) =>
    <Modal isOpen onClose={props.onClose} noDefaultContentWidth contentClassName='overflow-auto'>
        <ModalBody className='custom-reports-graph-modal--width custom-reports-graph-modal--height' noDefaultHeight>
            <Analysis
                isGraphInCustomReport
                customReportInitialFilter={props.filter}
                formId={props.formId}
                onCustomReportRender={(filter, filterInvalid, submitDisabled) =>
                    <div className='d-flex mb-3'>
                        <div className='me-auto fw-bold'>
                            {`${!props.filter ? 'Add' : 'Edit'} a graph of the ${props.reportName ? `"${props.reportName }"` : ''} report`}
                        </div>
                        <Button
                            onClick={() => props.filter ? props.onSaveChanges(filter) : props.onAddToReport(filter)}
                            className='btn-primary me-4'
                            disabled={filterInvalid || submitDisabled}
                            testId='save-graph-changes'
                        >
                            {props.filter ? 'Save changes' : 'Add to report'}
                        </Button>
                        <Close onClick={props.onClose}/>
                    </div>
                }
            />
        </ModalBody>
    </Modal>

export default CustomReportGraphModal
