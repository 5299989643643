import { classnames } from '_/facade/react'
import { VOID_ID } from '_/utils/tree'

import type { Expectations, SampleTypeQuantity } from '_/model/scheduling/monitoring-groups/types'
import { POSITION } from '_/model/predefined-lists/exposure-location/location-type'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import * as t from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'
import { formatActiveState } from '_/utils/format/common'

import MonitoringGroupData from './monitoring-group-data'

interface OwnProps {
    expectations: Expectations
    location: ExposureLocation
    sampleTypes: SampleType[]
    hasChildren: boolean
    expanded?: boolean
    nestingLevel: number
    canEdit: boolean
    onTrigger: (_: ExposureLocation) => void
    onChange: (value: SampleTypeQuantity[]) => void
}

function ExposureLocationsRow(props: OwnProps) {
    const triggerClasses = classnames('exposure-locations__trigger', {
        'exposure-locations__trigger--collapsed': !props.expanded,
        'exposure-locations__trigger--visible': props.hasChildren,
    })
        , positionOnly = props.location.locationType === POSITION ? t.systemTextNode(' (Position only)') : t.emptyTextNode()
        , locationName = formatActiveState(props.location.name, props.location.isActive).concat(positionOnly)

    const offsets = []
    for (let i = props.nestingLevel; i > 0; i--)
        offsets.push(
            <div key={i} className='exposure-locations__offset' />
        )

    return (
        <tr>
            <td className='w-50'>
                {offsets}
                {props.hasChildren
                    ? <a className={triggerClasses} href='.' onClick={e => {e.preventDefault(); props.onTrigger(props.location)} } />
                    : <div className={triggerClasses} />
                }
                <FormattedText text={locationName} />
            </td>
            {props.location.parentId !== VOID_ID
                ? <MonitoringGroupData
                    key={props.location.id}
                    value={props.expectations.sampleTypeQuantities}
                    sampleTypes={props.sampleTypes}
                    canEdit={props.canEdit}
                    onChange={props.onChange}
                    testId={`monitoring-group-${props.location.name}`}
                />
                : <td/>
            }
        </tr>
    )
}

export default ExposureLocationsRow
