import type ApiService from '../api-service'
import type BackendStatusService from '../backend-status-service'
import type { BackendStatus } from '_/model/backend-status/backend-status'

export default function factory(api: ApiService): BackendStatusService {
    return { checkStatus }
    function checkStatus() {
        return api.get<BackendStatus>(['status'])
    }
}
