import { useFieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'
import { classnames, useSelector, useState, useAction } from '_/facade/react'

import { TextField, TextareaField } from '_/components/form'
import { useCloseModal } from '_/components/modal'
import { showFieldError } from '_/components/form'
import Button from '_/components/button'
import Legend from '_/components/legend'

import * as tabs from '_/constants/modal-tab'

import Header from './header'
import AuditTrail from './trails'
import ActionButtons from '../action-buttons'

import { fullNameLocationList } from '_/utils/exposure-location'
import { formatActiveState } from '_/utils/format/common'

import type { FloorPlanLocationForm } from '_/model/floor-plan/editor/types'

import * as a from '../actions'
import * as deletionActions from '_/features/confirmation/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    submitDisabled: boolean
    focusedIndex: number | undefined
    onFocus: (_: number | undefined) => void
    className: string
    floorPlanName: string | undefined
    locationFieldsRef: React.RefObject<HTMLDivElement>
}

function Fields(props: Props) {
    const locationsField = useFieldArray('locations', { isEqual: areLengthsEqual })
        , floorPlanName = props.floorPlanName || ''
        , [activeTab, setActiveTab] = useState<tabs.Tab>(tabs.DATA)
        , removeFloorPlan = useRemoveFloorPlan(floorPlanName)

    return (
        <div className={props.className + ' h-100 d-flex flex-column'}>
            <Header floorPlanName={floorPlanName} activeTab={activeTab} onChangeTab={setActiveTab} />

            {activeTab === tabs.DATA
                ? <>
                    <TextField name='name' testId='field-name'>Name</TextField>
                    <TextareaField name='description' textareaClassName='floor-plans-details__text-area' testId='field-description'>Description</TextareaField>
                    <hr className='my-1' />
                    <div className='overflow-y-auto mb-2' ref={props.locationFieldsRef}>
                        <Legend>{`Locations on floor plan (${locationsField.fields.length})`}</Legend>
                        <hr className='my-0 fw-bold'/>
                        <div
                            // box shadow for selected location is trimmed by overflow-auto
                            // so added extra padding to fit shadows
                            className='p-1'
                        >
                            {locationsField.fields.map(
                                (name, index) =>
                                    <div key={index}>
                                        <LocationField
                                            name={name}
                                            focused={index === props.focusedIndex}
                                            onRemove={() => {
                                                props.onFocus(undefined)
                                                locationsField.fields.remove(index)
                                            }}
                                            onFocus={() => props.onFocus(index)}
                                        />
                                        <hr className='my-0'/>
                                    </div>
                            )}
                        </div>
                    </div>
                    <ActionButtons onDelete={removeFloorPlan} submitDisabled={props.submitDisabled} />
                </>
                : <AuditTrail />
            }
        </div>
    )
}

export default Fields

interface LocationFieldProps {
    name: string
    onRemove: () => void
    onFocus: () => void
    focused: boolean
}

function LocationField(props: LocationFieldProps) {
    const planLocationId = useField<FloorPlanLocationForm>(props.name)
        , locations = useSelector(_ => fullNameLocationList(_.predefinedLists.exposureLocations))
        , location = locations.find(_ => _.id === planLocationId.input.value.locationId)
        , error = showFieldError(planLocationId.meta) && planLocationId.meta.error
        , active = !error && props.focused

    return (
        <div
            className={classnames('rounded', error && 'border border-danger', active && 'highlight', 'd-flex flex-wrap p-2')}
            onClick={e => {
                const target = e.target as HTMLElement
                if (target.nodeName !== 'BUTTON')
                    props.onFocus()
            }}
        >
            <span className={classnames(error && 'text-danger')}>
                {error
                    ? error
                    : <FormattedText text={location && formatActiveState(location.pathName, location.isActive)} />
                }
            </span>
            <Button className='py-0 col-auto ms-auto text-danger material-icons md-18' onClick={props.onRemove}>
                delete_outline
            </Button>
        </div>
    )
}

function areLengthsEqual(one: any[] | undefined, two: any[] | undefined): boolean {
    return one?.length === two?.length
}

function useRemoveFloorPlan(floorPlanName: string) {
    const remove = useAction(a.removeFloorPlan)
        , showDeletionWarning = useAction(deletionActions.showDeletionConfirmationModal)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , id = useSelector(_ => _.router.route?.params.id)
        , closeModal = useCloseModal()

    function handleRemoveFloorPlan() {
        return showDeletionWarning(`Are you sure you want to delete ${floorPlanName}?`)
            .then(() => remove(id))
            .then(() => hasUnsavedChanges(false))
            .then(closeModal)
    }

    return handleRemoveFloorPlan
}
