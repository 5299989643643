import { useState, useAction, useEffect, useSelector } from '_/facade/react'
import type { FieldRenderProps } from 'react-final-form'
import { useField } from 'react-final-form'

import Button from '_/components/button'
import { PasswordField } from '_/components/form'

import * as authActions from '_/features/auth/actions'

import logo from '_/../assets/ms-logo.svg'
import { UserAgentApplication } from 'msal'
import type { AzureAdConfig } from '_/model/auth/types'
import type { SignatureMethod } from '_/model/critical-change-reason/types'
import { SMART_CONTROL } from '_/model/users/user-account-type'

interface Props {
    ssoESignatureAllowed: boolean
    signatureMethod: SignatureMethod | undefined
    setSignatureMethod: (_: SignatureMethod | undefined) => void
}

function SignatureFields(props: Props) {
    const getConfig = useAction(authActions.getAzureAdConfig)
        , [config, setConfig] = useState<AzureAdConfig>()
        , [logInPopup, signaturedBy] = useSsoPopup(config, props.setSignatureMethod)
        , tokenField = useField<string>('idToken')
        , sessionStateField = useField<string>('sessionState')
        , userAccountType = useSelector(_ => _.auth.user?.accountTypes) ?? []

    useEffect(
        () => {
            if (props.ssoESignatureAllowed)
                getConfig()
                    .then(setConfig)
        },
        [getConfig, props.ssoESignatureAllowed]
    )


    return (
        <div className='mt-1'>
            <div className='my-4'>Confirm your identity to save the change you made</div>
            {!props.signatureMethod &&
                <div>
                    <Button
                        className='btn-primary w-100 mb-1'
                        onClick={() => props.setSignatureMethod('smartControl')}
                        disabled={!userAccountType.some(_ => _ === SMART_CONTROL)}
                        testId='confirm-smartcontrol-pass-button'
                    >
                        Confirm your identity with SmartControl
                    </Button>
                    <Button
                        className='w-100 border text-muted'
                        testId='confirm-microsoft-pass-button'
                        onClick={() => {
                            props.setSignatureMethod('sso')
                            logInPopup(tokenField, sessionStateField)
                        }}>
                        <img src={logo} className='ms-logo me-2' /> Confirm your identity with Microsoft
                    </Button>
                </div>
            }

            {props.signatureMethod === 'sso' && signaturedBy &&
                <div data-testid='signed-by'>Signed by <span className='fw-bold'>{signaturedBy}</span></div>
            }

            {props.signatureMethod === 'smartControl' &&
                <PasswordField id='password' name='password' testId='confirm-smartcontrol-pass-field'>SmartControl password</PasswordField>
            }
        </div>
    )
}

export default SignatureFields

function useSsoPopup(azureAdConfig: AzureAdConfig | undefined, setSignatureMethod: (_: SignatureMethod | undefined) => void) {
    const [signaturedBy, setSignaturedBy] = useState<string | undefined>()
        , userAgentApplication = new UserAgentApplication(
            {
                auth: {
                    clientId: azureAdConfig?.clientId ?? '',
                    authority: 'https://login.microsoftonline.com/organizations',
                    validateAuthority: true,
                    navigateToLoginRequestUrl: false,
                    redirectUri: window.origin,
                },
                cache: {
                    cacheLocation: 'sessionStorage'
                }
            }
        )

    function ssoPopupLoginHandler(tokenField: FieldRenderProps<string>,  sessionStateField: FieldRenderProps<string>) {
        userAgentApplication.loginPopup({ scopes: ['openid', 'email', 'profile'], state: azureAdConfig?.nonce })
            .then(_ => {
                tokenField.input.onChange(_.idToken.rawIdToken)
                sessionStateField.input.onChange(_.idToken.nonce)
                setSignaturedBy(_.idToken.preferredName)
            }).catch(() =>
                setSignatureMethod(undefined)
            )
    }

    return [ssoPopupLoginHandler, signaturedBy] as const
}
