import { LinkButton } from '_/components/link'
import { LOG_IN } from '_/constants/routes'

interface Props {
    message: string
}

function IpRestricted(props: Props) {
    return (
        <div className='auth h-100'>
            <div className='restricted-ip-container text-white' data-testid='restricted-ip-login-page'>
                {props.message}

                <div className='mt-4'>
                    <LinkButton className='btn-primary' routeName={LOG_IN} testId='go-back-to-login'>Go back</LinkButton>
                </div>
            </div>
        </div>
    )
}

export default IpRestricted
