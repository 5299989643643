import { classnames, useReducer, useState, useAction, useEffect } from '_/facade/react'
import { useCustomFields, useExposureLocations } from '_/hooks/shared-hooks'

import ContextObserver from '_/components/context-observer'
import PageHeader from '_/components/page-header'
import DateFilter from '_/components/date-filter'
import HoverMenu from '_/components/overlay/hover-menu'

import * as breachTypes from '_/constants/sample-breach-type'

import type * as lt from '_/model/reports/limit-breach-report/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import { getLimits, getReferenceNumbers } from './helpers'
import { generatePastDate, generatePastPeriod } from '_/features/dashboard/helpers'
import * as actions from './actions'

import LIMIT_BREACH_DATE_RANGE from '_/constants/limit-breach-report'
import LimitBreachHint from './limit-breach-hint'
import LimitBreachIcon from './limit-breach-icon'
import Key from '_/features/key/key'
import { useTimeService } from '_/components/time'
import type { DateTime} from '_/model/date-time'
import { equals } from '_/model/date-time'
import { VOID_ID } from '_/utils/tree'
import { formatActiveState } from '_/utils/format/common'
import { fullNameLocationList } from '_/utils/exposure-location'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import { plainText, systemTextNode } from '_/model/text/text'
import FormattedText from '../text/formatted-text'
import { EmptyTableMessage, Table } from '_/components/table'

function LimitBreachReport() {
    const timeService = useTimeService()
        , currentDate = timeService.ctzDayStart(timeService.now())
        , [loadToken, forceLoad] = useReducer(_ => _ + 1, 0)
        , [filter, setFilter] = useState<DateTime>(currentDate)
        , customFields = useCustomFields()
        , locations = fullNameLocationList(useExposureLocations())
        , [limits, showSpinner] = useLimitBreachReport(filter, loadToken)
        , dateRange = generatePastPeriod(timeService, filter, LIMIT_BREACH_DATE_RANGE)
        , limitReport = getLimits(limits, dateRange)
        , { month } = timeService.ctzTimeStruct(dateRange[0])
        , monthDayCount = dateRange.reduce((acc, _) => acc + (timeService.ctzTimeStruct(_).month === month ? 1 : 0), 0)
        , [hoveredRow, setHoveredRow] = useState<number | undefined>()
        , showRightBorder = (index: number) => index + 1 === monthDayCount && monthDayCount !== LIMIT_BREACH_DATE_RANGE

    function locationName(id: string | undefined) {
        const location = locations.find(_ => _.id === id)
        return id !== VOID_ID && location
            ? formatActiveState(location.pathName, location.isActive)
            : [systemTextNode('Unknown location')]
    }

    function handleFilterChange(date: DateTime | undefined) {
        const filter = date == undefined ? currentDate : date
        setFilter(filter)
    }

    function handleContextSwitch() {
        setFilter(currentDate)

        if (equals(currentDate, filter))
            forceLoad()
    }

    return (
        <div className='container-fluid'>
            <ContextObserver onChange={handleContextSwitch} />
            <div className='row justify-content-center'>
                <div className='col-10'>
                    <PageHeader sticky title={
                        <div className='d-flex' data-testid='limit-breach-report-title'>
                            Limit breach timeline
                            <Key>
                                <div className='mb-1'>
                                    <LimitBreachIcon breachType={breachTypes.ACTION_LIMIT} />
                                    <span className='ms-1'>Viable action limit breach</span>
                                </div>
                                <div className='mb-1'>
                                    <LimitBreachIcon breachType={breachTypes.ACTION_LIMIT} withRef />
                                    <span className='ms-1'>Viable action limit breach with ref #</span>
                                </div>
                                <div className='mb-1'>
                                    <LimitBreachIcon breachType={breachTypes.ALERT_LIMIT} />
                                    <span className='ms-1'>Viable alert limit breach</span>
                                </div>
                                <div className='mb-1'>
                                    <LimitBreachIcon breachType={breachTypes.ALERT_LIMIT} withRef />
                                    <span className='ms-1'>Viable alert limit breach with ref #</span>
                                </div>
                                <div className='mb-1'>
                                    <LimitBreachIcon breachType={breachTypes.ACTION_LIMIT} isNonViable />
                                    <span className='ms-1'>Non-viable action limit breach</span>
                                </div>
                                <div className='mb-1'>
                                    <LimitBreachIcon breachType={breachTypes.ALERT_LIMIT} isNonViable />
                                    <span className='ms-1'>Non-viable alert limit breach</span>
                                </div>
                                <div className='mb-1'>
                                    <LimitBreachIcon breachType={breachTypes.COMPROMISED} />
                                    <span className='ms-1'>Compromised</span>
                                </div>
                                <div className='mb-1'>
                                    <LimitBreachIcon breachType={breachTypes.COMPROMISED} withRef />
                                    <span className='ms-1'>Compromised with ref #</span>
                                </div>
                            </Key>
                        </div>
                    }>
                        <div className='d-inline-flex'>
                            <span className='mt-2 me-4' data-testid='breaches-total-by-date'>
                                Showing breaches from {timeService.formatCtzDate(dateRange[0])} to {timeService.formatCtzDate(dateRange[LIMIT_BREACH_DATE_RANGE - 1])}
                            </span>
                            <DateFilter
                                onChange={handleFilterChange}
                                initialFilter={filter}
                                maxDate={timeService.now()}
                            />
                        </div>
                    </PageHeader>
                    {showSpinner
                        ? <div className='position-relative py-5'>
                            <i className='preview-spinner material-icons md-48'>sync</i>
                        </div>
                        : <Table className='text-center table-layout-fixed'>
                            <thead className='table-header--sticky table-header--default-offset'>
                                <tr className='limit-breach-timeline__table-header--sticky-row-1'>
                                    <th className='text-start align-top px-1 py-0 border-top-0 border-bottom-0 border-end px-1' style={{ width:'20%' }}>
                                        Location
                                    </th>

                                    <th
                                        colSpan={monthDayCount}
                                        className={classnames(
                                            'text-start align-top px-1 py-0 border-top-0 border-bottom-0',
                                            { 'border-end': monthDayCount !== LIMIT_BREACH_DATE_RANGE }
                                        )}
                                    >
                                        {timeService.formatCtzDate(dateRange[0], true)}
                                    </th>
                                    {monthDayCount < LIMIT_BREACH_DATE_RANGE &&
                                    <th className='text-start align-top border-top-0 border-bottom-0 py-0' colSpan={LIMIT_BREACH_DATE_RANGE - monthDayCount}>
                                        {timeService.formatCtzDate(dateRange[LIMIT_BREACH_DATE_RANGE - 1], true)}
                                    </th>
                                    }
                                </tr>
                                <tr className='limit-breach-timeline__table-header--sticky-row-2'>
                                    <th className='px-1 border-end border-top-0 border-bottom-0'></th>
                                    {dateRange.map((_, index) =>
                                        <th
                                            key={_}
                                            className={classnames(
                                                'px-0 py-0 border-top-0 border-bottom-0', {
                                                    'border-end': showRightBorder(index),
                                                    'fw-normal': !equals(_, timeService.ctzDayStart(timeService.now()))
                                                }
                                            )}
                                            data-testid={`date-range-${index}`}
                                        >
                                            {timeService.formatCtzShortWeek(_).split(' ').map((_, i) =>
                                                <div key={i}>{_}</div>
                                            )}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody className='border-bottom'>
                            {limitReport.length === 0
                                ? <EmptyTableMessage colSpan={30} message='No limit breaches' />
                                : limitReport.map((_, index) =>
                                    <tr
                                        key={index}
                                        onMouseOver={() => setHoveredRow(index)}
                                        onMouseLeave={() => setHoveredRow(undefined)}
                                        className={classnames({'table-active': hoveredRow === index })}
                                    >
                                        <td className='px-2 align-middle text-start border-end single-line-text'>
                                            <span title={plainText(locationName(_[0].locationId))}><FormattedText text={locationName(_[0].locationId)}/></span>
                                        </td>
                                        {_.map((limitInfo, cellIndex) =>
                                            <LimitInfoCell
                                                key={cellIndex}
                                                limitInfo={limitInfo}
                                                customFields={customFields}
                                                lastDateInTimeline={dateRange[LIMIT_BREACH_DATE_RANGE - 1]}
                                                showRightBorder={showRightBorder(cellIndex)}
                                                isHovered={hoveredRow === index}
                                                locations={locations}
                                            />
                                        )}
                                    </tr>
                                )
                            }
                            </tbody>
                        </Table>

                    }
                </div>
            </div>
        </div>
    )
}

export default LimitBreachReport

function useLimitBreachReport(filter: DateTime, loadToken: number) {
    const loadLimitBreachReport = useAction(actions.loadLimitBreachReport)
        , [limits, setLimits] = useState<lt.LimitBreachReport[]>([])
        , [showSpinner, setShowSpinner] = useState(false)
        , timeService = useTimeService()

    useEffect(
        () => {
            const query = {
                dateFrom: generatePastDate(timeService, LIMIT_BREACH_DATE_RANGE - 1, filter),
                dateTo: timeService.ctzDayEnd(filter),
            }

            setShowSpinner(true)
            loadLimitBreachReport(query)
                .then(setLimits)
                .finally(() => setShowSpinner(false))
        },
        [filter, loadToken, timeService, loadLimitBreachReport]
    )

    return [limits, showSpinner] as const
}

interface LimitInfoCellProps {
    limitInfo: ReturnType<typeof getLimits>[number][number]
    customFields: CustomField[]
    locations: ListExposureLocation[]
    lastDateInTimeline: DateTime
    showRightBorder: boolean
    isHovered: boolean
}

function LimitInfoCell(props: LimitInfoCellProps) {
    const { viableActionLimitBreached, viableAlertLimitBreached, nonViableActionLimitBreached, nonViableAlertLimitBreached, compromised } = props.limitInfo
        , [cellElement, setCellElement] = useState<HTMLElement | null>(null)
        , [alignmentElement, setAlignmentElement] = useState<HTMLElement | null>(null)
        , notEmpty = [viableActionLimitBreached, viableAlertLimitBreached, compromised, nonViableActionLimitBreached, nonViableAlertLimitBreached].some(_ => _.length > 0)
        , timeService = useTimeService()
        , today = equals(props.limitInfo.exposureDate, timeService.ctzDayStart(timeService.now()))

    return (
        <td
            ref={setCellElement}
            className={classnames(
                'px-1 align-middle position-relative px-0 py-0 limit-breach-timeline__row--height', {
                    'cursor-pointer': notEmpty,
                    'border-end': props.showRightBorder,
                    'bg-warning': props.isHovered && today,
                    'table-warning': today
                }
            )}
        >
            <div ref={setAlignmentElement}>
                {viableActionLimitBreached.length > 0 &&
                    <LimitBreachIcon
                        count={viableActionLimitBreached.length}
                        breachType={breachTypes.ACTION_LIMIT}
                        withRef={!viableActionLimitBreached.some(_ => !getReferenceNumbers(_))}
                    />
                }
                {viableAlertLimitBreached.length > 0 &&
                    <LimitBreachIcon
                        count={viableAlertLimitBreached.length}
                        breachType={breachTypes.ALERT_LIMIT}
                        withRef={!viableAlertLimitBreached.some(_ => !getReferenceNumbers(_))}
                    />
                }
                {nonViableAlertLimitBreached.length > 0 &&
                    <LimitBreachIcon
                        count={nonViableAlertLimitBreached.length}
                        breachType={breachTypes.ALERT_LIMIT}
                        isNonViable
                    />
                }
                {nonViableActionLimitBreached.length > 0 &&
                    <LimitBreachIcon
                        count={nonViableActionLimitBreached.length}
                        breachType={breachTypes.ACTION_LIMIT}
                        isNonViable
                    />
                }
                {compromised.length > 0 &&
                    <LimitBreachIcon
                        count={compromised.length}
                        breachType={breachTypes.COMPROMISED}
                        withRef={!compromised.some(_ => !getReferenceNumbers(_))}
                    />
                }
            </div>
            {notEmpty &&
                <HoverMenu element={cellElement} alignmentElement={alignmentElement} showTriangle>
                    <LimitBreachHint
                        data={props.limitInfo}
                        lastDateInTimeline={props.lastDateInTimeline}
                        customFields={props.customFields}
                        locations={props.locations}
                    />
                </HoverMenu>
            }
        </td>
    )
}
