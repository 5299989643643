import type SampleFilesService from '_/services/sample-files-service'
import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import * as a from '_/features/samples/actions'

import { uploaderFactory } from '_/model/sample/files/uploader'

const factory = (service: SampleFilesService): EffectsFactory => (_dispatch, _getState) => {
    const uploader = uploaderFactory(service)

    return [
        handler(a.uploadSampleFile, uploader.upload),
        handler(a.loadSampleFileList, service.getFiles),
        handler(a.downloadFile, _ => service.download(_.id, _.name)),
        handler(a.removeFile, service.remove),
    ]
}

export default factory
