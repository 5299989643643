import type { PredefinedLists } from '_/model/app-state'

import SampleLocationTable from '_/components/sample-location-table'

import { SAMPLE_LOCATION_REPORT } from '_/model/reports/sample-report-type'
import type { SampleReportSearchQuery } from '_/model/sample/search'
import { useRelatedSamples } from './shared-hooks'

interface Props {
    searchQuery: SampleReportSearchQuery
    predefinedLists: PredefinedLists
}

function SampleLocationInfo(props: Props) {
    const [samples, showSpinner] = useRelatedSamples(props.searchQuery)

    return (
        <SampleLocationTable
            sampleReportType={SAMPLE_LOCATION_REPORT}
            samples={samples}
            predefinedLists={props.predefinedLists}
            noDataMessage='No viable samples recorded on this location'
            showSpinner={showSpinner}
        />
    )
}

export default SampleLocationInfo
