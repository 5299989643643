import { actionFactory } from '_/utils/redux'
import type Context from '_/model/context/context'
import type { ContextCreate, ContextEdit } from '_/model/context/edit/types'
import type { ContextInviteRequest, ContextInvite } from '_/model/context/invite/types'
import type { UserSignUp } from '_/model/auth/types'
import type { ElectronicSignatureSettings } from '_/model/context/electronic-signature-settings'
import type { IpRestrictionValidationResponse, IpInWhitelistValidationResponse, IpInContextWhitelistValidationResponse } from '_/model/context/edit/ip-restriction'
import type Export from '_/model/export/types'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.contexts')

export const loadContexts = action<void, Promise<void>>('loadContexts')
           , contextsLoaded = action<Context[]>('contextsLoaded')
           , loadContext = action<string, Promise<Context>>('loadContext')
           , contextLoaded = action<Context>('contextLoaded')
           , clearContexts = action<void>('clearContexts')
           , loadContextInvite = action<string, Promise<ContextInvite>>('loadContextInvite')
           , invite = action<ContextInviteRequest, Promise<void>>('invite')
           , assign = action<string, Promise<void>>('assign')
           , rejectContextInvite = action<string, Promise<void>>('rejectContextInvite')
           , joinContext = action<{ token: string, user: UserSignUp }, Promise<void>>('joinContext')
           , createContext = action<ContextCreate, Promise<void>>('createContext')
           , saveChanges = action<{ id: string, oldContext: ContextEdit, newContext: ContextEdit, signatureSettings: ElectronicSignatureSettings[] }, Promise<void>>('saveChanges')
           , contextEdited = action<Context, Promise<void>>('contextEdited')
           , disableContext = action<{ id: string, signatureSettings: ElectronicSignatureSettings[] }, Promise<void>>('disableContext')
           , resumeContext = action<{ id: string, signatureSettings: ElectronicSignatureSettings[] }, Promise<void>>('resumeContext')
           , exportContext = action<void, Promise<Export>>('exportContext')
           , loadContextExports = action<void, Promise<Export[]>>('loadContextExports')
           , loadContextTrail = action<string, Promise<AuditTrail>>('loadContextTrail')
           , validateIpRestriction = action<string, Promise<IpRestrictionValidationResponse>>('validateIpRestriction')
           , validateIpInWhitelist = action<string, Promise<IpInWhitelistValidationResponse>>('validateIpInWhitelist')
           , validateIpInContextWhitelist = action<string, Promise<IpInContextWhitelistValidationResponse>>('validateIpInContextWhitelist')
