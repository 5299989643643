const NOT_YET_IDENTIFIED_NAME = 'Not yet identified'
    , NOT_YET_IDENTIFIED_ID = 'abbfeb7f-4ecf-4816-bc3b-7d124c338b95'

    , NOT_YET_IDENTIFIED = {
        id: NOT_YET_IDENTIFIED_ID,
        name: NOT_YET_IDENTIFIED_NAME,
        isGenus: false,
        inUse: true,
        isActive: true,
    }

export {
    NOT_YET_IDENTIFIED_NAME,
    NOT_YET_IDENTIFIED_ID,
    NOT_YET_IDENTIFIED,
}
