import * as fields from '_/constants/first-change-reason-ignore-fields'
import type { EditLimitsProps } from '_/model/predefined-lists/action-alert-limit/types'
import type { EditNonViableLimitsProps } from './non-viable-limits'

function reasonRequired(editedLimits: EditLimitsProps[], nonViableEditedLimits: EditNonViableLimitsProps[], gradeWasChanged: boolean) {
    const limitsWereChanged = editedLimits.length > 0 || nonViableEditedLimits.length > 0

    return gradeWasChanged || limitsWereChanged &&
        editedLimits.concat(nonViableEditedLimits)
            .some(_ => {
                const inOperationAlertLimitDiff = _.oldLimits.inOperationAlertLimit !== _.newLimits.inOperationAlertLimit
                    , inOperationActionLimitDiff = _.oldLimits.inOperationActionLimit !== _.newLimits.inOperationActionLimit
                    , isFieldEdited = (field: fields.FirstChangeReasonIgnoreFields) => (_.oldLimits.editedFields || []).some(_ => _.trim() === field)

                    , atRestAlertLimitDiff = _.oldLimits.atRestAlertLimit !== _.newLimits.atRestAlertLimit
                    , atRestActionLimitDiff = _.oldLimits.atRestActionLimit !== _.newLimits.atRestActionLimit

                return inOperationActionLimitDiff && isFieldEdited(fields.IN_OPERATION_ACTION_LIMIT)
                    || inOperationAlertLimitDiff && isFieldEdited(fields.IN_OPERATION_ALERT_LIMIT)
                    || atRestAlertLimitDiff && isFieldEdited(fields.AT_REST_ALERT_LIMIT)
                    || atRestActionLimitDiff && isFieldEdited(fields.AT_REST_ACTION_LIMIT)
            })
}

export {
    reasonRequired,
}
