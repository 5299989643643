const ALERT = 0
    , ACTION = 1
    , COMPROMISED = 2
    , TAGGED_USER = 3
    , MISSED_MONITORING = 4
    , DATA_EXPORT = 5
    , TREND_IDENTIFIED = 6
    , MISSED_MONITORING_EXPECTATION = 7

type NotificationType = never
    | typeof ALERT
    | typeof ACTION
    | typeof COMPROMISED
    | typeof TAGGED_USER
    | typeof MISSED_MONITORING
    | typeof DATA_EXPORT
    | typeof TREND_IDENTIFIED
    | typeof MISSED_MONITORING_EXPECTATION

const NOTIFICATION_TYPE: {id: NotificationType, name: string}[] = [
    {
        id: ALERT,
        name: 'Alert limit breach',
    },
    {
        id: ACTION,
        name: 'Action limit breach',
    },
    {
        id: COMPROMISED,
        name: 'Compromised',
    },
    {
        id: TAGGED_USER,
        name: 'Tagged user in comment',
    },
    {
        id: MISSED_MONITORING,
        name: 'Missed monitoring',
    },
    {
        id: DATA_EXPORT,
        name: 'My data exports'
    },
    {
        id: TREND_IDENTIFIED,
        name: 'Trends'
    },
    {
        id: MISSED_MONITORING_EXPECTATION,
        name: 'Missed monitoring'
    },
]

type NOTIFICATION_TYPE = typeof NOTIFICATION_TYPE

export {
    NOTIFICATION_TYPE,
    ACTION,
    ALERT,
    COMPROMISED,
    TAGGED_USER,
    MISSED_MONITORING,
    DATA_EXPORT,
    TREND_IDENTIFIED,
    MISSED_MONITORING_EXPECTATION,
    NotificationType,
}
