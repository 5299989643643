import { useState, useSelector } from '_/facade/react'
import type { CustomReportGraph } from '_/model/analysis/custom-report/custom-report'
import Button from '_/components/button'

import * as helpers from './helpers'
import { computeFilters } from '../../ui/helpers'

import type { OrganismIdentification } from '_/model/predefined-lists/organism-identification/types'
import * as dom from '_/model/dom'
import FormattedText from '_/features/text/formatted-text'
import { formatThresholdLineName } from '../../filter/helpers'

interface Props {
    customReportGraph: CustomReportGraph
    canEdit: boolean
    canDelete: boolean
    organisms: OrganismIdentification[]
    onDragStart: (_: CustomReportGraph) => void
    onDragEnd: () => void
    onDragDrop: (_: CustomReportGraph) => void
    canMoveAbove: (destination: CustomReportGraph) => boolean
    canMoveBelow: (destination: CustomReportGraph) => boolean
    onEdit: (graph: CustomReportGraph) => void
    onDelete: (graph: CustomReportGraph) => void
    testId?: string
}

function CustomReportsEditRow(props: Props) {
    const predefinedLists = useSelector(_ => _.predefinedLists)
        , dragEvents = !props.canEdit
            ? undefined
            : {
                onDragStart: handleDragStart,
                onDragEnd: handleDragEnd,
                onDragOver: handleDragOver,
                onDragLeave: handleDragLeave,
            }
        , [dragDirection, setDragDirection] = useState<dom.DragDirection>(dom.NONE)

    function handleDragStart(e: React.DragEvent<HTMLTableRowElement>) {
        e.dataTransfer.effectAllowed = 'move'
        props.onDragStart(props.customReportGraph)
    }

    function handleDragEnd() {
        props.onDragEnd()
    }

    function handleDragOver(e: React.DragEvent<HTMLTableRowElement>) {
        const direction = dom.getElementDragDirection(e.currentTarget, e)
            , canMove = direction === dom.BOTTOM && props.canMoveBelow(props.customReportGraph)
                || direction === dom.TOP && props.canMoveAbove(props.customReportGraph)

        if (!canMove)
            return

        setDragDirection(direction)

        e.preventDefault()
    }

    function handleDragLeave() {
        setDragDirection(dom.NONE)
    }

    function handleDrop() {
        if (!dragDirection)
            return

        setDragDirection(dom.NONE)
        props.onDragDrop(props.customReportGraph)
    }

    return (
        <tr
            className={`cursor-pointer table-row--drag-hover-${dragDirection}`}
            draggable={props.canEdit}
            {...dragEvents}
            onDrop={handleDrop}
        >
            <td onClick={e => e.preventDefault()}>
                {helpers.formatGraphType(props.customReportGraph.graph, predefinedLists.customFields)}
                {props.customReportGraph.graph.thresholdLines?.map((l, i) =>
                    <div key={i}>
                        {formatThresholdLineName(l)}
                    </div>
                )}
            </td>
            <td className='w-50'>
                <FormattedText
                    className='user-formatted-text'
                    testId={`${props.testId}-data-selection`}
                    text={computeFilters(props.customReportGraph.graph, predefinedLists, props.organisms).slice(1)}
                />
            </td>
            <td className='text-end'>
                <Button onClick={() => props.onEdit(props.customReportGraph)} className='btn-link' hasNoPermissions={!props.canEdit} testId={`${props.testId}-edit`}>Edit</Button>
                <Button
                    onClick={() => props.onDelete(props.customReportGraph)}
                    className='pb-0'
                    hasNoPermissions={!props.canEdit}
                    disabled={!props.canDelete}
                    title={!props.canDelete ? 'Cannot be removed, as it is the only graph' : ''}
                    testId={`${props.testId}-delete`}
                >
                    <i className='material-icons text-danger'>delete_outline</i>
                </Button>
            </td>
        </tr>
    )
}

export default CustomReportsEditRow
