const NUMBER_OF_ACTION_LIMIT_BREACHES = 0
    , ACTION_LIMIT_BREACH_RATE = 1
    , NUMBER_OF_CONTAMINATED_SAMPLES = 2
    , CONTAMINATION_RATE = 3
    , NUMBER_OF_ALERT_LIMIT_BREACHES = 4
    , ALERT_LIMIT_BREACH_RATE = 5

type SubChartType = never
    | typeof NUMBER_OF_ACTION_LIMIT_BREACHES
    | typeof ACTION_LIMIT_BREACH_RATE
    | typeof NUMBER_OF_CONTAMINATED_SAMPLES
    | typeof CONTAMINATION_RATE
    | typeof NUMBER_OF_ALERT_LIMIT_BREACHES
    | typeof ALERT_LIMIT_BREACH_RATE

const SUB_CHART_TYPE: { id: SubChartType, name: string }[] = [
    {
        id: NUMBER_OF_ACTION_LIMIT_BREACHES,
        name: 'Number of action limit breaches',
    },
    {
        id: ACTION_LIMIT_BREACH_RATE,
        name: 'Action limit breach rate',
    },
    {
        id: NUMBER_OF_CONTAMINATED_SAMPLES,
        name: 'Number of contaminated samples',
    },
    {
        id: CONTAMINATION_RATE,
        name: 'Contamination rate',
    },
    {
        id: NUMBER_OF_ALERT_LIMIT_BREACHES,
        name: 'Number of alert limit breaches',
    },
    {
        id: ALERT_LIMIT_BREACH_RATE,
        name: 'Alert limit breach rate',
    },
]

export {
    SUB_CHART_TYPE as default,
    NUMBER_OF_ACTION_LIMIT_BREACHES,
    ACTION_LIMIT_BREACH_RATE,
    NUMBER_OF_CONTAMINATED_SAMPLES,
    CONTAMINATION_RATE,
    NUMBER_OF_ALERT_LIMIT_BREACHES,
    ALERT_LIMIT_BREACH_RATE,
    SubChartType,
}
