import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'

import type Api from '_/services/notification-service'
import * as a from './actions'

const factory = (api: Api): EffectsFactory => _ => [
    handler(a.loadNotifications, api.getNotifications),
    handler(a.markAsRead, api.markAsRead),
    handler(a.markAsUnread, api.markAsUnread),
    handler(a.bulkMarkAsRead, api.bulkMarkAsRead),
    handler(a.loadNotificationInfo, api.getNotificationInfo),
]

export default factory
