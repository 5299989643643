import { createPortal } from 'react-dom'
import type { StateChangeOptions } from 'downshift'
import Downshift from 'downshift'
import { useState, classnames, useLayoutEffect, useCallback } from '_/facade/react'
import Overlay from '_/components/overlay/overlay'

import type { SearchIndexItem } from '_/model/help/search-index'

type Search = (_: string) => SearchIndexItem[]

interface Props {
    container: HTMLElement | null
    search: Search
    navigate: (page: string) => void
}

function Search(props: Props) {
    const portalContainer = props.container
        , [value, handleStateChange] = useChangeHandler(props.navigate)
        , searchResult = props.search(value)
        , menuProps = useMenuProps()

    if (!portalContainer)
        return null

    return createPortal(
        <Downshift<string | SearchIndexItem>
            selectedItem={value}
            onStateChange={handleStateChange}
            itemToString={_ => _ as string}
        >
            {d =>
                <div>
                    <input
                        {...d.getInputProps({
                            placeholder: 'Type here to search',
                            className: 'form-control',
                        })}
                    />
                    {d.isOpen && value.length >= 3 &&
                        <Overlay {...menuProps}>
                            <div
                                {...d.getMenuProps(
                                    {
                                        className: 'bg-white border rounded shadow p-2 help-search-results-menu',
                                    },
                                    { suppressRefError: true }
                                )}
                            >
                                {searchResult.map((item, idx) =>
                                    <div
                                        {
                                            ...d.getItemProps({
                                                key: item.uri,
                                                className: classnames('mb-3 border rounded cursor-pointer', d.highlightedIndex === idx ? 'bg-light' : 'border-white'),
                                                item,
                                            })
                                        }
                                    >
                                        <h5 className='mb-0' dangerouslySetInnerHTML={{ __html: item.title }} />
                                        <span dangerouslySetInnerHTML={{ __html: item.description }} />
                                    </div>
                                )}
                                {searchResult.length === 0 &&
                                    <div className='d-flex justify-content-center align-items-center h-100'>
                                        <span className='text-muted text-center'>
                                            We didn't find anything that matches your search.<br/>
                                            If you need more help, contact support at support@microgenetics.co.uk
                                        </span>
                                    </div>
                                }
                            </div>
                        </Overlay>
                    }
                </div>
            }
        </Downshift>,
        portalContainer
    )
}

export default Search

function useChangeHandler(navigate: (page: string) => void) {
    const [value, setValue] = useState('')

    function handleStateChange(changes: StateChangeOptions<string | SearchIndexItem>) {
        if (changes.selectedItem) {
            const item = changes.selectedItem as SearchIndexItem
            setValue('')
            navigate(item.uri)
        }
        else if (changes.inputValue != null) {
            setValue(changes.inputValue)
        }
    }

    return [value, handleStateChange] as const
}

function useMenuProps() {
    const [container, containerRef] = useState<HTMLElement | null>(null)
        , [topLeft, setTopLeft] = useState({ top: 0, left: 0 })
        , updateTopLeft = useCallback(
            (top: number, left: number) => {
                setTopLeft(
                    _ => _.top === top && _.left === left
                        ? _ // avoid re-render
                        : { top, left }
                )
            },
            []
        )

    useLayoutEffect(
        () => {
            const { width, height } = container?.getBoundingClientRect() ?? { width: 0, height: 0 }
                , top = Math.floor((window.innerHeight - height) / 2)
                , left = Math.floor((window.innerWidth - width) / 2)

            updateTopLeft(top, left)
        },
        [container, updateTopLeft]
    )

    return {
        ...topLeft,
        containerRef,
    }
}
