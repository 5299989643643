import { formatInlineExposureTime } from '../helpers'

import * as fieldIndex from '_/constants/custom-field-index'
import * as behaviours from '_/constants/objectionable-limit-behaviour'

import type { PredefinedLists } from '_/model/app-state'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type SampleDetails from '_/model/sample/sample-details'
import * as f from '_/model/sample/format'
import * as t from '_/model/text/text'

import FormattedText from '_/features/text/formatted-text'
import Barcode from '_/components/barcode'
import { useTimeService } from '_/components/time'
import { SampleLabel } from '_/components/sample-labels'

import { customFieldName } from '_/model/predefined-lists/custom-field/custom-field'

import {
    getActionLimitField,
    getAlertLimitField,
    formatSampleStatus,
} from '../helpers'
import { useMonitoringGroups } from '_/hooks/shared-hooks'

interface Props {
    sample: SampleDetails
    fields: CustomField[]
    predefinedLists: PredefinedLists
}

function SampleInformation(props: Props) {
    const timeService = useTimeService()
        , sample = props.sample
        , isManualActionLimitBreach = !sample.compromised && sample.manuallyActionLimitBreached
        , [monitoringGroups, adHocGroups] = useMonitoringGroups()

    function getFieldName(fieldIndex: fieldIndex.FieldIndex) {
        return customFieldName(fieldIndex, props.fields)
    }

    function breachText() {
        if (props.sample.behaviour === behaviours.BREACH_ALERT_LIMIT)
            return '(alert for objectionable organisms)'

        if (props.sample.behaviour === behaviours.BREACH_ACTION_LIMIT)
            return '(action required for objectionable organisms)'

        return ''
    }

    return (
        <fieldset>
            <h4 className='py-3'>
                Viable sample information
                {isManualActionLimitBreach &&
                    <span className='d-none d-print-inline'>
                        <SampleLabel className='label-action--color' text='Action (manual)'/>
                    </span>
                }
            </h4>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>Status</div>
                <div className='col-8 sample-reading__word-wrap fw-bold' data-testid='sample-info-data'>{formatSampleStatus(sample)}</div>
            </div>
            <div className='row'>
                <div className='col-4'>Monitoring group</div>
                <div className='col-8 sample-reading__word-wrap fw-bold'>
                    <FormattedText text={f.formatSampleMonitoringGroup(sample.fields, monitoringGroups, adHocGroups, props.predefinedLists.exposureLocations)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>Exposure time</div>
                <div className='col-8 sample-reading__word-wrap fw-bold' data-testid='sample-info-data'>
                    <FormattedText text={formatInlineExposureTime(sample, timeService, props.fields)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>{getFieldName(fieldIndex.EXPOSURE_LOCATION_ID)}</div>
                <div className='col-8 sample-reading__word-wrap'>
                    <div>
                        <span className='form-control-plaintext'>
                            <FormattedText
                                className='fw-bold'
                                testId='sample-info-data'
                                text={f.formatExposureLocation(sample.monitoringState, sample.fields, props.predefinedLists)}
                            />
                            <br />
                            <FormattedText
                                className='text-muted'
                                testId='sample-info-data'
                                text={[
                                    t.defaultTextNode('Grade: '),
                                    ...f.formatExposureLocationGrade(props.sample.monitoringState, props.sample.fields, props.predefinedLists),
                                    t.systemTextNode(' ' + breachText())
                                ]}
                            />
                            <br />
                            <span className='text-muted' data-testid='sample-info-data'>Alert limit: {getAlertLimitField(sample)}</span>
                            <br />
                            <span className='text-muted' data-testid='sample-info-data'>Action limit: {getActionLimitField(sample)}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>{getFieldName(fieldIndex.SAMPLE_TYPE_ID)}</div>
                <div className='col-8 fw-bold' data-testid='sample-info-data'>
                    <FormattedText text={f.formatByFieldIndex(fieldIndex.SAMPLE_TYPE_ID, props.sample.fields, props.predefinedLists)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>{getFieldName(fieldIndex.SESSION_ID)}</div>
                <div className='col-8 sample-reading__word-wrap fw-bold' data-testid='sample-info-data'>
                    <FormattedText text={f.formatByFieldIndex(fieldIndex.SESSION_ID, props.sample.fields, props.predefinedLists)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>{getFieldName(fieldIndex.OPERATORS_IDS)}</div>
                <div className='col-8 sample-reading__word-wrap fw-bold user-formatted-text' data-testid='sample-info-data'>
                    <FormattedText text={f.formatOperators(props.sample.fields, props.predefinedLists)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>{getFieldName(fieldIndex.BATCH_NUMBER)}</div>
                <div className='col-8 sample-reading__word-wrap fw-bold user-formatted-text' data-testid='sample-info-data'>
                    <FormattedText text={f.formatBatchNumbers(props.sample.fields)} />
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>{getFieldName(fieldIndex.BARCODE)}</div>
                <Barcode className='col-8 sample-reading__word-wrap fw-bold' data-testid='sample-info-data'>
                    <FormattedText text={f.formatByFieldIndex(fieldIndex.BARCODE, props.sample.fields, props.predefinedLists)} />
                </Barcode>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>Booked in by</div>
                <div
                    className='col-8 sample-reading__word-wrap fw-bold'
                    title={sample.personBookingIn.email}
                    data-testid='sample-info-data'
                >
                    {sample.personBookingIn.name}
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='sample-info-label'>Edited by</div>
                <div className='col-8 sample-reading__word-wrap fw-bold'>
                    {sample.editedBy.map(_ =>
                        <span
                            key={_.id}
                            title={_.email}
                            data-testid='sample-info-data'
                        >
                            {_.name}
                            <br />
                        </span>
                    )}
                </div>
            </div>
        </fieldset>
    )
}

export default SampleInformation
