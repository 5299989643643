import type { FormRenderProps} from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'
import { useAction, useState, useEffect, useSelector, classnames } from '_/facade/react'
import * as tree from '_/utils/tree'
import { diffObject, shallowUpdate } from '_/utils/object'
import type TimeService from '_/services/time-service'

import PageHeader from '_/components/page-header'
import { useContextSwitchObserver } from '_/components/context-observer'
import { LocalDateField, SelectField } from '_/components/form'
import { useTimeService } from '_/components/time'
import Button from '_/components/button'

import * as t from '_/model/text/text'
import type { LocationsReport, LocationsReportView } from '_/model/reports/locations-report/types'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import DEFAULT_DATE_RANGES, * as ranges from '_/constants/date-ranges'
import { ITEMS_LIMIT_COUNT, ITEMS_PRINT_LIMIT_COUNT } from '_/constants/items-limit-count'
import { CONTAMINATION, LIMIT_BREACH } from '_/model/reports/locations-report/trend-type'

import * as pa from '_/features/predefined-lists/redux/actions'
import { loadLocationsReportData } from './actions'
import validate from '_/model/reports/locations-report/validate'
import { formatLocationName, getSampleListParams } from '_/model/reports/locations-report/helpers'
import { getInitialDateValues, getGraphFooter, getDateRange } from '_/model/reports/helpers'
import { getGeneratedBy } from '_/features/samples/helpers'

import ExposureLocationsRow from './exposure-locations-row'
import LimitBreachSamples from './limit-breach-samples'
import ChildLocationTrends from './child-location-trends/child-location-trends'
import KpiBlock from '../kpi-block'
import LocationsReportGraph from '../report-line-graph'
import SessionBreaches from '../graph-by-day-session/session-breaches'
import OperatorFingerdabs from './operator-fingerdabs/fingerdab-table'
import OrganismGraph from './organism-pie-chart/organism-pie-chart'
import { useFilter } from '_/hooks/shared-hooks'
import { formatActiveState } from '_/utils/format/common'
import FormattedText from '_/features/text/formatted-text'

const LocationsReportPage = () => {
    const [predefinedLists, getGradeName] = useLoadDependentData()
        , timeService = useTimeService()
        , [values, changeFilter] = useLocationsFilter(timeService)
        , [locationReportResult, showSpinner] = useReport(values, timeService)
        , [expandState, setExpandState] = useState<tree.ExpandedItems>({})
        , selectedLocation = values.locationId ? predefinedLists.exposureLocations.idHash[values.locationId] : undefined
        , locationName = selectedLocation ? formatActiveState(formatLocationName(predefinedLists.exposureLocations, selectedLocation), selectedLocation.isActive) : [t.emptyTextNode()]
        , fullLocationName = selectedLocation ? t.plainText(formatActiveState(formatLocationName(predefinedLists.exposureLocations, selectedLocation), selectedLocation.isActive)) : ''
        , canExportData = useSelector(_ => _.auth.permissions.exportData)
        , [printMode, setPrintMode] = useState(false)
        , user = useSelector(_ => _.auth.user)
        , entityName = 'Exposure location: ' + fullLocationName
        , filterChangeHandler = useFilterChangeHandler(values)

    useResetLocationsReport(changeFilter, setExpandState, setPrintMode)
    useExpandRootLocation(values, predefinedLists.exposureLocations, setExpandState)

    return (
        <div className='d-flex align-items-stretch h-100 w-100 width-print-100'>
            <Form
                onSubmit={changeFilter}
                initialValues={values}
                validate={validate}
                render={form =>
                    <div className='d-flex d-print-none flex-column bg-light side-filters'>
                        <form className='overflow-y-auto flex-fill' onSubmit={form.handleSubmit}>
                            <FormSpy
                                onChange={_ => filterChangeHandler(form, _.values)}
                                subscription={{ values: true }}
                            />
                            <div className='px-3'>
                                <SelectField
                                    name='exposureDateRange'
                                    id='exposureDateRange'
                                    entities={DEFAULT_DATE_RANGES}
                                    calcId={_ => _.id}
                                    calcName={_ => _.name}
                                    placeholder='Select'
                                    testId='locations-report-filters-date-range'
                                >
                                    Exposure date range
                                </SelectField>
                                {form.values.exposureDateRange === ranges.CUSTOM &&
                                    <div>
                                        <LocalDateField id='exposureStartDateFrom' name='exposureStartDateFrom' testId='locations-report-start-date'>From exposure date</LocalDateField>
                                        <LocalDateField id='exposureStartDateTo' name='exposureStartDateTo' useDayEndTime  testId='locations-report-end-date'>To exposure date</LocalDateField>
                                    </div>
                                }
                                <hr className='mt-4 mb-3' />
                                <div className='text-nowrap' data-testid='locations-report-filters-locations-block'>
                                    <h5 className='fw-bold' data-testid='locations-report-filters-title'>Locations</h5>
                                    {tree.flatTree(predefinedLists.exposureLocations, expandState).map(location =>
                                        <ExposureLocationsRow
                                            key={location.id}
                                            expanded={expandState[location.id]}
                                            onTrigger={_ => setExpandState(shallowUpdate(expandState, { [_.id]: !expandState[_.id] }))}
                                            hasChildren={tree.hasChildren(predefinedLists.exposureLocations, location)}
                                            nestingLevel={tree.nestingLevel(predefinedLists.exposureLocations, location)}
                                            location={location}
                                            onClick={() => form.form.change('locationId', location.id)}
                                        />
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                }
            />
            <div className='container-fluid h-100 w-100 width-print-100 px-0'>
                <div className='d-flex justify-content-center align-items-stretch h-100'>
                    {!selectedLocation && <div className='align-self-center' data-testid='not-selected-location-placeholder'>Select a location to view the detailed report</div>}
                    {selectedLocation &&
                        <div className='flex-fill overflow-y-auto'>
                            {user && printMode &&
                                <div className='ms-3' data-testid='location-report-generated-by'>
                                    {getGeneratedBy(timeService, user.name, user.email)}
                                </div>
                            }

                            <PageHeader
                                sticky
                                className='d-print-block'
                                title={
                                    <div data-testid='locations-report-title'>
                                        Report for <FormattedText text={locationName} />
                                        <div className='form-control-plaintext fw-normal text-secondary fs-6' data-testid='locations-report-grade'>{getGradeName(selectedLocation)}</div>
                                    </div>
                                }
                            >
                                <Button
                                    className={classnames('align-self-start d-print-none btn-link me-1 ms-auto', {
                                        disabled: !canExportData,
                                    })}
                                    onClick={() => setPrintMode(!printMode)}
                                    hasNoPermissions={!canExportData}
                                    testId='location-report-print-mode'
                                >
                                    {printMode ? 'Report mode' : 'Print mode'}
                                </Button>
                                {printMode &&
                                    <Button
                                        className='btn-primary align-self-start d-print-none me-1'
                                        onClick={window.print}
                                        testId='location-report-print'>
                                            Print
                                    </Button>
                                }
                            </PageHeader>

                            <div className='container-fluid'>
                                <div className='row mt-3'>
                                    <KpiBlock
                                        kpiBlockInfo={locationReportResult?.limitBreachKpiBlock}
                                        trendType={LIMIT_BREACH}
                                        showSpinner={showSpinner}
                                        testId='locations-report-limit-breach-kpi'
                                    />
                                    <KpiBlock
                                        kpiBlockInfo={locationReportResult?.contaminationRateKpiBlock}
                                        trendType={CONTAMINATION}
                                        showSpinner={showSpinner}
                                        testId='locations-report-contamination-kpi'
                                    />
                                </div>

                                <div className={classnames('row mt-3', { 'flex-column': printMode })}>
                                    <LocationsReportGraph
                                        series={locationReportResult?.limitBreachGraphData ?? []}
                                        timeService={timeService}
                                        name={t.plainText(formatActiveState(selectedLocation.name, selectedLocation.isActive))}
                                        routeParams={getSampleListParams(values, true, timeService, predefinedLists.exposureLocations)}
                                        printMode={printMode}
                                        graphTitle={[t.defaultTextNode('graph for '), ...locationName]}
                                        footer={getGraphFooter(values, entityName, timeService)}
                                        chartTitle='Limit breach rate'
                                        emptyMessage='No data available'
                                        testId='limit-breach-graph'
                                        showSpinner={showSpinner}
                                    />
                                    <LocationsReportGraph
                                        series={locationReportResult?.contaminationRateGraphData ?? []}
                                        timeService={timeService}
                                        name={t.plainText(formatActiveState(selectedLocation.name, selectedLocation.isActive))}
                                        routeParams={getSampleListParams(values, false, timeService, predefinedLists.exposureLocations)}
                                        printMode={printMode}
                                        graphTitle={[t.defaultTextNode('graph for '), ...locationName]}
                                        footer={getGraphFooter(values, entityName, timeService)}
                                        chartTitle='Contamination rate'
                                        emptyMessage='No data available'
                                        testId='contamination-graph'
                                        showSpinner={showSpinner}
                                    />
                                </div>

                                <div className='row mt-3'>
                                    <ChildLocationTrends
                                        locationName={locationName}
                                        locationReport={values}
                                        childrenTrends={locationReportResult?.childrenTrends ?? []}
                                        printMode={printMode}
                                        entityName={entityName}
                                        itemsLimit={printMode ? ITEMS_PRINT_LIMIT_COUNT : ITEMS_LIMIT_COUNT}
                                        onLocationClick={locationId => {
                                            setExpandState(getExpandPath(locationId, predefinedLists.exposureLocations, expandState))
                                            changeFilter({...values, locationId})
                                        }}
                                        showSpinner={showSpinner}
                                        testId='child-locations-trends'
                                    />
                                </div>

                                <div className='row mt-3'>
                                    <LimitBreachSamples
                                        locationName={locationName}
                                        locationReport={values}
                                        predefinedLists={predefinedLists}
                                        printMode={printMode}
                                        entityName={entityName}
                                        routeParams={getSampleListParams(values, true, timeService, predefinedLists.exposureLocations)}
                                        itemsLimit={printMode ? ITEMS_PRINT_LIMIT_COUNT : ITEMS_LIMIT_COUNT}
                                        onLocationClick={locationId => {
                                            setExpandState(getExpandPath(locationId, predefinedLists.exposureLocations, expandState))
                                            changeFilter({...values, locationId})
                                        }}
                                        testId='limit-breaches-info'
                                    />
                                </div>

                                <div className={classnames('row mt-3 d-print-block', { 'flex-column': printMode })}>
                                    <div className='col-6 width-print-100'>
                                        <SessionBreaches
                                            name={t.plainText(formatActiveState(selectedLocation.name, selectedLocation.isActive))}
                                            routeParams={getSampleListParams(values, true, timeService, predefinedLists.exposureLocations)}
                                            sessionBreaches={locationReportResult?.sessionBreaches ?? []}
                                            title={[t.defaultTextNode('Average limit breaches by day and session')]}
                                            printMode={printMode}
                                            entityName={entityName}
                                            report={values}
                                            testId='average-breaches-by-session'
                                            showSpinner={showSpinner}
                                        />

                                        <OperatorFingerdabs
                                            locationName={locationName}
                                            locationReport={values}
                                            operatorFingerdabs={locationReportResult?.operatorFingerdabs ?? []}
                                            printMode={printMode}
                                            itemsLimit={printMode ? ITEMS_PRINT_LIMIT_COUNT : ITEMS_LIMIT_COUNT}
                                            locations={predefinedLists.exposureLocations}
                                            entityName={entityName}
                                            showSpinner={showSpinner}
                                            testId='operator-fingerdabs'
                                        />
                                    </div>

                                    <OrganismGraph
                                        locationName={t.plainText(formatActiveState(selectedLocation.name, selectedLocation.isActive))}
                                        routeParams={getSampleListParams(values, false, timeService, predefinedLists.exposureLocations)}
                                        series={locationReportResult?.organismGraphData ?? []}
                                        printMode={printMode}
                                        footer={getGraphFooter(values, entityName, timeService)}
                                        showSpinner={showSpinner}
                                        testId='organisms-recovered'
                                    />

                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default LocationsReportPage

function useLoadDependentData() {
    const loadPredefinedLists = useAction(pa.loadPredefinedLists)
        , predefinedLists = useSelector(_ => _.predefinedLists)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadPredefinedLists()
        },
        [contextSwitch, loadPredefinedLists]
    )

    function getGradeName(location: ExposureLocation) {
        if (location.isUngraded)
            return 'Ungraded'

        const name = predefinedLists.grades.find(_ => _.id === location.gradeId)?.name
        return name ? `Grade ${name}` : ''
    }

    return [predefinedLists, getGradeName] as const
}

function getInitialValues(timeService: TimeService): LocationsReport {
    return {
        locationId: undefined,
        ...getInitialDateValues(timeService)
    }
}

function useLocationsFilter(timeService: TimeService) {
    const [values, changeFilter] = useFilter<LocationsReport>('location-report', _ => ({ ...getInitialValues(timeService), ..._ }))

    return [values, changeFilter] as const
}

function useReport(filter: LocationsReport, timeService: TimeService) {
    const loadData = useAction(loadLocationsReportData)
        , [locationReportResult, setLocationReportResult] = useState<LocationsReportView>()
        , [showSpinner, setShowSpinner] = useState(false)

    useEffect(
        () => {
            const isFilterValid = Object.keys(validate(filter)).length === 0

            if (!isFilterValid)
                return

            let disposed = false
            setShowSpinner(true)
            loadData({
                    id: filter.locationId!,
                    request: getDateRange(timeService, filter)
                })
                .then(_ => {
                    if (!disposed)
                        setLocationReportResult(_)
                })
                .finally(() => setShowSpinner(false))

            return () => { disposed = true }
        },
        [filter, timeService, loadData]
    )

    return [locationReportResult, showSpinner] as const
}

function useResetLocationsReport(setValues: (_: LocationsReport) => void, setExpandState: (_: tree.ExpandedItems) => void, setPrintMode: (_: boolean) => void) {
    const contextSwitch = useContextSwitchObserver()
        , timeService = useTimeService()

    useEffect(
        () => {
            if (contextSwitch === 0)
                return

            setValues(getInitialValues(timeService))
            setExpandState({})
            setPrintMode(false)
        },
        [contextSwitch, timeService, setValues, setExpandState, setPrintMode]
    )
}

function useExpandRootLocation(filter: LocationsReport, locations: tree.Tree<ExposureLocation>, setExpandState: React.Dispatch<React.SetStateAction<tree.ExpandedItems>>) {
    const [initialLocation] = useState(filter.locationId)

    useEffect(
        () => {
            setExpandState(prevState => {
                if (Object.keys(prevState).length > 0)
                    return prevState

                const nextState: tree.ExpandedItems = {}
                tree.roots(locations)
                    .forEach(_ => nextState[_.id] = true)

                return nextState
            })
        },
        [locations, setExpandState]
    )

    useEffect(
        () => {
            setExpandState(
                _ => initialLocation ? getExpandPath(initialLocation, locations, _) : _
            )
        },
        [initialLocation, locations, setExpandState]
    )
}

function getExpandPath(locationId: string, locations: tree.Tree<ExposureLocation>, expandState: tree.ExpandedItems) {
    const location = locations.idHash[locationId]

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!location)
        return {}

    const path = tree.path(locations, location)
    let expandPath = expandState

    path.slice(0, path.length - 1)
        .forEach(_ => {
            expandPath = {...expandPath, [_.id]: true}
        })

    return expandPath
}

function useFilterChangeHandler(initialValues: LocationsReport) {
    return (form: FormRenderProps, values: LocationsReport) => {
        if (diffObject(initialValues, values))
            form.form.submit()
    }
}
