import * as bulkOperation from '_/constants/bulk-operation'

function bulkOperationConfirmationMessage(
    selectedSamplesCount: number, samplesWithActionByCurrentUserCount: number,
    samplesWithoutFlagCount: number, validSampleCount: number, operationId: bulkOperation.BulkOperationId
) {
    const { operationName, action, reason, flag } = getOperationProps(operationId)

    let message = ''

    if (samplesWithoutFlagCount === 0) {
        if (samplesWithActionByCurrentUserCount > 0 && selectedSamplesCount !== samplesWithActionByCurrentUserCount)
            message = `You cannot ${operationName} for ${samplesWithActionByCurrentUserCount} of the ${selectedSamplesCount} samples selected because you ${reason} ${samplesWithActionByCurrentUserCount === 1 ? 'it' : 'them'}. `
                + `Someone else will need to ${action} for ${samplesWithActionByCurrentUserCount === 1 ? 'this sample' : 'these samples'}. `
                + `Are you sure you want to ${action} for the other ${selectedSamplesCount - samplesWithActionByCurrentUserCount} sample${selectedSamplesCount - samplesWithActionByCurrentUserCount > 1 ? 's' : ''}?`

        if (samplesWithActionByCurrentUserCount === 0)
            message = `Are you sure you want to ${operationName} for ${selectedSamplesCount === 1 ? 'this sample' : `these ${selectedSamplesCount} samples`}?`

        if (selectedSamplesCount === samplesWithActionByCurrentUserCount)
            message = selectedSamplesCount === 1
                ? `You cannot ${operationName} for this sample because you ${reason} it. Someone else will need to ${action} for this sample`
                : `You cannot ${operationName} for any of the ${selectedSamplesCount} sample${selectedSamplesCount > 1 ? 's' : ''} selected because you ${reason} them. `
                    + `Someone else will need to ${action} for these samples`
    }

    if (samplesWithoutFlagCount > 0) {
        if (samplesWithActionByCurrentUserCount === 0)
            message = validSampleCount === 0
                ? `You cannot ${operationName} for ${samplesWithoutFlagCount === 1 ? 'this sample' : 'these samples'} because sample${samplesWithoutFlagCount > 1 ? 's do not' : ' does not'} have the flag ${flag}`
                : `All selected samples must have the flag ${flag} to perform this operation. `
                    + `You can not ${operationName} for ${samplesWithoutFlagCount} of the ${selectedSamplesCount} samples selected. `
                    + `Are you sure you want to ${action} for the other ${selectedSamplesCount - samplesWithoutFlagCount} sample${selectedSamplesCount - samplesWithoutFlagCount > 1 ? 's' : ''}?`

        if (samplesWithActionByCurrentUserCount > 0)
            message = validSampleCount === 0
                ? `You cannot ${operationName} for ${selectedSamplesCount === 1 ? 'this sample' : 'these samples'} because sample${selectedSamplesCount > 1 ? 's do not' : ' does not'}`
                    + ` have the flag ${flag} or you ${reason} ${selectedSamplesCount === 1 ? 'it' : 'them'}`
                : `You cannot ${operationName} for ${selectedSamplesCount - validSampleCount} of the ${selectedSamplesCount} `
                    + `sample${selectedSamplesCount > 1 ? 's' : ''} because you ${reason} ${selectedSamplesCount === 1 ? 'it' : 'them'} or sample${selectedSamplesCount > 1 ? 's do not' : ' does not'} have the flag ${flag}. `
                    + `${validSampleCount > 0 ? ` Are you sure you want to ${action} for the other ${validSampleCount} sample${validSampleCount > 1 ? 's' : ''}?` : ''}`
    }

    return message
}

function getOperationProps(operationId: bulkOperation.BulkOperationId) {
    return operationId === bulkOperation.CONFIRM_BOOK_IN
        ? {
            operationName: 'confirm book in',
            action: 'confirm book in',
            reason: 'booked',
            flag: '"Awaiting book in confirmation"',
        }
        : {
            operationName: 'verify CFU count',
            action: 'verify the CFU count',
            reason: 'last read',
            flag: '"Awaiting CFU count verification"',
        }
}

export {
    bulkOperationConfirmationMessage,
}
