import type TransitionInterceptor from './transition-interceptor'

import * as actions from '_/features/unsaved-changes/actions'

import type { RouteName } from '_/constants/routes'

const unsavedChangesInterceptor: TransitionInterceptor = (_, store, toState) => {
    if (store.getState().unsavedChange.unsavedChangeTargets.length === 0)
        return true

    const targetState = {
        name: toState.name as RouteName,
        params: toState.params,
    }
    store.dispatch(actions.showWarning({ showWarning: true,  targetState }))

    return toState.meta && toState.meta.source === 'popstate'
        ? Promise.reject({
            redirect: {
                name: toState.name,
                params: toState.params,
            },
        })
        : false
}

export default unsavedChangesInterceptor
