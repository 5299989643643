import { useState } from '_/facade/react'
import type Sample from '_/model/sample/sample'
import Contaminations from '_/components/contaminations'
import HoverMenu from '_/components/overlay/hover-menu'
import BreachIcon from '_/components/sample-icon/breach-icon'
import * as breachType from '_/constants/sample-breach-type'
import { getBreachTypeWithCompromised } from '../helpers'

interface Props {
    sample: Sample
}

const TotalCFU = (props: Props) => {
    const [countElement, setCountElement] = useState<HTMLElement | null>(null)
        , sample = props.sample
        , breachWithCompromised = getBreachTypeWithCompromised(sample)

    return (
        <span>
            <span ref={setCountElement}>
                {breachWithCompromised === breachType.NONE
                    ? sample.bothHandsCfu
                    : <BreachIcon
                        type={breachWithCompromised}
                        count={sample.bothHandsCfu}
                        withRef={!!sample.sampleInvestigationReferences}
                    />
                }
            </span>

            {(sample.bothHandsCfu || 0) > 0 &&
                <HoverMenu element={countElement}>
                    <div className='border p-1 bg-white word-wrap-break-word sample-list__identifications-hint'>
                        <Contaminations identifications={sample.identifications} />
                        <Contaminations identifications={sample.optionalIdentifications} />
                    </div>
                </HoverMenu>
            }
        </span>
    )
}
export default TotalCFU
