import { React, useCallback, useEffect } from '_/facade/react'
import getPlotly from '_/facade/plotly'

import ActionButtons from '_/features/reports/table-action-buttons'

import type { GradeGraphSeries } from '_/model/reports/missed-monitoring-report/types'
import { getFilterLength, getPrintLayout } from '_/features/analysis/helpers'
import { getOrganismPieChartLayout as getPieChartLayout } from '../../locations-report/organism-pie-chart/helpers'

interface PieChartProps {
    title: string
    series: GradeGraphSeries[]
    exportFileName: string
    footer: string[]
    author: string
    printMode: boolean
}

function PieChart(props: PieChartProps) {
    const root = React.createRef<HTMLDivElement>()
        , series = props.series
        , getChartData = useCallback(
            () => {
                const data = {
                    values: series.map(_ => _.count),
                    texttemplate: '%{percent} (%{value})',
                    labels: series.map(_ => _.gradeName ?? 'Ungraded'),
                    hovertext: formatData(series),
                    hoverinfo: 'text',
                    type: 'pie',
                    hole: .5,
                    sort: false,
                } as any as Plotly.Data

                return [data]
            },
            [series]
        )
        , plot = useCallback(
            () => {
                getPlotly().then(plotly => {
                    const footer = props.printMode ? props.footer : []
                    if (root.current)
                        plotly.newPlot(root.current, getChartData(), getPieChartLayout(props.title, footer, props.author), { displayModeBar: false })
                })
            },
            [root, getChartData, props.title, props.footer, props.author, props.printMode]
        )

    useEffect(plot, [plot])

    function handleExport() {
        getPlotly().then(plotly => {
            if (root.current) {
                const legendLength = props.series.length
                    , height = 700 + getFilterLength(0, legendLength)

                plotly.relayout(root.current, getPrintLayout({title: [props.title], subtitle: '', author: props.author, footerPlainText: props.footer, footer: []}, legendLength))

                const imgOpts = { format: 'png', width: 1000, height } as Plotly.ToImgopts
                plotly.downloadImage(root.current, {...imgOpts, filename: props.exportFileName })

                plotly.relayout(root.current, {...getPieChartLayout(props.title, [], ''), legend: {orientation: 'v'} })
            }
        })
    }

    return (
        <div>
            <div className='border border-light'>
                <div ref={root} />
            </div>
            {props.printMode
                ? null
                : <ActionButtons hideGoToSamples className='text-end me-3' onExport={handleExport} />
            }
        </div>
    )
}

export default PieChart

function formatData(series: GradeGraphSeries[]): string[] {
    const total = series.reduce((acc, v) => acc + v.count, 0)
    return series.map(data => {
            const percent = total === 0 ? 0 : data.count / total * 100
            return `${data.gradeName ?? 'Ungraded'} ${percent.toFixed(2)}% (${data.count})`
        })
}
