import { classnames, useSelector, useAction, useCallback, useEffect, useState } from '_/facade/react'

import Link from '_/components/link'

import * as routes from '_/constants/routes'

import * as actions from './actions'

import TrendsContainer from './trends-container'
import type { TrendStatistics } from '_/model/trends/types'
import { useContextSwitchObserver } from '_/components/context-observer'

function Trends() {
    const route = useSelector(_ => _.router.route!)
        , [trendStatistic, reloadStatistic] = useTrendStatistic()

    return(
        <div className='d-flex flex-column h-100'>
            <nav className='navbar navbar-secondary navbar-expand flex-shrink-0'>
                <ul className='navbar-nav'>
                    <li className='nav-item'>
                        <Link
                            className={classnames(
                                'nav-link',
                                {active: route.name === routes.TRENDS_ALL_TRENDS}
                            )}
                            routeName={routes.TRENDS_ALL_TRENDS}
                        >
                            All trends ({trendStatistic.totalCount})
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={classnames(
                                'nav-link',
                                {active: route.name === routes.TRENDS_WITHOUT_REF_TRENDS}
                            )}
                            routeName={routes.TRENDS_WITHOUT_REF_TRENDS}
                        >
                            Trends without investigation references ({trendStatistic.withoutRefCount})
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={classnames(
                                'nav-link',
                                {active: route.name === routes.TRENDS_WITH_REF_TRENDS}
                            )}
                            routeName={routes.TRENDS_WITH_REF_TRENDS}
                        >
                            Trends with investigation references ({trendStatistic.withRefCount})
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className='d-flex flex-fill overflow-auto'>
                <div className='container-fluid main-block'>
                    <div className='row justify-content-center h-100'>
                        <div className='col-9 h-100 d-flex flex-column'>
                            <TrendsContainer
                                trendStatistic={trendStatistic}
                                onReloadStatistic={reloadStatistic}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Trends

function useTrendStatistic() {
    const loadTrendStatistic = useAction(actions.loadTrendStatistics)
        , [trendStatistic, setTrendStatistic] = useState<TrendStatistics>({totalCount: 0, withRefCount: 0, withoutRefCount: 0})
        , contextSwitch = useContextSwitchObserver()
        , load = useCallback(
            () => {
                loadTrendStatistic()
                    .then(setTrendStatistic)
            },
            [loadTrendStatistic]
        )

        useEffect(load, [contextSwitch, load])

    return [trendStatistic, load] as const
}
