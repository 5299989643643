import { connect } from '_/facade/react'
import type AppState from '_/model/app-state'
import * as routes from '_/constants/routes'
import Link from '_/components/link'

const Navbar: React.FC<ConnectedProps> = props =>
    <nav className='navbar navbar-secondary navbar-expand flex-shrink-0'>
        <div className='collapse navbar-collapse'>
            <ul className='navbar-nav'>
                <li className='nav-item'>
                    <Link
                        routeName={routes.SETTINGS_CONTEXTS}
                        className='nav-link'
                        hasNoPermissions={!props.auth.permissions.readContexts}
                    >
                        Sites
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link
                        routeName={routes.SETTINGS_USERS}
                        className='nav-link'
                        hasNoPermissions={!props.auth.permissions.readUsers}
                    >
                        Users
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link
                        routeName={routes.SETTINGS_ROLES}
                        className='nav-link'
                        hasNoPermissions={!props.auth.permissions.manageRoles}
                    >
                        User roles
                    </Link>
                </li>
                {props.auth.permissions.smartControlMode &&
                    <>
                        <li className='nav-item'>
                            <Link
                                routeName={routes.SETTINGS_SAMPLE_FIELDS}
                                className='nav-link'
                                hasNoPermissions={!props.auth.permissions.readCustomFields}
                            >
                                Data fields
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                routeName={routes.SETTINGS_LOCATIONS}
                                className='nav-link'
                                hasNoPermissions={!props.auth.permissions.readExposureLocations}
                            >
                                Locations
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                routeName={routes.SETTINGS_OPERATORS}
                                className='nav-link'
                                hasNoPermissions={!props.auth.permissions.readSampleOperator}
                            >
                                Operators
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                routeName={routes.SETTINGS_ACTION_ALERT_LIMITS}
                                className='nav-link'
                                hasNoPermissions={!props.auth.permissions.readActionAlertLimits}
                            >
                                Grades
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                routeName={routes.SETTINGS_SESSIONS}
                                className='nav-link'
                                hasNoPermissions={!props.auth.permissions.readSampleSession}
                            >
                                Sessions
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                routeName={routes.SETTINGS_SAMPLE_TYPES}
                                className='nav-link'
                                hasNoPermissions={!props.auth.permissions.readSampleType}
                            >
                                Sample types
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                routeName={routes.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS}
                                className='nav-link'
                                hasNoPermissions={!props.auth.permissions.readObjectionableOrganisms}
                            >
                                Identifications
                            </Link>
                        </li>
                        {props.auth.permissions.editOrganismIdentification &&
                            <li className='nav-item'>
                                <Link
                                    routeName={routes.SETTINGS_ORGANISM_IDENTIFICATION}
                                    className='nav-link'
                                >
                                    Organism identification
                                </Link>
                            </li>
                        }
                        <li className='nav-item'>
                            <Link
                                routeName={routes.SETTINGS_TRENDS}
                                className='nav-link'
                                hasNoPermissions={!props.auth.permissions.readTrendSettings}
                            >
                                Trend settings
                            </Link>
                        </li>
                    </>
                }
            </ul>
        </div>
    </nav>



function mapStateToProps(state: AppState) {
    return {
        auth: state.auth,
    }
}

type ConnectedProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(Navbar)
