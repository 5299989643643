const START_CHAR = 'A'.charCodeAt(0)
    , CHAR_SPAN = 26

function encodeReference(ref: number): string {
    if (ref < 0)
        return ''

    let result = ''

    do {
        const rem = ref % CHAR_SPAN
        ref = Math.floor(ref / CHAR_SPAN - 1)

        result = String.fromCharCode(rem + START_CHAR) + result
    } while (ref >= 0)

    return result
}

function nextReference(ref: number): number {
    return ref + 1
}

function maxReference(...refs: (number | number[])[]): number {
    const referencesInUse = refs.flatMap(_ => _)
        , maxRef = Math.max(...referencesInUse)

    return maxRef
}

export {
    encodeReference,
    nextReference,
    maxReference,
}
