import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { React, useAction, useState, useLayoutEffect } from '_/facade/react'

import { Table } from '_/components/table'
import FormChangesObserver from '_/components/form/form-changes-observer'
import { SelectField, Submit, submitDisabled } from '_/components/form'
import FormattedText from '_/features/text/formatted-text'

import type Grade from '_/model/predefined-lists/grade/grade'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type * as lt from '_/model/predefined-lists/action-alert-limit/types'

import GradeForm from './grade'

import * as actions from './actions'
import * as toastActions from '_/features/toasts/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import * as confirmationActions from '_/features/confirmation/actions'

import { getEditedLimits, getLimits, limitsValues } from './helpers'
import validate from '_/model/predefined-lists/action-alert-limit/validate'

import { diffObject, dropFields } from '_/utils/object'

import OBJECTIONABLE_LIMIT_BEHAVIOUR from '_/constants/objectionable-limit-behaviour'
import GRADE_CFU_VERIFICATION,  * as gradeCfuVerification from '_/constants/grade-cfu-verification'
import PHOTOGRAPH_BEHAVIOUR from '_/constants/photograph-behaviour'
import { CHANGES_SAVED } from '_/features/samples/messages'
// import * as tree from '_/utils/tree'
// import { INSIGHT } from '_/constants/tiers'
import { formatActiveState } from '_/utils/format/common'
import type { EditNonViableLimitsProps, NonViableLimits } from '_/model/predefined-lists/action-alert-limit/non-viable-limits'
import LimitFields from './limit-fields'
import { PARTICLE_STATE } from '_/model/predefined-lists/action-alert-limit/non-viable-limit-type'

interface Props {
    sampleTypes: SampleType[]
    grade: Grade
    grades: Grade[]
    canEditLimits: boolean
    changeCfuConfirmationSetting: boolean
    limits: lt.Limits[]
    nonViableLimits: NonViableLimits[]
    onReloadLimits: () => void
    testId?: string
}

function LimitsForm(props: Props) {
    const [initialValues, setInitialValue] = useState(limitsValues(props.limits, props.sampleTypes, props.grade, props.nonViableLimits))
       /* , user = useSelector(_ => _.auth.user)
        , contexts = useSelector(_ => tree.list(_.contexts.list))
        , context = contexts.find(_ => user?.membership.contextId) */
        , handleSave = useSubmit(props.grade, props.limits, props.nonViableLimits, props.onReloadLimits)

    useLayoutEffect(
        () => {
            const newLimits = limitsValues(props.limits, props.sampleTypes, props.grade, props.nonViableLimits)
            setInitialValue(_ => diffObject(_, newLimits) ? newLimits : _)
        },
        [props.limits, props.sampleTypes, props.grade, props.nonViableLimits]
    )

    function getCfuVerificationOptions() {
        /* if (user?.membership && user.membership.tier < INSIGHT || !context?.zeroGrowthVerificationEnabled)
            return GRADE_CFU_VERIFICATION.filter(_ => _.id !== gradeCfuVerification.AUTOMATED) */

        return GRADE_CFU_VERIFICATION
    }

    return (
        <div className='col-6 py-3'>
            <div id={`grade-name-${props.grade.id}`} className='block-border p-3'>
                <GradeForm
                    grade={props.grade}
                    grades={props.grades}
                    hasNoPermission={!props.canEditLimits}
                    testId={props.testId}
                />

                <Form<lt.LimitsForm>
                    onSubmit={handleSave}
                    initialValues={initialValues}
                    mutators={{...arrayMutators}}
                    validate={_ => validate(_, props.sampleTypes)}
                    render={form =>
                        <form onSubmit={form.handleSubmit}>
                            <FormChangesObserver target={`limits-${props.grade.id}`} form={form}/>
                            <Table className='table table-borderless table-layout-fixed'>
                                <thead>
                                    <tr className='limits-table-header'>
                                        <th className='border-0' style={{ width: 'auto' }}/>
                                        <th className='border-bottom pb-3' colSpan={2} style={{ width: '34%' }}>In operation</th>
                                        <th className='border-bottom pb-3' colSpan={2} style={{ width: '34%' }}>At rest</th>
                                    </tr>
                                    <tr className='limits-table-header'>
                                        <th className='border-0' style={{ width: 'auto' }}>Sample name</th>
                                        <th className='border-0' style={{ width: '17%' }}>Alert limit</th>
                                        <th className='border-0' style={{ width: '17%' }}>Action limit</th>

                                        <th className='border-0' style={{ width: '17%' }}>Alert limit</th>
                                        <th className='border-0' style={{ width: '17%' }}>Action limit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.sampleTypes.map((type, i) =>
                                        <tr key={i}>
                                            <td className='py-0'>
                                                <div className='row g-2'>
                                                    <div className='col-form-label limits__sample-name-label' data-testid={`limits-sample-name-${type.name}`}>
                                                        <FormattedText text={formatActiveState(type.name, type.isActive)} />
                                                    </div>
                                                </div>
                                            </td>
                                            <LimitFields
                                                index={i}
                                                name={'limits'}
                                                hasNoPermissions={!props.canEditLimits || !props.grade.id}
                                                gradeId={props.grade.id}
                                                typeName={type.name}
                                                testId={props.testId}
                                            />
                                        </tr>
                                    )}
                                    {PARTICLE_STATE.map((_, i) =>
                                        <tr key={`non-viable-${i}`}>
                                            <td className='py-0'>
                                                <div className='row g-2'>
                                                    <div className='col-form-label limits__sample-name-label'>
                                                        {_.name}
                                                    </div>
                                                </div>
                                            </td>
                                            <LimitFields
                                                index={i}
                                                name={'nonViableLimits'}
                                                hasNoPermissions={!props.canEditLimits || !props.grade.id}
                                                gradeId={props.grade.id}
                                                typeName={_.name}
                                            />
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan={5} className='fw-bold text-muted'>Viable samples</td>
                                    </tr>
                                    <tr>
                                        <td className='py-0'>
                                            <div className='row g-2'>
                                                <span className='col-form-label'>
                                                    Objectionable organisms
                                                </span>
                                            </div>
                                        </td>
                                        <td colSpan={4} className='pt-0 px-1'>
                                            <SelectField
                                                inline
                                                key={props.grade.id}
                                                name={`behaviour`}
                                                entities={OBJECTIONABLE_LIMIT_BEHAVIOUR}
                                                calcId={_ => _.id}
                                                calcName={_ => _.name}
                                                className='px-1  mb-0'
                                                hasNoPermissions={!props.canEditLimits || !props.grade.id}
                                                testId={`${props.testId}-field-objectionable`}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='py-0'>
                                            <div className='row g-2'>
                                                <span className='col-form-label'>
                                                    Verify CFU count
                                                </span>
                                            </div>
                                        </td>
                                        <td colSpan={4} className='pt-0 px-1'>
                                            <SelectField
                                                inline
                                                name='cfuVerification'
                                                entities={getCfuVerificationOptions()}
                                                calcId={_ => _.id}
                                                calcName={_ => _.name}
                                                className='px-1  mb-0'
                                                hasNoPermissions={!props.canEditLimits || !props.changeCfuConfirmationSetting}
                                                testId={`${props.testId}-field-cfu-verification`}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='py-0'>
                                            <div className='row g-2'>
                                                <span className='col-form-label'>
                                                    Photograph required
                                                </span>
                                            </div>
                                        </td>
                                        <td colSpan={4} className='pt-0 px-1'>
                                            <SelectField
                                                inline
                                                name='photoBehaviour'
                                                entities={PHOTOGRAPH_BEHAVIOUR}
                                                calcId={_ => _.id}
                                                calcName={_ => _.name}
                                                className='px-1 mb-0'
                                                hasNoPermissions={!props.canEditLimits || !props.grade.id}
                                                testId={`${props.testId}-field-photo-behaviour`}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div className='clearfix pe-1 mb-2 mt-1'>
                                <Submit
                                    className='btn-primary float-end'
                                    disabled={submitDisabled(form)}
                                    hasNoPermissions={!props.canEditLimits || !props.grade.id}
                                    testId={`${props.testId}-save-changes`}
                                >
                                    Save changes
                                </Submit>
                            </div>
                        </form>
                    }
                />
            </div>
        </div>
    )
}

export default LimitsForm

function useSubmit(grade: Grade, limitsList: lt.Limits[], nonViableLimitsList: NonViableLimits[], reload: () => void) {
    const saveLimits = useAction(actions.saveLimits)
        , addSuccess = useAction(toastActions.addSuccess)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , showConfirmationWarning = useAction(confirmationActions.showConfirmationModal)

    function isConfirmationRequired(oldCfuVerification: gradeCfuVerification.GradeCfuVerification, newCfuVerification: gradeCfuVerification.GradeCfuVerification) {
        switch (oldCfuVerification) {
            case (gradeCfuVerification.ALWAYS):
                return true
            case (gradeCfuVerification.ON_ALERT_LIMIT):
                return newCfuVerification !== gradeCfuVerification.ALWAYS
            case (gradeCfuVerification.ON_ACTION_LIMIT):
                return newCfuVerification === gradeCfuVerification.NEVER // || newCfuVerification === gradeCfuVerification.AUTOMATED
            default: return false
        }
    }

    function handleSave(limits: lt.LimitsForm) {
        const newGrade = { name: grade.name, behaviour: limits.behaviour, photoBehaviour: limits.photoBehaviour!, cfuVerification: limits.cfuVerification }
            , oldGrade = dropFields(grade, 'id')
            , cfuVerificationChanged = oldGrade.cfuVerification !== newGrade.cfuVerification
            , editedLimits = limits.limits
                .map(limitDetails => {
                    const oldLimitsData = limitsList.find(_ => limitDetails.sampleTypeId === _.sampleTypeId)
                    return getEditedLimits(oldLimitsData, getLimits(limitDetails))
                })
                .filter((_: lt.EditLimitsProps | undefined): _ is lt.EditLimitsProps => _ !== undefined)

            , createdLimits = limits.limits
                .filter(limitDetails =>
                    !limitsList.find(_ => limitDetails.sampleTypeId === _.sampleTypeId) && Object.values(getLimits(limitDetails) ?? {}).some(_ => _)
                )

            , createdNonViableLimits = limits.nonViableLimits
                .filter(limitDetails =>
                    !nonViableLimitsList.find(_ => limitDetails.particleState === _.particleState) && Object.values(getLimits(limitDetails) ?? {}).some(_ => _)
                )

            , nonViableEditedLimits = limits.nonViableLimits
                .map(limitDetails => {
                    const oldLimitsData = nonViableLimitsList.find(_ => limitDetails.particleState === _.particleState)
                    return getEditedLimits(oldLimitsData, getLimits(limitDetails))
                })
                .filter((_: EditNonViableLimitsProps | undefined): _ is EditNonViableLimitsProps => _ !== undefined)

        hasUnsavedChanges(false)

        return Promise.resolve()
            .then(() => {
                if (cfuVerificationChanged && isConfirmationRequired(oldGrade.cfuVerification, newGrade.cfuVerification))
                    return showConfirmationWarning('There might be samples awaiting CFU count verification. Are you sure you want to remove the "Awaiting CFU count verification" flag from those samples?')
            })
            .then(() => saveLimits({
                editedLimits,
                newLimits: createdLimits,
                nonViableEditedLimits,
                newNonViableLimits: createdNonViableLimits,
                grade: {
                    gradeId: grade.id,
                    oldGrade,
                    newGrade,
                },
            }))
            .then(reload)
            .then(() => addSuccess(CHANGES_SAVED))
    }

    return handleSave
}
