import { Form } from 'react-final-form'

import { TextField } from '_/components/form'
import { Submit, submitDisabled } from '_/components/form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import Button from '_/components/button'

import type { ValidationResult} from '_/utils/form/validate'
import { isEmpty, allowedName } from '_/utils/form/validate'

import type { ExistedSampleSearchFilter } from '_/model/sample/search'

interface FormValues {
    filterName: string
}

interface OwnProps {
    isOpen: boolean
    onSave(filterName: string): void
    onCancel: () => void
    sampleSearchFilters: ExistedSampleSearchFilter[]
}

function validate(formValues: FormValues, existedSampleSearchFilter: ExistedSampleSearchFilter[]): ValidationResult<FormValues> {
    const result: ValidationResult<FormValues> = {}
        , notAllowedNames = allowedName('Filter name')(formValues.filterName)

    if (isEmpty('Name')(formValues.filterName))
        result.filterName = 'Filter Name is required'

    if (notAllowedNames)
        result.filterName = notAllowedNames

    if (formValues.filterName && existedSampleSearchFilter.find(_ => _.name.trim().toLowerCase() === formValues.filterName.trim().toLowerCase()))
        result.filterName = `Filter with name '${formValues.filterName.trim()}' already exists`

    if (formValues.filterName && formValues.filterName.length > 100)
        result.filterName = 'Filter name can be up to 100 characters'

    return result
}

const AddSearchFilterModal: React.FC<OwnProps> = props =>
    <Modal isOpen={props.isOpen} onClose={props.onCancel}>
        <Form
            validate={_ => validate(_ as FormValues, props.sampleSearchFilters)}
            onSubmit={_ => props.onSave((_ as FormValues).filterName)}
            render={form =>
                <form onSubmit={form.handleSubmit}>
                    <ModalHeader>Choose name for filter</ModalHeader>
                    <ModalBody>
                        <TextField name='filterName' id='rows' testId='filter-name'>Filter name</TextField>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='btn-secondary' onClick={props.onCancel}>
                            Cancel
                        </Button>
                        <Submit disabled={submitDisabled(form)} testId='filter-save'>Save</Submit>
                    </ModalFooter>
                </form>
            }
        />
    </Modal>

export default AddSearchFilterModal
