import { actionFactory } from '_/utils/redux'

import type { ApiKeyEdit, ApiKeyPair, ApiKeyCreateView } from '_/model/api-key/types'
import type ApiKey from '_/model/api-key/types'

const action = actionFactory('app.api-key')

export const loadApiKey = action<string, Promise<ApiKey>>('loadApiKey')
           , loadApiKeyList = action<string, Promise<void>>('loadApiKeyList')
           , apiKeyListLoaded = action<ApiKey[]>('apiKeyListLoaded')
           , createApiKey = action<void, Promise<ApiKeyCreateView>>('createApiKey')
           , saveApiKey = action<{ id: string, oldApiKey: ApiKeyEdit, newApiKey: ApiKeyEdit, fromCreate?: boolean }, Promise<void>>('saveApiKey')
           , apiKeyChanged = action<{ id: string, apiKey: Partial<ApiKey> }>('apiKeyChanged')
           , removeApiKey = action<string, Promise<void>>('removeApiKey')
           , apiKeyRemoved = action<string>('apiKeyRemoved')
           , clearApiKeys = action<void>('clearApiKeys')
           , downloadApiKeys = action<ApiKeyPair, Promise<void>>('downloadApiKeys')
           , dismissApiKey = action<string, Promise<void>>('dismissApiKey')
