import { useSelector, useState, useAction, useEffect } from '_/facade/react'

import AuditTrails from '_/components/audit-trail-list'

import * as a from '../actions'

import type * as at from '_/model/audit-trail/types'

function AuditTrail() {
    const trails = useAuditTrail()

    return (
        <div className='overflow-y-auto'>
            <AuditTrails hideHeader trail={trails}></AuditTrails>
        </div>
    )
}

export default AuditTrail

function useAuditTrail() {
    const load = useAction(a.loadFloorPlanTrail)
        , id = useSelector<string>(_ => _.router.route?.params.id)
        , [trails, setTrails] = useState<at.AuditTrail | undefined>(undefined)

    useEffect(
        () => {
            if (id)
                load(id).then(setTrails)
        },
        [load, id]
    )

    return trails
}
