import { useAction, useCallback, useRef, useState } from '_/facade/react'

import type { NonViableSample, NonViableSampleCreateRequest } from '_/model/non-viable-sample/booking/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import * as actions from '../actions'
import * as toastActions from '_/features/toasts/actions'
import * as spinnerActions from '_/features/spinner/actions'

import NonViableSampleFormComponent from './non-viable-sample-form'
import NonViableSampleList from './list'
import NonViableSampleCommentsModal from './non-viable-sample-comments-modal'
import NonViableSampleEditModal from './non-viable-sample-edit-modal'
import * as messages from '../messages'
import { getDefaultFormValues } from './helpers'

import { dropFields } from '_/utils/object'
import { memoize, noop } from '_/utils/function'

import { mergeAndReplaceEntity } from '_/utils/array'
import { useUnsavedChangesTracker } from '_/hooks/shared-hooks'
import type { ResetFocusFormRef } from '_/features/samples/booking/sample-booking-form'
import type { Guid } from '_/model/guid'
import { useBookInDependentData } from '_/features/samples/shared/hooks'

const SAMPLE_CREATE_FORM_ID = 'non-viable-sample-form'
    , SAMPLE_EDIT_FORM_ID = 'non-viable-sample-edit-form'

function NonViableSampleComponent() {
    const formRef = useRef<ResetFocusFormRef>(null)
        , [predefinedLists, exposureLocations] = useBookInDependentData(false)
        , initialValue = useInitialData(predefinedLists.customFields)
        , [samples, setSamples] = useState<NonViableSample[]>([])
        , [editedSample, setEditedSample] = useState<NonViableSample | undefined>()
        , [commentedSample, setCommentedSample] = useState<NonViableSample | undefined>()
        , [comments, setComments] = useState<string[]>([])
        , loadComments = useLoadComments(setComments)
        , handleAddComment = useAddCommentHandler(samples, setSamples, comments, setComments)
        , handleOpenCommentsModal = useOpenCommentsModalHandler(setCommentedSample, loadComments)
        , handleCreateSample = useCreateSampleHandler(samples, setSamples)
        , reset = useCallback(
            () => {
                setCommentedSample(undefined)
                setEditedSample(undefined)
                formRef.current?.resetFocus()
            },
            []
        )
        , handleEditSample = useEditSampleHandler(samples, setSamples, reset)

    useUnsavedChangesTracker(reset)

    return (
        <>
            <div
                style={{
                    // lets calendar to popup over table sticky headings but stay entire header a modal
                    zIndex: 5,
                }}
            >
                <p className='fw-bold ms-3'>Add a new non-viable sample</p>
                {initialValue.fields.length > 0 &&
                    <NonViableSampleFormComponent
                        initialValue={initialValue}
                        predefinedLists={predefinedLists}
                        exposureLocations={exposureLocations}
                        onSubmit={handleCreateSample}
                        ref={formRef}
                        formTarget={SAMPLE_CREATE_FORM_ID}
                    />
                }
                <p className='fw-bold ms-3 py-4 mb-0'>Non-viable samples added ({samples.length})</p>
            </div>
            <div className='flex-fill overflow-y-auto mb-3'>
                <NonViableSampleList
                    onOpenCommentsModal={handleOpenCommentsModal}
                    samples={samples}
                    exposureLocations={exposureLocations}
                    onOpenNonViableSampleEditModal={setEditedSample}
                    predefinedLists={predefinedLists}
                />
            </div>
            {commentedSample &&
                <NonViableSampleCommentsModal
                    onClose={reset}
                    onAddComment={_ => handleAddComment(commentedSample, _)}
                    comments={comments}
                />
            }
            {editedSample && editedSample.fields.length > 0 &&
                <NonViableSampleEditModal
                    onSubmit={_ => handleEditSample(editedSample, _)}
                    onClose={reset}
                    nonViableSample={dropFields(editedSample, 'id', 'comments')}
                    predefinedLists={predefinedLists}
                    exposureLocations={exposureLocations}
                    formTarget={SAMPLE_EDIT_FORM_ID}
                />
            }
        </>
    )
}

const memGetDefaultFormValues = memoize(getDefaultFormValues)

function useInitialData(editableFields: CustomField[]) {
    return memGetDefaultFormValues(editableFields)
}

function useAddCommentHandler(
    samples: NonViableSample[],
    setSamples: (_: NonViableSample[]) => void,
    comments: string[],
    setComments: (_: string[]) => void
) {
    const addNonViableSampleComments = useAction(actions.addNonViableSampleComments)
        , addSuccess = useAction(toastActions.addSuccess)

    function handleAddComment(sample: NonViableSample, comment: string) {
        addNonViableSampleComments({ nonViableSampleId: sample.id, nonViableSampleComments: [comment] })
            .then(() => setComments([comment].concat(comments)))
            .then(() => {
                const editedSample = samples.find(_ => _.id === sample.id)
                if (!editedSample)
                    return

                const newSample = { ...editedSample, comments: editedSample.comments.concat(comment) }
                setSamples(mergeAndReplaceEntity(samples, newSample))
            })
            .then(() => addSuccess(messages.CHANGES_SAVED))
    }

    return handleAddComment
}

function useLoadComments(setComments: (_: string[]) => void) {
    const showSpinner = useAction(spinnerActions.showSpinner)
        , hideSpinner = useAction(spinnerActions.hideSpinner)
        , load = useAction(actions.loadNonViableSampleComments)

    function loadComments(sampleId: Guid) {
        return Promise.resolve()
            .then(showSpinner)
            .then(() => load(sampleId))
            .then(_ => setComments(_.map(c => c.message).reverse()))
            .finally(hideSpinner)
    }

    return loadComments
}

function useOpenCommentsModalHandler(
    setCommentedSample: (_: NonViableSample | undefined) => void,
    loadComments: (_: Guid) => Promise<void>
) {
    function handleOpenCommentsModal(sample: NonViableSample) {
        loadComments(sample.id)
            .then(() => setCommentedSample(sample))
    }

    return handleOpenCommentsModal
}

function useCreateSampleHandler(
    samples: NonViableSample[],
    setSamples: (_: NonViableSample[]) => void
) {
    const create = useAction(actions.createNonViableSample)
        , addSuccess = useAction(toastActions.addSuccess)

    function handleCreateSample(sample: Partial<NonViableSampleCreateRequest>) {
        return create(sample)
            .then(id => {
                const newSample = { ...sample, id } as NonViableSample
                    , newSamples = samples.concat(newSample)

                setSamples(newSamples)
            })
            .then(() => addSuccess(messages.NON_VIABLE_SAMPLE_SAVED))
            .then(noop)
    }

    return handleCreateSample
}

function useEditSampleHandler(
    samples: NonViableSample[],
    setSamples: (_: NonViableSample[]) => void,
    onEditModalClose: () => void
) {
    const saveChanges = useAction(actions.editReadNonViableSample)
        , addSuccess = useAction(toastActions.addSuccess)

    function handleEditSample(sample: NonViableSample, editedSample: Partial<NonViableSampleCreateRequest>) {
        const newEditedSample = { ...sample, ...editedSample }
            , oldNonViableSample = dropFields(sample, 'comments')
            , newNonViableSample = dropFields(newEditedSample, 'comments')

        return saveChanges({ id: sample.id, oldNonViableSample, newNonViableSample })
            .then(() => setSamples(mergeAndReplaceEntity(samples, newEditedSample)))
            .then(() => addSuccess(messages.NON_VIABLE_SAMPLE_SAVED))
            .then(onEditModalClose)
            .then(noop)
    }

    return handleEditSample
}

export default NonViableSampleComponent
