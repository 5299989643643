import { useTimeService } from '_/components/time'
import preprocessTrailMessage from '_/model/audit-trail/helpers'

import FormattedText from '_/features/text/formatted-text'
import type { Text } from '_/model/text/text'
import type { Events } from '_/model/audit-trail/types'

interface MessageProps {
    message: Text
    entityType: number
    testId: string
}

const TrailMessage = (props: MessageProps) => {
    const timeService = useTimeService()
        , text = props.message.flatMap(_ => preprocessTrailMessage(timeService, _, props.entityType))

    return (
        <div className='user-formatted-text' data-testid={props.testId}>
            <FormattedText text={text} />
        </div>
    )
}

interface Props {
    event: Events
}

const TrailMessages = (props: Props) =>
    <div>
        {props.event.message.map((_, index) =>
            <TrailMessage key={index} message={_} entityType={props.event.entityInfo.entityType} testId='audit-trail-message' />
        )}
        {props.event.isSigned && <div>Electronically signed</div>}
        {props.event.reason &&
            <div>{`Reason: ${props.event.reason}`}</div>
        }
    </div>

export default TrailMessages
