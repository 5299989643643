import type { CustomReports as State } from '_/model/app-state'
import { commonReducer, handler, shallowUpdate } from '_/facade/reducer'

import * as actions from './actions'

const defaultState: State = {
    customReports: [],
}

export default commonReducer(
    defaultState,
    handler(actions.customReportsLoaded, (state, customReports) => shallowUpdate(state, { customReports })),
)
