import { useAction, useEffect, useState } from '_/facade/react'

import { SelectField, TextField, CheckboxField } from '_/components/form'

import LOCATION_TYPE, { POSITION } from '_/model/predefined-lists/exposure-location/location-type'
import type Grade from '_/model/predefined-lists/grade/grade'

import { loadGradesList } from '_/features/predefined-lists/action-alert-limits/actions'

interface OwnProps {
    hasChildren: boolean
    nestingLevel: number
    gradeDisabled: boolean
    ungradedDisabled: boolean
}

function ExposureLocationData(props: OwnProps) {
    const loadGrades = useAction(loadGradesList)
        , [grades, setGrades] = useState<Grade[]>([])

    useEffect(
        () => {
            loadGrades()
                .then(setGrades)
        },
        [loadGrades]
    )

    return (
        <div>
            <TextField name='name' id='name' testId='field-name'>Name</TextField>
            <SelectField
                name='locationType'
                id='locationType'
                entities={LOCATION_TYPE}
                calcId={_ => _.id}
                calcName={_ => _.name}
                calcDisabled={_ => _.id === POSITION && (props.nestingLevel <= 1 || props.hasChildren)}
                testId='field-location-type'
            >
                Type
            </SelectField>
            <SelectField
                name='gradeId'
                id='gradeId'
                entities={grades}
                calcId={_ => _.id}
                calcName={_ => _.name}
                disabled={props.gradeDisabled}
                testId='field-grade'
            >
                Grade
            </SelectField>
            <CheckboxField
                name='ungraded'
                id='ungraded'
                disabled={props.ungradedDisabled}
            >
                Ungraded
            </CheckboxField>
        </div>
    )
}

export default ExposureLocationData
