import type TimeService from '_/services/time-service'
import * as t from '_/model/text/text'
import { formatActiveState } from '_/utils/format/common'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type { FloorPlanLocation } from '_/model/floor-plan/floor-plan'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import type { ChartMetadata } from '_/model/analysis/chart-metadata'
import { filteredLocationsExistsOnFloorPlan } from '_/features/analysis/ui/helpers'
import { NOT_RECORDED_ID, NOT_RECORDED_NAME } from '_/constants/system-words'
import { systemFontStack } from '_/model/analysis/system-font-stack'
import { GRAPH_HEIGHT } from '_/model/analysis/graph-height'
import type { DateTime } from '_/model/date-time'
import { PARTICLE_COUNTS } from '_/model/analysis/chart-type'

// added, because Plotly does not support word-wrap yet: https://github.com/plotly/plotly.js/issues/382
function splitStringIntoFewLines(string: string, lineWidth: number): string[] {
    const array: string[] = []
    if (string.length > lineWidth) {
        let p = lineWidth
        while (p > 0 && string[p] != ' ') {
            p--
        }
        if (p > 0) {
            array.push(string.substring(0, p))
            const right = string.substring(p + 1)

            return array.concat(splitStringIntoFewLines(right, lineWidth))
        }
    }

    if (array.length === 0)
        array.push(string)

    return array
}

const MIN_FOOTER_LENGTH = 8

function getFilterLength(filterLength: number, legendLength = 0) {
    const filterSpacer = filterLength > 0 ? (legendLength / 4 + filterLength) : 0
    return 20 * (filterSpacer - MIN_FOOTER_LENGTH)
}

function getLineGraphLayoutMultiY(metadata: ChartMetadata, ...yAxisData: (readonly [yTitle: string, chartData: Partial<Plotly.PlotData>[]])[]): Partial<Plotly.Layout> {
    const { footerPlainText, legendTitle, filter } = metadata
        , isParticleCountGraph = filter?.chartType === PARTICLE_COUNTS

    const yAxisConfig: any = {}

    yAxisData.forEach(([yTitle, chartData], index) => {
        const key = index === 0 ? 'yaxis' : `yaxis${index + 1}`
            , additionalGraphBarChart = chartData.some((_: any) => _.yaxis === 'y2' && _.type === 'bar')
            , config: any = {
                title: yTitle,
                rangemode: 'tozero',
                range: chartData.every(_ => _.y == null || (_.y as any[]).every((_: any) => _ == null)) ? [0, 1] : undefined,
                overlaying: additionalGraphBarChart ? 'y2' : undefined
            }

        if (index > 0) {
            config.overlaying = additionalGraphBarChart ? false : 'y'
            config.side = 'right'
            config.showgrid = false
        }

        yAxisConfig[key] = config
    })

    return {
        barmode: 'stack',
        font: {
            family: systemFontStack,
        },
        height: footerPlainText.length > 0 ? GRAPH_HEIGHT + getFilterLength(footerPlainText.length) : 450,
        margin: footerPlainText.length > 0 ? { b: 290 + getFilterLength(footerPlainText.length) } : 0,
        annotations: [
            {
                text: '<b>' + metadata.title.join('<br>') + '</b>',
                font: {
                    size: 18,
                },
                showarrow: false,
                y: 1.3,
                xref: 'paper',
                yref: 'paper',
                align: 'center',
            },
            {
                text: metadata.subtitle,
                font: {
                    size: 13,
                    color: 'rgb(116, 101, 130)',
                },
                showarrow: false,
                y: 1.22 - 0.05 * metadata.title.length,
                xref: 'paper',
                yref: 'paper',
                align: 'center',
            },
            {
                text: footerPlainText.join('<br>'),
                font: {
                    size: 14,
                },
                showarrow: false,
                x: 0,
                y: -0.8 - 0.06 * (footerPlainText.length - MIN_FOOTER_LENGTH),
                xref: 'paper',
                yref: 'paper',
                align: 'left',
            },
            {
                text: metadata.author,
                font: {
                    size: 14,
                },
                showarrow: false,
                x: 0,
                y: -0.9 - 0.06 * (footerPlainText.length - MIN_FOOTER_LENGTH),
                xref: 'paper',
                yref: 'paper',
            },
        ],
        hovermode: isParticleCountGraph ? 'closest' : 'x',
        hoverdistance: 1,
        xaxis: {
            title: metadata.xAxisTitle ?? 'Date',
            automargin: true,
            rangemode: 'nonnegative',
        },
        ...yAxisConfig,
        showlegend: true,
        legend: {
            x: 1.07,
            title: { text: legendTitle || '' },
            orientation: 'v',
            traceorder: 'reversed',
        },
    }
}

function getPrintLayout(metadata: ChartMetadata, legendLength: number): Partial<Plotly.Layout> {
    const legendSpacer = legendLength / 5
        , title = metadata.reportName ? [metadata.reportName].concat(metadata.title) : metadata.title
        , { footerPlainText, legendTitle } = metadata

    return {
        legend: {
            title: { text: legendTitle ?? '' },
            orientation: 'h',
            traceorder: 'reversed',
            y: -0.2,
        } as Partial<Plotly.Legend>,
        height: footerPlainText.length > 0 ? 1100 + getFilterLength(footerPlainText.length, legendLength) : undefined,
        margin: footerPlainText.length > 0 ? { b: 290 + getFilterLength(footerPlainText.length, legendLength) } : undefined,
        annotations: [
            {
                text: '<b>' + title.join('<br>') + '</b>',
                font: {
                    size: 18,
                },
                showarrow: false,
                x: 0,
                y: 1.3,
                xref: 'paper',
                yref: 'paper',
                align: 'left',
            },
            {
                text: metadata.subtitle,
                font: {
                    size: 13,
                    color: 'rgb(116, 101, 130)',
                },
                showarrow: false,
                x: 0,
                y: 1.22 - 0.05 * title.length,
                xref: 'paper',
                yref: 'paper',
            },
            {
                text: metadata.footerPlainText.join('<br>'),
                font: {
                    size: 14,
                },
                showarrow: false,
                x: 0,
                y: -0.8 - 0.06 * (footerPlainText.length - MIN_FOOTER_LENGTH + legendSpacer),
                xref: 'paper',
                yref: 'paper',
                align: 'left',
            },
            {
                text: metadata.author,
                font: {
                    size: 14,
                },
                showarrow: false,
                x: 0,
                y: -0.9 - 0.06 * (footerPlainText.length - MIN_FOOTER_LENGTH + legendSpacer),
                xref: 'paper',
                yref: 'paper',
            },
        ],
    }
}

function getExportOpts(legendLength: number, metadata: ChartMetadata) {
    return {
        format: 'png',
        width: 1300,
        height: 700 + getFilterLength(metadata.footerPlainText.length, legendLength),
    } as Plotly.ToImgopts
}

function formatLocationNames(exposureLocationIds: string[], exposureLocationList: ListExposureLocation[]): string[] {
    return exposureLocationIds.map(id => {
        if (id === NOT_RECORDED_ID)
            return NOT_RECORDED_NAME

        const location = exposureLocationList.find(_ => _.id === id)
        if (!location)
            return ''

        return t.plainText(formatActiveState(location.pathName, location.isActive))
    })
}

function getCommaSeparatedNames<T extends { id: string | number, name: string, isActive?: boolean }>(ids: (string | number)[], entities: T[]) {
    const names = ids.map(id => {
        const entity = entities.find(_ => _.id === id)
        if (entity)
            return t.plainText(formatActiveState(entity.name, entity.isActive))
    })

    return names.filter(_ => _ !== undefined).join(', ')
}

function checkInactiveLocation(location: FloorPlanLocation, selectedLocations: string[], locationList: ListExposureLocation[]) {
    return selectedLocations.length > 0
        && !filteredLocationsExistsOnFloorPlan(selectedLocations, [location], locationList)
}

function formatFloorPlanName(floorPlan: FloorPlan | undefined): t.Text {
    return [
        t.defaultTextNode(floorPlan?.name ?? ''),
        t.systemTextNode(` (${floorPlan?.locations.length} location${floorPlan?.locations.length !== 1 ? 's' : ''})`)
    ]
}

function getLast28DaysDate(timeService: TimeService, currentDate: DateTime) {
    return timeService.addCtzDays(currentDate, -27) //27 days + current day
}

export {
    splitStringIntoFewLines,
    getFilterLength,
    getPrintLayout,
    getExportOpts,
    MIN_FOOTER_LENGTH,
    formatLocationNames,
    getCommaSeparatedNames,
    checkInactiveLocation,
    formatFloorPlanName,
    getLast28DaysDate,
    getLineGraphLayoutMultiY,
}
