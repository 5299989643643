import { React, bindComponent, connect, dispatchPropsMapper, classnames } from '_/facade/react'
import DateInput from '_/utils/input/local-date-input'
import Button from '_/components/button'
import { noop } from '_/utils/function'

import * as timeActions from '_/features/time/actions'
import type { DateTime} from '_/model/date-time'
import { greaterThanOrEqual } from '_/model/date-time'

// NOTE: must be a class instead of function component due to specifics of react-datepicker
class CalendarButton extends React.Component<{ onClick?: () => void }> {
    render() {
        return (
            <Button className='btn-light mb-0 pb-0 text-primary' onClick={this.props.onClick || noop} testId='calendar-today'>
                <i className='material-icons'>calendar_today</i>
            </Button>
        )
    }
}

export { CalendarButton }

interface OwnProps {
    onChange: (date: DateTime | undefined) => void
    initialFilter: DateTime
    maxDate?: DateTime
    filterByMonth?: boolean
    buttonName?: string
}

class DateFilter extends React.Component<ConnectedProps & OwnProps> {
    timeService = this.props.getTimeService()
    constructor(props: ConnectedProps & OwnProps) {
        super(props)
        bindComponent(this)
    }

    onFilterChange(date: DateTime) {
        this.props.onChange(date)
    }

    getCtzTodayDateBegin() {
        const now = this.timeService.now()
        return this.timeService.ctzDayStart(now)
    }

    getCtzPastDateBegin(date: DateTime): DateTime {
        const dayStart = this.timeService.ctzDayStart(date)

        return this.props.filterByMonth
            ? this.timeService.addCtzMonths(dayStart, -1)
            : this.timeService.addCtzDays(dayStart, -1)
    }

    getCtzFutureDateBegin(date: DateTime): DateTime {
        const dayStart = this.timeService.ctzDayStart(date)

        return  this.props.filterByMonth
            ? this.timeService.addCtzMonths(dayStart, 1)
            : this.timeService.addCtzDays(dayStart, 1)
    }

    handleChange(value: DateTime | '') {
        const newValue = value === '' ? undefined : value
        this.props.onChange(newValue)
    }

    render() {
        return (
            <div className='d-flex'>
                <Button
                    className='btn-light text-primary fw-bold me-1 pb-0'
                    onClick={() => this.onFilterChange(this.getCtzPastDateBegin(this.props.initialFilter))}
                    testId='backward-date'
                >
                    <i className='material-icons'>keyboard_arrow_left</i>
                </Button>
                <Button
                    className='btn-light text-primary fw-bold me-1'
                    onClick={() => this.onFilterChange(this.getCtzTodayDateBegin())}
                    testId='today-date'
                >
                    {this.props.buttonName || 'Today'}
                </Button>

                <div className={classnames('input-group', {'month-picker': this.props.filterByMonth})}>
                    <DateInput
                        id='date'
                        value={this.props.initialFilter}
                        onChange={this.handleChange}
                        max={this.props.maxDate}
                        isClearable={false}
                        customInput={<CalendarButton />}
                        isMonthPicker={this.props.filterByMonth}
                        calendarContainerWidth={this.props.filterByMonth ? 300 : undefined}
                    />
                </div>

                <Button
                    className='btn-light text-primary fw-bold ms-2 pb-0'
                    onClick={() => this.onFilterChange(this.getCtzFutureDateBegin(this.props.initialFilter))}
                    disabled={this.props.maxDate !== undefined && (greaterThanOrEqual(this.timeService.ctzDayStart(this.props.initialFilter), this.timeService.ctzDayStart(this.props.maxDate)))}
                    testId='forward-date'
                >
                    <i className='material-icons'>keyboard_arrow_right</i>
                </Button>
            </div>

        )
    }
}

const mapDispatchToProps = dispatchPropsMapper({
    getTimeService: timeActions.getTimeService,
})

type ConnectedProps = ReturnType<typeof mapDispatchToProps>

export default connect(undefined, mapDispatchToProps)(DateFilter)
