export const NEVER = 0
           , DAILY = 1
           , WEEKLY = 2
           , MONTHLY = 3
           , THREE_MONTHLY = 4
           , SIX_MONTHLY = 5
           , YEARLY = 6

type ReminderFrequency = never
    | typeof NEVER
    | typeof DAILY
    | typeof WEEKLY
    | typeof MONTHLY
    | typeof THREE_MONTHLY
    | typeof SIX_MONTHLY
    | typeof YEARLY

const REMINDER_FREQUENCY: { id: ReminderFrequency, name: string}[] = [
    {
        id: NEVER,
        name: 'Never',
    },
    {
        id: DAILY,
        name: 'Daily',
    },
    {
        id: WEEKLY,
        name: 'Weekly',
    },
    {
        id: MONTHLY,
        name: 'Monthly',
    },
    {
        id: THREE_MONTHLY,
        name: '3 Monthly',
    },
    {
        id: SIX_MONTHLY,
        name: '6 Monthly',
    },
    {
        id: YEARLY,
        name: 'Yearly',
    },
]

export {
    REMINDER_FREQUENCY as default,
    ReminderFrequency,
}