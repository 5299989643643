import { React, useCallback, useEffect, useRef, useMemo, useImperativeHandle, forwardRef } from '_/facade/react'
import type OrganismsBreakdownSeries from '_/model/analysis/organisms-breakdown-series'
import { OTHER } from '_/model/analysis/organisms-breakdown-series'
import type SampleSearchFields from '_/model/sample/search'
import getPlotly from '_/facade/plotly'

import { getFilterLength, MIN_FOOTER_LENGTH, getPrintLayout, getExportOpts } from '../helpers'

import ActionButtons from './action-buttons'
import { systemFontStack } from '_/model/analysis/system-font-stack'
import PieChartTabularView from './tabular-views/pie-chart-tabular-view'
import type { ChartMetadata } from '_/model/analysis/chart-metadata'
import type { ExportRef } from './date-series-chart'
import * as h from './export-handlers'
import { useTimeService } from '_/components/time'

interface Props {
    series: OrganismsBreakdownSeries
    metadata: ChartMetadata
    sampleListRouterParams: SampleSearchFields
    showActionButtons?: boolean | undefined
    multipleGraphs?: boolean | undefined
    exportDisabled: boolean
    showTabularView?: boolean | undefined
    onExport: (_: Blob) => Promise<void>
}

function PieChart(props: Props, ref: React.ForwardedRef<ExportRef>) {
    const root = useRef<HTMLDivElement>(null)
        , timeService = useTimeService()
        , legendLength = props.series.series.length

    const monthChartData: Plotly.Data[] = useMemo(
        () => {
            const series = props.series
                , LOWER_PERCENT = 0.05
                , total = totalCfu(series.series)
                , mainSeries = series.series.filter(_ => _.identification.type !== OTHER &&  _.count / total > LOWER_PERCENT)
                , lowPercentSeries = series.series.filter(_ => _.identification.type !== OTHER && _.count / total <= LOWER_PERCENT)
                , otherSeries = series.series.filter(_ => _.identification.type === OTHER)
                , data = {
                    values: mainSeries.concat(otherSeries).map(_ => _.count).concat(
                        lowPercentSeries.length > 0 ? totalCfu(lowPercentSeries) : []
                    ),
                    text: mainSeries.concat(otherSeries).map(_ => _.count + ' CFUs').concat(
                        lowPercentSeries.length > 0 ? totalCfu(lowPercentSeries) + ' CFUs' : ''
                    ),
                    labels: mainSeries.concat(otherSeries).map(_ => _.identification.name)
                        .concat(
                            lowPercentSeries.length > 0 ? `Organisms less than ${LOWER_PERCENT * 100}%` : []
                        ),
                    hoverinfo: 'text',
                    hovertext: mainSeries.map(_ => formatData(_.identification.name, _.count, total))
                        .concat(
                            otherSeries.length > 0 ? formatData('No organism ID', totalCfu(otherSeries), total) : []
                        )
                        .concat(
                            lowPercentSeries.length > 0
                                ? lowPercentSeries.map(_ => formatData(_.identification.name, _.count, total)).join('<br>')
                                : []
                        ),
                    name: 'Total CFU',
                    type: 'pie',
                    sort: false,
                } as any as Plotly.Data

            return [data]
        },
        [props.series]
    )

    const plot = useCallback(
        () => {
            getPlotly().then(plotly => {
                if (root.current)
                    plotly.newPlot(root.current, monthChartData, getLayout(props.metadata), { displayModeBar: false })
            })
        },
        [monthChartData, props.metadata]
    )

    useEffect(plot, [plot])

    function handleExport() {
        const { printLayout, layout, exportOpts } = getLayouts(props.metadata, legendLength)

        return h.exportChartHandler(root, !!props.multipleGraphs, props.onExport, printLayout, layout, exportOpts, timeService)
    }

    function getExportImage() {
        const { printLayout, layout, exportOpts } = getLayouts(props.metadata, legendLength)

        return h.getExportImage(root, printLayout, layout, exportOpts)
    }

    useImperativeHandle(ref, () => ({
        getExportImage,
    }))

    return (
        <div>
            <div className='border border-light'>
                <div ref={root} />
            </div>
            {props.showTabularView && <PieChartTabularView series={props.series}/> }
            {props.showActionButtons &&
                <ActionButtons
                    disabledGoToSamplesButton={props.series.series.length === 0}
                    onExport={handleExport}
                    sampleListRouterParams={props.sampleListRouterParams}
                    multipleGraphs={props.multipleGraphs}
                    exportButtonDisabled={props.exportDisabled}
                />
            }
        </div>
    )
}

export default forwardRef(PieChart)

function totalCfu(series: OrganismsBreakdownSeries['series']): number {
    return series.reduce((acc, v) => acc + v.count, 0)
}

function formatData(label: string, count: number, total: number): string {
    const percent = total === 0 ? 0 : count / total * 100
    return `${label} ${percent.toFixed(2)}% (${count})`
}

function getLayout(metadata: ChartMetadata): Partial<Plotly.Layout> {
    return {
        font: {
            family: systemFontStack,
        },
        height: 700 + getFilterLength(metadata.footerPlainText.length),
        margin: {
            b: 290 + getFilterLength(metadata.footerPlainText.length),
        },
        annotations: [
            {
                text: '<b>' + metadata.title.join('<br>') + '</b>',
                font: {
                    size: 18,
                },
                showarrow: false,
                align: 'center',
                y: 1.33,
                xref: 'paper',
                yref: 'paper',
            },
            {
                text: metadata.subtitle,
                font: {
                    size: 13,
                    color: 'rgb(116, 101, 130)',
                },
                showarrow: false,
                align: 'center',
                y: 1.25,
                xref: 'paper',
                yref: 'paper',
            },
            {
                text: metadata.footerPlainText.join('<br>'),
                font: {
                    size: 14,
                },
                showarrow: false,
                x: 0,
                y: -0.8 - 0.06 * (metadata.footerPlainText.length - MIN_FOOTER_LENGTH),
                xref: 'paper',
                yref: 'paper',
                align: 'left',
            },
            {
                text: metadata.author,
                font: {
                    size: 14,
                },
                showarrow: false,
                x: 0,
                y: -0.9 - 0.06 * (metadata.footerPlainText.length - MIN_FOOTER_LENGTH),
                xref: 'paper',
                yref: 'paper',
            },
        ],
        showlegend: true,
        legend: {
            orientation: 'v',
        },
    }
}

function getLayouts(metadata: ChartMetadata, legendLength: number) {
    return {
        layout: getLayout(metadata),
        printLayout: getPrintLayout(metadata, legendLength),
        exportOpts: getExportOpts(legendLength, metadata),
    }
}

