import { useGeneratedBy } from '_/features/samples/sample-edit/shared/shared-sample-hooks'

import { Table, EmptyTableMessage, SortableHead, SortableTh, PaginatedFooter } from '_/components/table'
import { useTimeService } from '_/components/time'
import { LinkButton } from '_/components/link'

import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import type { NonViableSampleFilter } from '_/model/non-viable-sample/list/types'
import type NonViableSampleView from '_/model/non-viable-sample/types'
import type CustomField  from '_/model/predefined-lists/custom-field/types'
import type PaginationState from '_/model/pagination-state'
import type { PredefinedLists } from '_/model/app-state'
import type SortState from '_/model/sort-state'
import type { Tree } from '_/utils/tree'

import { customFieldName } from '_/model/predefined-lists/custom-field/custom-field'
import { formatExposureDurationByFields } from '_/model/non-viable-sample/format'
import { formatParticleValue } from '_/model/non-viable-sample/format'
import * as f from '_/model/sample/format'

import FormattedText from '_/features/text/formatted-text'

import * as fi from '_/constants/custom-field-index'
import * as r from '_/constants/routes'

import { getFieldValue } from '_/features/samples/helpers'
import SampleLimitLabel from '../sample-limit-label'

interface Props {
    samples: NonViableSampleView[]
    totalCount: number
    filter: NonViableSampleFilter
    onSortChange: (sortState: SortState) => void
    onPaginationChange: (pagination: PaginationState) => void
    customFields: CustomField[]
    exposureLocations: Tree<ExposureLocation>
}

function NonViableSampleTable(props: Props) {
    const timeService = useTimeService()
        , genBy = useGeneratedBy()

    return (
        <Table>
            <SortableHead onChange={props.onSortChange} sticky miniAuditTrail={genBy}>
                <SortableTh name='exposureStartTime'>Exposure date</SortableTh>
                <SortableTh name='exposureLocationNotRecorded, exposureLocationName'>{customFieldName(fi.EXPOSURE_LOCATION_ID, props.customFields)}</SortableTh>
                <SortableTh name='sortableLowerParticleCount'>0.5μm particles</SortableTh>
                <SortableTh name='sortableHigherParticleCount'>5μm particles</SortableTh>
                <th>{customFieldName(fi.BATCH_NUMBER, props.customFields)}</th>
                <th/>
            </SortableHead>

            <tbody className='border-top-0'>
                {props.samples.length === 0
                    ? <EmptyTableMessage colSpan={6} message='No results found' />
                    : props.samples.map(_ =>
                        <tr key={_.id}>
                            <td>
                                {timeService.formatCtzDate(getFieldValue(_.fields, fi.EXPOSURE_START_DATE))}{' '}
                                <FormattedText text={formatExposureDurationByFields(_.fields, timeService)} />
                            </td>
                            <td><FormattedText text={f.formatExposureLocation(_.monitoringState, _.fields, { exposureLocations: props.exposureLocations } as PredefinedLists)} /></td>
                            <td>
                                <FormattedText text={formatParticleValue(_.lowerParticle)} />
                                <SampleLimitLabel breach={_.lowerParticleBreachType}/>
                            </td>
                            <td>
                                <FormattedText text={formatParticleValue(_.higherParticle)} />
                                <SampleLimitLabel breach={_.higherParticleBreachType}/>
                            </td>
                            <td><FormattedText text={f.formatBatchNumbersShort(_.fields)} /></td>
                            <td className='text-end'>
                                <LinkButton
                                    className='btn-link align-baseline p-0'
                                    routeName={r.NON_VIABLE_SAMPLES_EDIT}
                                    routeParams={{ id: _.id }}
                                >
                                    View
                                </LinkButton>
                            </td>
                        </tr>
                    )
                }
            </tbody>
            {props.samples.length > 0 &&
                <PaginatedFooter
                    colSpan={6}
                    state={{ start: props.filter.start, count: props.filter.count }}
                    onChange={props.onPaginationChange}
                    totalCount={props.totalCount}
                />
            }
        </Table >
    )
}

export default NonViableSampleTable
