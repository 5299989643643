import { useState } from '_/facade/react'

import { Modal, ModalHeader, ModalBody } from '_/components/modal'
import { Close } from '_/components/button'

interface Props {
    children: React.ReactNode
}

function Key(props: Props) {
    const [showKeyModal, setShowKeyModal] = useState(false)
    return (
        <>
            <button
                type='button'
                className='key-icon text-align-center text-white ms-2 align-self-center'
                onClick={() => setShowKeyModal(true)}
                data-testid='show-key-modal'
            >
                ?
            </button>

            {showKeyModal &&
                <Modal isOpen onClose={() => setShowKeyModal(false)}>
                    <ModalHeader className='border-bottom-0 pb-0'>
                        <h4>Key</h4>
                        <Close onClick={() => setShowKeyModal(false)} testId='close-key-modal'></Close>
                    </ModalHeader>
                    <ModalBody>
                        {props.children}
                    </ModalBody>
                </Modal>
            }
        </>
    )
}

export default Key
