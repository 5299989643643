import { useField } from 'react-final-form'
import { classnames } from '_/facade/react'

interface Props {
    id: string
    name: string
    value: any
    label: React.ReactNode
    className?: string
}

function RadioField(props: Props) {
    const field = useField(props.name)

    function handleChange() {
        field.input.onChange(props.value)
    }

    function handleFocus() {
        field.input.onFocus()
    }

    function handleBlur() {
        field.input.onBlur()
    }

    return (
        <div className={classnames('form-check', props.className)}>
            <input
                className='form-check-input'
                id={props.id}
                type='radio'
                name={field.input.name}
                value={props.value}
                checked={field.input.value === props.value}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
            <label htmlFor={props.id} className='form-check-label'>
                {props.label}
            </label>
        </div>
    )
}

export default RadioField
