const MARK_AS_NOT_IN_USE = 0
    , MARK_AS_IN_USE = 1

type MonitoringOverviewBulkOperation = typeof MARK_AS_NOT_IN_USE | typeof MARK_AS_IN_USE

const MONITORING_OVERVIEW_BULK_OPERATION: { id: MonitoringOverviewBulkOperation, name: string }[] = [
    {
        id: MARK_AS_NOT_IN_USE,
        name: 'Mark as not in use'
    },
    {
        id: MARK_AS_IN_USE,
        name: 'Mark as in use'
    }
]

export {
    MONITORING_OVERVIEW_BULK_OPERATION,
    MonitoringOverviewBulkOperation,
    MARK_AS_NOT_IN_USE,
}
