import { useState, useAction, useRef, useCallback, useMemo, classnames } from '_/facade/react'
import { searchBatchNumbers as searchAction } from '../actions'
import { NOT_RECORDED_NAME } from '_/constants/system-words'
import { useDebounce } from '_/hooks/shared-hooks'
import type { FieldRenderProps} from 'react-final-form'
import { useField } from 'react-final-form'
import Select from '_/components/downshift-select'
import { Close } from '_/components/button'
import FormattedText from '../../text/formatted-text'
import * as t from '_/model/text/text'
import { allowedName, hasTrimableSpaces, maxLength, minLength } from '_/utils/form/validate'
import { showFieldError } from '_/components/form'
import { NON_VIABLE, VIABLE } from '_/model/sample/entity-type'
import { areArraysConsistent } from '_/utils/array'

interface Props {
    id: string
    name: string
    className?: string
    disabled?: boolean
    hasLabel?: boolean
    autoFocus?: boolean
    editing: boolean
    isViable?: boolean
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

function BatchNumberField(props: Props) {
    const field = useField(props.name, { isEqual: areBatchNumbersEqual })
        , [
            selectedEntities,
            notSelectedEntities,
            handleInputValueChange,
            validationResult,
        ] = useBatchNumbers(props.editing, !!props.isViable, field)
        , [handleInputItemAdd, handleInputItemRemove] = [useHandleChange(field), useHandleRemove(field)]

    return (
        <div className={classnames('mb-3', props.hasLabel ? props.className : '')}>
            {props.hasLabel &&
                <label htmlFor={props.id} className='col-form-label'>Batch number</label>
            }
            <Select
                id={props.id}
                entities={notSelectedEntities}
                calcId={_ => _}
                calcName={formatEntity}
                className={classnames('form-control border-print-0', !props.hasLabel ? props.className : '', { 'is-invalid': props.editing && (validationResult || showFieldError(field.meta)) })}
                input={{
                    ...field.input,
                    value: '',
                    onChange: handleInputItemAdd,
                    onBlur: () => field.input.onBlur(),
                }}
                onInputValueChange={handleInputValueChange}
                shouldClearInput
                placeholder={props.editing ? '' : 'Search'}
                isSearchField
                disabled={props.disabled}
                onKeyDown={props.onKeyDown}
                defaultHighlightedIndex={props.editing ? 0 : undefined}
                autoFocus={props.autoFocus}
            />
            {props.editing && (validationResult || showFieldError(field.meta)) && <div className='invalid-feedback user-formatted-text'>{validationResult || field.meta.error}</div>}
            {selectedEntities.map((entity, key) =>
                <span key={key}>
                    {key > 0 && <br/>}
                    <Close
                        className='align-text-bottom text-danger'
                        aria-label='Close'
                        onClick={() => handleInputItemRemove(entity)}
                    />
                    <span className='break-word'><FormattedText text={formatEntity(entity)} /></span>
                </span>
            )}
        </div>
    )
}

export default BatchNumberField

const EMPTY_ARRAY: string[] = []

function useBatchNumbers(
        editing: boolean,
        isViable: boolean,
        field: FieldRenderProps<string[]>
) {
    const initialValue: string[] = useMemo(() => editing ? [] : [NOT_RECORDED_NAME], [editing])
        , searchBatchNumbers = useAction(searchAction)
        , [entitiesFound, setEntitiesFound] = useState(initialValue)
        , selectedEntities = Array.isArray(field.input.value) ? field.input.value : EMPTY_ARRAY
        , notSelectedEntities = entitiesFound.filter(entity => !selectedEntities.some(_ => entity === _))
        , [validationResult, setValidationResult] = useState<string | undefined>(undefined)

    const prevQuery = useRef<string>()
        , debounce = useDebounce()
        , handleSearch = useCallback(
            (query: string) => {
                prevQuery.current = query

                const validation = validate(query)

                if (editing)
                    setValidationResult(validation)

                const isInvalidQuery = validation || query.length === 0
                    , isAlreadySelected = selectedEntities.some(_ => areBatchesEqual(_, query))

                if (isInvalidQuery || isAlreadySelected) {
                    setEntitiesFound(initialValue)
                    return
                }

                debounce(() => {
                    searchBatchNumbers({ name: query, count: 10, entityType: isViable ? VIABLE : NON_VIABLE })
                        .then(batches => {
                            // query could had changed before the response has come
                            if (prevQuery.current !== query)
                                return

                            const matchFound = batches.some(_ => areBatchesEqual(_, query))
                                , newEntities = [...( editing && !matchFound ? [query] : [] ), ...initialValue, ...batches]
                            setEntitiesFound(newEntities)
                        })
                })
            },
            [initialValue, searchBatchNumbers, selectedEntities, isViable, setEntitiesFound, debounce, editing, setValidationResult]
        )

    return [
        selectedEntities,
        notSelectedEntities,
        handleSearch,
        validationResult,
    ] as const
}

function areBatchNumbersEqual<T extends number | string>(a: T[] | undefined, b: T[] | undefined): boolean {
    if (!a && !b)
        return true

    return areArraysConsistent(a, b)
}

function areBatchesEqual(a: string, b: string) {
    return a.toUpperCase() === b.toUpperCase()
}

function useHandleChange(field: FieldRenderProps<string[]>) {
    function handleChange(value: string) {
        const input = field.input
            , nextValue = Array.isArray(input.value) ? input.value.concat() : []

        if (nextValue.indexOf(value) !== -1)
            return

        nextValue.push(value)
        input.onChange(nextValue)
    }

    return handleChange
}

function useHandleRemove(field: FieldRenderProps<string[]>) {
    function handleRemove(value: string) {
        const input = field.input
            , previousValue = Array.isArray(input.value) ? input.value.concat() : []
            , nextValue = previousValue.filter(_ => _ !== value)

        input.onChange(nextValue.length === 0 ? '' : nextValue)
    }

    return handleRemove
}

function formatEntity(_: string) {
    return _ === NOT_RECORDED_NAME
        ? [t.systemTextNode(_)]
        : [t.defaultTextNode(_)]
}

function validate(value: string) {
    const fieldName = 'Batch number'
        , maxBatchLength = maxLength(fieldName, 200)(value) || undefined
        , minBatchLength = minLength(fieldName, 3)(value) || undefined
        , batchHasTrimableSpaces = hasTrimableSpaces(fieldName, value) || undefined
        , batchHasNotAllowedName = allowedName(fieldName)(value) || undefined

    return maxBatchLength || minBatchLength || batchHasTrimableSpaces || batchHasNotAllowedName
}
