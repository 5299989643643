import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import type { FormApi } from 'final-form'
import { useAction, useEffect, useSelector } from '_/facade/react'

import { TextField, TextareaField, submitDisabled } from '_/components/form'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import { useCloseModal } from '_/components/modal'
import PageHeader from '_/components/page-header'
import { Close } from '_/components/button'

import type { FloorPlanForm } from '_/model/floor-plan/upload/types'
import validate from '_/model/floor-plan/upload/validate'
import type FloorPlan from '_/model/floor-plan/floor-plan'

import formReset from '_/utils/form/reset'

import * as a from '../actions'
import * as toastActions from '_/features/toasts/actions'

import Upload from './upload-image'
import ActionButtons from '../action-buttons'

const initialFloorPlan: FloorPlanForm = {}

interface Props {
    plans: FloorPlan[]
}

function Uploader(props: Props) {
    const handleSubmit = useSubmitHandler()
        , handleClear = useClearHandler()
        , closeModal = useCloseModal()
        , hasUnsavedChanges = useSelector(_ => _.unsavedChange.unsavedChangeTargets.length > 0)

    useEffect(
        () => {
            if (!hasUnsavedChanges) // clear if changes were discarded
                handleClear()
        },
        [hasUnsavedChanges, handleClear]
    )

    return (
        <Form
            validate={_ => validate(_, props.plans)}
            initialValues={initialFloorPlan}
            onSubmit={handleSubmit}
            render={form =>
                <form onSubmit={form.handleSubmit} className='d-flex h-100'>
                    <UnsavedChangesObserver form={form} />
                    <Upload />
                    <div className='floor-plans-details ms-4 d-flex flex-column'>
                        <PageHeader sticky title='New floor plan' className='px-0'>
                            <Close onClick={closeModal} testId='close-floor-plan-modal' />
                        </PageHeader>
                        <TextField name='name' testId='field-name'>Name</TextField>
                        <TextareaField name='description' textareaClassName='floor-plans-details__text-area' testId='field-description'>Description</TextareaField>

                        <ActionButtons
                            submitDisabled={submitDisabled(form)}
                            removeDisabled={!form.values.image}
                            onDelete={() => handleClear(form)}
                        />
                    </div>
                </form>
            }
        />
    )
}

export default Uploader

function useClearHandler() {
    const clearImageUploads = useAction(a.clearImageUploads)

    function clear(form?: FormRenderProps<FloorPlanForm>) {
        clearImageUploads()
        if (form)
            formReset(form.form)
    }

    return clear
}

function useSubmitHandler() {
    const createFloorPlan = useAction(a.createFloorPlan)
        , addSuccessMessage = useAction(toastActions.addSuccess)

    function handleSubmit(floorPlan: FloorPlanForm, form: FormApi<FloorPlanForm>) {
        const image = floorPlan.image!
            , result = createFloorPlan({
                name: floorPlan.name!,
                description: floorPlan.description!,
                imageId: image.id,
                width: image.width,
                height: image.height,
            })

        result
            .then(() => addSuccessMessage('Floor plan added successfully'))
            .then(() => formReset(form))

        return result
    }

    return handleSubmit
}
