import { useState, useSelector, useAction, useEffect, useCallback } from '_/facade/react'

import Button from '_/components/button'
import { Close } from '_/components/button'
import { Modal, ModalHeader, ModalFooter, ModalBody } from '_/components/modal'
import { useTimeService } from '_/components/time'
import ExportList from '_/features/export/list'

import type Export from '_/model/export/types'

import * as routes from '_/constants/routes'
import * as routerActions from '_/features/routing/actions'
import * as exportActions from '_/features/export/actions'
import * as actions from '../actions'

function ExportModal() {
    const timeService = useTimeService()
        , navigateToList = useNavigateToList()
        , [contextDescription, exports, setExports] = useContextExports()
        , handleExport = useExportHandler(setExports)
        , handleDownload = useDownloadHandler()

    return (
        <Modal isOpen onClose={navigateToList} noDefaultContentWidth contentClassName='overflow-hidden export-modal--max-height export-modal--width'>
            <ModalHeader className='px-0 pb-0 border-bottom-0'>
                <h4>Site data export</h4>
                <Close onClick={navigateToList}/>
            </ModalHeader>
            <ModalBody className='d-flex flex-column px-0' noDefaultHeight>
                <div>
                    You're about to export all site data from {contextDescription}. When you export site data, we will<br />
                    compress your content into a single .zip archive. Once your export begins, you can leave this page.<br />
                    You'll be notified when this file is ready to download.
                </div>
                <Button
                    onClick={() => handleExport()}
                    testId='start-new-export'
                    className='ms-auto mt-2 btn-primary'
                >
                    Start a new export
                </Button>
            </ModalBody>
            {exports.length > 0 &&
                <ModalFooter className='flex-column flex-nowrap align-items-start justify-content-start px-0 mb-4 export-modal-table--height'>
                    <h4 className='ms-0 mb-3'>Your recent site data exports</h4>
                    <div className='w-100 m-0 overflow-auto'>
                        <ExportList onDownload={handleDownload} exports={exports} timeService={timeService}/>
                    </div>
                </ModalFooter>
            }
        </Modal>
    )
}

export default ExportModal

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SETTINGS_CONTEXTS), [navigateTo])

    return navigateToList
}

function useContextExports() {
    const loadExportContexts = useAction(actions.loadContextExports)
        , contextDescription = useSelector(_ => _.auth.user?.membership.description)
        , [exports, setExports] = useState<Export[]>([])

    const load = useCallback(
        () => {
            loadExportContexts()
                .then(setExports)
        },
        [loadExportContexts]
    )

    useEffect(load, [load])

    useEffect(() => {
        const interval = setInterval(load, 3000)
        return () => clearInterval(interval)
    })

    return [contextDescription, exports, setExports] as const
}

function useDownloadHandler() {
    const download = useAction(exportActions.downloadSiteExport)

    return (fileId: string) => download(fileId)
}

function useExportHandler(setExports: (_: React.SetStateAction<Export[]>) => void) {
    const exportContext = useAction(actions.exportContext)

    function handleExport() {
        exportContext()
            .then(e => setExports(prevExports => [e].concat(prevExports)))
    }

    return handleExport
}
