import * as t from '_/model/text/text'
import { isSystemId } from '_/model/predefined-lists/custom-field/custom-field'

function formatActiveState(name: string, active: boolean | undefined): t.Text {
    const nameNode = t.defaultTextNode(name)
        , activeNode = active !== false
            ? t.emptyTextNode()
            : t.systemTextNode(' (Inactive)')

    return [nameNode, activeNode]
}

function formatEntity(_: any, getName = (_: any) => _.name) {
    const name = getName(_)
    return isSystemId(_.id) ? [t.systemTextNode(name)] : formatActiveState(name, _.isActive)
}

function concatTexts(texts: t.Text[], separator: string = ', '): t.Text {
    function getSeparatorOrEnd(index: number) {
        return index + 1 !== texts.length ? t.defaultTextNode(separator) : t.emptyTextNode()
    }

    return texts.reduce((result, text, i) => [ ...result, ...text, getSeparatorOrEnd(i) ], [])
}

export {
    formatActiveState,
    formatEntity,
    concatTexts,
}
