import { actionFactory } from '_/utils/redux'
import type { NewGroup, CopyGroupsRequest, DaySchedulesRequest, DayScheduleStatistic, ChangeNotInUseRequest, Recurrence, Series, RemoveSeriesRequest, AddAdHocGroupRequest, Schedule, BulkChangeNotInUseRequest, ChangeMissedRequest } from '_/model/scheduling/day-scheduler/types'
import type DaySchedule from '_/model/scheduling/day-scheduler/types'
import type { DateTime } from '_/model/date-time'
import type { Guid } from '_/model/guid'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.day-ScheduleDaySchedule')

export const loadDaySchedule = action<DateTime, Promise<DaySchedule>>('loadDaySchedule')
           , dayScheduleDayScheduleLoaded = action<DaySchedule, Promise<void>>('dayScheduleDayScheduleLoaded')
           , addGroup = action<{date: DateTime, monitoringGroup: NewGroup}, Promise<void>>('addGroup')
           , deleteGroup = action<{date: DateTime, monitoringGroupId: string, sessionId: string}, Promise<void>>('deleteGroup')
           , copyGroups = action<{date: DateTime, request: CopyGroupsRequest}, Promise<void>>('copyGroups')
           , loadDayScheduleTrail = action<{dayScheduleId: string, date: DateTime}, Promise<AuditTrail>>('loadDayScheduleTrail')
           , loadDaySchedules = action<DaySchedulesRequest, Promise<DayScheduleStatistic[]>>('loadDaySchedules')
           , changeNotInUse = action<ChangeNotInUseRequest, Promise<void>>('changeNotInUse')
           , bulkChangeNotInUse = action<BulkChangeNotInUseRequest, Promise<void>>('bulkChangeNotInUse')
           , createSeries = action<Recurrence, Promise<void>>('createSeries')
           , loadSeries = action<string, Promise<Series>>('loadSeries')
           , saveSeries = action<{id: string, recurrence: Schedule}, Promise<void>>('saveSeries')
           , deleteSeries = action<{id: string, request: RemoveSeriesRequest}, Promise<void>>('deleteSeries')
           , addAdHocGroup = action<{date: DateTime, request: AddAdHocGroupRequest}, Promise<void>>('addAdHocGroup')
           , changeMissed = action<ChangeMissedRequest, Promise<void>>('changeMissed')
           , loadExpectationTrail = action<{id: Guid, date: DateTime, sessionId: Guid }, Promise<AuditTrail>>('loadExpectationTrail')
