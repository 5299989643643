import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type ApiKeyService from '_/services/api-key-service'
import { noop } from '_/utils/function'
import { updateTransaction } from '_/model/api-key/transaction'

import * as actions from './actions'

const factory = (service: ApiKeyService): EffectsFactory => dispatch => [
    handler(actions.loadApiKeyList, id =>
        service.getApiKeys(id)
            .then(_ => dispatch(actions.apiKeyListLoaded(_)))
            .then(noop)
    ),

    handler(actions.loadApiKey, id => service.get(id)),

    handler(actions.createApiKey, service.create),

    handler(actions.removeApiKey, id =>
        service.remove(id)
            .then(() => dispatch(actions.apiKeyRemoved(id)))
            .then(noop)
    ),

    handler(actions.saveApiKey, data => {
        const result = updateTransaction(data.id, data.oldApiKey, data.newApiKey, service, data.fromCreate)

        dispatch(actions.apiKeyChanged({ id: data.id, apiKey: data.newApiKey }))

        result.catch(() => dispatch(actions.apiKeyChanged({ id: data.id, apiKey: data.oldApiKey })))

        return result
    }),

    handler(actions.downloadApiKeys, service.downloadApiKeys),

    handler(actions.dismissApiKey, id => service.dismissApiKey(id)),
]

export default factory
