import type Insight from '_/model/insight/types'
import type ApiService from '../api-service'
import type InsightService from '../insight-service'

function factory(api: ApiService): InsightService {
    return {
        getTopInsight,
        dismiss,
        markAsSeen,
        markAsShown,
    }

    function getTopInsight(): Promise<Insight[]> {
        return api.get(['insights', 'top1'])
    }

    function dismiss(id: string): Promise<void> {
        return api.post(['insights', id, 'dismiss'])
    }

    function markAsSeen(id: string): Promise<void> {
        return api.post(['insights', id, 'seen'])
    }

    function markAsShown(id: string): Promise<void> {
        return api.post(['insights', id, 'shown'])
    }
}

export default factory
