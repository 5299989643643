import { React, useState, useAction, useCallback } from '_/facade/react'

import { EmptyTableMessage, PaginatedFooter, Table } from '_/components/table'
import type PaginationState from '_/model/pagination-state'
import RoundedImages from '_/components/sample-rounded-images'
import type Plate from '_/model/plate/plate'
import { LinkButton } from '_/components/link'
import * as r from '_/constants/routes'
import { useSelector } from '_/facade/react'
import { useTimeService } from '_/components/time'
import FormattedText from '_/features/text/formatted-text'
import Button from '_/components/button'
import AuditTrailModal from '_/components/audit-trail-modal'
import * as actions from '../actions'
import EditInfo from '../reading/view/edit-info'
import { navigateTo as navigateToAction } from '_/features/routing/actions'
import { Link } from '_/utils/router'

interface Props {
    list: Plate[]
    totalCount: number
    pagination: PaginationState
    handlePaginationChange: (pagination: PaginationState) => void
    showSpinner: boolean
}

function PlateTable(props: Props) {
    const permissions = useSelector(_ => _.auth.permissions)
        , auditingPlateId = useSelector(_ => _.router.route?.params.auditingPlateId)
        , navigateTo = useAction(navigateToAction)
        , [downloadingImagesIds, handleDownload] = useImageDownloader()
        , timeService = useTimeService()

    if (props.showSpinner) {
        return (
            <div className='position-relative py-5'>
                <i className='preview-spinner material-icons md-48'>sync</i>
            </div>
        )
    }

    return (
        <div className='overflow-auto flex-fill'>
            {auditingPlateId &&
                <AuditTrailModal
                    id={auditingPlateId}
                    loadAuditTrailAction={actions.loadPlateTrail}
                    onClose={() => navigateTo(r.PLATES)}
                />
            }
            <Table className='table-layout-fixed'>
                <thead className='thead table-header--sticky'>
                    <tr>
                        <th style={{ width: '25%' }}>Barcode</th>
                        <th style={{ width: '16.25%' }}>Last read</th>
                        <th style={{ width: '16.25%' }}>Read by</th>
                        <th style={{ width: '16.25%' }}>Photo</th>
                        <th style={{ width: '16.25%' }}>CFUs</th>
                        <th colSpan={2} style={{ width: '10%' }} />
                    </tr>
                </thead>
                <tbody>
                    {props.list.length === 0
                        ? <EmptyTableMessage colSpan={7} message='No results found' />
                        : props.list.map(_ =>
                            <tr key={_.id}>
                                <td><div className='single-line-text' title={_.barcode}>{_.barcode}</div></td>
                                <td>
                                    <FormattedText text={_.lastReadAt ? timeService.formatCtzDateTime(_.lastReadAt) : undefined} />
                                </td>
                                <td>
                                    { _.lastReaderName && _.lastReaderRole && <>{_.lastReaderName } ({_.lastReaderRole})</>}
                                </td>
                                <td>
                                    {downloadingImagesIds.includes(_.id)
                                        ? <div className='position-relative py-3'>
                                            <i className='preview-spinner material-icons md-24'>sync</i>
                                        </div>
                                        : <Button className='p-0' onClick={() => { handleDownload(_.id) }}>
                                            <RoundedImages images={_.images} />
                                        </Button>
                                    }
                                </td>
                                <td>
                                    {_.cfuCount}{<EditInfo info={_.lastEditorInfo} />}
                                </td>
                                <td>
                                    <Link
                                        className='d-block btn-link'
                                        routeName={r.PLATES}
                                        routeParams={{ auditingPlateId: _.id }}
                                    >
                                        <i className='material-icons md-24'>history</i>
                                    </Link>
                                </td>
                                <td className='text-end'>
                                    <LinkButton
                                        className='btn-link align-baseline p-0'
                                        routeName={r.PLATES_READING}
                                        routeParams={{ barcode: _.barcode }}
                                        hasNoPermissions={!permissions.readColonyCounterPlates}
                                    >
                                        View
                                    </LinkButton>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
                <PaginatedFooter
                    colSpan={7}
                    state={props.pagination}
                    onChange={props.handlePaginationChange}
                    totalCount={props.totalCount}
                />
            </Table>
        </div>
    )
}

export default PlateTable

function useImageDownloader() {
    const [downloadingImagesIds, setDownloadingImagesIds] = useState<string[]>([])
        , downloadPlateImages = useAction(actions.downloadPlateImages)

    const handleDownload = useCallback((plateId: string) => {
        setDownloadingImagesIds(prev => [...prev, plateId])
        downloadPlateImages(plateId)
            .finally(() => setDownloadingImagesIds(prev => prev.filter(_ => _ !== plateId)))
    }, [downloadPlateImages])

    return [downloadingImagesIds, handleDownload] as const
}
