import * as i from '_/constants/custom-field-index'

const NOT_EDITABLE_SHOW_WHEN_BOOKING_IN_FIELDS = new Set([
        i.EXPOSURE_DATE,
        i.EXPOSURE_LOCATION_ID,
        i.BARCODE,
        i.SAMPLE_TYPE_ID,
        i.MONITORING_POSITION,
        i.EXPOSURE_END_TIME,
        i.EXPOSURE_START_TIME,
    ])

    , NON_EDITABLE_VIABLE_REQUIRED_FIELDS = new Set([
        i.EXPOSURE_DATE,
        i.EXPOSURE_LOCATION_ID,
        i.BARCODE,
        i.MONITORING_POSITION,
        i.SAMPLE_TYPE_ID,
        i.EXPOSURE_START_TIME,
    ])

    , NON_EDITABLE_NON_VIABLE_REQUIRED_FIELDS = new Set([
        i.EXPOSURE_DATE,
        i.EXPOSURE_LOCATION_ID,
        i.BARCODE,
        i.MONITORING_POSITION,
    ])

    , NON_EDITABLE_ALLOW_NR_FIELDS = new Set([
        i.BARCODE,
        i.MONITORING_POSITION,
        i.EXPOSURE_DATE,
        i.SAMPLE_TYPE_ID,
        i.EXPOSURE_START_TIME,
        i.EXPOSURE_END_TIME,
    ])

    , NON_EDITABLE_PERSISTED_FIELDS = new Set([
        i.BARCODE,
    ])

    , DEFAULT_DATA_FIELDS = new Set([
        i.EXPOSURE_DATE,
        i.EXPOSURE_LOCATION_ID,
        i.SESSION_ID,
        i.BARCODE,
        i.SAMPLE_TYPE_ID,
        i.MONITORING_POSITION,
        i.EXPOSURE_START_TIME,
        i.EXPOSURE_END_TIME,
        i.OPERATORS_IDS,
        i.BATCH_NUMBER,
    ])

    , VIABLE_ONLY_DATA_FIELDS = new Set([
        i.SAMPLE_TYPE_ID,
    ])

function isEditableShowWhenBookingInField(fieldIndex: number) {
    return !NOT_EDITABLE_SHOW_WHEN_BOOKING_IN_FIELDS.has(fieldIndex)
}

function isEditableRequiredField(fieldIndex: number, isViable: boolean) {
    return isViable
        ? !NON_EDITABLE_VIABLE_REQUIRED_FIELDS.has(fieldIndex)
        : !NON_EDITABLE_NON_VIABLE_REQUIRED_FIELDS.has(fieldIndex)
}

function isEditableAllowNotRecordedField(fieldIndex: number) {
    return !NON_EDITABLE_ALLOW_NR_FIELDS.has(fieldIndex)
}

function isEditablePersistentField(fieldIndex: number) {
    return !NON_EDITABLE_PERSISTED_FIELDS.has(fieldIndex)
}

function isDefaultDataField(fieldIndex: number) {
    return DEFAULT_DATA_FIELDS.has(fieldIndex)
}

function isViableOnlyDataField(fieldIndex: number) {
    return VIABLE_ONLY_DATA_FIELDS.has(fieldIndex)
}

export {
    isEditableShowWhenBookingInField,
    isEditableRequiredField,
    isEditableAllowNotRecordedField,
    isEditablePersistentField,
    isDefaultDataField,
    isViableOnlyDataField,
    NOT_EDITABLE_SHOW_WHEN_BOOKING_IN_FIELDS,
}
