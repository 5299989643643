const ALERT_LIMIT = 0
    , ACTION_LIMIT = 1
    , COMPROMISED = 2
    , ALERT_BEHAVIOUR = 3
    , ACTION_BEHAVIOUR = 4
    , MANUAL_ACTION = 5

type NotificationSampleBreachType = never
    | typeof ALERT_LIMIT
    | typeof ACTION_LIMIT
    | typeof COMPROMISED
    | typeof ALERT_BEHAVIOUR
    | typeof ACTION_BEHAVIOUR
    | typeof MANUAL_ACTION

export {
    ACTION_LIMIT,
    ALERT_LIMIT,
    COMPROMISED,
    ALERT_BEHAVIOUR,
    ACTION_BEHAVIOUR,
    MANUAL_ACTION,
    NotificationSampleBreachType,
}
