import { useField } from 'react-final-form'
import { classnames } from '_/facade/react'
import type * as t from '_/model/text/text'

import Select from '../downshift-select'
import { showFieldError } from './helpers'

interface InputFieldProps {
    id?: string
    name: string
    disabled?: boolean
    entities: readonly any[]
    calcId(entity: any): any
    calcName(entity: any): string | t.Text
    autocomplete?: boolean
    showEndOfText?: boolean
    className?: string
    placeholder?: string
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    autoFocus?: boolean
    hasNoPermissions?: boolean
    inline?: boolean
    children?: React.ReactNode
    calcDisabled?(entity: any): boolean
    calcDisabledTitle?(entity: any): string
    testId?: string
}

function SelectField(props: InputFieldProps) {
    const field = useField(props.name)

    return (
        <div className={`${props.inline ? 'row g-2' : 'mb-3'} ${props.className || ''}`}>
            {props.children && <label htmlFor={props.id} className='col-form-label' data-testid='field-label'>{props.children}</label>}
            <Select
                id={props.id}
                entities={props.entities}
                calcId={props.calcId}
                calcName={props.calcName}
                disabled={props.disabled}
                className={classnames('form-control', { 'is-invalid': showFieldError(field.meta) })}
                input={field.input}
                autocomplete={props.autocomplete}
                showEndOfText={props.showEndOfText}
                placeholder={props.placeholder}
                onKeyDown={props.onKeyDown}
                autoFocus={props.autoFocus}
                hasNoPermissions={props.hasNoPermissions}
                calcDisabled={props.calcDisabled}
                calcDisabledTitle={props.calcDisabledTitle}
                testId={props.testId}
            />
            {showFieldError(field.meta) && <span className='invalid-feedback' data-testid='validation-error'>{field.meta.error}</span>}
        </div>
    )
}

export default SelectField
