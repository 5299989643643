import { useField } from 'react-final-form'

import { dropFields } from '_/utils/object'
import * as s from '_/model/predefined-lists/devices/status'

function MarkAsInActive() {
    const field = useField<s.DeviceStatus>('status')
        , inputProps = field.input
        , checked = field.input.value === s.INACTIVE
        , forwardedProps = dropFields(inputProps, 'type', 'value', 'checked', 'onChange')

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const checked = event.target.checked

        inputProps.onChange(checked ? s.INACTIVE : s.OFFLINE)
    }

    return (
        <div className={'form-check d-print-none'}>
            <input
                id='status'
                type='checkbox'
                className='form-check-input'
                checked={checked}
                onChange={handleChange}
                {...forwardedProps}
            />
            <label className='form-check-label' htmlFor='status'>
                Mark as inactive
            </label>
        </div>
    )
}

export default MarkAsInActive
