import { classnames } from '_/facade/react'

import Button, { Close } from '_/components/button'
import { useCloseModal } from '_/components/modal'
import PageHeader from '_/components/page-header'
import TabNavbar from '_/components/tab-navbar'

import * as tabs from '_/constants/modal-tab'

interface Props {
    floorPlanName: string
    activeTab: tabs.Tab
    onChangeTab: (_: tabs.Tab) => void
}

function Header(props: Props) {
    const closeModal = useCloseModal()

    return (
        <div>
            <PageHeader title={props.floorPlanName} hideTopOffset className='px-0 py-0 align-items-start flex-nowrap floor-plan-window__header'>
                <Close onClick={closeModal} />
            </PageHeader>
            <TabNavbar>
                <Button
                    onClick={() => props.onChangeTab(tabs.DATA)}
                    className={classnames('btn-link navbar-tab me-4', { active: props.activeTab === tabs.DATA })}
                >
                    Floor plan data
                </Button>
                <Button
                    onClick={() => props.onChangeTab(tabs.AUDIT_TRAIL)}
                    className={classnames('btn-link navbar-tab', { active: props.activeTab === tabs.AUDIT_TRAIL })}
                >
                    Audit trail
                </Button>
            </TabNavbar>
        </div>
    )
}

export default Header
