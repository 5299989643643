import type ApiService from '../api-service'
import type ApiKeyService from '../api-key-service'
import type { ApiKeyPair, ApiKeyCreateView, ApiKeyEdit } from '_/model/api-key/types'
import type ApiKey from '_/model/api-key/types'
import { downloadBlob } from '_/utils/blob'
import type { FieldsDiff } from '_/utils/object'

function factory(api: ApiService): ApiKeyService {
    return {
        ...api.resource(['api-keys']),
        create,
        save,
        getApiKeys,
        downloadApiKeys,
        dismissApiKey,
    }

    function create() {
        return api.post<ApiKeyCreateView>(['api-keys'])
    }

    function save(id: string, entity: FieldsDiff<ApiKeyEdit>, fromCreate: boolean): Promise<void> {
        return api.patch(['api-keys', id], { ...entity, fromCreate })
    }

    function getApiKeys(userId: string): Promise<ApiKey[]> {
        return api.get(['api-keys'], { userId })
    }

    function downloadApiKeys(apiKeys: ApiKeyPair): Promise<void> {
        return api.getFileForLongQuery(['api-keys', 'export-keys'], apiKeys)
            .then(_ => downloadBlob(_.blob, _.filename))
    }

    function dismissApiKey(id: string): Promise<void> {
        return api.post(['api-keys', id, 'dismiss'])
    }
}

export default factory
