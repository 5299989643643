import type { FieldArrayRenderProps } from 'react-final-form-arrays'
import { useFieldArray } from 'react-final-form-arrays'
import { useState, useEffect } from '_/facade/react'

import { Table } from '_/components/table'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { Expectations, SampleTypeQuantity } from '_/model/scheduling/monitoring-groups/types'
import { shallowUpdate } from '_/utils/object'
import * as tree from '_/utils/tree'

import ExposureLocationsRow from './exposure-locations-row'

interface Props {
    exposureLocations: tree.Tree<ExposureLocation> | undefined
    sampleTypes: SampleType[] | undefined
    canEdit: boolean
}

function useLocationsWithExpectations(props: Props, fields: FieldArrayRenderProps<Expectations, HTMLElement>) {
    const exposureLocations = props.exposureLocations
        , sampleTypes = props.sampleTypes
        , [expandState, setExpandState] = useState<tree.ExpandedItems>({})
        , expectations = fields.fields.value

    useEffect(
        () => {
            if (!exposureLocations || !sampleTypes)
                return

            const nextExpandState: tree.ExpandedItems = {}
                , locationsToExpand = getLocationsToExpand(exposureLocations, expectations)

            locationsToExpand.forEach(_ => nextExpandState[_] = true)

            setExpandState(nextExpandState)
        },
        [expectations, exposureLocations, sampleTypes]
    )

    const getLocationsToExpand = (exposureLocations: tree.Tree<ExposureLocation>, expectations: Expectations[]) => {
        const nonZero = expectations.filter(it => it.sampleTypeQuantities.some(_ => _.quantity > 0))
            , allLocations = tree.looseFlatTree(exposureLocations)
            , rootLocation = allLocations.find(_ => _.parentId === tree.VOID_ID)
            , locationsToExpand = new Set<string>()

        if (rootLocation)
            locationsToExpand.add(rootLocation.id)

        nonZero.forEach(it => {
            const location = exposureLocations.idHash[it.locationId]

            // Hash is empty during logout which happens on session expiration
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!location)
                return

            const path = tree.path(exposureLocations, location)

            path.pop()

            path.forEach(_ => locationsToExpand.add(_.id))
        })

        return locationsToExpand
    }

    , handleTrigger = (location: ExposureLocation) => {
        const isExpanded = !expandState[location.id]

        setExpandState(shallowUpdate(expandState, { [location.id]: isExpanded }))
    }

    return [exposureLocations, sampleTypes, expectations, expandState, handleTrigger] as const
}

function useExpectations(fields: FieldArrayRenderProps<Expectations, HTMLElement>) {
    const handleChange = (value: SampleTypeQuantity[], expectedLocationIndex: number) => {
        const expectations = fields.fields.value
            , expectationToCopy = expectations[expectedLocationIndex]
            , nextExpectation: Expectations = { ...expectationToCopy, sampleTypeQuantities: value }

        fields.fields.update(expectedLocationIndex, nextExpectation)
    }

    return [handleChange] as const
}

function expectationsEqual(x: Expectations[] | undefined, y: Expectations[] | undefined) {
    if (x === undefined && y === undefined)
        return true

    if (x === undefined || y === undefined)
        return false

    if (x.length !== y.length)
        return false

    for (let i = 0; i < x.length; i++) {
        if (x[i].locationId !== y[i].locationId)
            return false

        const xQuantities = x[i].sampleTypeQuantities
            , yQuantities = y[i].sampleTypeQuantities

        for (let j = 0; j < xQuantities.length; j++) {
            if (xQuantities.length !== yQuantities.length
                || xQuantities[j].sampleTypeId !== yQuantities[j].sampleTypeId
                || xQuantities[j].quantity !== yQuantities[j].quantity) {
                return false
            }
        }
    }

    return true
}

function ExposureLocations(props: Props) {
    const fields = useFieldArray<Expectations>('expectations', { isEqual: expectationsEqual })
        , [exposureLocations, sampleTypes, expectations, expandState, handleTrigger] = useLocationsWithExpectations(props, fields)
        , [handleChange] = useExpectations(fields)

    if (!sampleTypes || !exposureLocations)
        return null

    const locations = tree.flatTree(exposureLocations, expandState)
        , allLocations = tree.looseFlatTree(exposureLocations)
        , locationsWithExpectations = expectations.filter(it => locations.some(_ => _.id === it.locationId))

    return (
            <div className='overflow-auto planning-block'>
                <Table>
                    <thead className='thead'>
                        <tr>
                            <th>Name</th>
                            <th>Included viable samples</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locationsWithExpectations.map((locationExpectation) => {
                            const exposureLocation = exposureLocations.idHash[locationExpectation.locationId]
                                , fullTreeIndex = allLocations.findIndex(it => it.id === exposureLocation.id)

                            return <ExposureLocationsRow
                                key={locationExpectation.locationId}
                                expectations={locationExpectation}
                                location={exposureLocation}
                                sampleTypes={sampleTypes}
                                canEdit={props.canEdit}
                                hasChildren={tree.hasChildren(exposureLocations, exposureLocation)}
                                expanded={expandState[locationExpectation.locationId]}
                                nestingLevel={tree.nestingLevel(exposureLocations, exposureLocation)}
                                onTrigger={handleTrigger}
                                onChange={_ => handleChange(_, fullTreeIndex)}
                            />
                        })}
                    </tbody>
                </Table>
            </div>
        )
}

export default ExposureLocations
