import { Form, useField } from 'react-final-form'

import { classnames, React, useAction, useCallback, useEffect, useState } from '_/facade/react'

import { resetForm, Submit, submitDisabled } from '_/components/form'
import { Modal, ModalBody, ModalHeader, ModalFooter } from '_/components/modal'
import Button, { Close } from '_/components/button'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import * as routes from '_/constants/routes'
import * as routerActions from '_/features/routing/actions'

import * as actions from '../actions'
import * as customFieldsActions from '_/features/predefined-lists/custom-fields/actions'
import type ColumnEdit from '_/model/sample/list/column-edit'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import ColumnName from './column-name'
import type SampleListColumn from '_/model/sample/list/types'
import InlineCheckbox from '_/components/checkbox-inline'
import type { FormApi } from 'final-form'
import * as c from '_/model/sample/list/columns'
import * as dom from '_/model/dom'

interface Props {
    columns: ColumnEdit
}

function ColumnsEditModal(props: Props) {
    const navigateToList = useNavigateToList()
        , handleSubmit = useSubmitHandler()
        , customFields = useCustomFields()

    return (
        <Modal isOpen onClose={navigateToList}>
            <Form<ColumnEdit>
                onSubmit={handleSubmit}
                validate={_ => ({})}
                initialValues={props.columns}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form}/>

                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4>Edit columns</h4>
                                    <Close onClick={navigateToList} />
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <ColumnsField fields={customFields} />
                        </ModalBody>
                        <ModalFooter className='border-top-0 modal-footer-height'>
                            <div className='d-flex flex-fill justify-content-end'>
                                <div>
                                    <Button className='text-muted bg-transparent border-0 me-2' onClick={navigateToList}>Cancel</Button>
                                    <Submit disabled={submitDisabled(form)}>Save changes</Submit>
                                </div>
                            </div>
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default ColumnsEditModal

function useCustomFields() {
    const loadCustomFieldsIncludingInactive = useAction(customFieldsActions.loadCustomFieldsIncludingInactive)
        , [customFields, setCustomFields] = useState<CustomField[]>([])

    useEffect(
        () => {
            loadCustomFieldsIncludingInactive().then(setCustomFields)
        },
        [loadCustomFieldsIncludingInactive]
    )

    return customFields
}

function useSubmitHandler() {
    const saveListColumns = useAction(actions.saveListColumns)
        , navigateToList = useNavigateToList()

    function handleSubmit(columns: ColumnEdit, form: FormApi<any>) {
        return saveListColumns(columns).then(_ => {
            resetForm(form).then(navigateToList)
        })
    }

    return handleSubmit
}

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SAMPLES), [navigateTo])

    return navigateToList
}

interface ColumnsFieldProps {
    fields: CustomField[]
}

function ColumnsField(props: ColumnsFieldProps) {
    const [columns, handleMove, handleToggle] = useColumnsField()
        , [handlers, dropRow, dragDirection] = useDragAndDrop(handleMove)

    function columnDisabled(column: SampleListColumn) {
        return column.type === c.USER_DEFINED && !props.fields.find(_ => _.index === column.fieldIndex)?.viableSettings.isActive
    }

    return (
        <div className='border-top'>
            {columns.map((_, index) =>
                <div
                    key={index}
                    className={classnames('py-2 d-flex cursor-pointer', dropRow !== index ? 'border-bottom border-top' : dom.getDragDirectionClass('sample-column-edit__drag-hover', dragDirection))}
                    draggable

                    onDragStart={e => handlers.handleDragStart(e, index)}
                    onDragEnd={handlers.handleDragEnd}

                    onDragOver={e => handlers.handleDragEnterOrOver(e, index)}
                    onDragEnter={e => handlers.handleDragEnterOrOver(e, index)}

                    onDragLeave={handlers.handleDragLeave}

                    onDrop={handlers.handleDrop}
                >
                    <InlineCheckbox
                        id={`include-column-${index}`}
                        name={`include-column-${index}`}
                        checked={_.included}
                        onChange={() => handleToggle(index)}
                        disabled={columnDisabled(_)}
                    >
                        <ColumnName column={_} fields={props.fields} />
                    </InlineCheckbox>
                    <span className='material-icons ms-auto text-black-50'>drag_indicator</span>
                </div>
            )}
        </div>
    )
}

function areArraysEqual(x: SampleListColumn[] | undefined, y: SampleListColumn[] | undefined): boolean {
    if (x === y)
        return true

    if (!x || !y)
        return false

    return x.length === y.length
        && x.every((v, i) => v.fieldIndex === y[i].fieldIndex)
        && x.every((v, i) => v.included === y[i].included)
}

function useColumnsField() {
    const columns = useField<SampleListColumn[]>('columns', { isEqual: areArraysEqual })
        , input = columns.input

    function handleChange(value: SampleListColumn[]) {
        input.onChange(value)
        input.onBlur()
    }

    function handleMove(draggedRow: number, dropRow: number, dragDirection: dom.DragDirection) {
        const column = input.value[draggedRow]
            , filterNumber = dragDirection === dom.BOTTOM ? dropRow + 1 : dropRow
            , init = input.value.slice(0, filterNumber).filter(_ => _ !== column)
            , tail = input.value.slice(filterNumber).filter(_ => _ !== column)
            , newValue = init.concat(column, tail)

        handleChange(newValue)
    }

    function handleToggle(row: number) {
        const column = input.value[row]
            , newValue = input.value.slice()

        newValue[row] = { ...column, included: !column.included }

        handleChange(newValue)
    }

    return [input.value, handleMove, handleToggle] as const
}

function useDragAndDrop(onMove: (fromRow: number, toRow: number, dragDirection: dom.DragDirection) => void) {
    const [draggedRow, setDraggedRow] = useState<number>()
        , [dropRow, setDropRow] = useState<number>()
        , [dragDirection, setDragDirection] = useState<dom.DragDirection>(dom.NONE)

    function handleDragStart(e: React.DragEvent<HTMLElement>, row: number) {
        e.dataTransfer.effectAllowed = 'move'
        setDraggedRow(row)
    }

    function handleDragEnd() {
        setDraggedRow(undefined)
        setDropRow(undefined)
        setDragDirection(dom.NONE)
    }

    function handleDragEnterOrOver(e: React.DragEvent<HTMLElement>, row: number) {
        if (draggedRow === undefined || row === draggedRow)
            return

        const direction = dom.getElementDragDirection(e.currentTarget, e)
            , canMove = direction === dom.TOP && draggedRow !== row - 1
                || direction === dom.BOTTOM && draggedRow !== row + 1

        if (!canMove)
            return

        setDragDirection(direction)

        setDropRow(row)
        e.preventDefault()
    }

    function handleDragLeave(_e: React.DragEvent<HTMLElement>) {
        setDropRow(undefined)
        setDragDirection(dom.NONE)
    }

    function handleDrop(e: React.DragEvent<HTMLElement>) {
        if (draggedRow === undefined || dropRow === undefined || !dragDirection)
            return

        onMove(draggedRow, dropRow, dragDirection)
        setDragDirection(dom.NONE)

        e.preventDefault()
    }

    return [
        {
            handleDragStart,
            handleDragEnd,
            handleDragEnterOrOver,
            handleDragLeave,
            handleDrop,
        },
        dropRow,
        dragDirection,
    ] as const
}
