import { React, classnames } from '_/facade/react'
import type { FieldMetaState } from 'react-final-form'
import { Field } from 'react-final-form'
import type { Value, NOT_VALID_VALUE } from './number-text-input'
import NumberTextInput from './number-text-input'
import { showFieldError } from './helpers'

type Range = {
    from: string | NOT_VALID_VALUE | undefined
    to: string | NOT_VALID_VALUE | undefined
}

type FieldType = 'from' | 'to'

interface InputProps {
    id?: string
    value: Range | ''
    meta: FieldMetaState<any>
    className?: string
    disabled?: boolean
    onChange: (_: Range | '') => void
    onBlur: () => void
    onFocus: () => void
    testId?: string
}

function RangeInput(props: InputProps) {
    const fromId = (props.id || 'range') + '-from'
        , toId =  (props.id || 'range') + '-to'
        , isError = showFieldError(props.meta)

    function onChange(value: Value, field: FieldType): void {
        const range: Range = props.value ? { ...props.value } : {from: undefined, to: undefined}
        range[field] = value === '' ? undefined : value

        const result = range.from === undefined && range.to === undefined
                ? ''
                : range

        props.onChange(result)
    }

    function convertInputValue(field: FieldType) {
        if (!props.value || props.value[field] === undefined)
            return ''

        return props.value[field] as Value
    }

    return (
        <div className={`mb-3 ${props.className || ''}`}>
            <div className='d-flex'>
                <div>
                    <label htmlFor={fromId} className='col-form-label'>From</label>
                    <NumberTextInput
                        id={fromId}
                        disabled={props.disabled}
                        className={classnames('form-control', { 'is-invalid': isError && props.meta.error.from })}
                        value={convertInputValue('from')}
                        onChange={_ => onChange(_, 'from')}
                        onBlur={_ => props.onBlur()}
                        onFocus={_ => props.onFocus()}
                        testId={`${props.testId}-from`}
                    />
                </div>

                <div className='ms-1'>
                    <label htmlFor={toId} className='col-form-label'>To</label>
                    <NumberTextInput
                        id={toId}
                        disabled={props.disabled}
                        className={classnames('form-control', { 'is-invalid': isError && props.meta.error.to })}
                        value={convertInputValue('to')}
                        onChange={_ => onChange(_, 'to')}
                        onBlur={_ => props.onBlur()}
                        onFocus={_ => props.onFocus()}
                        testId={`${props.testId}-to`}
                    />
                </div>
            </div>

            {isError && <span className='form-control is-invalid d-none'/>}
            {isError &&
                <span className='invalid-feedback' data-testid='validation-error'>
                    {props.meta.error.errors.map((message: string, idx: number) => {
                        if (idx === 0)
                            return message

                        return (
                            <React.Fragment key={idx}>
                                <br />
                                {message}
                            </React.Fragment>
                        )
                    })}
                </span>
            }
        </div>
    )
}

interface FieldProps {
    id: string
    name: string
    label?: string
    className?: string
    disabled?: boolean
    testId?: string
}

function NumericRangeField(props: FieldProps) {
    return (
        <Field
            name={props.name}
            render={_ =>
                <div>
                    <div data-testid='field-label' className='word-wrap-break-word'>{props.label}</div>
                    <RangeInput
                        id={props.id}
                        {..._.input}
                        meta={_.meta}
                        className={props.className}
                        disabled={props.disabled}
                        testId={props.testId}
                    />
                </div>
            }
        />
    )
}

export default NumericRangeField
