import type { State as RouterState } from 'router5'

import { useSelector, classnames } from '_/facade/react'
import * as routes from '_/constants/routes'

import TabNavbar from '_/components/tab-navbar'
import Link from '_/components/link'
import ExposureLocationList from './tree/exposure-locations'
import FloorPlans from '../floor-plan/list/floor-plans'

function Locations() {
    const permissions = useSelector(_ => _.auth.permissions)
        , route = useSelector(_ => _.router.route)
        , routePage = useRenderRoute(route)

    return (
        <>
            <div className='page-header pt-3'>
                <TabNavbar>
                    <Link
                        routeName={routes.SETTINGS_EXPOSURE_LOCATIONS}
                        className={classnames('navbar-tab me-4', { active: route?.name === routes.SETTINGS_EXPOSURE_LOCATIONS })}
                        hasNoPermissions={!permissions.readExposureLocations}
                    >
                        Exposure locations
                    </Link>
                    <Link
                        routeName={routes.SETTINGS_FLOOR_PLANS}
                        className={classnames('navbar-tab', { active: route?.name === routes.SETTINGS_FLOOR_PLANS })}
                        hasNoPermissions={!permissions.manageFloorPlans}
                    >
                        Floor plans
                    </Link>
                </TabNavbar>
            </div>
            {routePage}
        </>
    )
}

export default Locations

function useRenderRoute(route: RouterState | null) {
    switch (route?.name) {
        case routes.SETTINGS_EXPOSURE_LOCATIONS:
        case routes.SETTINGS_EXPOSURE_LOCATIONS_CREATE:
        case routes.SETTINGS_EXPOSURE_LOCATIONS_EDIT:
            return <ExposureLocationList />

        case routes.SETTINGS_FLOOR_PLANS:
        case routes.SETTINGS_FLOOR_PLANS_ADD:
        case routes.SETTINGS_FLOOR_PLANS_EDIT:
            return <FloorPlans />

        default:
            return null
    }
}
