import { connect, dispatchPropsMapper, useAction } from '_/facade/react'
import type TimeService from '_/services/time-service'
import { getTimeService } from '_/features/time/actions'

interface Props extends ConnectedProps {
    render: (service: TimeService) => React.ReactNode
}

function Time({ render, getTimeService }: Props) {
    return render(getTimeService())
}

Time.defaultProps = {
    render: () => null,
}

const mapDispatchToProps = dispatchPropsMapper({ getTimeService })

type ConnectedProps = ReturnType<typeof mapDispatchToProps>

export default connect(undefined, mapDispatchToProps)(Time as React.FunctionComponent<Props>)

function useTimeService() {
    const timeService = useAction(getTimeService)

    return timeService()
}

export { useTimeService }
