import type { FormRenderProps} from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'
import { useAction, useState, useEffect, useSelector, classnames } from '_/facade/react'

import PageHeader from '_/components/page-header'
import { useContextSwitchObserver } from '_/components/context-observer'
import { LocalDateField, SelectField } from '_/components/form'
import { useTimeService } from '_/components/time'
import Button from '_/components/button'

import type { MissedMonitoringReport, MissedMonitoringReportView } from '_/model/reports/missed-monitoring-report/types'
import DEFAULT_DATE_RANGES, * as ranges from '_/constants/date-ranges'
import { ITEMS_LIMIT_COUNT, ITEMS_PRINT_LIMIT_COUNT } from '_/constants/items-limit-count'

import { loadMissedMonitoringReportData } from './actions'

import { getGeneratedBy } from '_/features/samples/helpers'

import ReportGraph from '../report-line-graph'
import { useFilter } from '_/hooks/shared-hooks'
import { validateReportDate } from '_/model/reports/validate'
import { getDateRange, getGraphFooter } from '_/model/reports/helpers'
import MissedMonitoringTable from './missed-monitoring-table'
import AverageMissedMonitoring from './missed-monitoring-by-day-and-session/average-missed-monitoring'
import type TimeService from '_/services/time-service'
import GradePieChart from './grade-pie-chart/grade-pie-chart'

import KpiBox from './kpi-box'
import { missedMonitoringGraphSubtitle, formatUtcDatePeriod, calculateTrend, areDatesInCurrentYear } from '_/model/reports/missed-monitoring-report/helpers'

import { defaultTextNode } from '_/model/text/text'
import { diffObject } from '_/utils/object'

const MissedMonitoringReportPage = () => {
    const timeService = useTimeService()
        , [filter, changeFilter] = useFilter<MissedMonitoringReport>('missed-monitoring-report', _ => ({}))
        , isFilterValid = Object.keys(validateReportDate(filter)).length === 0
        , [reportResult, showSpinner] = useReport(filter, isFilterValid, timeService)
        , [printMode, setPrintMode] = useState(false)
        , user = useSelector(_ => _.auth.user)
        , canExportData = useSelector(_ => _.auth.permissions.exportData)
        , kpiData = reportResult?.kpiData
        , missedSamplesPreviousPeriodDateRange = kpiData?.missedSamples.previousPeriodDateRange
        , previousPeriodMissedSamples = kpiData?.missedSamples.previousPeriodMissedSamples
        , noPreviousPeriodScheduledSamples = kpiData?.missedSamples.previousPeriodScheduledSamples === 0
        , samplesAwaitingBookIn = kpiData
            ? kpiData.scheduledNotBookedInSamplesTotalCount + kpiData.notInUseSamplesTotalCount + kpiData.missedSamples.currentPeriodMissedSamples
            : 0
        , scheduledBookedInSamplesTotalCount = kpiData?.scheduledBookedInSamplesTotalCount ?? 0
        , expectedSamples = scheduledBookedInSamplesTotalCount + samplesAwaitingBookIn
        , filterChangeHandler = useFilterChangeHandler(filter)

    useResetMissedMonitoringReport(changeFilter, setPrintMode)

    return (
        <div className='d-flex align-items-stretch h-100 w-100 width-print-100'>
            <Form
                onSubmit={changeFilter}
                validate={validateReportDate}
                initialValues={filter}
                render={form =>
                    <div className='d-flex d-print-none flex-column bg-light side-filters'>
                        <form className='overflow-y-auto flex-fill' onSubmit={form.handleSubmit}>
                            <FormSpy
                                onChange={_ => filterChangeHandler(form, _.values)}
                                subscription={{ values: true }}
                            />
                            <div className='px-3'>
                                <SelectField
                                    name='exposureDateRange'
                                    id='exposureDateRange'
                                    entities={DEFAULT_DATE_RANGES}
                                    calcId={_ => _.id}
                                    calcName={_ => _.name}
                                    placeholder='Select date range'
                                    testId='field-date-range'
                                >
                                    Exposure date range
                                </SelectField>
                                {form.values.exposureDateRange === ranges.CUSTOM &&
                                    <div>
                                        <LocalDateField id='exposureStartDateFrom' name='exposureStartDateFrom'>From exposure date</LocalDateField>
                                        <LocalDateField id='exposureStartDateTo' name='exposureStartDateTo' useDayEndTime>To exposure date</LocalDateField>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                }
            />
            <div className='h-100 w-100 width-print-100 px-0'>
                <div className='d-flex justify-content-center align-items-stretch h-100'>
                    {!isFilterValid
                        ? <div className='align-self-center' data-testid='not-selected-date-range-placeholder'>Select a date range to view the detailed report</div>
                        : <div className='flex-fill overflow-y-auto'>
                            {user && printMode &&
                                <div className='ms-3'>
                                    {getGeneratedBy(timeService, user.name, user.email)}
                                </div>
                            }
                            <PageHeader sticky className='d-print-block' title='Missed monitoring report'>
                                <Button
                                    className={classnames('align-self-start d-print-none btn-link me-1 ms-auto', {
                                        disabled: !canExportData,
                                    })}
                                    onClick={() => setPrintMode(!printMode)}
                                    hasNoPermissions={!canExportData}
                                >
                                    {printMode ? 'Report mode' : 'Print mode'}
                                </Button>
                                {printMode && <Button className='btn-primary align-self-start d-print-none me-1' onClick={window.print}>Print</Button>}
                            </PageHeader>

                            <div className='container-fluid'>
                                <div className='row mt-3'>
                                    <KpiBox
                                        samplesTotalCount={expectedSamples}
                                        title={expectedSamples}
                                        caption='Total number of viable samples expected'
                                        emptyMessage='No viable samples expected'
                                        showSpinner={showSpinner}
                                    />
                                    <KpiBox
                                        samplesTotalCount={reportResult?.kpiData.missedSamples.currentPeriodMissedSamples}
                                        title={reportResult?.kpiData.missedSamples.currentPeriodMissedSamples}
                                        trend={calculateTrend(reportResult?.kpiData.missedSamples)}
                                        caption='Total number of missed viable samples'
                                        emptyMessage={expectedSamples === 0 ? 'No missed viable samples' : undefined}
                                        showSpinner={showSpinner}
                                    >
                                        <>
                                            <div className='fw-bold'>
                                                {formatUtcDatePeriod(timeService, missedSamplesPreviousPeriodDateRange, areDatesInCurrentYear(missedSamplesPreviousPeriodDateRange, timeService))}
                                            </div>

                                            {noPreviousPeriodScheduledSamples
                                                ? 'No missed viable samples'
                                                : `${previousPeriodMissedSamples} - missed viable ${previousPeriodMissedSamples === 1 ? 'sample' : 'samples'}`
                                            }

                                        </>
                                    </KpiBox>
                                    <KpiBox
                                        samplesTotalCount={samplesAwaitingBookIn}
                                        title={samplesAwaitingBookIn}
                                        caption='Total number of viable samples awaiting booking in'
                                        emptyMessage={expectedSamples === 0 ? 'No viable samples awaiting booking in' : undefined}
                                        showSpinner={showSpinner}
                                    />
                                </div>
                                <div className={classnames('row d-print-block mt-3', { 'flex-column': printMode })}>
                                    <ReportGraph
                                        series={reportResult?.missedMonitoringRateGraphData ?? []}
                                        timeService={timeService}
                                        name='missed monitoring'
                                        printMode={printMode}
                                        graphTitle={[defaultTextNode('graph')]}
                                        subtitle={missedMonitoringGraphSubtitle(reportResult?.missedMonitoringRateGraphData[0], timeService)}
                                        emptyMessage='No data available'
                                        chartTitle='Missed monitoring rate'
                                        showSpinner={showSpinner}
                                        footer={getGraphFooter(filter, undefined, timeService)}
                                    />

                                    <GradePieChart
                                        series={reportResult?.missedMonitoringGradeGraphData ?? []}
                                        printMode={printMode}
                                        showSpinner={showSpinner}
                                        report={filter}
                                    />
                                </div>

                                <div className='d-none d-print-block width-print-100 page-break' />
                                <div className='row mt-3'>
                                    <MissedMonitoringTable
                                        printMode={printMode}
                                        itemsLimit={printMode ? ITEMS_PRINT_LIMIT_COUNT : ITEMS_LIMIT_COUNT}
                                        monitoringItems={reportResult?.missedMonitoringTableData ?? []}
                                        filter={filter}
                                        showSpinner={showSpinner}
                                    />
                                </div>
                                <div className='row mt-3'>
                                    <AverageMissedMonitoring
                                        printMode={printMode}
                                        report={filter}
                                        showSpinner={showSpinner}
                                        missedMonitoring={reportResult?.missedMonitoringByDayAndSession ?? []}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MissedMonitoringReportPage

function useReport(filter: MissedMonitoringReport, isFilterValid: boolean, timeService: TimeService) {
    const loadData = useAction(loadMissedMonitoringReportData)
        , [missedMonitoringReportResult, setMissedMonitoringReportResult] = useState<MissedMonitoringReportView>()
        , [showSpinner, setShowSpinner] = useState(false)

    useEffect(
        () => {
            if (!isFilterValid)
                return

            let disposed = false

            setShowSpinner(true)
            loadData(getDateRange(timeService, filter))
                .then(_ => {
                    if (!disposed)
                        setMissedMonitoringReportResult(_)
                })
                .finally(() => setShowSpinner(false))

            return () => { disposed = true }
        },
        [filter, isFilterValid, timeService, loadData]
    )

    return [missedMonitoringReportResult, showSpinner] as const
}

function useResetMissedMonitoringReport(onFilterChange: (_: MissedMonitoringReport) => void, setPrintMode: (_: boolean) => void) {
    const contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            if (contextSwitch === 0)
                return

            onFilterChange({})
            setPrintMode(false)
        },
        [contextSwitch, onFilterChange, setPrintMode]
    )
}

function useFilterChangeHandler(initialValues: MissedMonitoringReport) {
    return (form: FormRenderProps, values: MissedMonitoringReport) => {
        if (diffObject(initialValues, values))
            form.form.submit()
    }
}
