import ClippedShape from './clipped-shape'
import { toPath } from './helpers'

interface Props {
    size: number
}

function Triangle(props: Props) {
    const height = props.size
        , halfHeight = height / 2
        , halfSide = height / Math.sqrt(3)
        , path: [number, number][] = [
            [0, -halfHeight],
            [halfSide, halfHeight],
            [-halfSide, halfHeight],
        ]

    return <ClippedShape path={toPath(path)} size={props.size} />
}

export default Triangle
