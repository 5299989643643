export const ALERT_LIMIT_BREACHES = 0
           , ACTION_LIMIT_BREACHES = 1
           , ACTION_OR_ALERT_LIMIT_BREACHES = 2
           , STRICTLY_INCREASING_CFU_COUNT = 3
           , NON_STRICTLY_INCREASING_CFU_COUNT = 4

import type * as self from './rule-type'
type TrendRule = Extract<(typeof self)[keyof typeof self], number>

const TREND_RULE: readonly { id: TrendRule, name: string }[] = [
    {
        id: ALERT_LIMIT_BREACHES,
        name: 'Alert limit breaches',
    },
    {
        id: ACTION_LIMIT_BREACHES,
        name: 'Action limit breaches',
    },
    {
        id: ACTION_OR_ALERT_LIMIT_BREACHES,
        name: 'Action or alert limit breaches'
    },
    {
        id: STRICTLY_INCREASING_CFU_COUNT,
        name: 'Strictly increasing CFU count'
    },
    {
        id: NON_STRICTLY_INCREASING_CFU_COUNT,
        name: 'Non-strictly increasing CFU count'
    },
]

export {
    TREND_RULE,
    TrendRule,
}
