import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type PredefinedListsService from '_/services/predefined-lists-service'
import type FloorPlanService from '_/services/floor-plan-service'

import * as actions from './actions'

import exposureLocationEffectsFactory from '../exposure-locations/effects'
import floorPlanEffectsFactory from '../floor-plan/effects'
import organismIdentificationsEffectsFactory from '../organism-identification/effects'
import objectionableOrganismsEffectsFactory from '../objectionable-organisms/effects'
import sampleTypeEffectsFactory from '../sample-type/effects'
import limitsEffectsFactory from '../action-alert-limits/effects'
import sampleOperatorEffectsFactory from '../sample-operator/effects'
import sampleSessionEffectsFactory from '../sample-session/effects'
import customFieldEffectsFactory from '../custom-fields/effects'
import identificationsEffectsFactory from '../identifications/effects'
import trendSettingsEffectsFactory from '../trend-settings/effects'
import deviceEffectsFactory from '../devices/effects'
import type ReasonService from '_/model/reason/reason-service'

import * as tree from '_/utils/tree'
import type DeviceService from '_/services/device-service'

const factory = (service: PredefinedListsService): EffectsFactory => dispatch => [
    handler(actions.loadPredefinedLists, (payload) =>
        Promise
            .all([
                service.sampleType.list(),
                service.exposureLocation.list(),
                service.actionAlertLimits.list(),
                service.sampleOperator.list(),
                service.sampleSession.list(),
                service.grade.list(),
                service.customField.list(),
                service.identifications.list(true)
            ] as const)
            .then(([
                sampleTypes, locations, limits, sampleOperators, sampleSessions, grades, customFields, identifications,
            ]) => {
                dispatch(actions.predefinedListLoaded({
                    sampleTypes,
                    exposureLocations: tree.tree(locations),
                    limits,
                    sampleOperators,
                    sampleSessions,
                    grades,
                    customFields,
                    identifications,
                    includeInactiveCustomFields: payload ? payload.includeInactiveCustomFields : false,
                }))
            })
    ),
]

export default (service: PredefinedListsService, floorPlanService: FloorPlanService, reasonService: ReasonService, deviceService: DeviceService): EffectsFactory => {
    const effects = [
            factory,
            exposureLocationEffectsFactory,
            organismIdentificationsEffectsFactory,
            objectionableOrganismsEffectsFactory,
            sampleTypeEffectsFactory,
            limitsEffectsFactory,
            sampleOperatorEffectsFactory,
            sampleSessionEffectsFactory,
            customFieldEffectsFactory,
            identificationsEffectsFactory,
            trendSettingsEffectsFactory,
        ]
        .map(_ => _(service, reasonService))
        .concat(floorPlanEffectsFactory(floorPlanService), deviceEffectsFactory(service, reasonService, deviceService))

    return (dispatch, getState) =>
        effects
            .map(_ => _(dispatch, getState))
            .reduce((acc, v) => acc.concat(v))
}
