import { React, classnames } from '_/facade/react'
import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'

interface Props {
    onClick(): void
    disabled?: boolean
    className?: string
    title?: string
    hasNoPermissions?: boolean
    autoFocus?: boolean
    children?: React.ReactNode
    testId?: string
}

const Button = React.forwardRef((props: Props, ref?: React.Ref<HTMLButtonElement>) => (
    <button
        type='button'
        className={classnames(
            'btn ' + props.className || '',
            {'disabled': props.hasNoPermissions || props.disabled},
            {'no-permission': props.hasNoPermissions}
        )}
        disabled={props.hasNoPermissions || props.disabled}
        onClick={props.onClick}
        title={props.hasNoPermissions ? NO_PERMISSION_MESSAGE : props.title}
        autoFocus={props.autoFocus}
        ref={ref}
        data-testid={props.testId}
    >
        {props.children}
    </button>
))

Button.defaultProps = {
    className: '',
}

const Close = (props: Props) =>
    <button
        type='button'
        className={classnames(
            'close close--float-none ' + props.className || '',
            {'disabled': props.hasNoPermissions || props.disabled},
            {'no-permission': props.hasNoPermissions}
        )}
        disabled={props.hasNoPermissions || props.disabled}
        onClick={props.onClick}
        title={props.hasNoPermissions ? NO_PERMISSION_MESSAGE : props.title}
        data-testid={props.testId}
    >
        &times;
    </button>

Close.defaultProps = {
    className: '',
}

export default Button
export { Close }
