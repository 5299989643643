import type { ValidationResult} from '_/utils/form/validate'
import { required, checkDateRangeValidity, isValidNumberInRange } from '_/utils/form/validate'
import type TimeService from '_/services/time-service'
import type { RecurrenceForm, WeekRecurrence, MonthRecurrence } from './types'
import { generateFuturePeriod } from '../monthly-scheduler/helpers'

import type { DateTime} from '_/model/date-time'
import { greaterThan, greaterThanOrEqual } from '_/model/date-time'
import type { DayId } from '_/constants/weekday'
import { MONTH_RECURRENCE_ON_WEEK_DAY, MONTH_RECURRENCE_ON_DATE } from './month-recurrence-type'
import * as ft from './frequency-type'

export default function validate(entity: RecurrenceForm, date: DateTime, timeService: TimeService) {
    const recurrenceFrequency = validateRecurrenceFrequency(entity.recurrenceFrequency)
        , endDate = required('End date')(entity.endDate)
        , type = required('Recurrence frequency')(entity.type)
        , dateRange = checkDateRangeValidity(date, entity.endDate, `End date should be after ${timeService.formatUtcDate(date)}` )
        , weekRecurrenceDateRange = entity.type === ft.WEEK && !recurrenceFrequency && validateWeekRecurrenceDateRange(entity, date, timeService)
        , weekRecurrenceDays = entity.type === ft.WEEK && (!entity.recurOnDays || entity.recurOnDays.length === 0 )
            ? { recurOnDays: 'Day is required'}
            : undefined
        , monthRecurrence = entity.type === ft.MONTH && !recurrenceFrequency && validateMonthRecurrenceDateRange(entity, date, timeService)
        , result: ValidationResult<RecurrenceForm> = {}

    if (recurrenceFrequency)
        result.recurrenceFrequency = recurrenceFrequency

    if (type)
        result.type = type

    if (endDate)
        result.endDate = endDate

    if (dateRange)
        result.endDate = dateRange

    if (weekRecurrenceDateRange)
        result.endDate = weekRecurrenceDateRange

    return { ...weekRecurrenceDays, ...monthRecurrence, ...result }
}

function validateWeekRecurrenceDateRange(entity: Partial<WeekRecurrence>, startDate: DateTime, timeService: TimeService) {
    if (!entity.endDate || !entity.recurOnDays || entity.recurOnDays.length === 0 || !entity.recurrenceFrequency)
        return

    const startDateWeekDay = timeService.utcWeekDay(startDate)
        , firstWeekMondayDate = timeService.utcWeekStart(startDate)
        , seriesMondays = includedMondays(entity.endDate, entity.recurrenceFrequency, firstWeekMondayDate)
        , availableSeriesDate = availableWeekDays(startDate, entity.endDate, seriesMondays)

    function includedMondays(endDate: DateTime, frequency: number, firstWeekMondayDate: DateTime) {
        const result: DateTime[] = []
        let currentDate = timeService.addUtcDays(firstWeekMondayDate, 7 * frequency)

        while (greaterThanOrEqual(endDate, currentDate)) {
            result.push(currentDate)
            currentDate = timeService.addUtcDays(currentDate, 7 * frequency)
        }
        return result
    }

    function availableWeekDays(startDate: DateTime, endDate: DateTime, seriesMondays: DateTime[]) {
        const endWeekOffset = 7 - startDateWeekDay
            , result: DateTime[] = generateFuturePeriod(timeService, startDate, endWeekOffset)

        seriesMondays.forEach(_ => result.push(...generateFuturePeriod(timeService, _, 7)))

        return result
            .filter(_ => greaterThanOrEqual(endDate, _))
            .map(timeService.utcWeekDay)
    }

    return entity.recurOnDays.some(_ => availableSeriesDate.find(d => d === _) !== undefined)
        ? undefined
        : 'Series cannot start after End date'
}

function validateRecurrenceFrequency(frequency: number | undefined) {
    const [ minValue, maxValue ] = [ 1, 1000 ]
        , isInvalid = !Number.isInteger(frequency)
                    || !isValidNumberInRange(frequency, minValue, maxValue)
    return isInvalid
        ? `Must be integer between ${minValue} and ${maxValue}`
        : undefined
}

function validateMonthRecurrenceDateRange(entity: Partial<MonthRecurrence>, startDate: DateTime, timeService: TimeService) {
    const {day, month, year} = timeService.utcTimeStruct(startDate)
        , currentMonth = timeService.utc(year, month, 1)
        , firstRecurrenceMonth = getFirstRecurrenceMonth(startDate, entity.recurrenceFrequency!, timeService)
        , firstRecurrenceDaysOffset = getRecurrenceDaysOffset(firstRecurrenceMonth, timeService)

    if (entity.recurOnType === MONTH_RECURRENCE_ON_WEEK_DAY) {
        const weekNumber = required('Week number')(entity.weekNumber) || undefined
            , weekDayId = required('Day')(entity.weekDayId) || undefined

        if (weekNumber || weekDayId)
            return { weekNumber, weekDayId }

        if (!entity.endDate || entity.weekNumber === undefined || entity.weekDayId === undefined)
            return { endDate: undefined }

        const firstRecurrenceWeekDayOffset = getRecurrenceWeekDaysOffset(firstRecurrenceMonth, firstRecurrenceDaysOffset, entity.weekNumber, entity.weekDayId, timeService)
            , currentMonthWeekDayOffset = getRecurrenceWeekDaysOffset(currentMonth, getRecurrenceDaysOffset(currentMonth, timeService), entity.weekNumber, entity.weekDayId, timeService)
            , isFirstDateInCurrentMonth = day <= currentMonthWeekDayOffset + 1
            , month = isFirstDateInCurrentMonth ? currentMonth : firstRecurrenceMonth
            , dayOffset = isFirstDateInCurrentMonth ? currentMonthWeekDayOffset : firstRecurrenceWeekDayOffset
            , firstRecurrenceDate = timeService.addUtcDays(month, dayOffset)

        return { endDate: greaterThan(firstRecurrenceDate, entity.endDate) ? 'Series cannot start after End date' : undefined }
    }

    if (entity.recurOnType === MONTH_RECURRENCE_ON_DATE) {
        const dayNumber = required('Day')(entity.dayNumber) || undefined

        if (dayNumber)
            return { dayNumber }

        if (!entity.endDate || entity.dayNumber === undefined)
            return { endDate: undefined }

        const dayOffset = entity.dayNumber === 0 ? timeService.daysInMonth(year, month) : entity.dayNumber
            , firstMonth = day <= dayOffset ? currentMonth : firstRecurrenceMonth
            , firstRecurrenceDate = timeService.addUtcDays(firstMonth, dayOffset - 1)

        return { endDate: greaterThan(firstRecurrenceDate, entity.endDate) ? 'Series cannot start after End date' : undefined }
    }
}

function getFirstRecurrenceMonth(date: DateTime, recurrenceFrequency: number, timeService: TimeService) {
    const recurrenceMonth = timeService.addUtcMonths(date, recurrenceFrequency)
        , { year, month } = timeService.utcTimeStruct(recurrenceMonth)

    return timeService.utc(year, month, 1)
}

function getRecurrenceDaysOffset(date: DateTime | undefined, timeService: TimeService) {
    if (!date)
        return 0

    const { year, month } = timeService.utcTimeStruct(date)

    return timeService.daysInMonth(year, month) - 1
}

function getRecurrenceWeekDaysOffset(date: DateTime, daysOffset: number, weekNumber: number, weekDayId: DayId, timeService: TimeService) {
    const weeksInMonth = weekNumber === 0
        ? timeService.weeksDifference(date, timeService.addUtcDays(date, daysOffset)) - 1
        : weekNumber - 1

    return (7 + weekDayId - timeService.utcWeekDay(date)) % 7 + 7 * weeksInMonth
}
