import { classnames, useState, useRef, useAction, useSelector } from '_/facade/react'
import type { FormRenderProps } from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'

import type { SampleSearchEditFields } from '_/model/sample/search'
import type SampleSearchFields from '_/model/sample/search'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import SEARCH_DATE_TYPE, { SAMPLE_BREACH_DATE } from '_/constants/search-date-type'
import { SAMPLE_BREACH_TYPE } from '_/constants/sample-breach-type'
import * as fieldIndex from '_/constants/custom-field-index'
import * as fieldTypes from '_/constants/custom-field-column-type'
import * as tiers from '_/constants/tiers'
import { ANY, ANY_ID } from '_/constants/system-words'

import * as a from '_/features/samples/actions'
import { validate } from '_/features/samples/filter/validate'

import { LocalDateField, TextField, FilterMultiSelectField, SelectField, CheckboxField, TextTimeField, NumericRangeField, submitDisabled } from '_/components/form'
import Button, { Close } from '_/components/button'
import FilterActions from '_/components/filter-actions'
import { useTimeService } from '_/components/time'
import { useDebounce } from '_/hooks/shared-hooks'

import AddSearchFilterModal from './add-search-filter-modal'
import FilterListModal from '_/components/filter-list-modal'
import SampleStatusInput from './sample-status-input'
import MultipleOrganismSearch from '_/features/predefined-lists/organism-identification/multiple-organism-search'
import MultipleSearchByFieldValue from '_/features/predefined-lists/custom-fields/custom-field-search'

import * as toastActions from '_/features/toasts/actions'

import { diffObject, dropFields } from '_/utils/object'
import { formatEntity } from '_/utils/format/common'
import { memoize } from '_/utils/function'
import { isDefaultCustomField, getEntitiesForFilter } from '_/model/predefined-lists/custom-field/custom-field'
import MONITORING_STATE from '_/model/predefined-lists/action-alert-limit/monitoring-state'

import { formatFieldLabel, getFieldValue, findNotRecordedFilledValues, valuePath, getFieldNotRecorded } from '../helpers'
import { CHANGES_SAVED } from '../messages'
import MultipleTrendInvestigationRefSearch from '_/features/trends/trend-investigation-ref-search'
import MultipleTrendIdSearch from '_/features/trends/trend-id-search'
import BatchNumberField from '../shared/batch-number-field'

interface Props {
    onSearch(searchFields: SampleSearchFields): void
    onSearchTemplateLoaded(template: SampleSearchFields): void
    initialFilter: SampleSearchFields
    showFilter: boolean
    onClose: () => void
    onClearFilter: () => void
    fields: CustomField[]
}

function SamplesFilter(props: Props) {
    const timeService = useTimeService()
        , [showAddFilterModal, setShowAddFilterModal, handleAddFilter] = useAddSearchFilterModal()
        , [showFilterListModal, setShowFilterListModal] = useState(false)
        , predefinedLists = useSelector(_ => _.predefinedLists)
        , sampleSearchFilters = useSelector(_ => _.samples.sampleSearchFilters)
        , getInitialFilter = useGetInitialFilter()
        , previousFilter = usePreviousFilter(getInitialFilter(props.initialFilter, props.fields))
        , handleSubmitDebounce = useDebounce()
        , membership = useSelector(_ => _.auth.user?.membership)
        , context = useSelector(_ => _.contexts.contexts.find(ctx => ctx.id === membership?.contextId))
        , atLeastInsight = context && context.tier > tiers.COMPLIANCE

    function handleApplyFilter(filterId: string) {
        const filter = sampleSearchFilters.filter(_ => _.id === filterId)[0].filter
        props.onSearchTemplateLoaded(filter)
    }

    function handleClear() {
        props.onClearFilter()
    }

    function handleChange(newFilter: Partial<SampleSearchEditFields>, form: FormRenderProps) {
        const notRecordedValuesToClear = findNotRecordedFilledValues(newFilter.fields ?? [])

        notRecordedValuesToClear.forEach(_ => form.form.change(valuePath(_), undefined))

        const previousFilterValue = previousFilter.current
        previousFilter.current = newFilter

        if (diffObject(previousFilterValue, newFilter)) {
            handleSubmitDebounce(() => form.form.submit())
        }
    }

    function handleSubmit(filter: SampleSearchEditFields) {
        const isSampleBreachDate = filter.dateToFilter === SAMPLE_BREACH_DATE
            , dateFrom = isSampleBreachDate ? timeService.combineCtzDateTime(filter.dateFrom, filter.timeFrom) : filter.dateFrom
            , dateTo = isSampleBreachDate ? timeService.combineCtzDateTime(filter.dateTo, filter.timeTo) : filter.dateTo

        props.onSearch({
            ...dropFields(filter, 'dateTo', 'timeTo', 'dateFrom', 'timeFrom'),
            dateFrom,
            dateTo,
        })
    }

    function buildField(pos: number, fields: CustomField[], form: FormRenderProps): React.ReactNode {
        const customField = fields[pos]
            , fieldId = `${'field-' + customField.index}`
            , namePrefix = `fields[${pos}]`
            , valueName = `${namePrefix}.value`
            , notRecordedName = `${namePrefix}.notRecorded`
            , notRecordedState = form.form.getFieldState(notRecordedName as any)
            , isNotRecorded = !!(notRecordedState && notRecordedState.value)
            , label = customField.fieldName
            , testId = `field-${customField.index}` //added for selenium tests, do not change

        if (customField.index === fieldIndex.EXPOSURE_END_DATE)
            return null

        if (customField.index === fieldIndex.EXPOSURE_LOCATION_ID) {
            return (
                <div key={namePrefix}>
                    <FilterMultiSelectField
                        id={fieldId}
                        name={valueName}
                        entities={getEntitiesForFilter(customField.index, predefinedLists)}
                        calcId={_ => _.id}
                        calcName={_ => formatEntity(_, _ => _.pathName)}
                        autocomplete
                        testId={testId}
                    >
                        {label}
                    </FilterMultiSelectField>
                </div>
            )
        }

        if (customField.index === fieldIndex.OPERATORS_IDS) {
            return (
                <div key={namePrefix}>
                    <FilterMultiSelectField
                        id={fieldId}
                        name={valueName}
                        entities={getEntitiesForFilter(customField.index, predefinedLists)}
                        calcId={_ => _.id}
                        calcName={formatEntity}
                        autocomplete
                        anyOption={({ id: ANY_ID, name: ANY, isActive: true })}
                        testId={testId}
                    >
                        {label}
                    </FilterMultiSelectField>
                </div>
            )
        }

        if (customField.index === fieldIndex.BATCH_NUMBER)
            return (
                <BatchNumberField
                    id={fieldId}
                    name={valueName}
                    className='flex-fill'
                    editing={false}
                    hasLabel
                    isViable
                    key={namePrefix}
                />
            )

        switch (customField.fieldType) {
            case fieldTypes.TEXT: {
                return <div key={namePrefix}>
                    {isDefaultCustomField(customField)
                        ? <TextField
                            id={fieldId}
                            name={`${namePrefix}.value`}
                            className='flex-fill'
                            disabled={isNotRecorded}
                            testId={testId}
                        >
                            {label}
                        </TextField>
                        : <MultipleSearchByFieldValue
                            id={fieldId}
                            name={valueName}
                            className='flex-fill'
                            customField={customField}
                            testId={testId}
                        />
                    }
                </div>
            }
            case fieldTypes.SELECTION: {
                return <div key={namePrefix}>
                    <FilterMultiSelectField
                        id={fieldId}
                        name={valueName}
                        entities={getEntitiesForFilter(customField.index, predefinedLists)}
                        calcId={_ => _.id}
                        calcName={formatEntity}
                        autocomplete
                        testId={testId}
                    >
                        {label}
                    </FilterMultiSelectField>
                </div>
            }
            case fieldTypes.NUMBER: {
                return <div key={namePrefix} className='mb-3'>
                    <NumericRangeField
                        id={fieldId}
                        name={valueName}
                        label={formatFieldLabel(customField)}
                        className='mb-0'
                        disabled={isNotRecorded}
                        testId={testId}
                    />
                    <CheckboxField
                        id={notRecordedName}
                        name={notRecordedName}
                        className='mx-1'
                    >
                        Not recorded
                    </CheckboxField>
                </div>
            }
            default: {
                return null
            }
        }
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={getInitialFilter(props.initialFilter, props.fields)}
            validate={_ => validate(_, timeService, props.fields)}
            render={form =>
                <div className={classnames('position-absolute filters-popup d-flex flex-column side-filters', { 'filters-popup-hide': !props.showFilter }, 'd-print-none')}>
                    <nav className='navbar mx-0'>
                        <span className='navbar-brand me-auto filters-header-text'>Filters</span>
                        {sampleSearchFilters.length > 0 &&
                            <Button className='btn-link me-1' onClick={() => setShowFilterListModal(true)} testId='load-filter'>Load filter</Button>
                        }
                        <Close onClick={props.onClose} testId='close-sample-filters' />
                    </nav>

                    <form className='mb-2 overflow-y-auto flex-fill' onSubmit={form.handleSubmit}>
                        <FormSpy
                            onChange={_ => handleChange(_.values as Partial<SampleSearchFields>, form)}
                            subscription={{ values: true }}
                        />
                        <div className='px-3'>
                            <SampleStatusInput flag={props.initialFilter.flag}/>
                            {props.fields.map((_, i) => isDefaultCustomField(_) && buildField(i, props.fields, form))}
                            <TextField name='sampleInvestigationReferences' id='sampleInvestigationReferences' testId='field-investigation-reference'>Viable sample investigation refs</TextField>
                            <MultipleOrganismSearch
                                id='organismIds'
                                name='organismIds'
                                testId='field-organisms'
                            />
                            <FilterMultiSelectField
                                name='organismTypeIds'
                                id='organismTypeIds'
                                entities={predefinedLists.identifications.organismType}
                                calcId={_ => _.id}
                                calcName={formatEntity}
                                autocomplete
                                anyOption={({ id: ANY_ID, name: ANY })}
                                testId='field-organism-type'
                            >
                                Organism type
                            </FilterMultiSelectField>
                            <FilterMultiSelectField
                                name='catalaseIds'
                                id='catalaseIds'
                                entities={predefinedLists.identifications.catalase}
                                calcId={_ => _.id}
                                calcName={formatEntity}
                                autocomplete
                                anyOption={({ id: ANY_ID, name: ANY })}
                                testId='field-catalase'
                            >
                                Catalase
                            </FilterMultiSelectField>
                            <FilterMultiSelectField
                                name='oxidaseIds'
                                id='oxidaseIds'
                                entities={predefinedLists.identifications.oxidase}
                                calcId={_ => _.id}
                                calcName={formatEntity}
                                autocomplete
                                anyOption={({ id: ANY_ID, name: ANY })}
                                testId='field-oxidase'
                            >
                                Oxidase
                            </FilterMultiSelectField>
                            <FilterMultiSelectField
                                name='oxidationFermentationIds'
                                id='oxidationFermentationIds'
                                entities={predefinedLists.identifications.oxidationFermentation}
                                calcId={_ => _.id}
                                calcName={formatEntity}
                                autocomplete
                                anyOption={({ id: ANY_ID, name: ANY })}
                                testId='field-oxidation-fermentation'
                            >
                                Oxidation fermentation
                            </FilterMultiSelectField>
                            <FilterMultiSelectField
                                name='coagulaseIds'
                                id='coagulaseIds'
                                entities={predefinedLists.identifications.coagulase}
                                calcId={_ => _.id}
                                calcName={formatEntity}
                                autocomplete
                                anyOption={({ id: ANY_ID, name: ANY })}
                                testId='field-coagulase'
                            >
                                Coagulase
                            </FilterMultiSelectField>
                            <CheckboxField
                                id='showOnlyObjectionable'
                                name='showOnlyObjectionable'
                                className='mb-2'
                                testId='field-show-only-objectionable'
                            >
                                Show only objectionable
                            </CheckboxField>
                            <FilterMultiSelectField
                                name='gradeIds'
                                id='gradeIds'
                                entities={predefinedLists.grades}
                                calcId={_ => _.id}
                                calcName={formatEntity}
                                autocomplete
                                anyOption={({ id: ANY_ID, name: ANY, isActive: true })}
                                testId={`field-${fieldIndex.EXPOSURE_LOCATION_GRADE_ID}`}
                            >
                                Grade
                            </FilterMultiSelectField>
                            <FilterMultiSelectField
                                name='sampleBreachTypes'
                                id='sampleBreachTypes'
                                entities={SAMPLE_BREACH_TYPE}
                                calcId={_ => _.id}
                                calcName={_ => _.name}
                                autocomplete
                                testId='field-sample-breach-type'
                            >
                                Sample breach type
                            </FilterMultiSelectField>
                            <FilterMultiSelectField
                                id='monitoringStates'
                                name='monitoringStates'
                                entities={MONITORING_STATE}
                                calcId={_ => _.id}
                                calcName={_ => _.name}
                                autocomplete
                                testId={`field-${fieldIndex.MONITORING_STATE}`}
                            >
                                Monitoring state
                            </FilterMultiSelectField>
                            {props.fields.map((_, i) => !isDefaultCustomField(_) && buildField(i, props.fields, form))}
                            {atLeastInsight &&
                                <>
                                    <MultipleTrendInvestigationRefSearch
                                        id='trend-investigation-ref'
                                        name='trendInvestigationReferences'
                                    />

                                    <MultipleTrendIdSearch
                                        id='trend-id'
                                        name='trendIds'
                                    />
                                </>
                            }
                            <CheckboxField
                                id='include-compromised'
                                name='includeCompromised'
                                className='mb-2'
                                testId='field-include-compromised'
                            >
                                Include compromised
                            </CheckboxField>
                            <SelectField
                                name='dateToFilter'
                                id='dateToFilter'
                                entities={SEARCH_DATE_TYPE}
                                calcId={_ => _.id}
                                calcName={_ => _.name}
                                placeholder='Select'
                                testId='field-date-to'
                            >
                                Filter date
                            </SelectField>
                            {form.values.dateToFilter !== SAMPLE_BREACH_DATE &&
                                <div>
                                    <LocalDateField id='fromDate' name='dateFrom' testId='field-from-date'>From</LocalDateField>
                                    <LocalDateField id='toDate' name='dateTo' useDayEndTime testId='field-to-date'>To</LocalDateField>
                                </div>
                            }
                            {form.values.dateToFilter === SAMPLE_BREACH_DATE &&
                                <div>
                                    <LocalDateField id='fromDate' name='dateFrom' testId='field-from-date'>From date</LocalDateField>
                                    <TextTimeField id='fromTime' name='timeFrom' className='mx-1'>From time</TextTimeField>

                                    <LocalDateField id='toDate' name='dateTo' useDayEndTime testId='field-to-date'>To date</LocalDateField>
                                    <TextTimeField id='toTime' name='timeTo' className='mx-1'>To time</TextTimeField>
                                </div>
                            }
                        </div>

                        <AddSearchFilterModal
                            isOpen={showAddFilterModal}
                            onSave={_ => handleAddFilter(_, form.values)}
                            onCancel={() => setShowAddFilterModal(false)}
                            sampleSearchFilters={sampleSearchFilters}
                        />
                        <FilterListModal
                            isOpen={showFilterListModal}
                            onClose={() => setShowFilterListModal(false)}
                            onApplyFilter={handleApplyFilter}
                            loadFiltersAction={a.loadSampleSearchFilterList}
                            removeFilterAction={a.removeSampleSearchFilter}
                        />

                        <div className='side-filters__spacer' />

                        <FilterActions className='d-flex side-filters__actions'>
                            <Button
                                className='btn-link me-auto ps-0'
                                onClick={() => setShowAddFilterModal(true)}
                                disabled={submitDisabled(form) && form.dirty}
                                testId='save-filter'
                            >
                                Save filter
                            </Button>
                            <Button className='btn-link' onClick={handleClear} testId='sample-filter-clear'>Clear</Button>
                        </FilterActions>
                    </form>
                </div>
            }
        />
    )
}

export default SamplesFilter

function usePreviousFilter(initialFilter: SampleSearchFields) {
    const previousFilterRef = useRef<Partial<SampleSearchEditFields>>(initialFilter)
        , initialFilterRef = useRef(initialFilter)

    if (initialFilterRef.current !== initialFilter) {
        previousFilterRef.current = initialFilter
        initialFilterRef.current = initialFilter
    }

    return previousFilterRef
}

function useAddSearchFilterModal() {
    const [showAddFilterModal, setShowAddFilterModal] = useState(false)
        , createSampleSearchFilter = useAction(a.createSampleSearchFilter)
        , addSuccess = useAction(toastActions.addSuccess)

    function handleAddFilter(name: string, filter: SampleSearchFields) {
        setShowAddFilterModal(false)

        createSampleSearchFilter({ name, filter })
            .then(() => addSuccess(CHANGES_SAVED))
    }

    return [showAddFilterModal, setShowAddFilterModal, handleAddFilter] as const
}

function useGetInitialFilter() {
    const timeService = useTimeService()
        , [getInitialFilter] = useState(() => memoize(
            function getInitialFilter(initialFilter: SampleSearchFields, fields: CustomField[]): SampleSearchEditFields {
                const fromDateTime = timeService.splitCtzDateTime(initialFilter.dateFrom)
                    , toDateTime = timeService.splitCtzDateTime(initialFilter.dateTo)
                    , isSampleBreachDate = initialFilter.dateToFilter === SAMPLE_BREACH_DATE

                return {
                    ...dropFields(initialFilter, 'dateFrom', 'dateTo'),
                    timeFrom: fromDateTime.time,
                    dateFrom: isSampleBreachDate ? fromDateTime.date : initialFilter.dateFrom,
                    timeTo: toDateTime.time,
                    dateTo: isSampleBreachDate ? toDateTime.date : initialFilter.dateTo,
                    fields: fields.map(_ => ({
                        index: _.index,
                        value: getFieldValue(initialFilter.fields, _.index),
                        notRecorded: getFieldNotRecorded(initialFilter.fields, _.index),
                    })),
                }
            }
        ))

    return getInitialFilter
}
