import Navbar from './navbar'

interface Props {
    children: React.ReactNode
}

function BlankPage(props: Props) {
    return (
        <div className='d-flex flex-column full-screen-height d-print-block'>
            <Navbar/>
            <div className='main-block' style={{ height: 0 }}>
                {props.children}
            </div>
        </div>
    )
}

export default BlankPage
