import type { FloorPlanLocation } from './floor-plan'
import type LimitBreachFloorPlanSeries from './limit-breach-floor-plan-series'
import type { Area } from './area'
import { overlapArea } from './area'

import { getLimitBreachFloorPlanArea } from './draw'

import { shallowUpdate } from '_/facade/reducer'
import { replace } from '_/utils/array'
import { checkInactiveLocation } from '_/features/analysis/helpers'
import type { ListExposureLocation } from '../predefined-lists/exposure-location/exposure-location'

interface LimitBreachSpotData {
    floorPlanLocation: FloorPlanLocation
    series: LimitBreachFloorPlanSeries | undefined
}

function adjustFloorPlanLocationPositions(spotData: LimitBreachSpotData[], selectedLocations: string[], ratio: number, allLocations: ListExposureLocation[]) {
    return innerAdjustFloorPlanLocationPosition(spotData, selectedLocations, ratio, 1, allLocations)
}

//recursive algorithm
//we start from the closest location to the top left corner
//calculate all overlappings with it and move all overlapping locations in bottom or right direction
//do the same for the next closest point to the left top corner. (It will not be overlapping with locations on previous stages)
function innerAdjustFloorPlanLocationPosition(spotData: LimitBreachSpotData[], selectedLocations: string[], ratio: number, stage: number, allLocations: ListExposureLocation[]): LimitBreachSpotData[] {
    if (stage === spotData.length || spotData.length === 0)
        return spotData

    let result = spotData

    const sortedByDistanceSpotData = sortByDistance(spotData)
        , currentSpot = sortedByDistanceSpotData[stage - 1].spotData
        , currentLocationInactive = checkInactiveLocation(currentSpot.floorPlanLocation, selectedLocations, allLocations)
        , currentArea = getLimitBreachFloorPlanArea(currentSpot.series, currentSpot.floorPlanLocation.position, currentLocationInactive, ratio)

    for (let i = stage; i < sortedByDistanceSpotData.length; i++) {
        const innerSpotData = sortedByDistanceSpotData[i].spotData
            , innerLocation = innerSpotData.floorPlanLocation
            , innerPosition = innerLocation.position
            , innerLocationInactive = checkInactiveLocation(innerLocation, selectedLocations, allLocations)
            , innerArea = getLimitBreachFloorPlanArea(innerSpotData.series, innerPosition, innerLocationInactive, ratio)
            , overlap = overlapArea(currentArea, innerArea)

        if (overlap) {
            const positionAlignment = getPositionAligment(currentArea, innerArea)
                , newPosition = {
                    x: innerPosition.x + positionAlignment.x,
                    y: innerPosition.y + positionAlignment.y,
                }

            result = replace(result,
                             _ => _.floorPlanLocation.locationId === innerLocation.locationId,
                             _ => shallowUpdate(innerSpotData, { floorPlanLocation: shallowUpdate(innerLocation, { position: newPosition }) })
            )
        }
    }

    return innerAdjustFloorPlanLocationPosition(result, selectedLocations, ratio, stage + 1, allLocations)
}

function sortByDistance(spotData: LimitBreachSpotData[]) {
    return spotData.map(s => ({ spotData: s, distance: distanceToCorner(s) })).sort((a, b) => a.distance - b.distance)
}

function distanceToCorner(spotData: LimitBreachSpotData) {
    const position = spotData.floorPlanLocation.position

    return Math.sqrt(Math.pow(position.x, 2) + Math.pow(position.y, 2))
}

function getPositionAligment(area1: Area, area2: Area) {
    const xDiff = Math.abs(area1.x + area1.width - area2.x)
        , yDiff = Math.abs(area1.y + area1.height - area2.y)

    if (yDiff > xDiff)
        return { x: xDiff, y: 0 }
    else
        return { x: 0, y: yDiff }
}

export {
    adjustFloorPlanLocationPositions,
}
