import { classnames } from '_/facade/react'
import { Link } from '_/utils/router'
import * as routes from '_/constants/routes'
import type { TaggedUserNotification as TaggedUserData } from '_/model/notification/types'
import { useTimeService } from '_/components/time'

interface Props {
    notification: TaggedUserData
    onClick: () => void
    dropdownLayout: boolean
    testId?: string
}

function TaggedUserNotification(props: Props) {
    const data = props.notification.data
        , timeService = useTimeService()

    function handleClick(e: React.MouseEvent) {
        e.preventDefault()
        props.onClick()
    }

    return (
        <div>
            <Link
                routeName={routes.SAMPLES_EDIT}
                routeParams={{ id: data.entityId }}
                className={classnames('cursor-pointer', { 'text-white': props.dropdownLayout })}
                onClick={handleClick}
                testId={`${props.testId}-link`}
            >
                <div className='fw-bold' data-testid={`${props.testId}-tagged-user-info`}>
                Comment by {data.commentedByUserEmail} at {timeService.formatCtzTime(data.commentedAt)} on {timeService.formatCtzDate(data.commentedAt)}
                </div>
            </Link>
            <div className='text-secondary' data-testid={`${props.testId}-context-info`}>{props.notification.contextName}</div>
        </div>
    )
}

export default TaggedUserNotification
