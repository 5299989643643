import { actions as routerActions } from 'redux-router5'
import { React, useAction, useSelector } from '_/facade/react'
import type TimeService from '_/services/time-service'
import { shallowUpdate } from '_/utils/object'

import type { ContaminationFloorPlanSeriesGraphData, LimitBreachFloorPlanSeriesGraphData } from '_/model/analysis/types'
import type { AnalysisFilter } from '_/model/analysis/filter/types'
import type { SampleSearchEditFields } from '_/model/sample/search'
import type { FloorPlanSubFilter } from '_/model/floor-plan/floor-plan'
import type FloorPlan from '_/model/floor-plan/floor-plan'

import * as s from '_/constants/sample-status'
import type { BreachTypeWithCompromised } from '_/constants/sample-breach-type'
import * as fieldIndex from '_/constants/custom-field-index'
import * as routes from '_/constants/routes'
import * as chartType from '_/model/analysis/chart-type'

import ContaminationFloorPlanChart from './contamination-floor-plan-chart'
import LimitBreachFloorPlanChart from './limit-breach-floor-plan-chart'
import { buildSampleListRouterParams, getSampleListRouteParamsForFloorPlanGraph as getSampleListRouteParams } from './helpers'
import { forwardRef } from '_/facade/react'
import { replace } from '_/utils/array'
import type { FloorPlanChartMetadata } from '_/model/analysis/chart-metadata'
import type { FloorPlanChartRef } from '_/model/floor-plan/export'
import type * as withRefFlag from '_/model/sample/filter/with-ref-flag'
import { paramsFilter } from '_/model/filters/helpers'
import { fullNameLocationList } from '_/utils/exposure-location'
import type { FloorPlanLocationPage } from '_/model/floor-plan/floor-plan-location-page'

interface OwnProps {
    filter: AnalysisFilter
    timeService: TimeService
    showActionButtons: boolean
    graphData: ContaminationFloorPlanSeriesGraphData | LimitBreachFloorPlanSeriesGraphData
    multipleGraphs?: boolean | undefined
    exportDisabled: boolean
    floorPlans: FloorPlan[]
    floorPlanSubFilter: FloorPlanSubFilter
    metadata: FloorPlanChartMetadata[]
    showTabularView?: boolean
    floorPlanLocationPage: FloorPlanLocationPage
    testId?: string
}

const AnalysisFloorPlanCharts = (props: OwnProps, ref: React.ForwardedRef<FloorPlanChartRef>) => {
    const { floorPlans, floorPlanSubFilter } = props
        , filter = {...props.filter, ...floorPlanSubFilter.value}
        , handleContaminationClickFloorPlanLocation = useClickContaminationFloorPlanLocationHandler(filter, props.timeService)
        , handleLimitBreachClickFloorPlanLocation = useClickLimitBreachFloorPlanLocationHandler(filter, props.timeService)
        , locations = fullNameLocationList(useSelector(_ => _.predefinedLists.exposureLocations))

    return (
        <div data-testid={props.testId}>
            {props.graphData.chartType === chartType.CONTAMINATION_FLOOR_PLAN &&
                <ContaminationFloorPlanChart
                    floorPlans={floorPlans}
                    timeService={props.timeService}
                    filter={filter}
                    series={props.graphData.series}
                    showActionButtons={props.showActionButtons}
                    multipleGraphs={props.multipleGraphs}
                    exportDisabled={props.exportDisabled}
                    sampleListRouterParams={getSampleListRouteParams(filter, props.timeService, s.READ_STATUS_IDS, floorPlans, locations)}
                    onClickFloorPlanLocation={handleContaminationClickFloorPlanLocation}
                    floorPlanSubFilter={props.floorPlanSubFilter}
                    metadata={props.metadata}
                    ref={ref}
                    floorPlanLocationPage={props.floorPlanLocationPage}
                    showTabularView={props.showTabularView}
                />
            }
            {props.graphData.chartType === chartType.LIMIT_BREACH_FLOOR_PLAN &&
                <LimitBreachFloorPlanChart
                    floorPlans={floorPlans}
                    timeService={props.timeService}
                    filter={filter}
                    series={props.graphData.series}
                    showActionButtons={props.showActionButtons}
                    multipleGraphs={props.multipleGraphs}
                    exportDisabled={props.exportDisabled}
                    sampleListRouterParams={getSampleListRouteParams(filter, props.timeService, s.DEFAULT_STATUS_IDS, floorPlans, locations)}
                    onClickFloorPlanLocation={handleLimitBreachClickFloorPlanLocation}
                    floorPlanSubFilter={props.floorPlanSubFilter}
                    metadata={props.metadata}
                    ref={ref}
                    floorPlanLocationPage={props.floorPlanLocationPage}
                    showTabularView={props.showTabularView}
                />
            }
        </div>
    )
}

function useClickContaminationFloorPlanLocationHandler(filter: AnalysisFilter, timeService: TimeService) {
    const navigateTo = useNavigateTo()

    function handleClickFloorPlanLocation(locationId: string) {
        const fields = filter.fields && replace(filter.fields,
                                                _ => _.index === fieldIndex.EXPOSURE_LOCATION_ID,
                                                _ => shallowUpdate(_, { value: [locationId]}))
            , newQuery = shallowUpdate(buildSampleListRouterParams(filter, timeService, s.READ_STATUS_IDS), { fields })

        navigateTo(newQuery)
    }

    return handleClickFloorPlanLocation
}

function useClickLimitBreachFloorPlanLocationHandler(filter: AnalysisFilter, timeService: TimeService) {
    const navigateTo = useNavigateTo()

    function handleClickFloorPlanLocation(locationId: string, breachType: BreachTypeWithCompromised | undefined, sampleInvestigationReferenceFlag: withRefFlag.WithRefFlag) {
        const fields = filter.fields && replace(filter.fields,
                                                _ => _.index === fieldIndex.EXPOSURE_LOCATION_ID,
                                                _ => shallowUpdate(_, { value: [locationId]}))
            , newQuery = shallowUpdate(buildSampleListRouterParams(filter, timeService, s.DEFAULT_STATUS_IDS), { fields, sampleBreachTypes: breachType ? [breachType] : [], sampleInvestigationReferenceFlag })

        navigateTo(newQuery)
    }

    return handleClickFloorPlanLocation
}

function useNavigateTo() {
    const navigateToAction = useAction(routerActions.navigateTo)

    return (_: SampleSearchEditFields) => navigateToAction(routes.SAMPLES, paramsFilter(_))
}

export default forwardRef(AnalysisFloorPlanCharts)
