import { classnames } from '_/facade/react'

import Button from '_/components/button'
import { LinkButton } from '_/components/link'

import type SampleSearchFields from '_/model/sample/search'

import { SAMPLES } from '_/constants/routes'
import { paramsFilter } from '_/model/filters/helpers'

interface OwnProps {
    sampleListRouterParams: SampleSearchFields
    onExport: () => void
    disabledGoToSamplesButton: boolean
    multipleGraphs?: boolean | undefined
    exportButtonDisabled: boolean
    showAsPrimaryButtons?: boolean
}

const ActionButtons: React.FC<OwnProps> = props =>
    <div>
        {!props.multipleGraphs && <div className='analysis__spacer' />}
        <div className={classnames('d-flex bg-white justify-content-end py-2 px-3', { analysis__actions: !props.multipleGraphs })}>
            <LinkButton
                className={`${props.multipleGraphs && !props.showAsPrimaryButtons ? 'btn-link mb-5' : 'btn-primary'} me-1`}
                routeName={SAMPLES}
                routeParams={paramsFilter(props.sampleListRouterParams)}
                disabled={props.disabledGoToSamplesButton}
                testId='go-to-samples'
            >
                Go to viable samples
            </LinkButton>
            <Button
                className={classnames(`${props.multipleGraphs && !props.showAsPrimaryButtons ? 'mb-5 btn-link' :  'btn-primary'}`, { disabled: props.exportButtonDisabled })}
                onClick={props.onExport}
                disabled={props.exportButtonDisabled}
                hasNoPermissions={props.exportButtonDisabled}
                testId='export'
            >
                Export
            </Button>
        </div>
    </div>

export default ActionButtons
