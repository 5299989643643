import { classnames, useState, useEffect, useAction, useSelector } from '_/facade/react'
import type { FieldRenderProps, FormRenderProps } from 'react-final-form'
import { Form, Field } from 'react-final-form'

import Button, { Close } from '_/components/button'
import AuditTrails from '_/components/audit-trail-list'
import { resetForm, Submit, submitDisabled } from '_/components/form'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import TabNavbar from '_/components/tab-navbar'

import * as tabs from '_/constants/modal-tab'
import type { FormExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'

import * as confirmationActions from '_/features/confirmation/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import * as deletionActions from '_/features/confirmation/actions'
import * as routerActions from '_/features/routing/actions'
import * as actions from '../actions'

import * as tree from '_/utils/tree'

import validate from '_/model/predefined-lists/exposure-location/validate'
import ExposureLocationData from './exposure-location-data'
import { SETTINGS_EXPOSURE_LOCATIONS } from '_/constants/routes'
import type { FormApi } from 'final-form'
import type { AuditTrail } from '_/model/audit-trail/types'

interface Props {
    onLocationCreated: (parentId: string) => void
}

function ExposureLocationEditDialog(props: Props) {
    const { id, parentId } = useSelector(_ => _.router.route!.params)
        , exposureLocations = useSelector(_ => _.predefinedLists.exposureLocations)
        , locations = tree.list(exposureLocations)
        , parentLocation = locations.find(_ => _.id === parentId)
        , nestingLevel = parentLocation ? tree.nestingLevel(exposureLocations, parentLocation) + 1 : 0
        , hasActiveChildren = locations.some(it => it.parentId === id && it.isActive)
        , [exposureLocation, locationTrail, inUse, hasLinkedSamples] = useExposureLocation(id)
        , [activePage, setActivePage] = useState<tabs.Tab>(tabs.DATA)
        , navigateTo = useAction(routerActions.navigateTo)
        , onClose = () => navigateTo(SETTINGS_EXPOSURE_LOCATIONS)
        , handleSubmit = useSubmit(id, exposureLocation, onClose, props.onLocationCreated)
        , handleChangeLocationStatus = useChangeLocationStatus(hasActiveChildren, parentId)
        , handleOpenDeletionModal = useOpenDeletionModal(id, onClose)

    function handleChange(location: FormExposureLocation, form: FormRenderProps) {
        if (location.ungraded)
            form.form.change('gradeId', undefined)
    }

    function ungradedDisabled() {
        const parentLocation = locations.find(_ => _.id === parentId)

        return hasLinkedSamples
            || parentLocation?.gradeId !== undefined
            || locations.some(_ => _.parentId === id && _.isUngraded)
    }

    function usedNames(parentId: string, locations: ExposureLocation[]): string[] {
        if (id)
            return locations
                .filter(_ => _.id !== id && _.parentId === parentId)
                .map(_ => _.name)

        return locations
            .filter(_ => _.parentId === parentId)
            .map(_ => _.name)
    }

    return (
        <Modal isOpen onClose={onClose}>
            <Form<FormExposureLocation>
                onSubmit={(values, form) => handleSubmit({...values, parentId}, form)}
                validate={_ => validate(_, usedNames(parentId, locations))}
                initialValues={exposureLocation}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form} onChange={handleChange}/>

                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4 data-testid='location-title'>{id ? 'Edit exposure location' : 'New exposure location'}</h4>
                                    <Close onClick={onClose} testId='close-location-modal'/>
                                </div>
                                <TabNavbar>
                                    <Button
                                        onClick={() => setActivePage(tabs.DATA)}
                                        className={classnames('btn-link navbar-tab me-4', { active: activePage === tabs.DATA })}
                                    >
                                        Exposure location data
                                    </Button>
                                    <Button
                                        onClick={() => setActivePage(tabs.AUDIT_TRAIL)}
                                        className={classnames('btn-link navbar-tab', { active: activePage === tabs.AUDIT_TRAIL })}
                                        disabled={!id}
                                    >
                                        Audit trail
                                    </Button>
                                </TabNavbar>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='predefined-list-modal-body-height'>
                                {activePage === tabs.DATA &&
                                    <ExposureLocationData
                                        hasChildren={hasActiveChildren}
                                        nestingLevel={nestingLevel}
                                        gradeDisabled={form.values.ungraded}
                                        ungradedDisabled={ungradedDisabled()}
                                    />
                                }
                                {activePage === tabs.AUDIT_TRAIL && <AuditTrails trail={locationTrail} /> }
                            </div>
                        </ModalBody>
                        <ModalFooter className='border-top-0 modal-footer-height'>
                            {activePage !== tabs.AUDIT_TRAIL &&
                                <div className={`d-flex flex-fill ${exposureLocation ? 'justify-content-between' : 'justify-content-end'}`}>
                                    {exposureLocation &&
                                        <div>
                                            <Button
                                                disabled={inUse || exposureLocation.parentId === tree.VOID_ID}
                                                className='text-danger bg-transparent border-0 me-2'
                                                onClick={() => handleOpenDeletionModal(exposureLocation)}
                                            >
                                                Remove
                                            </Button>
                                            <Field name='isActive' render={_ =>
                                                <Button
                                                    className='bg-transparent border-0 me-2'
                                                    onClick={() => handleChangeLocationStatus(_, form.values.isActive) }
                                                    disabled={!inUse && exposureLocation.isActive || exposureLocation.parentId === tree.VOID_ID}
                                                    testId='toggle-active-state'
                                                >
                                                    {form.values.isActive ? 'Mark as inactive' : 'Mark as active' }
                                                </Button>
                                            }/>
                                        </div>
                                    }
                                    <div>
                                        <Button className='btn-secondary me-2' onClick={onClose}>Cancel</Button>
                                        <Submit disabled={submitDisabled(form)} testId='location-save'>Save</Submit>
                                    </div>
                                </div>
                            }
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default ExposureLocationEditDialog

function useExposureLocation(id: string | undefined) {
    const [location, setLocation] = useState<FormExposureLocation>()
        , [inUse, setInUse] = useState(false)
        , [hasLinkedSamples, setHasLinkedSamples] = useState(false)
        , [trail, setTrail] = useState<AuditTrail>()
        , loadExposureLocation = useAction(actions.loadExposureLocation)
        , loadExposureLocationTrail = useAction(actions.loadExposureLocationTrail)

    useEffect(
        () => {
            if (!id)
                return

            loadExposureLocationTrail(id).then(setTrail)
            loadExposureLocation(id)
                .then(_ => {
                    setLocation({
                        parentId: _.parentId,
                        name: _.name,
                        gradeId: _.gradeId,
                        locationType: _.locationType,
                        isActive: _.isActive,
                        ungraded: !_.gradeId,
                    })
                    setInUse(_.inUse)
                    setHasLinkedSamples(_.hasLinkedSamples)
                })
        },
        [id, loadExposureLocation, loadExposureLocationTrail]
    )

    return [location, trail, inUse, hasLinkedSamples] as const
}

function useSubmit(
    id: string,
    oldExposureLocation: FormExposureLocation | undefined,
    onClose: () => void,
    onLocationCreated: (parentId: string) => void
) {
    const save = useAction(actions.saveExposureLocation)
        , create = useAction(actions.createExposureLocation)

    function handleSubmit(exposureLocation: FormExposureLocation, form: FormApi<FormExposureLocation>) {
        const promise = id && oldExposureLocation
            ? save({ id, oldExposureLocation, newExposureLocation: exposureLocation })
            : create(exposureLocation)
                .then(() => onLocationCreated(exposureLocation.parentId))

        return promise
            .then(() => resetForm(form, !id))
            .then(onClose)
    }

    return handleSubmit
}

function useChangeLocationStatus(hasActiveChildren: boolean, parentId: string) {
    const showConfirmationWarning = useAction(confirmationActions.showConfirmationModal)

    function handleChangeLocationStatus(field: FieldRenderProps<HTMLButtonElement>, isActive: boolean) {
        let message

        if (hasActiveChildren && isActive)
            message = 'Are you sure you want to mark location as Inactive? All child locations will also be marked as Inactive'

        if (!isActive && parentId !== tree.VOID_ID)
            message = 'Are you sure you want to mark location as Active? All parent locations will also be marked as Active'

        const promise = message ? showConfirmationWarning(message) : Promise.resolve()

        promise.then(() => field.input.onChange(!isActive))
    }

    return handleChangeLocationStatus
}

function useOpenDeletionModal(id: string, onClose: () => void) {
    const removeExposureLocation = useAction(actions.removeExposureLocation)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , showDeletionWarning = useAction(deletionActions.showDeletionConfirmationModal)

    function handleRemove() {
        return removeExposureLocation(id)
            .then(() => hasUnsavedChanges(false))
            .then(onClose)
    }

    function handleOpenDeletionModal(exposureLocation: FormExposureLocation) {
        showDeletionWarning(`Are you sure you want to delete ${exposureLocation.name}?`)
            .then(handleRemove)
    }

    return handleOpenDeletionModal
}
