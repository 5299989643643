import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type ExportService from '_/services/export-service'
import type NonViableSampleService from '_/services/non-viable-sample-service'

import * as t from '_/model/non-viable-sample/edit/transaction'
import * as actions from './actions'
import * as st from '_/model/error/http-status-code'

const factory = (service: NonViableSampleService, exportService: ExportService): EffectsFactory => () => [
    handler(actions.createNonViableSample, service.createNonViableSample),
    handler(actions.editReadNonViableSample, data => t.editTransaction(data.id, data.oldNonViableSample, data.newNonViableSample, service, data.editedFields)),
    handler(actions.loadNonViableSampleComments, service.loadComments),
    handler(actions.addNonViableSampleComments, data => service.addComments(data.nonViableSampleId, data.nonViableSampleComments)),
    handler(actions.loadNonViableSampleByBarcode, barcode =>
        service.getByBarcode(barcode)
            .catch(reason => {
                if (reason.status !== st.NOT_FOUND)
                    return Promise.reject(reason)
            })
    ),
    handler(actions.loadNonViableSample, service.get),
    handler(actions.loadNonViableSampleTrail, service.getTrail),
    handler(actions.loadNonViableSampleEditedInfo, service.loadEditedInfo),
    handler(actions.loadNonViableSamples, service.list),
    handler(actions.exportNonViableSamples, exportService.exportNonViableSamples),
    handler(actions.loadNonViableSamplesExports, exportService.getNonViableSamplesExports),
]

export default factory
