import { useRef, useState } from '_/facade/react'

import { Modal, ModalBody, ModalFooter } from '_/components/modal'
import Button from '_/components/button'
import Comment from '_/components/comment'
import UserTagMessage from '_/components/tag/user-tag-message'

interface Props {
    comments: string[]
    onAddComment(comment: string, compromised: boolean): void
    onClose(): void
    compromised: boolean
}

function SampleCommentsModal(props: Props) {
    const [validationResult, setValidationResult] = useState(false)
        , [compromised, setCompromised] = useState(props.compromised)
        , [message, setMessage] = useState('')
        , compromisedPreviousValue = useRef(props.compromised)

    function handleSaveComment() {
        props.onAddComment(message, compromised)
        setMessage('')
        compromisedPreviousValue.current = compromised
    }

    function handleCommentChange(message: string, validationResult: boolean) {
        setMessage(message)
        setValidationResult(validationResult)
    }

    function handleCompromisedStatusChange() {
        setCompromised(!compromised)
    }

    return (
        <Modal isOpen onClose={props.onClose}>
            <ModalBody>
                <Comment
                    compromisedStatusChanged={compromised !== compromisedPreviousValue.current}
                    onCommentChange={handleCommentChange}
                    value={message}
                    testId='field-comments'
                />
                <ul className='list-group list-group-flush' data-testid='comments-area'>
                    {props.comments.length === 0
                        ? <li className='list-group-item'>
                                No comments
                            </li>
                        : props.comments.map((message, index) =>
                            <li key={index} className='list-group-item'>
                                <UserTagMessage message={message} />
                            </li>
                        )
                    }
                </ul>
            </ModalBody>
            <ModalFooter>
                <div className='form-check me-auto'>
                    <input
                        id='compromised'
                        type='checkbox'
                        onChange={handleCompromisedStatusChange}
                        checked={compromised}
                        className='form-check-input'
                        data-testid='compromised'
                    />
                    <label
                        className='form-check-label'
                        htmlFor='compromised'
                    >
                        Mark viable sample as compromised
                    </label>
                </div>
                <Button className='btn-secondary' onClick={props.onClose} testId='close-comment-modal'>
                    Close
                </Button>
                <Button
                    className='btn-primary'
                    disabled={!validationResult || message.length === 0}
                    onClick={handleSaveComment}
                    testId='add-comment'
                >
                    Add comment
                </Button>
            </ModalFooter>
        </Modal >
    )
}

export default SampleCommentsModal
