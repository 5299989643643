import { Field } from 'react-final-form'
import { classnames } from '_/facade/react'
import { showFieldError } from './helpers'

interface TextFieldInlineProps {
    name: string
    placeholder: string
    className?: string
}

const TextFieldInline: React.FC<TextFieldInlineProps> = props =>
    <Field name={props.name} render={
        _ =>
            <div className={`${props.className || ''}`}>
                <input
                    type='text'
                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                    placeholder={props.placeholder}
                    autoComplete='off'
                    {..._.input}
                />
                {showFieldError(_.meta) && <span className='invalid-feedback' data-testid='validation-error'>{_.meta.error}</span>}
            </div>
    }/>

export default TextFieldInline
