import { Form, Field } from 'react-final-form'
import { classnames, useState } from '_/facade/react'
import { noop } from '_/utils/function'

import { showFieldError } from '_/components/form/helpers'
import { submitDisabled, Submit } from '_/components/form'
import Button from '_/components/button'
import FormChangesObserver from '_/components/form/form-changes-observer'
import Error from '_/components/form/field-error'

import type { ApiKeyEdit } from '_/model/api-key/types'
import type ApiKey from '_/model/api-key/types'
import validate from '_/model/api-key/validate'

interface OwnProps {
    entity: ApiKey
    onSubmit: (_: ApiKeyEdit) => Promise<void>
    hasNoPermissions: boolean
    testId?: string
}

function ApiKeysDescriptionForm(props: OwnProps) {
    const [editMode, setEditMode] = useState(false)

    function handleSubmit(_: ApiKeyEdit) {
        return props.onSubmit(_)
            .then(() => setEditMode(false))
            .then(noop)
    }

    return editMode
        ? <Form
            initialValues={props.entity}
            onSubmit={handleSubmit}
            validate={validate}
            render={form =>
                <form onSubmit={form.handleSubmit}>
                    <FormChangesObserver target={props.entity.id} form={form} />
                    <div className='d-flex align-items-start'>
                        <div className='flex-fill'>
                            <Field name='description' render={_ =>
                                <>
                                    <input
                                        id='description'
                                        type='text'
                                        className={classnames('form-control me-1', { 'is-invalid': showFieldError(_.meta) })}
                                        autoComplete='off'
                                        autoFocus
                                        {..._.input}
                                        data-testid={`${props.testId}-field-description`}
                                    />
                                    <Error field={_} />
                                </>
                            } />
                        </div>
                        <Submit disabled={submitDisabled(form)} className='ms-1' testId={`${props.testId}-save-description`}>Save</Submit>
                        <Button
                            className='btn-secondary ms-1'
                            onClick={() => setEditMode(false)}
                            testId={`${props.testId}-cancel-description-edit`}
                        >
                            Cancel
                        </Button>
                    </div>
                </form>
            }
        />
        : <div className='d-flex'>
            <Button onClick={() => setEditMode(true)} hasNoPermissions={props.hasNoPermissions} className='p-0 border-0' testId={`${props.testId}-edit-description`}>
                <i className='sample-edit__edit-icon material-icons md-24 text-primary pt-3'>create</i>
            </Button>
            <span className='ms-2 text-break api-keys-list__description-field--width'>{props.entity.description}</span>
        </div>

}

export default ApiKeysDescriptionForm
