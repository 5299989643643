import { actionFactory } from '_/utils/redux'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import type { ImageUpload, FloorPlanCreate } from '_/model/floor-plan/upload/types'
import type { FloorPlanEdit } from '_/model/floor-plan/editor/types'
import type ImageTokens from '_/model/floor-plan/image-tokens'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.floor-plan')

export const loadFloorPlanList = action<void, Promise<void>>('loadFloorPlanList')
           , floorPlanListLoaded = action<FloorPlan[]>('floorPlanListLoaded')
           , getFloorPlan = action<string, Promise<FloorPlan>>('getFloorPlan')
           , createFloorPlan = action<FloorPlanCreate, Promise<void>>('createFloorPlan')
           , updateFloorPlan = action<{ id: string, current: FloorPlanEdit, next: FloorPlanEdit }, Promise<void>>('updateFloorPlan')
           , uploadImage = action<Blob, Promise<void>>('uploadImage')
           , uploadStateChanged = action<ImageUpload, Promise<void>>('uploadStateChanged')
           , clearImageUploads = action<void>('clearImageUploads')
           , clearedImageUploads = action<void>('clearedImageUploads')
           , getImageTokens = action<string, Promise<ImageTokens>>('getImageTokens')
           , loadFloorPlanTrail = action<string, Promise<AuditTrail>>('loadFloorPlanTrail')
           , removeFloorPlan = action<string, Promise<void>>('removeFloorPlan')
