import { actionFactory } from '_/utils/redux'

import type { FingerdabOverviewByOperator, FingerdabOverviewRequest, OperatorPerformance, OperatorPerformanceFilter } from '_/model/fingerdab-overview-by-operator/types'
import type { OperatorsReport } from '_/model/reports/operators-report/types'

const action = actionFactory('app.fingerdab-overview-by-operator')

export const loadFingerdabOverviewByOperator = action<FingerdabOverviewRequest, Promise<FingerdabOverviewByOperator[]>>('loadfingerdabOverviewByOperator')
           , loadOperatorPerformance = action<OperatorsReport, Promise<OperatorPerformance>>('loadOperatorPerformance')
           , exportOperatorPerformance = action<OperatorPerformanceFilter, Promise<void>>('exportOperatorPerformance')
