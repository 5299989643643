import { Form } from 'react-final-form'

import { useAction, useEffect, useSelector, useState } from '_/facade/react'

import type { SetPassword } from '_/model/auth/types'
import type { ResetPassword } from '_/model/users/types'
import { PasswordField, Submit, submitDisabled } from '_/components/form'
import { useTimeService } from '_/components/time'
import { USER_ACCOUNT } from '_/constants/routes'

import * as actions from '../actions'
import * as userActions from '_/features/users/actions'
import * as toastActions from '_/features/toasts/actions'
import { navigateTo as navigateToAction } from '_/features/routing/actions'

import validate from '_/model/auth/restore/set-password-page-validate'
import Button from '_/components/button'

import RestorePasswordMessage from './restore-password-message'

function SetPasswordPage() {
    const timeService = useTimeService()
        , info = usePasswordResetInfo()
        , navigateTo = useAction(navigateToAction)
        , handleSetPassword = useSetPassword()
        , expired = info?.expired
        , accepted = info?.accepted
        , inactive = info?.inactive
        , disableFields = accepted || expired || inactive
        , processedAt = info && timeService.formatCtzDate(info.processedAt)

    return(
        <div className='h-100'>
            <div className='d-flex justify-content-center'>
                <div className='set-password-form'>
                    <div className='auth-form-header-text text-start pb-4'>Set a new password</div>
                    <Form
                        onSubmit={handleSetPassword as any}
                        validate={validate}
                        render={form =>
                            <form onSubmit={form.handleSubmit}>
                                <PasswordField testId='field-password' name='password' disabled={disableFields}>New password</PasswordField>
                                <PasswordField testId='field-confirm-password' name='confirmPassword' disabled={disableFields}>Confirm new password</PasswordField>

                                <div className='mt-4'>
                                    <Submit disabled={submitDisabled(form) || disableFields} className='fw-bold py-2 px-4 me-3' testId='set-new-password'>Set new password</Submit>
                                    <Button onClick={() => navigateTo(USER_ACCOUNT)} className='btn-light text-primary fw-bold py-2 px-4'>Cancel</Button>
                                </div>
                            </form>
                        }
                    />
                    {disableFields
                    ?
                        <RestorePasswordMessage hasExpired={expired} hasAccepted={accepted} processedAt={processedAt} isInactive={inactive} />
                    :
                        <p className='set-password-form__hint text-dark'>
                            Set a password for your account that you can remember, or use a trusted password manager.
                            Keep your password secure. You are the only person who should have access to your account
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}

export default SetPasswordPage

function usePasswordResetInfo() {
    const token = useSelector(_ => _.router.route?.params.token)
        , loadResetPasswordInfo = useAction(actions.loadResetPassword)
        , [passwordResetInfo, setPasswordResetInfo] = useState<ResetPassword | undefined>()

    useEffect(
        () => {
            if (token) {
                loadResetPasswordInfo(token)
                    .then(setPasswordResetInfo)
                    .catch(() =>  setPasswordResetInfo({ hasExpired: true } as any))
            }
        },
        [loadResetPasswordInfo, token]
    )
    return passwordResetInfo
}

function useSetPassword() {
    const setPassword = useAction(actions.setPassword)
        , changePassword = useAction(userActions.changePassword)
        , addSuccess = useAction(toastActions.addSuccess)
        , token = useSelector(_ => _.router.route?.params.token)
        , userId = useSelector(_ => _.auth.user?.id)

    function handleSetPassword(password: SetPassword) {
        if (token) {
            return setPassword({ token, password })
                .catch(_ => ({ password: _.statusText }))
        }
        else if (userId) {
            return changePassword({id: userId, newPassword: password.password})
                .then(() => addSuccess('New password accepted. Please check your email for confirmation'))
                .catch(_ => ({ password: _.statusText }))
        }
    }
    return handleSetPassword
}
