import { useSelector, useRef, useAction } from '_/facade/react'
import type TimeService from '_/services/time-service'
import { useTimeService } from '_/components/time'
import * as routes from '_/constants/routes'
import * as breaches from '_/constants/sample-breach-type'
import * as fieldIndex from '_/constants/custom-field-index'

import { actions as ra } from 'redux-router5'
import { useOrganisms } from '_/hooks/shared-hooks'
import * as helpers from '_/features/analysis/ui/helpers'
import { paramsFilter } from '_/model/filters/helpers'

import LimitBreachFloorPlanChart from '_/features/analysis/ui/limit-breach-floor-plan-chart'
import * as s from '_/constants/sample-status'
import type { OperatorsReportView } from '_/model/reports/operators-report/types'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import type { FloorPlanChartRef } from '_/model/floor-plan/export'
import type { AnalysisFilter } from '_/model/analysis/filter/types'
import type { LocationsReport } from '_/model/reports/locations-report/types'
import { CONTEXTUAL_REPORT } from '_/model/floor-plan/floor-plan-location-page'
import * as t from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'
import NoDataAvailable from '../no-data-available'

interface Props {
    printMode: boolean
    operatorName: t.Text
    analysisFilter: AnalysisFilter
    reportView: OperatorsReportView | undefined
    floorPlans: FloorPlan[]
    floorPlanId: string
    showSpinner: boolean
    onFloorPlanIdChange: (id: string | undefined) => void
}

function OperatorLimitBreachFloorPlan(props: Props) {
    const timeService = useTimeService()
        , exportDisabled = useSelector(_ => !_.auth.permissions.exportData)
        , floorPlanList = useSelector(_ => _.predefinedLists.floorPlans.plans)
        , ref = useRef<FloorPlanChartRef>(null)
        , title = `Floor plan showing limit breaches for viable samples with ${t.plainText(props.operatorName)} as operator`
        , floorPlanMetadata = useFloorPlanMetadata(props.analysisFilter, props.floorPlanId, title)
        , handleLocationClick = useLocationClickHandler(props.analysisFilter)
        , series = props.reportView?.limitBreachFloorPlanGraphData?.series ?? []
        , routeParams = getSampleListRouteParams(props.analysisFilter, timeService, s.READ_STATUS_IDS, props.floorPlans, props.floorPlanId)
        , seriesEmpty = series.every(_ => _.readCount === 0)

    if (floorPlanList.length === 0)
        return (
            <div className='block-border px-3'>
                <div className='text-center my-4'>
                    <Title operatorName={props.operatorName} />
                    <div className='text-muted text-uppercase text-center my-4'>
                        No floor plans
                    </div>
                </div>
            </div>
        )

    return (
        <div className='block-border px-3 break-block'>
            <div className='text-center my-4'>
                <Title operatorName={props.operatorName} />
            </div>
            {props.floorPlans.length > 0 && !props.showSpinner && !seriesEmpty
                ? <LimitBreachFloorPlanChart
                    floorPlans={props.floorPlans}
                    timeService={timeService}
                    filter={props.analysisFilter}
                    series={series}
                    exportDisabled={exportDisabled}
                    sampleListRouterParams={{...routeParams, sampleBreachTypes: [breaches.ACTION_LIMIT, breaches.ALERT_LIMIT]}}
                    onClickFloorPlanLocation={handleLocationClick}
                    floorPlanSubFilter={{
                        value: {
                            floorPlanId: props.floorPlanId,
                            cumulativeView: props.analysisFilter.cumulativeView!,
                            timeSliderPosition: props.analysisFilter.timeSliderPosition!,
                        },
                        onChange: _ => props.onFloorPlanIdChange(_.floorPlanId),
                    }}
                    metadata={[floorPlanMetadata]}
                    hideAppliedFiltersBlock={!props.printMode}
                    multipleGraphs
                    showActionButtons={!props.printMode}
                    showActionButtonsAsPrimaryButtons
                    hideTimeSliderBlock
                    ref={ref}
                    floorPlanLocationPage={CONTEXTUAL_REPORT}
                />
                : <NoDataAvailable showSpinner={props.showSpinner}>
                    <div className='text-muted text-uppercase text-center my-4'>
                        No data available
                    </div>
                </NoDataAvailable>
            }
        </div>
    )
}

export default OperatorLimitBreachFloorPlan

const Title = (props: {operatorName: t.Text}) => <h4>
    Floor plan showing limit breaches for viable samples with <FormattedText text={props.operatorName} /> as operator
</h4>

function useFloorPlanMetadata(analysisFilter: AnalysisFilter, floorPlanId: string, title: string) {
    const predefinedLists = useSelector(_ => _.predefinedLists)
        , floorPlans = useSelector(_ => _.predefinedLists.floorPlans.plans)
        , floorPlan = floorPlans.find(_ => _.id === floorPlanId)
        , user = useSelector(_ => _.auth.user)
        , timeService = useTimeService()
        , organisms = useOrganisms(analysisFilter.organismIds ?? [])
        , chartMetadata = helpers.getFloorPlanMetadata({
            analysisFilter,
            timeService,
            user,
            predefinedLists,
            organisms,
            reportName: title,
            floorPlan
        })

    return { id: floorPlanId, metadata: chartMetadata }
}

function useLocationClickHandler(filter: AnalysisFilter) {
    const navigateTo = useNavigateTo()

    function handleClickFloorPlanLocation(locationId: string) {
            const newQuery = { ...filter, locationId: locationId }

        navigateTo(newQuery)
    }

    return handleClickFloorPlanLocation
}

function useNavigateTo() {
    const navigateToAction = useAction(ra.navigateTo)

    return (_: LocationsReport) => navigateToAction(routes.LOCATIONS_REPORT, paramsFilter(_))
}

function getSampleListRouteParams(
    analysisFilter: AnalysisFilter,
    timeService: TimeService,
    statuses: s.SampleStatus[],
    floorPlans: FloorPlan[],
    floorPlanId: string
) {
    const routeParams = helpers.buildSampleListRouterParams(analysisFilter, timeService, statuses)
        , selectedFloorPlan = floorPlans.find(_ => _.id === floorPlanId) ?? floorPlans[0]

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!selectedFloorPlan)
        return routeParams

    const fields = routeParams.fields?.map(_ => {
        if (_.index === fieldIndex.EXPOSURE_LOCATION_ID)
            return {..._, value: selectedFloorPlan.locations.length === 0 ? undefined : selectedFloorPlan.locations.map(_ => _.locationId) }
        return _
    })

    return { ...routeParams, fields }

}
