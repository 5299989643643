import type { ValidationResult} from '_/utils/form/validate'
import { required, isValidNumberInRange } from '_/utils/form/validate'
import type { LimitsFields, LimitsForm } from '_/model/predefined-lists/action-alert-limit/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import { isSwabSampleType } from '_/model/predefined-lists/sample-type/helpers'

export default function validate(entity: Partial<LimitsForm>, sampleTypes: SampleType[]) {
    const limits = isValidLimits(entity, sampleTypes)
        , behaviour = required('Objectionable organisms')(entity.behaviour)
        , cfuVerification = required('Verify CFU count')(entity.cfuVerification)
        , photoBehaviour = required('Photograph required')(entity.photoBehaviour)
        , nonViableLimits = validateNonViableLimits(entity)
        , result: ValidationResult<LimitsForm> = {nonViableLimits, limits}

    if (behaviour)
        result.behaviour = behaviour

    if (cfuVerification)
        result.cfuVerification = cfuVerification

    if (photoBehaviour)
        result.photoBehaviour = photoBehaviour

    return result
}

function isValidLimits(entity: Partial<LimitsForm>, sampleTypes: SampleType[]) {
    return entity.limits?.map(limit => {
        const isSwabPlateType = isSwabSampleType(limit.sampleTypeId, sampleTypes)
            , maxValue = isSwabPlateType ? 1 : 1000

        return validateLimits(limit, maxValue)
    })
}

function validateLimits<T extends LimitsFields>(limits: T, maxValue: number) {
    return {
        atRestActionLimit: isValidLimit(limits.atRestActionLimit, maxValue),
        atRestAlertLimit: isValidLimit(limits.atRestAlertLimit, maxValue),
        inOperationActionLimit: isValidLimit(limits.inOperationActionLimit, maxValue),
        inOperationAlertLimit: isValidLimit(limits.inOperationAlertLimit, maxValue),
    }
}

function isValidLimit(limit: number | undefined, maxValue=1000) {
    if (limit === undefined)
        return undefined

    const isInvalid = !Number.isInteger(limit) || !isValidNumberInRange(limit, 1, maxValue)

    return isInvalid
        ? `Must be an integer value between 1 and ${maxValue}`
        : undefined
}

function validateNonViableLimits(entity: Partial<LimitsForm>) {
    return entity.nonViableLimits?.map(limit => validateLimits(limit, 1000000000))
}
