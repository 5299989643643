import { diffObject } from '_/utils/object'
import { noop } from '_/utils/function'
import type Api from '_/services/predefined-lists-service'
import type { EditLimitsProps, LimitsCreate } from '_/model/predefined-lists/action-alert-limit/types'
import type { EditGradeProps, GradeEdit } from '_/model/predefined-lists/grade/grade'
import type ReasonService from '_/model/reason/reason-service'

import { electronicSignatureSettingsEnabled } from '_/model/critical-change-reason/helpers'
import * as s from '_/model/context/electronic-signature-settings'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import { reasonRequired } from './reason-rule'
import { signatureFailure } from '_/model/error/error'
import type { EditNonViableLimitsProps, NonViableLimitCreate } from './non-viable-limits'

function updateLimitsTransaction(
    editedLimits: EditLimitsProps[],
    newLimits: Partial<LimitsCreate>[],
    grade: EditGradeProps,
    newNonViableLimits: NonViableLimitCreate[],
    editedNonViableLimits: EditNonViableLimitsProps[],
    signatureSettings: s.ElectronicSignatureSettings[],
    api: Api,
    reasonService: ReasonService,
): Promise<void> {
    const signatureRequired = electronicSignatureSettingsEnabled(s.CHANGING_SETTINGS, signatureSettings)
        , gradeDiff = diffObject(grade.oldGrade, grade.newGrade)
        , saveActionAlertLimitsPromise = (approvalInfo: ApprovalInfo, isNonViableLimits?: boolean) => {
            const limits = isNonViableLimits ? editedNonViableLimits : editedLimits
                , save = isNonViableLimits ? api.nonViableLimits.save : api.actionAlertLimits.save
            return Promise.all(limits
                .map(_ => {
                    const diff = diffObject(_.oldLimits, _.newLimits)
                    return diff
                        ? save(_.id, { approvalInfo, ...diff })
                        : undefined
                })
            )
        }
        , reasonPromise = reasonRequired(editedLimits, editedNonViableLimits, !!gradeDiff)
            ? (reason?: string, error?: string) => reasonService.getReason(signatureRequired, reason, error)
            : () => Promise.resolve({reason: ''})

    function updateWithReason(reason?: string, error?: string): Promise<void> {
        let approvalInfoReason = ''
        return reasonPromise(reason, error)
            .then(approvalInfo => {
                approvalInfoReason = approvalInfo.reason
                return Promise.all([
                    editedLimits.length > 0 ? saveActionAlertLimitsPromise(approvalInfo) : undefined,
                    newLimits.length > 0 ? Promise.all(newLimits.map(_ => api.actionAlertLimits.create(_))) : undefined,
                    newNonViableLimits.length > 0 ? Promise.all(newNonViableLimits.map(_ => api.nonViableLimits.create(_))) : undefined,
                    editedNonViableLimits.length > 0 ? saveActionAlertLimitsPromise(approvalInfo, true) : undefined,
                    gradeDiff ? api.grade.save(grade.gradeId, { approvalInfo, ...gradeDiff}) : undefined,
                ])
            })
            .then(
                noop,
                _ => signatureFailure(_) ? updateWithReason(approvalInfoReason, _.statusText) : Promise.reject(_)
            )
    }

    return updateWithReason()
}

function updateGradeTransaction(
    id: string,
    current: GradeEdit,
    next: GradeEdit,
    api: Api,
) {
    const gradeDiff = diffObject(current, next)

    if (!gradeDiff)
        return Promise.resolve()

    return api.grade.saveWithReason(id, gradeDiff)
}

export {
    updateLimitsTransaction,
    updateGradeTransaction,
}
