import { useState, useEffect, useAction } from '_/facade/react'

import type { SampleReportSearchQuery } from '_/model/sample/search'
import type Sample from '_/model/sample/sample'

import * as a from '../actions'
import { dropFields } from '_/utils/object'

function useRelatedSamples(searchQuery: SampleReportSearchQuery) {
    const [showSpinner, setShowSpinner] = useState(false)
        , [samples, setSamples] = useState<Sample[]>([])
        , loadSampleList = useAction(a.getSampleList)

    useEffect(
        () => {
            setShowSpinner(true)
            loadSampleList(dropFields(searchQuery, 'sampleToExcludeId'))
                .then(_ => setSamples(_.items.filter(it => it.id !== searchQuery.sampleToExcludeId)))
                .finally(() => setShowSpinner(false))
        },
        [searchQuery, loadSampleList]
    )

    return [samples, showSpinner] as const
}

export {
    useRelatedSamples,
}
