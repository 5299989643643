import type { State } from 'router5'

function calcFilter<S>(route: State, persistedFilter: S | undefined, calcInitialFilter: (routeFilter: any) => S): S {
    if (isPreFilteredRoute(route))
        return calcInitialFilter(route.params.filter)

    return persistedFilter ?? calcInitialFilter({})
}

function isPreFilteredRoute(route: State) {
    // note: on forward navigation source is popstate as well
    const isHistoryNavigation = route.meta?.source === 'popstate'

    return !isHistoryNavigation && !!route.params.filter
}

function paramsFilter(filter: any) {
    return { filter }
}

export {
    calcFilter,
    paramsFilter,
    isPreFilteredRoute,
}
