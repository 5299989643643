import { useState, useEffect, useAction } from '_/facade/react'

import { useContextSwitchObserver } from '_/components/context-observer'

import type Insight from '_/model/insight/types'
import * as insightType from '_/model/insight/insight-type'

import * as locationActions from '_/features/predefined-lists/exposure-locations/actions'

import * as actions from './actions'
import LocationTrend from './insight-location-trend'
import OperatorTrend from './insight-operator-trend'
import OrganismTrend from './insight-organism-trend'
import LimitBreachTrend from './insights-limit-breach-trend'
import DistributionOfBreachesAcrossDays from './distribution-of-breaches/insight-distribution-of-breaches-across-days'
import DistributionOfBreachesAcrossSessions from './distribution-of-breaches/insight-distribution-of-breaches-across-session'

export default function InsightsWidgetContainer() {
    const [topInsight, setTopInsight] = useState<Insight | undefined>()
        , dismiss = useDismiss(topInsight?.id, setTopInsight)
        , markAsShown = useAction(actions.markAsShown)

    useTopInsight(setTopInsight)
    useLoadDependentData()

    useEffect(
        () => {
            if (topInsight)
                markAsShown(topInsight.id)
        },
        [topInsight, markAsShown]
    )

    return (
        <div className='col-4'>
            <div className='m-4'>
                <h5 className='ms-4'>Insights</h5>
                <div className='insights-widget'>
                    {!topInsight
                        ? <div className='py-3 ps-3'>
                            You have reviewed all currently available insights
                            <div className='insights-widget-like--color mt-3'>
                                This feature is not intended to replace your own trend detection
                            </div>
                        </div>
                        : getInsight(topInsight, dismiss)
                    }
                </div>
            </div>
        </div>
    )
}

function getInsight(insight: Insight, dismiss: () => void) {
    switch (insight.type) {
        case insightType.LOCATION_TREND:
            return <LocationTrend insight={insight} dismiss={dismiss} />
        case insightType.OPERATOR_TREND:
            return <OperatorTrend insight={insight} dismiss={dismiss} />
        case insightType.ORGANISM_TREND:
            return <OrganismTrend insight={insight} dismiss={dismiss} />
        case insightType.LIMIT_BREACH_TREND:
            return <LimitBreachTrend insight={insight} dismiss={dismiss} />
        case insightType.DISTRIBUTION_OF_BREACHES_ACROSS_DAY:
            return <DistributionOfBreachesAcrossDays insight={insight} dismiss={dismiss} />
        case insightType.DISTRIBUTION_OF_BREACHES_ACROSS_SESSION:
            return <DistributionOfBreachesAcrossSessions insight={insight} dismiss={dismiss}/>
    }
}

function useDismiss(id: string | undefined, setInsight: (_: Insight) => void) {
    const dismiss = useAction(actions.dismiss)
        , loadTopInsight = useAction(actions.loadTopInsight)
        , markAsSeen = useAction(actions.markAsSeen)

    function handleDismiss() {
        if (!id)
            return

        dismiss(id)
            .then(() => markAsSeen(id))
            .then(loadTopInsight)
            .then(_ => setInsight(_[0]))
    }

    return handleDismiss
}

function useTopInsight(setTopInsight: (_: Insight) => void) {
    const loadTopInsight = useAction(actions.loadTopInsight)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadTopInsight()
                .then(_ => setTopInsight(_[0]))
        },
        [loadTopInsight, contextSwitch, setTopInsight]
    )
}

function useLoadDependentData() {
    const loadLocations = useAction(locationActions.loadExposureLocationList)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadLocations()
        },
        [contextSwitch, loadLocations]
    )
}

