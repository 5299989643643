import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import FormChangesObserver from '_/components/form/form-changes-observer'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import { pickFields } from '_/utils/object'

import FieldFormCommon, { useFieldFormAction } from './shared/field-form'

interface Props {
    formId: string
    label: React.ReactNode
    hasNoPermissions?: boolean
    initialValues?: object
    validate?: (values: Partial<SampleDetailsEdit>) => object
    onSubmit?: (sample: SampleDetailsEdit) => Promise<void>
    onChange?: (sample: Partial<SampleDetailsEdit>, form: FormRenderProps) => void
    renderView: () => React.ReactNode
    renderEdit?: (form: FormRenderProps, handleSave: () => Promise<void>, handleCancel: () => void) => React.ReactNode
    testId?: string
    formClassName?: string
}

function FieldForm(props: Props) {
    const [handleSave, handleCancel] = useFieldFormAction()
        , forwardedProps = pickFields(props, 'formId', 'label', 'hasNoPermissions', 'testId', 'renderView')

    function handleChange(latestValue: Partial<SampleDetailsEdit>, form: FormRenderProps) {
        if (props.onChange)
            props.onChange(latestValue, form)
    }

    return (
        <FieldFormCommon
            {...forwardedProps}
            editAvailable={!!props.renderEdit}
            renderEditForm={() =>
                <Form
                    initialValues={props.initialValues}
                    onSubmit={_ => props.onSubmit?.(_ as any)}
                    validate={props.validate}
                    render={form =>
                        <form>
                            <FormChangesObserver form={form} target={props.formId} onChange={handleChange} />
                            {props.renderEdit?.(form, () => handleSave(form), () => handleCancel(form))}
                        </form>
                    }
                />
            }
        />
    )
}

export default FieldForm

