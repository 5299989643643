import { useSelector, useAction, useState, useCallback, useEffect, classnames } from '_/facade/react'

import { ITEMS_PER_PAGE } from '_/constants'
import * as routes from '_/constants/routes'
import { NOT_AVAILABLE } from '_/constants/common-messages'

import { Table, SortableHead, SortableTh, PaginatedFooter } from '_/components/table'
import PageHeader from '_/components/page-header'
import ContextObserver from '_/components/context-observer'
import Link from '_/components/link'
import { useTimeService } from '_/components/time'
import AddUserModal from '_/features/users/create/add-user-modal'

import type User from '_/model/users/types'
import type PaginationState from '_/model/pagination-state'
import type SortState from '_/model/sort-state'
import type Page from '_/model/page'

import * as actions from '../actions'
import * as routerActions from '_/features/routing/actions'

import { sortMemberships } from '_/model/users/helpers'

function UserList() {
    const timeService = useTimeService()
        , route = useSelector(_ => _.router.route)
        , [pagination, setPagination] = useState<PaginationState>(getPagination())
        , [sorting, setSorting] = useState(getSorting())
        , permissions = useSelector(_ => _.auth.permissions)
        , createMode = useSelector(_ => routes.SETTINGS_USERS_CREATE === _.router.route!.name)
        , navigateToList = useNavigateToList()
        , users = useUsers(sorting, pagination)
        , user = useSelector(_ => _.auth.user)

    function getPagination(): PaginationState {
        const historyQuery = route?.params.query
        return historyQuery ? historyQuery : { start: 0, count: ITEMS_PER_PAGE }
    }

    function getSorting(): SortState {
        const historyQuery = route?.params.query
        return historyQuery ? historyQuery.sort : { sort: ['name:asc'] }
    }

    function reset() {
        setPagination({ start: 0, count: ITEMS_PER_PAGE })
        setSorting({sort: 'name:asc'})
    }

    function getUserSettingsRoute() {
        if (permissions.manageMemberships)
            return routes.SETTINGS_USERS_MEMBERSHIPS

        if (permissions.manageApiKeys)
            return routes.SETTINGS_USERS_API_KEYS

        if (permissions.readUsers)
            return routes.SETTINGS_USERS_AUDIT_TRAILS

        return routes.SETTINGS_USERS_USER
    }

    function canAccessUserSettingsRoute(userId: string) {
        return permissions.manageMemberships || user?.id === userId
    }

    return (
        <>
            <ContextObserver onChange={reset} />
            <PageHeader title='Users'>
                <Link
                    className='btn btn-primary'
                    routeName={routes.SETTINGS_USERS_CREATE}
                    hasNoPermissions={!permissions.editUsers}
                    testId='add-new-user'
                >
                        Add a user
                </Link>
            </PageHeader>
            {createMode && <AddUserModal onClose={navigateToList} />}
            <div className="overflow-auto flex-fill">
                <Table>
                    <SortableHead onChange={setSorting} sticky>
                        <SortableTh name='name'>Name</SortableTh>
                        <SortableTh name='email'>Email</SortableTh>
                        <th>Roles</th>
                        <th>Last logged in</th>
                        <th/>
                    </SortableHead>
                    <tbody>
                        {users.items.map(_ =>
                            <tr key={_.id} className={_.isDeleted ? 'softDeleted' : ''}>
                                <td>{_.name}</td>
                                <td>{_.email}</td>
                                <td>
                                    {sortMemberships(_.memberships).map(
                                        _ => <div
                                                key={_.id}
                                                className={classnames({'text-muted': _.isSuspended})}
                                            >
                                                {_.description} ({_.role.name}) {_.isSuspended && '(suspended)'}
                                            </div>
                                    )}
                                </td>
                                <td>{_.lastLoggedIn ? timeService.formatCtzDateTime(_.lastLoggedIn) : NOT_AVAILABLE}</td>
                                <td className='text-end'>
                                    {!_.isDeleted &&
                                        <Link
                                            className='p-0 border-0 d-print-none'
                                            routeName={getUserSettingsRoute()}
                                            routeParams={{ id: _.id }}
                                            hasNoPermissions={!canAccessUserSettingsRoute(_.id)}
                                            testId={`edit-user-memberships-${_.email}`}
                                        >
                                            <i className='sample-edit__edit-icon material-icons text-primary md-18'>create</i>
                                        </Link>
                                    }
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <PaginatedFooter
                        colSpan={5}
                        state={pagination}
                        onChange={setPagination}
                        totalCount={users.totalCount}
                    />
                </Table>
            </div>
        </>
    )
}

export default UserList

function useUsers(sorting: SortState, pagination: PaginationState) {
    const loadUserList = useAction(actions.loadUserList)
        , navigateTo = useAction(routerActions.navigateTo)
        , [users, setUsers] = useState<Page<User>>({items: [], totalCount: 0})

    useEffect(
        () => {
            const query = {...sorting, ...pagination}
            navigateTo(routes.SETTINGS_USERS, {query})
            loadUserList(query)
                .then(setUsers)
        },
        [loadUserList, pagination, sorting, navigateTo]
    )
    return users
}

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SETTINGS_USERS), [navigateTo])

    return navigateToList
}
