import { useSelector } from '_/facade/react'
import type TimeService from '_/services/time-service'

import type { OrganismsBreakdownSeriesGraphData } from '_/model/analysis/types'
import type { AnalysisFilter, ThresholdLine } from '_/model/analysis/filter/types'
import type { SampleStatus } from '_/constants/sample-status'
import { GROWTH_STATUS_IDS, READ_STATUS_IDS } from '_/constants/sample-status'

import * as chartType from '_/model/analysis/chart-type'
import PieChart from './pie-chart'
import * as h from './helpers'
import type { ChartMetadata } from '_/model/analysis/chart-metadata'
import { useExportGraphData } from '../hooks'
import type { ExportRef } from './date-series-chart'
import DateSeriesChart from './date-series-chart'
import type { DateSeriesGraphData } from '_/model/analysis/date-series'

interface OwnProps {
    filter: AnalysisFilter
    onSplitLocation(locationName: string): void
    metadata: ChartMetadata
    timeService: TimeService
    showActionButtons: boolean
    graphData: OrganismsBreakdownSeriesGraphData | DateSeriesGraphData
    multipleGraphs?: boolean | undefined
    pieChartRef?: React.RefObject<ExportRef>
    dateSeriesChartRef?: React.RefObject<ExportRef>
    exportDisabled: boolean
    showTabularView?: boolean
    thresholdLines?: ThresholdLine[]
    testId?: string
}

function AnalysisCharts(props: OwnProps) {
    const predefinedLists = useSelector(_ => _.predefinedLists)
        , exportHandler = useExportGraphData({...props.filter, thresholdLines: props.thresholdLines}, predefinedLists)
        , requiredGrowthStatuses = props.graphData.chartType === chartType.CONTAMINATED_SAMPLES
            || props.graphData.chartType === chartType.ORGANISM_TYPE_BREAKDOWN
            || props.graphData.chartType === chartType.STACKED_SAMPLE_NAME
        , dateSeriesSampleStatuses: SampleStatus[] = requiredGrowthStatuses
            ? GROWTH_STATUS_IDS
            : READ_STATUS_IDS
        , aggregationPeriod = h.isAggregationAllowed(props.filter.chartType)
            ? props.filter.aggregationPeriod
            : undefined
        , isBarChart = props.graphData.chartType === chartType.ORGANISM_TYPE_BREAKDOWN || props.graphData.chartType === chartType.STACKED_SAMPLE_NAME

    return (
        <div data-testid={props.testId}>
            {props.graphData.chartType === chartType.ORGANISMS_BREAKDOWN_CHART &&
                <PieChart
                    series={props.graphData.series}
                    metadata={props.metadata}
                    sampleListRouterParams={h.buildSampleListRouterParams(props.filter, props.timeService, GROWTH_STATUS_IDS)}
                    showActionButtons={props.showActionButtons}
                    multipleGraphs={props.multipleGraphs}
                    ref={props.pieChartRef}
                    exportDisabled={props.exportDisabled}
                    showTabularView={props.showTabularView}
                    onExport={exportHandler}
                />
            }
            {(props.graphData.chartType === chartType.ACTION_LIMIT_BREACHES
                || props.graphData.chartType === chartType.ALERT_LIMIT_BREACHES
                || props.graphData.chartType === chartType.AVERAGE_CFU_CHART
                || props.graphData.chartType === chartType.CONTAMINATED_SAMPLES
                || props.graphData.chartType === chartType.TOTAL_CFU_CHART
                || props.graphData.chartType === chartType.TOTAL_SAMPLES_READ
                || props.graphData.chartType === chartType.STACKED_SAMPLE_NAME
                || props.graphData.chartType === chartType.ORGANISM_TYPE_BREAKDOWN
                || props.graphData.chartType === chartType.PARTICLE_COUNTS
            ) &&
                <DateSeriesChart
                    chartTitle={h.getChartTitle(props.graphData.chartType, props.filter.subChartType)}
                    data={props.graphData}
                    aggregationPeriod={aggregationPeriod}
                    onSplitLocation={!isBarChart ? props.onSplitLocation : undefined}
                    metadata={props.metadata}
                    sampleListRouterParams={h.buildSampleListRouterParams(props.filter, props.timeService, dateSeriesSampleStatuses)}
                    showActionButtons={props.showActionButtons}
                    disabledGoToSamplesButton={props.filter.chartType === chartType.PARTICLE_COUNTS}
                    multipleGraphs={props.multipleGraphs}
                    ref={props.dateSeriesChartRef}
                    exportDisabled={props.exportDisabled}
                    showTabularView={props.showTabularView}
                    thresholdLines={props.thresholdLines ?? []}
                    onExport={exportHandler}
                />
            }
        </div>
    )
}

export default AnalysisCharts
