import type { FieldRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'

import { shallowUpdate } from '_/utils/object'
import { Close } from '_/components/button'
import Select from '_/components/downshift-select'

import { NOT_RECORDED_NAME } from '_/constants/system-words'
import { defaultTextNode, systemTextNode } from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    id: string
    field: FieldRenderProps<any>
    className?: string
    label: string
    testId?: string
    entities: string[]
    onChangeEntities: (_: string[]) => void
    onInputValueChange: (value: string) => void
}

function MultipleFieldValuesSearch(props: Props) {
    function handleChange(value: string) {
        const input = props.field.input
            , nextValue = Array.isArray(input.value) ? input.value.concat() : []

        if (nextValue.indexOf(value) === -1)
            nextValue.push(value)

        input.onChange(nextValue)
    }

    function handleRemove(value: string) {
        const input = props.field.input
            , previousValue = Array.isArray(input.value) ? input.value.concat() : []
            , nextValue = previousValue.filter(_ => _ !== value)

        input.onChange(nextValue.length === 0 ? '' : nextValue)
    }

    function handleBlur() {
        props.field.input.onBlur()

        props.onChangeEntities([])
    }

    function formatEntity(_: string) {
        return _ === NOT_RECORDED_NAME
            ? [systemTextNode(_)]
            : [defaultTextNode(_)]
    }

    const input = props.field.input
        , value = Array.isArray(input.value) ? input.value : []
        , notSelectedEntities = props.entities.filter(_ => value.indexOf(_) === -1)

    return (
        <div className={'mb-3 ' + props.className || ''}>
            <label htmlFor={props.id} className='col-form-label' data-testid='field-label'>{props.label}</label>
            <Select
                id={props.id}
                entities={notSelectedEntities}
                calcId={_ => _}
                calcName={formatEntity}
                className='form-control border-print-0'
                input={shallowUpdate(input, {
                    value: '',
                    onChange: handleChange,
                    onBlur: handleBlur,
                })}
                onInputValueChange={props.onInputValueChange}
                autocomplete
                shouldClearInput
                placeholder='Search'
                isSearchField
                testId={props.testId}
            />
            {value.map((entity, key) =>
                <span key={key}>
                    {key > 0 && <br/>}
                    <Close
                        className='align-text-bottom text-danger'
                        aria-label='Close'
                        onClick={() => handleRemove(entity)}
                        testId={`${props.testId}-remove-${key}`}
                    />
                    <span data-testid={`${props.testId}-item`} className='break-word'> <FormattedText text={formatEntity(entity)}/></span>
                </span>
            )}
        </div>
    )
}


const ConnectedFieldValuesSearch = MultipleFieldValuesSearch

interface MultipleFieldValueSearchField {
    id: string
    name: string
    className?: string
    label: string
    testId?: string
    entities: string[]
    onChangeEntities: (_: string[]) => void
    onInputValueChange: (value: string) => void
}

const MultipleFieldValueSearchField = (props: MultipleFieldValueSearchField) =>
    <Field name={props.name} render={_ =>
        <ConnectedFieldValuesSearch {...props} field={_} />
    } />

export { MultipleFieldValueSearchField as default }
