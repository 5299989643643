import { EDITING_TREND_INVESTIGATION_REFERENCES } from '_/model/context/electronic-signature-settings'
import type * as t from '_/model/trends/types'
import * as h from '_/services/impl/helper'
import type ApiService from '../api-service'
import type TrendService from '../trend-service'

function factory(api: ApiService): TrendService {
    const resource = api.resource(['trends'])
    return {
        ...resource,
        list: getAllTrends,
        getTrendStatistics,
        getTrends,
        saveTrendInvestigationRef,
        createTrendInvestigationRef,
        searchTrendInvestigationRef,
        searchTrendId,
    }

    function getTrendStatistics(): Promise<t.TrendStatistics> {
        return api.get<t.TrendStatistics>(['trends', 'statistics'])
    }

    function getAllTrends(query: t.TrendQuery): Promise<t.Trend[]> {
        return api.get<t.ServerTrend[]>(['trends'], query)
            .then(_ => _.map(h.convertFromServerTrend))
    }

    function getTrends(query: t.TrendQuery): Promise<t.Trend[]> {
        return api.get<t.ServerTrend[]>(['trends'], query)
            .then(_ => _.map(h.convertFromServerTrend))
    }

    function saveTrendInvestigationRef(id: string, trend: t.TrendEdit): Promise<void> {
        return api.patchWithReason(['trends', id], EDITING_TREND_INVESTIGATION_REFERENCES, trend)
    }

    function createTrendInvestigationRef(id: string, trend: t.TrendEdit) {
        return api.post(['trends', id], trend)
    }

    function searchTrendInvestigationRef(query: string): Promise<string[]> {
        return api.post(['trend-investigation-references'], { query })
    }

    function searchTrendId(query: string): Promise<string[]> {
        return api.post(['trend-ids'], { query })
    }
}

export default factory
