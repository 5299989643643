import BreachIcon from '_/components/sample-icon/breach-icon'
import FloorPlanLocationIcon from '_/components/sample-icon/floor-plan-location-icon'

import { ACTION_LIMIT, ALERT_LIMIT, COMPROMISED } from '_/constants/sample-breach-type'
import type { FloorPlanChart} from '_/model/analysis/chart-type'
import { CONTAMINATION_FLOOR_PLAN, LIMIT_BREACH_FLOOR_PLAN } from '_/model/analysis/chart-type'
import * as floorPlanLocationPage from '_/model/floor-plan/floor-plan-location-page'

interface Props {
    chartType: FloorPlanChart
    floorPlanLocationPage: floorPlanLocationPage.FloorPlanLocationPage
}

function FloorPlanKeys(props: Props) {
    const showAdditionalData = props.floorPlanLocationPage !== floorPlanLocationPage.CONTEXTUAL_REPORT

    return (
        <span>
            <div className='d-flex'>
                <div className='d-flex flex-column'>
                    {props.chartType === CONTAMINATION_FLOOR_PLAN && <div className='mb-1'><FloorPlanLocationIcon count={3} /></div>}
                    <div className='mb-1 ms-1'><FloorPlanLocationIcon /></div>
                    {showAdditionalData && <div className='mb-1 ms-1'><FloorPlanLocationIcon outsideOfScope /></div>}
                    {props.chartType === LIMIT_BREACH_FLOOR_PLAN &&
                        <div className='d-flex flex-column'>
                            <div className='mb-1'><BreachIcon type={ACTION_LIMIT} count={3} /></div>
                            <div className='mb-1'><BreachIcon type={ACTION_LIMIT} count={3} withRef /></div>
                            <div className='mb-1'><BreachIcon type={ALERT_LIMIT} count={3} /></div>
                            <div className='mb-1'><BreachIcon type={ALERT_LIMIT} count={3} withRef /></div>
                            {showAdditionalData &&<div className='mb-1'><BreachIcon type={COMPROMISED} count={3} /></div>}
                            {showAdditionalData &&<div className='mb-1'><BreachIcon type={COMPROMISED} count={3} withRef /></div>}
                        </div>
                    }
                </div>
                <div className='ms-3'>
                    {props.chartType === CONTAMINATION_FLOOR_PLAN && <div className='mb-2 mt-1'>Total CFUs for this location</div>}
                    <div>
                        {props.chartType === CONTAMINATION_FLOOR_PLAN && 'Location with no CFUs'}
                        {props.chartType === LIMIT_BREACH_FLOOR_PLAN && 'Location with no limit breaches' + (showAdditionalData ? ' or compromised samples' : '')
                        }
                    </div>
                    {showAdditionalData && <div className='mb-1 mt-1'>Location outside of filter scope</div>}
                    {props.chartType === LIMIT_BREACH_FLOOR_PLAN &&
                        <div className='d-flex flex-column'>
                            <div className='mb-1'><span>Action limit breach</span></div>
                            <div className='mb-1'><span>Action limit breach with ref #</span></div>
                            <div className='mb-1'><span>Alert limit breach</span></div>
                            <div className='mb-1'><span>Alert limit breach with ref #</span></div>
                            {showAdditionalData && <div className='mb-1'><span>Compromised</span></div>}
                            {showAdditionalData && <div className='mb-1'><span>Compromised with ref #</span></div>}
                        </div>
                    }
                </div>
            </div>
        </span>
    )
}

export default FloorPlanKeys
