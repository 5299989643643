import { useState, useEffect, useAction } from '_/facade/react'

import type { MissedMonitoringReport, MissedMonitoringTableItem, SampleTypeDetails } from '_/model/reports/missed-monitoring-report/types'
import { PaginatedFooter, SortableHead, SortableTh, Table } from '_/components/table'

import * as actions from '_/features/reports/missed-monitoring-report/actions'
import TableActionButtons from '../table-action-buttons'
import TableFooter from '../table-footer'
import { useTimeService } from '_/components/time'
import type PaginationState from '_/model/pagination-state'
import type SortState from '_/model/sort-state'
import MissedSampleTypeIcon from './missed-sample-type-icon'
import { getDateRange } from '_/model/reports/helpers'
import { compare } from '_/utils/string'
import NoDataAvailable from '../no-data-available'
import { formatActiveState } from '_/utils/format/common'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    printMode: boolean
    itemsLimit: number
    monitoringItems: MissedMonitoringTableItem[]
    filter: MissedMonitoringReport
    showSpinner: boolean
}

const sortableFields = ['scheduledExposureDate'] as const

function MissedMonitoringTable(props: Props) {
    const [pagination, setPagination] = useState({ start: 0, count: props.itemsLimit })
        , [sort, setSort] = useState({ sort: 'scheduledExposureDate:desc' })
        , exportMissedMonitoringTable = useAction(actions.exportMissedMonitoringTable)
        , timeService = useTimeService()
        , isTableEmpty = props.monitoringItems.length === 0

    useEffect(
        () => setPagination({ start: 0, count: props.itemsLimit }),
        [props.itemsLimit, props.filter]
    )

    return (
        <div className='col-12'>
            <div className='block-border px-3'>
                <div className='my-4 text-center'>
                    <h4 data-testid='missed-monitoring-report-subtitle'>Missed monitoring</h4>
                    {props.printMode && props.monitoringItems.length > props.itemsLimit &&
                        <h5 className='text-danger d-print-none'>{`Table in Print mode is limited to ${props.itemsLimit} records!`}</h5>
                    }
                </div>
                <Table>
                    <SortableHead onChange={setSort}>
                        <SortableTh name='scheduledExposureDate'>Scheduled exposure date</SortableTh>
                        <th>Location</th>
                        <th>Missing viable samples</th>
                    </SortableHead>
                    <tbody>
                        {props.monitoringItems.length > 0 && !props.showSpinner
                            ? getDisplayedItems(props.monitoringItems, pagination, sort).map((_, index) =>
                                <tr key={`missed-${index}`}>
                                    <td>{timeService.formatUtcDate(_.scheduledExposureDate)}</td>
                                    <td><FormattedText text={formatActiveState(_.location, _.isActive)} /></td>
                                    <td>{formatMissingSamples(_.missingSamples, index)}</td>
                                </tr>
                            )
                            : <tr>
                                <td className='text-center' colSpan={3}>
                                    <NoDataAvailable showSpinner={props.showSpinner}>
                                        <span className='text-center text-muted text-uppercase'>No data available</span>
                                    </NoDataAvailable>
                                </td>
                            </tr>
                        }
                    </tbody>
                    {!props.printMode && !isTableEmpty &&
                        <PaginatedFooter
                            colSpan={9}
                            state={pagination}
                            onChange={setPagination}
                            totalCount={props.monitoringItems.length}
                            actionButtons={
                                <TableActionButtons
                                    hideGoToSamples
                                    onExport={() => exportMissedMonitoringTable(getDateRange(timeService, props.filter))}
                                    exportButtonDisabled={isTableEmpty}
                                    disabledGoToSamplesButton={isTableEmpty}
                                />
                            }
                        />
                    }
                </Table>
                {props.printMode && !isTableEmpty &&
                    <TableFooter report={props.filter} />
                }
            </div>
        </div>
    )
}

function formatMissingSamples(missingSamples: SampleTypeDetails[], rowIndex: number) {
    return (
        missingSamples.map((_, index) =>
            <MissedSampleTypeIcon
                key={`icon-${rowIndex}-${index}`}
                sampleTypeDetails={_}
                testId={`missed-monitoring-${rowIndex}`}
            />
        )
    )
}

function getDisplayedItems(
    monitoringItems: MissedMonitoringTableItem[],
    pagination: PaginationState,
    sort: SortState
) {
    const end = pagination.start + pagination.count
        , sortArray = sort.sort.split(':')
        , sortField = sortableFields.find(it => it === sortArray[0]) ?? 'scheduledExposureDate'
        , sortOrder = sortArray[1]

    return monitoringItems
        .sort((one, two) => {
            if (sortOrder == 'desc') {
                return compare(two[sortField], one[sortField])
            }

            return compare(one[sortField], two[sortField])
        })
        .slice(pagination.start, end)
}

export default MissedMonitoringTable
