import { dropFields, shallowUpdate } from '_/utils/object'
import type TimeService from '_/services/time-service'
import * as fieldIndex from '_/constants/custom-field-index'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type * as tr from '_/model/predefined-lists/trend-settings/types'
import type * as ti from '_/model/trends/types'
import * as ft from '_/model/predefined-lists/trend-settings/filter-type'
import type { IdentificationRow } from '_/model/sample/reading/sample-reading'
import * as vt from '_/model/predefined-lists/trend-settings/value-type'
import type { NonViableSampleEditRequest } from '_/model/non-viable-sample/booking/types'
import { getFieldValue } from '_/features/samples/helpers'
import { FINGERDAB_TWO_HANDS_PLATE } from '_/constants/plate-type'
import { equals } from '_/model/date-time'

function convertFromServerFields(timeService: TimeService, _: any): any {
    const exposureDateField = _.fields.find((_: FieldValues) => _.index === fieldIndex.EXPOSURE_DATE)
        , startTimeField = _.fields.find((_: FieldValues) => _.index === fieldIndex.EXPOSURE_START_TIME)
        , endTimeField = _.fields.find((_: FieldValues) => _.index === fieldIndex.EXPOSURE_END_TIME)
        , endDateField = _.fields.find((_: FieldValues) => _.index === fieldIndex.EXPOSURE_END_DATE)
        , startTime = timeService.splitCtzDateTime(startTimeField.value)
        , endTime = timeService.splitCtzDateTime(endTimeField.value)
        , startDate = timeService.ctzDayStart(exposureDateField.value)
        , endDate = endDateField?.value && timeService.ctzDayStart(endDateField.value)
        , isFingerdabTwoHandsPlate = getFieldValue(_.fields, fieldIndex.PLATE_TYPE) === FINGERDAB_TWO_HANDS_PLATE

    _.fields.forEach((_: FieldValues) => {
        if (_.index === fieldIndex.EXPOSURE_START_TIME)
            _.value = startTimeField.notRecorded ? undefined : startTime.time

        if (_.index === fieldIndex.EXPOSURE_END_TIME)
            _.value = endTimeField.notRecorded ? undefined : endTime.time

        if ((_.index === fieldIndex.OPERATORS_IDS || _.index === fieldIndex.BATCH_NUMBER) && _.value)
            _.value = JSON.parse(_.value)
    })

    const startDateField = {
            index: fieldIndex.EXPOSURE_START_DATE,
            notRecorded: startTimeField.notRecorded,
            value: startDate,
        }
        , endDateFieldValue = {
            index: fieldIndex.EXPOSURE_END_DATE,
            notRecorded: endTimeField.notRecorded,
            value: endDate ?? startDate,
        }

        , fields = _.fields
            .filter((_: FieldValues) => _.index !== fieldIndex.EXPOSURE_DATE && _.index !== fieldIndex.EXPOSURE_END_DATE)
            .concat(startDateField, endDateFieldValue)

    const identifications = {
                rows: convertIdentifications(_.identifications),
                complete: _.identificationComplete,
            }
        , optionalIdentifications = _.optionalIdentifications || isFingerdabTwoHandsPlate
            ? {
                rows: convertIdentifications(_.optionalIdentifications),
                complete: _.optionalIdentificationComplete,
            }
            : undefined

    return dropFields(shallowUpdate(_, { fields, identifications, optionalIdentifications }), 'identificationComplete', 'optionalIdentificationComplete')
}

function convertIdentifications(identifications: IdentificationRow[] | undefined) {
    if (identifications === undefined)
        return []

    if (identifications.length === 0)
        return [{ cfuCount: 0, types: [{}], objectionable: false, reference: 0}]

    return identifications
}

function convertToServerFields(timeService: TimeService, fields: FieldValues[] | undefined) {
    if (!fields)
        return fields

    const startTimeField = fields.find(_ => _.index === fieldIndex.EXPOSURE_START_TIME)
        , endTimeField = fields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)
        , startDateField = fields.find(_ => _.index === fieldIndex.EXPOSURE_START_DATE)
        , endDateField = fields.find(_ => _.index === fieldIndex.EXPOSURE_END_DATE)
        , startTime = startTimeField && startTimeField.value && !startTimeField.notRecorded ? startTimeField.value : '12:00'
        , startTimeValue = startDateField && timeService.combineCtzDateTime(startDateField.value, startTime)
        , endTime = endTimeField && endTimeField.value
        , endTimeValue = endDateField && timeService.combineCtzDateTime(endDateField.value, endTime)

    const newFields = fields
        .map(_ => {
            if (_.index === fieldIndex.EXPOSURE_START_TIME)
                return Object.assign({}, _, { value: startTimeField?.value || startTimeField?.notRecorded ? startTimeValue : undefined })

            if (_.index === fieldIndex.EXPOSURE_END_TIME)
                return {..._, value: !endTime ? undefined : endTimeValue }

            if (_.index === fieldIndex.EXPOSURE_END_DATE)
                return { ..._, value: equals(startDateField?.value, endDateField?.value) ? undefined : endTimeValue }

            if (_.index === fieldIndex.OPERATORS_IDS && _.value)
                return Object.assign({}, _, { value: JSON.stringify(_.value) })

            return _
        })
        .filter(_ => _.index > 0)

    if (newFields.some(_ => _.index === fieldIndex.EXPOSURE_START_TIME))
        newFields.push({ index: fieldIndex.EXPOSURE_DATE, notRecorded: false, value: startTimeValue })

    return newFields
}

//Backend partial model binder cannot properly bind model if model class have 2 or more properties with the same type which is not.net native type!
function convertToServiceNonViableSample(entity: Partial<NonViableSampleEditRequest>) {
    const newEntity: any = { ...dropFields(entity, 'lowerParticle', 'higherParticle') }

    if (entity.lowerParticle?.hasOwnProperty('count'))
        newEntity.lowerParticleCount = entity.lowerParticle.count

    if (entity.lowerParticle?.hasOwnProperty('notRecorded'))
        newEntity.lowerParticleNotRecorded = entity.lowerParticle.notRecorded

    if (entity.lowerParticle?.hasOwnProperty('volume'))
        newEntity.lowerParticleVolume = entity.lowerParticle.volume

    if (entity.higherParticle?.hasOwnProperty('count'))
        newEntity.higherParticleCount = entity.higherParticle.count

    if (entity.higherParticle?.hasOwnProperty('notRecorded'))
        newEntity.higherParticleNotRecorded = entity.higherParticle.notRecorded

    if (entity.higherParticle?.hasOwnProperty('volume'))
        newEntity.higherParticleVolume = entity.higherParticle.volume

    return newEntity
}

function convertTrendFromServerFilter(_: tr.ServerTrendSettings) {
    const serverFilters = _.filters
    if (!serverFilters)
        return { ..._, filters: [] }

    const hasTheSameIdentification = serverFilters.hasTheSameIdentification.map(_ => ({..._, type: ft.HAS_THE_SAME_IDENTIFICATION as typeof ft.HAS_THE_SAME_IDENTIFICATION}))
        , hasIdentification = serverFilters.hasIdentification.map(_ => ({..._, type: ft.HAS_IDENTIFICATION as typeof ft.HAS_IDENTIFICATION}))
        , inFilters = serverFilters.in.map(_ => ({ ..._, type: ft.IN as typeof ft.IN }))
        , hasTheSameFilters = serverFilters.hasTheSame.map(_ => ({..._, type: ft.HAS_THE_SAME as typeof ft.HAS_THE_SAME}))
        , hasGuidFilters = serverFilters.hasGuid.map(_ => ({ ..._, type: ft.HAS as typeof ft.HAS, valueType: vt.GUID as typeof vt.GUID }))
        , hasStringFilters = serverFilters.hasString.map(_ => ({ ..._, type: ft.HAS as typeof ft.HAS, valueType: vt.STRING as typeof vt.STRING }))
        , hasTimeFilters = serverFilters.hasTime.map(_ => ({ ..._, type: ft.HAS as typeof ft.HAS, valueType: vt.TIME as typeof vt.TIME }))
        , hasNumberFilters = serverFilters.hasNumber.map(_ => ({ ..._, type: ft.HAS as typeof ft.HAS, valueType: vt.NUMBER as typeof vt.NUMBER }))
        , filters: tr.Filter[] = [...hasTheSameIdentification, ...hasIdentification, ...hasTheSameFilters, ...inFilters, ...hasGuidFilters, ...hasStringFilters, ...hasTimeFilters, ...hasNumberFilters]

    if (serverFilters.inTheSame) {
        filters.push({ ...serverFilters.inTheSame, type: ft.IN_THE_SAME as typeof ft.IN_THE_SAME })
    }

    return { ..._, filters: filters.sort((a, b) => a.order - b.order) }
}

function convertTrendToServerFilter(_: tr.TrendSettingsEdit) {
    if (!_.filters)
        return _

    const filters = _.filters.map((filter, index) => ({ ...filter, order: index }))
        , hasGuidFilters = getFilters(filters, _ => _.type === ft.HAS && _.valueType === vt.GUID)
        , hasStringFilters = getFilters(filters, _ => _.type === ft.HAS && _.valueType === vt.STRING)
        , hasTimeFilters = getFilters(filters, _ => _.type === ft.HAS && _.valueType === vt.TIME)
        , hasNumberFilters = getFilters(filters, _ => _.type === ft.HAS && _.valueType === vt.NUMBER)
        , inTheSameFilters = getFilters(filters, _ => _.type === ft.IN_THE_SAME)
        , hasTheSameFilters = getFilters(filters, _ => _.type === ft.HAS_THE_SAME)
        , inFilters = getFilters(filters, _ => _.type === ft.IN)
        , hasIdentificationFilters = getFilters(filters, _ => _.type === ft.HAS_IDENTIFICATION)
        , hasTheSameIdentificationFilters = getFilters(filters, _ => _.type === ft.HAS_THE_SAME_IDENTIFICATION)

    return { ..._, filters: { hasGuid: hasGuidFilters, hasString: hasStringFilters, hasTime: hasTimeFilters, hasNumber: hasNumberFilters, inTheSame: inTheSameFilters.length > 0 ? inTheSameFilters[0] : null, hasTheSame: hasTheSameFilters, in: inFilters, hasIdentification: hasIdentificationFilters, hasTheSameIdentification: hasTheSameIdentificationFilters }}
}

function getFilters(filters: tr.FilterEdit[], predicate: (_: tr.FilterEdit) => boolean) {
    return filters
        .filter(predicate)
        .map(_ => _.type === ft.HAS ? dropFields(_, 'type', 'valueType') : dropFields(_, 'type'))
}

function convertFromServerTrend(_: ti.ServerTrend) {
    const metadata = _.metadata
        , theSameFiltersInfo = Object.keys(metadata).map(_ => {
            switch(_) {
                case 'hasTheSame': return { type: ft.HAS_THE_SAME, filters: metadata.hasTheSame }
                case 'inTheSame': return { type: ft.IN_THE_SAME, filters: metadata.inTheSame }
                case 'hasTheSameIdentification': return { type: ft.HAS_THE_SAME_IDENTIFICATION, filters: metadata.hasTheSameIdentification }
            }
        }
    )
    return { ...dropFields(_, 'metadata'), theSameFiltersInfo } as ti.Trend
}

export {
    convertFromServerFields,
    convertToServerFields,
    convertTrendFromServerFilter,
    convertTrendToServerFilter,
    convertFromServerTrend,
    convertToServiceNonViableSample,
}
