import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, isEmpty, required, allowedName } from '_/utils/form/validate'

import { NUMBER } from '_/constants/custom-field-column-type'
import type { CustomFieldEdit } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import { PROHIBITED_FIELD_NAMES } from './prohibited-field-names'

function validate(entity: CustomFieldEdit, customFields: CustomField[]) {
    const name = isEmpty('Name')(entity.fieldName)
        , nameLength = maxLength('Name', 64)(entity.fieldName)
        , notAllowedNames = allowedName('Name', PROHIBITED_FIELD_NAMES)(entity.fieldName)
        , fieldType = required('Type')(entity.fieldType)
        , duplicate = entity.fieldName
                        && customFields
                            .filter(_ => _.id !== entity.id)
                            .find(_ => _.fieldName.trim().toUpperCase() === entity.fieldName!.trim().toUpperCase())
        , measureUnit = entity.fieldType === NUMBER && maxLength('Unit of measure', 20)(entity.numberMeasureUnit?.trim())
        , allowedValues = entity.fieldType === NUMBER && required('Allowed values')(entity.allowedValues)
        , result: ValidationResult<CustomFieldEdit> = {}

    if (name)
        result.fieldName = name

    if (nameLength)
        result.fieldName = nameLength

    if (notAllowedNames)
        result.fieldName = notAllowedNames

    if (fieldType)
        result.fieldType = fieldType

    if (duplicate)
        result.fieldName = `Field with name '${entity.fieldName!.trim()}' already exists`

    if (measureUnit)
        result.numberMeasureUnit = measureUnit

    if (allowedValues)
        result.allowedValues = allowedValues

    return result
}

export default validate
