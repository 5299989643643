import { useAction, classnames, useSelector, useState } from '_/facade/react'

import type { SampleSession } from '_/model/predefined-lists/session/types'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'
import type { Session as DayPlanSession, NewGroup, CopyGroupsRequest, Group, GroupData } from '_/model/scheduling/day-scheduler/types'

import Button from '_/components/button'

import * as routes from '_/constants/routes'
import * as routerActions from '_/features/routing/actions'

import { VOID_ID } from '_/utils/tree'

import MonitoringGroupModal from '../monitoring-groups/monitoring-group-modal'
import * as dayPlanActions from './actions'
import * as toastActions from '_/features/toasts/actions'
import AddGroupMenu from './menu/add-group-menu'
import CopyGroupMenu from './menu/copy-group-menu'
import GroupItem from './group'
import type { MonitoringGroupEdit } from '_/model/scheduling/monitoring-groups/types'
import * as h from '_/model/scheduling/day-scheduler/helpers'
import type { DateTime } from '_/model/date-time'
import * as t from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'

type Session = Pick<SampleSession, 'id' | 'name' | 'isActive'>

interface Props {
    date: DateTime
    sampleSession: Session
    sampleSessions: Session[]
    sessions: DayPlanSession[]
    groupModalId: string | undefined
    copyToModalId: string | undefined
    monitoringGroups: MonitoringGroup[]
    adHocGroups: MonitoringGroup[]
    selectedGroup: GroupData
    adHocGroupSessionId: string | undefined
    onChangeAdHocGroupSessionId: (id?: string) => void
    onChangeGroupModalId: (id?: string) => void
    onChangeCopyToModalId: (id?: string) => void
    onChangeSelectedGroup: (_: GroupData) => void
    onReloadAdHocGroups: () => void
    onReloadDayPlan: () => void
}

function SessionScheduler(props: Props) {
    const permissions = useSelector(_ => _.auth.permissions)
        , [addGroupBtn, setAddGroupBtn] = useState<HTMLButtonElement | null>(null)
        , [copyToBtn, setCopyToBtn] = useState<HTMLButtonElement | null>(null)
        , groups = props.sessions.find(_ => _.id === props.sampleSession.id)?.groups ?? []
        , dayPlan = {date: props.date, sessionId: props.sampleSession.id}
        , { date } = props
        , createAddHocGroup = useCreateAdHocGroup(date, props.sampleSession.id, props.onReloadDayPlan)
        , addGroup = useAddGroup(props.onReloadDayPlan)
        , copyGroups = useCopyGroups(props.onReloadDayPlan, props.sessions)
        , hideRightBorder = props.sampleSession.id == VOID_ID
        , isActiveSession = props.sampleSession.isActive
        , sampleSessions = props.sampleSessions.filter(_ => _.id !== props.sampleSession.id && _.isActive) as SampleSession[]
        , [showCreateAdHocGroupModal, openCreateAdHocGroupModal, closeCreateAdHocGroupModal] = useAdHocGroupModal(props.onChangeAdHocGroupSessionId, props.date, props.sampleSession.id, props.adHocGroupSessionId)
        , sessionName = props.sampleSession.id === VOID_ID
            ? [t.systemTextNode(props.sampleSession.name)]
            : [t.defaultTextNode(`Session ${props.sampleSession.name}`)]

    function handleAdGroupAdded() {
        closeCreateAdHocGroupModal()
        props.onReloadAdHocGroups()
    }

    return (
        <div className={classnames(
            'flex-fill d-flex flex-shrink-0 flex-column mb-4 mt-2 planner-session-border--color border planner-session--width overflow-auto',
            { 'border-end': hideRightBorder, 'border-end-0': !hideRightBorder, 'bg-light': !isActiveSession }
        )}>
            <div className={classnames('d-flex justify-content-between align-items-center planner-session-header--height p-2 planner-session-border--color border-bottom')}>
                <span className='text-secondary fw-bold me-1' data-testid='day-scheduler-session'>
                    <FormattedText text={sessionName} modifier='table-header'/>
                </span>
                <div className='d-flex flex-nowrap'>
                    <Button
                        className='btn-light text-primary fw-bold me-1'
                        onClick={openCreateAdHocGroupModal}
                        hasNoPermissions={!permissions.editSchedule}
                        disabled={!isActiveSession}
                        testId={`add-sample-${t.plainText(sessionName)}`}
                    >
                        Add a viable sample
                    </Button>
                    <Button
                        className='btn-light text-primary fw-bold me-1'
                        onClick={() => {
                            props.onChangeGroupModalId(props.sampleSession.id)
                            props.onChangeCopyToModalId()
                        }}
                        ref={setAddGroupBtn}
                        hasNoPermissions={!permissions.editSchedule}
                        disabled={!isActiveSession}
                        testId={`add-group-${t.plainText(sessionName)}`}
                    >
                        Add a group
                    </Button>
                    <Button
                        onClick={() => {
                            props.onChangeCopyToModalId(props.sampleSession.id)
                            props.onChangeGroupModalId()
                        }}
                        className='btn-light text-primary fw-bold'
                        ref={setCopyToBtn}
                        hasNoPermissions={!permissions.editSchedule}
                        disabled={!isActiveSession || groups.length === 0 || sampleSessions.length === 0}
                        testId={`copy-to-${t.plainText(sessionName)}`}
                    >
                        Copy to
                    </Button>
                </div>
            </div>
            <div className={classnames('flex-fill flex-column border-top-0 overflow-auto')}>
                {groups.map((_, index) =>
                    <GroupItem
                        key={index}
                        index={index}
                        group={_}
                        date={date}
                        groupName={formatGroupName(_, _.isAdHoc ? props.adHocGroups : props.monitoringGroups)}
                        session={props.sampleSession}
                        selectedGroup={props.selectedGroup}
                        onChangeSelectedGroup={props.onChangeSelectedGroup}
                        onReloadAdHocGroups={props.onReloadAdHocGroups}
                        onReloadDayPlan={props.onReloadDayPlan}
                    />
                )}
            </div>

            {showCreateAdHocGroupModal &&
                <MonitoringGroupModal
                    allGroups={props.adHocGroups}
                    onUpdate={handleAdGroupAdded}
                    onClose={closeCreateAdHocGroupModal}
                    onCreateAdHocGroup={createAddHocGroup}
                    isAdHocGroup
                />
            }
            {props.groupModalId === props.sampleSession.id &&
                <AddGroupMenu
                    button={addGroupBtn}
                    planGroups={groups}
                    monitoringGroups={props.monitoringGroups.filter(_ => _.isActive)}
                    onAddGroup={monitoringGroupId => addGroup({date, monitoringGroup: {...dayPlan, monitoringGroupId}})}
                    onClose={props.onChangeGroupModalId}
                />
            }
            {props.copyToModalId === props.sampleSession.id &&
                <CopyGroupMenu
                    button={copyToBtn}
                    sessions={sampleSessions}
                    onCopyGroups={toSessionId => copyGroups({date, request: { date: dayPlan.date, fromSessionId: props.sampleSession.id, toSessionId }})}
                    onClose={props.onChangeCopyToModalId}
                />
            }
        </div>
    )
}

function useCreateAdHocGroup(date: DateTime, sessionId: string, reloadDayPlan: () => void) {
    const addAdHocGroup = useAction(dayPlanActions.addAdHocGroup)

    function createAddHocGroup(group: MonitoringGroupEdit) {
        return addAdHocGroup({ date, request: {...group, sessionId} })
            .then(reloadDayPlan)
    }

    return createAddHocGroup
}

function useAdHocGroupModal(onChangeAdHocGroupSessionId: (_: string | undefined) => void, date: DateTime, sessionId: string, adHocGroupSessionId: string | undefined) {
    const navigateTo = useAction(routerActions.navigateTo)
        , routeName = useSelector(_ => _.router.route?.name)
        , showCreateAdHocGroupModal = routeName === routes.SCHEDULING_DAY_SCHEDULER_CREATE_AD_HOC_GROUP
            && adHocGroupSessionId === sessionId

    function closeCreateAdHocGroupModal() {
        navigateTo(routes.SCHEDULING_DAY_SCHEDULER, { date })
        if (routeName !== routes.SCHEDULING_DAY_SCHEDULER_CREATE_AD_HOC_GROUP)
            onChangeAdHocGroupSessionId(undefined)
    }

    function openCreateAdHocGroupModal() {
        onChangeAdHocGroupSessionId(sessionId)
        navigateTo(routes.SCHEDULING_DAY_SCHEDULER_CREATE_AD_HOC_GROUP, { date })
    }

    return [showCreateAdHocGroupModal, openCreateAdHocGroupModal, closeCreateAdHocGroupModal] as const
}

function useAddGroup(reloadDayPlan: () => void) {
    const addGroup = useAction(dayPlanActions.addGroup)

    function handleAddGroup(data: {date: DateTime, monitoringGroup: NewGroup}) {
        return addGroup(data)
            .then(reloadDayPlan)
    }

    return handleAddGroup
}

function useCopyGroups(reloadDayPlan: () => void, sessions: DayPlanSession[]) {
    const addGroup = useAction(dayPlanActions.copyGroups)
        , addError = useAction(toastActions.addError)

    function handleCopyGroups(data: {date: DateTime, request: CopyGroupsRequest}) {
        const fromSessionGroups = sessions.find(_ => _.id === data.request.fromSessionId)?.groups.map(_ => _.id) ?? []
            , toSessionsGroups = sessions.find(_ => _.id === data.request.toSessionId)?.groups.map(_ => _.id) ?? []
            , uniqueGroups = fromSessionGroups.filter(_ => toSessionsGroups.indexOf(_) === -1)

        if (uniqueGroups.length === 0) {
            addError('Group duplication in a session not allowed')
            return
        }

        return addGroup(data)
            .then(reloadDayPlan)
    }

    return handleCopyGroups
}

function formatGroupName(group: Group, monitoringGroups: MonitoringGroup[]) {
    const matchingMonitoringGroup = monitoringGroups.find(_ => _.id === group.id)
        , isAdHoc = group.isAdHoc
        , groupName = h.formatGroupName(matchingMonitoringGroup?.name ?? '', matchingMonitoringGroup?.isActive, isAdHoc)

    return monitoringGroups.length === 0 || matchingMonitoringGroup?.expectations.some(e => e.sampleTypeQuantities.some(q => q.quantity > 0))
        ? groupName
        : [...groupName, t.systemTextNode(' (empty)')]
}

export default SessionScheduler
