export const DAY = 0
           , WEEK = 1
           , MONTH = 2

type DAY = typeof DAY
type WEEK = typeof WEEK
type MONTH = typeof MONTH

import type * as self from './frequency-type'
type FrequencyType = Extract<(typeof self)[keyof typeof self], number>

const FREQUENCY_TYPE: readonly { id: FrequencyType, name: string }[] = [
    {
        id: DAY,
        name: 'Day(s)',
    },
    {
        id: WEEK,
        name: 'Week(s)',
    },
    {
        id: MONTH,
        name: 'Month(s)',
    },
]

export {
    FREQUENCY_TYPE,
    FrequencyType,
}
