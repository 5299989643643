import type { Confirmation as State } from '_/model/app-state'

import { commonReducer, handler } from '_/facade/reducer'
import { shallowUpdate } from '_/utils/object'

import * as actions  from './actions'

const defaultState: State = {
    confirmationType: undefined,
    message: undefined,
    warning: undefined,
}

export default commonReducer(
    defaultState,
    handler(actions.confirmationModalOpened, (state, payload) =>
        shallowUpdate(state, {
            confirmationType: payload.type,
            message: payload.message,
            warning: payload.warning,
        }
    )),

    handler(actions.confirmationModalClosed, (state) =>
        shallowUpdate(state, defaultState)),
)
