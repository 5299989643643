import { useState } from '_/facade/react'
import type { DashboardDailySamplesStatistic } from '_/model/dashboard/types'
import HoverMenu from '_/components/overlay/hover-menu'

interface Props {
    dailyStats: DashboardDailySamplesStatistic
    maxTotal: number
    children: React.ReactNode
}

export default function DataLegend(props: Props) {
    function calculateDailyTotal(stats: DashboardDailySamplesStatistic) {
        return stats.awaitingIdentificationSamplesCount +
            stats.bookedInSamplesCount +
            stats.processedSamplesCount
    }

    const stats = props.dailyStats
        , dailyTotal = calculateDailyTotal(stats)
        , [element, setElement] = useState<HTMLDivElement | null>(null)
        , maxTotal = props.maxTotal
        , topOffset = dailyTotal === maxTotal ? 1 : dailyTotal / maxTotal

    return (
        <div className='data-legend ms-2'>
            {dailyTotal > 0 &&
                <HoverMenu element={element} topOffset={topOffset} position='top'>
                    <div className='data-legend--bubble data-legend--triangle ms-2'>
                        <div className='data-legend--message'>
                            {stats.noGrowthSamplesCount > 0 &&
                                <div>
                                    <div className='dashboard-graph--legend dashboard-graph--nogrowth-identified'/>
                                    &nbsp;
                                    <span>No Growth: {stats.noGrowthSamplesCount}</span>
                                </div>
                            }
                            {stats.growthsIdentifiedSamplesCount > 0 &&
                                <div>
                                    <div className='dashboard-graph--legend dashboard-graph--growth-identified'/>
                                    &nbsp;
                                    <span>Growths (ID complete): {stats.growthsIdentifiedSamplesCount}</span>
                                </div>
                            }
                            {stats.awaitingIdentificationSamplesCount > 0 &&
                                <div>
                                    <div className='dashboard-graph--legend dashboard-graph--awaiting-identification'/>
                                    &nbsp;
                                    <span>Growths (awaiting identification): {stats.awaitingIdentificationSamplesCount}</span>
                                </div>
                            }
                            {stats.bookedInSamplesCount > 0 &&
                                <div>
                                    <div className='dashboard-graph--legend dashboard-graph--booked-in'/>
                                    &nbsp;
                                    <span>Booked in: {stats.bookedInSamplesCount}</span>
                                </div>
                            }
                        </div>
                    </div>
                </HoverMenu>
            }
            <div className='data-legend--trigger' ref={setElement}>
                {props.children}
            </div>
        </div>
    )
}
