import { classnames } from '_/facade/react'

import { Table } from '_/components/table'
import PageHeader from '_/components/page-header'

import type { FingerdabOverviewByOperatorItems } from '_/model/fingerdab-overview-by-operator/types'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    operatorsWithoutFingerdabs: FingerdabOverviewByOperatorItems[]
    showSpinner: boolean
}

const FingerdabOverviewByOperator: React.FC<Props> = (props) =>
    <div className='daily-fingerdabs-content-container'>
        <PageHeader
            sticky
            title='Operators without fingerdabs'
        />
        <Table>
            <thead className='thead table-header--sticky table-header--default-offset'>
                <tr className='table-header-thin'>
                    <th style={{ width: '7em' }}>Operator</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {props.showSpinner
                    ? <tr>
                        <td className='text-center' colSpan={2}>
                            <div className='position-relative py-3'>
                                <i className='preview-spinner material-icons md-48'>sync</i>
                            </div>
                        </td>
                    </tr>
                    : props.operatorsWithoutFingerdabs.map((item, rowIndex) =>
                        <tr key={rowIndex}>
                            <td className={classnames({'border-top-0': rowIndex === 0 })}>
                                <FormattedText text={item.operatorName} />
                            </td>

                            <td className={classnames('text-center', {'border-top-0': rowIndex === 0})}>
                                <span className='text-muted text-uppercase'>
                                    No fingerdabs recorded on this day
                                </span>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </Table>
    </div >

export default FingerdabOverviewByOperator
