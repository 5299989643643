import { classnames } from '_/facade/react'

import { NOT_RECORDED } from '_/constants/common-messages'
import * as routes from '_/constants/routes'
import * as notificationSampleBreachType from '_/model/notification/notification-sample-breach-type'

import type { SampleLimitBreachedNotification } from '_/model/notification/types'
import { formatExposureDuration } from '_/model/notification/helpers'
import { useTimeService } from '_/components/time'
import { Link } from '_/utils/router'
import { AT_REST } from '_/model/predefined-lists/action-alert-limit/monitoring-state'

interface Props {
    notification: SampleLimitBreachedNotification
    onClick: () => void
    dropdownLayout: boolean
    testId?: string
}

function NotificationBreach(props: Props) {
    const data = props.notification.data
        , timeService = useTimeService()
        , locationName = `${data.exposureLocationName ?? NOT_RECORDED} ${data.monitoringState === AT_REST ? '(At rest)' : ''}`

    function breachLabelText() {
        switch (data.type) {
            case notificationSampleBreachType.ACTION_LIMIT:
                return 'Action limit breached'
            case notificationSampleBreachType.ALERT_LIMIT:
                return 'Alert limit breached'
            case notificationSampleBreachType.COMPROMISED:
                return 'Compromised'
            case notificationSampleBreachType.ALERT_BEHAVIOUR:
                return 'Objectionable alert'
            case notificationSampleBreachType.ACTION_BEHAVIOUR:
                return 'Objectionable action'
            case notificationSampleBreachType.MANUAL_ACTION:
                return 'Action (manual)'
        }
    }

    function breachesText() {
        if (data.type === notificationSampleBreachType.COMPROMISED)
            return ''

        if (data.type === notificationSampleBreachType.ALERT_LIMIT || data.type === notificationSampleBreachType.ACTION_LIMIT)
            return `(${data.cfu} CFU, breaches ${data.limitBreach} CFU ${data.type === notificationSampleBreachType.ACTION_LIMIT ? 'action' : 'alert'} limit)`

        if (data.type === notificationSampleBreachType.MANUAL_ACTION)
            return `(${data.cfu ?? 'unidentified'} CFU, manual action limit breach)`

        return `(${data.cfu} CFU, ${data.type === notificationSampleBreachType.ACTION_BEHAVIOUR ? 'action' : 'alert'} for objectionable organisms)`
    }

    function handleClick(e: React.MouseEvent) {
        e.preventDefault()
        props.onClick()
    }

    return (
        <div className='fw-bold text-white'>
            <span className={classnames('px-1 py-0 rounded text-uppercase notifications-breaches--font-size', {
                'label-compromised--color': data.type === notificationSampleBreachType.COMPROMISED,
                'label-alert--color': data.type === notificationSampleBreachType.ALERT_LIMIT || data.type === notificationSampleBreachType.ALERT_BEHAVIOUR,
                'label-action--color': data.type === notificationSampleBreachType.ACTION_LIMIT || data.type === notificationSampleBreachType.ACTION_BEHAVIOUR || data.type === notificationSampleBreachType.MANUAL_ACTION,
            })} data-testid={`notification-chip-label-${data.type}`}>
                {breachLabelText()}
            </span>
            <br/>
            <Link
                routeName={routes.SAMPLES_EDIT}
                routeParams={{ id: data.entityId }}
                className={classnames('cursor-pointer', { 'text-white': props.dropdownLayout })}
                onClick={handleClick}
                testId={`${props.testId}-link`}
            >
                <span data-testid={`${props.testId}-location-info`}>
                    {locationName}
                    <span className='fw-normal' data-testid={`${props.testId}-exposure-date-info`}>
                        , {timeService.formatCtzDate(data.exposureStartTime)} {formatExposureDuration(timeService, data)}
                    </span>
                </span>
                <br />
                <span data-testid={`${props.testId}-sample-type-info`}>
                    {data.sampleTypeName} {breachesText()}
                </span>
            </Link>
            <br />
            <span className='text-secondary fw-normal' data-testid={`${props.testId}-context-info`}>{props.notification.contextName}</span>
        </div>
    )
}

export default NotificationBreach
