import * as fieldIndex from '_/constants/custom-field-index'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import type { FingerdabOverviewByOperatorItems } from '_/model/fingerdab-overview-by-operator/types'
import type * as FingerdabOverviewByOperatorEntities from '_/model/fingerdab-overview-by-operator/types'
import { getFieldValue } from '../samples/helpers'
import { comparePlateTypes } from '_/model/scheduling/monitoring-overview/helpers'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import { NOT_RECORDED_SHORT } from '_/constants/system-words'
import { formatActiveState } from '_/utils/format/common'
import * as f from '_/model/sample/format'
import { systemTextNode } from '_/model/text/text'

function isSamplesWithActiveSession(activeSampleSession: SampleSession[], sessionName: string) {
    return activeSampleSession.some(s => s.name === sessionName)
}

function calculateSamplesCountForSession(items: FingerdabOverviewByOperatorItems[], sessionName: string) {

    function findSamplesBySession(samples: FingerdabOverviewByOperatorEntities.SampleData[][]) {
        const sessionSamples = samples.find(_ => _.some(s => getFieldValue(s.fields, fieldIndex.SESSION_NAME) === sessionName))

        return sessionSamples ? sessionSamples.length : 0
    }

    return items.reduce((acc, _) => acc + findSamplesBySession(_.samples), 0)
}

function calculateSamplesCountWithoutSession(items: FingerdabOverviewByOperatorItems[], sampleSession: SampleSession[]) {

    function findSamplesWithoutSession(samples: FingerdabOverviewByOperatorEntities.SampleData[][]) {
        const sessionSamples = samples.find(_ => _.some(samples => !isSamplesWithActiveSession(sampleSession, getFieldValue(samples.fields, fieldIndex.SESSION_NAME))))

        return sessionSamples ? sessionSamples.length : 0
    }

    return items.reduce((acc, _) => acc + findSamplesWithoutSession(_.samples), 0)
}

function calculateSamplesCount(items: FingerdabOverviewByOperatorItems[]) {
    return items.reduce((acc, _) => acc + _.samples.reduce((acc, _) => acc + _.length, 0), 0)
}

function getSampleData<T extends FingerdabOverviewByOperatorEntities.SampleData>(
    allSamples: T[],
    sampleSession: SampleSession[],
    isSessionApplicable: (sample: T, session: SampleSession) => boolean,
    areOtherSessionApplicable: (sample: T, allSessions: SampleSession[]) => boolean
) {
    const sessionSamples = sampleSession.map(session => allSamples.filter(_ => isSessionApplicable(_, session)))
        , otherSamples = allSamples.filter(_ => areOtherSessionApplicable(_, sampleSession))
        , samples = sessionSamples.concat([otherSamples])
            .map(samples => samples
                .slice()
                .sort((one, two) => {
                    const plateTypeOne = getFieldValue(one.fields, fieldIndex.PLATE_TYPE)
                        , plateTypeTwo = getFieldValue(two.fields, fieldIndex.PLATE_TYPE)

                    return comparePlateTypes(plateTypeOne, plateTypeTwo)
                })
            )
    return samples
}

function formatLocationName(sampleData: FingerdabOverviewByOperatorEntities.SampleData, locations: ListExposureLocation[]) {
    const locationId = getFieldValue(sampleData.fields, fieldIndex.EXPOSURE_LOCATION_ID)
        , location = locations.find(_ => _.id === locationId)
    return location
        ? f.atRestTextFormat(formatActiveState(location.pathName, location.isActive), sampleData.monitoringState)
        : f.atRestTextFormat([systemTextNode(NOT_RECORDED_SHORT)], sampleData.monitoringState)
}

export {
    isSamplesWithActiveSession,
    calculateSamplesCount,
    calculateSamplesCountForSession,
    calculateSamplesCountWithoutSession,
    getSampleData,
    formatLocationName,
}
