import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'

import type PredefinedListsService from '_/services/predefined-lists-service'
import * as a from './actions'

const factory = (api: PredefinedListsService): EffectsFactory => _ => [
    handler(a.loadAllIdentifications, api.identifications.list),
]

export default factory
