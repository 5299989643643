import { Field } from 'react-final-form'
import { classnames } from '_/facade/react'

import { showFieldError } from './helpers'
import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'

interface InputFieldProps {
    id?: string
    value?: string
    name: string
    className?: string
    tabIndex?: number
    inputRef?: React.RefObject<HTMLInputElement>
    disabled?: boolean
    hasNoPermissions?: boolean
    autoFocus?: boolean
    onClick?: () => void
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    testId?: string
    title?: string
}

const CheckboxField: React.FC<InputFieldProps> = props =>
    <Field name={props.name} type='checkbox' value={props.value} render={_ =>
        <div className={`form-check d-print-none ${props.className || ''}`}>
            <input
                className={classnames('form-check-input', { 'is-invalid': showFieldError(_.meta) })}
                id={props.id}
                type='checkbox'
                {..._.input}
                tabIndex={props.tabIndex}
                ref={props.inputRef}
                disabled={props.hasNoPermissions || props.disabled}
                autoFocus={props.autoFocus}
                onClick={props.onClick}
                data-testid={props.testId}
                onKeyDown={props.onKeyDown}
            />
            <label
                className={classnames('form-check-label', {'no-permission': props.hasNoPermissions})}
                htmlFor={props.id}
                title={props.hasNoPermissions ? NO_PERMISSION_MESSAGE : props.title}
                data-testid='field-label'
            >
                {props.children}
            </label>
            {showFieldError(_.meta) && <span className='invalid-feedback' data-testid='validation-error'>{_.meta.error}</span>}
        </div>
    } />

export default CheckboxField
