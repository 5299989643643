import { useAction, useSelector } from '_/facade/react'

import Button from '_/components/button'
import Link, { LinkButton } from '_/components/link'
import * as routes from '_/constants/routes'
import * as a from '_/features/plates/actions'
import * as ra from '_/features/routing/actions'
import type { PlateSearchByBarcodeResult } from '_/model/plate/plate'
import { paramsFilter } from '_/model/filters/helpers'
import SampleSearchCommon from './sample-search-common'

function ColonyCounterPlateSearch() {
    const loadSamples = useAction(a.searchPlatesByBarcode)
        , navigateTo = useAction(ra.navigateTo)
        , permissions = useSelector(_ => _.auth.permissions)

    return (
        <SampleSearchCommon
            readPermission={permissions.readColonyCounterPlates}
            loadByBarcode={loadSamples}
            hasExactMatch={_ => !!_.exactMatch}
            getTotalMatches={_ => _.possibleMatchesCount}
            navigateToSampleDetails={_ => navigateTo(routes.PLATES_READING, { barcode: _ })}
            navigateToSampleList={_ => navigateTo(routes.PLATES, paramsFilter({ barcode: _ }))}
            renderMenu={props => <Menu {...props} />}
        />
    )
}

export default ColonyCounterPlateSearch

interface MenuProps {
    barcode: string
    searchResult: PlateSearchByBarcodeResult
    onClose: () => void
}

function Menu(props: MenuProps) {
    const permissions = useSelector(_ => _.auth.permissions)

    return props.searchResult.possibleMatchesCount > 0
        ? <div>
            This search matches
            <> </>
            <Link
                routeName={routes.PLATES_READING}
                routeParams={{ barcode: props.barcode }}
            >
                1 exact plate
            </Link>
            <> and </>
            <Link
                routeName={routes.PLATES}
                routeParams={paramsFilter({ barcode: props.barcode })}
            >
                {props.searchResult.possibleMatchesCount} possible {props.searchResult.possibleMatchesCount === 1 ? 'plate' : 'plates'}.
            </Link>
            <> </>
            Click one of the options to proceed
        </div>
        : <div>
            <p>We didn't find anything that matches your search. {permissions.performColonyCounterPlateReading && 'Would you like to read a new plate?'}</p>
            <LinkButton
                className='btn-primary'
                routeName={routes.PLATES_READING}
                onClick={props.onClose}
                hasNoPermissions={!permissions.performColonyCounterPlateReading}
            >
                Read plate
            </LinkButton>
            <> </>
            <Button className='btn-secondary' onClick={props.onClose}>
                Cancel
            </Button>
        </div>
}
