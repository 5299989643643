import type ExportService from '_/services/export-service'
import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'

import * as a from './actions'

const factory = (service: ExportService): EffectsFactory => () => {
    return [
        handler(a.downloadSiteExport, service.downloadSiteExport),
        handler(a.downloadSamplesExport, service.downloadSamplesExport),
        handler(a.downloadPlatesExport, service.downloadPlatesExport),
        handler(a.downloadNonViableSamplesExport, service.downloadNonViableSamplesExport),
    ]
}

export default factory
