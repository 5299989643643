import * as t from '_/model/text/text'

type Modifier = 'table-header'

interface Props {
    text: t.Text | string | number | undefined
    modifier?: Modifier
    className?: string
    testId?: string
}

function FormattedText(props: Props) {
    if (!props.text)
        return null

    if (typeof props.text === 'string' || typeof props.text === 'number')
        return <span className={props.className} data-testid={props.testId}>{props.text}</span>

    return (
        <span className={props.className} data-testid={props.testId}>
            {props.text.filter(_ => _.type !== t.EMPTY_TEXT).map(
                (_, key) => <span key={key} className={getCssClass(_, props.modifier)}>{_.value}</span>
            )}
        </span>
    )
}

export default FormattedText

function getCssClass(text: t.TextNode, modifier?: Modifier): string | undefined {
    switch (text.type) {
        case t.SYSTEM_TEXT:
            return `formatted-text__system ${modifier === 'table-header' ? 'formatted-text__system--table-header' : ''}`
        default:
            return undefined
    }
}
