import { useField } from 'react-final-form'
import { SelectField, NumberFieldInline, CheckboxField } from '_/components/form'
import { isIncreasingCfuRule, isLimitRule } from '_/model/predefined-lists/trend-settings/helpers'
import * as rt from '_/model/predefined-lists/trend-settings/rule-type'
import * as tt from '_/model/predefined-lists/trend-settings/timeframe-type'
import type { RuleEdit, Timeframe } from '_/model/predefined-lists/trend-settings/types'

interface Props {
    disabled: boolean | undefined
}

function Rule(props: Props) {
    const [rules, currentRule] = useRules()

    return (
        <div className='mt-3'>
            <h5>Rule</h5>
            <div className='row g-2'>
                <SelectField
                    name='rule.type'
                    entities={rules}
                    calcId={_ => _.id}
                    calcName={_ => _.name}
                    className='col-5'
                    disabled={props.disabled}
                >
                    Rule
                </SelectField>
                {isLimitRule(currentRule) &&
                    <NumberFieldInline
                        name='rule.count'
                        disabled={props.disabled}
                        className='col-2'
                        formGroup
                    >
                        Number
                    </NumberFieldInline>
                }
                {isIncreasingCfuRule(currentRule) &&
                    <div className='form-group col-4'>
                        <label className='col-form-label my-2'></label>
                        <CheckboxField name={`rule.mode`} id={`rule.mode`} disabled={props.disabled} className='mt-2'>
                            Strictly increasing
                        </CheckboxField>
                    </div>
                }
            </div>
        </div>
    )
}

export default Rule

const BASIC_TREND_RULES = [rt.ALERT_LIMIT_BREACHES, rt.ACTION_LIMIT_BREACHES, rt.ACTION_OR_ALERT_LIMIT_BREACHES]
function useRules() {
    const currentRule = useField<RuleEdit | undefined>('rule').input.value
        , timeframe = useField<Timeframe | undefined>('timeframe').input.value

    let rules = BASIC_TREND_RULES.map(_ => rt.TREND_RULE.find(rule => rule.id === _)!)

    if (timeframe?.type !== tt.WITHIN) {
        rules = rules.concat({
            id: isIncreasingCfuRule(currentRule) ? currentRule.type : rt.NON_STRICTLY_INCREASING_CFU_COUNT,
            name: 'Increasing CFU count'
        })
    }

    return [rules, currentRule] as const
}
