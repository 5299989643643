interface Props {
    showSpinner: boolean
    children: React.ReactNode
}

function NoDataAvailable(props: Props) {
    if (props.showSpinner)
        return (
            <div className='position-relative py-3'>
                <i className='preview-spinner material-icons md-48'>sync</i>
            </div>
        )

    return (
        <div>
            {props.children}
        </div>
    )
}

export default NoDataAvailable
