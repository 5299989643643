import { React, useState, useAction, useSelector, useCallback } from '_/facade/react'

import Button from '_/components/button'
import Menu, { useCloseMenu } from '_/components/overlay/menu'

import type { Group, GroupData } from '_/model/scheduling/day-scheduler/types'
import type { SampleSession } from '_/model/predefined-lists/session/types'

import * as r from '_/constants/routes'

import * as dayPlanActions from './actions'
import RecurrenceModal from './recurrence/recurrence'
import { navigateTo } from '_/features/routing/actions'
import * as deletionActions from '_/features/confirmation/actions'
import type { DateTime } from '_/model/date-time'
import type { Text } from '_/model/text/text'
import { defaultTextNode } from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    index: number
    group: Group
    groupName: Text
    session: Pick<SampleSession, 'id' | 'name' | 'isActive'>
    date: DateTime
    selectedGroup: GroupData
    onChangeSelectedGroup: (_: GroupData) => void
    onReloadAdHocGroups: () => void
    onReloadDayPlan: () => void
}

function GroupItem(props: Props) {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
        , [btnElement, setBtnElement] = useState<HTMLElement | null>(null)
        , [showActionMenu, setShowActionMenu] = useState(false)
        , permissions = useSelector(_ => _.auth.permissions)
        , closeMenu = () => setShowActionMenu(false)
        , [showRecurrence, handleCloseRecurrence, handleOpenRecurrence] = useModal(props.index, props.group.id, props.session.id, props.selectedGroup, props.onChangeSelectedGroup)
        , [showDeletionSeriesConfirmation, setShowDeletionSeriesConfirmation] = useState(false)
        , [deleteGroup, deleteSeriesGroup] = useDeleteGroup(props.date, props.group.id, props.session.id, props.group, props.group.isAdHoc, setShowDeletionSeriesConfirmation, props.onReloadAdHocGroups, props.onReloadDayPlan)

    useCloseMenu(containerRef, closeMenu, btnElement)

    function handleClickRemove() {
        closeMenu()

        if (props.group.seriesId)
            setShowDeletionSeriesConfirmation(true)
        else
            deleteGroup()
    }

    return (
        <>
            <div className='d-flex justify-content-between bg-light border group-item--border rounded m-2 p-2'>
                <FormattedText text={props.groupName} className='align-self-center'/>
                <Button
                    onClick={() => setShowActionMenu(true)}
                    className='py-0 px-1 shadow-none text-primary'
                    disabled={!props.session.isActive}
                    hasNoPermissions={!permissions.editSchedule}
                >
                    <i className='material-icons' ref={setBtnElement}>more_horiz</i>
                </Button>
            </div>
            {showActionMenu &&
                <Menu element={btnElement} useParentWidth containerRef={setContainerRef}>
                    <div className='dropdown-menu dropdown-menu-right d-block'>
                        <Button
                            onClick={() => { handleOpenRecurrence(); closeMenu() }}
                            hasNoPermissions={!permissions.editSchedule}
                        >
                            Recurrence
                        </Button>
                        <Button
                            onClick={handleClickRemove}
                            className='text-danger'
                        >
                            Remove
                        </Button>
                    </div>
                </Menu>
            }
            {showRecurrence &&
                <RecurrenceModal
                    session={props.session}
                    group={props.group}
                    date={props.date}
                    onClose={handleCloseRecurrence}
                    onReloadDayPlan={props.onReloadDayPlan}
                />
            }
            {showDeletionSeriesConfirmation &&
                <DeletionSeriesModal
                    close={() => setShowDeletionSeriesConfirmation(false)}
                    confirm={deleteSeriesGroup}
                    group={props.group}
                />

            }
        </>
    )
}

export default GroupItem

function useModal(
    index: number,
    groupId: string,
    sessionId: string,
    selectedGroup: GroupData,
    handleChangeSelectedGroup: (_: GroupData) => void
) {
    const routeName = useSelector(_ => _.router.route?.name)
        , route = useSelector(_ => _.router.route?.name)
        , navigate = useAction(navigateTo)
        , handleClose = useCallback(
            () => {
                navigate(r.SCHEDULING_DAY_SCHEDULER)
                if (route === r.SCHEDULING_DAY_SCHEDULER_CREATE_SERIES)
                    return
                handleChangeSelectedGroup({ groupId: undefined, sessionId: undefined, index: undefined })
            },
            [navigate, handleChangeSelectedGroup, route]
        )
        , handleOpen = useCallback(
            () => {
                navigate(r.SCHEDULING_DAY_SCHEDULER_CREATE_SERIES)
                handleChangeSelectedGroup({ groupId, sessionId, index })
            },
            [navigate, groupId, sessionId, index, handleChangeSelectedGroup]
        )
        , showModal = routeName === r.SCHEDULING_DAY_SCHEDULER_CREATE_SERIES
            && groupId === selectedGroup.groupId
            && sessionId === selectedGroup.sessionId
            && index === selectedGroup.index

    return [showModal, handleClose, handleOpen] as const
}

function useDeleteGroup(
    date: DateTime,
    monitoringGroupId: string,
    sessionId: string,
    group: Group,
    isAdHocGroup: boolean,
    closeDeleteSeriesGroupModal: (_: boolean) => void,
    onReloadAdHocGroups: () => void,
    onReloadDayPlan: () => void
) {
    const deleteGroup = useAction(dayPlanActions.deleteGroup)
        , deleteSeries = useAction(dayPlanActions.deleteSeries)
        , showDeletionConfirmation = useAction(deletionActions.showDeletionConfirmationModal)

    function handleDeleteGroup() {
        return showDeletionConfirmation([defaultTextNode('Are you sure you want to delete '), ...formatGroupName(group.name, group.isActive, group.isAdHoc), defaultTextNode('?')])
            .then(() => deleteGroup({date, monitoringGroupId, sessionId}))
            .then(() => {
                onReloadDayPlan()
                if (isAdHocGroup)
                    onReloadAdHocGroups()
            })
    }

    function handleDeleteSeries(applyOnlyForCurrentGroup: boolean) {
        if (!group.seriesId)
            return

        closeDeleteSeriesGroupModal(false)
        deleteSeries({
            id: group.seriesId,
            request: {
                applyOnlyForCurrentGroup,
                date,
            }
        }).then(() => {
            onReloadDayPlan()
            if (isAdHocGroup)
                onReloadAdHocGroups()
        })
    }

    return [handleDeleteGroup, handleDeleteSeries] as const
}

import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import { formatGroupName } from '_/model/scheduling/day-scheduler/helpers'

interface DeletionSeriesModalProps {
    group: Group
    close: () => void
    confirm: (applyOnlyForCurrentGroup: boolean) => void
}

function DeletionSeriesModal(props: DeletionSeriesModalProps) {
    return(
        <Modal isOpen onClose={props.close}>
            <ModalHeader>Deletion confirmation</ModalHeader>
            <ModalBody>
                <div className='user-formatted-text'>
                    Are you sure you want to delete <FormattedText text={formatGroupName(props.group.name, props.group.isActive, props.group.isAdHoc)} />?
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.close} className='btn-secondary'>Cancel</Button>
                <Button onClick={() => props.confirm(false)} className='btn-danger'>Delete series</Button>
                <Button onClick={() => props.confirm(true)} className='btn-danger'>Delete current only</Button>
            </ModalFooter>
        </Modal>
    )
}
