export const VIEW_THE_DASHBOARD = 0
           , VIEW_THE_GLOBAL_AUDIT_TRAIL = 1
           , USE_ANALYSIS_AND_REPORTS = 2
           , EXPORT_DATA = 3
           , MANAGE_CUSTOM_REPORTS = 4

           , VIEW_SAMPLES = 5
           , EDIT_SAMPLES = 6
           , BOOK_IN_SAMPLES = 7
           , CONFIRM_BOOK_IN_FOR_SAMPLES = 8
           , VERIFY_CFU_COUNT = 9
           , EXPORT_SAMPLES = 10
           , IMPORT_SAMPLES = 11

           , VIEW_USERS = 12

           , MANAGE_CUSTOM_FIELDS = 15
           , MANAGE_EXPOSURE_LOCATIONS = 16
           , MANAGE_GRADES = 17
           , MANAGE_OPERATORS = 18
           , MANAGE_SESSIONS = 19
           , MANAGE_SAMPLE_TYPES = 20
           , MANAGE_TRENDS = 32

           , EXPORT_CUSTOM_REPORTS_DATA = 21
           , READ_SAMPLES = 22

           , MANAGE_FLOOR_PLANS = 23

           , VIEW_SCHEDULE = 24
           , EDIT_SCHEDULE = 25
           , MANAGE_MONITORING_GROUPS = 26
           , VIEW_MONITORING_OVERVIEW = 27
           , EDIT_MONITORING_OVERVIEW = 28
           , VIEW_INSIGHTS = 29

           , MANAGE_OBJECTIONABLE_ORGANISMS = 30

           , MANAGE_API_KEYS = 31

           , VIEW_TRENDS = 33
           , EDIT_TREND_INVESTIGATION_REFS = 34

           // Colony Counter permissions
           , CC_CREATE_AND_EDIT_PLATES = 1000
           , CC_VIEW_PLATES = 1001
           , CC_VIEW_AUDIT_TRAIL = 1002
           , CC_VIEW_USERS = 1003
           , CC_MANAGE_API_KEYS = 1004
           , CC_EXPORT_DATA = 1005

import type * as self from './business-permission'
type BusinessPermission = Extract<(typeof self)[keyof typeof self], number>

const BUSINESS_PERMISSION: readonly { id: BusinessPermission, name: string }[] = [
    {
        id: VIEW_THE_DASHBOARD,
        name: 'View the dashboard',
    },
    {
        id: VIEW_THE_GLOBAL_AUDIT_TRAIL,
        name: 'View the global audit trail',
    },
    {
        id: USE_ANALYSIS_AND_REPORTS,
        name: 'Use analysis and reports',
    },
    {
        id: EXPORT_DATA,
        name: 'Export data',
    },
    {
        id: MANAGE_CUSTOM_REPORTS,
        name: 'Manage custom reports',
    },
    {
        id: VIEW_SAMPLES,
        name: 'View samples',
    },
    {
        id: READ_SAMPLES,
        name: 'Read samples',
    },
    {
        id: EDIT_SAMPLES,
        name: 'Edit samples',
    },
    {
        id: BOOK_IN_SAMPLES,
        name: 'Book in samples',
    },
    {
        id: CONFIRM_BOOK_IN_FOR_SAMPLES,
        name: 'Confirm book in for samples',
    },
    {
        id: VERIFY_CFU_COUNT,
        name: 'Verify CFU count',
    },
    {
        id: EXPORT_SAMPLES,
        name: 'Export samples',
    },
    {
        id: IMPORT_SAMPLES,
        name: 'Import samples',
    },
    {
        id: VIEW_USERS,
        name: 'View users',
    },
    {
        id: MANAGE_CUSTOM_FIELDS,
        name: 'Manage custom fields',
    },
    {
        id: MANAGE_EXPOSURE_LOCATIONS,
        name: 'Manage exposure locations',
    },
    {
        id: MANAGE_GRADES,
        name: 'Manage grades',
    },
    {
        id: MANAGE_OPERATORS,
        name: 'Manage operators',
    },
    {
        id: MANAGE_SESSIONS,
        name: 'Manage sessions',
    },
    {
        id: MANAGE_SAMPLE_TYPES,
        name: 'Manage sample types',
    },
    {
        id: EXPORT_CUSTOM_REPORTS_DATA,
        name: 'Export custom reports data',
    },
    {
        id: MANAGE_FLOOR_PLANS,
        name: 'Manage floor plans',
    },
    {
        id: VIEW_SCHEDULE,
        name: 'View schedule (month/day views)',
    },
    {
        id: EDIT_SCHEDULE,
        name: 'Edit day schedule',
    },
    {
        id: MANAGE_MONITORING_GROUPS,
        name: 'Manage monitoring groups',
    },
    {
        id: VIEW_MONITORING_OVERVIEW,
        name: 'View monitoring overview',
    },
    {
        id: EDIT_MONITORING_OVERVIEW,
        name: 'Edit monitoring overview',
    },
    {
        id: VIEW_INSIGHTS,
        name: 'View insights',
    },
    {
        id: MANAGE_OBJECTIONABLE_ORGANISMS,
        name: 'Manage organisms',
    },
    {
        id: MANAGE_API_KEYS,
        name: 'Manage API keys',
    },
    {
        id: MANAGE_TRENDS,
        name: 'Manage trends',
    },
    {
        id: VIEW_TRENDS,
        name: 'View trends',
    },
    {
        id: EDIT_TREND_INVESTIGATION_REFS,
        name: 'Edit trend investigation references'
    },
    {
        id: CC_CREATE_AND_EDIT_PLATES,
        name: 'Create and edit plates',
    },
    {
        id: CC_VIEW_PLATES,
        name: 'View plates',
    },
    {
        id: CC_VIEW_AUDIT_TRAIL,
        name: 'View the global audit trail',
    },
    {
        id: CC_VIEW_USERS,
        name: 'View users',
    },
    {
        id: CC_MANAGE_API_KEYS,
        name: 'Manage API keys',
    },
    {
        id: CC_EXPORT_DATA,
        name: 'Export data',
    },
]

export {
   BusinessPermission,
   BUSINESS_PERMISSION,
}
