import type { State as RouterState } from 'router5'

import { useSelector, useAction, useEffect, useState } from '_/facade/react'
import * as routes from '_/constants/routes'

import { useContextSwitchObserver } from '_/components/context-observer'
import TabNavbar from '_/components/tab-navbar'
import Link from '_/components/link'
import ManageMemberships from './list/manage-memberships'
import ApiKeyList from './api-keys/api-keys-list'
import ApiKeyAuditTrail from './api-keys/api-keys-audit-trail'
import * as userActions from './actions'
import type User from '_/model/users/types'

function UserContainer() {
    const permissions = useSelector(_ => _.auth.permissions)
        , route = useSelector(_ => _.router.route)
        , user = useLoadUser(route?.params.id)
        , id = route?.params.id

    if (!id)
        return null

    return (
        <>
            <div className='page-header mt-3 user-edit__header--height'>
                <h4 data-testid={`user-page-title-${user?.name}`} className='mb-4'>{`Users > ${user?.name}`}</h4>
                <TabNavbar>
                    <Link
                        routeName={routes.SETTINGS_USERS_MEMBERSHIPS}
                        className='navbar-tab me-4'
                        routeParams={{ id }}
                        hasNoPermissions={!permissions.manageMemberships}
                    >
                        Memberships
                    </Link>
                    <Link
                        routeName={routes.SETTINGS_USERS_API_KEYS}
                        className='navbar-tab me-4'
                        routeParams={{ id }}
                        hasNoPermissions={!permissions.manageApiKeys}
                    >
                        API keys
                    </Link>
                    <Link
                        routeName={routes.SETTINGS_USERS_AUDIT_TRAILS}
                        className='navbar-tab'
                        routeParams={{ id }}
                        hasNoPermissions={!permissions.readUsers}
                    >
                        Audit trail
                    </Link>
                </TabNavbar>
            </div>
            {renderRoute(route)}
        </>
    )
}

export default UserContainer

function renderRoute(route: RouterState | null) {
    switch (route?.name ?? '') {
        case routes.SETTINGS_USERS_MEMBERSHIPS:
            return <ManageMemberships />

        case routes.SETTINGS_USERS_API_KEYS:
        case routes.SETTINGS_USERS_API_KEYS_CREATE:
            return <ApiKeyList />

        case routes.SETTINGS_USERS_AUDIT_TRAILS:
            return <ApiKeyAuditTrail />
    }
}

function useLoadUser(userId: string) {
    const loadUser = useAction(userActions.loadUser)
        , contextSwitch = useContextSwitchObserver()
        , [user, setUser] = useState<User | undefined>()

    useEffect(
        () => {
            if (userId)
                loadUser(userId).then(setUser)
        },
        [contextSwitch, loadUser, userId]
    )

    return user
}
