import * as statuses from './sample-status'

const AWAITING_BOOK_IN_CONFIRMATION = 0
    , AWAITING_CFU_COUNT_VERIFICATION = 1
    // AWAITING_IDENTIFICATION was removed and added to the sample status
    // Don't use it's numeric value
    // , AWAITING_IDENTIFICATION = 2
    , COMPROMISED = 3
    , FOR_REVIEW = 4

type SampleFlag = never
    | typeof AWAITING_BOOK_IN_CONFIRMATION
    | typeof AWAITING_CFU_COUNT_VERIFICATION
    | typeof COMPROMISED
    | typeof FOR_REVIEW

const SAMPLE_FLAG: { id: SampleFlag, name: string, availableForStatus: statuses.SampleStatus[]}[] = [
    {
        id: AWAITING_BOOK_IN_CONFIRMATION,
        name: 'Awaiting book in confirmation',
        availableForStatus: statuses.DEFAULT_STATUS_IDS,
    },
    {
        id: AWAITING_CFU_COUNT_VERIFICATION,
        name: 'Awaiting CFU count verification',
        availableForStatus: [statuses.GROWTHS_ID_COMPLETE, statuses.GROWTHS_AWAITING_IDENTIFICATION, statuses.NO_GROWTH],
    },
    {
        id: COMPROMISED,
        name: 'Compromised',
        availableForStatus: statuses.DEFAULT_STATUS_IDS,
    },
    {
        id: FOR_REVIEW,
        name: 'For review',
        availableForStatus: statuses.ALL_STATUS_IDS,
    },
]

export {
    SAMPLE_FLAG as default,
    AWAITING_BOOK_IN_CONFIRMATION,
    AWAITING_CFU_COUNT_VERIFICATION,
    COMPROMISED,
    FOR_REVIEW,
    SampleFlag,
}
