import { useAction, useEffect, useSelector } from '_/facade/react'

import { MAINTENANCE } from '_/constants/routes'
import { navigateTo } from '_/features/routing/actions'
import { checkStatus } from '_/features/backend-status/actions'
import AuthForm from './auth-form'

function Auth() {
    useCheckStatus()

    return <AuthForm />
}

export default Auth

function useCheckStatus() {
    const loadStatus = useAction(checkStatus)
        , navigate = useAction(navigateTo)
        , serviceAvailable = useSelector(_ => _.backendStatus.backendStatus.serviceAvailable)

    useEffect(
        () => {
            loadStatus()
                .then(() => {
                    if (!serviceAvailable)
                        navigate(MAINTENANCE)
                })
        },
        [serviceAvailable, loadStatus, navigate]
    )
}
