import { React, useState } from '_/facade/react'

import Menu, { useCloseMenu } from '_/components/overlay/menu'

interface Props {
    children: React.ReactNode
    element: HTMLButtonElement | null
    onClose: () => void
}

const NotificationDropdown = (props: Props) => {
    const [container, setContainer] = useState<HTMLDivElement | null>(null)

    useCloseMenu(container, props.onClose, props.element)

    return (
        <Menu element={props.element} containerRef={setContainer}>
            <div className='legend-bubble-triangle triangle-right me-1' data-testid='notifications-popup'>
                <div className='rounded text-light position-relative legend-bubble notifications'>
                    {props.children}
                </div>
            </div>
        </Menu>
    )
}

export default NotificationDropdown

