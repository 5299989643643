import type * as t from '_/model/trends/types'

import { actionFactory } from '_/utils/redux'
import type PaginationState from '_/model/pagination-state'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.trends')

export const loadTrends = action<PaginationState, Promise<t.Trend[]>>('loadTrends')
           , loadTrendStatistics = action<void, Promise<t.TrendStatistics>>('loadTrendStatistics')
           , saveTrendInvestigationRef = action<{id: string, oldTrend: t.Trend, newTrend: t.TrendEdit}, Promise<void>>('saveTrendInvestigationRef')
           , createTrendInvestigationRef = action<{id: string, newTrend: t.TrendEdit}, Promise<void>>('createTrendInvestigationRef')
           , loadTrendsWithRef = action<PaginationState, Promise<t.Trend[]>>('loadTrendsWithRef')
           , loadTrendsWithoutRef = action<PaginationState, Promise<t.Trend[]>>('loadTrendsWithoutRef')
           , loadTrendTrail =  action<string, Promise<AuditTrail>>('loadTrendTrail')
           , searchTrendInvestigationRef = action<string, Promise<string[]>>('searchTrendInvestigationRef')
           , searchTrendId = action<string, Promise<string[]>>('searchTrendId')
