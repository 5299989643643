import { useAction, useSelector } from '_/facade/react'

import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import Button from '_/components/button'

import * as confirmationActions from './actions'
import FormattedText from '../text/formatted-text'

interface Props {
    title: string
    confirmButtonName: string
    confirmButtonClassName?: string
    testId?: string
}
function ConfirmationModal(props: Props) {
    const message = useSelector(_ => _.confirmation.message)
        , warning = useSelector(_ => _.confirmation.warning)
        , cancel = useAction(confirmationActions.cancelAction)
        , confirm = useAction(confirmationActions.confirmAction)

    return (
        <Modal isOpen onClose={cancel}>
            <ModalHeader>{props.title}</ModalHeader>
            <ModalBody>
                <div className='user-formatted-text'>
                    <FormattedText text={message} />
                </div>
                {warning && <div className='alert alert-warning user-formatted-text mt-3'>{warning}</div>}
            </ModalBody>
            <ModalFooter>
                <Button onClick={cancel} className='btn-secondary' testId={`${props.testId}-cancel`}>Cancel</Button>
                <Button onClick={confirm} className={props.confirmButtonClassName ?? 'btn-danger'} testId={`${props.testId}-confirm`}>
                    {props.confirmButtonName}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal
