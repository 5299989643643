import * as lunr from 'lunr'
import type { SearchIndexItem } from './search-index'
import { memoize } from '_/utils/function'

const ref: Extract<keyof SearchIndexItem, 'uri'> = 'uri'
    , indexedFields: Extract<keyof SearchIndexItem, 'title' | 'description' | 'content'>[] = ['title', 'description', 'content']

function buildIndex(documents: SearchIndexItem[]): lunr.Index {
    const builder = new lunr.Builder()

    builder.ref(ref)

    indexedFields.forEach(
        _ => builder.field(_)
    )

    documents
        .filter(_ => _.title !== '' && _.content !== '')
        .forEach(_ => builder.add(_))

    return builder.build()
}

function searchFactory(documents: SearchIndexItem[]) {
    const index = buildIndex(documents)
        , documentMap = new Map(
            documents.map(_ => [_[ref], _] as const)
        )

    function search(term: string): SearchIndexItem[] {
        if (term.length < 3)
            return []

        const searchResult = index.query(query => {
            const options = {
                    wildcard: lunr.Query.wildcard.NONE,
                }

            query.term(lunr.tokenizer(term), options)
        })

        return searchResult.map(_ => documentMap.get(_.ref)!)
    }

    return memoize(search)
}

export default searchFactory
