import { combineReducers } from 'redux'

import type { PredefinedLists as State } from '_/model/app-state'

import { exposureLocations } from '../exposure-locations/reducer'
import { floorPlans } from '../floor-plan/reducer'
import { sampleTypes } from '../sample-type/reducer'
import { limits, grades } from '../action-alert-limits/reducer'
import { sampleOperators } from '../sample-operator/reducer'
import { sampleSessions } from '../sample-session/reducer'
import { customFields } from '../custom-fields/reducer'
import { identifications } from '../identifications/reducer'

export default combineReducers<State>({
    exposureLocations,
    floorPlans,
    sampleTypes,
    limits,
    sampleOperators,
    sampleSessions,
    grades,
    customFields,
    identifications,
})
