import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'

import { diffObject } from '_/utils/object'
import { noop } from '_/utils/function'

import * as actions from './actions'

import type ReportReminderService from '_/services/report-reminder-service'

const factory = (service: ReportReminderService): EffectsFactory => () => [
    handler(actions.loadReportReminders, () => service.list()),

    handler(actions.loadReportReminder, id => service.get(id)),

    handler(actions.createReportReminder, service.create),

    handler(actions.saveReportReminder, data =>
        Promise.resolve()
            .then(_ => {
                const diff = diffObject(data.oldReminder, data.newReminder)
                if (diff) {
                    return service.save(data.id, diff)
                }
            })
            .then(noop)
    ),
]

export default factory
