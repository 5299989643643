import { classnames, useSelector } from '_/facade/react'
import Link from '_/components/link'
import * as routes from '_/constants/routes'
import ReportContainer from './report-container'

function Reports() {
    const routeName = useSelector(_ => _.router.route?.name)
        , permissions = useSelector(_ => _.auth.permissions)

        return(
            <div className='d-flex flex-column h-100 d-print-block'>
                <nav className='navbar navbar-secondary navbar-expand flex-shrink-0'>
                    <ul className='navbar-nav'>
                        <li className='nav-item'>
                            <Link
                                className={classnames('nav-link', {active: routeName === routes.CUSTOM_REPORTS})}
                                routeName={routes.CUSTOM_REPORTS}
                                hasNoPermissions={!permissions.readCustomReports}
                                activeRoutes={[routes.CUSTOM_REPORTS_CREATE, routes.CUSTOM_REPORTS_EDIT, routes.CUSTOM_REPORTS_VIEW]}
                            >
                                Custom reports
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={classnames('nav-link', {active: routeName === routes.LIMIT_BREACH_REPORT})}
                                routeName={routes.LIMIT_BREACH_REPORT}
                                hasNoPermissions={!permissions.readReports}
                            >
                                Limit breach timeline
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={classnames('nav-link', {active: routeName === routes.FINGERDABS})}
                                routeName={routes.FINGERDABS}
                                hasNoPermissions={!permissions.readReports}
                            >
                                Fingerdabs
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={classnames('nav-link', {active: routeName === routes.LOCATIONS_REPORT})}
                                routeName={routes.LOCATIONS_REPORT}
                                hasNoPermissions={!permissions.readLocationsReport}
                            >
                                Locations report
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={classnames('nav-link', {active: routeName === routes.OPERATORS_REPORT})}
                                routeName={routes.OPERATORS_REPORT}
                                hasNoPermissions={!permissions.readOperatorsReport}
                            >
                                Operators report
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={classnames('nav-link', {active: routeName === routes.MISSED_MONITORING_REPORT})}
                                routeName={routes.MISSED_MONITORING_REPORT}
                                hasNoPermissions={!permissions.readMissedMonitoringReport}
                            >
                                Missed monitoring report
                            </Link>
                        </li>
                    </ul>
                </nav>

                <div className='d-flex justify-content-center h-100 height-print-auto flex-fill overflow-auto'>
                    <ReportContainer />
                </div>
            </div>
        )
}

export default Reports
