import type { EditLimitsProps, LimitsFields, Limits } from '_/model/predefined-lists/action-alert-limit/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type Grade from '_/model/predefined-lists/grade/grade'
import type { EditNonViableLimitsProps, NonViableLimits } from '_/model/predefined-lists/action-alert-limit/non-viable-limits'
import { PARTICLE_STATE } from '_/model/predefined-lists/action-alert-limit/non-viable-limit-type'
import { dropFields, pickFields, diffObject } from '_/utils/object'

const emptyLimits = {
    atRestAlertLimit: undefined,
    atRestActionLimit: undefined,
    inOperationActionLimit: undefined,
    inOperationAlertLimit: undefined,
}

function limitsValues(actionAlertLimits: Limits[], sampleTypes: SampleType[], grade: Grade, nonViableLimits: NonViableLimits[]) {
    const actionAlertLimitsValues = sampleTypes.map(sampleType => {
            const existedLimits = actionAlertLimits.find(_ => _.sampleTypeId === sampleType.id)
                , limits = existedLimits ? dropFields(existedLimits, 'editedFields', 'id') : emptyLimits

            return { sampleTypeId: sampleType.id, gradeId: grade.id, ...limits}
        })
        , nonViableLimitList = PARTICLE_STATE.map(_ => {
            const existedLimits = nonViableLimits.find(limits => limits.particleState === _.id)
                , limits = existedLimits ? dropFields(existedLimits, 'editedFields', 'id') : emptyLimits

            return { particleState: _.id, gradeId: grade.id, ...limits }
        })

    return {
        limits: actionAlertLimitsValues,
        nonViableLimits: nonViableLimitList,
        behaviour: grade.behaviour,
        cfuVerification: grade.cfuVerification,
        photoBehaviour: grade.photoBehaviour,
    }
}

function getLimits<T extends LimitsFields>(limits: T | undefined) {
    return limits && pickFields(limits, 'atRestAlertLimit', 'atRestActionLimit', 'inOperationActionLimit', 'inOperationAlertLimit')
}

function getEditedLimits(oldLimitsData: Limits | NonViableLimits | undefined, newLimits: LimitsFields | undefined) {
    const limitsChanged = diffObject(getLimits(oldLimitsData), newLimits)
        , result: EditNonViableLimitsProps | EditLimitsProps | undefined = oldLimitsData && limitsChanged
            ? {
                id: oldLimitsData.id,
                oldLimits: oldLimitsData,
                newLimits: {...oldLimitsData, ...newLimits },
            }
            : undefined

    return result
}

export {
    limitsValues,
    getLimits,
    getEditedLimits,
}
