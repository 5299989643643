import { classnames } from '_/facade/react'
import { useField } from 'react-final-form'
import type * as t from '_/model/text/text'

import { default as MultiSelect } from './multi-select'
import { showFieldError } from './helpers'
import { ANY_ID } from '_/constants/system-words'
import { areArraysEqual } from '_/utils/array'

interface InputFieldProps {
    id?: string
    name: string
    disabled?: boolean
    hasNoPermissions?: boolean
    entities: any[]
    calcId(entity: any): any
    calcName(entity: any): string | t.Text
    autocomplete?: boolean
    placeholder?: string
    emptyValuePlaceholder?: string
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    fillParentWidth?: boolean
    anyOption?: any
    children: React.ReactNode
    testId?: string
    className?: string
}

function MultiSelectField(props: InputFieldProps) {
    const anyOption = props.anyOption ? [props.anyOption] : []
        , field = useField<string[] | undefined>(props.name, { isEqual: areArraysEqual })

    function getEntitiesWithAny(inputValue: any | undefined) {
        if (!inputValue)
            return anyOption.concat(...props.entities)

        if (inputValue.some((_: any) => _ === ANY_ID))
            return anyOption

        if (inputValue.length > 0)
            return props.entities

        return anyOption.concat(...props.entities)
    }

    return (
        <div className={classnames('mb-3', props.fillParentWidth ? 'flex-fill' : '', props.className ?? '')}>
            <label htmlFor={props.id} className='col-form-label' data-testid='field-label'>{props.children}</label>
            <MultiSelect
                id={props.id}
                entities={getEntitiesWithAny(field.input.value)}
                calcId={props.calcId}
                calcName={props.calcName}
                disabled={!!props.disabled}
                hasNoPermissions={props.hasNoPermissions}
                className={classnames('form-control', { 'is-invalid': showFieldError(field.meta) })}
                input={field.input}
                autocomplete={!!props.autocomplete}
                placeholder={!props.emptyValuePlaceholder || (field.input.value?.length ?? 0) > 0
                    ? props.placeholder
                    : props.emptyValuePlaceholder
                }
                onKeyDown={props.onKeyDown}
                testId={props.testId}
            />
            {showFieldError(field.meta) && <span className='invalid-feedback d-block' data-testid='validation-error'>{field.meta.error}</span>}
        </div>
    )
}

type ConcreteFieldProps = Pick<InputFieldProps, 'id' | 'name' | 'disabled' | 'entities' | 'calcId' | 'calcName' | 'autocomplete' | 'onKeyDown' | 'fillParentWidth' | 'anyOption' | 'testId'>

const FilterMultiSelectField: React.FC<ConcreteFieldProps> = props =>
    <MultiSelectField
        name={props.name}
        id={props.id}
        disabled={props.disabled}
        entities={props.entities}
        calcId={props.calcId}
        calcName={props.calcName}
        autocomplete={props.autocomplete}
        placeholder='Select'
        emptyValuePlaceholder='All'
        onKeyDown={props.onKeyDown}
        fillParentWidth={props.fillParentWidth}
        children={props.children}
        anyOption={props.anyOption}
        testId={props.testId}
    />

export {
    MultiSelectField as default,
    FilterMultiSelectField,
}
