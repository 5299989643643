import { useState } from '_/facade/react'

import { Modal, ModalBody, ModalFooter } from '_/components/modal'
import Button from '_/components/button'
import UserTagMessage from '_/components/tag/user-tag-message'
import Comment from './comment'

interface Props {
    comments: string[]
    onAddComment(comment: string): void
    onClose(): void
}

function NonViableSampleCommentsModal(props: Props) {
    const [validationResult, setValidationResult] = useState(false)
        , [message, setMessage] = useState('')

    function handleSaveComment() {
        props.onAddComment(message)
        setMessage('')
    }

    function handleCommentChange(message: string, validationResult: boolean) {
        setMessage(message)
        setValidationResult(validationResult)
    }

    return (
        <Modal isOpen onClose={props.onClose}>
            <ModalBody>
                <Comment onCommentChange={handleCommentChange} value={message} />
                <ul className='list-group list-group-flush'>
                    {props.comments.length === 0
                        ? <li className='list-group-item'>No comments</li>
                        : props.comments.map((value, index) => <li key={index} className='list-group-item'><UserTagMessage message={value} /></li>)
                    }
                </ul>
            </ModalBody>
            <ModalFooter>
                <Button className='btn-secondary' onClick={props.onClose}>Close</Button>
                <Button className='btn-primary' disabled={!validationResult || message.length === 0} onClick={handleSaveComment}>Add comment</Button>
            </ModalFooter>
        </Modal >
    )
}

export default NonViableSampleCommentsModal
