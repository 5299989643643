import { useAction, useSelector, useEffect } from '_/facade/react'
import * as toastActions from '_/features/toasts/actions'

function useError() {
    const showError = useAction(toastActions.addError)
        , error = useSelector(_ => _.reason.error)

    useEffect(
        () => {
            if (error)
                showError(error)
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    )
}

export {
    useError,
}
