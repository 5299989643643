import * as ReactModal from 'react-modal'
import { React, classnames, useContext } from '_/facade/react'
import { noop } from '_/utils/function'

const CloseContext = React.createContext(noop)

interface ModalProps {
    isOpen: boolean
    onClose: () => void
    contentClassName?: string
    children: React.ReactNode
    noDefaultContentWidth: boolean
    shouldCloseOnOutsideClick?: boolean
}
const Modal = (props: ModalProps) =>
    <CloseContext.Provider value={props.onClose}>
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={props.onClose}
            overlayClassName='react-modal__overlay'
            shouldCloseOnOverlayClick={props.shouldCloseOnOutsideClick}
            className={classnames(
                'px-4',
                'react-modal__content',
                { 'react-modal__content--default-width': !props.noDefaultContentWidth },
                props.contentClassName
            )}
            role='dialog'
            ariaHideApp={false}
            children={props.children}
        />
    </CloseContext.Provider>

Modal.defaultProps = {
    noDefaultContentWidth: false,
}

interface HeaderProps {
    className?: string
    children: React.ReactNode
}
const ModalHeader = (props: HeaderProps) =>
    <div className={classnames('modal-header', props.className)} children={props.children}/>

interface BodyProps {
    noDefaultHeight: boolean
    children: React.ReactNode
    className?: string
}

const ModalBody = (props: BodyProps) =>
    <div
        className={classnames(
            'modal-body',
            props.className,
            { 'react-modal__body--default-height': !props.noDefaultHeight })
        }
        children={props.children}
    />

ModalBody.defaultProps = {
    noDefaultHeight: false,
    className: '',
}

interface FooterProps {
    className?: string
    children: React.ReactNode
}

const ModalFooter = (props: FooterProps) =>
    <div className={classnames('modal-footer', props.className)} children={props.children}/>

function useCloseModal() {
    return useContext(CloseContext)
}

export { Modal, ModalHeader, ModalBody, ModalFooter, useCloseModal }
