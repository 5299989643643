import { useSelector, useAction, useEffect, useState } from '_/facade/react'
import Button from '_/components/button'
import Legend from '_/components/legend'
import Barcode from '_/components/barcode'
import { LinkButton } from '_/components/link'
import PageHeader from '_/components/page-header'
import { useTimeService } from '_/components/time'
import SampleLabels from '_/components/sample-labels'

import * as routes from '_/constants/routes'
import * as fieldIndex from '_/constants/custom-field-index'
import * as breachTypes from '_/constants/sample-breach-type'
import { DEFAULT_STATUS_IDS } from '_/constants/sample-status'
import { EXPOSURE_DATE_START } from '_/constants/search-date-type'
import { FINGERDAB_TWO_HANDS_PLATE } from '_/constants/plate-type'

import { memoize } from '_/utils/function'
import { VOID_ID } from '_/utils/tree'

import * as t from '_/model/text/text'
import type { SampleReportSearchQuery } from '_/model/sample/search'
import type { FilterFieldValue } from '_/model/sample/search'
import { fullNameLocationList } from '_/utils/exposure-location'
import { getLast28DaysDate } from '_/features/analysis/helpers'

import SampleInformation from './sample-information'
import ContaminationInfo from './contamination-info'

import SampleLocationInfo from './sample-location-info'
import SampleOperatorInfo from './sample-operator-info'
import SampleOrganismInfo from './sample-organism-info'
import RelatedInformationContainer from './related-information-container'
import * as actions from '../actions'
import * as predefinedListsActions from '_/features/predefined-lists/redux/actions'

import { getEditableSampleCustomFields, getFieldValue, getGeneratedBy } from '../helpers'

import { formatActiveState } from '_/utils/format/common'
import type SampleDetails from '_/model/sample/sample-details'
import * as it from '_/model/sample/identification-type'
import type { DateTime } from '_/model/date-time'

function SampleReportPage() {
    const timeService = useTimeService()
        , [sample, showSpinner] = useSample()
        , fields = useEditableFields()
        , user = useSelector(_ => _.auth.user)
        , predefinedLists = useSelector(_ => _.predefinedLists)

        , exportOperatorRelatedSampleData = useAction(actions.exportOperatorRelatedSampleData)
        , exportOrganismRelatedSampleData = useAction(actions.exportOrganismRelatedSampleData)
        , exportLocationRelatedSampleData = useAction(actions.exportLocationRelatedSampleData)

    function getLocationToFilterBy(sample: SampleDetails) {
        const locationField = sample.fields.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ID)

        if (!locationField || locationField.notRecorded)
            return

        const exposureLocations = fullNameLocationList(predefinedLists.exposureLocations)
            , sampleLocation = exposureLocations.find(_ => _.id === locationField.value)

        return exposureLocations.find(_ => _.id === sampleLocation?.parentId)
    }

    function getCtzPastDateBegin(date: DateTime): DateTime {
        const prevDay = getLast28DaysDate(timeService, date)
        return timeService.ctzDayStart(prevDay)
    }

    function getCtzFutureDateEnd(date: DateTime): DateTime {
        const nextDay = timeService.addCtzDays(date, 7)
        return timeService.ctzDayEnd(nextDay)
    }

    function generateSearchQuery(date: DateTime, sampleToExcludeId?: string, fields?: FilterFieldValue[], organismIds?: string[]): SampleReportSearchQuery {
        return {
            includeChildOrganisms: false,
            start: 0,
            count: 1000,
            sort: 'exposureStartTime:asc',
            fields,
            sampleToExcludeId,
            organismIds: organismIds,
            sampleBreachTypes: [breachTypes.ACTION_LIMIT, breachTypes.ALERT_LIMIT],
            dateToFilter: EXPOSURE_DATE_START,
            dateFrom: getCtzPastDateBegin(date),
            dateTo: getCtzFutureDateEnd(date),
            statuses: DEFAULT_STATUS_IDS,
        }
    }

    if (sample === undefined || showSpinner)
        return showSpinner
            ? <div className='position-relative py-5'>
                <i className='preview-spinner material-icons md-48'>sync</i>
            </div>
            : null

    const isFingerdabTwoHandsPlate = getFieldValue(sample.fields, fieldIndex.PLATE_TYPE) === FINGERDAB_TWO_HANDS_PLATE
        , locationToFilterBy = getLocationToFilterBy(sample)
        , operatorsIds = getFieldValue(sample.fields, fieldIndex.OPERATORS_IDS) as string[]
        , allOrganisms = sample.identifications.rows.concat(sample.optionalIdentifications?.rows ?? [])
            .flatMap(_ => _.types).filter(_ => _.type === it.ORGANISM)
        , organisms = [...new Set(allOrganisms.map(_ => _.value))]
            .map(id => allOrganisms.find(_ => _.value === id)!)
        , sampleDateTime = getFieldValue(sample.fields, fieldIndex.EXPOSURE_START_DATE)
        , dateFrom = timeService.formatCtzDate(getCtzPastDateBegin(sampleDateTime))
        , dateTo = timeService.formatCtzDate(getCtzFutureDateEnd(sampleDateTime))
        , barcode = getFieldValue(sample.fields, fieldIndex.BARCODE)

        return (
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    {user &&
                        <span className='d-none d-print-block width-print-100'>
                            {getGeneratedBy(timeService, user.name, user.email)}
                        </span>
                    }
                    <div className='col-9 width-print-100'>
                        <PageHeader sticky title={<span data-testid='sample-report-title'>Report for sample <Barcode>{barcode}</Barcode></span>}>
                            <SampleLabels sample={sample}/>
                            <div className='d-print-none'>
                                <LinkButton
                                    routeName={routes.SAMPLES_EDIT}
                                    className='btn-primary px-2 me-2'
                                    routeParams={{ id: sample.id }}
                                    testId='sample-report-go-to-sample'
                                >
                                    Go to this sample
                                </LinkButton>
                                <Button
                                    className='btn-primary px-2'
                                    onClick={() => window.print()}
                                    testId='sample-report-print'
                                >
                                    Print
                                </Button>
                            </div>
                        </PageHeader>
                        <div className='row mb-4 align-items-start sample-edit__word_wrap'>
                            <div className='col-6'>
                                <div className='sample-report-border bg-light p-4 mb-3'>
                                <SampleInformation sample={sample} fields={fields} predefinedLists={predefinedLists}/>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='sample-report-border bg-light p-4 mb-3'>
                                    <h4 className='py-3'>Contamination</h4>
                                    <div>
                                        {isFingerdabTwoHandsPlate && <Legend>Left hand</Legend>}
                                        <ContaminationInfo
                                            entity={sample}
                                            isOptionalGrowth={false}
                                        />
                                        {isFingerdabTwoHandsPlate &&
                                            <div>
                                                <hr />
                                                <Legend>Right hand</Legend>
                                                <ContaminationInfo
                                                    entity={sample}
                                                    isOptionalGrowth
                                                />
                                            </div>
                                        }
                                        <div className='row'>
                                            <div className='col-4' data-testid='contamination-info-label'>Read by</div>
                                            <div className='col-8 sample-reading__word-wrap user-formatted-text fw-bold'>
                                                {sample.readBy.map(_ =>
                                                    <span
                                                        key={_.id}
                                                        title={_.email}
                                                    >
                                                        {_.name}
                                                        <br />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='sample-report-border bg-light p-4'>
                                    <h4 className='py-3'>Investigations</h4>
                                    <div className='row'>
                                        <div className='col-4' data-testid='investigation-info-label'>Viable sample investigation refs</div>
                                        <div className='col-8 sample-reading__word-wrap user-formatted-text fw-bold'>
                                        {sample.sampleInvestigationReferences}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {locationToFilterBy &&
                            <RelatedInformationContainer
                                fieldName='location'
                                titleInfo={
                                    locationToFilterBy.parentId === VOID_ID
                                        ? 'all locations'
                                        : [
                                            t.defaultTextNode('location '),
                                            ...formatActiveState(locationToFilterBy.pathName, locationToFilterBy.isActive)
                                        ]
                                    }
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                                onExport={() => exportLocationRelatedSampleData(generateSearchQuery(sampleDateTime, sample.id, [{ index: fieldIndex.EXPOSURE_LOCATION_ID, value: [locationToFilterBy.id] }]))}
                                testId='location-section'
                            >
                                <SampleLocationInfo
                                    searchQuery={
                                        generateSearchQuery(sampleDateTime, sample.id, [{ index: fieldIndex.EXPOSURE_LOCATION_ID, value: [locationToFilterBy.id] }])
                                    }
                                    predefinedLists={predefinedLists}
                                />
                            </RelatedInformationContainer>
                        }
                        {operatorsIds.map(_ =>
                            <RelatedInformationContainer
                                key={_}
                                fieldName='operator'
                                titleInfo={[
                                    t.defaultTextNode('operator '),
                                    ...formatActiveState(
                                        predefinedLists.sampleOperators.find(op => op.id === _)?.name ?? '',
                                        predefinedLists.sampleOperators.find(op => op.id === _)?.isActive,
                                    )
                                ]}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                                onExport={() => exportOperatorRelatedSampleData(generateSearchQuery(sampleDateTime, sample.id, [{ index: fieldIndex.OPERATORS_IDS, value: [_] }]))}
                                testId='operator-section'
                            >
                                <SampleOperatorInfo
                                    searchQuery={generateSearchQuery(sampleDateTime, sample.id, [{ index: fieldIndex.OPERATORS_IDS, value: [_] }])}
                                    predefinedLists={predefinedLists}
                                />
                            </RelatedInformationContainer>
                        )}
                        {organisms.map((_, i) =>
                            <RelatedInformationContainer
                                key={i}
                                fieldName='organism'
                                titleInfo={[
                                    t.defaultTextNode('organism '),
                                    ...formatActiveState(_.name!, _.isActive)
                                ]}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                                onExport={() => exportOrganismRelatedSampleData(generateSearchQuery(sampleDateTime, sample.id, undefined, [_.value!]))}
                                testId='organism-section'
                            >
                                <SampleOrganismInfo
                                    searchQuery={generateSearchQuery(sampleDateTime, sample.id, undefined, [_.value!])}
                                    predefinedLists={predefinedLists}
                                />
                            </RelatedInformationContainer>
                        )}
                    </div>
                </div>
            </div>
        )
}

const memGetEditableFields = memoize(getEditableSampleCustomFields)

function useEditableFields() {
    const customFields = useSelector(_ => _.predefinedLists.customFields)
    return memGetEditableFields(customFields)
}

function useSample() {
    const sampleId = useSelector(_ => _.router.route!.params.id)
        , loadSample = useAction(actions.loadSample)
        , loadPredefinedLists = useAction(predefinedListsActions.loadPredefinedLists)
        , [sample, setSample] = useState<SampleDetails | undefined>()
        , [showSpinner, setShowSpinner] = useState(false)

    useEffect(
        () => {
            setShowSpinner(true)
            Promise.all([loadSample(sampleId), loadPredefinedLists()])
                .then(([sample]) => setSample(sample))
                .finally(() => setShowSpinner(false))
        },
        [sampleId, loadSample, loadPredefinedLists]
    )

    return [sample, showSpinner] as const
}

export default SampleReportPage
