import { useSelector, useState, useAction, useEffect } from '_/facade/react'

import type { ExistedPlateComment, NewPlateComment } from '_/model/plate/comments'

import CommentList from '_/features/samples/sample-edit/comment'
import { validateComment } from '_/features/samples/validate'
import { CommentView } from '_/components/comment'
import Legend from '_/components/legend'

import * as wa from '_/features/unsaved-changes/actions'

interface OwnProps {
    comments: ExistedPlateComment[]
    onAddComment(comment: NewPlateComment): void
}

const PLATE_COMMENT = 'plate-comment'

function PlateComments(props: OwnProps) {
    const [comment, setComment] = useState('')
        , error = validateComment({ message: comment})
        , canEdit = useSelector(_ => _.auth.permissions.performColonyCounterPlateReading)

    useUnsavedChangesTracker(comment)

    function handleAddComment() {
        props.onAddComment({ message: comment })
        setComment('')
    }

    return (
        <>
            <Legend className='block-header'>Comments</Legend>
            {canEdit &&
                <div className='pb-1'>
                    <CommentView
                        value={comment}
                        onChange={setComment}
                        error={error}
                        onPost={handleAddComment}
                    />
                </div>
            }
            <CommentList comments={props.comments} />
        </>
    )
}

export default PlateComments

function useUnsavedChangesTracker(comment: string) {
    const hasUnsavedChanges = useAction(wa.hasUnsavedChanges)

    useEffect(
        () => {
            hasUnsavedChanges(comment.length > 0, PLATE_COMMENT)
            return () => { hasUnsavedChanges(false, PLATE_COMMENT) }
        },
        [comment, hasUnsavedChanges]
    )
}
