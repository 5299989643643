import { useSelector } from '_/facade/react'
import type TimeService from '_/services/time-service'

import GRAPH_TITLE_WIDTH from '_/model/reports/graph-title-width'
import type { ReportGraphData, ReportGraphSeries } from '_/model/reports/types'
import { CURRENT_PERIOD, SIX_PERIODS_AGO } from '_/model/reports/contextual-report-period'
import type SampleSearchFields from '_/model/sample/search'
import { formatExposureDateRange, computeAuthorInfo } from '_/features/analysis/ui/helpers'
import { splitStringIntoFewLines } from '_/features/analysis/helpers'
import type { UserContext } from '_/model/auth/types'
import { formatDatePeriod } from '_/model/reports/missed-monitoring-report/helpers'
import * as t from '_/model/text/text'
import ReportLineGraph from './line-graph'
import FormattedText from '../text/formatted-text'
import NoDataAvailable from './no-data-available'

interface OwnProps {
    timeService: TimeService
    series: ReportGraphData[]
    routeParams?: SampleSearchFields
    name: string
    printMode: boolean
    graphTitle: t.Text
    legendTitle?: string
    chartTitle: string
    emptyMessage: string
    subtitle?: string
    footer: string[]
    onExport?: (_: Blob) => void
    testId?: string
    showSpinner: boolean
}

function ReportGraph(props: OwnProps) {
    const showEmptyMessage = props.series.every(_ => _.series.every(_ => _.count === undefined))
        , graphTitle = `${props.chartTitle} ${t.plainText(props.graphTitle)}`
        , authUser = useSelector(_ => _.auth.user)

    return (
        <div className='col-6 break-block width-print-100'>
            <div className='block-border text-center py-3'>
                {props.series.length === 0 || showEmptyMessage || props.showSpinner
                    ? <div className='text-center break-word'>
                        <h4 className='mb-4' data-testid={`${props.testId}-title`}>
                            {props.chartTitle} <FormattedText text={props.graphTitle}/>
                        </h4>
                        <NoDataAvailable showSpinner={props.showSpinner}>
                            <span className='text-muted text-uppercase' data-testid={`${props.testId}-no-data-message`}>{props.emptyMessage}</span>
                        </NoDataAvailable>
                    </div>
                    : <ReportLineGraph
                        data={props.series.map(_ => generatePlotlyData(_, props.timeService))}
                        metadata={getMetadata(props.series[0].series, graphTitle, props.timeService, authUser, props.footer, props.subtitle, props.legendTitle)}
                        chartTitle={`${props.chartTitle}, %`}
                        printMode={props.printMode}
                        fileName={`SmartControl Graph for ${props.name} downloaded on ${props.timeService.formatCtzDate(props.timeService.now())}`}
                        disabledGoToSamplesButton={props.series.length === 0}
                        sampleListRouterParams={props.routeParams && getParams(props.timeService, props.series[0].series, props.routeParams)}
                        onExport={props.onExport}
                        testId={props.testId}
                    />
                }
            </div>
        </div>
    )
}

function getParams(timeService: TimeService, series: ReportGraphSeries[], routeParams: SampleSearchFields | undefined) {
    const { dateFrom, dateTo } = reportGraphDateRange(timeService, series)

    return {
        ...routeParams,
        dateFrom,
        dateTo,
    }
}

function getMetadata(series: ReportGraphSeries[], title: string, timeService: TimeService, user: UserContext | undefined, footer: string[], subtitle?: string, legendTitle?: string) {
    const { dateFrom, dateTo } = reportGraphDateRange(timeService, series)

    return {
        subtitle: subtitle ? subtitle : formatExposureDateRange(timeService, dateFrom, dateTo),
        title: splitStringIntoFewLines(title, GRAPH_TITLE_WIDTH),
        legendTitle,
        author: computeAuthorInfo(timeService, user),
        footerPlainText: footer,
        footer: [],
    }
}

function generatePlotlyData(graphSeries: ReportGraphData, timeService: TimeService) {
    const allInCurrentYear = allDatesInCurrentYear(timeService, graphSeries.series)
        , dateRanges = graphSeries.series.map(_ => ({dateRange: formatDatePeriod(timeService, { from: _.from, to: _.to }, allInCurrentYear), periodId: _.contextualReportPeriod}))
        , data = {
            x: dateRanges.map(_ => _.dateRange),
            y: dateRanges.map(p => {
                const count = graphSeries.series.find(s => s.contextualReportPeriod === p.periodId)?.count
                return count?.toFixed(2)
            }),
            text: dateRanges.map(_ => _.dateRange),
            showlegend: !!graphSeries.title || false,
            name: graphSeries.title,
            mode: 'lines+markers',
            connectgaps: false,
            hoverinfo: 'y+text',
        } as Plotly.PlotData


    return data
}

function allDatesInCurrentYear(timeService: TimeService, series: ReportGraphSeries[]) {
    const currentYear = timeService.ctzTimeStruct(timeService.now()).year
        , dateTo = series.find(_ => _.contextualReportPeriod === CURRENT_PERIOD)?.to
        , dateFrom = series.find(_ => _.contextualReportPeriod === SIX_PERIODS_AGO)?.from

    return !!(dateFrom && dateTo && timeService.ctzTimeStruct(dateFrom).year === currentYear && timeService.ctzTimeStruct(dateTo).year === currentYear)
}

function reportGraphDateRange(timeService: TimeService, series: ReportGraphSeries[]) {
    const currentPeriod = series.find(_ => _.contextualReportPeriod === CURRENT_PERIOD)!
        , sixPeriodsAgo = series.find(_ => _.contextualReportPeriod === SIX_PERIODS_AGO)!

    return {
        dateFrom: sixPeriodsAgo.from,
        dateTo: timeService.ctzDayEnd(timeService.addCtzDays(currentPeriod.to, -1))
    }
}

export default ReportGraph
