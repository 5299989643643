import { useAction, useEffect, useState, useSelector, useMemo } from '_/facade/react'
import * as m from '_/components/modal'
import * as a from '../actions'
import type Grade from '_/model/predefined-lists/grade/grade'
import Submit from '_/components/form/submit'
import { MultiSelectField, SelectField, resetForm, submitDisabled } from '_/components/form'
import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import Button, { Close } from '_/components/button'
import OrganismSearch from '_/features/predefined-lists/organism-identification/organism-search'
import type { ObjectionableOrganismForm } from '_/model/objectionable-organisms/editor/types'
import type ObjectionableOrganism from '_/model/objectionable-organisms/objectionable-organism'
import validate from '_/model/objectionable-organisms/editor/validate'
import FormChangesObserver from '_/components/form/form-changes-observer'
import type { FormApi } from 'final-form'
import * as it from '_/model/sample/identification-type'
import * as identificationsActions from '_/features/predefined-lists/identifications/actions'
import type { AllIdentifications } from '_/model/predefined-lists/identifications/identifications'
import { typeArgument } from '_/model/predefined-lists/identifications/helpers'
import { convertObjectionableOrganismFormToEdit } from '_/model/objectionable-organisms/editor/helpers'
import { diffObject } from '_/utils/object'

const defaultOrganism: Partial<ObjectionableOrganismForm> = {
    identificationType: undefined,
    identificationValue: undefined,
    gradeIds: []
}

interface Props {
    grades: Grade[]
    onClose: () => void
}

function ObjectionableOrganismEditor(props: Props) {
    const id = useSelector<string | undefined>(_ => _.router.route!.params.id)
        , organism = useOrganism(id)
        , initialValues = useMemo(
            () => organism ? { ...organism } : defaultOrganism,
            [organism]
        )
        , handleSubmit = useSubmitHandler(id, initialValues, props.onClose)
        , handleRemove = useRemoveHandler(id, props.onClose)
        , organisms = useObjectionableOrganisms(id)
        , organismTypeArguments = useLoadIdentifications()
        , excludedIdentificationTypes = [it.NOT_YET_IDENTIFIED, it.NO_ID_REQUIRED, it.CORRECTIONAL_MPN_VALUE]

    let previousValues = initialValues

    function handleFormChange(values: ObjectionableOrganismForm, form: FormRenderProps) {
        if (!diffObject(initialValues, values))
            return

        if (values.identificationType !== previousValues.identificationType)
            form.form.change('identificationValue', undefined)

        previousValues = values
    }

    function getFieldLabel(values: ObjectionableOrganismForm) {
        return it.IDENTIFICATION_TYPE.find(_ => _.id === values.identificationType)?.name
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={_ => validate(_, organisms)}
            render={form =>
                <m.Modal isOpen onClose={props.onClose} noDefaultContentWidth contentClassName='objectionable-organisms-modal-width'>
                    <FormChangesObserver form={form} onChange={handleFormChange} />
                    <form onSubmit={form.handleSubmit}>
                        <m.ModalHeader>
                            <h4>{!id ? 'Add an objectionable identification' : 'Edit objectionable identification'}</h4>
                            <Close onClick={props.onClose}/>
                        </m.ModalHeader>
                        <m.ModalBody>
                            <SelectField
                                id='identificationType'
                                name='identificationType'
                                entities={it.IDENTIFICATION_TYPE.filter(type => !excludedIdentificationTypes.some(_ => _ === type.id))}
                                calcId={_ => _.id}
                                calcName={_ => _.name}
                                placeholder='Select'
                            >
                                Identification type
                            </SelectField>
                            {form.values.identificationType === it.ORGANISM
                                ? <OrganismSearch
                                    id='identificationValue'
                                    name='identificationValue'
                                    hasLabel
                                    hasNoPermissions={false}
                                    labelText={getFieldLabel(form.values)}
                                    excludeInactive
                                />
                                : <SelectField
                                    id='identificationValue'
                                    name='identificationValue'
                                    entities={typeArgument(form.values.identificationType, organismTypeArguments)}
                                    calcId={_ => _.id}
                                    calcName={_ => _.name}
                                    placeholder='Select'
                                >
                                    {getFieldLabel(form.values)}
                                </SelectField>
                            }
                            <MultiSelectField
                                id='gradeIds'
                                name='gradeIds'
                                entities={props.grades}
                                calcId={_ => _.id}
                                calcName={_ => _.name}
                            >
                                Grades where objectionable
                            </MultiSelectField>
                        </m.ModalBody>
                        <m.ModalFooter>
                            {id && <Button className='text-danger btn-light me-auto' onClick={() => handleRemove(form.form)}>Delete</Button>}
                            <Submit disabled={submitDisabled(form)}>Save changes</Submit>
                        </m.ModalFooter>
                    </form>
                </m.Modal>
            }
        />
    )
}

export default ObjectionableOrganismEditor

function useOrganism(id: string | undefined) {
    const load = useAction(a.loadObjectionableOrganism)
        , [organism, setOrganism] = useState<ObjectionableOrganismForm | undefined>()

    useEffect(
        () => {
            if (!id)
                return

            load(id)
                .then(_ => setOrganism({ identificationType: _.identification.type, identificationValue: _.identification.value, gradeIds: _.gradeIds }))
        },
        [id, load]
    )

    return organism
}

function useSubmitHandler(id: string | undefined, oldValue: ObjectionableOrganismForm, onClose: () => void) {
    const create = useAction(a.createObjectionableOrganism)
        , save = useAction(a.saveObjectionableOrganism)

    function handleSubmit(newValue: ObjectionableOrganismForm, form: FormApi) {
        const convertedNewValue = convertObjectionableOrganismFormToEdit(newValue)
            , result = !id
                ? create(convertedNewValue)
                : save({ id, oldObjectionableOrganism: convertObjectionableOrganismFormToEdit(oldValue), newObjectionableOrganism: convertedNewValue })

        return result
            .then(() => resetForm(form))
            .then(onClose)
    }

    return handleSubmit
}

function useRemoveHandler(id: string | undefined, onClose: () => void) {
    const remove = useAction(a.removeObjectionableOrganism)

    function handleRemove(form: FormApi) {
        if (!id)
            return Promise.resolve()

        return remove(id)
            .then(() => resetForm(form))
            .then(onClose)
    }

    return handleRemove
}

function useObjectionableOrganisms(editedId: string | undefined) {
    const getAllOrganisms = useAction(a.loadObjectionableOrganisms)
        , [organisms, setOrganisms] = useState<ObjectionableOrganism[]>([])

        useEffect(
            () => {
                getAllOrganisms()
                    .then(o => o.filter(_ => _.id !== editedId))
                    .then(setOrganisms)
            },
            [getAllOrganisms, editedId]
        )

    return organisms
}

function useLoadIdentifications() {
    const loadAllIdentifications = useAction(identificationsActions.loadAllIdentifications)
        , [allIdentifications, setAllIdentifications] = useState<AllIdentifications>({ organismType: [], catalase: [], oxidase: [], oxidationFermentation: [], coagulase: [] })

    useEffect(
        () => {
            loadAllIdentifications(false)
                .then(setAllIdentifications)
        },
        [loadAllIdentifications]
    )

    return allIdentifications
}
