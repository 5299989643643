import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, isEmpty, allowedName } from '_/utils/form/validate'
import type { SampleSessionEdit, SampleSession } from '_/model/predefined-lists/session/types'
import { searchDuplicateName } from '_/model/common/helpers'

export default function validate(entity: Partial<SampleSessionEdit>, sampleSessions: SampleSession[]) {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 64)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , duplicate = entity.name && searchDuplicateName(entity, sampleSessions)
        , result: ValidationResult<SampleSessionEdit> = {}

    if (name)
        result.name = name

    if (notAllowedNames)
        result.name = notAllowedNames

    if (nameLength)
        result.name = nameLength

    if (duplicate)
        result.name = `Session with name '${entity.name!.trim()}' already exists`

    return result
}
