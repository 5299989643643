import type Sample from '_/model/sample/sample'
import type SampleReading from '_/model/sample/reading/sample-reading'
import type SampleService from '_/services/sample-service'
import type ReasonService from '_/model/reason/reason-service'

import reasonRule from './reason-rule'
import * as h from './helpers'
import { electronicSignatureSettingsEnabled } from '_/model/critical-change-reason/helpers'
import * as s from '_/model/context/electronic-signature-settings'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import { signatureFailure } from '_/model/error/error'

export interface ChangeCompromisedData {
    compromised: boolean
    compromisedReason: string
}

function readTransaction(
    originalSample: Sample,
    reading: SampleReading,
    skipPhotoApprovalInfo: ApprovalInfo,
    signatureSettings: s.ElectronicSignatureSettings[],
    sampleService: SampleService,
    reasonService: ReasonService,
    compromisedData: ChangeCompromisedData | undefined,
): Promise<ApprovalInfo | undefined> {
    const diff = h.identificationsDiff(originalSample, reading)

    if (!diff && !compromisedData)
        return Promise.resolve(undefined)

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (diff?.identifications?.rows?.[0].cfuCount === 0)
        diff.identifications.rows = []

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (diff?.optionalIdentifications?.rows?.[0].cfuCount === 0)
        diff.optionalIdentifications.rows = []

    const signatureRequired = electronicSignatureSettingsEnabled(s.EDITING_SAMPLES, signatureSettings)
        , promise = reasonRule(originalSample, diff)
            ? (reason?: string, error?: string) => reasonService.getReason(signatureRequired, reason, error)
            : () => Promise.resolve({ reason: '' })
        , overridePhotoRequirementPromise = reading.photoSkipped
            ? () => sampleService.overridePhotoRequirement(originalSample.id, true, skipPhotoApprovalInfo)
            : () => Promise.resolve()

    function readWithReason(reason?: string, error?: string): Promise<ApprovalInfo> {
        return promise(reason, error)
            .then(approvalInfo =>
                sampleService.read(originalSample.id, { approvalInfo, ...diff, ...compromisedData })
                    .then(
                        () => overridePhotoRequirementPromise().then(() => approvalInfo),
                        _ => signatureFailure(_) ? readWithReason(approvalInfo.reason, _.statusText) : Promise.reject(_)
                    )
            )
    }

    return readWithReason()
}

function verifyCFUCountTransaction(id: string, approvalInfo: ApprovalInfo, sampleService: SampleService) {
    return sampleService.verifyCFUCount(id, approvalInfo)
}

function checkPhotoRequirementSignatureTransaction(
    id: string,
    photoSkipped: boolean,
    getReason: (reason?: string, error?: string) => Promise<ApprovalInfo>,
    sampleService: SampleService,
    addError: (_: string) => void
): Promise<ApprovalInfo> {
    function skipPhotoWithReason(reason?: string, error?: string): Promise<ApprovalInfo> {
        if (!photoSkipped)
            return getReason()

        if (error)
            addError(error)

        return getReason(reason, error)
            .then(approvalInfo =>
                sampleService.overridePhotoRequirement(id, false, approvalInfo)
                    .then(
                        () => approvalInfo,
                        _ => signatureFailure(_) ? skipPhotoWithReason(approvalInfo.reason, _.statusText) : Promise.reject(_)
                    )
                )
    }

    return skipPhotoWithReason()
}

export {
    readTransaction,
    verifyCFUCountTransaction,
    checkPhotoRequirementSignatureTransaction,
}
