import { React, useAction, useEffect, useState, useSelector } from '_/facade/react'
import { ModalHeader, ModalBody, ModalFooter, useCloseModal } from '_/components/modal'
import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import Button, { Close } from '_/components/button'
import type { ApiKeyPair, ApiKeyCreateView } from '_/model/api-key/types'
import type ApiKey from '_/model/api-key/types'
import { TextField, TextareaField, Submit, submitDisabled } from '_/components/form'
import FormChangesObserver from '_/components/form/form-changes-observer'

import * as actions from './redux/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import validate from '_/model/api-key/validate'

function ApiKeyCreateForm() {
    const apiKey = useCreateApiKey()
        , handleKeysDownload = useKeyDownloader()
        , closeModal = useCloseModalHandler(apiKey?.id)
        , handleSubmit = useSubmit(closeModal)

    function disabled(form: FormRenderProps<Partial<ApiKeyCreateView>>) {
        return !!(submitDisabled(form) && form.values.description)
            || !form.values.keyPair
    }

    return (
            <Form
                onSubmit={handleSubmit as any}
                initialValues={apiKey}
                validate={validate}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <FormChangesObserver form={form} />
                        <ModalHeader className='pb-0 border-bottom-0'>
                            <h4 data-testid='api-key-modal-header'>New API key</h4>
                            <Close onClick={() => closeModal(true)}/>
                        </ModalHeader>
                        <ModalBody noDefaultHeight>
                            <TextareaField
                                name='keyPair.publicKey'
                                textareaClassName='api-keys-create-modal__key-textarea'
                                rows={3}
                                disabled
                                testId='field-public-key'
                            >
                                Public key
                            </TextareaField>
                            <TextareaField
                                name='keyPair.secretKey'
                                textareaClassName='api-keys-create-modal__key-textarea'
                                rows={6}
                                disabled
                                testId='field-private-key'
                            >
                                Secret key
                            </TextareaField>
                            <div className='alert alert-warning user-formatted-text'>
                                If you do not download the key file now, you will not be able to retrieve your secret access key again.<br/>
                                To help protect your security, store your secret access key securely and do not share it.
                            </div>
                            <div className='pb-5'>
                                <Button onClick={() => handleKeysDownload(apiKey?.keyPair)} className='btn-secondary float-end' testId='download-keys'>
                                    Download keys
                                </Button>
                            </div>
                            <TextField name='description' autoFocus className='mt-3' testId='field-description'>
                                Description
                            </TextField>
                        </ModalBody>
                        <ModalFooter className='border-top-0'>
                            <Button className='btn-secondary' onClick={() => closeModal(true)} testId='close-api-modal'>
                                Cancel
                            </Button>
                            <Submit disabled={disabled(form)} testId='save-and-activate'>Save and activate</Submit>
                        </ModalFooter>
                    </form>
                }
            />
    )
}

function useCreateApiKey() {
    const create = useAction(actions.createApiKey)
        , [apiKeys, setApiKeys] = useState<ApiKeyCreateView>()

    useEffect(
        () => {
            create()
                .then(_ => setApiKeys(_))
        },
        [create]
    )

    return apiKeys
}

function useSubmit(closeModal: () => void) {
    const save = useAction(actions.saveApiKey)
        , loadList = useAction(actions.loadApiKeyList)
        , userId = useSelector(_ => _.auth.user?.id)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)

    function handleSubmit(apiKey: ApiKey) {
        const id = apiKey.id
            , oldApiKey = {isActive: true}
            , newApiKey = {
                isActive: true,
                description: apiKey.description,
            }

        return save({ id, oldApiKey, newApiKey, fromCreate: true })
            .then(() => hasUnsavedChanges(false))
            .then(() => loadList(userId!))
            .then(closeModal)
    }

    return handleSubmit
}

function useKeyDownloader() {
    const download = useAction(actions.downloadApiKeys)

    return (apiKey: ApiKeyPair | undefined) => {
        if (apiKey)
            return download(apiKey)
    }
}

function useCloseModalHandler(apiKeyId: string | undefined) {
    const dismissHandler = useAction(actions.dismissApiKey)
        , closeModal = useCloseModal()
        , closeHandler = (dismissed = false) => {
            if (dismissed && apiKeyId) {
                dismissHandler(apiKeyId)
            }

            closeModal()
        }

    return closeHandler
}

export default ApiKeyCreateForm
