import { UP_POINTING_TRIANGLE, DOWN_POINTING_TRIANGLE } from '_/constants/characters'
import type SortState from '_/model/sort-state'
import type PaginationState from '_/model/pagination-state'
import { SortContainer, SortTarget } from '_/utils/sort'
import Pagination from '_/utils/pagination'
import { classnames } from '_/facade/react'

const Table: React.FC<{ className?: string, style?: React.CSSProperties, testId?: string }> = props =>
    <table className={`table table-border ${props.className || ''}`} style={props.style} data-testid={props.testId}>
        {props.children}
    </table>

interface SortableHeadProps {
    onChange: (state: SortState) => void
    sticky?: boolean
    className?: string
    miniAuditTrail?: string
}

const SortableHead: React.FC<SortableHeadProps> = props =>
    <SortContainer onChange={props.onChange}>
        <thead className={classnames(props.className || '', { 'table-header--sticky': props.sticky })}>
            <tr>
                <th className='d-none d-print-table-cell border-bottom-0 border-top-0' style={{fontWeight: 'normal'}} colSpan={9}>
                    {props.miniAuditTrail}
                </th>
            </tr>
            <tr>
                {props.children}
            </tr>
        </thead>
    </SortContainer>

interface SortableThProps {
    name: string
    sortByDescending?: boolean
    className?: string
    testId?: string
}

const SortableTh: React.FC<SortableThProps> = props =>
    <SortTarget name={props.name} sortByDescending={props.sortByDescending} children={_ =>
        <th onClick={_.onClick} className='cursor-pointer' data-testid={props.testId}>
            {props.children}&nbsp;{_.sorted && (_.ascending ? UP_POINTING_TRIANGLE : DOWN_POINTING_TRIANGLE)}
        </th>
    }/>


interface EmptyTableMessageProps {
    colSpan: number
    message: string
    className?: string
}

const EmptyTableMessage = (props: EmptyTableMessageProps) =>
    <tr>
        <td colSpan={props.colSpan} className='text-center'>
            <span className={props.className}>{props.message}</span>
        </td>
    </tr>

interface PaginatedFooterProps {
    colSpan: number
    totalCount: number
    state: PaginationState
    onChange: (state: PaginationState) => void
    actionButtons?: React.ReactNode
    alignPaginationLeft?: boolean
}

const PaginatedFooter: React.FC<PaginatedFooterProps> = props =>
    <tfoot>
        <tr>
            <td colSpan={props.colSpan}>
                <div className={classnames('d-flex', {
                    'justify-content-between': props.actionButtons,
                    'justify-content-center': !props.actionButtons,
                })}>
                    {props.actionButtons && !props.alignPaginationLeft && <div className='invisible'/>}
                    <Pagination state={props.state} onChange={props.onChange} totalCount={props.totalCount} />
                    {props.actionButtons}
                </div>
            </td>
        </tr>
    </tfoot>

export {
    Table,
    SortableHead,
    SortableTh,
    EmptyTableMessage,
    PaginatedFooter,
}
