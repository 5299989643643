interface Props {
    children: React.ReactNode
    icon: 'error' | 'help' | 'warning' | 'pass' | 'inProgress'
}

const Icon = (props: Props) => {
    const MATERIAL_ICONS = {
            error: {
                name: 'error',
                class: 'smart-check-validation__message-failure',
            },
            help: {
                name: 'help',
                class: 'smart-check-validation__message-failure',
            },
            warning: {
                name: 'warning',
                class: 'smart-check-validation__message-warning',
            },
            pass: {
                name: 'check_circle',
                class: 'smart-check-validation__message-success',
            },
            inProgress: {
                name: 'sync',
                class: 'smart-check-validation__message-uploading',
            },
        }
        , details = MATERIAL_ICONS[props.icon]

    return (
        <div className={`d-flex my-2 ${details.class}`}>
            <i className='material-icons'>{details.name}</i>
            <div>{props.children}</div>
        </div>
    )
}

export default Icon
