import { useState, useEffect } from '_/facade/react'
import * as dom from '_/utils/dom'

import type { MenuPosition } from './menu'
import Menu from './menu'

interface Props {
    element: Element | null
    alignmentElement?: Element | null
    position: MenuPosition
    children?: React.ReactNode
    topOffset?: number
    showTriangle?: boolean
}

function HoverMenu(props: Props) {
    const [container, setContainer] = useState<HTMLElement | null>(null)
        , [isHovering, setIsHovering] = useState(false)
        , element = props.element
        , alignElement = props.alignmentElement === undefined ? element : props.alignmentElement

    useEffect(
        () => {
            function mouseOverHandler(target: Element) {
                return (event: MouseEvent) => setIsHovering(hoversOver(event, true, target))
            }

            function mouseOutHandler(target: Element, secondaryTarget: Element | null) {
                return (event: MouseEvent) => setIsHovering(hoversOver(event, false, target, secondaryTarget))
            }

            const subscriptions: (() => void)[] = []

            if (element) {
                // TODO: not sure how to properly type element so that SVGElement (that extends Element) is accepted
                subscriptions.push(dom.listen(element as HTMLElement, 'mouseover', mouseOverHandler(element)))
                subscriptions.push(dom.listen(element as HTMLElement, 'mouseout', mouseOutHandler(element, container)))
            }

            if (container) {
                subscriptions.push(dom.listen(container, 'mouseover', mouseOverHandler(container)))
                subscriptions.push(dom.listen(container, 'mouseout', mouseOutHandler(container, element)))
            }

            return () => {
                subscriptions.forEach(unsubscribe => unsubscribe())
            }
        },
        [element, container]
    )

    if (!isHovering)
        return null

    return (
        <Menu
            element={alignElement}
            containerRef={setContainer}
            position={props.position}
            topOffset={props.topOffset}
            showTriangle={props.showTriangle}
        >
            {props.children as any /* typescript bug */}
        </Menu>
    )
}

HoverMenu.defaultProps = {
    position: 'below',
}

export default HoverMenu

function hoversOver(event: MouseEvent, isOverEvent: true, hoverNode: Node | null): boolean
function hoversOver(event: MouseEvent, isOverEvent: false, hoverNode: Node | null, secondaryHoverNode: Node | null): boolean
function hoversOver(event: MouseEvent, isOverEvent: boolean, hoverNode: Node | null, secondaryHoverNode: Node | null = null): boolean {
    if (hoverNode === null)
        return false

    if (isOverEvent && contains(hoverNode, event.target))
        return true

    if (!isOverEvent) {
        return contains(hoverNode, event.relatedTarget) || !!secondaryHoverNode && contains(secondaryHoverNode, event.relatedTarget)
    }

    return false
}

function contains(node: Node, target: EventTarget | null) {
    return target instanceof Node ? node.contains(target) : false
}
