import { classnames } from '_/facade/react'
import type { SmartInfo } from '_/model/smart-check/image'
import Icon from './validation-icon'

interface Props {
    showCfuCount: boolean
    isHistoricalImage?: boolean
    isBeingUploaded?: boolean
    zeroGrowthCheckEnabled?: boolean
    info?: SmartInfo
    automatedGrowthCountVerificationEnabled?: boolean
}

function ValidationPlaceholder(props: Pick<Props, 'zeroGrowthCheckEnabled' | 'automatedGrowthCountVerificationEnabled' | 'info'>) {
    const smartCheckResult = props.info?.validationResult

    return (
        <div>
            <Group>
                <Icon icon='pass'>Capturing photo</Icon>
                {smartCheckResult
                    ? <>
                        <Icon icon={getIcon(smartCheckResult.alignPassed)}>
                            Checking the sample is in frame
                        </Icon>
                        <Icon icon={getIcon(smartCheckResult.repetitionPassed)}>
                            Checking the sample is a different photo
                        </Icon>
                        <Icon icon={getIcon(smartCheckResult.blurCheckPassed)}>
                            Checking the photo is in focus
                        </Icon>
                    </>
                    : <>
                        <Icon icon='inProgress'>Checking the sample is in frame</Icon>
                        <Icon icon='inProgress'>Checking the sample is a different photo</Icon>
                        <Icon icon='inProgress'>Checking the photo is in focus</Icon>
                    </>
                }
            </Group>

            {props.zeroGrowthCheckEnabled && !props.info?.mlDisabled &&
                <Group topMargin>
                    <Icon icon='inProgress'>
                        {props.automatedGrowthCountVerificationEnabled ? 'Verifying CFU count' : 'Checking for CFUs'}
                    </Icon>
                    {props.info?.moldPredictorEnabled &&
                        <Icon icon='inProgress'>Checking for mould</Icon>
                    }
                </Group>
            }
        </div>
    )
}

function DetachedValidation(props: Pick<Props, 'zeroGrowthCheckEnabled' | 'info' | 'automatedGrowthCountVerificationEnabled' | 'showCfuCount'>) {
    const growthCountResult = props.info?.growthCountResult
        , growthsPatches = growthCountResult?.growths
        , modelSuggestedCount = growthsPatches?.map(_ => _.cfu).reduce((a, c) => a + c, 0) ?? 0
        , hasUnknownGrowth = growthsPatches?.some(_ => _.cfu === -1)
        , smartCheckResult = props.info!.validationResult!
        , contaminated = growthsPatches?.length !== 0
        , hasMold = (growthsPatches ?? []).some(_ => _.mold)

        , passed = smartCheckResult.alignPassed && smartCheckResult.repetitionPassed && smartCheckResult.blurCheckPassed && !! growthCountResult
        , nonPassLabel = props.automatedGrowthCountVerificationEnabled ? 'Verifying CFU count' : 'Checking for CFUs'
        , passLabel = contaminated ? `Contamination detected${ !hasUnknownGrowth && props.automatedGrowthCountVerificationEnabled ? ': ' + modelSuggestedCount : ''}` :  'Contamination not detected'

    function getGrowthIcon(passed: boolean, warning: boolean): 'pass' | 'error' | 'warning' {
        if (passed && warning)
            return 'warning'

        return getIcon(passed)
    }

    return (
        <>
            <Group>
                <Icon icon='pass'>Capturing photo</Icon>
                <Icon icon={getIcon(smartCheckResult.alignPassed)}>
                    Checking the sample is in frame
                </Icon>
                <Icon icon={getIcon(smartCheckResult.repetitionPassed)}>
                    Checking the sample is a different photo
                </Icon>
                <Icon icon={getIcon(smartCheckResult.blurCheckPassed)}>
                    Checking the photo is in focus
                </Icon>
            </Group>

            {props.zeroGrowthCheckEnabled && !props.info?.mlDisabled &&
                <Group topMargin>
                    <Icon icon={getGrowthIcon(passed, contaminated)}>
                        {passed ? passLabel : nonPassLabel}
                    </Icon>
                    {props.info?.moldPredictorEnabled &&
                        <Icon icon={getGrowthIcon(passed, hasMold)}>
                            {passed
                                ? hasMold
                                    ? 'Mould detected'
                                    : 'Mould not detected'
                                : 'Checking for mould'
                            }
                        </Icon>
                    }
                </Group>
            }
            {props.showCfuCount && passed && !props.info?.mlDisabled &&
                <GrowthCounts info={props.info} />
            }
        </>
    )
}

function AttachedValidation(props: Pick<Props, 'info' | 'showCfuCount'>) {
    const smartCheckResult = props.info?.validationResult
        , growthCountResult = props.info?.growthCountResult

    if (!smartCheckResult?.blurCheckPassed) {
        return (
            <Group>
                <Icon icon='help'>Cannot check sample alignment</Icon>
                <Icon icon='error'>Photo is not in focus</Icon>
            </Group>
        )
    }

    const inFrame = smartCheckResult.alignPassed
        , growths = growthCountResult?.growths
        , hasGrowths = growths?.length !== 0
        , hasMold = growths?.some(_ => _.mold)
        , passed = smartCheckResult.alignPassed && smartCheckResult.repetitionPassed

    return (
        <div>
            <Group>
                <Icon icon={getIcon(inFrame)}>
                    {inFrame ? 'Sample is in frame' : 'Sample is not in frame'}
                </Icon>
                <Icon icon='pass'>Photo is in focus</Icon>
            </Group>
            {growths && passed &&
                <Group topMargin>
                    {hasGrowths
                        ? <Icon icon='warning'>Contamination detected</Icon>
                        : <Icon icon='pass'>Contamination not detected</Icon>
                    }
                    {props.info?.moldPredictorEnabled &&
                        <>
                            {hasMold
                                ? <Icon icon='warning'>Mould detected</Icon>
                                : <Icon icon='pass'>Mould not detected</Icon>
                            }
                        </>
                    }
                </Group>
            }
            {props.showCfuCount && passed &&
                <GrowthCounts info={props.info} />
            }
        </div>
    )
}

const ImageValidationResult = (props: Props) =>
    <div className='smart-check-validations'>
        {
            props.isBeingUploaded
                ? <ValidationPlaceholder
                    info={props.info}
                    zeroGrowthCheckEnabled={props.zeroGrowthCheckEnabled}
                    automatedGrowthCountVerificationEnabled={props.automatedGrowthCountVerificationEnabled}
                />
                : props.info?.validationResult &&
                    <div>
                        {props.isHistoricalImage
                            ? <AttachedValidation
                                showCfuCount={props.showCfuCount}
                                info={props.info}
                            />
                            : <DetachedValidation
                                showCfuCount={props.showCfuCount}
                                info={props.info}
                                zeroGrowthCheckEnabled={props.zeroGrowthCheckEnabled}
                                automatedGrowthCountVerificationEnabled={props.automatedGrowthCountVerificationEnabled}
                            />
                        }
                    </div>
        }
    </div>

export default ImageValidationResult

interface GroupProps {
    topMargin?: boolean
    children: React.ReactNode
}

function Group(props: GroupProps) {
    return (
        <div className={classnames('smart-check-validation__message-group rounded', { 'mt-4': props.topMargin })}>
            {props.children}
        </div>
    )
}

function getIcon(pass: boolean | undefined): 'pass' | 'error' {
    return pass ? 'pass' : 'error'
}

function GrowthCounts(props: { info?: SmartInfo }) {
    const growths = props.info?.growthCountResult?.growths

    if (!growths || growths.length === 0)
        return null

    const sum = (x: number, y: number) => x + y
        , totalCount = growths.map(_ => _.cfu).reduce(sum, 0)
        , nonMoldCount = growths.filter(_ => !_.mold).map(_ => _.cfu).reduce(sum, 0)
        , moldCount = growths.filter(_ => _.mold).map(_ => _.cfu).reduce(sum, 0)

    return (
        <Group topMargin>
            <Icon icon='warning'>
                {props.info?.moldPredictorEnabled
                    ? <>
                        <div>
                            Total count: {totalCount}
                        </div>
                        <div>
                            Non-mould: <span className='smart-check-validation__message-success'>{nonMoldCount}</span>
                        </div>
                        <div>
                            Mould: <span className='smart-check-validation__message-failure'>{moldCount}</span>
                        </div>
                    </>
                    : <div>
                        Count: {totalCount}
                    </div>
                }
            </Icon>
        </Group>
    )
}
