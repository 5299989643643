function searchDuplicateName<T extends { id: string, name: string }>(entity: Partial<T>, entities: T[]) {
    if (entity.id && entity.name)
        return entities
            .filter(_ => _.id !== entity.id)
            .find(_ => _.name.trim().toUpperCase() === entity.name!.trim().toUpperCase())
    return entity.name && entities.find(_ => _.name.trim().toUpperCase() === entity.name!.trim().toUpperCase())
}

export {
    searchDuplicateName,
}
