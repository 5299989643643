import { useState, classnames } from '_/facade/react'

import HoverMenu from '_/components/overlay/hover-menu'

import type { ChildrenTrend, Breaches } from '_/model/reports/locations-report/types'
import { useTimeService } from '_/components/time'
import { formatDatePeriod, areDatesInCurrentYear } from '_/model/reports/missed-monitoring-report/helpers'

const NO_LIMIT_BREACHES = 'No limit breaches'

interface Props {
    trend: ChildrenTrend
}

function LimitBreachData(props: Props) {
    const [element, setElement] = useState<HTMLDivElement | null>(null)
        , {currentPeriodBreaches, previousPeriodBreaches} = props.trend
        , calculateBreachesTotal = (b: Breaches | undefined) => !b?.total ? undefined : b.actionBreaches + b.alertBreaches
        , currentPeriodBreachesTotal = calculateBreachesTotal(currentPeriodBreaches)
        , previousPeriodBreachesTotal = calculateBreachesTotal(previousPeriodBreaches)
        , timeService = useTimeService()
        , areDateRangesOutOfYear = areDatesInCurrentYear(props.trend.currentPeriodDateRange, timeService)
            && areDatesInCurrentYear(props.trend.previousPeriodDateRange, timeService)
        , currentPeriodDateRange = formatDatePeriod(timeService, props.trend.currentPeriodDateRange, areDateRangesOutOfYear)
        , previousPeriodDateRange = formatDatePeriod(timeService, props.trend.previousPeriodDateRange, areDateRangesOutOfYear)

    return (
        <td>
            <div ref={setElement} className='d-inline-block'>
                {!props.trend.currentPeriodBreaches?.total
                    ? <span className='text-muted text-uppercase'>{NO_LIMIT_BREACHES}</span>
                    : <div>
                        <span className='me-2' data-testid='limit-breach-value'>
                            {currentPeriodBreachesTotal}
                        </span>
                        {currentPeriodBreachesTotal !== undefined && previousPeriodBreachesTotal !== undefined &&
                            <i className={classnames('material-icons md-24 align-bottom', {
                                'text-secondary': currentPeriodBreachesTotal === previousPeriodBreachesTotal,
                                'text-danger rotate-315': currentPeriodBreachesTotal > previousPeriodBreachesTotal,
                                'text-success rotate-45': currentPeriodBreachesTotal < previousPeriodBreachesTotal,
                            })} data-testid='limit-breach-trend-arrow'>
                                arrow_forward
                            </i>
                        }
                    </div>
                }

                <HoverMenu element={element} position='right' showTriangle>
                    <div className='legend-bubble text-white py-2 px-4 ms-1'>
                        {breachesFormat(currentPeriodBreaches, true, currentPeriodDateRange)}
                        {breachesFormat(previousPeriodBreaches, false, previousPeriodDateRange)}
                    </div>
                </HoverMenu>
            </div>
        </td>
    )
}

function breachesFormat(breaches: Breaches | undefined, areBreachesForCurrentPeriod: boolean, period: string) {
    return <div className={classnames({ 'mt-2': !areBreachesForCurrentPeriod })} data-testid={`limit-breaches-trend-tooltip-${areBreachesForCurrentPeriod ? 'current-period' : 'previous-period'}`}>
        <div className='fw-bold' data-testid={`date-range-${areBreachesForCurrentPeriod ? 'current-period' : 'previous-period'}`}>{period}</div>
        {!breaches?.total
            ? NO_LIMIT_BREACHES
            : <div>
                {breaches.actionBreaches} action limit {breaches.actionBreaches === 1 ? 'breach' : 'breaches'}
                <br/>
                {breaches.alertBreaches} alert limit {breaches.alertBreaches === 1 ? 'breach' : 'breaches'}
            </div>
        }
    </div>
}

export default LimitBreachData
