import { Field } from 'react-final-form'
import { useSelector } from '_/facade/react'

import type { RoleEdit } from '_/model/roles/types'

import * as bp from '_/constants/business-permission'
import * as tiers from '_/constants/tiers'

import InlineCheckbox from '_/components/checkbox-inline'

import * as h from './helpers'

interface DisabledProps {
    disabled: boolean
}

interface PermissionInputProps {
    permission: bp.BusinessPermission
    value: bp.BusinessPermission[]
    onChange: (_: bp.BusinessPermission[]) => void
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
    testId?: string
}

function PermissionInput(props: PermissionInputProps & DisabledProps) {
    const tier = useSelector(_ => _.auth.user ? _.auth.user.membership.tier : tiers.COMPLIANCE)
        , hasNoPermission = useSelector(_ => !_.auth.permissions.manageRoles)
            || h.hasNoTierPermission(props.permission, tier)
        , id = `business-permission-${props.permission}`
        , checked = props.value.includes(props.permission)
        , secondaryParents = h.secondaryParents(props.permission)
        , parentNames = bp.BUSINESS_PERMISSION
            .filter(_ => secondaryParents.includes(_.id))
            .map(_ => _.name)
        , hint = parentNames.length === 0
            ? ''
            : ` (requires ${parentNames.join(', ')})`

    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const checked = event.target.checked

        if (!checked) {
            props.onChange(
                props.value.filter(_ => _ !== props.permission)
            )
        }
        else {
            props.onChange(
                props.value.concat(props.permission).sort((one, two) => one - two)
            )
        }
    }

    return <InlineCheckbox
        className='d-inline-block'
        id={id}
        name={id}
        checked={checked}
        hasNoPermissions={hasNoPermission}
        disabled={props.disabled || h.editDisabled(props.permission, props.value)}
        onChange={handleChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        children={
            bp.BUSINESS_PERMISSION.filter(_ => _.id === props.permission)[0].name + hint
        }
        testId={props.testId}
    />
}

interface EditablePermissionsInputProps {
    value: bp.BusinessPermission[]
    onChange: (_: bp.BusinessPermission[]) => void
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => void
}

function EditablePermissionsInput(props: EditablePermissionsInputProps & DisabledProps) {
    const manageColonyCounterRoles = useSelector(_ =>_.auth.permissions.colonyCounterMode)

    const render = (permission: bp.BusinessPermission) =>
            <div>
                {h.permissionLevel(permission) > 0 && <span className='me-4' />}
                {h.permissionLevel(permission) > 1 && <span className='me-4' />}
                <PermissionInput {...props} permission={permission} testId={`field-permission-${permission}`} />
            </div>

    if (manageColonyCounterRoles) {
        return (
            <div>
                <div className='mb-3'>
                    {render(bp.CC_VIEW_PLATES)}
                    {render(bp.CC_CREATE_AND_EDIT_PLATES)}
                    {render(bp.CC_EXPORT_DATA)}
                    {render(bp.CC_VIEW_AUDIT_TRAIL)}
                    {render(bp.CC_VIEW_USERS)}
                    {render(bp.CC_MANAGE_API_KEYS)}
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className='mb-3'>
                <label className='form-label' data-testid='section-label'>Analysis</label>
                {render(bp.VIEW_THE_DASHBOARD)}
                {render(bp.VIEW_THE_GLOBAL_AUDIT_TRAIL)}
                {render(bp.USE_ANALYSIS_AND_REPORTS)}
                {render(bp.EXPORT_DATA)}
                {render(bp.EXPORT_CUSTOM_REPORTS_DATA)}
                {render(bp.MANAGE_CUSTOM_REPORTS)}
            </div>

            <div className='mb-3'>
                <label className='form-label' data-testid='section-label'>Samples</label>
                {render(bp.VIEW_SAMPLES)}
                {render(bp.BOOK_IN_SAMPLES)}
                {render(bp.READ_SAMPLES)}
                {render(bp.EDIT_SAMPLES)}
                {render(bp.CONFIRM_BOOK_IN_FOR_SAMPLES)}
                {render(bp.VERIFY_CFU_COUNT)}
                {render(bp.EXPORT_SAMPLES)}
                {render(bp.IMPORT_SAMPLES)}
            </div>

            <div className='mb-3'>
                <label className='form-label' data-testid='section-label'>User management</label>
                {render(bp.VIEW_USERS)}
                {render(bp.MANAGE_API_KEYS)}
            </div>

            <div className='mb-3'>
                <label className='form-label' data-testid='section-label'>Settings</label>
                {render(bp.MANAGE_CUSTOM_FIELDS)}
                {render(bp.MANAGE_EXPOSURE_LOCATIONS)}
                {render(bp.MANAGE_GRADES)}
                {render(bp.MANAGE_OPERATORS)}
                {render(bp.MANAGE_SESSIONS)}
                {render(bp.MANAGE_SAMPLE_TYPES)}
                {render(bp.MANAGE_FLOOR_PLANS)}
                {render(bp.MANAGE_OBJECTIONABLE_ORGANISMS)}
                {render(bp.MANAGE_TRENDS)}
            </div>

            <div className='mb-3'>
                <label className='form-label' data-testid='section-label'>Scheduling</label>
                {render(bp.VIEW_SCHEDULE)}
                {render(bp.EDIT_SCHEDULE)}
                {render(bp.MANAGE_MONITORING_GROUPS)}
                {render(bp.VIEW_MONITORING_OVERVIEW)}
                {render(bp.EDIT_MONITORING_OVERVIEW)}
            </div>

            <div className='mb-3'>
                <label className='form-label' data-testid='section-label'>Insights</label>
                {render(bp.VIEW_INSIGHTS)}
            </div>

            <div className='mb-3'>
                <label className='form-label' data-testid='section-label'>Trends</label>
                {render(bp.VIEW_TRENDS)}
                {render(bp.EDIT_TREND_INVESTIGATION_REFS)}
            </div>
        </div>
    )
}

const permissionsEqual = (x: number[], y: number[] | undefined) => !!y && x.length === y.length && x.every((v, i) => v === y[i])

function PermissionField(props: DisabledProps) {
    const name: keyof RoleEdit = 'permissions'

    return <Field
        name={name}
        isEqual={permissionsEqual}
        render={_ =>
            <EditablePermissionsInput
                value={_.input.value}
                onChange={_.input.onChange}
                onBlur={_.input.onBlur}
                onFocus={_.input.onFocus}
                disabled={props.disabled}
            />
        }
    />
}

export default PermissionField
