import type { ActionType, Action, Default } from './types'

function actionFactory(prefix: string) {
    function action<P, R = Default>(type: ActionType<P, R>): [P] extends [void]
        ? (() => Action<void, R>) & {type: ActionType<P, R>}
        : ((payload: P) => Action<P, R>) & {type: ActionType<P, R>}

    function action<P, R>(type: ActionType<P, R>) {
        const fullType = prefix + '.' + type
            , result = function (payload: P) {
            return arguments.length ? { type: fullType, payload } : { type: fullType }
        }

        result.type = fullType
        return result
    }

    return action
}

function isPayloadAction(action: Action<any, any>): action is { type: ActionType<any, any>, payload: any } {
    return 'payload' in action
}

function actionHasType<P, R>(action: any, type: ActionType<P, R>): action is Action<P, R> {
    return action.type === type
}

export { isPayloadAction, actionHasType, actionFactory }
