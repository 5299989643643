import * as Routes from '_/constants/routes'
import type Permissions from '_/model/permissions/type'

type AppRoutePermission = keyof Permissions | 'none'

type ParameterType = 'date' | 'dummy-type-to-avoid-unnecessary-conditional-error'

interface AppRoute {
    name: Routes.RouteName
    path: string
    forwardTo?: Routes.RouteName
    contextSwitchAware: boolean
    permissions: AppRoutePermission[]
    parameterTypes?: [string, ParameterType][]
}

const routes: AppRoute[] = [
    {
        name: Routes.LOG_IN,
        path: '/log-in',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.AVAILABLE_MEMBERSHIPS,
        path: '/available-memberships',
        contextSwitchAware: false,
        permissions: ['notAuthenticated']
    },
    {
        name: Routes.SSO_LOG_IN,
        path: '/sso-log-in',
        contextSwitchAware: false,
        permissions: ['notAuthenticated']
    },
    {
        name: Routes.SSO_AVAILABLE_MEMBERSHIPS,
        path: '/sso-available-memberships',
        contextSwitchAware: false,
        permissions: ['notAuthenticated']
    },
    {
        name: Routes.SSO_SIGN_UP,
        path: '/sso-sign-up',
        contextSwitchAware: false,
        permissions: ['notAuthenticated']
    },
    {
        name: Routes.AUTH_CONFIRM,
        path: '/confirm',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.AUTH_CONFIRM_EMAIL,
        path: '/confirm-email/:token',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.CONFIRM_CHANGED_PASSWORD,
        path: '/confirm-changed-password/:token',
        contextSwitchAware: false,
        permissions: ['none'],
    },
    {
        name: Routes.FORGOT_PASSWORD,
        path: '/forgot-password',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.FORGOT_PASSWORD_SENT,
        path: '/forgot-password-sent',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.SET_PASSWORD_PAGE,
        path: '/set-password/:token',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.PLATES,
        path: '/plates',
        contextSwitchAware: true,
        permissions: ['readColonyCounterPlates'],
    },
    {
        name: Routes.PLATES_EXPORT,
        path: '/export',
        contextSwitchAware: true,
        permissions: ['exportData'],
    },
    {
        name: Routes.PLATES_READING,
        path: '/reading',
        contextSwitchAware: true,
        permissions: ['readColonyCounterPlates'],
    },
    {
        name: Routes.SAMPLES,
        path: '/viable-samples',
        contextSwitchAware: true,
        permissions: ['readSamples'],
    },
    {
        name: Routes.SAMPLES_EXPORT,
        path: '/export',
        contextSwitchAware: true,
        permissions: ['exportSamples'],
    },
    {
        name: Routes.SAMPLES_COLUMNS,
        path: '/columns',
        contextSwitchAware: false,
        permissions: ['readSamples'],
    },
    {
        name: Routes.SAMPLES_BOOKING,
        path: '/booking',
        contextSwitchAware: false,
        permissions: ['bookSamples'],
    },
    {
        name: Routes.SAMPLES_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['readSamples'],
    },
    {
        name: Routes.SAMPLES_READING,
        path: '/reading',
        contextSwitchAware: false,
        permissions: ['performSampleReading'],
    },
    {
        name: Routes.SAMPLES_REPORT,
        path: '/:id/report',
        contextSwitchAware: false,
        permissions: ['readSampleReport'],
    },
    {
        name: Routes.NON_VIABLE_SAMPLES,
        path: '/non-viable-samples',
        contextSwitchAware: false,
        permissions: ['readSamples'],
    },
    {
        name: Routes.NON_VIABLE_SAMPLES_RECORDING,
        path: '/recording',
        contextSwitchAware: false,
        permissions: ['bookSamples'],
    },
    {
        name: Routes.NON_VIABLE_SAMPLES_EXPORT,
        path: '/export',
        contextSwitchAware: true,
        permissions: ['exportSamples'],
    },
    {
        name: Routes.NON_VIABLE_SAMPLES_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['readSamples'],
    },
    {
        name: Routes.USERS_SIGN_UP,
        path: '/sign-up?token',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.CONTEXTS_INVITE_CONFIRM,
        path: '/invite/:token',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.USER_ACCOUNT,
        path: '/account',
        forwardTo: Routes.USER_ACCOUNT_GENERAL_SETTINGS,
        contextSwitchAware: true,
        permissions: ['authenticated'],
    },
    {
        name: Routes.USER_ACCOUNT_GENERAL_SETTINGS,
        path: '/general-settings',
        contextSwitchAware: true,
        permissions: ['authenticated'],
    },
    {
        name: Routes.USER_NOTIFICATIONS,
        path: '/notifications',
        contextSwitchAware: true,
        permissions: ['readNotificationPreferences'],
    },
    {
        name: Routes.USER_CHANGE_PASSWORD,
        path: '/account/change-password',
        contextSwitchAware: false,
        permissions: ['authenticated'],
    },
    {
        name: Routes.SETTINGS,
        path: '/settings',
        forwardTo: Routes.SETTINGS_CONTEXTS,
        contextSwitchAware: false,
        permissions: ['readPredefinedLists'],
    },
    {
        name: Routes.SETTINGS_USERS,
        path: '/users',
        contextSwitchAware: true,
        permissions: ['readUsers'],
    },
    {
        name: Routes.SETTINGS_USERS_USER,
        path: '/:id',
        forwardTo: Routes.SETTINGS_USERS_MEMBERSHIPS,
        contextSwitchAware: false,
        permissions: ['readUsers'],
    },
    {
        name: Routes.SETTINGS_USERS_API_KEYS,
        path: '/api-keys',
        contextSwitchAware: false,
        permissions: ['manageApiKeys'],
    },
    {
        name: Routes.SETTINGS_USERS_API_KEYS_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['manageApiKeys'],
    },
    {
        name: Routes.SETTINGS_USERS_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['editUsers'],
    },
    {
        name: Routes.SETTINGS_USERS_MEMBERSHIPS,
        path: '/memberships',
        contextSwitchAware: false,
        permissions: ['manageMemberships'],
    },
    {
        name: Routes.SETTINGS_USERS_AUDIT_TRAILS,
        path: '/audit-trails',
        contextSwitchAware: false,
        permissions: ['readUsers'],
    },
    {
        name: Routes.SETTINGS_ROLES,
        path: '/roles',
        contextSwitchAware: true,
        permissions: ['manageRoles'],
    },
    {
        name: Routes.SETTINGS_ROLES_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['manageRoles'],
    },
    {
        name: Routes.SETTINGS_ROLES_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['manageRoles'],
    },
    {
        name: Routes.SETTINGS_ORGANISM_IDENTIFICATION,
        path: '/organism-identification',
        forwardTo: Routes.SETTINGS_ORGANISM_IDENTIFICATION_IDENTIFICATIONS,
        contextSwitchAware: false,
        permissions: ['editOrganismIdentification'],
    },
    {
        name: Routes.SETTINGS_ORGANISM_IDENTIFICATION_IDENTIFICATIONS,
        path: '/identifications',
        contextSwitchAware: false,
        permissions: ['editOrganismIdentification'],
    },
    {
        name: Routes.SETTINGS_ORGANISM_IDENTIFICATION_TRAIL,
        path: '/audit-trails',
        contextSwitchAware: false,
        permissions: ['editOrganismIdentification'],
    },
    {
        name: Routes.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS,
        path: '/objectionable-identifications',
        contextSwitchAware: true,
        permissions: ['readObjectionableOrganisms'],
    },
    {
        name: Routes.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['editObjectionableOrganisms'],
    },
    {
        name: Routes.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['editObjectionableOrganisms'],
    },
    {
        name: Routes.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS_TRAIL,
        path: '/trail',
        contextSwitchAware: false,
        permissions: ['readObjectionableOrganisms'],
    },
    {
        name: Routes.SETTINGS_SAMPLE_TYPES,
        path: '/sample-types',
        contextSwitchAware: true,
        permissions: ['readSampleType'],
    },
    {
        name: Routes.SETTINGS_SAMPLE_TYPES_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['editSampleType'],
    },
    {
        name: Routes.SETTINGS_SAMPLE_TYPES_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['editSampleType'],
    },
    {
        name: Routes.SETTINGS_LOCATIONS,
        path: '/locations',
        forwardTo: Routes.SETTINGS_EXPOSURE_LOCATIONS,
        contextSwitchAware: false,
        permissions: ['readExposureLocations'],
    },
    {
        name: Routes.SETTINGS_EXPOSURE_LOCATIONS,
        path: '/exposure-locations',
        contextSwitchAware: true,
        permissions: ['readExposureLocations'],
    },
    {
        name: Routes.SETTINGS_EXPOSURE_LOCATIONS_CREATE,
        path: '/:parentId/create',
        contextSwitchAware: false,
        permissions: ['editExposureLocations'],
    },
    {
        name: Routes.SETTINGS_EXPOSURE_LOCATIONS_EDIT,
        path: '/:parentId/:id/edit',
        contextSwitchAware: false,
        permissions: ['editExposureLocations'],
    },
    {
        name: Routes.SETTINGS_FLOOR_PLANS,
        path: '/floor-plans',
        contextSwitchAware: true,
        permissions: ['manageFloorPlans'],
    },
    {
        name: Routes.SETTINGS_FLOOR_PLANS_ADD,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['manageFloorPlans'],
    },
    {
        name: Routes.SETTINGS_FLOOR_PLANS_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['manageFloorPlans'],
    },
    {
        name: Routes.SETTINGS_ACTION_ALERT_LIMITS,
        path: '/grades',
        contextSwitchAware: true,
        permissions: ['readActionAlertLimits'],
    },
    {
        name: Routes.SETTINGS_OPERATORS,
        path: '/operators',
        contextSwitchAware: true,
        permissions: ['readSampleOperator'],
    },
    {
        name: Routes.SETTINGS_OPERATORS_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['editSampleOperator'],
    },
    {
        name: Routes.SETTINGS_OPERATORS_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['editSampleOperator'],
    },
    {
        name: Routes.SETTINGS_SESSIONS,
        path: '/sessions',
        contextSwitchAware: true,
        permissions: ['readSampleSession'],
    },
    {
        name: Routes.SETTINGS_SESSIONS_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['editSampleSession'],
    },
    {
        name: Routes.SETTINGS_SESSIONS_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['editSampleSession'],
    },
    {
        name: Routes.SETTINGS_SAMPLE_FIELDS,
        path: '/sample-fields',
        contextSwitchAware: true,
        permissions: ['readCustomFields'],
    },
    {
        name: Routes.SETTINGS_SAMPLE_FIELDS_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['readCustomFields'],
    },
    {
        name: Routes.SETTINGS_SAMPLE_FIELDS_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['readCustomFields'],
    },
    {
        name: Routes.SETTINGS_TRENDS,
        path: '/trends',
        contextSwitchAware: true,
        permissions: ['readTrendSettings'],
    },
    {
        name: Routes.SETTINGS_TRENDS_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['readTrendSettings'],
    },
    {
        name: Routes.SETTINGS_TRENDS_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['readTrendSettings'],
    },
    {
        name: Routes.SETTINGS_CONTEXTS,
        path: '/sites',
        contextSwitchAware: true,
        permissions: ['readContexts'],
    },
    {
        name: Routes.SETTINGS_CONTEXTS_EXPORT,
        path: '/:id/export',
        contextSwitchAware: true,
        permissions: ['exportContexts'],
    },
    {
        name: Routes.SETTINGS_CONTEXTS_CREATE,
        path: '/create',
        contextSwitchAware: true,
        permissions: ['readContexts'],
    },
    {
        name: Routes.SETTINGS_CONTEXTS_EDIT,
        path: '/:id/edit',
        contextSwitchAware: true,
        permissions: ['readContexts'],
    },
    {
        name: Routes.CONTEXTS_INVITE_CONFIRMED,
        path: '/invite-confirmed',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.CONTEXTS_ASSIGN_CONFIRM,
        path: '/assign-confirm/:token',
        contextSwitchAware: false,
        permissions: ['notAuthenticated'],
    },
    {
        name: Routes.DASHBOARD,
        path: '/home',
        contextSwitchAware: true,
        permissions: ['readDashboard'],
    },
    {
        name: Routes.ANALYSIS,
        path: '/analysis',
        contextSwitchAware: true,
        permissions: ['readAnalysis'],
    },
    {
        name: Routes.TRAILS,
        path: '/global-audit-trails',
        contextSwitchAware: true,
        permissions: ['readGlobalTrails'],
    },
    {
        name: Routes.REPORTS,
        path: '/reports',
        forwardTo: Routes.CUSTOM_REPORTS,
        contextSwitchAware: true,
        permissions: ['readCustomReports'],
    },
    {
        name: Routes.LIMIT_BREACH_REPORT,
        path: '/limit-breach-report',
        contextSwitchAware: true,
        permissions: ['readReports'],
    },
    {
        name: Routes.FINGERDABS,
        path: '/fingerdabs',
        forwardTo: Routes.FINGERDABS_DAILY_FINGERDABS,
        contextSwitchAware: true,
        permissions: ['readReports'],
    },
    {
        name: Routes.FINGERDABS_DAILY_FINGERDABS,
        path: '/daily-fingerdabs',
        contextSwitchAware: true,
        permissions: ['readReports'],
    },
    {
        name: Routes.FINGERDABS_OPERATOR_PERFORMANCE,
        path: '/operator-performance',
        contextSwitchAware: true,
        permissions: ['readOperatorPerformanceReport'],
    },
    {
        name: Routes.LOCATIONS_REPORT,
        path: '/locations-report',
        contextSwitchAware: true,
        permissions: ['readLocationsReport'],
    },
    {
        name: Routes.OPERATORS_REPORT,
        path: '/operators-report',
        contextSwitchAware: true,
        permissions: ['readOperatorsReport'],
    },
    {
        name: Routes.MISSED_MONITORING_REPORT,
        path: '/missed-monitoring-report',
        contextSwitchAware: true,
        permissions: ['readMissedMonitoringReport'],
    },
    {
        name: Routes.HELP,
        path: '/help*page',
        contextSwitchAware: true,
        permissions: ['authenticated'],
    },
    {
        name: Routes.MAINTENANCE,
        path: '/maintenance',
        contextSwitchAware: true,
        permissions: ['none'],
    },
    {
        name: Routes.CUSTOM_REPORTS,
        path: '/custom-reports',
        contextSwitchAware: true,
        permissions: ['readCustomReports'],
    },
    {
        name: Routes.CUSTOM_REPORTS_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['editCustomReports'],
    },
    {
        name: Routes.CUSTOM_REPORTS_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['editCustomReports'],
    },
    {
        name: Routes.CUSTOM_REPORTS_VIEW,
        path: '/:id/view',
        contextSwitchAware: false,
        permissions: ['readCustomReports'],
    },
    {
        name: Routes.SCHEDULING,
        path: '/scheduling',
        forwardTo: Routes.SCHEDULING_MONTHLY_SCHEDULER,
        contextSwitchAware: true,
        permissions: ['readSchedule'],
    },
    {
        name: Routes.SCHEDULING_MONTHLY_SCHEDULER,
        path: '/monthly-scheduler',
        contextSwitchAware: true,
        permissions: ['readSchedule'],
    },
    {
        name: Routes.SCHEDULING_DAY_SCHEDULER,
        path: '/day-schedule',
        contextSwitchAware: true,
        permissions: ['readSchedule'],
    },
    {
        name: Routes.SCHEDULING_DAY_SCHEDULER_TRAILS,
        path: '/:id/trail',
        contextSwitchAware: false,
        permissions: ['readSchedule'],
    },
    {
        name: Routes.SCHEDULING_DAY_SCHEDULER_CREATE_SERIES,
        path: '/create-series',
        contextSwitchAware: false,
        permissions: ['readSchedule'],
    },
    {
        name: Routes.SCHEDULING_DAY_SCHEDULER_CREATE_AD_HOC_GROUP,
        path: '/create-ad-hoc-group',
        contextSwitchAware: false,
        permissions: ['readSchedule'],
    },
    {
        name: Routes.SCHEDULING_MONITORING_GROUPS,
        path: '/monitoring-groups',
        contextSwitchAware: true,
        permissions: ['editMonitoringGroups'],
    },
    {
        name: Routes.SCHEDULING_MONITORING_GROUPS_CREATE,
        path: '/create',
        contextSwitchAware: false,
        permissions: ['editMonitoringGroups'],
    },
    {
        name: Routes.SCHEDULING_MONITORING_GROUPS_EDIT,
        path: '/:id/edit',
        contextSwitchAware: false,
        permissions: ['editMonitoringGroups'],
    },
    {
        name: Routes.SCHEDULING_MONITORING_OVERVIEW,
        path: '/monitoring-overview',
        contextSwitchAware: true,
        permissions: ['readMonitoringOverview'],
        parameterTypes: [['date', 'date']]
    },
    {
        name: Routes.SCHEDULING_MONITORING_OVERVIEW_TRAIL,
        path: '/trail',
        contextSwitchAware: true,
        permissions: ['readMonitoringOverview'],
    },
    {
        name: Routes.SCHEDULING_MONITORING_OVERVIEW_SAMPLE_TRAIL,
        path: '/:id/:sessionId/sample-trail',
        contextSwitchAware: true,
        permissions: ['readMonitoringOverview'],
    },
    {
        name: Routes.NOTIFICATIONS,
        path: '/notifications',
        contextSwitchAware: true,
        permissions: ['authenticated'],
    },
    {
        name: Routes.TRENDS,
        path: '/trends',
        forwardTo: Routes.TRENDS_ALL_TRENDS,
        contextSwitchAware: true,
        permissions: ['readTrends'],
    },
    {
        name: Routes.TRENDS_ALL_TRENDS,
        path: '/all-trends',
        contextSwitchAware: true,
        permissions: ['readTrends'],
    },
    {
        name: Routes.TRENDS_WITH_REF_TRENDS,
        path: '/with-investigation-reference-trends',
        contextSwitchAware: true,
        permissions: ['readTrends'],
    },
    {
        name: Routes.TRENDS_WITHOUT_REF_TRENDS,
        path: '/without-investigation-reference-trends',
        contextSwitchAware: true,
        permissions: ['readTrends'],
    },
]

export default routes
