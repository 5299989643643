import { Form, useField } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { classnames, useState, useEffect, useAction, useSelector, useCallback } from '_/facade/react'

import AuditTrails from '_/components/audit-trail-list'
import Button, { Close } from '_/components/button'
import { Submit, submitDisabled, TextField, resetForm } from '_/components/form'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import { Modal, ModalBody, ModalHeader, ModalFooter } from '_/components/modal'
import TabNavbar from '_/components/tab-navbar'
import { showFieldError } from '_/components/form/helpers'
import ActiveField from '_/features/predefined-lists/shared/active-field'

import * as tabs from '_/constants/modal-tab'
import * as routes from '_/constants/routes'

import { loadExposureLocationList as loadExposureLocationListAction } from '_/features/predefined-lists/exposure-locations/actions'
import { loadSampleTypeList as loadSampleTypeListAction } from '_/features/predefined-lists/sample-type/actions'
import * as routerActions from '_/features/routing/actions'

import { getEditableExpectations, toMonitoringGroup, getSamplesQuantity } from '_/model/scheduling/monitoring-groups/helpers'
import type { MonitoringGroup, Expectations, MonitoringGroupEdit } from '_/model/scheduling/monitoring-groups/types'
import validate from '_/model/scheduling/monitoring-groups/validate'
import * as tree from '_/utils/tree'

import ExposureLocations from './exposure-locations'
import * as spinnerAction from '_/features/spinner/actions'
import * as deletionActions from '_/features/confirmation/actions'
import * as actions from './actions'
import type { FormApi } from 'final-form'
import { defaultTextNode } from '_/model/text/text'
import { formatGroupName } from '_/model/scheduling/day-scheduler/helpers'
import type { AuditTrail } from '_/model/audit-trail/types'

interface Props {
    allGroups: MonitoringGroup[]
    onUpdate: () => void
    onClose?: () => void
    onCreateAdHocGroup?: (_: MonitoringGroupEdit) => Promise<void>
    isAdHocGroup?: boolean
}

const defaultMonitoringGroup: MonitoringGroup = {
        id: '',
        name: '',
        expectations: [],
        isActive: true,
        inUse: false,
    }
    , defaultTrail: AuditTrail = {
        events: [],
    }

function useDataInitialization(
    setInitialValue: (_: MonitoringGroup) => void,
    setTrail: (_: AuditTrail) => void
) {
    const [monitoringGroup, setMonitoringGroup] = useState<MonitoringGroup | undefined>(undefined)

        , locations = useSelector(_ => _.predefinedLists.exposureLocations)
        , sampleTypes = useSelector(_ => _.predefinedLists.sampleTypes)
        , monitoringGroupId = useSelector<string | undefined>(_ => _.router.route?.params.id)
        , expectationsFromRoute = useSelector(_ => _.router.route?.params.expectations)

        , loadExposureLocationList = useAction(loadExposureLocationListAction)
        , loadSampleTypes = useAction(loadSampleTypeListAction)
        , loadMonitoringGroup = useAction(actions.loadMonitoringGroup)
        , loadMonitoringGroupTrail = useAction(actions.loadMonitoringGroupTrail)

    useEffect(
        () => {
            Promise.all([loadExposureLocationList(), loadSampleTypes()])
                .then(_ => {
                    if (!monitoringGroupId) {
                        setMonitoringGroup({...defaultMonitoringGroup, expectations: expectationsFromRoute ?? []})
                        return
                    }

                    loadMonitoringGroup(monitoringGroupId).then(monitoringGroup => {
                        setMonitoringGroup(monitoringGroup)
                    })

                    loadMonitoringGroupTrail(monitoringGroupId).then(setTrail)
                })
        },
        [monitoringGroupId, expectationsFromRoute, loadExposureLocationList, loadSampleTypes, loadMonitoringGroup, loadMonitoringGroupTrail, setMonitoringGroup, setTrail]
    )

    useEffect(
        () => {
            const flat = tree.looseFlatTree(locations)

            if (monitoringGroup === undefined)
                return

            setInitialValue({
                ...monitoringGroup,
                expectations: getEditableExpectations(flat, sampleTypes, monitoringGroup.expectations),
            })
        },
        [sampleTypes, locations, monitoringGroup, setInitialValue]
    )

    return [monitoringGroupId, locations, sampleTypes] as const
}

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SCHEDULING_MONITORING_GROUPS), [navigateTo])
    return navigateToList
}

function useSubmit(
    initialValue: MonitoringGroup,
    onUpdate: () => void,
    isAdHocGroup?: boolean,
    createAdHocGroup?: (_: MonitoringGroupEdit) => Promise<void>
) {
    const contextId = useSelector(_ => _.auth.user && _.auth.user.membership.contextId)
        , monitoringGroupId = useSelector<string | undefined>(_ => _.router.route?.params.id)
        , createMonitoringGroup = useAction(actions.createMonitoringGroup)
        , editMonitoringGroup = useAction(actions.editMonitoringGroup)
        , navigateToList = useNavigateToList()
        , showSpinner = useAction(spinnerAction.showSpinner)
        , hideSpinner = useAction(spinnerAction.hideSpinner)

    function handleSubmit(newMonitoringGroup: MonitoringGroup, form: FormApi<MonitoringGroup>) {
        if (!contextId)
            return

        const monitoringGroup = toMonitoringGroup(newMonitoringGroup)
            , result = monitoringGroupId
                ? editMonitoringGroup({
                    id: monitoringGroupId,
                    oldMonitoringGroup: toMonitoringGroup(initialValue),
                    newMonitoringGroup: monitoringGroup,
                })
                : isAdHocGroup ? createAdHocGroup!(monitoringGroup) : createMonitoringGroup(monitoringGroup)

        showSpinner()
        return result
            .then(() => resetForm(form))
            .finally(hideSpinner)
            .then(() => {
                if (!isAdHocGroup)
                    navigateToList()

                onUpdate()
            })
    }

    return handleSubmit
}

function IncludedSamples() {
    const expectations = useField<Expectations[]>('expectations').input.value
        , samplesIncluded = getSamplesQuantity(expectations)
        , sampleSuffix = samplesIncluded === 1 ? 'sample' : 'samples'

    return (
        <span className='align-baseline'>{`${samplesIncluded} viable ${sampleSuffix} included`}</span>
    )
}

function MonitoringGroupModal(props: Props) {
    const [initialValue, setInitialValue] = useState(defaultMonitoringGroup)
        , [trail, setTrail] = useState(defaultTrail)
        , [monitoringGroupId, locations, sampleTypes] = useDataInitialization(setInitialValue, setTrail)
        , submit = useSubmit(initialValue, props.onUpdate, props.isAdHocGroup, props.onCreateAdHocGroup)
        , navigateToList = useNavigateToList()
        , [activeTab, setActiveTab] = useState(tabs.DATA)
        , onClose = props.onClose ?? navigateToList
        , handleRemove = useDeletionModal(initialValue, props.onUpdate)

    return (
        <Modal
            isOpen
            onClose={onClose}
            contentClassName='planning-window'
        >
            <Form<MonitoringGroup>
                onSubmit={submit}
                validate={_ => validate(_, props.allGroups)}
                initialValues={initialValue}
                mutators={{...arrayMutators}}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form} />

                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4 data-testid='monitoring-group-modal'>{monitoringGroupId ? 'Edit monitoring group' : 'New monitoring group'}</h4>
                                    <Close onClick={onClose} />
                                </div>
                                <TabNavbar>
                                    <Button
                                        onClick={() => setActiveTab(tabs.DATA)}
                                        className={classnames('btn-link navbar-tab me-4', {active: activeTab === tabs.DATA})}
                                    >
                                        Group details
                                    </Button>
                                    <Button
                                        onClick={() => setActiveTab(tabs.AUDIT_TRAIL)}
                                        className={classnames('btn-link navbar-tab', {active: activeTab === tabs.AUDIT_TRAIL})}
                                        disabled={!monitoringGroupId}
                                    >
                                        Audit trail
                                    </Button>
                                </TabNavbar>
                            </div>
                        </ModalHeader>
                        <ModalBody noDefaultHeight={true}>
                            <div className='planning-body'>
                                { activeTab === tabs.DATA
                                    && <>
                                        <TextField name='name' id='name' testId='group-name'>Group name</TextField>
                                        <ExposureLocations exposureLocations={locations} sampleTypes={sampleTypes} canEdit={!initialValue.inUse}/>
                                    </> }
                                { activeTab === tabs.AUDIT_TRAIL && <AuditTrails trail={trail} /> }

                            </div>
                        </ModalBody>
                        <ModalFooter className='border-top-0 modal-footer-height'>
                            {activeTab !== tabs.AUDIT_TRAIL &&
                                <div className='d-flex flex-fill'>
                                    {monitoringGroupId && !props.isAdHocGroup &&
                                        <div>
                                            <Button
                                                className='text-danger bg-transparent border-0 me-2'
                                                onClick={() => handleRemove(form.form)}
                                                disabled={initialValue.inUse}
                                            >
                                                Remove
                                            </Button>
                                            <ActiveField inUse={initialValue.inUse} isActive={initialValue.isActive} />
                                        </div>
                                    }
                                    <div className={classnames('d-flex align-items-center pb-1', showFieldError(form.form.getFieldState('expectations') ?? {}) && 'flex-column pb-0')}>
                                        <IncludedSamples />
                                        <ExpectationError />
                                    </div>
                                    <div className='ms-auto'>
                                        <Button className='text-muted bg-transparent border-0 me-2' onClick={onClose}>Cancel</Button>
                                        <Submit disabled={submitDisabled(form)} testId='submit-changes'>{monitoringGroupId ? 'Save changes' : 'Create'}</Submit>
                                    </div>
                                </div>
                            }
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default MonitoringGroupModal

const ExpectationError = () => {
    const expectationField = useField<Expectations[] | ''>('expectations')
        , showExpectationField = showFieldError(expectationField.meta)

    return showExpectationField ? <span className='d-block invalid-feedback mt-0' data-testid='validation-error'>{expectationField.meta.error}</span> : null
}

function useDeletionModal(group: MonitoringGroup | undefined, onUpdate: () => void) {
    const removeMonitoringGroup = useAction(actions.removeMonitoringGroup)
        , showSpinner = useAction(spinnerAction.showSpinner)
        , hideSpinner = useAction(spinnerAction.hideSpinner)
        , confirmDeletion = useAction(deletionActions.showDeletionConfirmationModal)
        , navigateToList = useNavigateToList()

    function handleRemove(form: FormApi<MonitoringGroup>) {
        confirmDeletion([defaultTextNode('Are you sure you want to delete '), ...formatGroupName(group?.name ?? '', group?.isActive), defaultTextNode('?')])
            .then(showSpinner)
            .then(_ => removeMonitoringGroup(group!.id))
            .then(() => resetForm(form))
            .then(onUpdate)
            .finally(hideSpinner)
            .then(navigateToList)
    }

    return handleRemove
}
