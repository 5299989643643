import type { ValidationResult } from '_/utils/form/validate'
import type User from '_/model/users/types'
import { validateEmail } from '../sign-up/validate'

export default function validate(entity: Partial<User>) {
    const email = validateEmail(entity.email)
        , result: ValidationResult<User> = {}

    if (email)
        result.email = email

    return result
}
