import { classnames } from '_/facade/react'

import { EmptyTableMessage, Table } from '_/components/table'
import Button from '_/components/button'
import { useTimeService } from '_/components/time'

import type { PredefinedLists } from '_/model/app-state'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import * as f from '_/model/sample/format'
import FormattedText from '_/features/text/formatted-text'

import * as fieldIndex from '_/constants/custom-field-index'

import AddCommentsIcon from './sample-add-comments-icon'
import { getFieldValue } from '../helpers'
import * as h from './helpers'

import { customFieldName } from '_/model/predefined-lists/custom-field/custom-field'
import type { ViableSampleBooking } from '_/model/sample/booking/types'

interface Props {
    onOpenCommentsModal: (sample: ViableSampleBooking) => void
    samples: Map<string, ViableSampleBooking[]>
    exposureLocations: ListExposureLocation[]
    onOpenSampleEditModal: (sample: ViableSampleBooking) => void
    predefinedLists: PredefinedLists
}

function SampleList(props: Props) {
    const timeService = useTimeService()
        , locationIds = [...props.samples.keys()]

    function samplesComparator(sample1: ViableSampleBooking, sample2: ViableSampleBooking) {
        return h.compareByExposureDate(sample1.fields, sample2.fields)
            || h.compareBySession(sample1.fields, sample2.fields, props.predefinedLists.sampleSessions)
    }

    function getSamples(locationId: string) {
        return props.samples.get(locationId)?.slice().sort(samplesComparator).reverse() ?? []
    }

    function columnName(fieldIndex: fieldIndex.FieldIndex) {
        return customFieldName(fieldIndex, props.predefinedLists.customFields)
    }

    return (
        <Table>
            <thead className='thead table-header--sticky'>
                <tr className='table-header'>
                    <th>{columnName(fieldIndex.EXPOSURE_START_DATE)}</th>
                    <th>{columnName(fieldIndex.EXPOSURE_LOCATION_ID)}</th>
                    <th>{columnName(fieldIndex.SAMPLE_TYPE_ID)}</th>
                    <th>{columnName(fieldIndex.SESSION_ID)}</th>
                    <th>Exposure time</th>
                    <th>{columnName(fieldIndex.OPERATORS_IDS)}</th>
                    <th>{columnName(fieldIndex.BATCH_NUMBER)}</th>
                    <th/>
                    <th/>
                </tr>
            </thead>
            {locationIds.length === 0
                ? <tbody className='border-top-0'>
                    <EmptyTableMessage colSpan={9} message='There are no recently added viable samples at the moment' />
                </tbody>
                : locationIds.map((locationId, locationIndex) =>
                    getSamples(locationId).map((_, index) =>
                        <tbody key={index} className='border-top-0'>
                            {index === 0 &&
                                <tr key={locationId}>
                                    <td colSpan={9} className={classnames('fw-bold', { 'pt-5': locationIndex !== 0 })}>
                                        <FormattedText text={f.formatByFieldIndex(fieldIndex.EXPOSURE_LOCATION_ID, _.fields, props.predefinedLists)} />
                                    </td>
                                </tr>
                            }
                            <tr key={_.id}>
                                <td>{timeService.formatCtzDate(getFieldValue(_.fields, fieldIndex.EXPOSURE_START_DATE))}</td>
                                <td><FormattedText text={f.formatExposureLocation(_.monitoringState, _.fields, props.predefinedLists)} /></td>
                                <td><FormattedText text={f.formatByFieldIndex(fieldIndex.SAMPLE_TYPE_ID, _.fields, props.predefinedLists)} /></td>
                                <td><FormattedText text={f.formatByFieldIndex(fieldIndex.SESSION_ID, _.fields, props.predefinedLists)} /></td>
                                <td><FormattedText text={f.formatExposureDurationByFields( _.fields, props.predefinedLists.customFields, timeService)} /></td>
                                <td><FormattedText text={f.formatOperatorsShort(_.fields, props.predefinedLists)} /></td>
                                <td><FormattedText text={f.formatBatchNumbersShort(_.fields)} /></td>
                                <td><AddCommentsIcon numberOfComments={_.comments.length} onClick={() => props.onOpenCommentsModal(_)} /></td>
                                <td>
                                    <Button
                                        className='py-0 px-1 bg-white'
                                        onClick={() => props.onOpenSampleEditModal(_)}
                                        testId={`edit-sample-${index}`}
                                    >
                                        <i className='material-icons md-18'>create</i>
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    )
                )
            }
        </Table >
    )
}
export default SampleList
