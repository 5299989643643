import type { ApiKeyEdit } from '_/model/api-key/types'
import type ApiKey from '_/model/api-key/types'

function convertToEdit(_: ApiKey): ApiKeyEdit {
    return {
        description: _.description,
        isActive: _.isActive,
    }
}

export {
    convertToEdit,
}
