import { useField } from 'react-final-form'
import { useEffect } from '_/facade/react'
import type { SmartInfo } from '_/model/smart-check/image'

interface Props {
    detachedImages: SmartInfo[]
}

function ImageField(props: Props) {
    const field = useField('detachedImages')

    useEffect(
        () => {
            const value = props.detachedImages.length > 0 ? props.detachedImages.map(_ => ({ id: _.imageId })) : ''

            if (JSON.stringify(field.input.value) === JSON.stringify(value))
                return

            field.input.onChange(value)
            field.input.onBlur()
        },
        [field, props.detachedImages]
    )

    return null
}

export default ImageField
