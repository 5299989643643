export const INACTIVE = 0
           , OFFLINE = 1
           , ONLINE = 2


import type * as self from './status'
type DeviceStatus = Extract<(typeof self)[keyof typeof self], number>

const DEVICE_STATUS: { id: DeviceStatus, name: string }[] = [
    {
        id: INACTIVE,
        name: 'Inactive'
    },
    {
        id: ONLINE,
        name: 'Online'
    },
    {
        id: OFFLINE,
        name: 'Offline'
    },
]

export {
    DEVICE_STATUS,
    DeviceStatus,
}
