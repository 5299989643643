import type { FormRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { classnames, useMemo, useRef } from '_/facade/react'
import { showFieldError } from '_/components/form/helpers'
import { CheckboxField } from '_/components/form'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { SampleEditedInfo } from '_/model/sample/sample'
import EditedLabel from '../edited-label'
import FormattedText from '_/features/text/formatted-text'
import * as t from '_/model/text/text'

import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'
import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, required, minLength, hasTrimableSpaces } from '_/utils/form/validate'

import FieldForm from '../field-form'
import Error from '_/components/form/field-error'
import InlineActions from '../inline-actions'
import { findFieldValuePos, valuePath, notRecordedPath, getFieldValue, getFieldNotRecorded } from '../../helpers'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'

interface Props {
    entity: SampleDetailsEdit | NonViableSampleEdit
    field: CustomField
    onSubmit: (sample: SampleDetailsEdit | NonViableSampleEdit) => Promise<void>
    hasNoPermissions: boolean
    editedInfo: SampleEditedInfo | undefined
    isViable?: boolean
}

function TextFieldForm(props: Props) {
    const position = findFieldValuePos(props.entity.fields, props.field.index)
        , fieldSettings = props.isViable ? props.field.viableSettings : props.field.nonViableSettings

    function validate(entity: Partial<SampleDetailsEdit>) {
        const existedFields = entity.fields || []
            , resultFields: Partial<FieldValues>[] = []
            , result: ValidationResult<SampleDetailsEdit> = {}
            , value = getFieldValue(existedFields, props.field.index)
            , notRecorded = getFieldNotRecorded(existedFields, props.field.index)
            , isRequired = fieldSettings.required

        const validationResult = (!notRecorded && isRequired && required(props.field.fieldName)(value))
            || maxLength(props.field.fieldName, 250)(value)
            || minLength(props.field.fieldName, 4)(value)
            || hasTrimableSpaces(props.field.fieldName, value)

        if (validationResult)
            resultFields[position] = { value: validationResult }

        return Object.assign(result, { fields: resultFields })
    }

    function handleChange(sample: Partial<SampleDetailsEdit>, form: FormRenderProps) {
        const textField = sample.fields && sample.fields[position]

        if (textField && textField.notRecorded) {
            if (!fieldSettings.notRecorded) // in case when field was N\R but later admin changed settings and N/R is not allowed anymore
                form.form.change(notRecordedPath(position), false)

            if (fieldSettings.notRecorded && textField.value !== undefined)
                form.form.change(valuePath(position), undefined)
        }
    }

    function handleSubmit(subEntity: SampleDetailsEdit) {
        const fields = subEntity.fields
            , sampleEdit = { ...props.entity,  fields }

        return props.onSubmit(sampleEdit)
    }

    function isNotRecorded(form: FormRenderProps, pos: number) {
        const notRecordedState = form.form.getFieldState(notRecordedPath(pos))
        return !!(notRecordedState && notRecordedState.value)
    }

    const textField = props.entity.fields[position]
        , textFieldValue = textField.value
        , textFieldNotRecorded = textField.notRecorded
        , initialValues = useMemo(
            () => ({
                fields: props.entity.fields,
            }),
            [props.entity.fields]
        )
        , textFieldNotRecordedRef = useRef<HTMLInputElement>(null)

    return (
        <FieldForm
            formId={`text-field[${position}]`}
            label={props.field.fieldName}
            testId={`sample-field-${props.field.index}`}
            initialValues={initialValues}
            validate={validate}
            onSubmit={handleSubmit}
            onChange={handleChange}
            hasNoPermissions={props.hasNoPermissions}
            renderView={() =>
                <span className='form-control-plaintext'>
                    <span className='fw-bold'>
                        <FormattedText text={textFieldNotRecorded ? [t.systemTextNode('Not recorded')] : textFieldValue} />
                        <EditedLabel editedInfo={props.editedInfo}/>
                    </span>
                </span>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div className='d-flex align-items-start'>
                    <div className='flex-fill'>
                        <Field name={valuePath(position)} render={_ =>
                            <>
                                <input
                                    id={`field-${props.field.index}`}
                                    type='text'
                                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                                    disabled={isNotRecorded(form, position)}
                                    autoComplete='off'
                                    {..._.input}
                                    onKeyDown={_ => focusElementOnAltNInput(textFieldNotRecordedRef, _)}
                                    autoFocus
                                    data-testid={`field-${props.field.index}`}
                                />
                                <Error field={_} />
                            </>
                        } />
                        {fieldSettings.notRecorded &&
                            <CheckboxField
                                id={notRecordedPath(position)}
                                name={notRecordedPath(position)}
                                tabIndex={isNotRecorded(form, position) ? 0 : -1}
                                inputRef={textFieldNotRecordedRef}
                            >
                                Not recorded
                            </CheckboxField>
                        }
                    </div>
                    <InlineActions form={form} onSave={handleSave} onCancel={handleCancel} testId={`sample-field-${props.field.index}`} />
                </div>
            }
        />
    )
}

export default TextFieldForm
