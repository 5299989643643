import { classnames, useAction, useSelector } from '_/facade/react'
import type { FormRenderProps } from 'react-final-form'
import Button from '_/components/button'
import * as actions from '../../actions'
import { noop } from '_/utils/function'

interface Props {
    formId: string
    label: React.ReactNode
    hasNoPermissions?: boolean
    renderView: () => React.ReactNode
    renderEditForm: () => React.ReactNode
    editAvailable?: boolean
    testId?: string
    formClassName?: string
}

function FieldFormCommon(props: Props) {
    const currentEditedSampleField = useSelector(_ => _.samples.currentEditedSampleField)
        , editSampleField = useAction(actions.editSampleField)
        , canActivateEdit = !props.hasNoPermissions && props.editAvailable && currentEditedSampleField !== props.formId
        , editButtonDisabled = currentEditedSampleField !== ''

    return (
        <div className='row g-2'>
            <label htmlFor='inputPassword' className='d-flex justify-content-between col-4 col-form-label'>
                <span className='text-muted'>{props.label}</span>
                {canActivateEdit &&
                    <Button
                        onClick={() => editSampleField(props.formId)}
                        className={classnames('align-self-start p-0 border-0 d-print-none', { 'cursor-auto': editButtonDisabled })}
                        disabled={editButtonDisabled}
                        testId={`${props.testId}-edit`}
                    >
                        <i className='sample-edit__edit-icon material-icons text-primary md-18'>create</i>
                    </Button>
                }
            </label>
            <div className={props.formClassName + ' col-8'}>
                {currentEditedSampleField === props.formId
                    ? props.renderEditForm()
                    : props.renderView()
                }
            </div>
        </div>
    )
}

export default FieldFormCommon
export { useFieldFormAction }

function useFieldFormAction() {
    const editSampleField = useAction(actions.editSampleField)

    function handleSave<T>(form: FormRenderProps<T>) {
        const result = form.handleSubmit()

        if (!result)
            return Promise.resolve()

        result.then(_ => editSampleField(''))

        return result.then(noop)
    }

    function handleCancel<T>(form: FormRenderProps<T>) {
        editSampleField('')
        form.form.reset()
    }

    return [handleSave, handleCancel] as const
}

