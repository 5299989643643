import type { FormRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { classnames, useMemo, useRef } from '_/facade/react'
import { default as MultiSelect } from '_/components/form/multi-select'
import { showFieldError } from '_/components/form/helpers'
import { CheckboxField } from '_/components/form'
import FormattedText from '_/features/text/formatted-text'
import * as t from '_/model/text/text'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'
import type { SampleOperator } from '_/model/predefined-lists/operator/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import * as fieldIndex from '_/constants/custom-field-index'
import type { SampleEditedInfo } from '_/model/sample/sample'
import EditedLabel from '../edited-label'

import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'

import FieldForm from '../field-form'
import InlineActions from '../inline-actions'
import { formatSampleOperators, findFieldValuePos, valuePath, notRecordedPath } from '../../helpers'
import { validateNonViableOperators, validateViableOperators } from '../../validate'
import { formatActiveState } from '_/utils/format/common'

interface Props {
    entity: SampleDetailsEdit | NonViableSampleEdit
    field: CustomField
    sampleTypes?: SampleType[]
    onSubmit: (sample: SampleDetailsEdit | NonViableSampleEdit) => Promise<void>
    hasNoPermissions: boolean
    operators: SampleOperator[]
    editedInfo: SampleEditedInfo | undefined
    isViable?: boolean
}

function OperatorForm(props: Props) {
    const position = findFieldValuePos(props.entity.fields, fieldIndex.OPERATORS_IDS)
        , fieldSettings = props.isViable ? props.field.viableSettings : props.field.nonViableSettings

    function handleChange(sample: Partial<SampleDetailsEdit>, form: FormRenderProps) {
        const operatorField = sample.fields && sample.fields[position]

        if (operatorField && operatorField.notRecorded) {
            if (!fieldSettings.notRecorded) // in case when field was N\R but later admin changed settings and N/R is not allowed anymore
                form.form.change(notRecordedPath(position), false)

            if (fieldSettings.notRecorded && operatorField.value !== undefined)
                form.form.change(valuePath(position), undefined)
        }
    }

    function handleSubmit(subEntity: SampleDetailsEdit) {
        const fields = subEntity.fields
            , sampleEdit = { ...props.entity, fields }

        return props.onSubmit(sampleEdit)
    }

    function canShowNotRecordedCheckbox(_: FormRenderProps, pos: number) {
        if (!fieldSettings.notRecorded)
            return false

        return (!_.values.fields[pos].value) || (_.values.fields[pos].value.length === 0)
    }

    function isNotRecorded(form: FormRenderProps, pos: number) {
        const notRecordedState = form.form.getFieldState(notRecordedPath(pos))
        return !!(notRecordedState && notRecordedState.value)
    }

    const operatorsField = props.entity.fields[position]
        , operatorsFieldValue = operatorsField.value
        , operatorsNotRecorded = operatorsField.notRecorded
        , operators = props.operators.filter(_ => operatorsFieldValue && operatorsFieldValue.some((it: string) => it === _.id))
        , sampleTypeField = props.entity.fields.find(_ => _.index === fieldIndex.SAMPLE_TYPE_ID)
        , initialValues = useMemo(
            () => ({
                fields: props.entity.fields,
            }),
            [props.entity.fields]
        )
        , operatorNotRecordedRef = useRef<HTMLInputElement>(null)

    return (
        <FieldForm
            formId='operatorIds'
            label={props.field.fieldName}
            testId={`sample-field-${props.field.index}`}
            initialValues={initialValues}
            onChange={handleChange}
            onSubmit={handleSubmit as any}
            validate={_ => props.isViable
                ? validateViableOperators(_.fields, props.field, sampleTypeField && sampleTypeField.value, props.sampleTypes)
                : validateNonViableOperators(_.fields, props.field)
            }
            hasNoPermissions={props.hasNoPermissions}
            renderView={() =>
                <span className='form-control-plaintext'>
                    <span className='fw-bold user-formatted-text'>
                        <FormattedText text={!!operatorsNotRecorded
                            ? [t.systemTextNode('Not recorded')]
                            : formatSampleOperators(operators)
                        } />
                        <EditedLabel editedInfo={props.editedInfo} breakToNewLine/>
                    </span>
                </span>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div className='d-flex align-items-start'>
                    <div className='flex-fill'>
                        <Field name={valuePath(position)} render={_ =>
                            <div>
                                <MultiSelect
                                    autocomplete
                                    entities={props.operators}
                                    calcId={_ => _.id}
                                    calcName={_ => formatActiveState(_.name, _.isActive)}
                                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                                    input={_.input}
                                    disabled={isNotRecorded(form, position)}
                                    onKeyDown={_ => focusElementOnAltNInput(operatorNotRecordedRef, _)}
                                    autoFocus
                                    testId={`field-${props.field.index}`}
                                />
                                {showFieldError(_.meta) && <span className='invalid-feedback d-block' data-testid='validation-error'>{_.meta.error}</span>}
                            </div>
                        }
                        />
                        {canShowNotRecordedCheckbox(form, position) &&
                            <CheckboxField
                                id={notRecordedPath(position)}
                                name={notRecordedPath(position)}
                                tabIndex={isNotRecorded(form, position) ? 0 : -1}
                                inputRef={operatorNotRecordedRef}
                            >
                                Not recorded
                            </CheckboxField>
                        }
                    </div>
                    <InlineActions form={form} onSave={handleSave} onCancel={handleCancel} testId={`sample-field-${props.field.index}`} />
                </div>
            }
        />
    )
}

export default OperatorForm
