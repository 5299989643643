const DATA = 0
    , AUDIT_TRAIL = 1

type Tab = typeof DATA | typeof AUDIT_TRAIL

export {
    Tab,
    DATA,
    AUDIT_TRAIL,
}
