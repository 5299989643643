export const DAYS = 0
           , WEEKS = 1

import type * as self from './time-period'
type TrendTimePeriod = Extract<(typeof self)[keyof typeof self], number>

const TREND_TIME_PERIOD: readonly { id: TrendTimePeriod, name: string, nameSingularForm: string }[] = [
    {
        id: DAYS,
        name: 'Days',
        nameSingularForm: 'day',
    },
    {
        id: WEEKS,
        name: 'Weeks',
        nameSingularForm: 'week',
    },
]

export {
    TREND_TIME_PERIOD,
    TrendTimePeriod,
}
