const BLUE_400 = '#42a5f5'
    // , BLUE_700 = '#1976d2'
    , AMBER_500 = '#ffc107'
    , AMBER_800 = '#ff8f00'
    , RED_500 = '#f44336'
    // , RED_800 = '#c62828'
    , DEEP_PURPLE_500 = '#673ab7'
    // , DEEP_PURPLE_700 = '#512da8'
    , GREEN_400 = '#66bb6a'
    , GREEN_700 = '#388e3c'
    , BROWN_400 = '#8d6e63'
    // , BROWN_700 = '#5d4037'
    , BLUE_GREY_400 = '#78909c'
    // , BLUE_GREY_700 = '#455a64'
    , YELLOW_500 = '#ffeb3b'
    // , YELLOW_800 = '#f9a825'
    , PINK_400 = '#ec407a'
    // , PINK_700 = '#c2185b'
    , CYAN_400 = '#26c6da'
    // , CYAN_700 = '#0097a7'

    , GREY_300 = '#e0e0e0'
    , GREY_500 = '#9e9e9e'

const ORGANISM_TYPE_BREAKDOWN_COLORS = new Map<string, string>([
    ['Gram positive rod (GPR)', GREEN_400],
    ['Gram positive rod (GPR) sporing', BROWN_400],
    ['Gram positive rod (GPR) non-sporing', BLUE_GREY_400],
    ['Gram positive filaments', DEEP_PURPLE_500],
    ['Gram positive cocci (GPC)', RED_500],
    ['Gram negative rod (GNR)', AMBER_500],
    ['Gram negative cocci (GNC)', BLUE_400],
    ['Mould', PINK_400],
    ['Yeast', CYAN_400],
    ['Inconclusive', YELLOW_500],
    ['Not yet identified', GREY_500],
    ['Other', GREY_300],
    ['Excursion rate', GREEN_700],
    ['Contamination rate', AMBER_800],
])

type GetColor = (index: number, name: string, isBarChart: boolean) => string | undefined

function getDefaultColor(_index: number, name: string, _isBarChart: boolean) {
    return name === 'Other' || name === 'Total number of samples read' ? GREY_300 : undefined
}

function getOrganismTypeBreakdownColor(_index: number, name: string, _isBarChart: boolean) {
    return ORGANISM_TYPE_BREAKDOWN_COLORS.get(name)
}

// Colors can be obtained with Plotly.d3.scale.category10().range()
// Plotly isn't used directly to keep library lazy loaded
const DEFAULT_STACKED_SAMPLE_NAME_COLORS = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf']
    // Obtained with DEFAULT_STACKED_SAMPLE_NAME_COLORS.map(_ => Plotly.d3.rgb(_).brighter(1.2).toString())
    , BRIGHTER_STACKED_SAMPLE_NAME_COLORS = ['#2fb6ff', '#ffc22e', '#43f543', '#ff3b3d', '#e39eff', '#d68373', '#ffb6ff', '#c2c2c2', '#ffff34', '#2effff']

function getStackedSampleNameColor(index: number, _name: string, isBarChart: boolean) {
    const repeatedIndex = index % 10
        , colors = isBarChart ? DEFAULT_STACKED_SAMPLE_NAME_COLORS : BRIGHTER_STACKED_SAMPLE_NAME_COLORS

    return colors[repeatedIndex]
}

export {
    getDefaultColor,
    getOrganismTypeBreakdownColor,
    getStackedSampleNameColor,
}

export type {
    GetColor,
}
