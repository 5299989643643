import type {
    DashboardSampleStatisticRequest,
    DashboardDailySamplesStatistic,
    DashboardStatisticsTotals,
    DashboardBreachLimitsStatistic,
    DashboardLimitStatisticWidgetRequest,
} from '_/model/dashboard/types'

import type ApiService from '../api-service'
import type DashboardService from '../dashboard-service'

function factory(api: ApiService): DashboardService {
    return { getDailySamplesStatistic,  getSamplesStatistic, getLimitsStatistic }

    function getDailySamplesStatistic(request: DashboardSampleStatisticRequest): Promise<DashboardDailySamplesStatistic[]> {
        return api.get<DashboardDailySamplesStatistic[]>(['dashboard', 'samples', 'daily'], request)
    }

    function getSamplesStatistic(request: DashboardSampleStatisticRequest): Promise<DashboardStatisticsTotals> {
        return api.get<DashboardStatisticsTotals>(['dashboard', 'samples', 'general'], request)
    }

    function getLimitsStatistic(request: DashboardLimitStatisticWidgetRequest): Promise<DashboardBreachLimitsStatistic[]> {
        return api.get<DashboardBreachLimitsStatistic[]>(['dashboard', 'breach-limit-statistic', 'general'], request)
    }
}

export default factory
