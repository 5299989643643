import { combineReducers } from 'redux'
import { router5Reducer } from 'redux-router5'

import type AppState from '_/model/app-state'

import type { EffectsFactory } from '_/utils/redux/effect'
import effectMwFactory from '_/utils/redux/effect'
import apiServiceFactory from '_/services/impl/api-service'

import timeEffectsFactory from '_/features/time/effects'
import timeServiceFactory from '_/services/impl/time-service'

import auth from '_/features/auth/reducer'
import authEffectFactory from '_/features/auth/effects'
import sessionMw from '_/features/auth/session-middleware'

import plateEffectFactory from '_/features/plates/effects'
import plateServiceFactory from '_/services/impl/plate-service'

import samples from '_/features/samples/reducer'
import samplesEffectFactory from '_/features/samples/effects'
import sampleFilesEffectFactory from '_/features/samples/sample-files-effects'
import sampleServiceFactory from '_/services/impl/sample-service'

import nonViableSamplesEffectFactory from '_/features/non-viable-samples/effects'
import nonViableSampleServiceFactory from '_/services/impl/non-viable-sample-service'

import smartCheck from '_/features/smart-check/reducer'
import smartCheckEffectFactory from '_/features/smart-check/effects'
import smartCheckServiceFactory from '_/services/impl/smart-check-service'
import webcamMiddlewareFactory from '_/features/smart-check/webcam/webcam-middleware'

import predefinedLists from '_/features/predefined-lists/redux/reducer'
import predefinedServiceFactory from '_/services/impl/predefined-lists-service'
import floorPlanServiceFactory from '_/services/impl/floor-plan-service'
import predefinedEffectFactory from '_/features/predefined-lists/redux/effects'

import toasts from '_/features/toasts/reducer'
import users from '_/features/users/reducer'
import usersEffectFactory from '_/features/users/effects'
import notificationSettingsEffectFactory from '_/features/users/notification-settings/effects'
import userServiceFactory from '_/services/impl/user-service'
import notificationSettingsFactory from '_/services/impl/notification-settings-service'

import rolesEffectFactory from '_/features/roles/effects'
import roleServiceFactory from '_/services/impl/role-service'

import sampleSearchFilterServiceFactory from '_/services/impl/sample-search-filter-service'

import dashboardEffectFactory from '_/features/dashboard/effects'
import dashboardServiceFactory from '_/services/impl/dashboard-service'

import analysis from '_/features/analysis/reducer'
import analysisEffectFactory from '_/features/analysis/effects'
import analysisServiceFactory from '_/services/impl/analysis-service'
import analysisSearchFilterServiceFactory from '_/services/impl/analysis-search-filter-service'
import customReports from '_/features/analysis/custom-reports/reducer'
import customReportsEffectFactory from '_/features/analysis/custom-reports/effects'
import customReportsServiceFactory from '_/services/impl/custom-report-service'

import monitoringGroupsEffectFactory from '_/features/scheduling/monitoring-groups/effects'
import monitoringGroupsServiceFactory from '_/services/impl/monitoring-group-service'

import dayPlanEffectFactory from '_/features/scheduling/day-scheduler/effects'
import dayPlanServiceFactory from '_/services/impl/day-schedule-service'

import reportRemindersEffectFactory from '_/features/analysis/report-reminders/effects'
import reportRemindersServiceFactory from '_/services/impl/report-reminder-service'

import membershipServiceFactory from '_/services/impl/membership-service'

import contexts from '_/features/contexts/reducer'
import contextsEffectFactory from '_/features/contexts/effects'
import contextServiceFactory from '_/services/impl/context-service'

import trailsEffectFactory from '_/features/audit-trails/effects'
import trailsServiceFactory from '_/services/impl/audit-trail-service'

import limitBreachReportEffectFactory from '_/features/limit-breach-report/effects'
import limitBreachReportServiceFactory from '_/services/impl/limit-breach-report-service'

import monitoringOverviewEffectFactory from '_/features/scheduling/monitoring-overview/effects'
import monitoringOverviewServiceFactory from '_/services/impl/monitoring-overview-service'

import locationsReportEffectFactory from '_/features/reports/locations-report/effects'
import locationsReportServiceFactory from '_/services/impl/locations-report-service'

import operatorsReportEffectFactory from '_/features/reports/operators-report/effects'
import operatorsReportServiceFactory from '_/services/impl/operators-report-service'

import missedMonitoringReportEffectFactory from '_/features/reports/missed-monitoring-report/effects'
import missedMonitoringReportServiceFactory from '_/services/impl/missed-monitoring-report-service'

import fingerdabOverviewByOperatorEffectFactory from '_/features/monitoring-overview/by-operator/effects'
import fingerdabOverviewByOperatorServiceFactory from '_/services/impl/fingerdab-overview-by-operator-service'

import helpEffectFactory from '_/features/help/effects'

import confirmationEffectsFactory from '_/features/confirmation/effects'

import unsavedChange from '_/features/unsaved-changes/reducer'

import confirmation from '_/features/confirmation/reducer'

import backendStatus from '_/features/backend-status/reducer'
import statusEffectFactory from '_/features/backend-status/effects'
import statusServiceFactory from '_/services/impl/backend-status-service'

import { restoreState } from '_/features/auth/syncState'

import sampleFilesServiceFactory from '_/services/impl/sample-files-service'

import reason from '_/features/critical-change-reason/reducer'
import reasonEffectFactory from '_/features/critical-change-reason/effects'

import spinner from '_/features/spinner/reducer'

import reasonServiceFactory from '_/services/impl/redux/reason-service'
import type ReduxService from '_/services/impl/redux/redux-service'

import apiKeys from '_/features/users/api-keys/redux/reducer'
import apiKeysEffectFactory from '_/features/users/api-keys/redux/effects'
import apiKeysServiceFactory from '_/services/impl/api-key-service'

import insightServiceFactory from '_/services/impl/insight-service'
import insightEffectFactory from '_/features/insights/effects'

import notificationServiceFactory from '_/services/impl/notification-service'
import notificationEffectFactory from '_/features/notifications/effects'

import trendServiceFactory from '_/services/impl/trend-service'
import trendEffectFactory from '_/features/trends/effects'

import azureAdServiceFactory from '_/services/impl/azure-ad-service'

import exportEffectFactory from '_/features/export/effects'
import exportServiceFactory from '_/services/impl/export-service'

import deviceServiceFactory from '_/services/impl/device-service'

import filters from '_/features/filters/reducer'

const rootReducer = combineReducers<AppState>({
    auth,
    samples,
    smartCheck,
    users,
    predefinedLists,
    router: router5Reducer,
    toasts,
    analysis,
    contexts,
    unsavedChange,
    confirmation,
    backendStatus,
    reason,
    spinner,
    customReports,
    filters,
    apiKeys,
})

const persistedState = restoreState()

const timeService = timeServiceFactory()
    , reasonService = reasonServiceFactory()
    , apiService = apiServiceFactory(reasonService, persistedState && persistedState.auth.token || undefined)
    , exportService = exportServiceFactory(apiService)
    , plateService = plateServiceFactory(apiService)
    , sampleService = sampleServiceFactory(apiService, timeService)
    , nonViableSampleService = nonViableSampleServiceFactory(apiService, timeService)
    , smartCheckService = smartCheckServiceFactory(apiService, reasonService)
    , userService = userServiceFactory(apiService)
    , notificationSettingsService = notificationSettingsFactory(apiService)
    , roleService = roleServiceFactory(apiService)
    , predefinedListsService = predefinedServiceFactory(apiService)
    , floorPlanService = floorPlanServiceFactory(apiService)
    , sampleSearchService = sampleSearchFilterServiceFactory(apiService)
    , dashboardService = dashboardServiceFactory(apiService)
    , analysisService = analysisServiceFactory(apiService, timeService)
    , analysisSearchService = analysisSearchFilterServiceFactory(apiService)
    , membershipService = membershipServiceFactory(apiService)
    , contextService = contextServiceFactory(apiService)
    , trailsService = trailsServiceFactory(apiService)
    , limitBreachReportService = limitBreachReportServiceFactory(apiService, timeService)
    , monitoringOverviewService = monitoringOverviewServiceFactory(apiService, timeService)
    , fingerdabOverviewByOperatorService = fingerdabOverviewByOperatorServiceFactory(apiService, timeService)
    , statusService = statusServiceFactory(apiService)
    , sampleFilesService = sampleFilesServiceFactory(apiService)
    , customReportService = customReportsServiceFactory(apiService)
    , reportReminderService = reportRemindersServiceFactory(apiService)
    , monitoringGroupService = monitoringGroupsServiceFactory(apiService)
    , dayPlanService = dayPlanServiceFactory(apiService)
    , reduxServices: ReduxService[] = [reasonService]
    , locationsReportService = locationsReportServiceFactory(apiService)
    , operatorsReportService = operatorsReportServiceFactory(apiService)
    , missedMonitoringReportService = missedMonitoringReportServiceFactory(apiService)
    , apiKeysService = apiKeysServiceFactory(apiService)
    , insightService = insightServiceFactory(apiService)
    , notificationService = notificationServiceFactory(apiService)
    , azureAdService = azureAdServiceFactory(apiService)
    , trendService = trendServiceFactory(apiService)
    , deviceService = deviceServiceFactory(apiService)

const effects = ([] as EffectsFactory<AppState>[]).concat(
        authEffectFactory(apiService, userService, membershipService, azureAdService, timeService),
        plateEffectFactory(plateService, exportService),
        samplesEffectFactory(sampleService, sampleSearchService, reasonService, exportService),
        sampleFilesEffectFactory(sampleFilesService),
        nonViableSamplesEffectFactory(nonViableSampleService, exportService),
        smartCheckEffectFactory(smartCheckService),
        usersEffectFactory(userService, membershipService),
        notificationSettingsEffectFactory(notificationSettingsService),
        rolesEffectFactory(roleService),
        predefinedEffectFactory(predefinedListsService, floorPlanService, reasonService, deviceService),
        dashboardEffectFactory(dashboardService),
        analysisEffectFactory(analysisService, analysisSearchService),
        monitoringGroupsEffectFactory(monitoringGroupService),
        contextsEffectFactory(contextService, timeService, exportService),
        trailsEffectFactory(trailsService),
        limitBreachReportEffectFactory(limitBreachReportService),
        monitoringOverviewEffectFactory(monitoringOverviewService),
        fingerdabOverviewByOperatorEffectFactory(fingerdabOverviewByOperatorService),
        helpEffectFactory(apiService),
        confirmationEffectsFactory(),
        statusEffectFactory(statusService),
        timeEffectsFactory(timeService),
        reasonEffectFactory(),
        customReportsEffectFactory(customReportService),
        dayPlanEffectFactory(dayPlanService),
        reportRemindersEffectFactory(reportReminderService),
        locationsReportEffectFactory(locationsReportService),
        operatorsReportEffectFactory(operatorsReportService),
        missedMonitoringReportEffectFactory(missedMonitoringReportService),
        insightEffectFactory(insightService),
        notificationEffectFactory(notificationService),
        apiKeysEffectFactory(apiKeysService),
        trendEffectFactory(trendService),
        exportEffectFactory(exportService),
    )
    , effectMw = effectMwFactory(effects)
    , webcamMw = webcamMiddlewareFactory()
    , middlewares = [effectMw, webcamMw, sessionMw]

export { rootReducer, persistedState, middlewares, reduxServices }
