import type { Middleware } from '_/utils/redux'

const log: Middleware<any> = _api => next => action => {
    const result = next(action)

    // console.log('store', _api.getState())

    return result
}

export default log
