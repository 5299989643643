const SITE_EXPORT = 0
    , SAMPLES_EXPORT = 1
    , CC_SAMPLES_EXPORT = 2


type ExportType = never
    | typeof SITE_EXPORT
    | typeof SAMPLES_EXPORT
    | typeof CC_SAMPLES_EXPORT

export {
    ExportType,
    SITE_EXPORT,
    SAMPLES_EXPORT,
    CC_SAMPLES_EXPORT,
}
