import { classnames } from '_/facade/react'

import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'

interface Props {
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
    id: string
    checked?: boolean
    name: string
    disabled?: boolean
    hasNoPermissions?: boolean
    className?: string
    children?: React.ReactNode
    testId?: string
    title?: string
}

const InlineCheckbox = (props: Props) =>
    <div className={`form-check ${props.className || ''}`}>
        <input
            type='checkbox'
            className='form-check-input'
            id={props.id}
            name={props.name}
            checked={props.checked}
            disabled={props.hasNoPermissions || props.disabled}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onFocus={props.onFocus}
            data-testid={props.testId}
        />
        <label
            className={classnames('form-check-label', { 'no-permission': props.hasNoPermissions })}
            htmlFor={props.id}
            title={props.hasNoPermissions ? NO_PERMISSION_MESSAGE : props.title}
        >
            {props.children}
        </label>
    </div>

export default InlineCheckbox
