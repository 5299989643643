import { useSelector, useState, useAction } from '_/facade/react'

import Button from '_/components/button'
import { useTimeService } from '_/components/time'
import AuditTrailModal from '_/components/audit-trail-modal'
import { Table, PaginatedFooter } from '_/components/table/index'
import FormattedText from '_/features/text/formatted-text'
import type TimeService from '_/services/time-service'

import type { Trend, TrendEdit } from '_/model/trends/types'
import type PaginationState from '_/model/pagination-state'
import type { PredefinedLists } from '_/model/app-state'

import TrendInvestigationRefsForm from './trend-investigation-refs-form'
import type { TrendSettings } from '_/model/predefined-lists/trend-settings/types'
import * as h from '_/model/predefined-lists/trend-settings/helpers'
import * as a from './actions'
import { HAS_THE_SAME, HAS_THE_SAME_IDENTIFICATION, IN_THE_SAME } from '_/model/predefined-lists/trend-settings/filter-type'
import { diffObject } from '_/utils/object'
import { Link } from '_/utils/router'
import * as routes from '_/constants/routes'
import { paramsFilter } from '_/model/filters/helpers'
import { ALL_STATUS_IDS } from '_/constants/sample-status'

interface Props {
    trends: Trend[]
    totalCount: number
    trendSettings: TrendSettings[]
    pagination: PaginationState
    onReloadTrends: () => void
    onReloadStatistic: () => void
    onChangePagination: (_: PaginationState) => void
}

function TrendsTable(props: Props) {
    const timeService = useTimeService()
        , predefinedLists = useSelector(_ => _.predefinedLists)
        , [editedIndex, setEditedIndex] = useState<string | undefined>(undefined)
        , [trailIndex, setTrailIndex] = useState<string | undefined>(undefined)
        , { trends, trendSettings, totalCount } = props

    function handleReload() {
        props.onReloadTrends()
        props.onReloadStatistic()
    }

    return (
        <div className='overflow-auto'>
            <Table>
                <thead className='thead table-header--sticky'>
                    <tr>
                        <th>Trend discovered</th>
                        <th>Trend setting name</th>
                        <th>Trend ID</th>
                        <th>Trend conditions</th>
                        <th>Trend investigation references</th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {trends.length === 0
                        ? <tr>
                            <td colSpan={6} className='text-center'>There are no recorded trends at the moment</td>
                        </tr>
                        : trends.map(_ =>
                            <TrendRow
                                key={_.id}
                                editedIndex={editedIndex}
                                onChangeEditedIndex={setEditedIndex}
                                isOpenTrailModal={_.id === trailIndex}
                                onChangeTrailIndex={setTrailIndex}
                                trend={_}
                                trendSettings={trendSettings}
                                timeService={timeService}
                                predefinedLists={predefinedLists}
                                onReload={handleReload}
                            />
                        )
                    }
                </tbody>
                {trends.length > 0 &&
                    <PaginatedFooter
                        colSpan={6}
                        state={props.pagination}
                        onChange={props.onChangePagination}
                        totalCount={totalCount}
                    />
                }
            </Table>
        </div>
    )
}

export default TrendsTable

interface TrendRowProps {
    trend: Trend
    trendSettings: TrendSettings[]
    timeService: TimeService
    predefinedLists: PredefinedLists
    isOpenTrailModal: boolean
    onChangeTrailIndex: (_?: string) => void
    editedIndex: string | undefined
    onChangeEditedIndex: (_?: string) => void
    onReload: () => void
}

function TrendRow(props: TrendRowProps) {
    const trendSettings = props.trendSettings.find(_ => _.id === props.trend.trendSettingId)
        , timeframe = trendSettings?.timeframe ? h.timeframeFormat(trendSettings.timeframe) : ''
        , formattedFilters = trendSettings?.filters?.filter(_ => _.type !== HAS_THE_SAME && _.type !== IN_THE_SAME && _.type !== HAS_THE_SAME_IDENTIFICATION)
            .map(_ => h.filterFormat(_, props.predefinedLists))
            .concat(props.trend.theSameFiltersInfo
                .filter(_ => _.filters.length)
                .map(_ => h.formatTheSameValues(_, props.predefinedLists))
            )
        , rule = trendSettings?.rule ? h.ruleFormat(trendSettings.rule) : ''
        , permissions = useSelector(_ => _.auth.permissions)
        , handleCancel = () => props.onChangeEditedIndex(undefined)
        , handleSubmit = useSubmit(props.trend, props.onReload, handleCancel)

    return (
        <tr key={props.trend.id}>
            <td>{props.timeService.formatCtzDateTime(props.trend.createdAt, true)}</td>
            <td>{trendSettings?.name}</td>
            <td>
                <Link
                    routeName={routes.SAMPLES}
                    routeParams={paramsFilter({ trendIds: [props.trend.trendId], statuses: ALL_STATUS_IDS })}
                >
                    {props.trend.trendId}
                </Link>
            </td>
            <td>
                For viable samples <span className='lowercase-first-letter d-inline-block fw-bold pe-1'>{timeframe},</span>
                {formattedFilters?.map((_, i) =>
                    <span key={i} className='lowercase-first-letter d-inline-block fw-bold pe-1'>
                        <FormattedText text={_}/>,
                    </span>
                )}
                look for <span className='fw-bold'>{rule}</span>.
            </td>
            <td className='trend__ref-column--width'>
                {props.editedIndex === props.trend.id
                    ? <TrendInvestigationRefsForm
                        trend={props.trend}
                        onSubmit={handleSubmit}
                        onCancel={() => props.onChangeEditedIndex(undefined)}
                    />
                    : <div className='d-flex'>
                        <Button
                            onClick={() => props.onChangeEditedIndex(props.trend.id)} className='p-0 border-0'
                            hasNoPermissions={!permissions.editTrendInvestigationRefs}
                            disabled={props.editedIndex !== undefined}
                        >
                            <i className='sample-edit__edit-icon material-icons md-24 text-primary pt-3'>create</i>
                        </Button>
                        <div className='single-line-text'>
                            {props.trend.trendInvestigationReferences?.map((_, i) =>
                                <span key={i} title={_} className='ms-2'>{_} <br/></span>
                            )}
                        </div>
                    </div>
                }
            </td>
            <td>
                <Button
                    onClick={() => props.onChangeTrailIndex(props.trend.id)}
                    disabled={!props.trend.trendInvestigationReferenceEdited}
                    className='p-0 border-0'
                >
                    <i className='material-icons text-primary'>history</i>
                </Button>
                {props.isOpenTrailModal &&
                    <AuditTrailModal
                        id={props.trend.id}
                        onClose={props.onChangeTrailIndex}
                        loadAuditTrailAction={a.loadTrendTrail}
                    />
                }
            </td>
        </tr>
    )
}

function useSubmit(trend: Trend, onReload: () => void, onCancel: () => void) {
    const save = useAction(a.saveTrendInvestigationRef)
        , create = useAction(a.createTrendInvestigationRef)

    function handleSubmit(newTrend: TrendEdit) {
        if (!diffObject(trend.trendInvestigationReferences, newTrend.trendInvestigationReferences))
            return Promise.resolve()

        const request = trend.trendInvestigationReferenceEdited
            ? save({id: trend.id, oldTrend: trend, newTrend})
            : create({ id: trend.id, newTrend })

        return request
            .then(onCancel)
            .then(onReload)
    }
    return handleSubmit
}
