export const WITHIN = 0
           , FOR_CONSECUTIVE_SAMPLES = 1


import type * as self from './timeframe-type'
type TrendTimeframe = Extract<(typeof self)[keyof typeof self], number>

const TREND_TIMEFRAME: readonly { id: TrendTimeframe, name: string }[] = [
    {
        id: WITHIN,
        name: 'Within',
    },
    {
        id: FOR_CONSECUTIVE_SAMPLES,
        name: 'For consecutive viable samples',
    },
]

export {
    TREND_TIMEFRAME,
    TrendTimeframe,
}
