import { useState, useAction, useEffect } from '_/facade/react'
import { debounce, noop } from '_/utils/function'

import MultipleFieldValueSearchField from '_/components/form/multi-values-search-field'

import type CustomField from '_/model/predefined-lists/custom-field/types'

import { searchFieldValues } from './actions'

interface Props {
    id: string
    name: string
    className?: string
    customField: CustomField
    fieldName?: string
    isNonViable?: boolean
    testId?: string
}

function MultipleFieldValuesSearch(props: Props) {
    const [entities, setEntities] = useState<string[]>([])
        , handleInputValueChange = useSearchFieldValues(props.customField.id, setEntities, !!props.isNonViable)

    return (
        <MultipleFieldValueSearchField
            id={props.id}
            name={props.name}
            label={props.fieldName ?? props.customField.fieldName}
            className={props.className}
            entities={entities}
            onChangeEntities={setEntities}
            onInputValueChange={handleInputValueChange}
            testId={props.testId}
        />
    )
}

function useSearchFieldValues(id: string, setEntities: (_: string[]) => void, isNonViable: boolean) {
    const [searchHandler, setSearchHandler] = useState<(value: string) => void>(() => noop)
        , searchCustomFieldValues = useAction(searchFieldValues)

    useEffect(
        () => {
            function search(query: string) {
                const result = query.length >= 4
                    ? searchCustomFieldValues({ id, query, isNonViable })
                    : Promise.resolve([])

                result.then(setEntities)
            }

            setSearchHandler(() => debounce(search, 300))
        },
        [id, isNonViable, searchCustomFieldValues, setEntities]
    )
    return searchHandler
}

export { MultipleFieldValuesSearch as default }
