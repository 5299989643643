import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type PredefinedListsService from '_/services/predefined-lists-service'
import { noop } from '_/utils/function'
import { NO_BREACH } from '_/constants/objectionable-limit-behaviour'
import { NEVER } from '_/constants/grade-cfu-verification'
import * as actions from './actions'
import { ALWAYS } from '_/constants/photograph-behaviour'
import type ReasonService from '_/model/reason/reason-service'
import { updateLimitsTransaction, updateGradeTransaction } from '_/model/predefined-lists/action-alert-limit/transaction'

const factory = (service: PredefinedListsService, reasonService: ReasonService): EffectsFactory => (dispatch, getState) => [
    handler(actions.loadLimitsList, service.actionAlertLimits.list),

    handler(actions.saveLimits, payload =>
        Promise.resolve()
            .then(_ => {
                const signatureSettings = getState().auth.electronicSignatureSettings
                return updateLimitsTransaction(
                    payload.editedLimits, payload.newLimits, payload.grade, payload.newNonViableLimits, payload.nonViableEditedLimits, signatureSettings, service, reasonService
                )
            })
            .then(_ => dispatch(actions.loadGradesList()))
            .then(noop)
    ),

    handler(actions.loadLimitsTrail, service.actionAlertLimits.trail),

    handler(actions.loadGradesList, () =>
        Promise.resolve()
            .then(() => service.grade.list())
            .then(_ => {
                dispatch(actions.gradesListLoaded(_))
                return _
            })
    ),

    handler(actions.saveGrade, data => {
        const result = updateGradeTransaction(data.id, data.oldGrade, data.newGrade, service)

        dispatch(actions.gradeChanged({
            id: data.id,
            grade: data.newGrade,
        }))

        result.catch(() =>
            dispatch(actions.gradeChanged({
                id: data.id,
                grade: data.oldGrade,
            }))
        )

        return result
    }),

    handler(actions.createGrade, data =>
        service.grade.create(data)
            .then(_ => dispatch(actions.gradeCreated({ id: _, name: data.name, behaviour: NO_BREACH, photoBehaviour: ALWAYS, cfuVerification: NEVER })))
            .then(noop)
    ),

    handler(actions.loadNonViableLimitsList, service.nonViableLimits.list)
]

export default factory
