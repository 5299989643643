import { CommentView } from '_/components/comment'
import { useState } from '_/facade/react'
import { validateComment } from '_/model/non-viable-sample/validate'

interface NonViableSampleCommentProps {
    value: string
    onCommentChange: (comment: string, isValid: boolean) => void
}

function Comment(props: NonViableSampleCommentProps) {
    const [validationResult, handleChangeValidationResult] = useState<string>()
        , validate = (message: string) => {
            const commentValidationResult =  validateComment(message)

            handleChangeValidationResult(commentValidationResult)
            props.onCommentChange(message, !commentValidationResult)
        }

    return <CommentView value={props.value} onChange={validate} error={validationResult} />
}

export default Comment
