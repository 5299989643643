import * as t from '_/model/text/text'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'

import { formatActiveState } from '_/utils/format/common'
import type * as types from '_/model/scheduling/monitoring-overview/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'
import { getSampleData, isSamplesWithActiveSession } from '_/features/monitoring-overview/helpers'

import { POSITION } from '_/model/predefined-lists/exposure-location/location-type'
import * as pt from '_/constants/plate-type'
import { UNKNOWN_LOCATION } from '_/constants/system-words'
import { VOID_ID } from '_/utils/tree'
import * as s from './monitoring-overview-sample-statuses'
import { getFieldValue } from '_/features/samples/helpers'
import * as fi from '_/constants/custom-field-index'
import type { Guid } from '_/model/guid'
import { formatGroupName } from '../day-scheduler/helpers'

function getMonitoringOverview(monitoringOverviewList: types.MonitoringOverview[], locations: ListExposureLocation[], sampleSession: SampleSession[], sampleTypes: SampleType[]) {
    return collapseLocationsWithoutSamples(monitoringOverviewList, locations)
        .map(item => {
            const expectedSamples = getExpectedSamples(item.expectedSamples, sampleSession, sampleTypes)
                , bookedSamples = getSampleData(
                        item.bookedSamples,
                        sampleSession.filter(_ => _.id !== VOID_ID),
                        isSampleSessionApplicable,
                        areOtherSampleSessionApplicable
                ).map(_ =>
                    _.map(s => ({
                        ...s,
                        id: getFieldValue(s.fields, fi.ID),
                        sessionId: s.groupId === VOID_ID
                            ? getFieldValue(s.fields, fi.SESSION_ID) ?? VOID_ID
                            : getFieldValue(s.fields, fi.MONITORING_SESSION_ID)
                    }))
                )

            return { locationName: item.locationName, bookedSamples, expectedSamples }
        })
}

function isSampleSessionApplicable<T extends types.BookedInSampleData>(sample: T, session: SampleSession) {
    return (getFieldValue(sample.fields, fi.MONITORING_SESSION_NAME) === session.name)
        || (sample.groupId === VOID_ID && (getFieldValue(sample.fields, fi.SESSION_NAME) === session.name))
}

function areOtherSampleSessionApplicable<T extends types.BookedInSampleData>(sample: T, allSessions: SampleSession[]) {
    return !isSamplesWithActiveSession(allSessions, getFieldValue(sample.fields, fi.MONITORING_SESSION_NAME))
        && !allSessions.some(session => isSampleSessionApplicable(sample, session))
        && getFieldValue(sample.fields, fi.MONITORING_SESSION_ID) === VOID_ID
}

function getExpectedSamples(expectedSamples: types.ExpectedSample[], sampleSession: SampleSession[], sampleTypes: SampleType[]) {
    const sessions = sampleSession.map(_ => ({id: _.id}))
        , sessionSamples = sessions.map(session => expectedSamples.filter(_ => _.sessionId === session.id))
        , samples = sessionSamples.map(samples => samples
            .slice()
            .sort((one, two) => {
                const plateTypeOne = sampleTypes.find(_ => _.id === one.sampleTypeId)?.sampleType
                    , plateTypeTwo = sampleTypes.find(_ => _.id === two.sampleTypeId)?.sampleType

                return comparePlateTypes(plateTypeOne, plateTypeTwo)
            })
        )

    return samples
}

function comparePlateTypes(plateTypeOne: pt.SampleName | undefined, plateTypeTwo: pt.SampleName | undefined) {
    const orderOne = pt.default.find(_ => _.id === plateTypeOne)?.order ?? 0
        , orderTwo = pt.default.find(_ => _.id === plateTypeTwo)?.order ?? 0
    return orderOne - orderTwo
}

function collapseLocationsWithoutSamples(list: types.MonitoringOverview[], locations: ListExposureLocation[]): types.CollapsedMonitoringOverview[] {
    const collapsedLocations: string[] = []

    return list
        .map(_ => {
            const locationName = _.locationName
                , childrenItems = list.filter(_ => _.locationName !== locationName && isChildLocation(locationName, _.locationName))

            if (canCollapseLocation(_, childrenItems, collapsedLocations, locations)) {
                collapsedLocations.push(locationName)

                return {
                    locationName: formatActiveState(locationName, _.isActive).concat(t.systemTextNode(' (all locations & positions)')),
                    bookedSamples: _.bookedSamples,
                    expectedSamples: _.expectedSamples,
                    collapsed: true,
                    isActive: _.isActive,
                }
            }

            return {
                locationName: locationName === UNKNOWN_LOCATION ? [t.systemTextNode(locationName)] : formatActiveState(locationName, _.isActive),
                bookedSamples: _.bookedSamples,
                expectedSamples: _.expectedSamples,
                collapsed: false,
                isActive: _.isActive,
            }
        })
        .filter(_ => isCollapsedLocations(_, collapsedLocations))
}

function isChildLocation(parentLocation: string, childLocation: t.Text | string) {
    const splitParentLocation = t.plainText(parentLocation).split('-')
        , splitChildLocation = t.plainText(childLocation).split('-')

    return splitParentLocation.every((_, i) => {
        const partialParent = _ && _.trim()
            , partialChild = splitChildLocation[i] && splitChildLocation[i].trim()
        return partialParent === partialChild
    })
}

function canCollapseLocation(item: types.MonitoringOverview, childrenItems: types.MonitoringOverview[], collapsedLocations: string[], locations: ListExposureLocation[]) {
    if (item.expectedSamples.length !== 0 || item.bookedSamples.length !== 0 || !collapsedLocations.every(_ => !isChildLocation(_, item.locationName)))
        return false

    return childrenItems.length > 0 && childrenItems.every(i => i.expectedSamples.length === 0 && i.bookedSamples.length === 0)
        || hasOnlyPositionChildLocations(item.locationName, locations)
}

function hasOnlyPositionChildLocations(locationName: string, locations: ListExposureLocation[]) {
    const parentLocation = locations.find(l => l.pathName === locationName)
        , positionChildLocations = locations.filter(l => l.parentId === parentLocation?.id && l.locationType === POSITION)
        , nonPositionChildLocations = locations.filter(l => l.parentId === parentLocation?.id && l.locationType !== POSITION)

    return positionChildLocations.length > 0 && nonPositionChildLocations.length == 0
}

function isCollapsedLocations(item: types.CollapsedMonitoringOverview, collapsedLocations: string[]) {
    return item.collapsed || !collapsedLocations.some(_ => isChildLocation(_, item.locationName))
}

function notInUse(status: s.MonitoringOverviewSampleStatuses) {
    return status === s.NOT_IN_USE
}

function missed(status: s.MonitoringOverviewSampleStatuses) {
    return status === s.MISSING
}

function calculateSamplesCount(items: types.MonitoringOverview[]) {
    return items.reduce((acc, _) => acc + _.bookedSamples.length + _.expectedSamples.length, 0)
}

function convertToSelectedSample(_: types.MonitoringOverviewSample) {
    return { id: _.id, sessionId: _.sessionId }
}

function getCellSamples(cell: {row?: number, column?: number}, items: types.MonitoringOverviewItems[]) {
    return items
        .filter((_, rowIndex) => cell.row === undefined || cell.row === rowIndex)
        .map(_ => _.bookedSamples
            .map((bs, i) => bs.map(convertToSelectedSample).concat(_.expectedSamples[i].map(convertToSelectedSample)))
            .filter((_, columnIndex) => cell.column === undefined || cell.column === columnIndex)
        )
        .flat(2)
}

function findSamples(items: types.MonitoringOverviewItems[], selectedSamples: types.SelectedSample[]) {
    return items.flatMap(_ => _.expectedSamples)
            .concat(items.flatMap(_ => convertToMonitoringOverviewSamples(_.bookedSamples)))
            .flat()
            .filter(_ => findSelectedSample(selectedSamples, convertToSelectedSample(_)))
}

function areSelectedSamplesEqual(s1: types.SelectedSample, s2: types.SelectedSample) {
    return s1.id === s2.id
        && s1.sessionId === s2.sessionId
}

function findSelectedSample(selectedSamples: types.SelectedSample[], sample: types.SelectedSample) {
    return selectedSamples.find(s => areSelectedSamplesEqual(s, sample))
}

function convertToMonitoringOverviewSamples(bookedSamples: types.MonitoringOverviewSampleData[][]) {
    return bookedSamples
        .map(_ =>
            _.map(s => ({
                status: s.status as s.NotBookedInStatuses,
                id: s.id,
                sessionId: s.sessionId,
                sampleTypeId: getFieldValue(s.fields, fi.SAMPLE_TYPE_ID),
                locationId: getFieldValue(s.fields, fi.EXPOSURE_LOCATION_ID),
                groupId: s.groupId,
            }))
        )
}


function normalizeFilter(filter: types.MonitoringOverviewFilter, predefinedLists: { locations: ListExposureLocation[], sampleTypes: SampleType[], sessions: SampleSession[], monitoringGroups: MonitoringGroup[]}) {
    const {locations, sessions, sampleTypes, monitoringGroups} = predefinedLists
        , exposureLocationIds = filterNotExistedId(filter.exposureLocationIds, locations)
        , sessionIds = filterNotExistedId(filter.sessionIds, sessions)
        , sampleTypeIds = filterNotExistedId(filter.sampleTypeIds, sampleTypes)
        , monitoringGroupIds = filterNotExistedId(filter.monitoringGroupIds, monitoringGroups)

    return {
        ...filter,
        exposureLocationIds,
        sessionIds,
        sampleTypeIds,
        monitoringGroupIds,
    }
}

function filterNotExistedId<T extends {id: Guid | string}>(selectedIds: Guid[] | undefined, entities: T[]) {
    const ids = selectedIds ?? []
    return ids.filter(id => entities.some(_ => _.id === id))
}

function sampleData(sample: types.MonitoringOverviewSample) {
    switch (sample.status) {
        case s.BOOKED_IN:
            return {
                sampleTypeId: getFieldValue(sample.fields, fi.SAMPLE_TYPE_ID),
                locationId: getFieldValue(sample.fields, fi.EXPOSURE_LOCATION_ID),
                sessionId: getFieldValue(sample.fields, fi.SESSION_ID),
            }
        default:
            return {
                sampleTypeId: sample.sampleTypeId,
                locationId: sample.locationId,
                sessionId: sample.sessionId,
            }
    }
}

function formatMonitoringGroupName(id: Guid | undefined, monitoringGroups: MonitoringGroup[], adHocGroups: MonitoringGroup[]) {
    if (!id || id === VOID_ID)
        return [t.systemTextNode('No group (unscheduled)')]

    const adHocGroup = adHocGroups.find(_ => _.id === id)
        , group = adHocGroup ?? monitoringGroups.find(_ => _.id === id)

    return formatGroupName(group?.name ?? '', group?.isActive, !!adHocGroup)
}

export {
    comparePlateTypes,
    getMonitoringOverview,
    notInUse,
    calculateSamplesCount,
    getCellSamples,
    findSamples,
    areSelectedSamplesEqual,
    findSelectedSample,
    normalizeFilter,
    missed,
    sampleData,
    formatMonitoringGroupName,
}
