import { useState, useCallback, useEffect, classnames } from '_/facade/react'
import Overlay from './overlay'

type MenuPosition = 'below' | 'right' | 'top'

interface Props {
    element: Element | null
    // TS bug https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20544
    // Should be ReactNode
    children: React.ReactElement
    useParentWidth: boolean
    position: MenuPosition
    containerRef?: React.Ref<HTMLDivElement>
    topOffset?: number
    showTriangle?: boolean
}

function Menu(props: Props) {
    const [position, setPosition] = useState<{top: number, left: number, width: number | undefined}>()
        , [dropdown, setDropdown] = useState<HTMLElement | null>(null)
        , [outOfScreen, setOutOfScreen] = useState<{outOfScreenTop: boolean, outOfScreenRight: boolean}>({ outOfScreenTop: false, outOfScreenRight: false })
        , updatePosition = useCallback(
            () => {
                if (!props.element) {
                    setPosition(undefined)
                    return
                }

                const scrollParent = verticalScrollParent(props.element)
                if (outOfTableHeader(props.element) || outOfViewport(props.element, scrollParent)) {
                    setPosition(undefined)
                    return
                }

                const rect = props.element.getBoundingClientRect()
                    , dropdownRectSize = dropdown?.getBoundingClientRect() ?? { height: 0, width: 0 }

                    , outOfScreenTop = rect.y + rect.height + dropdownRectSize.height > window.innerHeight
                    , outOfScreenOffset =  props.position === 'right'
                        ? dropdownRectSize.height - rect.height + 5
                        : dropdownRectSize.height + rect.height + 5
                    , topOffset = props.topOffset ? rect.height - rect.height * props.topOffset - dropdownRectSize.height : 0
                    , topPosition = props.position === 'right' || props.position === 'top' ? rect.top + topOffset : rect.bottom
                    , top = topPosition + 1 - (outOfScreenTop ? outOfScreenOffset : 0)

                    , outOfScreenRight = rect.x + dropdownRectSize.width > window.innerWidth
                    , outOfScreenRightOffset = dropdownRectSize.width - rect.width - 20 // 20 - leave place for scroll bar
                    , leftPosition = props.position === 'right' ? rect.right : rect.left
                    , left = leftPosition - (outOfScreenRight ? outOfScreenRightOffset : 0)

                    , width = props.useParentWidth ? rect.width : undefined

                setPosition(
                    _ => _ === undefined || _.top !== top || _.left !== left || _.width !== width
                        ? { top, left, width }
                        : _
                )
                setOutOfScreen({ outOfScreenTop, outOfScreenRight })
            },
            [props.element, props.useParentWidth, dropdown, props.position, props.topOffset]
        )

    useEffect(
        () => {
            let active = true

            function next() {
                if (!active)
                    return

                updatePosition()
                window.requestAnimationFrame(next)
            }

            next()

            return () => {
                active = false
            }
        },
        [updatePosition]
    )

    if (!position)
        return null

    return (
        <Overlay {...position} containerRef={props.containerRef} dropdownRef={setDropdown}>
            <div className={classnames(
                props.showTriangle && 'legend-bubble-triangle',
                props.position === 'right' && 'triangle-left',
                outOfScreen.outOfScreenTop && 'inversed-vertically',
                outOfScreen.outOfScreenRight && 'triangle-right'
            )}>
                {props.children}
            </div>
        </Overlay>
    )
}

Menu.defaultProps = {
    useParentWidth: false,
    position: 'below',
    showTriangle: false,
}

function useCloseMenu(ref: HTMLDivElement | null, close: () => void, openMenuBtn?: HTMLElement | null) {
    useEffect(
        () => {
            function handleClose(event: FocusEvent) {
                function contains(node: Node | null) {
                    if (!(event.target instanceof Node))
                        return

                    return openMenuBtn?.contains(event.target)
                        || node?.contains(event.target)
                }

                if (!contains(ref))
                    close()
            }

            document.addEventListener('click', handleClose)

            return () => document.removeEventListener('click', handleClose)
        }
    )
}

function verticalScrollParent(element: Element): HTMLElement | undefined {
    for (let parent = element.parentElement; parent !== null; parent = parent.parentElement) {
        const style = getComputedStyle(parent)

        if (style.position === 'fixed')
            return undefined

        if (['scroll', 'auto'].includes(style.overflowY))
            return parent
    }
    return undefined
}

function outOfViewport(element: Element, viewportElement: Element | undefined): boolean {
    if (!viewportElement)
        return false

    const elBox = element.getBoundingClientRect()
        , vpBox = viewportElement.getBoundingClientRect()
        , outOfX = elBox.right < vpBox.left || elBox.left > vpBox.right
        , outOfY = elBox.bottom < vpBox.top || elBox.top > vpBox.bottom

    return outOfX || outOfY
}

function outOfTableHeader(element: Element) {
    for (let parent = element.parentElement; parent !== null; parent = parent.parentElement) {
        if (parent.nodeName === 'TABLE') {
            const thead = Array.from(parent.children).find(_ => _.nodeName === 'THEAD')
                , row = thead && Array.from(thead.children)
                , thElements =  row && Array.from(row[0].children)
                , tableHeader = thElements?.[0].getBoundingClientRect()
                , elBox = element.getBoundingClientRect()

            return tableHeader && tableHeader.bottom > elBox.bottom
        }
    }
    return false
}

export {
    Menu as default,
    MenuPosition,
    useCloseMenu,
    verticalScrollParent,
    outOfViewport,
}
