import { useAction } from '_/facade/react'

import type * as ap from '_/model/analysis/aggregation-period'
import { calculateDifference } from '_/model/floor-plan/time-slider'

import * as timeActions from '_/features/time/actions'
import type { DateTime } from '_/model/date-time'

interface Props {
    disabled: boolean
    startDate: DateTime
    endDate: DateTime
    range: ap.AggregationPeriod | undefined
    value: number | undefined
    onChange?: (exposureStartDateTo: number) => void
}

const TimeSliderInput = (props: Props) => {
    const getTimeService = useAction(timeActions.getTimeService)
        , timeService = getTimeService()
        , max = calculateDifference(props.startDate, props.endDate, props.range, timeService)
        , tickMarksArray = new Array(max).fill('')
        , value = props.value === undefined || props.value > max ? max : props.value

    function handleChange(_: number) {
        props.onChange?.(_)
    }

    return (
        <>
            <div id='tick-marks' className='tick-marks-block mt-2'>
                {tickMarksArray.length > 1 && tickMarksArray.map((_, i) => <span className='tick-marks-block__tick-mark' key={i}></span>)}
            </div>
            <input
                type='range'
                className='time-slider flex-fill mt-1'
                id='time-slider'
                data-testid = 'time-slider'
                name='time-slider'
                value={value}
                min={1}
                max={max}
                onChange={_ => handleChange(parseFloat(_.target.value))}
                disabled={props.disabled || tickMarksArray.length <= 1}
            />
        </>
    )
}

export default TimeSliderInput
