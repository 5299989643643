import { classnames } from '_/facade/react'
import type { FormRenderProps} from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'

import type User from '_/model/users/types'
import { ENTITY_TYPE } from '_/constants/entity-type'

import { LocalDateField, MultiSelectField, CheckboxField, TextField } from '_/components/form'
import Button, { Close } from '_/components/button'
import FilterActions from '_/components/filter-actions'

import { validate } from '_/model/audit-trail/validate'
import type { GlobalAuditTrailsFilter } from '_/model/audit-trail/types'
import { diffObject } from '_/utils/object'
import { useDebounce, useSyncRef } from '_/hooks/shared-hooks'


interface Props {
    showFilter: boolean
    userList: User[]
    filterState: GlobalAuditTrailsFilter
    onChange: (template: GlobalAuditTrailsFilter) => void
    onClose: () => void
    onClearFilter: () => void
}

function Filter(props: Props) {
    const previousFilter = useSyncRef(props.filterState)
        , submitDebounce = useDebounce()

    function handleChangeFormValues(values: GlobalAuditTrailsFilter, form: FormRenderProps<any>) {
        const prevValues = previousFilter.current
        previousFilter.current = values

        if (diffObject(prevValues, values)) {
            submitDebounce(form.form.submit)
        }
    }

    function handleClear(form: FormRenderProps) {
        form.form.reset()
        props.onClearFilter()
    }

    return (
        <Form
            onSubmit={props.onChange as any}
            initialValues={props.filterState}
            validate={validate}
            render={form =>
                <div className={classnames('position-absolute filters-popup d-flex flex-column side-filters', { 'filters-popup-hide': !props.showFilter })}>
                    <nav className='navbar mx-0'>
                        <span className='navbar-brand me-auto'>Filters</span>
                        <Close onClick={props.onClose} testId='close-filters' />
                    </nav>
                    <form className='mb-2 overflow-y-auto flex-fill' onSubmit={form.handleSubmit}>
                        <FormSpy
                            onChange={_ => handleChangeFormValues(_.values as GlobalAuditTrailsFilter, form)}
                            subscription={{ values: true }}
                        />
                        <div className='px-3'>
                            <MultiSelectField
                                name='entityTypes'
                                id='entityTypes'
                                entities={ENTITY_TYPE}
                                calcId={_ => _.id}
                                calcName={_ => _.name}
                                autocomplete={true}
                                testId='field-entity-types'
                            >
                                Entity type
                            </MultiSelectField>
                            <MultiSelectField
                                name='users'
                                id='users'
                                entities={props.userList}
                                calcId={_ => _.id}
                                calcName={_ => `${_.name} (${_.email}) `}
                                autocomplete={true}
                            >
                                User
                            </MultiSelectField>
                            <LocalDateField id='dateFrom' name='dateFrom'>From</LocalDateField>
                            <LocalDateField id='dateTo' name='dateTo' useDayEndTime>To</LocalDateField>
                            <TextField id='actionQuery' name='actionQuery'>Search</TextField>
                            <CheckboxField id='onlyReasonsIncluded' name='onlyReasonsIncluded'>Only show exceptions</CheckboxField>
                        </div>

                        <div className='side-filters__spacer' />

                        <FilterActions className='d-flex side-filters__actions'>
                            <Button className='btn-link ms-auto' onClick={() => handleClear(form)}>Clear</Button>
                        </FilterActions>
                    </form>
                </div>
            }
        />
    )
}

export default Filter
