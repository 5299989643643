import { useAction, useSelector, useState, useEffect, classnames, useCallback } from '_/facade/react'

import type Role from '_/model/roles/types'
import type ApiKey from '_/model/api-key/types'
import { convertToEdit } from '_/model/api-key/helpers'

import { useContextSwitchObserver } from '_/components/context-observer'
import { Table } from '_/components/table'
import { useTimeService } from '_/components/time'
import ApiKeysCellData from './api-keys-cell-data'
import ApiKeysDescriptionForm from './api-keys-description-form'
import ApiKeyCreateForm from './api-key-create-form'
import { Modal } from '_/components/modal'
import { navigateTo } from '_/features/routing/actions'
import { LinkButton } from '_/components/link'

import * as r from '_/constants/routes'

import * as deletionActions from '_/features/confirmation/actions'
import * as roleActions from '_/features/roles/actions'
import * as userActions from '_/features/users/actions'
import * as actions from './redux/actions'
import { getApiBaseUrl } from '_/model/environment/helpers'

function ApiKeyList() {
    const route = useSelector(_ => _.router.route)
        , userId = useSelector(_ => _.auth.user?.id)
        , [apiKeys, getUserRoleName] = useLoadDependentData(route?.params.id)
        , timeService = useTimeService()
        , handleDelete = useDelete()
        , handleSubmit = useSubmit()
        , [showModal, closeModal] = useModal(r.SETTINGS_USERS_API_KEYS_CREATE, userId)
        , permissions = useSelector(_ => _.auth.permissions)
        , user = useSelector(_ => _.auth.user)
        , canGenerateApiKey = permissions.manageApiKeys && route?.params.id === userId
        , canManageApiKey = permissions.manageApiKeys && (route?.params.id === userId || user?.membership.role.isAdmin)
        , apiDocsUrl = getApiBaseUrl()

    return (
        <>
            <div className='d-flex py-3 page-header align-items-center justify-content-between'>
                <LinkButton
                    routeName={r.SETTINGS_USERS_API_KEYS_CREATE}
                    routeParams={{ id: userId }}
                    className='btn-primary'
                    hasNoPermissions={!canGenerateApiKey}
                    testId='generate-api-key'
                >
                    Generate a new API key
                </LinkButton>
                <a href={apiDocsUrl} target='_blank' className='link' data-testid='api-documentation'>API documentation</a>
            </div>

            <div className='overflow-auto flex-fill'>
                <Modal isOpen={showModal} onClose={closeModal}>
                    <ApiKeyCreateForm />
                </Modal>
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>API key</th>
                            <th>Description</th>
                            <th>User role</th>
                            <th>Generated</th>
                            <th>Status</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {apiKeys.map((_, index) =>
                            <tr key={_.id} data-testid='api-key-item'>
                                <td className='text-break api-keys-list__key-column--width'>{_.publicKey}</td>
                                <td className='text-break api-keys-list__description-column--width'>
                                    <ApiKeysDescriptionForm
                                        entity={_}
                                        onSubmit={data => handleSubmit(_, _.isActive, data.description)}
                                        hasNoPermissions={!canManageApiKey}
                                        testId={`api-key-${index}`}
                                    />
                                </td>
                                <td>{getUserRoleName(_.roleId)}</td>
                                <td>{timeService.formatCtzDateTime(_.createdAt)}</td>
                                <td>
                                    <span className={classnames('px-2 text-white rounded fw-bold', _.isActive ? 'bg-success' : 'bg-danger')}>
                                        {_.isActive ? 'Active' : 'Inactive'}
                                    </span>
                                </td>
                                <ApiKeysCellData
                                    onSubmit={() => handleSubmit(_, !_.isActive, _.description)}
                                    onDelete={() => handleDelete(_.id, _.publicKey)}
                                    isActive={_.isActive}
                                    hasNoPermissions={!canManageApiKey}
                                    testId={`api-key-${index}`}
                                />
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ApiKeyList

const UNREALISTIC_USER_COUNT = 1000

function useLoadDependentData(userId: string) {
    const load = useAction(actions.loadApiKeyList)
        , loadRoles = useAction(roleActions.loadContextRoles)
        , loadUsers = useAction(userActions.loadUserList)
        , apiKeys = useSelector(_ => _.apiKeys.list)
        , contextSwitch = useContextSwitchObserver()
        , contextId = useSelector(_ => _.auth.user && _.auth.user.membership.contextId)
        , [roles, setRoles] = useState<Role[]>([])
        , users = useSelector(_ => _.users.list.items)
        , clear = useAction(actions.clearApiKeys)
        , manageApiKeys = useSelector(_ => _.auth.permissions.manageApiKeys)

    useEffect(
        () => {
            clear()
            if (manageApiKeys && contextId && userId) {
                load(userId)
                loadUsers({ start: 0, count: UNREALISTIC_USER_COUNT, sort: 'name:asc' })
                loadRoles(contextId).then(setRoles)
            }
        },
        [contextSwitch, loadRoles, contextId, userId, load, loadUsers, clear, manageApiKeys]
    )

    function getUserRoleName(roleId: string) {
        const user = users.find(_ => _.id === userId)
            , role = roles.find(_ => _.id === roleId)

        return `${user?.name} (${role?.name})`
    }

    return [apiKeys, getUserRoleName] as const
}

function useSubmit() {
    const save = useAction(actions.saveApiKey)

    function handleSubmit(apiKey: ApiKey, isActive: boolean, description: string | undefined) {
        const id = apiKey.id
            , oldApiKey = convertToEdit(apiKey)
            , newApiKey = {
                isActive,
                description,
            }

        return save({ id, oldApiKey, newApiKey })
    }

    return handleSubmit
}

function useDelete() {
    const removeApiKey = useAction(actions.removeApiKey)
        , showDeletionWarning = useAction(deletionActions.showDeletionConfirmationModal)
        , deleteMessage = 'Are you sure you want to delete the access key with ID'
        , warning = 'If you delete an access key, any requests signed with that access key ID will fail immediately. You cannot reactivate a deleted access key.'

    function handleDelete(id: string, publicKey: string) {
        return showDeletionWarning(`${deleteMessage} ${publicKey}`, warning)
            .then(() => removeApiKey(id))
    }

    return handleDelete
}

function useModal(name: r.RouteName, userId: string | undefined) {
    const stateName = useSelector(_ => _.router.route?.name)
        , navigate = useAction(navigateTo)
        , handleClose = useCallback(
            () => navigate(r.SETTINGS_USERS_API_KEYS, { id: userId }),
            [navigate, userId],
        )

    return [stateName === name, handleClose] as const
}
