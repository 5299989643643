import CaptureControls from './capture-controls'
import type { SmartInfo } from '_/model/smart-check/image'
import type ImageProvider from '_/model/smart-check/image-provider'
import * as a from '../actions'
import * as ca from '_/features/confirmation/actions'
import { classnames, forwardRef, useAction, useEffect, useImperativeHandle, useReducer, useSelector } from '_/facade/react'
import type { Guid } from '_/model/guid'
import type WebcamState from '_/model/smart-check/webcam-state'
import * as pt from '_/constants/plate-type'
import { useSyncRef } from '_/hooks/shared-hooks'
import { noop } from '_/utils/function'
import SmartImage from './smart-image'
import ImageValidationResult from './image-validation/validation-result'
import ShowSuggestedGrowthRegionsAndCounts from './show-suggested-growth-regions-and-counts'
import Thumbnail from './thumbnail'
import ValidationModal from './validation-modal'
import classNames from 'classnames'
import LiveFeed from '../webcam/live-feed'

interface Props {
    entityId?: Guid
    plateType?: pt.SampleName
    className?: string
    attachedImages: Guid[]
    detachedImages: Guid[]
    blank?: boolean
    webcam: WebcamState
    photoError?: string | undefined
    hasNoPermission?: boolean
    onPhotoTaking?: (busy: boolean) => void
    onPhotoTaken?: (info: SmartInfo) => Promise<void>
    onPhotoRemoved?: (id: Guid) => void
    showGrowthCountOverPatch?: boolean
    zeroGrowthCheckEnabled?: boolean
    automatedGrowthCountVerificationEnabled?: boolean
}

interface PhotoGalleryRef{
    focusImage(imageId: Guid): void
}

type Mode = (
        | { tag: 'default' }
        | {
            tag: 'taking-photo'
            uploadedImage?: Guid
            uploadCompletedData?: { info: SmartInfo, imageState: 'none' | 'accepted' | 'declined' }
            cancel: () => void
        }
    ) & { selectedImage?: Guid}

type ModeAction =
    | ['reset', Guid?]
    | ['select-image', Guid]
    | ['take-photo', () => void]
    | ['photo-got-id', Guid]
    | ['photo-taken', SmartInfo]
    | ['remove-image', Guid]
    | ['update-image-state', 'accepted' | 'declined']

function reducer(state: Mode, action: ModeAction): Mode {
    const [type] = action

    switch (type) {
        case 'reset': {
            return { tag: 'default', selectedImage: action[1] }
        }

        case 'select-image': {
            return { ...state, selectedImage: action[1] }
        }

        case 'take-photo': {
            return { tag: 'taking-photo', cancel: action[1] }
        }

        case 'remove-image': {
            const imageToRemove = action[1]
                , selectedImage = state.selectedImage === imageToRemove ? undefined : state.selectedImage

            if (state.tag === 'taking-photo' && state.uploadedImage === imageToRemove)
                return { tag: 'default', selectedImage: selectedImage }

            return { ...state, selectedImage }
        }

        default: {
            if (state.tag !== 'taking-photo')
                return state
        }
    }


    switch (type) {
        case 'photo-got-id': {
            const image = action[1]
            return { ...state, uploadedImage: image, selectedImage: image }
        }

        case 'photo-taken': {
            const info = action[1]
            return {
                ...state,
                uploadCompletedData: { info, imageState: isValid(info) ? 'accepted' : 'none' }
            }
        }

        case 'update-image-state': {
            if (!state.uploadCompletedData)
                return state

            return {
                ...state,
                uploadCompletedData: {
                    ...state.uploadCompletedData,
                    imageState: action[1],
                },
            }
        }
    }
}

function PhotoGalleryRenderFunction(props: Props, ref: React.ForwardedRef<PhotoGalleryRef>) {
    const [mode, dispatch] = useReducer(reducer, { tag: 'default' })
        , canUpload = !!props.onPhotoTaken
        , isWebcamMode = useSelector(_ => _.smartCheck.currentReadingDeviceId === 'webcam') && canUpload
        , [info, addInfo] = useInfo(mode, props.detachedImages, props.attachedImages, isWebcamMode)
        , handleTakePhoto = useTakePhotoHandler(mode, dispatch, addInfo, props.plateType, props.onPhotoTaken || (() => Promise.resolve()), props.detachedImages, props.attachedImages, props.entityId)
        , handleDelete = useDeleteHandler(mode, dispatch, props.detachedImages, props.onPhotoRemoved || noop)
        , showSuggestedGrowthRegions = useSelector(_ => _.smartCheck.showSuggestedGrowthRegionsAndCounts)
        , smartCheckEnabled = useSelector(_ => _.auth.user?.membership.context.smartCheckEnabled)
        , currentImage = getCurrentImage(mode, props.detachedImages, props.attachedImages, isWebcamMode)
        , isCurrenImageOpenable = mode.tag !== 'taking-photo' || mode.uploadedImage !== currentImage
        , showValidationModal = mode.tag === 'taking-photo' && mode.uploadCompletedData?.imageState === 'none'
        , cropRequired = info?.validationResult
            && info.validationResult.alignPassed
            && info.validationResult.blurCheckPassed
            && !!props.attachedImages.find(_ => _ === currentImage)
        , showLiveFeed = canUpload && isWebcamMode && currentImage === undefined
        , backgroundClass = canUpload
            ? 'webcam-bg-light'
            : props.attachedImages.length > 0
                ? 'webcam-bg-black'
                : undefined
        , passed = info?.validationResult?.alignPassed && info.validationResult.blurCheckPassed && info.validationResult.repetitionPassed

    useBusyNotifier(mode, props.onPhotoTaking || noop)

    useImperativeHandle(
        ref,
        () => ({
            focusImage: imageId => dispatch(['select-image', imageId])
        }),
        []
    )

    if (props.blank)
        return <div className={props.className} />

    return (
        <div className={classNames('d-flex flex-column', props.className)}>
            {showValidationModal &&
                <ValidationModal
                    result={mode.uploadCompletedData!.info.validationResult!}
                    onAccept={() => dispatch(['update-image-state', 'accepted'])}
                    onDiscard={() => dispatch(['update-image-state', 'declined'])}
                />
            }

            {canUpload &&
                <CaptureControls
                    isImageSelected={mode.selectedImage !== undefined}
                    webcam={props.webcam}
                    inProgress={mode.tag === 'taking-photo'}
                    onImageCapture={handleTakePhoto}
                    onShowLiveFeed={() => dispatch(['reset'])}
                    hasNoPermission={props.hasNoPermission}
                />
            }

            <div className={classnames('d-flex flex-column flex-fill position-relative rounded text-muted', backgroundClass, showLiveFeed && 'justify-content-center')}>
                {mode.tag === 'default' && !currentImage && props.attachedImages.length === 0 && props.detachedImages.length === 0 && !showLiveFeed &&
                    <div className={classnames('d-flex flex-fill align-items-center justify-content-center', !canUpload && 'smart-check-image-readonly-height')}>
                        There are no photos yet
                    </div>
                }
                {mode.tag === 'taking-photo' && !currentImage &&
                    <i className='preview-spinner material-icons md-48'>sync</i>
                }
                {showLiveFeed
                    ? props.webcam.type === 'ready'
                        ? <LiveFeed stream={props.webcam.value} />
                        : props.webcam.type === 'error' && <div className='align-self-center'>{props.webcam.value}</div>
                    : null
                }
                {currentImage &&
                    <>
                        <SmartImage
                            type='original'
                            className={classnames('smart-check-image flex-fill', !canUpload && 'smart-check-image-readonly-height', isCurrenImageOpenable && 'cursor-pointer')}
                            imageId={currentImage}
                            openable={isCurrenImageOpenable}
                            plateRect={info?.validationResult?.rectangle}
                            growths={showSuggestedGrowthRegions && passed ? info.growthCountResult?.growths : undefined}
                            cropPlate={cropRequired}
                            showGrowthCountOverPatch={props.showGrowthCountOverPatch}
                        />
                        <ImageValidationResult
                            showCfuCount={showSuggestedGrowthRegions}
                            isBeingUploaded={mode.tag === 'taking-photo' && smartCheckEnabled}
                            isHistoricalImage={props.attachedImages.includes(currentImage)}
                            info={info}
                            zeroGrowthCheckEnabled={props.zeroGrowthCheckEnabled}
                            automatedGrowthCountVerificationEnabled={props.automatedGrowthCountVerificationEnabled}
                        />
                        <ShowSuggestedGrowthRegionsAndCounts
                            smartCheckResult={info?.validationResult}
                            growthCountResult={info?.growthCountResult}
                        />
                    </>
                }
            </div>

            {canUpload &&
                <div className='invalid-feedback d-block' data-testid='validation-error'>{props.photoError || <>&nbsp;</>}</div>
            }

            <div className='smart-check-thumbnails'>
                {mode.tag === 'taking-photo' && mode.uploadedImage &&
                    <Thumbnail
                        imageId={mode.uploadedImage}
                        selectedImageId={currentImage}
                        hasSpinner
                        onClick={() => dispatch(['select-image', mode.uploadedImage!])}
                        onDelete={handleDelete}
                    />
                }
                {props.detachedImages.map(imageId =>
                    <Thumbnail
                        key={imageId}
                        imageId={imageId}
                        selectedImageId={currentImage}
                        onClick={() => dispatch(['select-image', imageId])}
                        onDelete={handleDelete}
                    />
                )}
                {props.attachedImages.map(imageId =>
                    <Thumbnail
                        key={imageId}
                        imageId={imageId}
                        selectedImageId={currentImage}
                        onClick={() => dispatch(['select-image', imageId])}
                    />
                )}
            </div>
        </div>
    )
}

const PhotoGallery = Object.assign(
    forwardRef(PhotoGalleryRenderFunction),
    {
        defaultProps: {
            attachedImages: [],
            detachedImages: [],
            webcam: { type: 'no-webcam' }
        }
    }
)

export default PhotoGallery
export {
    PhotoGalleryRef,
}

function useTakePhotoHandler(
    mode: Mode, dispatch: React.Dispatch<ModeAction>,
    addInfo: (_: SmartInfo) => void,
    plateType: pt.SampleName | undefined,
    onPhotoTaken: (info: SmartInfo) => Promise<void>,
    detachedImages: Guid[],
    attachedImages: Guid[],
    entityId: Guid | undefined,
) {
    const takePhotoAction = useAction(a.takePhoto)

    function handleTakePhoto(provider: ImageProvider) {
        if (!entityId)
            return

        const photoPromise = takePhotoAction({ provider, previousImageId: detachedImages.concat(attachedImages)[0], plateType: plateType ?? pt.OTHER, entityId })

        dispatch(['take-photo', photoPromise.cancel])

        photoPromise.imageIdPromise.then(info => {
            addInfo(info)
            dispatch(['photo-got-id', info.imageId])
        })

        photoPromise.smartInfoPromise.then(addInfo)

        Promise.all([photoPromise.smartInfoPromise, photoPromise])
            .then(([_]) => dispatch(['photo-taken', _]))
            .catch(() => dispatch(['reset']))
    }

    const uploadCompletedData = mode.tag === 'taking-photo' && mode.uploadCompletedData
        , cancelUpload = mode.tag === 'taking-photo' ? mode.cancel : noop
        , callbacks = useSyncRef(onPhotoTaken)

    useEffect(
        () => {
            if (!uploadCompletedData || uploadCompletedData.imageState === 'none')
                return

            if (uploadCompletedData.imageState === 'accepted') {
                callbacks.current(uploadCompletedData.info)
                    .finally(() => dispatch(['reset', uploadCompletedData.info.imageId]))
            }

            if (uploadCompletedData.imageState === 'declined') {
                cancelUpload()
                dispatch(['reset'])
            }
        },
        [uploadCompletedData, cancelUpload, callbacks, dispatch]
    )

    return handleTakePhoto
}

function useInfo(mode: Mode, detachedImages: Guid[], attachedImages: Guid[], isWebcamMode: boolean) {
    const [info, addInfo] = useReducer(
            (_: SmartInfo[], info: SmartInfo) => _.filter(i => i.imageId !== info.imageId).concat(info),
            []
        )
        , loadInfo = useAction(a.getInfo)

    useEffect(
        () => {
            const completedImages = attachedImages.concat(detachedImages)
                , isInProgressImage = mode.tag === 'taking-photo' && mode.selectedImage && !completedImages.includes(mode.selectedImage)
                , missingInfos = completedImages
                    .concat(mode.selectedImage !== undefined && !isInProgressImage ? mode.selectedImage : [])
                    .filter(id => info.find(_ => _.imageId === id) === undefined)

            missingInfos.forEach(imageId => {
                // avoid repeated loading of image info
                addInfo({ imageId, validationResult: undefined, growthCountResult: undefined, moldPredictorEnabled: false })
                loadInfo(imageId).then(addInfo)
            })
        },
        [info, mode.selectedImage, mode.tag, attachedImages, detachedImages, loadInfo, addInfo]
    )

    const currentImage = getCurrentImage(mode, detachedImages, attachedImages, isWebcamMode)
        , currentImageInfo = info.find(_ => _.imageId === currentImage)

    return [currentImageInfo, addInfo] as const
}

function useDeleteHandler(mode: Mode, dispatch: React.Dispatch<ModeAction>, detachedImages: Guid[], onPhotoRemoved: (id: Guid) => void) {
    const confirmDeletion = useAction(ca.showDeletionConfirmationModal)
        , removeImage = useAction(a.removeImage)
        , args = useSyncRef({ mode, detachedImages, onPhotoRemoved })

    function handleDelete(image: Guid) {
        return confirmDeletion('Are you sure you want to delete image?')
            .then(() => {
                const { mode, detachedImages, onPhotoRemoved } = args.current

                if (mode.tag === 'taking-photo' && mode.uploadedImage === image) {
                    mode.cancel()
                    dispatch(['remove-image', image])
                    return
                }

                if (detachedImages.includes(image)) {
                    removeImage(image)
                    onPhotoRemoved(image)
                    dispatch(['remove-image', image])
                    return
                }
            })
    }

    return handleDelete
}

function getCurrentImage(mode: Mode, detachedImages: Guid[], attachedImages: Guid[], isWebcamMode: boolean) {
    const images: (Guid | undefined)[] = detachedImages.concat(attachedImages)
    if (mode.tag === 'taking-photo' && mode.uploadedImage)
        images.unshift(mode.uploadedImage)

    if (mode.tag === 'taking-photo' && !mode.uploadedImage)
        return undefined

    return images.includes(mode.selectedImage)
            ? mode.selectedImage
            : isWebcamMode
                ? undefined
                : images[0]
}

function isValid(info: SmartInfo) {
    const v = info.validationResult

    return v ? v.alignPassed && v.blurCheckPassed && v.repetitionPassed : true
}

function useBusyNotifier(mode: Mode, onBusyChange: (_: boolean) => void) {
    const callback = useSyncRef(onBusyChange)
    useEffect(
        () => {
            callback.current(mode.tag === 'taking-photo')
        },
        [mode, callback]
    )
}
