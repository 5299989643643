import { useState, classnames } from '_/facade/react'
import type { DatePickerWrapperProps } from '_/utils/input/local-date-input'
import DateInput from '_/utils/input/utc-date-input'

interface Props extends DatePickerWrapperProps {
}

function UtcDateInput(props: Props) {
    const [focused, setFocused] = useState(false)

    function handleFocus(event: React.FocusEvent<HTMLInputElement>): void {
        setFocused(true)
        if (props.onFocus)
            props.onFocus(event)
    }

    function handleBlur(event: React.FocusEvent<HTMLInputElement>): void {
        setFocused(false)
        if (props.onBlur)
            props.onBlur(event)
    }

    return (
        <span className={classnames('local-date-input form-control', props.className, { focus: focused })}>
            <DateInput
                {...props}
                className={'form-control'}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </span>
    )
}

export default UtcDateInput
