import type { PredefinedLists as State } from '_/model/app-state'
import { commonReducer, handler } from '_/facade/reducer'

import { predefinedListLoaded } from '../redux/actions'
import * as actions from './actions'

const defaultState: State['identifications'] = {
        organismType: [],
        catalase: [],
        oxidase: [],
        oxidationFermentation: [],
        coagulase: [],
    }
    , identifications = commonReducer(
        defaultState,

        handler(actions.allIdentificationsLoaded, (_, payload) => payload),
        handler(predefinedListLoaded, (_, payload) => payload.identifications),
    )

export { identifications }
