import { shallowUpdate } from './object'

function tokenizedSearch<T>(query: string, entities: readonly T[], description: (_: T) => string): T[] {
    const tokens = query.toLowerCase().split(/\s+/).filter(_ => _)

    return entities.filter(entity => {
        const name = (description(entity) || '').toLowerCase()

        let position = 0
        return tokens.every(token => {
            position = name.indexOf(token, position)

            if (position >= 0)
                position += token.length

            return position !== -1
        })
    })
}

function mergeAndReplaceEntity<T extends { id: string }>(items: T[], item: Partial<T>) {
    return replace(items, _ => _.id === item.id, _ => shallowUpdate(_, item))
}

function mergeAndReplaceEntityById<T extends { id: string }>(items: T[], item: Partial<T>, id: string) {
    return replace(items, _ => _.id === id, _ => shallowUpdate(_, item))
}

function replace<T>(items: T[], comparer: (_: T) => boolean, updater: (_: T) => T) {
    return items.map(_ => comparer(_) ? updater(_) : _)
}

function areArraysLengthsEqual<T>(oldArray: T[], newArray: T[]) {
    return oldArray.length === newArray.length
}

function isEmptyArray(items: any[]) {
    return (Array.isArray(items) && items.length === 0)
}

function generateArray<T>(length: number, generator: (index: number) => T): T[] {
    return Array.from({ length }, (_, i) => generator(i))
}

function areArraysEqual<T extends number | string>(x: T[] | undefined, y: T[] | undefined): boolean {
    if (x === y)
        return true

    if (!x || !y)
        return false

    return x.length === y.length && x.every((v, i) => v === y[i])
}

function areArraysConsistent<T extends number | string>(x: T[] | undefined, y: T[] | undefined): boolean {
    if (x === y)
        return true

    if (!x || !y)
        return false

    return x.length === y.length && x.every(v => y.find(_ => _ === v))
}

export {
    tokenizedSearch,
    mergeAndReplaceEntity,
    mergeAndReplaceEntityById,
    replace,
    areArraysLengthsEqual,
    isEmptyArray,
    generateArray,
    areArraysEqual,
    areArraysConsistent,
}
