import { actionFactory } from '_/utils/redux'
import type Page from '_/model/page'
import type { SampleEditedInfo } from '_/model/sample/sample'
import type Sample from '_/model/sample/sample'
import type SampleReading from '_/model/sample/reading/sample-reading'
import type { SampleCreateRequest, BookedInSampleRequest } from '_/model/sample/booking/types'
import type { SampleSearchByBarcodeResult, SampleSearchStatistics } from '_/model/sample/sample-search-result'
import type SampleSearchResult from '_/model/sample/sample-search-result'
import type { ExportSampleListQuery } from '_/model/sample/list-query'
import type SampleListQuery from '_/model/sample/list-query'
import type { NewSampleSearchFilter, ExistedSampleSearchFilter } from '_/model/sample/search'
import type { NewSampleComment, ExistedSampleComment } from '_/model/sample/comment'
import type { VacantExpectation, VacantExpectationQuery } from '_/model/sample/expectation'
import type { BulkOperationId } from '_/constants/bulk-operation'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import type SampleDetails from '_/model/sample/sample-details'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type { UploadData, UploadResult } from '_/model/sample/files/uploader'
import type { AttachedFile } from '_/model/sample/files/files'
import type Export from '_/model/export/types'

import type { ChangeCompromisedData } from '_/model/sample/reading/transaction'
import type ColumnEdit from '_/model/sample/list/column-edit'
import type { SampleTrend, SampleTrendsQuery } from '_/model/sample/sample-trends'
import type { Guid } from '_/model/guid'
import type { AuditTrail } from '_/model/audit-trail/types'
import type { SearchBatchNumbers } from '_/model/sample/batch-number'

const action = actionFactory('app.samples')

export const loadSampleList = action<SampleListQuery, Promise<void>>('loadSampleList')
           , getSampleList = action<SampleListQuery, Promise<SampleSearchResult>>('getSampleList')
           , sampleListLoaded = action<SampleSearchResult>('sampleListLoaded')
           , loadSampleListStatistics = action<SampleListQuery, Promise<void>>('loadSampleListStatistics')
           , getSampleListStatistics = action<SampleListQuery, Promise<SampleSearchStatistics>>('getSampleListStatistics')
           , sampleListStatisticsLoaded = action<SampleSearchStatistics>('sampleListStatisticsLoaded')
           , navigateToSampleList = action<SampleListQuery, void>('navigateToSampleList')
           , createSample = action<Partial<SampleCreateRequest>, Promise<Guid>>('createSample')
           , loadSample = action<string, Promise<SampleDetails>>('loadSample')
           , sampleLoaded = action<Sample>('sampleLoaded')
           , editSampleField = action<string>('editSampleField')
           , saveChanges = action<{ sample: SampleDetails, oldSampleEdit: SampleDetailsEdit, newSampleEdit: SampleDetailsEdit, approvalInfo?: ApprovalInfo }, Promise<void>>('saveChanges')
           , readSample = action<{ originalSample: Sample, reading: SampleReading, skipPhotoApprovalInfo: ApprovalInfo, compromisedData: ChangeCompromisedData | undefined }, Promise<ApprovalInfo | undefined>>('readSample')
           , editBookedInSample = action<{ id: string, oldSample: Partial<BookedInSampleRequest>, newSample: Partial<BookedInSampleRequest> }, Promise<void>>('editBookedInSample')
           , loadSampleTrail = action<string, Promise<AuditTrail>>('loadSampleTrail')
           , sampleTrailLoaded = action<AuditTrail>('sampleTrailLoaded')
           , restoreSamples = action<void, Partial<Sample>>('restoreSamples')
           , loadSampleByBarcode = action<{ barcode: string, includeNullified: boolean, matchExactly: boolean }, Promise<Sample | void>>('loadSampleByBarcode')
           , loadSamplesByBarcode = action<{ barcode: string, includeNullified: boolean, matchExaclty: boolean }, Promise<SampleSearchByBarcodeResult>>('loadSamplesByBarcode')
           , getVacantExpectations = action<VacantExpectationQuery, Promise<VacantExpectation[]>>('getVacantExpectations')
           , exportSamples = action<SampleListQuery, Promise<Export>>('exportSamples')
           , loadSamplesExports = action<void, Promise<Export[]>>('loadSamplesExports')
           , exportLimitBreachesRelatedSampleData = action<ExportSampleListQuery, Promise<void>>('exportLimitBreachesRelatedSampleData')
           , exportOperatorLimitBreachesRelatedSampleData = action<ExportSampleListQuery, Promise<void>>('exportOperatorLimitBreachesRelatedSampleData')
           , exportLimitBreachTrendsRelatedSampleData = action<ExportSampleListQuery, Promise<void>>('exportLimitBreachTrendsRelatedSampleData')
           , exportLocationRelatedSampleData = action<ExportSampleListQuery, Promise<void>>('exportLocationRelatedSampleData')
           , exportOperatorRelatedSampleData = action<SampleListQuery, Promise<void>>('exportOperatorRelatedSampleData')
           , exportOrganismRelatedSampleData = action<SampleListQuery, Promise<void>>('exportOrganismRelatedSampleData')
           , exportOperatorFingerdabsSampleData = action<ExportSampleListQuery, Promise<void>>('exportOperatorFingerdabsSampleData')
           , createSampleSearchFilter = action<NewSampleSearchFilter, Promise<void>>('createSampleSearchFilter')
           , loadSampleSearchFilterList = action<void, Promise<void>>('loadSampleSearchFilterList')
           , sampleSearchFilterListLoaded = action<ExistedSampleSearchFilter[]>('sampleSearchFilterListLoaded')
           , removeSampleSearchFilter = action<string, Promise<void>>('removeSampleSearchFilter')
           , loadSampleComments = action<string, Promise<ExistedSampleComment[]>>('loadSampleComments')
           , sampleCommentsLoaded = action<ExistedSampleComment[]>('sampleCommentsLoaded')
           , addSampleComments = action<{ sampleId: string, sampleComments: NewSampleComment[] }, Promise<void>>('addSampleComments')
           , loadPrintSamples = action<SampleListQuery, Promise<Sample[]>>('loadPrintSamples')
           , importSamples = action<File[], Promise<void>>('importSamples')
           , importStatus = action<void, Promise<{inProgress: boolean}>>('importStatus')
           , bulkOperation = action<{operationId: BulkOperationId, sampleId: string[]}, Promise<void>>('bulkOperation')
           , confirmBookIn = action<string, Promise<void>>('confirmBookIn')
           , verifyCFUCount = action<{id: string, approvalInfo: ApprovalInfo}, Promise<void>>('verifyCFUCount')
           , changeCompromised = action<{id: string, compromised: boolean, checkBreach: boolean, approvalInfo: ApprovalInfo}, Promise<void>>('changeCompromised')

           , attachImages = action<{ sampleId: string, images: Guid[] }, Promise<void>>('attachImages')
           , checkOverridePhotoRequirementSignature = action<{ sampleId: string, photoSkipped: boolean, getReasonHandler: (reason?: string, error?: string) => Promise<ApprovalInfo> }, Promise<ApprovalInfo>>('checkOverridePhotoRequirementSignature')

           , uploadSampleFile = action<UploadData, UploadResult>('uploadSampleFile')
           , loadSampleFileList = action<string, Promise<AttachedFile[]>>('loadSampleFileList')
           , downloadFile = action<{ id: string, name: string }, Promise<void>>('downloadFile')
           , removeFile = action<string, Promise<void>>('removeFile')

           , loadListColumns = action<void, Promise<ColumnEdit>>('loadListColumns')
           , saveListColumns = action<ColumnEdit, Promise<void>>('saveListColumns')
           , getSampleEditedInfo = action<string, Promise<SampleEditedInfo[]>>('getSampleEditedInfo')
           , loadSampleTrends = action<SampleTrendsQuery, Promise<Page<SampleTrend>>>('loadSampleTrends')

           , searchBatchNumbers = action<SearchBatchNumbers, Promise<string[]>>('searchBatchNumbers')
