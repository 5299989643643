import { actionFactory } from '_/utils/redux'
import type {
    DashboardDailySamplesStatistic,
    DashboardStatisticsTotals,
    DashboardSampleStatisticRequest,
    DashboardBreachLimitsStatistic,
    DashboardLimitStatisticWidgetRequest,
} from '_/model/dashboard/types'

const action = actionFactory('app.dashboard')

export const loadSamplesDailyStatistic = action<DashboardSampleStatisticRequest, Promise<DashboardDailySamplesStatistic[]>>('loadSamplesDailyStatistic')
           , loadSamplesStatisticTotals = action<DashboardSampleStatisticRequest, Promise<DashboardStatisticsTotals>>('loadSamplesStatisticTotals')
           , loadBreachLimitStatistic = action<DashboardLimitStatisticWidgetRequest, Promise<DashboardBreachLimitsStatistic[]>>('loadBreachLimitStatistic')
           , breachLimitsLoaded = action<DashboardBreachLimitsStatistic[]>('breachLimitsLoaded')
