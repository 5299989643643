import Button from '_/components/button'
import { useTimeService } from '_/components/time'

import { ACTION_LIMIT_BREACHES } from '_/model/analysis/chart-type'
import { ACTION_LIMIT_BREACH_RATE } from '_/model/analysis/sub-chart-type'
import * as fieldIndex from '_/constants/custom-field-index'
import { CUSTOM } from '_/constants/date-ranges'
import { MONTH } from '_/model/analysis/aggregation-period'
import * as routes from '_/constants/routes'

import { useGrades } from '_/hooks/shared-hooks'

import type { LimitBreachInsight } from '_/model/insight/types'
import { paramsFilter } from '_/model/filters/helpers'

import InsightsWidget from './insights-widget'
import { useNavigateTo } from './helpers'

interface OwnProps {
    insight: LimitBreachInsight
    dismiss: () => void
}

export default function LimitBreachTrend(props: OwnProps) {
    const routeParams = useBuildAnalysisFilterRouterParams()
        , navigateTo = useNavigateTo(props.insight.id)
        , { lastMonthRate, currentMonthRate, operators, locations } = props.insight.data
        , gradeIds = useGrades().map(_ => _.id)

    const growthFactor = lastMonthRate > currentMonthRate ? 'decreased' : 'increased'

    return (
        <InsightsWidget
            dismiss={props.dismiss}
            header={
                `Did you know that the action limit breach rate has ${growthFactor} from ${lastMonthRate.toFixed(1)}% to ${currentMonthRate.toFixed(1)}% compared with last month?`
            }
        >
            <Button
                onClick={() => navigateTo(routes.ANALYSIS, paramsFilter({...routeParams, gradeIds, seriesFieldIndex: fieldIndex.EXPOSURE_LOCATION_GRADE_ID}))}
                className='btn-link text-start d-block p-0'
            >
                See how different grades contributed to the breaches
            </Button>
            <Button
                onClick={() => navigateTo(routes.ANALYSIS, paramsFilter({...routeParams, fields: [{index: fieldIndex.OPERATORS_IDS, value: operators}], seriesFieldIndex: fieldIndex.OPERATORS_IDS }))}
                className='btn-link text-start d-block p-0'
            >
                See a trend line for the three worst performing operators
            </Button>
            <Button
                onClick={() => navigateTo(routes.ANALYSIS, paramsFilter({...routeParams, fields: [{index: fieldIndex.EXPOSURE_LOCATION_ID, value: locations}], seriesFieldIndex: fieldIndex.EXPOSURE_LOCATION_ID}))}
                className='btn-link text-start d-block p-0'
            >
                See a trend line for the three worst locations
            </Button>
        </InsightsWidget>
    )
}

function useBuildAnalysisFilterRouterParams() {
    const timeService = useTimeService()
        , now = timeService.now()
        , { day } = timeService.ctzTimeStruct(now)
        , firstDayOfCurrentMonth = timeService.addCtzDays(now, -(day - 1))
        , exposureStartDateTo = timeService.ctzDayEnd(timeService.addCtzDays(firstDayOfCurrentMonth, -1))
        , exposureStartDateFrom = timeService.ctzDayStart(timeService.addCtzMonths(firstDayOfCurrentMonth, -2))

    return {
        chartType: ACTION_LIMIT_BREACHES,
        subChartType: ACTION_LIMIT_BREACH_RATE,
        exposureDateRange: CUSTOM,
        exposureStartDateFrom,
        exposureStartDateTo,
        aggregationPeriod: MONTH,
        includeCompromised: false,
    }
}
