import { useAction } from '_/facade/react'

import { useTimeService } from '_/components/time'
import { LinkButton } from '_/components/link'
import { Table } from '_/components/table'
import Button from '_/components/button'

import type { DashboardBreachLimitsStatistic } from '_/model/dashboard/types'
import type Permissions from '_/model/permissions/type'

import * as routes from '_/constants/routes'
import LIMIT_BREACH_DATE_RANGE from '_/constants/limit-breach-report'
import * as breachTypes from '_/constants/sample-breach-type'
import { SAMPLE_BREACH_DATE } from '_/constants/search-date-type'
import * as columnTypes from '_/constants/limit-breach-column-type'
import { COMPROMISED } from '_/constants/sample-flag'

import { generatePastDate } from './helpers'

import { navigateTo as navigateToAction } from '_/features/routing/actions'
import { paramsFilter } from '_/model/filters/helpers'

interface Props {
    limitsStatistic: DashboardBreachLimitsStatistic[]
    permissions: Permissions
}

function Limits(props: Props) {
    const timeService = useTimeService()
        , navigateTo = useAction(navigateToAction)

    function handleClick(daysPast: number, breachType: breachTypes.BreachTypeWithCompromised, includeCompromised: boolean) {
        const dateFrom = generatePastDate(timeService, daysPast, timeService.now())
            , sampleBreachTypes = breachType === breachTypes.COMPROMISED ? undefined : [breachType]
            , routeParams = {
                dateFrom,
                dateTo: timeService.now(),
                sampleBreachTypes,
                dateToFilter: SAMPLE_BREACH_DATE,
                includeCompromised,
                flag: includeCompromised ? COMPROMISED : undefined,
            }

        navigateTo(routes.SAMPLES, paramsFilter(routeParams))
    }

    function calculateLimits(daysPast: number, breachType: breachTypes.BreachTypeWithCompromised, columnType: number) {
        const currentLimits = props.limitsStatistic.find(_ => _.columnType === columnType)
            , isActionLimit = breachType === breachTypes.ACTION_LIMIT
            , isAlertLimit = breachType === breachTypes.ALERT_LIMIT

        if (!currentLimits)
            return ''

        const withReferenceNumberCount = isActionLimit
                ? getCountWithRef(currentLimits.actionLimitWithReferenceCount)
                : isAlertLimit
                    ? getCountWithRef(currentLimits.alertLimitWithReferenceCount)
                    : getCountWithRef(currentLimits.compromisedWithReferenceCount)
            , includeCompromised = !isActionLimit && !isAlertLimit

        return <div>
            <Button
                onClick={() => handleClick(daysPast, breachType, includeCompromised)}
                className='btn-link fw-bold p-0 pb-1'
                testId={`limits-${columnType}-period-${breachType}-breach`}
            >
                {isActionLimit
                    ? currentLimits.actionLimitWithoutReferenceCount + currentLimits.actionLimitWithReferenceCount
                    : isAlertLimit
                        ? currentLimits.alertLimitWithoutReferenceCount + currentLimits.alertLimitWithReferenceCount
                        : currentLimits.compromisedWithoutReferenceCount + currentLimits.compromisedWithReferenceCount
                }
            </Button>
            {withReferenceNumberCount}
        </div >
    }

    function getCountWithRef(count: number) {
        if (count > 0)
            return `(${count} with ref #)`

        return ''
    }

    return (
        <div className='text-center'>
            <Table className='text-start'>
                <thead>
                    <tr>
                        <th className='border-top-0' data-testid='limit-breaches'>Limit breaches</th>
                        <th className='border-top-0'>Last 24 hours</th>
                        <th className='border-top-0'>Past 7 days</th>
                        <th className='border-top-0'>Past 28 days</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Action</td>
                        <td>{calculateLimits(1, breachTypes.ACTION_LIMIT, columnTypes.DAY)}</td>
                        <td>{calculateLimits(7, breachTypes.ACTION_LIMIT, columnTypes.WEEK)}</td>
                        <td>{calculateLimits(LIMIT_BREACH_DATE_RANGE, breachTypes.ACTION_LIMIT, columnTypes.MONTH)}</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Alert</td>
                        <td>{calculateLimits(1, breachTypes.ALERT_LIMIT, columnTypes.DAY)}</td>
                        <td>{calculateLimits(7, breachTypes.ALERT_LIMIT, columnTypes.WEEK)}</td>
                        <td>{calculateLimits(LIMIT_BREACH_DATE_RANGE, breachTypes.ALERT_LIMIT, columnTypes.MONTH)}</td>
                    </tr>
                    <tr className='border-bottom'>
                        <td>Compromised</td>
                        <td>{calculateLimits(1, breachTypes.COMPROMISED, columnTypes.DAY)}</td>
                        <td>{calculateLimits(7, breachTypes.COMPROMISED, columnTypes.WEEK)}</td>
                        <td>{calculateLimits(LIMIT_BREACH_DATE_RANGE, breachTypes.COMPROMISED, columnTypes.MONTH)}</td>
                    </tr>
                </tbody>
            </Table>
            <LinkButton
                className='btn-primary'
                routeName={routes.LIMIT_BREACH_REPORT}
                hasNoPermissions={!props.permissions.readReports}
                testId='view-limit-breach-timeline'
            >
                View limit breach timeline
            </LinkButton>

        </div>
    )
}

export default Limits
