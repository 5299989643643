import type RoleService from '_/services/role-service'

import * as actions from './actions'

import { updateTransaction } from '_/model/roles/transaction'

import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'

const factory = (service: RoleService): EffectsFactory => (_dispatch) => [
    handler(actions.loadContextRoles, service.getContextRoles),
    handler(actions.loadRole, service.get),
    handler(actions.loadRoleTrail, service.getTrail),
    handler(actions.createRole, _ => service.createInContext(_.contextId, _.role)),
    handler(actions.removeRole, service.remove),
    handler(actions.saveRole, payload =>
        updateTransaction(payload.id, payload.oldRole, payload.newRole, service)
    ),
]

export default factory
