const STRING = 0
    , GUID = 1
    , TIME = 2
    , NUMBER = 3

type STRING = typeof STRING
type GUID = typeof GUID
type TIME = typeof TIME
type NUMBER = typeof NUMBER

export {
    STRING,
    GUID,
    TIME,
    NUMBER,
}
