import type { Reducer } from 'redux'
import { reducer, handler } from '_/utils/redux/reducer'
import type { ActionHandler } from '_/utils/redux//types'
import * as authActions from '_/features/auth/actions'
import { mergeAndReplaceEntity, mergeAndReplaceEntityById } from '_/utils/array'
import { shallowUpdate } from '_/utils/object'

function commonReducer<S>(defaultState: S, ...payloadHandlers: ActionHandler<S, any, any>[]): Reducer<S> {
    return reducer(
        defaultState,
        handler(authActions.loggedOut, _ => defaultState),
        handler(authActions.contextChanged, _ => defaultState),
        ...payloadHandlers
    )
}

export {
    reducer,
    commonReducer,
    handler,
    mergeAndReplaceEntity,
    mergeAndReplaceEntityById,
    shallowUpdate,
}
