const NONE = 0
    , ALERT_LIMIT = 1
    , ACTION_LIMIT = 2
    , COMPROMISED = 3

type BreachTypeWithCompromised = never
    | typeof NONE
    | typeof ACTION_LIMIT
    | typeof ALERT_LIMIT
    | typeof COMPROMISED

type BreachType = never
    | typeof NONE
    | typeof ACTION_LIMIT
    | typeof ALERT_LIMIT

const SAMPLE_BREACH_TYPE: {id: BreachType, name: string}[] = [
    {
        id: ALERT_LIMIT,
        name: 'Alert limit breach',
    },
    {
        id: ACTION_LIMIT,
        name: 'Action limit breach',
    },
]

const BREACH_TYPE_WITH_COMPROMISED: {id: BreachTypeWithCompromised, name: string}[] = [...SAMPLE_BREACH_TYPE, { id: COMPROMISED, name: 'Compromised' }]

type SAMPLE_BREACH_TYPE = typeof SAMPLE_BREACH_TYPE

export {
    SAMPLE_BREACH_TYPE,
    NONE,
    ACTION_LIMIT,
    ALERT_LIMIT,
    COMPROMISED,
    BreachTypeWithCompromised,
    BreachType,
    BREACH_TYPE_WITH_COMPROMISED,
}
