import * as routes from '_/constants/routes'
import * as toastActions from '_/features/toasts/actions'
import * as authActions from '_/features/auth/actions'
import { getDefaultRoute } from '_/features/routing/helpers'
import { canAccessState } from '../helpers'
import type TransitionInterceptor from './transition-interceptor'

const permissionInterceptor: TransitionInterceptor = (_, store, toState) => {
    const permissions = store.getState().auth.permissions
        , bypass = canAccessState(toState.name as routes.RouteName, permissions)

    if (bypass)
        return true

    const targetState = getDefaultRoute(permissions)

    if (targetState === routes.LOG_IN)
        store.dispatch(authActions.unauthenticatedPageRequest(toState))
    else
        store.dispatch(toastActions.addError('You do not have permission to view requested page'))

    return Promise.reject({
        redirect: { name: targetState },
    })
}

export default permissionInterceptor
