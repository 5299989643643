import { Field } from 'react-final-form'
import { showFieldError } from './helpers'
import { classnames } from '_/facade/react'

type InputType = 'text' | 'password'

interface InputFieldProps {
    id?: string
    name: string
    type: InputType
    className?: string
    placeholder?: string
    disabled?: boolean
    inputRef?: React.RefObject<HTMLInputElement>
    testId?: string
}

interface NewInputProps {
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

const InputField: React.FC<InputFieldProps & NewInputProps> = props =>
    <Field name={props.name} type={props.type} render={
        _ =>
            <div className={`mb-3 ${props.className ?? ''}`}>
                <label htmlFor={props.id} className='col-form-label'>{props.children}</label>
                <input
                    id={props.id}
                    ref={props.inputRef}
                    type={props.type}
                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                    autoComplete='off'
                    {..._.input}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    onKeyDown={props.onKeyDown}
                    data-testid={props.testId}
                />
                {showFieldError(_.meta) && <span className='invalid-feedback' data-testid='validation-error'>{_.meta.error || _.meta.submitError}</span>}
            </div>
    }/>

type ConcreteFieldProps = Pick<InputFieldProps, 'id' | 'name' | 'className' | 'placeholder' | 'disabled' | 'inputRef' | 'testId'>

const PasswordField: React.FC<ConcreteFieldProps> = props =>
    <InputField
        id={props.id}
        name={props.name}
        type='password'
        placeholder={props.placeholder}
        children={props.children}
        disabled={props.disabled}
        testId={props.testId}
    />

export { PasswordField }
