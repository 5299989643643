import * as d3 from 'd3-shape'
import type { DayScheduleStatistic } from '_/model/scheduling/day-scheduler/types'
import { calculateScheduledBookedInSamplesCount } from '_/model/scheduling/monthly-scheduler/helpers'

const RADIUS = 50

interface Props {
    stats: DayScheduleStatistic
}

function Donut(props: Props) {
    const arcs = calcArcs(props.stats)

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            viewBox={`0 0 ${RADIUS * 2} ${RADIUS * 2}`}
        >
            <g transform={`translate(${RADIUS} ${RADIUS})`}>
                <path d={arcs.bookedIn} className='day-plan-donut__booked-in-arc' />
                <path d={arcs.notInUse} className='day-plan-donut__not-in-use-arc' />
                <path d={arcs.missed} className='day-plan-donut__missed-arc' />
                <path d={arcs.remaining} className='day-plan-donut__remaining-arc' />
            </g>
        </svg>
    )
}

export default Donut

interface Arcs {
    bookedIn: string
    notInUse: string
    missed: string
    remaining: string
}

function calcArcs(stats: DayScheduleStatistic): Arcs {
    const { notInUseSamplesCount, remainingSamplesCount, missedSamplesCount } = stats
        , bookedInSamplesCount = calculateScheduledBookedInSamplesCount(stats)
        , total = bookedInSamplesCount + notInUseSamplesCount + remainingSamplesCount + missedSamplesCount
        , completed = remainingSamplesCount === 0

    const pie = d3.pie().sort(null)
        , arcs = pie([
            bookedInSamplesCount,
            // draw not in use circle when nothing was scheduled
            total === 0 ? 1 : notInUseSamplesCount,
            missedSamplesCount,
            remainingSamplesCount,
        ])

    return {
        bookedIn: calcArc(arcs[0], completed),
        notInUse: calcArc(arcs[1], completed),
        missed: calcArc(arcs[2], completed),
        remaining: calcArc(arcs[3], completed),
    }
}

function calcArc(datum: d3.PieArcDatum<any>, completed: boolean): string {
    const arc = d3.arc()

    return arc({
        ...datum,
        outerRadius: RADIUS,
        innerRadius: completed ? 0 : RADIUS / 2,
        padAngle: completed ? 0 : Math.PI / 90,
    })!
}
