import type { FloorPlanCommonFormFields } from './floor-plan'
import type FloorPlan from './floor-plan'
import type { ValidationResult} from '_/utils/form/validate'
import { isEmpty, maxLength, allowedName } from '_/utils/form/validate'
import { searchDuplicateName } from '_/model/common/helpers'

export default function validate(value: FloorPlanCommonFormFields, plans: FloorPlan[], id?: string) {
    const name = isEmpty('Name')(value.name)
        , notAllowedNames = allowedName('Name')(value.name)
        , nameLength = maxLength('Name', 100)(value.name)
        , duplicateName = value.name && searchDuplicateName({...value, id}, plans)
        , descriptionLength = maxLength('Description', 250)(value.description)
        , result: ValidationResult<FloorPlanCommonFormFields> = {}

    if (name)
        result.name = name

    if (notAllowedNames)
        result.name = notAllowedNames

    if (nameLength)
        result.name = nameLength

    if (duplicateName)
        result.name = `Floor plan with name '${value.name!.trim()}' already exists`

    if (descriptionLength)
        result.description = descriptionLength

    return result
}
