import type { AnalysisFilter } from '_/model/analysis/filter/types'
import * as sh from '_/features/samples/helpers'
import type TimeService from '_/services/time-service'
import * as h from '_/features/analysis/ui/helpers'
import CHART_TYPE from '../chart-type'
import * as ct from '../chart-type'
import * as fieldIndex from '_/constants/custom-field-index'
import type { FilterFieldValue } from '_/model/sample/search'
import * as fh from '_/features/analysis/filter/helpers'
import { getAllFields } from '_/utils/object'
import { areArraysEqual } from '_/utils/array'
import { validateParticleCountsDateRange } from '_/features/analysis/validate'
import { WEEK } from '_/constants/date-ranges'

interface PathValue {
    path: string
    value: any
}

function pathValue(path: string, value: any): PathValue {
    return { path, value }
}

function getPathsValues<T extends object>(obj: T) {
    return getAllFields(obj).map(_ => pathValue(String(_), obj[_]))
}

function getChartTypeChangeUpdates(filter: AnalysisFilter, prevFilter: AnalysisFilter, exposureDateRangeModified: boolean, timeService: TimeService): Partial<AnalysisFilter> {
    const chartTypeChanged = prevFilter.chartType !== undefined && prevFilter.chartType !== filter.chartType
        , subChartTypeChanged = prevFilter.subChartType !== undefined && prevFilter.subChartType !== filter.subChartType

    const chartType = CHART_TYPE.find(type => type.id === filter.chartType)?.id
    if (chartType === undefined)
        return {}

    if (!(chartTypeChanged || subChartTypeChanged))
        return {}

    const exposureDateRange = exposureDateRangeModified && !shouldUpdateDateRange(filter, timeService)
            ? {}
            : h.computeExposureDateRange(timeService, chartType)
        , subChartType = h.isChartTypeWithSubCharts(chartType)
            ? h.computeSubChartType(chartType, filter.subChartType)
            : undefined
        , seriesFieldIndex = prevFilter.chartType !== ct.PARTICLE_COUNTS && filter.chartType === ct.PARTICLE_COUNTS && fh.allParticleStatesAreSelected(filter.particleStates)
            || prevFilter.chartType === ct.PARTICLE_COUNTS && filter.chartType !== ct.PARTICLE_COUNTS && prevFilter.seriesFieldIndex === fieldIndex.PARTICLES_STATE
                ? h.computeSeriesFieldIndex(filter.chartType!)
                : filter.seriesFieldIndex
        , includeContaminationRates = chartType === ct.STACKED_SAMPLE_NAME ? false : undefined
        , includeTotalSamples = filter.includeTotalSamples && !fh.totalSamplesBarAvailable(chartType) ? false : filter.includeTotalSamples
        , clearIdentification = chartType === ct.ORGANISM_TYPE_BREAKDOWN
            ? {
                organismIds: [],
                catalaseIds: [],
                oxidaseIds: [],
                oxidationFermentationIds: [],
                coagulaseIds: [],
            }
            : {}
        , clearSampleName = chartType === ct.STACKED_SAMPLE_NAME
            ? {
                fields: filter.fields?.map<FilterFieldValue>(_ => _.index !== fieldIndex.SAMPLE_TYPE_ID ? _ : { index: fieldIndex.SAMPLE_TYPE_ID, value: undefined, notRecorded: undefined })
            }
            : {}

    return {
        subChartType,
        seriesFieldIndex,
        includeContaminationRates,
        includeTotalSamples,
        ...exposureDateRange,
        ...clearIdentification,
        ...clearSampleName,
        joinPoints: true,
    }
}

function shouldUpdateDateRange(filter: AnalysisFilter, timeService: TimeService) {
    return validateParticleCountsDateRange(filter, timeService)
        || filter.chartType !== ct.PARTICLE_COUNTS && filter.exposureDateRange === WEEK
}

function getParticleStateChangeUpdates(filter: AnalysisFilter, prevFilter: AnalysisFilter) {
    if (prevFilter.chartType !== ct.PARTICLE_COUNTS || filter.chartType !== ct.PARTICLE_COUNTS
            || areArraysEqual(filter.particleStates, prevFilter.particleStates))
        return {}

    if (prevFilter.seriesFieldIndex === fieldIndex.PARTICLES_STATE && !fh.allParticleStatesAreSelected(filter.particleStates)) {
        return {seriesFieldIndex: undefined}
    }

    if (fh.allParticleStatesAreSelected(filter.particleStates)) {
        return {seriesFieldIndex: fieldIndex.PARTICLES_STATE}
    }

    return {}
}

function getNormalizationData(filter: AnalysisFilter, prevFilter: AnalysisFilter, exposureDateRangeModified: boolean, timeService: TimeService) {
    const notRecordedValuesToClearPaths = sh.findNotRecordedFilledValues(filter.fields ?? [])
            .map(_ => pathValue(sh.valuePath(_), undefined))
        , chartTypeChangeUpdates = getChartTypeChangeUpdates(filter, prevFilter, exposureDateRangeModified, timeService)
        , chartTypeChangePaths = getPathsValues(chartTypeChangeUpdates)

        , particleStateChangeUpdates = getParticleStateChangeUpdates(filter, prevFilter)
        , particleStateChangePaths = getPathsValues(particleStateChangeUpdates)

    const paths = notRecordedValuesToClearPaths.concat(chartTypeChangePaths, particleStateChangePaths)

    return {
        paths,
    }
}

export default getNormalizationData
