import type { ServiceStatus as State } from '_/model/app-state'
import { commonReducer, handler, shallowUpdate } from '_/facade/reducer'

import * as actions from './actions'

const defaultState: State = {
    backendStatus: {
        serviceAvailable: true,
        progressInfo: undefined,
    },
}

export default commonReducer(
    defaultState,

    handler(actions.statusChecked, (state, backendStatus) =>
        shallowUpdate(state, { backendStatus })
    ),
)
