import { classnames } from '_/facade/react'
import { dropFields } from '_/utils/object'
import type { DashboardDailySamplesStatistic } from '_/model/dashboard/types'
import * as sampleStatuses from '_/constants/sample-status'

import { calculateHeight, calculateMaxTotal, generatePastDate, sampleListRouteParams } from '../helpers'
import DataLegend from './data-legend'
import { DASHBOARD_DATE_RANGE } from '_/constants/dashboard'
import Key from '_/features/key/key'
import { useTimeService } from '_/components/time'
import Link from '_/components/link'
import { SAMPLES } from '_/constants/routes'
import { paramsFilter } from '_/model/filters/helpers'
import { equals } from '_/model/date-time'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    stats: DashboardDailySamplesStatistic[]
}

function Graph(props: Props) {
    const timeService = useTimeService()
        , calcHeight = calculateHeight(props.stats)
        , maxTotal = calculateMaxTotal(props.stats)
        , startDay = timeService.formatCtzDate(generatePastDate(timeService, DASHBOARD_DATE_RANGE - 1))
        , endDay = timeService.formatCtzDate(timeService.now())

    function barRouteParams(status: sampleStatuses.SampleStatus, stat: DashboardDailySamplesStatistic) {
        return sampleListRouteParams(status, stat.exposureDate, timeService)
    }

    return (
        <div className='flex-fill'>
            <div className='dashboard-graph--header'>
                <div className='dashboard-graph--header-text me-auto d-flex' data-testid='recent-exposure'>
                    Recent exposure
                    <Key>
                        <div className='dashboard-graph--legend dashboard-graph--nogrowth-identified me-2' />
                        <span>No growth</span> <br/>
                        <div className='dashboard-graph--legend dashboard-graph--growth-identified me-2'/>
                        <span>Growths (ID complete)</span><br/>
                        <div className='dashboard-graph--legend dashboard-graph--awaiting-identification me-2'/>
                        <span>Growths (awaiting identification)</span><br/>
                        <div className='dashboard-graph--legend dashboard-graph--booked-in me-2'/>
                        <span>Booked in</span><br/>
                    </Key>
                </div>
            </div>
            <div className='dashboard-graph--header-date-range me-auto'>{startDay} to {endDay}</div>
            <div className={classnames('dashboard-graph d-flex justify-content-around me-auto', props.className)} {...dropFields(props, 'stats', 'className')}>
            <div className='dashboard-graph--scales dashboard-graph--scales-text justify-content-end align-items-center'>
                <div>{maxTotal}</div>
                <div className='dashboard-graph--scales'>{Math.round((maxTotal * 10) / 2) / 10}</div>
                <div>0</div>
            </div>
                {props.stats.map(stat =>
                    <div key={stat.exposureDate} className='d-flex flex-column justify-content-end align-items-center'>
                        <DataLegend dailyStats={stat} maxTotal={maxTotal}>
                            <div className='dashboard-graph--column flex-fill d-flex flex-column justify-content-end' data-testid='dashboard-graph-column'>
                                {stat.noGrowthSamplesCount > 0
                                    && <Link
                                        routeName={SAMPLES}
                                        routeParams={paramsFilter(barRouteParams(sampleStatuses.NO_GROWTH, stat))}
                                        className='dashboard-graph--nogrowth-identified'
                                        style={calcHeight(stat.noGrowthSamplesCount)}
                                    />
                                }
                                {stat.growthsIdentifiedSamplesCount > 0
                                    && <Link
                                        routeName={SAMPLES}
                                        routeParams={paramsFilter(barRouteParams(sampleStatuses.GROWTHS_ID_COMPLETE, stat))}
                                        className='dashboard-graph--growth-identified'
                                        style={calcHeight(stat.growthsIdentifiedSamplesCount)}
                                    />
                                }
                                {stat.awaitingIdentificationSamplesCount > 0
                                    && <Link
                                        routeName={SAMPLES}
                                        routeParams={paramsFilter(barRouteParams(sampleStatuses.GROWTHS_AWAITING_IDENTIFICATION, stat))}
                                        className='dashboard-graph--awaiting-identification'
                                        style={calcHeight(stat.awaitingIdentificationSamplesCount)}
                                    />
                                }
                                {stat.bookedInSamplesCount > 0
                                    && <Link
                                        routeName={SAMPLES}
                                        routeParams={paramsFilter(barRouteParams(sampleStatuses.BOOKED_IN, stat))}
                                        className='dashboard-graph--booked-in'
                                        style={calcHeight(stat.bookedInSamplesCount)}
                                    />
                                }
                            </div>
                        </DataLegend>
                        {!equals(timeService.ctzDayStart(stat.exposureDate), timeService.ctzDayStart(timeService.now()))
                            ? <div className='dashboard-graph--date-text'>{timeService.formatCtzShortWeek(stat.exposureDate)}</div>
                            : <div className='dashboard-graph--date-text-today'>Today</div>}
                    </div>
                )}

            </div>
        </div>
    )
}

export default Graph
