import { classnames, useSelector, useAction, useState, useEffect, useCallback } from '_/facade/react'

import { EmptyTableMessage, Table } from '_/components/table'
import { useTimeService } from '_/components/time'
import PageHeader from '_/components/page-header'
import { LinkButton } from '_/components/link'
import Button from '_/components/button'
import CustomReportsTrailsModal from '_/components/audit-trail-modal'
import { useContextSwitchObserver } from '_/components/context-observer'

import * as routes from '_/constants/routes'
import AUTOMATED_ACTION_USER_ID from '_/constants/users'
import * as deletionActions from '_/features/confirmation/actions'
import * as actions from './actions'

import type CustomReport from '_/model/analysis/custom-report/custom-report'

import * as t from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'

function CustomReports() {
    const timeService = useTimeService()
        , [trailReportId, setTrailReportId] = useState<string | undefined>()
        , permissions = useSelector(_ => _.auth.permissions)
        , [customReports, reload, showSpinner] = useCustomReports()
        , handleDeleteCustomReport = useRemoveCustomReport(reload)

    return (
        <div className='container-fluid h-100'>
            <div className='row justify-content-center h-100'>
                <div className='d-flex flex-column col-9 h-100'>
                    {trailReportId &&
                        <CustomReportsTrailsModal
                            id={trailReportId}
                            onClose={() => setTrailReportId(undefined)}
                            loadAuditTrailAction={actions.loadCustomReportTrail}
                        />
                    }
                    <PageHeader title='Custom reports' sticky>
                        <LinkButton
                            routeName={routes.CUSTOM_REPORTS_CREATE}
                            className='btn-primary'
                            hasNoPermissions={!permissions.editCustomReports}
                            testId='create-new-report'
                        >
                            Create a new report
                        </LinkButton>
                    </PageHeader>

                    <div className='overflow-auto flex-fill'>
                        <Table>
                            <thead className='thead table-header--sticky'>
                                <tr>
                                    <th>Report</th>
                                    <th>Graphs</th>
                                    <th>Report template last modified</th>
                                    <th/>
                                </tr>
                            </thead>
                            <tbody>
                                {showSpinner
                                    ? <tr>
                                        <td colSpan={4}>
                                            <div className='position-relative py-3'>
                                                <i className='preview-spinner material-icons md-48'>sync</i>
                                            </div>
                                        </td>
                                    </tr>
                                    : customReports.length === 0
                                        ? <EmptyTableMessage colSpan={4} message='There are no custom reports at the moment' />
                                        : customReports.map((_, index) =>
                                            <tr key={_.id}>
                                                <td data-testid={`report-name-${index}`}>{_.name}</td>
                                                <td>{_.graphs.length}</td>
                                                <td>
                                                    {timeService.formatCtzDateTime(_.lastModifiedAt)}{' '}
                                                    (<FormattedText text={[_.lastModifiedBy.id === AUTOMATED_ACTION_USER_ID ? t.systemTextNode(_.lastModifiedBy.name) : t.defaultTextNode(_.lastModifiedBy.name)]} />)
                                                </td>
                                                <td className='text-end'>
                                                    <Button
                                                        onClick={() => setTrailReportId(_.id)}
                                                        className='btn-link align-baseline p-0 me-4'
                                                        testId={`audit-trail-report-${index}`}
                                                    >
                                                        Audit trail
                                                    </Button>
                                                    <Button
                                                        className={classnames('btn-link align-baseline p-0 me-4', { disabled: !permissions.editCustomReports })}
                                                        onClick={() => handleDeleteCustomReport(_.id, _.name)}
                                                        hasNoPermissions={!permissions.editCustomReports}
                                                        testId={`delete-report-${index}`}
                                                    >
                                                        Delete
                                                    </Button>
                                                    <LinkButton
                                                        className='btn-link align-baseline p-0 me-4'
                                                        routeName={routes.CUSTOM_REPORTS_EDIT}
                                                        hasNoPermissions={!permissions.editCustomReports}
                                                        routeParams={{ id: _.id }}
                                                        testId={`edit-report-${index}`}
                                                    >
                                                        Edit
                                                    </LinkButton>
                                                    <LinkButton
                                                        className='btn-link align-baseline p-0'
                                                        routeName={routes.CUSTOM_REPORTS_VIEW}
                                                        hasNoPermissions={!permissions.readCustomReports}
                                                        routeParams={{ id: _.id }}
                                                        testId={`view-report-${index}`}
                                                    >
                                                        View report
                                                    </LinkButton>
                                                </td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomReports

function useCustomReports() {
    const loadCustomReports = useAction(actions.loadCustomReports)
        , contextSwitch = useContextSwitchObserver()
        , [customReports, setCustomReports] = useState<CustomReport[]>([])
        , [showSpinner, setShowSpinner] = useState(false)
        , load = useCallback(
            () => {
                setShowSpinner(true)
                loadCustomReports()
                    .then(setCustomReports)
                    .finally(() => setShowSpinner(false))
            },
            [loadCustomReports]
        )
    useEffect(load, [contextSwitch, load])
    return [customReports, load, showSpinner] as const
}

function useRemoveCustomReport(reload: () => void) {
    const removeCustomReport = useAction(actions.removeCustomReport)
        , showDeletionWarning = useAction(deletionActions.showDeletionConfirmationModal)

    function handleRemove(id: string, name: string) {
        showDeletionWarning(`Are you sure you want to delete report ${name}?`)
            .then(() => removeCustomReport(id))
            .then(reload)
    }

    return handleRemove
}
