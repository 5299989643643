import type Confirmation from '_/model/confirmation/types'
import * as confirmationType from '_/constants/confirmation-type'

function getDuplicatedBarcodeConfirmationInfo(): Confirmation {
    return {
        message: 'Warning, this barcode relates to a non-viable sample that has already been booked in. Would you like to view the sample?',
        type: confirmationType.CONFIRMATION_MODAL,
    }
}

export {
    getDuplicatedBarcodeConfirmationInfo,
}
