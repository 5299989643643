
import { actionFactory } from '_/utils/redux'
import type AnalysisQuery from '_/model/analysis/filter/types'
import type { NewAnalysisSearchFilter, AnalysisSearchFilter } from '_/model/analysis/filter/types'
import type {
    OrganismsBreakdownSeriesGraphData,
    ContaminationFloorPlanSeriesGraphData,
    LimitBreachFloorPlanSeriesGraphData,
} from '_/model/analysis/types'
import type { DateSeriesGraphData } from '_/model/analysis/date-series'

const action = actionFactory('app.analysis')

export const loadLinesMarkersChartSeries = action<AnalysisQuery, Promise<DateSeriesGraphData>>('loadLinesMarkersChartSeries')
           , loadOrganismsBreakdown = action<AnalysisQuery, Promise<OrganismsBreakdownSeriesGraphData>>('loadOrganismsBreakdown')
           , loadOrganismTypeBreakdown = action<AnalysisQuery, Promise<DateSeriesGraphData>>('loadOrganismTypeBreakdown')
           , loadStackedSampleName = action<AnalysisQuery, Promise<DateSeriesGraphData>>('loadStackedSampleName')
           , createAnalysisSearchFilter = action<NewAnalysisSearchFilter, Promise<void>>('createAnalysisSearchFilter')
           , loadAnalysisSearchFilterList = action<void, Promise<void>>('loadAnalysisSearchFilterList')
           , analysisSearchFilterListLoaded = action<AnalysisSearchFilter[]>('analysisSearchFilterListLoaded')
           , removeAnalysisSearchFilter = action<string, Promise<void>>('removeAnalysisSearchFilter')
           , loadContaminationFloorPlan = action<AnalysisQuery, Promise<ContaminationFloorPlanSeriesGraphData>>('loadContaminationFloorPlan')
           , loadLimitBreachFloorPlan = action<AnalysisQuery, Promise<LimitBreachFloorPlanSeriesGraphData>>('loadLimitBreachFloorPlan')
           , exportGraphCsv = action<AnalysisQuery, Promise<{blob: Blob, filename: string}>>('exportGraphTableData')
           , exportAnalysisGraphData = action<{blob: Blob, filename: string}[], Promise<void>>('exportAnalysisGraphData')
