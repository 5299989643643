import { Table } from '_/components/table'
import FormattedText from '_/features/text/formatted-text'
import type ContaminationFloorPlanSeries from '_/model/floor-plan/contamination-floor-plan-series'
import type { FloorPlanLocation } from '_/model/floor-plan/floor-plan'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import { formatActiveState } from '_/utils/format/common'
import { filteredLocationsExistsOnFloorPlan } from '../helpers'

interface Props {
    floorPlanLocations: FloorPlanLocation[]
    series: ContaminationFloorPlanSeries[]
    locations: ListExposureLocation[]
    selectedLocations: string[]
}

function ContaminationFloorPlanTabularView(props: Props) {
    const renderedLocation = props.locations.filter(_ => props.floorPlanLocations.some(fl => fl.locationId === _.id))

    return (
        <Table>
            <thead className='text-center'>
                <tr>
                    <th>Location</th>
                    <th>Total CFU</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {renderedLocation
                    .filter(_ => props.selectedLocations.length === 0 ? true : filteredLocationsExistsOnFloorPlan(props.selectedLocations, [{locationId: _.id} as FloorPlanLocation], props.locations))
                    .map((l, i) => {
                        const series = props.series.find(_ => _.locationId === l.id)

                        return <tr key={i}>
                            <td><FormattedText text={formatActiveState(l.pathName, l.isActive)} /></td>
                            <td>{!series?.count ? '' : series.count}</td>
                        </tr>
                    })
                }
            </tbody>
        </Table>
    )
}

export default ContaminationFloorPlanTabularView
