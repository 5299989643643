import { diffObject, dropFields } from '_/utils/object'
import type Api from '_/services/predefined-lists-service'
import type { CustomFieldEdit, CustomFieldExternal } from '_/model/predefined-lists/custom-field/types'

function updateTransaction(
    id: string,
    current: CustomFieldEdit,
    next: CustomFieldEdit,
    api: Api,
): Promise<void> {
    const diff = diffObject(current, next)

    if (!diff)
        return Promise.resolve()

    let result: Partial<CustomFieldExternal> = diff

    if (diff.viableSettings) {
        if (diff.viableSettings.isActive !== undefined)
            result.viableIsActive = next.viableSettings.isActive
        result.viableSettings = dropFields(next.viableSettings, 'isActive')
    }

    if (diff.nonViableSettings) {
        if (diff.nonViableSettings.isActive !== undefined)
            result.nonViableIsActive = next.nonViableSettings.isActive
        result.nonViableSettings = dropFields(next.nonViableSettings, 'isActive')
    }

    return api.customField.save(id, result)
}

export {
    updateTransaction,
}
