import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type MissedMonitoringReportService from '_/services/missed-monitoring-report-service'

import * as actions from './actions'

const factory = (service: MissedMonitoringReportService): EffectsFactory => () => [
    handler(actions.loadMissedMonitoringReportData, service.getMissedMonitoringReportData),
    handler(actions.exportMissedMonitoringTable, payload => {
        const query = { ...payload, start: 0, count: 1000, format: 'csv' }

        return service.downloadMissedMonitoringTable(query)
    }),
]

export default factory
