import classnames from 'classnames'

interface Props {
    children: React.ReactNode
    className?: string
}

const FilterActions = (props: Props) =>
        <div className={classnames('py-2 px-3', props.className)}>
            {props.children}
        </div>

export default FilterActions
