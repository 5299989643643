import { useState, useSelector } from '_/facade/react'

import { GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION } from '_/constants/sample-status'

import type { OrganismGraphSeries } from '_/model/reports/types'
import { computeAuthorInfo } from '_/features/analysis/ui/helpers'

import { useTimeService } from '_/components/time'
import { noop } from '_/utils/function'

import ActionButtons from '_/features/reports/table-action-buttons'
import { useScrollTo } from '_/features/reports/hooks'
import PieChart from './pie-chart'
import type SampleSearchFilter from '_/model/sample/search'
import NoDataAvailable from '../../no-data-available'

interface Props {
    routeParams: SampleSearchFilter
    series: OrganismGraphSeries[]
    locationName: string
    printMode: boolean
    footer: string[]
    showSpinner: boolean
    testId?: string
}

function OrganismGraph(props: Props) {
    const timeService = useTimeService()
        , [divElement, setDivElement] = useState<HTMLDivElement | null>(null)
        , user = useSelector(_ => _.auth.user)
        , author = computeAuthorInfo(timeService, user)
        , title = 'Organisms recovered in this location'

    useScrollTo(divElement, 'organism-pie-chart')

    return (
        <div className='col-6 break-block width-print-100' ref={setDivElement}>
            <div className='block-border text-center py-3'>
                {props.series.length === 0 || props.showSpinner
                    ? <div className='text-center'>
                        <h4 className='mt-2 mb-4'>{title}</h4>
                        <NoDataAvailable showSpinner={props.showSpinner}>
                            <span className='text-muted text-uppercase' data-testid={`${props.testId}-no-data-message`}>No data available</span>
                        </NoDataAvailable>
                    </div>
                    : <PieChart
                        series={props.series}
                        title={title}
                        footer={props.printMode ? props.footer : []}
                        author={author}
                        onLegendItemClick={noop} // Need to be changed when Organism report will be ready
                        renderActionButtons={onExport =>
                            props.printMode
                                ? null
                                : <ActionButtons
                                    disabledGoToSamplesButton={props.series.length === 0}
                                    onExport={() => {
                                        const exportDate = timeService.formatCtzDate(timeService.now())

                                        onExport(`SmartControl Graph for ${props.locationName} downloaded on ${exportDate}`, props.footer)
                                    }}
                                    routeParams={{...props.routeParams, statuses: [GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION]}}
                                    className='text-end me-3'
                                    testId={props.testId}
                                />
                        }
                    />
                }
            </div>
        </div>
    )
}

export default OrganismGraph
