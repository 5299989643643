import { React, useState } from '_/facade/react'

import SampleIcon from '_/components/sample-icon/sample-icon'
import type { SampleTypeDetails } from '_/model/reports/missed-monitoring-report/types'
import HoverMenu from '_/components/overlay/hover-menu'
import FormattedText from '_/features/text/formatted-text'
import { formatActiveState } from '_/utils/format/common'

interface Props {
    sampleTypeDetails: SampleTypeDetails
    testId?: string
}

function MissedSampleTypeIcon(props: Props) {
    const containerRef = React.createRef<HTMLDivElement>()
        , [iconElement, setIconElement] = useState<SVGSVGElement | null>(null)

    return (
        <div>
            <SampleIcon type={props.sampleTypeDetails.sampleType} ref={setIconElement} testId={`${props.testId}-${props.sampleTypeDetails.sampleType}`} />
            <span data-testid={`${props.testId}-${props.sampleTypeDetails.sampleType}-quantity`}>{` x ${props.sampleTypeDetails.quantity}`}</span>
            <HoverMenu element={iconElement} alignmentElement={iconElement} showTriangle>
                <div ref={containerRef} className='legend-bubble bg-dark px-3 py-2'>
                    {props.sampleTypeDetails.sampleNameQuantities.map((_, index) =>
                        <div key={index} className='text-white' data-testid='missed-monitoring-hover-info'>
                            <FormattedText text={formatActiveState(_.sampleName, _.isActive)} />
                            {` x ${_.quantity}`}
                        </div>
                    )}
                </div>
            </HoverMenu>
        </div>
    )
}

export default MissedSampleTypeIcon
