const COMPLIANCE = 0
    , INSIGHT = 1
    , ENTERPRISE = 2
    , COLONY_COUNTER = 3

type Tier =
    | typeof COMPLIANCE
    | typeof INSIGHT
    | typeof ENTERPRISE
    | typeof COLONY_COUNTER

const TIERS: { id: Tier, name: string }[] = [
    {
        id: COMPLIANCE,
        name: 'Compliance',
    },
    {
        id: INSIGHT,
        name: 'Insight',
    },
    {
        id: ENTERPRISE,
        name: 'Enterprise',
    },
    {
        id: COLONY_COUNTER,
        name: 'SmartControl Colony Counter',
    },
]

export {
    TIERS as default,
    COMPLIANCE,
    INSIGHT,
    ENTERPRISE,
    COLONY_COUNTER,
    Tier,
}
