import type { FormRenderProps } from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'

import { classnames, useRef, useState, useAction, useSelector } from '_/facade/react'

import type { AnalysisFilter, ThresholdLine } from '_/model/analysis/filter/types'
import type { PredefinedLists } from '_/model/app-state'

import * as dateRanges from '_/constants/date-ranges'
import CHART_TYPE from '_/model/analysis/chart-type'

import { submitDisabled } from '_/components/form'
import Button from '_/components/button'
import FilterActions from '_/components/filter-actions'
import FilterListModal from '_/components/filter-list-modal'
import { useTimeService } from '_/components/time'

import { validate } from '_/features/analysis/validate'
import * as toastActions from '_/features/toasts/actions'

import AddSearchFilterModal from './add-search-filter-modal'
import * as analysisActions from '../actions'
import Filter from './filter'
import { recalculateDynamicExposureDate, isAnySelected } from './helpers'
import { dropFields, diffObject } from '_/utils/object'
import type { FloorPlanSubFilterValue } from '_/model/floor-plan/floor-plan'
import getNormalizationData from '_/model/analysis/filter/normalize'
import { CHANGES_SAVED } from '_/features/samples/messages'
import { isFloorPlanChart } from '../ui/helpers'
import * as fi from '_/constants/custom-field-index'
import { findFieldValuePos } from '_/features/samples/helpers'

interface Props {
    onGenerated: (template: AnalysisFilter) => void
    initialValue: AnalysisFilter
    onClear: () => void
    showAggregationPeriod: boolean
    onSearchTemplateLoaded: (template: AnalysisFilter) => void
    filtersTemplatesIncluded?: boolean | undefined
    predefinedLists: PredefinedLists
    isGraphInCustomReport?: boolean
    onChangeCanCloseModal?: () => void
    formId?: string
    floorPlanSubFilterValue: FloorPlanSubFilterValue
    exposureDateRangeModified: boolean
    onInvalid: (_: boolean) => void
    showThresholdLineModal: () => void
    onRemoveThresholdLine: (_: number) => void
    onRemoveAllThresholdLine: () => void
    thresholdLines: ThresholdLine[]
}

function AnalysisFilterForm(props: Props) {
    const previousFilter = useRef(props.initialValue)
        , timeService = useTimeService()
        , [showAddFilterModal, setShowAddFilterModal] = useState(false)
        , [showFilterListModal, setShowFilterListModal] = useState(false)
        , addSuccess = useAction(toastActions.addSuccess)
        , createAnalysisSearchFilter = useAction(analysisActions.createAnalysisSearchFilter)
        , analysisSearchFilters = useSelector(_ => _.analysis.analysisSearchFilters)
        , permissions = useSelector(_ => _.auth.permissions)
        , addSearchFilterOnSubmit = useRef(false)

    function handleAddSearchFilter(filterName: string, sampleSearchFields: AnalysisFilter) {
        const newSearchFilter = {
                name: filterName,
                filter: {
                    ...sampleSearchFields,
                    ...recalculateDynamicExposureDate(sampleSearchFields, timeService),
                    ...props.floorPlanSubFilterValue,
                    thresholdLines: props.thresholdLines,
                },
            }

        createAnalysisSearchFilter(newSearchFilter)
            .then(() => addSuccess(CHANGES_SAVED))

        setShowAddFilterModal(false)
    }

    function handleApplyFilter(filterId: string) {
        const filter = analysisSearchFilters.filter(_ => _.id === filterId)[0].filter

        previousFilter.current = filter

        props.onSearchTemplateLoaded(filter)
    }

    function handleSubmit(filter: AnalysisFilter) {
        if (addSearchFilterOnSubmit.current) {
            addSearchFilterOnSubmit.current = false
            setShowAddFilterModal(true)
            return
        }

        if (filter.exposureDateRange !== dateRanges.CUSTOM) {
            const recalculatedDates = recalculateDynamicExposureDate(filter, timeService)

            props.onGenerated({
                ...filter,
                ...(props.isGraphInCustomReport && isFloorPlanChart(filter.chartType) ? dropFields(recalculatedDates, 'exposureStartDateTo') : recalculatedDates),
            })
        }
        else {
            props.onGenerated(filter)
        }
    }

    function handleChangeFormValues(values: AnalysisFilter, form: FormRenderProps<any>) {
        const prevValues = previousFilter.current
        previousFilter.current = values

        props.onInvalid(form.form.getState().invalid)

        const normalizationData = getNormalizationData(values, prevValues, props.exposureDateRangeModified, timeService)
        if (normalizationData.paths.length > 0) {
            form.form.batch(() => {
                normalizationData.paths.forEach(_ => {
                    form.form.change(_.path, _.value)
                })
            })
        }

        if (values.chartType !== prevValues.chartType)
            props.onRemoveAllThresholdLine()

        if (values.seriesFieldIndex !== prevValues.seriesFieldIndex) {
            const fields = values.fields ?? []
                , operatorPos = findFieldValuePos(fields, fi.OPERATORS_IDS)
                , operatorValue = operatorPos ? fields[operatorPos].value : []

            if (values.seriesFieldIndex === fi.EXPOSURE_LOCATION_GRADE_ID && isAnySelected(values.gradeIds))
                form.form.change('gradeIds', undefined)

            if (values.seriesFieldIndex === fi.OPERATORS_IDS && isAnySelected(operatorValue))
                form.form.change(`fields[${operatorPos}].value`, undefined)
        }

        if (diffObject(prevValues, values) || diffObject(form.values, values)) {
            form.form.submit()
        }
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={props.initialValue}
            initialValuesEqual={(one, two) => one === two}
            validate={_ => validate(_, props.predefinedLists.customFields, timeService)}
            render={form =>
                <div className='d-flex flex-column bg-light side-filters'>
                    <nav className='navbar mx-0'>
                        <span className='navbar-brand me-auto filters-header-text'>Filters</span>
                        {props.filtersTemplatesIncluded && analysisSearchFilters.length > 0 &&
                            <Button
                                className={classnames('btn-link me-1', {
                                    disabled: !permissions.manageAnalysisFilters,
                                })}
                                onClick={() => setShowFilterListModal(true)}
                                hasNoPermissions={!permissions.manageAnalysisFilters}
                                testId='load-filter'
                            >
                                Load filter
                            </Button>
                        }
                    </nav>

                    <form className='overflow-y-auto flex-fill' onSubmit={form.handleSubmit}>
                        <FormSpy
                            onChange={_ => handleChangeFormValues(_.values as AnalysisFilter, form)}
                            subscription={{ values: true }}
                        />
                        <Filter
                            predefinedLists={props.predefinedLists}
                            values={form.values}
                            showCustomDateRangeInputs={form.values.exposureDateRange === dateRanges.CUSTOM}
                            showAggregationPeriod={props.showAggregationPeriod}
                            excludeCustomDateRange={props.isGraphInCustomReport}
                            fields={props.predefinedLists.customFields}
                            chartType={CHART_TYPE.find(_ => _.id === props.initialValue.chartType)?.id}
                            permissions={permissions}
                            getFieldState={form.form.getFieldState}
                            showModal={props.showThresholdLineModal}
                            onRemoveThresholdLine={props.onRemoveThresholdLine}
                            thresholdLines={props.thresholdLines}
                        />
                        {props.filtersTemplatesIncluded && permissions.manageAnalysisFilters &&
                            <div>
                                <AddSearchFilterModal
                                    isOpen={showAddFilterModal}
                                    onSave={_ => handleAddSearchFilter(_, form.values)}
                                    onCancel={() => setShowAddFilterModal(false)}
                                    analysisSearchFilters={analysisSearchFilters}
                                />
                                <FilterListModal
                                    isOpen={showFilterListModal}
                                    onClose={() => setShowFilterListModal(false)}
                                    onApplyFilter={handleApplyFilter}
                                    loadFiltersAction={analysisActions.loadAnalysisSearchFilterList}
                                    removeFilterAction={analysisActions.removeAnalysisSearchFilter}
                                    isAnalysisFilters
                                />
                            </div>
                        }

                        <div className='side-filters__spacer' />

                        <FilterActions className='d-flex side-filters__actions'>
                            {props.filtersTemplatesIncluded &&
                                <Button
                                    className='btn btn-link me-auto ps-0'
                                    onClick={() => {
                                        addSearchFilterOnSubmit.current = true
                                        form.form.submit()
                                    }}
                                    disabled={submitDisabled(form) && form.dirty}
                                    hasNoPermissions={!permissions.manageAnalysisFilters}
                                    testId='save-filter'
                                >
                                    Save filter
                                </Button>
                            }
                            <Button className='btn-link ms-auto' onClick={props.onClear} testId='clear'>Clear</Button>
                        </FilterActions>
                    </form>
                </div>
            }
        />
    )
}

export default AnalysisFilterForm

