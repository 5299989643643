import { React, classnames, useState } from '_/facade/react'
import HoverMenu from '_/components/overlay/hover-menu'

import type { KpiData, KpiBlockSamplesInfo } from '_/model/reports/types'
import type { TrendType} from '_/model/reports/locations-report/trend-type'
import { CONTAMINATION, LIMIT_BREACH, FINGERDAB_ACTION_LIMIT_BREACH } from '_/model/reports/locations-report/trend-type'

import { calculateKpiBlockRate, calculateTrend } from '_/model/reports/locations-report/helpers'
import type TimeService from '_/services/time-service'
import { useTimeService } from '_/components/time'
import { formatDatePeriod, areDatesInCurrentYear } from '_/model/reports/missed-monitoring-report/helpers'
import NoDataAvailable from './no-data-available'

interface OwnProps {
    kpiBlockInfo: KpiData | undefined
    trendType: TrendType
    testId?: string
    showSpinner: boolean
}

function KpiBlockHint(props: OwnProps) {
    const hintContainerRef = React.createRef<HTMLDivElement>()
        , [containerElement, setContainerElement] = useState<HTMLElement | null>(null)
        , [blockElement, setBlockElement] = useState<HTMLElement | null>(null)
        , emptyMessage = 'No data available'
        , emptyHintMessage = props.trendType === CONTAMINATION
            ? 'No contamination recorded'
            : 'No limit breaches recorded'
        , caption = props.trendType === CONTAMINATION
            ? 'Contamination rate'
            : 'Limit breach rate'
        , trend = calculateTrend(props.kpiBlockInfo)
        , timeService = useTimeService()
        , areDateRangesOutOfYear = areDatesInCurrentYear(props.kpiBlockInfo?.currentPeriodInfo.dateRange, timeService)
            && areDatesInCurrentYear(props.kpiBlockInfo?.previousPeriodInfo.dateRange, timeService)

    return (
        <div className='col-3'>
            <div className='block-border text-center py-3'>
                <div className='d-flex justify-content-center' ref={setContainerElement}>
                    <div ref={setBlockElement} data-testid={props.testId} className='pb-2'>
                        <div className='text-center'>
                            <h4 className='mb-4'>{caption}</h4>
                        </div>
                        {!props.kpiBlockInfo?.currentPeriodInfo || props.kpiBlockInfo.currentPeriodInfo.readSamplesCount === 0 || props.showSpinner
                            ? <NoDataAvailable showSpinner={props.showSpinner}>
                                <span className='text-muted text-uppercase' data-testid={`${props.testId}-empty-message`}>{emptyMessage}</span>
                            </NoDataAvailable>
                            : <div>
                                <div className='d-flex justify-content-center'>
                                    <h4 data-testid={`${props.testId}-percentage`}>{calculateKpiBlockRate(props.kpiBlockInfo)}%</h4>
                                    {trend !== undefined &&
                                        <i data-testid={`${props.testId}-arrow`} className={
                                            classnames(
                                                'material-icons md-24 text-secondary',
                                                {'text-danger rotate-315': trend > 0},
                                                {'text-success rotate-45 ps-1': trend < 0},
                                            )}
                                        >
                                            arrow_forward
                                        </i>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    {blockElement &&
                        <HoverMenu element={containerElement} alignmentElement={blockElement} showTriangle>
                            <div ref={hintContainerRef} className='legend-bubble bg-dark'>
                                <div className='text-white p-3'>
                                    <KpiBlockHintFormat
                                        info={props.kpiBlockInfo?.currentPeriodInfo}
                                        trendType={props.trendType}
                                        emptyMessage={emptyHintMessage}
                                        currentPeriod={true}
                                        timeService={timeService}
                                        omitYear={areDateRangesOutOfYear}
                                    />
                                    <KpiBlockHintFormat
                                        info={props.kpiBlockInfo?.previousPeriodInfo}
                                        trendType={props.trendType}
                                        emptyMessage={emptyHintMessage}
                                        currentPeriod={false}
                                        timeService={timeService}
                                        omitYear={areDateRangesOutOfYear}
                                    />
                                    <div className='fst-italic mt-3' data-testid='excludes-compromised-label'>
                                        Excludes compromised viable samples
                                    </div>
                                </div>
                            </div>
                        </HoverMenu>
                    }
                </div>
            </div>
        </div>
    )
}

interface KpiBlockHintFormatProps {
    info: KpiBlockSamplesInfo | undefined
    trendType: TrendType
    emptyMessage: string
    currentPeriod: boolean
    timeService: TimeService
    omitYear: boolean
}

function KpiBlockHintFormat(props: KpiBlockHintFormatProps) {
    const {info, trendType, emptyMessage, currentPeriod, omitYear, timeService} = props
    function getCFUSamplesMessage(trendType: TrendType, samplesWithCfuCount: number) {
        switch(trendType) {
            case CONTAMINATION:
                return samplesWithCfuCount === 1 ? 'viable sample contaminated' : 'viable samples contaminated'
            case LIMIT_BREACH:
                return samplesWithCfuCount === 1 ? 'limit breach' : 'limit breaches'
            case FINGERDAB_ACTION_LIMIT_BREACH:
                return samplesWithCfuCount === 1 ? 'fingerdab action limit breach' : 'fingerdab action limit breaches'
        }
    }

    return <div className={classnames({ 'mt-3': !currentPeriod })} data-testid={`kpi-block-tooltip-${currentPeriod ? 'current-period' : 'previous-period'}`}>
        <div className='fw-bold' data-testid={`date-range-${currentPeriod ? 'current-period' : 'previous-period'}`}>{formatDatePeriod(timeService, info?.dateRange, omitYear)}</div>
        {!info?.readSamplesCount
            ? emptyMessage
            : <div>
                {info.readSamplesCount} {info.readSamplesCount === 1 ? 'viable sample' : 'viable samples'} read<br/>
                {info.samplesWithCfuCount} {getCFUSamplesMessage(trendType, info.samplesWithCfuCount)}
            </div>
        }
    </div>
}

export default KpiBlockHint
export { KpiBlockHintFormat }
