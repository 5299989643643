import { useState, useEffect, useSelector, useAction, useCallback } from '_/facade/react'
import { Table } from '_/components/table'
import ContextObserver from '_/components/context-observer'
import PageHeader from '_/components/page-header'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import * as tree from '_/utils/tree'

import * as toastActions from '_/features/toasts/actions'
import * as a from '../actions'

import { loadGradesList } from '_/features/predefined-lists/action-alert-limits/actions'
import * as routes from '_/constants/routes'

import ExposureLocationsRow from './exposure-locations-row'
import ExposureLocationEditDialog from '../edit/exposure-location-modal'

import { fullNameLocationList } from '_/utils/exposure-location'
import { searchDuplicateName } from '_/model/predefined-lists/exposure-location/validate'

function ExposureLocations() {
    const [expandState, setExpandState] = useState<tree.ExpandedItems>({})
        , [exposureLocations, grades, reload] = useLoadData()
        , [handleDragStart, handleDragEnd, handleDragDrop, draggedLocation, resetDraggedLocation] = useDragDrop(exposureLocations, setExpandState)

        , permissions = useSelector(_ => _.auth.permissions)
        , route = useSelector(_ => _.router.route)
        , locations = tree.flatTree(exposureLocations, expandState)
        , editing = [routes.SETTINGS_EXPOSURE_LOCATIONS_CREATE, routes.SETTINGS_EXPOSURE_LOCATIONS_EDIT].some(it => route?.name === it)

    useExpandRootLocation(exposureLocations, setExpandState)

    function handleTrigger(location: ExposureLocation) {
        setExpandState(_ => ({..._, [location.id]: !_[location.id]}))
    }

    function handleCreate(parentId: string) {
        setExpandState(_ => ({..._, [parentId]: true }))
    }

    function canMoveTo(destination: ExposureLocation): boolean {
        if (!draggedLocation || !destination.isActive)
            return false

        return tree.canMove(exposureLocations, draggedLocation, destination)
    }

    function reset() {
        setExpandState({})
        resetDraggedLocation()
        reload()
    }

    return (
        <>
            <ContextObserver onChange={reset} />
            <PageHeader title='Exposure locations'/>
            {editing &&
                <ExposureLocationEditDialog onLocationCreated={handleCreate} />
            }
            <div className="overflow-auto flex-fill">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Grade</th>
                            <th>Status</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {locations.map(location =>
                            <ExposureLocationsRow
                                key={location.id}
                                location={location}
                                grade={grades.find(_ => _.id === location.gradeId)}
                                hasChildren={tree.hasChildren(exposureLocations, location)}
                                expanded={expandState[location.id]}
                                nestingLevel={tree.nestingLevel(exposureLocations, location)}
                                canEdit={permissions.editExposureLocations}
                                canMoveTo={canMoveTo}
                                onDragStart={handleDragStart}
                                onDragEnd={handleDragEnd}
                                onDragDrop={handleDragDrop}
                                onTrigger={handleTrigger}
                            />
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ExposureLocations

function useExpandRootLocation(locations: tree.Tree<ExposureLocation>, setExpandState: React.Dispatch<React.SetStateAction<tree.ExpandedItems>>) {
    useEffect(
        () => {
            setExpandState(prevState => {
                if (Object.keys(prevState).length > 0)
                    return prevState

                const nextState: tree.ExpandedItems = {}
                tree.roots(locations)
                    .forEach(_ => nextState[_.id] = true)

                return nextState
            })
        },
        [locations, setExpandState]
    )
}

function useLoadData() {
    const loadExposureLocationList = useAction(a.loadExposureLocationList)
        , loadGrades = useAction(loadGradesList)
        , grades = useSelector(_ =>_.predefinedLists.grades)
        , locations = useSelector(_ => _.predefinedLists.exposureLocations)

        , load = useCallback(
            () => {
                loadExposureLocationList()
                loadGrades()
            },
            [loadExposureLocationList, loadGrades]
        )

    useEffect(() => { load() }, [load])
    return [locations, grades, load] as const
}

function useDragDrop(exposureLocations: tree.Tree<ExposureLocation>, setExpandState: React.Dispatch<React.SetStateAction<tree.ExpandedItems>>) {
    const [draggedLocation, setDraggedLocation] = useState<ExposureLocation | undefined>(undefined)
        , saveExposureLocation = useAction(a.saveExposureLocation)
        , addError = useAction(toastActions.addError)

    function handleDragStart(draggedLocation: ExposureLocation) {
        setDraggedLocation(draggedLocation)
    }

    function handleDragEnd() {
        setDraggedLocation(undefined)
    }

    function handleDragDrop(destination: ExposureLocation) {
        if (!draggedLocation)
            return

        const oldLocation = { parentId: draggedLocation.parentId }
            , newLocation = { parentId: destination.id }
            , usedNames = fullNameLocationList(exposureLocations)
                            .filter(_ => _.parentId === destination.id)
                            .map(_ => _.name)

        if (searchDuplicateName(draggedLocation, usedNames)) {
            addError(`Location with name '${draggedLocation.name}' already exists within this location branch`)
            return
        }

        saveExposureLocation({
            id: draggedLocation.id,
            oldExposureLocation: oldLocation,
            newExposureLocation: newLocation,
        }).then(_ =>
            setExpandState(prevExpandState => ({...prevExpandState, [destination.id]: true }))
        )
    }

    function resetDraggedLocation() {
        setDraggedLocation(undefined)
    }

    return [handleDragStart, handleDragEnd, handleDragDrop, draggedLocation, resetDraggedLocation] as const
}
