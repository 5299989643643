import FieldForm from './field-form'

interface OwnProps {
    text: string | JSX.Element
    label: string
    title?: string
    testId?: string
}

const PlainText = (props: OwnProps) => (
    <FieldForm
        formId='plain-text'
        label={props.label}
        renderView={() => <span title={props.title || ''} className='form-control-plaintext fw-bold' data-testid={props.testId}>{props.text}</span>}
    />
)
export default PlainText
