import { useState, classnames } from '_/facade/react'

import Link from '_/components/link'

import type { SampleSession } from '_/model/predefined-lists/session/types'
import { SETTINGS_SESSIONS_EDIT } from '_/constants/routes'
import type { DragDirection} from '_/model/dom'
import { getElementDragDirection, TOP, BOTTOM, NONE } from '_/model/dom'

interface Props {
    entity: SampleSession
    canEdit: boolean
    onDragStart: (_: SampleSession) => void
    onDragEnd: () => void
    onDragDrop: (_: SampleSession) => void
    canMoveAbove: (destination: SampleSession) => boolean
    canMoveBelow: (destination: SampleSession) => boolean
}

function SampleSessionRow(props: Props) {
    const dragEvents = !props.canEdit
            ? undefined
            : {
                onDragStart: handleDragStart,
                onDragEnd: handleDragEnd,
                onDragOver: handleDragOver,
                onDragLeave: handleDragLeave,
            }
        , [dragDirection, setDragDirection] = useState<DragDirection>(NONE)

    function handleDragStart(e: React.DragEvent<HTMLTableRowElement>) {
        e.dataTransfer.effectAllowed = 'move'
        props.onDragStart(props.entity)
    }

    function handleDragEnd() {
        props.onDragEnd()
    }

    function handleDragOver(e: React.DragEvent<HTMLTableRowElement>) {
        const direction = getElementDragDirection(e.currentTarget, e)
            , canMove = direction === BOTTOM && props.canMoveBelow(props.entity)
                || direction === TOP && props.canMoveAbove(props.entity)

        if (!canMove)
            return

        setDragDirection(direction)

        e.preventDefault()
    }

    function handleDragLeave() {
        setDragDirection(NONE)
    }

    function handleDrop() {
        if (!dragDirection)
            return

        setDragDirection(NONE)
        props.onDragDrop(props.entity)
    }

    return (
        <tr
            className={classnames(`table-row--drag-hover-${dragDirection}`, {'cursor-pointer': props.canEdit})}
            draggable={props.canEdit}
            {...dragEvents}
            onDrop={handleDrop}
        >
            <td onClick={e => e.preventDefault()} data-testid={`session-${props.entity.name}`}>{props.entity.name}</td>
            <td>{props.entity.isActive ? 'Active' : 'Inactive'}</td>
            <td className='text-end'>
                {props.canEdit && <Link className='btn-link' routeName={SETTINGS_SESSIONS_EDIT} routeParams={{ id: props.entity.id }} testId={`session-${props.entity.name}-edit`}>Edit</Link>}
            </td>
        </tr>
    )
}

export default SampleSessionRow
