import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type PredefinedListsService from '_/services/predefined-lists-service'
import { noop } from '_/utils/function'
import * as spinnerActions from '_/features/spinner/actions'
import * as actions from './actions'

import { updateTransaction } from '_/model/predefined-lists/session/transaction'

const factory = (service: PredefinedListsService): EffectsFactory => dispatch => [
    handler(actions.loadSampleSessionList, () =>
        service.sampleSession.list()
            .then(_ => dispatch(actions.sampleSessionListLoaded(_)))
            .then(noop)
    ),

    handler(actions.loadSampleSession, service.sampleSession.get),

    handler(actions.loadSampleSessionTrail, service.sampleSession.getTrail),

    handler(actions.createSampleSession, data =>
        service.sampleSession.create(data)
            .then(_ => service.sampleSession.list())
            .then(_ => dispatch(actions.sampleSessionListLoaded(_)))
            .then(noop)
    ),

    handler(actions.removeSampleSession, id =>
        service.sampleSession.remove(id)
            .then(_ => dispatch(actions.sampleSessionRemoved(id)))
            .then(noop)
    ),

    handler(actions.saveSampleSession, data =>
        Promise.resolve()
            .then(() => dispatch(spinnerActions.showSpinner()))
            .then(_ => updateTransaction(
                data.id,
                data.oldSampleSession,
                data.newSampleSession,
                service,
            ))
            .then(_ => service.sampleSession.list())
            .then(_ => dispatch(actions.sampleSessionListLoaded(_)))
            .then(noop)
            .finally(() => dispatch(spinnerActions.hideSpinner()))
    ),

    handler(actions.changePosition, data =>
        service.sampleSession.changePosition(data.id, data.position)
            .then(_ => service.sampleSession.list())
            .then(_ => dispatch(actions.sampleSessionListLoaded(_)))
            .then(noop)
    ),
]

export default factory
