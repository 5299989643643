import { useAction } from '_/facade/react'

import * as actions from '../actions'
import ContextConfirm from './context-confirm'

function ContextAssignConfirm() {
    const assign = useAction(actions.assign)

    return (
        <ContextConfirm assign={assign} />
    )
}

export default ContextAssignConfirm
