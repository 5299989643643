import { React, useAction, useSelector } from '_/facade/react'
import InlineCheckbox from '_/components/checkbox-inline'
import type { GrowthCountResult, SmartCheckResult } from '_/model/smart-check/image'
import * as a from '../actions'

interface Props {
    growthCountResult: GrowthCountResult | undefined
    smartCheckResult: SmartCheckResult | undefined
}

function ShowSuggestedGrowthRegionsAndCounts(props: Props) {
    const handleChange = useAction(a.showSuggestedGrowthRegionsAndCountsChanged)
        , showValues = useSelector(_ => _.smartCheck.showSuggestedGrowthRegionsAndCounts)
        , noGrowth = props.growthCountResult && props.growthCountResult.growths.length === 0

    const visible = props.growthCountResult
            && props.smartCheckResult?.alignPassed
            && props.smartCheckResult.blurCheckPassed
            && props.smartCheckResult.repetitionPassed

    if (!visible)
        return null

    return (
        <div className='smart-check-show-suggested-growths rounded text-light p-1 m-4'>
            <InlineCheckbox
                id='showSuggestedGrowthRegions'
                name='showSuggestedGrowthRegions'
                checked={!noGrowth && showValues}
                onChange={e => handleChange(e.target.checked)}
                disabled={noGrowth}
                title={noGrowth ? 'Not available for a current image due to no contamination detected on the sample' : ''}
            >
                Show suggested growth regions and counts
            </InlineCheckbox>
        </div>
    )
}

export default ShowSuggestedGrowthRegionsAndCounts
