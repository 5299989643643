type DateTime = `${string}-${string}-${string}T${string}:${string}:${string}Z`

function equals(dateToCompare: DateTime | undefined, comparedDate: DateTime | undefined) {
    if (dateToCompare === undefined && comparedDate === undefined)
        return true

    if (dateToCompare === undefined || comparedDate === undefined)
        return false

    const toCompare = new Date(dateToCompare)
        , compared = new Date(comparedDate)

    return toCompare.getTime() === compared.getTime()
}

function greaterThan(dateToCompare: DateTime, comparedDate: DateTime) {
    const toCompare = new Date(dateToCompare)
        , compared = new Date(comparedDate)

    return toCompare.getTime() > compared.getTime()
}

function greaterThanOrEqual(dateToCompare: DateTime, comparedDate: DateTime) {
    const toCompare = new Date(dateToCompare)
        , compared = new Date(comparedDate)

    return toCompare.getTime() >= compared.getTime()
}

export {
    DateTime,
    equals,
    greaterThan,
    greaterThanOrEqual,
}
