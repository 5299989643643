export const EMPTY_TEXT = 0
           , DEFAULT_TEXT = 1
           , SYSTEM_TEXT = 2

export type EMPTY_TEXT = typeof EMPTY_TEXT
export type DEFAULT_TEXT = typeof DEFAULT_TEXT
export type SYSTEM_TEXT = typeof SYSTEM_TEXT

export type TextType =
    | EMPTY_TEXT
    | DEFAULT_TEXT
    | SYSTEM_TEXT

export type TextNode =
    | { type: EMPTY_TEXT, value: '' }
    | { type: DEFAULT_TEXT, value: string }
    | { type: SYSTEM_TEXT, value: string }

export type Text = TextNode[]

type InferTextNode<T extends TextType> = Extract<TextNode, { type: T }>

function textNode<T extends TextType>(type: T, value: InferTextNode<T>['value']): InferTextNode<T> {
    return { type, value } as InferTextNode<T>
}

const emptyTextNode = () => textNode(EMPTY_TEXT, '')
    , defaultTextNode = (value: string) => textNode(DEFAULT_TEXT, value)
    , systemTextNode = (value: string) => textNode(SYSTEM_TEXT, value)

function plainText(text: Text | string): string {
    if (typeof text === 'string')
        return text

    return text.map(_ => _.value).join('')
}

export {
    textNode,
    emptyTextNode,
    defaultTextNode,
    systemTextNode,

    plainText,
}
