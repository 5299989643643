import { useState, useAction, useEffect } from '_/facade/react'
import { debounce, noop } from '_/utils/function'

import MultipleFieldValueSearchField from '_/components/form/multi-values-search-field'

import * as actions from './actions'

interface Props {
    id: string
    name: string
    className?: string
}

function MultipleTrendIdSearch(props: Props) {
    const [entities, setEntities] = useState<string[]>([])
        , handleInputValueChange = useSearchTrendId(setEntities)

    return (
        <MultipleFieldValueSearchField
            id={props.id}
            name={props.name}
            label='Trend ID'
            className={props.className}
            entities={entities}
            onChangeEntities={setEntities}
            onInputValueChange={handleInputValueChange}
        />
    )
}

function useSearchTrendId(setEntities: (_: string[]) => void) {
    const [searchHandler, setSearchHandler] = useState<(value: string) => void>(() => noop)
        , searchTrendId = useAction(actions.searchTrendId)

    useEffect(
        () => {
            let disposed = false

            function search(query: string) {
                if (query.length < 3 || disposed)
                    return

                searchTrendId(query).then(setEntities)
            }

            setSearchHandler(() => debounce(search, 300))

            return () => { disposed = true }
        },
        [searchTrendId, setEntities]
    )

    return searchHandler
}

export default MultipleTrendIdSearch
