export const HAS = 0
           , IN = 1
           , HAS_THE_SAME = 2
           , IN_THE_SAME = 3
           , HAS_IDENTIFICATION = 4
           , HAS_THE_SAME_IDENTIFICATION = 5

import type * as self from './filter-type'
type TrendFilter = Extract<(typeof self)[keyof typeof self], number>

const TREND_FILTER: readonly { id: TrendFilter, name: string }[] = [
    {
        id: HAS,
        name: 'Has',
    },
    {
        id: IN,
        name: 'In',
    },
    {
        id: HAS_THE_SAME,
        name: 'Has the same',
    },
    {
        id: IN_THE_SAME,
        name: 'In the same',
    },
    {
        id: HAS_IDENTIFICATION,
        name: 'Has identification',
    },
    {
        id: HAS_THE_SAME_IDENTIFICATION,
        name: 'Has the same identification',
    },
]

export {
    TREND_FILTER,
    TrendFilter,
}
