import type SampleFilesService from '_/services/sample-files-service'
import type { UploadedFile } from './files'

interface UploadData {
    sampleId: string
    file: File
}

interface UploadResult {
    file: UploadedFile
    progress: Promise<void>
}

interface Uploader {
    upload(data: UploadData): UploadResult
}

function uploaderFactory(service: SampleFilesService): Uploader {
    return {
        upload
    }

    function upload(data: UploadData): UploadResult {
        const uploadId = Math.random().toString()
            , fileName = data.file.name

        const progress = service.initUpload(data.sampleId, { fileName, fileSize: data.file.size })
                .then(token =>
                    service
                        .upload(data.file, token.uploadToken)
                        .then(() => service.completeUpload(data.sampleId, token.fileId))
                )

        return {
            file: {
                id: uploadId,
                fileName,
            },
            progress,
        }
    }
}

export {
    uploaderFactory,
}

export type {
    UploadData,
    UploadResult,
    Uploader,
}
