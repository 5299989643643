import type { SampleSearchEditFields, FilterFieldValue } from '_/model/sample/search'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import type { ValidationResult} from '_/utils/form/validate'
import { checkDateRangeValidity, required, minLengthForSearch, isValidFraction, isValidNumberInRange } from '_/utils/form/validate'
import type TimeService from '_/services/time-service'

import { SAMPLE_BREACH_DATE } from '_/constants/search-date-type'
import * as fieldIndex from '_/constants/custom-field-index'
import * as fieldType from '_/constants/custom-field-column-type'

import { isExistingTime } from '../validate'
import { getFieldValue, findFieldValuePos } from '../helpers'

function validate(entity: Partial<SampleSearchEditFields>, timeService: TimeService, customFields: CustomField[]) {
    const result: ValidationResult<SampleSearchEditFields> = {}
        , dateTo = entity.dateToFilter !== undefined
            && !entity.dateFrom
            && required('Date To')(entity.dateTo)
            || checkDateRange(entity, timeService)
        , dateFrom = entity.dateToFilter !== undefined
            && !entity.dateTo
            && required('Date From')(entity.dateFrom)
        , dateToFilter = (entity.dateTo || entity.dateFrom)
            && required('Filter Date')(entity.dateToFilter)
        , timeTo = entity.dateToFilter === SAMPLE_BREACH_DATE && isExistingTime(timeService, entity.dateTo, entity.timeTo)
        , timeFrom = entity.dateToFilter === SAMPLE_BREACH_DATE && isExistingTime(timeService, entity.dateFrom, entity.timeFrom)
        , values = entity.fields || []
        , fields: Partial<FieldValues>[] = []
        , barcodeValidation = validateBarcode(entity)
        , customValuesOnly = values.filter(f => fieldIndex.default.indexOf(f.index) === -1)

    customValuesOnly.forEach(customValue => {
        const number = validateCustomFieldNumberRange(customValue, customFields)
        if (number) {
            fields[findFieldValuePos(values, customValue.index)] = { value: number }
        }
    })

    const overlaidFields = values.map((_, index) =>
        fields[index] ||
        barcodeValidation.fields[index]
    )

    if (dateFrom)
        result.dateFrom = dateFrom

    if (dateTo)
        result.dateTo = dateTo

    if (dateToFilter)
        result.dateToFilter = dateToFilter

    if (timeTo)
        result.timeTo = timeTo

    if (timeFrom)
        result.timeFrom = timeFrom

    return Object.assign(result, { fields: overlaidFields })
}

function validateBarcode(entity: Partial<SampleSearchEditFields>) {
    const existedFields = entity.fields || []
        , resultFields: Partial<FieldValues>[] = []
        , result: ValidationResult<SampleSearchEditFields> = {}
        , barcode = getFieldValue(existedFields, fieldIndex.BARCODE)

        , barcodeLength = minLengthForSearch(4, 'barcode')(barcode)

    resultFields[findFieldValuePos(existedFields, fieldIndex.BARCODE)] = { value: barcodeLength }

    return Object.assign(result, { fields: resultFields })
}

function validateCustomFieldTextMinLength(value: FilterFieldValue | undefined, customFields: CustomField[]) {
    const customField = customFields.find(f => f.index === value!.index)

    if (customField && customField.fieldType === fieldType.TEXT && value) {
        return minLengthForSearch(4, customField.fieldName)(value.value)
    }
}

function validateCustomFieldNumberRange(value: FilterFieldValue | undefined, customFields: CustomField[]) {
    const customField = customFields.find(f => f.index === value!.index)

    if (customField && customField.fieldType === fieldType.NUMBER && value) {
        const range: {from: string | undefined, to: string | undefined} | undefined = value.value

        if (range === undefined)
            return

        const validateNumber = validateCustomFieldNumberInRange(-99999.999, 99999.999)
            , from = range.from !== undefined ? validateNumber('From', range.from) : undefined
            , to = range.to !== undefined ? validateNumber('To', range.to) : undefined
            , greaterOrEqual = (from || to) !== undefined || range.from === undefined || range.to === undefined
                ? undefined
                : parseFloat(range.to) < parseFloat(range.from)
                    ? 'To value must be greater than or equal to From value'
                    : undefined

        if (greaterOrEqual)
            return { from: true, to: true, errors: [greaterOrEqual] }

        return from || to
            ? {
                from: !!from,
                to: !!to,
                errors: [from, to].filter(_ => _ !== undefined),
            }
            : undefined
    }
}

function checkDateRange(entity: SampleSearchEditFields, timeService: TimeService) {
    const isSampleBreachDate = entity.dateToFilter === SAMPLE_BREACH_DATE
        , dateFrom = isSampleBreachDate ? timeService.combineCtzDateTime(entity.dateFrom, entity.timeFrom) : entity.dateFrom
        , dateTo = isSampleBreachDate ? timeService.combineCtzDateTime(entity.dateTo, entity.timeTo) : entity.dateTo

    return checkDateRangeValidity(dateFrom, dateTo)
}

function validateCustomFieldNumberInRange(min: number, max: number) {
    return (name: string, value: number | string) => {
        return !isValidFraction(value)
            || !isValidNumberInRange(value, min, max)
                ? `${name} must be value between ${min} and ${max}, maximum 3 decimal places`
                : undefined
    }
}

export {
    validate,
    validateCustomFieldNumberRange,
    validateCustomFieldTextMinLength,
    validateCustomFieldNumberInRange,
}
