import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, required } from '_/utils/form/validate'
import type ApprovalInfo from '_/model/critical-change-reason/types'

function validate(approvalInfo: Partial<ApprovalInfo>, signatureRequired: boolean): ValidationResult<ApprovalInfo> {
    const reason = approvalInfo.reason
        , idToken = approvalInfo.idToken
        , password = !idToken && signatureRequired && required('Password')(approvalInfo.password)
        , result: ValidationResult<ApprovalInfo> = {}

    const minLength = !reason || reason.trim().length >= 1 ? false : 'Reason should not be empty'
        , reasonLength = minLength || maxLength('Reason', 250)(reason)
        , reasonRequired = required('Reason')(reason)

    if (password)
        result.password = password

    if (reasonRequired)
        result.reason = reasonRequired

    if (reasonLength)
        result.reason = reasonLength

    return result
}

export default validate
