import { React, classnames, useState, useSelector, useAction, useEffect } from '_/facade/react'
import * as userActions from '_/features/users/actions'
import Menu from '../overlay/menu'
import { useContextSwitchObserver } from '../context-observer'

type ForwardedAttributes = Pick<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onFocus' | 'onBlur' | 'className' | 'placeholder'>

interface User {
    name: string
    email: string
}

interface Props extends ForwardedAttributes {
    value: string
    onChange: (_: string) => void
    testId?: string
}

interface TagResult {
    value: string
    text: string
    start: number
    end: number
}

function UserTagInput(props: Props) {
    const [textarea, textareaRef] = useState<HTMLTextAreaElement | null>(null)
        , [focused, setFocused] = useState(false)
        , [tag, handleTagSelected] = useTag(textarea, props.onChange, setFocused)
        , users = useUsers(tag)
        , showMenu = focused && users.length > 0
        , [menuIndex, handleKeyDown] = useMenu(users, showMenu, handleTagSelected)

    function handleFocus(event: React.FocusEvent<HTMLTextAreaElement>) {
        if (props.onFocus)
            props.onFocus(event)

        setFocused(true)
    }

    useEffect(
        () => {
            function handleClose(event: FocusEvent) {
                const contains = (node: Node | null) => event.target instanceof Node ? node?.contains(event.target) : false

                if (!contains(textarea))
                    setFocused(false)
            }

            window.addEventListener('click', handleClose)
            return () => window.removeEventListener('click', handleClose)
        }
    )

    return (
        <>
            <textarea
                className={props.className}
                placeholder={props.placeholder}
                ref={textareaRef}
                onChange={_ => props.onChange(_.target.value)}
                onBlur={props.onBlur}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                value={props.value}
                data-testid={props.testId}
            />
            {showMenu &&
                <Menu element={textarea}>
                    <div
                        className='dropdown-menu d-block'
                        style={{
                            overflowY: 'auto',
                            maxHeight: '300px',
                        }}
                        data-testid='tag-user-popup'
                    >
                        {users.map((user, key) =>
                            <div
                                key={key}
                                className={classnames('dropdown-item cursor-pointer', { 'fw-bold': key === menuIndex })}
                                onClick={() => handleTagSelected(user)}
                                data-testid={`tag-user-${user.email}`}
                            >
                                {user.email} ({user.name})
                            </div>
                        )}
                    </div>
                </Menu>
            }
        </>
    )
}

export default UserTagInput

function useUsers(tag: TagResult | undefined): User[] {
    const canTag = useSelector(_ => _.auth.permissions.tagUsers && _.auth.permissions.readUsers)
        , allUsers = useSelector(_ => _.users.list.items)
        , loadUsers = useAction(userActions.loadUserList)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            if (canTag)
                loadUsers({ start: 0, count: 0, sort: 'asc' })
        },
        [contextSwitch, canTag, loadUsers]
    )

    if (tag === undefined || !canTag)
        return []

    return allUsers
        .filter(_ => _.email.toLocaleLowerCase().startsWith(tag.value.toLocaleLowerCase()))
        .filter(_ => _.email.toLocaleLowerCase() !== tag.value.toLocaleLowerCase())
}

function useTag(textarea: HTMLTextAreaElement | null, onChange: (value: string) => void, setFocused: (_: boolean) => void) {
    const [tag, setTag] = useState<TagResult>()
        , [tagWasInserted, setTagWasInserted] = useState(false)

    React.useLayoutEffect(() => {
        if (!textarea)
            return

        // Cursor is restored here instead of handleTagSelected
        // because component is controlled and in handleTagSelected new text is not applied yet
        restoreNextCursor(textarea)
        checkIncompleteTag(textarea)
    })

    function tagSelectedHandler(user: User) {
        if (!tag)
            return

        const newTag = replaceTagValue(tag, user.email)

        onChange(newTag.text)

        setTag(newTag)
        setTagWasInserted(true)

        // restore focus because input loses focus on menu click
        textarea?.focus()
    }

    function restoreNextCursor(input: HTMLTextAreaElement): boolean {
        if (!tag || !tagWasInserted)
            return false

        input.setSelectionRange(tag.end, tag.end)

        setTag(undefined)
        setTagWasInserted(false)

        return true
    }

    function checkIncompleteTag(input: HTMLTextAreaElement) {
        const newTag = calcTag(input.value, input.selectionEnd)

        if (!tagsEqual(tag, newTag)) {
            setTag(newTag)
            setFocused(true)
        }
    }

    return [tag, tagSelectedHandler] as const
}

function useMenu(users: User[], showMenu: boolean, onSelect: (user: User) => void) {
    const [menuIndex, setMenuIndex] = useState(0)
        , wrappedMenuIndex = menuIndex % users.length

    React.useEffect(
        () => {
            // reset index when menu closed
            if (!showMenu)
                setMenuIndex(0)
        },
        [showMenu]
    )

    function handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (!showMenu)
            return

        const selectUser = () => onSelect(users[wrappedMenuIndex])

        tryHandleKey('ArrowUp', () => setMenuIndex(menuIndex - 1))
        tryHandleKey('ArrowDown', () => setMenuIndex(menuIndex + 1))
        tryHandleKey('Enter', selectUser)
        tryHandleKey('Tab', selectUser)

        function tryHandleKey(key: string, handler: () => void) {
            if (key === event.key) {
                handler()
                event.preventDefault()
            }
        }
    }

    return [wrappedMenuIndex, handleKeyDown] as const
}

function calcTag(text: string, cursorPos: number): TagResult | undefined {
    const textOfInterest = text.substring(0, cursorPos)
        , tagPattern = /(?:^|\s)@[\S]*$/
        , result = tagPattern.exec(textOfInterest)

    if (!result)
        return undefined

    const atIndex = result[0].indexOf('@')
        , tagPos = result.index + atIndex + 1
        , matchedValue = text.substring(tagPos, cursorPos)

    return {
        value: matchedValue,
        start: tagPos,
        end: cursorPos,
        text,
    }
}

function tagsEqual(one: TagResult | undefined, two: TagResult | undefined) {
    if (one === two)
        return true

    if (one === undefined || two === undefined)
        return false

    return one.value === two.value
        && one.start === two.start
        && one.end === two.end
        && one.text === two.text
}

function replaceTagValue(tag: TagResult, value: string): TagResult {
    const textBeforeTag = tag.text.substring(0, tag.start)
        , textAfterTag = tag.text.substring(tag.end)
        , replacement = value + ' '
        , text = textBeforeTag + replacement + textAfterTag
        , start = tag.start
        , end = tag.start + replacement.length

    return {
        value,
        start,
        end,
        text,
    }
}
