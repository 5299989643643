import type { FieldRenderProps } from 'react-final-form'
import FormattedText from '_/features/text/formatted-text'
import type * as t from '_/model/text/text'
import Select from '../downshift-select'
import { compare } from '_/utils/string'

interface Props {
    id?: string
    entities: any[]
    disabled: boolean
    hasNoPermissions?: boolean
    calcId(entity: any): any
    calcName(entity: any): string | t.Text
    input: Partial<FieldRenderProps<string[]>['input']>
    className?: string
    autocomplete: boolean
    placeholder?: string
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    autoFocus?: boolean
    testId?: string
}

function MultiSelect(props: Props) {
    const value = Array.isArray(props.input.value) ? props.input.value : []
        , notSelectedEntities = props.entities.filter(_ => value.indexOf(props.calcId(_)) === -1)
        , selectedEntities = props.entities.filter(_ => value.indexOf(props.calcId(_)) !== -1)
        , handleChange = useHandleChange(props.input)
        , handleRemove = useHandleRemove(props.input)

    return (
        <div>
            <Select
                id={props.id}
                entities={notSelectedEntities}
                calcId={props.calcId}
                calcName={props.calcName}
                disabled={props.disabled}
                hasNoPermissions={props.hasNoPermissions}
                className={props.className}
                input={{ ...props.input, value: '', onChange: handleChange }}
                autocomplete={props.autocomplete}
                shouldClearInput={true}
                placeholder={props.placeholder}
                onKeyDown={props.onKeyDown}
                autoFocus={props.autoFocus}
                testId={props.testId}
            />
            {selectedEntities.map((entity, key) =>
                <span key={key}>
                    {key > 0 && <br/>}
                    <button
                        type='button'
                        className='close float-none align-text-bottom text-danger'
                        aria-label='Close'
                        onClick={_ => handleRemove(props.calcId(entity))}
                        data-testid={`${props.testId}-remove`}
                    >
                        <span aria-hidden='true'>&times;</span>
                    </button>
                    <span data-testid={`${props.testId}-item`} className='break-word'> <FormattedText text={props.calcName(entity)} /></span>
                </span>
            )}
        </div>
    )
}

export { MultiSelect as default, Props }

function useHandleChange(input: Partial<FieldRenderProps<string[]>['input']>) {
    function handleChange(value: string) {
        const nextValue = Array.isArray(input.value) ? input.value.concat() : []

        if (nextValue.indexOf(value) === -1)
            nextValue.push(value)

        if (input.onChange)
            input.onChange(nextValue.sort((a, b) => compare(a, b)))
    }

    return handleChange
}

function useHandleRemove(input: Partial<FieldRenderProps<string[]>['input']>) {
    function handleRemove(value: string) {
        const previousValue = Array.isArray(input.value) ? input.value.concat() : []
            , nextValue = previousValue.filter(_ => _ !== value)

        if (input.onChange)
            input.onChange(nextValue.length === 0 ? '' : nextValue)
    }

    return handleRemove
}
