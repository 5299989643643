import type { PredefinedLists as State } from '_/model/app-state'
import { commonReducer, handler, mergeAndReplaceEntityById } from '_/facade/reducer'
import { predefinedListLoaded } from '../redux/actions'
import * as actions from './actions'
import { compare } from '_/utils/string'

const limitsDefaultState: State['limits'] = []
    , limits = commonReducer(
        limitsDefaultState,

        handler(predefinedListLoaded, (_, payload) => payload.limits)
    )
    , gradesDefaultState: State['grades'] = []
    , grades = commonReducer(
        gradesDefaultState,
        handler(actions.gradesListLoaded, (_, payload) => payload),
        handler(actions.gradeChanged, (state, { id, grade }) => mergeAndReplaceEntityById(state, grade, id).sort((a, b) => compare(a.name, b.name))),
        handler(actions.gradeCreated, (state, grade) => state.concat(grade).sort((a, b) => compare(a.name, b.name))),
        handler(predefinedListLoaded, (_, payload) => payload.grades)
    )

export { limits, grades }
