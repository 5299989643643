import Select from '_/components/downshift-select'

import type { Group } from '_/model/scheduling/day-scheduler/types'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'

import Menu from './menu-container'

interface GroupModalProps {
    button: HTMLButtonElement | null
    planGroups: Group[]
    monitoringGroups: MonitoringGroup[]
    onAddGroup: (id: string) => void
    onClose: () => void
}

const AddGroupMenu = (props: GroupModalProps) => {
    const groups = props.monitoringGroups.filter(group =>
            !props.planGroups.some(_ => _.id === group.id)
                && group.expectations.some(e => e.sampleTypeQuantities.some(q => q.quantity > 0)))

    return (
        <Menu element={props.button} onClose={props.onClose} className='px-2'>
            <label htmlFor='groupId' className='col-form-label py-0'>Group</label>
            <Select
                id='groupId'
                className='form-control col'
                entities={groups}
                calcId={_ => _.id}
                calcName={_ => _.name}
                autocomplete
                input={{
                    onChange: props.onAddGroup,
                }}
            />
        </Menu>
    )
}

export default AddGroupMenu
