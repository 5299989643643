const UNAUTHORIZED = 401
    , FORBIDDEN = 403
    , NOT_FOUND = 404
    , SIGNATURE_FAILURE_STATUS_CODE = 418
    , LOCKED = 423
    , SERVICE_UNAVAILABLE = 503

export {
    SIGNATURE_FAILURE_STATUS_CODE,
    LOCKED,
    SERVICE_UNAVAILABLE,
    NOT_FOUND,
    UNAUTHORIZED,
    FORBIDDEN,
}
