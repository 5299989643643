import { useAction, useEffect, useSelector, useState } from '_/facade/react'

import AuditTrails from '_/components/audit-trail-list'

import * as actions from '../actions'
import type { AuditTrail } from '_/model/audit-trail/types'

function ApiKeysAuditTrail() {
    const trail = useAuditTrail()

    return <AuditTrails
        trail={trail}
        ownScroll
        stickyHeader
    />
}

export default ApiKeysAuditTrail

function useAuditTrail() {
    const load = useAction(actions.loadUserTrail)
        , id = useSelector(_ => _.router.route?.params.id)
        , [trail, setTrail] = useState<AuditTrail | undefined>()

    useEffect(
        () => {
            if (id)
                load(id).then(_ => setTrail(_))
        },
        [load, id]
    )

    return trail
}
