import { React, useAction, useEffect, useState } from '_/facade/react'
import BLANK_IMAGE from '_/constants/blank-image'
import type { Rectangle, GrowthPatch } from '_/model/smart-check/image'
import { drawText } from '_/model/canvas/draw'
import { systemFontStack } from '_/model/analysis/system-font-stack'
import { dropFields } from '_/utils/object'
import { loadImage, exportCanvasToBlob } from '_/utils/image'
import * as a from '../actions'

interface Props extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> {
    imageId: string
    type: 'original' | 'medium' | 'thumbnail'
    openable?: boolean
    cropPlate?: boolean
    plateRect?: Rectangle
    growths?: GrowthPatch[]
    showGrowthCountOverPatch?: boolean
    testId?: string
}

function SmartImage(props: Props) {
    const imageProps =
            dropFields(
                props,
                'openable',
                'imageId',
                'type',
                'onClick',
                'plateRect',
                'cropPlate',
                'growths',
                'showGrowthCountOverPatch',
                'testId',
            )
        , getImageDownloadTokens = useAction(a.getDownloadTokens)
        , getImageInfo = useAction(a.getInfo)
        , src = useSrc(props)

    if (src === undefined) {
        const noAltProps = dropFields(imageProps, 'alt')
        return <img src={BLANK_IMAGE} {...noAltProps} />
    }

    return <img
        {...imageProps}
        // Somehow fixes net::ERR_FAILED in chrome.
        // It occurs when there two img tags that refer same image
        // but one of them has crossOrigin='anonymous' attribute while other has not
        // and this image is cached.
        // That other image with crossOrigin='anonymous' is created at loadImage function
        crossOrigin='anonymous'
        src={src}
        onClick={e => {
            if (props.openable) {
                Promise
                    .all([
                        getImageDownloadTokens(props.imageId),
                        getImageInfo(props.imageId),
                    ])
                    .then(([token, info]) => {
                        const params = {
                            sourceUrl: token['original'],
                            imageName: info.fileName,
                        }

                        window.open('/sample-image-preview.html?p=' + encodeURIComponent(JSON.stringify(params)))
                    })
            }

            if (props.onClick)
                props.onClick(e)
        }}
        data-testid={props.testId}
    />
}

export default SmartImage

function useSrc(props: Props): string | undefined {
    const [baseSrc, setBaseSrc] = useState<string>()
        , [image, setImage] = useState<HTMLImageElement>()
        , [src, setSrc] = useState<string>()
        , getDownloadTokens = useAction(a.getDownloadTokens)

    useEffect(
        () => {
            let disposed = false

            getDownloadTokens(props.imageId)
                .then(_ => _[props.type])
                .then(baseSrc => {
                    if (!disposed)
                        setBaseSrc(baseSrc)
                })

            return () => {
                disposed = true
                setBaseSrc(undefined)
            }
        },
        [props.imageId, props.type, getDownloadTokens]
    )

    useEffect(
        () => {
            if (baseSrc === undefined || !props.plateRect && !props.cropPlate) {
                setSrc(baseSrc)
                return () => setSrc(undefined)
            }

            let disposed = false
            loadImage(baseSrc).then(img => {
                if (disposed)
                    return

                setImage(img)
            })

            return () => {
                disposed = true
                setImage(undefined)
            }
        },
        [baseSrc, props.plateRect, props.cropPlate]
    )

    useEffect(
        () => {
            if (!image)
                return

            const blob = applyRect(image, props.plateRect, props.cropPlate, props.growths, props.showGrowthCountOverPatch)

            const url = URL.createObjectURL(blob)
            setSrc(url)

            return () => {
                setSrc(undefined)
                URL.revokeObjectURL(url)
            }
        },
        [image, props.plateRect, props.growths, props.cropPlate, props.showGrowthCountOverPatch]
    )

    return src
}

const PADDING = 10
    , LINE_WIDTH = 4

function applyRect(img: HTMLImageElement, rect: Rectangle | undefined, crop: boolean | undefined, growths: GrowthPatch[] = [], showPatchSuggestedGrowthCount = false): Blob {
    const canvas = document.createElement('canvas')
        , ctx = canvas.getContext('2d')!
        , width = img.naturalWidth
        , height = img.naturalHeight

    canvas.width = width
    canvas.height = height
    ctx.drawImage(img, 0, 0, width, height)

    if (rect) {
        ctx.save()

        ctx.strokeStyle = '#2FF77E'
        ctx.lineWidth = LINE_WIDTH
        ctx.setLineDash([9, 3])
        ctx.strokeRect(rect.xMin - PADDING * 4, rect.yMin - PADDING * 4, rect.xMax - rect.xMin + PADDING * 8, rect.yMax - rect.yMin + PADDING * 8)

        ctx.restore()
    }

    ctx.save()

    ctx.lineWidth = LINE_WIDTH
    growths.forEach(patch => {
        ctx.strokeStyle = patch.mold ? '#FF1744' : '#2FF77E'
        // add extra pixels from each side of growths patch for better UX
        const x = patch.rectangle.xMin - PADDING
            , y = patch.rectangle.yMin - PADDING
            , width = patch.rectangle.xMax - patch.rectangle.xMin + PADDING * 2
            , height = patch.rectangle.yMax - patch.rectangle.yMin + PADDING * 2

        ctx.strokeRect(x, y, width, height)
        if (showPatchSuggestedGrowthCount)
            printModelSuggestedCount(ctx, patch)
    })

    ctx.restore()

    if (!crop || !rect)
        return exportCanvasToBlob(canvas)

    return cropRect(canvas, rect)
}

function printModelSuggestedCount(ctx: CanvasRenderingContext2D, patch: GrowthPatch) {
    if (patch.cfu === 1)
        return

    const suggestedCountText = patch.cfu.toString()
        , fontSize = 28
        , aboveLeftTopCorner: [number, number] = [patch.rectangle.xMin - PADDING - LINE_WIDTH / 2, patch.rectangle.yMin - PADDING * 2.5]
        , font = `bold ${fontSize}px ${systemFontStack}`

    drawText(ctx, aboveLeftTopCorner, suggestedCountText, font, '#2FF77E')
}

function cropRect(img: CanvasImageSource, rect: Rectangle): Blob {
    const width = rect.xMax - rect.xMin
        , height = rect.yMax - rect.yMin

    // draw cropped image
    const canvas = document.createElement('canvas')
        , ctx = canvas.getContext('2d')!

    canvas.width = width
    canvas.height = height
    ctx.drawImage(img, rect.xMin, rect.yMin, width, height, 0, 0, width, height)

    return exportCanvasToBlob(canvas)
}
