import type { FloorPlanUploadService } from '_/model/floor-plan/upload/upload-service'
import { loadImageFromBlob, convertImageToJPEG } from '_/utils/image'

function uploadTransaction(blob: Blob, service: FloorPlanUploadService) {
    const dimensionsPromise = (blob: Blob) =>
            loadImageFromBlob(blob)
                .then(_ => ({
                    width: _.naturalWidth,
                    height: _.naturalHeight,
                }))

    return convertImageToJPEG(blob)
        .then(_ =>
            Promise.all([service.startUpload(_), dimensionsPromise(_)])
                .then(([uploadResult, dimensions]) =>
                    ({
                        ...uploadResult,
                        ...dimensions,
                    }))
        )
}

export {
    uploadTransaction,
}
