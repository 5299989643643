import type SampleDetails from '../sample-details'
import type { SampleDetailsEdit } from './types'
import type { BookedInSampleRequest } from '_/model/sample/booking/types'
import type SampleService from '_/services/sample-service'
import type ReasonService from '_/model/reason/reason-service'
import type { FieldsDiff } from '_/utils/object'
import { diffObject } from '_/utils/object'
import { getCustomFieldsDiff } from '_/features/samples/helpers'
import * as s from '_/model/context/electronic-signature-settings'
import { electronicSignatureSettingsEnabled } from '_/model/critical-change-reason/helpers'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import reasonRequired from './reason-rule'
import { noop } from '_/utils/function'
import { signatureFailure } from '_/model/error/error'

function updateTransaction(
    sample: SampleDetails,
    oldSampleEdit: SampleDetailsEdit,
    newSampleEdit: SampleDetailsEdit,
    signatureSettings: s.ElectronicSignatureSettings[],
    api: SampleService,
    reasonService: ReasonService,
    approvalInfo?: ApprovalInfo
) {
    const changePromise = getChangePromise(sample.id, oldSampleEdit, newSampleEdit, api)
        , sampleDiff = diffObject(oldSampleEdit, newSampleEdit)

    if (!sampleDiff)
        return Promise.resolve()

    if (sampleDiff.fields)
        sampleDiff.fields = getCustomFieldsDiff(sampleDiff.fields, oldSampleEdit.fields, newSampleEdit.fields)

    const reasonNotRequired = !reasonRequired(sample.editedFields, sampleDiff)

    function updateWithReason(reason?: string, error?: string): Promise<void> {
        let approvalInfoReason = ''

        return getReasonPromise(signatureSettings, reasonService, approvalInfo, reasonNotRequired, reason, error)
            .then(approvalInfo => {
                approvalInfoReason = approvalInfo.reason
                return changePromise(approvalInfo)
                    .then(() => sampleDiff && updateSampleRequired(sampleDiff)
                        ? api.save(sample.id, { approvalInfo, ...sampleDiff })
                        : Promise.resolve())
            })
            .then(
                noop,
                _ => signatureFailure(_) && !approvalInfo ? updateWithReason(approvalInfoReason, _.statusText) : Promise.reject(_)
            )
    }

    return updateWithReason()
}

function updateSampleRequired(sampleDiff: FieldsDiff<SampleDetailsEdit>) {
    return sampleDiff.isForReview !== undefined
        || sampleDiff.monitoringState !== undefined
        || 'sampleInvestigationReferences' in sampleDiff
        || sampleDiff.fields !== undefined
}

function getReasonPromise(
    signatureSettings: s.ElectronicSignatureSettings[],
    reasonService: ReasonService,
    approvalInfo?: ApprovalInfo,
    reasonNotRequired?: boolean,
    reason?: string,
    error?: string,
) {
    if (approvalInfo)
        return Promise.resolve(approvalInfo)

    if (reasonNotRequired)
        return Promise.resolve({reason: ''})

    const signatureRequired = electronicSignatureSettingsEnabled(s.EDITING_SAMPLES, signatureSettings)

    return reasonService.getReason(signatureRequired, reason, error)
}

function getChangePromise(id: string, oldSample: Partial<SampleDetailsEdit>, newSample: Partial<SampleDetailsEdit>, api: SampleService) {
    const nullifiedChanged = oldSample.nullified !== newSample.nullified
        , compromisedChanged = oldSample.compromised !== newSample.compromised
        , manuallyActionLimitBreachedChanged = oldSample.manuallyActionLimitBreached !== newSample.manuallyActionLimitBreached

    if (!nullifiedChanged && !compromisedChanged && !manuallyActionLimitBreachedChanged)
        return () => Promise.resolve()

    const changeNullified = nullifiedChanged ? (approvalInfo: ApprovalInfo) => api.changeNullified(id, { nullified: !!newSample.nullified }, approvalInfo) : () => Promise.resolve()
        , changeCompromised = compromisedChanged ? (approvalInfo: ApprovalInfo) => api.changeCompromised(id, !!newSample.compromised, true, approvalInfo) : () => Promise.resolve()
        , changeManualActionBreach = manuallyActionLimitBreachedChanged ? (approvalInfo: ApprovalInfo) => api.changeManualActionBreach(id, { breached: !!newSample.manuallyActionLimitBreached }, approvalInfo) : () => Promise.resolve()
        , changeCompromisedAndManualActionBreach = (approvalInfo: ApprovalInfo) => compromisedChanged && !newSample.compromised && manuallyActionLimitBreachedChanged && !newSample.manuallyActionLimitBreached
            ? changeManualActionBreach(approvalInfo).then(() => changeCompromised(approvalInfo))
            : changeCompromised(approvalInfo).then(() => changeManualActionBreach(approvalInfo))

    if (nullifiedChanged && newSample.nullified) {
        return (approvalInfo: ApprovalInfo) => changeCompromisedAndManualActionBreach(approvalInfo)
            .then(() => changeNullified(approvalInfo))
    }

    return (approvalInfo: ApprovalInfo) => changeNullified(approvalInfo)
        .then(() => changeCompromisedAndManualActionBreach(approvalInfo))
        .then(noop)
}

function editBookedInSampleTransaction(
    id: string,
    current: Partial<BookedInSampleRequest>,
    next: Partial<BookedInSampleRequest>,
    api: SampleService,
) {
    const diff = diffObject(current, next)
    if (!diff)
        return Promise.resolve()

    if (diff.fields)
        diff.fields = getCustomFieldsDiff(diff.fields, current.fields!, next.fields!)

    return api.editBookedInSample(id, diff)
}

function changeCompromisedTransaction(
    id: string,
    compromised: boolean,
    checkBreach: boolean,
    approvalInfo: ApprovalInfo,
    api: SampleService,
) {
    return api.changeCompromised(id, compromised, checkBreach, approvalInfo)
}

export {
    updateTransaction,
    editBookedInSampleTransaction,
    changeCompromisedTransaction,
}
