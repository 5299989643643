import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import uploaderFactory from '_/model/smart-check/uploader'
import type SmartCheckService from '_/services/smart-check-service'
import * as a from './actions'

const factory = (service: SmartCheckService): EffectsFactory => (dispatch, getState) => {
    const uploader = uploaderFactory(service)

    return [
        handler(a.removeImage, service.removeImage),
        handler(a.getDownloadTokens, service.getDownloadTokens),
        handler(a.getInfo, service.getImageInfo),
        handler(a.takePhoto, payload => {
            const capturePromise = payload.provider.type === 'remote'
                    ? service.captureRemoteImage(payload.provider.deviceId)
                    : payload.provider.type === 'webcam'
                        ? Promise.resolve(dispatch(a.captureWebcam()))
                        : Promise.resolve(payload.provider.image)
                , smartCheck = getState().auth.user!.membership.context.smartCheckEnabled
                , moldPredictor = getState().auth.user!.membership.context.moldPredictorEnabled
                , result = uploader.upload(capturePromise, payload.previousImageId, payload.plateType, smartCheck, moldPredictor, payload.entityId)

            return result
        })
    ]
}

export default factory
