import { Form, Field } from 'react-final-form'
import { classnames, useSelector, useAction, useState } from '_/facade/react'

import { CheckboxField, showFieldError } from '_/components/form'
import { Modal, ModalBody, ModalHeader } from '_/components/modal'
import { Close } from '_/components/button'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import UserTagInput from '_/components/tag/user-tag-input'
import SignatureFields from '_/features/critical-change-reason/signature-fields'

import Error from '_/components/form/field-error'

import * as actions from '../../actions'
import * as warningActions from '_/features/unsaved-changes/actions'

import type { SampleDetailsEdit } from '_/model/sample/edit/types'

import { validateComment } from '../../validate'
import type { ValidationResult} from '_/utils/form/validate'
import { required } from '_/utils/form/validate'

import * as setting from '_/model/context/electronic-signature-settings'
import { electronicSignatureSettingsEnabled } from '_/model/critical-change-reason/helpers'
import type { SignatureMethod } from '_/model/critical-change-reason/types'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import ReasonModalFooter from '_/features/critical-change-reason/modal-footer'

import { useUnsavedChangesTracker } from '_/hooks/shared-hooks'

interface SampleIssue {
    isForReview: boolean
    compromised: boolean
    nullified: boolean
    manuallyActionLimitBreached: boolean
    message: string
    password?: string
    idToken?: string
    sessionState?: string
}

interface Props {
    isRead: boolean
    entity: SampleDetailsEdit
    sampleId: string
    onSubmit: (sample: SampleDetailsEdit, approvalInfo: ApprovalInfo) => Promise<void>
    onClose(): void
}

const FORM_ID = 'raise-issue-modal'

function RaiseIssueModal(props: Props) {
    const signatureSettings = useSelector(_ => _.auth.electronicSignatureSettings)
        , ssoESignatureAllowed = useSelector(_ => _.auth.permissions.useSsoESignature)
        , signatureRequired = electronicSignatureSettingsEnabled(setting.EDITING_SAMPLES, signatureSettings)
        , [signatureMethod, setSignatureMethod] = useState<SignatureMethod | undefined>(ssoESignatureAllowed && signatureRequired ? undefined : 'smartControl')
        , handleSaveChanges = useSubmit(props.entity, props.onSubmit, props.onClose, props.sampleId)
        , handleClose = useClose(props.onClose)
        , [initialValues] = useState({
            message: '',
            password: undefined,
            idToken: undefined,
            sessionState: undefined,
            isForReview: props.entity.isForReview,
            compromised: props.entity.compromised,
            nullified: props.entity.nullified,
            manuallyActionLimitBreached: props.entity.manuallyActionLimitBreached,
        })

    useUnsavedChangesTracker(props.onClose)

    function validate(sampleIssue: Partial<SampleIssue>, signatureRequired: boolean) {
        const result: ValidationResult<Partial<SampleIssue>> = {}

        const message = validateComment({ message: sampleIssue.message ?? '' }, true)
            , idToken = sampleIssue.idToken
            , password = !idToken && signatureRequired && required('Password')(sampleIssue.password)

        if (message)
            result.message = message

        if (password)
            result.password = password

        return result
    }

    return (
        <Modal isOpen onClose={handleClose}>
            <Form<SampleIssue>
                onSubmit={handleSaveChanges}
                initialValues={initialValues}
                validate={_ => validate(_, signatureRequired)}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form} target={FORM_ID} />

                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4 data-testid='raise-issue-modal-title'>Raise an issue</h4>
                                    <Close onClick={handleClose} testId='close-raise-issue-modal' />
                                </div>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <CheckboxField
                                name='isForReview'
                                id='isForReview'
                                className='mb-2'
                                autoFocus
                                testId='field-is-for-review'
                            >
                                Viable sample needs review
                            </CheckboxField>
                            <CheckboxField
                                name='compromised'
                                id='compromised'
                                className='mb-2'
                                disabled={form.values.nullified}
                                testId='field-compromised'
                            >
                                Viable sample is compromised
                            </CheckboxField>
                            <CheckboxField
                                name='nullified'
                                id='nullified'
                                className='mb-2'
                                disabled={form.values.compromised || form.values.manuallyActionLimitBreached}
                                testId='field-nullified'
                            >
                                Viable sample is nullified
                            </CheckboxField>
                            <CheckboxField
                                name='manuallyActionLimitBreached'
                                id='manuallyActionLimitBreached'
                                className='mb-2'
                                disabled={form.values.nullified || !props.isRead}
                                title={!props.isRead ? 'Only read viable samples may be manually marked as action limit breaching' : ''}
                                testId='field-manual-action-breach'
                            >
                                Manual action limit breach
                            </CheckboxField>
                            {!props.entity.nullified && form.values.nullified &&
                                <p className='text-danger my-2'>
                                    Marking viable sample as nullified should only be used for viable samples that should not have been booked into SmartControl.
                                    <br />
                                    If the viable sample is genuine but has a problem associated with it, consider marking as compromised instead
                                </p>
                            }
                            <Field name='message' render={_ =>
                                <>
                                    <label className='form-label mt-3'>Comment</label>
                                    <UserTagInput
                                        className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                                        {..._.input}
                                        testId='raise-issue-modal-comments'
                                    />
                                    <Error field={_} />
                                </>
                            } />

                            {signatureRequired &&
                                <SignatureFields
                                    signatureMethod={signatureMethod}
                                    ssoESignatureAllowed={ssoESignatureAllowed}
                                    setSignatureMethod={setSignatureMethod}
                                />
                            }
                        </ModalBody>
                        <ReasonModalFooter
                            form={form}
                            cancel={handleClose}
                            signatureRequired={signatureRequired}
                            signatureMethod={signatureMethod}
                            ssoESignatureAllowed={ssoESignatureAllowed}
                            setSignatureMethod={setSignatureMethod}
                        />
                    </form>
                }
            />
        </Modal>
    )
}

export default RaiseIssueModal

function useSubmit(
    entity: SampleDetailsEdit,
    onSubmit: (sample: SampleDetailsEdit, approvalInfo: ApprovalInfo) => Promise<void>,
    onClose: () => void,
    sampleId: string,
) {
    const addSampleComments = useAction(actions.addSampleComments)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)

    function handleSaveChanges(sampleIssue: SampleIssue) {
        const updatedSample = {
            ...entity,
            isForReview: sampleIssue.isForReview,
            compromised: sampleIssue.compromised,
            nullified: sampleIssue.nullified,
            manuallyActionLimitBreached: sampleIssue.manuallyActionLimitBreached,
        }

        return onSubmit(updatedSample, { reason: sampleIssue.message, password: sampleIssue.password, idToken: sampleIssue.idToken, sessionState: sampleIssue.sessionState })
            .then(() => addSampleComments({ sampleId, sampleComments: [{ message: sampleIssue.message }] }))
            .then(() => hasUnsavedChanges(false, FORM_ID))
            .then(onClose)
    }

    return handleSaveChanges
}

function useClose(onClose: () => void) {
    const showWarning = useAction(warningActions.showWarning)
        , unsavedChangeTargets = useSelector(_ => _.unsavedChange.unsavedChangeTargets)

    function handleClose() {
        if (unsavedChangeTargets.some(_ => _ === FORM_ID))
            showWarning({ showWarning: true })
        else
            onClose()
    }
    return handleClose
}
