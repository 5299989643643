import { useSelector, useState } from '_/facade/react'

import { GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION } from '_/constants/sample-status'

import { useTimeService } from '_/components/time'
import { noop } from '_/utils/function'

import { useScrollTo } from '_/features/reports/hooks'
import ActionButtons from '_/features/reports/table-action-buttons'
import PieChart from '_/features/reports/locations-report/organism-pie-chart/pie-chart'
import { getSampleListParams } from '_/model/reports/operators-report/helpers'
import { computeAuthorInfo } from '_/features/analysis/ui/helpers'
import type { OperatorsReport } from '_/model/reports/operators-report/types'
import type { OrganismGraphSeries } from '_/model/reports/types'
import * as t from '_/model/text/text'
import NoDataAvailable from '../no-data-available'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    operatorReport: OperatorsReport
    series: OrganismGraphSeries[]
    operatorName: t.Text
    showSpinner: boolean
    printMode: boolean
    footer: string[]
}

function OrganismGraph(props: Props) {
    const timeService = useTimeService()
        , [divElement, setDivElement] = useState<HTMLDivElement | null>(null)
        , user = useSelector(_ => _.auth.user)
        , author = computeAuthorInfo(timeService, user)
        , title = [t.defaultTextNode('Organisms recovered in viable samples with '), ...props.operatorName, t.defaultTextNode(' as operator')]

    useScrollTo(divElement, 'organism-pie-chart')

    return (
        <div className='col-6 break-block width-print-100' ref={setDivElement}>
            <div className='block-border text-center py-3'>
                {props.series.length === 0 || props.showSpinner
                    ? <div className='text-center'>
                        <h4 className='mt-2 mb-4'><FormattedText text={title} /></h4>
                        <NoDataAvailable showSpinner={props.showSpinner}>
                            <span className='text-muted text-uppercase'>No data available</span>
                        </NoDataAvailable>
                    </div>
                    : <PieChart
                        series={props.series}
                        footer={props.printMode ? props.footer : []}
                        author={author}
                        title={t.plainText(title)}
                        onLegendItemClick={noop} // Need to be changed when Organism report will be ready
                        renderActionButtons={onExport =>
                            props.printMode
                                ? null
                                : <ActionButtons
                                    disabledGoToSamplesButton={props.series.length === 0}
                                    onExport={() => {
                                        const exportDate = timeService.formatCtzDate(timeService.now())

                                        onExport(`SmartControl Graph for ${t.plainText(props.operatorName)} downloaded on ${exportDate}`, props.footer)
                                    }}
                                    routeParams={{...getSampleListParams(props.operatorReport, timeService), statuses: [GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION]}}
                                    className='text-end me-3'
                                />
                        }
                    />
                }
            </div>
        </div>
    )
}

export default OrganismGraph
