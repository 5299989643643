export const NOT_YET_IDENTIFIED = 0
           , ORGANISM = 1
           , ORGANISM_TYPE = 2
           // , MOULD = 3 merged into ORGANISM_TYPE
           // , YEAST = 4 merged into ORGANISM_TYPE
           , CATALASE = 5
           , OXIDASE = 6
           , OXIDATION_FERMENTATION = 7
           , COAGULASE = 8
           , NO_ID_REQUIRED = 9
           , CORRECTIONAL_MPN_VALUE = 10

import type * as self from './identification-type'
type IdentificationType = Extract<(typeof self)[keyof typeof self], number>

const IDENTIFICATION_TYPE: readonly { id: IdentificationType, name: string }[] = [
    {
        id: NOT_YET_IDENTIFIED,
        name: 'Not yet identified',
    },
    {
        id: ORGANISM,
        name: 'Organisms',
    },
    {
        id: ORGANISM_TYPE,
        name: 'Organism type',
    },
    {
        id: CATALASE,
        name: 'Catalase',
    },
    {
        id: OXIDASE,
        name: 'Oxidase',
    },
    {
        id: OXIDATION_FERMENTATION,
        name: 'Oxidation Fermentation',
    },
    {
        id: COAGULASE,
        name: 'Coagulase',
    },
    {
        id: NO_ID_REQUIRED,
        name: 'No ID required'
    },
    {
        id: CORRECTIONAL_MPN_VALUE,
        name: 'Correctional MPN value'
    }
]

const IDENTIFICATION_WITH_ARGUMENT = [ORGANISM, ORGANISM_TYPE, CATALASE, OXIDASE, OXIDATION_FERMENTATION, COAGULASE]

export type {
    IdentificationType,
}

export {
    IDENTIFICATION_TYPE,
    IDENTIFICATION_WITH_ARGUMENT,
}
