import * as fieldIndex from '_/constants/custom-field-index'
import type { KpiData, KpiBlockSamplesInfo } from '_/model/reports/types'
import type TimeService from '_/services/time-service'
import { sampleListRouteParams } from '../helpers'
import type { OperatorsReport } from './types'

function getSampleListParams(operatorReport: OperatorsReport, timeService: TimeService, includeBreaches?: boolean) {
    return {
        ...sampleListRouteParams(operatorReport, timeService, includeBreaches),
        fields: [{ index: fieldIndex.OPERATORS_IDS as fieldIndex.FieldIndex, value: [operatorReport.operatorId] }],
    }
}

function calculateTrend(block: KpiData | undefined) {
    if (!block || block.previousPeriodInfo.readSamplesCount === 0)
        return undefined

    const calcTrend = (block: KpiBlockSamplesInfo) => block.readSamplesCount === 0 ? 0 : block.samplesWithCfuCount / block.readSamplesCount
        , currentPeriod = calcTrend(block.currentPeriodInfo)
        , lastPeriod = calcTrend(block.previousPeriodInfo)

    if (currentPeriod > lastPeriod)
        return 1

    if (currentPeriod < lastPeriod)
        return -1

    return 0
}

export {
    getSampleListParams,
    calculateTrend,
}
