import { classnames } from '_/facade/react'

type ClassName = { className?: string | undefined }

const Container: React.FC<ClassName> =
    props => <div className={classnames('container', props.className)}>{props.children}</div>

const Row: React.FC<ClassName> =
    props => <div className={classnames('row', props.className)}>{props.children}</div>

interface ColProps extends ClassName {
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | undefined
    bp?: 'sm' | 'md' | 'lg' | 'xl' | undefined
}

const Col: React.FC<ColProps> =
    props => <div className={classnames(formatCol(props.size, props.bp), props.className)}>{props.children}</div>

const UnitContainer: React.FC = props =>
    <Container>
        <Row>
            <Col>
                {props.children}
            </Col>
        </Row>
    </Container>

export {
    Container,
    Row,
    Col,

    UnitContainer,
}

function formatCol(size: ColProps['size'], bp: ColProps['bp']) {
    return 'col'
        + (bp ? '-' + bp : '')
        + (size ? '-' + size : '')
}
