import { NO_SESSION_NAME } from '_/constants/system-words'

const NO_SESSION_ID = 'b24d3db3-564d-4d04-9a6a-169267bae40c'
    , NO_SESSION = {
        id: NO_SESSION_ID,
        name: NO_SESSION_NAME,
        isActive: true,
    }

export {
    NO_SESSION,
    NO_SESSION_ID,
    NO_SESSION_NAME,
}
