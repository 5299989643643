import type { SampleEditedInfo } from '_/model/sample/sample'
import { useTimeService } from '_/components/time'
import { getEditedFieldHoverText } from '../helpers'
import FormattedText from '_/features/text/formatted-text'
import * as t from '_/model/text/text'

interface Props {
    editedInfo: SampleEditedInfo | undefined
    breakToNewLine?: boolean | undefined
}

function EditedLabel(props: Props) {
    const timeService = useTimeService()
    if (!props.editedInfo)
        return null

    return (
        <span
            className={`${props.breakToNewLine ? 'd-block' : 'ms-1'}`}
            title={getEditedFieldHoverText(props.editedInfo, timeService)}
        >
            <FormattedText text={[t.systemTextNode(props.editedInfo.automatedAction ? '(automated action)' : '(edited)')]} />
        </span>
    )
}

export default EditedLabel
