const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL'
    , DELETION_CONFIRMATION_MODAL = 'DELETION_CONFIRMATION_MODAL'
    , DISABLE_CONFIRMATION_MODAL = 'DISABLE_CONFIRMATION_MODAL'
    , WARNING_MODAL = 'WARNING_MODAL'

import type * as self from './confirmation-type'
type ConfirmationType = (typeof self)[keyof typeof self]

export {
    CONFIRMATION_MODAL,
    DELETION_CONFIRMATION_MODAL,
    DISABLE_CONFIRMATION_MODAL,
    WARNING_MODAL,
    ConfirmationType,
}
