import { Table } from '_/components/table'
import FormattedText from '_/features/text/formatted-text'

import * as fieldIndex from '_/constants/custom-field-index'
import type { PredefinedLists } from '_/model/app-state'

import { customFieldName } from '_/model/predefined-lists/custom-field/custom-field'

import { getFieldValue } from '../helpers'
import TotalCFU from '../list/total-cfu'
import LimitInfo from '../limit-info'
import Contaminations from '_/components/contaminations'
import NoDataAvailable from '_/features/reports/no-data-available'
import * as f from '_/model/sample/format'
import { useTimeService } from '_/components/time'
import { useRelatedSamples } from './shared-hooks'
import type { SampleReportSearchQuery } from '_/model/sample/search'

interface Props {
    searchQuery: SampleReportSearchQuery
    predefinedLists: PredefinedLists
}

function SampleOperatorInfo(props: Props) {
    const timeService = useTimeService()
        , [samples, showSpinner] = useRelatedSamples(props.searchQuery)

    function columnName(fieldIndex: fieldIndex.FieldIndex) {
        return customFieldName(fieldIndex, props.predefinedLists.customFields)
    }

    return (
        <Table>
            <thead className='thead'>
                <tr className='table-header-thin' data-testid='operator-info-table-header'>
                    <th>{columnName(fieldIndex.EXPOSURE_DATE)}</th>
                    <th>Location</th>
                    <th>Grade</th>
                    <th>{columnName(fieldIndex.SESSION_ID)}</th>
                    <th>CFUs</th>
                    <th>Identification</th>
                    <th>{columnName(fieldIndex.SAMPLE_TYPE_ID)}</th>
                </tr>
            </thead>
            <tbody>
                {samples.length > 0 && !showSpinner
                    ? samples.map(_ =>
                        <tr key={_.id}>
                            <td className='sample-list__column-word-wrap-wide'>
                                {timeService.formatCtzDate(getFieldValue(_.fields, fieldIndex.EXPOSURE_START_DATE))}
                            </td>
                            <td className='sample-list__column-word-wrap-wide'>
                                <FormattedText text={f.formatExposureLocation(_.monitoringState, _.fields, props.predefinedLists)} />
                            </td>
                            <td>
                                <FormattedText text={f.formatExposureLocationGrade(_.monitoringState, _.fields, props.predefinedLists)} />
                            </td>
                            <td className='sample-list__column-word-wrap-narrow'>
                                <FormattedText text={f.formatByFieldIndex(fieldIndex.SESSION_ID, _.fields, props.predefinedLists)} />
                            </td>
                            <td className='sample-list__column-word-wrap-narrow'>
                                <TotalCFU sample={_}/>
                                <LimitInfo sample={_}/>
                            </td>
                            <td className='sample-list__column-word-wrap-narrow'>
                                <Contaminations identifications={_.identifications} />
                                <Contaminations identifications={_.optionalIdentifications} />
                            </td>
                            <td className='sample-list__column-word-wrap-wide'>
                                <FormattedText text={f.formatByFieldIndex(fieldIndex.SAMPLE_TYPE_ID, _.fields, props.predefinedLists)} />
                            </td>
                        </tr>
                )
                : <tr>
                    <td className='text-center' colSpan={7}>
                        <NoDataAvailable showSpinner={showSpinner}>
                            <span className='text-center text-muted text-uppercase'>
                                No viable samples recorded with this operator
                            </span>
                        </NoDataAvailable>
                    </td>
                </tr>
            }
            </tbody>
        </Table>
    )
}

export default SampleOperatorInfo
