import { useSelector, useAction, useState } from '_/facade/react'

import { useSessions } from '_/hooks/shared-hooks'

import { EmptyTableMessage, Table } from '_/components/table'
import PageHeader from '_/components/page-header'
import { LinkButton } from '_/components/link'

import type { SampleSession } from '_/model/predefined-lists/session/types'
import * as routes from '_/constants/routes'

import * as actions from './actions'
import SampleSessionModal from './modal/sample-session-modal'
import SampleSessionRow from './sample-session-row'

function SampleSessionList() {
    useSessions()

    const predefinedLists = useSelector(_ => _.predefinedLists)
        , permissions = useSelector(_ => _.auth.permissions)
        , editMode = useSelector(
            _ => [routes.SETTINGS_SESSIONS_CREATE, routes.SETTINGS_SESSIONS_EDIT].some(it => _.router.route?.name === it)
        )
        , [draggedSession, setDraggedSession] = useState<SampleSession | undefined>()
        , changeSessionPosition = useAction(actions.changePosition)

    function handleDragStart(draggedSession: SampleSession) {
        setDraggedSession(draggedSession)
    }

    function handleDragEnd() {
        setDraggedSession(undefined)
    }

    function canMoveAbove(destination: SampleSession): boolean {
        if (!draggedSession)
            return false

        return draggedSession.position !== destination.position
            && (draggedSession.position - 1) !== destination.position
    }

    function canMoveBelow(destination: SampleSession): boolean {
        if (!draggedSession)
            return false

        return draggedSession.position !== destination.position
            && (draggedSession.position + 1) !== destination.position
    }

    function handleDragDrop(destination: SampleSession) {
        changeSessionPosition({ id: draggedSession!.id, position: destination.position })
    }

    return (
        <>
            {editMode && <SampleSessionModal />}

            <PageHeader title='Sessions'>
                <LinkButton
                    className='btn-primary'
                    routeName={routes.SETTINGS_SESSIONS_CREATE}
                    hasNoPermissions={!permissions.editSampleSession}
                    testId='add-new-session'
                >
                    New session
                </LinkButton>
            </PageHeader>
            <div className='p-3 mb-0'>
                Use this page to:
                <br />
                Set the sessions used for monitoring samples in your facility
                <br />
                Sort sessions using drag and drop
                <br />
                <LinkButton
                    routeName={routes.HELP}
                    routeParams={{ page: '/tutorials-sc/site_admin/predefined_lists/sessions/' }}
                    className='btn-link ps-0'
                >
                    View help
                </LinkButton>
            </div>

            <div className="overflow-auto flex-fill">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {predefinedLists.sampleSessions.length === 0
                            ? <EmptyTableMessage colSpan={3} message='There are no sessions at the moment' />
                            : predefinedLists.sampleSessions.map(_ =>
                                <SampleSessionRow
                                    entity={_}
                                    canEdit={permissions.editSampleSession}
                                    onDragDrop={handleDragDrop}
                                    onDragEnd={handleDragEnd}
                                    onDragStart={handleDragStart}
                                    canMoveAbove={canMoveAbove}
                                    canMoveBelow={canMoveBelow}
                                    key={_.id}
                                />
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default SampleSessionList
