import { useState, useLayoutEffect } from '_/facade/react'
import * as breachType from '_/constants/sample-breach-type'
import Octagon from './shape/octagon'
import Triangle from './shape/triangle'
import Rectangle from './shape/rectangle'

const config = {
    [breachType.ACTION_LIMIT]: {
        color: '#d32f2f',
        shape: Octagon,
    },
    [breachType.ALERT_LIMIT]: {
        color: '#ef6c00',
        shape: Triangle,
    },
    [breachType.COMPROMISED]: {
        color: '#1976d2',
        shape: Rectangle,
    },
}

interface Props {
    className?: string
    count?: number
    type: Exclude<breachType.BreachTypeWithCompromised, typeof breachType.NONE>
    withRef: boolean
    testId?: string
    maxCount?: number
}

function BreachIcon(props: Props) {
    const count = props.maxCount && props.count && props.count >= props.maxCount ? '!!!' : props.count
        , [textWidth, capHeight, textRef, textBaselineRef, textCentralRef] = useMetrics(count)

    const fontSize = 14
        , padding = 4
        , iconSize = capHeight
        , iconOffset = iconSize / 2 + padding
        , height = iconSize + padding * 2
        , width = iconSize + textWidth + padding * (props.count == null ? 2 : 3)
        , textX = iconSize + padding * 2
        , textY = iconSize + padding
        , Shape = config[props.type].shape
        , color = config[props.type].color

    return (
        <svg
            className={props.className}
            width={width}
            height={height}
            version='1.1'
            viewBox={`0 0 ${width} ${height}`}
            xmlns='http://www.w3.org/2000/svg'
            fill='white'
            stroke='white'
            style={{
                verticalAlign: -padding,
            }}
            data-testid={props.testId}
        >
            <g fill='none' stroke='none'>
                <text ref={textBaselineRef} dominantBaseline='baseline'>
                    0
                </text>

                <text ref={textCentralRef} dominantBaseline='central'>
                    0
                </text>
            </g>

            <rect width={width} height={height} rx={height * 0.2} fill={color} stroke='none' data-testid={`breach-type-${props.type}`} />

            <g
                transform={`translate(${iconOffset} ${iconOffset})`}
                fill={props.withRef ? 'transparent' : undefined}
            >
                <Shape size={capHeight} />
            </g>

            <text ref={textRef} x={textX} y={textY} fontSize={fontSize} stroke='none' fontWeight='bold' dominantBaseline='baseline' data-testid='samples-amount'>
                {count}
            </text>
        </svg>
    )
}

BreachIcon.defaultProps = {
    withRef: false,
}

export default BreachIcon

function useMetrics(count: number | string | undefined) {
    const [textElem, textElemRef] = useState<SVGTextElement | null>(null)
        , [textWidth, setTextWidth] = useState<number>(0)

    const [textBaseline, textBaselineRef] = useState<SVGTextElement | null>(null)
        , [textCentral, textCentralRef] = useState<SVGTextElement | null>(null)
        , [capHeight, setCapHeight] = useState<number>(0)

    useLayoutEffect(
        () => {
            if (textElem && textCentral && textBaseline) {
                const nextWidth = textElem.getComputedTextLength()
                    , centralY = textCentral.getBBox().y
                    , baselineY = textBaseline.getBBox().y
                    , nextCapHeight = (centralY - baselineY) * 2

                setTextWidth(nextWidth)
                setCapHeight(nextCapHeight)
            }
        },
        [textElem, textBaseline, textCentral, count]
    )

    return [textWidth, capHeight, textElemRef, textBaselineRef, textCentralRef] as const
}
