import { actionFactory } from '_/utils/redux'
import type NonViableSampleView from '_/model/non-viable-sample/types'
import type { NonViableSampleFilter } from '_/model/non-viable-sample/list/types'
import type { NonViableSampleCreateRequest, NonViableSampleEditRequest } from '_/model/non-viable-sample/booking/types'
import type { ExistedComment } from '_/model/non-viable-sample/comment'
import type { Guid } from '_/model/guid'
import type { SampleEditedInfo } from '_/model/sample/sample'
import type Page from '_/model/page'
import type { ExportFormat } from '_/model/export/types'
import type Export from '_/model/export/types'
import type { AuditTrail } from '_/model/audit-trail/types'
import type { FieldIndex } from '_/constants/custom-field-index'

const action = actionFactory('app.non-viable-samples')

export const createNonViableSample = action<Partial<NonViableSampleCreateRequest>, Promise<Guid>>('createNonViableSample')
           , editNonViableSampleField = action<Guid>('editNonViableSampleField')
           , editReadNonViableSample = action<{ id: Guid, oldNonViableSample: Partial<NonViableSampleEditRequest>, newNonViableSample: Partial<NonViableSampleEditRequest>, editedFields?: FieldIndex[] }, Promise<void>>('editReadNonViableSample')
           , loadNonViableSampleByBarcode = action<string, Promise<NonViableSampleView | void>>('loadNonViableSampleByBarcode')
           , loadNonViableSampleComments = action<Guid, Promise<ExistedComment[]>>('loadNonViableSampleComments')
           , addNonViableSampleComments = action<{ nonViableSampleId: Guid, nonViableSampleComments: string[] }, Promise<void>>('addNonViableSampleComments')
           , loadNonViableSample = action<Guid, Promise<NonViableSampleView>>('loadNonViableSample')
           , loadNonViableSampleTrail = action<Guid, Promise<AuditTrail>>('loadNonViableSampleTrail')
           , loadNonViableSampleEditedInfo = action<Guid, Promise<SampleEditedInfo[]>>('loadNonViableSampleEditedInfo')
           , loadNonViableSamples = action<NonViableSampleFilter, Promise<Page<NonViableSampleView>>>('loadNonViableSamples')
           , exportNonViableSamples = action<ExportFormat, Promise<Export>>('exportNonViableSamples')
           , loadNonViableSamplesExports = action<void, Promise<Export[]>>('loadNonViableSamplesExports')
