import * as it from '_/model/sample/identification-type'
import type { AllIdentifications, Identification } from './identifications'

function typeHasArgument(type: it.IdentificationType | undefined) {
    return type !== undefined && it.IDENTIFICATION_WITH_ARGUMENT.includes(type)
}

function typeArgument(type: it.IdentificationType | undefined, typeArguments: AllIdentifications): Identification[] {
    switch (type) {
        case it.ORGANISM_TYPE:
            return typeArguments.organismType

        case it.CATALASE:
            return typeArguments.catalase

        case it.OXIDASE:
            return typeArguments.oxidase

        case it.OXIDATION_FERMENTATION:
            return typeArguments.oxidationFermentation

        case it.COAGULASE:
            return typeArguments.coagulase

        default:
            return []
    }
}

export {
    typeHasArgument,
    typeArgument,
}
