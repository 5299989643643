import SmartImage from '_/features/smart-check/ui/smart-image'
import { NOT_AVAILABLE } from '_/constants/common-messages'

interface Props {
    images: string[]
}

function RoundedImages(props: Props) {
    const roundedImages = props.images.slice(0, 3).reverse()
        , imagesAmount = roundedImages.length === 3 && props.images.length - roundedImages.length

    if (props.images.length === 0)
        return null

    return (
        <span style={{ whiteSpace: 'nowrap' }}>
            <span className='list-smart-image-thumbnail-container'>
                {roundedImages.map((_, i) =>
                    <SmartImage
                        key={i}
                        className='rounded-circle list-smart-image-thumbnail'
                        type='thumbnail'
                        imageId={_}
                        alt={NOT_AVAILABLE}
                    />
                )}
            </span>
            {imagesAmount > 0 && <span className='images-amount'>+{imagesAmount}</span>}
        </span>
    )
}

export default RoundedImages
