const MONTH = 0
    , QUARTER = 1
    , YEAR = 2
    , CUSTOM = 3
    , WEEK = 4

type DateRanges = typeof WEEK | typeof MONTH | typeof QUARTER | typeof YEAR | typeof CUSTOM

const DATE_RANGES: { id: DateRanges, name: string }[] = [
    {
        id: WEEK,
        name: 'Last 7 days',
    },
    {
        id: MONTH,
        name: 'Last 28 days',
    },
    {
        id: QUARTER,
        name: 'Last 3 months',
    },
    {
        id: YEAR,
        name: 'Last 12 months',
    },
    {
        id: CUSTOM,
        name: 'Custom range',
    },
]

const DEFAULT_DATE_RANGES = [MONTH, QUARTER, YEAR, CUSTOM].map(_ => DATE_RANGES.find(__ => __.id === _)!)

export {
    DATE_RANGES,
    DEFAULT_DATE_RANGES as default,
    DateRanges,
    WEEK,
    MONTH,
    QUARTER,
    YEAR,
    CUSTOM,
}
