const NEVER = 0
    , FOR_ALERT_LIMIT_BREACHES = 1
    , FOR_ACTION_LIMIT_BREACHES = 2
    , ALWAYS = 3

type PhotoBehaviour = typeof NEVER | typeof FOR_ALERT_LIMIT_BREACHES | typeof FOR_ACTION_LIMIT_BREACHES | typeof ALWAYS

const PHOTOGRAPH_BEHAVIOURS: { id: PhotoBehaviour, name: string }[] = [
    {
        id: NEVER,
        name: 'Never',
    },
    {
        id: FOR_ALERT_LIMIT_BREACHES,
        name: 'For alert limit breaches',
    },
    {
        id: FOR_ACTION_LIMIT_BREACHES,
        name: 'For action limit breaches',
    },
    {
        id: ALWAYS,
        name: 'Always',
    },
]

export {
    PHOTOGRAPH_BEHAVIOURS as default,
    NEVER,
    FOR_ALERT_LIMIT_BREACHES,
    FOR_ACTION_LIMIT_BREACHES,
    ALWAYS,
    PhotoBehaviour,
}
