import * as fieldIndex from '_/constants/custom-field-index'
import * as breachType from '_/constants/sample-breach-type'
import * as objectionableBehaviour from '_/constants/objectionable-limit-behaviour'
import * as cfuVerification from '_/constants/grade-cfu-verification'
import * as plateType from '_/constants/plate-type'
import * as growthsStatus from '_/constants/growth-status'

import type { IdentificationRows, default as Sample } from '_/model/sample/sample'
import type { IdentificationRows as ReadingIdentificationRows } from '_/model/sample/reading/sample-reading'
import type SampleReading from '_/model/sample/reading/sample-reading'
import type Grade from '_/model/predefined-lists/grade/grade'
import type { ActionAlertLimits } from '_/model/predefined-lists/action-alert-limit/types'
import type { Limits } from '_/model/predefined-lists/action-alert-limit/types'
import type { UserContext } from '_/model/auth/types'

import { dropFields, diffObject } from '_/utils/object'

import * as h from '_/features/samples/helpers'
import type { ObjectionableOrganismEffectiveView } from '_/model/objectionable-organisms/objectionable-organism'
import type { MonitoringState } from '_/model/predefined-lists/action-alert-limit/monitoring-state'
import { IN_OPERATION } from '_/model/predefined-lists/action-alert-limit/monitoring-state'
import type * as it from '../identification-type'
import * as ir from '../identification-reference'
import type SampleDetails from '../sample-details'
import type { GrowthPatch, SmartInfo } from '_/model/smart-check/image'

function convertToReading(reading: Sample): SampleReading {
    const identificationHighestReference = reading.identificationHighestReference
        , identifications = convertToReadIdentifications(reading.identifications, ir.nextReference(identificationHighestReference))
        , optionalIdentifications = isTwoHandsPlate(reading)
            ? convertToReadIdentifications(
                reading.optionalIdentifications,
                ir.nextReference(ir.maxReference(identificationHighestReference, getRefs(identifications)))
            )
            : undefined

    return {
        identifications,
        optionalIdentifications,
        identificationHighestReference,
    }

    function convertToReadIdentifications(ids: IdentificationRows = { rows: [], complete: false }, freeReference: number): ReadingIdentificationRows {
        const result: ReadingIdentificationRows = h.calcGrowthsStatus(ids) !== growthsStatus.UNIDENTIFIED_ID
                ? { rows: ids.rows.map(i => ({ ...i, types: i.types.map(t => dropFields(t, 'name')) })), complete: ids.complete }
                : { rows: [{ reference: freeReference, objectionable: false, types: [{}] }], complete: false }

        if (result.rows[0].cfuCount === 0){
            result.rows[0].types = [{}]
            result.rows[0].reference = freeReference
        }

        return result
    }

    function getRefs(idRows: ReadingIdentificationRows) {
        return idRows.rows.map(_ => _.reference)
    }
}

function getGradeLimitsValue(sample: Sample, grades: Grade[], allLimits: Limits[]) {
    const fields = sample.fields
        , gradeId = h.getFieldValue(fields, fieldIndex.EXPOSURE_LOCATION_GRADE_ID)
        , sampleTypeId = h.getFieldValue(fields, fieldIndex.SAMPLE_TYPE_ID)
        , grade = grades.find(_ => _.id === gradeId)
        , limits = allLimits.find(l => l.gradeId === gradeId && l.sampleTypeId === sampleTypeId)

    return {
        grade,
        limits: getActionAlertLimits(limits, sample.monitoringState)
    }
}

function getActionAlertLimits(limits: Limits | undefined, monitoringState: MonitoringState) {
    return monitoringState === IN_OPERATION
        ? { actionLimit: limits?.inOperationActionLimit, alertLimit: limits?.inOperationAlertLimit }
        : { actionLimit: limits?.atRestActionLimit, alertLimit: limits?.atRestAlertLimit }
}

function hasObjectionalOrganisms(growths: IdentificationRows | ReadingIdentificationRows, optionalGrowths: IdentificationRows | ReadingIdentificationRows | undefined) {
    return growths.rows.find(_ => _.objectionable)
        || (optionalGrowths?.rows ?? []).find(_ => _.objectionable)
}

function breachedByCount(count: number, optionalCount: number, limit?: number) {
    return limit && (count >= limit || optionalCount >= limit)
}

function calculateSampleBreachType(reading: SampleReading, grade?: Grade, limits?: ActionAlertLimits) {
    const behaviour = grade
            && hasObjectionalOrganisms(reading.identifications, reading.optionalIdentifications)
            && grade.behaviour
        , totalCFU = h.calculateTotalCFU(reading.identifications)
        , optionalTotalCFU = h.calculateTotalCFU(reading.optionalIdentifications)
        , alertLimit = limits && limits.alertLimit
        , actionLimit = limits && limits.actionLimit

    if (breachedByCount(totalCFU, optionalTotalCFU, actionLimit) || behaviour === objectionableBehaviour.BREACH_ACTION_LIMIT)
        return breachType.ACTION_LIMIT
    else if (breachedByCount(totalCFU, optionalTotalCFU, alertLimit) || behaviour === objectionableBehaviour.BREACH_ALERT_LIMIT)
        return breachType.ALERT_LIMIT
    else
        return objectionableBehaviour.NO_BREACH
}

function checkCfuConfirmationRequiredByGrade(sample: Sample | SampleDetails, grade: Grade) {
    const totalCfu = h.calculateTotalCFU(sample.identifications)
        , optionalTotalCfu = h.calculateTotalCFU(sample.optionalIdentifications)
        , { actionLimit, alertLimit } = getLimitsFromSampleFields(sample)
        , isActionLimitBreached = !!breachedByCount(totalCfu, optionalTotalCfu, actionLimit)
        , isAlertLimitBreached = !!breachedByCount(totalCfu, optionalTotalCfu, alertLimit)

    switch (grade.cfuVerification) {
        case cfuVerification.NEVER: return false
        case cfuVerification.ON_ALERT_LIMIT: return isAlertLimitBreached || isActionLimitBreached
        case cfuVerification.ON_ACTION_LIMIT: return isActionLimitBreached
        case cfuVerification.ALWAYS: return true
        // case cfuVerification.AUTOMATED: return false
    }
}

function cfuVerificationRequired(sample: Sample | undefined, grades: Grade[]): boolean {
    if (!sample)
        return false

    const gradeId = h.getFieldValue(sample.fields, fieldIndex.EXPOSURE_LOCATION_GRADE_ID)
        , grade = gradeId && grades.find(g => g.id === gradeId)

    if (!grade)
        return false

    return !!sample.firstReadAt
        && sample.awaitingCfuConfirmation
        && checkCfuConfirmationRequiredByGrade(sample, grade)
}

function hasNewLimitBreach(sample: Sample, reading: SampleReading, grades: Grade[], allLimits: Limits[], compromised: boolean): boolean {
    if (compromised)
        return false
    if (!identificationsDiff(sample, reading))
        return false

    const { grade, limits } = getGradeLimitsValue(sample, grades, allLimits)
        , limitBreachType = calculateSampleBreachType(reading, grade, limits)
        , previousGrade = {...grade, behaviour: sample.behaviour }
        , breachStateChanged = isBreachStateChanged(sample, previousGrade as Grade, limitBreachType)
        , isLimitBreached = (breachTypeId: breachType.BreachType) => breachTypeId !== breachType.NONE

    return breachStateChanged && isLimitBreached(limitBreachType)
}

function isBreachStateChanged(sample: Sample, grade: Grade, limitBreachType: breachType.BreachType) {
    const prevLimits = getLimitsFromSampleFields(sample)
        , prevBreachType = calculateSampleBreachType(sample, grade, prevLimits)
        , manuallyBreachedSampleHasActionBreach = sample.manuallyActionLimitBreached
            && limitBreachType === breachType.ACTION_LIMIT
            && prevBreachType !== breachType.ACTION_LIMIT

    return sample.breach !== limitBreachType
        || manuallyBreachedSampleHasActionBreach
}

function getLimitsFromSampleFields(sample: Sample | SampleDetails) {
    const actionLimit = h.getFieldValue(sample.fields, fieldIndex.EXPOSURE_LOCATION_ACTION_LIMIT)
        , alertLimit = h.getFieldValue(sample.fields, fieldIndex.EXPOSURE_LOCATION_ALERT_LIMIT)

    return {actionLimit, alertLimit}
}

function identificationsDiff(originalSample: Sample, reading: SampleReading){
    const initialReading = convertToReading(originalSample)
        , nextReading = {...reading}

    if (!isTwoHandsPlate(originalSample)) {
        initialReading.optionalIdentifications = undefined
        nextReading.optionalIdentifications = undefined
    }

    return diffObject(initialReading, nextReading)
}

function isSampleLastReadByUser(sample: Sample | undefined, user: UserContext | undefined): boolean {
    return !!(user && sample && sample.lastReadBy === user.id)
}

function isTwoHandsPlate(sample: Pick<Sample, 'fields'>) {
   return h.getFieldValue(sample.fields, fieldIndex.PLATE_TYPE) === plateType.FINGERDAB_TWO_HANDS_PLATE
}

function isSwabPlateType(sample: Pick<Sample, 'fields'>) {
    return h.getFieldValue(sample.fields, fieldIndex.PLATE_TYPE) === plateType.SWAB
}

// todo: write tests
function getMostSuspiciousImage(growthCheckResults: SmartInfo[]) {
    growthCheckResults = growthCheckResults.filter(_ => _.growthCountResult)

    growthCheckResults.sort(
        (one, two) => {
            const oneHasMold = hasMold(one.growthCountResult!.growths)
                , twoHasMold = hasMold(two.growthCountResult!.growths)

            if (oneHasMold !== twoHasMold)
                // mold priority
                return oneHasMold ? -1 : 1

            const oneKnownCfus = countableCfu(one.growthCountResult!.growths)
                , twoKnownCfus = countableCfu(two.growthCountResult!.growths)

            // descending order
            return twoKnownCfus - oneKnownCfus
        }
    )

    return growthCheckResults[0]
}

function verifyZeroGrowth(detectedGrowths: GrowthPatch[], enteredCfu: number) {
    const growthsMissed = hasMold(detectedGrowths)
            || enteredCfu === 0 && detectedGrowths.length > 0

    if (growthsMissed) {
        return {
            userMissedGrowths: true,
            showSuggestedGrowthRegions: true,
            showPatchSuggestedGrowthCount: false,
        }
    }
}

function verifyGrowthCount(detectedGrowths: GrowthPatch[], enteredCfu: number) {
    const growthsMissed = hasMold(detectedGrowths)
            || countableCfu(detectedGrowths) !== enteredCfu

    if (growthsMissed) {
        return {
            userMissedGrowths: true,
            showSuggestedGrowthRegions: true,
            showPatchSuggestedGrowthCount: true,
        }
    }
}

/* function automatedCFUCountVerificationEnabled(sample: Sample | undefined, grades: Grade[]): boolean {
    const gradeId = h.getFieldValue(sample?.fields, fieldIndex.EXPOSURE_LOCATION_GRADE_ID)

    return grades.find(_ => _.id === gradeId)?.cfuVerification === cfuVerification.AUTOMATED
} */

function hasMold(growthsPatches: GrowthPatch[]) {
    return growthsPatches.some(_ => _.mold)
}

function countableCfu(growthsPatches: GrowthPatch[]): number {
    return growthsPatches
            .filter(_ => _.cfu !== -1)
            .map(_ => _.cfu).reduce((x, y) => x + y, 0)
}

function enumerateIdentificationRows(reading: SampleReading) {
    const idProps: readonly Extract<keyof SampleReading, 'identifications' | 'optionalIdentifications'>[] = ['identifications', 'optionalIdentifications'] as const

    return idProps
        .flatMap(
            prop => (reading[prop]?.rows ?? [])
                .map((row, index) => {
                    const pathPrefix = `${prop}.rows[${index}]`
                    return { row, pathPrefix }
                })
        )
}

function isObjectionable(identificationType: it.IdentificationType, identificationValue: string, locationGradeId: string | undefined, organisms: ObjectionableOrganismEffectiveView[]): boolean {
    const objectionableGrades = organisms.find(_ => _.identification.type === identificationType && _.identification.value === identificationValue)?.gradeIds ?? []

    return objectionableGrades.some(_ => _ === locationGradeId)
}

export {
    convertToReading,
    getGradeLimitsValue,
    hasObjectionalOrganisms,
    calculateSampleBreachType,
    cfuVerificationRequired,
    isSampleLastReadByUser,
    hasNewLimitBreach,
    isBreachStateChanged,
    getLimitsFromSampleFields,
    identificationsDiff,
    isTwoHandsPlate,
    getMostSuspiciousImage,
    verifyZeroGrowth,
    verifyGrowthCount,
    // automatedCFUCountVerificationEnabled,
    enumerateIdentificationRows,
    isObjectionable,
    isSwabPlateType,
    checkCfuConfirmationRequiredByGrade,
}
