import { useAction, useState, useEffect } from '_/facade/react'

import Button from '_/components/button'
import * as routes from '_/constants/routes'
import * as ranges from '_/constants/date-ranges'
import * as locationActions from '_/features/predefined-lists/exposure-locations/actions'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import type { LocationInsight } from '_/model/insight/types'
import { paramsFilter } from '_/model/filters/helpers'
import { useNavigateTo } from './helpers'
import InsightsWidget from './insights-widget'
import { defaultTextNode, emptyTextNode } from '_/model/text/text'
import { formatActiveState } from '_/utils/format/common'
import FormattedText from '../text/formatted-text'

interface Props {
    insight: LocationInsight
    dismiss: () => void
}

function LocationTrend(props: Props) {
    const locationId = props.insight.data.locationId
        , location = useLocation(locationId)
        , navigateTo = useNavigateTo(props.insight.id)
        , data = props.insight.data
        , locationName = location ? formatActiveState(location.name, location.isActive) : [emptyTextNode()]
        , header = [
            defaultTextNode(`Did you know that there ${data.breaches === 1 ? `was ${data.breaches} breach` : `were ${data.breaches} breaches`} at location `),
            ...locationName,
            defaultTextNode(` in the past ${data.days} days?`)
        ]
        , routeParams = {
            locationId,
            exposureStartDateFrom: data.from,
            exposureStartDateTo: data.to,
            exposureDateRange: ranges.CUSTOM,
        }

    return (
        <InsightsWidget
            dismiss={props.dismiss}
            header={header}
        >
            <Button onClick={() => navigateTo(routes.LOCATIONS_REPORT, paramsFilter(routeParams))} className='btn-link d-block text-start p-0'>
                See a location report for <FormattedText text={locationName} />
            </Button>
            <Button onClick={() => navigateTo(routes.LOCATIONS_REPORT, { ...paramsFilter({...routeParams}), scrollTo: 'organism-pie-chart'})} className='btn-link text-start d-block p-0'>
                See which organisms have been recovered at location <FormattedText text={locationName} />
            </Button>
            <Button onClick={() => navigateTo(routes.LOCATIONS_REPORT, { ...paramsFilter({...routeParams}), scrollTo: 'fingerdab-table' })} className='btn-link text-start d-block p-0'>
                See how your operators have performed at location <FormattedText text={locationName} />
            </Button>
        </InsightsWidget>
    )
}

function useLocation(id: string) {
    const loadLocation = useAction(locationActions.loadExposureLocation)
        , [location, setLocation] = useState<ExposureLocation | undefined>()

    useEffect(
        () => {
            loadLocation(id).then(setLocation)
        },
        [loadLocation, id]
    )

    return location

}

export default LocationTrend
