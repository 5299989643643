const NO_BREACH = 0
    , BREACH_ALERT_LIMIT = 1
    , BREACH_ACTION_LIMIT = 2

type ObjectionableLimitBehaviour = never
    | typeof NO_BREACH
    | typeof BREACH_ALERT_LIMIT
    | typeof BREACH_ACTION_LIMIT

const OBJECTIONABLE_LIMIT_BEHAVIOUR = [
    {
        id: NO_BREACH,
        name: 'No breach',
    },
    {
        id: BREACH_ALERT_LIMIT,
        name: 'Breach alert limit',
    },
    {
        id: BREACH_ACTION_LIMIT,
        name: 'Breach action limit',
    },
]

type OBJECTIONABLE_LIMIT_BEHAVIOUR = typeof OBJECTIONABLE_LIMIT_BEHAVIOUR

export default OBJECTIONABLE_LIMIT_BEHAVIOUR
export {
    NO_BREACH,
    BREACH_ALERT_LIMIT,
    BREACH_ACTION_LIMIT,
    ObjectionableLimitBehaviour,
}
