import { classnames, useSelector, useState, useAction, useEffect } from '_/facade/react'

import { LinkButton } from '_/components/link'
import * as routes from '_/constants/routes'
import * as characters from '_/constants/characters'
import Button from '_/components/button'
import Menu, { useCloseMenu } from '_/components/overlay/menu'
import * as actions from '_/features/auth/actions'
import ContextSwitcher from '_/features/auth/log-in/context-switcher'
import type { UserMembership } from '_/model/auth/types'
import { SSO } from '_/model/users/user-credentials-type'

import * as warningActions from '_/features/unsaved-changes/actions'
import { useTimeService } from '_/components/time'
import { usePreviousValue } from '_/hooks/shared-hooks'

function SessionInfo() {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null)
        , timeService = useTimeService()
        , auth = useSelector(_ => _.auth)
        , hasUnsavedChanges = useSelector(_ => _.unsavedChange.unsavedChangeTargets.length > 0)

        , [switchingContext, setSwitchingContext] = useState(false)
        , [showSessionInfo, setShowSessionInfo] = useState(false)
        , [setCanLogOut, setCanSwitchContext] = useUnsavedChangesTracker(setSwitchingContext)

        , logOut = useAction(actions.logOut)
        , changeContext = useAction(actions.changeContext)
        , showWarning = useAction(warningActions.showWarning)
        , loadMemberships = useAction(actions.loadMemberships)

    useCloseMenu(containerRef, () => setShowSessionInfo(false))

    function handleShowSessionInfo() {
        const loadMembershipsPromise = showSessionInfo
            ? Promise.resolve()
            : loadMemberships()

        loadMembershipsPromise
            .then(() => setShowSessionInfo(_ => !_))
    }

    function hideSessionInfo() {
        setShowSessionInfo(false)
    }

    function handleOpenContextSwitcher() {
        if (hasUnsavedChanges) {
            showWarning({ showWarning: true })
            setCanSwitchContext(true)
        }
        else {
            setSwitchingContext(true)
            setShowSessionInfo(false)
        }
    }

    function handleCloseContextSwitcher() {
        setSwitchingContext(false)
    }

    function handleContextChange(context: UserMembership) {
        handleCloseContextSwitcher()
        return changeContext(context.id)
    }

    function handleRedirectToSsoSignOut() {
        document.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${document.location.origin}`
    }

    function handleLogOut() {
        if (hasUnsavedChanges) {
            showWarning({ showWarning: true })
            setCanLogOut(true)
        }
        else {
            logOut()
                .then(() => auth.user?.credentialsType === SSO
                    ? handleRedirectToSsoSignOut()
                    : Promise.resolve()
                )
        }
    }

    return (
        <div ref={setContainerRef}>
            <Button
                onClick={handleShowSessionInfo}
                className={classnames(
                    'btn-link d-flex justify-content-center align-content-center text-white no-underline navbar__user-account--focus',
                    {'navbar__user-account--active-color': showSessionInfo }
                )}
            >
                <div>
                    <div className='user-account--name' title={auth.user?.email} data-testid='user-account-name'>
                        {auth.user?.name} ({auth.user?.membership.role.name})
                    </div>
                    <div className={classnames(
                        'user-account--context-info text-white-50',
                        {'navbar__user-account--active-color': showSessionInfo}
                    )} data-testid='user-account-context-info'>
                        {auth.user?.membership.description} {timeService.calculateOffset()}
                    </div>
                </div>
                <div className='mt-2'>
                    {showSessionInfo ? characters.SMALL_UP_POINTING_TRIANGLE : characters.SMALL_DOWN_POINTING_TRIANGLE}
                </div>
            </Button>

            <ContextSwitcher
                isOpen={switchingContext}
                onClose={handleCloseContextSwitcher}
                onChange={handleContextChange}
            />

            {showSessionInfo &&
                <Menu element={containerRef} useParentWidth>
                    <div className='border bg-white rounded mx-2 ms-auto navbar__user-account-dropdown'>
                        <LinkButton
                            routeName={routes.USER_ACCOUNT}
                            onClick={hideSessionInfo}
                            className='btn-link text-start d-block mt-1'
                            testId='user-info-dropdown-item'
                        >
                            My account
                        </LinkButton>
                        <hr className='my-1' />
                        {auth.memberships.length > 1 &&
                            <div>
                                <Button className='btn-link d-block text-start' onClick={handleOpenContextSwitcher} testId='user-info-dropdown-item'>Switch site/role</Button>
                                <hr className='my-1' />
                            </div>
                        }
                        <LinkButton
                            routeName={routes.HELP}
                            routeParams={{ page: '' }}
                            onClick={hideSessionInfo}
                            className='btn-link text-start d-block'
                            testId='user-info-dropdown-item'
                        >
                            Help
                        </LinkButton>
                        <hr className='my-1' />
                        <Button className='btn-link d-block text-start mb-1' onClick={handleLogOut} testId='user-info-dropdown-item'>Log out</Button>
                    </div>
                </Menu>
            }
        </div>
    )
}

export default SessionInfo

function useUnsavedChangesTracker(setSwitchingContext: (_: boolean) => void) {
    const hasUnsavedChanges = useSelector(_ => _.unsavedChange)
        , prevHasUnsavedChanges = usePreviousValue(hasUnsavedChanges)
        , [canLogOut, setCanLogOut] = useState(false)
        , [canSwitchContext, setCanSwitchContext] = useState(false)
        , logOut = useAction(actions.logOut)

    useEffect(
        () => {
            const unsavedChangeTargets = hasUnsavedChanges.unsavedChangeTargets.length > 0
                , prevUnsavedChangeTargets = prevHasUnsavedChanges.unsavedChangeTargets.length > 0
                , changesWereDiscarded = unsavedChangeTargets !== prevUnsavedChangeTargets && !unsavedChangeTargets

            if (changesWereDiscarded) {
                if (canLogOut) {
                    if (prevHasUnsavedChanges.showConfirmationModal) {
                        logOut()
                    }
                    setCanLogOut(false)
                }
                else if (canSwitchContext) {
                    if (prevHasUnsavedChanges.showConfirmationModal) {
                        setSwitchingContext(true)
                    }
                    setCanSwitchContext(false)
                }
            }
        },
        [hasUnsavedChanges, prevHasUnsavedChanges, canLogOut, canSwitchContext, logOut, setSwitchingContext]
    )

    return [setCanLogOut, setCanSwitchContext] as const
}
