import { classnames } from '_/facade/react'

interface Props {
    page: number
    disabled: boolean
    active?: boolean
    onClick: (page: number) => void
    children: React.ReactNode
    testId?: string
}

const Page = ({ page, disabled, active, onClick, children, testId }: Props) =>
    <li className={classnames('page-item', { disabled: disabled && !active, active })}>
        <a
            className='page-link'
            href='#'
            tabIndex={disabled && !active ? -1 : undefined}
            onClick={e => {
                e.preventDefault()
                if (!disabled)
                    onClick(page)
            }}
            data-testid={testId}
        >
            {children}
        </a>
        &nbsp;
    </li>

export { Page }
