import type { Router } from 'router5'
import * as React from 'react'

const { Provider: RouterProvider, Consumer } = React.createContext<Router>(undefined as any)

class RouterListener extends React.Component<React.ConsumerProps<Router> & { router: Router }> {
    private disposed = false

    private handleRouterEvent() {
        if (!this.disposed)
            this.forceUpdate()
    }

    componentDidMount() {
        const dispose = this.props.router.subscribe(this.handleRouterEvent.bind(this)) as () => void

        this.componentWillUnmount = () => {
            this.disposed = true
            dispose()
        }
    }

    render() {
        return this.props.children(this.props.router)
    }
}

const RouterConsumer: React.FC<React.ConsumerProps<Router>> = props =>
    <Consumer children={
        _ => <RouterListener router={_} children={props.children} />
    }/>

export { RouterProvider, RouterConsumer }
