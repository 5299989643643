import { useAction, useSelector, useState, useCallback, useEffect } from '_/facade/react'

import { useContextSwitchObserver } from '_/components/context-observer'
import Link from '_/components/link'
import PageHeader from '_/components/page-header'
import { EmptyTableMessage, Table } from '_/components/table'

import * as routes from '_/constants/routes'

import { getSamplesQuantity } from '_/model/scheduling/monitoring-groups/helpers'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'

import * as actions from './actions'
import MonitoringGroupModal from './monitoring-group-modal'

function useMonitoringGroups() {
    const [monitoringGroups, setMonitoringGroups] = useState<MonitoringGroup[]>([])
        , loadMonitoringGroupList = useAction(actions.loadMonitoringGroupList)
        , contextSwitch = useContextSwitchObserver()

        , reset = useCallback(
            () => {
                setMonitoringGroups([])
                loadMonitoringGroupList().then(setMonitoringGroups)
            },
            [loadMonitoringGroupList]
        )

    useEffect(reset, [contextSwitch, reset])

    return [monitoringGroups, reset] as const
}

function MonitoringGroups() {
    const [monitoringGroups, reset] = useMonitoringGroups()
        , auth = useSelector(_ => _.auth)
        , editMode = useSelector(
            _ => [routes.SCHEDULING_MONITORING_GROUPS_CREATE, routes.SCHEDULING_MONITORING_GROUPS_EDIT].includes(_.router.route!.name)
        )

    return (
        <div>
            {editMode && <MonitoringGroupModal allGroups={monitoringGroups} onUpdate={reset} />}

            <PageHeader sticky title='Monitoring groups'>
                <Link
                    className='btn btn-primary'
                    routeName={routes.SCHEDULING_MONITORING_GROUPS_CREATE}
                    hasNoPermissions={!auth.permissions.editMonitoringGroups}
                    testId='add-monitoring-group'
                >
                    Add monitoring group
                </Link>
            </PageHeader>
            <div className='p-3 mb-0 p--sticky monitoring-group-message-block'>
                Use monitoring groups to define sets of environmental monitoring that are
                <br />
                frequently performed together. You can then save time by scheduling whole
                <br />
                groups when scheduling your environmental monitoring
                <br />
            </div>
            <Table>
                <thead className='thead table-header--sticky planning-table-header--sticky-offset'>
                    <tr>
                        <th>Group name</th>
                        <th>Viable samples included</th>
                        <th>Status</th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {monitoringGroups.length === 0
                        ? <EmptyTableMessage colSpan={3} message='There are no monitoring groups at the moment' />
                        : monitoringGroups.map(_ =>
                            <tr key={_.id}>
                                <td>{_.name}</td>
                                <td>{getSamplesQuantity(_.expectations)}</td>
                                <td>{_.isActive ? 'Active' : 'Inactive'}</td>
                                <td className='text-end'>
                                    <Link
                                        className='btn btn-link py-0'
                                        routeName={routes.SCHEDULING_MONITORING_GROUPS_CREATE}
                                        routeParams={{ expectations: _.expectations }}
                                        hasNoPermissions={!auth.permissions.editMonitoringGroups}
                                    >
                                        Duplicate
                                    </Link>
                                    <Link
                                        className='btn btn-link py-0'
                                        routeName={routes.SCHEDULING_MONITORING_GROUPS_EDIT}
                                        routeParams={{ id: _.id }}
                                        hasNoPermissions={!auth.permissions.editMonitoringGroups}
                                        testId='group-edit'
                                    >
                                        <i className='material-icons md-18 text-primary'>create</i>
                                    </Link>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default MonitoringGroups
