import { React, useState, useLayoutEffect, forwardRef } from '_/facade/react'
import * as sampleName from '_/constants/plate-type'
import HoverMenu from '_/components/overlay/hover-menu'
import FormattedText from '_/features/text/formatted-text'
import type { Text } from '_/model/text/text'
import * as s from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'

const config = {
    [sampleName.SETTLE_PLATE]: {
        color: '#78909C',
    },
    [sampleName.CONTACT_PLATE]: {
        color: '#9575CD',
    },
    [sampleName.AIR_PLATE]: {
        color: '#0097A7',
    },
    [sampleName.FINGERDAB_PLATE]: {
        color: '#EC407A',
    },
    [sampleName.FINGERDAB_TWO_HANDS_PLATE]: {
        color: '#EC407A',
    },
    [sampleName.OTHER]: {
        color: '#A1887F',
    },
    [sampleName.SWAB]: {
        color: '#9e9d24',
    },
}

interface Props {
    className?: string
    status?: s.MonitoringOverviewSampleStatuses
    type: sampleName.SampleName
    darkBackground?: boolean
    sampleTypeName?: Text
    showTooltip?: boolean
    testId?: string
    selected?: boolean
}

function SampleIcon(props: Props, ref: React.ForwardedRef<SVGSVGElement>) {
    const [capHeight, textBaselineRef, textCentralRef] = useMetrics()
        , [element, setElement] = useState<SVGTextElement | null>(null)
        , hasStatus = (status: s.MonitoringOverviewSampleStatuses | undefined) => status === props.status
        , booked = hasStatus(s.BOOKED_IN)
        , notInUse = hasStatus(s.NOT_IN_USE)

    const fontSize = props.type === sampleName.SWAB ? 10 : 14
        , padding = capHeight * 0.39
        , size = capHeight + padding * 2
        , radiusWithBorder = size / 2
        , innerRadius = radiusWithBorder * 0.8
        , lineWidth = radiusWithBorder - innerRadius
        , radius = radiusWithBorder - lineWidth
        , selectedCircleRadius = radiusWithBorder + lineWidth
        , cx = props.selected ? selectedCircleRadius + 1 : radiusWithBorder
        , rectHeight = radiusWithBorder
        , width = props.selected ? cx * 2 : size
        , height = props.selected ? cx * 2 + rectHeight : size
        , rectWidth = width / 2
        , rectY = selectedCircleRadius * 2
        , rectX = (width - rectWidth) / 2
        // Math.floor is used because capHeight tends to be a pixel higher than actual size of a capital letter
        // so vertical centring is a bit better that way
        , textY = capHeight + Math.floor(padding) - (props.type === sampleName.SWAB ? 1 : 0) + (props.selected ? lineWidth : 0)
        , primaryColor = config[props.type].color
        , background = props.darkBackground ? '#212529' : 'white'
        , innerColor = booked || notInUse ? background : primaryColor
        , textColor = booked || notInUse ? primaryColor : background
        , strokeDasharray = notInUse ? 2 : undefined
        , name = getSampleName(props.type)

    return (
        <>
            <svg
                ref={ref}
                className={props.className}
                width={width} height={height} viewBox={`0 0 ${width} ${height}`}
                version='1.1' xmlns='http://www.w3.org/2000/svg'
                fill='white' stroke='none' strokeWidth='0'
                style={{
                    verticalAlign: -padding,
                }}
                data-testid={props.testId}
            >
                <g fill='none' stroke='none'>
                    <text ref={textBaselineRef} dominantBaseline='baseline'>
                        0
                    </text>

                    <text ref={textCentralRef} dominantBaseline='central'>
                        0
                    </text>
                </g>

                {props.selected &&
                    <g>
                        <rect y={rectY} x={rectX} width={rectWidth} height={rectHeight} fill={'#007bff'} rx={2}/>
                        <polyline
                            fill={'none'}
                            stroke={'white'}
                            strokeWidth={1.5}
                            points={`
                                ${rectX + rectWidth / 4}, ${rectY + rectHeight / 2}
                                ${rectWidth}, ${rectY + rectHeight * 0.8}
                                ${rectX + rectWidth}, ${rectX + rectWidth}
                            `}/>
                    </g>
                }

                {props.selected && <circle cx={cx} cy={cx} r={selectedCircleRadius} fill={'white'} stroke={'#007bff'} strokeWidth={lineWidth} />}
                <circle cx={cx} cy={cx} r={radius} fill={innerColor} strokeWidth={lineWidth} stroke={primaryColor} strokeDasharray={strokeDasharray}/>

                <text ref={setElement} x={cx} y={textY} fontSize={fontSize} fill={textColor} textAnchor='middle' fontWeight='bold' dominantBaseline='baseline'>
                    {name}
                </text>

                {hasStatus(s.MISSING) &&
                    <g transform={`translate(${cx} ${cx}) rotate(-45)`}>
                        <path d={`M ${-radius} 0 h ${radius * 2}`} stroke={primaryColor} strokeWidth={lineWidth * 2} />
                        <path d={`M ${-radiusWithBorder} 0 h ${radiusWithBorder * 2}`} stroke={background} strokeWidth={lineWidth} />
                    </g>
                }
            </svg>
            {props.showTooltip &&
                <HoverMenu element={element}>
                    <div className='bg-dark text-white px-2'>
                        <FormattedText text={props.sampleTypeName} />
                    </div>
                </HoverMenu>
            }
        </>
    )
}

export default forwardRef(SampleIcon)

function useMetrics() {

    const [textBaseline, textBaselineRef] = useState<SVGTextElement | null>(null)
        , [textCentral, textCentralRef] = useState<SVGTextElement | null>(null)
        , [capHeight, setCapHeight] = useState<number>(0)

    useLayoutEffect(
        () => {
            if (textCentral && textBaseline) {
                const centralY = textCentral.getBBox().y
                    , baselineY = textBaseline.getBBox().y
                    , nextCapHeight = (centralY - baselineY) * 2

                setCapHeight(nextCapHeight)
            }
        },
        [textBaseline, textCentral]
    )

    return [capHeight, textBaselineRef, textCentralRef] as const
}

function getSampleName(type: sampleName.SampleName): string {
    return sampleName.default.find(_ => _.id === type)!.shortName
}
