import { React, classnames } from '_/facade/react'
import type { ExistedSampleComment } from '_/model/sample/comment'

import Time from '_/components/time'
import UserTagMessage from '_/components/tag/user-tag-message'

interface Props {
    index: string | number
    comment: ExistedSampleComment
}

const Comment = (props: Props) =>
    <li className={classnames('list-group-item ps-0', props.index === 0 ? 'border-top-0' : '')}>
        <div>
            <b title={props.comment.createdBy.email}>{props.comment.createdBy.name}</b>
            &nbsp;
            <span className='text-secondary small' data-testid={`comment-time-${props.index}`}>
                <Time render={_ =>
                    `${_.formatCtzDateTime(props.comment.createdAt)}`
                } />
            </span>
        </div>
        <span style={{ whiteSpace: 'pre-wrap' }}>
            <UserTagMessage message={props.comment.message} />
        </span>
    </li>

interface CommentListProps {
    comments: ExistedSampleComment[]
}
const CommentList = (props: CommentListProps) =>
    <ul className='list-group list-group-flush'>
        {props.comments.length === 0
            ? <li className='list-group-item border-top-0 border-bottom-0'>No comments</li>
            : props.comments.map((value, index) => <Comment key={index} index={index} comment={value} />)
        }
    </ul>

export default React.memo(CommentList)
