import { classnames } from '_/facade/react'

import { Table, PaginatedFooter } from '_/components/table'
import { useTimeService } from '_/components/time'
import TrailMessages from '_/components/trail-message'

import AUTOMATED_ACTION_USER_ID from '_/constants/users'

import type Page from '_/model/page'
import type PaginationState from '_/model/pagination-state'
import type { AuditTrail, Events } from '_/model/audit-trail/types'

interface Props {
    trail?: AuditTrail
    hideHeader?: boolean
    paginatedEvents?: Page<Events>
    onPaginationChange?: (pagination: PaginationState) => void
    pagination?: PaginationState
    stickyHeader?: boolean
    tableClassName?: string
    headerClassName?: string
    ownScroll?: boolean
    emptyMessage?: string
}

function AuditTrails(props: Props) {
    const timeService = useTimeService()
        , events = props.trail
            ? props.trail.events
            : props.paginatedEvents && props.paginatedEvents.items

    return (
        <>
            {!props.hideHeader &&
                <legend className={classnames({ 'page-header page-header--sticky legend--sticky-offset': props.stickyHeader }, `${props.headerClassName || ''}`)}>
                    {props.stickyHeader
                        ? <h4 className='ps-3 py-3 d-inline-block'>Audit trail</h4>
                        : 'Audit trail'
                    }
                </legend>
            }
            <div className={classnames({'overflow-auto flex-fill': props.ownScroll})}>
                <Table>
                    <thead className={classnames('thead', { 'table-header--sticky': props.stickyHeader, 'audit-trail--sticky-offset': props.stickyHeader && !props.ownScroll }, `${props.tableClassName || ''}`)}>
                        <tr>
                            <th>Date</th>
                            <th>User</th>
                            <th>Role</th>
                            <th data-testid='audit-trail-action-header'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events && events.length > 0
                            ? events.map((_, index) =>
                                <tr key={index} className={classnames('mb-3', { 'text-secondary': _.userId === AUTOMATED_ACTION_USER_ID })} data-testid='audit-trail-row'>
                                    <td>{`${timeService.formatCtzDateTime(_.issued, true)}`}</td>
                                    <td className='audit-trail__user-name-column--word-wrap'>
                                        <span title={_.userId === AUTOMATED_ACTION_USER_ID ? '' : _.userEmail}>{_.user}</span>
                                    </td>
                                    <td>{_.userRoleName}</td>
                                    <td className='audit-trail__user-name-column--word-wrap'>
                                        <TrailMessages event={_} />
                                    </td>
                                </tr>
                            )
                            : <tr>
                                <td colSpan={4} className='text-center'>{props.emptyMessage}</td>
                            </tr>
                        }
                    </tbody>
                    {props.paginatedEvents && props.pagination && props.onPaginationChange &&
                        <PaginatedFooter
                            colSpan={4}
                            state={props.pagination}
                            onChange={props.onPaginationChange}
                            totalCount={props.paginatedEvents.totalCount}
                        />
                    }
                </Table>
            </div>
        </>
    )
}

export default AuditTrails
