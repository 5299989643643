import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import { emptyTextNode } from '_/model/text/text'
import { formatActiveState } from '_/utils/format/common'
import type {
    Expectations,
    SampleTypeQuantity,
    MonitoringGroup,
} from './types'

function getSamplesQuantity(expectations: Expectations[]) {
    let result = 0
    expectations
        .forEach(it => it.sampleTypeQuantities
            .forEach((a: SampleTypeQuantity) => {
                result += a.quantity
            })
        )

    return result
}

function toMonitoringGroup(group: MonitoringGroup) {
    return {
        name: group.name,
        expectations: getNotEmptyExpectations(group.expectations),
        isActive: group.isActive,
    }
}

function getEditableExpectations(locations: ExposureLocation[], sampleTypes: SampleType[], expectations?: Expectations[]): Expectations[] {
    return locations.map(it => ({
        locationId: it.id,
        sampleTypeQuantities: getEditableSampleTypeQuantities(expectations?.find(_ => _.locationId === it.id)?.sampleTypeQuantities, sampleTypes),
    }))
}

function getQuantity(sampleTypeQuantities: SampleTypeQuantity[] | undefined, sampleTypeId: string) {
    return sampleTypeQuantities?.find(stQuantity => stQuantity.sampleTypeId === sampleTypeId)?.quantity || 0
}

function getEditableSampleTypeQuantities(sampleTypeQuantities: SampleTypeQuantity[] | undefined, sampleTypes: SampleType[]): SampleTypeQuantity[] {
    return sampleTypes
        .filter(sampleType => {
            const quantity = getQuantity(sampleTypeQuantities, sampleType.id)
            return sampleType.isActive || quantity > 0
        })
        .map(sampleType => ({
            sampleTypeId: sampleType.id,
            quantity: getQuantity(sampleTypeQuantities, sampleType.id),
        }))
}

function getNotEmptyExpectations(expectations: Expectations[]): Expectations[] {
    const nonZeroLocations = expectations.filter(it => it.sampleTypeQuantities.some(_ => _.quantity > 0))

    return nonZeroLocations.map(it => ({
        locationId: it.locationId,
        sampleTypeQuantities: getNotZeroSampleTypeQuantities(it.sampleTypeQuantities),
    }))
}

function getNotZeroSampleTypeQuantities(sampleTypeQuantities: SampleTypeQuantity[]): SampleTypeQuantity[] {
    return sampleTypeQuantities
        .filter(it => it.quantity > 0)
        .map(it => ({
            sampleTypeId: it.sampleTypeId,
            quantity: it.quantity,
        }))
}

function formatSampleTypeName(id: string, sampleTypes: SampleType[]) {
    const sampleType = sampleTypes.find(_ => _.id === id)

    if (!sampleType)
        return [emptyTextNode()]

    return formatActiveState(sampleType.name, sampleType.isActive)
}

export {
    getSamplesQuantity,
    toMonitoringGroup,
    getEditableExpectations,
    formatSampleTypeName,
}
