import { useAction, useSelector, useEffect, useState, useCallback, useRef } from '_/facade/react'

import { useContextSwitchObserver } from '_/components/context-observer'

import type Grade from '_/model/predefined-lists/grade/grade'
import type Context from '_/model/context/context'

import * as customFieldsActions from '_/features/predefined-lists/custom-fields/actions'
import * as gradesActions from '_/features/predefined-lists/action-alert-limits/actions'
import * as sessionActions from '_/features/predefined-lists/sample-session/actions'
import * as operatorActions from '_/features/predefined-lists/sample-operator/actions'
import * as floorPlanActions from '_/features/predefined-lists/floor-plan/actions'
import * as exposureLocationActions from '_/features/predefined-lists/exposure-locations/actions'
import * as limitsActions from '_/features/predefined-lists/action-alert-limits/actions'
import * as sampleTypesActions from '_/features/predefined-lists/sample-type/actions'
import * as organismIdentificationActions from '_/features/predefined-lists/organism-identification/actions'
import * as deviceActions from '_/features/predefined-lists/devices/actions'
import * as predefinedListsActions from '_/features/predefined-lists/redux/actions'
import * as contextActions from '_/features/contexts/actions'
import * as filterActions from '_/features/filters/actions'
import * as groupAction from '_/features/scheduling/monitoring-groups/actions'

import type { NonViableLimits } from '_/model/predefined-lists/action-alert-limit/non-viable-limits'
import type { OrganismIdentification } from '_/model/predefined-lists/organism-identification/types'
import { calcFilter, isPreFilteredRoute } from '_/model/filters/helpers'
import type { FilterName } from '_/model/filters/filter'
import type { Device } from '_/model/predefined-lists/devices/types'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'
import type AppState from '_/model/app-state'
import type { Limits } from '_/model/predefined-lists/action-alert-limit/types'
import type { DependencyList, EffectCallback } from 'react'

function useCustomFields() {
    const load = useAction(customFieldsActions.loadCustomFields)
        , fields = useSelector(_ => _.predefinedLists.customFields)
        , contextSwitch = useContextSwitchObserver()

    useEffect(() => { load() }, [load, contextSwitch])

    return fields
}

function useGrades() {
    const loadGrades = useAction(gradesActions.loadGradesList)
        , [grades, setGrades] = useState<Grade[]>([])
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadGrades().then(setGrades)
        },
        [loadGrades, contextSwitch]
    )

    return grades
}

function useSessions() {
    const loadSessions = useAction(sessionActions.loadSampleSessionList)
        , sessions = useSelector(_ => _.predefinedLists.sampleSessions)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadSessions()
        },
        [loadSessions, contextSwitch]
    )

    return sessions
}

function useOperators() {
    const loadOperators = useAction(operatorActions.loadSampleOperatorList)
        , operators = useSelector(_ => _.predefinedLists.sampleOperators)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadOperators()
        },
        [loadOperators, contextSwitch]
    )

    return operators
}

function useFloorPlans() {
    const load = useAction(floorPlanActions.loadFloorPlanList)
        , canUseFloorPlansCharts = useSelector(_ => _.auth.permissions.useFloorPlansCharts)
        , floorPlans = useSelector(_ => _.predefinedLists.floorPlans)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            if (canUseFloorPlansCharts)
                load()
        },
        [load, canUseFloorPlansCharts, contextSwitch]
    )

    return floorPlans
}

function useExposureLocations() {
    const load = useAction(exposureLocationActions.loadExposureLocationList)
        , exposureLocations = useSelector(_ => _.predefinedLists.exposureLocations)
        , contextSwitch = useContextSwitchObserver()

    useEffect(() => { load() }, [load, contextSwitch])

    return exposureLocations
}

function useLimits() {
    const load = useAction(limitsActions.loadLimitsList)
        , [limits, setLimits] = useState<Limits[]>([])
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            load().then(setLimits)
        },
        [contextSwitch, load]
    )

    return limits
}

function useSampleTypes() {
    const load = useAction(sampleTypesActions.loadSampleTypeList)
        , sampleTypes = useSelector(_ => _.predefinedLists.sampleTypes)
        , contextSwitch = useContextSwitchObserver()

    useEffect(() => { load() }, [load, contextSwitch])

    return sampleTypes
}

function usePredefinedLists() {
    useCustomFields()
    useGrades()
    useSessions()
    useOperators()
    useFloorPlans()
    useExposureLocations()
    useLimits()
    useSampleTypes()

    const predefinedLists = useSelector(_ => _.predefinedLists)

    return predefinedLists
}

function useAllPredefinedLists() {
    const load = useAction(predefinedListsActions.loadPredefinedLists)
        , predefinedLists = useSelector(_ => _.predefinedLists)
        , contextSwitch = useContextSwitchObserver()

    useEffect(() => { load() }, [load, contextSwitch])

    return predefinedLists
}

function useFilter<T>(filterName: FilterName, calcInitialFilter: (routeFilter: any) => T) {
    const route = useSelector(_ => _.router.route!)
        , latestFilters = useSelector(_ => _.filters.filters)
        , [initialFilter] = useState(() => calcFilter(route, latestFilters.find(_ => _.name === filterName)?.value, calcInitialFilter))
        , [filter, setFilter] = useState<T>(initialFilter)
        , filterChanged = filter !== initialFilter
        , appliedFilterRef = useAppliedFilterRef(filterName)

    useEffect(
        () => {
            if (!isPreFilteredRoute(route) || filterChanged)
                return

            appliedFilterRef.current = filter
        },
        [filterChanged, filter, route, appliedFilterRef]
    )

    useEffect(
        () => {
            if (filterChanged)
                appliedFilterRef.current = filter
        },
        [filter, filterChanged, appliedFilterRef]
    )

    return [filter, setFilter] as const
}

const membershipIdSelector = (_: AppState) => _.auth.user?.membership.id
function useAppliedFilterRef<T = unknown>(name: FilterName) {
    // store filter when component is dismounted, otherwise it triggers redundant re-rendering
    const latestAppliedFilter = useRef<T>()
        , filterApplied = useAction(filterActions.filterApplied)
        , membershipId = useSelector(membershipIdSelector)

    useUpdateEffect(
        () => {
            latestAppliedFilter.current = undefined
        },
        [membershipId]
    )

    useEffect(
        () => {
            return () => {
                if (latestAppliedFilter.current)
                    filterApplied({ name, value: latestAppliedFilter.current })
            }
        },
        [name, filterApplied]
    )

    return latestAppliedFilter
}

function useDebounce() {
    const TIMEOUT = 300
        , currentTimeout = useRef<number>()

    const debounce = useCallback(
        function debounce(f: () => unknown): void {
            if (currentTimeout.current !== undefined)
                window.clearTimeout(currentTimeout.current)

            currentTimeout.current = window.setTimeout(f, TIMEOUT)
        },
        []
    )

    return debounce
}

function useContext() {
    const contextId = useSelector(_ => _.auth.user?.membership.contextId)
        , loadContext = useAction(contextActions.loadContext)
        , [context, setContext] = useState<Context | undefined>()

    useEffect(
        () => {
            if (contextId)
                loadContext(contextId).then(setContext)
        },
        [contextId, loadContext]
    )

    return context
}

function useSyncRef<T>(value: T) {
    const ref = useRef(value)
    ref.current = value

    return ref
}

function useDevices(contextId: string) {
    const loadDevices = useAction(deviceActions.loadDevices)
        , [devices, setDevices] = useState<Device[]>([])
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadDevices(contextId).then(setDevices)
        },
        [loadDevices, contextSwitch, contextId]
    )

    return devices
}

function useChanged(value: unknown): boolean {
    const ref = useRef(value)
        , changed = ref.current !== value

    ref.current = value
    return changed
}

function usePreviousValue<T>(currentValue: T) {
    const ref = useRef({ previousValue: currentValue, currentValue })

    if (ref.current.currentValue !== currentValue)
        ref.current = { previousValue: ref.current.currentValue, currentValue }

    return ref.current.previousValue
}

type DialogState<T, P> =
    | {
        tag: 'none'
    }
    | {
        tag: 'open'
        props: (P extends void ? {} : P) & {
            accept: (result: T) => void
            cancel: () => void
        }
        resolve: (result: T) => void
        reject: () => void
    }

function useDialog<T, P extends (object | void) = void>() {
    const [state, setState] = useState<DialogState<T, P>>({ tag: 'none' })
        , latestState = useSyncRef(state)

    // cleanup (before unmount reset modal state and release promise)
    useEffect(
        () => {
            const state = latestState.current

            return () => {
                if (state.tag !== 'open')
                    return

                state.reject()
                setState({ tag: 'none' })
            }
        },
        [latestState]
    )

    const accept = useCallback(
        function accept(result: T) {
            const state = latestState.current
            if (state.tag !== 'open')
                throw new Error('Dialog is not open')

            state.resolve(result)
            setState({ tag: 'none' })
        },
        [latestState]
    )

    const cancel = useCallback(
        function cancel() {
            const state = latestState.current
            if (state.tag !== 'open')
                throw new Error('Dialog is not open')

            state.reject()
            setState({ tag: 'none' })
        },
        [latestState]
    )

    const show = useCallback(
        function showInternal(extraProps: P): Promise<T> {
            const state = latestState.current
            if (state.tag === 'open')
                throw new Error('Dialog is already open')

            const result = new Promise<T>(
                (resolve, reject) => {
                    setState({ tag: 'open', props: { ...extraProps, accept, cancel } as any, resolve, reject })
                }
            )

            return result
        },
        [latestState, accept, cancel]
    )

    const dialogProps = state.tag === 'open' ? state.props : undefined

    return [show, dialogProps] as const
}

function useResetOnContextSwitch(reset: () => void) {
    const contextSwitch = useContextSwitchObserver()
        , handleReset = useSyncRef(reset)

    useUpdateEffect(
        () => {
            handleReset.current()
        },
        [contextSwitch, handleReset]
    )
}

function useNonViableLimits() {
    const loadNonViableLimits = useAction(gradesActions.loadNonViableLimitsList)
        , [nonViableLimits, setNonViableLimits] = useState<NonViableLimits[]>([])
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadNonViableLimits()
                .then(setNonViableLimits)
        },
        [contextSwitch, loadNonViableLimits]
    )

    return nonViableLimits
}

function useMonitoringGroups() {
    const loadAdHocGroupList = useAction(groupAction.loadAdHocGroupList)
        , loadGroupList = useAction(groupAction.loadMonitoringGroupList)
        , [adHocGroups, setAdHocGroups] = useState<MonitoringGroup[]>([])
        , [groups, setGroups] = useState<MonitoringGroup[]>([])
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadGroupList()
                .then(setGroups)
            loadAdHocGroupList()
                .then(setAdHocGroups)
        },
        [contextSwitch, loadAdHocGroupList, loadGroupList]
    )

    return [groups, adHocGroups] as const
}

function useUnsavedChangesTracker(onChangesDiscarded: () => void) {
    const hasUnsavedChanges = useSelector(_ => _.unsavedChange)
        , prevHasUnsavedChanges = usePreviousValue(hasUnsavedChanges)

    useEffect(
        () => {
            const unsavedChangeTargets = hasUnsavedChanges.unsavedChangeTargets.length > 0
                , prevUnsavedChangeTargets = prevHasUnsavedChanges.unsavedChangeTargets.length > 0
                , changesWereDiscarded = unsavedChangeTargets !== prevUnsavedChangeTargets && !unsavedChangeTargets

            if (prevHasUnsavedChanges.showConfirmationModal && changesWereDiscarded) // clear if changes were discarded
                onChangesDiscarded()
        },
        [hasUnsavedChanges, prevHasUnsavedChanges, onChangesDiscarded]
    )
}

function useOrganisms(ids: string[] | undefined) {
    const [organisms, setOrganisms] = useState<OrganismIdentification[]>([])
        , searchOrganisms = useAction(organismIdentificationActions.searchOrganismIdentification)

    useEffect(
        () => {
            if (ids && ids.length > 0) {
                searchOrganisms({ ids })
                    .then(setOrganisms)
            }
        },
        [ids, searchOrganisms]
    )

    return organisms
}

function useUpdateEffect(callback: EffectCallback, deps?: DependencyList) {
    const update = useRef(false)

    useEffect(
        () => {
            if (!update.current) {
                update.current = true
                return
            }
            return callback()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    )
}

export {
    useCustomFields,
    useGrades,
    useSessions,
    useOperators,
    useFloorPlans,
    useExposureLocations,
    useLimits,
    useSampleTypes,
    usePredefinedLists,
    useAllPredefinedLists,
    useFilter,
    useAppliedFilterRef,
    useContext,
    useDebounce,
    useSyncRef,
    useDevices,
    useChanged,
    usePreviousValue,
    useDialog,
    useResetOnContextSwitch,
    useNonViableLimits,
    useMonitoringGroups,
    useUnsavedChangesTracker,
    useOrganisms,
    useUpdateEffect,
}
