import type TimeService from '_/services/time-service'
import type { DateTime } from '_/model/date-time'
import type { DayScheduleStatistic } from '../day-scheduler/types'

function calculateMonthlyViewDays(date: DateTime, timeService: TimeService) {
    const { year, month } = timeService.utcTimeStruct(date)
        , dateOfFirstMonday = timeService.firstMondayOfMonth(year, month)
        , daysInMonth = timeService.daysInMonth(year, month)
        , daysFromPreviousMonthCount = dateOfFirstMonday === 1 ? 0 : 7 - dateOfFirstMonday + 1
        , daysCount = daysInMonth + daysFromPreviousMonthCount
        , remainDaysForWeekEnd = daysCount % 7
        , daysFromNextMonthCount = remainDaysForWeekEnd && 7 - remainDaysForWeekEnd
        , lastDate = timeService.addUtcDays(timeService.utc(year, month, daysInMonth), daysFromNextMonthCount)

    return generatePastPeriod(timeService, lastDate, daysCount + daysFromNextMonthCount)
}

function generatePastPeriod(timeService: TimeService, isoTime: DateTime, daysCount: number): DateTime[] {
    const result: DateTime[] = []

    for (let i = 1; i <= daysCount; i++) {
        result.push(timeService.addUtcDays(isoTime, -(i - 1)))
    }

    return result.reverse()
}

function generateFuturePeriod(timeService: TimeService, isoTime: DateTime, daysCount: number): DateTime[] {
    const result: DateTime[] = []

    for (let i = 0; i <= daysCount; i++) {
        result.push(timeService.addUtcDays(isoTime, i))
    }

    return result
}

function calculateScheduledBookedInSamplesCount(statistic: DayScheduleStatistic) {
    const { scheduledSamplesCount, remainingSamplesCount, notInUseSamplesCount, missedSamplesCount } = statistic
    return scheduledSamplesCount - notInUseSamplesCount - remainingSamplesCount - missedSamplesCount
}

export {
    calculateMonthlyViewDays,
    calculateScheduledBookedInSamplesCount,
    generatePastPeriod,
    generateFuturePeriod,
}
