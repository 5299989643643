import type AzureAdService from '../azure-ad-service'
import type { AzureAdConfig } from '_/model/auth/types'
import type ApiService from '../api-service'

export default function factory(api: ApiService): AzureAdService {
    return { getAzureAdConfig }
    function getAzureAdConfig() {
        return api.get<AzureAdConfig>(['azure-ad', 'config'])
    }
}
