import { FlagField } from '_/components/form'
import * as s from '_/model/context/electronic-signature-settings'

interface Props {
    disabled: boolean
    atLeastInsight: boolean
    hasNoPermissions: boolean
}

function ElectronicSignatures(props: Props) {
    return (
        <>
            <h5>Electronic signatures</h5>

            <p>
                SmartControl can require users to record an electronic signature when performing certain actions.
                If this requirement is turned on, users will need to re-enter their password for each action they perform.
            </p>

            <p>
                <b>Require password when:</b>
            </p>

            <FlagField
                name='electronicSignatureSettings'
                id={`electronicSignatureSettings-${s.CHANGING_SETTINGS}`}
                flag={s.CHANGING_SETTINGS}
                disabled={props.disabled}
                hasNoPermissions={props.hasNoPermissions}
                testid='electronicSignatureSettings-change-settings'
            >
                Changing settings
            </FlagField>

            <FlagField
                name='electronicSignatureSettings'
                id={`electronicSignatureSettings-${s.EDITING_SAMPLES}`}
                flag={s.EDITING_SAMPLES}
                disabled={props.disabled}
                hasNoPermissions={props.hasNoPermissions}
                testid='electronicSignatureSettings-editing-samples'
            >
                Editing samples
            </FlagField>

            <FlagField
                name='electronicSignatureSettings'
                id={`electronicSignatureSettings-${s.EDITING_CUSTOM_REPORTS}`}
                flag={s.EDITING_CUSTOM_REPORTS}
                disabled={props.disabled}
                hasNoPermissions={props.hasNoPermissions}
                testid='electronicSignatureSettings-editing-custom-reports'
            >
                Editing custom reports
            </FlagField>

            <FlagField
                name='electronicSignatureSettings'
                id={`electronicSignatureSettings-${s.EDITING_SCHEDULING_GROUPS}`}
                flag={s.EDITING_SCHEDULING_GROUPS}
                disabled={props.disabled}
                hasNoPermissions={props.hasNoPermissions}
                testid='electronicSignatureSettings-editing-scheduling-groups'
            >
                Editing schedules
            </FlagField>

            <FlagField
                name='electronicSignatureSettings'
                id={`electronicSignatureSettings-${s.VERIFYING_CFU_COUNTS}`}
                flag={s.VERIFYING_CFU_COUNTS}
                disabled={props.disabled}
                hasNoPermissions={props.hasNoPermissions}
                testid='electronicSignatureSettings-verifying-cfu-counts'
            >
                Verifying CFU count
            </FlagField>

            <FlagField
                name='electronicSignatureSettings'
                id={`electronicSignatureSettings-${s.OVERRIDE_PHOTO_REQUIREMENT}`}
                flag={s.OVERRIDE_PHOTO_REQUIREMENT}
                disabled={props.disabled}
                hasNoPermissions={props.hasNoPermissions}
                testid='electronicSignatureSettings-override-photo-requirement'
            >
                Overriding photo requirement
            </FlagField>
            <FlagField
                name='electronicSignatureSettings'
                id={`electronicSignatureSettings-${s.EDITING_TREND_INVESTIGATION_REFERENCES}`}
                flag={s.EDITING_TREND_INVESTIGATION_REFERENCES}
                disabled={props.disabled || !props.atLeastInsight}
                hasNoPermissions={props.hasNoPermissions || !props.atLeastInsight}
            >
                Editing trend investigation references
            </FlagField>
        </>
    )
}

export default ElectronicSignatures
