const NONE = 0
    , SITE = 1
    , ORGANISATION = 2
    , SYSTEM = 3

type ContextLevel = typeof SYSTEM | typeof ORGANISATION | typeof SITE | typeof NONE

const CONTEXT_LEVEL: { id: ContextLevel, name: string }[] = [
    {
        id: SYSTEM,
        name: 'System',
    },
    {
        id: ORGANISATION,
        name: 'Organisation',
    },
    {
        id: SITE,
        name: 'Site',
    },
    {
        id: NONE,
        name: 'None',
    },
]

export {
    CONTEXT_LEVEL as default,
    SYSTEM,
    ORGANISATION,
    SITE,
    NONE,
    ContextLevel,
}
