import * as g from './geometry'
import type { DRAW_TYPE } from '_/model/canvas/draw'
import { drawRoundedRectangle, drawCycle, drawPolygon, drawText, Fill, Stroke } from '_/model/canvas/draw'
import type { FloorPlanLocation } from './floor-plan'
import { checkInactiveLocation } from '_/features/analysis/helpers'
import type ContaminationFloorPlanSeries from '_/model/floor-plan/contamination-floor-plan-series'
import { systemFontStack } from '../analysis/system-font-stack'
import type LimitBreachFloorPlanSeries from '_/model/floor-plan/limit-breach-floor-plan-series'
import * as icon from '_/model/floor-plan/icon-size'
import type ICON_TYPE from '_/model/floor-plan/icon-type'
import * as iconType from '_/model/floor-plan/icon-type'
import * as breachType from '_/constants/sample-breach-type'
import type { Point} from './area'
import { isPointInsideArea } from './area'
import { adjustFloorPlanLocationPositions } from './overlap'
import * as withRefFlag from '../sample/filter/with-ref-flag'
import type { ListExposureLocation } from '../predefined-lists/exposure-location/exposure-location'

function drawLocation(ctx: CanvasRenderingContext2D, [x, y]: [number, number], ratio: number, fillStyle = 'rgb(48,65,122)', inactive?: boolean) {
    const dimension = Math.floor(g.PLOT_RADIUS * ratio)

    if (inactive)
        drawRoundedRectangle(ctx, { x, y }, dimension, dimension, fillStyle)
    else
        drawCycle(ctx, { x, y }, dimension, fillStyle)
}

function renderLocations(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D,
    locations: FloorPlanLocation[],
    series: ContaminationFloorPlanSeries[],
    selectedLocations: string[],
    allLocations: ListExposureLocation[],
    exportRatio = -1,
) {
    const ratio = exportRatio < 0 ? g.plotScaleRatio(canvas) : exportRatio

    locations.forEach(location => {
        const p = location.position
            , inactiveLocation = checkInactiveLocation(location, selectedLocations, allLocations)
            , item = series.find(_ => _.locationId === location.locationId)
            , currentRatio = getLocationCircleRatio(ratio, inactiveLocation, item?.count)
            , font = `${13 * ratio}px ${systemFontStack}`
            , text = inactiveLocation ? '' : `${item?.count ?? ''}`

        drawLocation(ctx, [p.x, p.y], currentRatio, getLocationCircleColor(inactiveLocation, item?.count), inactiveLocation)

        drawText(ctx, [p.x, p.y], text, font, inactiveLocation ? 'rgb(105,105,105)' : undefined, 'center')
    })
}

function getLocationCircleColor(inactiveLocation: boolean, cfuCount: number | undefined) {
    if (inactiveLocation || !cfuCount)
        return 'rgb(128,128,128)'

    return undefined
}

function getLocationCircleRatio(defaultRatio: number, inactiveLocation: boolean, cfuCount: number | undefined) {
    const noSamplesRatio = 0.75

    if (inactiveLocation || !cfuCount)
        return defaultRatio * noSamplesRatio

    return defaultRatio
}

function getRenderData(limitBreachFloorPlanSeries: LimitBreachFloorPlanSeries[], locations: FloorPlanLocation[], selectedLocations: string[], ratio: number, allLocations: ListExposureLocation[]) {
    const initData = locations.map(l => {
        const series = limitBreachFloorPlanSeries.find(s => s.locationId === l.locationId)
        return { floorPlanLocation: l, series }
    })
    return adjustFloorPlanLocationPositions(initData, selectedLocations, ratio, allLocations)
}

function renderLimitBreachFloorPlan(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, locations: FloorPlanLocation[], limitBreachFloorPlans: LimitBreachFloorPlanSeries[], selectedLocations: string[], allLocations: ListExposureLocation[], exportRatio = -1) {
    const ratio = exportRatio < 0 ? g.plotScaleRatio(canvas) : exportRatio
        , renderData = getRenderData(limitBreachFloorPlans, locations, selectedLocations, ratio, allLocations)

    renderData.forEach(p => {
        const limitBreachFloorPlan = p.series
            , location = p.floorPlanLocation
            , locationPosition = location.position
            , inactiveLocation = checkInactiveLocation(location, selectedLocations, allLocations)
            , iconFillStyle = inactiveLocation ? 'rgb(211,211,211)' : undefined
            , textFillStyle = inactiveLocation ? 'rgb(105,105,105)' : undefined
            , actionBreachCount = limitBreachFloorPlan?.actionBreachCount
            , alertBreachCount = limitBreachFloorPlan?.alertBreachCount
            , compromisedCount = limitBreachFloorPlan?.compromisedCount
            , actionBreachWithRefCount = limitBreachFloorPlan?.actionBreachWithRefCount
            , alertBreachWithRefCount = limitBreachFloorPlan?.alertBreachWithRefCount
            , compromisedWithRefCount = limitBreachFloorPlan?.compromisedWithRefCount
            , defaultIcon = isDefaultIconUsed(limitBreachFloorPlan, inactiveLocation)

        if (defaultIcon) {
            const locationCircleRatio = getLocationCircleRatio(ratio, inactiveLocation, undefined)
            drawLocation(ctx, [locationPosition.x, locationPosition.y], locationCircleRatio, getLocationCircleColor(inactiveLocation, undefined), inactiveLocation)
        }
        else {
            renderIconIfNeeded(ctx, locationPosition, iconType.ACTION_LIMIT, ratio, limitBreachFloorPlan!, actionBreachCount, iconFillStyle, textFillStyle)
            renderIconIfNeeded(ctx, locationPosition, iconType.ACTION_LIMIT_WITH_REF, ratio, limitBreachFloorPlan!, actionBreachWithRefCount, iconFillStyle, textFillStyle)
            renderIconIfNeeded(ctx, locationPosition, iconType.ALERT_LIMIT, ratio, limitBreachFloorPlan!, alertBreachCount, iconFillStyle, textFillStyle)
            renderIconIfNeeded(ctx, locationPosition, iconType.ALERT_LIMIT_WITH_REF, ratio, limitBreachFloorPlan!, alertBreachWithRefCount, iconFillStyle, textFillStyle)
            renderIconIfNeeded(ctx, locationPosition, iconType.COMPROMISED, ratio, limitBreachFloorPlan!, compromisedCount, iconFillStyle, textFillStyle)
            renderIconIfNeeded(ctx, locationPosition, iconType.COMPROMISED_WITH_REF, ratio, limitBreachFloorPlan!, compromisedWithRefCount, iconFillStyle, textFillStyle)
        }
    })
}

function getIconPosition(type: ICON_TYPE, ratio: number, locationPosition: Point, limitBreachFloorPlan: LimitBreachFloorPlanSeries) {
    const firstIconPosition = getFirstIconRenderPosition(locationPosition, ratio, limitBreachFloorPlan)
    if (!firstIconPosition)
        return undefined

    const iconHeight = (icon.ICON_HEIGHT + icon.ICON_MARGIN) * ratio
        , actionLimitWithRefIconYPosition = firstIconPosition.y + (limitBreachFloorPlan.actionBreachCount ? iconHeight : 0)
        , alertLimitIconYPosition = actionLimitWithRefIconYPosition + (limitBreachFloorPlan.actionBreachWithRefCount ? iconHeight : 0)
        , alertLimitWithRefIconYPosition = alertLimitIconYPosition + (limitBreachFloorPlan.alertBreachCount ? iconHeight : 0)
        , compromisedIconYPosition = alertLimitWithRefIconYPosition + (limitBreachFloorPlan.alertBreachWithRefCount ? iconHeight : 0)

    switch (type) {
        case iconType.ACTION_LIMIT:
            return !limitBreachFloorPlan.actionBreachCount ? undefined : firstIconPosition
        case iconType.ACTION_LIMIT_WITH_REF:
            return !limitBreachFloorPlan.actionBreachWithRefCount
                ? undefined
                : {
                    x: firstIconPosition.x,
                    y: actionLimitWithRefIconYPosition,
                }

        case iconType.ALERT_LIMIT:
            return !limitBreachFloorPlan.alertBreachCount
                ? undefined
                : {
                    x: firstIconPosition.x,
                    y: alertLimitIconYPosition,
                }
        case iconType.ALERT_LIMIT_WITH_REF:
            return !limitBreachFloorPlan.alertBreachWithRefCount
                ? undefined
                : {
                    x: firstIconPosition.x,
                    y: alertLimitWithRefIconYPosition,
                }
        case iconType.COMPROMISED:
            return !limitBreachFloorPlan.compromisedCount
                ? undefined
                : {
                    x: firstIconPosition.x,
                    y: compromisedIconYPosition,
                }
        case iconType.COMPROMISED_WITH_REF:
            return !limitBreachFloorPlan.compromisedWithRefCount
                ? undefined
                : {
                    x: firstIconPosition.x,
                    y: compromisedIconYPosition + (limitBreachFloorPlan.compromisedCount ? iconHeight : 0),
            }
    }
}

function getIconArea(iconType: ICON_TYPE, ratio: number, locationPosition: Point, limitBreachFloorPlan: LimitBreachFloorPlanSeries) {
    const iconPosition = getIconPosition(iconType, ratio, locationPosition, limitBreachFloorPlan)
    if (!iconPosition)
        return undefined

    return {
        x: iconPosition.x,
        y: iconPosition.y,
        width: icon.ICON_WIDTH * ratio,
        height: icon.ICON_HEIGHT * ratio,
    }
}

function getLimitBreachFloorPlanArea(limitBreachFloorPlan: LimitBreachFloorPlanSeries | undefined, locationPosition: Point, inactiveLocation: boolean, ratio: number) {
    if (isDefaultIconUsed(limitBreachFloorPlan, inactiveLocation))
        return getDefaultIconArea(locationPosition, ratio)

    const iconsCount = calculateIconsCount(limitBreachFloorPlan!)
        , point = getFirstIconRenderPosition(locationPosition, ratio, limitBreachFloorPlan!)!
        , height = (iconsCount * icon.ICON_HEIGHT + (iconsCount - 1) * icon.ICON_MARGIN) * ratio
        , width = icon.ICON_WIDTH * ratio

    return {
        x: point.x,
        y: point.y,
        width,
        height,
    }
}

function getDefaultIconArea(locationPosition: Point, ratio: number) {
    const size = icon.NO_LIMIT_BREACH_ICON_SIZE * ratio

    return {
        x: locationPosition.x - size,
        y: locationPosition.y - size,
        width: size * 2,
        height: size * 2,
    }
}

function calculateIconsCount(floorPlan: LimitBreachFloorPlanSeries) {
    return (floorPlan.actionBreachCount && floorPlan.actionBreachCount > 0 ? 1 : 0)
        + (floorPlan.actionBreachWithRefCount > 0 ? 1 : 0)
        + (floorPlan.alertBreachCount > 0 ? 1 : 0)
        + (floorPlan.alertBreachWithRefCount > 0 ? 1 : 0)
        + (floorPlan.compromisedCount > 0 ? 1 : 0)
        + (floorPlan.compromisedWithRefCount > 0 ? 1 : 0)
}

function getFirstIconRenderPosition(locationPosition: Point, ratio: number, limitBreachFloorPlan: LimitBreachFloorPlanSeries) {
    const iconsCount = calculateIconsCount(limitBreachFloorPlan)
    if (iconsCount === 0)
        return undefined

    // center of all icons should be in location position.
    return {
        x: locationPosition.x - icon.ICON_WIDTH * ratio / 2,
        y: locationPosition.y - (iconsCount * icon.ICON_HEIGHT + (iconsCount - 1) * icon.ICON_MARGIN) * ratio / 2,
    }
}

function findLocation(point: Point, locations: FloorPlanLocation[], limitBreachedFloorPlans: LimitBreachFloorPlanSeries[], selectedLocations: string[], ratio: number, allLocations: ListExposureLocation[]): [FloorPlanLocation, breachType.BreachTypeWithCompromised | undefined, withRefFlag.WithRefFlag] | undefined {
    const renderData = getRenderData(limitBreachedFloorPlans, locations, selectedLocations, ratio, allLocations)

    for (let i = 0; i < renderData.length; i++) {
        const data = renderData[i]
            , limitBreach = data.series
            , inactiveLocation = checkInactiveLocation(data.floorPlanLocation, selectedLocations, allLocations)
            , defaultIcon = isDefaultIconUsed(limitBreach, inactiveLocation)
            , location = data.floorPlanLocation
            , locationPosition = data.floorPlanLocation.position

        if (!defaultIcon) {
            const actionIconArea = getIconArea(iconType.ACTION_LIMIT, ratio, locationPosition, limitBreach!)
                , actionWithRefIconArea = getIconArea(iconType.ACTION_LIMIT_WITH_REF, ratio, locationPosition, limitBreach!)
                , alertIconArea = getIconArea(iconType.ALERT_LIMIT, ratio, locationPosition, limitBreach!)
                , alertWithRefIconArea = getIconArea(iconType.ALERT_LIMIT_WITH_REF, ratio, locationPosition, limitBreach!)
                , compromisedIconArea = getIconArea(iconType.COMPROMISED, ratio, locationPosition, limitBreach!)
                , compromisedWithRefIconArea = getIconArea(iconType.COMPROMISED_WITH_REF, ratio, locationPosition, limitBreach!)

            if (actionIconArea && isPointInsideArea(point, actionIconArea))
                return [location, breachType.ACTION_LIMIT, withRefFlag.WITHOUT_REF]

            if (actionWithRefIconArea && isPointInsideArea(point, actionWithRefIconArea))
                return [location, breachType.ACTION_LIMIT, withRefFlag.WITH_REF]

            if (alertIconArea && isPointInsideArea(point, alertIconArea))
                return [location, breachType.ALERT_LIMIT, withRefFlag.WITHOUT_REF]

            if (alertWithRefIconArea && isPointInsideArea(point, alertWithRefIconArea))
                return [location, breachType.ALERT_LIMIT, withRefFlag.WITH_REF]

            if (compromisedIconArea && isPointInsideArea(point, compromisedIconArea))
                return [location, breachType.COMPROMISED, withRefFlag.WITHOUT_REF]

            if (compromisedWithRefIconArea && isPointInsideArea(point, compromisedWithRefIconArea))
                return [location, breachType.COMPROMISED, withRefFlag.WITH_REF]
        }
        else if (isPointInsideDefaultIcon(point, locationPosition, ratio))
            return [location, undefined, withRefFlag.ALL]
    }

    return undefined
}

function renderIconIfNeeded(ctx: CanvasRenderingContext2D, locationPosition: Point, iconType: ICON_TYPE, ratio: number,
                            limitBreachFloorPlanSeries: LimitBreachFloorPlanSeries, number: number | undefined,
                            iconFillStyle: string | undefined, textFillStyle: string | undefined) {
    if (number) {
        const iconPosition = getIconPosition(iconType, ratio, locationPosition, limitBreachFloorPlanSeries)
        renderIcon(ctx, iconPosition!, iconType, ratio, number, iconFillStyle, textFillStyle)
    }
}

function renderIcon(ctx: CanvasRenderingContext2D, iconPosition: Point, iconType: ICON_TYPE, ratio: number,
                    number: number, iconFillStyle: string | undefined, textFillStyle: string | undefined) {

    const innerFigureCoordinates = getInnerFigureCoordinates(iconPosition, ratio)
        , textCoordinates = getTextCoordinates(iconPosition, ratio)
        , iconColor = getIconColor(iconType, iconFillStyle)
        , font = `bold ${(icon.INNER_FIGURE_SIZE + 2) * ratio}px ${systemFontStack}`
        , text = number <= icon.MAX_DISPLAYABLE_LIMIT_BREACH_NUMBER ? number.toString() : '!!!'

    drawRoundedRectangle(ctx, iconPosition, icon.ICON_WIDTH * ratio, icon.ICON_HEIGHT * ratio, iconColor, Fill, true)
    drawInnerFigure(ctx, innerFigureCoordinates, iconType, ratio)
    drawText(ctx, [textCoordinates.x, textCoordinates.y], text, font, textFillStyle)
}

function isPointInsideDefaultIcon(point: Point, locationPosition: Point, ratio: number) {
    return Math.pow(point.x - locationPosition.x, 2) + Math.pow(point.y - locationPosition.y, 2) < Math.pow(icon.NO_LIMIT_BREACH_ICON_SIZE * ratio, 2)
}

function isDefaultIconUsed(series: LimitBreachFloorPlanSeries | undefined, inactiveLocation: boolean) {
    return inactiveLocation
        || !series
        || (
            (series.actionBreachCount > 0 ? series.actionBreachCount : series.actionBreachWithRefCount) === 0
            && (series.alertBreachCount > 0 ? series.alertBreachCount : series.alertBreachWithRefCount) === 0
            && (series.compromisedCount > 0 ? series.compromisedCount : series.compromisedWithRefCount) === 0
        )
}

function drawInnerFigure(ctx: CanvasRenderingContext2D, figureCoordinates: Point, type: ICON_TYPE, ratio: number) {
    const figureColor = 'rgb(255,255,255)'
        , withRef = type === iconType.COMPROMISED_WITH_REF || type === iconType.ACTION_LIMIT_WITH_REF || type === iconType.ALERT_LIMIT_WITH_REF
        , drawConfig: { lineWidth: number | undefined, drawType: DRAW_TYPE } = withRef
            ? { lineWidth: 3, drawType: Stroke }
            : { lineWidth: undefined, drawType: Fill }

    switch (type) {
        case iconType.ACTION_LIMIT_WITH_REF:
        case iconType.ACTION_LIMIT:
            const hexagonPoints = getOctagonPoints(figureCoordinates, ratio)
            drawPolygon(ctx, hexagonPoints, figureColor, drawConfig.lineWidth, drawConfig.drawType)
            break
        case iconType.ALERT_LIMIT_WITH_REF:
        case iconType.ALERT_LIMIT:
            const trianglePoints = getTrianglePoints(figureCoordinates, ratio)
            drawPolygon(ctx, trianglePoints, figureColor, drawConfig.lineWidth, drawConfig.drawType)
            break
        case iconType.COMPROMISED_WITH_REF:
        case iconType.COMPROMISED:
            drawRoundedRectangle(ctx, figureCoordinates, icon.INNER_FIGURE_SIZE * ratio, icon.INNER_FIGURE_SIZE * ratio, figureColor, drawConfig.drawType, false, drawConfig.lineWidth)
            break
    }
}

function getOctagonPoints(point: Point, ratio: number) {
    return [
        { x: point.x + icon.INNER_FIGURE_SIZE * ratio / 3, y: point.y },
        { x: point.x + icon.INNER_FIGURE_SIZE * ratio * 2 / 3, y: point.y },
        { x: point.x + icon.INNER_FIGURE_SIZE * ratio, y: point.y + icon.INNER_FIGURE_SIZE * ratio / 3 },
        { x: point.x + icon.INNER_FIGURE_SIZE * ratio, y: point.y + icon.INNER_FIGURE_SIZE * ratio * 2 / 3 },
        { x: point.x + icon.INNER_FIGURE_SIZE * ratio * 2 / 3, y: point.y + icon.INNER_FIGURE_SIZE * ratio },
        { x: point.x + icon.INNER_FIGURE_SIZE * ratio / 3, y: point.y + icon.INNER_FIGURE_SIZE * ratio},
        { x: point.x, y: point.y + icon.INNER_FIGURE_SIZE * ratio * 2 / 3 },
        { x: point.x, y: point.y + icon.INNER_FIGURE_SIZE * ratio / 3 },
    ]
}

function getTrianglePoints(point: Point, ratio: number) {
    return [
        { x: point.x, y: point.y + icon.INNER_FIGURE_SIZE * ratio },
        { x: point.x + icon.INNER_FIGURE_SIZE * ratio, y: point.y + icon.INNER_FIGURE_SIZE * ratio },
        { x: point.x + icon.INNER_FIGURE_SIZE * ratio / 2, y: point.y },
    ]
}

function getIconColor(type: ICON_TYPE, iconFillStyle: string | undefined) {
    if (iconFillStyle)
        return iconFillStyle

    switch (type) {
        case iconType.ACTION_LIMIT_WITH_REF:
        case iconType.ACTION_LIMIT:
            return 'rgb(211,47,47)'
        case iconType.ALERT_LIMIT_WITH_REF:
        case iconType.ALERT_LIMIT:
            return 'rgb(239,108,0)'
        case iconType.COMPROMISED_WITH_REF:
        case iconType.COMPROMISED:
            return 'rgb(25,118,210)'
    }
}

function getTextCoordinates(iconPosition: Point, ratio: number) {
    return {
        x: iconPosition.x + icon.INNER_FIGURE_SIZE * ratio + 2 * icon.ICON_PADDING * ratio,
        y: iconPosition.y + icon.ICON_HEIGHT * ratio / 2,
    }
}

function getInnerFigureCoordinates(iconPosition: Point, ratio: number) {
    return {
        x: iconPosition.x + icon.ICON_PADDING * ratio,
        y: iconPosition.y + icon.ICON_PADDING * ratio,
    }
}

export {
    drawLocation,
    renderLocations,
    renderLimitBreachFloorPlan,
    findLocation,
    getLimitBreachFloorPlanArea,
    getLocationCircleRatio,
    renderIcon,
}
