import type { Reducer } from 'redux'

import type { NoInfer } from '../object'
import { deepFreeze } from '../object'

import type { Action, Handler, ActionHandler, ActionCreator } from './types'
import { isPayloadAction } from './action'

function handler<S, P, R>(actionCreator: ActionCreator<P, R>, handler: Handler<NoInfer<S>, NoInfer<P>>): ActionHandler<S, P, R> {
    const actionType = actionCreator.type

    return {actionType, handler}
}

function reducer<S>(defaultState: S, ...payloadHandlers: ActionHandler<S, any, any>[]): Reducer<S> {
    const handlerMap = new Map<string, Handler<S, any>>()
    payloadHandlers.forEach(_ => handlerMap.set(_.actionType, _.handler))

    return (state = defaultState, action: Action<any, any> | Action<void, any>) => {
        const handler = handlerMap.get(action.type) as any

        const nextState = handler
            ? isPayloadAction(action) ? handler(state, action.payload) : handler(state)
            : state

        deepFreeze(nextState)

        return nextState
    }
}

export { handler, reducer }
