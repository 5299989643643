import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type PredefinedListsService from '_/services/predefined-lists-service'
import { noop } from '_/utils/function'
import * as spinnerActions from '_/features/spinner/actions'
import * as actions from './actions'

import { updateTransaction } from '_/model/predefined-lists/sample-type/transaction'

const factory = (service: PredefinedListsService): EffectsFactory => dispatch => [
    handler(actions.loadSampleTypeList, () =>
        Promise.resolve()
            .then(() => service.sampleType.list())
            .then(_ => dispatch(actions.sampleTypeListLoaded(_)))
            .then(noop)
    ),

    handler(actions.loadSampleType, service.sampleType.get),

    handler(actions.loadSampleTypeTrail, service.sampleType.getTrail),

    handler(actions.createSampleType, sampleTypeCreate =>
        Promise.resolve()
            .then(_ => service.sampleType.create(sampleTypeCreate))
            .then(_ => dispatch(actions.loadSampleTypeList()))
            .then(noop)
    ),

    handler(actions.removeSampleType, id =>
        Promise.resolve()
            .then(() => dispatch(spinnerActions.showSpinner()))
            .then(_ => service.sampleType.remove(id))
            .then(_ => dispatch(actions.sampleTypeRemoved(id)))
            .then(noop)
            .finally(() => dispatch(spinnerActions.hideSpinner()))
    ),

    handler(actions.saveSampleType, sampleTypeData =>
        Promise.resolve()
            .then(() => dispatch(spinnerActions.showSpinner()))
            .then(() =>
                updateTransaction(
                    sampleTypeData.id,
                    sampleTypeData.oldSampleType,
                    sampleTypeData.newSampleType,
                    service,
                )
            )
            .then(_ => dispatch(actions.loadSampleTypeList()))
            .then(noop)
            .finally(() => dispatch(spinnerActions.hideSpinner()))
    ),
]

export default factory
