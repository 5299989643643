import { actionFactory } from '_/utils/redux'
import type { SampleOperator, SampleOperatorEdit } from '_/model/predefined-lists/operator/types'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.sample-operators')

export const loadSampleOperatorList = action<void, Promise<void>>('loadSampleOperatorList')
           , sampleOperatorListLoaded = action<SampleOperator[]>('sampleOperatorListLoaded')
           , loadSampleOperatorTrail = action<string, Promise<AuditTrail>>('loadSampleOperatorTrail')
           , loadSampleOperator = action<string, Promise<SampleOperator>>('loadSampleOperator')
           , createSampleOperator = action<SampleOperatorEdit, Promise<void>>('createSampleOperator')
           , saveSampleOperator = action<{ id: string, oldSampleOperator: SampleOperatorEdit, newSampleOperator: SampleOperatorEdit }, Promise<void>>('saveSampleOperator')
           , removeSampleOperator = action<string, Promise<void>>('removeSampleOperator')
           , sampleOperatorRemoved = action<string>('sampleOperatorRemoved')
           , importSampleOperators = action<File, Promise<void>>('importSampleOperators')
