import Barcode from '_/components/barcode'
import Button from '_/components/button'
import NumberInput from '_/components/form/number-input'
import Legend from '_/components/legend'
import { useTimeService } from '_/components/time'
import { useAction, useCallback, useState } from '_/facade/react'
import FormattedText from '_/features/text/formatted-text'
import type { PlateEdit } from '_/model/plate/plate'
import type Plate from '_/model/plate/plate'
import { memoize } from '_/utils/function'
import PlateField, { FieldContainer } from './plate-field'
import EditInfo from './edit-info'
import * as a from '../../actions'
import * as v from '_/utils/form/validate'
import SampleAuditTrails from '_/components/sample-audit-trail-list'
import PlateComments from '../plate-comments'
import type { NewPlateComment } from '_/model/plate/comments'
import type { AuditTrail } from '_/model/audit-trail/types'

interface Props {
    plate: Plate
    trail: AuditTrail
    isTakingPhoto: boolean
    onSubmit: (_: PlateEdit) => Promise<void>
    onCancel: () => void
    onAddComment: (plateComment: NewPlateComment) => void
}

function PlateView(props: Props) {
    const timeService = useTimeService()
        , validateBarcode = useBarcodeValidator(props.plate.barcode)
        , validateCount = useCountValidator()
        , [getPlateEdit] = useState(() => memoize((_: Plate): PlateEdit => ({ barcode: _.barcode, cfuCount: _.cfuCount })))

    return (
        <>
            <div className='flex-fill h-0 px-4 overflow-auto height-print-auto'>
                <FieldContainer plate={getPlateEdit(props.plate)} onSubmit={props.onSubmit}>
                    <fieldset>
                        <Legend>Plate information</Legend>

                        <PlateField
                            fieldId='barcode'
                            label='Barcode'
                            disabled={props.isTakingPhoto}
                            validate={validateBarcode}
                            renderView={() =>
                                <Barcode>
                                    <FormattedText text={props.plate.barcode} />
                                </Barcode>
                            }
                            renderEdit={(_, inputProps) =>
                                <input id='barcode' {...inputProps} />
                            }
                        />

                        <PlateField
                            fieldId='last-read'
                            label='Last read'
                            renderView={() =>
                                <FormattedText text={props.plate.lastReadAt ? timeService.formatCtzDateTime(props.plate.lastReadAt) : undefined} />}
                        />

                        <PlateField
                            fieldId='cfuCount'
                            label='CFUs'
                            disabled={props.isTakingPhoto}
                            validate={validateCount}
                            renderView={() =>
                                <span>
                                    {props.plate.cfuCount}
                                    <EditInfo info={props.plate.lastEditorInfo} />
                                </span>
                            }
                            renderEdit={(_, inputProps) =>
                                <NumberInput id='cfuCount' {...inputProps} />
                            }
                        />
                    </fieldset>
                </FieldContainer>
                <PlateComments
                    comments={props.plate.comments}
                    onAddComment={props.onAddComment}
                />
                <SampleAuditTrails trail={props.trail} />
            </div>

            <div className='d-flex mt-auto border-top px-4 py-3'>
                <Button
                    className='btn-primary ms-auto'
                    onClick={props.onCancel}
                >
                    Next
                </Button>
            </div>
        </>
    )
}

export default PlateView

function useBarcodeValidator(initialBarcode: string) {
    const searchPlate = useAction(a.searchPlatesByBarcode)
        , validate = useCallback(
            (plate: PlateEdit): Promise<v.ValidationResult<PlateEdit>> => {
                if (plate.barcode === initialBarcode)
                    return Promise.resolve({})

                const fieldName = 'Barcode'
                    , barcodeRequired = v.required(fieldName)(plate.barcode)
                    , barcodeLength = v.minLength(fieldName, 4)(plate.barcode) || v.maxLength(fieldName, 200)(plate.barcode)
                    , barcodeHasTrimableSpaces = v.hasTrimableSpaces(fieldName, plate.barcode)

                const syncError = barcodeRequired || barcodeLength || barcodeHasTrimableSpaces
                if (syncError)
                    return Promise.resolve({ barcode: syncError })

                return searchPlate(plate.barcode!).then(
                    result => {
                        if (result.exactMatch)
                            return { barcode: 'This barcode is already in use' }
                        else
                            return {}
                    }
                )
            },
            [initialBarcode, searchPlate]
        )

    return validate
}

function useCountValidator() {
    function validate(plate: PlateEdit): v.ValidationResult<PlateEdit> {
        const result: v.ValidationResult<PlateEdit> = {}

        if (plate.cfuCount === undefined || !Number.isInteger(plate.cfuCount) || !v.isValidNumberInRange(plate.cfuCount, 0, 1000))
            result.cfuCount = 'CFUs must be integer between 0 and 1000'

        return result
    }

    return validate
}
