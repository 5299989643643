import type { UserNotificationSettings } from '_/model/auth/types'
import type { ChangeUserNotifications, ChangeEmailNotificationFrequency } from '_/model/users/types'

import type ApiService from '../api-service'
import type NotificationSettingsService from '../notification-settings-service'

function factory(api: ApiService): NotificationSettingsService {
    return {
        getNotificationSettings,
        changeNotificationSettings,
        changeEmailNotificationFrequency,
    }

    function getNotificationSettings(id: string): Promise<UserNotificationSettings> {
        return api.get(['users', id, 'notification-settings'])
    }

    function changeNotificationSettings(userData: ChangeUserNotifications): Promise<void> {
        return api.post(['users', userData.id, 'change-notification-settings'], { contextNotificationSettings: userData.contextNotificationSettings })
    }

    function changeEmailNotificationFrequency(userData: ChangeEmailNotificationFrequency): Promise<void>{
        return api.post(['users', userData.id, 'change-email-notification-frequency'], { emailNotificationFrequency: userData.emailNotificationFrequency })
    }
}

export default factory
