import {
    React,
    useState,
    useReducer,
    useEffect,
    forwardRef,
    useSelector,
} from '_/facade/react'

import type TimeService from '_/services/time-service'

import type { AnalysisFilter } from '_/model/analysis/filter/types'
import { checkInactiveLocation } from '_/features/analysis/helpers'
import type SampleSearchFields from '_/model/sample/search'

import * as fieldIndex from '_/constants/custom-field-index'

import type ContaminationFloorPlanSeries from '_/model/floor-plan/contamination-floor-plan-series'
import type { FloorPlanLocation, FloorPlanSubFilter } from '_/model/floor-plan/floor-plan'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import { renderLocations } from '_/model/floor-plan/draw'
import * as g from '_/model/floor-plan/geometry'

import { useImageSource } from '_/features/predefined-lists/floor-plan/hooks'

import FloorPlanChartContainer from './floor-plan-chart-container'

import type { FloorPlanChartRef} from '_/model/floor-plan/export'
import { exportFloorPlanToImage } from '_/model/floor-plan/export'
import type { FloorPlanChartMetadata } from '_/model/analysis/chart-metadata'
import { CONTAMINATION_FLOOR_PLAN } from '_/model/analysis/chart-type'
import { formatAggregationPeriodName } from './helpers'
import LocationMenuInfo from './location-menu-info'
import { fullNameLocationList } from '_/utils/exposure-location'
import type { FloorPlanLocationPage } from '_/model/floor-plan/floor-plan-location-page'
import ContaminationFloorPlanTabularView from './tabular-views/contamination-floor-plan-tabular-view'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'

interface Props {
    floorPlans: FloorPlan[]
    filter: AnalysisFilter
    timeService: TimeService
    series: ContaminationFloorPlanSeries[]
    showActionButtons?: boolean | undefined
    multipleGraphs?: boolean | undefined
    exportDisabled: boolean
    sampleListRouterParams: SampleSearchFields
    floorPlanSubFilter: FloorPlanSubFilter
    metadata: FloorPlanChartMetadata[]
    floorPlanLocationPage: FloorPlanLocationPage
    onClickFloorPlanLocation(_: string): void
    showTabularView?: boolean
}

const ContaminationFloorPlanChart = (props: Props, ref: React.ForwardedRef<FloorPlanChartRef>) => {
    const floorPlan = props.floorPlans.find(_ => _.id === props.floorPlanSubFilter.value.floorPlanId)
        , selectedLocations = props.filter.fields?.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ID)
        , locations = fullNameLocationList(useSelector(_ => _.predefinedLists.exposureLocations))
        , src = useImageSource(floorPlan?.imageId, 'original')
        , [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
        , [hoveredLocation, setHoveredLocation] = useState<FloorPlanLocation | undefined>(undefined)
        , redraw = useLocationRenderer(canvas, floorPlan?.locations ?? [], props.series, selectedLocations?.value ?? [], locations)
        , handleClick = useClickHandler(floorPlan?.locations ?? [], selectedLocations?.value ?? [], props.series, props.onClickFloorPlanLocation, locations)
        , handleMouseMove = useMouseMoveHandler(floorPlan?.locations ?? [], selectedLocations?.value ?? [], props.series, setHoveredLocation, locations)
        , chartType: typeof CONTAMINATION_FLOOR_PLAN = CONTAMINATION_FLOOR_PLAN
        , aggregatePeriodName = formatAggregationPeriodName(chartType, props.filter.aggregationPeriod)
        , chartData = {
            series: props.series,
            aggregatePeriodName,
            chartType: chartType,
            value: selectedLocations?.value ?? [],
        }
        , floorPlanSeries = props.series.filter(s => (floorPlan?.locations ?? []).some(l => l.locationId === s.locationId))

    return (
        <FloorPlanChartContainer
            {...props}
            chartType={chartType}
            metadata={props.metadata}
            disableGoToSamples={floorPlanSeries.length === 0}
            ref={ref}
            floorPlanLocationPage={props.floorPlanLocationPage}
            onExport={(exportData) => exportFloorPlanToImage({...chartData, exportData}, props.floorPlanLocationPage, locations)}
            renderFloorPlan={() =>
                <div className='position-relative mt-3'>
                    <img src={src} onLoad={redraw} className='img-fluid' />
                    <canvas
                        ref={setCanvas}
                        className='floor-plans-image'
                        width={floorPlan?.width}
                        height={floorPlan?.height}
                        onClick={handleClick}
                        onMouseMove={handleMouseMove}
                    />
                    {canvas && hoveredLocation &&
                        <LocationMenuInfo
                            canvas={canvas}
                            focusedFloorPlanLocation={hoveredLocation}
                            locations={locations}
                            chartType={CONTAMINATION_FLOOR_PLAN}
                        />
                    }
                </div>
            }
            renderTabularView={() => props.showTabularView &&
                <ContaminationFloorPlanTabularView
                    floorPlanLocations={floorPlan?.locations ?? []}
                    series={chartData.series}
                    locations={locations}
                    selectedLocations={selectedLocations?.value ?? []}
                />
            }
        />)
}

function useLocationRenderer(canvas: HTMLCanvasElement | null, locations: FloorPlanLocation[], series: ContaminationFloorPlanSeries[], selectedLocations: string[], allLocations: ListExposureLocation[]) {
    const [, redraw] = useReducer(() => ({}), {})

    useEffect(
        () => {
            if (!canvas)
                return

            const ctx = canvas.getContext('2d')!
            renderLocations(canvas, ctx, locations, series, selectedLocations, allLocations)

            return () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height)
            }
        }
    )

    return redraw
}

function useMouseMoveHandler(locations: FloorPlanLocation[], selectedLocations: string[], series: ContaminationFloorPlanSeries[], onMouseMoveOnFloorPlan: (location: FloorPlanLocation | undefined) => void, allLocations: ListExposureLocation[]) {
    function handleMove(event: React.MouseEvent<HTMLCanvasElement>): void {
        const xy = g.calcCanvasCoordinates(event)
            , ratio = g.plotScaleRatio(event.target as HTMLCanvasElement)
            , location = g.findLocation(xy, locations, ratio)
            , specificSeries = series.find(s => s.locationId === location?.locationId)

        onMouseMoveOnFloorPlan(location)

        if (isLocationClickable(location, specificSeries, selectedLocations, allLocations))
            event.currentTarget.style.cursor = 'pointer'
        else
            event.currentTarget.style.cursor = 'default'
    }

    return handleMove
}

function useClickHandler(locations: FloorPlanLocation[], selectedLocations: string[], series: ContaminationFloorPlanSeries[], onClickFloorPlanLocation: (locationId: string) => void, allLocations: ListExposureLocation[]) {
    function handleClick(event: React.MouseEvent<HTMLCanvasElement>): void {
        const xy = g.calcCanvasCoordinates(event)
            , ratio = g.plotScaleRatio(event.target as HTMLCanvasElement)
            , location = g.findLocation(xy, locations, ratio)
            , specificSeries = series.find(s => s.locationId === location?.locationId)

        if (isLocationClickable(location, specificSeries, selectedLocations, allLocations))
            onClickFloorPlanLocation(location!.locationId)
    }

    return handleClick
}

function isLocationClickable(location: FloorPlanLocation | undefined, series: ContaminationFloorPlanSeries | undefined, selectedLocations: string[], allLocations: ListExposureLocation[]) {
    return location && !checkInactiveLocation(location, selectedLocations, allLocations) && series
}

export default forwardRef(ContaminationFloorPlanChart)
