const CONTAMINATION = 0
    , LIMIT_BREACH = 1
    , FINGERDAB_ACTION_LIMIT_BREACH = 2

type TrendType = typeof CONTAMINATION | typeof LIMIT_BREACH | typeof FINGERDAB_ACTION_LIMIT_BREACH
export {
    TrendType,
    CONTAMINATION,
    LIMIT_BREACH,
    FINGERDAB_ACTION_LIMIT_BREACH,
}
