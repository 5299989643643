import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import * as tree from './tree'
import { compare } from '_/utils/string'

function fullNameLocationList(locationTree: tree.Tree<ExposureLocation>): ListExposureLocation[] {

    return tree.list(locationTree)
        .map(location => {
            const pathName = tree.path(locationTree, location)
                    .slice(1)
                    .map(_ => _.name)
                    .join(' - ')

            return { pathName, ...location }
        })
        .sort((one, two) => compare(one.pathName, two.pathName))
}

function hasLocationChildren(location: ExposureLocation, allLocations: ExposureLocation[]): boolean {
    return allLocations.some(_ => _.parentId === location.id && _.isActive)
}

function hasLocationGradedDescendants(location: ExposureLocation, allLocations: ExposureLocation[]): boolean {
    const locationTree = tree.tree(allLocations)

    return allLocations.some(_ => _.isActive && tree.isAncestorOf(locationTree, _, location) && _.gradeId)
}

function getBookableLocations(exposureLocations: ListExposureLocation[]) {
    return exposureLocations.filter(_ =>
        _.parentId !== tree.VOID_ID
            && (_.gradeId || hasLocationGradedDescendants(_, exposureLocations))
    )
}

export {
    fullNameLocationList,
    hasLocationChildren,
    getBookableLocations,
}
