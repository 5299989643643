import { React, classnames, useState, useAction, useEffect } from '_/facade/react'
import type { SampleSearchByBarcodeResult } from '_/model/sample/sample-search-result'
import type Sample from '_/model/sample/sample'
import Scanner from '../scanner/scanner'
import { minLengthForSearch } from '_/utils/form/validate'
import { loadSamplesByBarcode } from '_/features/samples/actions'
import { useDebounce, useSyncRef } from '_/hooks/shared-hooks'

interface Props {
    onLoad: (_: Sample) => void
}

function SampleSearch(props: Props) {
    const [barcode, setBarcode] = useState('')
        , [mode, setMode] = useState<Mode>({ tag: 'empty' })

    useInputValueChange(barcode, props.onLoad, setMode)

    function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter')
            e.preventDefault()

        if (e.key === 'Tab' && (e.target as any).value !== '')
            e.preventDefault()
    }

    return (
        <div className='p-4'>
            <div className='position-relative'>
                <input
                    className={classnames('form-control', mode.tag === 'error' && 'is-invalid')}
                    type='search'
                    aria-label='Search'
                    value={barcode}
                    onChange={_ => setBarcode(_.target.value)}
                    onKeyDown={handleKeyPress}
                    autoFocus
                    data-testid='field-sample-search'
                />
                {barcode === '' && <i className='search-icon material-icons'>search</i>}
                {mode.tag === 'error' && <span className='invalid-feedback' data-testid='validation-error'>{mode.message}</span>}
            </div>
            <div className='text-center m-2'>
                {mode.tag === 'empty' && <p>Scan the barcode of a viable sample to start reading</p>}
                {mode.tag === 'not-found' && <p>The barcode you entered was not found. Please check and try again. There might be partial matches that you can find by searching on Sample list page</p>}
            </div>
            <Scanner
                suppressInput
                onSampleLoaded={props.onLoad}
                onSampleNotFound={_ => setMode({ tag: 'not-found' })}
            />
        </div>
    )
}

export default SampleSearch

function useInputValueChange(barcode: string, onLoad: (_: Sample) => void, setMode: React.Dispatch<React.SetStateAction<Mode>>) {
    const loadSamples = useAction(loadSamplesByBarcode)
        , debounce = useDebounce()
        , onLoadRef = useSyncRef(onLoad)

    useEffect(
        () => {
            let disposed = false

            debounce(() => {
                if (barcode === '') {
                    setMode({ tag: 'empty' })
                    return
                }

                const validationResult = minLengthForSearch(4, 'barcode')(barcode)
                if (validationResult) {
                    setMode({ tag: 'error', message: validationResult })
                    return
                }

                loadSamples({ barcode, includeNullified: false, matchExaclty: true }).then(result => {
                    if (disposed)
                        return

                    if (result.exactMatch) {
                        onLoadRef.current(result.exactMatch)
                        setMode({ tag: 'found', result, searchedBarcode: barcode })
                        return
                    }
                    else {
                        setMode({ tag: 'not-found' })
                        return
                    }
                })
            })

            return () => { disposed = true }
        },
        [barcode, onLoadRef, loadSamples, debounce, setMode]
    )
}

type Mode =
    | { tag: 'empty' }
    | { tag: 'error', message: string }
    | { tag: 'not-found' }
    | { tag: 'found', result: SampleSearchByBarcodeResult, searchedBarcode: string }
