import { actions as routerActions } from 'redux-router5'

import { useSelector, useState, useAction, useCallback, useEffect, useLayoutEffect } from '_/facade/react'
import * as h from '_/model/help/helpers'
import searchFactory from '_/model/help/search-factory'
import * as routes from '_/constants/routes'

import * as actions from './actions'
import Search from './search'
import { useResetOnContextSwitch } from '_/hooks/shared-hooks'

const HOME_PAGE = '/'

function Help() {
    const [container, setContainer] = useState<HTMLDivElement | null>(null)
        , currentUrl = useCurrentUrl()
        , navigate = useNavigate(currentUrl)
        , [page, reload] = usePage(currentUrl, navigate)
        , handleClick = useClickHandler(currentUrl, navigate, page)
        , search = useSearch()
        , searchContainer = useInjector(page, container)

    useResetOnContextSwitch(
        () => {
            if (currentUrl === HOME_PAGE)
                reload(HOME_PAGE)

            navigate(HOME_PAGE)
        }
    )

    return (
        <>
            <div
                className='help'
                onClick={handleClick}
                ref={setContainer}
            />
            <Search container={searchContainer} search={search} navigate={navigate} />
        </>
    )
}

export default Help

function useInjector(page: HTMLElement | null, container: HTMLElement | null) {
    const [searchContainer, setSearchContainer] = useState<HTMLElement | null>(null)

    useLayoutEffect(
        () => {
            if (!(container && page))
                return

            container.innerText = ''
            container.scrollTop = 0
            container.append(page)

            setSearchContainer(container.querySelector<HTMLElement>('.docs-search'))
        },
        [page, container]
    )

    return searchContainer
}

function usePage(url: string, navigate: Navigate) {
    const loadDocument = useAction(actions.loadDocument)
        , [page, setPage] = useState<HTMLElement | null>(null)

        , load = useCallback(
            (currentUrl = url) => {
                if (currentUrl.startsWith('#'))
                    return

                if (!currentUrl.endsWith('/')) {
                    // path must end with leading slash for proper work of relative links
                    navigate(currentUrl + '/', true)
                    return
                }

                loadDocument(currentUrl)
                    .then(h.preparePage)
                    .then(setPage)
                    .catch(() => navigate('/'))
            },
            [url, navigate, loadDocument]
        )

    useEffect(load, [load])

    return [page, load] as const
}

function useClickHandler(currentUrl: string, navigate: Navigate, page: HTMLElement | null,) {
    function handleClick(event: React.MouseEvent<HTMLElement>) {
        const target = event.target

        if (target instanceof HTMLElement && h.toggleableSection(target)) {
            h.toggleSection(target)
            return
        }

        if (target instanceof HTMLElement && h.isAccordion(target)) {
            h.accordionItemClick(target, page)
            return
        }

        if (target instanceof HTMLElement && h.linkToAccordionTab(target)) {
            h.openAccordionCard(target, page)
            return
        }

        if (target instanceof HTMLElement && h.toggleableTab(target)) {
            h.toggleTab(target)
            event.preventDefault()
            return
        }

        if (!(target instanceof HTMLAnchorElement))
            return

        const href = target.getAttribute('href') || ''

        // let jump to the element
        if (href.startsWith('#'))
            return

        // let navigate outside of the app
        const isAbsoluteUrl = href.includes(':')
        if (isAbsoluteUrl)
            return

        // custom link click handling
        event.preventDefault()
        const path = h.normalizePath(currentUrl + href)

        navigate(path)
    }

    return handleClick
}

function useSearch() {
    const loadSearchIndex = useAction(actions.loadSearchIndex)
        , [search, setSearch] = useState<ReturnType<typeof searchFactory>>(() => () => [])

    useEffect(
        () => {
            loadSearchIndex()
                .then(items => {
                    const search = searchFactory(items)
                    setSearch(() => search)
                })
        },
        [loadSearchIndex]
    )

    return search
}

function useCurrentUrl(): string {
    const url = useSelector<string>(_ => _.router.route!.params.page)
        // trim anchor
        , page = /^[^#]*/.exec(url)![0]

    return page
}

function useNavigate(currentUrl: string) {
    const navigateTo = useAction(routerActions.navigateTo)
        // type must be supplied otherwise optional replace parameter is inferred to be any instead of boolean
        , navigate = useCallback<Navigate>(
            function navigate(page: string, replace = false) {
                if (!page.startsWith('/'))
                    page = '/' + page

                if (page === currentUrl)
                    return

                navigateTo(routes.HELP, { page }, { replace })
            },
            [currentUrl, navigateTo]
        )

    return navigate
}

type Navigate = (page: string, replace?: boolean) => void
