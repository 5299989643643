import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import { useAction, useEffect, useState } from '_/facade/react'
import { Close } from '_/components/button'
import { Submit } from '_/components/form'
import RadioField from '_/components/form/radio-field'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '_/components/modal'
import * as fi from '_/constants/custom-field-index'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type { DateTime } from '_/model/date-time'
import type { Guid } from '_/model/guid'
import FormattedText from '_/features/text/formatted-text'
import * as t from '_/model/text/text'
import type { VacantExpectation, VacantExpectationId } from '_/model/sample/expectation'
import * as a from '../actions'
import * as h from '../helpers'
import { memoize } from '_/utils/function'
import { formatActiveState } from '_/utils/format/common'

interface MonitoringGroupProps {
    fields: FieldValues[]
    sampleId?: Guid | undefined
    id?: VacantExpectationId
    autoSelect?: boolean
    mustChangeId?: boolean
    skipIfAvailableExpectationSelected?: boolean
}

interface VacantExpectationDialogResult {
    fields: FieldValues[]
    isMonitoringExpectationChosenBySystem?: boolean
}

interface Props extends MonitoringGroupProps {
    accept: (_: VacantExpectationDialogResult) => void
    cancel: () => void
}

function VacantExpectationDialog(props: Props) {
    const expectations = useExpectations(props)
        , [getInitialValues] = useState(() => memoize(
            (id: VacantExpectationId | undefined, expectations: VacantExpectation[] | undefined): { index?: number } => {
                if (!id || !expectations)
                    return {}

                if (id.groupId === undefined || id.locationId === undefined)
                    return { index: expectations.length }

                return { index: expectations.findIndex(_ => _.groupId === id.groupId && _.locationId === id.locationId && _.sessionId === id.sessionId) }
            }
        ))
        , isAlreadySelectedAvailableExpectation = props.skipIfAvailableExpectationSelected && isSelectedAvailableExpectation(expectations, props.id)

    if (!expectations || props.autoSelect && expectations.length <= 1 || isAlreadySelectedAvailableExpectation)
        return null

    function handleSubmit(value: { index?: number }) {
        const expectation = value.index !== undefined && expectations && expectations.length > value.index
                ? expectations[value.index]
                : { groupId: undefined, locationId: undefined, sessionId: undefined }

        props.accept({ fields: updateExpectationFields(props.fields, expectation) })
    }

    function formatGroupName(e: VacantExpectation) {
        return formatActiveState(e.locationName, e.isLocationActive)
            .concat([
                t.defaultTextNode(` (${e.groupName}`),
                t.systemTextNode(e.isAdHocGroup ? ', ad-hoc' : ''),
                t.systemTextNode(!e.isGroupActive ? ', inactive' : ''),
                t.defaultTextNode(`) Session: ${e.sessionName ?? 'Other session'}`)
            ])
    }

    function disableSave(form: FormRenderProps) {
        return (props.mustChangeId && !form.dirty)
            || form.values.index === undefined
            || form.values.index === -1
    }

    return (
        <Modal isOpen onClose={props.cancel}>
            <Form
                initialValues={getInitialValues(props.id, expectations)}
                onSubmit={handleSubmit}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <ModalHeader className='border-0 pb-0 px-0'>
                            <h5>Scheduled monitoring</h5>
                            <Close onClick={props.cancel} />
                        </ModalHeader>
                        <ModalBody className='px-0'>
                            <div className='mb-3'>
                                Select the scheduled monitoring expectation that this sample satisfies.
                                This will mark the scheduled expectation as booked in.
                            </div>

                            {expectations.map((_, index) =>
                                <RadioField
                                    key={index}
                                    id={`groupId-${index}`}
                                    name='index'
                                    label={<FormattedText text={formatGroupName(_)} />}
                                    value={index}
                                />
                            )}
                            <RadioField
                                id='groupId-unscheduled'
                                name='index'
                                label={<FormattedText text={[t.systemTextNode('No group (unscheduled)')]} />}
                                value={expectations.length}
                            />
                        </ModalBody>
                        <ModalFooter className='border-0 px-0'>
                            <Submit className='m-0' disabled={disableSave(form)}>
                                Save
                            </Submit>
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

function useExpectations(props: Props) {
    const [result, setResult] = useState<VacantExpectation[] | undefined>(undefined)
        , getVacantExpectations = useAction(a.getVacantExpectations)
        , { exposureDate, sampleTypeId, locationId, sessionId } = getExpectationQueryProps(props.fields)

    useEffect(
        () => {
            if (!locationId) {
                setResult([])
                return
            }

            getVacantExpectations({ exposureDate, sampleTypeId, locationId, sessionId, sampleId: props.sampleId })
                .then(setResult)
                .catch(props.cancel)
        },
        [exposureDate, sampleTypeId, locationId, sessionId, props.sampleId, props.cancel, getVacantExpectations]
    )

    const accept = props.accept
    useEffect(
        () => {
            if (props.skipIfAvailableExpectationSelected && isSelectedAvailableExpectation(result, props.id))
                return accept({ fields: props.fields })


            if (!props.autoSelect)
                return

            if (result?.length === 0) {
                accept({ fields: updateExpectationFields(props.fields, { groupId: undefined, locationId: undefined, sessionId: undefined }) })
                return
            }

            if (result?.length === 1) {
                accept({ fields: updateExpectationFields(props.fields, result[0]), isMonitoringExpectationChosenBySystem: true })
                return
            }
        },
        [result, props.autoSelect, props.fields, props.skipIfAvailableExpectationSelected, props.id, accept]
    )

    return result
}

interface ExpectationQueryProps {
    exposureDate: DateTime
    sampleTypeId: Guid
    locationId: Guid | undefined
    sessionId: Guid | undefined
}

function getExpectationQueryProps(fields: Pick<FieldValues, 'index' | 'value'>[]): ExpectationQueryProps {
    const result = {
        exposureDate: h.getFieldValue(fields, fi.EXPOSURE_DATE) || h.getFieldValue(fields, fi.EXPOSURE_START_DATE),
        locationId: h.getFieldValue(fields, fi.MONITORING_POSITION),
        sampleTypeId: h.getFieldValue(fields, fi.SAMPLE_TYPE_ID),
        sessionId: h.getFieldValue(fields, fi.SESSION_ID),
    }

    return result
}

function getMonitoringGroupId(fields: Pick<FieldValues, 'index' | 'value'>[]): VacantExpectationId {
    const monitoringGroupId = h.getFieldValue(fields, fi.MONITORING_GROUP_ID)
        , monitoringLocationId = h.getFieldValue(fields, fi.MONITORING_LOCATION_ID)
        , monitoringSessionId = h.getFieldValue(fields, fi.MONITORING_SESSION_ID)

    return { groupId: monitoringGroupId, locationId: monitoringLocationId, sessionId: monitoringSessionId }
}

function updateExpectationFields(fields: FieldValues[], expectation: { groupId: Guid | undefined, locationId: Guid | undefined, sessionId: Guid | undefined }) {
    const normalizedFields = fields.slice()
    ;([fi.MONITORING_GROUP_ID, fi.MONITORING_LOCATION_ID, fi.MONITORING_SESSION_ID] as const).forEach(
        index => {
            if (!normalizedFields.some(_ => _.index === index))
                normalizedFields.push({ index, value: undefined, notRecorded: false })
        }
    )

    const updatedFields = normalizedFields.map(
        field => {
            if (field.index === fi.MONITORING_GROUP_ID)
                return { ...field, value: expectation.groupId }

            if (field.index === fi.MONITORING_LOCATION_ID)
                return { ...field, value: expectation.locationId }

            if (field.index === fi.MONITORING_SESSION_ID)
                return { ...field, value: expectation.sessionId }

            return field
        }
    )

    return updatedFields
}

function isSelectedAvailableExpectation(expectations: VacantExpectation[] | undefined, id: VacantExpectationId | undefined) {
    if (!expectations)
        return false

    const hasGroup = id?.groupId && id.locationId && id.sessionId
    if (!hasGroup && expectations.length === 0)
        return true

    return expectations.length <= 1
        && expectations[0]?.groupId === id?.groupId
        && expectations[0]?.locationId === id.locationId
        && expectations[0]?.sessionId === id.sessionId
        && expectations[0]?.isGroupActive
}

export default VacantExpectationDialog

export {
    getExpectationQueryProps,
    getMonitoringGroupId,
    updateExpectationFields,
}

export type {
    MonitoringGroupProps,
    VacantExpectationDialogResult,
}
