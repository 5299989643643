import { useAction, useState, useEffect } from '_/facade/react'

import * as sessionActions from '_/features/predefined-lists/sample-session/actions'
import { formatActiveState } from '_/utils/format/common'
import { VOID_ID } from '_/utils/tree'

import * as t from '_/model/text/text'
import type { DistributionOfBreachesAcrosSessionsInsight } from '_/model/insight/types'
import InsightsWidget from '../insights-widget'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import type { FieldIndex } from '_/constants/custom-field-index'
import { SESSION_ID } from '_/constants/custom-field-index'
import { NO_SESSION_ID } from '_/model/predefined-lists/session/no-session'
import InsightBody from './insight-body'

interface Props {
    insight: DistributionOfBreachesAcrosSessionsInsight
    dismiss: () => void
}

function DistributionOfBreachesAcrossSessions(props: Props) {
    const { sessionId, from, to } = props.insight.data
        , session = useSession(sessionId)
        , sessionInfo = session ? [t.defaultTextNode('on '), ...formatActiveState(session.name, session.isActive)] : [t.defaultTextNode('with samples without any session')]
        , header = [
            t.defaultTextNode('Did you know that there was an unusually high concentration of breaches occurring '),
            ...sessionInfo,
            t.defaultTextNode(' last month?'),
        ]
        , analysisRouteParams = {
            fields: [{ index: SESSION_ID as FieldIndex, value: [sessionId === VOID_ID ? NO_SESSION_ID : sessionId] }],
        }

    return (
        <InsightsWidget
            dismiss={props.dismiss}
            header={header}
        >
            <InsightBody analysisRouteParams={analysisRouteParams} insightId={props.insight.id} from={from} to={to} />
        </InsightsWidget>
    )
}

export default DistributionOfBreachesAcrossSessions

function useSession(id: string) {
    const loadSession = useAction(sessionActions.loadSampleSession)
        , [session, setSession] = useState<SampleSession>()

    useEffect(
        () => {
            if (id === VOID_ID)
                return

            loadSession(id).then(setSession)
        },
        [loadSession, id]
    )

    return session
}
