import { actions as routerActions } from 'redux-router5'

import { useAction } from '_/facade/react'
import { USERS_SIGN_UP } from '_/constants/routes'

import ContextConfirm from './context-confirm'

function ContextInviteConfirm() {
    const navigateTo = useAction(routerActions.navigateTo)
        , assign = (token: string) => navigateTo(USERS_SIGN_UP, { token })

    return (
        <ContextConfirm assign={assign} />
    )
}

export default ContextInviteConfirm
