import type { Point } from '_/model/floor-plan/area'

const Fill = 0
    , Stroke = 1

type DRAW_TYPE = never
    | typeof Fill
    | typeof Stroke

function drawRoundedRectangle(ctx: CanvasRenderingContext2D, startPoint: Point, width: number, height: number, style: string, drawType: DRAW_TYPE = Fill, drawShadow = false, lineWidth?: number) {
    const x = startPoint.x
        , y = startPoint.y
        , radius = Math.min(width, height) / 4

    ctx.save()

    if (drawShadow) {
        ctx.shadowColor = 'rgb(220,220,220)'
        ctx.shadowOffsetX = 3
        ctx.shadowOffsetY = 3
        ctx.shadowBlur = 3
    }

    if (drawType === Stroke)
        ctx.strokeStyle = style
    else
        ctx.fillStyle = style

    ctx.beginPath()
    ctx.moveTo(x + radius, y)
    ctx.lineTo(x + width - radius, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
    ctx.lineTo(x + width, y + height - radius)
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
    ctx.lineTo(x + radius, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
    ctx.lineTo(x, y + radius)
    ctx.quadraticCurveTo(x, y, x + radius, y)
    ctx.closePath()

    if (lineWidth)
        ctx.lineWidth = lineWidth
    if (drawType === Stroke)
        ctx.stroke()
    else
        ctx.fill()

    ctx.restore()
}

function drawPolygon(ctx: CanvasRenderingContext2D, points: Point[], color: string, lineWidth?: number, drawType: DRAW_TYPE = Fill) {
    ctx.save()

    const firstPoint = points[0]

    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y)
    }
    ctx.closePath()

    if (lineWidth)
        ctx.lineWidth = lineWidth

    if (drawType === Stroke) {
        ctx.strokeStyle = color
        ctx.stroke()
    }
    else {
        ctx.fillStyle = color
        ctx.fill()
    }

    ctx.restore()
}

function drawLine(ctx: CanvasRenderingContext2D, start: Point, end: Point, lineWidth: number | undefined, lineColor: string | undefined) {
    ctx.save()

    if (lineWidth)
        ctx.lineWidth = lineWidth
    if (lineColor)
        ctx.strokeStyle = lineColor

    ctx.beginPath()
    ctx.moveTo(start.x, start.y)
    ctx.lineTo(end.x, end.y)
    ctx.stroke()

    ctx.restore()
}

function drawCycle(ctx: CanvasRenderingContext2D, cycleCenter: Point, radius: number, color: string) {
    ctx.save()

    const circle = new Path2D()
    circle.arc(cycleCenter.x, cycleCenter.y, radius, 0, 2 * Math.PI)

    ctx.fillStyle = color
    ctx.fill(circle)

    ctx.restore()
}

function drawText(ctx: CanvasRenderingContext2D, [x, y]: [number, number], text: string, font: string, fillStyle = 'rgb(255,255,255)', textAlign: CanvasTextAlign = 'left') {
    ctx.save()

    ctx.font = font
    ctx.fillStyle = fillStyle
    ctx.textAlign = textAlign
    ctx.textBaseline = 'middle'
    ctx.fillText(text, x, y)

    ctx.restore()
}

export {
    Fill,
    Stroke,
    DRAW_TYPE,
    drawRoundedRectangle,
    drawPolygon,
    drawCycle,
    drawText,
    drawLine,
}
