import { actionFactory } from '_/utils/redux'
import type { UserEdit, ChangeUserRole } from '_/model/users/types'
import type User from '_/model/users/types'
import type Page from '_/model/page'
import type { UserSignUp, UserContext } from '_/model/auth/types'
import type UserListQuery from '_/model/users/list-query'
import type { ElectronicSignatureSettings } from '_/model/context/electronic-signature-settings'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.users')

export const loadUserList = action<UserListQuery, Promise<Page<User>>>('loadUserList')
           , userListLoaded = action<Page<User>>('userListLoaded')
           , loadUserTrail = action<string, Promise<AuditTrail>>('loadUserTrail')
           , signUpUser = action<UserSignUp, Promise<void>>('signUpUser')
           , loadUser = action<string, Promise<User>>('loadUser')
           , clearUsers = action<void>('clearUsers')
           , saveChanges = action<{ id: string, oldUser: UserEdit, newUser: UserEdit }, Promise<void>>('saveChanges')
           , userChanged = action<Partial<UserContext>, Promise<void>>('userChanged')
           , changeRole = action<{ role: ChangeUserRole, signatureSettings: ElectronicSignatureSettings[] }, Promise<void>>('changeRole')
           , changePassword = action<{id: string, newPassword: string}, Promise<void>>('changePassword')
           , searchContextUsersByEmail = action<{ contextId: string, email: string }, Promise<User[]>>('searchContextUsersByEmail')
           , suspendUserMembership = action<{ userId: string, membershipId: string, signatureSettings: ElectronicSignatureSettings[] }, Promise<void>>('suspendUserMembership')
           , resumeUserMembership = action<{ userId: string, membershipId: string, signatureSettings: ElectronicSignatureSettings[] }, Promise<void>>('resumeUserMembership')
