import type ImageTokens from '_/model/floor-plan/image-tokens'
import { noop } from '_/utils/function'

import type ApiService from '../api-service'
import type FloorPlanService from '../floor-plan-service'
import { CHANGING_SETTINGS } from '_/model/context/electronic-signature-settings'

function factory(api: ApiService): FloorPlanService {
    const resource = api.resource(['floor-plans'], CHANGING_SETTINGS)
    return {
        ...resource,
        save: resource.saveWithReason,
        remove: resource.removeWithReason,
        getUploadTokens,
        getDownloadTokens,
        uploadImage,
    }

    function getUploadTokens(): Promise<ImageTokens> {
        return api.post(['floor-plans', 'upload-tokens'])
    }

    function getDownloadTokens(imageId: string): Promise<ImageTokens> {
        return api.post<ImageTokens[]>(['floor-plans', 'download-tokens'], { imageIds: [imageId] }).then(_ => _[0])
    }

    function uploadImage(token: string, blob: Blob): Promise<void> {
        return api.rawPut(token, blob).then(noop)
    }
}

export default factory
