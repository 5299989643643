import { useField } from 'react-final-form'
import { classnames } from '_/facade/react'
import { showFieldError } from '_/components/form/helpers'
import Error from '_/components/form/field-error'
import { areArraysEqual } from '_/utils/array'

interface Props {
    name: string
    entities: any[]
    calcLabel: (_: any) => React.ReactNode
    disabled?: boolean
    children?: React.ReactNode
    inline?: boolean
}

function CheckboxGroupField(props: Props) {
    const field = useField<number[]>(props.name, { isEqual: areArraysEqual })

    function handleChange(id: number) {
        const value = field.input.value
            , selectedEntity = value.includes(id)
            , newValue = selectedEntity
                ? value.filter(_ => _ !== id)
                : value.concat(id)

        field.input.onChange(newValue.slice().sort((a, b) => a - b))
    }

    return(
        <div className='mb-3'>
            {props.children && <label className='col-form-label'>{props.children}</label>}
            <div className={classnames(props.inline && 'd-flex justify-content-between', showFieldError(field.meta) && 'is-invalid')}>
                {props.entities.map(_ =>
                    <div key={_.id} className={classnames('form-check', props.inline && 'checkbox-block')}>
                        <input
                            className='form-check-input'
                            name={_.name}
                            id={_.name}
                            type='checkbox'
                            checked={field.input.value.includes(_.id)}
                            onChange={() => handleChange(_.id)}
                            onBlur={field.input.onBlur}
                            disabled={props.disabled}
                        />
                        <label className='form-check-label' htmlFor={_.name}>
                            {props.calcLabel(_)}
                        </label>
                    </div>
                )}
            </div>
            <Error field={field} />
        </div>
    )
}

export default CheckboxGroupField
