import * as fieldType from '_/constants/custom-field-column-type'
import * as ms from '_/model/predefined-lists/action-alert-limit/monitoring-state'
import { NOT_RECORDED, NOT_AVAILABLE } from '_/constants/common-messages'
import { formatActiveState } from '_/utils/format/common'
import { fullNameLocationList } from '_/utils/exposure-location'
import * as t from '_/model/text/text'
import type { DateTime } from '_/model/date-time'
import type { PredefinedLists } from '_/model/app-state'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import * as fi from '_/constants/custom-field-index'
import type TimeService from '_/services/time-service'
import type { MonitoringGroup } from '../scheduling/monitoring-groups/types'
import { formatMonitoringGroupName } from '_/model/scheduling/monitoring-overview/helpers'
import { getFieldValue } from '_/features/samples/helpers'
import * as tree from '_/utils/tree'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'

function isUserDefinedField(index: number) {
    return index > fi.BATCH_NUMBER && index < fi.SESSION_NAME
}

function formatByFieldIndex(index: number, fields: FieldValues[], predefinedLists: PredefinedLists) {
    if (isUserDefinedField(index) && !predefinedLists.customFields.find(_ => _.index === index)) {
        return [t.emptyTextNode()]
    }

    const field = fields.find(_ => _.index === index)
        , list = predefinedListByFieldIndex(index, predefinedLists)

    return formatByFieldValue(field?.value, field?.notRecorded, list)
}

function formatByFieldValue(value: string | undefined, notRecorded: boolean | undefined, list: { id: string, isActive: boolean, name: string }[] | undefined) {
    if (notRecorded)
        return [t.systemTextNode(NOT_RECORDED)]

    if (value === undefined)
        return [t.emptyTextNode()]

    if (!list)
        return [t.defaultTextNode(value)]

    const option = list.find(_ => _.id === value)
    if (!option)
        return [t.emptyTextNode()]

    return formatActiveState(option.name, option.isActive)
}

function predefinedListByFieldIndex(index: number, predefinedLists: PredefinedLists): { id: string, isActive: boolean, name: string }[] | undefined {
    if (isUserDefinedField(index)) {
        const config = predefinedLists.customFields.find(_ => _.index === index)
        return config?.fieldType === fieldType.SELECTION ? config.selectionFieldValues : undefined
    }

    switch (index) {
        case fi.EXPOSURE_LOCATION_ID:
        case fi.MONITORING_POSITION:
            return fullNameLocationList(predefinedLists.exposureLocations).map(_ => ({ ..._, name: _.pathName }))

        case fi.SESSION_ID:
            return predefinedLists.sampleSessions

        case fi.OPERATORS_IDS:
            return predefinedLists.sampleOperators

        case fi.SAMPLE_TYPE_ID:
            return predefinedLists.sampleTypes

        case fi.EXPOSURE_LOCATION_GRADE_ID:
            return predefinedLists.grades.map(_ => ({ ..._, isActive: true }))

        default:
            return undefined
    }
}

function atRestTextFormat(text: t.Text, monitoringState: ms.MonitoringState) {
    return monitoringState === ms.AT_REST
        ? text.concat(t.systemTextNode(' (At rest)'))
        : text
}

function formatExposureLocation(monitoringState: ms.MonitoringState, fields: FieldValues[], predefinedLists: PredefinedLists) {
    return atRestTextFormat(
        formatByFieldIndex(fi.MONITORING_POSITION, fields, predefinedLists),
        monitoringState,
    )
}

function formatExposureLocationGrade(monitoringState: ms.MonitoringState, fields: FieldValues[], predefinedLists: PredefinedLists) {
    const text = formatByFieldIndex(fi.EXPOSURE_LOCATION_GRADE_ID, fields, predefinedLists)

    if (t.plainText(text) === '')
        return text

    return atRestTextFormat(text, monitoringState)
}

function formatOperators(fields: FieldValues[], predefinedLists: PredefinedLists): t.Text {
    const index = fi.OPERATORS_IDS
        , list = predefinedListByFieldIndex(index, predefinedLists)
        , field = fields.find(_ => _.index === index)
        , operatorIds: string[] = field?.value ?? []

    return field?.notRecorded
        ? [t.systemTextNode(NOT_RECORDED)]
        : operatorIds
            .map(_ => formatByFieldValue(_, field?.notRecorded, list))
            .map((_, i) => i === 0 ? _ : [t.defaultTextNode('\n'), ..._])
            .flatMap(_ => _)
}

function formatOperatorsShort(fields: FieldValues[], predefinedLists: PredefinedLists): string | t.Text {
    const index = fi.OPERATORS_IDS
        , field = fields.find(_ => _.index === index)
        , list = predefinedListByFieldIndex(index, predefinedLists)
        , value = field?.value as string[] | undefined

    if (value?.length !== undefined && value.length > 1)
        return [t.systemTextNode(`${value.length} operators`)]

    return formatByFieldValue(value?.[0], !!field?.notRecorded, list)
}

function formatBatchNumbers(fields: FieldValues[]): t.Text {
    const index = fi.BATCH_NUMBER
        , field = fields.find(_ => _.index === index)
        , batchNumbers: string[] = field?.value ?? []

    return field?.notRecorded
        ? [t.systemTextNode(NOT_RECORDED)]
        : batchNumbers
            .map(_ => [t.defaultTextNode(_)])
            .map((_, i) => i === 0 ? _ : [t.defaultTextNode('\n'), ..._])
            .flat()
}

function formatBatchNumbersShort(fields: FieldValues[]): string | t.Text {
    const index = fi.BATCH_NUMBER
        , field = fields.find(_ => _.index === index)
        , value = field?.value as string[] | undefined

    if (value?.length !== undefined && value.length > 1)
        return [t.systemTextNode(`${value.length} batch numbers`)]

    return formatByFieldValue(value?.[0], !!field?.notRecorded, undefined)
}

function formatExposureDurationByFields(fields: FieldValues[], customFields: CustomField[], timeService: TimeService): t.Text {
    const startTimeField = fields.find(_ => _.index === fi.EXPOSURE_START_TIME)
        , startDateField = fields.find(_ => _.index === fi.EXPOSURE_START_DATE)
        , endTimeField = fields.find(_ => _.index === fi.EXPOSURE_END_TIME)
        , endDateField = fields.find(_ => _.index === fi.EXPOSURE_END_DATE)

    const endTimeFieldConfig = customFields.find(_ => _.index === fi.EXPOSURE_END_TIME)
        , hasEndTime = endTimeField?.value || endTimeField?.notRecorded || endTimeFieldConfig?.viableSettings.required

    return formatExposureDuration(
        {
            time: startTimeField?.value,
            timeNotRecorded: startTimeField?.notRecorded,
            date: startDateField?.value,
        },
        hasEndTime && {
            time: endTimeField?.value,
            timeNotRecorded: endTimeField?.notRecorded,
            date: endDateField?.value,
        },
        timeService
    )
}

type ExposureTime = {
    time: string | undefined
    timeNotRecorded: boolean | undefined
    date: DateTime | undefined
}

function formatExposureDuration(
    start: ExposureTime,
    end: ExposureTime | false | undefined,
    timeService: TimeService
): t.Text {
    if (!end)
        return formatDateTime(start)

    const days = Math.floor(timeService.daysDifference(start.date, end.date))

    return [
        ...formatDateTime(start),
        t.defaultTextNode('-'),
        ...formatDateTime(end),
        days === 0
            ? t.emptyTextNode()
            : t.defaultTextNode(` (${days > 0 ? '+' : ''}${days} ${days > 1 ? 'days' : 'day'})`)
    ]

    function formatDateTime(data: ExposureTime) {
        if (data.timeNotRecorded)
            return [t.systemTextNode(NOT_RECORDED)]

        if (data.time === undefined)
            return [t.systemTextNode(NOT_AVAILABLE)]

        const dateTime = timeService.combineCtzDateTime(data.date, data.time)

        return [t.defaultTextNode(timeService.formatCtzTime(dateTime))]
    }
}

function formatSampleMonitoringGroup(fields: FieldValues[], monitoringGroups: MonitoringGroup[], adHocGroups: MonitoringGroup[], exposureLocations: tree.Tree<ExposureLocation>) {
    const monitoringGroupId = getFieldValue(fields, fi.MONITORING_GROUP_ID)
        , monitoringLocationId = getFieldValue(fields, fi.MONITORING_LOCATION_ID)
        , monitoringLocationName = getFieldValue(fields, fi.MONITORING_LOCATION_NAME)
        , monitoringSessionName = getFieldValue(fields, fi.MONITORING_SESSION_NAME)
        , groupName = formatMonitoringGroupName(monitoringGroupId, monitoringGroups, adHocGroups)
        , location = tree.list(exposureLocations).find(_ => _.id === monitoringLocationId)

    return monitoringLocationName
        ? [
            ...groupName,
            t.defaultTextNode(' - '),
            t.defaultTextNode(monitoringSessionName ?? 'Other session'),
            t.defaultTextNode(' - '),
            ...formatActiveState(monitoringLocationName, location?.isActive)
        ]
        : [t.systemTextNode('No group (unscheduled)')]
}

export {
    formatByFieldIndex,
    formatByFieldValue,
    predefinedListByFieldIndex,
    atRestTextFormat,
    formatOperators,
    formatOperatorsShort,
    formatBatchNumbers,
    formatBatchNumbersShort,
    formatExposureLocation,
    formatExposureLocationGrade,
    formatExposureDuration,
    formatExposureDurationByFields,
    formatSampleMonitoringGroup,
}
