import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'

import { useState, useRef, useSelector, useMemo, useEffect, useAction } from '_/facade/react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import { SelectField, MultiSelectField, Submit, submitDisabled, TextField } from '_/components/form'
import Button, { Close } from '_/components/button'
import FormChangesObserver from '_/components/form/form-changes-observer'

import * as contextActions from '../../contexts/actions'
import * as roleActions from '_/features/roles/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import * as tree from '_/utils/tree'
import { noop } from '_/utils/function'

import type { UserContext } from '_/model/auth/types'
import type { ContextInviteRequest } from '_/model/context/invite/types'
import type Role from '_/model/roles/types'
import { SITE } from '_/constants/context-level'

import { isSystemAdmin } from '_/model/users/helpers'
import validate from './validate'
import type Context from '_/model/context/context'

interface Props {
    onClose(): void
}

function AddUserModal(props: Props) {
    const user = useSelector(_ => _.auth.user)
        , [contexts, userContexts] = useContexts(user)
        , [roles, handleChangeFormValues] = useRoles(contexts)
        , [
            message,
            handleInvite,
            handleModalClose,
        ] = useMessage(props.onClose)

    return (
        <Modal isOpen onClose={props.onClose}>
            <Form
                onSubmit={handleInvite as any}
                validate={_ => validate(_, user && user.email)}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <FormChangesObserver form={form} onChange={handleChangeFormValues}/>
                        <ModalHeader className='border-bottom-0'>
                            <h4 data-testid='new-user-modal'>New user</h4>
                            <Close onClick={props.onClose}/>
                        </ModalHeader>
                        <ModalBody noDefaultHeight>
                            {!message
                                ? <div className='justify-content-center'>
                                    <TextField name='email' testId='field-email'>Email</TextField>
                                    <SelectField
                                        name='contextId'
                                        entities={userContexts}
                                        calcId={_ => _.id}
                                        calcName={_ => _.description}
                                        autocomplete
                                        testId='field-context'
                                    >
                                        Contexts
                                    </SelectField>
                                    <MultiSelectField
                                        name='roleIds'
                                        id='roleIds'
                                        entities={roles}
                                        calcId={_ => _.id}
                                        calcName={_ => _.name}
                                        autocomplete
                                        testId='field-roles'
                                    >
                                        User roles
                                    </MultiSelectField>
                                </div>
                                : <div className='d-flex justify-content-start'>
                                    <span>
                                        {message}
                                    </span>
                                </div>

                            }
                        </ModalBody>
                        <ModalFooter className='border-top-0'>
                            <Button className='btn-secondary' onClick={handleModalClose} testId={message ? 'user-modal-close' : 'user-modal-cancel'}>{message ? 'Close' : 'Cancel'}</Button>
                            {!message && <Submit disabled={submitDisabled(form)} testId='add-user'>Add user</Submit>}
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default AddUserModal

function useContexts(user: UserContext | undefined) {
    const loadContexts = useAction(contextActions.loadContexts)

    useEffect(
        () => {
            loadContexts()
        },
        [loadContexts]
    )

    const contextsTree = useSelector(_ => _.contexts.list)
        , allContexts = useMemo(() => tree.list(contextsTree).filter(_ => !_.isDeleted), [contextsTree])
        , userContexts = user
                ? isSystemAdmin(user)
                    ? allContexts.filter(_ => _.level !== SITE)
                    : allContexts
                : []

    return [allContexts, userContexts]
}

function useRoles(contexts: Context[]) {
    const [roles, setRoles] = useState<Role[]>([])
        , prevValue = useRef<Partial<ContextInviteRequest>>({})
        , loadContextRoles = useAction(roleActions.loadContextRoles)


    function handleChangeFormValues(value: Partial<ContextInviteRequest>, form: FormRenderProps) {
        if (value.contextId !== prevValue.current.contextId) {
            form.form.change('roleIds', undefined)

            setRoles([])

            if (value.contextId) {
                const contextIdBeforeRequest = value.contextId
                loadContextRoles(value.contextId)
                    .then(roles => {

                        // context might be changed during request
                        if (value.contextId !== contextIdBeforeRequest)
                            return

                        const context = contexts.find(_ => _.id === value.contextId)
                        if (!context)
                            return

                        setRoles(roles.filter(_ => _.contextLevel === context.level))
                    })
            }
        }
        prevValue.current = value
    }

    return [roles, handleChangeFormValues] as const
}

function useMessage(onClose: () => void) {
    const [message, setMessage] = useState<string | undefined>('')
        , invite = useAction(contextActions.invite)
        , hasUnsavedChanges = warningActions.hasUnsavedChanges

    function handleFormSubmit(request: ContextInviteRequest) {
        invite(request)
            .then(
                () => { setMessage('Invitation was sent') },
                (_) => !_.statusText && setMessage('Something went wrong. Please try again later')
            )
            .then(() => hasUnsavedChanges(false))
            .then(noop)
    }

    function handleModalClose() {
        setMessage(undefined)
        onClose()
    }

    return [message, handleFormSubmit, handleModalClose] as const
}
