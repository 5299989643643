import type Sample from '_/model/sample/sample'
import * as fieldIndex from '_/constants/custom-field-index'
import { NOT_EDITABLE_SHOW_WHEN_BOOKING_IN_FIELDS as REASON_REQUIRED_FIELDS } from '_/features/predefined-lists/custom-fields/helpers'

function reasonRequired(editedFields: number[], sample: Partial<Sample>) {
    const editedInvestigatedRef = 'sampleInvestigationReferences' in sample
            && editedFields.some(_ => _ === fieldIndex.REFERENCE_NUMBERS)
        , editedRequiredFields =  sample.fields
            && sample.fields.some(_ =>
                REASON_REQUIRED_FIELDS.has(_.index)
                    || _.index === fieldIndex.MONITORING_GROUP_ID
                    || _.index === fieldIndex.MONITORING_LOCATION_ID
                    || _.index === fieldIndex.MONITORING_SESSION_ID
            )
        , editedCustomFields = sample.fields
            && editedFields.some(editedField => (sample.fields || []).some(_ => _.index === editedField))
        , editedMonitoringState = 'monitoringState' in sample

    return editedInvestigatedRef || editedRequiredFields || editedCustomFields || editedMonitoringState
}

export default reasonRequired
