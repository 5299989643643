import { useSelector } from '_/facade/react'

import * as routes from '_/constants/routes'
import Link from '_/components/link'

import SampleList from '_/features/samples/list/list'
import NonViableSampleList from '_/features/non-viable-samples/list/list'
import { isBaseRoute } from '_/utils/router/common'

function Samples() {
    const route = useSelector(_ => _.router.route)

    return (
        <div className='d-flex flex-column h-100 d-print-block'>
            <nav className='navbar navbar-secondary navbar-expand flex-shrink-0'>
                <div className='collapse navbar-collapse'>
                    <ul className='navbar-nav'>
                        <li className='nav-item'>
                            <Link
                                routeName={routes.SAMPLES}
                                className='nav-link'
                            >
                                Viable samples
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                routeName={routes.NON_VIABLE_SAMPLES}
                                className='nav-link'
                            >
                                Non-viable samples
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
            {route && isBaseRoute(routes.NON_VIABLE_SAMPLES, route)
                ? <NonViableSampleList />
                : <SampleList />
            }
        </div>
    )
}

export default Samples
