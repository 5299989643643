import { classnames } from '_/facade/react'

import Button from '_/components/button'
import TabNavbar from '_/components/tab-navbar'

import * as flags from '_/constants/sample-flag'

import { getAlwaysAvailableFlags } from '../helpers'

interface FlagTabProps {
    flagId: number
    selectedFlag?: number
    onChange(flag?: number): void
}

const FlagTab = (props: FlagTabProps) => {
    const flag = flags.default.find(_ => _.id === props.flagId)

    return flag === undefined
        ? null
        : <Button
            className={classnames('btn-link navbar-tab me-4', {active: props.selectedFlag !== undefined && props.selectedFlag === flag.id})}
            onClick={() => props.onChange(flag.id)}
            testId={`sample-flag-${props.flagId}`}
        >
            {flag.name}
        </Button>

}

interface Props {
    onChange(flag?: number): void
    value?: number
    bookInConfirmationEnabled: boolean
    cfuCountVerificationEnabled: boolean
}

function SampleFlagInput (props: Props) {
    const alwaysAvailableFlags = getAlwaysAvailableFlags()
    return (
        <TabNavbar className='mt-3 d-print-none'>
            <Button
                className={classnames('btn-link navbar-tab me-4', {active: !props.value && props.value != 0 })}
                onClick={() => props.onChange(undefined)}
                testId='all-samples'
            >
                All viable samples
            </Button>
            {props.bookInConfirmationEnabled &&
                <FlagTab selectedFlag={props.value} flagId={flags.AWAITING_BOOK_IN_CONFIRMATION} onChange={props.onChange} />
            }
            {props.cfuCountVerificationEnabled &&
                <FlagTab selectedFlag={props.value} flagId={flags.AWAITING_CFU_COUNT_VERIFICATION} onChange={props.onChange} />
            }
            {alwaysAvailableFlags.map(flag =>
                <FlagTab key={flag.id} selectedFlag={props.value} flagId={flag.id} onChange={props.onChange} />
            )}
        </TabNavbar>
    )
}

export default SampleFlagInput
