import { useImperativeHandle, useLayoutEffect, useState } from '_/facade/react'
import { createPortal } from 'react-dom'

interface Props {
    top: number
    left: number
    width?: number
    children?: React.ReactNode
    dropdownRef?: React.Ref<HTMLElement>
    containerRef?: React.Ref<HTMLDivElement>
}

function useOverlayRoot() {
    const overlayRootClass = 'overlay-root'
        , [overlayRoot, setOverlayRoot] = useState(
            () => document.querySelector<HTMLDivElement>('.' + overlayRootClass) || undefined
        )

    useLayoutEffect(
        () => {
            if (overlayRoot)
                return

            const plane = document.createElement('div')
            plane.setAttribute('class', overlayRootClass)

            document.body.appendChild(plane)

            setOverlayRoot(plane)
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    return overlayRoot
}

function Overlay(props: Props) {
    const root = useOverlayRoot()
        , [dropdown, setDropdown] = useState<HTMLElement | null>(null)
        , [container, setContainer] = useState<HTMLDivElement | null>(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useLayoutEffect(
        () => {
            setDropdown(container ? container.firstElementChild as HTMLElement | null : null)
        }
    )

    useImperativeHandle(
        // False error, ref accepts null values, cast works around this condition
        props.containerRef as React.Ref<HTMLDivElement | null>,
        () => container
    )

    useImperativeHandle(
        // False error, ref accepts null values, cast works around this condition
        props.dropdownRef as React.Ref<HTMLElement | null>,
        () => dropdown
    )

    if (!root)
        return null

    return createPortal(
        <div
            ref={setContainer}
            style={{ top: props.top, left: props.left, width: props.width }}
            className='overlay-container'
            data-testid='overlay'
        >
            {props.children}
        </div>,
        root
    )
}

export default Overlay
