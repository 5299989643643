import WEEK from '_/constants/weekday'

import type { DistributionOfBreachesAcrossDaysInsight } from '_/model/insight/types'
import InsightsWidget from '../insights-widget'
import InsightBody from './insight-body'

interface Props {
    insight: DistributionOfBreachesAcrossDaysInsight
    dismiss: () => void
}

function DistributionOfBreachesAcrossDays(props: Props) {
    const { from, to, dayOfWeek } = props.insight.data
        , day = WEEK.find(_ => _.id === dayOfWeek)?.name
        , analysisRouteParams = { cumulativeView: false }

    return (
        <InsightsWidget
            dismiss={props.dismiss}
            header={`Did you know that there was an unusually high number of breaches occurring on ${day}s of last month?`}
        >
            <InsightBody analysisRouteParams={analysisRouteParams} insightId={props.insight.id} from={from} to={to} />
        </InsightsWidget>
    )
}

export default DistributionOfBreachesAcrossDays
