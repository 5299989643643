import type { LimitBreachReport, SampleData } from '_/model/reports/limit-breach-report/types'

import * as fieldIndex from '_/constants/custom-field-index'
import { getFieldValue } from '../samples/helpers'
import type { DateTime} from '_/model/date-time'
import { equals } from '_/model/date-time'
import { VOID_ID } from '_/utils/tree'

function getLimits(limitBreachReport: LimitBreachReport[], range: DateTime[]) {
    const locationSet = new Set<string>()
        , locations: string[] = []

    limitBreachReport.forEach(report => locationSet.add(report.locationId ?? VOID_ID))
    locationSet.forEach(_ => locations.push(_))

    return locations.map(locationId =>
        range.map(exposureDate => {
            const dayStats: LimitBreachReport = limitBreachReport.find(_ => equals(_.exposureDate, exposureDate) && locationId == (_.locationId ?? VOID_ID)) || {
                exposureDate,
                locationId,
                viableAlertLimitBreached: [],
                viableActionLimitBreached: [],
                nonViableAlertLimitBreached: [],
                nonViableActionLimitBreached: [],
                compromised: [],
            }
            return {
                ...dayStats,
            }
        })
    )
}

function getReferenceNumbers(sample: SampleData) {
    return getFieldValue(sample.fields, fieldIndex.REFERENCE_NUMBERS)
}

export {
    getLimits,
    getReferenceNumbers,
}
