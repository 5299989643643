import DatePicker from 'react-datepicker'
import Overlay from '_/components/overlay/overlay'
import { useTimeService } from '_/components/time'
import type { DateTime } from '_/model/date-time'

import type { DatePickerWrapperProps } from './local-date-input'

type WrapperTime = DateTime | ''
type PickerTime = Date | null

function UtcDatePickerWrapper(props: DatePickerWrapperProps) {
    const timeService = useTimeService()

    function handleChange(value: PickerTime) {
        if (props.onChange)
            props.onChange(convertFromPickerValue(value))
    }

    function convertToPickerValue(value: WrapperTime): PickerTime {
        if (value === '')
            return null

        const { year, month, day } = timeService.utcTimeStruct(value)
        return new Date(year, month - 1, day)
    }

    function convertFromPickerValue(value: PickerTime): WrapperTime {
        if (value == null)
            return ''

        const year = value.getFullYear()
            , month = value.getMonth() + 1
            , day = value.getDate()
            , [hour, minute, second, milli] = props.useDayEndTime ? [23, 59, 59, 999] : [0, 0, 0, 0]

        const result = timeService.utc(year, month, day, hour, minute, second, milli)

        return result
    }

    const CalendarContainer = (_: { children: React.ReactNode[] }) =>
        <Overlay top={0} left={0} width={props.calendarContainerWidth}>
            {_.children}
        </Overlay>

    return (
        <DatePicker
            id={props.id}
            className={props.className}
            selected={convertToPickerValue(props.value)}
            dateFormat='dd-MMM-yyyy'
            onChange={handleChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            maxDate={convertToPickerValue(props.max == undefined ? '' : props.max)}
            minDate={convertToPickerValue(props.min == undefined ? '' : props.min)}
            isClearable={props.isClearable}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            customInput={props.customInput}
            showMonthYearPicker={props.isMonthPicker}
            autoComplete='off'
            popperContainer={CalendarContainer}
        />
    )
}

UtcDatePickerWrapper.defaultProps = {
    isClearable: true,
}

export default UtcDatePickerWrapper
