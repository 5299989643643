import type { EmailConfirmation } from '_/model/auth/types'
import { useTimeService } from '_/components/time'
interface Props {
    emailConfirmation: EmailConfirmation | undefined
}

function EmailConfirmationMessage(props: Props) {
    const emailConfirmation = props.emailConfirmation
        , timeService = useTimeService()
        , expired = emailConfirmation && emailConfirmation.expired
        , confirmed = emailConfirmation && emailConfirmation.confirmed
        , inactive = emailConfirmation && emailConfirmation.inactive
        , processedAt = emailConfirmation && timeService.formatCtzDate(emailConfirmation.processedAt)
    return (
        <div>
            {confirmed &&
                <p className='text-danger text-start mt-4'>
                    <b>You confirmed this email change on {processedAt}</b>
                </p>
            }
            {(expired || inactive) &&
                <p className='text-danger text-start mt-4'>
                    <b>Confirmation link was not found or had expired</b>
                </p>
            }
        </div>
    )
}

export default EmailConfirmationMessage
