import { useAction, useCallback, useEffect, useState } from '_/facade/react'

import { useContextSwitchObserver } from '_/components/context-observer'

import PageHeader from '_/components/page-header'

import { ITEMS_PER_PAGE } from '_/constants'

import type { Trend } from '_/model/trends/types'
import type PaginationState from '_/model/pagination-state'

import * as a from './actions'
import type { TrendSettings } from '_/model/predefined-lists/trend-settings/types'
import TrendsTable from './trends-table'

interface Props {
    trendSettings: TrendSettings[]
    totalCount: number
    onReloadStatistic: () => void
}

function WithRefTrends(props: Props) {
    const [pagination, setPagination] = useState<PaginationState>({ start: 0, count: ITEMS_PER_PAGE })
        , [trends, onReloadTrends] = useTrends(pagination)

    return (
        <div className='d-flex flex-column h-100'>
            <PageHeader title='Trends with investigation references'/>
            <TrendsTable
                trends={trends}
                totalCount={props.totalCount}
                trendSettings={props.trendSettings}
                pagination={pagination}
                onReloadTrends={onReloadTrends}
                onReloadStatistic={props.onReloadStatistic}
                onChangePagination={setPagination}
            />
        </div>
    )
}

export default WithRefTrends

function useTrends(pagination: PaginationState) {
    const loadTrends = useAction(a.loadTrendsWithRef)
        , [trends, setTrends] = useState<Trend[]>([])
        , contextSwitch = useContextSwitchObserver()
        , load = useCallback(
            () => {
                loadTrends(pagination)
                    .then(setTrends)
            },
            [pagination, loadTrends]
        )

    useEffect(() => { load() }, [contextSwitch, load])

    return [trends, load] as const
}
