import { React, useState, classnames } from '_/facade/react'
import Button from '_/components/button'
import SampleIcon from '_/components/sample-icon/sample-icon'
import type { SampleTypeQuantity } from '_/model/scheduling/monitoring-groups/types'
import { formatSampleTypeName } from '_/model/scheduling/monitoring-groups/helpers'
import GroupsNumberField, { GroupsNumberFieldDisabled } from '_/components/groups-number-field'
import Menu, { useCloseMenu } from '_/components/overlay/menu'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { Value } from '_/components/form/number-input'
import FormattedText from '_/features/text/formatted-text'
import { plainText } from '_/model/text/text'

interface MonitoringGroupDataProps {
    value: SampleTypeQuantity[]
    sampleTypes: SampleType[]
    onChange(sampleTypeQuantities: SampleTypeQuantity[]): void
    canEdit: boolean
    testId?: string
}

function renderSampleTypePlates(sampleTypeQuantities: SampleTypeQuantity[], sampleTypes: SampleType[], testId?: string) {
    return sampleTypeQuantities
        .filter(_ => _.quantity > 0)
        .map((it, i) =>
            <span key={i} className='me-1' data-testid={`${testId}-${getSampleType(it.sampleTypeId, sampleTypes)!.name}-sample`}>
                <SampleIcon
                    type={getSampleType(it.sampleTypeId, sampleTypes)!.sampleType}
                    className='me-1'
                    sampleTypeName={formatSampleTypeName(it.sampleTypeId, sampleTypes)}
                    showTooltip
                />
                x {it.quantity}
            </span>
        )
}

function handleChange(
    value: Value,
    sampleTypeQuantities: SampleTypeQuantity[],
    sampleTypeQuantityIndex: number,
    onChange: (sampleTypeQuantities: SampleTypeQuantity[]) => void) {

    if (value === '')
        value = 0

    if (typeof value !== 'number'
        || value < 0
        || !Number.isInteger(value)) {
        return
    }

    const nextQuantities: SampleTypeQuantity[] = sampleTypeQuantities.map(it => ({...it}))

    if (nextQuantities[sampleTypeQuantityIndex]) {
        nextQuantities[sampleTypeQuantityIndex].quantity = value

        onChange(nextQuantities)
    }
}

const MenuBody = React.forwardRef((props: MonitoringGroupDataProps, ref: React.Ref<HTMLDivElement>) => (
    <div
        className={classnames('position-relative legend-bubble ms-3 py-2 bg-dark expectations-menu', {
            inversed: true,
        })}
        ref={ref}
    >
        {props.value.map((_, index) =>
            <div key={index} className='px-3 py-1 d-flex flex-fill justify-content-between align-items-center'>
                <SampleIcon className='flex-shrink-0' type={getSampleType(_.sampleTypeId, props.sampleTypes)!.sampleType} />
                <div className='text-white flex-grow-1 p-0 ms-2 expectation-sample-name-block'>
                    <FormattedText text={formatSampleTypeName(_.sampleTypeId, props.sampleTypes)} />
                </div>
                <div className='flex-shrink-0 expectation-input-block ms-2'>
                    {!getSampleType(_.sampleTypeId, props.sampleTypes)?.isActive
                        ? <GroupsNumberFieldDisabled
                            name={`sampleTypeQuantities[${index}].quantity`}
                            sampleTypeName={plainText(formatSampleTypeName(_.sampleTypeId, props.sampleTypes))}
                            id='sample-type'
                            value={_.quantity}
                            canEdit={props.canEdit}
                            onChange={_ => handleChange(_, props.value, index, props.onChange)}
                        />
                        : <GroupsNumberField
                            name={`sampleTypeQuantities[${index}].quantity`}
                            sampleTypeName={plainText(formatSampleTypeName(_.sampleTypeId, props.sampleTypes))}
                            id='sample-type'
                            value={_.quantity}
                            onChange={_ => handleChange(_, props.value, index, props.onChange)}
                            canEdit={props.canEdit}
                        />
                    }
                </div>
            </div>
        )}
    </div>
))

function MonitoringGroupData(props: MonitoringGroupDataProps) {
    const { value } = props
        , [openMenuBtn, setOpenMenuBtn] = useState<HTMLElement | null>(null)
        , [containerElement, setContainerElement] = useState<HTMLElement | null>(null)
        , [showExpectationsMenu, setShowExpectationsMenu] = useState(false)
        , [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null)

    useCloseMenu(menuRef, () => setShowExpectationsMenu(false), openMenuBtn)

    return (
        <td className='d-flex align-items-center flex-wrap' ref={setContainerElement}>
            {renderSampleTypePlates(value, props.sampleTypes, props.testId)}
            <div className='d-inline-block'>
                <Button
                    onClick={() => setShowExpectationsMenu(!showExpectationsMenu)}
                    className='show-expectations-button p-0'
                    testId={`${props.testId}-show-expectation-button`}
                >
                    <i
                        id={`location[${new Date().getTime()}]`}
                        className='material-icons text-muted align-middle'
                        ref={setOpenMenuBtn}
                    >
                        control_point
                    </i>
                </Button>
            </div>
            {value.length > 0 &&
                showExpectationsMenu
                    ? <Menu element={containerElement}>
                        <MenuBody ref={setMenuRef} {...props}/>
                    </Menu>
                    : null
            }
        </td>
    )
}

function getSampleType(id: string, sampleTypes: SampleType[]) {
    return sampleTypes.find(it => it.id === id)
}

export default MonitoringGroupData
