import { diffObject } from '_/utils/object'
import { noop } from '_/utils/function'
import type Api from '_/services/api-key-service'
import type { ApiKeyEdit } from './types'

function updateTransaction(
    id: string,
    current: ApiKeyEdit,
    next: ApiKeyEdit,
    api: Api,
    fromCreate?: boolean
): Promise<void> {
    const diff = diffObject(current, next)

    if (!diff && !fromCreate)
        return Promise.resolve()

    return api.save(id, fromCreate ? { description: next.description } : { ...diff }, !!fromCreate).then(noop)
}

export {
    updateTransaction,
}
