import type { SmartCheck as State } from '_/model/app-state'
import { commonReducer, handler } from '_/facade/reducer'
import * as a from './actions'

const defaultState: State = {
    currentReadingDeviceId: undefined,
    showSuggestedGrowthRegionsAndCounts: false,
}

export default commonReducer(
    defaultState,

    handler(a.readingDeviceChanged, (state, currentReadingDeviceId) => ({ ...state, currentReadingDeviceId })),
    handler(a.showSuggestedGrowthRegionsAndCountsChanged, (state, _) => ({ ...state, showSuggestedGrowthRegionsAndCounts: _ })),
)
