import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import { noop } from '_/utils/function'

import type Api from '_/services/floor-plan-service'
import uploadServiceFactory from '_/model/floor-plan/upload/upload-service'
import { uploadTransaction } from '_/model/floor-plan/upload/transaction'
import { updateTransaction } from '_/model/floor-plan/editor/transaction'
import * as a from './actions'
import * as toastActions from '_/features/toasts/actions'

const factory = (api: Api): EffectsFactory => {
    const uploadService = uploadServiceFactory(api)

    return dispatch => [
        handler(
            a.loadFloorPlanList,
            () =>
                api.list()
                    .then(a.floorPlanListLoaded)
                    .then(dispatch)
                    .then(noop)
        ),

        handler(a.getFloorPlan, api.get),

        handler(
            a.uploadImage,
            blob => uploadTransaction(blob, uploadService)
                .then(result => {
                    const baseImageData = {
                        id: result.id,
                        width: result.width,
                        height: result.height,
                    }

                    const uploadStarted = a.uploadStateChanged({
                            ...baseImageData,
                            uploaded: false,
                        })
                        , uploadCompleted = a.uploadStateChanged({
                            ...baseImageData,
                            uploaded: true,
                        })
                        , uploadFailed = a.clearImageUploads()

                    dispatch(uploadStarted)
                    result.promise.then(
                        _ => dispatch(uploadCompleted),
                        _ => {
                            dispatch(uploadFailed)
                            dispatch(toastActions.addError('The file upload failed. Please try again'))
                        },
                    )
                })
        ),

        handler(
            a.createFloorPlan,
            plan =>
                api.create(plan)
                    .then(() => dispatch(a.clearImageUploads))
                    .then(() => dispatch(a.loadFloorPlanList))
        ),

        handler(
            a.updateFloorPlan,
            _ =>
                updateTransaction(_.id, _.current, _.next, api)
                    .then(() => dispatch(a.loadFloorPlanList))
        ),

        handler(
            a.getImageTokens,
            imageId => {
                const tempTokens = uploadService.getTempTokens(imageId)
                return tempTokens ? Promise.resolve(tempTokens) : api.getDownloadTokens(imageId)
            }
        ),

        handler(
            a.clearImageUploads,
            () => dispatch(a.clearedImageUploads())
        ),

        handler(
            a.loadFloorPlanTrail,
            id => api.getTrail(id)
        ),

        handler(
            a.removeFloorPlan,
            id =>
                api.remove(id)
                    .then(() => dispatch(a.loadFloorPlanList))
        ),
    ]
}

export default factory
