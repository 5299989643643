import type { PredefinedLists as State } from '_/model/app-state'
import { commonReducer, handler } from '_/facade/reducer'
import { predefinedListLoaded } from '../redux/actions'
import * as actions from './actions'

const sampleOperatorsDefaultState: State['sampleOperators'] = []
    , sampleOperators = commonReducer(
        sampleOperatorsDefaultState,

        handler(actions.sampleOperatorListLoaded, (_, payload) => payload),
        handler(actions.sampleOperatorRemoved, (state, id) => state.filter(_ => _.id !== id)),
        handler(predefinedListLoaded, (_, payload) => payload.sampleOperators),
    )

export { sampleOperators }
