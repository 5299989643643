import '_/utils/input/number-input-broken-value-workaround'
import './init-help'
import { createRoot } from 'react-dom/client'

import { Provider } from 'react-redux'

import Shell from '_/features/shell/shell'
import { RouterProvider } from '_/utils/router'

import store, { router } from './store'

router.start()
const container = document.getElementById('app')
    , root = container && createRoot(container)

root?.render(
    <Provider store={store}>
        <RouterProvider value={router}>
            <Shell />
        </RouterProvider>
    </Provider>
)
