import { classnames, useAction, useEffect, useState, useCallback } from '_/facade/react'

import Button from '_/components/button'
import { useContextSwitchObserver } from '_/components/context-observer'
import NotificationRow from './notification-row'
import NotificationDropdown from './notification-dropdown'
import NotificationPermissionsWarningModal from './notification-permissions-warning-modal'
import NotificationChangeRoleModal from './notification-change-role-modal'

import * as routes from '_/constants/routes'
import type { NotificationInfo } from '_/model/notification/types'
import type Notification from '_/model/notification/types'
import * as a from '../actions'
import { Link } from '_/utils/router'
import { useNotificationClickHandler, useNavigateHandler, useChangeStatusHandler, useMarkAllAsReadHandler } from './common'

function Notifications() {
    const [showNotifications, setShowNotifications] = useState(false)
        , [notificationBtn, setNotificationBtn] = useState<HTMLButtonElement | null>(null)
        , [showOnlyUnread, setShowOnlyUnread] = useState(false)
        , [notifications, notificationInfo, reset] = useNotifications(showNotifications, showOnlyUnread)
        , [dialog, handleNotificationClick, handleModalClose] = useNotificationClick(setShowNotifications, reset)

        , handleMarkAsReadAndNavigateTo = useMarkAsReadAndNavigateTo(reset)
        , handleMarkAllAsRead = useMarkAllAsReadHandler(notifications, reset)
        , handleChangeStatus = useChangeStatusHandler(reset)
        , hasUnreadNotifications = notificationInfo && notificationInfo.unread !== 0

    return (
        <div className='position-relative'>
            <Button
                ref={setNotificationBtn}
                className={classnames('btn-link text-light p-0 shadow-none', { 'blue-focus': showNotifications })}
                onClick={() => setShowNotifications(!showNotifications)}
                testId='notifications-bell'
            >
                <i className='material-icons'>notifications</i>
                {hasUnreadNotifications && <span className='unread-notifications position-absolute' data-testid='unread-notifications'/>}
            </Button>
            {dialog.tag === 'access-warning' &&
                <NotificationPermissionsWarningModal onClose={handleModalClose} metadata={dialog.data} />
            }
            {dialog.tag === 'change-membership' &&
                <NotificationChangeRoleModal
                    onClose={handleModalClose}
                    data={dialog.data}
                    onRoleChanged={() => handleMarkAsReadAndNavigateTo(dialog.data.notification)}
                />
            }

            {showNotifications &&
                <NotificationDropdown element={notificationBtn} onClose={() => setShowNotifications(false)}>
                    {notificationInfo?.totalCount === 0
                        ? <div className='text-center my-2' data-testid='empty-notifications-placeholder'>No notifications to show</div>
                        : (
                            <div data-testid='notifications'>
                                <div className='d-flex justify-content-between mx-3'>
                                    <Button
                                        className='btn-link text-white px-0 py-0 shadow-none'
                                        onClick={() => setShowOnlyUnread(!showOnlyUnread)}
                                        testId={showOnlyUnread ? 'view-all-notifications' : 'show-only-unread'}
                                    >
                                        {showOnlyUnread ? 'View all' : 'Show only unread'}
                                    </Button>
                                    <Button
                                        className='btn-link text-white px-0 shadow-none'
                                        onClick={handleMarkAllAsRead}
                                        disabled={notifications.every(_ => _.isRead)}
                                        testId='mark-all-as-read'
                                    >
                                        Mark all as read
                                    </Button>
                                </div>
                                <div>
                                    {notifications.length === 0
                                        ? <div className='text-center my-2' data-testid='no-unread-notifications-placeholder'>
                                            No unread notifications. <br/>
                                            Click
                                            <Button
                                                className='btn-link text-white text-decoration-underline px-1 mb-1 align-baseline shadow-none'
                                                onClick={() => setShowOnlyUnread(!showOnlyUnread)}
                                            >
                                                View all
                                            </Button>
                                            to view all of your notifications
                                        </div>
                                        : notifications.map((notification, index) =>
                                            <NotificationRow
                                                key={notification.id}
                                                notification={notification}
                                                onChangeStatus={() => handleChangeStatus(notification)}
                                                onNotificationClick={() => handleNotificationClick(notification)}
                                                testId={`sample-notification-${index}`}
                                            />
                                        )
                                    }
                                    <div className='notifications-footer--color'>
                                        <Link onClick={() => setShowNotifications(false)} routeName={routes.NOTIFICATIONS} className='btn btn-link d-block w-100 p-2 text-white' testId='see-all-notifications'>
                                            See all {notificationInfo?.totalCount} notifications ({notificationInfo?.unread} unread)
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </NotificationDropdown>
            }
        </div>
    )
}

const NOTIFICATION_COUNT = 10

function useNotifications(showNotifications: boolean, showOnlyUnread: boolean) {
    const load = useAction(a.loadNotifications)
        , loadNotificationInfo = useAction(a.loadNotificationInfo)
        , contextSwitch = useContextSwitchObserver()
        , [notifications, setNotifications] = useState<Notification[]>([])
        , [notificationInfo, setNotificationInfo] = useState<NotificationInfo | undefined>()
        , reset = useCallback(
            () => {
                loadNotificationInfo().then(setNotificationInfo)

                if (showNotifications)
                    load({ count: NOTIFICATION_COUNT, unreadOnly: showOnlyUnread })
                        .then(setNotifications)
            },
            [load, loadNotificationInfo, showNotifications, showOnlyUnread]
        )

    useEffect(reset, [reset, contextSwitch])

    useEffect(() => {
        const interval = setInterval(reset, 30000)
        return () => clearInterval(interval)
    })

    return [notifications, notificationInfo, reset] as const
}

function useNotificationClick(setShowNotifications: (_: boolean) => void, reset: () => void) {
    const [dialog, handleCommonNotificationClick, handleCloseModal] = useNotificationClickHandler()

    function handleNotificationClick(notification: Notification) {
        setShowNotifications(false)
        handleCommonNotificationClick(notification).then(reset)
    }

    return [dialog, handleNotificationClick, handleCloseModal] as const
}

function useMarkAsReadAndNavigateTo(reset: () => void) {
    const navigateTo = useNavigateHandler()

    return (notification: Notification) => navigateTo(notification).then(reset)
}


export default Notifications
