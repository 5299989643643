import { actions as routerActions } from 'redux-router5'
import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import type { FormApi } from 'final-form'
import { React, useRef, useState, useEffect, useAction, forwardRef, useImperativeHandle, useSelector } from '_/facade/react'

import FormChangesObserver from '_/components/form/form-changes-observer'
import NumberInputField from '_/components/form/number-input-field'
import Button from '_/components/button'
import { CheckboxField, submitDisabled } from '_/components/form'
import { useTimeService } from '_/components/time'

import type { PredefinedLists } from '_/model/app-state'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type { NonViableSampleCreateRequest, NonViableSampleForm } from '_/model/non-viable-sample/booking/types'
import { IN_OPERATION } from '_/model/predefined-lists/action-alert-limit/monitoring-state'

import { noop } from '_/utils/function'
import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'
import { getBookableLocations } from '_/utils/exposure-location'
import { useDebounce } from '_/hooks/shared-hooks'

import * as fieldIndex from '_/constants/custom-field-index'
import { NON_VIABLE_SAMPLES_EDIT } from '_/constants/routes'
import { showGenericConfirmationModal } from '_/features/confirmation/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import * as sa from '_/features/spinner/actions'
import * as actions from '../actions'
import * as h from '_/features/samples/helpers'
import type { ResetFocusFormRef } from '_/features/samples/booking/sample-booking-form'
import { isValueChanged, resetNonViableForm, subLocationList } from '_/features/samples/booking/helpers'
import { formFieldHasValue, isFormFieldNotRecorded } from './helpers'
import { getDuplicatedBarcodeConfirmationInfo } from '../helpers'
import validate from './validate'

import AddCommentsIcon from '_/features/samples/booking/sample-add-comments-icon'
import NonViableSampleCustomFields from './non-viable-sample-form-custom-fields'
import CommentsModal from './non-viable-sample-comments-modal'
import NumberTextField from '_/components/form/number-text-field'

interface Props {
    exposureLocations: ListExposureLocation[]
    predefinedLists: PredefinedLists
    initialValue: NonViableSampleForm
    onSubmit: (_: Partial<NonViableSampleCreateRequest>) => Promise<void>
    formTarget: string
    isEditScreen?: boolean
}

function NonViableSampleFormComponent(props: Props, ref: React.ForwardedRef<ResetFocusFormRef>) {
    const editScreenPrefix = props.isEditScreen ? 'edit-' : ''
        , timeService = useTimeService()
        , [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false)
        , [comments, setComments] = useState<string[]>([])
        , [hideOptionalDate, setHideOptionalDate] = useState(false)
        , [lowerParticleNotRecordedRef, higherParticleNotRecordedRef, getRef, setRef, resetFocus] = useFieldRefsHandler(ref)
        , customFields = props.predefinedLists.customFields
        , [previousFields, verifyingBarcode, locationChildren, setLocationChildren, handleChangeFormValues] = useFormValuesChangeHandler(
            props.initialValue,
            props.exposureLocations,
            customFields,
            props.formTarget,
            props.isEditScreen,
            hideOptionalDate,
            setHideOptionalDate
        )
        , handleSubmit = useSubmit(comments, props.formTarget, reset, props.onSubmit)

    useMonitoringPositionEditModalUpdater(props.initialValue, previousFields, props.exposureLocations, setLocationChildren, props.isEditScreen)

    function reset(reading: Partial<NonViableSampleForm>, form: FormApi) {
        const endTimeField = props.predefinedLists.customFields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)
        resetNonViableForm(form, reading, props.predefinedLists)

        resetFocus()
        setComments([])
        setHideOptionalDate(!endTimeField?.nonViableSettings.persisted)
    }

    return (
        <Form<Partial<NonViableSampleForm>>
            onSubmit={(value, form) => handleSubmit(value, form)}
            initialValues={props.initialValue}
            validate={_ => validate(timeService, _, customFields)}
            render={form =>
                <form onSubmit={form.handleSubmit} autoComplete='off'>
                    <FormChangesObserver form={form} onChange={handleChangeFormValues} target={props.formTarget} />
                    <div className='col-12 px-3 d-flex flex-wrap sample-booking__form--persistent-fields-bg border-top border-white clearfix'>
                        {customFields.map((_, i) => _.nonViableSettings.persisted &&
                            <NonViableSampleCustomFields
                                key={i}
                                pos={i}
                                form={form}
                                initialValue={props.initialValue}
                                exposureLocations={props.exposureLocations}
                                predefinedLists={props.predefinedLists}
                                fieldPrefix={editScreenPrefix}
                                hideOptionalDate={hideOptionalDate}
                                locationChildren={locationChildren}
                                getRef={getRef}
                                setRef={setRef}
                            />
                        )}
                    </div>
                    <div className='col-12 px-3 d-flex flex-wrap bg-light border-top border-white clearfix'>
                        {customFields.map((_, i) => !_.nonViableSettings.persisted &&
                            <NonViableSampleCustomFields
                                key={i}
                                pos={i}
                                form={form}
                                initialValue={props.initialValue}
                                exposureLocations={props.exposureLocations}
                                predefinedLists={props.predefinedLists}
                                fieldPrefix={editScreenPrefix}
                                hideOptionalDate={hideOptionalDate}
                                locationChildren={locationChildren}
                                getRef={getRef}
                                setRef={setRef}
                            />
                        )}
                        <div className='mb-3 mx-1'>
                            <NumberInputField
                                id='lower-particle-count'
                                name='lowerParticle.count'
                                className='me-1'
                                inputRef={lowerParticleNotRecordedRef}
                                disabled={isFormFieldNotRecorded(form, 'lowerParticle.notRecorded')}
                                onKeyDown={_ => focusElementOnAltNInput(lowerParticleNotRecordedRef, _)}
                            >
                                0.5μm count
                            </NumberInputField>
                            <CheckboxField
                                id={`${editScreenPrefix}-lower-particle-not-recorded`}
                                name='lowerParticle.notRecorded'
                                className='mx-1'
                                tabIndex={isFormFieldNotRecorded(form, 'lowerParticle.notRecorded') ? 0 : -1}
                                inputRef={lowerParticleNotRecordedRef}
                            >
                                Not recorded
                            </CheckboxField>
                        </div>
                        <NumberTextField
                            id='lower-particle-volume'
                            name='lowerParticle.volume'
                            className='me-1'
                            disabled={!formFieldHasValue(form, 'lowerParticle.count')}
                        >
                            0.5μm vol. (m³)
                        </NumberTextField>
                        <div className='mb-3 mx-1'>
                            <NumberInputField
                                id='higher-particle-count'
                                name='higherParticle.count'
                                className='me-1'
                                inputRef={higherParticleNotRecordedRef}
                                disabled={isFormFieldNotRecorded(form, 'higherParticle.notRecorded')}
                                onKeyDown={_ => focusElementOnAltNInput(higherParticleNotRecordedRef, _)}
                            >
                                5μm count
                            </NumberInputField>
                            <CheckboxField
                                id={`${editScreenPrefix}-higher-particle-not-recorded`}
                                name='higherParticle.notRecorded'
                                className='mx-1'
                                tabIndex={isFormFieldNotRecorded(form, 'higherParticle.notRecorded') ? 0 : -1}
                                inputRef={higherParticleNotRecordedRef}
                            >
                                Not recorded
                            </CheckboxField>
                        </div>
                        <NumberTextField
                            id='higher-particle-volume'
                            name='higherParticle.volume'
                            className='me-1'
                            disabled={!formFieldHasValue(form, 'higherParticle.count')}
                        >
                            5μm vol. (m³)
                        </NumberTextField>
                        {!props.isEditScreen &&
                            <div className='mb-3 mx-4'>
                                <label className='col-form-label'>&nbsp;</label>
                                <AddCommentsIcon numberOfComments={comments.length} onClick={() => setCommentsModalIsOpen(true)} className='pt-1' />
                            </div>
                        }
                        {commentsModalIsOpen &&
                            <CommentsModal onClose={() => setCommentsModalIsOpen(false)} onAddComment={comment => setComments([comment].concat(comments))} comments={comments} />
                        }
                        <div className='mb-3 float-end'>
                            <label className='col-form-label'></label>
                            <div style={{marginTop: '12px'}}>
                                <Button onClick={form.form.submit} disabled={submitDisabled(form) || verifyingBarcode} className='btn-primary'>Save</Button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        />
    )
}

function useSubmit(
    comments: string[],
    formTarget: string,
    onFormReset: (_: Partial<NonViableSampleForm>, form: FormApi) => void,
    onSubmit: (_: Partial<NonViableSampleCreateRequest>) => Promise<void>
) {
    const hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , showSpinner = useAction(sa.showSpinner)
        , hideSpinner = useAction(sa.hideSpinner)

    function handleSubmit(reading: Partial<NonViableSampleForm>, form: FormApi) {
        const result = onSubmit({ ...reading, comments: comments.slice().reverse() })
            .then(noop)

        showSpinner()
        result
            .then(() => onFormReset(reading, form))
            .then(() => hasUnsavedChanges(false, formTarget))
            .finally(hideSpinner)

        return result
    }

    return handleSubmit
}

function useFormValuesChangeHandler(
    initialValues: NonViableSampleForm,
    exposureLocations: ListExposureLocation[],
    customFields: CustomField[],
    formTarget: string,
    isEditScreen: boolean | undefined,
    hideOptionalDate: boolean,
    setHideOptionalDate: (_: boolean) => void
) {
    const [lastCustomFields, setLastCustomFields] = useState(customFields)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , unsavedChangeTargets = useSelector(_ => _.unsavedChange.unsavedChangeTargets)
        , [verifyingBarcode, setVerifyingBarcode] = useState(false)
        , [locationChildren, setLocationChildren] = useState<ReturnType<typeof subLocationList>>([])
        , prevValues = useRef(initialValues)
        , verifyBarcodeDuplication = useDuplicateBarcodeVerification()

    useEffect(
        () => {
            if (lastCustomFields === customFields)
                return

            setLastCustomFields(customFields)
            prevValues.current = initialValues
        },
        [lastCustomFields, customFields, initialValues]
    )

    function handleChangeFormValues(values: NonViableSampleForm, form: FormRenderProps) {
        const fields = prevValues.current.fields
        form.form.batch(() => {
            const notRecordedValuesToClear = h.findNotRecordedFilledValues(values.fields)
            notRecordedValuesToClear.forEach(_ => form.form.change(h.valuePath(_), undefined))

            if (values.lowerParticle.notRecorded && values.lowerParticle.count !== undefined) {
                form.form.change('lowerParticle.count', undefined)
                form.form.change('lowerParticle.volume', undefined)
            }

            if (values.higherParticle.notRecorded && values.higherParticle.count !== undefined) {
                form.form.change('higherParticle.count', undefined)
                form.form.change('higherParticle.volume', undefined)
            }

            if (values.lowerParticle.count === undefined && values.lowerParticle.volume !== undefined)
                form.form.change('lowerParticle.volume', undefined)

            if (values.higherParticle.count === undefined && values.higherParticle.volume !== undefined)
                form.form.change('higherParticle.volume', undefined)

            const location = h.findFieldValue(fields, values.fields, fieldIndex.EXPOSURE_LOCATION_ID)
                , monitoringPosition = h.findFieldValue(fields, values.fields, fieldIndex.MONITORING_POSITION)
                , monitoringPositionPos = h.findFieldValuePos(fields, fieldIndex.MONITORING_POSITION)

            if (monitoringPosition?.notRecorded !== location?.notRecorded) {
                // note: when location is set notRecorded position is also set notRecorded
                // but is cleared on next handleChangeFormValues triggered by form.change call below
                form.form.change(h.notRecordedPath(monitoringPositionPos), location?.notRecorded)
            }

            if (!location?.notRecorded && isValueChanged(location, fields)) {
                form.form.change(h.valuePath(monitoringPositionPos), undefined)
                form.form.change('monitoringState', IN_OPERATION)

                setLocationChildren(subLocationList(getBookableLocations(exposureLocations), location?.value))
            }

            const barcode = h.findFieldValue(fields, values.fields, fieldIndex.BARCODE)
            if (isValueChanged(barcode, fields)) {
                const barcodePosition = h.findFieldValuePos(fields, fieldIndex.BARCODE)
                    , onDuplication = () => form.form.change(h.valuePath(barcodePosition), undefined)
                    , onComplete = () => setVerifyingBarcode(false)

                if (!verifyingBarcode)
                    setVerifyingBarcode(true)

                verifyBarcodeDuplication(barcode?.value ?? '', onDuplication, onComplete)
            }

            const startDate = h.findFieldValue(fields, values.fields, fieldIndex.EXPOSURE_START_DATE)?.value
            if (h.findFieldValue(fields, values.fields, fieldIndex.EXPOSURE_END_TIME)?.notRecorded)
                form.form.change(h.valuePath(h.findFieldValuePos(fields, fieldIndex.EXPOSURE_END_DATE)), startDate)
        })

        const endDate = h.findFieldValue(fields, values.fields, fieldIndex.EXPOSURE_END_DATE)
        if (isValueChanged(endDate, fields) && hideOptionalDate)
            setHideOptionalDate(false)

        if (!isEditScreen && !unsavedChangeTargets.some(_ => _ === formTarget))
            hasUnsavedChanges(true, formTarget)

        prevValues.current = values
    }

    return [prevValues.current.fields, verifyingBarcode, locationChildren, setLocationChildren, handleChangeFormValues] as const
}

function useDuplicateBarcodeVerification() {
    const debounce = useDebounce()
        , loadByBarcode = useAction(actions.loadNonViableSampleByBarcode)
        , showBarcodeDuplicateWarning = useAction(showGenericConfirmationModal)
        , navigateTo = useAction(routerActions.navigateTo)
        , info = getDuplicatedBarcodeConfirmationInfo()

    function verifyBarcodeDuplication(barcode: string, onDuplication: () => void, onComplete: () => void) {
        debounce(() => loadByBarcode(barcode)
            .then(reading => {
                if (!reading)
                    return Promise.resolve()

                return Promise.resolve()
                    .then(onDuplication)
                    .then(() => showBarcodeDuplicateWarning(info))
                    .then(() => navigateTo(NON_VIABLE_SAMPLES_EDIT, {id: reading.id}))
            })
            .finally(onComplete)
        )
    }

    return verifyBarcodeDuplication
}

function useMonitoringPositionEditModalUpdater(
    initialValue: NonViableSampleForm,
    previousFields: FieldValues[],
    exposureLocations: ListExposureLocation[],
    setLocationChildren: (_: ReturnType<typeof subLocationList>) => void,
    isEditScreen: boolean | undefined
) {
    const initalMount = useRef(true)

    useEffect(
        () => {
            if (!isEditScreen || initialValue.fields.length === 0 || !initalMount.current)
                return

            initalMount.current = false

            const location = h.findFieldValue(previousFields, initialValue.fields, fieldIndex.EXPOSURE_LOCATION_ID)
            setLocationChildren(subLocationList(getBookableLocations(exposureLocations), location?.value))
        },
        [initialValue.fields, previousFields, exposureLocations, setLocationChildren, isEditScreen]
    )
}

function useFieldRefsHandler(ref: React.ForwardedRef<ResetFocusFormRef>) {
    const thisRef = useRef(resetFocus)
        , fieldsRefs = useRef(new Map<string, React.RefObject<HTMLInputElement>>())
        , lowerParticleNotRecordedRef = useRef<HTMLInputElement | null>(null)
        , higherParticleNotRecordedRef = useRef<HTMLInputElement | null>(null)

    useImperativeHandle(ref, () => ({
        resetFocus: () => thisRef.current(),
    }))

    function resetFocus() {
        getRef(`${'field-' + fieldIndex.BARCODE}`)?.current?.focus()
    }

    function getRef(key: string) {
        return fieldsRefs.current.get(key)
    }

    function setRef(key: string) {
        fieldsRefs.current.set(key, React.createRef<HTMLInputElement>())
    }

    return [lowerParticleNotRecordedRef, higherParticleNotRecordedRef, getRef, setRef, resetFocus] as const
}

export default forwardRef(NonViableSampleFormComponent)
