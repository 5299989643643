import type { Contexts as State } from '_/model/app-state'
import type Context from '_/model/context/context'
import { commonReducer, handler, shallowUpdate } from '_/facade/reducer'
import * as tree from '_/utils/tree'

import * as actions from './actions'

const defaultState: State = {
    contexts: [],
    list: tree.emptyTree<Context>(),
}

export default commonReducer(
    defaultState,
    handler(actions.contextsLoaded, (state, list) => shallowUpdate(state, { list: tree.tree(list) })),
    handler(actions.clearContexts, state => shallowUpdate(state, { contexts: [] })),
    handler(actions.contextLoaded, (state, context) => shallowUpdate(state, { contexts: state.contexts.concat(context) })),
)
