import { classnames, useAction, useSelector, useState } from '_/facade/react'
import { Field, Form } from 'react-final-form'

import { Modal, ModalBody, ModalHeader } from '_/components/modal'
import { showFieldError } from '_/components/form'
import { Close } from '_/components/button'

import Error from '_/components/form/field-error'
import type { SignatureMethod } from '_/model/critical-change-reason/types'
import type Reason from '_/model/critical-change-reason/types'
import validate from '_/model/critical-change-reason/validate'
import { useError } from './helpers'
import * as actions from './actions'
import SignatureFields from './signature-fields'
import ReasonModalFooter from './modal-footer'

function ReasonForChangeModal() {
    const isSignatureRequired = useSelector(_ => _.reason.signatureRequired)
        , isSettlePlateChanged = useSelector(_ => _.reason.isSettlePlateChanged)
        , isPlateTypeChangedToSettlePlate = useSelector(_ => _.reason.isPlateTypeChangedToSettlePlate)
        , isFingerdabTwoHandsPlateChanged = useSelector(_ => _.reason.isFingerdabTwoHandsPlateChanged)
        , reason =  useSelector(_ => _.reason.reason)
        , ssoESignatureAllowed = useSelector(_ => _.auth.permissions).useSsoESignature
        , [signatureMethod, setSignatureMethod] = useState<SignatureMethod | undefined>(ssoESignatureAllowed && isSignatureRequired ? undefined : 'smartControl')

        , cancel = useAction(actions.cancelAction)
        , handleSubmit = useSubmit()

    useError()

    return (
        <Modal
            isOpen
            shouldCloseOnOutsideClick={false}
            onClose={cancel}
            contentClassName='overflow-auto'
        >
            <Form
                initialValues={{ reason }}
                onSubmit={handleSubmit}
                validate={(_: Partial<Reason>) => validate(_, isSignatureRequired)}
                render={form =>
                    <div>
                        <ModalHeader className='border-bottom-0 pb-0'>
                            <h4 data-testid='reason-for-change-modal'>Reason for change</h4>
                            <Close onClick={cancel} testId='close-reason-modal' />
                        </ModalHeader>
                        <ModalBody noDefaultHeight>
                            <form className='d-print-none mt-3' onSubmit={form.handleSubmit}>
                                <div className='d-flex'>
                                    <div className='flex-fill'>
                                        <Field name='reason' render={_ =>
                                            <>
                                                {isSignatureRequired && <div className='mb-2'>Please provide a reason for the change you have made</div>}
                                                <textarea
                                                    className={classnames('form-control reason-for-change__text-area', { 'is-invalid': showFieldError(_.meta) })}
                                                    {..._.input}
                                                    data-testid='field-reason-for-change'
                                                />
                                                <Error field={_} />

                                                {isSettlePlateChanged &&
                                                    <div className='alert alert-warning user-formatted-text mt-3' data-testid='reason-modal-warning'>
                                                        The change you made means that an exposure end time is not needed. SmartControl has marked the exposure end time as "Not available"
                                                    </div>

                                                }
                                                {isPlateTypeChangedToSettlePlate &&
                                                    <div className='alert alert-warning user-formatted-text mt-3'>
                                                        The change you made means that an exposure end time is now required. SmartControl has marked the exposure end time as "Not recorded"
                                                    </div>
                                                }
                                                {isFingerdabTwoHandsPlateChanged &&
                                                    <div className='alert alert-warning user-formatted-text mt-3'>
                                                        Changing the fingerdab type will merge any colonies that have been entered. Do you want to continue?
                                                    </div>
                                                }
                                            </>
                                        } />

                                        {isSignatureRequired &&
                                            <SignatureFields
                                                signatureMethod={signatureMethod}
                                                ssoESignatureAllowed={ssoESignatureAllowed}
                                                setSignatureMethod={setSignatureMethod}
                                            />
                                        }
                                    </div>
                                </div>
                            </form>
                        </ModalBody>
                        <ReasonModalFooter
                            form={form}
                            cancel={cancel}
                            signatureRequired={isSignatureRequired}
                            ssoESignatureAllowed={ssoESignatureAllowed}
                            setSignatureMethod={setSignatureMethod}
                            signatureMethod={signatureMethod}
                        />
                    </div>
                }
            />
        </Modal>
    )
}

export default ReasonForChangeModal

function useSubmit() {
    const allowWithReason = useAction(actions.allowAction)

    function handleSubmit(reason: Reason) {
        return allowWithReason(reason)
    }

    return handleSubmit
}
