import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import { updateTransaction } from '_/model/trends/transaction'
import type TrendService from '_/services/trend-service'

import * as actions from './actions'

const factory = (service: TrendService): EffectsFactory => () => [
    handler(actions.loadTrends, service.list),
    handler(actions.loadTrendStatistics, service.getTrendStatistics),
    handler(actions.createTrendInvestigationRef, payload => service.createTrendInvestigationRef(payload.id, payload.newTrend)),
    handler(actions.saveTrendInvestigationRef, payload =>
        updateTransaction(payload.id, payload.oldTrend, payload.newTrend, service)
    ),
    handler(actions.loadTrendsWithRef, _ => service.getTrends({..._, withReference: true})),
    handler(actions.loadTrendsWithoutRef, _ => service.getTrends({..._, withReference: false})),
    handler(actions.loadTrendTrail, service.getTrail),
    handler(actions.searchTrendInvestigationRef, service.searchTrendInvestigationRef),
    handler(actions.searchTrendId, service.searchTrendId),
]

export default factory
