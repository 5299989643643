import { diffObject } from '_/utils/object'
import type Api from '_/services/floor-plan-service'
import type { FloorPlanEdit } from '_/model/floor-plan/editor/types'

function updateTransaction(
    id: string,
    current: FloorPlanEdit,
    next: FloorPlanEdit,
    api: Api,
): Promise<void> {
    const diff = diffObject(current, next)

    return diff ? api.save(id, diff) : Promise.resolve()
}

export {
    updateTransaction,
}
