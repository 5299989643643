import type PaginationState from '_/model/pagination-state'
import { shallowUpdate } from '_/utils/object'

import { Page } from './components'

interface Props {
    totalCount: number
    state: PaginationState
    onChange: (state: PaginationState) => void
}

function Pagination(props: Props) {
    const { state, totalCount } = props
        , currentPage = calcCurrentPage(state)
        , maxPage = totalCount > 0 ? Math.ceil(totalCount / state.count) - 1 : 0
        , pages = calcSuggestedPages(currentPage, maxPage)

    function handlePageClick(page: number) {
        notifyPageChange(page)
    }

    function handlePrevClick() {
        notifyPageChange(calcCurrentPage(state) - 1)
    }

    function handleNextClick() {
        notifyPageChange(calcCurrentPage(state) + 1)
    }

    function handleFirstClick() {
        notifyPageChange(0)
    }

    function handleLastClick() {
        notifyPageChange(Math.ceil(totalCount / state.count) - 1)
    }

    function notifyPageChange(page: number) {
        const start = page * state.count
            , nextState = shallowUpdate(state, { start })

        props.onChange(nextState)
    }

    return (
        <nav>
            <ul className='pagination justify-content-center'>
                <Page
                    page={NaN}
                    disabled={currentPage === 0}
                    onClick={handleFirstClick}
                    testId='first-page'
                >&laquo;</Page>
                <Page
                    page={NaN}
                    disabled={currentPage === 0}
                    onClick={handlePrevClick}
                    testId='previous-page'
                >&lsaquo;</Page>
                {pages.map(_ =>
                    <Page
                        key={_}
                        page={_}
                        disabled={_ === currentPage}
                        active={_ === currentPage}
                        onClick={handlePageClick}
                    >{_ + 1}</Page>
                )}
                <Page
                    page={NaN}
                    disabled={currentPage === maxPage}
                    onClick={handleNextClick}
                    testId='next-page'
                >&rsaquo;</Page>
                <Page
                    page={NaN}
                    disabled={currentPage === maxPage}
                    onClick={handleLastClick}
                    testId='last-page'
                >&raquo;</Page>
            </ul>
        </nav>
    )
}

export default Pagination

function calcCurrentPage(state: PaginationState): number {
    const count = state.count
        , start = state.start % count === 0 ? state.start : 0 // normalize start
        , currentPage = start / count

    return currentPage
}

function calcSuggestedPages(page: number, maxPage: number): number[]  {
    const MAX_LENGTH = 6
        , numbersAroundPage = sequence(page - Math.floor(MAX_LENGTH / 2), MAX_LENGTH)
        , firstNumber = numbersAroundPage[0]
        , lastNumber = numbersAroundPage[numbersAroundPage.length - 1]
        , leftAdjustedNumbers = firstNumber < 0
            ? numbersAroundPage.concat(sequence(lastNumber + 1, -firstNumber))
            : numbersAroundPage
        , rightAdjustedNumbers = lastNumber > maxPage
            ? sequence(firstNumber - lastNumber + maxPage, lastNumber - maxPage).concat(leftAdjustedNumbers)
            : leftAdjustedNumbers
        , result = rightAdjustedNumbers
            .filter(n => n >= 0)
            .filter(n => n <= maxPage)
            .filter((_, i) => i < MAX_LENGTH)

    return result
}

function sequence(start: number, count: number): number[] {
    return Array.from({ length: count }, (_, i) => i + start)
}
