import type { Filters as State } from '_/model/app-state'
import { commonReducer, handler, shallowUpdate } from '_/facade/reducer'
import * as actions from './actions'


const defaultState: State = {
    filters: [],
}

export default commonReducer(
    defaultState,

    handler(
        actions.filterApplied,
        (state, filter) => shallowUpdate(state, { filters: state.filters.filter(_ => _.name !== filter.name).concat(filter) })
    ),
    handler(actions.clearFilters, () => defaultState),
)
