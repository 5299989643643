import { useEffect, useMemo } from 'react'
import { useAction, useSelector } from '_/facade/react'
import { fullNameLocationList } from '_/utils/exposure-location'
import { memoize } from '_/utils/function'
import * as h from '_/features/samples/helpers'
import * as pa from '_/features/predefined-lists/redux/actions'

const memGetEditableFields = memoize(h.getEditableSampleCustomFields)

function useBookInDependentData(isViable: boolean) {
    const loadPredefinedLists = useAction(pa.loadPredefinedLists)
        , predefinedLists = useSelector(_ => _.predefinedLists)
        , predefinedListsResult = useMemo(
            () => {
                const memCustomFields = memGetEditableFields(predefinedLists.customFields)
                    , customFields = h.filterNotShownOnBookingInFields(memCustomFields, isViable)
                    , sampleTypes = h.filterInactiveEntities(predefinedLists.sampleTypes)
                    , sampleOperators = h.filterInactiveEntities(predefinedLists.sampleOperators)
                    , sampleSessions = h.filterInactiveEntities(predefinedLists.sampleSessions)

                return { ...predefinedLists, customFields, sampleTypes, sampleOperators, sampleSessions }
            },
            [predefinedLists, isViable]
        )
        , exposureLocations = h.filterInactiveEntities(fullNameLocationList(predefinedLists.exposureLocations))

    useEffect(
        () => {
            loadPredefinedLists({ includeInactiveCustomFields: true })
        },
        [loadPredefinedLists]
    )

    return [predefinedListsResult, exposureLocations] as const
}

export {
    useBookInDependentData,
}
