const UP_POINTING_TRIANGLE = '▲'
    , DOWN_POINTING_TRIANGLE = '▼'
    , SMALL_UP_POINTING_TRIANGLE = '▴'
    , SMALL_DOWN_POINTING_TRIANGLE = '▾'

export {
    UP_POINTING_TRIANGLE,
    DOWN_POINTING_TRIANGLE,
    SMALL_UP_POINTING_TRIANGLE,
    SMALL_DOWN_POINTING_TRIANGLE,
}
