const PENDING = 0
    , REJECTED = 1
    , ACCEPTED = 2

type StateId = never
    | typeof PENDING
    | typeof REJECTED
    | typeof ACCEPTED

const CONTEXT_INVITATION_STATE: { id: StateId, name: string }[] = [
    {
        id: PENDING,
        name: 'Pending',
    },
    {
        id: REJECTED,
        name: 'Rejected',
    },
    {
        id: ACCEPTED,
        name: 'Accepted',
    },
]

export {
    CONTEXT_INVITATION_STATE as default,
    PENDING,
    REJECTED,
    ACCEPTED,
    StateId,
}
