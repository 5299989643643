import { useAction, useState, useSelector } from '_/facade/react'
import type { NotificationWarningMetadata, NotificationChangeRoleMetadata } from '_/model/notification/types'
import type Notification from '_/model/notification/types'
import { getRoute, getRouteParams, validateEntityAccessibility } from '_/model/notification/helpers'
import * as routerActions from '_/features/routing/actions'
import * as authActions from '_/features/auth/actions'
import * as a from '../actions'

type NotificationNavigateDialog =
    { tag: 'none' }
    | { tag: 'access-warning', data: NotificationWarningMetadata }
    | { tag: 'change-membership', data: NotificationChangeRoleMetadata }

function useNavigateHandler() {
    const markAsRead = useAction(a.markAsRead)
        , navigateTo = useAction(routerActions.navigateTo)

    return (notification: Notification) =>
        markAsRead(notification.id)
            .then(
                _ => navigateTo(getRoute(notification), getRouteParams(notification))
            )
}

function useChangeStatusHandler(reset: () => void) {
    const markAsRead = useAction(a.markAsRead)
        , markAsUnread = useAction(a.markAsUnread)

    return (notification: Notification) => {
        const action = notification.isRead ? markAsUnread : markAsRead

        return action(notification.id).then(reset)
    }
}

function useMarkAllAsReadHandler(notifications: Notification[], reload: () => void) {
    const markAllAsRead = useAction(a.bulkMarkAsRead)

    return () => markAllAsRead(notifications.map(_ => _.id)).then(reload)
}

function useNotificationClickHandler() {
    const [dialog, setDialog] = useState<NotificationNavigateDialog>({ tag: 'none' })
        , navigate = useNavigateHandler()
        , loadMemberships = useAction(authActions.loadMemberships)
        , currentMembershipId = useSelector(_ => _.auth.user?.membership.id)

    function handleNotificationClick(notification: Notification) {
        return loadMemberships()
            .then(memberships => {
                const accessibilityResult = validateEntityAccessibility(memberships, getRoute(notification), notification.contextId)

                if (accessibilityResult.type === 'memberships') {
                    if (accessibilityResult.memberships.some(_ => _.id === currentMembershipId))
                        return navigate(notification)
                    else
                        setDialog({ tag: 'change-membership', data: { notification, accessibleMemberships: accessibilityResult.memberships }})
                }
                else {
                    setDialog({
                        tag: 'access-warning',
                        data: { notification, reason: accessibilityResult.type },
                    })
                }
            })
    }

    function handleCloseModal() {
        setDialog({ tag: 'none' })
    }

    return [dialog, handleNotificationClick, handleCloseModal] as const
}

export type {
    NotificationNavigateDialog,
}

export {
    useNavigateHandler,
    useChangeStatusHandler,
    useMarkAllAsReadHandler,
    useNotificationClickHandler,
}
