import { React } from '_/facade/react'

import { Modal, ModalBody, ModalFooter } from '_/components/modal'
import Button from '_/components/button'
import type { SmartCheckResult } from '_/model/smart-check/image'

interface Props {
    result: SmartCheckResult
    onDiscard(): void
    onAccept(): void
}

function ValidationModal(props: Props) {
    const result = props.result
    return (
        <Modal isOpen onClose={props.onAccept} shouldCloseOnOutsideClick={false}>
            <ModalBody>
                <div>
                    {!result.blurCheckPassed && <div>The photo is blurred. Alignment cannot be accurately checked.</div>}
                    {!result.alignPassed && result.blurCheckPassed && <div>The sample in the photo you captured appears not to be in frame.</div>}
                    {!result.repetitionPassed && <div>The photo you captured appears to be the same as the last one.</div>}
                    <div>Do you want to keep it, or discard it and capture a new photo?</div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className='container-fluid'>
                    <div className='d-flex justify-content-center'>
                        <Button onClick={props.onAccept} className='btn-secondary me-2' testId='keep-photo'>Keep this photo</Button>
                        <Button onClick={props.onDiscard} className='btn-primary' autoFocus testId='discard-photo'>Discard this photo</Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ValidationModal
