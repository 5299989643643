import type { FieldRenderProps } from 'react-final-form'
import { showFieldError } from '_/components/form/helpers'

function Error (props: { field: FieldRenderProps<any> }) {
    return showFieldError(props.field.meta)
        ? <span className='invalid-feedback' data-testid='validation-error'>{props.field.meta.error}</span>
        : null
}

export default Error
