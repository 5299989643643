import * as fieldIndex from '_/constants/custom-field-index'
import type Sample from '_/model/sample/sample'
import type { PredefinedLists } from '_/model/app-state'

import { customFieldName } from '_/model/predefined-lists/custom-field/custom-field'
import * as f from '_/model/sample/format'

import { getFieldValue } from '_/features/samples/helpers'
import TotalCFU from '_/features/samples/list/total-cfu'
import LimitInfo from '_/features/samples/limit-info'
import FormattedText from '_/features/text/formatted-text'
import { useTimeService } from './time'
import Button from './button'
import { Table } from './table'
import type { SampleReportType} from '_/model/reports/sample-report-type'
import { LIMIT_BREACH_LOCATION_REPORT, LIMIT_BREACH_OPERATOR_REPORT, SAMPLE_LOCATION_REPORT } from '_/model/reports/sample-report-type'
import Contaminations from './contaminations'
import NoDataAvailable from '_/features/reports/no-data-available'

interface Props {
    samples: Sample[]
    sampleReportType: SampleReportType
    predefinedLists: PredefinedLists
    footer?: React.ReactNode
    className?: string
    onClickLocation?: (locationId: string) => void
    onClickSampleName?: (sampleId: string) => void
    noDataMessage: string
    testId?: string
    showSpinner?: boolean
}

function SampleLocationTable(props: Props) {
    const timeService = useTimeService()
        , isLimitBreachOperatorReport = props.sampleReportType === LIMIT_BREACH_OPERATOR_REPORT
        , isSampleLocationReport = props.sampleReportType === SAMPLE_LOCATION_REPORT
        , isLimitBreachLocationReport = props.sampleReportType === LIMIT_BREACH_LOCATION_REPORT
        , showGradeColumn = isLimitBreachOperatorReport || isLimitBreachLocationReport
        , showObjectionableColumn = isLimitBreachOperatorReport || isLimitBreachLocationReport
        , showOperatorColumn = isSampleLocationReport || isLimitBreachLocationReport

    function columnName(fieldIndex: fieldIndex.FieldIndex) {
        return customFieldName(fieldIndex, props.predefinedLists.customFields)
    }

    return (
        <Table className={props.className}>
            <thead className='thead'>
                <tr className='table-header-thin' data-testid='location-info-table-header'>
                    <th>{columnName(fieldIndex.EXPOSURE_DATE)}</th>
                    <th>Position</th>
                    {showGradeColumn && <th>Grade</th>}
                    <th>{columnName(fieldIndex.SESSION_ID)}</th>
                    <th>CFUs</th>
                    <th>Identification</th>
                    {showObjectionableColumn && <th>Objectionable</th>}
                    <th>{columnName(fieldIndex.SAMPLE_TYPE_ID)}</th>
                    {showOperatorColumn && <th>{columnName(fieldIndex.OPERATORS_IDS)}</th>}
                </tr>
            </thead>
            <tbody>
                {props.samples.length > 0 && !props.showSpinner
                    ? props.samples.map(_ =>
                        <tr key={_.id} data-testid='location-info-table-data'>
                            <td className='sample-list__column-word-wrap-wide'>
                                {timeService.formatCtzDate(getFieldValue(_.fields, fieldIndex.EXPOSURE_START_DATE))}
                            </td>
                            <td className='sample-list__column-word-wrap-wide'>
                                {props.onClickLocation
                                    ? <Button
                                        className='btn-link p-0 break-word'
                                        onClick={() => props.onClickLocation?.(getFieldValue(_.fields, fieldIndex.EXPOSURE_LOCATION_ID))}
                                        testId={`${props.testId}-location-name-cell`}
                                    >
                                        <FormattedText text={f.formatExposureLocation(_.monitoringState, _.fields, props.predefinedLists)} />
                                    </Button>
                                    : <FormattedText text={f.formatExposureLocation(_.monitoringState, _.fields, props.predefinedLists)} />
                                }
                            </td>
                            {showGradeColumn &&
                                <td className='sample-list__column-word-wrap-narrow'>
                                    <FormattedText text={f.formatExposureLocationGrade(_.monitoringState, _.fields, props.predefinedLists)} />
                                </td>
                            }
                            <td className='sample-list__column-word-wrap-narrow'>
                                <FormattedText text={f.formatByFieldIndex(fieldIndex.SESSION_ID, _.fields, props.predefinedLists)} />
                            </td>
                            <td className='sample-list__column-word-wrap-narrow'>
                                <TotalCFU sample={_}/>
                                <LimitInfo sample={_}/>
                            </td>
                            <td className='sample-list__column-word-wrap-narrow'>
                                <Contaminations identifications={_.identifications} />
                                <Contaminations identifications={_.optionalIdentifications} />
                            </td>
                            {showObjectionableColumn && <td className='sample-list__column-word-wrap-narrow text-capitalize'>{hasObjectionable(_)}</td>}
                            <td className='sample-list__column-word-wrap-wide'>
                                {props.onClickSampleName
                                    ? <Button className='btn-link p-0' onClick={() => props.onClickSampleName?.(_.id)}>
                                        <FormattedText text={f.formatByFieldIndex(fieldIndex.SAMPLE_TYPE_ID, _.fields, props.predefinedLists)} />
                                    </Button>
                                    : <FormattedText text={f.formatByFieldIndex(fieldIndex.SAMPLE_TYPE_ID, _.fields, props.predefinedLists)} />
                                }
                            </td>
                            {showOperatorColumn && <td className='sample-list__column-word-wrap-narrow user-formatted-text'>
                                <FormattedText text={f.formatOperators(_.fields, props.predefinedLists)} />
                            </td>}
                        </tr>
                    )
                    : <tr>
                        <td className='text-center' colSpan={getColSpan(showGradeColumn, showObjectionableColumn, showOperatorColumn)}>
                            <NoDataAvailable showSpinner={!!props.showSpinner}>
                                <span className='text-center text-muted text-uppercase' data-testid={`${props.testId}-no-data-message`}>{props.noDataMessage}</span>
                            </NoDataAvailable>
                        </td>
                    </tr>
                }
            </tbody>
            {props.footer}
        </Table>
    )
}

function getColSpan(showGradeColumn: boolean, showObjectionableColumn: boolean, showOperatorColumn: boolean) {
    let columnsAmount = 7

    if (showGradeColumn)
        columnsAmount += 1

    if (showObjectionableColumn)
        columnsAmount += 1

    if (showOperatorColumn)
        columnsAmount += 1

    return columnsAmount
}

function hasObjectionable(sample: Sample) {
    const hasObjectionable = sample.identifications.rows.some(_ => _.objectionable)
        || sample.optionalIdentifications?.rows.some(_ => _.objectionable)
    return (!!hasObjectionable).toString()
}

export default SampleLocationTable
