import { actionFactory } from '_/utils/redux'

import type { Limits, LimitsCreate, EditLimitsProps } from '_/model/predefined-lists/action-alert-limit/types'
import type { GradeEdit, EditGradeProps } from '_/model/predefined-lists/grade/grade'
import type Grade from '_/model/predefined-lists/grade/grade'
import type { EditNonViableLimitsProps, NonViableLimitCreate, NonViableLimits } from '_/model/predefined-lists/action-alert-limit/non-viable-limits'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.action-alert-limits')

interface SaveLimitsProps {
    editedLimits: EditLimitsProps[]
    newLimits: Partial<LimitsCreate>[]
    grade: EditGradeProps
    nonViableEditedLimits: EditNonViableLimitsProps[]
    newNonViableLimits: NonViableLimitCreate[]
}

export const loadLimitsList = action<void, Promise<Limits[]>>('loadLimitsList')
           , loadLimitsTrail = action<void, Promise<AuditTrail>>('loadLimitsTrail')
           , saveLimits = action<SaveLimitsProps, Promise<void>>('saveLimits')

           , loadGradesList = action<void, Promise<Grade[]>>('loadGrades')
           , gradesListLoaded = action<Grade[]>('gradesListLoaded')
           , saveGrade = action<{ id: string, oldGrade: GradeEdit, newGrade: GradeEdit }, Promise<void>>('saveGrade')
           , gradeChanged = action<{ id: string, grade: GradeEdit }>('gradeChanged')
           , createGrade = action<GradeEdit, Promise<void>>('createGrade')
           , gradeCreated = action<Grade>('gradeCreated')

           , loadNonViableLimitsList = action<void, Promise<NonViableLimits[]>>('loadNonViableLimitsList')
           , saveNonViableLimits = action<{editedLimits: EditLimitsProps[], newLimits: Partial<LimitsCreate>[], grade: EditGradeProps }, Promise<void>>('saveNonViableLimits')
