import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type ApiService from '_/services/api-service'

import * as actions from './actions'
import type { SearchIndex } from '_/model/help/search-index'

const factory = (service: ApiService): EffectsFactory => _ => [
    handler(actions.loadDocument, page =>
        service.getDocument(['help', ...page.split('/')])
    ),

    handler(actions.loadSearchIndex, () =>
        service.get<SearchIndex>(['help', 'index.json'])
            .then(_ => _.index)
    ),
]

export default factory
