import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'
import { classnames } from '_/facade/react'

interface SubmitProps {
    className?: string
    disabled?: boolean
    hasNoPermissions?: boolean
    title?: string
    onClick?: () => void
    testId?: string
}

const Submit: React.FC<SubmitProps> = (props) =>
    <button className={classnames(`btn btn-primary ${props.className || ''}`, {
            'no-permission': props.hasNoPermissions,
        })}
        type='submit'
        disabled={props.hasNoPermissions || props.disabled}
        title={props.hasNoPermissions ? NO_PERMISSION_MESSAGE : props.title}
        onClick={props.onClick}
        data-testid={props.testId}
    >
        {props.children}
    </button>

export default Submit
