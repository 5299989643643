import type { FormRenderProps } from 'react-final-form'
import { useEffect, useMemo } from '_/facade/react'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import FormattedText from '_/features/text/formatted-text'
import type { SampleEditedInfo } from '_/model/sample/sample'
import type SampleDetails from '_/model/sample/sample-details'
import * as fi from '_/constants/custom-field-index'

import * as h from '../../helpers'
import EditedLabel from '../edited-label'
import FieldForm from '../field-form'
import type { MonitoringGroupProps, VacantExpectationDialogResult } from '../../shared/vacant-expectation-dialog'
import { useMonitoringGroups } from '_/hooks/shared-hooks'
import { formatSampleMonitoringGroup } from '_/model/sample/format'
import type { Tree } from '_/utils/tree'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'

interface Props {
    sample: SampleDetails
    exposureLocations: Tree<ExposureLocation>
    entity: SampleDetailsEdit
    onSubmit: (sample: SampleDetailsEdit) => Promise<void>
    hasNoPermissions: boolean
    editedInfo: SampleEditedInfo | undefined
    getVacantExpectation: (_: MonitoringGroupProps) => Promise<VacantExpectationDialogResult>
}

function MonitoringGroupForm(props: Props) {
    function handleMount(form: FormRenderProps<any>, handleSave: () => Promise<void>, handleCancel: () => void) {
        const modalResult = props.getVacantExpectation({
                fields: props.sample.fields,
                sampleId: props.sample.id,
                id: {
                    groupId: h.getFieldValue(props.sample.fields, fi.MONITORING_GROUP_ID),
                    locationId: h.getFieldValue(props.sample.fields, fi.MONITORING_LOCATION_ID),
                    sessionId: h.getFieldValue(props.sample.fields, fi.MONITORING_SESSION_ID),
                },
                mustChangeId: true,
                skipIfAvailableExpectationSelected: false,
            })

        modalResult.then(_ => {
            form.form.change('fields', _.fields)

            handleSave().catch(handleCancel)
        })

        modalResult.catch(handleCancel)
    }

    function handleSubmit(subEntity: SampleDetailsEdit) {
        const fields = subEntity.fields
            , sampleEdit = { ...props.entity, fields }

        return props.onSubmit(sampleEdit)
    }

    const [monitoringGroups, adHocGroups] = useMonitoringGroups()
        , sampleMonitoringGroupName = formatSampleMonitoringGroup(props.sample.fields, monitoringGroups, adHocGroups, props.exposureLocations)
        , initialValues = useMemo(
            () => ({
                fields: props.entity.fields,
            }),
            [props.entity.fields]
        )

    return (
        <FieldForm
            formId='monitoring-group'
            label='Monitoring group'
            testId='sample-field-monitoring-group'
            initialValues={initialValues}
            onSubmit={handleSubmit}
            hasNoPermissions={props.hasNoPermissions}
            renderView={() =>
                <span className='form-control-plaintext fw-bold'>
                    <FormattedText text={sampleMonitoringGroupName} />
                    <EditedLabel editedInfo={props.editedInfo}/>
                </span>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div className='d-flex align-items-start'>
                    <div className='flex-fill form-control-plaintext fw-bold'>
                        <FormattedText text={sampleMonitoringGroupName} />
                        <EditedLabel editedInfo={props.editedInfo}/>
                    </div>
                    <Lifecycle onMount={() => handleMount(form, handleSave, handleCancel)}/>
                </div>
            }
        />
    )
}

export default MonitoringGroupForm

interface LifecycleProps {
    onMount?: () => void
}

// somewhat hacky approach, don't use elsewhere
function Lifecycle(props: LifecycleProps) {
    useEffect(
        () => {
            props.onMount?.()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return null
}
