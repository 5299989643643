import { classnames, useSelector } from '_/facade/react'
import { useDebounce, useSyncRef } from '_/hooks/shared-hooks'

import type { FormRenderProps} from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'

import type User from '_/model/users/types'
import type * as pf from '_/model/plate/plate-filter'

import { LocalDateField, MultiSelectField, TextField } from '_/components/form'
import FilterActions from '_/components/filter-actions'
import Button, { Close } from '_/components/button'

import { diffObject } from '_/utils/object'
import { checkDateRangeValidity, minLengthForSearch } from '_/utils/form/validate'

interface Props {
    showFilter: boolean
    users: User[]
    filter: pf.Filter
    onChange: (values: pf.Filter) => void
    onClose: () => void
    onClearFilter: () => void
}

function Filter(props: Props) {
    const previousFilter = useSyncRef(props.filter)
        , submitDebounce = useDebounce()
        , canReadUsers = useSelector(_ => _.auth.permissions.readUsers)

    function handleChangeFormValues(values: pf.Filter, form: FormRenderProps<pf.Filter>) {
        const prevValues = previousFilter.current
        previousFilter.current = values

        if (diffObject(prevValues, values))
            submitDebounce(form.form.submit)
    }

    function handleClear(form: FormRenderProps) {
        form.form.reset()
        props.onClearFilter()
    }

    function validate(entity: Partial<pf.Filter>) {
        const dateTo = checkDateRangeValidity(entity.dateFrom, entity.dateTo) || undefined
            , barcode = minLengthForSearch(4, 'barcode')(entity.barcode) || undefined

        return { dateTo, barcode }
    }

    return (
        <Form<pf.Filter>
            onSubmit={props.onChange}
            initialValues={props.filter}
            validate={validate}
            render={form =>
                <div className={classnames('position-absolute filters-popup d-flex flex-column side-filters', { 'filters-popup-hide': !props.showFilter })}>
                    <nav className='navbar mx-0'>
                        <span className='navbar-brand me-auto'>Filters</span>
                        <Close onClick={props.onClose} />
                    </nav>
                    <form className='mb-2 overflow-y-auto flex-fill' onSubmit={form.handleSubmit}>
                        <FormSpy
                            onChange={_ => handleChangeFormValues(_.values, form)}
                            subscription={{ values: true }}
                        />
                        <div className='px-3'>
                            <LocalDateField id='dateFrom' name='dateFrom'>From</LocalDateField>
                            <LocalDateField id='dateTo' name='dateTo' useDayEndTime>To</LocalDateField>
                            <MultiSelectField
                                name='reader'
                                id='reader'
                                entities={props.users}
                                calcId={_ => _.id}
                                calcName={_ => `${_.name} (${_.email}) `}
                                hasNoPermissions={!canReadUsers}
                                autocomplete
                            >
                                Reader
                            </MultiSelectField>
                            <TextField name='barcode'>Barcode</TextField>
                        </div>

                        <div className='side-filters__spacer' />

                        <FilterActions className='d-flex side-filters__actions'>
                            <Button className='btn-link ms-auto' onClick={() => handleClear(form)}>Clear</Button>
                        </FilterActions>
                    </form>
                </div>
            }
        />
    )
}

export default Filter
