const CURRENT_PERIOD = 0
    , PREVIOUS_PERIOD = 1
    , TWO_PERIODS_AGO = 2
    , THREE_PERIODS_AGO = 3
    , FOUR_PERIODS_AGO = 4
    , FIVE_PERIODS_AGO = 5
    , SIX_PERIODS_AGO = 6

type ContextualReportPeriod = never
    | typeof CURRENT_PERIOD
    | typeof PREVIOUS_PERIOD
    | typeof TWO_PERIODS_AGO
    | typeof THREE_PERIODS_AGO
    | typeof FOUR_PERIODS_AGO
    | typeof FIVE_PERIODS_AGO
    | typeof SIX_PERIODS_AGO

const REPORT_PERIODS: { id: ContextualReportPeriod, name: string }[] = [
    {
        id: CURRENT_PERIOD,
        name: 'Current period',
    },
    {
        id: PREVIOUS_PERIOD,
        name: 'Previous period',
    },
    {
        id: TWO_PERIODS_AGO,
        name: '2 periods ago',
    },
    {
        id: THREE_PERIODS_AGO,
        name: '3 periods ago',
    },
    {
        id: FOUR_PERIODS_AGO,
        name: '4 periods ago',
    },
    {
        id: FIVE_PERIODS_AGO,
        name: '5 periods ago',
    },
    {
        id: SIX_PERIODS_AGO,
        name: '6 periods ago',
    },
]

export {
    REPORT_PERIODS as default,
    CURRENT_PERIOD,
    PREVIOUS_PERIOD,
    TWO_PERIODS_AGO,
    THREE_PERIODS_AGO,
    FOUR_PERIODS_AGO,
    FIVE_PERIODS_AGO,
    SIX_PERIODS_AGO,
    ContextualReportPeriod,
}
