import { React, classnames, useState } from '_/facade/react'

import HoverMenu from '_/components/overlay/hover-menu'
import NoDataAvailable from '../no-data-available'

interface Props {
    samplesTotalCount: number | undefined
    emptyMessage?: string
    title: string | number | undefined
    caption: string
    trend?: number
    children?: React.ReactNode
    showSpinner: boolean
}

function KpiBox(props: Props) {
    const hintContainerRef = React.createRef<HTMLDivElement>()
        , [containerElement, setContainerElement] = useState<HTMLElement | null>(null)
        , [blockElement, setBlockElement] = useState<HTMLElement | null>(null)

    return (
        <div className='col-3'>
            <div className='block-border text-center py-3'>
                <div className='d-flex justify-content-center' ref={setContainerElement}>
                    <div ref={setBlockElement} className='pb-2'>
                        {props.samplesTotalCount === 0 && props.emptyMessage || props.showSpinner
                            ? <NoDataAvailable showSpinner={props.showSpinner}>
                                <span>{props.emptyMessage}</span>
                            </NoDataAvailable>
                            : <div>
                                <div className='d-flex justify-content-center'>
                                    <h4>{props.title}</h4>
                                    {props.trend !== undefined &&
                                        <i className={
                                            classnames(
                                                'material-icons md-24 text-secondary',
                                                {'text-danger rotate-315': props.trend > 0},
                                                {'text-success rotate-45 ps-1': props.trend < 0},
                                            )}
                                        >
                                            arrow_forward
                                        </i>
                                    }
                                </div>
                                <div className='text-secondary'>{props.caption}</div>
                            </div>
                        }
                    </div>
                    {blockElement && props.children &&
                        <HoverMenu element={containerElement} alignmentElement={blockElement} showTriangle>
                            <div ref={hintContainerRef} className='legend-bubble bg-dark'>
                                <div className='text-white p-3'>
                                    {props.children}
                                </div>
                            </div>
                        </HoverMenu>
                    }
                </div>
            </div>
        </div>
    )
}

export default KpiBox
