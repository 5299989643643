import { useSelector, useAction, useCallback } from '_/facade/react'

import ExportModal from '_/features/export/export-modal'

import * as routes from '_/constants/routes'
import * as routerActions from '_/features/routing/actions'

interface Props {
    totalCount: number
}

function SampleExportModal(props: Props) {
    const navigateToList = useNavigateToList()
        , contextDescription = useSelector(_ => _.auth.user?.membership.description)

    return (
        <ExportModal
            totalCount={props.totalCount}
            entityType='non-viable sample'
            modalText={`You're about to export data for ${props.totalCount} non-viable samples from ${contextDescription}.`}
            navigateToList={navigateToList}
        />
    )
}

export default SampleExportModal

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.NON_VIABLE_SAMPLES), [navigateTo])

    return navigateToList
}
