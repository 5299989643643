import type { CustomReportEdit } from '_/model/analysis/custom-report/custom-report'
import type CustomReport from '_/model/analysis/custom-report/custom-report'
import type { AnalysisFilter } from '_/model/analysis/filter/types'
import AGGREGATION_PERIOD from '_/model/analysis/aggregation-period'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import CHART_TYPE, { ORGANISMS_BREAKDOWN_CHART, PARTICLE_COUNTS } from '_/model/analysis/chart-type'
import SUB_CHART_TYPE from '_/model/analysis/sub-chart-type'
import { graphSeriesName, isChartTypeWithSubCharts, isLinesMarkersChart } from '_/features/analysis/ui/helpers'
import type { ReportReminderEdit } from '_/model/analysis/custom-report/report-reminder'
import type ReportReminder from '_/model/analysis/custom-report/report-reminder'

function convertToEdit(report?: CustomReport, reminder?: ReportReminder): ReportReminderEdit & CustomReportEdit | undefined {
    if (!report)
        return

    return {
        name: report.name,
        frequency: reminder && reminder.frequency,
        reminderStartDate: reminder && reminder.reminderStartDate,
        recipients: reminder && reminder.recipients && reminder.recipients.map(_ => _.id),
    }
}

function formatGraphType(filter: AnalysisFilter, fields: CustomField[]) {
    const graphSeries = filter.seriesFieldIndex !== undefined && isLinesMarkersChart(filter.chartType) ? `, by ${graphSeriesName(filter.seriesFieldIndex, fields)}` : ''
        , aggregationPeriod = filter.chartType === ORGANISMS_BREAKDOWN_CHART || filter.chartType === PARTICLE_COUNTS
            ? ''
            : `, ${AGGREGATION_PERIOD.find(_ => _.id === filter.aggregationPeriod)?.name.toLowerCase()}`
        , chartName = isChartTypeWithSubCharts(filter.chartType)
            ? SUB_CHART_TYPE.find(_ => _.id === filter.subChartType)?.name
            : CHART_TYPE.find(_ => _.id === filter.chartType)?.name

    return `${chartName}${graphSeries}${aggregationPeriod}`
}

export {
    convertToEdit,
    formatGraphType,
}
