import { useSelector } from '_/facade/react'

import Button from '_/components/button'
import Link from '_/components/link'
import PageHeader from '_/components/page-header'
import SampleIcon from '_/components/sample-icon/sample-icon'
import SiteTimeZoneDateFilter from '_/components/site-time-zone-date-filter'

import Key from '_/features/key/key'
import PLATE_TYPE, { FINGERDAB_TWO_HANDS_PLATE } from '_/constants/plate-type'
import * as r from '_/constants/routes'
import type { DateTime } from '_/model/date-time'
import { useTimeService } from '_/components/time'
import * as s from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'

interface Props {
    date: DateTime
    text: string
    selectMode: boolean
    onChangeDate: (_: DateTime) => void
    onChangeShowFilter: () => void
    onChangeSelectMode: () => void
}

function Header(props: Props) {
    const mediumTypes = PLATE_TYPE.filter(_ => _.id !== FINGERDAB_TWO_HANDS_PLATE)
        , timeService = useTimeService()
        , permissions = useSelector(_ => _.auth.permissions)

    function handleDateChange(date: DateTime | undefined) {
        if (date === undefined)
            return

        props.onChangeDate(date)
    }

    return (
        <PageHeader title={
            <div className='d-flex align-items-center' data-testid='monitoring-overview-title'>
                {`Monitoring overview for ${timeService.formatUtcDate(props.date)}`}
                <Key>
                    <div>
                        {mediumTypes.map((plate, index) => <SampleIcon key={plate.id} type={plate.id} className='me-1' status={s.BOOKED_IN} testId={`booked-in-icon-${index}`} />)}
                        <span className='ms-3 mb-1'>Booked in </span>
                    </div>
                    <div className='mt-1'>
                        {mediumTypes.map((plate, index) => <SampleIcon key={plate.id} type={plate.id} className='me-1' status={s.SCHEDULED_NOT_BOOKED_IN} testId={`scheduled-icon-${index}`} />)}
                        <span className='ms-3'>Scheduled, not booked in </span>
                    </div>
                    <div className='mt-1'>
                        {mediumTypes.map((plate, index) => <SampleIcon key={plate.id} type={plate.id} className='me-1' status={s.NOT_IN_USE} testId={`not-in-use-icon-${index}`} />)}
                        <span className='ms-3'>Not in use</span>
                    </div>
                    <div className='mt-1'>
                        {mediumTypes.map((plate, index) => <SampleIcon key={plate.id} type={plate.id} className='me-1' status={s.MISSING} testId={`not-in-use-icon-${index}`} />)}
                        <span className='ms-3'>Missed</span>
                    </div>
                </Key>
                <Link
                    routeName={r.SCHEDULING_MONITORING_OVERVIEW_TRAIL}
                    className='btn btn-link'
                >
                    Audit trail
                </Link>
            </div>
        }>
            <div className='d-inline-flex'>
                <span className='align-self-center'>{props.text}</span>
                <Button
                    className='btn-link me-2'
                    onClick={props.onChangeShowFilter}
                    disabled={props.selectMode}
                    testId='open-filters'
                >
                    <i className='material-icons align-middle me-2'>tune</i>
                    <span className='pt-1'>Filters</span>
                </Button>
                <Button
                    className='btn-link me-2'
                    hasNoPermissions={!permissions.editMonitoringOverview}
                    onClick={props.onChangeSelectMode}
                >
                    <i className='material-icons align-middle me-2'>check_circle_outline</i>
                    <span className='pt-1'>Select</span>
                </Button>
                <SiteTimeZoneDateFilter onChange={handleDateChange} initialFilter={props.date} />
            </div>
        </PageHeader>
    )
}

export default Header
