import { useAction, useState, useEffect } from '_/facade/react'

import * as routes from '_/constants/routes'
import * as ranges from '_/constants/date-ranges'

import Button from '_/components/button'

import * as operatorActions from '_/features/predefined-lists/sample-operator/actions'
import type { SampleOperator } from '_/model/predefined-lists/operator/types'
import type { OperatorInsight } from '_/model/insight/types'
import InsightsWidget from './insights-widget'
import { useNavigateTo } from './helpers'
import { useSampleTypes } from '_/hooks/shared-hooks'
import * as sampleName from '_/constants/plate-type'
import { OPERATORS_IDS, SAMPLE_TYPE_ID } from '_/constants/custom-field-index'
import { LIMIT_BREACH_FLOOR_PLAN } from '_/model/analysis/chart-type'
import { paramsFilter } from '_/model/filters/helpers'
import { formatActiveState } from '_/utils/format/common'
import { defaultTextNode, emptyTextNode } from '_/model/text/text'
import FormattedText from '../text/formatted-text'

interface Props {
    insight: OperatorInsight
    dismiss: () => void
}

function OperatorTrend(props: Props) {
    const operatorId = props.insight.data.operatorId
        , operator = useOperator(operatorId)
        , data = props.insight.data
        , operatorName = operator ? formatActiveState(operator.name, operator.isActive) : [emptyTextNode()]
        , header = [
            defaultTextNode('Did you know that operator '),
            ...operatorName,
            defaultTextNode(` had ${data.breaches === 1 ? `${data.breaches} finger dab breach` : `${data.breaches} finger dab breaches`} over the past ${data.days} days?`)
        ]
        , navigateTo = useNavigateTo(props.insight.id)
        , sampleTypes = useSampleTypes()
            .filter(_ => _.sampleType === sampleName.FINGERDAB_PLATE || _.sampleType === sampleName.FINGERDAB_TWO_HANDS_PLATE)
            .map(_ => _.id)
        , fields = [
            { index: SAMPLE_TYPE_ID, value: sampleTypes },
            { index: OPERATORS_IDS, value: [operatorId] }
        ]
        , routeParams = {
            operatorId,
            exposureStartDateFrom: data.from,
            exposureStartDateTo: data.to,
            exposureDateRange: ranges.CUSTOM,
            chartType: LIMIT_BREACH_FLOOR_PLAN,
            fields
        }

    return (
        <InsightsWidget
            dismiss={props.dismiss}
            header={header}
        >
            <Button onClick={() => navigateTo(routes.OPERATORS_REPORT, paramsFilter(routeParams))} className='btn-link text-start d-block p-0'>
                See an operator report for <FormattedText text={operatorName} />
            </Button>
            <Button onClick={() => navigateTo(routes.OPERATORS_REPORT, { ...paramsFilter({...routeParams}), scrollTo: 'organism-pie-chart' })} className='btn-link text-start d-block p-0'>
                See which organisms have been recovered by operator <FormattedText text={operatorName} />
            </Button>
            <Button onClick={() => navigateTo(routes.ANALYSIS, paramsFilter({...routeParams, includeCompromised: false }))} className='btn-link d-block text-start p-0'>
                See at which locations breaches occurred
            </Button>
        </InsightsWidget>
    )
}

function useOperator(id: string) {
    const loadOperator = useAction(operatorActions.loadSampleOperator)
        , [operator, setOperator] = useState<SampleOperator | undefined>()

    useEffect(
        () => {
            loadOperator(id).then(setOperator)
        },
        [loadOperator, id]
    )

    return operator
}

export default OperatorTrend
