import type TrailService from '_/services/audit-trail-service'
import type { EffectsFactory} from '_/facade/effect'
import { handler } from '_/facade/effect'
import * as trailActions from './actions'

const factory = (service: TrailService): EffectsFactory => _ => [
    handler(trailActions.loadTrailList, service.getTrails),
    handler(trailActions.getDeletedInfo, service.getDeletedInfo),
]

export default factory
