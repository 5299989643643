import { UnitContainer } from '_/components/layout'
import { Link } from '_/utils/router'
import { LOG_IN } from '_/constants/routes'

const ContextInviteConfirmed: React.FC = () =>
    <UnitContainer>
        <h2>Invitation Accepted</h2>

        <div>You were successfully added to the context</div>
        <Link className='btn btn-link ps-0' routeName={LOG_IN} testId='go-to-login'>Back to login page</Link>
    </UnitContainer>

export default ContextInviteConfirmed
