import type { FormRenderProps } from 'react-final-form'

import type { SignatureMethod } from '_/model/critical-change-reason/types'

import Button from '_/components/button'
import {  ModalFooter } from '_/components/modal'
import { submitDisabled } from '_/components/form'

interface Props {
    form: FormRenderProps<any>
    signatureRequired: boolean
    ssoESignatureAllowed: boolean
    signatureMethod: SignatureMethod | undefined
    cancel: () => void
    setSignatureMethod: (_: SignatureMethod | undefined) => void
}

function ReasonModalFooter(props: Props) {
    const showGoBackButton = props.ssoESignatureAllowed && !props.form.values.idToken && props.signatureRequired

    return (
        <ModalFooter className='border-top-0'>
            {props.signatureMethod &&
                <div>
                    <Button onClick={() => showGoBackButton ? props.setSignatureMethod(undefined) : props.cancel()} testId='cancel-reason'>
                        {showGoBackButton ? 'Go back' : 'Cancel'}
                    </Button>
                    <Button
                        className='btn-primary'
                        onClick={props.form.form.submit}
                        disabled={submitDisabled(props.form)}
                        testId='save-reason'
                    >
                        Save change
                    </Button>
                </div>
            }
        </ModalFooter>
    )
}

export default ReasonModalFooter
