const ERROR = 1
    , SUCCESS = 2

import type * as self from './toast-type'
type ToastType = (typeof self)[keyof typeof self]

export {
    ERROR,
    SUCCESS,
    ToastType,
}
