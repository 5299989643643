import type { ValidationResult} from '_/utils/form/validate'
import { isEmpty, required, equalLength, allowedName, minLength, maxLength } from '_/utils/form/validate'
import type { Device } from './types'

function validate(entity: Partial<Device>, devices: Device[]) {
    const name = entity.id && isEmpty('Device name')(entity.name)
        , notAllowedNames = entity.id && allowedName('Device name')(entity.name)
        , duplicate = entity.id && entity.name
            && devices
                .filter(_ => _.id !== entity.id)
                .find(_ => _.name.trim().toUpperCase() === entity.name!.trim().toUpperCase())
        , deviceNameMinLength = minLength('Device name', 5)(entity.name)
        , deviceNameMaxLength = maxLength('Device name', 100)(entity.name)
        , deviceIdLength = equalLength('Device ID', 10)(entity.deviceId)
        , requiredDeviceId = required('Device ID')(entity.deviceId)
        , requiredDeviceType = required('Device type')(entity.type)
        , result: ValidationResult<Device> = {}

    if (name)
        result.name = name

    if (notAllowedNames)
        result.name = notAllowedNames

    if (deviceIdLength)
        result.deviceId = deviceIdLength

    if (requiredDeviceId)
        result.deviceId = requiredDeviceId

    if (requiredDeviceType)
        result.type = requiredDeviceType

    if (duplicate)
        result.name = `Device with name '${entity.name?.trim()}' already exists`

    if (deviceNameMinLength)
        result.name = deviceNameMinLength

    if (deviceNameMaxLength)
        result.name = deviceNameMaxLength

    return result
}

export default validate
