import { classnames } from '_/facade/react'
import type { DashboardStatisticsTotals } from '_/model/dashboard/types'

import Link from '_/components/link'

import * as routes from '_/constants/routes'
import * as statuses from '_/constants/sample-status'
import * as flags from '_/constants/sample-flag'
import * as dateType from '_/constants/search-date-type'
import { DASHBOARD_BOOKED_IN_MORE_THAN_PERIOD } from '_/constants/dashboard'

import { generatePastDate } from '../helpers'
import { useTimeService } from '_/components/time'
import { paramsFilter } from '_/model/filters/helpers'

interface Props {
    totals: DashboardStatisticsTotals
    className?: string
    bookInConfirmationEnabled: boolean
    cfuCountVerificationEnabled: boolean
}

function Totals(props: Props) {
    const timeService = useTimeService()
        , defaultRouteParams = { sort: 'exposureStartTime:desc' }

    return(
        <div className={classnames('dashboard-totals', props.className)}>
            <div className='dashboard-totals--header'>
                <div className='dashboard-totals--header-text me-5' data-testid='samples-overview'>Viable samples overview</div>
            </div>
            <div>
                {props.bookInConfirmationEnabled &&
                    <div>
                        <Link
                            className='dashboard-totals--number pe-2'
                            routeName={routes.SAMPLES}
                            routeParams={paramsFilter({ ...defaultRouteParams, flag: flags.AWAITING_BOOK_IN_CONFIRMATION })}
                        >
                            {props.totals.awaitingBookInConfirmationSamplesCount}
                        </Link>
                        Awaiting book in confirmation
                    </div>
                }
                {props.cfuCountVerificationEnabled &&
                    <div>
                        <Link
                            className='dashboard-totals--number pe-2'
                            routeName={routes.SAMPLES}
                            routeParams={paramsFilter({ ...defaultRouteParams, flag: flags.AWAITING_CFU_COUNT_VERIFICATION })}
                        >
                            {props.totals.awaitingCfuConfirmationSamplesCount}
                        </Link>
                        Awaiting CFU count verification
                    </div>
                }
                <div>
                    <Link
                        className='dashboard-totals--number pe-2'
                        routeName={routes.SAMPLES}
                        routeParams={paramsFilter({ ...defaultRouteParams, statuses: [statuses.BOOKED_IN] })}
                    >
                        {props.totals.bookedInSamplesCount}
                    </Link>
                    Booked in
                </div>
                <div>
                    <Link
                        className='dashboard-totals--number pe-2'
                        routeName={routes.SAMPLES}
                        routeParams={paramsFilter({
                            ...defaultRouteParams,
                            statuses: [statuses.BOOKED_IN],
                            dateTo: timeService.ctzDayStart(generatePastDate(timeService, DASHBOARD_BOOKED_IN_MORE_THAN_PERIOD)),
                            dateToFilter: dateType.CREATED_DATE,
                        })}
                    >
                        {props.totals.bookedInMoreThan7DaysSamplesCount}
                    </Link>
                    Booked in for more than 7 days
                </div>
                <div>
                    <Link
                        className='dashboard-totals--number pe-2'
                        routeName={routes.SAMPLES}
                        routeParams={paramsFilter({ ...defaultRouteParams, statuses: [statuses.GROWTHS_AWAITING_IDENTIFICATION] })}
                    >
                        {props.totals.awaitingIdentificationSamplesCount}
                    </Link>
                    Awaiting identification
                </div>
                <div>
                    <Link
                        className='dashboard-totals--number pe-2'
                        routeName={routes.SAMPLES}
                        routeParams={paramsFilter({ ...defaultRouteParams, flag: flags.FOR_REVIEW })}
                    >
                        {props.totals.forReviewSamplesCount}
                    </Link>
                    For review
                </div>
            </div>
        </div>
    )
}

export default Totals
