import { Form, FormSpy } from 'react-final-form'
import type { FormApi } from 'final-form'

import { useState, useSelector, useAction, useCallback, useEffect, classnames, useRef } from '_/facade/react'
import { ITEMS_PER_PAGE } from '_/constants'
import { Table, PaginatedFooter } from '_/components/table'
import ContextObserver from '_/components/context-observer'
import { Submit, submitDisabled } from '_/components/form'
import Button from '_/components/button'
import PageHeader from '_/components/page-header'

import type { AllIdentifications } from '_/model/predefined-lists/identifications/identifications'
import type { OrganismIdentification, OrganismIdentificationFilter } from '_/model/predefined-lists/organism-identification/types'
import type PaginationState from '_/model/pagination-state'
import type Page from '_/model/page'

import TextFieldInline from '_/components/form/text-field-inline'
import * as actions from './actions'
import * as identificationsActions from '_/features/predefined-lists/identifications/actions'
import * as spinnerAction from '_/features/spinner/actions'
import * as deletionActions from '_/features/confirmation/actions'
import validate from './validate'
import { useDebounce } from '_/hooks/shared-hooks'
import { diffObject } from '_/utils/object'

function OrganismIdentifications() {
    const initialFilter: OrganismIdentificationFilter = {}
        , initialPagination = { start: 0, count: ITEMS_PER_PAGE }
        , [filter, setFilter] = useState<OrganismIdentificationFilter>(initialFilter)
        , [pagination, setPagination] = useState<PaginationState>(initialPagination)
        , [organismIdentifications, allIdentifications, reload] = useLoadOrganismIdentificationList(pagination, filter)
        , permissions = useSelector(_ => _.auth.permissions)
        , handleRemove = useDelete(reload)
        , handleCreate = useCreate(reload)
        , handleSave = useSave(reload)
        , importOrganismIdentification = useAction(actions.importOrganismIdentification)
        , previousFilter = useRef(initialFilter)

    function handleFilesChanged(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files)
            return

        const files = Array.from(e.target.files)

        importOrganismIdentification(files).then(resetFilter)
    }

    function resetFilter() {
        setFilter(initialFilter)
        setPagination(initialPagination)
    }

    function handleFilterChange(newFilter: OrganismIdentificationFilter) {
        setFilter(newFilter)
        setPagination(initialPagination)
    }

    function handleChangeFormValues<T extends OrganismIdentificationFilter>(values: T) {
        if (!diffObject(previousFilter.current.fullName, values.fullName)) {
            return
        }

        previousFilter.current = values

        handleFilterChange({ fullName: values.fullName })
    }

    return (
        <>
            <ContextObserver onChange={resetFilter} />
            <PageHeader title='Organism identifications'>
                {permissions.editOrganismIdentification &&
                    <Form
                        onSubmit={handleCreate as any}
                        validate={_ => validate(_, allIdentifications)}
                        render={form =>
                            <form onSubmit={form.handleSubmit} className='d-flex align-items-start'>
                                <FormSpy
                                    onChange={_ => handleChangeFormValues(_.values)}
                                    subscription={{ values: true }}
                                />
                                <TextFieldInline name='fullName' placeholder='Search' className='me-2'/>
                                <TextFieldInline name='name' placeholder='Name' className='me-2'/>
                                <Submit disabled={submitDisabled(form)}>Create</Submit>
                                <label
                                    htmlFor='import-organism-identification'
                                    className={classnames('btn btn-secondary my-0 ms-1')}
                                >
                                    Import
                                </label>
                                <input
                                    onChange={handleFilesChanged}
                                    accept='.csv'
                                    value=''
                                    name='myFile'
                                    type='file'
                                    className='d-none'
                                    autoComplete='off'
                                    id='import-organism-identification'
                                />
                            </form>
                        }
                    />
                }
            </PageHeader>

            <div className="overflow-auto flex-fill">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {organismIdentifications.items.length === 0
                            ? <tr>
                                <td colSpan={3} className="text-center">No results found</td>
                            </tr>
                            : organismIdentifications.items.map((_, i) =>
                                <tr key={i}>
                                    <td>{_.name}</td>
                                    <td>{_.isActive ? 'Active' : 'Inactive'}</td>
                                    <td className='text-end'>
                                        <Button
                                            className='btn-link py-0'
                                            onClick={() => handleSave(_)}
                                            disabled={!_.inUse && _.isActive}
                                        >
                                            {_.isActive ? 'Mark as inactive' : 'Mark as active'}
                                        </Button>
                                        <Button
                                            className='text-danger btn-link py-0'
                                            onClick={() => handleRemove(_)}
                                            disabled={_.inUse}
                                        >
                                            Remove
                                        </Button>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                    <PaginatedFooter
                        colSpan={3}
                        state={pagination}
                        onChange={setPagination}
                        totalCount={organismIdentifications.totalCount}
                    />
                </Table>
            </div>
        </>
    )
}

export default OrganismIdentifications

function useLoadOrganismIdentificationList(pagination: PaginationState, filter: OrganismIdentificationFilter) {
    const loadOrganismIdentification = useAction(actions.loadOrganismIdentificationList)
        , loadAllIdentifications = useAction(identificationsActions.loadAllIdentifications)
        , [organismIdentifications, setOrganismIdentification] = useState<Page<OrganismIdentification>>({ totalCount: 0, items: [] })
        , [allIdentifications, setAllIdentifications] = useState<AllIdentifications>({ organismType: [], catalase: [], oxidase: [], oxidationFermentation: [], coagulase: [] })
        , showSpinner = useAction(spinnerAction.showSpinner)
        , hideSpinner = useAction(spinnerAction.hideSpinner)
        , debounce = useDebounce()
        , load = useCallback(
            () => {
                debounce(() => {
                    const query = { ...pagination, ...filter }

                    Promise.resolve()
                        .then(showSpinner)
                        .then(() => loadOrganismIdentification(query))
                        .then(setOrganismIdentification)
                        .then(() => loadAllIdentifications(true))
                        .then(setAllIdentifications)
                        .finally(hideSpinner)
                })
            },
            [pagination, filter, loadOrganismIdentification, loadAllIdentifications, showSpinner, hideSpinner, debounce]
        )
    useEffect(load, [load])
    return [organismIdentifications, allIdentifications, load] as const
}

function useDelete(reset: () => void) {
    const removeOrganismIdentification = useAction(actions.removeOrganismIdentification)
        , showDeletionConfirmation = useAction(deletionActions.showDeletionConfirmationModal)
        , showSpinner = useAction(spinnerAction.showSpinner)
        , hideSpinner = useAction(spinnerAction.hideSpinner)

    function handleRemove(organism: OrganismIdentification) {
        showDeletionConfirmation(`Are you sure you want to delete ${organism.isGenus ? `genus ${organism.name}? All species will also be deleted` : `${organism.name}?`}`)
            .then(showSpinner)
            .then(() => removeOrganismIdentification(organism.id))
            .then(reset)
            .finally(hideSpinner)
    }

    return handleRemove
}

function useCreate(reset: () => void) {
    const createOrganismIdentification = useAction(actions.createOrganismIdentification)

    function handleCreate(organism: OrganismIdentification, form: FormApi) {
        createOrganismIdentification(organism)
            .then(() => form.reset({}))
            .then(reset)
    }

    return handleCreate
}

function useSave(reset: () => void) {
    const saveOrganismIdentification = useAction(actions.saveOrganismIdentification)
        , showConfirmation = useAction(deletionActions.showConfirmationModal)
        , showSpinner = useAction(spinnerAction.showSpinner)
        , hideSpinner = useAction(spinnerAction.hideSpinner)

    function handleSave(organism: OrganismIdentification) {
        getConfirmation(organism)
            .then(showSpinner)
            .then(() => saveOrganismIdentification({id: organism.id, isActive: !organism.isActive}))
            .then(reset)
            .finally(hideSpinner)
    }

    function getConfirmation(organism: OrganismIdentification) {
        if (organism.isGenus && organism.isActive)
            return showConfirmation(`Are you sure you want to mark genus ${organism.name} as Inactive? All species will also be marked as Inactive`)

        if (!organism.isGenus && !organism.isActive)
            return showConfirmation(`Are you sure you want to mark species ${organism.name} as Active? Genus ${organism.genusName} will also be marked as Active`)

        return Promise.resolve()
    }

    return handleSave
}
