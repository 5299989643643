import { React, classnames } from '_/facade/react'
import { useGeneratedBy } from '_/features/samples/sample-edit/shared/shared-sample-hooks'
import { Table, SortableHead, SortableTh, PaginatedFooter, EmptyTableMessage } from '_/components/table'
import { LinkButton } from '_/components/link'
import Checkbox from '_/components/checkbox-inline'

import { formatSampleStatus } from '../helpers'
import { SAMPLES_EDIT } from '_/constants/routes'
import * as fieldIndex from '_/constants/custom-field-index'

import LimitInfo from '../limit-info'
import TotalCFU from './total-cfu'
import * as h from '../helpers'
import * as c from '_/model/sample/list/columns'
import * as f from '_/model/sample/format'

import type { PredefinedLists } from '_/model/app-state'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import { useTimeService } from '_/components/time'
import type SortState from '_/model/sort-state'
import type Sample from '_/model/sample/sample'
import type { SampleSearchStatistics } from '_/model/sample/sample-search-result'
import type PaginationState from '_/model/pagination-state'
import RoundedImages from '_/components/sample-rounded-images'
import type SampleListColumn from '_/model/sample/list/types'
import ColumnName from './column-name'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    list: Sample[]
    listStatistics: SampleSearchStatistics
    predefinedLists: PredefinedLists
    printMode: boolean
    showSpinner: boolean

    handleSortingChange: (sorting: SortState) => void

    pagination: PaginationState
    handlePaginationChange: (pagination: PaginationState) => void

    selectedItems: Sample[]
    handleSelectSample: (sample: Sample) => void
    handleSelectAll: () => void

    bulkOperationsDisabled: boolean
    bulkOperationsHasNoPermissions: boolean
    columns: SampleListColumn[]
}

function SampleTable(props: Props) {
    const genBy = useGeneratedBy()
        , includedColumns = props.columns
            .filter(_ => _.included)
            .filter(_ => !props.printMode || _.type !== c.PHOTOS)

    return (
        <>
            {props.showSpinner &&
                <div className='position-relative py-5'>
                    <i className='preview-spinner material-icons md-48' data-testid='samples-preview-spinner'>sync</i>
                </div>
            }

            {/* table headers use context from SortableHead  to track sorting state, so table should not be unmounted during reload */}
            <div className={classnames('overflow-auto flex-fill', { 'd-none': props.showSpinner })}>
                <Table className={classnames('sample-list__table', { 'sample-list__calc-width': !props.printMode })} style={{ '--columns': includedColumns.length + 2 } as any}>
                    <SortableHead
                        onChange={props.handleSortingChange}
                        sticky={!props.printMode}
                        miniAuditTrail={genBy}
                    >
                        {includedColumns.map(
                            (column, idx) => <HeaderCell key={idx} fields={props.predefinedLists.customFields} column={column} />
                        )}
                        {!props.printMode &&
                            <React.Fragment>
                                <th />
                                <th>
                                    <Checkbox
                                        name='all'
                                        id='all'
                                        checked={props.list.length !== 0 && props.list.length === props.selectedItems.length}
                                        onChange={props.handleSelectAll}
                                        disabled={props.bulkOperationsDisabled}
                                        hasNoPermissions={props.bulkOperationsHasNoPermissions}
                                        testId='field-bulk-operation-header-checkbox'
                                    />
                                </th>
                            </React.Fragment>
                        }
                    </SortableHead>
                    <tbody>
                        {/* svg icon is broken when rendered on invisible table (display: none), so rows are rendered only when table is visible */}
                        {props.list.length === 0
                            ? <EmptyTableMessage colSpan={includedColumns.length + 2} message='No results found' />
                            : !props.showSpinner && props.list.map((_, index) =>
                                <tr key={_.id}>
                                    {includedColumns.map(
                                        (column, idx) =>
                                            <DataCell
                                                key={idx}
                                                column={column}
                                                predefinedLists={props.predefinedLists}
                                                sample={_}
                                                printMode={props.printMode}
                                            />
                                    )}
                                    {!props.printMode &&
                                        <React.Fragment>
                                            <td className='text-end'>
                                                <LinkButton
                                                    className='btn-link align-baseline p-0'
                                                    routeName={SAMPLES_EDIT}
                                                    routeParams={{ id: _.id }}
                                                    testId={`sample-${index}-view`}
                                                >
                                                    View
                                                </LinkButton>
                                            </td>
                                            <td>
                                                <Checkbox
                                                    name={_.id}
                                                    id={_.id}
                                                    checked={props.selectedItems.some(sample => sample.id === _.id)}
                                                    onChange={() => props.handleSelectSample(_)}
                                                    disabled={props.bulkOperationsDisabled}
                                                    hasNoPermissions={props.bulkOperationsHasNoPermissions}
                                                    testId={`field-bulk-operation-checkbox-${index}`}
                                                />
                                            </td>
                                        </React.Fragment>
                                    }
                                </tr>
                            )
                        }
                    </tbody>
                    {!props.printMode &&
                        <PaginatedFooter
                            colSpan={props.columns.length + 2}
                            state={props.pagination}
                            onChange={props.handlePaginationChange}
                            totalCount={props.listStatistics.totalCount}
                        />
                    }
                </Table>
            </div>
        </>
    )
}

export default SampleTable

interface HeaderCellProps {
    column: SampleListColumn
    fields: CustomField[]
}

function HeaderCell(props: HeaderCellProps) {
    function sortableHeader(sortField: string) {
        return <SortableTh name={sortField} testId={`sorted-header-${props.column.fieldIndex}`}><ColumnName {...props} /></SortableTh>
    }

    switch (props.column.type) {
        case c.EXPOSURE_DATE:
            return sortableHeader('exposureStartTime')

        case c.EXPOSURE_LOCATION:
            return sortableHeader('exposureLocationNotRecorded, exposureLocationName')

        case c.SAMPLE_NAME:
            return sortableHeader('sampleTypeName')

        case c.SESSION:
            return sortableHeader('sessionName')

        case c.EXPOSURE_TIME:
            return sortableHeader('exposureStartTime, exposureEndTime')

        case c.STATUS:
            return sortableHeader('status')

        case c.TOTAL_CFU:
            return sortableHeader('bothHandsCfu')

        case c.BARCODE:
        case c.OPERATORS:
        case c.BATCH_NUMBER:
        case c.PHOTOS:
        case c.INVESTIGATION_REFERENCES:
        case c.USER_DEFINED:
            return <th><ColumnName {...props} /></th>

        default:
            return null
    }
}

interface DataCellProps {
    column: SampleListColumn
    predefinedLists: PredefinedLists
    sample: Sample
    printMode: boolean
}

function DataCell(props: DataCellProps) {
    const timeService = useTimeService()
        , sample = props.sample

    switch (props.column.type) {
        case c.EXPOSURE_DATE:
            return (
                <td className='user-formatted-text'>
                    {timeService.formatCtzDate(h.getFieldValue(sample.fields, fieldIndex.EXPOSURE_START_DATE))}
                </td>
            )

        case c.EXPOSURE_TIME:
            return (
                <td className='user-formatted-text'>
                    <FormattedText text={f.formatExposureDurationByFields(sample.fields, props.predefinedLists.customFields, timeService)} />
                </td>
            )

        case c.STATUS:
            return (
                <td className='user-formatted-text'>{formatSampleStatus(sample)}</td>
            )

        case c.TOTAL_CFU:
            return (
                <td>
                    <TotalCFU sample={sample} />
                    {props.printMode && <LimitInfo sample={sample}/>}
                </td>
            )

        case c.PHOTOS:
            return (
                <td>
                    <RoundedImages images={sample.images} />
                </td>
            )

        case c.INVESTIGATION_REFERENCES:
            return (
                <td>
                    {sample.sampleInvestigationReferences}
                </td>
            )

        case c.EXPOSURE_LOCATION:
            return (
                <td className='user-formatted-text'>
                    <FormattedText text={
                        f.formatExposureLocation(sample.monitoringState, sample.fields, props.predefinedLists)
                    } />
                </td>
            )

        case c.OPERATORS:
            return (
                <td className='user-formatted-text'>
                    <FormattedText text={f.formatOperatorsShort(sample.fields, props.predefinedLists)} />
                </td>
            )

        case c.BATCH_NUMBER:
            return (
                <td className='user-formatted-text'>
                    <FormattedText text={f.formatBatchNumbersShort(sample.fields)} />
                </td>
            )

        case c.BARCODE:
        case c.SAMPLE_NAME:
        case c.SESSION:
        case c.USER_DEFINED:
            return (
                <td className='user-formatted-text'>
                    <FormattedText text={f.formatByFieldIndex(props.column.fieldIndex, sample.fields, props.predefinedLists)} />
                </td>
            )

        default:
            return null
    }
}
