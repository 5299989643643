import { classnames } from '_/facade/react'

interface Props {
    title: React.ReactNode
    children?: React.ReactNode
    className?: string
    hideTopOffset?: boolean
    testId?: string
    sticky?: boolean
}

const PageHeader = (props: Props) =>
    <div className={classnames(`navbar page-header ${props.className ?? ''}`, {'py-3': !props.hideTopOffset, 'page-header--sticky': props.sticky})}>
        <h4 data-testid={`${props.testId ? `${props.testId}-` : ''}page-title`}>{props.title}</h4>
        {props.children}
    </div>

export default PageHeader
