import { Field } from 'react-final-form'

import { classnames, useAction, useMemo } from '_/facade/react'
import { showFieldError } from '_/components/form/helpers'
import Barcode from '_/components/barcode'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import EditedLabel from '../edited-label'

import * as routes from '_/constants/routes'
import * as fieldIndex from '_/constants/custom-field-index'

import FieldForm from '../field-form'
import Error from '_/components/form/field-error'
import InlineActions from '../inline-actions'
import * as routerActions from '_/features/routing/actions'
import type { SampleEditedInfo } from '_/model/sample/sample'

import * as actions from '../../actions'
import * as nonViableSampleActions from '_/features/non-viable-samples/actions'
import { findFieldValuePos, valuePath, getFieldValue, getDuplicatedBarcodeConfirmationInfo } from '../../helpers'
import * as nonViableHelpers from '_/features/non-viable-samples/helpers'
import { validateBarcode } from '../../validate'
import { showGenericConfirmationModal } from '_/features/confirmation/actions'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'

interface OwnProps {
    entity: SampleDetailsEdit | NonViableSampleEdit
    field: CustomField
    onSubmit: (sample: SampleDetailsEdit | NonViableSampleEdit) => Promise<void>
    hasNoPermissions: boolean
    editedInfo: SampleEditedInfo | undefined
    isViable?: boolean
}

function BarcodeForm(props: OwnProps) {
    const position = findFieldValuePos(props.entity.fields, fieldIndex.BARCODE)
        , showBarcodeDuplicateWarning = useAction(showGenericConfirmationModal)
        , duplicatedBarcodeConfirmationInfo = props.isViable
            ? getDuplicatedBarcodeConfirmationInfo()
            : nonViableHelpers.getDuplicatedBarcodeConfirmationInfo()
        , loadSampleByBarcode = useAction(actions.loadSampleByBarcode)
        , loadNonViableSampleByBarcode = useAction(nonViableSampleActions.loadNonViableSampleByBarcode)
        , navigateTo = useAction(routerActions.navigateTo)

    function handleSubmit(subEntity: SampleDetailsEdit) {
        const sampleEdit = { ...props.entity, fields: subEntity.fields }
            , barcode = getFieldValue(subEntity.fields, fieldIndex.BARCODE)

        const duplicateCheckPromise = (props.isViable ? loadSampleByBarcode({ barcode, includeNullified: true, matchExactly: true }) : loadNonViableSampleByBarcode(barcode))
            .then(sample => {
                if (!sample || getFieldValue(props.entity.fields, fieldIndex.BARCODE) === (barcode && barcode.trim()))
                    return Promise.resolve<void>(undefined)

                showBarcodeDuplicateWarning(duplicatedBarcodeConfirmationInfo)
                    .then(() => navigateTo(props.isViable ? routes.SAMPLES_EDIT : routes.NON_VIABLE_SAMPLES_EDIT, { id: sample.id }))

                return Promise.reject<void>()
            })

        return duplicateCheckPromise.then(_ => props.onSubmit(sampleEdit))
    }

    const barcodeField = props.entity.fields[position]
        , initialValues = useMemo(
            () => ({
                fields: props.entity.fields,
            }),
            [props.entity.fields]
        )

    return (
        <FieldForm
            formId='barcode'
            label={props.field.fieldName}
            testId={`sample-field-${props.field.index}`}
            initialValues={initialValues}
            validate={_ => validateBarcode(_, props.field.fieldName)}
            onSubmit={handleSubmit}
            hasNoPermissions={props.hasNoPermissions}
            renderView={() =>
                <Barcode className='form-control-plaintext'>
                    <span className='fw-bold'>
                        {barcodeField.value}
                        <EditedLabel editedInfo={props.editedInfo}/>
                    </span>
                </Barcode>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div className='d-flex align-items-start'>
                    <div className='flex-fill'>
                        <Field name={valuePath(position)} render={_ =>
                            <>
                                <input
                                    id={`field-${props.field.index}`}
                                    type='text'
                                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                                    autoComplete='off'
                                    {..._.input}
                                    autoFocus
                                    data-testid={`field-${props.field.index}`}
                                />
                                <Error field={_} />
                            </>
                        } />
                    </div>
                    <InlineActions form={form} onSave={handleSave} onCancel={handleCancel} testId={`sample-field-${props.field.index}`} />
                </div>
            }
        />
    )
}

export default BarcodeForm
