import { diffObject, dropFields } from '_/utils/object'
import type Api from '_/services/predefined-lists-service'
import type { TrendSettings, TrendSettingsEdit } from './types'

function updateTransaction(
    id: string,
    current: TrendSettings,
    next: TrendSettingsEdit,
    api: Api,
): Promise<void> {
    const oldTrendSettings: TrendSettingsEdit = dropFields(current, 'id', 'inUse')
        , diff = diffObject(oldTrendSettings, next)

    if (!diff)
        return Promise.resolve()

    return api.trend.save(id, diff)
}

export {
    updateTransaction,
}
