import { useField } from 'react-final-form'

interface Props<T> {
    id: string
    name: string
    entities: T[]
    calcValue: (_: T) => any
    calcLabel: (_: T) => React.ReactNode
    testId?: string
}

function RadioGroupField<T>(props: Props<T>) {
    const field = useField(props.name)

    function getValue(index: number) {
        const entity = props.entities[index]
            , value = props.calcValue(entity)

        return value
    }

    function getRadioId(index: number) {
        return `${props.id}-${index}`
    }

    function handleChange(index: number): void {
        const value = getValue(index)

        field.input.onChange(value)
    }

    function handleFocus() {
        field.input.onFocus()
    }

    function handleBlur() {
        field.input.onBlur()
    }

    return (
        <div id={props.id} className='form-check'>
            {props.entities.map(
                (e, i) =>
                    <div key={i}>
                        <input
                            className='form-check-input'
                            id={getRadioId(i)}
                            type='radio'
                            name={field.input.name}
                            value={i}
                            checked={field.input.value === getValue(i)}
                            onChange={_ => handleChange(i)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            data-testid={`${props.testId}-${i}`}
                        />
                        <label
                            htmlFor={getRadioId(i)}
                            className='form-check-label'
                            data-testid={`field-label-${i}`}
                        >
                            {props.calcLabel(e)}
                        </label>
                    </div>
            )}
        </div>
    )
}

export default RadioGroupField
