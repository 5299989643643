import { useEffect, useAction, useState, useSelector } from '_/facade/react'
import type { AzureAdConfig, MsSsoResponse } from '_/model/auth/types'
import * as a from './actions'
import type { SsoRequestSource} from '_/model/auth/sso-request-source'
import { LOG_IN, SIGN_UP } from '_/model/auth/sso-request-source'
import * as routes from '_/constants/routes'

function useSsoUrl(source: SsoRequestSource) {
    const getConfig = useAction(a.getAzureAdConfig)
        , [config, setConfig] = useState<AzureAdConfig>()
        , callBackUrl = buildCallbackUrl(source)
        , invitationId = useSelector(_ => _.router.route)?.params.token

    function buildCallbackUrl (source: SsoRequestSource) {
        let result = config?.callbackPath + '/'
        switch(source) {
            case LOG_IN:
                return result + routes.SSO_LOG_IN
            case SIGN_UP:
                return result + routes.SSO_SIGN_UP
            default:
                return result + routes.SSO_LOG_IN
        }
    }


    useEffect(
        () => {
            getConfig().then(setConfig)
        },
        [getConfig]
    )

    return config
            ? `${config.instance}?client_id=${config.clientId}&response_type=id_token&redirect_uri=${callBackUrl}&scope=openid%20email%20profile&nonce=${config.nonce}&response_mode=fragment&state=${source === SIGN_UP ? `${config.nonce}%25${invitationId}` : config.nonce}`
            : ''
}


function useCredentialExtractor(): MsSsoResponse {
    const url = window.location
        , hash = url.hash.slice(1).split('&').map(p => {
            const nameValue = p.split('=')
            return { name: nameValue[0], value: nameValue[1] }
        })

    if (hash[0].name === 'error') {
        return {
            type: 'error',
            errorSubcode: hash[1].value,
            state: hash[2].value,
        }
    }

    const stateValue = hash[1].value.split('%25')

    return {
        type: 'success',
        idToken: hash[0].value,
        state: stateValue[0],
        invitationId: stateValue[1],
    }
}

export {
    useSsoUrl,
    useCredentialExtractor,
}
