import { useState, useAction, useEffect } from '_/facade/react'

import { ITEMS_PER_PAGE } from '_/constants'

import AuditTrails from '_/components/audit-trail-list'
import PageHeader from '_/components/page-header'

import type PaginationState from '_/model/pagination-state'
import type Page from '_/model/page'

import * as actions from './actions'
import * as spinnerAction from '_/features/spinner/actions'
import type { Events } from '_/model/audit-trail/types'

function OrganismIdentificationsTrail() {
    const [pagination, setPagination] = useState<PaginationState>({ start: 0, count: ITEMS_PER_PAGE })
        , trails = useLoadOrganismTrails(pagination)

    return (
        <>
            <PageHeader title='Audit trail'/>
            <AuditTrails
                paginatedEvents={trails}
                pagination={pagination}
                onPaginationChange={setPagination}
                hideHeader
                ownScroll
                stickyHeader
            />
        </>
    )
}

export default OrganismIdentificationsTrail

function useLoadOrganismTrails(pagination: PaginationState) {
    const loadOrganismIdentificationTrail = useAction(actions.loadOrganismIdentificationTrail)
        , [trails, setTrails] = useState<Page<Events>>({ totalCount: 0, items: [] })
        , showSpinner = useAction(spinnerAction.showSpinner)
        , hideSpinner = useAction(spinnerAction.hideSpinner)

    useEffect(
        () => {
            Promise.resolve()
                .then(showSpinner)
                .then(() => loadOrganismIdentificationTrail(pagination))
                .then(setTrails)
                .finally(hideSpinner)
        },
        [pagination, loadOrganismIdentificationTrail, showSpinner, hideSpinner]
    )
    return trails
}
