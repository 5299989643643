export function loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>(
        (resolve, reject) => {
            const img = document.createElement('img')
            img.onload = () => resolve(img)
            img.onerror = () => reject('image not loaded')
            img.crossOrigin = 'anonymous'
            img.src = src
        }
    )
}

export function loadImageFromBlob(blob: Blob): Promise<HTMLImageElement> {
    const url = URL.createObjectURL(blob)

    return loadImage(url).finally(() => URL.revokeObjectURL(url))
}

export function exportCanvasToBlob(canvas: HTMLCanvasElement, type = 'image/jpeg') {
    const str = canvas.toDataURL(type)
        , data = atob(str.split(',')[1])
        , array = new Uint8Array(data.length).map((_, i) => data.charCodeAt(i))
        , image = new Blob([array.buffer], {type: type})

    return image
}

export function downscaleImage(blob: Blob, size: number): Promise<Blob> {
    return loadImageFromBlob(blob).then(img => {
        let width = img.naturalWidth,
            height = img.naturalHeight

        const maxSideSize = Math.max(width, height)
        if (maxSideSize > size) {
            const minSideSize = Math.min(width, height)
                , downScaledMinSideSize = Math.floor(minSideSize * size / maxSideSize)
                , downScaledDimensions = [downScaledMinSideSize, size]

            if (width > height)
            downScaledDimensions.reverse()

            ;
            [width, height] = downScaledDimensions
        }

        const canvas = document.createElement('canvas')
            , ctx = canvas.getContext('2d')!
        canvas.width = width
        canvas.height = height
        ctx.drawImage(img, 0, 0, width, height)

        return exportCanvasToBlob(canvas)
    })
}

export function convertImageToJPEG(blob: Blob) {
    if (blob.type === 'image/jpeg')
        return Promise.resolve(blob)

    return loadImageFromBlob(blob)
        .then(img => {
            const canvas = document.createElement('canvas')
                , ctx = canvas.getContext('2d')!
            canvas.width = img.width
            canvas.height = img.height
            ctx.drawImage(img, 0, 0)

            return exportCanvasToBlob(canvas)
        })
}
