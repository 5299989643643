import { Form } from 'react-final-form'

import { useAction } from '_/facade/react'

import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import { Submit, submitDisabled, TextField } from '_/components/form'
import Button, { Close } from '_/components/button'

import type { GradeEdit } from '_/model/predefined-lists/grade/grade'
import type Grade from '_/model/predefined-lists/grade/grade'

import validate from '_/model/predefined-lists/grade/grade-validate'

import * as actions from './actions'
import * as toastActions from '_/features/toasts/actions'

interface Props {
    grades: Grade[]
    onClose(): void
}

function AddGradeModal(props: Props) {
    const handleAddNewGrade = useSubmit(props.onClose)

    return (
        <Modal isOpen onClose={props.onClose}>
            <Form
                onSubmit={handleAddNewGrade}
                validate={_ => validate(_, props.grades)}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <ModalHeader className='border-bottom-0'>
                            <h4>New grade</h4>
                            <Close onClick={props.onClose}/>
                        </ModalHeader>
                        <ModalBody noDefaultHeight>
                            <TextField name='name' testId='field-name'>Name</TextField>
                        </ModalBody>
                        <ModalFooter className='border-top-0'>
                            <Button className='btn-secondary' onClick={props.onClose}>Cancel</Button>
                            <Submit disabled={submitDisabled(form)} testId='add-grade'>Add grade</Submit>
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default AddGradeModal

function useSubmit(onClose: () => void) {
    const createGrade = useAction(actions.createGrade)
        , addSuccess = useAction(toastActions.addSuccess)

    function handleAddNewGrade(grade: GradeEdit) {
        createGrade(grade)
            .then(() => addSuccess('Grade added successfully'))
            .then(onClose)
    }

    return handleAddNewGrade
}
