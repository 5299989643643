import { useSelector, useAction, useState, useEffect } from '_/facade/react'

import { confirmEmail, loadEmailConfirmation } from '../actions'
import { Form } from 'react-final-form'
import { PasswordField, Submit, submitDisabled } from '_/components/form'
import type { ConfirmEmailRequest, EmailConfirmation } from '_/model/auth/types'
import EmailConfirmationMessage from './confirm-email-message'

function AuthConfirmEmail() {
    const handleSubmit = useSubmitHandler()
        , confirmation = useEmailConfirmation()
        , showConfirmationForm = !(confirmation?.confirmed || confirmation?.expired || confirmation?.inactive)

    return (
        <div className='d-flex justify-content-center'>
            <div className='auth-confirm-email-form'>
                <h4>Email confirmation page</h4>
                {showConfirmationForm
                    ? <Form
                        onSubmit={handleSubmit as any}
                        render={form =>
                            <form onSubmit={form.handleSubmit}>
                                <PasswordField testId='field-password' id='password' name='password' placeholder='Password' />
                                <Submit className='auth-form-submit w-100' disabled={submitDisabled(form)} testId='complete-email-confirmation'>Press to complete email confirmation</Submit>
                            </form>
                        }
                    />
                    : <EmailConfirmationMessage
                        emailConfirmation={confirmation}
                    />
                }
            </div>
        </div>
    )
}

function useSubmitHandler() {
    const confirm = useAction(confirmEmail)
        , token = useSelector(_ => _.router.route?.params.token ?? '')

    function handleSubmit(formValue: ConfirmEmailRequest) {
        const confirmRequest = {
            ...formValue,
            token,
        }

        confirm(confirmRequest)
    }

    return handleSubmit
}

function useEmailConfirmation() {
    const loadConfirmation = useAction(loadEmailConfirmation)
        , token = useSelector(_ => _.router.route?.params.token ?? '')
        , [emailConfirmation, setEmailConfirmation] = useState<EmailConfirmation>()

    useEffect(
        () => {
            loadConfirmation(token)
                .then(setEmailConfirmation)
        },
        [loadConfirmation, token]
    )

    return emailConfirmation
}

export default AuthConfirmEmail
