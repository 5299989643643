import type { LocationsReportView } from '_/model/reports/locations-report/types'
import type { ReportRequest } from '_/model/reports/types'

import type ApiService from '../api-service'
import type LocationsReportService from '../locations-report-service'

function factory(api: ApiService): LocationsReportService {
    return { getLocationsReportData }

    function getLocationsReportData(id: string, request: ReportRequest): Promise<LocationsReportView> {
        return api.get<LocationsReportView>(['exposure-locations', id, 'report'], request)
    }
}

export default factory
