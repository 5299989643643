import { NEVER } from '_/constants/grade-cfu-verification'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import type Grade from '_/model/predefined-lists/grade/grade'
import type * as tree from '_/utils/tree'

function allChildrenIds(state: tree.Tree<ExposureLocation>, locationId: string): string[] {
    const childrenIds = state.childrenHash[locationId]

    return childrenIds.reduce(
        (acc, id) => {
            const children = [id].concat(allChildrenIds(state, id))

            return acc.concat(children)
        },
        [] as string[]
    )
}

function cfuCountVerificationEnabled(grades: Grade[]) {
    return grades.some(it => it.cfuVerification !== NEVER)
}

export {
    allChildrenIds,
    cfuCountVerificationEnabled,
}
