import { useAction, useSelector } from '_/facade/react'

import Button from '_/components/button'
import Link, { LinkButton } from '_/components/link'

import * as routes from '_/constants/routes'
import * as fieldIndex from '_/constants/custom-field-index'
import { ALL_STATUS_IDS as statuses } from '_/constants/sample-status'
import { ITEMS_PER_PAGE } from '_/constants'

import { paramsFilter } from '_/model/filters/helpers'
import type { SampleSearchByBarcodeResult } from '_/model/sample/sample-search-result'
import * as sa from '../samples/actions'
import * as ra from '_/features/routing/actions'
import SampleSearchCommon from './sample-search-common'

function SampleSearch() {
    const loadSamples = useAction(sa.loadSamplesByBarcode)
        , navigateTo = useAction(ra.navigateTo)
        , permissions = useSelector(_ => _.auth.permissions)

    return (
        <SampleSearchCommon
            readPermission={permissions.readSamples}
            loadByBarcode={(barcode: string) => loadSamples({ barcode, includeNullified: true, matchExaclty: false })}
            hasExactMatch={_ => !!_.exactMatch}
            getTotalMatches={_ => _.possibleMatchesCount}
            navigateToSampleDetails={(_, searchResult) => navigateTo(routes.SAMPLES_EDIT, { id: searchResult.exactMatch?.id })}
            navigateToSampleList={_ =>
                navigateTo(
                    routes.SAMPLES,
                    paramsFilter({ fields: [{ index: fieldIndex.BARCODE, value: _ }], start: 0, count: ITEMS_PER_PAGE, sort: 'createdAt:desc', statuses })
                )
            }
            renderMenu={props => <Menu {...props} />}
        />
    )
}

export default SampleSearch

interface MenuProps {
    barcode: string
    searchResult: SampleSearchByBarcodeResult
    onClose: () => void
}

function Menu(props: MenuProps) {
    const permissions = useSelector(_ => _.auth.permissions)

    return props.searchResult.possibleMatchesCount > 0
        ? <div>
            This search matches
            <> </>
            <Link
                routeName={routes.SAMPLES_EDIT}
                routeParams={{ id: props.searchResult.exactMatch!.id }}
            >
                1 exact viable sample
            </Link>
            <> and </>
            <Link
                routeName={routes.SAMPLES}
                routeParams={paramsFilter({ fields: [{ index: fieldIndex.BARCODE, value: props.barcode }], statuses })}
            >
                {props.searchResult.possibleMatchesCount} possible viable {props.searchResult.possibleMatchesCount === 1 ? 'sample' : 'samples'}.
            </Link>
            <> </>
            Click one of the options to proceed
        </div>
        : <div>
            <p>We didn't find anything that matches your search. {permissions.bookSamples && 'Would you like to book in a new viable sample?'}</p>
            <LinkButton
                className='btn-primary'
                routeName={routes.SAMPLES_BOOKING}
                onClick={props.onClose}
                hasNoPermissions={!permissions.bookSamples}
            >
                Book in viable samples
            </LinkButton>
            <> </>
            <Button className='btn-secondary' onClick={props.onClose}>
                Cancel
            </Button>
        </div>
}
