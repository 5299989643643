import { useState } from '_/facade/react'

import HoverMenu from '_/components/overlay/hover-menu'

import type { DayBreaches } from '_/model/reports/types'
import { calculateColor } from '_/model/reports/locations-report/helpers'
import WEEK from '_/constants/weekday'

import Cell from './cell'

interface Props {
    limitBreaches: DayBreaches | undefined
    maxBreach: number
    testId?: string
}

function LimitBreachData(props: Props) {
    const [element, setElement] = useState<SVGTextElement | null>(null)

    if (!props.limitBreaches)
        return <Cell backgroundColor='#f8f9fa' testId='limit-breach-data-by-session-empty-cell' />

    const { actionLimitCount, alertLimitCount, averageLimitCount, daysCount } = props.limitBreaches
        , dayName = WEEK.find(_ => _.id === props.limitBreaches?.dayOfWeek)?.name
        , backgroundColor = calculateColor(props.maxBreach, averageLimitCount)

    return (
        <>
            <Cell backgroundColor={backgroundColor} count={averageLimitCount} setRef={setElement} testId='limit-breach-data-by-session' />

            <HoverMenu element={element} position='right'>
                <div className='legend-bubble-triangle triangle-left'>
                    <div className='legend-bubble text-white py-2 px-4 ms-1'>
                        <span data-testid='limit-breach-data-by-session-tool-tip'>
                            {actionLimitCount} action limit breaches and <br/>
                            {alertLimitCount} alert limit breaches across {daysCount} {dayName}{daysCount > 1 ? 's' : ''}
                        </span>
                    </div>
                </div>
            </HoverMenu>
        </>
    )
}

export default LimitBreachData


