import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import type { FormApi } from 'final-form'

import { classnames, useAction, useSelector, useCallback, useState, useEffect, React } from '_/facade/react'

import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import { SelectField, Submit, submitDisabled, TextField, CheckboxField, resetForm } from '_/components/form'
import Button, { Close } from '_/components/button'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import AuditTrails from '_/components/audit-trail-list'
import TabNavbar from '_/components/tab-navbar'

import type { CustomFieldEdit } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import * as tabs from '_/constants/modal-tab'
import * as routes from '_/constants/routes'
import type { FieldIndex } from '_/constants/custom-field-index'
import STANDARD_FIELD_INDEXES from '_/constants/custom-field-index'
import FIELD_TYPE, { NUMBER, TEXT, SELECTION } from '_/constants/custom-field-column-type'
import * as helpers  from './helpers'

import * as actions from './actions'
import * as routerActions from '_/features/routing/actions'
import * as confirmationActions from '_/features/confirmation/actions'

import validate from '_/model/predefined-lists/custom-field/validate'
import SelectionItemsForm from './selection-field-items-form'
import { ALLOWED_NUMBER_VALUES, POSITIVE_AND_NEGATIVE_NUMBERS } from '_/model/predefined-lists/custom-field/allowed-number-values'
import Legend from '_/components/legend'
import { memoize } from '_/utils/function'
import type { AuditTrail } from '_/model/audit-trail/types'

type ViabilityKey = Extract<keyof CustomField, 'viableSettings' | 'nonViableSettings'>

interface Props {
    customFields: CustomField[]
    onReload: () => void
}
function CustomFieldModal(props: Props) {
    const customFieldId = useSelector<string | undefined>(_ => _.router.route!.params.id)
        , [customField, customFieldTrail] = useCustomField(customFieldId)
        , [activeTab, setActiveTab] = useState(tabs.DATA)
        , navigateToList = useNavigateToList()
        , handleSubmit = useSubmit(customField, props.onReload)
        , handleRemove = useRemoveCustomField(customField, props.onReload)
        , fieldTypes = FIELD_TYPE.filter(_ => _.id === NUMBER || _.id === TEXT || _.id === SELECTION)
        , isStandardField = (index: FieldIndex | undefined) => STANDARD_FIELD_INDEXES.some(_ => _ === index)
        , isInactive = (form: FormRenderProps<CustomFieldEdit>) => customField
            && !form.values.viableSettings.isActive
            && !form.values.nonViableSettings.isActive

    let previousValues = customField

    function activeViabilitySettings(form: FormRenderProps<CustomFieldEdit>) {
        const result: ViabilityKey[] = []

        if (form.values.viableSettings.isActive)
            result.push('viableSettings')

        if (form.values.nonViableSettings.isActive)
            result.push('nonViableSettings')

        return result
    }

    function isDisabledShowWhenBookingIn(form: FormRenderProps<CustomFieldEdit>, key: ViabilityKey) {
        const isNotEditable = customField && !helpers.isEditableShowWhenBookingInField(customField.index)

        return isNotEditable || form.values[key].persisted || form.values[key].required
    }

    function handleFormChange(values: CustomField, form: FormRenderProps) {
        if (values.fieldType === NUMBER && previousValues?.fieldType !== NUMBER)
            form.form.change('allowedValues', POSITIVE_AND_NEGATIVE_NUMBERS)

        previousValues = values
    }

    return (
        <Modal isOpen onClose={navigateToList}>
            <Form<CustomFieldEdit, CustomFieldEdit>
                onSubmit={handleSubmit}
                validate={values => validate(values, props.customFields)}
                initialValues={getInitialValue(customField)}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form} onChange={handleFormChange}/>
                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4 data-testid='custom-field-modal-title'>{customFieldId ? 'Edit data field' : 'New data field'}</h4>
                                    <Close onClick={navigateToList} testId='close-custom-field-modal' />
                                </div>
                                <TabNavbar>
                                    <Button
                                        onClick={() => setActiveTab(tabs.DATA)}
                                        className={classnames('btn-link navbar-tab me-4', { active: activeTab === tabs.DATA })}
                                    >
                                        Field settings
                                    </Button>
                                    <Button
                                        onClick={() => setActiveTab(tabs.AUDIT_TRAIL)}
                                        className={classnames('btn-link navbar-tab', { active: activeTab === tabs.AUDIT_TRAIL })}
                                        disabled={!customFieldId}
                                        testId='custom-field-audit-trails'
                                    >
                                        Audit trail
                                    </Button>
                                </TabNavbar>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='predefined-list-modal-body-height'>
                                {activeTab === tabs.DATA &&
                                    <div>
                                        <TextField
                                            name='fieldName'
                                            disabled={isStandardField(customField?.index) || isInactive(form)}
                                            testId='field-name'
                                        >
                                            Name
                                        </TextField>
                                        <SelectField
                                            name='fieldType'
                                            entities={customFieldId ? FIELD_TYPE : fieldTypes}
                                            disabled={!!customFieldId || isInactive(form)}
                                            calcId={_ => _.id}
                                            calcName={_ => _.name}
                                            autocomplete
                                            testId='field-type'
                                        >
                                            Type
                                        </SelectField>
                                        {form.values.fieldType === SELECTION && !isStandardField(customField?.index) &&
                                            <SelectionItemsForm
                                                id={customFieldId}
                                                name='selectionFieldValues'
                                                disabled={isInactive(form)}
                                            />
                                        }
                                        {form.values.fieldType === NUMBER &&
                                            <>
                                                <TextField
                                                    name='numberMeasureUnit'
                                                    disabled={isInactive(form)}
                                                >
                                                    Unit of measure (optional)
                                                </TextField>
                                                <SelectField
                                                    name='allowedValues'
                                                    disabled={isInactive(form)}
                                                    entities={ALLOWED_NUMBER_VALUES}
                                                    calcId={_ => _.id}
                                                    calcName={_ => _.name}
                                                    autocomplete
                                                >
                                                    Allowed values
                                                </SelectField>
                                            </>
                                        }

                                        <Legend>Active for</Legend>
                                        <CheckboxField
                                            name='viableSettings.isActive'
                                            id='viableSettings.isActive'
                                            className='mb-3'
                                            disabled={isStandardField(customField?.index)}
                                        >
                                            Viable samples
                                        </CheckboxField>
                                        <CheckboxField
                                            name='nonViableSettings.isActive'
                                            id='nonViableSettings.isActive'
                                            className='mb-3'
                                            disabled={isStandardField(customField?.index)}
                                        >
                                            Non-viable samples
                                        </CheckboxField>

                                        {activeViabilitySettings(form).map((_, i) =>
                                            <div key={i}>
                                                <div className='border-bottom mt-3'/>
                                                <Legend>{_ === 'viableSettings' ? 'Settings for viable samples' : 'Settings for non-viable samples'}</Legend>
                                                <CheckboxField
                                                    name={`${_}.showOnBookingIn`}
                                                    id={`${_}.showOnBookingIn`}
                                                    className='mb-3'
                                                    disabled={isDisabledShowWhenBookingIn(form, _)}
                                                    testId='field-show-on-booking-in'
                                                >
                                                    Show when booking in
                                                </CheckboxField>
                                                <CheckboxField
                                                    name={`${_}.persisted`}
                                                    id={`${_}.persisted`}
                                                    disabled={!form.values[_].showOnBookingIn || customField && !helpers.isEditablePersistentField(customField.index)}
                                                    className='mb-3 ms-4'
                                                    testId='field-persisted'
                                                >
                                                    Persistent when booking in
                                                </CheckboxField>
                                                <CheckboxField
                                                    name={`${_}.required`}
                                                    id={`${_}.required`}
                                                    className='mb-3 ms-4'
                                                    disabled={!form.values[_].showOnBookingIn || customField && !helpers.isEditableRequiredField(customField.index, _ === 'viableSettings')}
                                                    testId='field-required'
                                                >
                                                    Required
                                                </CheckboxField>
                                                <CheckboxField
                                                    name={`${_}.notRecorded`}
                                                    id={`${_}.notRecorded`}
                                                    className='mb-3'
                                                    disabled={customField && !helpers.isEditableAllowNotRecordedField(customField.index)}
                                                    testId='field-not-recorded'
                                                >
                                                    Allow not recorded
                                                </CheckboxField>
                                            </div>
                                        )}

                                    </div>
                                }
                                {activeTab === tabs.AUDIT_TRAIL &&
                                    <AuditTrails trail={customFieldTrail} />
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter className='border-top-0 modal-footer-height'>
                            {activeTab !== tabs.AUDIT_TRAIL &&
                                <div
                                    className={`d-flex flex-fill ${customField && !isStandardField(customField.index) && activeTab === tabs.DATA
                                        ? 'justify-content-between'
                                        : 'justify-content-end'}`
                                    }
                                >
                                    {customField && !isStandardField(customField.index) &&
                                        <div>
                                            <Button
                                                className='text-danger bg-transparent border-0 me-2'
                                                onClick={() => handleRemove(form.form)}
                                                disabled={customField.inUse}
                                            >
                                                Remove
                                            </Button>
                                        </div>
                                    }
                                    <div>
                                        <Button className='text-muted bg-transparent border-0 me-2' onClick={navigateToList}>Cancel</Button>
                                        <Submit disabled={submitDisabled(form)} testId='custom-field-save'>{customFieldId ? 'Save changes' : 'Add data field'}</Submit>
                                    </div>
                                </div>
                            }
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default CustomFieldModal

const getInitialValue = memoize(
    (field: CustomField | undefined): CustomFieldEdit => {
        if (!field)
            return {
                viableSettings: {},
                nonViableSettings: {},
            }

        const result: CustomFieldEdit = {
            id: field.id,
            fieldName: field.fieldName,
            fieldType: field.fieldType,
            viableSettings: field.viableSettings,
            nonViableSettings: field.nonViableSettings,
        }

        if (field.selectionFieldValues !== undefined)
            result.selectionFieldValues = field.selectionFieldValues

        if (field.numberMeasureUnit !== undefined)
            result.numberMeasureUnit = field.numberMeasureUnit

        if (field.allowedValues !== undefined)
            result.allowedValues = field.allowedValues

        return result
    }
)

function useCustomField(id: string | undefined) {
    const load = useAction(actions.loadCustomField)
        , loadTrail = useAction(actions.loadCustomFieldTrail)
        , [customField, setCustomField] = useState<CustomField | undefined>()
        , [trail, setTrail] = useState<AuditTrail>()

    useEffect(
        () => {
            if (!id) {
                setCustomField(undefined)
                return
            }

            load(id).then(setCustomField)
            loadTrail(id).then(setTrail)
        },
        [id, load, loadTrail]
    )

    return [customField, trail] as const
}

function useSubmit(customField: CustomField | undefined, onReload: () => void) {
    const createCustomField = useAction(actions.createCustomField)
        , saveCustomField = useAction(actions.saveCustomField)
        , showConfirmationModal = useAction(confirmationActions.showConfirmationModal)
        , navigateToList = useNavigateToList()

    function submit(newCustomField: CustomFieldEdit, form: FormApi<CustomFieldEdit, CustomFieldEdit>) {
        const confirmationRequired = customField?.viableSettings.isActive && !newCustomField.viableSettings.isActive
                                    || customField?.nonViableSettings.isActive && !newCustomField.nonViableSettings.isActive
            , confirmation = confirmationRequired
                ? showConfirmationModal('All fields and recorded data will be hidden from view on SmartControl and the import/export files for data fields that are set not Active. Are you sure you want to proceed?')
                : Promise.resolve()
            , save = customField
                ? () => saveCustomField({ id: customField.id, oldCustomField: form.getState().initialValues, newCustomField: newCustomField })
                : () => createCustomField(newCustomField)

        confirmation
            .then(save)
            .then(() => resetForm(form))
            .then(() => {
                onReload()
                navigateToList()
            })
    }

    return submit
}

function useRemoveCustomField(customField: CustomField | undefined, onReload: () => void) {
    const removeCustomField = useAction(actions.removeCustomField)
        , confirmDeletion = useAction(confirmationActions.showDeletionConfirmationModal)
        , navigateToList = useNavigateToList()
        , remove = (form: FormApi<CustomFieldEdit, Partial<CustomFieldEdit>>) => {
            confirmDeletion(`Are you sure you want to delete ${customField!.fieldName}?`)
                .then(_ => removeCustomField(customField!.id))
                .then(() => resetForm(form))
                .then(() => {
                    onReload()
                    navigateToList()
                })
        }

    return remove
}

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SETTINGS_SAMPLE_FIELDS), [navigateTo])

    return navigateToList
}
