import type FloorPlan from '_/model/floor-plan/floor-plan'
import { useImageSource } from '../hooks'
import * as routes from '_/constants/routes'
import Link from '_/components/link'

interface Props {
    floorPlan: FloorPlan
    hasNoPermissions: boolean
    testId?: string
}

function FloorPlanItem(props: Props) {
    const src = useImageSource(props.floorPlan.imageId, 'medium')

    const locationsCount = props.floorPlan.locations.length
        , floorPlanId = props.floorPlan.id

    return (
        <div key={floorPlanId} className='col-4 p-2'>
            <div className='d-flex justify-content-center border p-2'>
                <img src={src} />
            </div>
            <div className='d-flex border p-2 justify-content-between'>
                <div data-testid='floor-plan-info'>
                    <div className='fw-bold' data-testid='floor-plan-name'>{props.floorPlan.name}</div>
                    <div data-testid='floor-plan-count'>{locationsCount} {locationsCount == 1 ? 'location' : 'locations'}</div>
                </div>
                <Link
                    routeName={routes.SETTINGS_FLOOR_PLANS_EDIT}
                    routeParams={{ id: floorPlanId }}
                    className='btn material-icons text-primary md-18'
                    hasNoPermissions={props.hasNoPermissions}
                    testId={`${props.testId}-edit`}
                >
                    create
                </Link>
            </div>
        </div>
    )
}

export default FloorPlanItem
