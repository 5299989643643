import { actionFactory } from '_/utils/redux'
import type { ReportReminderCreate, ReportReminderEdit } from '_/model/analysis/custom-report/report-reminder'
import type ReportReminder from '_/model/analysis/custom-report/report-reminder'

const action = actionFactory('app.report-reminders')

export const createReportReminder = action<ReportReminderCreate, Promise<void>>('createReportReminder')
           , loadReportReminder = action<string, Promise<ReportReminder>>('loadReportReminder')
           , saveReportReminder = action<{ id: string, reportId: string, oldReminder: ReportReminderEdit, newReminder: ReportReminderEdit }, Promise<void>>('saveReportReminder')
           , loadReportReminders = action<void, Promise<ReportReminder[]>>('loadReportReminders')
