import { useAction, useSelector } from '_/facade/react'
import Legend from '_/components/legend'
import Button from '_/components/button'
import { CheckboxField, NumberFieldInline, SelectField } from '_/components/form'
import * as it from '_/model/sample/identification-type'
import type { IdentificationRow, IdentificationRows, IdentificationType } from '_/model/sample/reading/sample-reading'
import type SampleReading from '_/model/sample/reading/sample-reading'
import { useForm } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import * as ta from '_/features/toasts/actions'
import * as deletionActions from '_/features/confirmation/actions'
import OrganismSearch from '_/features/predefined-lists/organism-identification/organism-search'
import type { AllIdentifications } from '_/model/predefined-lists/identifications/identifications'
import { typeArgument, typeHasArgument } from '_/model/predefined-lists/identifications/helpers'
import { useNextReference } from './identifications-reference'
import { encodeReference } from '_/model/sample/identification-reference'
import { NO_ID_REQUIRED, CORRECTIONAL_MPN_VALUE } from '_/model/sample/identification-type'

interface IdentificationsLegendProps {
    totalCfu: number
    isOptional: boolean
    isFingerdabTwoHandsPlate: boolean
    sampleCfuConfirmationEnabled: boolean
    onCfuConfirmed?: () => void
}

function IdentificationsLegend(props: IdentificationsLegendProps) {
    const LEGEND_TITLE = props.isFingerdabTwoHandsPlate
            ? props.isOptional ? 'Right hand reading' : 'Left hand reading'
            : 'Reading'
        , canConfirmCfu = useSelector(_ => _.auth.permissions.confirmCfuCount)

    return (
        <Legend className='d-flex'>
            {LEGEND_TITLE}
            <div className='ms-auto'>
                <span>
                    <span className='fw-normal me-2' data-testid='total-cfus'>
                        Total CFUs: {props.totalCfu}
                    </span>
                    {props.onCfuConfirmed &&
                        <Button
                            className='btn-primary d-print-none '
                            onClick={props.onCfuConfirmed}
                            disabled={!props.sampleCfuConfirmationEnabled}
                            hasNoPermissions={!canConfirmCfu}
                            testId='verify-count'
                        >
                            Verify count
                        </Button>
                    }
                </span>
            </div>
        </Legend>
    )
}

interface RowProps {
    currentValue: IdentificationRows
    fieldName: string
    fieldIndex: number
    onDelete: (reference: string) => void
    defaultInputRef: React.Ref<HTMLInputElement> | undefined
    organismTypeArguments: AllIdentifications
    isOrganismObjectionable: (identificationType: it.IdentificationType, identificationValue: string) => boolean
    isOptional: boolean
    noIdRequiredEnabled: boolean
    correctionalMpnValueEnabled: boolean
    testId: string
}

function Row(props: RowProps) {
    const typesField = useFieldArray<IdentificationType>(`${props.fieldName}.types`, { isEqual: areArrayFieldsEqual })
        , addError = useAction(ta.addError)
        , showDeletionConfirmation = useAction(deletionActions.showDeletionConfirmationModal)

    const currentValue = props.currentValue
        , identificationComplete = currentValue.complete
        , rowValue = currentValue.rows[props.fieldIndex]
        , hasNoGrowth = rowHasNoGrowth(rowValue)
        , reference = '#' + encodeReference(props.currentValue.rows[props.fieldIndex].reference)
        , historicalNoIdRequired = rowValue.types.filter(_ => _.type === NO_ID_REQUIRED).length > 0
        , noIdRequiredEnabled = props.noIdRequiredEnabled || historicalNoIdRequired
        , correctionalMpnValueSelected = rowValue.types.filter(_ => _.type === CORRECTIONAL_MPN_VALUE).length > 0
        , correctionalMpnValueEnabled = props.correctionalMpnValueEnabled || correctionalMpnValueSelected

    function alwaysObjectionable(): boolean {
        const anyOfIdentificationsObjectionable = rowValue.types
            .some(_ => _.type !== undefined && _.value !== undefined ? props.isOrganismObjectionable(_.type, _.value) : false)

        return anyOfIdentificationsObjectionable
    }

    function handleRemoveOrganismType(index: number): void {
        const type = typesField.fields.value[index].type
            , typeName = it.IDENTIFICATION_TYPE.find(_ => _.id === type)?.name ?? 'empty identification type'

        showDeletionConfirmation(`Are you sure you want to remove ${typeName} from row ${reference}?`)
            .then(() => typesField.fields.remove(index))
    }

    return (
        <tr>
            <td>{reference}</td>
            <td>
                <NumberFieldInline
                    id={`${props.fieldName}.cfuCount`}
                    name={`${props.fieldName}.cfuCount`}
                    disabled={identificationComplete}
                    autoFocus={props.fieldIndex === 0 && !props.isOptional}
                    inputRef={props.fieldIndex === 0 ? props.defaultInputRef : undefined}
                    testId={`${props.testId}-cfu`}
                />
            </td>
            <td>
                {typesField.fields.map((fieldName, fieldIndex) =>
                    <div key={fieldName} className='row g-2 mb-2 mx-0'>
                        <div className={`${typeHasArgument(typesField.fields.value[fieldIndex].type) ? 'col-5' : 'col-11'} d-flex align-self-start`}>
                            <SelectField
                                inline
                                className='flex-fill min-width-0'
                                id={`${fieldName}.type`}
                                name={`${fieldName}.type`}
                                disabled={identificationComplete || hasNoGrowth}
                                calcId={_ => _.id}
                                calcName={_ => _.name}
                                placeholder='Select'
                                entities={getIdentificationTypes(rowValue, fieldIndex, noIdRequiredEnabled, correctionalMpnValueEnabled)}
                                testId={`${props.testId}-types-${fieldIndex}`}
                            />
                        </div>
                        {typeHasArgument(typesField.fields.value[fieldIndex].type) &&
                            <div className='col-6 ps-3'>
                                {rowValue.types[fieldIndex].type === it.ORGANISM
                                    ? <OrganismSearch
                                        id={`${fieldName}.value`}
                                        name={`${fieldName}.value`}
                                        hasNoPermissions={false}
                                        disabled={identificationComplete}
                                        excludeInactive
                                        includeInconclusive
                                    />
                                    : <SelectField
                                        inline
                                        className='flex-fill min-width-0'
                                        id={`${fieldName}.value`}
                                        name={`${fieldName}.value`}
                                        disabled={identificationComplete}
                                        calcId={_ => _.id}
                                        calcName={_ => _.name}
                                        placeholder='Select'
                                        entities={typeArgument(typesField.fields.value[fieldIndex].type, props.organismTypeArguments)}
                                        testId={`${props.testId}-arguments-${fieldIndex}`}
                                    />
                                }
                            </div>
                        }
                        <div className='col-1'>
                            <Button
                                onClick={() => handleRemoveOrganismType(fieldIndex)}
                                disabled={identificationComplete || typesField.fields.length === 1}
                                className='p-0 text-danger d-print-none'
                                testId={`${props.testId}-delete-${fieldIndex}`}
                            >
                                <i className='material-icons'>delete</i>
                            </Button>
                        </div>
                    </div>
                )}

                <CheckboxField
                    id={`${props.fieldName}.objectionable`}
                    name={`${props.fieldName}.objectionable`}
                    disabled={identificationComplete || rowHasNoGrowth(rowValue) || alwaysObjectionable() || correctionalMpnValueSelected}
                    testId={`${props.testId}-objectionable`}
                >
                    Objectionable
                </CheckboxField>

                <div className='d-none d-print-block'>
                    <span className='me-1'>Objectionable</span>
                    {rowValue.objectionable ? '☑' : '☐'}
                </div>

                <Button
                    className='btn-primary d-print-none me-1'
                    disabled={identificationComplete || hasNoGrowth || correctionalMpnValueSelected}
                    title={correctionalMpnValueSelected ? 'You can not add other ID type if Correctional MPN value is selected' : ''}
                    onClick={() => {
                        const notYetIdentified = rowValue.types[0].type === it.NOT_YET_IDENTIFIED
                            , noIdRequired = rowValue.types[0].type === it.NO_ID_REQUIRED

                        if (notYetIdentified) {
                            addError('You cannot add a new ID type until an identification has been selected')
                            return
                        }

                        if (noIdRequired) {
                            addError('You cannot add a new ID type while No ID required is selected')
                            return
                        }

                        typesField.fields.push({})
                    }}
                    testId={`${props.testId}-add-id-type`}
                >
                    Add an ID type
                </Button>

                <Button
                    className='btn-primary d-print-none'
                    disabled={identificationComplete || currentValue.rows.length === 1}
                    onClick={() => props.onDelete(reference)}
                    testId={`${props.testId}-delete`}
                >
                    Delete
                </Button>
            </td>
        </tr>
    )
}

interface Props {
    isOptional: boolean
    isFingerdabTwoHandsPlate: boolean
    sampleCfuConfirmationEnabled: boolean
    onCfuConfirmed?: () => void
    defaultInputRef?: React.Ref<HTMLInputElement>
    organismTypeArguments: AllIdentifications
    isOrganismObjectionable: (identificationType: it.IdentificationType, identificationValue: string) => boolean
    noIdRequiredEnabled: boolean
    correctionalMpnValueEnabled: boolean
}

function Identifications(props: Props) {
    const FIELD: keyof SampleReading = props.isOptional ? 'optionalIdentifications' : 'identifications'
        , form = useForm<SampleReading>()
        , rowsField = useFieldArray<IdentificationRow>(`${FIELD}.rows`, { isEqual: areArrayFieldsEqual })
        , showDeletionConfirmation = useAction(deletionActions.showDeletionConfirmationModal)
        , nextReference = useNextReference()
        , currentValue = form.getState().values[FIELD] as IdentificationRows
        , hasNoGrowth = currentValue.rows.length === 1 && rowHasNoGrowth(currentValue.rows[0])
        , totalCfu = currentValue.rows.map(_ => typeof _.cfuCount === 'number' ? _.cfuCount : 0).reduce((acc, v) => acc + v, 0)
        , canCompleteIdentification = !hasNoGrowth
            && !currentValue.rows.some(_ => _.types[0].type === it.NOT_YET_IDENTIFIED)
            && !hasErrors(form.getState().errors?.[FIELD])
            && (props.noIdRequiredEnabled
                || !currentValue.rows.some(_ => _.types[0].type === it.NO_ID_REQUIRED) && !props.noIdRequiredEnabled)
        // when sample was saved with No Id required and then context
        // settings changed it should be possible to uncheck ID Complete
        , canUncompleteIdentification = currentValue.complete



    return (
        <fieldset>
            <IdentificationsLegend
                totalCfu={totalCfu}
                isOptional={props.isOptional}
                isFingerdabTwoHandsPlate={props.isFingerdabTwoHandsPlate}
                sampleCfuConfirmationEnabled={props.sampleCfuConfirmationEnabled}
                onCfuConfirmed={props.onCfuConfirmed}
            />

            <table className='table border-bottom' style={{ tableLayout: 'fixed' }}>
                <thead className='thead'>
                    <tr className='table-header-thin'>
                        <th style={{ width: '3em' }}>Row</th>
                        <th style={{ width: '4em' }}>CFUs</th>
                        <th style={{ width: 'auto' }}>Identification</th>
                    </tr>
                </thead>
                <tbody>
                    {rowsField.fields.map((fieldName, fieldIndex) =>
                        <Row
                            key={fieldName}
                            fieldName={fieldName}
                            fieldIndex={fieldIndex}
                            currentValue={currentValue}
                            defaultInputRef={props.defaultInputRef}
                            isOptional={props.isOptional}
                            onDelete={(reference) => {
                                showDeletionConfirmation(`Are you sure you want to delete Row ${reference}?`)
                                    .then(() => rowsField.fields.remove(fieldIndex))
                            }}
                            organismTypeArguments={props.organismTypeArguments}
                            isOrganismObjectionable={props.isOrganismObjectionable}
                            noIdRequiredEnabled={props.noIdRequiredEnabled}
                            correctionalMpnValueEnabled={props.correctionalMpnValueEnabled}
                            testId={`field-${FIELD}-${fieldIndex}`}
                        />
                    )}
                </tbody>
            </table>

            <div className='d-flex align-items-center'>
                <Button
                    className='btn-primary d-print-none'
                    disabled={currentValue.complete || totalCfu === 0}
                    onClick={() => {
                        rowsField.fields.push({ reference: nextReference(), objectionable: false, types: [{}] })
                    }}
                    testId='add-organism'
                >
                    Add an organism
                </Button>
                <CheckboxField
                    className='ms-auto'
                    id={`${FIELD}.complete`}
                    name={`${FIELD}.complete`}
                    disabled={!canCompleteIdentification && !canUncompleteIdentification}
                    testId={`${FIELD}-complete`}
                >
                    Identification complete
                </CheckboxField>
                <div className='d-none d-print-block'>
                    <span className='me-1'>Identification complete</span>
                    {currentValue.complete ? '☑' : '☐'}
                </div>
            </div>
        </fieldset>
    )
}

export default Identifications

function rowHasNoGrowth(row: IdentificationRow) {
    return row.types.length === 1 && (row.cfuCount === 0 || row.cfuCount === undefined)
}

function getIdentificationTypes(row: IdentificationRow, ownTypeIndex: number, noIdRequiredEnabled: boolean, correctionalMpnValueEnabled: boolean) {
    const usedTypes = row.types.filter((_, i) => i !== ownTypeIndex).map(_ => _.type)
        , singleType = row.types.length === 1
        , redundantTypes = [!noIdRequiredEnabled && it.NO_ID_REQUIRED, !correctionalMpnValueEnabled && it.CORRECTIONAL_MPN_VALUE].filter(_ => _)
        , idTypes = it.IDENTIFICATION_TYPE.filter(_ => !redundantTypes.some(type => _.id === type))
        , result = idTypes.filter(type => {
            if (!singleType && type.id === it.NOT_YET_IDENTIFIED)
                return false

            if (!singleType && type.id === it.NO_ID_REQUIRED)
                return false

            if (!singleType && type.id === it.CORRECTIONAL_MPN_VALUE)
                return false

            return !usedTypes.includes(type.id)
        })

    return result
}

function areArrayFieldsEqual(one?: any[], two?: any[]): boolean {
    return one?.length === two?.length
}

function hasErrors(state: any): boolean {
    if (state == null)
        return false

    const keys = Object.keys(state)

    return keys.some(key => {
        const subState = state[key]

        if (typeof subState === 'string')
            return true

        if (Array.isArray(subState))
            return subState.some(hasErrors)

        if (typeof subState === 'object')
            return hasErrors(subState)

        return false
    })
}
