import type { ValidationResult} from '_/utils/form/validate'
import { required, maxLength, isEmpty, allowedName } from '_/utils/form/validate'
import type Context from '_/model/context/context'
import * as contexts from '_/constants/context-level'

import type { ContextForm } from './types'

export default function validate(entity: Partial<ContextForm>, siblingContexts: Context[], ignoreDuplicateName: boolean): ValidationResult<ContextForm> {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 256)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , level = required('Type')(entity.level)
        , context = required('Organisation')(entity.parentId)
        , timeZone = isEmpty('TimeZone')(entity.timeZoneName)
        , tier = required('Tier')(entity.tier)
        , result: ValidationResult<ContextForm> = {}

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (notAllowedNames)
        result.name = notAllowedNames

    if (!ignoreDuplicateName && entity.name && siblingContexts.find(_ => _.name.trim().toLowerCase() === entity.name?.trim().toLowerCase()))
        result.name = `${entity.level === contexts.SITE ? 'Site' : 'Organisation'} with name '${entity.name.trim()}' already exists`

    if (level)
        result.level = level

    if (entity.level === contexts.SITE && context)
        result.parentId = context

    if (timeZone)
        result.timeZoneName = timeZone

    if (entity.level === contexts.ORGANISATION && tier)
        result.tier = tier

    return result
}
