import { useState, useEffect, useAction, useSelector } from '_/facade/react'

import { Table, PaginatedFooter } from '_/components/table'
import Link from '_/components/link'

import * as fieldIndex from '_/constants/custom-field-index'
import * as routes from '_/constants/routes'
import { ANY_ID } from '_/constants/system-words'

import type { OperatorFingerdab, LocationsReport } from '_/model/reports/locations-report/types'
import { getSampleListParams } from '_/model/reports/locations-report/helpers'
import { paramsFilter } from '_/model/filters/helpers'

import type TimeService from 'services/time-service'
import * as sampleActions from '_/features/samples/actions'
import * as sampleTypeActions from '_/features/predefined-lists/sample-type/actions'

import { useScrollTo } from '_/features/reports/hooks'
import TableActionButtons from '_/features/reports/table-action-buttons'
import TableFooter from '_/features/reports/table-footer'
import LimitBreachData from './limit-breach-data'
import { FINGERDAB_PLATE, FINGERDAB_TWO_HANDS_PLATE } from '_/constants/plate-type'
import type { Tree } from '_/utils/tree'
import { dropFields } from '_/utils/object'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import { useTimeService } from '_/components/time'
import FormattedText from '_/features/text/formatted-text'
import type * as t from '_/model/text/text'
import NoDataAvailable from '../../no-data-available'
import { formatActiveState } from '_/utils/format/common'

interface Props {
    locationName: t.Text
    locationReport: LocationsReport
    operatorFingerdabs: OperatorFingerdab[]
    printMode: boolean
    itemsLimit: number
    entityName: string
    locations: Tree<ExposureLocation>
    showSpinner: boolean
    testId?: string
}

function OperatorFingerdabs(props: Props) {
    const [pagination, setPagination] = useState({ start: 0, count: props.itemsLimit })
        , exportOperatorFingerdabsSampleData = useAction(sampleActions.exportOperatorFingerdabsSampleData)
        , buttonsDisabled = props.operatorFingerdabs.length === 0
        , [divElement, setDivElement] = useState<HTMLDivElement | null>(null)
        , sampleTypes = useSampleTypes()
        , timeService = useTimeService()
        , params = calculateSampleListParams(props.locationReport, props.locations, sampleTypes, timeService)
        , isTableEmpty = props.operatorFingerdabs.length === 0
        , operatorReportParams = dropFields(props.locationReport, 'locationId')

    function calculateOperatorFingerdabs() {
        const end = pagination.start + pagination.count
        return props.operatorFingerdabs.slice(pagination.start, end)
    }

    useEffect(
        () => { setPagination({ start: 0, count: props.itemsLimit }) },
        [props.operatorFingerdabs, props.itemsLimit]
    )

    useScrollTo(divElement, 'fingerdab-table')

    return (
        <div ref={setDivElement}>
            <div className='block-border px-3 mt-3'>
                <div className='my-4 text-center break-word'>
                    <h4 data-testid='locations-report-subtitle'>Operator fingerdabs in all locations within <FormattedText text={props.locationName} /></h4>
                    {props.printMode && props.operatorFingerdabs.length > props.itemsLimit &&
                        <h5 className='text-danger d-print-none'>{`Table in Print mode is limited to ${props.itemsLimit} records!`}</h5>
                    }
                </div>
                <Table className='mt-2'>
                    <thead className='thead'>
                        <tr className='table-header-thin' data-testid={`${props.testId}-table-header`}>
                            <th>Operator name</th>
                            <th className='text-end'>Breach rate</th>
                            <th className='text-end'>Fingerdabs</th>
                            <th className='text-end'>Limit breaches</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isTableEmpty || props.showSpinner
                            ? <tr>
                                <td colSpan={4} className='text-center'>
                                    <NoDataAvailable showSpinner={props.showSpinner}>
                                        <span className='text-muted text-uppercase' data-testid={`${props.testId}-no-data-message`}>
                                            No data available
                                        </span>
                                    </NoDataAvailable>
                                </td>
                            </tr>
                            : calculateOperatorFingerdabs().map(_ =>
                                <tr key={_.operatorId}>
                                    <td>
                                        <Link
                                            routeName={routes.OPERATORS_REPORT}
                                            routeParams={paramsFilter({...operatorReportParams, operatorId: _.operatorId})}
                                            testId={`${props.testId}-operator-name`}
                                        >
                                            <FormattedText text={formatActiveState(_.operatorName, _.isActive)} />
                                        </Link>
                                    </td>
                                    <td className='numeric-font-tabular-nums text-end'>{_.breachRate + '%'}</td>
                                    <td className='numeric-font-tabular-nums text-end'>{_.fingerdabCount}</td>
                                    <LimitBreachData limitBreaches={_.limitBreaches}/>
                                </tr>
                            )
                        }
                    </tbody>
                    {!props.printMode && !isTableEmpty &&
                        <PaginatedFooter
                            colSpan={4}
                            state={pagination}
                            onChange={setPagination}
                            totalCount={props.operatorFingerdabs.length}
                            alignPaginationLeft
                            actionButtons={
                                <TableActionButtons
                                    routeParams={params}
                                    onExport={() => exportOperatorFingerdabsSampleData({
                                        ...params,
                                        start: 0,
                                        count: props.itemsLimit,
                                        sort: 'exposureStartTime:asc',
                                        sampleBreachTypes: undefined,
                                        includeGeneratedByInfo: true,
                                    })}
                                    exportButtonDisabled={buttonsDisabled}
                                    disabledGoToSamplesButton={buttonsDisabled}
                                    testId={props.testId}
                                />
                            }
                        />
                    }
                </Table>
                {props.printMode && !isTableEmpty &&
                    <TableFooter report={props.locationReport} entityName={props.entityName} />
                }
            </div>
        </div>
    )
}

export default OperatorFingerdabs

function useSampleTypes() {
    const load = useAction(sampleTypeActions.loadSampleTypeList)
        , sampleTypes = useSelector(_ => _.predefinedLists.sampleTypes)

    useEffect(() => { load() }, [load])

    return sampleTypes
}

function calculateSampleListParams(
    locationReport: LocationsReport,
    locations: Tree<ExposureLocation>,
    sampleTypes: SampleType[],
    timeService: TimeService,
) {
    const routeParams = getSampleListParams(locationReport, false, timeService, locations)
        , sampleTypeIds = sampleTypes
            .filter(_ => _.sampleType === FINGERDAB_TWO_HANDS_PLATE || _.sampleType === FINGERDAB_PLATE)
            .map(_ => _.id)

    return {
        ...routeParams,
        fields: routeParams.fields?.concat([
            { index: fieldIndex.OPERATORS_IDS as fieldIndex.FieldIndex, value: [ANY_ID] },
            { index: fieldIndex.SAMPLE_TYPE_ID as fieldIndex.FieldIndex, value: sampleTypeIds },
        ]),
    }
}
