interface Props {
    isInactive?: boolean
    hasExpired?: boolean
    hasAccepted?: boolean
    processedAt?: string
}

const RestorePasswordMessage: React.FC<Props> = props =>
    <div>
        {(props.hasExpired || props.isInactive) &&
            <p className='text-danger text-start mt-4'>
                <b>Reset password request was not found or had expired.</b>
            </p>
        }
        {!props.hasExpired && !props.isInactive && props.hasAccepted &&
            <p className='text-danger text-start mt-4'>
                <b>You accepted this request on {props.processedAt}.</b>
            </p>
        }
    </div>

export default RestorePasswordMessage
