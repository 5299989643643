import type { AuditTrail } from '_/model/audit-trail/types'
import type { TrendSettings, TrendSettingsEdit } from '_/model/predefined-lists/trend-settings/types'
import { actionFactory } from '_/utils/redux'

const action = actionFactory('app.trend-rules')

export const loadTrendSettingsList = action<void, Promise<TrendSettings[]>>('loadTrendSettingsList')
           , loadTrendSettings = action<string, Promise<TrendSettings>>('loadTrendSettings')
           , loadTrendSettingsTrail = action<string, Promise<AuditTrail>>('loadTrendSettingsTrail')
           , createTrendSettings = action<TrendSettingsEdit, Promise<void>>('createTrendSettings')
           , saveTrendSettings = action<{ id: string, oldTrendSettings: TrendSettings, newTrendSettings: TrendSettingsEdit }, Promise<void>>('saveTrendSettings')
           , removeTrendSettings = action<string, Promise<void>>('removeTrendSettings')
