import Overlay from '_/components/overlay/overlay'
import FormattedText from '_/features/text/formatted-text'

import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'

import * as g from '_/model/floor-plan/geometry'
import type { ChartType } from '_/model/analysis/chart-type'
import { LIMIT_BREACH_FLOOR_PLAN } from '_/model/analysis/chart-type'
import type { FloorPlanLocation } from '_/model/floor-plan/floor-plan'
import { formatActiveState } from '_/utils/format/common'

interface Props {
    canvas: HTMLCanvasElement
    focusedFloorPlanLocation: FloorPlanLocation | undefined
    locations: ListExposureLocation[]
    chartType: ChartType
}

function LocationMenuInfo(props: Props) {
    const location = props.locations.find(_ => _.id === props.focusedFloorPlanLocation!.locationId)

    if (!props.focusedFloorPlanLocation || !location)
        return null

    const [left, top] = calcPosition(props.canvas, props.focusedFloorPlanLocation, props.chartType)

    return (
        <Overlay left={left} top={top}>
            <div className='bg-dark'>
                <div className='text-white text-start px-3 py-2'>
                    <FormattedText text={formatActiveState(location.pathName, location.isActive)} />
                </div>
            </ div>
        </Overlay>
    )
}

export default LocationMenuInfo

function calcPosition(canvas: HTMLCanvasElement, location: FloorPlanLocation, chartType: ChartType) {
    const [left, top] = g.calcDomCoordinates(canvas, location)
        , offset = g.PLOT_RADIUS

    // rendering popup from right side
    // because limit breach may have different number of icons (depending on how many breaches are in location
    if (chartType === LIMIT_BREACH_FLOOR_PLAN)
        return [left + offset * 2, top - offset]

    return [left - offset + 3, top + offset + 3]
}
