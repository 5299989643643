import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type PredefinedListsService from '_/services/predefined-lists-service'
import { noop } from '_/utils/function'
import * as spinnerActions from '_/features/spinner/actions'
import * as toastActions from '_/features/toasts/actions'
import * as actions from './actions'

import { updateTransaction } from '_/model/predefined-lists/operator/transaction'

const factory = (service: PredefinedListsService): EffectsFactory => dispatch => [
    handler(actions.loadSampleOperatorList, () =>
        service.sampleOperator.list()
            .then(_ => dispatch(actions.sampleOperatorListLoaded(_)))
            .then(noop)
    ),

    handler(actions.loadSampleOperator, service.sampleOperator.get),

    handler(actions.loadSampleOperatorTrail, service.sampleOperator.getTrail),

    handler(actions.createSampleOperator, data =>
        service.sampleOperator.create(data)
            .then(_ => service.sampleOperator.list())
            .then(_ => dispatch(actions.sampleOperatorListLoaded(_)))
            .then(noop)
    ),

    handler(actions.removeSampleOperator, id =>
        service.sampleOperator.remove(id)
            .then(_ => dispatch(actions.sampleOperatorRemoved(id)))
            .then(noop)
    ),

    handler(actions.saveSampleOperator, data =>
        Promise.resolve()
            .then(() => dispatch(spinnerActions.showSpinner()))
            .then(_ => updateTransaction(
                data.id,
                data.oldSampleOperator,
                data.newSampleOperator,
                service,
            ))
            .then(_ => service.sampleOperator.list())
            .then(_ => dispatch(actions.sampleOperatorListLoaded(_)))
            .then(noop)
            .finally(() => dispatch(spinnerActions.hideSpinner()))),

    handler(actions.importSampleOperators, file =>
        Promise.resolve()
            .then(() => service.sampleOperator.import(file))
            .then(() => dispatch(actions.loadSampleOperatorList()))
            .then(() => dispatch(toastActions.addSuccess('Import successfully completed')))
            .then(noop)
    ),
]

export default factory
