import { classnames } from '_/facade/react'
import { Link } from '_/utils/router'
import type { DataExportNotification as ExportNotification } from '_/model/notification/types'
import { dataExportNotificationParams } from '_/model/notification/helpers'
import { useTimeService } from '_/components/time'

interface Props {
    notification: ExportNotification
    onClick: () => void
    dropdownLayout: boolean
    testId?: string
}

function DataExportNotification(props: Props) {
    const data = props.notification.data
        , timeService = useTimeService()
        , { routeName, routeParams, entityName } = dataExportNotificationParams(props.notification)
        , commonMessage = `The ${entityName} export you requested on ${timeService.formatCtzDateTime(data.requestedAt)}`

    function handleClick(e: React.MouseEvent) {
        e.preventDefault()
        props.onClick()
    }

    return (
        <>
            <Link
                routeName={routeName}
                routeParams={routeParams}
                className={classnames('cursor-pointer', { 'text-white': props.dropdownLayout })}
                onClick={handleClick}
                testId={`${props.testId}-link`}
            >
                <div className='fw-bold' data-testid={`${props.testId}-export-info`}>
                    {data.successful
                        ? `${commonMessage} is ready to download.`
                        : `${commonMessage} has failed due to ${data.errorMessage}.`
                    }
                </div>
            </Link>
            <div className='text-secondary' data-testid={`${props.testId}-context-info`}>{props.notification.contextName}</div>
        </>
    )
}

export default DataExportNotification
