import { useAction, useEffect, useState, useMemo, useCallback } from '_/facade/react'
import type { AttachedFile } from '_/model/sample/files/files'
import * as a from '../../actions'
import * as toastActions from '_/features/toasts/actions'
import type { FileItem } from './file-list'
import FileList from './file-list'

interface Props {
    sampleId: string
    canEdit: boolean
}

function Attachments(props: Props) {
    const [files, deletedFiles, handleUpload, handleRemove] = useFileItems(props.sampleId)
        , handleDownload = useDownloadHandler()

    return (
        <div>
            <fieldset>
                <div className='d-flex justify-content-between'>
                    <div className='custom-legend'>Attachments ({files.length} file{files.length !== 1 ? 's' : ''})</div>
                    {props.canEdit &&
                        <>
                            <label htmlFor='sample-files' className='btn btn-primary mt-2 d-print-none' data-testid='upload-attachment'>Upload a new attachment</label>
                            <input id='sample-files' type='file' className='d-none' value='' multiple onChange={handleUpload} data-testid='upload-attachment-input' />
                        </>
                    }
                </div>
            </fieldset>
            <FileList
                files={files}
                canEdit={props.canEdit}
                canDelete={_ => !deletedFiles.includes(_)}
                onDownload={handleDownload}
                onRemove={handleRemove}
            />
        </div>
    )
}

export default Attachments

const UNKNOWN_UPLOAD_ERROR = 'There seems to be a problem with the file you uploaded. Try again, or use a different file'

function useFileItems(sampleId: string) {
    const [attachedFiles, reload] = useAttachedFiles(sampleId)
        , [deletedFiles, setDeletedFiles] = useState<string[]>([])
        , [progressFiles, setProgressFiles] = useState<Exclude<FileItem, { tag: 'attached' }>[]>([])
        , upload = useAction(a.uploadSampleFile)
        , remove = useAction(a.removeFile)
        , addError = useAction(toastActions.addError)
        , fileItems = useMemo<FileItem[]>(
            () =>
                Array.of<FileItem>().concat(
                    progressFiles,
                    attachedFiles.map<FileItem>(_ => ({ tag: 'attached', file: _ }))
                )
            ,
            [attachedFiles, progressFiles]
        )

    function getErrorMessage(error: any): string {
        if (error?.status === 400)
            return error?.statusText ?? UNKNOWN_UPLOAD_ERROR

        return UNKNOWN_UPLOAD_ERROR
    }

    function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const files = Array.from(event.target.files ?? [])

        files.reduce((lastPromise, file) => lastPromise.then(() => {
            const result = upload({ file, sampleId })

            setProgressFiles(_ => _.concat({ tag: 'uploaded', file: result.file }))

            return result.progress
                .then(reload)
                .catch(err => {
                    const error = getErrorMessage(err)

                    setProgressFiles(
                        f => f.map(_ => _.file.id === result.file.id ? { tag: 'failed', file: { ...result.file, error } } : _)
                    )

                    addError(error)
                })
                .finally(
                    () => setProgressFiles(f => f.filter(_ => _.tag === 'failed' || _.file.id !== result.file.id))
                )
        }), Promise.resolve())
    }

    function handleRemove(fileId: string) {
        setDeletedFiles(_ => _.concat(fileId))

        return remove(fileId)
            .then(reload)
            .finally(() => {
                setDeletedFiles(_ => _.filter(id => id !== fileId))
            })
    }

    return [fileItems, deletedFiles, handleUpload, handleRemove] as const
}

function useAttachedFiles(sampleId: string) {
    const [attachedFiles, setAttachedFiles] = useState<AttachedFile[]>([])
        , load = useAction(a.loadSampleFileList)
        , reload = useCallback(
            () => load(sampleId).then(setAttachedFiles),
            [sampleId, load]
        )

    useEffect(
        () => {
            reload()
        },
        [reload]
    )

    return [attachedFiles, reload] as const
}

function useDownloadHandler() {
    const download = useAction(a.downloadFile)

    function handleDownload(fileId: string, name: string) {
        return download({ id: fileId, name })
    }

    return handleDownload
}
