import { Form } from 'react-final-form'

import { useAction, useSelector } from '_/facade/react'

import Button from '_/components/button'
import { SelectField, TextField, submitDisabled } from '_/components/form'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'

import type { Device } from '_/model/predefined-lists/devices/types'
import { DEVICE_TYPE } from '_/model/predefined-lists/devices/device-type'

import * as actions from '_/features/predefined-lists/devices/actions'
import * as confirmationActions from '_/features/confirmation/actions'
import * as usersHelpers from '_/model/users/helpers'
import type { ElectronicSignatureSettings } from '_/model/context/electronic-signature-settings'
import validate from '_/model/predefined-lists/devices/validate'
import MarkAsInActive from './mark-as-inactive-field'

interface Props {
    devices: Device[]
    device: Partial<Device>
    contextId: string
    signatureSettings: ElectronicSignatureSettings[]
    isSystemAdmin: boolean
    onReload: () => void
    onCancelEdit: () => void
    onReloadTrail: () => void

}

function DeviceForm(props: Props) {
    const user = useSelector(_ => _.auth.user)
        , isSiteAdmin = usersHelpers.isSiteAdmin(user)
        , handleSubmit = useSubmit(props.onReload, props.onCancelEdit, props.onReloadTrail, props.contextId, props.signatureSettings, isSiteAdmin, props.device)
        , handleRemove = useRemoveDevice(props.onReload, props.onCancelEdit, props.onReloadTrail, props.signatureSettings)

    return (
        <tr>
            <td colSpan={2}>
                <Form<Device>
                    onSubmit={handleSubmit}
                    validate={values => validate(values, props.devices)}
                    initialValues={props.device}
                    render={form =>
                        <form onSubmit={form.handleSubmit}>
                            <UnsavedChangesObserver form={form} />
                            <div>
                                {isSiteAdmin &&
                                    <TextField name='name'>
                                        Device name
                                    </TextField>
                                }
                                {props.isSystemAdmin &&
                                    <div>
                                        <SelectField
                                            name='type'
                                            entities={DEVICE_TYPE}
                                            calcId={_ => _.id}
                                            calcName={_ => _.name}
                                        >
                                            Device type
                                        </SelectField>
                                        <TextField name='deviceId'>
                                            Device ID
                                        </TextField>
                                        {props.device.id &&
                                            <MarkAsInActive />
                                        }
                                    </div>
                                }
                            </div>
                            <div
                                className={`mt-2 d-flex flex-fill ${props.device.id && props.isSystemAdmin ? 'justify-content-between' : 'justify-content-end'}`}>
                                {props.device.id && props.isSystemAdmin &&
                                    <Button
                                        className='text-danger bg-transparent px-0 border-0'
                                        onClick={() => handleRemove(props.device.id!, props.device.name!)}
                                    >
                                        Remove
                                    </Button>
                                }
                                <div>
                                    <Button
                                        className='text-muted bg-transparent border-0 me-2'
                                        onClick={props.onCancelEdit}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className='btn-primary'
                                        onClick={form.handleSubmit}
                                        disabled={submitDisabled(form)}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </form>
                    }
                />
            </td>
        </tr>
    )
}

export default DeviceForm

function useSubmit(reload: () => void, onCancelEdit: () => void, onReloadTrail: () => void, contextId: string, signatureSettings: ElectronicSignatureSettings[], isSiteAdmin: boolean | undefined, oldDevice: Partial<Device>) {
    const createDevice = useAction(actions.createDevice)
        , changeName = useAction(actions.changeDeviceName)
        , saveDeviceChanges = useAction(actions.saveDeviceChanges)

    function handleSubmit(newDevice: Device) {
        const systemAdminRequest = oldDevice.id
                ? saveDeviceChanges({id: oldDevice.id, oldDevice: oldDevice as Device, newDevice, signatureSettings})
                : createDevice({id: contextId, device: newDevice})
            , request = isSiteAdmin
                ? changeName({ id: oldDevice.id!, name: newDevice.name })
                : systemAdminRequest

        request
            .then(reload)
            .then(onReloadTrail)
            .then(onCancelEdit)
    }

    return handleSubmit
}

function useRemoveDevice(reload: () => void, onCancelEdit: () => void, onReloadTrail: () => void, signatureSettings: ElectronicSignatureSettings[]) {
    const removeDevice = useAction(actions.removeDevice)
        , showConfirmation = useAction(confirmationActions.showDeletionConfirmationModal)

    function handleRemove(id: string, name: string) {
        showConfirmation(`Are you sure you want to delete ${name}?`)
            .then(() => removeDevice({ id, signatureSettings }))
            .then(reload)
            .then(onReloadTrail)
            .then(onCancelEdit)
    }

    return handleRemove
}
