import { useAction, useState, classnames, useSelector, useEffect } from '_/facade/react'

import { useContextSwitchObserver } from '_/components/context-observer'
import PageHeader from '_/components/page-header'
import SiteTimeZoneDateFilter from '_/components/site-time-zone-date-filter'
import Link from '_/components/link'

import FULL_NAME_WEEK from '_/constants/weekday'
import * as routes from '_/constants/routes'

import { calculateMonthlyViewDays } from '_/model/scheduling/monthly-scheduler/helpers'
import type { DayScheduleStatistic as DayPlanStatisticTotals } from '_/model/scheduling/day-scheduler/types'

import * as timeActions from '_/features/time/actions'
import * as DayScheduler from '../day-scheduler/actions'

import DayScheduleStatistic, { DayPlanStatisticTotal } from './day-schedule-statistic'
import type { DateTime} from '_/model/date-time'
import { equals } from '_/model/date-time'

function MonthlyScheduler() {
    const permissions = useSelector(_ => _.auth.permissions)
        , getTimeService = useAction(timeActions.getTimeService)
        , timeService = getTimeService()
        , date = useSelector<DateTime | undefined>(_ => _.router.route?.params.date)
        , siteTimeZoneDate = timeService.castUtcDayStartFromCtzDay(timeService.now())
        , [currentMonth, setMonth] = useState(date ?? siteTimeZoneDate)
        , days = calculateMonthlyViewDays(currentMonth, timeService)
        , schedules = useDaySchedules(days[0], days[days.length - 1])
        , hideRightBorder = (index: number) => index % 7 !== 0
        , hideBottomBorder = (index: number) => index < days.length - 7

    function handleSetMonth(date: DateTime | undefined) {
        if (!date)
            return

        const { year, month } = timeService.utcTimeStruct(date)
        setMonth(timeService.utc(year, month, 1, 0, 0, 0))
    }

    return (
        <div>
            <PageHeader sticky title={`Schedule for ${timeService.formatUtcDate(currentMonth, true)}`}>
                <SiteTimeZoneDateFilter
                    filterByMonth
                    onChange={handleSetMonth}
                    initialFilter={currentMonth}
                    buttonName='Go to current month'
                />
            </PageHeader>

            <div className='d-flex justify-content-center mx-3'>
                {FULL_NAME_WEEK.map(_ => <span className='custom-legend monthly-planner_title' key={_.id}>{_.name}</span>)}
            </div>
            <div className='d-flex flex-wrap justify-content-center'>
                {days.map((day, index) =>
                    <div
                        key={index}
                        className={classnames(
                            'd-flex flex-column monthly-planner_day-container border', {
                            'border-end': !hideRightBorder(index + 1), 'border-end-0': hideRightBorder(index + 1),
                            'border-bottom-0': hideBottomBorder(index), 'border-bottom': !hideBottomBorder(index),
                            'alert-warning': equals(day, siteTimeZoneDate),
                            'bg-light': timeService.utcTimeStruct(currentMonth).month !== timeService.utcTimeStruct(day).month,
                        })}
                    >
                        <div className='d-flex'>
                            <div className='fw-bold text-secondary me-2'>{timeService.utcTimeStruct(day).day}</div>
                            <DayPlanStatisticTotal
                                dayPlanStatistic={schedules.find(_ => equals(_.date, day))}
                                testId={`day-scheduler-${timeService.utcTimeStruct(day).month}-${timeService.utcTimeStruct(day).day}`}
                            />
                        </div>
                        {!schedules.find(_ => equals(_.date, day))?.scheduledSamplesCount
                            ? <div className='d-flex m-auto align-items-center'>
                                <Link
                                    routeName={routes.SCHEDULING_DAY_SCHEDULER}
                                    routeParams={{date: day}}
                                    hasNoPermissions={!permissions.editSchedule}
                                    testId={`day-scheduler-${timeService.utcTimeStruct(day).month}-${timeService.utcTimeStruct(day).day}`}
                                >
                                    <i className='material-icons md-24 mb-4 text-secondary'>control_point</i>
                                </Link>
                            </div>
                            : <DayScheduleStatistic dayPlanStatistic={schedules.find(_ => equals(_.date, day))!} testId={`day-scheduler-${timeService.utcTimeStruct(day).month}-${timeService.utcTimeStruct(day).day}`} />
                        }
                    </div>
                )}
            </div>
        </div>

    )
}

export default MonthlyScheduler

function useDaySchedules(dateFrom: DateTime, dateTo: DateTime) {
    const load = useAction(DayScheduler.loadDaySchedules)
        , [schedules, setSchedules] = useState<DayPlanStatisticTotals[]>([])
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            load({dateFrom, dateTo}).then(setSchedules)
        },
        [load, dateFrom, dateTo, contextSwitch]
    )

    return schedules
}
