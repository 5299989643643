import { diffObject } from '_/utils/object'
import type Api from '_/services/predefined-lists-service'
import type { SampleOperatorEdit } from '_/model/predefined-lists/operator/types'

function updateTransaction(
    id: string,
    current: SampleOperatorEdit,
    next: SampleOperatorEdit,
    api: Api,
): Promise<void> {
    const diff = diffObject(current, next)

    if (!diff)
        return Promise.resolve()

    return api.sampleOperator.save(id, diff)
}

export {
    updateTransaction,
}
