import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, isEmpty, allowedName } from '_/utils/form/validate'
import type { SampleOperatorEdit, SampleOperator } from '_/model/predefined-lists/operator/types'
import { searchDuplicateName } from '_/model/common/helpers'

export default function validate(entity: Partial<SampleOperatorEdit>, sampleOperators: SampleOperator[]) {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 50)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , duplicate = entity.name && searchDuplicateName(entity, sampleOperators)
        , result: ValidationResult<SampleOperatorEdit> = {}

    if (name)
        result.name = name

    if (notAllowedNames)
        result.name = notAllowedNames

    if (nameLength)
        result.name = nameLength

    if (duplicate)
        result.name = `Operator with name '${entity.name?.trim()}' already exists`

    return result
}
