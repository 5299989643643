import { actionFactory } from '_/utils/redux'
import type Toast from '_/model/toast'
import * as toastType from '_/constants/toast-type'

const action = actionFactory('app.toasts')

export const addToast = action<Toast>('add')
           , deleteToast = action<Toast>('delete')

export function addError(message: string) {
    return addToast({ message, type: toastType.ERROR })
}
addError.type = addToast.type

export function addSuccess(message: string) {
    return addToast({ message, type: toastType.SUCCESS })
}
addSuccess.type = addToast.type
