import type { TimezoneAwareTimeService } from '_/services/time-service'
import type { EffectsFactory} from '_/facade/effect'
import { handler } from '_/facade/effect'
import * as actions from './actions'

const factory = (service: TimezoneAwareTimeService): EffectsFactory => _ => [
    handler(actions.setTimezone, timezone => {
        service.init(timezone)
        return timezone
    }),
    handler(actions.getTimeService, _ => service),
]

export default factory
