import { useEffect, useAction, useSelector } from '_/facade/react'
import type { AnalysisFilter } from '_/model/analysis/filter/types'

import Button from '_/components/button'
import * as routes from '_/constants/routes'
import * as ranges from '_/constants/date-ranges'
import { LIMIT_BREACH_FLOOR_PLAN } from '_/model/analysis/chart-type'
import { DAY } from '_/model/analysis/aggregation-period'
import { paramsFilter } from '_/model/filters/helpers'

import * as locationActions from '_/features/predefined-lists/exposure-locations/actions'
import * as tree from '_/utils/tree'

import { useNavigateTo } from '../helpers'

interface Props {
    analysisRouteParams: Pick<AnalysisFilter, 'cumulativeView' | 'fields'>
    insightId: string
    from: number
    to: number
}

function InsightBody(props: Props) {
    const rootLocationId = useRootLocationId()
        , navigateTo = useNavigateTo(props.insightId)
        , locationReportRouteParams = {
            locationId: rootLocationId,
            exposureStartDateFrom: props.from,
            exposureStartDateTo: props.to,
            exposureDateRange: ranges.CUSTOM,
        }
        , analysisRouteParams = {
            aggregationPeriod: DAY,
            chartType: LIMIT_BREACH_FLOOR_PLAN,
            includeCompromised: false,
            exposureStartDateFrom: props.from,
            exposureStartDateTo: props.to,
            exposureDateRange: ranges.CUSTOM,
            ...props.analysisRouteParams,
        }

    return (
        <div>
            <Button onClick={() => navigateTo(routes.LOCATIONS_REPORT, { ...paramsFilter(locationReportRouteParams), scrollTo: 'session-breaches' })} className='btn-link d-block text-start p-0'>
                See more details about the distribution of breaches in your facility
            </Button>
            <Button onClick={() => navigateTo(routes.ANALYSIS, paramsFilter(analysisRouteParams))} className='btn-link d-block text-start p-0'>
                See where breaches occurred on a map
            </Button>
        </div>
    )
}

export default InsightBody

function useRootLocationId() {
    const loadLocations = useAction(locationActions.loadExposureLocationList)
        , locations = useSelector(_ => _.predefinedLists.exposureLocations)

    useEffect(
        () => { loadLocations() },
        [loadLocations]
    )

    return tree.roots(locations)[0]?.id
}
