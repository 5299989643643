import { Field } from 'react-final-form'
import { classnames } from '_/facade/react'
import { showFieldError } from '_/components/form/helpers'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'

import type { ValidationResult} from '_/utils/form/validate'
import { maxLength } from '_/utils/form/validate'

import FieldForm from './field-form'
import Error from '_/components/form/field-error'
import InlineActions from './inline-actions'
import * as fieldIndex from '_/constants/custom-field-index'
import type { SampleEditedInfo } from '_/model/sample/sample'
import EditedLabel from './edited-label'

const SAMPLE_INVESTIGATION_REFERENCES = 'sampleInvestigationReferences'
type SAMPLE_INVESTIGATION_REFERENCES = typeof SAMPLE_INVESTIGATION_REFERENCES

type SubEntity = Pick<SampleDetailsEdit, SAMPLE_INVESTIGATION_REFERENCES>

interface OwnProps {
    entity: SampleDetailsEdit
    onSubmit: (sample: SampleDetailsEdit) => Promise<void>
    hasNoPermissions: boolean
    editedInfo: SampleEditedInfo | undefined
}

function SampleInvestigationRefsForm(props: OwnProps) {
    function validate(entity: Partial<SampleDetailsEdit>) {
        const investigationRefsLength = maxLength('Viable sample investigation refs', 200)(entity[SAMPLE_INVESTIGATION_REFERENCES])
            , result: ValidationResult<SampleDetailsEdit> = {}

        if (investigationRefsLength)
            result[SAMPLE_INVESTIGATION_REFERENCES] = investigationRefsLength

        return result
    }

    function handleSubmit(subEntity: SubEntity) {
        const { sampleInvestigationReferences } = subEntity
            , sampleEdit = { ...props.entity, sampleInvestigationReferences } // allow saving empty value

        return props.onSubmit(sampleEdit)
    }

    return (
        <FieldForm
            formId='investigation-ref'
            label='Viable sample investigation refs'
            testId={`sample-field-${fieldIndex.REFERENCE_NUMBERS}`}
            initialValues={props.entity}
            validate={validate}
            onSubmit={handleSubmit}
            hasNoPermissions={props.hasNoPermissions}
            renderView={() =>
                <span className='form-control-plaintext'>
                    <span className='fw-bold'>{props.entity[SAMPLE_INVESTIGATION_REFERENCES]}</span>
                    <EditedLabel editedInfo={props.editedInfo}/>
                </span>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div className='d-flex align-items-start'>
                    <div className='flex-fill'>
                        <Field name={SAMPLE_INVESTIGATION_REFERENCES} render={_ =>
                            <>
                                <input
                                    id={SAMPLE_INVESTIGATION_REFERENCES}
                                    type='text'
                                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                                    autoComplete='off'
                                    {..._.input}
                                    autoFocus
                                    data-testid={`field-${fieldIndex.REFERENCE_NUMBERS}`}
                                />
                                <Error field={_} />
                            </>
                        } />
                    </div>
                    <InlineActions form={form} onSave={handleSave} onCancel={handleCancel} testId={`sample-field-${fieldIndex.REFERENCE_NUMBERS}`} />
                </div>
            }
        />
    )
}

export default SampleInvestigationRefsForm
