import { diffObject } from '_/utils/object'
import type Api from '_/services/predefined-lists-service'
import type { FormSampleType } from '_/model/predefined-lists/sample-type/types'

function updateTransaction(
    id: string,
    current: FormSampleType,
    next: FormSampleType,
    api: Api,
): Promise<void> {
    const diff = diffObject(current, next)

    if (!diff)
        return Promise.resolve()

    return api.sampleType.save(id, diff)
}

export {
    updateTransaction,
}
