import type TimeService from '_/services/time-service'

import WEEK from '_/constants/weekday'

import type { DateTime } from '_/model/date-time'
import * as t from '_/model/text/text'

import { generatePastPeriod } from '../monthly-scheduler/helpers'
import { formatActiveState } from '_/utils/format/common'

function formatGroupName(name: string, isActive: boolean | undefined, isAdHoc?: boolean) {
    return formatActiveState(name, isActive)
        .concat(isAdHoc ? t.systemTextNode(' (ad-hoc)') : t.emptyTextNode())
}

function ordinaryNumbers(maxValue = 31) {
    function calcName(value: number) {
        let endings = 'th'
        const numberEndings = value.toString().slice(-1)
        if (numberEndings === '1' && value !== 11)
            endings = 'st'
        if (numberEndings === '2' && value !== 12)
            endings = 'nd'
        if (numberEndings === '3' && value !== 13)
            endings = 'rd'
        return value + endings
    }

    return Array.from(Array(maxValue))
        .map((_, i) => ({
            id: i+1,
            name: calcName(i+1)
        }))
        .concat({ id: 0, name: 'Last' })
}

function formatStartDate(timeService: TimeService, startDate: DateTime) {
    const startDateWeekDayId = timeService.utcWeekDay(startDate)
        , weekDay = WEEK.find(_ => _.id === startDateWeekDayId)?.shortName

    return `${timeService.formatUtcDate(startDate)} (${weekDay})`
}

function calculateNumberOfWeekDay(timeService: TimeService, date: DateTime) {
    const { day } = timeService.utcTimeStruct(date)
        , weekday = timeService.utcWeekDay(date)
        , dateRange = generatePastPeriod(timeService, date, day)
            .filter(_ => timeService.utcWeekDay(_) === weekday)

    return dateRange.length
}

export {
    formatGroupName,
    ordinaryNumbers,
    formatStartDate,
    calculateNumberOfWeekDay,
}
