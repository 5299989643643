import type { MissedMonitoringByDayAndSession, MissedMonitoringReport } from '_/model/reports/missed-monitoring-report/types'
import { convertMissedMonitoringToExportFormat } from '_/model/reports/export'
import { calculateMaxMissedSamplesCount } from '_/model/reports/missed-monitoring-report/helpers'
import SessionDayGraphContainer from '_/features/reports/graph-by-day-session/session-day-graph-container'
import MissedSamplesData from './missed-monitoring-data'
import * as t from '_/model/text/text'

interface Props {
    missedMonitoring: MissedMonitoringByDayAndSession[]
    report: MissedMonitoringReport
    printMode: boolean
    showSpinner: boolean
}

function AverageMissedMonitoring(props: Props) {
    const maxMissedSamplesCount = calculateMaxMissedSamplesCount(props.missedMonitoring)

    function dayData(dayId: number, sessionId: string) {
        const sessionMissedMonitoring = props.missedMonitoring.find(_ => _.sessionId === sessionId)
        return sessionMissedMonitoring?.missedMonitoring.find(_ => _.dayOfWeek === dayId)
    }

    return (
        <div className='col-6 width-print-100'>
            <div className='block-border px-3'>
                <SessionDayGraphContainer
                    title={[t.defaultTextNode('Average missed monitoring by day and session')]}
                    name='Missed monitoring report'
                    noDataAvailable={props.missedMonitoring.length === 0}
                    printMode={props.printMode}
                    exportGraphData={convertMissedMonitoringToExportFormat(props.missedMonitoring)}
                    maxValue={maxMissedSamplesCount}
                    report={props.report}
                    showSpinner={props.showSpinner}
                    hideGoToSamples
                    hideOtherSessionHint
                    renderDayCell={(sessionId, dayId) =>
                        <MissedSamplesData
                            missedMonitoring={dayData(dayId, sessionId)}
                            key={dayId}
                            maxMissedSamplesCount={maxMissedSamplesCount}
                        />
                    }
                />
            </div>
        </div>
    )
}

export default AverageMissedMonitoring
