import { classnames, useSelector } from '_/facade/react'
import type Permissions from '_/model/permissions/type'
import Link from '_/components/link'
import * as routes from '_/constants/routes'
import logo from '_/../assets/microgenetics-roundel.svg'

import SessionInfo from './session-info'
import SampleSearch from './sample-search'
import ColonyCounterPlateSearch from './colony-counter-plate-search'
import Notifications from '_/features/notifications/ui/notifications'

declare const VERSION: string

function Navbar() {
    const auth = useSelector(_ => _.auth)
        , permissions = auth.permissions
        , isBackendAvailable = useSelector(_ => _.backendStatus.backendStatus)
        , route = useSelector(_ => _.router.route)

    return (
        isBackendAvailable.serviceAvailable
            ? <nav className='navbar navbar-app navbar-expand navbar-dark flex-shrink-0'>
                <span className='navbar-brand' title={VERSION}>
                    <img className='navbar-app__logo' src={logo}/> SmartControl
                </span>
                <div className='collapse navbar-collapse'>
                    {auth.token && permissions.smartControlMode &&
                        <ul className='navbar-nav'>
                            <li className='nav-item'>
                                <Link
                                    routeName={routes.DASHBOARD}
                                    className='nav-link'
                                    hasNoPermissions={!permissions.readDashboard}
                                >
                                    Home
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    routeName={routes.ANALYSIS}
                                    className='nav-link'
                                    hasNoPermissions={!permissions.readAnalysis}
                                >
                                    Analysis
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    routeName={routes.TRENDS}
                                    className='nav-link'
                                    hasNoPermissions={!permissions.readTrends}
                                >
                                    Trends
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    routeName={permissions.readCustomReports ? routes.CUSTOM_REPORTS : routes.LIMIT_BREACH_REPORT}
                                    className={classnames(
                                        'nav-link',
                                        { active: route?.name.startsWith(routes.REPORTS) },
                                    )}
                                    hasNoPermissions={!permissions.readCustomReports && !permissions.readReports}
                                >
                                    Reports
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    routeName={getSchedulingRoute(auth.permissions)}
                                    className='nav-link'
                                    hasNoPermissions={
                                        !permissions.readSchedule
                                        && !permissions.editMonitoringGroups
                                        && !permissions.readMonitoringOverview
                                    }
                                >
                                    Scheduling
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    routeName={routes.SAMPLES} className={classnames(
                                        'nav-link',
                                        { active: route?.name === routes.NON_VIABLE_SAMPLES },
                                    )}
                                    ignoredRoutes={[routes.SAMPLES_BOOKING, routes.SAMPLES_READING]}
                                    hasNoPermissions={!permissions.readSamples}
                                >
                                    Samples
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    routeName={routes.SAMPLES_BOOKING}
                                    className='nav-link'
                                    hasNoPermissions={!permissions.bookSamples}
                                    activeRoutes={[routes.NON_VIABLE_SAMPLES_RECORDING]}
                                >
                                    Book in
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    routeName={routes.SAMPLES_READING}
                                    className='nav-link'
                                    hasNoPermissions={!permissions.performSampleReading}
                                >
                                    Read
                                </Link>
                            </li>
                        </ul>
                    }
                    {auth.token && permissions.colonyCounterMode &&
                        <ul className='navbar-nav'>
                            <li className='nav-item'>
                                <Link
                                    routeName={routes.PLATES}
                                    className='nav-link'
                                    ignoredRoutes={[routes.PLATES_READING]}
                                    hasNoPermissions={!permissions.readColonyCounterPlates}
                                >
                                    Plates
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    routeName={routes.PLATES_READING}
                                    className='nav-link'
                                    hasNoPermissions={!permissions.readColonyCounterPlates}
                                >
                                    Read
                                </Link>
                            </li>
                        </ul>
                    }
                </div>
                {auth.token &&
                    <div className='nav d-flex align-items-center justify-content-center'>
                        {permissions.smartControlMode &&
                            <SampleSearch />
                        }
                        {permissions.colonyCounterMode &&
                            <ColonyCounterPlateSearch />
                        }
                        <Link
                            routeName={routes.TRAILS}
                            hasNoPermissions={!permissions.readGlobalTrails}
                            title='Global audit trail'
                            className='mx-2'
                        >
                            <i className={classnames(
                                'material-icons text-light',
                                {'navbar__user-account--active-color': route?.name === routes.TRAILS}
                            )}>
                                history
                            </i>
                        </Link>
                        <Link
                            routeName={routes.SETTINGS}
                            className='mx-2'
                            title='Settings'
                        >
                            <i className={classnames(
                                'material-icons text-light',
                                {'navbar__user-account--active-color': route?.name.startsWith(routes.SETTINGS)}
                            )}>
                                settings
                            </i>
                        </Link>
                        <div className='navbar__vertical-separator mx-2'/>
                        <Notifications />
                        <SessionInfo />
                    </div>
                }
            </nav>
            : null
    )
}

function getSchedulingRoute(permissions: Permissions) {
    if (permissions.readSchedule)
        return routes.SCHEDULING

    if (permissions.editMonitoringGroups)
        return routes.SCHEDULING_MONITORING_GROUPS

    if (permissions.readMonitoringOverview)
        return routes.SCHEDULING_MONITORING_OVERVIEW

    return routes.SCHEDULING
}

export default Navbar
