import { React, useCallback, useEffect, useMemo } from '_/facade/react'
import getPlotly from '_/facade/plotly'

import type SampleSearchFields from '_/model/sample/search'

import type { ChartMetadata } from '_/model/analysis/chart-metadata'
import { getLineGraphLayoutMultiY as getLayout, getPrintLayout, getExportOpts } from '_/features/analysis/helpers'
import ActionButtons from './table-action-buttons'

interface Props {
    data: Plotly.PlotData[]
    chartTitle: string
    metadata: ChartMetadata
    sampleListRouterParams: SampleSearchFields | undefined
    printMode: boolean
    disabledGoToSamplesButton: boolean
    fileName: string
    testId?: string
    onExport?: (_: Blob) => void
}

function LineChart(props: Props) {
    const root = React.createRef<HTMLDivElement>()
        , metadata = useMemo(
            () => props.printMode ? props.metadata : ({...props.metadata, footerPlainText: [], footer: [], author: ''}),
            [props.metadata, props.printMode]
        )
        , plot = useCallback(
            () => {
                getPlotly().then(plotly => {
                    if (root.current)
                        plotly.newPlot(root.current, props.data, getLayout(metadata, ...getYAxesData([props.chartTitle], props.data)), { displayModeBar: false })
                })
            },
            [root, props.data, metadata, props.chartTitle]
        )

    useEffect(plot, [plot])

    function handleExport() {
        getPlotly().then(plotly => {
            if (root.current) {
                const chartData = props.data
                    , legendLength = chartData.length

                plotly.relayout(root.current, getPrintLayout(props.metadata, legendLength))

                const downloadImgOpts = {...getExportOpts(legendLength, props.metadata), filename: props.fileName }
                plotly.downloadImage(root.current, downloadImgOpts)

                plotly.relayout(root.current, getLayout(metadata, ...getYAxesData([props.chartTitle], chartData)))
            }
        })
    }

    return (
        <div>
            <div className='border border-light'>
                <div ref={root} />
            </div>
            {!props.printMode &&
                <ActionButtons
                    disabledGoToSamplesButton={props.disabledGoToSamplesButton}
                    onExport={handleExport}
                    routeParams={props.sampleListRouterParams}
                    className='text-end me-3'
                    testId={props.testId}
                />
            }
        </div>
    )
}

export default LineChart

function getYAxesData(yAxes: [string, ...string[]], chartData: Plotly.PlotData[]) {
    return yAxes.map(_ => [_, chartData] as const)
}
