import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type LimitBreachReportService from '_/services/limit-breach-report-service'

import * as actions from './actions'

const factory = (service: LimitBreachReportService): EffectsFactory => () => [
    handler(actions.loadLimitBreachReport, service.getLimitBreachReport)
]

export default factory
