import type { FieldInputProps, FieldRenderProps, FormRenderProps } from 'react-final-form'
import { Field, Form } from 'react-final-form'
import Button from '_/components/button'
import { showFieldError, Submit, submitDisabled } from '_/components/form'
import FormChangesObserver from '_/components/form/form-changes-observer'
import { React, classnames, useState, useContext, useCallback, useSelector } from '_/facade/react'
import type { PlateEdit } from '_/model/plate/plate'
import type { ValidationResult } from '_/utils/form/validate'

interface Context {
    plate: PlateEdit
    onSubmit: (_: PlateEdit) => Promise<void>
    editedFieldState: [string, React.Dispatch<React.SetStateAction<string>>]
}

const ctx = React.createContext<Context>(undefined as any)

interface Props {
    fieldId: string
    label: string
    disabled?: boolean
    renderView: () => React.ReactNode
    renderEdit?: (field: FieldRenderProps<any, any>, inputProps: FieldInputProps<any>) => React.ReactNode
    validate?: (plate: PlateEdit) => ValidationResult<PlateEdit> | Promise<ValidationResult<PlateEdit>>
}

function PlateField(props: Props) {
    const ctxData = useContext(ctx)
        , [editedField, setEditedField] = ctxData.editedFieldState
        , inEditMode = editedField === props.fieldId
        , canEdit = useSelector(_ => _.auth.permissions.performColonyCounterPlateReading)
        , canActivateEdit = !inEditMode && props.renderEdit
        , editButtonDisabled = editedField !== '' || props.disabled

    function handleCancel(form: FormRenderProps<PlateEdit>) {
        setEditedField('')
        form.form.reset()
    }

    return (
        <div className='row g-2'>
            <label className='d-flex col-4 col-form-label'>
                <span className='text-muted'>{props.label}</span>
                {canActivateEdit &&
                    <Button
                        onClick={() => setEditedField(props.fieldId)}
                        className={classnames('ms-auto p-0 border-0 mb-n1', { 'cursor-auto': editButtonDisabled })}
                        disabled={editButtonDisabled}
                        hasNoPermissions={!canEdit}
                    >
                        <i className='sample-edit__edit-icon material-icons text-primary md-18'>create</i>
                    </Button>
                }
            </label>
            <div className='col-8'>
                {editedField === props.fieldId
                    ? <Form
                        initialValues={ctxData.plate}
                        validate={props.validate}
                        onSubmit={ctxData.onSubmit}
                        render={form =>
                            <form className='d-flex align-items-start' onSubmit={form.handleSubmit}>
                                <FormChangesObserver form={form} target={props.fieldId} />
                                <div className='flex-fill'>
                                    <Field name={props.fieldId} render={_ =>
                                        <>
                                            {props.renderEdit!(
                                                _,
                                                {
                                                    ..._.input,
                                                    className: classnames('form-control', { 'is-invalid': showFieldError(_.meta) }),
                                                    autoComplete: 'off',
                                                    autoFocus: true,
                                                }
                                            )}
                                            {showFieldError(_.meta) && <span className='invalid-feedback'>{_.meta.error}</span>}
                                        </>
                                    } />
                                </div>
                                <Submit
                                    className='btn-primary ms-1'
                                    disabled={submitDisabled(form)}
                                    onClick={form.handleSubmit}
                                >
                                    Save
                                </Submit>
                                <Button
                                    className='btn-secondary ms-1'
                                    onClick={() => handleCancel(form)}
                                >
                                    Cancel
                                </Button>
                            </form>
                        }
                    />
                    : <div className='break-word form-control-plaintext'>
                        {props.renderView()}
                    </div>
                }
            </div>
        </div>
    )
}

export default PlateField

interface FieldContainerProps {
    plate: PlateEdit
    onSubmit: (_: PlateEdit) => Promise<void>
    children: React.ReactNode
}

export function FieldContainer(props: FieldContainerProps) {
    const editedFieldState = useState('')
        , [_, setEditedField] = editedFieldState
        , submit = props.onSubmit
        , submitHandler = useCallback(
            (plate: PlateEdit) => submit(plate).then(_ => setEditedField('')),
            [submit, setEditedField]
        )


    return (
        <ctx.Provider value={{ plate: props.plate, onSubmit: submitHandler, editedFieldState }}>
            {props.children}
        </ctx.Provider>
    )
}
