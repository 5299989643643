const PHOTO_BOOTH = 0

type DeviceType = typeof PHOTO_BOOTH

const DEVICE_TYPE: { id: DeviceType, name: string }[] = [
    {
        id: PHOTO_BOOTH,
        name: 'Photo booth'
    }
]

export {
    DEVICE_TYPE,
    PHOTO_BOOTH,
    DeviceType,
}
