import {
    React,
    useState,
    useReducer,
    useEffect,
    useSelector,
    forwardRef,
} from '_/facade/react'
import type TimeService from '_/services/time-service'

import type { AnalysisFilter } from '_/model/analysis/filter/types'
import type SampleSearchFields from '_/model/sample/search'
import type FloorPlanSeries from '_/model/floor-plan/limit-breach-floor-plan-series'
import type { FloorPlanChartMetadata } from '_/model/analysis/chart-metadata'
import type { FloorPlanLocation, FloorPlanSubFilter } from '_/model/floor-plan/floor-plan'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import FloorPlanChartContainer from './floor-plan-chart-container'

import type { BreachTypeWithCompromised } from '_/constants/sample-breach-type'
import * as fieldIndex from '_/constants/custom-field-index'

import { useImageSource } from '_/features/predefined-lists/floor-plan/hooks'

import { renderLimitBreachFloorPlan } from '_/model/floor-plan/draw'
import * as g from '_/model/floor-plan/geometry'
import { findLocation } from '_/model/floor-plan/draw'
import { fullNameLocationList } from '_/utils/exposure-location'
import { checkInactiveLocation } from '_/features/analysis/helpers'
import type { FloorPlanChartRef} from '_/model/floor-plan/export'
import { exportFloorPlanToImage } from '_/model/floor-plan/export'
import type * as withRefFlag from '_/model/sample/filter/with-ref-flag'
import { LIMIT_BREACH_FLOOR_PLAN } from '_/model/analysis/chart-type'
import { formatAggregationPeriodName } from './helpers'
import LocationMenuInfo from './location-menu-info'
import type { FloorPlanLocationPage } from '_/model/floor-plan/floor-plan-location-page'
import LimitBreachFloorPlanTabularView from './tabular-views/limit-breach-floor-plan-tabular-view'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'

interface Props {
    floorPlans: FloorPlan[]
    filter: AnalysisFilter
    timeService: TimeService
    series: FloorPlanSeries[]
    showActionButtons?: boolean | undefined
    showActionButtonsAsPrimaryButtons?: boolean
    multipleGraphs?: boolean | undefined
    exportDisabled: boolean
    sampleListRouterParams: SampleSearchFields
    floorPlanSubFilter: FloorPlanSubFilter
    metadata: FloorPlanChartMetadata[]
    hideTimeSliderBlock?: boolean
    hideAppliedFiltersBlock?: boolean
    floorPlanLocationPage: FloorPlanLocationPage
    onClickFloorPlanLocation(locationId: string, breachType: BreachTypeWithCompromised | undefined, sampleInvestigationRefFlag: withRefFlag.WithRefFlag): void
    showTabularView?: boolean
}

const LimitBreachFloorPlanChart = (props: Props, ref: React.ForwardedRef<FloorPlanChartRef>) => {
    const floorPlan = props.floorPlans.find(_ => _.id === props.floorPlanSubFilter.value.floorPlanId)
        , selectedLocations = props.filter.fields?.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ID)
        , locations = fullNameLocationList(useSelector(_ => _.predefinedLists.exposureLocations))
        , src = useImageSource(floorPlan?.imageId, 'original')
        , [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
        , [hoveredLocation, setHoveredLocation] = useState<FloorPlanLocation | undefined>(undefined)
        , redraw = useLocationRenderer(canvas, floorPlan?.locations ?? [], props.series, selectedLocations?.value ?? [], locations)
        , handleClick = useClickHandler(floorPlan?.locations ?? [], selectedLocations?.value ?? [], props.series, locations, props.onClickFloorPlanLocation)
        , handleMouseMove = useMouseMove(floorPlan?.locations ?? [], selectedLocations?.value ?? [], props.series, setHoveredLocation, locations)
        , chartType: typeof LIMIT_BREACH_FLOOR_PLAN = LIMIT_BREACH_FLOOR_PLAN
        , aggregatePeriodName = formatAggregationPeriodName(chartType, props.filter.aggregationPeriod)
        , chartData = {
            series: props.series,
            aggregatePeriodName,
            chartType: chartType,
            value: selectedLocations?.value ?? [],
        }
        , floorPlanSeries = props.series.filter(s => (floorPlan?.locations ?? []).some(l => l.locationId === s.locationId))
        , disabledGoToSamples = floorPlanSeries.reduce(
                (acc, v) => acc
                    + v.actionBreachCount
                    + v.actionBreachWithRefCount
                    + v.alertBreachCount
                    + v.alertBreachWithRefCount
                    + v.compromisedCount
                    + v.compromisedWithRefCount,
                0
            ) === 0

    return (
        <FloorPlanChartContainer
            {...props}
            chartType={chartType}
            metadata={props.metadata}
            ref={ref}
            disableGoToSamples={disabledGoToSamples}
            floorPlanLocationPage={props.floorPlanLocationPage}
            onExport={(exportData) => exportFloorPlanToImage({...chartData, exportData}, props.floorPlanLocationPage, locations)}
            renderFloorPlan={() =>
                <div className='position-relative mt-3'>
                    <img src={src} onLoad={redraw} className='img-fluid d-print-block mx-auto'/>
                    <canvas
                        ref={setCanvas}
                        className='floor-plans-image'
                        width={floorPlan?.width}
                        height={floorPlan?.height}
                        onClick={handleClick}
                        onMouseMove={handleMouseMove}
                    />
                    {canvas && hoveredLocation &&
                        <LocationMenuInfo
                            canvas={canvas}
                            focusedFloorPlanLocation={hoveredLocation}
                            locations={locations}
                            chartType={LIMIT_BREACH_FLOOR_PLAN}
                        />
                    }
                </div>
            }
            renderTabularView={() => props.showTabularView &&
                <LimitBreachFloorPlanTabularView
                    floorPlanLocations={floorPlan?.locations ?? []}
                    series={chartData.series}
                    locations={locations}
                    selectedLocations={selectedLocations?.value ?? []}
                />
            }
        />
    )
}

function useLocationRenderer(canvas: HTMLCanvasElement | null, locations: FloorPlanLocation[], series: FloorPlanSeries[], selectedLocations: string[], allLocations: ListExposureLocation[]) {
    const [, redraw] = useReducer(() => ({}), {})

    useEffect(
        () => {
            if (!canvas)
                return

            const ctx = canvas.getContext('2d')!
            renderLimitBreachFloorPlan(canvas, ctx, locations, series, selectedLocations, allLocations)

            return () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height)
            }
        }
    )

    return redraw
}

function useClickHandler(locations: FloorPlanLocation[], selectedLocations: string[], series: FloorPlanSeries[], allLocations: ListExposureLocation[], onClickFloorPlanLocation: (locationId: string, breachType: BreachTypeWithCompromised | undefined, sampleInvestigationRefFlag: withRefFlag.WithRefFlag) => void) {
    function handleClick(event: React.MouseEvent<HTMLCanvasElement>): void {
        const xy = g.calcCanvasCoordinates(event)
            , ratio = g.plotScaleRatio(event.target as HTMLCanvasElement)
            , locationBreachTypePair = findLocation({ x: xy[0], y: xy[1] }, locations, series, selectedLocations, ratio, allLocations)

        if (!locationBreachTypePair)
            return

        const locationId = locationBreachTypePair[0].locationId
            , breachType = locationBreachTypePair[1]
            , withRefFlag = locationBreachTypePair[2]
            , location = locations.find(l => l.locationId === locationId)

        if (isLocationClickable(location, breachType, selectedLocations, allLocations))
            onClickFloorPlanLocation(locationId, breachType, withRefFlag)
    }

    return handleClick
}

function useMouseMove(floorPlanLocations: FloorPlanLocation[], selectedLocations: string[], series: FloorPlanSeries[], onMouseMoveOnFloorPlan: (location: FloorPlanLocation | undefined) => void, allLocations: ListExposureLocation[]) {
    return function (event: React.MouseEvent<HTMLCanvasElement>): void {
        const xy = g.calcCanvasCoordinates(event)
            , ratio = g.plotScaleRatio(event.target as HTMLCanvasElement)
            , locationBreachTypePair = findLocation({ x: xy[0], y: xy[1] }, floorPlanLocations, series, selectedLocations, ratio, allLocations)
            , location = locationBreachTypePair?.[0]
            , breachType = locationBreachTypePair?.[1]

        onMouseMoveOnFloorPlan(location)

        if (isLocationClickable(location, breachType, selectedLocations, allLocations))
            event.currentTarget.style.cursor = 'pointer'
        else
            event.currentTarget.style.cursor = 'default'
    }
}

function isLocationClickable(location: FloorPlanLocation | undefined, breachType: BreachTypeWithCompromised | undefined, selectedLocations: string[], allLocations: ListExposureLocation[]) {
    return location && breachType && !checkInactiveLocation(location, selectedLocations, allLocations)
}

export default forwardRef(LimitBreachFloorPlanChart)
