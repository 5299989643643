import { classnames } from '_/facade/react'
import UtcDateInput from '_/utils/input/utc-date-input'
import Button from '_/components/button'
import { CalendarButton } from '_/components/date-filter'

import { useTimeService } from './time'
import type { DateTime} from '_/model/date-time'
import { greaterThanOrEqual } from '_/model/date-time'

interface OwnProps {
    onChange: (date: DateTime | undefined) => void
    initialFilter: DateTime
    maxDate?: DateTime
    filterByMonth?: boolean
    buttonName?: string
}

const MONTH_CALENDAR_CONTAINER_WIDTH = 300

function SiteTimeZoneDateFilter(props: OwnProps) {
    const timeService = useTimeService()

    function getUtcPastDateBegin(date: DateTime): DateTime {
        const dayStart = timeService.utcDayStart(date)
        return props.filterByMonth
            ? timeService.addUtcMonths(dayStart, -1)
            : timeService.addUtcDays(dayStart, -1)
    }

    function castUtcDayStartFromCtzDay() {
        const unixTime = timeService.now()

        return timeService.castUtcDayStartFromCtzDay(unixTime)
    }

    function getUtcFutureDateBegin(date: DateTime): DateTime {
        const dayStart = timeService.utcDayStart(date)

        return props.filterByMonth
            ? timeService.addUtcMonths(dayStart, 1)
            : timeService.addUtcDays(dayStart, 1)
    }

    function handleChange(value: DateTime | '') {
        const newValue = value === '' ? undefined : value
        props.onChange(newValue)
    }

    return (
        <div className='d-flex'>
            <Button
                className='btn-light text-primary fw-bold me-1 pb-0'
                onClick={() => props.onChange(getUtcPastDateBegin(props.initialFilter))}
                testId='backward-date'
            >
                <i className='material-icons'>keyboard_arrow_left</i>
            </Button>
            <Button
                className='btn-light text-primary fw-bold me-1'
                onClick={() => props.onChange(castUtcDayStartFromCtzDay())}
                testId='today-date'
            >
                {props.buttonName || 'Today'}
            </Button>

            <div className={classnames('input-group', {'month-picker': props.filterByMonth})}>
                <UtcDateInput
                    id='date'
                    value={props.initialFilter}
                    onChange={handleChange}
                    max={props.maxDate}
                    isClearable={false}
                    customInput={<CalendarButton />}
                    isMonthPicker={props.filterByMonth}
                    calendarContainerWidth={props.filterByMonth ? MONTH_CALENDAR_CONTAINER_WIDTH : undefined}
                />
            </div>

            <Button
                className='btn-light text-primary fw-bold ms-2 pb-0'
                onClick={() => props.onChange(getUtcFutureDateBegin(props.initialFilter))}
                disabled={props.maxDate !== undefined && greaterThanOrEqual(timeService.utcDayStart(props.initialFilter), timeService.utcDayStart(props.maxDate))}
                testId='forward-date'
            >
                <i className='material-icons'>keyboard_arrow_right</i>
            </Button>
        </div>

    )
}

export default SiteTimeZoneDateFilter
