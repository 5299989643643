import { useSelector, useState, useAction, useEffect } from '_/facade/react'
import { actions as routerActions } from 'redux-router5'

import { PaginatedFooter } from '_/components/table'
import { useContextSwitchObserver } from '_/components/context-observer'
import SampleLocationTable from '_/components/sample-location-table'

import { dropFields } from '_/utils/object'

import { LIMIT_BREACH_OPERATOR_REPORT } from '_/model/reports/sample-report-type'

import type { PredefinedLists } from '_/model/app-state'
import type PaginationState from '_/model/pagination-state'
import type { OperatorsReport } from '_/model/reports/operators-report/types'
import { getSampleListParams } from '_/model/reports/operators-report/helpers'
import { paramsFilter } from '_/model/filters/helpers'
import type TimeService from '_/services/time-service'

import * as routes from '_/constants/routes'
import type * as t from '_/model/text/text'

import * as sampleActions from '_/features/samples/actions'
import TableActionButtons from '../table-action-buttons'
import { useTimeService } from '_/components/time'
import TableFooter from '../table-footer'
import FormattedText from '_/features/text/formatted-text'

interface Props {
    operatorName: t.Text
    operatorReport: OperatorsReport
    predefinedLists: PredefinedLists
    printMode: boolean
    itemsLimit: number
    entityName: string
    testId?: string
}

function LimitBreachSamples(props: Props) {
    const [pagination, setPagination] = useState({ start: 0, count: props.itemsLimit })
        , timeService = useTimeService()
        , [sampleList, showSpinner] = useSampleList(pagination, props.operatorReport, timeService)
        , navigateToLocationReport = useNavigateToLocationReport(props.operatorReport)
        , navigateToSampleReport = useNavigateToSampleReport()
        , exportLimitBreachesRelatedSampleData = useAction(sampleActions.exportOperatorLimitBreachesRelatedSampleData)
        , isTableEmpty = sampleList.list.items.length === 0

    useEffect(
        () => setPagination({ start: 0, count: props.itemsLimit }),
        [props.itemsLimit]
    )

    return (
        <div className='col-12'>
            <div className='block-border px-3'>
                <div className='my-4 text-center'>
                    <h4 data-testid='operators-report-subtitle'>Limit breaches for viable samples with <FormattedText text={props.operatorName} /> as operator</h4>
                    {props.printMode && sampleList.listStatistics.totalCount > sampleList.list.items.length &&
                        <h5 className='text-danger d-print-none'>{`Table in Print mode is limited to ${props.itemsLimit} records!`}</h5>
                    }
                </div>

                <SampleLocationTable
                    samples={sampleList.list.items}
                    sampleReportType={LIMIT_BREACH_OPERATOR_REPORT}
                    predefinedLists={props.predefinedLists}
                    onClickLocation={navigateToLocationReport}
                    onClickSampleName={navigateToSampleReport}
                    className='mt-2'
                    noDataMessage='No data available'
                    showSpinner={showSpinner}
                    footer={!props.printMode && !isTableEmpty &&
                        <PaginatedFooter
                            colSpan={9}
                            state={pagination}
                            onChange={setPagination}
                            totalCount={sampleList.listStatistics.totalCount}
                            actionButtons={
                                <TableActionButtons
                                    routeParams={getSampleListParams(props.operatorReport, timeService, true)}
                                    onExport={() => exportLimitBreachesRelatedSampleData({
                                        ...getSampleListParams(props.operatorReport, timeService, true),
                                        start: 0,
                                        count: props.itemsLimit,
                                        sort: 'exposureStartTime:asc',
                                        includeGeneratedByInfo: true,
                                    })}
                                    exportButtonDisabled={isTableEmpty}
                                    disabledGoToSamplesButton={isTableEmpty}
                                    testId={props.testId}
                                />
                            }
                        />
                    }
                    testId={props.testId}
                />
                {props.printMode && !isTableEmpty &&
                    <TableFooter entityName={props.entityName} report={props.operatorReport}/>
                }
            </div>
        </div>
    )
}

function useSampleList(pagination: PaginationState, operatorReport: OperatorsReport, timeService: TimeService) {
    const sampleList = useSelector(_ => _.samples)
        , load = useAction(sampleActions.loadSampleList)
        , loadStatistics = useAction(sampleActions.loadSampleListStatistics)
        , contextSwitch = useContextSwitchObserver()
        , [showSpinner, setShowSpinner] = useState(false)

    useEffect(
        () => {
            const query = {
                ...getSampleListParams(operatorReport, timeService, true),
                start: pagination.start,
                count: pagination.count,
                sort: 'exposureStartTime:asc',
            }

            setShowSpinner(true)
            Promise.all([load(query), loadStatistics(query)])
                .finally(() => setShowSpinner(false))
        },
        [load, loadStatistics, contextSwitch, pagination, operatorReport, timeService]
    )

    return [sampleList, showSpinner] as const
}

function useNavigateToLocationReport(operatorReport: OperatorsReport) {
    const navigateToAction = useAction(routerActions.navigateTo)
        , dateParams = dropFields(operatorReport, 'operatorId')

    return (locationId: string) => navigateToAction(routes.LOCATIONS_REPORT, paramsFilter({ locationId, ...dateParams }))
}

function useNavigateToSampleReport() {
    const navigateToAction = useAction(routerActions.navigateTo)

    return (sampleId: string) => navigateToAction(routes.SAMPLES_REPORT, { id: sampleId })
}

export default LimitBreachSamples
