import { Form, Field } from 'react-final-form'
import type { FormApi } from 'final-form'
import { React, classnames, useAction, useSelector, useState } from '_/facade/react'
import Button from '_/components/button'
import { submitDisabled } from '_/components/form'
import FormChangesObserver from '_/components/form/form-changes-observer'
import { showFieldError } from '_/components/form/helpers'
import type { ValidationResult } from '_/utils/form/validate'
import UserTagInput from '_/components/tag/user-tag-input'
import Error from '_/components/form/field-error'
import { validateComment } from '../validate'
import * as actions from '../actions'
import * as toastActions from '_/features/toasts/actions'
import * as sampleMessages from '../messages'
import CommentList from '../sample-edit/comment'
import formReset from '_/utils/form/reset'

const MESSAGE = 'message'
type MESSAGE = typeof MESSAGE

interface Comment {
    [MESSAGE]?: string
}

interface Props {
    sampleId: string
    hasNoPermissions: boolean
}

function CommentsForm(props: Props) {
    const comments = useSelector(_ => _.samples.sampleComments)
        , handleSubmit = useSubmit(props.sampleId)
        , [initialValue] = useState({})

    function validate(comment: Partial<Comment>): ValidationResult<Comment> {
        const message = validateComment(
                { message: comment.message || '' }
            )

        return message ? { message } : {}
    }

    return (
        <div>
            {!props.hasNoPermissions && <Form
                initialValues={initialValue}
                initialValuesEqual={(one, two) => one === two}
                onSubmit={handleSubmit}
                validate={validate}
                render={form =>
                    <form className='d-print-none mt-3'>
                        <FormChangesObserver form={form} target={MESSAGE} />

                        <div className='d-flex flex-nowrap align-items-start'>
                            <div className='flex-fill me-2'>
                                <Field name={MESSAGE} render={_ =>
                                    <>
                                        <UserTagInput
                                            className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                                            {..._.input}
                                            testId='sample-edit-comments'
                                        />
                                        <Error field={_} />
                                    </>
                                } />
                            </div>

                            <Button
                                className='btn-light material-icons md-24 blue-focus'
                                disabled={submitDisabled(form)}
                                onClick={form.handleSubmit}
                                testId='sample-edit-comments-send'
                            >
                                send
                            </Button>
                        </div>
                    </form>
                }
            />}
            <CommentList comments={comments}/>
        </div>
    )
}

export default CommentsForm

function useSubmit(sampleId: string) {
    const addSampleComments = useAction(actions.addSampleComments)
        , addSuccess = useAction(toastActions.addSuccess)

    function handleSubmit(comment: Comment, form: FormApi) {
        return addSampleComments({
                sampleId,
                sampleComments: [{ message: comment.message! }],
            })
            .then(() => addSuccess(sampleMessages.CHANGES_SAVED))
            .then(() => formReset(form))
    }

    return handleSubmit
}
