import type { FloorPlanLocation } from './floor-plan'

const PLOT_RADIUS = 15

function findLocation<T extends Pick<FloorPlanLocation, 'position'>>([x, y]: [number, number], locations: T[], ratio: number): T | undefined {
    return locations.find(loc => {
        const p = loc.position

        return Math.pow(x - p.x, 2) + Math.pow(y - p.y, 2) < Math.pow(PLOT_RADIUS * ratio, 2)
    })
}

function calcCanvasCoordinates(event: React.MouseEvent<HTMLCanvasElement>): [number, number] {
    const canvas = event.target as HTMLCanvasElement
        , domRect = canvas.getBoundingClientRect()
        , elementX = event.clientX - domRect.left - canvas.clientLeft
        , elementY = event.clientY - domRect.top - canvas.clientTop
        , x = Math.floor(elementX * canvas.width / domRect.width)
        , y = Math.floor(elementY * canvas.height / domRect.height)

    return [x, y]
}

function calcDomCoordinates(canvas: HTMLCanvasElement, location: Pick<FloorPlanLocation, 'position'>): [number, number] {
    const domRect = canvas.getBoundingClientRect()
        , x = domRect.left + canvas.clientLeft + Math.floor(location.position.x * domRect.width / canvas.width)
        , y = domRect.top + canvas.clientTop + Math.floor(location.position.y * domRect.height / canvas.height)

    return [x, y]
}

function plotScaleRatio(canvas: HTMLCanvasElement): number {
    const rect = canvas.getBoundingClientRect()
    return canvas.width / rect.width
}

export {
    PLOT_RADIUS,
    plotScaleRatio,
    findLocation,
    calcCanvasCoordinates,
    calcDomCoordinates,
}
