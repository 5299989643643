import type { State as RouterState } from 'router5'

import { useSelector, classnames } from '_/facade/react'
import * as routes from '_/constants/routes'

import TabNavbar from '_/components/tab-navbar'
import Link from '_/components/link'
import SampleBooking from './sample-booking'
import NonViableSampleView from '_/features/non-viable-samples/recording/non-viable-sample'

function Booking() {
    const permissions = useSelector(_ => _.auth.permissions)
        , route = useSelector(_ => _.router.route)
        , routePage = useRenderRoute(route)

    return (
        <div className='d-flex flex-column h-100'>
            <div className='d-flex flex-fill overflow-auto'>
                <div className='container-fluid main-block'>
                    <div className='row justify-content-center h-100'>
                        <div className='col-10 h-100 d-flex flex-column'>
                            <div className='page-header pt-3'>
                                <TabNavbar>
                                    <Link
                                        routeName={routes.SAMPLES_BOOKING}
                                        className={classnames('navbar-tab me-4', { active: route?.name === routes.SAMPLES_BOOKING })}
                                        hasNoPermissions={!permissions.bookSamples}
                                    >
                                        Book in viable samples
                                    </Link>
                                    <Link
                                        routeName={routes.NON_VIABLE_SAMPLES_RECORDING}
                                        className={classnames('navbar-tab', { active: route?.name === routes.NON_VIABLE_SAMPLES_RECORDING })}
                                    >
                                        Record non-viable samples
                                    </Link>
                                </TabNavbar>
                            </div>
                            {routePage}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Booking

function useRenderRoute(route: RouterState | null) {
    switch (route?.name) {
        case routes.SAMPLES_BOOKING:
            return <SampleBooking />

        case routes.NON_VIABLE_SAMPLES_RECORDING:
            return <NonViableSampleView />

        default:
            return null
    }
}
