
import { React, useAction, useEffect, useState, useSelector, classnames } from '_/facade/react'

import { useChanged, useSyncRef, useFilter, useResetOnContextSwitch } from '_/hooks/shared-hooks'

import { ITEMS_PER_PAGE } from '_/constants'
import { useContextSwitchObserver } from '_/components/context-observer'
import PageHeader from '_/components/page-header'
import Button from '_/components/button'
import { LinkButton } from '_/components/link'
import PlateTable from '_/features/plates/list/table'
import type PaginationState from '_/model/pagination-state'
import type Page from '_/model/page'
import type Plate from '_/model/plate/plate'
import type { Filter as PlateFilter } from '_/model/plate/plate-filter'

import { PLATES_EXPORT } from '_/constants/routes'

import * as userActions from '_/features/users/actions'
import * as actions from '../actions'

import Filter from './filter'
import PlateExportModal from './export-modal'

function PlateList() {
    const [pagination, setPagination] = useFilter<PaginationState>('plates-pagination', (params = {}) => ({ start: 0, count: ITEMS_PER_PAGE, ...params }))
        , [filter, setFilter] = useFilter<PlateFilter>('plates-filter', (params = {}) => params)
        , [plates, showSpinner] = usePlates(pagination, filter)
        , [showFilter, setShowFilter] = useState(!isEmpty())
        , users = useUsers()
        , permissions = useSelector(_ => _.auth.permissions)
        , routeName = useSelector(_ =>_.router.route?.name)
        , exportMode = routeName === PLATES_EXPORT

    function isEmpty() {
        return Object.values(filter).every(_ => _ === undefined)
    }

    useBarcodeSearchObserver(handleSearchByBarcode)
    useResetOnContextSwitch(resetFilter)

    function handleFilterChange(filter: PlateFilter) {
        setFilter(filter)
        setPagination({ start: 0, count: ITEMS_PER_PAGE })
    }

    function handleSearchByBarcode(filter: PlateFilter) {
        setFilter(filter)
        setShowFilter(true)
    }

    function resetFilter() {
        handleFilterChange({})
    }

    return (
        <div className='position-relative h-100'>
            {exportMode &&
                <PlateExportModal totalCount={plates.totalCount} query={filter} />
            }
            <Filter
                onChange={handleFilterChange}
                filter={filter}
                showFilter={showFilter}
                onClose={() => setShowFilter(false)}
                onClearFilter={resetFilter}
                users={users}
            />
            <div className='container-fluid h-100'>
                <div className="row justify-content-center h-100">
                    <div className="col-9 d-flex flex-column h-100">
                        <PageHeader title="Plates">
                            <div>
                                <Button
                                    className='btn-link'
                                    onClick={() => setShowFilter(!showFilter)}
                                >
                                    <i className='material-icons align-middle me-1'>tune</i>
                                    <span>Filters</span>
                                </Button>
                                <LinkButton
                                    className={classnames('btn-link me-1', { disabled: !permissions.exportData })}
                                    routeName={PLATES_EXPORT}
                                    hasNoPermissions={!permissions.exportData}
                                >
                                    <i className='material-icons align-middle me-1'>get_app</i>
                                    <span>Export</span>
                                </LinkButton>
                            </div>
                        </PageHeader>

                        <PlateTable
                            list={plates.items}
                            totalCount={plates.totalCount}
                            pagination={pagination}
                            handlePaginationChange={setPagination}
                            showSpinner={showSpinner}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

function usePlates(pagination: PaginationState, filter: PlateFilter) {
    const loadPlates = useAction(actions.loadPlates)
        , [plates, setPlates] = useState<Page<Plate>>({ items: [], totalCount: 0 })
        , [showSpinner, setShowSpinner ] = useState<boolean>(false)

    useEffect(
        () => {
            let disposed = false

            const query = { ...pagination, ...filter }

            setShowSpinner(true)
            loadPlates(query)
                .then(_ => {
                    if (disposed)
                        return

                    setPlates(_)
                })
                .finally(() => setShowSpinner(false))

            return () => { disposed = true }
        },
        [pagination, filter, loadPlates, setPlates, setShowSpinner]
    )

    return [plates, showSpinner] as const
}

export default PlateList

const UNREALISTIC_USER_COUNT = 1000

function useUsers() {
    const loadUsers = useAction(userActions.loadUserList)
        , users = useSelector(_ => _.users.list.items)
        , canReadUsers = useSelector(_ => _.auth.permissions.readUsers)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            if (canReadUsers)
                loadUsers({ start: 0, count: UNREALISTIC_USER_COUNT, sort: 'name:asc' })
        },
        [canReadUsers, contextSwitch, loadUsers]
    )

    return users
}

function useBarcodeSearchObserver(onSearchByBarcode: (searchFields: PlateFilter) => void) {
    const filter = useSelector(_ => _.router.route?.params.filter)
        , filterChanged = useChanged(filter)
        , handleNotify = useSyncRef(onSearchByBarcode)

    useEffect(
        () => {
            if (!(filterChanged && filter))
                return

            handleNotify.current({ barcode: filter.barcode })
        },
        [filterChanged, filter, handleNotify]
    )
}
