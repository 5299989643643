import { actions as routerActions } from 'redux-router5'
import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import type { FormApi } from 'final-form'
import { React, forwardRef, useRef, useState, useImperativeHandle, useEffect, useAction, useSelector } from '_/facade/react'

import FormChangesObserver from '_/components/form/form-changes-observer'
import Button from '_/components/button'
import { submitDisabled } from '_/components/form'
import { useTimeService } from '_/components/time'

import type { PredefinedLists } from '_/model/app-state'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type { ViableSampleBookingFormSubmitProps, ViableSampleBookingForm } from '_/model/sample/booking/types'
import { IN_OPERATION } from '_/model/predefined-lists/action-alert-limit/monitoring-state'

import { noop } from '_/utils/function'
import { getBookableLocations } from '_/utils/exposure-location'
import { useDebounce } from '_/hooks/shared-hooks'

import { SAMPLES_EDIT } from '_/constants/routes'
import * as fieldIndex from '_/constants/custom-field-index'
import { showGenericConfirmationModal } from '_/features/confirmation/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import * as actions from '../actions'
import * as sa from '_/features/spinner/actions'
import * as h from '../helpers'
import { subLocationList, canEditExposureTime, isValueChanged, resetViableForm } from './helpers'
import validate from './validate'

import AddCommentsIcon from './sample-add-comments-icon'
import SampleBookingCustomFields from './sample-booking-form-custom-fields'
import SampleCommentsModal from './sample-comments-modal'

interface Props {
    exposureLocations: ListExposureLocation[]
    predefinedLists: PredefinedLists
    initialValue: ViableSampleBookingForm
    onSubmit: (_: Partial<ViableSampleBookingFormSubmitProps>) => Promise<void>
    formTarget: string
    isEditScreen?: boolean
}

export interface ResetFocusFormRef {
    resetFocus(): void
}

function SampleBookingFormComponent(props: Props, ref: React.ForwardedRef<ResetFocusFormRef>) {
    const editScreenPrefix = props.isEditScreen ? 'edit-' : ''
        , timeService = useTimeService()
        , [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false)
        , [comments, setComments] = useState<string[]>([])
        , [compromised, setCompromised] = useState(false)
        , [hideOptionalDate, setHideOptionalDate] = useState(false)
        , [getRef, setRef, resetFocus] = useFieldRefsHandler(ref)
        , customFields = props.predefinedLists.customFields
        , [previousFields, verifyingBarcode, locationChildren, setLocationChildren, handleChangeFormValues] = useFormValuesChangeHandler(
            props.initialValue,
            props.exposureLocations,
            props.predefinedLists,
            props.formTarget,
            props.isEditScreen,
            hideOptionalDate,
            setHideOptionalDate
        )
        , handleSubmit = useSubmit(comments, compromised, props.formTarget, reset, props.onSubmit)

    useMonitoringPositionEditModalUpdater(props.initialValue, previousFields, props.exposureLocations, setLocationChildren)

    function handleAddComment(comment: string, compromised: boolean) {
        setComments([comment].concat(comments))
        setCompromised(compromised)
    }

    function reset(sample: Partial<ViableSampleBookingForm>, form: FormApi) {
        const endTimeField = props.predefinedLists.customFields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)
        resetViableForm(form, sample, props.predefinedLists)

        resetFocus()
        setComments([])
        setHideOptionalDate(!endTimeField?.viableSettings.persisted)
    }

    return (
        <Form<Partial<ViableSampleBookingForm>>
            onSubmit={(value, form) => handleSubmit(value, form)}
            initialValues={props.initialValue}
            validate={_ => validate(timeService, _, props.predefinedLists)}
            render={form =>
                <form onSubmit={form.handleSubmit} autoComplete='off'>
                    <FormChangesObserver form={form} onChange={handleChangeFormValues} target={props.formTarget} />
                    {<div className='col-12 px-3 d-flex flex-wrap sample-booking__form--persistent-fields-bg border-top border-white clearfix' data-testid='persistent-area'>
                        {customFields.map((_, i) => _.viableSettings.persisted &&
                            <SampleBookingCustomFields
                                key={i}
                                pos={i}
                                form={form}
                                initialValue={props.initialValue}
                                exposureLocations={props.exposureLocations}
                                predefinedLists={props.predefinedLists}
                                fieldPrefix={editScreenPrefix}
                                hideOptionalDate={hideOptionalDate}
                                locationChildren={locationChildren}
                                getRef={getRef}
                                setRef={setRef}
                            />
                        )}
                    </div>}
                    <div className='col-12 px-3 d-flex flex-wrap bg-light border-top border-white clearfix' data-testid='not-persistent-area'>
                        {customFields.map((_, i) => !_.viableSettings.persisted &&
                            <SampleBookingCustomFields
                                key={i}
                                pos={i}
                                form={form}
                                initialValue={props.initialValue}
                                exposureLocations={props.exposureLocations}
                                predefinedLists={props.predefinedLists}
                                fieldPrefix={editScreenPrefix}
                                hideOptionalDate={hideOptionalDate}
                                locationChildren={locationChildren}
                                getRef={getRef}
                                setRef={setRef}
                            />
                        )}
                        {!props.isEditScreen &&
                            <div className='mb-3 mx-4'>
                                <label className='col-form-label'>&nbsp;</label>
                                <AddCommentsIcon
                                    numberOfComments={comments.length}
                                    onClick={() => setCommentsModalIsOpen(true)}
                                    className='pt-1'
                                />
                            </div>
                        }
                        {commentsModalIsOpen &&
                            <SampleCommentsModal
                                onClose={() => setCommentsModalIsOpen(false)}
                                onAddComment={handleAddComment}
                                comments={comments}
                                compromised={compromised}
                            />
                        }
                        <div className='mb-3 float-end'>
                            <label className='col-form-label'></label>
                            <div style={{marginTop: '12px'}}>
                                <Button
                                    onClick={form.form.submit}
                                    disabled={submitDisabled(form) || verifyingBarcode}
                                    className='btn-primary'
                                    testId={props.isEditScreen ? 'booking-edit' : 'booking-create'}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        />
    )
}

function useSubmit(
    comments: string[],
    compromised: boolean,
    formTarget: string,
    onFormReset: (_: Partial<ViableSampleBookingForm>, form: FormApi) => void,
    onSubmit: (_: Partial<ViableSampleBookingFormSubmitProps>) => Promise<void>
) {
    const hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , showSpinner = useAction(sa.showSpinner)
        , hideSpinner = useAction(sa.hideSpinner)

    function handleSubmit(sample: Partial<ViableSampleBookingForm>, form: FormApi) {
        const sortedComments = comments.slice().reverse()
            , sampleBooking = { ...sample, comments: sortedComments, compromised }

        const result = onSubmit(sampleBooking).then(noop)

        showSpinner()
        result
            .then(() => onFormReset(sample, form))
            .then(() => hasUnsavedChanges(false, formTarget))
            .finally(hideSpinner)

        return result
    }

    return handleSubmit
}

function useFormValuesChangeHandler(
    initialValues: ViableSampleBookingForm,
    exposureLocations: ListExposureLocation[],
    predefinedLists: PredefinedLists,
    formTarget: string,
    isEditScreen: boolean | undefined,
    hideOptionalDate: boolean,
    setHideOptionalDate: (_: boolean) => void
) {
    const [lastCustomFields, setLastCustomFields] = useState(predefinedLists.customFields)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , unsavedChangeTargets = useSelector(_ => _.unsavedChange.unsavedChangeTargets)
        , [verifyingBarcode, setVerifyingBarcode] = useState(false)
        , [locationChildren, setLocationChildren] = useState<ReturnType<typeof subLocationList>>([])
        , prevValues = useRef(initialValues)
        , verifyBarcodeDuplication = useDuplicateBarcodeVerification()

    useEffect(
        () => {
            if (lastCustomFields === predefinedLists.customFields)
                return

            setLastCustomFields(predefinedLists.customFields)
            prevValues.current = initialValues
        },
        [lastCustomFields, predefinedLists.customFields, initialValues]
    )

    function handleChangeFormValues(values: ViableSampleBookingForm, form: FormRenderProps) {
        const fields = prevValues.current.fields

        form.form.batch(() => {
            const notRecordedValuesToClear = h.findNotRecordedFilledValues(values.fields)
            notRecordedValuesToClear.forEach(_ => form.form.change(h.valuePath(_), undefined))

            const location = h.findFieldValue(fields, values.fields, fieldIndex.EXPOSURE_LOCATION_ID)
                , monitoringPosition = h.findFieldValue(fields, values.fields, fieldIndex.MONITORING_POSITION)
                , monitoringPositionPos = h.findFieldValuePos(fields, fieldIndex.MONITORING_POSITION)

            if (monitoringPosition?.notRecorded !== location?.notRecorded) {
                // note: when location is set notRecorded position is also set notRecorded
                // but is cleared on next handleChangeFormValues triggered by form.change call below
                form.form.change(h.notRecordedPath(monitoringPositionPos), location?.notRecorded)
            }

            if (!location?.notRecorded && isValueChanged(location, fields)) {
                form.form.change(h.valuePath(monitoringPositionPos), undefined)
                form.form.change('monitoringState', IN_OPERATION)

                setLocationChildren(subLocationList(getBookableLocations(exposureLocations), location?.value))
            }

            const barcode = h.findFieldValue(fields, values.fields, fieldIndex.BARCODE)
            if (isValueChanged(barcode, fields)) {
                const barcodePosition = h.findFieldValuePos(fields, fieldIndex.BARCODE)
                    , onDuplication = () => form.form.change(h.valuePath(barcodePosition), undefined)
                    , onComplete = () => setVerifyingBarcode(false)

                if (!verifyingBarcode)
                    setVerifyingBarcode(true)

                verifyBarcodeDuplication(barcode?.value ?? '', onDuplication, onComplete)
            }

            const sampleType = h.findFieldValue(fields, values.fields, fieldIndex.SAMPLE_TYPE_ID)
                , startDate = h.findFieldValue(fields, values.fields, fieldIndex.EXPOSURE_START_DATE)?.value
            if (isValueChanged(sampleType, fields)) {
                const endTimeField = predefinedLists.customFields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)
                    , canEdit = canEditExposureTime(sampleType?.value, predefinedLists.sampleTypes, endTimeField?.viableSettings.required ?? false)

                if (!canEdit) {
                    const timePos = h.findFieldValuePos(fields, fieldIndex.EXPOSURE_END_TIME)
                        , endDatePos = h.findFieldValuePos(fields, fieldIndex.EXPOSURE_END_DATE)

                    form.form.change(h.valuePath(timePos), undefined)
                    form.form.change(h.notRecordedPath(timePos), undefined)
                    form.form.change(h.valuePath(endDatePos), startDate)
                }
            }

            if (h.findFieldValue(fields, values.fields, fieldIndex.EXPOSURE_END_TIME)?.notRecorded)
                form.form.change(h.valuePath(h.findFieldValuePos(fields, fieldIndex.EXPOSURE_END_DATE)), startDate)
        })

        const endDate = h.findFieldValue(fields, values.fields, fieldIndex.EXPOSURE_END_DATE)
        if (isValueChanged(endDate, fields) && hideOptionalDate)
            setHideOptionalDate(false)

        if (!isEditScreen && !unsavedChangeTargets.some(_ => _ === formTarget))
            hasUnsavedChanges(true, formTarget)

        prevValues.current = values
    }

    return [prevValues.current.fields, verifyingBarcode, locationChildren, setLocationChildren, handleChangeFormValues] as const
}

function useDuplicateBarcodeVerification() {
    const debounce = useDebounce()
        , loadByBarcode = useAction(actions.loadSampleByBarcode)
        , showBarcodeDuplicateWarning = useAction(showGenericConfirmationModal)
        , navigateTo = useAction(routerActions.navigateTo)
        , info = h.getDuplicatedBarcodeConfirmationInfo()

    function verifyBarcodeDuplication(barcode: string, onDuplication: () => void, onComplete: () => void) {
        debounce(() => loadByBarcode({ barcode, includeNullified: true, matchExactly: true })
            .then(sample => {
                if (!sample)
                    return Promise.resolve()

                return Promise.resolve()
                    .then(onDuplication)
                    .then(() => showBarcodeDuplicateWarning(info))
                    .then(() => navigateTo(SAMPLES_EDIT, { id: sample.id }))
            })
            .finally(onComplete)
        )
    }

    return verifyBarcodeDuplication
}

function useMonitoringPositionEditModalUpdater(
    initialValue: ViableSampleBookingForm,
    previousFields: FieldValues[],
    exposureLocations: ListExposureLocation[],
    setLocationChildren: (_: ReturnType<typeof subLocationList>) => void,
) {
    const initialMount = useRef(true)

    useEffect(
        () => {
            if (initialValue.fields.length === 0 || !initialMount.current)
                return

            initialMount.current = false

            const location = h.findFieldValue(previousFields, initialValue.fields, fieldIndex.EXPOSURE_LOCATION_ID)
            setLocationChildren(subLocationList(getBookableLocations(exposureLocations), location?.value))
        },
        [initialValue.fields, previousFields, exposureLocations, setLocationChildren]
    )
}

function useFieldRefsHandler(ref: React.ForwardedRef<ResetFocusFormRef>) {
    const thisRef = useRef(resetFocus)
        , fieldsRefs = useRef(new Map<string, React.RefObject<HTMLInputElement>>())

    useImperativeHandle(ref, () => ({
        resetFocus: () =>
            thisRef.current(),
    }))

    function resetFocus() {
        getRef(`${'field-' + fieldIndex.BARCODE}`)?.current?.focus()
    }

    function getRef(key: string) {
        return fieldsRefs.current.get(key)
    }

    function setRef(key: string) {
        fieldsRefs.current.set(key, React.createRef<HTMLInputElement>())
    }

    return [getRef, setRef, resetFocus] as const
}

export default forwardRef(SampleBookingFormComponent)
