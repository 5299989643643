import { React } from '_/facade/react'

import { ACTION_LIMIT, ALERT_LIMIT, COMPROMISED } from '_/constants/sample-breach-type'

import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { LimitBreachReport } from '_/model/reports/limit-breach-report/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import LimitBreachHintItem from './limit-breach-hint-item'
import type { DateTime} from '_/model/date-time'

interface OwnProps {
    data: LimitBreachReport
    lastDateInTimeline: DateTime
    customFields: CustomField[]
    locations: ListExposureLocation[]
}

function LimitBreachHint(props: OwnProps) {
    const containerRef = React.createRef<HTMLDivElement>()

    return (
        <div ref={containerRef} className='legend-bubble py-1 bg-dark'>
            {props.data.viableActionLimitBreached.map((_, index) =>
                <LimitBreachHintItem sampleData={_} breachType={ACTION_LIMIT} key={index} customFields={props.customFields} locations={props.locations} />
            )}
            {props.data.viableAlertLimitBreached.map((_, index) =>
                <LimitBreachHintItem sampleData={_} breachType={ALERT_LIMIT} key={index} customFields={props.customFields} locations={props.locations} />
            )}
            {props.data.nonViableActionLimitBreached.map((_, index) =>
                <LimitBreachHintItem sampleData={_} breachType={ACTION_LIMIT} key={index} customFields={props.customFields} locations={props.locations} isNonViable />
            )}
            {props.data.nonViableAlertLimitBreached.map((_, index) =>
                <LimitBreachHintItem sampleData={_} breachType={ALERT_LIMIT} key={index} customFields={props.customFields} locations={props.locations} isNonViable />
            )}
            {props.data.compromised.map((_, index) =>
                <LimitBreachHintItem sampleData={_} breachType={COMPROMISED} key={index} customFields={props.customFields} locations={props.locations} />
            )}
        </div>
    )
}

export default LimitBreachHint
