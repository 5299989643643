import { classnames, React, useAction, useEffect, useSelector, useState } from '_/facade/react'
import * as routes from '_/constants/routes'
import type { PlateSearchByBarcodeResult } from '_/model/plate/plate'
import type Plate from '_/model/plate/plate'
import { useDebounce, useSyncRef } from '_/hooks/shared-hooks'
import * as v from '_/utils/form/validate'
import { observeBarcodeScanner } from '_/features/samples/scanner/helpers'

import { CreateRecordBtn, PossibleMatchesLink, GoToRecordBtn } from './navigation-btn'

import * as a from '../../actions'
import * as ra from '_/features/routing/actions'

interface Props {
    onLoad: (_: Plate) => void
}

function PlateSearch(props: Props) {
    const [barcode, setBarcode] = useState('')
        , canEdit = useSelector(_ => _.auth.permissions.performColonyCounterPlateReading)
        , searchState = useSearchState(barcode)
        , searchResult = searchState.tag === 'match' ? searchState.searchResult : undefined
        , handleCreate = useCreateHandler(props.onLoad)

    useScanner(props.onLoad, handleCreate)
    useRouteParameter(props.onLoad)

    function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Tab' && (e.target as any).value !== '')
            e.preventDefault()
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        if (searchState.tag !== 'match')
            return

        if (searchState.searchResult.exactMatch)
            props.onLoad(searchState.searchResult.exactMatch)
        else if (canEdit)
            handleCreate(searchState.barcode)
    }

    const plate = searchResult?.exactMatch
        // exclude exact match itself
        , partialMatches = searchResult ? searchResult.possibleMatchesCount - (plate ? 1 : 0) : undefined

    return (
        <div className='p-4'>
            <form className='position-relative' onSubmit={handleSubmit}>
                <input
                    className={classnames('form-control', { 'is-invalid': searchState.tag === 'error' })}
                    type='search'
                    aria-label='Search'
                    value={barcode}
                    onChange={_ => setBarcode(_.target.value)}
                    onKeyDown={handleKeyPress}
                    autoFocus
                />
                {barcode === '' && <i className='search-icon material-icons'>search</i>}
                {searchState.tag === 'error' && <span className='invalid-feedback'>{searchState.error}</span>}
            </form>

            <div className='py-2 text-center'>
                {searchState.tag === 'match' &&
                    <>
                        {!plate && partialMatches === 0 &&
                            <>
                                The barcode you entered was not found.
                                {canEdit &&
                                    <div>
                                        <CreateRecordBtn onCreate={() => handleCreate(searchState.barcode)} />
                                    </div>
                                }
                            </>
                        }

                        {!plate && partialMatches! > 0 &&
                            <>
                                This search matches {partialMatches} possible plates. You can
                                {canEdit &&
                                    <>
                                        <CreateRecordBtn onCreate={() => handleCreate(searchState.barcode)} />
                                        or
                                    </>
                                }
                                <PossibleMatchesLink barcode={barcode} />.
                            </>
                        }

                        {plate && partialMatches! > 0 &&
                            <>
                                This search matches
                                <GoToRecordBtn onLoad={() => props.onLoad(plate)} text='1 exact plate'/>
                                and
                                <PossibleMatchesLink barcode={barcode} text={`${partialMatches! + 1} possible plates`}/>.
                            </>
                        }

                        {plate && partialMatches === 0 &&
                            <GoToRecordBtn onLoad={() => props.onLoad(plate)} />
                        }
                    </>
                }

                {searchState.tag === 'empty' && 'Scan barcode or provide a unique ID to create a record'}
            </div>
        </div>
    )
}

export default PlateSearch

type SearchState =
    | { tag: 'empty' }
    | { tag: 'error', error: string }
    | { tag: 'match', barcode: string, searchResult: PlateSearchByBarcodeResult }

function useSearchState(barcode: string) {
    const debounce = useDebounce()
        , searchPlates = useAction(a.searchPlatesByBarcode)
        , [searchState, setSearchState] = useState<SearchState>({ tag: 'empty' })

    useEffect(
        () => {
            let disposed = false

            debounce(() => {
                const minLength = v.minLengthForSearch(4, 'barcode')(barcode)
                    , maxLength = v.maxLength('Barcode', 200)(barcode)

                if (maxLength || minLength) {
                    setSearchState({ tag: 'error', error: maxLength || 'Enter at least four characters to search for a barcode' })
                    return
                }

                if (barcode === '') {
                    setSearchState({ tag: 'empty' })
                    return
                }

                searchPlates(barcode).then(searchResult => {
                    if (disposed)
                        return

                    setSearchState({ tag: 'match', barcode, searchResult })
                })
            })

            return () => { disposed = true }
        },
        [barcode, debounce, searchPlates]
    )

    return searchState
}

function useCreateHandler(onLoad: (_: Plate) => void) {
    const createPlate = useAction(a.createPlate)
        , searchPlates = useAction(a.searchPlatesByBarcode)

    function handleCreate(barcode: string) {
        createPlate({ barcode })
            .then(_ => searchPlates(barcode))
            .then(_ => onLoad(_.exactMatch!))
    }

    return handleCreate
}

function useScanner(onLoad: (_: Plate) => void, onCreate: (_: string) => void) {
    const searchPlates = useAction(a.searchPlatesByBarcode)
        , callbacks = useSyncRef({ onLoad, onCreate })

    useEffect(
        () => {
            return observeBarcodeScanner(
                barcode => {
                    searchPlates(barcode).then(result => {
                        if (result.exactMatch)
                            callbacks.current.onLoad(result.exactMatch)
                        else
                            callbacks.current.onCreate(barcode)
                    })
                },
                true
            )
        },
        [callbacks, searchPlates]
    )
}

function useRouteParameter(onLoad: (_: Plate) => void) {
    const searchPlates = useAction(a.searchPlatesByBarcode)
        , loadPlate = useAction(a.loadPlate)
        , routeParams = useSelector(_ => _.router.route?.params)
        , { id, barcode } = routeParams ?? {}
        , handleLoad = useSyncRef(onLoad)
        , navigateTo = useAction(ra.navigateTo)

    useEffect(
        () => {
            function handlePlateLoaded(plate: Plate) {
                navigateTo(routes.PLATES_READING, undefined, { replace: true })
                handleLoad.current(plate)
            }

            if (id) {
                loadPlate(id).then(handlePlateLoaded)
                return
            }

            if (!barcode)
                return

            searchPlates(barcode).then(_ => {
                if (_.exactMatch)
                    handlePlateLoaded(_.exactMatch)
            })
        },
        [barcode, id, handleLoad, navigateTo, searchPlates, loadPlate]
    )
}
