function downloadBlob(blob: Blob, filename: string): void {
    if ((window.navigator as any).msSaveBlob) {
        (window.navigator as any).msSaveBlob(blob, filename)
        return
    }

    const blobUrl = window.URL.createObjectURL(blob)
        , anchor = document.createElement('a')

    anchor.href = blobUrl
    anchor.download = filename

    document.body.appendChild(anchor)
    anchor.click()

    window.setTimeout(
        function () {
            document.body.removeChild(anchor)
            window.URL.revokeObjectURL(blobUrl)
        },
        100
    )
}

function imageDataToBlob(imageData: string, type: string) {
    const data = atob(imageData.split(',')[1])
        , array = new Uint8Array(data.length).map((_, i) => data.charCodeAt(i))

    return new Blob([array.buffer], { type })
}

export { downloadBlob, imageDataToBlob }
