import { useState, useEffect, useAction, classnames, useCallback, useSelector } from '_/facade/react'

import { Table } from '_/components/table'
import Button from '_/components/button'
import { useContextSwitchObserver } from '_/components/context-observer'

import type { Device } from '_/model/predefined-lists/devices/types'
import * as d from '_/model/predefined-lists/devices/device-type'
import * as s from '_/model/predefined-lists/devices/status'

import * as helpers from '_/model/users/helpers'
import * as actions from '_/features/predefined-lists/devices/actions'
import type { ElectronicSignatureSettings } from '_/model/context/electronic-signature-settings'

import DeviceForm from './device-form'

interface Props {
    id: string
    signatureSettings: ElectronicSignatureSettings[]
    onReloadTrail: () => void
}

function Devices(props: Props) {
    const [editIndex, setEditIndex] = useState<number | undefined>()
        , [devices, reload] = useDevices(props.id)
        , user = useSelector(_ => _.auth.user)
        , isSystemAdmin = user && helpers.isSystemAdmin(user)

    function cancelEdit(index: number, id: string | undefined) {
        if (index + 1 === devices.length && !id)
            devices.pop()
        setEditIndex(undefined)
    }

    return (
        <>
            <Table className='table-layout-fixed'>
                <thead className='thead'>
                    <tr className='table-header-thin'>
                        <th style={{width: '100%'}}>Device</th>
                        <th style={{width: '8em'}}>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {devices.length === 0
                        ? <tr>
                            <td colSpan={2}>There are no devices added in the site</td>
                        </tr>
                        : devices.map((device, index) => index === editIndex
                            ? <DeviceForm
                                devices={devices}
                                device={device}
                                contextId={props.id}
                                isSystemAdmin={!!isSystemAdmin}
                                signatureSettings={props.signatureSettings}
                                onCancelEdit={() => cancelEdit(index, device.id)}
                                onReload={reload}
                                onReloadTrail={props.onReloadTrail}
                                key={index+'edit'}
                            />
                            : <tr key={index}>
                                <td>
                                    <div className='d-flex'>
                                        <Button
                                            onClick={() => setEditIndex(index)}
                                            className={classnames('px-1 border-0 d-print-none')}
                                            disabled={editIndex !== undefined}
                                        >
                                            <i className='sample-edit__edit-icon material-icons text-primary md-18'>create</i>
                                        </Button>
                                        <div className='flex-grow-1 min-width-0 ms-2'>
                                            <div className='single-line-text' title={device.name}>{device.name}</div>
                                            <div className='text-muted'>
                                                {/*todo remove when DEVICE_TYPE would contain more than one element*/}
                                                {/*eslint-disable-next-line @typescript-eslint/no-unnecessary-condition*/}
                                                {d.DEVICE_TYPE.find(_ => _.id === device.type)?.name} {isSystemAdmin && device.deviceId}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className='align-bottom'>
                                    <span className={classnames('fw-bold', {
                                        'text-warning': device.status === s.OFFLINE,
                                        'text-success': device.status === s.ONLINE,
                                        'text-muted': device.status === s.INACTIVE,
                                    })}>
                                        {s.DEVICE_STATUS.find(_ => _.id === device.status)?.name}
                                    </span>
                                </td>
                            </tr>
                    )}
                </tbody>
            </Table>
            {isSystemAdmin &&
                <Button
                    className='btn-primary d-print-none me-1'
                    disabled={editIndex !== undefined}
                    onClick={() => {
                        devices.push({type: d.PHOTO_BOOTH} as Device)
                        setEditIndex(devices.length - 1)
                    }}
                >
                    Add a device
                </Button>
            }
        </>
    )
}

export default Devices

function useDevices(id: string) {
    const loadDevices = useAction(actions.loadDevices)
        , [devices, setDevices] = useState<Device[]>([])
        , contextSwitch = useContextSwitchObserver()
        , load = useCallback(
            () => {
                loadDevices(id).then(setDevices)
            },
            [id, loadDevices]
        )

    useEffect(load, [contextSwitch, load])

    return [devices, load] as const
}
