import { useSelector, useState, useAction, useEffect } from '_/facade/react'

import { useTimeService } from '_/components/time'
import { PaginatedFooter } from '_/components/table'
import { useContextSwitchObserver } from '_/components/context-observer'
import SampleLocationTable from '_/components/sample-location-table'

import { LIMIT_BREACH_LOCATION_REPORT } from '_/model/reports/sample-report-type'

import type { PredefinedLists } from '_/model/app-state'
import type SampleSearchFields from '_/model/sample/search'
import type PaginationState from '_/model/pagination-state'
import type { LocationsReport } from '_/model/reports/locations-report/types'
import { getSampleListParams } from '_/model/reports/locations-report/helpers'

import * as sampleActions from '_/features/samples/actions'
import TableActionButtons from '../table-action-buttons'
import TableFooter from '../table-footer'
import FormattedText from '_/features/text/formatted-text'
import type * as t from '_/model/text/text'

interface Props {
    locationName: t.Text
    locationReport: LocationsReport
    predefinedLists: PredefinedLists
    printMode: boolean
    itemsLimit: number
    entityName: string
    routeParams: SampleSearchFields
    onLocationClick: (locationId: string) => void
    testId?: string
}

function LimitBreachSamples(props: Props) {
    const [pagination, setPagination] = useState({ start: 0, count: props.itemsLimit })
        , [sampleList, showSpinner] = useSampleList(pagination, props.locationReport)
        , exportLimitBreachesRelatedSampleData = useAction(sampleActions.exportLimitBreachesRelatedSampleData)
        , isTableEmpty = sampleList.list.items.length === 0

    useEffect(
        () => setPagination({ start: 0, count: props.itemsLimit }),
        [props.itemsLimit]
    )

    return (
        <div className='col-12'>
            <div className='block-border px-3'>
                <div className='my-4 text-center'>
                    <h4 data-testid='locations-report-subtitle'>
                        Information about limit breaches involving <FormattedText text={props.locationName} /> and all its child locations
                    </h4>
                    {props.printMode && sampleList.listStatistics.totalCount > sampleList.list.items.length &&
                        <h5 className='text-danger d-print-none' data-testid='print-mode-1000-records-warning'>{`Table in Print mode is limited to ${props.itemsLimit} records!`}</h5>
                    }
                </div>

                <SampleLocationTable
                    samples={sampleList.list.items}
                    sampleReportType={LIMIT_BREACH_LOCATION_REPORT}
                    predefinedLists={props.predefinedLists}
                    onClickLocation={props.onLocationClick}
                    className='mt-2'
                    noDataMessage='No data available'
                    showSpinner={showSpinner}
                    footer={!props.printMode && !isTableEmpty &&
                        <PaginatedFooter
                            colSpan={9}
                            state={pagination}
                            onChange={setPagination}
                            totalCount={sampleList.listStatistics.totalCount}
                            actionButtons={
                                <TableActionButtons
                                    routeParams={props.routeParams}
                                    onExport={() => exportLimitBreachesRelatedSampleData({
                                        ...props.routeParams,
                                        start: 0,
                                        count: props.itemsLimit,
                                        sort: 'exposureStartTime:asc',
                                        includeGeneratedByInfo: true,
                                    })}
                                    exportButtonDisabled={isTableEmpty}
                                    disabledGoToSamplesButton={isTableEmpty}
                                    testId={props.testId}
                                />
                            }
                        />
                    }
                    testId={props.testId}
                />
                {props.printMode && !isTableEmpty &&
                    <TableFooter entityName={props.entityName} report={props.locationReport} />
                }
            </div>
        </div>
    )
}

function useSampleList(pagination: PaginationState, locationReport: LocationsReport) {
    const sampleList = useSelector(_ => _.samples)
        , load = useAction(sampleActions.loadSampleList)
        , loadStatistics = useAction(sampleActions.loadSampleListStatistics)
        , contextSwitch = useContextSwitchObserver()
        , timeService = useTimeService()
        , [showSpinner, setShowSpinner] = useState(false)

    useEffect(
        () => {
            const query = {
                ...getSampleListParams(locationReport, true, timeService),
                ...{
                    start: pagination.start,
                    count: pagination.count,
                    sort: 'exposureStartTime:asc',
                },
            }
            setShowSpinner(true)
            Promise.all([load(query), loadStatistics(query)])
                .finally(() => setShowSpinner(false))
        },
        [load, loadStatistics, contextSwitch, pagination, locationReport, timeService]
    )

    return [sampleList, showSpinner] as const
}

export default LimitBreachSamples
