import { useForm } from 'react-final-form'
import type { FieldArrayRenderProps } from 'react-final-form-arrays'
import { useState, useSelector, useEffect } from '_/facade/react'
import Overlay from '_/components/overlay/overlay'
import Select from '_/components/downshift-select'
import Button from '_/components/button'
import { fullNameLocationList } from '_/utils/exposure-location'
import { VOID_ID } from '_/utils/tree'
import { formatActiveState } from '_/utils/format/common'

import type { FloorPlanLocationForm } from '_/model/floor-plan/editor/types'
import * as g from '_/model/floor-plan/geometry'

interface Props {
    canvas: HTMLCanvasElement
    focusedIndex: number
    onFocus: (_: number | undefined) => void
    locationsField: FieldArrayRenderProps<FloorPlanLocationForm, HTMLElement>
    locationMenuRef: React.RefObject<HTMLDivElement>
}

function LocationMenu(props: Props) {
    const form = useForm()
        , planLocation = props.locationsField.fields.value[props.focusedIndex]
        , [locationId, setLocationId] = useState(planLocation.locationId)
        , exposureLocations = useExposureLocations(props.locationsField, props.focusedIndex)
        , [left, top] = calcPosition(props.canvas, planLocation)

    useEffect(
        () => setLocationId(planLocation.locationId),
        [planLocation.locationId]
    )

    return (
        <Overlay left={left} top={top} containerRef={props.locationMenuRef}>
            <div className='shadow-sm rounded bg-white border border p-2'>
                <div>
                    <label htmlFor='locationId' className='col-form-label pt-0'>Location</label>
                    <div className='row g-2 m-0'>
                        <Select
                            id='locationId'
                            className='form-control col'
                            entities={exposureLocations}
                            calcId={_ => _.id}
                            calcName={_ => formatActiveState(_.pathName, _.isActive)}
                            autocomplete
                            input={{
                                value: locationId,
                                onChange: nextId => setLocationId(nextId),
                            }}
                            testId='field-location'
                        />

                        <Button
                            className='col-auto py-0 px-1'
                            onClick={() => {
                                props.onFocus(undefined)
                                form.change(`locations[${props.focusedIndex}]locationId`, locationId)
                            }}
                            testId='add-location'
                        >
                            <span className='text-success material-icons md-24'>
                                check
                            </span>
                        </Button>
                        <Button
                            className='col-auto py-0 px-1'
                            onClick={() => {
                                props.onFocus(undefined)
                                props.locationsField.fields.remove(props.focusedIndex)
                            }}
                        >
                            <span className='text-danger material-icons md-24'>
                                delete_outline
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </Overlay>
    )
}

export default LocationMenu

function useExposureLocations(field: FieldArrayRenderProps<FloorPlanLocationForm, HTMLElement>, index: number) {
    const exclusions = new Set(
            field.fields.value
                .map(_ => _.locationId)
                .filter((_, i) => i !== index)
        )
        , allExposureLocations = useSelector(_ => fullNameLocationList(_.predefinedLists.exposureLocations))
        , locations = allExposureLocations.filter(_ => _.parentId !== VOID_ID && !exclusions.has(_.id))

    return locations
}

function calcPosition(canvas: HTMLCanvasElement, location: FloorPlanLocationForm) {
    const [left, top] = g.calcDomCoordinates(canvas, location)
        , offset = g.PLOT_RADIUS

    return [left - offset * 2, top + offset + 4]
}
