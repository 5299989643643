import type { FormApi } from 'final-form'

function formReset(form: FormApi<any>) {
    return new Promise<void>(
        (resolve, reject) =>
            setTimeout(() => {
                try {
                    form.reset()
                    resolve()
                }
                catch (error) {
                    reject(error)
                }
            })
    )
}

export default formReset
