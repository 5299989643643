import { LinkButton } from '_/components/link'
import Button from '_/components/button'
import PageHeader from '_/components/page-header'

import { NON_VIABLE_SAMPLES } from '_/constants/routes'
import type { BreachType } from '_/constants/sample-breach-type'

import type { Guid } from '_/model/guid'
import SampleLimitLabel from '../sample-limit-label'

interface Props {
    id: Guid
    breachType: BreachType
    showAuditTrail: () => void
}

function NonViableSampleEditPageHeader(props: Props) {
    return (
        <PageHeader sticky title='Non-viable sample details'>
            <div className='me-auto mb-1'>
                <SampleLimitLabel breach={props.breachType}/>
            </div>
            <div>
                <LinkButton routeName={NON_VIABLE_SAMPLES} className='btn-link'>
                    <i className='material-icons md-18 mb-1 me-1 align-middle'>west</i>
                    Go back to non-viable samples list
                </LinkButton>
                <Button
                    onClick={props.showAuditTrail}
                    className='btn-link'
                >
                    <i className='material-icons md-18 mb-1 me-1 align-middle'>history</i>
                    View audit trail
                </Button>
            </div>
        </PageHeader>
    )
}

export default NonViableSampleEditPageHeader
