import { useState } from '_/facade/react'

import type CustomField from '_/model/predefined-lists/custom-field/types'
import type  SampleType from '_/model/predefined-lists/sample-type/types'
import type { SampleData } from '_/model/fingerdab-overview-by-operator/types'

import * as fieldIndex from '_/constants/custom-field-index'

import { getFieldValue } from '_/features/samples/helpers'

import SampleIcon from '_/components/sample-icon/sample-icon'
import HoverMenu from './sample-hover-menu'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import { BOOKED_IN } from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'

interface SampleCellDataProps {
    samples: SampleData[]
    customFields: CustomField[]
    sampleTypesIncludingDeleted: SampleType[]
    index: number
    locations: ListExposureLocation[]
}

function SampleCellData(props: SampleCellDataProps) {
    const NO_SAMPLES = 'No Viable Samples'
        , { samples } = props
        , [iconContainerElement, setIconContainerElement] = useState<HTMLSpanElement | null>(null)

    return (
        <td>
            <span className='text-muted text-uppercase' ref={setIconContainerElement}>
                {samples.length > 0
                    ? samples.map((_, index) =>
                        <SampleIcon
                            key={index}
                            status={BOOKED_IN}
                            className='me-1'
                            type={getFieldValue(_.fields, fieldIndex.PLATE_TYPE)}
                            testId='fingerdab-report-sample-icon'
                        />
                    )
                    : NO_SAMPLES
                }
            </span>
            {samples.length > 0 &&
                <HoverMenu
                    samples={samples}
                    index={props.index}
                    iconContainerElement={iconContainerElement}
                    customFields={props.customFields}
                    sampleTypes={props.sampleTypesIncludingDeleted}
                    locations={props.locations}
                />
            }
        </td>
    )
}

export default SampleCellData
