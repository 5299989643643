import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, isEmpty, allowedName } from '_/utils/form/validate'
import { searchDuplicateName } from '_/model/common/helpers'
import type { MonitoringGroup, Expectations } from './types'
import { getSamplesQuantity } from './helpers'

export default function validate(entity: MonitoringGroup, duplicatedMonitoringGroups: MonitoringGroup[]) {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 100)(entity.name)
        , monitoringGroup = { id: entity.id, name: entity.name }
        , notAllowedNames = allowedName('Name')(entity.name)
        , duplicate = entity.name && searchDuplicateName(monitoringGroup, duplicatedMonitoringGroups)
        , expectations = requiredExpectations(entity.expectations)
        , result: Omit<ValidationResult<MonitoringGroup>, 'expectations'> & { expectations?: string } = {}

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (notAllowedNames)
        result.name = notAllowedNames

    if (duplicate)
        result.name = `Monitoring group with name '${entity.name.trim()}' already exists`

    if (expectations)
        result.expectations = expectations

    return result
}

function requiredExpectations(value: Expectations[]) {
    return getSamplesQuantity(value) > 0
        ? false
        : 'At least one sample is required'
}
