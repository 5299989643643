const TEXT = 0
    , NUMBER = 1
    , SELECTION = 2
    , DATE_TIME = 3
    , DATE = 4
    , TIME = 5

type CustomFieldType = never
    | typeof TEXT
    | typeof NUMBER
    | typeof SELECTION
    | typeof DATE_TIME
    | typeof DATE
    | typeof TIME

const FIELD_TYPE: { id: CustomFieldType, name: string }[] = [
    {
        id: TEXT,
        name: 'Text',
    },
    {
        id: NUMBER,
        name: 'Number',
    },
    {
        id: SELECTION,
        name: 'Selection',
    },
    {
        id: DATE_TIME,
        name: 'DateTime',
    },
    {
        id: DATE,
        name: 'Date',
    },
    {
        id: TIME,
        name: 'Time',
    },
]

export {
    FIELD_TYPE as default,
    TEXT,
    NUMBER,
    SELECTION,
    DATE_TIME,
    DATE,
    TIME,
    CustomFieldType,
}
