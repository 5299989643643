import type { FieldRenderProps} from 'react-final-form'
import { useField } from 'react-final-form'

import { React, useEffect, useState, useAction, useMemo } from '_/facade/react'
import { debounce, noop } from '_/utils/function'
import { Close } from '_/components/button'
import Select from '_/components/downshift-select'
import type { OrganismIdentification } from '_/model/predefined-lists/organism-identification/types'
import FormattedText from '_/features/text/formatted-text'

import * as actions from './actions'
import { formatActiveState } from '_/utils/format/common'
import { CORRECTIONAL_MPN_VALUE } from '_/model/predefined-lists/identifications/correctional-mpn-value'
import { NOT_YET_IDENTIFIED } from '_/model/predefined-lists/identifications/not-yet-identified'
import { NO_ID_REQUIRED } from '_/model/predefined-lists/identifications/no-id-required'

interface Props {
    id: string
    name: string
    className?: string
    disabled?: boolean
    testId?: string
    excludeInactive?: boolean
    excludeNotYetIdentified?: boolean
    excludeNoIdRequired?: boolean
    excludeCorrectionMpnValue?: boolean
}

function MultipleOrganismSearchField (props: Props) {
    const field = useField(props.name)
        , input = field.input
        , initialDropdownIdentifications = useMemo(() => [
            ...(props.excludeNotYetIdentified ? [] : [NOT_YET_IDENTIFIED]),
            ...(props.excludeNoIdRequired ? [] : [NO_ID_REQUIRED]),
            ...(props.excludeCorrectionMpnValue ? [] : [CORRECTIONAL_MPN_VALUE]),
        ], [props])
        , [dropdownIdentifications, inputValueChangeHandler, handleBlur] = useDropdownValueChange(initialDropdownIdentifications, field)
        , handleChange = useHandleChange(field)
        , handleRemove = useHandleRemove(field)
        , value = Array.isArray(input.value) ? input.value : []
        , notSelectedEntities = dropdownIdentifications.filter(_ => value.indexOf(_.id) === -1)
        , identificationsCache = useCache(value, dropdownIdentifications)
        , selectedEntities = value.map<OrganismIdentification>(
            id => identificationsCache.find(_ => _.id === id)
                || { id, name: '', inUse: false, isActive: true, isGenus: false }
        )

    return (
        <div className={'mb-3 ' + props.className || ''}>
            <label htmlFor={props.id} className='col-form-label' data-testid='field-label'>Organisms</label>
            <Select
                id='organismIds'
                entities={props.excludeInactive ? notSelectedEntities.filter(_ => _.isActive) : notSelectedEntities}
                calcId={_ => _.id}
                calcName={_ => formatActiveState(_.name, _.isActive)}
                className='form-control border-print-0'
                input={{
                    ...input,
                    value: '',
                    onChange: handleChange,
                    onBlur: handleBlur,
                }}
                onInputValueChange={inputValueChangeHandler}
                shouldClearInput
                placeholder='Search'
                isSearchField
                disabled={props.disabled}
                testId={props.testId}
            />
            {selectedEntities.map((entity, key) =>
                <span key={key}>
                    {key > 0 && <br/>}
                    <Close
                        className='align-text-bottom text-danger'
                        aria-label='Close'
                        onClick={() => handleRemove(entity.id)}
                    />
                    <FormattedText className='ms-1' text={formatActiveState(entity.name, entity.isActive)} />
                </span>
            )}
        </div>
    )
}

export { MultipleOrganismSearchField as default }

function useDropdownValueChange(initialValue: OrganismIdentification[], field: FieldRenderProps<string[]>) {
    const [inputValueChangeHandler, setInputValueChangeHandler] = useState<(inputValue: string) => void>(() => noop)
        , [dropdownIdentifications, setDropdownIdentifications] = useState<OrganismIdentification[]>(initialValue)
        , searchOrganismByName = useAction(actions.searchOrganismIdentification)

    function handleBlur() {
        field.input.onBlur()
    }

    useEffect(
        () => {
            function handleInputValueChange(inputValue: string) {
                if (inputValue.length < 3) {
                    setDropdownIdentifications(initialValue)
                    return
                }

                searchOrganismByName({name: inputValue, count: 10})
                    .then(identifications => setDropdownIdentifications(initialValue.concat(identifications)))
            }

            setInputValueChangeHandler(() => debounce(handleInputValueChange, 300))
        },
        [initialValue, setInputValueChangeHandler, searchOrganismByName]
    )

    return [dropdownIdentifications, inputValueChangeHandler, handleBlur] as const
}

function useCache(ids: string[], dropdownIdentifications: OrganismIdentification[]) {
    const [cache, setCache] = useState<OrganismIdentification[]>([])

    const searchOrganism = useAction(actions.searchOrganismIdentification)

    useEffect(
        () => {
            const cacheIsConsistent = ids.length === cache.length
                    && ids.every(id => cache.find(_ => _.id === id))

            if (cacheIsConsistent) {
                return
            }

            const isOrganism = (_: OrganismIdentification | undefined): _ is OrganismIdentification => _ !== undefined
                , offlineCache = ids.map(id => cache.concat(dropdownIdentifications)
                    .find(_ => _.id === id))
                    .filter(isOrganism)

            if (ids.length === offlineCache.length) {
                setCache(offlineCache)
                return
            }

            let disposed = false

            searchOrganism({ ids }).then(response => {
                if (!disposed)
                    setCache(response)
            })

            return () => { disposed = true }
        },
        [ids, cache, dropdownIdentifications, searchOrganism]
    )

    return cache
}

function useHandleChange(field: FieldRenderProps<string[]>) {
    function handleChange(value: string) {
        const input = field.input
            , nextValue = Array.isArray(input.value) ? input.value.concat() : []

        if (nextValue.indexOf(value) !== -1)
            return

        nextValue.push(value)
        input.onChange(nextValue)
    }

    return handleChange
}

function useHandleRemove(field: FieldRenderProps<string[]>) {
    function handleRemove(value: string) {
        const input = field.input
            , previousValue = Array.isArray(input.value) ? input.value.concat() : []
            , nextValue = previousValue.filter(_ => _ !== value)

        input.onChange(nextValue.length === 0 ? '' : nextValue)
    }

    return handleRemove
}
