import { React, useCallback, useEffect, forwardRef, useImperativeHandle, useState, useRef, useMemo } from '_/facade/react'
import getPlotly from '_/facade/plotly'

import type TimeService from '_/services/time-service'

import type { AggregationPeriod } from '_/model/analysis/aggregation-period'
import type SampleSearchFields from '_/model/sample/search'

import type { ChartMetadata } from '_/model/analysis/chart-metadata'
import { getLineGraphLayoutMultiY as getLayout, getPrintLayout, getExportOpts } from '../helpers'
import { generateDateRange, formatDate } from './helpers'
import ActionButtons from './action-buttons'

import { useTimeService } from '_/components/time'
import type { DateSeries, DateSeriesGraphData } from '_/model/analysis/date-series'
import DateSeriesChartTabularView from './tabular-views/date-series-chart-tabular-view'
import { memoize } from '_/utils/function'
import type { DateTime } from '_/model/date-time'
import { plainText } from '_/model/text/text'
import type { ThresholdLine } from '_/model/analysis/filter/types'
import { defaultTextNode } from '_/model/text/text'
import { formatThresholdLineName } from '../filter/helpers'
import * as h from './export-handlers'

interface Props {
    data: DateSeriesGraphData
    chartTitle: string
    aggregationPeriod: AggregationPeriod | undefined
    metadata: ChartMetadata
    sampleListRouterParams: SampleSearchFields
    showTabularView?: boolean
    multipleGraphs?: boolean | undefined
    showActionButtons: boolean
    disabledGoToSamplesButton?: boolean
    exportDisabled: boolean
    thresholdLines: ThresholdLine[]
    onSplitLocation?: (locationName: string) => void
    onExport?: (_: Blob) => Promise<void>
}

export interface ExportRef {
    getExportImage(): Promise<string | undefined>
}

function DateSeriesChart(props: Props, ref: React.ForwardedRef<ExportRef>) {
    const root = useRef<HTMLDivElement>(null)
        , timeService = useTimeService()
        , [memGenerateDateRange] = useState(() => memoize(generateDateRange))
        , dates = memGenerateDateRange(timeService, props.aggregationPeriod, props.sampleListRouterParams)
        , chartData = useMemo(
            () => {
                return generatePlotlyDataFromSeriesData(dates, props.data, timeService, props.aggregationPeriod, props.thresholdLines)
            },
            [props.data, props.thresholdLines, dates, props.aggregationPeriod, timeService]
        )
        , plot = useCallback(
            () => {
                getPlotly().then(plotly => {
                    if (root.current)
                        plotly.newPlot(root.current, chartData, getLayout(props.metadata, ...getYAxesData(props.data.yAxes, chartData)), { displayModeBar: false })
                })
            },
            [chartData, props.data, props.metadata]
        )

    useEffect(plot, [plot])

    useImperativeHandle(ref, () => ({
        getExportImage,
    }))

    useLegendItemClickHandler(root, props.onSplitLocation)

    function handleExport() {
        const { printLayout, layout, exportOpts } = getLayouts(props.metadata, chartData, props.data)

        return h.exportChartHandler(root, !!props.multipleGraphs, props.onExport, printLayout, layout, exportOpts, timeService)
    }

    function getExportImage() {
        const { printLayout, layout, exportOpts } = getLayouts(props.metadata, chartData, props.data)

        return h.getExportImage(root, printLayout, layout, exportOpts)
    }

    return (
        <div>
            <div className='border border-light'>
                <div ref={root} />
            </div>
            {props.showTabularView &&
                <DateSeriesChartTabularView
                    series={props.data.series}
                    aggregationPeriod={props.aggregationPeriod}
                    sampleListRouterParams={props.sampleListRouterParams}
                />
            }
            {props.showActionButtons &&
                <ActionButtons
                    disabledGoToSamplesButton={!!props.disabledGoToSamplesButton || props.data.series.length === 0}
                    onExport={handleExport}
                    sampleListRouterParams={props.sampleListRouterParams}
                    multipleGraphs={props.multipleGraphs}
                    exportButtonDisabled={props.exportDisabled}
                />
            }
        </div>
    )
}

export default forwardRef(DateSeriesChart)

function generatePlotlyDataFromSeriesData(
    dates: DateTime[],
    data: DateSeriesGraphData,
    timeService: TimeService,
    aggregationPeriod: AggregationPeriod | undefined,
    thresholdLines: ThresholdLine[]
) {
    const thresholdLinesSeries: DateSeries[] = thresholdLines
        .map((thresholdLine, i) => ({
            name: [defaultTextNode(formatThresholdLineName(thresholdLine))],
            yAxis: thresholdLine.axis,
            mode: 'lines',
            type: 'line',
            line: {
                dash: `${(i > 10 ? i % 10 : i)*2 + 2}px, 2px`,
            },
            color: '#212121 ',
            points: dates.map(_ => ({ date: _, value: thresholdLine.value })),
        }))
        , series = thresholdLinesSeries.concat(data.series.slice().reverse())

    return series.length > 0
        ? series.map(_ => generatePlotlyData(dates, _, timeService, aggregationPeriod, series.length > 1))
        : [generatePlotlyData(dates, undefined, timeService, aggregationPeriod)]
}

function generatePlotlyData(dates: DateTime[], series: DateSeries | undefined, timeService: TimeService, aggregationPeriod: AggregationPeriod | undefined, showNameOnHover?: boolean) {
    if (!series)
        return {
            x: dates.map(d => aggregationPeriod !== undefined ? formatDate(d, timeService, aggregationPeriod) : timeService.isoWithCtzOffset(d)),
            y: dates.map(_ => null),
            showlegend: false,
        } as Plotly.PlotData

    const type = series.type === 'bar' ? 'bar' : undefined
        , yaxis = series.yAxis === 0 ? undefined : `y${series.yAxis + 1}`

    return ({
        /**
         * A note about timeService.isoWithCtzOffset
         * Plotly is able to treat date times like continuous data.
         * There is no direct support of custom time zones,
         * but it properly plots tick marks when date is formatted
         * in ISO 8601 with time offset relative to utc. E.g 2021-03-21T22:12:23+02:00
         */
        x: series.points.map(_ => aggregationPeriod !== undefined ? formatDate(_.date, timeService, aggregationPeriod) : timeService.isoWithCtzOffset(_.date)),
        y: series.points.map(_ => _.value ?? null),
        hovertext: series.points.map(_ => _.text ?? null),
        yaxis,
        name: series.name && plainText(series.name),
        mode: series.mode,
        line: series.line,
        hoverinfo: showNameOnHover ? 'x+y+name' : 'x+y',
        hovertemplate: series.hoverTemplate
            ? series.hoverTemplate
            : series.points.some(_ => _.text)
                ? showNameOnHover ? '%{hovertext}' : '%{hovertext}<extra></extra>'
                : undefined,
        showlegend: series.name !== undefined,
        marker: series.color ? { color: series.color } : {},
        type,
    } as Plotly.PlotData)
}

function getYAxesData(yAxes: [string, ...string[]], chartData: Plotly.PlotData[]) {
    return yAxes.map(_ => [_, chartData] as const)
}

function useLegendItemClickHandler(elementRef: React.RefObject<HTMLDivElement>, onSplitLocation?: (locationName: string) => void) {
    useEffect(() => {
        const element = elementRef.current
        if (!element || !onSplitLocation)
            return

        /**
         * Plotly has no built in means to track clicks on legend or to customize it.
         * So there is check if legend is clicked and then location name is searched.
         */
        function handleMouseUp(e: MouseEvent) {
            const target = e.target as HTMLDivElement
                // note: classList doesn't for svg elements in IE11, so getAttribute is used instead
                , className = target.getAttribute('class') || ''
                , isLegendItemElement = className.indexOf('legendtoggle') !== -1

            if (!isLegendItemElement)
                return

            e.stopPropagation()

            const location = target.parentElement?.querySelector('.legendtext')?.textContent ?? ''
            onSplitLocation?.(location)
        }

        element.addEventListener('mouseup', handleMouseUp, true)
        return () => element.removeEventListener('mouseup', handleMouseUp, true)
    })
}

function getLayouts(metadata: ChartMetadata, chartData: Plotly.PlotData[], data: DateSeriesGraphData) {
    return {
        printLayout: getPrintLayout(metadata, chartData.length),
        layout: getLayout(metadata, ...getYAxesData(data.yAxes, chartData)),
        exportOpts: getExportOpts(chartData.length, metadata)
    }
}
