import type AnalysisSeries from '_/model/analysis/analysis-series'
import type { DateTime} from '_/model/date-time'
import { equals } from '_/model/date-time'
import { formatActiveState } from '_/utils/format/common'
import { plainText } from '_/model/text/text'

function formatTotalSamplesHoverInfo(analysisSeries: (AnalysisSeries | undefined)[], date: DateTime, seriesTypeTitle: string) {
    if (analysisSeries.length === 1)
        return ''

    return analysisSeries.map(series =>
        `${seriesTypeTitle} ${plainText(formatActiveState(series?.title.name ?? '', series?.title.isActive))}: ${series?.series.find(s => equals(s.utcDateTime, date))?.totalSamples ?? 0}`
    ).join('<br>')
}

export {
    formatTotalSamplesHoverInfo,
}
