import { useAction, useState, useEffect, classnames, useReducer } from '_/facade/react'

import { ITEMS_PER_PAGE } from '_/constants'

import { Table, PaginatedFooter } from '_/components/table'
import Button from '_/components/button'
import PageHeader from '_/components/page-header'
import { useContextSwitchObserver } from '_/components/context-observer'
import { useTimeService } from '_/components/time'

import type PaginationState from '_/model/pagination-state'

import type { NotificationInfo } from '_/model/notification/types'
import type Notification from '_/model/notification/types'

import * as a from '../actions'

import SingleNotification from './single-notification'
import NotificationPermissionsWarningModal from './notification-permissions-warning-modal'
import NotificationChangeRoleModal from './notification-change-role-modal'
import { useNotificationClickHandler, useNavigateHandler, useChangeStatusHandler, useMarkAllAsReadHandler } from './common'

function NotificationsList() {
    const [filter, handleShowUnreadOnlyToggle, handlePaginationChange] = useFilter()
        , [notifications, totalCount, reload] = useNotifications(filter)
        , handleMarkAllAsRead = useMarkAllAsReadHandler(notifications, filter.unreadOnly ? goToPreviousPage : reload)
        , handleChangeStatus = useChangeStatusHandler(filter.unreadOnly && notifications.length === 1 ? goToPreviousPage : reload)
        , [dialog, handleNotificationClick, handleModalClose] = useNotificationClickHandler()
        , handleNavigate = useNavigateHandler()
        , timeService = useTimeService()

    function goToPreviousPage() {
        const start = filter.pagination.start - ITEMS_PER_PAGE
        handlePaginationChange({ ...filter.pagination, start: start >= 0 ? start : 0 })
    }

    return (
        <div className='d-flex flex-column h-100'>
            {dialog.tag === 'access-warning' &&
                <NotificationPermissionsWarningModal onClose={handleModalClose} metadata={dialog.data} />
            }
            {dialog.tag === 'change-membership' &&
                <NotificationChangeRoleModal
                    onClose={handleModalClose}
                    data={dialog.data}
                    onRoleChanged={() => handleNavigate(dialog.data.notification)}
                />
            }
            <div className='d-flex flex-fill overflow-auto'>
                <div className='container-fluid main-block'>
                    <div className='row justify-content-center'>
                        <div className='col-6'>
                            <PageHeader sticky title='Notifications'>
                                <Button
                                    className='btn-link ms-auto shadow-none'
                                    onClick={handleShowUnreadOnlyToggle}
                                    testId={`notification-page-${filter.unreadOnly ? 'view-all' : 'show-only-unread'}`}
                                >
                                    {filter.unreadOnly ? 'View all' : 'Show only unread'}
                                </Button>
                                <Button
                                    className='btn-link shadow-none'
                                    onClick={handleMarkAllAsRead}
                                    disabled={notifications.every(_ => _.isRead)}
                                    testId='notification-page-mark-all-as-read'
                                >
                                    Mark all as read
                                </Button>
                            </PageHeader>

                            {notifications.length === 0
                                ? <div className='text-center mt-5'>
                                    {filter.unreadOnly
                                        ? <div data-testid='notification-page-no-unread-notifications-placeholder'>
                                            No unread notifications. <br/>
                                            Click
                                            <Button
                                                className='btn-link text-dark text-decoration-underline px-1 py-0 mb-1 shadow-none'
                                                onClick={handleShowUnreadOnlyToggle}
                                            >
                                                View all
                                            </Button>
                                            to view all of your notifications
                                        </div>
                                        : 'No notifications to show'
                                    }
                                </div>
                                : <Table>
                                    <thead className='thead table-header--sticky table-header--default-offset'>
                                        <tr>
                                            <th data-testid='notification-page-table-header'>Time</th>
                                            <th data-testid='notification-page-table-header'>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {notifications.map((notification, index) =>
                                            <tr key={notification.id} data-testid='notification-page-data-row'>
                                                <td data-testid={`notification-page-${index}-time-data`}>
                                                    <Button
                                                        onClick={() => handleChangeStatus(notification)}
                                                        className='bg-transparent me-2 p-0 shadow-none'
                                                        title={notification.isRead ? 'Mark as unread' : 'Mark as read'}
                                                        testId={`notification-page-${index}-mark-${notification.isRead ? 'unread' : 'read'}`}
                                                    >
                                                        <span className={classnames('mark-as-read-button', notification.isRead ? 'bg-transparent' : 'bg-primary')}/>
                                                    </Button>
                                                    {timeService.formatCtzDateTime(notification.createdAt, true)}
                                                </td>
                                                <td>
                                                    <SingleNotification
                                                        notification={notification}
                                                        onClick={() => handleNotificationClick(notification)}
                                                        dropdownLayout={false}
                                                        testId={`notification-page-${index}`}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <PaginatedFooter
                                        colSpan={2}
                                        state={filter.pagination}
                                        onChange={handlePaginationChange}
                                        totalCount={totalCount}
                                    />
                                </Table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationsList

interface Filter {
    unreadOnly: boolean
    pagination: PaginationState
}

function useFilter() {
    const DEFAULT_PAGINATION = { start: 0, count: ITEMS_PER_PAGE }
        , [filter, setFilter] = useState<Filter>({ unreadOnly: false, pagination: DEFAULT_PAGINATION })

    function handleShowUnreadOnlyToggle() {
        setFilter(_ => ({ ..._, pagination: DEFAULT_PAGINATION, unreadOnly: !_.unreadOnly }))
    }

    function handlePaginationChange(pagination: PaginationState) {
        setFilter(_ => ({ ..._, pagination }))
    }

    return [filter, handleShowUnreadOnlyToggle, handlePaginationChange] as const
}

function useNotifications(filter: Filter) {
    const load = useAction(a.loadNotifications)
        , loadNotificationInfo = useAction(a.loadNotificationInfo)
        , contextSwitch = useContextSwitchObserver()
        , [notifications, setNotifications] = useState<Notification[]>([])
        , [notificationInfo, setNotificationInfo] = useState<NotificationInfo | undefined>()
        , [reloadCounter, reload] = useReducer(_ => _ + 1, 0)
        , totalCount = filter.unreadOnly ? notificationInfo?.unread : notificationInfo?.totalCount

    useEffect(
        () => {
            loadNotificationInfo().then(setNotificationInfo)

            load({ ...filter.pagination, unreadOnly: filter.unreadOnly })
                .then(setNotifications)
        },
        [filter, reloadCounter, contextSwitch, load, loadNotificationInfo]
    )

    return [notifications, totalCount ?? 0, reload] as const
}
