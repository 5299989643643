import CHART_TYPE from './chart-type'
import * as ct from './chart-type'
import { isThresholdLineAvaible } from '_/features/analysis/filter/helpers'

const MAIN_AXIS = 0
    , ADDITIONAL_AXIS = 1

const CHART_AXIS = CHART_TYPE
    .filter(_ => isThresholdLineAvaible(_.id))
    .map(_ => {
        switch (_.id) {
            case ct.TOTAL_SAMPLES_READ:
            case ct.PARTICLE_COUNTS:
                return {
                    id: _.id,
                    axis: [
                        {
                            id: MAIN_AXIS,
                            name: _.name
                        },
                    ]
                }
            case ct.ORGANISM_TYPE_BREAKDOWN:
                return {
                    id: _.id,
                    axis: [
                        {
                            id: MAIN_AXIS,
                            name: _.name
                        },
                        {
                            id: ADDITIONAL_AXIS,
                            name: 'Contamination and limit breach rates'
                        },
                    ]
                }
            case ct.STACKED_SAMPLE_NAME:
                return {
                    id: _.id,
                    axis: [
                        {
                            id: MAIN_AXIS,
                            name: 'Number of CFUs'
                        },
                        {
                            id: ADDITIONAL_AXIS,
                            name: 'Contamination rate'
                        },
                    ]
                }
            default:
                return {
                    id: _.id,
                    axis: [
                        {
                            id: MAIN_AXIS,
                            name: _.name
                        },
                        {
                            id: ADDITIONAL_AXIS,
                            name: 'Total number of samples read'
                        },
                    ]
                }
        }
    })

export {
    CHART_AXIS,
}
