import { actionFactory } from '_/utils/redux'
import type { CustomFieldEdit } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.custom-fields')

export const loadCustomFields = action<void, Promise<CustomField[]>>('loadCustomFields')
            , loadCustomField = action<string, Promise<CustomField>>('loadCustomField')
            , loadCustomFieldTrail = action<string, Promise<AuditTrail>>('loadCustomFieldTrail')
            , customFieldsLoaded = action<CustomField[]>('customFieldsLoaded')
            , loadCustomFieldsIncludingInactive = action<void, Promise<CustomField[]>>('loadCustomFieldsIncludingInactive')
            , createCustomField = action<CustomFieldEdit, Promise<void>>('createCustomField')
            , saveCustomField = action<{ id: string, oldCustomField: CustomFieldEdit, newCustomField: CustomFieldEdit }, Promise<void>>('saveCustomField')
            , searchFieldValues = action<{ id: string,  query: string, isNonViable: boolean }, Promise<string[]>>('searchFieldValues')
            , parseImportFile = action<File, Promise<string[]>>('parseImportFile')
            , removeCustomField = action<string, Promise<void>>('removeCustomField')
