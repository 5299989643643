import { classnames, useAction, useSelector, useEffect } from '_/facade/react'

import * as tree from '_/utils/tree'

import Button from '_/components/button'
import { Table } from '_/components/table'
import { LinkButton } from '_/components/link'
import PageHeader from '_/components/page-header'
import { useTimeService } from '_/components/time'
import { useContextSwitchObserver } from '_/components/context-observer'

import type { ContextLevel } from '_/constants/context-level'
import CONTEXT_LEVEL, { SITE } from '_/constants/context-level'
import { SETTINGS_CONTEXTS_CREATE, SETTINGS_CONTEXTS_EDIT, SETTINGS_CONTEXTS_EXPORT } from '_/constants/routes'

import * as confirmationActions from '_/features/confirmation/actions'
import { navigateTo } from '_/features/routing/actions'

import ExportModal from '_/features/contexts/list/export-modal'
import ContextModal from '../modal/context-modal'
import * as actions from '../actions'

function nestingLevel(nestingLevel: number) {
    const offsets = []
    for (let i = nestingLevel; i > 0; i--)
        offsets.push(<div key={i} className='exposure-locations__offset' />)

    return offsets
}

function ContextList() {
    const timeService = useTimeService()
        , contexts = useContexts()
        , editMode = useSelector(_ => [SETTINGS_CONTEXTS_CREATE, SETTINGS_CONTEXTS_EDIT].some(it => _.router.route!.name === it))
        , exportMode = useSelector(_ => _.router.route!.name === SETTINGS_CONTEXTS_EXPORT)
        , permissions = useSelector(_ => _.auth.permissions)
        , contextsTree = tree.looseFlatTree(contexts)
        , handleClick = useHandleEditClick()
        , navigateToAction = useAction(navigateTo)

    function getContextLevelName(contextLevel: ContextLevel) {
        return CONTEXT_LEVEL.find(level => level.id === contextLevel)!.name
    }

    return (
        <>
            <PageHeader title='Sites'>
                <LinkButton
                    className='btn-primary'
                    routeName={SETTINGS_CONTEXTS_CREATE}
                    hasNoPermissions={!permissions.createRemoveContexts}
                    testId='add-new-context'
                >
                    Add new
                </LinkButton>
            </PageHeader>

            {editMode && <ContextModal contexts={contextsTree}/>}
            {exportMode && <ExportModal />}

            <div className="overflow-auto flex-fill">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Description</th>
                            <th>Level</th>
                            <th>Created at</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {contextsTree.map(_ =>
                            <tr key={_.id} className={classnames({ 'text-secondary' : _.isDeleted })}>
                                <td data-testid='context-description'>
                                    {nestingLevel(tree.looseNestingLevel(contexts, _))}
                                    {_.description}
                                </td>
                                <td>{getContextLevelName(_.level)}</td>
                                <td>{timeService.formatCtzDate(_.createdAt)}</td>
                                <td className='text-end'>
                                    {permissions.smartControlMode &&
                                        <Button
                                            className={classnames('btn-link py-0', {
                                                disabled: !(_.level === SITE && permissions.exportContexts),
                                            })}
                                            onClick={() => navigateToAction(SETTINGS_CONTEXTS_EXPORT, { id: _.id })}
                                            hasNoPermissions={!(_.level === SITE && permissions.exportContexts)}
                                            testId={`context-${_.description}-export`}
                                        >
                                            Export
                                        </Button>
                                    }
                                    <Button
                                        className={classnames('btn-link py-0', { disabled: !permissions.editContexts })}
                                        onClick={() => handleClick(_.id)}
                                        hasNoPermissions={!permissions.editContexts}
                                        testId={`context-${_.description}-edit`}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ContextList

function useHandleEditClick() {
    const validateIpContextInWhitelist = useAction(actions.validateIpInContextWhitelist)
        , showWarningModal = useAction(confirmationActions.showWarningModal)
        , navigateToAction = useAction(navigateTo)

    return function handleEditClick(contextId: string) {
        validateIpContextInWhitelist(contextId)
            .then(_ => {
                if (_.isValid)
                    navigateToAction(SETTINGS_CONTEXTS_EDIT, { id: contextId })
                else
                    showWarningModal(_.message)
            })
    }
}

function useContexts() {
    const loadContexts = useAction(actions.loadContexts)
        , contextSwitch = useContextSwitchObserver()
        , contexts = useSelector(_ => _.contexts.list)

    useEffect(
        () => {
            loadContexts()
        },
        [contextSwitch, loadContexts]
    )

    return contexts
}
