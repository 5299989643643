import { actions as routerActions } from 'redux-router5'
import type * as router5 from 'router5'

import type { AuthService } from '_/services/api-service'
import type UserService from '_/services/user-service'
import type MembershipService from '_/services/membership-service'
import type { TimezoneAwareTimeService } from '_/services/time-service'
import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import { noop } from '_/utils/function'
import * as Routes from '_/constants/routes'
import { mustRedirectOnContextSwitch, getDefaultRoute } from '_/features/routing/helpers'

import * as actions from './actions'
import * as filterActions from '_/features/filters/actions'
import { convertToUserContext } from '_/model/permissions/helpers'
import type AzureAdService from '_/services/azure-ad-service'
import type { LogInResponse, SsoLoginCredentials, Credentials } from '_/model/auth/types'
import { dropFields } from '_/utils/object'

const LAST_UNAUTHENTICATED_PAGE_REQUEST = 'last-unauthenticated-page-request'

const factory = (
    service: AuthService,
    userService: UserService,
    membershipService: MembershipService,
    azureAdService: AzureAdService,
    timeService: TimezoneAwareTimeService
): EffectsFactory => (dispatch, getState) => {
    const timeZone = getState().auth.timeZoneName

    if (timeZone)
        timeService.init(timeZone)

    return [
        handler(actions.logIn, credentials =>
            service.logIn(credentials)
                .then(_ => logInCommon(_, credentials))
        ),

        handler(actions.availableMemberships, service.availableMemberships),

        handler(actions.ssoLogIn, credentials =>
            service.ssoLogIn(credentials)
                .then(_ => logInCommon(_, credentials))
        ),

        handler(actions.ssoAvailableMemberships, service.ssoAvailableMemberships),

        handler(actions.ssoSignUp, service.ssoSignUp),

        handler(actions.logOut, _ =>
            service.logOut()
                .then(_ => dispatch(actions.loggedOut()))
                .then(_ => dispatch(routerActions.navigateTo(Routes.LOG_IN)))
                .then(noop)
        ),

        handler(actions.keepAlive, _ => service.keepAlive().then(noop)),

        handler(actions.confirmEmail, token =>
            Promise.resolve()
                .then(_ => userService.confirmEmail(token))
                .then(_ => dispatch(routerActions.navigateTo(Routes.LOG_IN)))
                .then(noop)
        ),

        handler(actions.forgotPassword, email =>
            Promise.resolve()
                .then(_ => userService.forgotPassword(email))
                .then(_ => dispatch(routerActions.navigateTo(Routes.FORGOT_PASSWORD_SENT)))
                .then(noop)
        ),

        handler(actions.setPassword, data =>
            Promise.resolve()
                .then(_ => userService.setPassword(data.token, data.password))
                .then(_ => dispatch(routerActions.navigateTo(Routes.LOG_IN)))
                .then(noop)
        ),

        handler(actions.loadMemberships, () =>
            service.getMemberships()
                .then(_ => {
                    dispatch(actions.membershipsLoaded(_))
                    return _
                })
        ),

        handler(actions.changeContext, id =>
            service.changeContext(id)
                .then(context => {
                    timeService.init(context.timeZoneName)
                    dispatch(actions.contextChanged({ id, context }))
                })
                .then(() => {
                    const state = getState()
                        , redirectToDefaultPage = mustRedirectOnContextSwitch(
                            state.router.route!.name as Routes.RouteName,
                            state.auth.permissions
                        ), route = state.router.route

                    if (route?.params.filter)
                        dispatch(routerActions.navigateTo(route.name, dropFields(route.params, 'filter'), { replace: true }))

                    if (redirectToDefaultPage)
                        dispatch(routerActions.navigateTo(getDefaultRoute(state.auth.permissions)))
                })
        ),

        handler(actions.confirmChangedPassword, token =>
            userService.confirmChangedPassword(token)
                .then(() => dispatch(routerActions.navigateTo(Routes.LOG_IN)))
                .then(noop)
        ),

        handler(actions.loadUserMembership, id =>
            membershipService.getMembership(id)
                .then(_ => dispatch(actions.userMembershipLoaded(_)))
                .then(noop)
        ),

        handler(actions.unauthenticatedPageRequest, routerState => {
            try {
                window.sessionStorage.setItem(LAST_UNAUTHENTICATED_PAGE_REQUEST, JSON.stringify(routerState, null, 2))
            }
            catch (_) {
            }

            return {
                type: actions.unauthenticatedPageRequest,
                payload: routerState,
            }
        }),

        handler(actions.loadResetPassword, userService.getResetPassword),

        handler(actions.loadEmailConfirmation, userService.getEmailConfirmation),

        handler(actions.getAzureAdConfig, azureAdService.getAzureAdConfig),
    ]

    function logInCommon(response: LogInResponse, credentials: Credentials | SsoLoginCredentials) {
        timeService.init(response.timeZoneName)

        dispatch(filterActions.clearFilters())

        dispatch(actions.loggedIn({
            token: response.token,
            user: convertToUserContext(response, credentials.membershipId),
            timeZoneName: response.timeZoneName,
            electronicSignatureSettings: response.membership?.context.electronicSignatureSettings ?? [],
        }))

        let state: router5.State | null = null
        try {
            state = JSON.parse(
                window.sessionStorage.getItem(LAST_UNAUTHENTICATED_PAGE_REQUEST) || 'null'
            )
            window.sessionStorage.removeItem(LAST_UNAUTHENTICATED_PAGE_REQUEST)
        }
        catch (error) {
        }

        const routeName = state ? state.name : getDefaultRoute(getState().auth.permissions)
            , routeParams = state == null ? undefined : state.params

        dispatch(routerActions.navigateTo(routeName, routeParams, { replace: true }))

        return
    }
}

export default factory
