import type { FormRenderProps } from 'react-final-form'
import { Form, FormSpy } from 'react-final-form'
import { useAction, useState, useEffect, useSelector, classnames } from '_/facade/react'
import type TimeService from '_/services/time-service'

import PageHeader from '_/components/page-header'
import { useContextSwitchObserver } from '_/components/context-observer'
import { LocalDateField, SelectField } from '_/components/form'
import { useTimeService } from '_/components/time'
import Button from '_/components/button'

import * as t from '_/model/text/text'
import type { OperatorsReport, OperatorsReportView } from '_/model/reports/operators-report/types'
import DEFAULT_DATE_RANGES, * as ranges from '_/constants/date-ranges'
import { ITEMS_LIMIT_COUNT, ITEMS_PRINT_LIMIT_COUNT } from '_/constants/items-limit-count'

import { loadExposureLocationList } from '_/features/predefined-lists/exposure-locations/actions'
import { loadOperatorsReportData } from './actions'
import validate from '_/model/reports/operators-report/validate'
import { getSampleListParams } from '_/model/reports/operators-report/helpers'
import { useFilter, useOperators, usePredefinedLists } from '_/hooks/shared-hooks'
import { getGraphFooter, getInitialDateValues, getDateRange } from '_/model/reports/helpers'
import { formatActiveState } from '_/utils/format/common'
import OrganismGraph from './organism-pie-chart'
import ReportGraph from '../report-line-graph'
import LimitBreachSamples from './limit-breach-samples'
import KpiTable from './kpi-table/kpi-table'
import SessionBreaches from '../graph-by-day-session/session-breaches'

import type { AnalysisFilter } from '_/model/analysis/filter/types'
import { getGeneratedBy } from '_/features/samples/helpers'
import { loadLimitBreachFloorPlan } from '_/features/analysis/actions'
import { LIMIT_BREACH_FLOOR_PLAN } from '_/model/analysis/chart-type'
import { OPERATORS_IDS, EXPOSURE_LOCATION_ID } from '_/constants/custom-field-index'
import * as aggregationPeriod from '_/model/analysis/aggregation-period'
import * as helpers from '_/features/analysis/ui/helpers'
import OperatorLimitBreachFloorPlan from './operator-limit-breach-floor-plan'
import LeagueTablePositionKpiBlock from './kpi-box'
import { diffObject } from '_/utils/object'
import { fullNameLocationList } from '_/utils/exposure-location'
import FormattedText from '_/features/text/formatted-text'


const OperatorsReportPage = () => {
    const operators = useOperators()
        , timeService = useTimeService()
        , [values, changeFilter] = useOperatorsFilter(timeService)
        , canUseFloorPlans = useSelector(_ => _.auth.permissions.useFloorPlansCharts)
        , canExportData = useSelector(_ => _.auth.permissions.exportData)
        , [operatorReportResult, showSpinner] = useReport(values, canUseFloorPlans, timeService)
        , handleChangeFormValues = useFormValuesChangesHandler(values)
        , selectedOperator = operators.find(_ => _.id === values.operatorId)
        , [printMode, setPrintMode] = useState(false)
        , user = useSelector(_ => _.auth.user)
        , operatorName = selectedOperator ? formatActiveState(selectedOperator.name, selectedOperator.isActive) : [t.emptyTextNode()]
        , locations = fullNameLocationList(useSelector(_ => _.predefinedLists.exposureLocations))
        , floorPlanList = useSelector(_ => _.predefinedLists.floorPlans.plans)
        , floorPlans = operatorReportResult?.limitBreachFloorPlanGraphData?.series
            ? helpers.getFilteredFloorPlans(floorPlanList, getAnalysisFilter(values, timeService), locations, operatorReportResult.limitBreachFloorPlanGraphData.series)
            : []
        , floorPlanId = values.floorPlanId && floorPlans.find(_ => _.id === values.floorPlanId)
            ? values.floorPlanId
            : floorPlans[0]?.id
        , entityName = 'Operator: ' + t.plainText(operatorName)
        , predefinedLists = usePredefinedLists()

    useLoadDependentData()
    useResetOperatorsReport(changeFilter, setPrintMode)

    return (
        <div className='d-flex align-items-stretch h-100 w-100 width-print-100'>
            <Form
                onSubmit={changeFilter}
                initialValues={values}
                validate={validate}
                render={form =>
                    <div className='d-flex d-print-none flex-column bg-light side-filters'>
                        <form className='overflow-y-auto flex-fill' onSubmit={form.handleSubmit}>
                            <FormSpy
                                onChange={_ => handleChangeFormValues(form, _.values)}
                                subscription={{ values: true }}
                            />
                            <div className='px-3'>
                                <SelectField
                                    name='exposureDateRange'
                                    id='exposureDateRange'
                                    entities={DEFAULT_DATE_RANGES}
                                    calcId={_ => _.id}
                                    calcName={_ => _.name}
                                    placeholder='Select'
                                >
                                    Exposure date range
                                </SelectField>
                                {form.values.exposureDateRange === ranges.CUSTOM &&
                                    <div>
                                        <LocalDateField id='exposureStartDateFrom' name='exposureStartDateFrom'>From exposure date</LocalDateField>
                                        <LocalDateField id='exposureStartDateTo' name='exposureStartDateTo' useDayEndTime>To exposure date</LocalDateField>
                                    </div>
                                }
                                <SelectField
                                    name='operatorId'
                                    id='operatorId'
                                    entities={operators}
                                    calcId={_ => _.id}
                                    calcName={_ => formatActiveState(_.name, _.isActive)}
                                    placeholder='Select'
                                    testId='field-operators'
                                >
                                    Operator
                                </SelectField>
                            </div>
                        </form>
                    </div>
                }
            />
            <div className='h-100 w-100 width-print-100 px-0'>
                <div className='d-flex justify-content-center align-items-stretch h-100'>
                    {!selectedOperator && <div className='align-self-center' data-testid='not-selected-operator-placeholder'>Select an operator to view the detailed report</div>}
                    {selectedOperator &&
                        <div className='flex-fill overflow-y-auto'>
                            {user && printMode &&
                                <div className='ms-3'>
                                    {getGeneratedBy(timeService, user.name, user.email)}
                                </div>
                            }
                            <PageHeader sticky className='d-print-block' title={<span>Report for <FormattedText text={operatorName} /></span>}>
                                <Button
                                    className={classnames('align-self-start d-print-none btn-link me-1 ms-auto', {
                                        disabled: !canExportData,
                                    })}
                                    onClick={() => setPrintMode(!printMode)}
                                    hasNoPermissions={!canExportData}
                                >
                                    {printMode ? 'Report mode' : 'Print mode'}
                                </Button>
                                {printMode && <Button className='btn-primary align-self-start d-print-none me-1' onClick={window.print}>Print</Button>}
                            </PageHeader>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <LeagueTablePositionKpiBlock
                                        kpiBlockInfo={operatorReportResult?.leagueTablePositionKpiBox}
                                        operatorName={`${selectedOperator.name}'s${selectedOperator.isActive ? '' : ' (Inactive)'}`}
                                        operatorTotalCount={operators.length}
                                        operatorReport={values}
                                        showSpinner={showSpinner}
                                    />
                                </div>
                                <div className={classnames('row mt-3 d-print-block', { 'flex-column': printMode })}>
                                    <ReportGraph
                                        series={operatorReportResult?.limitBreachGraphData ?? []}
                                        timeService={timeService}
                                        name={t.plainText(operatorName)}
                                        routeParams={getSampleListParams(values, timeService, true)}
                                        printMode={printMode}
                                        graphTitle={[t.defaultTextNode('graph for viable samples with '), ...operatorName, t.defaultTextNode(' as operator')]}
                                        legendTitle='Exposure location grade'
                                        footer={getGraphFooter(values, entityName, timeService)}
                                        chartTitle='Limit breach rate'
                                        emptyMessage='No data available'
                                        showSpinner={showSpinner}
                                    />
                                    <ReportGraph
                                        series={operatorReportResult?.contaminationRateGraphData ?? []}
                                        timeService={timeService}
                                        name={t.plainText(operatorName)}
                                        routeParams={getSampleListParams(values, timeService)}
                                        printMode={printMode}
                                        graphTitle={[t.defaultTextNode('graph for viable samples with '), ...operatorName, t.defaultTextNode(' as operator')]}
                                        legendTitle='Exposure location grade'
                                        footer={getGraphFooter(values, entityName, timeService)}
                                        chartTitle='Contamination rate'
                                        emptyMessage='No data available'
                                        showSpinner={showSpinner}
                                    />
                                </div>
                                <div className='row mt-3'>
                                    <LimitBreachSamples
                                        operatorName={operatorName}
                                        operatorReport={values}
                                        predefinedLists={predefinedLists}
                                        printMode={printMode}
                                        entityName={entityName}
                                        itemsLimit={printMode ? ITEMS_PRINT_LIMIT_COUNT : ITEMS_LIMIT_COUNT}
                                        testId='limit-breaches-info'
                                    />
                                </div>
                                <div className={classnames('row mt-3 d-print-block', { 'flex-column': printMode })}>
                                    <div className='col-6 width-print-100'>
                                        <SessionBreaches
                                            name={t.plainText(operatorName)}
                                            printMode={printMode}
                                            routeParams={getSampleListParams(values, timeService, true)}
                                            sessionBreaches={operatorReportResult?.sessionBreaches ?? []}
                                            title={[t.defaultTextNode('Average limit breaches by day and session for viable samples with '), ...operatorName, t.defaultTextNode(' as operator')]}
                                            entityName={entityName}
                                            report={values}
                                            testId='average-breaches-by-session'
                                            showSpinner={showSpinner}
                                        />

                                        <KpiTable
                                            kpiTableData={operatorReportResult?.kpiTable ?? []}
                                            operatorName={operatorName}
                                            showSpinner={showSpinner}
                                            operatorReport={values}
                                            entityName={entityName}
                                            printMode={printMode}
                                        />
                                    </div>
                                    <OrganismGraph
                                        series={operatorReportResult?.organismGraphData ?? []}
                                        operatorName={operatorName}
                                        operatorReport={values}
                                        printMode={printMode}
                                        showSpinner={showSpinner}
                                        footer={getGraphFooter(values, entityName, timeService)}
                                    />
                                </div>
                                {canUseFloorPlans &&
                                    <div className='row mt-3'>
                                        <div className='col-12'>
                                            <OperatorLimitBreachFloorPlan
                                                analysisFilter={getAnalysisFilter(values, timeService)}
                                                reportView={operatorReportResult}
                                                operatorName={operatorName}
                                                floorPlanId={floorPlanId}
                                                onFloorPlanIdChange={floorPlanId => changeFilter({...values, floorPlanId})}
                                                floorPlans={floorPlans}
                                                printMode={printMode}
                                                showSpinner={showSpinner}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default OperatorsReportPage

function useOperatorsFilter(timeService: TimeService) {
    const [values, changeFilter] = useFilter<OperatorsReport>('operator-report', _ => ({ ...getInitialValues(timeService), ..._ }))

    return [values, changeFilter] as const
}

function getInitialValues(timeService: TimeService): OperatorsReport {
    return {
        operatorId: undefined,
        ...getInitialDateValues(timeService),
    }
}

function useLoadDependentData() {
    const loadLocations = useAction(loadExposureLocationList)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadLocations()
        },
        [contextSwitch, loadLocations]
    )
}

function useReport(filter: OperatorsReport, canUseFloorPlans: boolean, timeService: TimeService) {
    const [operatorReportResult, setOperatorReportResult] = useState<OperatorsReportView>()
        , loadData = useAction(loadOperatorsReportData)
        , loadFloorPlanData = useAction(loadLimitBreachFloorPlan)
        , [showSpinner, setShowSpinner] = useState(false)

    useEffect(
        () => {
            const isFilterValid = Object.keys(validate(filter)).length === 0

            if (!isFilterValid)
                return

            let disposed = false

            setShowSpinner(true)
            Promise.all([
                    loadData({
                        id: filter.operatorId!,
                        request: getDateRange(timeService, filter)
                    }),
                    canUseFloorPlans ? loadFloorPlanData(getAnalysisFilter(filter, timeService)) : undefined
                ])
                .then(([operatorReportResult, limitBreachFloorPlanGraphData]) => {
                    if (disposed)
                        return

                    setOperatorReportResult({...operatorReportResult, limitBreachFloorPlanGraphData})
                })
                .finally(() => setShowSpinner(false))

            return () => { disposed = true }
        },
        [filter, canUseFloorPlans, timeService, loadData, loadFloorPlanData]
    )

    return [operatorReportResult, showSpinner] as const
}

function useFormValuesChangesHandler(initialValues: OperatorsReport) {
    return (form: FormRenderProps, values: OperatorsReport) => {
        if (!diffObject(initialValues, values))
            return

        if (values.operatorId !== initialValues.operatorId && values.floorPlanId !== undefined) {
            form.form.change('floorPlanId', undefined)
            return
        }

        form.form.submit()
    }
}

function useResetOperatorsReport(setValues: (_: OperatorsReport) => void, setPrintMode: (_: boolean) => void) {
    const contextSwitch = useContextSwitchObserver()
        , timeService = useTimeService()

    useEffect(
        () => {
            if (contextSwitch === 0)
                return

            setValues(getInitialValues(timeService))
            setPrintMode(false)
        },
        [contextSwitch, timeService, setValues, setPrintMode]
    )
}

function getAnalysisFilter(operatorReport: OperatorsReport, timeService: TimeService): AnalysisFilter {
    return {
        aggregationPeriod: aggregationPeriod.MONTH,
        chartType: LIMIT_BREACH_FLOOR_PLAN,
        includeCompromised: false,
        seriesFieldIndex: OPERATORS_IDS,
        fields: [{index: OPERATORS_IDS, value: [operatorReport.operatorId]}, {index: EXPOSURE_LOCATION_ID}],
        exposureDateRange: operatorReport.exposureDateRange,
        ...getDateRange(timeService, operatorReport, false),
        cumulativeView: true,
        timeSliderPosition: undefined,
        includeNotReadSamples: false,
    }
}
