import type { State as RouterState } from 'router5'
import type * as routes from '_/constants/routes'

function isBaseRoute(name: routes.RouteName, routerState: RouterState) {
    const currentName = routerState.name

    return currentName === name || currentName.startsWith(name + '.')
}

export { isBaseRoute }
