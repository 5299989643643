import { useState, useEffect } from '_/facade/react'
import Button from '_/components/button'
import { Table } from '_/components/table'
import * as routes from '_/constants/routes'
import type { AttachedFile, FailedFile, UploadedFile } from '_/model/sample/files/files'
import { Link } from '_/utils/router'
import { convertToReadableBytes } from '../../helpers'
import { useTimeService } from '_/components/time'
import { ITEMS_LIMIT_COUNT } from '_/constants/items-limit-count'

type FileItem =
    { tag: 'attached', file: AttachedFile }
    | { tag: 'uploaded', file: UploadedFile }
    | { tag: 'failed', file: FailedFile }

interface Props {
    files: FileItem[]
    canEdit: boolean
    canDelete: (id: string) => boolean
    onRemove: (id: string) => void
    onDownload: (id: string, name: string) => void
}

function FileList(props: Props) {
    const [pagination, setPagination] = useState({ start: 0, count: ITEMS_LIMIT_COUNT })

    useEffect(
        () => setPagination({ start: 0, count: ITEMS_LIMIT_COUNT }),
        [props.files]
    )

    return(
        <Table className='table-layout-fixed mb-0'>
            <thead className='thead'>
                <tr className='table-header-thin'>
                    <th style={{width: '100%'}}>File name</th>
                    <th style={{width: '8em'}}>Size</th>
                    <th style={{width: '3em'}} />
                </tr>
            </thead>
            <tbody>
                {props.files.slice(pagination.start, pagination.start + pagination.count).map(file =>
                    <Row
                        key={file.file.id}
                        file={file}
                        canEdit={props.canEdit}
                        canDelete={props.canDelete}
                        onRemove={props.onRemove}
                        onDownload={props.onDownload}
                    />
                )}
            </tbody>
            {props.files.length > ITEMS_LIMIT_COUNT &&
                <tfoot>
                    <tr>
                        <td colSpan={3} className='pb-0'>
                            <div className='d-flex justify-content-center'>
                                {pagination.count > ITEMS_LIMIT_COUNT
                                    ? <Button className='btn-link shadow-none no-underline d-flex flex-column align-items-center' onClick={() => setPagination({ start: 0, count: ITEMS_LIMIT_COUNT })}>
                                        <i className='material-icons'>expand_less</i>
                                        <span className='text-decoration-underline--on-hover'>Show fewer</span>
                                    </Button>
                                    : <Button className='btn-link shadow-none no-underline d-flex flex-column align-items-center' onClick={() => setPagination({ start: 0, count: props.files.length })}>
                                        <span className='text-decoration-underline--on-hover'>Show all ({props.files.length} attachments)</span>
                                        <i className='material-icons'>expand_more</i>
                                    </Button>
                                }
                            </div>
                        </td>
                    </tr>
                </tfoot>
            }
        </Table>
    )
}

interface RowProps {
    file: FileItem
    canEdit: boolean
    canDelete: (id: string) => boolean
    onRemove: (id: string) => void
    onDownload: (id: string, name: string) => void
}

function Row(props: RowProps) {
    const file = props.file
        , timeService = useTimeService()

    if (file.tag === 'failed') {
        return (
            <tr data-testid={`attachment-file-${file.file.id}`}>
                <td>
                    <div
                        className='text-muted single-line-text'
                        title={file.file.fileName}
                        data-testid = {`attachment-name-${file.file.fileName}`}
                    >
                        {file.file.fileName}
                    </div>
                    <div className='fw-bold text-danger' data-testid='attachment-error'>{file.file.error}</div>
                    <div>
                        <Link
                            routeName={routes.HELP}
                            routeParams={{ page: '/tutorials-sc/samples/edit_sample/' }}
                            className='btn-link ps-0'
                            data-testid={`attachment-link-${file.file.id}`}
                        >
                            See what restrictions apply to attachments
                        </Link>
                    </div>
                </td>
                <td />
                <td />
            </tr>
        )
    }

    if (file.tag === 'uploaded') {
        return (
            <tr data-testid={`attachment-file-${file.file.id}`}>
                <td>
                    <div
                        className='fw-bold single-line-text'
                        title={file.file.fileName}
                        data-testid={`attachment-name-${file.file.fileName}`}
                    >
                        {file.file.fileName}
                    </div>
                </td>
                <td className='text-muted'>
                    Uploading...
                </td>
                <td className='text-muted'>
                    <i className='material-icons upload-spinner' data-testid='uploading'>loop</i>
                </td>
            </tr>
        )
    }

    return (
        <tr key={file.file.id} data-testid={`attachment-file-${file.file.id}`}>
            <td>
                <a
                    onClick={e => { e.preventDefault(); props.onDownload(file.file.id, file.file.fileName) }}
                    href='#'
                    className='d-block single-line-text fw-bold cursor-pointer'
                    title={file.file.fileName}
                    data-testid={`attachment-name-${file.file.fileName}`}
                >
                    {file.file.fileName}
                </a>
                <div
                    className='text-muted single-line-text'
                    title={`Added ${timeService.formatCtzDateTime(file.file.uploadedAt)}`}
                    data-testid = 'attachment-uploaded-date'
                >
                    Added {timeService.formatCtzDateTime(file.file.uploadedAt)}
                </div>
                <div
                    className='text-muted single-line-text'
                    title={`by ${file.file.uploadedBy.email}`}
                    data-testid = 'attachment-uploaded-by'
                >
                    by {file.file.uploadedBy.email}
                </div>
            </td>
            <td data-testid='attachment-size'>
                {convertToReadableBytes(file.file.fileSize)}
            </td>
            <td>
                {props.canEdit &&
                    <Button
                        onClick={() => props.onRemove(file.file.id)}
                        disabled={!props.canDelete(file.file.id)}
                        className='p-0 d-print-none'
                        testId={`attachment-delete-${file.file.fileName}`}
                    >
                        <i className='material-icons'>delete</i>
                    </Button>
                }
            </td>
        </tr>
    )
}

export default FileList

export type {
    FileItem
}
