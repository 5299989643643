import { classnames, useState } from '_/facade/react'
import Button from '_/components/button'
import UserTagInput from '_/components/tag/user-tag-input'
import { validateComment } from '_/features/samples/validate'
import { useUpdateEffect } from '_/hooks/shared-hooks'

interface SampleCommentProps {
    value: string
    compromisedStatusChanged: boolean
    onCommentChange: (comment: string, isValid: boolean) => void
    testId?: string
}

function SampleComments(props: SampleCommentProps) {
    const [validationResult, handleChangeValidationResult] = useState<string>()

        , validate = (message: string) => {
            const comment = { message }
                , commentValidationResult =  validateComment(comment, props.compromisedStatusChanged)

            handleChangeValidationResult(commentValidationResult)
            props.onCommentChange(comment.message, !commentValidationResult)
        }

    useUpdateEffect(
        () => {
            validate(props.value)
        },
        // eslint-disable-next-line
        [props.compromisedStatusChanged]
    )

    return (
        <CommentView
            value={props.value}
            onChange={validate}
            error={validationResult}
            testId={props.testId}
        />
    )
}

interface CommentViewProps {
    error: string | undefined
    value: string
    onChange: (value: string) => void
    onPost?: () => void
    testId?: string
}

function CommentView(props: CommentViewProps) {
    return (
        <div className='position-relative d-print-none comments-padding-top'>
            <UserTagInput
                value={props.value}
                onChange={props.onChange}
                className={classnames('form-control comment-input', { 'is-invalid': props.error, 'w-100': !props.onPost })}
                placeholder='Type comment here'
                testId={props.testId}
            />
            {props.error && <span className='invalid-feedback' data-testid='validation-error'>{props.error}</span>}
            {props.onPost &&
                <div className='comment-send-button'>
                    <Button
                        className='btn-light material-icons blue-focus'
                        disabled={!!props.error || props.value === ''}
                        onClick={props.onPost}
                        testId='send-comment'
                    >
                        send
                    </Button>
                </div>
            }
        </div>
    )
}

export default SampleComments

export { CommentView }
