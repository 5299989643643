import ClippedShape from './clipped-shape'
import { toPath } from './helpers'

interface Props {
    size: number
}

function Octagon(props: Props) {
    // https://en.wikipedia.org/wiki/Octagon
    // https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Octagon_in_square.svg/250px-Octagon_in_square.svg.png
    const sqrt2 = Math.sqrt(2)
        , a = props.size / (2 / sqrt2 + 1)
        , halfSize = props.size / 2
        , halfA = a / 2
        , path: [number, number][] = [
            [-halfA, -halfSize],
            [halfA, -halfSize],
            [halfSize, -halfA],
            [halfSize, halfA],
            [halfA, halfSize],
            [-halfA, halfSize],
            [-halfSize, halfA],
            [-halfSize, -halfA],
        ]

    return <ClippedShape path={toPath(path)} size={props.size} />
}

export default Octagon
