import { actionFactory } from '_/utils/redux'
import type { RouteName } from '_/constants/routes'
interface ShowWarning {
    showWarning: boolean
    targetState?: {
        name: RouteName
        params?: any
    }
}

const action = actionFactory('app.unsaved-changes')

const hasUnsavedChangesInternal = action<{ hasChanges: boolean, changeTarget: string }>('hasUnsavedChangesInternal')
export const showWarning = action<ShowWarning>('showWarning')
           , discardChanges = action<void>('discardChanges')

export function hasUnsavedChanges(hasChanges: boolean, changeTarget = 'default') {
    return hasUnsavedChangesInternal({ hasChanges, changeTarget })
}

hasUnsavedChanges.type = hasUnsavedChangesInternal.type
