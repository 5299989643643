export const AVERAGE_CFU_CHART = 0
           , ORGANISMS_BREAKDOWN_CHART = 1
           , TOTAL_CFU_CHART = 2
           , CONTAMINATED_SAMPLES = 3
           , ALERT_LIMIT_BREACHES = 4
           , ACTION_LIMIT_BREACHES = 5
           , CONTAMINATION_FLOOR_PLAN = 6
           , LIMIT_BREACH_FLOOR_PLAN = 7
           , TOTAL_SAMPLES_READ = 8
           , ORGANISM_TYPE_BREAKDOWN = 9
           , STACKED_SAMPLE_NAME = 10
           , PARTICLE_COUNTS = 11

type ChartType = never
    | typeof AVERAGE_CFU_CHART
    | typeof ORGANISMS_BREAKDOWN_CHART
    | typeof TOTAL_CFU_CHART
    | typeof CONTAMINATED_SAMPLES
    | typeof ALERT_LIMIT_BREACHES
    | typeof ACTION_LIMIT_BREACHES
    | typeof CONTAMINATION_FLOOR_PLAN
    | typeof LIMIT_BREACH_FLOOR_PLAN
    | typeof TOTAL_SAMPLES_READ
    | typeof ORGANISM_TYPE_BREAKDOWN
    | typeof STACKED_SAMPLE_NAME
    | typeof PARTICLE_COUNTS

type LinesMarkersChart = never
    | typeof AVERAGE_CFU_CHART
    | typeof TOTAL_CFU_CHART
    | typeof CONTAMINATED_SAMPLES
    | typeof ALERT_LIMIT_BREACHES
    | typeof ACTION_LIMIT_BREACHES
    | typeof TOTAL_SAMPLES_READ
    | typeof PARTICLE_COUNTS

type PieChart = typeof ORGANISMS_BREAKDOWN_CHART

type FloorPlanChart = typeof CONTAMINATION_FLOOR_PLAN | typeof LIMIT_BREACH_FLOOR_PLAN

type ChartTypeWithSubCharts = typeof ACTION_LIMIT_BREACHES | typeof CONTAMINATED_SAMPLES | typeof ALERT_LIMIT_BREACHES

const CHART_TYPE: { id: ChartType, name: string }[] = [
    {
        id: AVERAGE_CFU_CHART,
        name: 'Average CFUs',
    },
    {
        id: ORGANISMS_BREAKDOWN_CHART,
        name: 'Organism identification breakdown',
    },
    {
        id: ORGANISM_TYPE_BREAKDOWN,
        name: 'Organism type breakdown'
    },
    {
        id: STACKED_SAMPLE_NAME,
        name: 'Stacked sample name'
    },
    {
        id: TOTAL_CFU_CHART,
        name: 'Total CFUs',
    },
    {
        id: TOTAL_SAMPLES_READ,
        name: 'Total samples read',
    },
    {
        id: CONTAMINATED_SAMPLES,
        name: 'Contaminated samples',
    },
    {
        id: ALERT_LIMIT_BREACHES,
        name: 'Alert limit breaches',
    },
    {
        id: ACTION_LIMIT_BREACHES,
        name: 'Action limit breaches',
    },
    {
        id: CONTAMINATION_FLOOR_PLAN,
        name: 'Contamination floor plan',
    },
    {
        id: LIMIT_BREACH_FLOOR_PLAN,
        name: 'Limit breach floor plan',
    },
    {
        id: PARTICLE_COUNTS,
        name: 'Particle counts',
    },
]

type CHART_TYPE = typeof CHART_TYPE

export default CHART_TYPE
export {
    ChartType,
    LinesMarkersChart,
    PieChart,
    FloorPlanChart,
    ChartTypeWithSubCharts,
}
