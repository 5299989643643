import type ApiService from '../api-service'
import type AnalysisService from '../analysis-service'
import type AnalysisQuery from '_/model/analysis/filter/types'
import type AnalysisSeries from '_/model/analysis/analysis-series'
import type OrganismsBreakdownSeries from '_/model/analysis/organisms-breakdown-series'
import { formatHintTitle } from '_/model/analysis/organism-type-breakdown-series'
import type { SeriesData} from '_/model/analysis/stacked-sample-name-series'
import { convertToDateSeriesGraphData, formatTitle } from '_/model/analysis/stacked-sample-name-series'
import { formatTotalSamplesHoverInfo } from '_/model/analysis/line-graph-series'
import type ContaminationFloorPlanSeries from '_/model/floor-plan/contamination-floor-plan-series'
import type LimitBreachFloorPlanSeries from '_/model/floor-plan/limit-breach-floor-plan-series'
import type * as t from '_/model/analysis/types'
import * as chartType from '_/model/analysis/chart-type'
import { downloadBlob } from '_/utils/blob'
import type TimeService from '../time-service'
import { generateDateRange, graphSeriesName, isAggregationAllowed, isRateChart } from '_/features/analysis/ui/helpers'
import type { DateSeriesGraphData } from '_/model/analysis/date-series'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import { formatActiveState } from '_/utils/format/common'
import { defaultTextNode, plainText } from '_/model/text/text'
import { getOrganismTypeBreakdownColor, getStackedSampleNameColor } from '_/model/analysis/series-color'

function factory(_api: ApiService, time: TimeService): AnalysisService {
    return {
        linesMarkersChartSeries,
        organismsBreakdown,
        organismTypeBreakdown,
        stackedSampleName,
        contaminationFloorPlan,
        limitBreachFloorPlan,
        exportGraphCsv,
        exportAnalysisGraphData,
    }

    function linesMarkersChartSeries(query: AnalysisQuery, fields: CustomField[]): Promise<DateSeriesGraphData> {
        const { exposureStartDateFrom: dateFrom, exposureStartDateTo: dateTo } = query
            , aggregationAllowed = isAggregationAllowed(query.chartType)
            , dates = generateDateRange(time, aggregationAllowed ? query.aggregationPeriod : undefined, { dateFrom, dateTo })
            , seriesTitle = graphSeriesName(query.seriesFieldIndex, fields) ?? ''
            , graphName = chartType.default.find(_ => _.id === query.chartType)?.name ?? ''

        return Promise.all([
            _api.post<AnalysisSeries[]>(['analysis'], query),
            query.includeTotalSamples
                ? _api.post<AnalysisSeries[]>(['analysis'], {...query, chartType: chartType.TOTAL_SAMPLES_READ, includeTotalSamples: false, seriesFieldIndex: undefined})
                : undefined
            ])
            .then(([series, totalSamples]) => {
                const mainSeries: SeriesData = {
                        series: series,
                        type: 'line',
                        mode: query.joinPoints === false ? 'markers' : 'lines+markers',
                        nameFormat: (_) => {
                            return query.includeTotalSamples && !_.title.name
                                ? [defaultTextNode(graphName)]
                                : formatTitle(_.title)
                        },
                        textFormat: query.chartType !== chartType.PARTICLE_COUNTS
                            ? undefined
                            : (_series, count, _cfuCount, date) => count !== undefined && date !== undefined
                                ? `${count}, ${time.formatCtzDateTime(date)}`
                                : '',
                        unitOfMeasure: isRateChart(query.subChartType) ? '%' : '',
                    }
                    , additionalSeries: SeriesData = {
                        series: totalSamples ?? [],
                        type: 'bar',
                        nameFormat: () => [defaultTextNode('Total number of samples read')],
                        textFormat: (_, _count, _cfuCount, date) => formatTotalSamplesHoverInfo(series, date!, seriesTitle),
                        hoverTemplate: 'Total samples read: %{y}' + `${series.length === 1 ? '' : '<br>'}` + '%{hovertext} <extra></extra>',
                    }

                return convertToDateSeriesGraphData(
                    mainSeries,
                    additionalSeries,
                    dates,
                    query.includeTotalSamples,
                    query.chartType as chartType.LinesMarkersChart,
                    [`${graphName}${isRateChart(query.subChartType) ? ', %' : ''}`, 'Total number of samples read'],
                    !aggregationAllowed
                )
            })
    }

    function organismsBreakdown(_query: AnalysisQuery): Promise<t.OrganismsBreakdownSeriesGraphData> {
        return _api.post<OrganismsBreakdownSeries>(['analysis', 'organismcfu'], _query)
            .then(_ => ({
                chartType: chartType.ORGANISMS_BREAKDOWN_CHART,
                series: _,
            }))
    }

    function organismTypeBreakdown(query: AnalysisQuery): Promise<DateSeriesGraphData> {
        const { exposureStartDateFrom: dateFrom, exposureStartDateTo: dateTo } = query
            , dates = generateDateRange(time, query.aggregationPeriod, { dateFrom, dateTo })

        return _api.post<t.OrganismTypeBreakdownSeries>(['analysis', 'organism-type'], query)
            .then(_ =>
                convertToDateSeriesGraphData(
                    {
                        series: _.cfuSeries.concat().reverse(),
                        type: 'bar',
                        nameFormat: (_) => formatTitle(_.title),
                        textFormat: (_, count, cfuCount) => `${formatHintTitle(plainText(formatActiveState(_.title.name ?? '', _.title.isActive)))} ${count}% ${cfuCount} CFU`,
                        unitOfMeasure: '%',
                        hoverTemplate: '%{hovertext}<extra></extra>',
                    },
                    {
                        series: [
                            { series: _.contaminationRate ?? [], title: { name: 'Contamination rate' } },
                            { series: _.excursionRate ?? [], title: { name: 'Excursion rate' } },
                        ],
                        type: 'line',
                        nameFormat: (_) => formatTitle(_.title),
                        textFormat: (_, count) => `${plainText(formatActiveState(_.title.name ?? '', _.title.isActive))} ${count}%`,
                        unitOfMeasure: '%',
                        hoverTemplate: '%{hovertext}<extra></extra>',
                    },
                    dates,
                    _.contaminationRate && _.contaminationRate.length !== 0,
                    chartType.ORGANISM_TYPE_BREAKDOWN,
                    [`${chartType.default.find(_ => _.id === chartType.ORGANISM_TYPE_BREAKDOWN)!.name}, %`, 'Contamination and limit breach rates, %'],
                    !isAggregationAllowed(query.chartType),
                    getOrganismTypeBreakdownColor,
                )
            )
    }

    function stackedSampleName(query: AnalysisQuery): Promise<DateSeriesGraphData> {
        const { exposureStartDateFrom: dateFrom, exposureStartDateTo: dateTo } = query
            , dates = generateDateRange(time, query.aggregationPeriod, { dateFrom, dateTo })

        return _api.post<t.StackedSampleNameSeries[]>(['analysis', 'stacked-sample-name'], query)
            .then(_ =>
                convertToDateSeriesGraphData(
                    {
                        series: _.map(_ =>
                            ({
                                ..._,
                                series: _.series.map(s => ({utcDateTime: s.utcDateTime, count: s.cfuCount})),
                            })
                        ),
                        type: 'bar',
                        nameFormat: (_) => [...formatActiveState(_.title.name ?? '', _.title.isActive), defaultTextNode(', CFU')],
                        textFormat: (_, v) => `${plainText(formatActiveState(_.title.name ?? '', _.title.isActive))} ${v} CFU`,
                        hoverTemplate: '%{hovertext}<extra></extra>',
                    },
                    {
                        series: _.map(_ => ({..._, series: _.series.map(s => ({utcDateTime: s.utcDateTime, count: s.contaminationRate}))})),
                        type: 'line',
                        nameFormat: (_) => [...formatActiveState(_.title.name ?? '', _.title.isActive), defaultTextNode(', %')],
                        textFormat: (_, v) => `${plainText(formatActiveState(_.title.name ?? '', _.title.isActive))} ${v}%`,
                        unitOfMeasure: '%',
                        hoverTemplate: '%{hovertext}<extra></extra>',
                    },
                    dates,
                    query.includeContaminationRates,
                    chartType.STACKED_SAMPLE_NAME,
                    ['Number of CFUs', 'Contamination rate, %'],
                    !isAggregationAllowed(query.chartType),
                    getStackedSampleNameColor,
                )
            )
    }

    function contaminationFloorPlan(query: AnalysisQuery): Promise<t.ContaminationFloorPlanSeriesGraphData> {
        return _api.post<ContaminationFloorPlanSeries[]>(['analysis', 'contamination-floor-plan'], query)
            .then(_ => ({
                chartType: chartType.CONTAMINATION_FLOOR_PLAN,
                series: _,
            }))
    }

    function limitBreachFloorPlan(query: AnalysisQuery): Promise<t.LimitBreachFloorPlanSeriesGraphData> {
        return _api.post<LimitBreachFloorPlanSeries[]>(['analysis', 'limit-breaches-floor-plan'], query)
            .then(_ => ({
                chartType: chartType.LIMIT_BREACH_FLOOR_PLAN,
                series: _,
            }))
    }

    function exportGraphCsv(query: AnalysisQuery) {
        return _api.getFileForLongQuery(['analysis', 'graph', 'export'], query)
    }

    function exportAnalysisGraphData(files: {blob: Blob, filename: string}[]) {
        const formData = new FormData()
        files.forEach(_ => formData.append('files', _.blob, _.filename))

        return _api.postFile(['analysis', 'graph', 'export', 'zip'], formData)
            .then(_ => downloadBlob(_.blob, _.filename))
    }
}

export default factory
