import { verticalScrollParent } from '_/components/overlay/menu'
import { useEffect, useReducer, useSelector, useState } from '_/facade/react'

type ScrollTarget = 'none' | 'fingerdab-table' | 'organism-pie-chart' | 'session-breaches'

function useScrollTo(divElement: HTMLDivElement | null, scrollTarget: ScrollTarget) {
    /**
     * Current problem is that parent element height might grow when extra content is loaded
     * after automatic scroll is performed.
     *
     * Solution is to track parent content changes and re-scroll until user has interacted with
     * parent element, e.g. using wheel or clicking elements.
     */

    const routeParams = useSelector(_ => _.router.route?.params)
        , scrollTo: ScrollTarget = routeParams?.scrollTo
        , [containerInteracted, setContainerInteracted] = useState(false)
        , active = scrollTo === scrollTarget && !containerInteracted
        , [scrollParent, setScrollParent] = useState<HTMLElement>()
        , [counter, incCounter] = useReducer(_ => _ + 1, 0)

    useEffect(
        () => {
            const scrollParent = divElement && verticalScrollParent(divElement)
            setScrollParent(scrollParent ?? undefined)
        },
        [divElement]
    )

    useEffect(
        () => {
            if (!active || !scrollParent)
                return

            const observer = new MutationObserver(() => { incCounter() })

            observer.observe(scrollParent, { subtree: true, childList: true, attributes: true, characterData: true })

            function handleInteracted() {
                setContainerInteracted(true)
            }

            scrollParent.addEventListener('pointerdown', handleInteracted)
            scrollParent.addEventListener('mousedown', handleInteracted)
            scrollParent.addEventListener('keydown', handleInteracted)
            scrollParent.addEventListener('wheel', handleInteracted)

            return () => {
                observer.disconnect()

                scrollParent.removeEventListener('pointerdown', handleInteracted)
                scrollParent.removeEventListener('mousedown', handleInteracted)
                scrollParent.removeEventListener('keydown', handleInteracted)
                scrollParent.removeEventListener('wheel', handleInteracted)
            }
        },
        [active, scrollParent]
    )

    useEffect(
        () => {
            if (!active)
                return

            divElement?.scrollIntoView()
        },
        [active, divElement, counter]
    )
}

export {
    useScrollTo,
    ScrollTarget,
}
