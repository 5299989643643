import type { OperatorsReportView } from '_/model/reports/operators-report/types'
import type { ReportRequest } from '_/model/reports/types'
import { downloadBlob } from '_/utils/blob'

import type ApiService from '../api-service'
import type OperatorsReportService from '../operators-report-service'

function factory(api: ApiService): OperatorsReportService {
    return { getOperatorsReportData, exportOperatorContaminationBreachRates }

    function getOperatorsReportData(id: string, request: ReportRequest): Promise<OperatorsReportView> {
        return api.get<OperatorsReportView>(['operators', id, 'report'], request)
    }

    function exportOperatorContaminationBreachRates(id: string, request: ReportRequest): Promise<void>{
        return api.getFile(['operators', id, 'export-contamination-breach-rate-by-grade'], request)
            .then(_ => downloadBlob(_.blob, _.filename))
    }
}

export default factory
