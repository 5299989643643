import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type PredefinedListsService from '_/services/predefined-lists-service'

import { updateTransaction } from '_/model/predefined-lists/custom-field/transaction'
import * as spinnerActions from '_/features/spinner/actions'

import * as actions from './actions'

const factory = (service: PredefinedListsService): EffectsFactory => dispatch => [
    handler(actions.loadCustomFields, () =>
        service.customField.list()
            .then(_ => {
                dispatch(actions.customFieldsLoaded(_))
                return _
            })
    ),

    handler(actions.loadCustomFieldsIncludingInactive, () => service.customField.list()),

    handler(actions.loadCustomField, service.customField.get),

    handler(actions.loadCustomFieldTrail, service.customField.getTrail),

    handler(actions.createCustomField, service.customField.create),

    handler(actions.saveCustomField, data =>
        Promise.resolve()
            .then(() => dispatch(spinnerActions.showSpinner()))
            .then(() => updateTransaction(data.id, data.oldCustomField, data.newCustomField, service))
            .finally(() => dispatch(spinnerActions.hideSpinner()))
    ),

    handler(actions.parseImportFile, service.customField.parseImportFile),

    handler(actions.searchFieldValues, service.customField.search),

    handler(actions.removeCustomField, service.customField.remove),
]

export default factory
