import * as breachTypes from '_/constants/sample-breach-type'
import { SampleLabel } from '_/components/sample-labels'

function SampleLimitLabel(props: { breach: breachTypes.BreachType }) {
    return (
        <>
            {props.breach === breachTypes.ACTION_LIMIT && <SampleLabel text='Action' className='label-action--color' />}
            {props.breach === breachTypes.ALERT_LIMIT && <SampleLabel text='Alert' className='label-alert--color' />}
        </>
    )
}

export default SampleLimitLabel
