import { useNonViableLimits } from '_/hooks/shared-hooks'

import { Table, EmptyTableMessage } from '_/components/table'
import Button from '_/components/button'
import { useTimeService } from '_/components/time'

import type { NonViableSample } from '_/model/non-viable-sample/booking/types'
import type { PredefinedLists } from '_/model/app-state'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import * as f from '_/model/sample/format'
import { formatExposureDurationByFields } from '_/model/non-viable-sample/format'
import FormattedText from '_/features/text/formatted-text'

import * as fieldIndex from '_/constants/custom-field-index'

import AddCommentsIcon from '_/features/samples/booking/sample-add-comments-icon'
import { formatParticleValue } from '_/model/non-viable-sample/format'

import { customFieldName } from '_/model/predefined-lists/custom-field/custom-field'
import { getFieldValue } from '_/features/samples/helpers'
import * as h from '_/features/samples/booking/helpers'
import SampleLimitLabel from '../sample-limit-label'
import { calculateBreachType } from '_/model/non-viable-sample/booking/helpers'

interface Props {
    onOpenCommentsModal: (nonViableSample: NonViableSample) => void
    samples: NonViableSample[]
    exposureLocations: ListExposureLocation[]
    onOpenNonViableSampleEditModal: (nonViableSample: NonViableSample) => void
    predefinedLists: PredefinedLists
}

function NonViableSampleList(props: Props) {
    const timeService = useTimeService()
        , nonViableLimits = useNonViableLimits()

    function getSamples() {
        return props.samples
            .slice()
            .sort((reading1, reading2) => h.compareByExposureDate(reading1.fields, reading2.fields))
            .reverse()
    }

    function columnName(fieldIndex: fieldIndex.FieldIndex) {
        return customFieldName(fieldIndex, props.predefinedLists.customFields)
    }

    return (
        <Table>
            <thead className='thead table-header--sticky'>
                <tr className='table-header'>
                    <th>{columnName(fieldIndex.BARCODE)}</th>
                    <th>{columnName(fieldIndex.EXPOSURE_START_DATE)}</th>
                    <th>{columnName(fieldIndex.EXPOSURE_LOCATION_ID)}</th>
                    <th>{columnName(fieldIndex.SESSION_ID)}</th>
                    <th>{columnName(fieldIndex.OPERATORS_IDS)}</th>
                    <th>{columnName(fieldIndex.BATCH_NUMBER)}</th>
                    <th>0.5μm particles</th>
                    <th>5μm particles</th>
                    <th/>
                    <th/>
                </tr>
            </thead>
            <tbody className='border-top-0'>
                {props.samples.length === 0
                    ? <EmptyTableMessage colSpan={10} message='There are no recently added non-viable samples at the moment' />
                    : getSamples().map(_ =>
                        <tr key={_.id}>
                            <td>{getFieldValue(_.fields, fieldIndex.BARCODE)}</td>
                            <td>
                                {timeService.formatCtzDate(getFieldValue(_.fields, fieldIndex.EXPOSURE_START_DATE))}{' '}
                                <FormattedText text={formatExposureDurationByFields(_.fields, timeService)} />
                            </td>
                            <td><FormattedText text={f.formatExposureLocation(_.monitoringState, _.fields, props.predefinedLists)} /></td>
                            <td><FormattedText text={f.formatByFieldIndex(fieldIndex.SESSION_ID, _.fields, props.predefinedLists)} /></td>
                            <td><FormattedText text={f.formatOperatorsShort(_.fields, props.predefinedLists)} /></td>
                            <td><FormattedText text={f.formatBatchNumbersShort(_.fields)} /></td>
                            <td>
                                <FormattedText text={formatParticleValue(_.lowerParticle)} />
                                <SampleLimitLabel breach={calculateBreachType(_, props.exposureLocations, nonViableLimits)} />
                            </td>
                            <td>
                                <FormattedText text={formatParticleValue(_.higherParticle)} />
                                <SampleLimitLabel breach={calculateBreachType(_, props.exposureLocations, nonViableLimits, true)} />
                            </td>
                            <td><AddCommentsIcon numberOfComments={_.comments.length} onClick={() => props.onOpenCommentsModal(_)} /></td>
                            <td>
                                <Button
                                    className='py-0 px-1 bg-white'
                                    onClick={() => props.onOpenNonViableSampleEditModal(_)}
                                >
                                    <i className='material-icons md-18'>create</i>
                                </Button>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </Table >
    )
}

export default NonViableSampleList
