import { classnames } from '_/facade/react'

import type SampleDetails from '_/model/sample/sample-details'
import * as growthsStatus from '_/constants/growth-status'
import FormattedText from '_/features/text/formatted-text'

import { calculateTotalCFU, isBreachedByBehaviour, contaminations } from '../helpers'
import { computeContaminationInfo } from '../sample-edit/helpers'

interface Props {
    entity: SampleDetails
    isOptionalGrowth: boolean
}

function ContaminationInfo(props: Props) {
    const info = computeContaminationInfo(props)

    return (
        <div>
            <div className='row'>
                <div className='col-4' data-testid='contamination-info-label'>Growth status</div>
                <div className='col-8 sample-reading__word-wrap user-formatted-text fw-bold'>
                    <div>{info.growthsStatusName}</div>
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='contamination-info-label'>Total CFUs</div>
                <div className='col-8 sample-reading__word-wrap user-formatted-text fw-bold'>{
                    <div>
                        {info.growthsStatusId === growthsStatus.UNIDENTIFIED_ID ? '' : calculateTotalCFU(info.growths)}
                        {(info.limitBreachedByTotalCfu.actionLimitBreached || info.limitBreachedByTotalCfu.alertLimitBreached) &&
                            <span className={classnames('ms-2 p-1 text-uppercase small fw-bold rounded text-white', {
                                'label-alert--color': info.limitBreachedByTotalCfu.alertLimitBreached,
                                'label-action--color': info.limitBreachedByTotalCfu.actionLimitBreached,
                            })}>
                                {info.limitBreachedByTotalCfu.actionLimitBreached ? 'Action' : 'Alert'}
                            </span>
                        }
                    </div>
                }
                </div>
            </div>
            <div className='row'>
                <div className='col-4' data-testid='contamination-info-label'>Identification</div>
                <div className='col-8 sample-reading__word-wrap user-formatted-text fw-bold'>
                    {contaminations(info.growths).map((row, rowIdx) =>
                        <div key={rowIdx} className='my-1'>
                            {row.types.map((type, typeIdx) =>
                                <div key={typeIdx}>
                                    <span className={typeIdx !== 0 ? 'invisible' : undefined}>{row.cfuCount} x </span>
                                    <FormattedText text={type} />
                                    {row.objectionable && typeIdx === 0 && (isBreachedByBehaviour(props.entity.behaviour)
                                        ? <span className={`ms-2 p-1 text-uppercase small fw-bold rounded text-white ${info.alertBreached ? 'label-alert--color' : 'label-action--color'}`}>
                                            Objectionable - {info.alertBreached ? 'Alert' : 'Action'}
                                        </span>
                                        : <span className='ms-2 p-1 text-uppercase small fw-bold rounded text-white bg-secondary'>
                                            Objectionable
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ContaminationInfo
