import type { State } from 'router5'
import { React, connect, useSelector, useReducer } from '_/facade/react'
import type AppState from '_/model/app-state'
import type Permissions from '_/model/permissions/type'
import type { RouteName } from '_/constants/routes'
import { mustRedirectOnContextSwitch } from '_/features/routing/helpers'
import { useUpdateEffect } from '_/hooks/shared-hooks'

interface OwnProps {
    onChange(): void
    children?: never
    alwaysNotify?: boolean
}

interface StateProps {
    membershipId: string | undefined
    permissions: Permissions
    state: State
}

class ContextObserver extends React.Component<OwnProps & StateProps> {
    componentDidUpdate(prevProps: StateProps) {
        const props = this.props
            , notify = props.membershipId
                && props.membershipId !== prevProps.membershipId
                && (props.alwaysNotify || !mustRedirectOnContextSwitch(
                    props.state.name as RouteName,
                    props.permissions
                ))

        if (notify)
            props.onChange()
    }

    render() {
        return null
    }
}

function mapStateToProps(state: AppState): StateProps {
    return {
        membershipId: state.auth.user && state.auth.user.membership.id,
        permissions: state.auth.permissions,
        state: state.router.route!,
    }
}

export default connect(mapStateToProps)(ContextObserver)

const membershipIdSelector = (_: AppState) => _.auth.user?.membership.id
    , routerSelector = (_: AppState) => _.router.route!
    , permissionsSelector = (_: AppState) => _.auth.permissions
    , reducerExpression = (_: number) => _ + 1

export function useContextSwitchObserver() {
    const membershipId = useSelector(membershipIdSelector)
        , state = useSelector(routerSelector)
        , permissions = useSelector(permissionsSelector)
        , [value, update] = useReducer(reducerExpression, 0)

    useUpdateEffect(
        () => {
            const shouldNotify = !mustRedirectOnContextSwitch(
                state.name as RouteName,
                permissions
            )

            if (shouldNotify)
                update()
        },
        // eslint-disable-next-line
        [membershipId]
    )

    return value
}
