import type Permissions from '_/model/permissions/type'
import type { LogInResponse, UserMembership } from '_/model/auth/types'

import * as bp from '_/constants/business-permission'
import * as levels from '_/constants/context-level'
import * as tiers from '_/constants/tiers'

const emptyPermissions: Permissions = {
    readUsers: false,
    editUsers: false,
    restoreUsers: false,
    manageMemberships: false,
    manageRoles: false,
    manageTrends: false,
    readTrendSettings: false,
    readPredefinedLists: false,
    readSampleType: false,
    editSampleType: false,
    readExposureLocations: false,
    editExposureLocations: false,
    readActionAlertLimits: false,
    editActionAlertLimits: false,
    editOrganismIdentification: false,
    readObjectionableOrganisms: false,
    editObjectionableOrganisms: false,
    readSampleOperator: false,
    editSampleOperator: false,
    readSampleSession: false,
    editSampleSession: false,
    readColonyCounterPlates: false,
    performColonyCounterPlateReading: false,
    preferSettingsOverPlatesReading: false,
    readSamples: false,
    performSampleReading: false,
    bookSamples: false,
    editSamples: false,
    exportSamples: false,
    importSamples: false,
    createRemoveContexts: false,
    readContexts: false,
    editContexts: false,
    editElectronicSignatureSettings: false,
    exportContexts: false,
    manageSiteTier: false,
    manageSiteWebcam: false,
    readGlobalTrails: false,
    readReports: false,
    readAnalysis: false,
    readDashboard: false,
    authenticated: false,
    notAuthenticated: true,
    colonyCounterMode: false,
    smartControlMode: true,

    manageAnalysisFilters: false,
    readCustomReports: false,
    editCustomReports: false,
    manageNotifications: false,
    confirmCfuCount: false,
    confirmBookInForSample: true,
    editCustomFields: false,
    readCustomFields: false,
    exportCustomReportsData: false,
    exportData: false,
    applyBulkOperations: false,
    readSampleReport: false,
    changeCfuConfirmationSetting: false,
    changeZeroGrowthVerificationSetting: false,
    changeMoldPredictorSetting: false,
    manageFloorPlans: false,
    useFloorPlansCharts: false,
    readInsights: false,

    readSchedule: false,
    editSchedule: false,
    editMonitoringGroups: false,
    readMonitoringOverview: false,
    editMonitoringOverview: false,
    readLocationsReport: false,
    readOperatorsReport: false,
    readMissedMonitoringReport: false,
    editIpRestrictionSetting: false,
    manageApiKeys: false,
    useSsoESignature: false,
    readNotificationPreferences: false,

    tagUsers: false,

    readOperatorPerformanceReport: false,

    readTrends: false,
    editTrendInvestigationRefs: false,
}

function calcPermissions(membership?: UserMembership): Permissions {
    if (membership === undefined)
        return emptyPermissions

    if (!isSmartControl(membership.tier)) {
        return calcColonyCounterPermissions(membership)
    }

    const atLeastInsightTier = membership.tier >= tiers.INSIGHT
        , level = membership.type
        , isSite = level === levels.SITE
        , has = (permission: bp.BusinessPermission) => membership.role.permissions.includes(permission)
        , result: Permissions = Object.assign({}, emptyPermissions)

    // ****** General permissions ******
    result.manageNotifications = true
    result.readInsights = has(bp.VIEW_INSIGHTS)

    // ****** Analysis ******
    result.readDashboard = has(bp.VIEW_THE_DASHBOARD)
    result.readGlobalTrails = has(bp.VIEW_THE_GLOBAL_AUDIT_TRAIL)

    const useAnalysis = has(bp.USE_ANALYSIS_AND_REPORTS)
    result.readAnalysis = useAnalysis
    result.readReports = useAnalysis
    result.manageAnalysisFilters = useAnalysis && atLeastInsightTier
    result.readCustomReports = useAnalysis && atLeastInsightTier

    result.editCustomReports = has(bp.MANAGE_CUSTOM_REPORTS)

    result.exportData = has(bp.EXPORT_DATA)
    result.exportCustomReportsData = has(bp.EXPORT_CUSTOM_REPORTS_DATA)

    result.useFloorPlansCharts = useAnalysis && atLeastInsightTier

    result.readLocationsReport = useAnalysis && atLeastInsightTier
    result.readOperatorsReport = useAnalysis && atLeastInsightTier
    result.readMissedMonitoringReport = useAnalysis && has(bp.VIEW_SCHEDULE) && atLeastInsightTier

    result.readOperatorPerformanceReport = useAnalysis && atLeastInsightTier

    // ****** Samples ******
    result.readSamples = has(bp.VIEW_SAMPLES)
    result.performSampleReading = has(bp.READ_SAMPLES)
    result.editSamples = has(bp.EDIT_SAMPLES)
    result.bookSamples = has(bp.BOOK_IN_SAMPLES)
    result.confirmBookInForSample = has(bp.CONFIRM_BOOK_IN_FOR_SAMPLES)
    result.confirmCfuCount = has(bp.VERIFY_CFU_COUNT)
    result.exportSamples = has(bp.EXPORT_SAMPLES)
    result.importSamples = has(bp.IMPORT_SAMPLES)
    result.applyBulkOperations = has(bp.VERIFY_CFU_COUNT) || has(bp.CONFIRM_BOOK_IN_FOR_SAMPLES)
    result.readSampleReport = useAnalysis

    // ****** User management ******
    result.readUsers = has(bp.VIEW_USERS)
    result.manageApiKeys = has(bp.MANAGE_API_KEYS)

    // ****** Settings ******
    result.readPredefinedLists = isSite
    result.readCustomFields = isSite
    result.editCustomFields = has(bp.MANAGE_CUSTOM_FIELDS)
    result.readExposureLocations = isSite
    result.editExposureLocations = has(bp.MANAGE_EXPOSURE_LOCATIONS)
    result.manageFloorPlans = has(bp.MANAGE_FLOOR_PLANS)
    result.readActionAlertLimits = isSite
    result.editActionAlertLimits = has(bp.MANAGE_GRADES)
    result.readSampleOperator = isSite
    result.editSampleOperator = has(bp.MANAGE_OPERATORS)
    result.readSampleSession = isSite
    result.editSampleSession = has(bp.MANAGE_SESSIONS)
    result.readSampleType = isSite
    result.editSampleType = has(bp.MANAGE_SAMPLE_TYPES)
    result.readObjectionableOrganisms = isSite
    result.editObjectionableOrganisms = has(bp.MANAGE_OBJECTIONABLE_ORGANISMS)
    result.readTrendSettings = isSite && atLeastInsightTier
    result.manageTrends = has(bp.MANAGE_TRENDS) && atLeastInsightTier

    // ****** Scheduling ******
    result.readSchedule = has(bp.VIEW_SCHEDULE)
    result.editSchedule = has(bp.EDIT_SCHEDULE)
    result.editMonitoringGroups = has(bp.MANAGE_MONITORING_GROUPS)
    result.readMonitoringOverview = has(bp.VIEW_MONITORING_OVERVIEW)
    result.editMonitoringOverview = has(bp.EDIT_MONITORING_OVERVIEW)

    // ****** Trends ******
    result.readTrends = has(bp.VIEW_TRENDS) && atLeastInsightTier
    result.editTrendInvestigationRefs = has(bp.EDIT_TREND_INVESTIGATION_REFS) && atLeastInsightTier

    // ****** Internal ******
    const isAdmin = membership.role.isAdmin
        , isSysAdmin = isAdmin && level === levels.SYSTEM
        , isOrgAdmin = isAdmin && level === levels.ORGANISATION
        , isSiteAdmin = isAdmin && level === levels.SITE

    result.editUsers = isAdmin
    result.manageMemberships = isAdmin
    result.restoreUsers = isAdmin

    result.manageRoles = isOrgAdmin
    result.editOrganismIdentification = isSysAdmin
    result.manageSiteTier = isSysAdmin

    result.readContexts = true
    result.createRemoveContexts = isSysAdmin
    result.exportContexts = isSiteAdmin
    result.editContexts = isAdmin
    result.editElectronicSignatureSettings = isSite || isOrgAdmin
    result.manageSiteWebcam = isSiteAdmin

    result.authenticated = true
    result.notAuthenticated = false

    result.changeCfuConfirmationSetting = has(bp.MANAGE_GRADES)
    result.changeZeroGrowthVerificationSetting = isAdmin
    result.changeMoldPredictorSetting = isSiteAdmin
    result.editIpRestrictionSetting = isAdmin && membership.tier === tiers.ENTERPRISE

    result.useSsoESignature = membership.tier === tiers.ENTERPRISE

    result.readNotificationPreferences = isSite

    result.tagUsers = atLeastInsightTier

    return result
}

function calcColonyCounterPermissions(membership: UserMembership): Permissions {
    const level = membership.type
        , isSite = level === levels.SITE
        , has = (permission: bp.BusinessPermission) => membership.role.permissions.includes(permission)
        , result: Permissions = Object.assign({}, emptyPermissions)

    // ****** General permissions ******
    result.readGlobalTrails = has(bp.CC_VIEW_AUDIT_TRAIL)
    result.exportData = has(bp.CC_EXPORT_DATA)

    // ****** Samples ******
    result.readColonyCounterPlates = has(bp.CC_VIEW_PLATES)
    result.performColonyCounterPlateReading = has(bp.CC_CREATE_AND_EDIT_PLATES)

    // ****** User management ******
    result.readUsers = has(bp.CC_VIEW_USERS)
    result.manageApiKeys = has(bp.CC_MANAGE_API_KEYS)

    // ****** Settings ******
    result.readPredefinedLists = isSite

    // ****** Internal ******
    const isAdmin = membership.role.isAdmin
        , isOrgAdmin = isAdmin && level === levels.ORGANISATION
        , isSiteAdmin = isAdmin && level === levels.SITE

    result.editUsers = isAdmin
    result.manageMemberships = isAdmin
    result.restoreUsers = isAdmin

    result.manageRoles = isOrgAdmin

    result.readContexts = true
    result.exportContexts = false
    result.editContexts = isAdmin
    result.editElectronicSignatureSettings = isSite || isOrgAdmin

    result.authenticated = true
    result.notAuthenticated = false
    result.colonyCounterMode = true
    result.smartControlMode = false

    result.changeMoldPredictorSetting = isSiteAdmin

    result.useSsoESignature = true

    result.readNotificationPreferences = isSite

    result.preferSettingsOverPlatesReading = isSite && isAdmin

    return result
}

function isSmartControl(tier: tiers.Tier) {
    return tier !== tiers.COLONY_COUNTER
}

function convertToUserContext(response: LogInResponse, membershipId: string) {
    const membership = membershipId
        ? response.memberships.find(_ => _.id === membershipId)!
        : response.memberships[0]

    return {
        id: response.id,
        name: response.name,
        email: response.login,
        emailConfirmed: response.emailConfirmed,
        notConfirmedEmail: response.notConfirmedEmail,
        role: membership.role,
        membership,
        accountTypes: response.accountTypes,
        credentialsType: response.credentialsType
    }
}

export {
    calcPermissions,
    isSmartControl,
    convertToUserContext,
}
