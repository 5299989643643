import type ApiService from '../api-service'
import type PredefinedListsService from '../predefined-lists-service'
import type { OrganismIdentification, OrganismIdentificationListQuery, SearchOrganisms } from '_/model/predefined-lists/organism-identification/types'
import type Page from '_/model/page'
import type PaginationState from '_/model/pagination-state'
import type { CustomFieldEdit, CustomFieldExternal, SearchFieldValues } from '_/model/predefined-lists/custom-field/types'
import * as s from '_/model/context/electronic-signature-settings'
import type { ObjectionableOrganismEffectiveView } from '_/model/objectionable-organisms/objectionable-organism'
import type ObjectionableOrganism from '_/model/objectionable-organisms/objectionable-organism'
import type { AllIdentifications } from '_/model/predefined-lists/identifications/identifications'
import type { TrendSettings, TrendSettingsEdit, ServerTrendSettings } from '_/model/predefined-lists/trend-settings/types'
import type { FieldsDiff } from '_/utils/object'
import { dropFields } from '_/utils/object'
import type { Device } from '_/model/predefined-lists/devices/types'
import type { ApprovalInfoProps } from '_/model/critical-change-reason/types'

import * as h from './helper'
import type { AuditTrail, Events } from '_/model/audit-trail/types'

function factory(api: ApiService): PredefinedListsService {
    const organismIdentificationApiResource = api.resource(['organism-identifications'], s.CHANGING_SETTINGS)
        , sampleTypeApiResource = api.resource(['sample-types'], s.CHANGING_SETTINGS)
        , locationApiResource = api.resource(['exposure-locations'], s.CHANGING_SETTINGS)
        , operatorApiResource = api.resource(['sample-operators'], s.CHANGING_SETTINGS)
        , sessionApiResource = api.resource(['sample-sessions'], s.CHANGING_SETTINGS)
        , customFiledApiResource = api.resource(['custom-fields'], s.CHANGING_SETTINGS)
        , objectionableOrganismApiResource = api.resource(['objectionable-organisms'], s.CHANGING_SETTINGS)
        , trendSettingsResource = api.resource(['trend-settings'], s.CHANGING_SETTINGS)
        , deviceApiResource = api.resource(['devices'], s.CHANGING_SETTINGS)

    return {
        organismIdentification: {
            ...organismIdentificationApiResource,
            save: organismIdentificationApiResource.saveWithReason,
            remove: organismIdentificationApiResource.removeWithReason,
            import: importOrganismIdentifications,
            trail: getOrganismIdentificationTrail,
            search: searchOrganismIdentifications,
            organismList: getOrganismIdentifications,
        },

        objectionableOrganism: {
            ...objectionableOrganismApiResource,
            save: objectionableOrganismApiResource.saveWithReason,
            remove: objectionableOrganismApiResource.removeWithReason,
            trail: getObjectionableOrganismsTrail,
            getAll: getAllObjectionableOrganisms,
            getEffectiveList: getObjectionableOrganismsEffectiveList,
        },

        identifications: {
            list: listIdentifications,
        },

        sampleType: {
            ...sampleTypeApiResource,
            save: sampleTypeApiResource.saveWithReason,
            remove: sampleTypeApiResource.removeWithReason,
        },
        exposureLocation: {
            ...locationApiResource,
            save: locationApiResource.saveWithReason,
            remove: locationApiResource.removeWithReason,
        },
        actionAlertLimits: Object.assign(
            api.resource(['limits'], s.CHANGING_SETTINGS),
            {
                trail: getActionAlertLimitsTrail,
            }
        ),

        nonViableLimits: api.resource(['non-viable-limits'], s.CHANGING_SETTINGS),

        sampleOperator: {
            ...operatorApiResource,
            save: operatorApiResource.saveWithReason,
            remove: operatorApiResource.removeWithReason,
            import: importSampleOperators,
        },
        sampleSession: {
            ...sessionApiResource,
            save: sessionApiResource.saveWithReason,
            remove: sessionApiResource.removeWithReason,
            changePosition: sampleSessionChangePosition,
        },
        grade: api.resource(['grades'], s.CHANGING_SETTINGS),
        customField: {
            ...customFiledApiResource,
            create: createCustomField,
            save: customFiledApiResource.saveWithReason,
            remove: customFiledApiResource.removeWithReason,
            search: searchFieldValues,
            parseImportFile
        },
        trend: {
            ...trendSettingsResource,
            get: loadTrend,
            list: getTrendSettingsList,
            create: createTrendSettings,
            save: saveTrendSettings,
            remove: trendSettingsResource.removeWithReason,
        },
        devices: {
            ...deviceApiResource,
            contextList: getDeviceList,
            createDevice,
            markAsActive,
            markAsInactive,
            changeDeviceName,
            changeDeviceId,
            removeDevice,
        }
    }

    function importOrganismIdentifications(files: File[]): Promise<void> {
        const formData = new FormData()
        files.forEach(_ => formData.append('organismIdentifications[]', _, _.name))

        return api.post(['organism-identifications', 'import'], formData)
    }

    function getOrganismIdentificationTrail(query: PaginationState): Promise<Page<Events>> {
        return api.get(['organism-identifications', 'trail'], query)
    }

    function getOrganismIdentifications(query: OrganismIdentificationListQuery): Promise<Page<OrganismIdentification>> {
        return api.post(['organism-identifications', 'search'], query)
    }

    function searchOrganismIdentifications(request: SearchOrganisms): Promise<Page<OrganismIdentification>> {
        return api.post<Page<OrganismIdentification>>([`organism-identifications`, `name-search`], request)
    }

    function listIdentifications(includeInconclusive: boolean): Promise<AllIdentifications> {
        return api.get(['identifications'], { includeInconclusive })
    }

    function getActionAlertLimitsTrail(): Promise<AuditTrail> {
        return api.get(['limits', 'trail'])
    }

    function searchFieldValues(query: SearchFieldValues): Promise<string[]> {
        return api.get<string[]>(['custom-fields-values'], query)
    }

    function parseImportFile(file: File): Promise<string[]> {
        const formData = new FormData()
        formData.append('file', file, file.name)

        return api.post<string[]>(['custom-fields', 'parse-import-file'], formData)
    }

    function importSampleOperators(file: File): Promise<void> {
        const formData = new FormData()
        formData.append('sampleOperators[]', file, file.name)

        return api.post(['sample-operators', 'import'], formData)
    }

    function sampleSessionChangePosition(id: string, position: number): Promise<void> {
        return api.postWithReason(['sample-sessions', id, 'change-position'], s.CHANGING_SETTINGS, { position })
    }

    function getObjectionableOrganismsTrail(): Promise<AuditTrail> {
        return api.get(['objectionable-organisms', 'trail'])
    }

    function getAllObjectionableOrganisms(): Promise<ObjectionableOrganism[]> {
        return api.get<ObjectionableOrganism[]>(['objectionable-organisms', 'list'])
    }

    function getObjectionableOrganismsEffectiveList(): Promise<ObjectionableOrganismEffectiveView[]> {
        return api.get<ObjectionableOrganismEffectiveView[]>(['objectionable-organisms', 'effective-list'])
    }

    function createCustomField(field: CustomFieldEdit) {
        const result: CustomFieldExternal = {
                ...field,
                viableSettings: dropFields(field.viableSettings, 'isActive'),
                viableIsActive: !!field.viableSettings.isActive,
                nonViableSettings: dropFields(field.nonViableSettings, 'isActive'),
                nonViableIsActive: !!field.nonViableSettings.isActive
            }
        return api.post(['custom-fields'], result)
    }

    function saveTrendSettings(id: string, trend: FieldsDiff<TrendSettingsEdit>): Promise<void> {
        return api.patchWithReason(['trend-settings', id], s.CHANGING_SETTINGS, h.convertTrendToServerFilter(trend))
    }

    function createTrendSettings(trend: TrendSettingsEdit): Promise<void> {
        return api.post(['trend-settings'], h.convertTrendToServerFilter(trend))
    }

    function loadTrend(id: string) {
        return api.get<ServerTrendSettings>(['trend-settings', id])
            .then(trend => h.convertTrendFromServerFilter(trend))
    }

    function getTrendSettingsList(): Promise<TrendSettings[]> {
        return api.get<ServerTrendSettings[]>(['trend-settings'])
            .then(trends => trends.map(_ => h.convertTrendFromServerFilter(_)))
    }

    function getDeviceList(contextId: string): Promise<Device[]> {
        return api.get<Device[]>(['contexts', contextId, 'devices'])
    }

    function createDevice(contextId: string, device: Device): Promise<void> {
        return api.post(['contexts', contextId, 'devices'], device)
    }

    function markAsActive(id: string, approvalInfo: ApprovalInfoProps): Promise<void> {
        return api.post(['devices', id, 'mark-as-active'], approvalInfo)
    }

    function markAsInactive(id: string, approvalInfo: ApprovalInfoProps): Promise<void> {
        return api.post(['devices', id, 'mark-as-inactive'], approvalInfo)
    }

    function changeDeviceName(id: string, name: string): Promise<void> {
        return api.postWithReason(['devices', id, 'change-name'], s.CHANGING_SETTINGS, { name })
    }

    function changeDeviceId(id: string, deviceId: string, approvalInfo: ApprovalInfoProps): Promise<void> {
        return api.post(['devices', id, 'change-device-id'], { deviceId, ...approvalInfo }, )
    }

    function removeDevice(id: string, signatureSettings: s.ElectronicSignatureSettings[]): Promise<void> {
        return api.removeWithReason(['devices', id], s.CHANGING_SETTINGS, signatureSettings)
    }
}

export default factory
