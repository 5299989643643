export const NONE = ''
           , TOP = 'top'
           , BOTTOM = 'bottom'

type NONE = typeof NONE
type TOP = typeof TOP
type BOTTOM = typeof BOTTOM

export type DragDirection =
    | NONE
    | TOP
    | BOTTOM

export function getElementDragDirection(element: Element | null, event: React.DragEvent<HTMLElement>): DragDirection {
    const position = getElementPositionOnPage(element)

    if (event.pageY < position.top + position.height / 2) {
        return TOP
    }
    if (event.pageY > position.top + position.height / 2) {
        return BOTTOM
    }

    return NONE
}

function getElementPositionOnPage(el: Element | null) {
    const rect = el?.getBoundingClientRect()

    return {
      left: rect?.left || 0 + window.scrollX,
      top: rect?.top || 0 + window.screenY,
      height: rect?.height || 0,
    }
}

export function getDragDirectionClass(classPrefix: string, dragDirection: DragDirection) {
    switch(dragDirection) {
        case TOP:
        case BOTTOM:
            return `${classPrefix}-${dragDirection}`
        default:
            return ''
    }
}
