import { classnames, useSelector } from '_/facade/react'

import Link from '_/components/link'
import * as routes from '_/constants/routes'
import SchedulingContainer from './scheduling-container'

function Scheduling() {
    const route = useSelector(_ => _.router.route!)
        , auth = useSelector(_ => _.auth)

    return(
        <div className='d-flex flex-column h-100'>
            <nav className='navbar navbar-secondary navbar-expand flex-shrink-0'>
                <ul className='navbar-nav'>
                    <li className='nav-item'>
                        <Link
                            className={classnames(
                                'nav-link',
                                {active: route.name === routes.SCHEDULING_MONTHLY_SCHEDULER || route.name === routes.SCHEDULING_DAY_SCHEDULER}
                            )}
                            routeName={routes.SCHEDULING_MONTHLY_SCHEDULER}
                            hasNoPermissions={!auth.permissions.readSchedule}
                        >
                            Scheduler
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={classnames('nav-link', {active: route.name === routes.SCHEDULING_MONITORING_OVERVIEW})}
                            routeName={routes.SCHEDULING_MONITORING_OVERVIEW}
                            hasNoPermissions={!auth.permissions.readMonitoringOverview}
                        >
                            Monitoring overview
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link
                            className={classnames('nav-link', {active: route.name === routes.SCHEDULING_MONITORING_GROUPS})}
                            routeName={routes.SCHEDULING_MONITORING_GROUPS}
                            hasNoPermissions={!auth.permissions.editMonitoringGroups}
                        >
                            Monitoring groups
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className='d-flex flex-fill overflow-auto'>
                <SchedulingContainer />
            </div>
        </div>
    )
}

export default Scheduling
