import { Form } from 'react-final-form'

import { classnames, useAction, useSelector, useState, useCallback, useEffect } from '_/facade/react'
import { TextField, PasswordField, Submit, submitDisabled } from '_/components/form'
import { useTimeService } from '_/components/time'
import Button from '_/components/button'
import ContextInviteMessage from '_/features/contexts/invite/context-invite-message'

import type { UserSignUp } from '_/model/auth/types'
import type { ContextInvite } from '_/model/context/invite/types'
import * as invitationStates from '_/constants/context-invitation-state'
import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'
import * as routes from '_/constants/routes'
import logo from '_/../assets/ms-sign-in-light.svg'
import { ENTERPRISE } from '_/constants/tiers'

import * as contextActions from '_/features/contexts/actions'
import { navigateTo } from '_/features/routing/actions'

import validate from '_/model/auth/sign-up/validate'

import { useSsoUrl } from '../hooks'
import { SIGN_UP } from '_/model/auth/sso-request-source'
import SmartControlLinks from '../links'

function SignUp() {
    const [contextInviteInfo, setContextInviteInfo] = useState<ContextInvite | undefined>()
        , timeService = useTimeService()
        , invitationState = contextInviteInfo && contextInviteInfo.invitationState
        , hasExpired = contextInviteInfo && contextInviteInfo.hasExpired
        , validInvite = invitationState !== invitationStates.PENDING || hasExpired
        , processedAt = contextInviteInfo && timeService.formatCtzDate(contextInviteInfo.processedAt)
        , roles = contextInviteInfo && contextInviteInfo.roles.map(_ => _.name)
        , route = useSelector(_ => _.router.route)!
        , joinContext = useAction(contextActions.joinContext)
        , getContextInvite = useAction(contextActions.loadContextInvite)
        , rejectContextInvite = useAction(contextActions.rejectContextInvite)
        , navigateToAction = useAction(navigateTo)
        , ssoUrl = useSsoUrl(SIGN_UP)
        , noPermissionUseSso = !ssoUrl || contextInviteInfo?.tier !== ENTERPRISE
        , [showSpinner, setShowSpinner] = useState(false)
        , load = useCallback(
            () => {
                setShowSpinner(true)
                getContextInvite(route.params.token)
                    .then(_ => _.userExists ? navigateToAction(routes.CONTEXTS_ASSIGN_CONFIRM, { token: route.params.token }) : setContextInviteInfo(_))
                    .finally(() => setShowSpinner(false))
            },
            [route, getContextInvite, navigateToAction]
        )

    useEffect(
        () => {
            load()
        },
        [load]
    )

    function handleCreateUser(user: UserSignUp) {
        return joinContext({ token: route.params.token, user })
            .catch(_ => ({ password: _.statusText }))
    }

    function handleReject() {
        rejectContextInvite(route.params.token)
            .then(load)
    }

    return (
        <div className='auth h-100 d-flex justify-content-center'>
            <div className='d-flex flex-column mb-4'>
                <div className='auth-form mx-auto'>
                    <div className='auth-form-header-text text-white text-start text-nowrap mb-3'>
                        Join Microgenetics SmartControl
                    </div>

                    {showSpinner && <i className='preview-spinner text-white material-icons md-48'>sync</i>}

                    {contextInviteInfo && !validInvite &&
                        <p className='text-white'>
                            You have been invited to join the <b>{contextInviteInfo.contextDescription}</b> with the role of <b>{roles && roles.join(', ')}</b>.<br/>
                            To accept this invitation, create a Microgenetics SmartControl account
                        </p>
                    }
                    {!validInvite && <Form
                        onSubmit={handleCreateUser}
                        validate={validate}
                        initialValues={{ email: contextInviteInfo!.inviteeEmail }}
                        render={form =>
                            <form className='clearfix' onSubmit={form.handleSubmit}>
                                <TextField id='auth-email' name='email' disabled testId='field-email' />
                                <TextField id='auth-name' name='name' placeholder='Name' testId='field-name' />
                                <PasswordField id='auth-password' name='password' placeholder='Password' testId='field-password' />
                                <PasswordField id='auth-confirm-password' name='confirmPassword' placeholder='Confirm Password' testId='field-confirm-password' />
                                <Submit
                                    className='auth-form-btn fw-bold'
                                    disabled={submitDisabled(form)}
                                    testId='sign-up'
                                >
                                    Sign Up
                                </Submit>
                                <Button
                                    className='auth-form-btn btn-light text-primary fw-bold float-end'
                                    onClick={handleReject}
                                    disabled={form.submitting}
                                >
                                    Reject invitation
                                </Button>
                                <div
                                    className={classnames('mt-2 form-control-plaintext text-center', {'no-permission': noPermissionUseSso})}
                                    title={noPermissionUseSso ? NO_PERMISSION_MESSAGE : ''}
                                >
                                    <a className={classnames({ 'link-disabled text-secondary': noPermissionUseSso })} data-testid='sign-up-sso' href={ssoUrl}>
                                        <img src={logo}/>
                                    </a>
                                </div>
                                <div className='text-center text-white mt-2'>
                                    Sign in with Microsoft is only available for enterprise tier accounts. Refer to <a target='_blank' href='https://microgenetics.co.uk/smartcontrol/#pricing'>link</a> for more information
                                </div>
                            </form>
                        }
                    />}
                    {!showSpinner &&
                        <ContextInviteMessage
                            processedAt={processedAt}
                            hasExpiredOrNotFound={hasExpired || !contextInviteInfo}
                            invitationState={invitationState}
                        />
                    }

                </div>
                <SmartControlLinks />
            </div>
        </div>
    )
}

export default SignUp
