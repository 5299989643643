
import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type PredefinedListsService from '_/services/predefined-lists-service'
import { noop } from '_/utils/function'
import { NOT_YET_IDENTIFIED_ID, NOT_YET_IDENTIFIED } from '_/model/predefined-lists/identifications/not-yet-identified'

import * as actions from './actions'
import { NO_ID_REQUIRED_ID, NO_ID_REQUIRED } from '_/model/predefined-lists/identifications/no-id-required'
import type { OrganismIdentification } from '_/model/predefined-lists/organism-identification/types'
import { CORRECTIONAL_MPN_VALUE_ID, CORRECTIONAL_MPN_VALUE } from '_/model/predefined-lists/identifications/correctional-mpn-value'

const factory = (service: PredefinedListsService): EffectsFactory => () => [
    handler(actions.loadOrganismIdentificationList, service.organismIdentification.organismList),

    handler(actions.loadOrganismIdentificationTrail, service.organismIdentification.trail),

    handler(actions.createOrganismIdentification, data => service.organismIdentification.create(data).then(noop)),

    handler(actions.removeOrganismIdentification, service.organismIdentification.remove),

    handler(actions.searchOrganismIdentification, request => {
        const notYetIdentifiedIndex = request.ids?.indexOf(NOT_YET_IDENTIFIED_ID) ?? -1
            , noIdRequiredIndex = request.ids?.indexOf(NO_ID_REQUIRED_ID) ?? -1
            , correctionMpnValueIndex = request.ids?.indexOf(CORRECTIONAL_MPN_VALUE_ID) ?? -1
            , normalizedRequest = { ...request }

        if (notYetIdentifiedIndex !== -1 || noIdRequiredIndex !== -1 || correctionMpnValueIndex !== -1) {
            normalizedRequest.ids = normalizedRequest.ids!.filter(_ => _ !== NOT_YET_IDENTIFIED_ID && _ !== NO_ID_REQUIRED_ID && _ !== CORRECTIONAL_MPN_VALUE_ID)
        }

        function concatIdentifications(organismIdentifications: OrganismIdentification[], notYetIdentifiedIndex: number, noIdRequiredIndex: number, correctionMpnValueIndex: number) {
            let result = organismIdentifications

            if (notYetIdentifiedIndex !== -1)
                result = result.slice(0, notYetIdentifiedIndex)
                    .concat(NOT_YET_IDENTIFIED)
                    .concat(result.slice(notYetIdentifiedIndex))

            if (noIdRequiredIndex !== -1)
                result = result.slice(0, noIdRequiredIndex)
                    .concat(NO_ID_REQUIRED)
                    .concat(result.slice(noIdRequiredIndex))

            if (correctionMpnValueIndex !== -1)
                result = result.slice(0, correctionMpnValueIndex)
                    .concat(CORRECTIONAL_MPN_VALUE)
                    .concat(result.slice(correctionMpnValueIndex))

            return result
        }

        return service.organismIdentification.search(normalizedRequest)
            .then(_ => notYetIdentifiedIndex === -1 && noIdRequiredIndex === -1 && correctionMpnValueIndex === -1
                ? _.items
                : concatIdentifications(_.items, notYetIdentifiedIndex, noIdRequiredIndex, correctionMpnValueIndex)
            )
    }),

    handler(actions.saveOrganismIdentification, data =>
        service.organismIdentification.save(data.id, {isActive: data.isActive})),

    handler(actions.importOrganismIdentification, service.organismIdentification.import),

    handler(actions.loadOrganismIdentification, service.organismIdentification.get),
]

export default factory
