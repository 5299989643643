import { classnames } from '_/facade/react'

interface Props {
    className?: string
    children: React.ReactNode
}

const TabNavbar = (props: Props) =>
    <div className={classnames('position-relative', props.className)}>
        <div className='border-bottom h-100 w-100 position-absolute' />

        <div className='d-flex position-relative'>
            {props.children}
        </div>
    </div>

export default TabNavbar
