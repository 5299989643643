import { Field } from 'react-final-form'
import { classnames } from '_/facade/react'
import { showFieldError } from './helpers'

interface OwnProps {
    id?: string
    name: string
    disabled?: boolean
    className?: string
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
    inputRef?: React.RefObject<HTMLInputElement>
    testId?: string
}

const TextTimeField: React.FC<OwnProps> = props =>
        <Field name={props.name} type='time' render={
            _ =>
            <div className={`mb-3 ${props.className ?? ''}`}>
                <label htmlFor={props.id} className='col-form-label' data-testid='field-label'>{props.children}</label>
                <input
                    id={props.id}
                    type='time'
                    disabled={props.disabled}
                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                    {..._.input}
                    onKeyDown={props.onKeyDown}
                    ref={props.inputRef}
                    data-testid={props.testId}
                />
                {showFieldError(_.meta) && <span className='invalid-feedback' data-testid='validation-error'>{_.meta.error}</span>}
            </div>
        }/>

export default TextTimeField
