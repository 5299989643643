import { useAction, useEffect, useState } from '_/facade/react'
import * as m from '_/components/modal'
import AuditTrails from '_/components/audit-trail-list'
import { Close } from '_/components/button'
import * as a from '../actions'
import type { AuditTrail } from '_/model/audit-trail/types'

interface OwnProps {
    onClose(): void
}

function AuditTrailModal(props: OwnProps) {
    const trails = useAuditTrail()

    return(
        <m.Modal isOpen onClose={props.onClose}>
            <m.ModalHeader className='pb-0 border-bottom-0'>
                <h4 className='ps-1'>Audit trail</h4>
                <Close onClick={props.onClose}/>
            </m.ModalHeader>
            <m.ModalBody>
                <AuditTrails
                    trail={trails}
                    hideHeader
                />
            </m.ModalBody>
        </m.Modal>
    )
}

function useAuditTrail() {
    const load = useAction(a.loadObjectionableOrganismsTrail)
        , [trails, setTrails] = useState<AuditTrail | undefined>(undefined)

    useEffect(
        () => {
            load().then(setTrails)
        },
        [load]
    )

    return trails
}

export default AuditTrailModal
