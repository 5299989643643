import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, isEmpty, allowedName, required, isValidNumberInRange } from '_/utils/form/validate'

import type CustomField from '_/model/predefined-lists/custom-field/types'
import { searchDuplicateName } from '_/model/common/helpers'
import * as tp from '_/model/predefined-lists/trend-settings/time-period'
import type { TrendSettings, TrendSettingsEdit, FilterEdit, Timeframe, Rule } from './types'
import * as ft from './filter-type'
import * as tt from './timeframe-type'
import type { FieldIndex } from '_/constants/custom-field-index'
import { validateCustomFieldNumberRange } from '_/features/samples/filter/validate'
import { NUMBER } from '_/constants/custom-field-column-type'
import { isLimitRule } from './helpers'

export default function validate(entity: Partial<TrendSettingsEdit>, trends: TrendSettings[], customFields: CustomField[]) {
    const result: ValidationResult<TrendSettings> = {}
        , name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 100)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , duplicateName = entity.name && searchDuplicateName(entity, trends)
        , filters = validateFilter(entity.filters ?? [], customFields)
        , timeframe = validateTimeframe(entity.timeframe)
        , rule = validateRule(entity.rule)

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (notAllowedNames)
        result.name = notAllowedNames

    if (duplicateName)
        result.name = `Trend setting with name '${entity.name?.trim()}' already exists`

    return {...result, filters, timeframe, rule}
}

function isValidCount(count: number | undefined, min: number, max: number) {
    if (count === undefined)
        return 'Number is required'

    if (!Number.isInteger(count) || !isValidNumberInRange(count, min, max))
        return `Number must be integer between ${min} and ${max}`
}

function validateDataFieldValue(fieldIndex: FieldIndex | undefined, value: any, customFields: CustomField[]) {
    const field = customFields.find(_ => _.index === fieldIndex)
        , isValidNumber = fieldIndex && validateCustomFieldNumberRange({ value, index: fieldIndex }, customFields)
        , isNumberField = field?.fieldType === NUMBER
        , isRequired = required('Value')(value)
        , fromRequired = isNumberField && required('From')(value?.from)
        , toRequired = isNumberField && required('To')(value?.to)
        , numberFieldRequired = fromRequired || toRequired
        , requiredError = numberFieldRequired
            ? {errors: [fromRequired, toRequired].filter(_ => _), from: !!fromRequired, to: !!toRequired}
            : isRequired

    return requiredError || isValidNumber || undefined
}

function validateFilter(filters: Partial<FilterEdit>[], customFields: CustomField[]) {
    return filters.map(_ => {
        if (_.type === ft.HAS)
            return {
                dataField: required('Data field')(_.dataField) || undefined,
                value: validateDataFieldValue(_.dataField, _.value, customFields)
            }

        if (_.type === ft.IN)
            return { exposureLocation: required('Exposure location')(_.exposureLocation) || undefined }

        if (_.type === ft.HAS_THE_SAME)
            return { dataFields: required('Data field')(_.dataFields) || undefined }

        if (_.type === ft.IN_THE_SAME)
            return { locationTypes: required('Location type')(_.locationTypes) || undefined }

        if (_.type === ft.HAS_IDENTIFICATION)
            return {
                identificationType: required('Identification')(_.identificationType) || undefined,
                value: required('Value')(_.value) || undefined
            }

        if (_.type === ft.HAS_THE_SAME_IDENTIFICATION)
            return {
                identificationTypes: required('Identification')(_.identificationTypes) || undefined,
            }

        return {type: 'Filter is required'}
    })
}

function validateTimeframe(timeframe: Timeframe | undefined) {
    const type = required('Timeframe')(timeframe?.type) || undefined
        , countValid = timeframe?.type === tt.WITHIN
            ? isValidCount(timeframe.count, 1, timeframe.period === tp.WEEKS ? 52 : 365)
            : timeframe?.type === tt.FOR_CONSECUTIVE_SAMPLES
                ? isValidCount(timeframe.count, 2, 100000)
                : undefined
        , period = timeframe?.type === tt.WITHIN
            ? required('Time period')(timeframe.period) || undefined
            : undefined

    return { count: countValid || undefined, type, period }
}

function validateRule(rule: Rule | undefined) {
    return {
        type: required('Rule')(rule?.type) || undefined,
        count: rule && isLimitRule(rule) ? isValidCount(rule.count, 1, 1000000) : undefined
    }
}

