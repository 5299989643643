import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, isEmpty, allowedName } from '_/utils/form/validate'
import type User from '_/model/users/types'
import { validateEmail } from '_/model/auth/sign-up/validate'

export default function validate(entity: Partial<User>) {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 128)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , email = validateEmail(entity.email) || isEmpty('Email')(entity.email)
        , emailLength = maxLength('Email', 128)(entity.email)
        , result: ValidationResult<User> = {}

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (notAllowedNames)
        result.name = notAllowedNames

    if (email)
        result.email = email

    if (emailLength)
        result.email = emailLength

    return result
}
