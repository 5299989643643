import { useAction, useEffect, useSelector } from '_/facade/react'
import { useSyncRef } from '_/hooks/shared-hooks'
import type WebcamState from '_/model/smart-check/webcam-state'
import * as a from '../actions'

interface Props {
    onInit: (webcam: WebcamState) => void
}

function WebcamLifecycle(props: Props) {
    useWebcam(props)

    return null
}

function useWebcam(props: Props) {
    const activateWebcam = useAction(a.activateWebcam)
        , deactivateWebcam = useAction(a.deactivateWebcam)
        , webcamEnabled = useSelector(_ => _.auth.user?.membership.context.webcamEnabled)
        , onInitRef = useSyncRef(props.onInit)

    useEffect(
        () => {
            if (!webcamEnabled) {
                onInitRef.current({ type: 'no-webcam' })
                return
            }

            activateWebcam().then(_ =>
                onInitRef.current(
                    typeof _ === 'string'
                        ? { type: 'error', value: _ }
                        : { type: 'ready', value: _ }
                )
            )

            return () => {
                deactivateWebcam()
            }
        },
        [webcamEnabled, onInitRef, activateWebcam, deactivateWebcam]
    )
}

export default WebcamLifecycle
