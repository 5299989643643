import { useAction, useSelector, useState, useEffect } from '_/facade/react'
import { actions as routeActions } from 'redux-router5'

import { useSessions, useCustomFields, useSampleTypes } from '_/hooks/shared-hooks'

import ContextObserver, { useContextSwitchObserver } from '_/components/context-observer'
import { useTimeService } from '_/components/time'
import PageHeader from '_/components/page-header'
import DateFilter from '_/components/date-filter'

import type { FingerdabOverviewByOperator } from '_/model/fingerdab-overview-by-operator/types'
import { getFingerdabOverviewByOperator } from '_/model/fingerdab-overview-by-operator/helpers'

import { FINGERDABS_DAILY_FINGERDABS } from '_/constants/routes'

import { calculateSamplesCount } from '../helpers'

import * as actions from './actions'
import OperatorsWithFingerdabs from './operators-with-fingerdabs'
import OperatorsWithoutFingerdabs from './operators-without-fingerdabs'
import type { DateTime } from '_/model/date-time'

function DailyFingerdabs() {
    const route = useSelector(_ => _.router.route)
        , timeService = useTimeService()
        , [filter, setFilter] = useState<DateTime>(getSearchFields())
        , allSessions = useSessions()
        , activeSessions = allSessions.filter(_ => _.isActive)
        , customFields = useCustomFields()
        , sampleTypesIncludingDeleted = useSampleTypes()
        , [fingerdabOverviewItems, showSpinner] = useFingerdabOverview(filter)
        , fingerdabsByOperator = getFingerdabOverviewByOperator(fingerdabOverviewItems, activeSessions)
        , operatorsWithFingerdabs = fingerdabsByOperator.filter(it => it.samples.some(x => x.length !== 0))
        , operatorsWithoutFingerdabs = fingerdabsByOperator.filter(it => it.operatorName && (it.samples.every(x => x.length === 0)))

    function getSearchFields() {
        const historyQuery = route?.params.query
        return historyQuery
                ? historyQuery.dateFrom
                : timeService.ctzDayStart(timeService.now())
    }

    return (
        <div className='d-flex flex-column w-100 h-100'>
            <PageHeader title=''>
                <div className='d-inline-flex'>
                    <span className='mt-2 me-4' data-testid='fingerdabs-total-by-date'>
                        Showing all {calculateSamplesCount(operatorsWithFingerdabs)} fingerdabs from {timeService.formatCtzDate(filter)}
                    </span>
                    <DateFilter
                        onChange={date => setFilter(date ?? timeService.ctzDayStart(timeService.now()))}
                        initialFilter={filter}
                        maxDate={timeService.now()}
                    />
                </div>
            </PageHeader>
            <ContextObserver onChange={() => setFilter(timeService.ctzDayStart(timeService.now()))} />

            <div className='overflow-auto'>
                <OperatorsWithFingerdabs
                    operatorsWithFingerdabs={operatorsWithFingerdabs}
                    sampleSession={activeSessions}
                    customFields={customFields}
                    sampleTypesIncludingDeleted={sampleTypesIncludingDeleted}
                    showSpinner={showSpinner}
                />
                <OperatorsWithoutFingerdabs
                    operatorsWithoutFingerdabs={operatorsWithoutFingerdabs}
                    showSpinner={showSpinner}
                />
            </div>
        </div>
    )
}

export default DailyFingerdabs

function useFingerdabOverview(dateFrom: DateTime) {
    const loadFingerdabOverviewByOperator = useAction(actions.loadFingerdabOverviewByOperator)
        , [fingerdabOverviewItems, setFingerdabOverviewItems] = useState<FingerdabOverviewByOperator[]>([])
        , [showSpinner, setShowSpinner] = useState(false)
        , timeService = useTimeService()
        , contextSwitch = useContextSwitchObserver()
        , navigateTo = useAction(routeActions.navigateTo)

    useEffect(
        () => {
            const query = {dateFrom, dateTo: timeService.ctzDayEnd(dateFrom)}
            setShowSpinner(true)
            loadFingerdabOverviewByOperator(query)
                .then(_ => {
                    setFingerdabOverviewItems(_)
                    navigateTo(FINGERDABS_DAILY_FINGERDABS, { query })
                })
                .finally(() => setShowSpinner(false))
        },
        [loadFingerdabOverviewByOperator, contextSwitch, navigateTo, dateFrom, timeService]
    )
    return [fingerdabOverviewItems, showSpinner] as const
}
