import { useMemo } from '_/facade/react'

interface Props {
    path: string
    size: number
}

let shapeId = 0

function ClippedShape(props: Props) {
    const id = useMemo(() => shapeId++, [])
        , pathId = 'limit-breach-icon-clip-path-' + id

    return (
        <g>
            <clipPath id={pathId}>
                <path d={props.path} />
            </clipPath>
            <path d={props.path} clipPath={`url(#${pathId})`} strokeWidth={props.size * 0.4} />
        </g>
    )
}

export default ClippedShape
