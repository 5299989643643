import { React } from '_/facade/react'

import type CustomField from '_/model/predefined-lists/custom-field/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { SampleData } from '_/model/fingerdab-overview-by-operator/types'

import { useTimeService } from '_/components/time'
import HoverMenu from '_/components/overlay/hover-menu'
import Link from '_/components/link'

import * as fieldIndex from '_/constants/custom-field-index'
import * as routes from '_/constants/routes'

import * as f from '_/model/sample/format'
import * as t from '_/model/text/text'

import { getFieldValue } from '_/features/samples/helpers'
import { formatSampleTypeName } from '_/model/scheduling/monitoring-groups/helpers'

import SampleIcon from '_/components/sample-icon/sample-icon'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import { formatLocationName } from '../helpers'
import FormattedText from '_/features/text/formatted-text'
import { BOOKED_IN } from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'

interface Props {
    samples: SampleData[]
    customFields: CustomField[]
    sampleTypes: SampleType[]
    index: number
    iconContainerElement: Element | null
    locations: ListExposureLocation[]
}

const SampleHoverMenu = (props: Props) => {
    const containerRef = React.createRef<HTMLDivElement>()
        , timeService = useTimeService()

    function formatSampleInfo(sampleData: SampleData) {
        const sampleTypeId = getFieldValue(sampleData.fields, fieldIndex.SAMPLE_TYPE_ID)
            , sampleType = formatSampleTypeName(sampleTypeId, props.sampleTypes)
            , locationName = formatLocationName(sampleData, props.locations)

        return <span><FormattedText text={sampleType} /> (<FormattedText text={locationName}/>), </span>
    }

    return (
        <HoverMenu element={props.iconContainerElement} alignmentElement={props.iconContainerElement} showTriangle>
            <div className='legend-bubble bg-dark' ref={containerRef}>
                {props.samples.map((_, index) =>
                    <div key={index} className='px-3 py-2 d-flex'>
                        <SampleIcon className='me-2 flex-shrink-0' status={BOOKED_IN} type={getFieldValue(_.fields, fieldIndex.PLATE_TYPE)} darkBackground testId='fingerdab-report-sample-icon' />
                        <div>
                            <Link
                                className='text-white'
                                routeName={routes.SAMPLES_EDIT}
                                routeParams={{ id: getFieldValue(_.fields, fieldIndex.ID) }}
                                testId='icon-link'
                            >
                                {formatSampleInfo(_)}
                            </Link>

                            <span className='text-white-50'>
                                {t.plainText(f.formatExposureDurationByFields(_.fields, props.customFields, timeService))}
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </HoverMenu>
    )
}

export default SampleHoverMenu
