import { classnames } from '_/facade/react'
import { Link } from '_/utils/router'
import type { TrendIdentificationNotification as TrendIdentificationData }  from '_/model/notification/types'
import { getRoute }  from '_/model/notification/helpers'

interface Props {
    notification: TrendIdentificationData
    onClick: () => void
    dropdownLayout: boolean
    testId?: string
}

function TrendIdentificationNotification(props: Props) {
    const data = props.notification.data

    function handleClick(e: React.MouseEvent) {
        e.preventDefault()
        props.onClick()
    }

    return (
        <div>
            <Link
                routeName={getRoute(props.notification)}
                className={classnames('cursor-pointer', { 'text-white': props.dropdownLayout })}
                onClick={handleClick}
                testId={`${props.testId}-link`}
            >
                <div className='fw-bold'>
                    The {data.trendName} trend has been identified
                </div>
            </Link>
            <div className='text-secondary' data-testid={`${props.testId}-context-info`}>{props.notification.contextName}</div>
        </div>
    )
}

export default TrendIdentificationNotification
