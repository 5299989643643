import { useAction, useEffect, useState } from '_/facade/react'
import * as m from '_/components/modal'
import AuditTrails from '_/components/audit-trail-list'
import { Close } from '_/components/button'
import type { ActionCreator } from '_/utils/redux'
import type { Guid } from '_/model/guid'
import type { AuditTrail } from '_/model/audit-trail/types'

type LoadAuditTrailAction = ActionCreator<string, Promise<AuditTrail>>

interface OwnProps {
    onClose(): void
    id: string | Guid
    loadAuditTrailAction: LoadAuditTrailAction
}

function AuditTrailModal(props: OwnProps) {
    const trails = useAuditTrail(props.id, props.loadAuditTrailAction)

    return(
        <m.Modal isOpen onClose={props.onClose}>
            <m.ModalHeader className='pb-0 border-bottom-0'>
                <h4 className='ps-1'>Audit trail</h4>
                <Close onClick={props.onClose} testId='close-audit-trail-modal' />
            </m.ModalHeader>
            <m.ModalBody>
                <AuditTrails
                    trail={trails}
                    hideHeader
                />
            </m.ModalBody>
        </m.Modal>
    )
}

function useAuditTrail(id: string | Guid, action: LoadAuditTrailAction) {
    const load = useAction(action)
        , [trails, setTrails] = useState<AuditTrail | undefined>(undefined)

    useEffect(
        () => {
            load(id).then(setTrails)
        },
        [load, id]
    )

    return trails
}

export default AuditTrailModal
