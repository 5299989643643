import { Table } from '_/components/table'
import Button from '_/components/button'
import type TimeService from '_/services/time-service'
import type Export from '_/model/export/types'
import { COMPLETED, FAILED, IN_PROGRESS } from '_/model/export/export-status'
import { convertToReadableBytes } from '_/features/samples/helpers'
import { NON_VIABLE_SAMPLES_EXPORT, PLATES_EXPORT, SAMPLES_EXPORT } from '_/model/export/export-type'

interface Props {
    onDownload: (id: string) => void
    exports: Export[]
    timeService: TimeService
}

function ExportList(props: Props) {

    function buildExportingAmountInfo(exportData: Export) {
        switch(exportData.exportType) {
            case SAMPLES_EXPORT: {
                return <span>
                    &nbsp;&mdash; {exportData.metadata.samplesAmount} viable {exportData.metadata.samplesAmount === 1 ? 'sample' : 'samples'}{exportData.metadata.nullifiedIncluded ? ' (including nullified)' : ''}
                </span>
            }
            case NON_VIABLE_SAMPLES_EXPORT: {
                return <span>
                    &nbsp;&mdash; {exportData.metadata.samplesAmount} non-viable {exportData.metadata.samplesAmount === 1 ? ' sample' : ' samples'}
                </span>
            }
            case PLATES_EXPORT: {
                return <span>
                    &nbsp;&mdash; {exportData.metadata.platesAmount} {exportData.metadata.platesAmount === 1 ? 'plate' : 'plates'}
                </span>
            }
            default:
                return null
        }
    }

    return (
        <Table>
            <thead className='thead px-0 table-header--sticky'>
                <tr className='table-header-thin'>
                    <th className='border-top-0 border-bottom-0 px-0'>Export date</th>
                    <th className='border-top-0 border-bottom-0 px-0'/>
                </tr>
            </thead>
            <tbody className='export-modal-table--border'>
                {props.exports.map(_ =>
                    <tr key={_.id}>
                        <td className='px-0'>
                            {props.timeService.formatCtzDateTime(_.createdAt, true)}
                            {buildExportingAmountInfo(_)}
                            {_.metadata && !_.metadata.rejected && !!_.metadata.size &&
                                <div className='text-secondary small'>
                                    {convertToReadableBytes(_.metadata.size)}. Available until {props.timeService.formatCtzDate(props.timeService.addCtzDays(_.createdAt, 7))}
                                </div>
                            }
                            {_.metadata?.rejected && <div className='text-danger small'>{_.metadata.rejectReason}</div>}
                        </td>
                        <td className='text-end align-middle px-0'>
                            {_.exportStatus === IN_PROGRESS && <span className='text-secondary'>Preparing...</span>}
                            {_.exportStatus === COMPLETED &&
                                <Button
                                    onClick={() => props.onDownload(_.id)}
                                    className='btn-primary'
                                    title='Download'
                                    testId='download-export-file'
                                >
                                    Download
                                </Button>
                            }
                            {_.exportStatus === FAILED &&
                                <span className='py-0 px-2 ms-2 mt-1 rounded text-uppercase fw-bold text-white bg-danger'>
                                    Failed
                                </span>
                            }
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    )
}

export default ExportList
