import { UnitContainer } from '_/components/layout'
import { Link } from '_/utils/router'
import { LOG_IN } from '_/constants/routes'

const AuthConfirm: React.FC = () =>
    <UnitContainer>
        <h4>Confirmation Email Sent</h4>
        <div className='row'>
            <fieldset className='col-10'>
                <legend>Please check your email to verify your email address</legend>
                <Link
                    className='btn btn-primary'
                    routeName={LOG_IN}
                >
                    Back to login page
                </Link>
            </fieldset>
        </div>
    </UnitContainer>

export default AuthConfirm
