import { Form } from 'react-final-form'

import { useState, useAction, useEffect, useSelector } from '_/facade/react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import { SelectField, Submit, submitDisabled } from '_/components/form'
import Button from '_/components/button'
import type { UserMembership } from '_/model/auth/types'
import type Role from '_/model/roles/types'
import type User from '_/model/users/types'

import * as tree from '_/utils/tree'

import * as actions from '../actions'
import * as roleActions from '_/features/roles/actions'
import * as contextActions from '_/features/contexts/actions'

import validate from './validate'

interface Props {
    isOpen: boolean
    onClose: () => void
    user: User | undefined
    membership: UserMembership
    existingRoles: Role[]
    onRoleChanged: () => void
}

function ChangeRole(props: Props) {
    const allRoles = useRoles(props.membership.contextId)
        , contexts = useContexts()
        , handleChangeRole = useChangeRole(props.onRoleChanged, props.onClose, allRoles, props.membership, props.user)

    function getUserRoles() {
        if (contexts.length === 0)
            return []

        const context = contexts.find(_ => _.id === props.membership.contextId)!
            , userRoles = allRoles.filter(_ => _.contextLevel === context.level)

        return userRoles.filter(_ => !props.existingRoles.some(r => r.id === _.id))
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalHeader>
                {`Choose a new user role for ${props.user?.email}`}<br/>
                {`${props.membership.description} context`}
            </ModalHeader>
            <Form
                onSubmit={handleChangeRole}
                initialValues={{ roleId: props.membership.role.id }}
                validate={validate}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <ModalBody>
                            <div style={{height: '300px'}}>
                                <SelectField name='roleId' entities={getUserRoles()} calcId={_ => _.id} calcName={_ => _.name}>
                                    User roles
                                </SelectField>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button className='btn-secondary' onClick={props.onClose}>Cancel</Button>
                            <Submit disabled={submitDisabled(form)}>Save</Submit>
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default ChangeRole

function useRoles(contextId: string) {
    const loadContextRoles = useAction(roleActions.loadContextRoles)
        , [roles, setRoles] = useState<Role[]>([])

    useEffect(
        () => {
            loadContextRoles(contextId).then(setRoles)
        },
        [contextId, loadContextRoles]
    )

    return roles
}

function useContexts() {
    const loadContexts = useAction(contextActions.loadContexts)
        , contexts = useSelector(_ => _.contexts.list)

    useEffect(() => { loadContexts() }, [loadContexts])

    return tree.list(contexts)
}

function useChangeRole(onRoleChanged: () => void, onClose: () => void, allRoles: Role[], membership: UserMembership, user: User | undefined) {
    const changeRole = useAction(actions.changeRole)

    function handleChangeRole(values: { roleId: string }) {
        const role = allRoles.find(_ => _.id === values.roleId)!

        changeRole({
            role: { userId: user!.id, membershipId: membership.id, role },
            signatureSettings: membership.context.electronicSignatureSettings,
        }).then(onRoleChanged)

        onClose()
    }
    return handleChangeRole
}
