import { useField } from 'react-final-form'
import { classnames } from '_/facade/react'

import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'
import { dropFields } from '_/utils/object'
import { areArraysEqual } from '_/utils/array'

import { showFieldError } from './helpers'

interface InputFieldProps {
    id?: string
    name: string
    flag: number
    className?: string
    disabled?: boolean
    hasNoPermissions?: boolean
    autoFocus?: boolean
    children: React.ReactNode
    testid?: string
}

function FlagField(props: InputFieldProps) {
    const field = useField<number[]>(props.name, { isEqual: areArraysEqual })
        , inputProps = field.input
        , checked = inputProps.value.includes(props.flag)
        , forwardedProps = dropFields(inputProps, 'type', 'value', 'checked', 'onChange')

    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const checked = event.target.checked
            , nextValue = checked
                ? inputProps.value.concat(props.flag).sort((one, two) => one - two)
                : inputProps.value.filter(_ => _ !== props.flag)

        inputProps.onChange(nextValue)
    }

    return (
        <div className={`form-check d-print-none ${props.className || ''}`}>
            <input
                id={props.id}
                name={props.name}
                type='checkbox'
                className={classnames('form-check-input', { 'is-invalid': showFieldError(field.meta) })}
                checked={checked}
                onChange={handleChange}
                {...forwardedProps}
                disabled={props.hasNoPermissions || props.disabled}
                autoFocus={props.autoFocus}
                data-testid={props.testid}
            />
            <label
                className={classnames('form-check-label', {'no-permission': props.hasNoPermissions})}
                htmlFor={props.id}
                title={props.hasNoPermissions ? NO_PERMISSION_MESSAGE : ''}
            >
                {props.children}
            </label>
        </div>
    )
}

export default FlagField
