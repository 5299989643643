import { RECT_HEIGHT, RECT_WIDTH } from '_/model/reports/session-breaches-rect'

interface Props {
    backgroundColor: string
    count?: number
    setRef?: (_: SVGTextElement | null) => void
    testId?: string
}

const Cell = (props: Props) =>
    <svg
        width={RECT_WIDTH}
        height={RECT_HEIGHT}
        viewBox={`0 0 ${RECT_WIDTH} ${RECT_HEIGHT}`}
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect width={RECT_WIDTH} height={RECT_HEIGHT} fill={props.backgroundColor} data-testid={`${props.testId}-rect`} stroke='none' />

        {props.count !== undefined &&
            <text ref={props.setRef} x='50%' y='50%' fontSize={14} stroke='none' fill='black' textAnchor='middle' dominantBaseline='middle' data-testid={props.testId}>
                {props.count}
            </text>
        }
    </svg>

export default Cell
