import { Form } from 'react-final-form'

import { classnames, useState, useSelector, useAction, useEffect, useCallback, useMemo } from '_/facade/react'

import { resetForm, Submit, submitDisabled, TextField } from '_/components/form'
import { Modal, ModalBody, ModalHeader, ModalFooter } from '_/components/modal'
import AuditTrails from '_/components/audit-trail-list'
import Button, { Close } from '_/components/button'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import TabNavbar from '_/components/tab-navbar'

import * as tabs from '_/constants/modal-tab'
import * as routes from '_/constants/routes'

import type { SampleOperatorEdit, SampleOperator } from '_/model/predefined-lists/operator/types'

import validate from './validate'
import * as actions from '../actions'
import * as deletionActions from '_/features/confirmation/actions'
import * as routerActions from '_/features/routing/actions'
import type { FormApi } from 'final-form'
import ActiveField from '../../shared/active-field'
import type { AuditTrail } from '_/model/audit-trail/types'

const defaultSampleOperatorEdit: Partial<SampleOperatorEdit> = {
    name: '',
    isActive: false,
}

function SampleOperatorModal() {
    const sampleOperatorId = useSelector<string | undefined>(_ => _.router.route!.params.id)
        , navigateToList = useNavigateToList()
        , [sampleOperator, sampleOperatorTrail] = useOperator()
        , sampleOperators = useSelector(_ => _.predefinedLists.sampleOperators)
        , initialValue = useMemo(
            () => (
                sampleOperator
                    ? {
                        name: sampleOperator.name,
                        isActive: sampleOperator.isActive,
                    }
                    : defaultSampleOperatorEdit
            ),
            [sampleOperator]
        )
        , submit = useSubmitModal(initialValue)
        , remove = useDeletionModal(sampleOperator)
        , [activeTab, setActiveTab] = useState(tabs.DATA)

    return (
        <Modal isOpen onClose={navigateToList}>
            <Form<SampleOperatorEdit>
                onSubmit={submit}
                validate={_ => validate(_, sampleOperators.filter(_ => sampleOperator ? _.id !== sampleOperator.id : true))}
                initialValues={initialValue}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form} />

                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4 data-testid='operator-modal-title'>{sampleOperatorId ? 'Edit operator' : 'New operator'}</h4>
                                    <Close onClick={navigateToList} />
                                </div>
                                <TabNavbar>
                                    <Button
                                        onClick={() => setActiveTab(tabs.DATA)}
                                        className={classnames('btn-link navbar-tab me-4', { active: activeTab === tabs.DATA })}
                                    >
                                        Operator data
                                    </Button>
                                    <Button
                                        onClick={() => setActiveTab(tabs.AUDIT_TRAIL)}
                                        className={classnames('btn-link navbar-tab', { active: activeTab === tabs.AUDIT_TRAIL })}
                                        disabled={!sampleOperatorId}
                                    >
                                        Audit trail
                                    </Button>
                                </TabNavbar>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div className='predefined-list-modal-body-height'>
                                { activeTab === tabs.DATA && <TextField name='name' id='name' testId='field-name'>Name</TextField> }
                                { activeTab === tabs.AUDIT_TRAIL && <AuditTrails trail={sampleOperatorTrail} /> }
                            </div>
                        </ModalBody>
                        <ModalFooter className='border-top-0 modal-footer-height'>
                            {activeTab !== tabs.AUDIT_TRAIL &&
                                <div
                                    className={`d-flex flex-fill ${sampleOperatorId && activeTab === tabs.DATA
                                                                        ? 'justify-content-between'
                                                                        : 'justify-content-end'}`}>
                                    {sampleOperator && activeTab === tabs.DATA &&
                                        <div>
                                            <Button
                                                className='text-danger bg-transparent border-0 me-2'
                                                onClick={() => remove(form.form)}
                                                disabled={sampleOperator.inUse}
                                            >
                                                Remove
                                            </Button>
                                            <ActiveField inUse={sampleOperator.inUse} isActive={sampleOperator.isActive} />
                                        </div>
                                    }
                                    <div>
                                        <Button className='text-muted bg-transparent border-0 me-2' onClick={navigateToList} testId='operator-modal-close'>Cancel</Button>
                                        <Submit disabled={submitDisabled(form)} testId='operator-save'>{sampleOperatorId ? 'Save changes' : 'Create'}</Submit>
                                    </div>
                                </div>
                            }
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default SampleOperatorModal

function useOperator() {
    const [sampleOperator, setSampleOperator] = useState<SampleOperator>()
        , [sampleOperatorTrail, setSampleOperatorTrail] = useState<AuditTrail>()
        , sampleOperatorId = useSelector<string | undefined>(_ => _.router.route!.params.id)
        , loadSampleOperator = useAction(actions.loadSampleOperator)
        , loadSampleOperatorTrail = useAction(actions.loadSampleOperatorTrail)

    useEffect(
        () => {
            if (!sampleOperatorId)
                return

            loadSampleOperator(sampleOperatorId).then(setSampleOperator)
            loadSampleOperatorTrail(sampleOperatorId).then(setSampleOperatorTrail)
        },
        [sampleOperatorId, setSampleOperator, loadSampleOperator, loadSampleOperatorTrail]
    )

    return [sampleOperator, sampleOperatorTrail] as const
}

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SETTINGS_OPERATORS), [navigateTo])

    return navigateToList
}

function useSubmitModal(initialValue: Partial<SampleOperatorEdit>) {
    const sampleOperatorId = useSelector<string | undefined>(_ => _.router.route!.params.id)
        , createSampleOperator = useAction(actions.createSampleOperator)
        , saveSampleOperator = useAction(actions.saveSampleOperator)
        , navigateToList = useNavigateToList()

    const submit = (newSampleOperator: SampleOperatorEdit, form: FormApi<SampleOperatorEdit, Partial<SampleOperatorEdit>>) => {
        const result = sampleOperatorId
            ? saveSampleOperator({
                id: sampleOperatorId,
                oldSampleOperator: initialValue as SampleOperatorEdit,
                newSampleOperator: newSampleOperator,
            })
            : createSampleOperator(newSampleOperator)

        return result
            .then(() => resetForm(form, !sampleOperatorId))
            .then(navigateToList)
    }

    return submit
}

function useDeletionModal(sampleOperator: SampleOperator | undefined) {
    const removeSampleOperator = useAction(actions.removeSampleOperator)
        , confirmDeletion = useAction(deletionActions.showDeletionConfirmationModal)
        , navigateToList = useNavigateToList()
        , remove = (form: FormApi<SampleOperatorEdit, Partial<SampleOperatorEdit>>) => {
            confirmDeletion(`Are you sure you want to delete ${sampleOperator!.name}?`)
                .then(_ => removeSampleOperator(sampleOperator!.id))
                .then(() => resetForm(form))
                .then(navigateToList)
        }

    return remove
}
