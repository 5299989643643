import type { PredefinedLists as State } from '_/model/app-state'
import { commonReducer, handler } from '_/facade/reducer'
import { predefinedListLoaded } from '../redux/actions'
import * as actions from './actions'

const sampleTypesDefaultState: State['sampleTypes'] = []
    , sampleTypes = commonReducer(
        sampleTypesDefaultState,

        handler(actions.sampleTypeListLoaded, (_, payload) => payload),
        handler(actions.sampleTypeRemoved, (state, id) => state.filter(_ => _.id !== id)),
        handler(predefinedListLoaded, (_, payload) => payload.sampleTypes),
    )

export {
    sampleTypes,
}
