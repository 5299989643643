import type { FormRenderProps} from 'react-final-form'
import { FormSpy } from 'react-final-form'
import type { FormState } from 'final-form'

import { React, connect, bindComponent, dispatchPropsMapper } from '_/facade/react'

import * as warningActions from '_/features/unsaved-changes/actions'

interface Props extends ConnectedProps {
    form?: FormRenderProps<any>
    target?: string
    includePristineNotification?: boolean
    onChange?: (values: any, form: FormRenderProps) => void
}

interface State {
    wasDirty: boolean
}

class FormChangesObserver extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        bindComponent(this)
        this.state = {
            wasDirty: false
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.form && prevProps.form && this.props.form.dirty !== prevProps.form.dirty)
            this.props.hasUnsavedChanges(this.props.form.dirty, this.props.target)
    }

    componentWillUnmount() {
        this.props.hasUnsavedChanges(false, this.props.target)
    }

    handleChangeFormValues(_: FormState<any>) {
        const wasDirty = this.state.wasDirty || _.dirty

        if (wasDirty !== this.state.wasDirty)
            this.setState({ wasDirty })

        if ((wasDirty || this.props.includePristineNotification) && this.props.onChange)
            this.props.onChange(_.values, this.props.form!)
    }

    render() {
        return (
            <FormSpy
                onChange={this.handleChangeFormValues}
                subscription={{values: true, dirty: true}}
            />
        )
    }
}

const mapDispatchToProps = dispatchPropsMapper({
    hasUnsavedChanges: warningActions.hasUnsavedChanges,
})
type ConnectedProps = ReturnType<typeof mapDispatchToProps>

export default connect(undefined, mapDispatchToProps)(FormChangesObserver)
