import type { ValidationResult} from '_/utils/form/validate'
import { required, maxLength, isEmpty, allowedName } from '_/utils/form/validate'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import { searchDuplicateName } from '_/model/common/helpers'
import type { Guid } from '_/model/guid'

export default function validate(entity: Partial<SampleType>, sampleTypes: SampleType[], id: Guid | undefined) {
    const name = isEmpty('Sample name')(entity.name)
        , nameLength = maxLength('Sample name', 100)(entity.name)
        , notAllowedNames = allowedName('Sample name')(entity.name)
        , manufacturer = isEmpty('Manufacturer')(entity.manufacturer)
        , manufacturerLength = maxLength('Manufacturer', 100)(entity.manufacturer)
        , sampleType = required('Sample type')(entity.sampleType)
        , mediumType = isEmpty('Medium type')(entity.mediumType)
        , mediumTypeLength = maxLength('Medium type', 100)(entity.mediumType)
        , duplicateName = entity.name && searchDuplicateName({...entity, id}, sampleTypes)
        , result: ValidationResult<SampleType> = {}

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (notAllowedNames)
        result.name = notAllowedNames

    if (duplicateName)
        result.name = `Sample type with name '${entity.name!.trim()}' already exists`

    if (manufacturer)
        result.manufacturer = manufacturer

    if (manufacturerLength)
        result.manufacturer = manufacturerLength

    if (sampleType)
        result.sampleType = sampleType

    if (mediumType)
        result.mediumType = mediumType

    if (mediumTypeLength)
        result.mediumType = mediumTypeLength

    return result
}
