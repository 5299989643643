import Link from '_/components/link'
import { paramsFilter } from '_/model/filters/helpers'

import { SAMPLES } from '_/constants/routes'
import { ALL_STATUS_IDS } from '_/constants/sample-status'

interface Props {
    trendInvestigationRefs: string[]
}

function TrendInvestigationRefs(props: Props) {
    return (
        <div className='row g-2'>
            <label className='d-flex justify-content-between col-4 col-form-label'>
                <span className='text-muted'>Trend investigation refs</span>
            </label>
            <div className='col-8'>
                <span className='form-control-plaintext'>
                    {props.trendInvestigationRefs.map((_, i) =>
                        <div key={i} className='fw-bold single-line-text' title={_}>
                            <Link routeName={SAMPLES} routeParams={paramsFilter({ trendInvestigationReferences: [_], statuses: ALL_STATUS_IDS })}>{_}</Link>
                        </div>
                    )}
                </span>
            </div>
        </div>

    )
}

export default TrendInvestigationRefs
