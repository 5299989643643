const LOG_IN = 0
    , SIGN_UP = 1
    , E_SIGNATURE = 2

type SsoRequestSource = typeof LOG_IN | typeof SIGN_UP | typeof E_SIGNATURE

export {
    LOG_IN,
    SIGN_UP,
    E_SIGNATURE,
    SsoRequestSource,
}
