import { classnames, React, useAction, useSelector } from '_/facade/react'

import { Table } from '_/components/table'
import { LinkButton } from '_/components/link'

import { GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION } from '_/constants/sample-status'
import { ANY_ID } from '_/constants/system-words'
import * as routes from '_/constants/routes'

import { paramsFilter } from '_/model/filters/helpers'
import type { KpiRow } from '_/model/reports/operators-report/types'
import type { OperatorsReport } from '_/model/reports/operators-report/types'
import { getSampleListParams } from '_/model/reports/operators-report/helpers'
import { CONTAMINATION, LIMIT_BREACH } from '_/model/reports/locations-report/trend-type'
import type { KpiData } from '_/model/reports/types'
import KpiInfo from './kpi-data'
import Button from '_/components/button'
import type { KpiBlockSamplesInfo } from '_/model/reports/types'

import * as sampleReportActions from '_/features/reports/operators-report/actions'
import { useTimeService } from '_/components/time'
import { getDateRange } from '_/model/reports/helpers'
import TableFooter from '../../table-footer'
import type * as t from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'
import NoDataAvailable from '../../no-data-available'

interface Props {
    kpiTableData: KpiRow[]
    operatorName: t.Text
    operatorReport: OperatorsReport
    showSpinner: boolean
    printMode: boolean
    entityName: string
}

function KpiTable(props: Props) {
    const canExportData = useSelector(_ => _.auth.permissions.exportData)
        , exportOperatorContaminationBreachRates = useAction(sampleReportActions.exportOperatorContaminationBreachRates)
        , noContaminationData = props.kpiTableData.every(kpiRow => isKpiDataEmpty(kpiRow.contaminationRateKpiData))
        , noLimitBreachData = props.kpiTableData.every(kpiRow => isKpiDataEmpty(kpiRow.limitBreachKpiData))
        , kpiTableEmpty = noContaminationData && noLimitBreachData
        , timeService = useTimeService()
        , isTableEmpty = isKpiTableDataEmpty(props.kpiTableData)

    function isKpiDataEmpty(kpiData: KpiData | undefined) {
        if (!kpiData)
            return true

        const currentPeriod = kpiData.currentPeriodInfo

        return currentPeriod.readSamplesCount === 0
            && currentPeriod.samplesWithCfuCount === 0
    }

    return (
        <div className='block-border px-3 mt-3'>
            <div className='my-4 text-center'>
                <h4>Contamination and breach rates by grade for viable samples with <FormattedText text={props.operatorName} /> as operator</h4>
            </div>
            <Table>
                <thead className='thead'>
                    <tr className='table-header-thin'>
                        <th>Grade</th>
                        <th className='text-end'>Contamination rate</th>
                        <th className='text-end'>Limit breach rate</th>
                    </tr>
                </thead>
                <tbody>
                    {isTableEmpty || props.showSpinner
                        ? <tr>
                            <td colSpan={3} className='text-center'>
                                <NoDataAvailable showSpinner={props.showSpinner}>
                                    <span className='text-muted text-uppercase'>
                                        No data available
                                    </span>
                                </NoDataAvailable>
                            </td>
                        </tr>
                        : props.kpiTableData.map((_, i) =>
                            <tr key={i}>
                                <td>{_.gradeName}</td>
                                <KpiInfo kpiData={_.contaminationRateKpiData} trendType={CONTAMINATION} />
                                <KpiInfo kpiData={_.limitBreachKpiData} trendType={LIMIT_BREACH} />
                            </tr>
                        )
                    }
                </tbody>
                {!props.printMode && !isTableEmpty &&
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <div className='d-flex justify-content-end'>
                                    <LinkButton
                                        className={classnames('btn-primary me-1', { disabled: noContaminationData })}
                                        routeName={routes.SAMPLES}
                                        routeParams={paramsFilter({ ...getSampleListParams(props.operatorReport, timeService), statuses: [GROWTHS_ID_COMPLETE, GROWTHS_AWAITING_IDENTIFICATION], gradeIds: [ANY_ID] })}
                                    >
                                        Go to contaminated viable samples
                                    </LinkButton>
                                    <LinkButton
                                        className={classnames('btn-primary me-1', { disabled: noLimitBreachData })}
                                        routeName={routes.SAMPLES}
                                        routeParams={paramsFilter({ ...getSampleListParams(props.operatorReport, timeService, true), gradeIds: [ANY_ID] })}
                                    >
                                        Go to limit breached viable samples
                                    </LinkButton>
                                    <Button
                                        className={classnames('btn-primary', { 'cursor-default': kpiTableEmpty })}
                                        disabled={kpiTableEmpty}
                                        hasNoPermissions={!canExportData}
                                        onClick={() => exportOperatorContaminationBreachRates({
                                            id: props.operatorReport.operatorId!,
                                            request: getDateRange(timeService, props.operatorReport, false)
                                        })}
                                    >
                                        Export
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                }
            </Table>
            {props.printMode && !isTableEmpty &&
                <TableFooter report={props.operatorReport} entityName={props.entityName} />
            }
        </div>
    )
}

export default KpiTable

function isKpiTableDataEmpty(kpiTableData: KpiRow[]) {
    return kpiTableData.length === 0
        || kpiTableData.every(_ =>
            isKpiBlockEmpty(_.limitBreachKpiData?.currentPeriodInfo)
            && isKpiBlockEmpty(_.contaminationRateKpiData?.currentPeriodInfo)
        )
}

function isKpiBlockEmpty(info: KpiBlockSamplesInfo | undefined) {
    return !info?.readSamplesCount
        && !info?.samplesWithCfuCount
}
