import { useAction, useEffect, useSelector, useState } from '_/facade/react'
import { useResetOnContextSwitch } from '_/hooks/shared-hooks'
import type { PlateEdit } from '_/model/plate/plate'
import type Plate from '_/model/plate/plate'
import PlateSearch from './search/plate-search'
import PlateView from './view/plate-view'
import * as a from '../actions'
import { pickFields } from '_/utils/object'
import PhotoGallery from '_/features/smart-check/ui/photo-gallery'
import type { SmartInfo } from '_/model/smart-check/image'
import type { NewPlateComment } from '_/model/plate/comments'
import type { AuditTrail } from '_/model/audit-trail/types'

type Mode =
    | { tag: 'search' }
    | { tag: 'view', plate: Plate }

function Reading() {
    const [mode, setMode] = useState<Mode>({ tag: 'search' })
        , [isTakingPhoto, setIsTakingPhoto] = useState(false)
        , canEdit = useSelector(_ => _.auth.permissions.performColonyCounterPlateReading)
        , trail = useTrail(mode)
        , handleSubmit = useSubmitHandler(mode, setMode)
        , handlePhotoTaken = usePhotoTakenHandler(mode, setMode)
        , handleAddComment = useAddCommentHandler(mode, setMode)

    useResetOnContextSwitch(() => setMode({ tag: 'search' }))

    return (
        <div className='container-fluid h-100'>
            <div className='row h-100 d-print-block'>
                <div className='col-3 d-flex flex-column p-0 width-print-100'>
                    {mode.tag === 'search' &&
                        <PlateSearch onLoad={_ => setMode({ tag: 'view', plate: _ })} />
                    }
                    {mode.tag === 'view' &&
                        <PlateView
                            isTakingPhoto={isTakingPhoto}
                            plate={mode.plate}
                            trail={trail}
                            onSubmit={handleSubmit}
                            onCancel={() => setMode({ tag: 'search' })}
                            onAddComment={handleAddComment}
                        />
                    }
                </div>
                <div className='col-9 bg-light width-print-100'>
                    <PhotoGallery
                        className='h-100'
                        entityId={mode.tag === 'view' ? mode.plate.id : undefined}
                        attachedImages={mode.tag === 'view' ? mode.plate.images : undefined}
                        blank={mode.tag === 'search'}
                        onPhotoTaking={setIsTakingPhoto}
                        onPhotoTaken={handlePhotoTaken}
                        hasNoPermission={!canEdit}
                        // zeroGrowthCheckEnabled
                        // automatedGrowthCountVerificationEnabled
                    />
                </div>
            </div>
        </div>
    )
}

export default Reading

function useTrail(mode: Mode) {
    const [EMPTY_TRAIL] = useState({ events: [] })
        , loadPlateTrail = useAction(a.loadPlateTrail)
        , plate = mode.tag === 'view' ? mode.plate : undefined
        , [trail, setTrail] = useState<AuditTrail>(EMPTY_TRAIL)

    useEffect(
        () => {
            setTrail(EMPTY_TRAIL)
            if (!plate)
                return

            loadPlateTrail(plate.id).then(setTrail)
        },
        [plate, EMPTY_TRAIL, loadPlateTrail]
    )

    return trail
}

function useSubmitHandler(mode: Mode, setMode: React.Dispatch<React.SetStateAction<Mode>>) {
    const savePlate = useAction(a.savePlate)
        , loadPlate = useAction(a.loadPlate)

    function handleSubmit(newPlate: PlateEdit) {
        if (mode.tag !== 'view')
            return Promise.reject()

        return savePlate({ id: mode.plate.id, newPlate, oldPlate: pickFields(mode.plate, 'barcode', 'cfuCount') })
            .then(() => loadPlate(mode.plate.id))
            .then(plate => setMode({ tag: 'view', plate }))
    }

    return handleSubmit
}

function usePhotoTakenHandler(mode: Mode, setMode: React.Dispatch<React.SetStateAction<Mode>>) {
    const attachImage = useAction(a.attachImage)
        , loadPlate = useAction(a.loadPlate)

    function handlePhotoTaken(info: SmartInfo) {
        if (mode.tag !== 'view')
            return Promise.reject()

        return attachImage({ plateId: mode.plate.id, imageId: info.imageId })
            .then(() => loadPlate(mode.plate.id))
            .then(plate => setMode({ tag: 'view', plate }))
    }

    return handlePhotoTaken
}

function useAddCommentHandler(mode: Mode, setMode: React.Dispatch<React.SetStateAction<Mode>>,) {
    const addComment = useAction(a.addPlateComment)
        , loadPlate = useAction(a.loadPlate)

    function handleAddComment(comment: NewPlateComment) {
        if(mode.tag !== 'view')
            return Promise.reject()

        return addComment({ plateId: mode.plate.id, plateComment: comment })
            .then(() => loadPlate(mode.plate.id))
            .then(plate => setMode({ tag: 'view', plate }))
    }

    return handleAddComment
}
