import { reducer, handler } from '_/facade/reducer'
import type { Toasts as State } from '_/model/app-state'
import * as actions from './actions'

const defaultState: State = {
    list: [],
}

export default reducer(
    defaultState,
    handler(actions.addToast, (state, payload) => ({ list: state.list.concat([payload]) })),
    handler(actions.deleteToast, (state, payload) => ({ list: state.list.filter((item) => item != payload) }))
)
