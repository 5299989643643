import { useSelector } from '_/facade/react'

import * as confirmationType from '_/constants/confirmation-type'
import ConfirmationModal from './confirmation-modal'
import WarningModal from './warning-modal'

function GenericConfirmationModal() {
    const type = useSelector(_ => _.confirmation.confirmationType)

    switch (type) {
        case confirmationType.CONFIRMATION_MODAL:
            return <ConfirmationModal title='Confirmation' confirmButtonName='Confirm' confirmButtonClassName='btn-primary' testId='confirmation' />
        case confirmationType.DELETION_CONFIRMATION_MODAL:
            return <ConfirmationModal title='Deletion confirmation' confirmButtonName='Delete' testId='deletion-confirmation' />
        case confirmationType.DISABLE_CONFIRMATION_MODAL:
            return <ConfirmationModal title='Disable confirmation' confirmButtonName='Disable' testId='disable-confirmation' />
        case confirmationType.WARNING_MODAL:
            return <WarningModal />
        default:
            return null
    }
}

export default GenericConfirmationModal
