const ALL = 0
    , WITH_REF = 1
    , WITHOUT_REF = 2

type WithRefFlag = never
    | typeof ALL
    | typeof WITH_REF
    | typeof WITHOUT_REF

export {
    WithRefFlag,
    ALL,
    WITH_REF,
    WITHOUT_REF,
}
