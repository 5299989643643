import { useForm } from 'react-final-form'
import { React, useContext } from '_/facade/react'
import type SampleReading from '_/model/sample/reading/sample-reading'
import * as ref from '_/model/sample/identification-reference'

const ctx = React.createContext<() => number>(() => { throw new Error('unexpected call') })

interface Props {
    children: React.ReactNode
    isFingerdabTwoHandsPlate: boolean
}

function IdentificationsReference(props: Props) {
    const form = useForm<SampleReading>('')
        , state = form.getState()

    const calculatedHighestReference = highestReference(state.initialValues.identificationHighestReference ?? -1, state.values, props.isFingerdabTwoHandsPlate)

    return <ctx.Provider value={() => ref.nextReference(calculatedHighestReference)} children={props.children} />
}

function useNextReference() {
    return useContext(ctx)
}

export default IdentificationsReference
export { useNextReference }

function highestReference(highestStoredReference: number, reading: SampleReading, isFingerdabTwoHandsPlate: boolean): number {
    const idReferences = reading.identifications.rows.map(_ => _.reference)
        , optionalIdReferences = isFingerdabTwoHandsPlate
            ? reading.optionalIdentifications?.rows.map(_ => _.reference) ?? []
            : []

    return ref.maxReference(highestStoredReference, ...idReferences, ...optionalIdReferences)
}
