import { classnames } from '_/facade/react'
import type { Router } from 'router5'
import { dropFields } from '../object'

import { RouterConsumer } from './context'

interface LinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
    routeName: string
    routeParams?: any
    ignoredRoutes?: string[]
    activeRoutes?: string[]
    testId?: string
}

interface InternalProps extends LinkProps {
    router: Router
}

function handleClick(event: React.MouseEvent<HTMLAnchorElement>, props: InternalProps) {
    if (event.defaultPrevented)
        return

    if (props.onClick)
        props.onClick(event)

    const skip = event.button !== 0
        || event.metaKey || event.altKey || event.ctrlKey || event.shiftKey
        || event.defaultPrevented

    if (skip)
        return

    event.preventDefault()
    props.router.navigate(props.routeName, props.routeParams)
}

const InternalLink: React.FC<InternalProps> = props => {
        const forwardedProps = dropFields(props, 'routeName', 'routeParams', 'router', 'onClick', 'ignoredRoutes', 'testId', 'activeRoutes')
            , href = props.router.buildPath(props.routeName, props.routeParams)
            , ignoredRoutes = props.ignoredRoutes || []
            , activeRoutes = props.activeRoutes ?? []
            , active = props.router.isActive(props.routeName, props.routeParams)
                && !ignoredRoutes.some(_ => props.router.isActive(_))
                || activeRoutes.some(_ => props.router.isActive(_))

        return <a
            {...forwardedProps}
            className={classnames(props.className, { active })}
            href={href}
            onClick={_ => handleClick(_, props)}
            data-testid={props.testId}
        />
    }

const Link: React.FC<LinkProps> = props =>
    <RouterConsumer children={
        _ => <InternalLink router={_} {...props}/>
    }/>

export default Link
export { LinkProps }
