import { useFieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'

import { useSelector } from '_/facade/react'

import { Table } from '_/components/table'
import Button from '_/components/button'
import type { FilterEdit, Timeframe } from '_/model/predefined-lists/trend-settings/types'
import * as ft from '_/model/predefined-lists/trend-settings/filter-type'
import * as fieldTypes from '_/constants/custom-field-column-type'

import type CustomField from '_/model/predefined-lists/custom-field/types'
import { list } from '_/utils/tree'
import { TextField, SelectField, LocalDateField, TextTimeField, NumericRangeField, MultiSelectField, CheckboxField } from '_/components/form'
import SearchByFieldValue from '_/features/predefined-lists/custom-fields/custom-field-search'
import { isDefaultCustomField, getEntitiesForFilter, isSystemId } from '_/model/predefined-lists/custom-field/custom-field'
import { formatActiveState } from '_/utils/format/common'

import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import LOCATION_TYPE from '_/model/predefined-lists/exposure-location/location-type'

import { filterFormat, isRestrictedFilterForConsecutiveSamples } from '_/model/predefined-lists/trend-settings/helpers'
import * as fi from '_/constants/custom-field-index'
import FormattedText from '_/features/text/formatted-text'
import { formatFieldLabel } from '_/features/samples/helpers'
import * as idt from '_/model/sample/identification-type'
import { typeArgument } from '_/model/predefined-lists/identifications/helpers'
import type { AllIdentifications } from '_/model/predefined-lists/identifications/identifications'
import MultipleOrganismSearch from '_/features/predefined-lists/organism-identification/multiple-organism-search'
import * as rit from '_/model/predefined-lists/trend-settings/ranked-identification-type'
import * as tt from '_/model/predefined-lists/trend-settings/timeframe-type'
import { systemTextNode } from '_/model/text/text'
import BatchNumberField from '_/features/samples/shared/batch-number-field'

interface Props {
    editMode: boolean
}

function Filters(props: Props) {
    const filtersField = useFieldArray<FilterEdit>('filters')
        , predefinedLists = useSelector(_ => _.predefinedLists)

    return (
        <div className='mt-3'>
            <h5>Filters</h5>
            <Table>
                <thead className='thead'>
                    <tr className='table-header-thin'>
                        <th>Filters</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {filtersField.fields.map((_, fieldIndex) =>
                        <tr key={fieldIndex}>
                            <td className='py-1'>
                                {props.editMode
                                    ? <FormattedText text={filterFormat(filtersField.fields.value[fieldIndex], predefinedLists)} />
                                    : <NewFilterRow
                                        index={fieldIndex}
                                        values={filtersField.fields.value}
                                        identifications={predefinedLists.identifications}
                                        customFields={predefinedLists.customFields}
                                        locations={list(predefinedLists.exposureLocations)}
                                    />
                                }
                            </td>
                            <td className='py-1 text-end'>
                                {!props.editMode &&
                                    <div className='row g-2'>
                                        <div className='mb-3 col-auto'>
                                            <label className='col-form-label'>&nbsp;</label>
                                            <Button
                                                onClick={() => filtersField.fields.remove(fieldIndex)}
                                                className='p-0 text-danger input-group form-control'
                                            >
                                                <i className='material-icons'>delete</i>
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {!props.editMode &&
                <Button
                    className='btn-primary d-print-none me-1'
                    onClick={() => filtersField.fields.push({ includeAllChildren: true })}
                >
                    Add another filter
                </Button>
            }
        </div>
    )
}

export default Filters

interface NewFilterRow {
    index: number
    values: FilterEdit[]
    identifications: AllIdentifications
    customFields: CustomField[]
    locations: ExposureLocation[]
}

const NOT_USED_FIELDS = [fi.BARCODE, fi.EXPOSURE_DATE, fi.EXPOSURE_LOCATION_ID, fi.MONITORING_POSITION]

function NewFilterRow(props: NewFilterRow) {
    const predefinedLists = useSelector(_ => _.predefinedLists)
        , gradeField = { index: fi.EXPOSURE_LOCATION_GRADE_ID, fieldName: 'Grade', fieldType: fieldTypes.SELECTION }
        , value = props.values[props.index]
        , customFields = [gradeField]
            .concat(props.customFields)
            .filter(_ => NOT_USED_FIELDS.indexOf(_.index) === -1)
        , identificationTypes = idt.IDENTIFICATION_TYPE
            .filter(_ => idt.IDENTIFICATION_WITH_ARGUMENT.some(typeId => typeId === _.id))
        , filterEntities = ft.TREND_FILTER
            .filter(_ => {
                if (_.id === value.type)
                    return true

                if (_.id === ft.IN)
                    return !props.values.some(_ => _.type === ft.IN)

                if (_.id === ft.IN_THE_SAME)
                    return !props.values.some(_ => _.type === ft.IN_THE_SAME)

                return true
            })
        , hasTheSameIdentificationEntities = rit.RANKED_IDENTIFICATION_TYPE
            .filter(_ => {
                if (_.id === rit.ORGANISM_GENUS)
                    return !props.values.some(v => v.type === ft.HAS_THE_SAME_IDENTIFICATION && v.identificationTypes?.some(t => t === rit.ORGANISM_SPECIES))

                if (_.id === rit.ORGANISM_SPECIES)
                    return !props.values.some(v => v.type === ft.HAS_THE_SAME_IDENTIFICATION && v.identificationTypes?.some(t => t === rit.ORGANISM_GENUS))

                return true
            })
        , timeframe = useField<Timeframe>('timeframe').input.value

    function buildFields() {
        switch (value.type) {
            case ft.HAS: {
                const unusedCustomFields = customFields.filter(_ =>
                    !props.values.some(v => v.type === ft.HAS && v.dataField === _.index && v.dataField !== value.dataField)
                )

                return <SelectField
                    name={`filters.${props.index}.dataField`}
                    entities={unusedCustomFields}
                    calcId={_ => _.index}
                    calcName={formatFieldLabel}
                    className='col-5'
                >
                    Data fields
                </SelectField>
            }
            case ft.IN:
                return <>
                    <MultiSelectField
                        name={`filters.${props.index}.exposureLocation`}
                        entities={getEntitiesForFilter(fi.EXPOSURE_LOCATION_ID, predefinedLists)}
                        calcId={_ => _.id}
                        calcName={_ => isSystemId(_.id) ? [systemTextNode(_.pathName)] : formatActiveState(_.pathName, _.isActive)}
                        className='col-5'
                    >
                        Exposure location
                    </MultiSelectField>
                    <div className='mb-3 col-4'>
                        <label className='col-form-label my-2'></label>
                        <CheckboxField name={`filters.${props.index}.includeAllChildren`} id={`filters.${props.index}.includeAllChildren`} className='mt-2'>
                            Include all child locations
                        </CheckboxField>
                    </div>
                </>
            case ft.HAS_THE_SAME:
                return <MultiSelectField
                    name={`filters.${props.index}.dataFields`}
                    entities={customFields}
                    calcId={_ => _.index}
                    calcName={formatFieldLabel}
                    className='col-5'
                >
                    Data fields
                </MultiSelectField>
            case ft.IN_THE_SAME:
                return <>
                    <MultiSelectField
                        name={`filters.${props.index}.locationTypes`}
                        entities={LOCATION_TYPE}
                        calcId={_ => _.id}
                        calcName={_ => _.name}
                        className='col-5'
                    >
                        Location types
                    </MultiSelectField>
                    <div className='mb-3 col-4'>
                        <label className='col-form-label my-2'></label>
                        <CheckboxField name={`filters.${props.index}.includeAllChildren`} id={`filters.${props.index}.includeAllChildren`} className='mt-2'>
                            Include all child locations
                        </CheckboxField>
                    </div>
                </>
            case ft.HAS_IDENTIFICATION: {
                const unusedIdentificationTypes = identificationTypes.filter(_ =>
                    !props.values.some(v => v.type === ft.HAS_IDENTIFICATION && v.identificationType === _.id && v.identificationType !== value.identificationType)
                )
                return <SelectField
                    name={`filters.${props.index}.identificationType`}
                    entities={unusedIdentificationTypes}
                    calcId={_ => _.id}
                    calcName={_ => _.name}
                    className='col-5'
                >
                    Identification
                </SelectField>
            }
            case ft.HAS_THE_SAME_IDENTIFICATION:
                return <MultiSelectField
                    name={`filters.${props.index}.identificationTypes`}
                    entities={hasTheSameIdentificationEntities}
                    calcId={_ => _.id}
                    calcName={_ => _.name}
                    className='col-5'
                >
                    Identifications
                </MultiSelectField>
            default: return
        }
    }

    function buildValueField() {
        switch (value.type) {
            case ft.HAS: {
                const name = `filters.${props.index}.value`
                    , fieldIndex = value.dataField
                    , customField = props.customFields.find(_ => _.index === fieldIndex)

                if (fieldIndex === fi.EXPOSURE_LOCATION_GRADE_ID)
                    return <MultiSelectField
                        name={name}
                        entities={predefinedLists.grades}
                        calcId={_ => _.id}
                        calcName={_ => _.name}
                        autocomplete
                        className='col-4'
                    >
                        Value
                    </MultiSelectField>

                if (fieldIndex === fi.BATCH_NUMBER)
                    return <BatchNumberField
                        id={name}
                        name={name}
                        editing={false}
                        hasLabel
                        isViable
                        className='col-4'
                    />

                if (!customField)
                    return null

                switch (customField.fieldType) {
                    case fieldTypes.TEXT: {
                        return isDefaultCustomField(customField)
                            ? <TextField
                                name={name}
                                className='col-4'
                            >
                                Value
                            </TextField>
                            : <SearchByFieldValue
                                id={name}
                                name={name}
                                fieldName='Value'
                                className='col-4'
                                customField={customField}
                            />

                    }
                    case fieldTypes.SELECTION: {
                        return <MultiSelectField
                            name={name}
                            entities={getEntitiesForFilter(customField.index, predefinedLists)}
                            calcId={_ => _.id}
                            calcName={_ => formatActiveState(_.name, _.isActive)}
                            autocomplete
                            className='col-4'
                        >
                            Value
                        </MultiSelectField>
                    }
                    case fieldTypes.NUMBER: {
                        return <div className='col-4'>
                            <NumericRangeField
                                id={name}
                                name={name}
                            />
                        </div>
                    }
                    case fieldTypes.DATE: {
                        return <LocalDateField
                            id={name}
                            name={name}
                            inline
                            className='col-4'
                        >
                            Value
                        </LocalDateField>
                    }
                    case fieldTypes.TIME: {
                        return <TextTimeField
                            id={name}
                            name={name}
                            className='col-4'
                        >
                            Value
                        </TextTimeField>
                    }
                    default: {
                        return null
                    }
                }
            }
            case ft.HAS_IDENTIFICATION: {
                const identificationType = value.identificationType

                if (!identificationType)
                    return

                if (identificationType === idt.ORGANISM) {
                    return <MultipleOrganismSearch
                        id='organismIds'
                        name={`filters.${props.index}.value`}
                        excludeInactive={true}
                        excludeNotYetIdentified={true}
                        excludeNoIdRequired={true}
                        excludeCorrectionMpnValue
                        className='col-4'
                    />
                }

                return <MultiSelectField
                    name={`filters.${props.index}.value`}
                    entities={typeArgument(identificationType as idt.IdentificationType, props.identifications)}
                    calcId={_ => _.id}
                    calcName={_ => isSystemId(_.id) ? [systemTextNode(_.name)] : formatActiveState(_.name, _.isActive)}
                    autocomplete
                    className='col-4'
                >
                    Value
                </MultiSelectField>
            }
            default: return
        }
    }

    function calcDisabled(type: ft.TrendFilter) {
        return timeframe.type === tt.FOR_CONSECUTIVE_SAMPLES
            && isRestrictedFilterForConsecutiveSamples(type)
    }

    return (
        <div className='row g-2'>
            <SelectField
                name={`filters.${props.index}.type`}
                entities={filterEntities}
                calcId={_ => _.id}
                calcName={_ => _.name}
                calcDisabled={_ => calcDisabled(_.id)}
                calcDisabledTitle={_ => `'for consecutive samples' timeframe not allowed for '${_.name.toLowerCase()}'`}
                className='col-3'
            >
                Filter
            </SelectField>
            {buildFields()}
            {buildValueField()}
        </div>
    )
}
