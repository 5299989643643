import { classnames } from '_/facade/react'
import Button from '_/components/button'
import type Notification from '_/model/notification/types'
import SingleNotification from './single-notification'

interface Props {
    notification: Notification
    onChangeStatus: () => void
    onNotificationClick: () => void
    testId?: string
}

function NotificationRow(props: Props) {
    return (
        <div className='border-bottom border-secondary' data-testid='notification-row'>
            <div className='d-flex justify-content-between mx-3 mb-2'>
                <div className='mt-2 me-2' data-testid={`notification-${props.notification.type}`}>
                    <SingleNotification
                        notification={props.notification}
                        onClick={props.onNotificationClick}
                        dropdownLayout
                        testId={props.testId}
                    />
                </div>
                <div className='mx-3 align-self-center'>
                    <Button
                        className='bg-transparent mb-3 p-0 shadow-none'
                        onClick={props.onChangeStatus}
                        title={props.notification.isRead ? 'Mark as unread' : 'Mark as read'}
                        testId={`${props.testId}-mark-${props.notification.isRead ? 'unread' : 'read'}`}
                    >
                        <span className={classnames('mark-as-read-button position-absolute', {'bg-primary': !props.notification.isRead, 'bg-transparent': props.notification.isRead})}/>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NotificationRow
