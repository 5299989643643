import { React, bindComponent, connect, dispatchPropsMapper } from '_/facade/react'
import { LocalDateField } from '_/components/form'
import type { FormRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { getIn } from 'final-form'

import * as timeActions from '_/features/time/actions'
import type { DateTime} from '_/model/date-time'
import { equals } from '_/model/date-time'

interface OwnProps {
    disabled?: boolean
    className?: string
    dateFieldName: string
    dateFieldId: string
    startDate: DateTime | undefined
    showDateFieldId?: string
    form: FormRenderProps<any>
    endTimeRef: React.RefObject<HTMLInputElement>
    differentDateRef?: React.RefObject<HTMLInputElement>
    forceHideDateField: boolean
    onKeyDown: React.KeyboardEventHandler<HTMLInputElement>
    testId?: string
}

interface State {
    checked: boolean
}

class OptionalDate extends React.Component<OwnProps & ConnectedProps, State> {
    focusDateField = false
    timeService = this.props.getTimeService()

    constructor(props: OwnProps & ConnectedProps) {
        super(props)

        bindComponent(this)

        const date = getIn(props.form.values, props.dateFieldName)
            , checked = !equals(props.startDate, date)

        this.state = { checked }
    }

    componentDidUpdate(prevProps: OwnProps) {
        const uncheck = prevProps.forceHideDateField !== this.props.forceHideDateField && this.props.forceHideDateField
        if (uncheck)
            this.setState({ checked: false })

        if (this.state.checked)
            return

        const date: DateTime | undefined = getIn(this.props.form.values, this.props.dateFieldName)
            , synchronize = !equals(this.props.startDate, date)

        if (synchronize)
            this.props.form.form.change(this.props.dateFieldName, this.props.startDate)
    }

    calculateEndDate(startDate: DateTime | undefined) {
        if (!startDate)
            return

        return this.timeService.addCtzDays(startDate, 1)
    }

    handleChangeShowOptionalDate(_: any) {
        const form = this.props.form
        this.setState({ checked: !this.state.checked })

        if (_.target.checked) {
            form.form.change(this.props.dateFieldName, this.calculateEndDate(this.props.startDate))
            this.focusDateField = true
        }
        else {
            form.form.change(this.props.dateFieldName, this.props.startDate)
            if (this.props.endTimeRef.current)
                this.props.endTimeRef.current.focus()
        }
    }

    render() {
        const props = this.props
            , showOptionalDateId = props.showDateFieldId || 'show-optional-date'
        return (
            <div>
                <div className={`form-check d-print-none ${props.className || ''}`}>
                    <input
                        className='form-check-input'
                        name='showOptionalDate'
                        id={showOptionalDateId}
                        type='checkbox'
                        checked={this.state.checked}
                        onChange={_ => this.handleChangeShowOptionalDate(_)}
                        disabled={props.disabled}
                        ref={props.differentDateRef}
                        data-testid='show-optional-date'
                        onKeyDown={props.onKeyDown}
                        tabIndex={-1}
                    />
                    <label
                        className='form-check-label'
                        htmlFor={showOptionalDateId}
                        data-testid='field-label'
                    >
                        Different date
                    </label>
                </div>
                {this.state.checked
                    ? <LocalDateField
                        autoFocus={this.focusDateField}
                        id={props.dateFieldId}
                        name={props.dateFieldName}
                        disabled={props.disabled}
                        testId={props.testId}
                        onKeyDown={props.onKeyDown}
                    >
                        End date
                    </LocalDateField>
                    : <Field name={props.dateFieldName}>{() => null}</Field>
                }
            </div>
        )
    }
}

const mapDispatchToProps = dispatchPropsMapper({
    getTimeService: timeActions.getTimeService,
})

type ConnectedProps = ReturnType<typeof mapDispatchToProps>

export default connect(undefined, mapDispatchToProps)(OptionalDate)
