import { useSelector, useAction, useState, useEffect } from '_/facade/react'

import { useAllPredefinedLists } from '_/hooks/shared-hooks'
import { useContextSwitchObserver } from '_/components/context-observer'

import * as trendSettingsActions from '_/features/predefined-lists/trend-settings/actions'

import * as routes from '_/constants/routes'

import type { TrendSettings } from '_/model/predefined-lists/trend-settings/types'
import type { TrendStatistics } from '_/model/trends/types'

import AllTrends from './all-trends'
import WithRefTrends from './with-ref-trends'
import WithoutRefTrends from './without-ref-trends'

interface Props {
    trendStatistic: TrendStatistics
    onReloadStatistic: () => void
}

function TrendsContainer(props: Props) {
    const route = useSelector(_ => _.router.route!)
        , trendSettings = useTrendSettings()

    useAllPredefinedLists()

    switch (route.name) {
        case routes.TRENDS_WITH_REF_TRENDS:
            return <WithRefTrends
                trendSettings={trendSettings}
                totalCount={props.trendStatistic.withRefCount}
                onReloadStatistic={props.onReloadStatistic}
            />
        case routes.TRENDS_WITHOUT_REF_TRENDS:
            return <WithoutRefTrends
                trendSettings={trendSettings}
                totalCount={props.trendStatistic.withoutRefCount}
                onReloadStatistic={props.onReloadStatistic}
            />
        default:
            return <AllTrends
                trendSettings={trendSettings}
                totalCount={props.trendStatistic.totalCount}
                onReloadStatistic={props.onReloadStatistic}
            />
    }
}

export default TrendsContainer

function useTrendSettings() {
    const loadTrendSettings = useAction(trendSettingsActions.loadTrendSettingsList)
        , [trendSettings, setTrendSettings] = useState<TrendSettings[]>([])
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            loadTrendSettings()
                .then(setTrendSettings)
        },
        [contextSwitch, loadTrendSettings]
    )

    return trendSettings
}
