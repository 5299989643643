import { memoize } from '_/utils/function'

declare module 'plotly.js/lib/core' {
    function register(charts: any[]): void
}

function getPlotly() {
    return Promise.all([
            import(/* webpackChunkName: 'plotly' */ 'plotly.js/lib/core'),
            import(/* webpackChunkName: 'plotly' */ 'plotly.js/lib/bar'),
            import(/* webpackChunkName: 'plotly' */ 'plotly.js/lib/pie'),
        ])
        .then(([core, bar, pie]) => {
            core.register([
                bar,
                pie,
            ])

            return core
        })
}

export default memoize(getPlotly)
