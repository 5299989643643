import { useState } from '_/facade/react'

import type { ExpectedSample, MonitoringOverviewSample, MonitoringOverviewSampleData, SelectedSample } from '_/model/scheduling/monitoring-overview/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import Button from '_/components/button'
import Checkbox from '_/components/checkbox-inline'
import SampleIcon from '_/components/sample-icon/sample-icon'

import ClickMenu from './scheduled-sample-click-menu'
import type { DateTime } from '_/model/date-time'
import { formatMonitoringGroupName, sampleData } from '_/model/scheduling/monitoring-overview/helpers'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import type { Guid } from '_/model/guid'
import type { MonitoringGroup } from '_/model/scheduling/monitoring-groups/types'

interface SampleCellDataProps {
    bookedSamples: MonitoringOverviewSampleData[]
    expectedSamples: (ExpectedSample & {id: string})[]
    customFields: CustomField[]
    sampleTypesIncludingDeleted: SampleType[]
    locations: ListExposureLocation[]
    index: number
    rowIndex: number
    date: DateTime
    sessions: SampleSession[]
    adHocGroups: MonitoringGroup[]
    monitoringGroups: MonitoringGroup[]
    onChangeNotInUse: (id: Guid, sessionId: Guid, notInUse: boolean) => void
    onChangeMissed: (id: Guid, sessionId: Guid, missed: boolean) => void
    onSelectSample: (_: SelectedSample) => void
    isSelected: (_: SelectedSample[]) => boolean
    selectMode: boolean
    selected: boolean
    onSelectCell: () => void
}

function SampleCellData(props: SampleCellDataProps) {
    const NO_SAMPLES = 'No Viable Samples'
        , { bookedSamples, expectedSamples } = props
        , [activeSample, setActiveSample] = useState<MonitoringOverviewSample>()
        , buttons = new Map<number, HTMLButtonElement | null>()
        , [activeButton, setActiveButton] = useState<HTMLButtonElement | null>(null)
        , emptyCell = bookedSamples.length === 0 && expectedSamples.length === 0
        , samples: MonitoringOverviewSample[] = [...props.bookedSamples, ...props.expectedSamples]

    function handleSampleClick(sample: MonitoringOverviewSample, index: number) {
        if (props.selectMode) {
            props.onSelectSample({ id: sample.id, sessionId: sample.sessionId })
            return
        }
        setActiveSample(sample)
        setActiveButton(buttons.get(index)!)
    }

    return (
        <td>
            <div className='d-flex flex-wrap align-items-start'>
                {props.selectMode &&
                    <Checkbox
                        name={`cell-${props.index}-row-${props.rowIndex}`}
                        id={`cell-${props.index}-row-${props.rowIndex}`}
                        checked={props.selected}
                        onChange={props.onSelectCell}
                        disabled={emptyCell}
                    />
                }
                {emptyCell && <span className='text-muted text-uppercase'>{NO_SAMPLES}</span>}

                {samples.map((_, index) =>
                    <span key={index}>
                        {findSampleType(_, props.sampleTypesIncludingDeleted) &&
                            <Button
                                onClick={() => handleSampleClick(_, index)}
                                ref={_ => buttons.set(index, _)}
                                className='align-baseline p-0'
                                testId='monitoring-overview-missed-icon'
                            >
                                <SampleIcon
                                    type={findSampleType(_, props.sampleTypesIncludingDeleted)!.sampleType}
                                    status={_.status}
                                    selected={props.selectMode && props.isSelected([{ id: _.id, sessionId: _.sessionId }])}
                                />
                            </Button>
                        }

                        {activeSample && activeSample === _ &&
                            <ClickMenu
                                sample={activeSample}
                                sampleTypes={props.sampleTypesIncludingDeleted}
                                locations={props.locations}
                                sessions={props.sessions}
                                monitoringGroups={props.monitoringGroups}
                                groupName={formatMonitoringGroupName(_.groupId, props.monitoringGroups, props.adHocGroups)}
                                date={props.date}
                                onChangeNotInUse={props.onChangeNotInUse}
                                onChangeMissed={props.onChangeMissed}
                                onClose={() => setActiveSample(undefined)}
                                element={activeButton}
                            />
                        }
                    </span>
                )}
            </div>
        </td>
    )
}

function findSampleType(sample: MonitoringOverviewSample, sampleTypes: SampleType[]) {
    const { sampleTypeId } = sampleData(sample)
    return sampleTypes.find(s => s.id == sampleTypeId)
}

export default SampleCellData
