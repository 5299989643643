import type { ExportDownloadResponse, ExportFormat } from '_/model/export/types'
import type Export from '_/model/export/types'
import type { PlateExportQuery } from '_/model/plate/plate'
import type SampleListQuery from '_/model/sample/list-query'
import { downloadBlob } from '_/utils/blob'

import type ExportService from '../export-service'
import type ApiService from '../api-service'
import type PaginationState from '_/model/pagination-state'

function factory(api: ApiService): ExportService {
    return {
        getAllContextExports,
        getAllSamplesExports,
        getAllPlatesExports,
        getNonViableSamplesExports,
        exportContext,
        exportSamples,
        exportPlates,
        exportNonViableSamples,
        downloadSiteExport,
        downloadSamplesExport,
        downloadPlatesExport,
        downloadNonViableSamplesExport,
    }

    function getAllContextExports(): Promise<Export[]> {
        return api.get(['exports', 'context'])
    }

    function getAllSamplesExports(): Promise<Export[]> {
        return api.get(['exports', 'samples'])
    }

    function getAllPlatesExports(): Promise<Export[]> {
        return api.get(['exports', 'plates'])
    }

    function getNonViableSamplesExports(): Promise<Export[]> {
        return api.get(['exports', 'non-viable-samples'])
    }

    function exportContext(): Promise<Export> {
        return api.post(['contexts', 'export'])
    }

    function exportSamples(query: SampleListQuery): Promise<Export> {
        return api.post(['samples', 'export'], query)
    }

    function exportPlates(query: PlateExportQuery & PaginationState): Promise<Export> {
        return api.get(['plates', 'export'], query)
    }

    function exportNonViableSamples(query: ExportFormat): Promise<Export> {
        return api.get(['non-viable-samples', 'export'], { ...query, start: 0, count: 1000000 })
    }

    function downloadSiteExport(exportId: string): Promise<void> {
        const tokenPromise = api.post<ExportDownloadResponse>(['exports', exportId, 'context', 'download-token'])

        return tokenPromise.then(response => api.rawGetFile(response.token).then(_ => downloadBlob(_, response.fileName)))
    }

    function downloadSamplesExport(exportId: string): Promise<void> {
        const tokenPromise = api.post<ExportDownloadResponse>(['exports', exportId, 'samples', 'download-token'])

        return tokenPromise.then(response => api.rawGetFile(response.token).then(_ => downloadBlob(_, response.fileName)))
    }

    function downloadPlatesExport(exportId: string): Promise<void> {
        const tokenPromise = api.post<ExportDownloadResponse>(['exports', exportId, 'plates', 'download-token'])

        return tokenPromise.then(response => api.rawGetFile(response.token).then(_ => downloadBlob(_, response.fileName)))
    }

    function downloadNonViableSamplesExport(exportId: string): Promise<void> {
        const tokenPromise = api.post<ExportDownloadResponse>(['exports', exportId, 'non-viable-samples', 'download-token'])

        return tokenPromise.then(response => api.rawGetFile(response.token).then(_ => downloadBlob(_, response.fileName)))
    }
}

export default factory
