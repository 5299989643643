import { UNIDENTIFIED_ID } from '_/constants/growth-status'
import type SampleReading from '_/model/sample/reading/sample-reading'
import { calcGrowthsStatus } from '_/features/samples/helpers'
import { enumerateIdentificationRows, isObjectionable } from './helpers'
import type { ObjectionableOrganismEffectiveView } from '_/model/objectionable-organisms/objectionable-organism'
import * as it from '_/model/sample/identification-type'
import { NOT_VALID_VALUE } from '_/components/form/number-input'

interface PathValue {
    path: string
    value: any
}

function pathValue(path: string, value: any): PathValue {
    return { path, value }
}

function getNormalizationData(reading: SampleReading, prevReading: SampleReading | undefined, locationGrade: string | undefined, organisms: ObjectionableOrganismEffectiveView[]) {
    const paths: PathValue[] = []

    const prevRows = prevReading ? enumerateIdentificationRows(prevReading).map(_ => _.row) : []

    enumerateIdentificationRows(reading)
        .map(({ row, pathPrefix }) => {
            const prevRow = prevRows.find(_ => _.reference === row.reference)

            return { row, prevRow, pathPrefix }
        })
        .forEach(({ row, prevRow, pathPrefix }) => {
            const hasColonies = typeof row.cfuCount === 'number' && row.cfuCount > 0 || row.types.length > 1 || row.cfuCount === NOT_VALID_VALUE
                , singleType = row.types.length === 1

            if (hasColonies) {
                const mustResetIdType = singleType && row.types[0].type === undefined
                if (mustResetIdType) {
                    paths.push(pathValue(pathPrefix + 'types[0].type', it.NOT_YET_IDENTIFIED))
                    paths.push(pathValue(pathPrefix + 'types[0].value', undefined))
                }

                const mustClearAllIdType = row.types.length > 1 && row.cfuCount === 0
                if (mustClearAllIdType)
                    paths.push(pathValue(pathPrefix + 'types', [{}]))

                const mustSetObjectionableFlag = row.types
                    .some(_ => _.type !== undefined && _.value !== undefined ? isObjectionable(_.type, _.value, locationGrade, organisms) : false)

                if (mustSetObjectionableFlag)
                    paths.push(pathValue(pathPrefix + 'objectionable', true))

                const mustUnsetObjectionableFlag = !mustSetObjectionableFlag
                    && prevRow?.types
                        .some(_ => _.type !== undefined && _.value !== undefined ? isObjectionable(_.type, _.value, locationGrade, organisms) : false)
                    || prevRow?.objectionable && row.types.some(_ => _.type === it.CORRECTIONAL_MPN_VALUE)

                if (mustUnsetObjectionableFlag)
                    paths.push(pathValue(pathPrefix + 'objectionable', false))
            }
            else {
                if (row.types[0].type !== undefined)
                    paths.push(pathValue(pathPrefix + 'types[0].type', undefined))

                if (row.types[0].value !== undefined)
                    paths.push(pathValue(pathPrefix + 'types[0].value', undefined))

                if (row.objectionable)
                    paths.push(pathValue(pathPrefix + 'objectionable', false))
            }

            if (row.types.length === prevRow?.types.length) {
                row.types.forEach((type, index) => {
                    const prevType = prevRow.types[index]

                    if (type.type !== prevType.type)
                        paths.push(pathValue(pathPrefix + `types[${index}].value`, undefined))
                })
            }
        })

    const statuses = [calcGrowthsStatus(reading.identifications)].concat(reading.optionalIdentifications ? calcGrowthsStatus(reading.optionalIdentifications) : [])
        , notUnidentifiedSample = statuses.some(_ => _ !== UNIDENTIFIED_ID)

    return {
        paths,
        notUnidentifiedSample,
    }
}

export default getNormalizationData
