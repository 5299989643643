const NEVER = 0
    , ON_ALERT_LIMIT = 1
    , ON_ACTION_LIMIT = 2
    , ALWAYS = 3
    // , AUTOMATED = 4

type GradeCfuVerification = never
    | typeof NEVER
    | typeof ON_ALERT_LIMIT
    | typeof ON_ACTION_LIMIT
    | typeof ALWAYS
   //  | typeof AUTOMATED

const GRADE_CFU_VERIFICATION: { id: GradeCfuVerification, name: string }[] = [
    {
        id: NEVER,
        name: 'Never',
    },
    {
        id: ON_ALERT_LIMIT,
        name: 'On alert limit',
    },
    {
        id: ON_ACTION_LIMIT,
        name: 'On action limit',
    },
    {
        id: ALWAYS,
        name: 'Always',
    },
    /* {
        id: AUTOMATED,
        name: 'Automated CFU count',
    }, */
]

export {
    GRADE_CFU_VERIFICATION as default,
    GradeCfuVerification,
    NEVER,
    ON_ALERT_LIMIT,
    ON_ACTION_LIMIT,
    ALWAYS,
    // AUTOMATED,
}
