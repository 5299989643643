import { useSelector, useMemo } from '_/facade/react'

import { memoize } from '_/utils/function'
import type { PredefinedLists } from '_/model/app-state'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import * as fieldIndex from '_/constants/custom-field-index'

import {
    tryRestoreInactiveOperators,
    tryRestoreInactiveSessions,
} from '../../sample-edit/helpers'

import { getFieldValue, getEditableSampleCustomFields } from '../../helpers'

import type SampleDetails from '_/model/sample/sample-details'
import type NonViableSampleView from '_/model/non-viable-sample/types'

import { useTimeService } from '_/components/time'
import * as h from '_/features/samples/helpers'


const memGetEditableFields = memoize(getEditableSampleCustomFields)

function usePredefinedLists(sample: SampleDetails | NonViableSampleView | undefined, fields?: CustomField[] ) {
    const predefinedLists = useSelector(_ => _.predefinedLists)
        , result = useMemo<PredefinedLists>(
            () => {
                const allSampleTypes = predefinedLists.sampleTypes
                    , sampleTypeId = getFieldValue(sample?.fields, fieldIndex.SAMPLE_TYPE_ID)
                    , sampleTypes = allSampleTypes.filter(_ => _.isActive || _.id === sampleTypeId)

                const customFields = memGetEditableFields(fields ?? predefinedLists.customFields)
                    , sampleOperators = tryRestoreInactiveOperators(sample, predefinedLists.sampleOperators)
                    , sampleSessions = tryRestoreInactiveSessions(sample, predefinedLists.sampleSessions)

                return {
                    ...predefinedLists,
                    customFields,
                    sampleTypes,
                    sampleOperators,
                    sampleSessions,
                }
            },
            [sample, predefinedLists, fields]
        )

    return result
}

function useGeneratedBy() {
    const user = useSelector(_ => _.auth.user)
        , timeService = useTimeService()

    return user && h.getGeneratedBy(timeService, user.name, user.email)
}

export {
    usePredefinedLists,
    useGeneratedBy,
}
