import type { State as RouterState } from 'router5'

import { connect } from '_/facade/react'
import type AppState from '_/model/app-state'

import * as routes from '_/constants/routes'

import Navbar from './navbar'
import OrganismIdentifications from './organism-identification/organism-identification-container'
import ObjectionableOrganisms from './objectionable-organisms/ui/list'
import SampleTypeList from './sample-type/sample-type'
import Locations from './exposure-locations/locations'
import Limits from './action-alert-limits/grades'
import SampleOperatorList from './sample-operator/sample-operator'
import SampleSessionList from './sample-session/sample-session-list'
import CustomFieldList  from './custom-fields/list'
import Users from '_/features/users/list/list'
import Roles from '_/features/roles/ui/list'
import ContextList from '_/features/contexts/list/list'
import TrendSettings from './trend-settings/list'
import { isBaseRoute } from '_/utils/router/common'
import UserContainer from '_/features/users/user-container'


const PredefinedLists: React.FC<ConnectedProps> =
    props =>
        <div className='d-flex flex-column h-100'>
            <Navbar />
            <div className='d-flex flex-fill overflow-auto'>
                <div className='container-fluid main-block'>
                    <div className='row justify-content-center h-100'>
                        <div className='col-9 h-100 d-flex flex-column'>
                            {renderRoute(props.route)}
                        </div>
                    </div>
                </div>
            </div>
        </div>

function renderRoute(route: RouterState): React.ReactNode {
    if (isBaseRoute(routes.SETTINGS_ROLES, route))
        return <Roles />

    if (isBaseRoute(routes.SETTINGS_LOCATIONS, route))
        return <Locations />

    if (isBaseRoute(routes.SETTINGS_USERS_USER, route))
        return <UserContainer />

    if (isBaseRoute(routes.SETTINGS_OPERATORS, route))
        return <SampleOperatorList />

    if (isBaseRoute(routes.SETTINGS_SESSIONS, route))
        return <SampleSessionList />

    if (isBaseRoute(routes.SETTINGS_SAMPLE_TYPES, route))
        return <SampleTypeList />

    if (isBaseRoute(routes.SETTINGS_OBJECTIONABLE_IDENTIFICATIONS, route))
        return <ObjectionableOrganisms />

    if (isBaseRoute(routes.SETTINGS_SAMPLE_FIELDS, route))
        return <CustomFieldList />

    if (isBaseRoute(routes.SETTINGS_TRENDS, route))
        return <TrendSettings />

    if (isBaseRoute(routes.SETTINGS_ORGANISM_IDENTIFICATION, route))
        return <OrganismIdentifications/>

    switch (route.name) {
        case routes.SETTINGS_USERS:
        case routes.SETTINGS_USERS_CREATE:
            return <Users />

        case routes.SETTINGS_CONTEXTS:
            return <ContextList />

        case routes.SETTINGS_ACTION_ALERT_LIMITS:
            return <Limits />

        default:
            return <ContextList />
    }
}

function mapStateToProps(state: AppState) {
    return {
        route: state.router.route!,
    }
}

type ConnectedProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(PredefinedLists)
