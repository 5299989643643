const AT_REST_ALERT_LIMIT = 'AtRestAlertLimit'
    , AT_REST_ACTION_LIMIT = 'AtRestActionLimit'
    , IN_OPERATION_ALERT_LIMIT = 'InOperationAlertLimit'
    , IN_OPERATION_ACTION_LIMIT = 'InOperationActionLimit'

type FirstChangeReasonIgnoreFields = typeof AT_REST_ALERT_LIMIT
    | typeof AT_REST_ACTION_LIMIT
    | typeof IN_OPERATION_ALERT_LIMIT
    | typeof IN_OPERATION_ACTION_LIMIT

export {
    FirstChangeReasonIgnoreFields,
    AT_REST_ALERT_LIMIT,
    AT_REST_ACTION_LIMIT,
    IN_OPERATION_ALERT_LIMIT,
    IN_OPERATION_ACTION_LIMIT,
}
