import { Close } from '_/components/button'
import { NOT_AVAILABLE } from '_/constants/system-words'
import { classnames } from '_/facade/react'
import type { Guid } from '_/model/guid'
import SmartImage from './smart-image'

interface Props {
    imageId: Guid
    selectedImageId: Guid | undefined
    hasSpinner?: boolean
    onClick: (_: Guid) => void
    onDelete?: (_: Guid) => void
}

function Thumbnail(props: Props) {
    return (
        <div key={props.imageId} className='d-inline-block'>
            <div className='d-inline-block position-relative'>
                <SmartImage
                    type='thumbnail'
                    className={classnames('smart-check-thumbnails__thumbnail border', props.imageId === props.selectedImageId && 'smart-check-thumbnails__thumbnail--active')}
                    imageId={props.imageId}
                    onClick={() => props.onClick(props.imageId)}
                    alt={NOT_AVAILABLE}
                />
                {props.hasSpinner &&
                    <i className='preview-spinner material-icons md-48 text-light' data-testid='image-preview-spinner'>sync</i>
                }
            </div>
            {props.onDelete &&
                <Close
                    className='text-danger align-top d-print-none'
                    aria-label='Close'
                    onClick={() => props.onDelete?.(props.imageId)}
                />
            }
        </div>
    )
}

export default Thumbnail
