import { SWAB } from '_/constants/plate-type'
import type SampleType from '_/model/predefined-lists/sample-type/types'

function isSwabSampleType(sampleTypeId: string, sampleTypes: SampleType[]) {
    const sampleType = sampleTypes.find(_ => _.id === sampleTypeId)
    return sampleType?.sampleType === SWAB
}

export {
    isSwabSampleType,
}
