import { useState, useEffect, useAction } from '_/facade/react'

import { Table, PaginatedFooter } from '_/components/table'
import { useTimeService } from '_/components/time'
import Button from '_/components/button'

import * as fieldIndex from '_/constants/custom-field-index'

import type { ChildrenTrend, LocationsReport } from '_/model/reports/locations-report/types'
import { getSampleListParams } from '_/model/reports/locations-report/helpers'
import * as sampleActions from '_/features/samples/actions'
import type TimeService from '_/services/time-service'

import TableActionButtons from '_/features/reports/table-action-buttons'
import TableFooter from '_/features/reports/table-footer'
import LimitBreachData from './limit-breach-data'
import type { Text } from '_/model/text/text'
import FormattedText from '_/features/text/formatted-text'
import NoDataAvailable from '../../no-data-available'
import { formatActiveState } from '_/utils/format/common'

interface Props {
    locationName: Text
    locationReport: LocationsReport
    childrenTrends: ChildrenTrend[]
    printMode: boolean
    itemsLimit: number
    entityName: string
    onLocationClick: (locationId: string) => void
    testId?: string
    showSpinner: boolean
}

function ChildLocationTrends(props: Props) {
    const [pagination, setPagination] = useState({ start: 0, count: props.itemsLimit })
        , exportLimitBreachTrendsRelatedSampleData = useAction(sampleActions.exportLimitBreachTrendsRelatedSampleData)
        , buttonsDisabled = actionButtonsDisabled(props.childrenTrends)
        , noDataAvailable = props.childrenTrends.length === 0 || props.childrenTrends.every(_ => !_.currentPeriodBreaches?.total)
        , timeService = useTimeService()

    function calculateChildrenTrends() {
        const end = pagination.start + pagination.count
        return props.childrenTrends.slice(pagination.start, end)
    }

    useEffect(
        () => { setPagination({ start: 0, count: props.itemsLimit }) },
        [props.childrenTrends, props.itemsLimit]
    )

    return (
        <div className='col-12'>
            <div className='block-border px-3'>
                <div className='my-4 text-center'>
                    <h4 data-testid='locations-report-subtitle'>
                        Information about limit breach trends for all locations within <FormattedText text={props.locationName} />
                    </h4>
                    {props.printMode && props.childrenTrends.length > props.itemsLimit &&
                        <h5 className='text-danger d-print-none'>{`Table in Print mode is limited to ${props.itemsLimit} records!`}</h5>
                    }
                </div>
                <Table className='mt-2'>
                    <thead className='thead'>
                        <tr className='table-header-thin' data-testid='child-location-trends-table-header'>
                            <th>Location</th>
                            <th>Limit breaches</th>
                        </tr>
                    </thead>
                    <tbody>
                        {noDataAvailable || props.showSpinner
                            ? <tr>
                                <td colSpan={2} className='text-center'>
                                    <NoDataAvailable showSpinner={props.showSpinner}>
                                        <span className='text-muted text-uppercase' data-testid={`${props.testId}-no-data-message`}>
                                            No data available
                                        </span>
                                    </NoDataAvailable>
                                </td>
                            </tr>
                            : calculateChildrenTrends().map(_ =>
                                <tr key={_.locationId}>
                                    <td>
                                        <Button onClick={() => props.onLocationClick(_.locationId)} className='btn-link p-0' testId={`${props.testId}-table-row-location-name`}>
                                            <FormattedText text={formatActiveState(_.locationName, _.isActive)} />
                                        </Button>
                                    </td>
                                    <LimitBreachData trend={_}/>
                                </tr>
                            )
                        }
                    </tbody>
                    {!props.printMode && !noDataAvailable &&
                        <PaginatedFooter
                            colSpan={2}
                            state={pagination}
                            onChange={setPagination}
                            totalCount={props.childrenTrends.length}
                            actionButtons={
                                <TableActionButtons
                                    routeParams={getParams(props.locationReport, props.childrenTrends, timeService)}
                                    onExport={() => exportLimitBreachTrendsRelatedSampleData({
                                        ...getParams(props.locationReport, props.childrenTrends, timeService),
                                        start: 0,
                                        count: props.itemsLimit,
                                        sort: 'exposureStartTime:asc',
                                        sampleBreachTypes: undefined,
                                        includeGeneratedByInfo: true,
                                    })}
                                    exportButtonDisabled={buttonsDisabled}
                                    disabledGoToSamplesButton={buttonsDisabled}
                                    testId={props.testId}
                                />
                            }
                        />
                    }
                </Table>
                {props.printMode && !noDataAvailable &&
                    <TableFooter report={props.locationReport} entityName={props.entityName} />
                }
            </div>
        </div>
    )
}

export default ChildLocationTrends

function actionButtonsDisabled(trends: ChildrenTrend[]) {
    return trends.length === 0 || trends.every(_ => !_.currentPeriodBreaches?.total)
}

function getParams(locationReport: LocationsReport, trends: ChildrenTrend[], timeService: TimeService) {
    const locationIds = trends.map(_ => _.locationId)

    return {
        ...getSampleListParams(locationReport, true, timeService),
        fields: [{ index: fieldIndex.EXPOSURE_LOCATION_ID as fieldIndex.FieldIndex, value: locationIds }],
    }
}
