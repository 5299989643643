import { useAction, useCallback, useEffect, useSelector, useState } from '_/facade/react'

import { EmptyTableMessage, Table } from '_/components/table'
import PageHeader from '_/components/page-header'

import Link from '_/components/link'

import * as routes from '_/constants/routes'

import * as actions from './actions'

import TrendModal from './modal/modal'
import type { TrendSettings } from '_/model/predefined-lists/trend-settings/types'
import { useContextSwitchObserver } from '_/components/context-observer'
import { useAllPredefinedLists } from '_/hooks/shared-hooks'
import * as h from '_/model/predefined-lists/trend-settings/helpers'
import FormattedText from '_/features/text/formatted-text'

function TrendSettingList() {
    const editMode = useSelector(
            _ => [routes.SETTINGS_TRENDS_CREATE, routes.SETTINGS_TRENDS_EDIT].some(it => _.router.route!.name === it)
        )
        , [trendSettings, reload] = useLoadTrendSettings()
        , predefinedLists = useSelector(_ => _.predefinedLists)
        , permissions = useSelector(_ => _.auth.permissions)

    useAllPredefinedLists()

    return (
        <>
            {editMode && <TrendModal trends={trendSettings} onReloadList={reload} />}

            <PageHeader title='Trend settings'>
                <Link
                    className='btn btn-primary'
                    routeName={routes.SETTINGS_TRENDS_CREATE}
                    hasNoPermissions={!permissions.manageTrends}
                >
                    Create a new trend setting
                </Link>
            </PageHeader>

            <div className="overflow-auto flex-fill">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Trend setting name</th>
                            <th>Filters</th>
                            <th>Timeframe</th>
                            <th>Rule</th>
                            <th>Status</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {trendSettings.length === 0
                            ? <EmptyTableMessage colSpan={6} message='There are no trend settings at the moment' />
                            : trendSettings.map(_ =>
                                <tr key={_.id}>
                                    <td>{_.name}</td>
                                    <td>
                                        {_.filters?.map((_, i) =>
                                            <div key={i}><FormattedText text={h.filterFormat(_, predefinedLists)} /></div>
                                        )}
                                    </td>
                                    <td>{h.timeframeFormat(_.timeframe)}</td>
                                    <td>{h.ruleFormat(_.rule)}</td>
                                    <td>{_.isActive ? 'Active' : 'Inactive'}</td>
                                    <td className='text-end'>
                                        <Link
                                            className='btn-link'
                                            routeName={routes.SETTINGS_TRENDS_EDIT}
                                            routeParams={{ id: _.id }}
                                            hasNoPermissions={!permissions.manageTrends}
                                        >
                                            <i className='material-icons md-18 text-primary'>create</i>
                                        </Link>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default TrendSettingList

function useLoadTrendSettings() {
    const loadTrendSettings = useAction(actions.loadTrendSettingsList)
        , contextSwitch = useContextSwitchObserver()
        , [trendSettings, setTrendSettings] = useState<TrendSettings[]>([])
        , load = useCallback(
            () => {
                loadTrendSettings().then(setTrendSettings)
            },
            [loadTrendSettings]
        )

    useEffect(() => load(), [load, contextSwitch])

    return [trendSettings, load] as const
}
