import { React, connect, bindComponent, dispatchPropsMapper } from '_/facade/react'
import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'

import type { ValidationResult} from '_/utils/form/validate'
import { isEmpty, required, allowedName } from '_/utils/form/validate'
import { shallowUpdate } from '_/utils/object'
import { areArraysEqual, mergeAndReplaceEntity } from '_/utils/array'
import { noop } from '_/utils/function'
import { VOID_ID } from '_/utils/tree'
import { searchDuplicateName } from '_/model/common/helpers'

import FormChangesObserver from '_/components/form/form-changes-observer'
import ContextObserver from '_/components/context-observer'
import { Submit, submitDisabled, TextField, SelectField, LocalDateField, MultiSelectField } from '_/components/form'
import CustomReportsGraphModal from './custom-reports-graph-modal'
import CustomReportsEditRow from './custom-reports-edit-row'
import PageHeader from '_/components/page-header'
import { Table } from '_/components/table'
import Button from '_/components/button'

import type { CustomReportEdit, CustomReportGraph } from '_/model/analysis/custom-report/custom-report'
import type CustomReport from '_/model/analysis/custom-report/custom-report'
import type { AnalysisFilter } from '_/model/analysis/filter/types'
import type AppState from '_/model/app-state'

import * as routes from '_/constants/routes'

import * as messages from '_/features/samples/messages'

import * as predefinedListsActions from '_/features/predefined-lists/redux/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import * as deletionActions from '_/features/confirmation/actions'
import * as toastActions from '_/features/toasts/actions'
import { actions as routerActions } from 'redux-router5'
import * as actions from '../actions'
import * as userActions from '_/features/users/actions'
import * as reminderActions from '_/features/analysis/report-reminders/actions'
import * as organismIdentificationActions from '_/features/predefined-lists/organism-identification/actions'

import { convertToEdit, formatGraphType } from './helpers'
import FREQUENCY, { NEVER } from '_/constants/custom-report-reminder-frequency'
import type { ReportReminderEdit } from '_/model/analysis/custom-report/report-reminder'
import type ReportReminder from '_/model/analysis/custom-report/report-reminder'
import { normalizeFilteredFields } from '_/features/analysis/ui/helpers'
import type { OrganismIdentification } from '_/model/predefined-lists/organism-identification/types'
import * as string from '_/utils/string'

interface State {
    graph: AnalysisFilter | undefined
    existedGraphs: CustomReportGraph[]
    editedGraphs: CustomReportGraph[]
    newGraphs: CustomReportGraph[]
    showModal: {
        isOpen: boolean
        graphId?: string | undefined
        position?: number | undefined
    }
    draggedGraph: CustomReportGraph | undefined
    changedPositionGraphs: CustomReportGraph[]
    reminder: ReportReminder | undefined
    initialValues: CustomReportEdit & ReportReminderEdit | undefined
    organisms: OrganismIdentification[]
}

const EDIT_CUSTOM_REPORT = 'edit-custom-report'
    , EDIT_GRAPH = 'edit-graph'

function validate(values: Partial<CustomReportEdit> & Partial<ReportReminderEdit>, reportId: string | undefined, customReports: CustomReport[]) {
    const result: ValidationResult<CustomReportEdit> & ValidationResult<ReportReminderEdit> = {}
        , duplicateName = values.name && searchDuplicateName({ id: reportId, name: values.name }, customReports)
        , frequency = required('Reminder frequency')(values.frequency)
        , notAllowedNames = allowedName('Report name')(values.name)

    if (isEmpty('Name')(values.name))
        result.name = 'Report name is required'

    if (notAllowedNames)
        result.name = notAllowedNames

    if (frequency)
        result.frequency = 'Reminder frequency is required'

    if (duplicateName)
        result.name = `Custom report with name '${values.name!.trim()}' already exists`

    if (values.frequency !== NEVER) {
        const reminderStartDate = required('Reminder start date')(values.reminderStartDate)
            , recipients = required('Reminder recipients')(values.recipients)

        if (reminderStartDate)
            result.reminderStartDate = reminderStartDate

        if (recipients)
            result.recipients = [recipients]
    }

    return result
}

class CustomReportsEdit extends React.Component<ConnectedProps, State> {
    hasUnsavedFormChanges = false

    constructor(props: ConnectedProps) {
        super(props)
        bindComponent(this)

        this.state = {
            existedGraphs: [],
            newGraphs: [],
            editedGraphs: [],
            showModal: {
                isOpen: false,
                graphId: undefined,
                position: undefined,
            },
            graph: undefined,
            draggedGraph: undefined,
            changedPositionGraphs: [],
            reminder: undefined,
            initialValues: undefined,
            organisms: [],
        }

        this.reset()
    }

    handleSaveChanges(values: CustomReportEdit & ReportReminderEdit) {
        const props = this.props
            , state = this.state
            , id = props.customReport!.id
            , oldCustomReport = { name: props.customReport!.name }
            , newCustomReport = { name: values.name }
            , oldReminder = {
                frequency: state.reminder && state.reminder.frequency,
                reminderStartDate: state.reminder && state.reminder.reminderStartDate,
                recipients: state.reminder && state.reminder.recipients && state.reminder.recipients.map(_ => _.id),
            }
            , newReminder = {
                reportId: id,
                frequency: values.frequency,
                reminderStartDate: values.reminderStartDate,
                recipients: values.recipients,
            }
            , editedGraphs = this.state.editedGraphs.map(_ => ({..._, graph: normalizeFilteredFields(_.graph, this.props.predefinedLists)}))
            , newGraphs = this.state.newGraphs.map(_ => ({..._, graph: normalizeFilteredFields(_.graph, this.props.predefinedLists)}))
            , changedPositions = this.state.changedPositionGraphs

        return Promise.all([
                props.saveCustomReport({ id, oldCustomReport, newCustomReport }),
                state.reminder ? props.saveReminder({ id: state.reminder.id, reportId: id, oldReminder, newReminder }) : props.createReminder(newReminder),
                editedGraphs.length > 0
                    ? props.changeCustomReportGraphs({ id, graphs: editedGraphs })
                    : undefined,
                newGraphs.length > 0 ? props.addCustomReportGraphs({ id, graphs: newGraphs }) : undefined,
                changedPositions.length > 0 ? props.changeGraphsPosition({ id, graphs: changedPositions }) : undefined,
            ])
            .then(() => props.hasUnsavedChanges(false, EDIT_CUSTOM_REPORT))
            .then(() => props.navigateTo(routes.CUSTOM_REPORTS))
            .then(noop)
    }

    handleCreate(values: CustomReportEdit & ReportReminderEdit) {
        const newReport = { name: values.name, graphs: this.state.newGraphs }
            , newReminder = {
                reportId: '',
                frequency: values.frequency,
                reminderStartDate: values.reminderStartDate,
                recipients: values.recipients,
            }

        this.props.hasUnsavedChanges(false, EDIT_CUSTOM_REPORT)
        return this.props.createCustomReport(newReport)
            .then(reportId => {
                const reminderCreate = shallowUpdate(newReminder, { reportId })
                this.props.createReminder(reminderCreate)
            })
            .then(() => this.props.hasUnsavedChanges(false, EDIT_CUSTOM_REPORT))
            .then(() => this.props.navigateTo(routes.CUSTOM_REPORTS))
            .then(noop)
    }

    componentDidUpdate(prevProps: ConnectedProps, prevState: State) {
        const hasGraphUnsavedChanges = this.props.unsavedChangeTargets.some(_ => _ === EDIT_GRAPH)
            , prevHasGraphUnsavedChanges = prevProps.unsavedChangeTargets.some(_ => _ === EDIT_GRAPH)
            , changesWereDiscarded = !hasGraphUnsavedChanges && prevHasGraphUnsavedChanges

        const organismIds = this.getOrganisms(this.state.existedGraphs, this.state.editedGraphs)
            , prevOrganismIds = this.getOrganisms(prevState.existedGraphs, prevState.editedGraphs)

        if (!areArraysEqual(organismIds, prevOrganismIds))
            this.loadOrganisms()

        if (prevProps.warningShowed && changesWereDiscarded) {
            this.setState({
                showModal: {
                    isOpen: false,
                    graphId: undefined,
                    position: undefined,
                },
                graph: undefined,
            })

            if (this.hasUnsavedFormChanges)
                this.props.hasUnsavedChanges(true, EDIT_CUSTOM_REPORT)
        }
    }

    handleCloseModal() {
        if (this.props.unsavedChangeTargets.some(_ => _ === EDIT_GRAPH)) {
            this.props.showWarning({ showWarning: true })
        }
        else {
            this.setState({
                showModal: {
                    isOpen: false,
                    graphId: undefined,
                    position: undefined,
                },
                graph: undefined,
            })
        }
    }

    handleAddToReport(filter: AnalysisFilter) {
        const graphPosition = this.state.existedGraphs.length > 0
                ? this.state.existedGraphs[this.state.existedGraphs.length - 1].position + 1
                : 0
            , newGraph = this.convertToNewCustomReportGraph(filter, graphPosition)

        this.setState({
            newGraphs: this.state.newGraphs.concat(newGraph),
            existedGraphs: this.state.existedGraphs.concat(newGraph),
        })

        this.props.hasUnsavedChanges(true, EDIT_CUSTOM_REPORT)

        Promise.resolve()
            .then(() => this.props.hasUnsavedChanges(false, EDIT_GRAPH))
            .then(this.handleCloseModal)
    }

    convertToNewCustomReportGraph(_: AnalysisFilter, position: number) {
        return {
            id: VOID_ID,
            graph: _,
            position,
        }
    }

    handleSaveChangesToReport(_: AnalysisFilter) {
        const graphId = this.state.showModal.graphId!
            , position = this.state.showModal.position!

        if (graphId !== VOID_ID) {
            const graph = {
                id: graphId,
                graph: _,
                position,
            }
            this.setState({
                existedGraphs: mergeAndReplaceEntity(this.state.existedGraphs, graph),
                editedGraphs: this.state.editedGraphs.some(_ => _.id === graph.id)
                    ? mergeAndReplaceEntity(this.state.editedGraphs, graph)
                    : this.state.editedGraphs.concat(graph),
            })
        }
        else {
            this.setState({
                newGraphs: this.state.newGraphs
                    .map(g => g.position === this.state.showModal.position ? shallowUpdate(g, { graph: _ }) : g),
                existedGraphs: this.state.existedGraphs
                    .map(g => g.position === this.state.showModal.position ? shallowUpdate(g, { graph: _ }) : g),
            })
        }

        this.props.hasUnsavedChanges(true, EDIT_CUSTOM_REPORT)

        Promise.resolve()
            .then(() => this.props.hasUnsavedChanges(false, EDIT_GRAPH))
            .then(this.handleCloseModal)
    }

    handleOpenDeletionModal(graph: CustomReportGraph) {
        if (graph.id !== VOID_ID) {
            this.props.showDeletionWarning(`Are you sure you want to delete ${formatGraphType(graph.graph, this.props.fields)}?`)
                .then(() => {
                    this.props.removeCustomReportGraph({ id: this.props.customReport!.id, graphId: graph.id })
                        .then(() => this.setState({
                            existedGraphs: this.state.existedGraphs.filter(_ => _.id !== graph.id),
                            editedGraphs: this.state.editedGraphs.filter(_ => _.id !== graph.id),
                        }))
                        .then(() => this.props.addSuccess(messages.CHANGES_SAVED))
                        .then(() => {
                            if (this.state.editedGraphs.length === 0 && this.state.newGraphs.length === 0 && !this.hasUnsavedFormChanges)
                                this.props.hasUnsavedChanges(false, EDIT_CUSTOM_REPORT)
                        })
                })
        }
        else {
            this.setState(
                {
                    newGraphs: this.state.newGraphs.filter(_ => _.position !== graph.position),
                    existedGraphs: this.state.existedGraphs.filter(_ => _.position !== graph.position),
                },
                () => {
                    if (this.state.newGraphs.length === 0 && this.state.editedGraphs.length === 0 && !this.hasUnsavedFormChanges)
                        this.props.hasUnsavedChanges(false, EDIT_CUSTOM_REPORT)
                }
            )
        }
    }

    handleOpenEditGraphModal(_: CustomReportGraph) {
        this.setState({
            showModal: {
                isOpen: true,
                graphId: _.id,
                position: _.position,
            },
            graph: _.graph,
        })
    }

    handleChange(values: ReportReminderEdit, form: FormRenderProps) {
        if (!this.hasUnsavedFormChanges)
            this.hasUnsavedFormChanges = true

        if (values.frequency === NEVER) {
            form.form.change('reminderStartDate', undefined)
            form.form.change('recipients', undefined)
        }
    }

    submitDisabled(form: FormRenderProps<any>) {
        const disabled = submitDisabled(form)
            , graphsIsEmpty = this.state.changedPositionGraphs.length === 0
                && this.state.editedGraphs.length === 0
                && this.state.newGraphs.length === 0

        if (this.props.customReport !== undefined)
            return form.dirty ? disabled : graphsIsEmpty

        return graphsIsEmpty || disabled
    }

    handleDragStart(draggedGraph: CustomReportGraph) {
        this.setState({ draggedGraph })
    }

    handleDragEnd() {
        this.setState({ draggedGraph: undefined })
    }

    canMoveAbove(destination: CustomReportGraph): boolean {
        if (!this.state.draggedGraph)
            return false

        return this.state.draggedGraph.position !== destination.position
            &&  (destination.position - 1) !== this.state.draggedGraph.position
    }

    canMoveBelow(destination: CustomReportGraph): boolean {
        if (!this.state.draggedGraph)
            return false

        return this.state.draggedGraph.position !== destination.position
            &&  (destination.position + 1) !== this.state.draggedGraph.position
    }

    reorderGraphs(graphsToReorder: CustomReportGraph[], oldPosition: number, newPosition: number) {
        const orderedGraphs = this.sortGraphs(graphsToReorder)
            .map(_ => {
                if (oldPosition > newPosition) {
                    if (_.position < oldPosition && _.position >= newPosition) {
                        _.position += 1
                    }
                }
                else if (oldPosition < newPosition) {
                    if (_.position > oldPosition && _.position <= newPosition && _.position > 0) {
                        _.position -= 1
                    }
                }

                return _
            })

        return orderedGraphs
    }

    sortGraphs(graph: CustomReportGraph[]) {
        return graph.slice().sort((one, two) => one.position - two.position)
    }

    handleDragDrop(destination: CustomReportGraph) {
        const draggedGraph = this.state.draggedGraph!
            , oldPosition = draggedGraph.position
            , newPosition = destination.position
            , graphsToReorder = this.state.existedGraphs.filter(_ => _.position !== oldPosition)
            , reorderedGraphs = this.reorderGraphs(graphsToReorder, oldPosition, newPosition)
            , newPositionedGraph = shallowUpdate(draggedGraph, { position: newPosition })
            , graphs = this.sortGraphs(reorderedGraphs.concat(newPositionedGraph))

        if (newPositionedGraph.id !== VOID_ID)
            this.setState({ changedPositionGraphs: this.state.changedPositionGraphs.concat(newPositionedGraph) })

        this.setState({
            existedGraphs: graphs,
            newGraphs: graphs.filter(_ => _.id === VOID_ID),
        })

        this.props.hasUnsavedChanges(true, EDIT_CUSTOM_REPORT)
    }

    reset() {
        this.props.loadPredefinedLists({ includeInactiveCustomFields: true })
        this.props.loadCustomReports()
        this.handleLoadUsers()

        const id = this.props.route.params.id
        if (id) {
            Promise.all([
                this.props.loadCustomReport(id),
                this.props.loadReportReminders(),
            ])
            .then(([customReport, reminders]) => {
                const reminder = reminders.find(_ => _.reportId === id)
                    , initialValues = convertToEdit(customReport, reminder)

                this.setState({ reminder, existedGraphs: customReport.graphs, initialValues })
            })
        }
    }

    loadOrganisms() {
        const ids = this.getOrganisms(this.state.existedGraphs, this.state.editedGraphs)

        if (ids.length > 0) {
            this.props.searchOrganisms({ ids })
                .then(_ => this.setState({ organisms: _ }))
        }
    }

    getOrganisms(existedGraphs: CustomReportGraph[], editedGraphs: CustomReportGraph[]) {
        const existingOrganisms = existedGraphs.flatMap(it => it.graph.organismIds ?? [])
            , editedOrganisms = editedGraphs.flatMap(it => it.graph.organismIds ?? [])
            , concatenated = existingOrganisms.concat(editedOrganisms)

        return concatenated.sort(string.compare)
    }

    handleLoadUsers() {
        this.props.loadUsers({count: 1000, sort: '', start: 0})
    }

    disabled(values: Partial<CustomReportEdit> & Partial<ReportReminderEdit>) {
        return values.frequency === NEVER
    }

    render() {
        const props = this.props

        return (
            <div className='col-9 h-100'>
                <ContextObserver onChange={this.reset} />
                <Form
                    onSubmit={_ =>
                        props.customReport
                            ? this.handleSaveChanges(_ as any)
                            : this.handleCreate(_ as any)
                    }
                    initialValues={this.state.initialValues}
                    validate={_ => validate(_, props.customReport && props.customReport.id, this.props.customReports)}
                    render={form =>
                        <form className='h-100 d-flex flex-column' onSubmit={form.handleSubmit}>
                            <FormChangesObserver form={form} onChange={this.handleChange} target={EDIT_CUSTOM_REPORT}/>

                            <PageHeader title={`${props.customReport ? 'Edit' : 'New'} custom report template`}>
                                <Submit
                                    disabled={this.submitDisabled(form)}
                                    title={this.state.existedGraphs.length === 0 ? 'At least one graph is required' : ''}
                                    testId='save-report'
                                >
                                    Save report
                                </Submit>
                            </PageHeader>

                            <div className='row'>
                                <div className='col-5'>
                                    <TextField name='name' id='name' testId='field-name'>Report name</TextField>
                                </div>
                                <div className='col-2'>
                                    <SelectField
                                        id='frequency'
                                        name='frequency'
                                        entities={FREQUENCY}
                                        calcId={_ => _.id}
                                        calcName={_ => _.name}
                                        testId='field-frequency'
                                    >
                                        Reminder frequency
                                    </SelectField>
                                </div>
                                <div className='col-2'>
                                    <LocalDateField
                                        id='reminderStartDate'
                                        name='reminderStartDate'
                                        disabled={this.disabled(form.values)}
                                        testId='field-reminder-start-date'
                                    >
                                        Reminder start date
                                    </LocalDateField>
                                </div>
                                <div className='col-3'>
                                    <MultiSelectField
                                        id='recipients'
                                        name='recipients'
                                        entities={this.props.recipients}
                                        calcId={_ => _.id}
                                        calcName={_ => _.name + ' ' + _.email}
                                        autocomplete
                                        disabled={this.disabled(form.values)}
                                        testId='field-recipients'
                                    >
                                        Reminder recipients
                                    </MultiSelectField>
                                </div>
                            </div>
                            <div className='overflow-auto flex-fill'>
                                <Table>
                                    <thead className='thead table-header--sticky'>
                                        <tr>
                                            <th>Graph type</th>
                                            <th>Data selection</th>
                                            <th/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.existedGraphs.map((_, i) =>
                                            <CustomReportsEditRow
                                                customReportGraph={_}
                                                canEdit={props.permissions.editCustomReports}
                                                canDelete={this.state.existedGraphs.length > 1}
                                                organisms={this.state.organisms}
                                                canMoveAbove={this.canMoveAbove}
                                                canMoveBelow={this.canMoveBelow}
                                                onDelete={this.handleOpenDeletionModal}
                                                onEdit={this.handleOpenEditGraphModal}
                                                onDragStart={this.handleDragStart}
                                                onDragEnd={this.handleDragEnd}
                                                onDragDrop={this.handleDragDrop}
                                                key={i}
                                                testId={`edit-report-${i}`}
                                            />
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            {this.state.showModal.isOpen && this.props.permissions.editCustomReports &&
                                <CustomReportsGraphModal
                                    onClose={this.handleCloseModal}
                                    filter={this.state.graph}
                                    onAddToReport={this.handleAddToReport}
                                    onSaveChanges={this.handleSaveChangesToReport}
                                    reportName={form.values.name}
                                    formId={EDIT_GRAPH}
                                />
                            }
                            <div>
                                <Button
                                    onClick={() => this.setState({ showModal: { isOpen: true } })}
                                    className='ms-3 btn-primary'
                                    hasNoPermissions={!props.permissions.editCustomReports}
                                    testId='add-graph'
                                >
                                    Add a graph
                                </Button>
                            </div>
                        </form>
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    permissions: state.auth.permissions,
    route: state.router.route!,
    unsavedChangeTargets: state.unsavedChange.unsavedChangeTargets,
    warningShowed: state.unsavedChange.showConfirmationModal,
    customReports: state.customReports.customReports,
    customReport: state.customReports.customReports.find(_ => _.id === state.router.route!.params.id),
    recipients: state.users.list.items,
    fields: state.predefinedLists.customFields,
    predefinedLists: state.predefinedLists,
})

const mapDispatchToProps = dispatchPropsMapper({
    loadCustomReports: actions.loadCustomReports,
    loadCustomReport: actions.loadCustomReport,
    createCustomReport: actions.createCustomReport,
    saveCustomReport: actions.saveCustomReport,
    addCustomReportGraphs: actions.addCustomReportGraphs,
    changeCustomReportGraphs: actions.changeCustomReportGraphs,
    removeCustomReportGraph: actions.removeCustomReportGraph,
    changeGraphsPosition: actions.changeGraphsPosition,
    loadPredefinedLists: predefinedListsActions.loadPredefinedLists,
    showDeletionWarning: deletionActions.showDeletionConfirmationModal,
    showWarning: warningActions.showWarning,
    addSuccess: toastActions.addSuccess,
    hasUnsavedChanges: warningActions.hasUnsavedChanges,
    navigateTo: routerActions.navigateTo,
    loadUsers: userActions.loadUserList,
    loadReportReminders: reminderActions.loadReportReminders,
    saveReminder: reminderActions.saveReportReminder,
    createReminder: reminderActions.createReportReminder,
    searchOrganisms: organismIdentificationActions.searchOrganismIdentification,
})

type ConnectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(CustomReportsEdit)
