import type SmartCheckService from '_/services/smart-check-service'
import type { SampleName } from '_/constants/plate-type'
import { dropFields } from '_/utils/object'
import { FIVE_K, FOUR_K_MEDIUM_RATIO, getImageUploadRequests, validateImageResolution } from './uploader-helpers'
import type { Rectangle, SmartInfo, TakePhotoPromise } from './image'
import type { Guid } from '../guid'

type CancelUpload = () => void

function factory(api: SmartCheckService) {
    return {
        upload,
    }

    function upload(imagePromise: Promise<Blob>, previousImageId: Guid | undefined, plateType: SampleName, smartCheck: boolean, moldPredictorEnabled: boolean, entityId: Guid): TakePhotoPromise {
        const [cancellation, cancel] = getCancellation<Guid>()

        const requestsPromise = imagePromise.then(prepareUploadRequests)
            // images must be uploaded when id is available, so it is possible to show them in preview
            , imageIdPromise = requestsPromise.then(
                r => api.uploadImage(r.requests).then<SmartInfo>(_ => ({ imageId: r.imageId, mlDisabled: r.imageType !== FIVE_K, moldPredictorEnabled, growthCountResult: undefined, validationResult: undefined }))
            )
            , imageCheckPromise = requestsPromise.then(
                requests => {
                    if (!smartCheck)
                        return undefined

                    const is5K = requests.imageType === FIVE_K

                    return api.validateImage(
                            requests.imageId,
                            requests.requests[is5K ? 0 : 1].image,
                            previousImageId,
                            plateType,
                            is5K,
                        )
                        .then(validation => {
                            return {
                                ...validation,
                                rectangle: is5K ? validation.rectangle : rescale(validation.rectangle, FOUR_K_MEDIUM_RATIO),
                            }
                        })
                }
            )
            , smartInfoPromise = Promise.all([imageIdPromise, imageCheckPromise])
                .then<SmartInfo>(([info, imageCheckResult]) =>
                    ({
                        imageId: info.imageId,
                        mlDisabled: info.mlDisabled,
                        moldPredictorEnabled,
                        validationResult: imageCheckResult && dropFields(imageCheckResult, 'growths'),
                        growthCountResult: imageCheckResult?.growths && { growths: imageCheckResult.growths },
                    })
                )
            , imageInfoPromise = Promise.all([smartInfoPromise, imagePromise])
                .then(([info, image]) =>
                    api.saveImageInfo(
                        {
                            ...info,
                            contentType: image.type,
                            size: image.size,
                        },
                        entityId
                    )
                    .then(_ => info.imageId)
                )
            , cancellableUpload = Promise.race([imageInfoPromise, cancellation])
                // cleanup
                .finally(cancel)

        const result = Object.assign(
            cancellableUpload,
            {
                imageIdPromise,
                smartInfoPromise,
                cancel,
            }
        )

        return result
    }

    function prepareUploadRequests(image: Blob) {
        return validateImageResolution(image).then(imageType =>
            api.getUploadTokens().then(
                tokens => getImageUploadRequests(tokens, image).then(
                    requests => ({ imageId: tokens.imageId, image, imageType, requests })
                )
            )
        )
    }
}

export default factory

function getCancellation<T>(): [Promise<T>, CancelUpload] {
    let rejectCancel: () => void
    const cancelPromise = new Promise<T>((_, reject) => {
            rejectCancel = () => reject({ isHandled: true })
        })

    return [cancelPromise, rejectCancel!] //NOSONAR
}

function rescale(rect: Rectangle, ratio: number): Rectangle {
    return {
        xMin: Math.floor(rect.xMin * ratio),
        yMin: Math.floor(rect.yMin * ratio),
        xMax: Math.floor(rect.xMax * ratio),
        yMax: Math.floor(rect.yMax * ratio),
    }
}
