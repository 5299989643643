import type { FieldRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { classnames } from '_/facade/react'
import { noop } from '_/utils/function'

interface OwnProps {
    checked?: boolean
    onSeriesSelect(_: FieldRenderProps<HTMLElement>): void
    showSeriesPicker: boolean
    render(): React.ReactNode
    testId: string
    disableMessage?: string
}

const MultiSelectWrapper = (props: OwnProps) =>
    // btn-check uses absolute positioning. This brakes vertical scroll on analysis.
    // position-relative works around this issue.
    <div className='d-flex align-items-start position-relative'>
        {props.showSeriesPicker &&
            <Field name='seriesFieldIndex' render={_ =>
                <div className='mb-3'>
                    <label className='col-form-label'>&nbsp;</label>
                    <div data-toggle='buttons'>
                        <label className={classnames('btn', props.disableMessage && 'cursor-default')}>
                            <input
                                type='radio'
                                className='btn-check'
                                name='series'
                                checked={props.checked}
                                disabled={!!props.disableMessage}
                                onChange={() => props.onSeriesSelect(_)}
                                onClick={() => props.checked ? props.onSeriesSelect(_) : noop}
                            />
                            {/*data-testid used here to avoid ElementClickInterceptedException, because input field intercepted with this element and not clickable*/}
                            <i
                                data-testid={props.testId}
                                title={props.disableMessage ?? 'Set as graph series'}
                                className={classnames('material-icons md-24', props.checked && 'text-primary', !props.checked && 'text-secondary', props.disableMessage && 'half-opacity')}
                            >
                                assessment
                            </i>
                        </label>
                    </div>
                </div>
            } />
        }
        {props.render()}
    </div>

export default MultiSelectWrapper
