import { useField } from 'react-final-form'

import { useSelector, useEffect } from '_/facade/react'

import type { ImageUpload } from '_/model/floor-plan/upload/types'

import { useImageTokens } from '../hooks'

function ImageField() {
    const image = useImageField()
        , src = useImageTokens(image?.id)?.original

    if (!image)
        return null

    const inProgress = !image.uploaded

    return (
        <>
            {src && <img src={src} className='floor-plans-image' />}
            {inProgress && <i className='preview-spinner material-icons text-primary md-48' data-testid='upload-floor-plan-spinner'>sync</i>}
        </>
    )
}

export default ImageField

function useImageField() {
    const image = useSelector<ImageUpload | undefined>(_ => _.predefinedLists.floorPlans.imageUploads[0])
        , field = useField('image')

    useEffect(
        () => {
            const nextValue = image ?? ''

            if (field.input.value === nextValue)
                return

            field.input.onChange(image)
        },
        [image, field]
    )

    return image
}
