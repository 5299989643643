import { Field } from 'react-final-form'
import classnames from 'classnames'

import NO_PERMISSION_MESSAGE from '_/constants/permission-messages'

import NumberInput from './number-input'

import { showFieldError } from './helpers'

interface InputFieldProps {
    id?: string
    name: string
    disabled?: boolean
    hasNoPermissions?: boolean
    autoFocus?: boolean
    inputRef?: React.Ref<HTMLInputElement>
    testId?: string
    children?: React.ReactNode
    className?: string
    formGroup?: boolean
}

const NumberFieldInline = (props: InputFieldProps) =>
    <Field name={props.name} render={
        _ =>
            <div className={`${props.formGroup ? 'mb-3' : 'row g-2'} ${props.className ?? ''}`}>
                {props.children && <label htmlFor={props.id} className='col-form-label'>{props.children}</label>}
                <NumberInput
                    id={props.id}
                    disabled={props.hasNoPermissions || props.disabled}
                    className={classnames('form-control', {
                        'is-invalid': showFieldError(_.meta),
                        'no-permission-input': props.hasNoPermissions,
                    })}
                    title={props.hasNoPermissions ? NO_PERMISSION_MESSAGE : '' }
                    {..._.input}
                    autoFocus={props.autoFocus}
                    ref={props.inputRef}
                    testId={props.testId}
                />
                {showFieldError(_.meta) && <span className='invalid-feedback' data-testid='validation-error'>{_.meta.error}</span>}
            </div>
    }/>

export default NumberFieldInline
