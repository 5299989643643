import { useSelector, useAction, useState, useEffect, useCallback, useLayoutEffect, useMemo, classnames } from '_/facade/react'
import { useAppliedFilterRef, useDebounce, useOrganisms } from '_/hooks/shared-hooks'
import getPlotly from '_/facade/plotly'

import type { AnalysisFilter, ThresholdLine } from '_/model/analysis/filter/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { GraphData } from '_/model/analysis/types'

import { fullNameLocationList } from '_/utils/exposure-location'
import { diffObject, dropFields, getAllFields, getFields, shallowUpdate } from '_/utils/object'

import { useTimeService } from '_/components/time'

import * as actions from '../actions'
import * as contextActions from '_/features/contexts/actions'
import * as predefinedListsActions from '_/features/predefined-lists/redux/actions'
import * as floorPlanActions from '_/features/predefined-lists/floor-plan/actions'

import * as fieldIndex from '_/constants/custom-field-index'
import * as tiers from '_/constants/tiers'

import * as ct from '_/model/analysis/chart-type'
import Filter from '../filter/analysis-filter-form'
import AddThresholdLineModal from '../filter/threshold-line-modal'
import { recalculateDynamicExposureDate } from '../filter/helpers'

import * as helpers from './helpers'
import { sortCustomFields } from '_/features/samples/filter/helpers'
import { USED_FIELDS } from '../filter/helpers'

import { getFieldNotRecorded, getFieldValue } from '_/features/samples/helpers'
import AnalysisCharts from './analysis-charts'
import FloorPlanCharts from './analysis-floor-plan-charts'

import { memoize } from '_/utils/function'
import { isDefaultCustomField } from '_/model/predefined-lists/custom-field/custom-field'
import type Context from '_/model/context/context'
import type { FloorPlanSubFilterValue } from '_/model/floor-plan/floor-plan'
import { calculateTimeSliderMaxPosition, calculateStartDate, calculateTimeSliderDate } from '_/model/floor-plan/time-slider'
import { calcFilter } from '_/model/filters/helpers'
import Button from '_/components/button'
import { ANALYSIS_GRAPH, CUSTOM_REPORT } from '_/model/floor-plan/floor-plan-location-page'
import type { FilterFieldValue } from '_/model/sample/search'
import type { PredefinedLists } from '_/model/app-state'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import * as uc from '_/features/unsaved-changes/actions'
import { AggregationPeriod, MONTH } from '_/model/analysis/aggregation-period'

interface DefaultFilter {
    initialFilter: AnalysisFilter
    floorPlanSubFilterValue: FloorPlanSubFilterValue
}

interface AnalysisProps {
    onCustomReportRender?: (filter: AnalysisFilter, reportGenerated: boolean, submitDisabled?: boolean) => React.ReactNode
    customReportInitialFilter?: AnalysisFilter | undefined
    isGraphInCustomReport?: boolean | undefined
    formId?: string
}

function Analysis(props: AnalysisProps) {
    const predefinedLists = usePreload()

    if (!predefinedLists)
        return null

    return <AnalysisInternal {...props} predefinedLists={predefinedLists} />
}

interface AnalysisInternalProps {
    predefinedLists: PredefinedLists
    onCustomReportRender?: (filter: AnalysisFilter, reportGenerated: boolean, submitDisabled?: boolean) => React.ReactNode
    customReportInitialFilter?: AnalysisFilter | undefined
    isGraphInCustomReport?: boolean | undefined
    formId?: string
}

function AnalysisInternal(props: AnalysisInternalProps) {
    const timeService = useTimeService()

    const predefinedLists = props.predefinedLists
        , exposureLocationList = fullNameLocationList(predefinedLists.exposureLocations)
        , permissions = useSelector(_ => _.auth.permissions)

    const defaultFilter = useDefaultFilter(predefinedLists.customFields, !!props.isGraphInCustomReport, props.customReportInitialFilter)
        , [initialFilter, setInitialFilter] = useState(defaultFilter.initialFilter)
        , [latestFilter, setLatestFilter] = useState(defaultFilter.initialFilter)
        , [floorPlanSubFilterValue, setFloorPlanSubFilterValue] = useState(defaultFilter.floorPlanSubFilterValue)
        , [exposureDateRangeModified, setExposureDateRangeModified] = useState(
            () => helpers.isExposureDateRangeModified(initialFilter, helpers.computeExposureDateRange(timeService, initialFilter.chartType ?? ct.AVERAGE_CFU_CHART)),
        )
        , [showTabularView, setShowTabularView] = useState(false)
        , [filterInvalid, setFilterInvalid] = useState(false)
        , [showThresholdLineModal, setShowThresholdLineModal] = useState(false)
        , [thresholdLines, setThresholdLines, addThresholdLine, removeThresholdLine, removeAllThresholdLines] = useThresholdLines(initialFilter)

    const graphData = useGraphData(latestFilter, floorPlanSubFilterValue, !!props.isGraphInCustomReport, thresholdLines, props.formId, hasGraphChanged())

    const loadData = useCallback(
        function loadData(query: AnalysisFilter, floorPlanSubFilterValue: FloorPlanSubFilterValue) {
            setInitialFilter(_ => diffObject(_, query) ? query : _)
            setLatestFilter(_ => diffObject(_, query) ? query : _)
            setFloorPlanSubFilterValue(_ => diffObject(_, floorPlanSubFilterValue) ? floorPlanSubFilterValue : _)
        },
        []
    )

    useEffect(
        () => {
            loadData(defaultFilter.initialFilter, defaultFilter.floorPlanSubFilterValue)
        },
        [defaultFilter, loadData]
    )

    function handleFilterClear() {
        setInitialFilter(_ => ({
            chartType: _.chartType,
            aggregationPeriod: MONTH as AggregationPeriod,
            subChartType: _.subChartType,
            includeCompromised: false,
            seriesFieldIndex: helpers.computeSeriesFieldIndex(_.chartType!),
            fields: predefinedLists.customFields.map(_ => ({ index: _.index, value: undefined, notRecorded: false })),
            includeTotalSamples: false,
            joinPoints: true,
            ...helpers.computeExposureDateRange(timeService, _.chartType!)
        }))
        removeAllThresholdLines()
    }

    function handleSplitLocation(locationName: string) {
        const exposureLocations = helpers.getLocationChildIds(exposureLocationList, locationName)
        if (!exposureLocations)
            return

        const fields = latestFilter.fields!.map(_ => {
            if (_.index === fieldIndex.EXPOSURE_LOCATION_ID)
                return {
                    index: _.index,
                    value: exposureLocations,
                }
            return _
        })

        const newLatestFilter = shallowUpdate(latestFilter, { fields })

        loadData(newLatestFilter, floorPlanSubFilterValue)
    }

    function handleFilterGenerated(latestFilter: AnalysisFilter) {
        const newExposureDateRangeModified = initialFilter.chartType === latestFilter.chartType
                && helpers.isExposureDateRangeModified(latestFilter, initialFilter)
            , aggregationPeriodModified = latestFilter.aggregationPeriod !== initialFilter.aggregationPeriod
            , timeSliderPosition = calculateTimeSliderMaxPosition(latestFilter, timeService)
            , newFloorPlanSubFilterValue = newExposureDateRangeModified || aggregationPeriodModified
                ? shallowUpdate(floorPlanSubFilterValue, { timeSliderPosition })
                : floorPlanSubFilterValue

        setExposureDateRangeModified(exposureDateRangeModified || newExposureDateRangeModified)

        loadData(latestFilter, newFloorPlanSubFilterValue)
    }

    function handleSearchTemplateLoaded(filterTemplate: AnalysisFilter) {
        const fields = predefinedLists.customFields.map(_ => ({ index: _.index, value: getFieldValue(filterTemplate.fields, _.index), notRecorded: getFieldNotRecorded(filterTemplate.fields, _.index) }))
            , { cumulativeView, floorPlanId, timeSliderPosition } = filterTemplate

        setThresholdLines(filterTemplate.thresholdLines ?? [])

        loadData(
            dropFields(shallowUpdate(filterTemplate, { fields }), 'cumulativeView', 'floorPlanId', 'timeSliderPosition'),
            { cumulativeView: cumulativeView ?? true, floorPlanId, timeSliderPosition }
        )
    }

    function hasGraphChanged() {
        const emptyArraysFields = getFields(defaultFilter.initialFilter, value => Array.isArray(value) && value.length === 0)
            , fieldsValid = getFields(latestFilter, value => value)
            , fieldsToDrop = getAllFields(defaultFilter.initialFilter)
                .filter(key => !fieldsValid.some(_ => _ === key))
                .filter(key => emptyArraysFields.some(_ => _ === key))
                .concat('fields')
            , fieldsChanged = () => {
                const diff = diffObject(latestFilter.fields, defaultFilter.initialFilter.fields)

                if (!diff)
                    return false

                return Object.keys(diff).map(Number).some(_ => {
                    const field = defaultFilter.initialFilter.fields![_]
                        , newField = latestFilter.fields?.find(_ => _.index === field.index)
                        , fieldChanged = !newField
                                    || (field.notRecorded ?? false) !== (newField.notRecorded ?? false)
                                    || !!diffObject(newField.value, field.value)
                    return fieldChanged
                })
            }
            , filterChanged = !!diffObject(dropFields(latestFilter, ...fieldsToDrop), dropFields(defaultFilter.initialFilter, ...fieldsToDrop)) || fieldsChanged()
            , floorPlanInfoChanged = floorPlanSubFilterValue.cumulativeView !== defaultFilter.floorPlanSubFilterValue.cumulativeView
                || floorPlanSubFilterValue.timeSliderPosition !== defaultFilter.floorPlanSubFilterValue.timeSliderPosition
            , thresholdLinesChanged = diffObject(defaultFilter.initialFilter.thresholdLines ?? [], thresholdLines)

        return filterChanged || floorPlanInfoChanged || !!thresholdLinesChanged
    }

    const [metadata, floorPlanMetadata, floorPlans] = useCharMetadata(
            latestFilter,
            floorPlanSubFilterValue,
            predefinedLists,
            exposureLocationList,
            helpers.isFloorPlanChartData(graphData),
        )
        , disableSubmit = props.customReportInitialFilter ? !hasGraphChanged() : false

    return (
        <>
            {props.onCustomReportRender && props.onCustomReportRender({ ...latestFilter, ...floorPlanSubFilterValue, thresholdLines }, filterInvalid, disableSubmit)}
            <div className='d-flex align-items-stretch h-100'>
                <Filter
                    predefinedLists={predefinedLists}
                    initialValue={initialFilter}
                    onGenerated={handleFilterGenerated}
                    onClear={handleFilterClear}
                    showAggregationPeriod={initialFilter.chartType !== ct.ORGANISMS_BREAKDOWN_CHART && initialFilter.chartType !== ct.PARTICLE_COUNTS}
                    onSearchTemplateLoaded={handleSearchTemplateLoaded}
                    filtersTemplatesIncluded={!props.onCustomReportRender}
                    isGraphInCustomReport={props.isGraphInCustomReport}
                    formId={props.formId}
                    floorPlanSubFilterValue={floorPlanSubFilterValue}
                    exposureDateRangeModified={exposureDateRangeModified}
                    onInvalid={setFilterInvalid}
                    showThresholdLineModal={() => setShowThresholdLineModal(true)}
                    onRemoveThresholdLine={removeThresholdLine}
                    onRemoveAllThresholdLine={removeAllThresholdLines}
                    thresholdLines={thresholdLines}
                />

                {showThresholdLineModal &&
                    <AddThresholdLineModal
                        thresholdLines={thresholdLines}
                        chartType={graphData.chartType}
                        onClose={() => setShowThresholdLineModal(false)}
                        onAddThresholdLine={addThresholdLine}
                    />
                }

                <div className='container-fluid overflow-y-auto h-100'>
                    {!props.onCustomReportRender && <div className='d-flex'>
                        <Button
                            className={classnames('align-self-start d-print-none btn-link me-1 mt-2 ms-auto', initialFilter.chartType === ct.PARTICLE_COUNTS && 'cursor-default')}
                            disabled={initialFilter.chartType === ct.PARTICLE_COUNTS}
                            onClick={() => setShowTabularView(!showTabularView)}
                        >
                            {showTabularView ? 'Hide data' : 'View data'}
                        </Button>
                    </div>}
                    <div className='d-flex justify-content-center align-items-stretch'>
                        <div className='flex-fill w-100'>
                            {helpers.isAnalysisChartData(graphData) &&
                                <AnalysisCharts
                                    filter={latestFilter}
                                    onSplitLocation={handleSplitLocation}
                                    metadata={metadata}
                                    timeService={timeService}
                                    showActionButtons={!props.onCustomReportRender}
                                    graphData={graphData}
                                    exportDisabled={!permissions.exportData}
                                    showTabularView={showTabularView}
                                    thresholdLines={thresholdLines}
                                />
                            }
                            {helpers.isFloorPlanChartData(graphData) && permissions.useFloorPlansCharts &&
                                (predefinedLists.floorPlans.plans.length !== 0
                                    ? <FloorPlanCharts
                                            filter={latestFilter}
                                            timeService={timeService}
                                            showActionButtons={!props.onCustomReportRender}
                                            graphData={graphData}
                                            exportDisabled={!permissions.exportData}
                                            floorPlans={floorPlans}
                                            floorPlanSubFilter={{
                                                value: {
                                                    floorPlanId: floorPlanMetadata.id,
                                                    cumulativeView: floorPlanSubFilterValue.cumulativeView,
                                                    timeSliderPosition: floorPlanSubFilterValue.timeSliderPosition,
                                                },
                                                onChange: setFloorPlanSubFilterValue,
                                            }}
                                            metadata={[floorPlanMetadata]}
                                            showTabularView={showTabularView}
                                            floorPlanLocationPage={props.onCustomReportRender ? CUSTOM_REPORT : ANALYSIS_GRAPH}
                                        />
                                    : <div className='text-center'>There are no floor plans to display</div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Analysis

function usePreload() {
    const [filterFields] = useState(() =>
            memoize((fields: CustomField[], context: Context | undefined) => {
                const newFields: CustomField[] = []
                    , atLeastInsight = context && context.tier > tiers.COMPLIANCE

                fields.forEach(_ => {
                    if (USED_FIELDS.some(index => index === _.index) || (atLeastInsight && !isDefaultCustomField(_)))
                        newFields.push(_)
                })
                return sortCustomFields(newFields)
            })
        )

    const predefinedLists = useSelector<PredefinedLists>(_ => _.predefinedLists)
        , membership = useSelector(_ => _.auth.user?.membership)
        , context = useSelector(_ => _.contexts.contexts.find(ctx => ctx.id === membership?.contextId))

    const [loaded, setLoaded] = useState(false)
        , [updatedPredefinedLists, setUpdatedPredefinedLists] = useState<PredefinedLists>()

    useEffect(
        () => {
            const customFields = filterFields(predefinedLists.customFields, context)

            setUpdatedPredefinedLists({ ...predefinedLists, customFields })
        },
        [context, predefinedLists, filterFields]
    )

    const loadPredefinedLists = useAction(predefinedListsActions.loadPredefinedLists)
        , loadContext = useAction(contextActions.loadContext)
        , loadAnalysisSearchFilterList = useAction(actions.loadAnalysisSearchFilterList)
        , loadFloorPlanList = useAction(floorPlanActions.loadFloorPlanList)
        , permissions = useSelector(_ => _.auth.permissions)

    useEffect(
        () => {
            if (!membership || !permissions.readPredefinedLists)
                return

            setLoaded(false)
            Promise
                .all([
                    loadContext(membership.contextId),
                    loadPredefinedLists({ includeInactiveCustomFields: true }),
                    permissions.useFloorPlansCharts ? loadFloorPlanList() : undefined,
                    permissions.manageAnalysisFilters ? loadAnalysisSearchFilterList() : undefined,
                    // preload plotly code with data above as well
                    getPlotly(),
                ])
                .then(() => setLoaded(true))
        },
        [membership, permissions, loadContext, loadPredefinedLists, loadFloorPlanList, loadAnalysisSearchFilterList]
    )

    return loaded ? updatedPredefinedLists : undefined
}

function useThresholdLines(filter: AnalysisFilter) {
    const [thresholdLines, setThresholdLines] = useState<ThresholdLine[]>(filter.thresholdLines ?? [])

    function addThresholdLine(newThresholdLine: ThresholdLine) {
        setThresholdLines(_ => _.concat(newThresholdLine))
    }

    function removeThresholdLine(index: number) {
        setThresholdLines(_ => _.filter((_, i) => i !== index))
    }

    function removeAllThresholdLines() {
        setThresholdLines([])
    }

    return [
        thresholdLines,
        setThresholdLines,
        addThresholdLine,
        removeThresholdLine,
        removeAllThresholdLines
    ] as const
}

function useGraphData(
    mainFilter: AnalysisFilter,
    floorPlanSubFilter: FloorPlanSubFilterValue,
    isGraphInCustomReport: boolean,
    thresholdLines: ThresholdLine[],
    formId: string | undefined,
    hasGraphChanged: boolean
): GraphData {
    const [graphData, setGraphData] = useState<GraphData>(() => emptyGraphData(mainFilter.chartType))
        , timeService = useTimeService()
        , permissions = useSelector(_ => _.auth.permissions)

    const loadLinesMarkersChartSeries = useAction(actions.loadLinesMarkersChartSeries)
        , loadOrganismsBreakdown = useAction(actions.loadOrganismsBreakdown)
        , loadOrganismTypeBreakdown = useAction(actions.loadOrganismTypeBreakdown)
        , loadStackedSampleName = useAction(actions.loadStackedSampleName)
        , loadContaminationFloorPlan = useAction(actions.loadContaminationFloorPlan)
        , loadLimitBreachFloorPlan = useAction(actions.loadLimitBreachFloorPlan)

        , hasUnsavedChanges = useAction(uc.hasUnsavedChanges)

    const getFloorPlanSeries = useCallback(
        function getFloorPlanSeries(query: AnalysisFilter, currentChartType: ct.FloorPlanChart): Promise<GraphData> {
            switch (currentChartType) {
                case ct.CONTAMINATION_FLOOR_PLAN:
                    return loadContaminationFloorPlan(query)

                case ct.LIMIT_BREACH_FLOOR_PLAN:
                    return loadLimitBreachFloorPlan(query)
            }
        },
        [loadContaminationFloorPlan, loadLimitBreachFloorPlan]
    )

    const handleLoadFloorPlanSeries = useCallback(
        function handleLoadFloorPlanSeries(query: AnalysisFilter, currentChartType: ct.FloorPlanChart) {
            if (!permissions.useFloorPlansCharts)
                return Promise.resolve(emptyGraphData(currentChartType))

            const computedExposureDateRange = recalculateDynamicExposureDate(query, timeService)
                , dateFrom = query.exposureStartDateFrom ?? computedExposureDateRange.exposureStartDateFrom
                , dateTo = query.exposureStartDateTo ?? computedExposureDateRange.exposureStartDateTo
                , exposureStartDateTo = calculateTimeSliderDate(dateFrom, dateTo, query.aggregationPeriod, timeService, query.timeSliderPosition)
                , exposureStartDateFrom = calculateStartDate(query.aggregationPeriod, dateFrom, exposureStartDateTo, query.cumulativeView!, timeService)
                , floorPlanQuery = {...query, exposureStartDateTo, exposureStartDateFrom}

            return getFloorPlanSeries(floorPlanQuery, currentChartType)
        },
        [getFloorPlanSeries, timeService, permissions.useFloorPlansCharts]
    )

    const loadGraphData = useCallback(
        function loadGraphData(filter: AnalysisFilter): Promise<GraphData> {
            const newQuery = dropFields(filter, 'exposureDateRange', 'chartType')

            if (filter.chartType === ct.ORGANISMS_BREAKDOWN_CHART) {
                return loadOrganismsBreakdown(newQuery)
            }
            else if (filter.chartType === ct.ORGANISM_TYPE_BREAKDOWN) {
                return loadOrganismTypeBreakdown(newQuery)
            }
            else if (filter.chartType === ct.STACKED_SAMPLE_NAME) {
                return loadStackedSampleName(newQuery)
            }
            else if (helpers.isLinesMarkersChart(filter.chartType)) {
                return loadLinesMarkersChartSeries({ ...newQuery, chartType: filter.chartType })
            }
            else if (helpers.isFloorPlanChart(filter.chartType)) {
                return handleLoadFloorPlanSeries(newQuery, filter.chartType)
            }
            else {
                return Promise.resolve(emptyGraphData(filter.chartType))
            }
        },
        [
            loadLinesMarkersChartSeries,
            loadOrganismsBreakdown,
            loadOrganismTypeBreakdown,
            loadStackedSampleName,
            handleLoadFloorPlanSeries,
        ]
    )

    const latestAppliedFilter = useAppliedFilterRef<AnalysisFilter>('analysis')
        , debounce = useDebounce()

    useEffect(
        () => {
            if (!permissions.readAnalysis)
                return

            let disposed = false
            debounce(() => {
                const filter = { ...mainFilter, ...floorPlanSubFilter }
                loadGraphData(filter).then(graphData => {
                    if (disposed)
                        return

                    setGraphData(graphData)
                })
            })

            return () => {
                disposed = true
            }
        },
        [mainFilter, floorPlanSubFilter, permissions.readAnalysis, loadGraphData, debounce]
    )

    useEffect(
        () => {
            const filter = { ...mainFilter, ...floorPlanSubFilter, thresholdLines }
            if (isGraphInCustomReport)
                hasUnsavedChanges(hasGraphChanged, formId)
            else
                latestAppliedFilter.current = filter

            return () => { hasUnsavedChanges(false, formId) }
        },
        [mainFilter, floorPlanSubFilter, thresholdLines, isGraphInCustomReport, latestAppliedFilter, formId, hasGraphChanged, hasUnsavedChanges]
    )

    return graphData
}

function useDefaultFilter(customFields: CustomField[], isGraphInCustomReport: boolean, customReportFilter: AnalysisFilter | undefined) {
    const timeService = useTimeService()
        , latestFilters = useSelector(_ => _.filters.filters)
        , route = useSelector(_ => _.router.route!)
        // avoids filter update after new filter application
        , [persistedFilterData] = useState({ latestFilters, route })

    const getDefaultFilter = useCallback(
            function getDefaultFilter(): DefaultFilter {
                const initialFilter = customReportFilter
                        ? getInitialCustomReportFilter(customReportFilter)
                        : getInitialFilter()

                const fields = helpers.normalizeFields(initialFilter.fields || [], customFields)
                    , floorPlanSubFilterValue = {
                        floorPlanId: initialFilter.floorPlanId,
                        cumulativeView: initialFilter.cumulativeView ?? true,
                        timeSliderPosition: initialFilter.timeSliderPosition ?? calculateTimeSliderMaxPosition(initialFilter, timeService),
                    }

                return {
                    initialFilter: { ...initialFilter, ...floorPlanSubFilterValue, fields },
                    floorPlanSubFilterValue,
                }

                function getInitialCustomReportFilter(customReportFilter: AnalysisFilter) {
                    const fields = syncFilterFields(customReportFilter.fields, customFields)
                        , { exposureStartDateFrom, exposureStartDateTo } = recalculateDynamicExposureDate(customReportFilter, timeService)

                    return { ...customReportFilter, fields, exposureStartDateFrom, exposureStartDateTo }
                }

                function getInitialFilter(): AnalysisFilter {
                    return calcFilter(persistedFilterData.route, isGraphInCustomReport ? undefined : persistedFilterData.latestFilters.find(_ => _.name === 'analysis')?.value, (params = {}) => {
                        const chartType = params.chartType ?? ct.AVERAGE_CFU_CHART
                            , seriesFieldIndex = params.seriesFieldIndex ?? helpers.computeSeriesFieldIndex(chartType)
                            , computedExposureDateRange = helpers.computeExposureDateRange(timeService, chartType)
                            , exposureStartDateTo = params.exposureStartDateTo ?? computedExposureDateRange.exposureStartDateTo

                        return {
                            aggregationPeriod: params.aggregationPeriod ?? MONTH as AggregationPeriod,
                            chartType,
                            subChartType: helpers.computeSubChartType(params.chartType, params.subChartType),
                            includeCompromised: params.includeCompromised ?? false,
                            seriesFieldIndex,
                            fields: syncFilterFields(params.fields, customFields),
                            exposureDateRange: params.exposureDateRange ?? computedExposureDateRange.exposureDateRange,
                            exposureStartDateFrom: params.exposureStartDateFrom ?? computedExposureDateRange.exposureStartDateFrom,
                            exposureStartDateTo,
                            gradeIds: params.gradeIds,
                            cumulativeView: params.cumulativeView,
                            timeSliderPosition: undefined,
                            includeTotalSamples: params.includeTotalSamples ?? false,
                            particleStates: params.particleStates,
                            joinPoints: params.joinPoints ?? true,
                        }
                    })
                }
            },
            [customFields, isGraphInCustomReport, customReportFilter, timeService, persistedFilterData]
        )

    const [defaultFilter, setDefaultFilter] = useState(() => getDefaultFilter())

    useLayoutEffect(
        () => {
            const newFilter = getDefaultFilter()
            setDefaultFilter(_ => diffObject(newFilter, _) ? newFilter : _)
        },
        [getDefaultFilter]
    )

    return defaultFilter
}

function emptyGraphData(chartType: ct.ChartType = ct.AVERAGE_CFU_CHART): GraphData {
    return chartType === ct.ORGANISMS_BREAKDOWN_CHART
            ? {
                chartType,
                // todo: unify chart shape
                series: { series: [] }
            }
            : {
                chartType,
                series: [],
                yAxes: [''],
            }
}

function syncFilterFields(filterFields: FilterFieldValue[] | undefined, customFields: CustomField[]) {
    return customFields.map(_ => ({ index: _.index, value: getFieldValue(filterFields, _.index), notRecorded: getFieldNotRecorded(filterFields, _.index) }))
}

function useCharMetadata(analysisFilter: AnalysisFilter, floorPlanSubFilterValue: FloorPlanSubFilterValue, predefinedLists: PredefinedLists, exposureLocations: ListExposureLocation[], isFloorPlanData: boolean) {
    const timeService = useTimeService()
        , user = useSelector(_ => _.auth.user)
        , organisms = useOrganisms(analysisFilter.organismIds)

    const floorPlans = isFloorPlanData
            ? helpers.getFilteredFloorPlans(predefinedLists.floorPlans.plans, analysisFilter, exposureLocations)
            : []
        , floorPlan = floorPlans.find(_ => _.id === floorPlanSubFilterValue.floorPlanId) ?? floorPlans.find((_, idx) => idx === 0)

    const metadata = useMemo(
        () => {
            if (isFloorPlanData) {
                return helpers.getFloorPlanMetadata({
                    analysisFilter,
                    timeService,
                    user,
                    predefinedLists,
                    organisms,
                    floorPlan,
                    reportName: undefined,
                })
            }
            else {
                return helpers.getAnalysisMetadata({
                    analysisFilter,
                    timeService,
                    user,
                    predefinedLists,
                    organisms,
                    reportName: undefined,
                })
            }
        },
        [analysisFilter, floorPlan, isFloorPlanData, predefinedLists, organisms, user, timeService]
    )

    const floorPlanMetadata = { id: floorPlan?.id ?? '', metadata }

    return [metadata, floorPlanMetadata, floorPlans] as const
}
