import CONFIG from '_/constants/config'
import { buildUrl } from '_/utils/ajax'

function loadHelpStyle(href: string[], media: string) {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.media = media
    link.href = buildUrl({ api: CONFIG.api, segments: ['help'].concat(href) })

    document.head.appendChild(link)
}
loadHelpStyle(['css', 'docs.css'], 'screen')
loadHelpStyle(['css', 'pdf.css'], 'print')
