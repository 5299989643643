export const EXPOSURE_DATE = 0
           , EXPOSURE_LOCATION = 1
           , SAMPLE_NAME = 2
           , SESSION = 3
           , EXPOSURE_TIME = 4
           , OPERATORS = 5
           , BATCH_NUMBER = 6
           , STATUS = 7
           , TOTAL_CFU = 8
           , PHOTOS = 9
           , INVESTIGATION_REFERENCES = 10
           , BARCODE = 11
           , USER_DEFINED = 12

import type * as self from './columns'
type ColumnType = Extract<(typeof self)[keyof typeof self], number>

export {
    ColumnType,
}
