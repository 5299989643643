import type Sample from '_/model/sample/sample'
import type SampleDetails from '_/model/sample/sample-details'
import type { IdentificationRow } from '_/model/sample/reading/sample-reading'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type { SampleSession } from '_/model/predefined-lists/session/types'
import type { SampleOperator } from '_/model/predefined-lists/operator/types'

import * as fieldIndex from '_/constants/custom-field-index'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import GROWTHS_STATUS from '_/constants/growth-status'
import { calcGrowthsStatus, getFieldValue, isBreachedByTotalCfu } from '../helpers'
import * as behaviours from '_/constants/objectionable-limit-behaviour'

function convertSampleToSampleEdit(sample: SampleDetails, fields: CustomField[]): SampleDetailsEdit {
    return {
        isForReview: sample.isForReview,
        sampleInvestigationReferences: sample.sampleInvestigationReferences,
        compromised: sample.compromised,
        nullified: sample.nullified,
        manuallyActionLimitBreached: sample.manuallyActionLimitBreached,
        monitoringState: sample.monitoringState,
        fields: normalizeFields(sample.fields, fields),
    }
}

function normalizeFields(fields: FieldValues[], customFields: CustomField[]) {
    const missedFields = customFields
        .filter(_ => !fields.some(it => it.index == _.index))
        .map(_ => <FieldValues> {
            index: _.index,
            value: undefined,
            notRecorded: false,
        })

    return fields.concat(missedFields)
}

function updateEndTimeEndDateFields(sampleFields: FieldValues[], fields: CustomField[], isSettlePlate: boolean, isViable: boolean): FieldValues[] {
    const endTimeField = fields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)
        , endTimeRequired = endTimeField && endTimeField.viableSettings.required

    if (isViable && !endTimeRequired && !isSettlePlate)
        return sampleFields.map(field => {
            if (field.index === fieldIndex.EXPOSURE_END_TIME)
                return {
                    ...field,
                    notRecorded: false,
                    value: undefined,
                }
            if (field.index === fieldIndex.EXPOSURE_END_DATE)
                return {
                    ...field,
                    value: getFieldValue(sampleFields, fieldIndex.EXPOSURE_START_DATE),
                }
            return field
        })
    return sampleFields
}

function tryRestoreInactiveExposureLocation(sample: Pick<Sample, 'fields'> | undefined, exposureLocations: ListExposureLocation[]) {
    const locationField = sample && sample.fields.find(_ => _.index === fieldIndex.MONITORING_POSITION)

    return exposureLocations.filter(_ => _.isActive || _.id === locationField?.value)
}

function tryRestoreInactiveSessions(sample: Pick<Sample, 'fields'> | undefined, sampleSessions: SampleSession[]) {
    const sessionField = sample && sample.fields.find(_ => _.index === fieldIndex.SESSION_ID)

    return sampleSessions.filter(_ => _.isActive || _.id === sessionField?.value)
}

function tryRestoreInactiveOperators(sample: Pick<Sample, 'fields'> | undefined, operators: SampleOperator[]) {
    const operatorField = sample && sample.fields.find(_ => _.index === fieldIndex.OPERATORS_IDS)

    return operators.filter(op =>
        op.isActive || (operatorField?.value ?? []).some((_: string) => _ === op.id)
    )
}

function canChangeSampleTypeToSwab(sample: SampleDetails | undefined) {
    const isValidCfu = (rows: IdentificationRow[]) => !rows.some(_ => _.cfuCount && _.cfuCount > 1)
    return sample
        ? isValidCfu(sample.identifications.rows) && isValidCfu(sample.optionalIdentifications?.rows ?? [])
        : false
}

function computeContaminationInfo({ entity, isOptionalGrowth }: { entity: SampleDetails, isOptionalGrowth: boolean }) {
    const growthsStatusId = isOptionalGrowth
            ? calcGrowthsStatus(entity.optionalIdentifications)
            : calcGrowthsStatus(entity.identifications)
        , growthsStatusName = GROWTHS_STATUS.find(_ => _.id === growthsStatusId)!.name
        , growths = isOptionalGrowth ? entity.optionalIdentifications! : entity.identifications
        , alertBreached = entity.behaviour === behaviours.BREACH_ALERT_LIMIT
        , limitBreachedByTotalCfu = isBreachedByTotalCfu(entity, isOptionalGrowth)

    return {
        growthsStatusId,
        growthsStatusName,
        growths,
        alertBreached,
        limitBreachedByTotalCfu,
    }
}

export {
    convertSampleToSampleEdit,
    updateEndTimeEndDateFields,
    tryRestoreInactiveExposureLocation,
    tryRestoreInactiveSessions,
    tryRestoreInactiveOperators,
    canChangeSampleTypeToSwab,
    computeContaminationInfo,
    normalizeFields,
}
