import { useAction, useEffect, useState } from '_/facade/react'

import * as m from '_/components/modal'
import AuditTrails from '_/components/audit-trail-list'
import { Close } from '_/components/button'

import * as dayScheduleActions from './actions'
import type { DateTime } from '_/model/date-time'
import type { AuditTrail } from '_/model/audit-trail/types'

interface Props {
    onClose(): void
    dayScheduleId: string | undefined
    date: DateTime
}

function AuditTrailModal(props: Props) {
    const trail = useAuditTrail(props.dayScheduleId, props.date)

    return(
        <m.Modal isOpen onClose={props.onClose}>
            <m.ModalHeader className='pb-0 border-bottom-0'>
                <h4 className='ps-1'>Audit trail</h4>
                <Close onClick={props.onClose}/>
            </m.ModalHeader>
            <m.ModalBody>
                <AuditTrails
                    trail={trail}
                    emptyMessage='There are no records'
                    hideHeader
                />
            </m.ModalBody>
        </m.Modal>
    )
}

function useAuditTrail(dayScheduleId: string | undefined, date: DateTime) {
    const loadTrail = useAction(dayScheduleActions.loadDayScheduleTrail)
        , [trails, setTrails] = useState<AuditTrail | undefined>(undefined)

    useEffect(
        () => {
            if (!dayScheduleId)
                return

            loadTrail({dayScheduleId, date})
                .then(setTrails)
        },
        [loadTrail, dayScheduleId, date]
    )

    return trails
}

export default AuditTrailModal
