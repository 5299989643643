import { required } from '_/utils/form/validate'
import  generalValidate from '../validate'
import type FloorPlan from '../floor-plan'

import type { FloorPlanForm, FloorPlanFormValidation } from './types'

function validate(form: FloorPlanForm, plans: FloorPlan[], id: string | undefined): FloorPlanFormValidation {
    const generalValidationResult = generalValidate(form, plans, id)
        , locations = form.locations.map(
            location => required('Location')(location.locationId) || undefined
        )
        , result: FloorPlanFormValidation = {...generalValidationResult, locations }

    return result
}

export default validate
