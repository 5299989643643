import type { ValidationResult} from '_/utils/form/validate'
import { maxLength, isEmpty, allowedName } from '_/utils/form/validate'
import type { OrganismIdentification } from '_/model/predefined-lists/organism-identification/types'
import { IDENTIFICATION_TYPE } from '_/model/sample/identification-type'
import type { AllIdentifications } from '_/model/predefined-lists/identifications/identifications'
import { SYSTEM_WORDS } from '_/constants/system-words'

export default function validate(entity: Partial<OrganismIdentification>, allIdentifications: AllIdentifications) {
    const name = isEmpty('Name')(entity.name)
        , nameLength = maxLength('Name', 100)(entity.name)
        , notAllowedNames = allowedName('Name')(entity.name)
        , notAllowedOrganism = notAllowedOrganismName(entity.name, allIdentifications)
        , result: ValidationResult<OrganismIdentification> = {}

    if (name)
        result.name = name

    if (nameLength)
        result.name = nameLength

    if (notAllowedNames)
        result.name = notAllowedNames

    if (notAllowedOrganism)
        result.name = notAllowedOrganism

    return result
}

function notAllowedOrganismName(value: string | undefined, allIdentifications: AllIdentifications) {
    const allOrganismTypes = allIdentifications.organismType.map(_ => _.name)
        , allCatalase = allIdentifications.catalase.map(_ => _.name)
        , allOxidase = allIdentifications.oxidase.map(_ => _.name)
        , allOxidationFermentation = allIdentifications.oxidationFermentation.map(_ => _.name)
        , allCoagulase = allIdentifications.coagulase.map(_ => _.name)
        , allIdentificationTypes = IDENTIFICATION_TYPE.map(_ => _.name)
        , allProhibitedNames = allIdentificationTypes.concat(allOrganismTypes, allCatalase, allOxidase, allOxidationFermentation, allCoagulase, SYSTEM_WORDS)
        , genusName = value?.trim().split(' ')[0]

    return allProhibitedNames.some(_ => _.toLowerCase() === genusName?.toLowerCase())
        ? `Genus cannot be "${genusName}"`
        : false
}
