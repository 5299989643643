import type { SetPassword, UserSignUp, ConfirmEmailRequest, EmailConfirmation } from '_/model/auth/types'
import type { ChangePassword, ResetPassword } from '_/model/users/types'
import type User from '_/model/users/types'
import type Page from '_/model/page'

import type ApiService from '../api-service'
import type UserService from '../user-service'
import { downloadBlob } from '_/utils/blob'
import { CHANGING_SETTINGS } from '_/model/context/electronic-signature-settings'

function factory(api: ApiService): UserService {
    const resource = api.resource(['users'], CHANGING_SETTINGS)
    return Object.assign(
        resource,
        {
            save: resource.saveWithReason,
            signUp,
            confirmEmail,
            forgotPassword,
            setPassword,
            changePassword,
            downloadAccountData,
            confirmChangedPassword,
            searchContextUsersByEmail,
            getResetPassword,
            getEmailConfirmation,
        }
    )

    function signUp(user: UserSignUp): Promise<void> {
        return api.post(['sign-up'], user)
    }

    function confirmEmail(tokenAndPassword: ConfirmEmailRequest): Promise<void> {
        return api.post(['confirm-email', tokenAndPassword.token], tokenAndPassword)
    }

    function confirmChangedPassword(token: string): Promise<void> {
        return api.post(['change-password', token])
    }

    function setPassword(token: string, password: SetPassword): Promise<void> {
        return api.post(['set-password', token], password)
    }

    function forgotPassword(email: string): Promise<void> {
        return api.post(['forgot-password'], email)
    }

    function changePassword(userData: ChangePassword): Promise<void> {
        return api.post(['users', userData.id, 'change-password'], { password: userData.newPassword })
    }

    function downloadAccountData(): Promise<void> {
        return api.getFile(['users', 'my-data']).then(
            _ => downloadBlob(_.blob, _.filename)
        )
    }

    function searchContextUsersByEmail(query: { contextId: string, email: string }): Promise<Page<User>> {
        return api.resource(['users-search']).list({ contextId: query.contextId, email: query.email })
    }

    function getResetPassword(token: string): Promise<ResetPassword> {
        return api.get(['reset-password-request', token])
    }

    function getEmailConfirmation(token: string): Promise<EmailConfirmation> {
        return api.get(['confirm-email-request', token])
    }
}

export default factory
