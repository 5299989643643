import { useEffect, useAction, useSelector, useState } from '_/facade/react'
import type { ExistedSampleSearchFilter } from '_/model/sample/search'
import type { AnalysisSearchFilter } from '_/model/analysis/filter/types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import Button from '_/components/button'
import * as deletionActions from '_/features/confirmation/actions'
import type { ActionCreator } from '_/utils/redux'

interface Props {
    isOpen: boolean
    onClose: () => void
    onApplyFilter: (filterId: string) => void
    loadFiltersAction: ActionCreator<void, Promise<void>>
    removeFilterAction: ActionCreator<string, Promise<void>>
    isAnalysisFilters?: boolean
}

function FilterListModal(props: Props) {
    const filters = useSearchFilters(props.loadFiltersAction, props.isAnalysisFilters)
        , [currentSearchFilter, setCurrentSearchFilter] = useState<ExistedSampleSearchFilter | AnalysisSearchFilter | undefined>()
        , selectedId = currentSearchFilter?.id
        , handleOpenDeletionModal = useRemove(setCurrentSearchFilter, props.removeFilterAction)

    function handleApplyFilter() {
        if (currentSearchFilter) {
            props.onApplyFilter(currentSearchFilter.id)
            props.onClose()
        }
    }

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalHeader>
                Load search filter
            </ModalHeader>
            <ModalBody>
                {filters.length === 0
                    ? <span>No filters to load</span>
                    : <table className='table table-striped table-borderless'>
                        <tbody>
                            {filters.map((_, i) =>
                                <tr
                                    key={_.id}
                                    className={_.id === selectedId ? 'table-primary' : ''}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setCurrentSearchFilter(_)}
                                    data-testid={`modal-filter-${i}`}
                                >
                                    <td style={{ width: '100%' }} data-testid={`modal-filter-name-${i}`}>
                                        {_.name}
                                    </td>
                                    <td style={{ width: 0 }}>
                                        <div className='clearfix'>
                                            <span
                                                className='close text-danger'
                                                onClick={() => handleOpenDeletionModal(_)}
                                                data-testid={`modal-remove-filter-${i}`}
                                            >
                                                &times;
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
            </ModalBody>
            <ModalFooter>
                <Button className='btn-secondary' onClick={props.onClose} testId='modal-close'>
                    Cancel
                </Button>
                <Button className='btn-primary' onClick={handleApplyFilter} disabled={selectedId === undefined} testId='modal-load-filter'>
                    Load filter
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default FilterListModal

function useSearchFilters(loadFiltersAction: ActionCreator<void, Promise<void>>, isAnalysisFilters: boolean | undefined) {
    const loadFilterList = useAction(loadFiltersAction)
        , filters = useSelector(_ => isAnalysisFilters ? _.analysis.analysisSearchFilters : _.samples.sampleSearchFilters)

    useEffect(() => { loadFilterList() }, [loadFilterList])

    return filters
}

function useRemove(setCurrentSearchFilter: (_: ExistedSampleSearchFilter | undefined) => void, removeFilterAction: ActionCreator<string, Promise<void>>) {
    const showDeletionWarning = useAction(deletionActions.showDeletionConfirmationModal)
        , removeFilter = useAction(removeFilterAction)

    function handleOpenDeletionModal(currentSearchFilter: ExistedSampleSearchFilter) {
        showDeletionWarning(`Are you sure you want to delete ${currentSearchFilter.name}?`)
            .then(() => removeFilter(currentSearchFilter.id))
            .then(() => setCurrentSearchFilter(undefined))
    }
    return handleOpenDeletionModal
}
