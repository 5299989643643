import type { UserEdit, ChangeUserRole } from '_/model/users/types'

import { diffObject } from '_/utils/object'
import type ApiUserService from '_/services/user-service'
import type ApiMembershipService from '_/services/membership-service'

import type * as s from '../context/electronic-signature-settings'

function updateTransaction(
    id: string,
    current: UserEdit,
    next: UserEdit,
    api: ApiUserService,
): Promise<UserEdit | void> {
    const diff = diffObject(current, next)

    if (!diff)
        return Promise.resolve()

    if (diff.notConfirmedEmail)
        next.emailConfirmed = false

    return  api.save(id, diff)
        .then(() => next)
}

function changeRoleTransaction(role: ChangeUserRole, signatureSettings: s.ElectronicSignatureSettings[], api: ApiMembershipService) {
    const { membershipId, userId } = role
        , changeRoleProps = { roleId: role.role.id, membershipId, userId }

    return api.changeRole({...changeRoleProps }, signatureSettings)
}

export {
    updateTransaction,
    changeRoleTransaction,
}
