import { classnames } from '_/facade/react'
import { Link } from '_/utils/router'
import * as routes from '_/constants/routes'
import type * as nt from '_/model/notification/types'
import { paramsFilter } from '_/model/filters/helpers'
import { MISSING } from '_/model/scheduling/monitoring-overview/monitoring-overview-sample-statuses'
import FormattedText from '_/features/text/formatted-text'
import { formatActiveState } from '_/utils/format/common'
import { defaultTextNode } from '_/model/text/text'

interface Props {
    notification: nt.MissedMonitoringExpectationNotification
    onClick: () => void
    dropdownLayout: boolean
}

function MissedMonitoringExpectationNotification(props: Props) {
    const data = props.notification.data

    function handleClick(e: React.MouseEvent) {
        e.preventDefault()
        props.onClick()
    }

    return (
        <>
            <Link
                routeName={routes.SCHEDULING_MONITORING_OVERVIEW}
                routeParams={paramsFilter({ date: data.date, statuses: [MISSING] })}
                className={classnames('cursor-pointer', { 'text-white': props.dropdownLayout })}
                onClick={handleClick}
            >
                <div className='fw-bold'>
                    Monitoring expectation {' '}
                    <FormattedText text={[
                        ...formatActiveState(data.monitoringGroup.name, data.monitoringGroup.isActive),
                        defaultTextNode(' - '),
                        ...formatActiveState(data.session.name, data.session.isActive),
                        defaultTextNode(' - '),
                        ...formatActiveState(data.location.name, data.location.isActive),
                    ]} />
                    {' '}has been set to 'missed'
                </div>
            </Link>
            <div className='text-secondary'>{props.notification.contextName}</div>
        </>
    )
}

export default MissedMonitoringExpectationNotification
