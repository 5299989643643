import { Table } from '_/components/table'
import PageHeader from '_/components/page-header'

import type {
    FingerdabOverviewByOperatorItems,
} from '_/model/fingerdab-overview-by-operator/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { SampleSession } from '_/model/predefined-lists/session/types'

import {
    calculateSamplesCountForSession,
    calculateSamplesCountWithoutSession,
} from '../helpers'

import SampleCellData from '../shared/sample-cell-data'
import { useExposureLocations } from '_/hooks/shared-hooks'
import { fullNameLocationList } from '_/utils/exposure-location'
import FormattedText from '_/features/text/formatted-text'
import { systemTextNode } from '_/model/text/text'
import { NO_OPERATOR } from '_/constants/system-words'
import NoDataAvailable from '_/features/reports/no-data-available'

interface OwnProps {
    operatorsWithFingerdabs: FingerdabOverviewByOperatorItems[]
    sampleSession: SampleSession[]
    customFields: CustomField[]
    sampleTypesIncludingDeleted: SampleType[]
    showSpinner: boolean
}

function OperatorsWithFingerdabs(props: OwnProps) {
    const locations = fullNameLocationList(useExposureLocations())
    function getColumnHeader(items: FingerdabOverviewByOperatorItems[], name: string) {
        const count = calculateSamplesCountForSession(items, name)
        return `Session ${name} (${count} viable sample${count !== 1 ? 's' : ''})`
    }

    const samplesWithoutSessionCount = calculateSamplesCountWithoutSession(props.operatorsWithFingerdabs, props.sampleSession)

    return (
        <div className='daily-fingerdabs-content-container'>
            <PageHeader
                sticky
                title='Operators with fingerdabs'
                className='fingerdab-overview--z-index-999'
            />
            <Table>
                <thead className='thead table-header--sticky table-header--default-offset'>
                    <tr className='table-header-thin'>
                        <th style={{ width: '7em' }}>Operator</th>
                        {props.sampleSession.map(_ =>
                            <th key={_.id}>
                                {getColumnHeader(props.operatorsWithFingerdabs, _.name)}
                            </th>
                        )}
                        <th>
                            <FormattedText
                                text={[systemTextNode(`Other viable samples (${samplesWithoutSessionCount} viable sample${samplesWithoutSessionCount !== 1 ? 's' : ''})`)]}
                                modifier='table-header'
                            />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.operatorsWithFingerdabs.length === 0 || props.showSpinner
                        ? <tr>
                            <td
                                className='text-center'
                                colSpan={props.sampleSession.length + 2}
                            >
                                <NoDataAvailable showSpinner={props.showSpinner}>
                                    <span className='text-muted text-uppercase'>
                                        No fingerdabs recorded on this day
                                    </span>
                                </NoDataAvailable>
                            </td>
                        </tr>
                        : props.operatorsWithFingerdabs.map((item, index) =>
                            <tr key={index}>
                                <td><FormattedText text={item.operatorName ?? [systemTextNode(NO_OPERATOR)]} /></td>

                                {item.samples.map(
                                    (samples, index) =>
                                        <SampleCellData
                                            key={index}
                                            samples={samples}
                                            customFields={props.customFields}
                                            index={index}
                                            sampleTypesIncludingDeleted={props.sampleTypesIncludingDeleted}
                                            locations={locations}
                                        />
                                )}
                            </tr>
                    )}
                </tbody>
            </Table>
        </div>
    )
}

export default OperatorsWithFingerdabs
