import type { RouteName } from '_/constants/routes'
import type { UserMembership } from '_/model/auth/types'
import { calcPermissions } from '_/model/permissions/helpers'
import { canAccessState } from '_/features/routing/helpers'
import * as entityType from '_/constants/entity-type'
import * as routes from '_/constants/routes'
import { SETTLE_PLATE } from '_/constants/plate-type'
import type TimeService from '_/services/time-service'
import type { DataExportNotification, NotificationEntityAccessibilityResult, SampleLimitBreachedNotificationData } from './types'
import { ENTITY_TYPE } from '_/constants/entity-type'
import * as notificationType from './notification-type'
import type Notification from '_/model/notification/types'
import * as f from '_/model/sample/format'
import * as t from '_/model/text/text'
import type { DateTime } from '../date-time'
import * as et from './export-type'
import { paramsFilter } from '../filters/helpers'
import { MISSING, SCHEDULED_NOT_BOOKED_IN } from '../scheduling/monitoring-overview/monitoring-overview-sample-statuses'

function getEntityTypeName(notification: Notification): string {
    switch (notification.type) {
        case notificationType.COMPROMISED:
        case notificationType.ACTION:
        case notificationType.ALERT:
        case notificationType.TAGGED_USER:
            return ENTITY_TYPE.find(_ => _.id === entityType.SAMPLE)!.name
        case notificationType.MISSED_MONITORING:
        case notificationType.MISSED_MONITORING_EXPECTATION:
            return ENTITY_TYPE.find(_ => _.id === entityType.MONITORING_OVERVIEW)!.name
        case notificationType.DATA_EXPORT: {
            const entityName = dataExportNotificationParams(notification).entityName
            return entityName[0].toUpperCase() + entityName.substring(1)
        }
        case notificationType.TREND_IDENTIFIED:
            return ENTITY_TYPE.find(_ => _.id === entityType.TREND)!.name
    }
}

function getRoute(notification: Notification): RouteName {
    switch (notification.type) {
        case notificationType.COMPROMISED:
        case notificationType.ACTION:
        case notificationType.ALERT:
        case notificationType.TAGGED_USER:
            return routes.SAMPLES_EDIT
        case notificationType.MISSED_MONITORING:
        case notificationType.MISSED_MONITORING_EXPECTATION:
            return routes.SCHEDULING_MONITORING_OVERVIEW
        case notificationType.DATA_EXPORT:
            return dataExportNotificationParams(notification).routeName
        case notificationType.TREND_IDENTIFIED:
            return routes.TRENDS_ALL_TRENDS
    }
}

function getRouteParams(notification: Notification) {
    switch (notification.type) {
        case notificationType.COMPROMISED:
        case notificationType.ACTION:
        case notificationType.ALERT:
        case notificationType.TAGGED_USER:
            return { id: notification.data.entityId }
        case notificationType.MISSED_MONITORING:
            return paramsFilter({ date: notification.data.missDate, statuses: [SCHEDULED_NOT_BOOKED_IN] })
        case notificationType.MISSED_MONITORING_EXPECTATION:
            return paramsFilter({ date: notification.data.date, statuses: [MISSING] })
        case notificationType.DATA_EXPORT:
            return dataExportNotificationParams(notification).routeParams
        case notificationType.TREND_IDENTIFIED:
            return {}
    }
}

function dataExportNotificationParams(notification: DataExportNotification): { entityName: string, routeName: routes.RouteName, routeParams: any} {
    switch (notification.data.exportType) {
        case et.SAMPLES_EXPORT:
            return { entityName: 'sample', routeName: routes.SAMPLES_EXPORT, routeParams: {}}
        case et.SITE_EXPORT:
            return { entityName: 'site', routeName: routes.SETTINGS_CONTEXTS_EXPORT, routeParams: { id: notification.contextId }}
        case et.CC_SAMPLES_EXPORT:
            return { entityName: 'plate', routeName: routes.PLATES_EXPORT, routeParams: {}}
    }
}

function getEntityRelatedMemberships(memberships: UserMembership[], entityContextId: string) {
    return memberships.filter(_ => _.contextId === entityContextId)
}

function getMembershipsWithEnabledPermissions(memberships: UserMembership[], route: RouteName) {
    return memberships.filter(_ => canAccessNotification(route, _))
}

function validateEntityAccessibility(memberships: UserMembership[], route: RouteName, entityContextId: string): NotificationEntityAccessibilityResult {
    const entityRelatedMemberships = getEntityRelatedMemberships(memberships, entityContextId)
        , membershipsWithPermissions = getMembershipsWithEnabledPermissions(entityRelatedMemberships, route)

    if (entityRelatedMemberships.length === 0)
        return { type: 'no-membership' }

    if (membershipsWithPermissions.length === 0)
        return { type: 'no-permission' }

    return { type: 'memberships', memberships: membershipsWithPermissions }
}

function canAccessNotification(route: RouteName, membership: UserMembership) {
    return canAccessState(route, calcPermissions(membership))
}

function formatExposureDuration(timeService: TimeService, data: SampleLimitBreachedNotificationData) {
    const hasEndTime = data.plateType === SETTLE_PLATE || data.endTimeFieldRequired

    function getExposureTime(dateTime: DateTime | undefined, timeNotRecorded: boolean) {
        const { date, time } = timeService.splitCtzDateTime(dateTime)
        return { time, timeNotRecorded, date }
    }

    const text = f.formatExposureDuration(
        getExposureTime(data.exposureStartTime, data.exposureStartTimeNotRecorded),
        hasEndTime && getExposureTime(data.exposureEndTime, data.exposureEndTimeNotRecorded),
        timeService
    )

    return t.plainText(text)
}

export {
    getEntityTypeName,
    getRoute,
    getRouteParams,
    dataExportNotificationParams,
    validateEntityAccessibility,
    formatExposureDuration,
}
