import type { LimitBreachReport, LimitBreachReportRequest, SampleData } from '_/model/reports/limit-breach-report/types'
import type ApiService from '../api-service'
import type LimitBreachReportService from '../limit-breach-report-service'
import { convertFromServerFields } from './helper'
import type TimeService from '_/services/time-service'

function factory(api: ApiService, time: TimeService): LimitBreachReportService {
    return { getLimitBreachReport }

    function getLimitBreachReport(request: LimitBreachReportRequest): Promise<LimitBreachReport[]> {
        return api.get<LimitBreachReport[]>(['dashboard', 'breach-limit-statistic', 'daily'], request)
            .then(list => list.map(_ => {
                const convert = (samples: SampleData[]) => samples.map(_ => convertFromServerFields(time, _))

                return {
                    ..._,
                    nonViableActionLimitBreached: convert(_.nonViableActionLimitBreached),
                    nonViableAlertLimitBreached: convert(_.nonViableAlertLimitBreached),
                    viableAlertLimitBreached: convert(_.viableAlertLimitBreached),
                    viableActionLimitBreached: convert(_.viableActionLimitBreached),
                    compromised: convert(_.compromised),
                }
            }))
    }
}

export default factory
