import type TimeService from '_/services/time-service'
import { fullNameLocationList } from '_/utils/exposure-location'

import type { PredefinedLists } from '_/model/app-state'
import type { AnalysisFilter } from '_/model/analysis/filter/types'
import type { AggregationPeriod} from '_/model/analysis/aggregation-period'
import AGGREGATION_PERIOD, { DAY, MONTH, WEEK } from '_/model/analysis/aggregation-period'
import type { UserContext } from '_/model/auth/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { FilterFieldValue } from '_/model/sample/search'
import type SampleSearchFields from '_/model/sample/search'
import type ContaminationFloorPlanSeries from '_/model/floor-plan/contamination-floor-plan-series'
import type LimitBreachFloorPlanSeries from '_/model/floor-plan/limit-breach-floor-plan-series'
import type { FloorPlanLocation } from '_/model/floor-plan/floor-plan'
import type FloorPlan from '_/model/floor-plan/floor-plan'
import * as t from '_/model/text/text'

import type { SampleStatus } from '_/constants/sample-status'
import { EXPOSURE_DATE_START } from '_/constants/search-date-type'
import * as ranges from '_/constants/date-ranges'
import * as fieldIndex from '_/constants/custom-field-index'
import * as breaches from '_/constants/sample-breach-type'
import { ANY_ID, NOT_RECORDED, NOT_RECORDED_NAME, NOT_FILTERED, NOT_RECORDED_ID } from '_/constants/system-words'

import SUB_CHART_TYPE, * as subChartType from '_/model/analysis/sub-chart-type'
import CHART_TYPE, * as chartType from '_/model/analysis/chart-type'

import * as helpers from '../helpers'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import { concatTexts, formatActiveState } from '_/utils/format/common'
import { formatFieldLabel, getFieldNotRecorded, getFieldValue } from '_/features/samples/helpers'
import { customFieldName, isDefaultCustomField } from '_/model/predefined-lists/custom-field/custom-field'
import { NUMBER, SELECTION, TEXT } from '_/constants/custom-field-column-type'
import { isActiveCustomField, recalculateDynamicExposureDate } from '_/features/analysis/filter/helpers'
import { calculateLinkParams, calculateStartDate, calculateTimeSliderDate } from '_/model/floor-plan/time-slider'
import type { ChartMetadata } from '_/model/analysis/chart-metadata'
import type {
    GraphData,
    OrganismsBreakdownSeriesGraphData,
    ContaminationFloorPlanSeriesGraphData,
    LimitBreachFloorPlanSeriesGraphData,
} from '_/model/analysis/types'
import { NO_SESSION, NO_SESSION_ID } from '_/model/predefined-lists/session/no-session'
import type { OrganismIdentification } from '_/model/predefined-lists/organism-identification/types'
import { MONITORING_STATE } from '_/model/predefined-lists/action-alert-limit/monitoring-state'
import { PARTICLE_ANALYSIS_STATE } from '_/model/predefined-lists/action-alert-limit/non-viable-limit-type'
import { areArraysEqual } from '_/utils/array'
import { getAllLocationChildren } from '_/features/samples/booking/helpers'
import type { DateSeriesGraphData } from '_/model/analysis/date-series'
import * as dt from '_/model/date-time'
import { isSystemId } from '_/model/predefined-lists/custom-field/custom-field'
import type SortState from '_/model/sort-state'

const LINE_WIDTH = 150

function getAnalysisMetadata(filter: CommonMetadataProps): ChartMetadata {
    const commonMetadata = getCommonMetadata(filter)
        , currentChartType = CHART_TYPE.find(_ => _.id === commonMetadata.filter.chartType)
        , currentSubChartType = SUB_CHART_TYPE.find(_ => _.id === filter.analysisFilter.subChartType)
        , chartName = currentSubChartType ? currentSubChartType.name : currentChartType?.name
        , title = helpers.splitStringIntoFewLines(`${chartName ?? ''} ${selectedSeriesTypeTitle(commonMetadata.filter, filter.predefinedLists.customFields)}`, 90)
        , subtitle = `${formatExposureDateRange(filter.timeService, commonMetadata.filter.exposureStartDateFrom, commonMetadata.filter.exposureStartDateTo)}${commonMetadata.aggregationPeriodName}. Filters are applied (see below)`

    return {
        ...commonMetadata,
        title,
        subtitle,
        xAxisTitle: filter.analysisFilter.aggregationPeriod === WEEK && isAggregationAllowed(filter.analysisFilter.chartType)
            ? 'Date (week commencing)'
            : undefined
    }
}

function getFloorPlanMetadata(filter: FloorPlanMetadataProps): ChartMetadata {
    const commonMetadata = getCommonMetadata(filter)
        , title = helpers.splitStringIntoFewLines(t.plainText(helpers.formatFloorPlanName(filter.floorPlan)), 90)
        , {exposureStartDateFrom, exposureStartDateTo, timeSliderPosition, aggregationPeriod, cumulativeView } = commonMetadata.filter
        , timeSliderDate = calculateTimeSliderDate(exposureStartDateFrom, exposureStartDateTo, aggregationPeriod, filter.timeService, timeSliderPosition)
        , startDate = calculateStartDate(aggregationPeriod, exposureStartDateFrom, timeSliderDate, cumulativeView ?? true, filter.timeService)
        , subtitle = `${formatExposureDateRange(filter.timeService, startDate, timeSliderDate)}${commonMetadata.aggregationPeriodName}. Filters are applied (see below)`

    return {
        author: commonMetadata.author,
        footer: commonMetadata.footer,
        footerPlainText: commonMetadata.footerPlainText,
        reportName: commonMetadata.reportName,
        title,
        subtitle,
    }
}

function getCommonMetadata(metadataFilter: CommonMetadataProps) {
    const filter = {
            ...metadataFilter.analysisFilter,
            ...recalculateDynamicExposureDate(metadataFilter.analysisFilter, metadataFilter.timeService),
        }
        , currentChartType = CHART_TYPE.find(_ => _.id === filter.chartType)
        , aggregationPeriodName = formatAggregationPeriodName(currentChartType?.id, filter.aggregationPeriod)
        , author = computeAuthorInfo(metadataFilter.timeService, metadataFilter.user)
        , footer = computeFilters(filter, metadataFilter.predefinedLists, metadataFilter.organisms)

    return {
        author,
        footer,
        footerPlainText: t.plainText(footer).split(/\n/g).map(_ => helpers.splitStringIntoFewLines(_, LINE_WIDTH)).flatMap(_ => _),
        reportName: metadataFilter.reportName,
        aggregationPeriodName,
        filter,
        currentChartType,
    }
}

function formatAggregationPeriodName(currentChartType: chartType.ChartType | undefined, period: AggregationPeriod | undefined) {
    if (!period || currentChartType === chartType.ORGANISMS_BREAKDOWN_CHART)
        return ''

    const name = AGGREGATION_PERIOD.find(_ => _.id === period)?.name.toLowerCase()
    return name ? ` ${name}` : ''
}

function formatExposureDateRange(timeService: TimeService, from: dt.DateTime | undefined, to: dt.DateTime | undefined) {
    const startDate = from ? timeService.formatCtzDate(from) : ''
        , endDate = to ? timeService.formatCtzDate(to) : ''

    return `${startDate} to ${endDate}`
}

function graphSeriesName(seriesFieldIndex: number | undefined, fields: CustomField[]) {
    if (seriesFieldIndex === undefined)
        return ''

    switch (seriesFieldIndex) {
        case fieldIndex.EXPOSURE_LOCATION_GRADE_ID:
            return 'Exposure location grade'
        case fieldIndex.IDENTIFICATION:
            return 'Organisms'
        case fieldIndex.MONITORING_STATE:
            return 'Monitoring state'
        case fieldIndex.PARTICLES_STATE:
            return 'Particle size'
    }

    return customFieldName(seriesFieldIndex, fields)
}

function selectedSeriesTypeTitle(filter: AnalysisFilter, fields: CustomField[]) {
    if(filter.seriesFieldIndex === undefined)
        return ''

    const name = graphSeriesName(filter.seriesFieldIndex, fields)
        , showSeriesName = filter.chartType !== chartType.ORGANISMS_BREAKDOWN_CHART
            && filter.chartType !== chartType.ORGANISM_TYPE_BREAKDOWN
            && filter.chartType !== chartType.STACKED_SAMPLE_NAME

    return name && showSeriesName ? `by ${name}` : ''
}

function computeAuthorInfo(timeService: TimeService, user: UserContext | undefined) {
    const currentDate = timeService.now()

    return user
        ? `Generated by ${user.name} (${user.email}) at ${timeService.formatCtzTime(currentDate)} on ${timeService.formatCtzDate(currentDate)}`
        : ''
}

function computeFilters(filter: AnalysisFilter, predefinedLists: PredefinedLists, organismsList: OrganismIdentification[]): t.Text {
    const locations = getFieldValue(filter.fields, fieldIndex.EXPOSURE_LOCATION_ID)
        , sampleTypes = getFieldValue(filter.fields, fieldIndex.SAMPLE_TYPE_ID)
        , sessions = getFieldValue(filter.fields, fieldIndex.SESSION_ID)
        , operators = getFieldValue(filter.fields, fieldIndex.OPERATORS_IDS)
        , batchNumbers = getFieldValue(filter.fields, fieldIndex.BATCH_NUMBER)
        , locationList = fullNameLocationList(predefinedLists.exposureLocations)
        , formattedLocationNames = formatLocationNames(locations || [], locationList)
        , filtersHeader: t.Text = [t.defaultTextNode(`${'<b>' + 'Filters' + '</b>'}\n`)]
        , locationsText = [t.defaultTextNode('Exposure locations: '), ...computeLocationsTitle(formattedLocationNames, locationList.length - 1, isNotRecordedSelected(locations))]
        , locationGradesText = [t.defaultTextNode('\nExposure location grades: '), ...computeListTitle(filter.gradeIds, predefinedLists.grades, 'all grades', 'any grades')]
        , sampleTypesText = [t.defaultTextNode('\nSample names: '), ...computeListTitle(sampleTypes, predefinedLists.sampleTypes, 'all sample names')]
        , sessionsText = [t.defaultTextNode('\nSessions: '), ...computeSampleSessionsTitle(sessions || [], predefinedLists)]
        , operatorsText = [t.defaultTextNode('\nOperators: '), ...computeListTitle(operators, predefinedLists.sampleOperators, 'all operators', 'any operators')]
        , batchNumbersText = [t.defaultTextNode('\nBatch numbers: '), ...computeBatchNumbersTitle(batchNumbers || [])]
        , organismsText = [t.defaultTextNode('\nOrganisms: '), ...computeOrganismsTitle(filter.organismIds || [], organismsList)]

        , organismTypeText = [t.defaultTextNode('\nOrganism type tests: '), ...computeListTitle(filter.organismTypeIds, predefinedLists.identifications.organismType, 'all organism type tests', 'any organism type tests')]
        , catalaseText = [t.defaultTextNode('\nCatalase tests: '), ...computeListTitle(filter.catalaseIds, predefinedLists.identifications.catalase, 'all catalase tests', 'any catalase tests')]
        , oxidaseText = [t.defaultTextNode('\nOxidase tests: '), ...computeListTitle(filter.oxidaseIds, predefinedLists.identifications.oxidase, 'all oxidase tests', 'any oxidase tests')]
        , oxidationFermentationText = [t.defaultTextNode('\nOxidation fermentation tests: '), ...computeListTitle(filter.oxidationFermentationIds, predefinedLists.identifications.oxidationFermentation, 'all oxidation fermentation tests', 'any oxidation fermentation tests')]
        , coagulaseText = [t.defaultTextNode('\nCoagulase tests: '), ...computeListTitle(filter.coagulaseIds, predefinedLists.identifications.coagulase, 'all coagulase tests', 'any coagulase tests')]
        , showOnlyObjectionableText = filter.showOnlyObjectionable ? [t.defaultTextNode('\nShow only samples with objectionable organisms')] : [t.emptyTextNode()]
        , monitoringStateText = [t.defaultTextNode('\nMonitoring state: '), ...computeListTitle(filter.monitoringStates, MONITORING_STATE, 'all monitoring states')]
        , particleStateText = [t.defaultTextNode('\nParticle sizes: '), ...computeListTitle(filter.particleStates, PARTICLE_ANALYSIS_STATE, 'all particle sizes')]
        , compromisedText = [t.defaultTextNode(`\n${filter.includeCompromised ? 'Includes' : 'Excludes'} compromised samples`)]
        , totalSamplesBarText = filter.includeTotalSamples ? [t.defaultTextNode('\nTotal samples bar axis: included')] : [t.emptyTextNode()]
        , isNonViable = filter.chartType === chartType.PARTICLE_COUNTS
        , customFieldValuesText = predefinedLists.customFields
            .filter(_ => !isDefaultCustomField(_) && isActiveCustomField(_, isNonViable))
            .map(_ => formatCustomFieldValues(_, filter))
            .flatMap(_ => _)

    const filters = filtersHeader.concat(
            locationsText,
            locationGradesText,
            ...(isNonViable ? [] : [sampleTypesText]),
            sessionsText,
            operatorsText,
            batchNumbersText,
            ...(isNonViable ? [particleStateText] : [
                organismsText,
                organismTypeText,
                catalaseText,
                oxidaseText,
                oxidationFermentationText,
                coagulaseText,
                showOnlyObjectionableText
            ]),
            monitoringStateText,
            customFieldValuesText,
            compromisedText,
            totalSamplesBarText,
        )

    return filters
}

function formatCustomFieldValues(field: CustomField, filter: AnalysisFilter): t.Text {
    const filterField = filter.fields?.find(f => f.index === field.index) ?? { index: field.index, value: undefined }
        , fieldLabelText = t.defaultTextNode(`\n${formatFieldLabel(field)}: `)

    if (field.fieldType === NUMBER) {
        if (filterField.notRecorded)
            return [fieldLabelText, t.systemTextNode(NOT_RECORDED_NAME)]

        if (!filterField.value || filterField.value.from === undefined && filterField.value.to === undefined)
            return [fieldLabelText, t.systemTextNode(NOT_FILTERED)]

        return [fieldLabelText, t.defaultTextNode(`from ${filterField.value.from} to ${filterField.value.to}`)]
    }

    if (field.fieldType === TEXT) {
        const values: string[] = filterField.value ?? []

        if (values.length > 0)
            return [fieldLabelText, ...concatTexts(values.map(_ => [_ === NOT_RECORDED_NAME ? t.systemTextNode(NOT_RECORDED_NAME) : t.defaultTextNode(_)]))]

        return [fieldLabelText, t.systemTextNode(NOT_FILTERED)]
    }

    if (field.fieldType === SELECTION) {
        const valuesIds: string[] = filterField.value || []
            , values = [{ ...NOT_RECORDED, isActive: true }].concat(field.selectionFieldValues ?? [])
            , notRecordedSelected = valuesIds.some(_ => _ === NOT_RECORDED_ID)

        if (valuesIds.length === 0)
            return [fieldLabelText, t.systemTextNode(NOT_FILTERED)]

        if (field.selectionFieldValues?.length === values.length && !notRecordedSelected)
            return [fieldLabelText, t.defaultTextNode(`all ${field.fieldName}`)]

        return [fieldLabelText, ...getCommaSeparatedNames(valuesIds, values)]
    }

    return [t.emptyTextNode()]
}

function formatLocationNames(exposureLocationIds: string[], exposureLocationList: ListExposureLocation[]): t.Text[] {
    return exposureLocationIds.map(id => {
        const location = exposureLocationList.find(_ => _.id === id)
        if (id === NOT_RECORDED_ID)
            return [t.systemTextNode(NOT_RECORDED.name)]

        if (!location)
            return [t.emptyTextNode()]

        return formatActiveState(location.pathName, location.isActive)
    })
}

function computeListTitle(ids: (number | string)[] = [], entities: { id: (number | string), name: string}[], allTitle: string, anyTitle?: string): t.Text {
    if (anyTitle && ids.some(_ => _ === ANY_ID))
        return [t.defaultTextNode(anyTitle)]

    if (ids.length == 0)
        return [t.systemTextNode(NOT_FILTERED)]

    if (ids.length === entities.length && !isNotRecordedSelected(ids))
        return [t.defaultTextNode(allTitle)]

    return getCommaSeparatedNames(ids, entities.concat(NOT_RECORDED))
}

function computeSampleSessionsTitle(sessionIds: string[], predefinedLists: PredefinedLists): t.Text {
    const isNoSessionSelected = sessionIds.some(_ => _ === NO_SESSION_ID)

    if (sessionIds.length === 0)
        return [t.systemTextNode(NOT_FILTERED)]

    if (sessionIds.length === predefinedLists.sampleSessions.length && !isNoSessionSelected && !isNotRecordedSelected(sessionIds))
        return [t.defaultTextNode('all sessions')]

    return getCommaSeparatedNames(sessionIds, [NO_SESSION, NOT_RECORDED].concat(predefinedLists.sampleSessions))
}

function computeBatchNumbersTitle(batchNumbers: string[]) {
    if (batchNumbers.length === 0)
        return [t.systemTextNode(NOT_FILTERED)]

    return concatTexts(batchNumbers.map(_ => [_ === NOT_RECORDED_NAME ? t.systemTextNode(_) : t.defaultTextNode(_)]))
}

function computeLocationsTitle(exposureLocations: t.Text[], locationsTotalCount: number, isNotRecordedSelected: boolean): t.Text {
    const locations = exposureLocations.length === 0
        ? [t.systemTextNode(NOT_FILTERED)]
        : locationsTotalCount === exposureLocations.length && !isNotRecordedSelected
            ? [t.defaultTextNode('all locations')]
            : concatTexts(exposureLocations)

    return locations
}

function computeOrganismsTitle(identifications: string[], organisms: OrganismIdentification[]) {
    if (identifications.length == 0)
        return [t.systemTextNode(NOT_FILTERED)]

    return getCommaSeparatedNames(identifications, organisms)
}

function isNotRecordedSelected(ids: (number | string)[] = []) {
    return ids.some(_ => _ === NOT_RECORDED_ID)
}

function getCommaSeparatedNames<T extends { id: string | number, name: string, isActive?: boolean }>(ids: (string | number)[], entities: T[]): t.Text {
    const names = ids.map(id => {
        const entity = entities.find(_ => _.id === id)

        if (entity)
            return isSystemId(id) ? [t.systemTextNode(entity.name)] : formatActiveState(entity.name, entity.isActive)
    })

    return concatTexts(names.filter((_: t.Text | undefined): _ is t.Text => _ != undefined))
}

type ExposureDateRange = Pick<AnalysisFilter, 'exposureDateRange' | 'exposureStartDateFrom' | 'exposureStartDateTo'>

function computeExposureDateRange(timeService: TimeService, currentChartType: chartType.ChartType, currentToDate?: dt.DateTime): ExposureDateRange {
    const dateTo = currentToDate ?? timeService.ctzDayStart(timeService.now())
        , dateFrom = getDateFrom()

    function getDateFrom() {
        switch(currentChartType) {
            case chartType.ORGANISMS_BREAKDOWN_CHART:
                return helpers.getLast28DaysDate(timeService, dateTo)
            case chartType.PARTICLE_COUNTS:
                return timeService.addCtzDays(timeService.now(), -6)
            default:
                return timeService.addCtzYears(dateTo, -1)
        }
    }

    function getExposureDateRange() {
        switch(currentChartType) {
            case chartType.ORGANISMS_BREAKDOWN_CHART:
                return ranges.MONTH
            case chartType.PARTICLE_COUNTS:
                return ranges.WEEK
            default:
                return ranges.YEAR
        }
    }

    return {
        exposureStartDateFrom: dateFrom,
        exposureStartDateTo: timeService.ctzDayEnd(dateTo),
        exposureDateRange: getExposureDateRange()
    }
}

function computeSeriesFieldIndex(currentChartType: chartType.ChartType) {
    return currentChartType === chartType.PARTICLE_COUNTS
            ? fieldIndex.PARTICLES_STATE
            : fieldIndex.EXPOSURE_LOCATION_ID
}

function isAggregationAllowed(currentChartType: chartType.ChartType | undefined) {
    return currentChartType !== chartType.PARTICLE_COUNTS
}

function isExposureDateRangeModified(latestFilter: ExposureDateRange, initialFilter: ExposureDateRange) {
    return latestFilter.exposureDateRange !== initialFilter.exposureDateRange
        || !dt.equals(latestFilter.exposureStartDateFrom, initialFilter.exposureStartDateFrom)
        || !dt.equals(latestFilter.exposureStartDateTo, initialFilter.exposureStartDateTo)
}

function getLocationChildIds(exposureLocationList: ListExposureLocation[], locationName: string) {
    const location = exposureLocationList
        .find(_ => t.plainText(formatActiveState(_.pathName, _.isActive)) === locationName)

    if (!location)
        return

    const exposureLocationNames = exposureLocationList
        .map(_ => _.pathName)
        .filter(_ => _.startsWith(location.pathName))
        .filter(_ => _ !== location.pathName)

    if (exposureLocationNames.length === 0)
        return

    const exposureLocations = exposureLocationNames
        .map(name => exposureLocationList.find(_ => _.pathName === name)!.id)

    return exposureLocations
}

function isLinesMarkersChart(type: chartType.ChartType | number | undefined): type is chartType.LinesMarkersChart {
    return type === chartType.ACTION_LIMIT_BREACHES
        || type === chartType.ALERT_LIMIT_BREACHES
        || type === chartType.AVERAGE_CFU_CHART
        || type === chartType.CONTAMINATED_SAMPLES
        || type === chartType.TOTAL_CFU_CHART
        || type === chartType.TOTAL_SAMPLES_READ
        || type === chartType.PARTICLE_COUNTS
}

function isFloorPlanChart(type: chartType.ChartType | number | undefined): type is chartType.FloorPlanChart {
    return type === chartType.CONTAMINATION_FLOOR_PLAN || type === chartType.LIMIT_BREACH_FLOOR_PLAN
}

function getFloorPlanSubtitle(timeService: TimeService, startDate: dt.DateTime, endDate: dt.DateTime, aggregationPeriod: AggregationPeriod | undefined, chartType: number) {
    return formatExposureDateRange(timeService, startDate, endDate)
        + formatAggregationPeriodName(CHART_TYPE[chartType].id, aggregationPeriod) + '. Filters are applied (see below)'
}

function getAllAvailableFloorPlanLocations(locations: ListExposureLocation[], selectedLocations: string[], floorPlans: FloorPlan[]) {
    return locations.filter(_ =>
        floorPlans.some(f => f.locations.some(l => l.locationId === _.id))
        || selectedLocations.some(sl => sl === _.id)
    )
}

function getBreachTypes(currentChartType: number | undefined) {
    if (currentChartType == chartType.ALERT_LIMIT_BREACHES)
        return [breaches.ALERT_LIMIT, breaches.ACTION_LIMIT]

    if (currentChartType == chartType.ACTION_LIMIT_BREACHES)
        return [breaches.ACTION_LIMIT]

    return undefined
}

function buildSampleListRouterParams(latestFilter: AnalysisFilter, timeService: TimeService, statuses: SampleStatus[]): SampleSearchFields & SortState {
    const filter = {
            ...latestFilter,
            ...recalculateDynamicExposureDate(latestFilter, timeService),
        }
        , isFloorPlan = isFloorPlanChart(latestFilter.chartType)
        , { exposureStartDateFrom, exposureStartDateTo } = calculateLinkParams(filter, timeService)

    return {
        fields: filter.fields,
        gradeIds: filter.gradeIds,
        includeCompromised: filter.includeCompromised,
        dateToFilter: EXPOSURE_DATE_START,
        dateFrom: isFloorPlan ? exposureStartDateFrom : filter.exposureStartDateFrom,
        dateTo: isFloorPlan ? exposureStartDateTo : filter.exposureStartDateTo,
        organismIds: filter.organismIds,
        organismTypeIds: filter.organismTypeIds,
        catalaseIds: filter.catalaseIds,
        oxidaseIds: filter.oxidaseIds,
        oxidationFermentationIds: filter.oxidationFermentationIds,
        coagulaseIds: filter.coagulaseIds,
        showOnlyObjectionable: filter.showOnlyObjectionable,
        sampleBreachTypes: getBreachTypes(filter.chartType),
        monitoringStates: filter.monitoringStates,
        statuses,
        sort: 'exposureStartTime:desc',
    }
}

function getSampleListRouteParamsForFloorPlanGraph(
    filter: AnalysisFilter,
    timeService: TimeService,
    statuses: SampleStatus[],
    floorPlans: FloorPlan[],
    allLocations: ListExposureLocation[],
) {
    const routeParams = buildSampleListRouterParams(filter, timeService, statuses)
        , selectedFloorPlan = floorPlans.find(_ => _.id === filter.floorPlanId) ?? floorPlans[0]
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!selectedFloorPlan)
        return routeParams

    const locationPlottedOnGraph = selectedFloorPlan.locations
        , selectedLocations = filter.fields?.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ID)?.value ?? []
        , activeLocations = selectedLocations.length === 0
            ? locationPlottedOnGraph
            : locationPlottedOnGraph.filter(_ => filteredLocationsExistsOnFloorPlan(selectedLocations, [_], allLocations))
        , fields = routeParams.fields?.map(_ => {
            if (_.index === fieldIndex.EXPOSURE_LOCATION_ID)
                return {..._, value: activeLocations.length === 0 ? undefined : activeLocations.map(_ => _.locationId) }
            return _
        })

    return { ...routeParams, fields }
}

function filteredLocationsExistsOnFloorPlan(
    selectedLocations: string[],
    floorPlanLocations: FloorPlanLocation[],
    locationList: ListExposureLocation[],
) {
    return selectedLocations.some(l => {
        const location = locationList.find(_ => _.id === l)
        if (!location)
            return

        const allChildren = getAllLocationChildren([location], locationList)

        return floorPlanLocations.some(_ => _.locationId === l || allChildren.some(loc => _.locationId === loc.id))
    })
}

function seriesLocationsExistsOnFloorPlan(series: (ContaminationFloorPlanSeries | LimitBreachFloorPlanSeries)[], floorPlanLocations: FloorPlanLocation[]) {
    return series.some(s => floorPlanLocations.some(_ => _.locationId === s.locationId))
}

function getFilteredFloorPlans(floorPlans: FloorPlan[], filter: AnalysisFilter, locationList: ListExposureLocation[], series?: (ContaminationFloorPlanSeries | LimitBreachFloorPlanSeries)[]) {
    const selectedLocations = filter.fields?.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ID)?.value
        , selectedGrades = filter.gradeIds
        , selectedIdentifications = filter.organismIds
        , dataFiltersApplied = filter.fields?.find(_ => {
                if (_.index === fieldIndex.EXPOSURE_LOCATION_ID)
                    return false

                if (Array.isArray(_.value))
                    return _.value.some(_ => !!_)

                return !!_.value
            })
            || selectedGrades
            || selectedIdentifications
            || !filter.includeCompromised

    return floorPlans.filter(_ => {
        if (_.locations.length === 0)
            return false

        const selectedLocationsExistOnFloorPlan = !selectedLocations
            || filteredLocationsExistsOnFloorPlan(selectedLocations, _.locations, locationList)

        if (dataFiltersApplied && series)
            return seriesLocationsExistsOnFloorPlan(series, _.locations) && selectedLocationsExistOnFloorPlan

        return selectedLocationsExistOnFloorPlan
    })
}

function isAnalysisChartData(graphData: GraphData): graphData is OrganismsBreakdownSeriesGraphData | DateSeriesGraphData {
    return graphData.chartType === chartType.ORGANISMS_BREAKDOWN_CHART
        || graphData.chartType === chartType.ORGANISM_TYPE_BREAKDOWN
        || graphData.chartType === chartType.STACKED_SAMPLE_NAME
        || isLinesMarkersChart(graphData.chartType)
}

function isFloorPlanChartData(graphData: GraphData): graphData is ContaminationFloorPlanSeriesGraphData | LimitBreachFloorPlanSeriesGraphData {
    return graphData.chartType === chartType.CONTAMINATION_FLOOR_PLAN || graphData.chartType === chartType.LIMIT_BREACH_FLOOR_PLAN
}

function isChartTypeWithSubCharts(c: chartType.ChartType | number | undefined): c is chartType.ChartTypeWithSubCharts {
    return c === chartType.ACTION_LIMIT_BREACHES || c === chartType.CONTAMINATED_SAMPLES || c === chartType.ALERT_LIMIT_BREACHES
}

function isAlertLimitBreachesSubChart(sc: subChartType.SubChartType | undefined) {
    return sc === subChartType.ALERT_LIMIT_BREACH_RATE || sc === subChartType.NUMBER_OF_ALERT_LIMIT_BREACHES
}

function isActionLimitBreachesSubChart(sc: subChartType.SubChartType | undefined) {
    return sc === subChartType.ACTION_LIMIT_BREACH_RATE || sc === subChartType.NUMBER_OF_ACTION_LIMIT_BREACHES
}

function isContaminatedSamplesSubChart(sc: subChartType.SubChartType | undefined) {
    return sc === subChartType.CONTAMINATION_RATE || sc === subChartType.NUMBER_OF_CONTAMINATED_SAMPLES
}

function isRateChart(sc: subChartType.SubChartType | undefined) {
    return sc === subChartType.ACTION_LIMIT_BREACH_RATE || sc === subChartType.ALERT_LIMIT_BREACH_RATE || sc === subChartType.CONTAMINATION_RATE
}

function getChartTitle(c: chartType.ChartType, subChartTypeId: subChartType.SubChartType | undefined) {
    const title = CHART_TYPE.find(_ => _.id === c)!.name
        , isRateChartType = subChartTypeId === subChartType.ACTION_LIMIT_BREACH_RATE
            || subChartTypeId === subChartType.ALERT_LIMIT_BREACH_RATE
            || subChartTypeId === subChartType.CONTAMINATION_RATE
            || c === chartType.ORGANISM_TYPE_BREAKDOWN

    return `${title}${isRateChartType ? ', %': ''}`
}

function getBucketStart(dateFrom: dt.DateTime, timeService: TimeService, aggregationPeriod: AggregationPeriod | undefined) {
    switch (aggregationPeriod) {
        case WEEK: {
            const dateStart = timeService.ctzDayStart(dateFrom)
                , weekDay = timeService.ctzWeekDay(dateFrom)
            return timeService.addCtzDays(dateStart, -weekDay + 1)
        }
        case MONTH:
            const timeStruct = timeService.ctzTimeStruct(dateFrom)
            return timeService.ctz(timeStruct.year, timeStruct.month, 1)
        case DAY:
        default:
            return timeService.ctzDayStart(dateFrom)
    }
}

function getNextDate(date: dt.DateTime, timeService: TimeService, aggregationPeriod: AggregationPeriod) {
    switch (aggregationPeriod) {
        case DAY: return timeService.addCtzDays(date, 1)
        case WEEK: return timeService.addCtzDays(date, 7)
        case MONTH: return timeService.addCtzMonths(date, 1)
    }
}

function getDateRange(dateFrom: dt.DateTime, nextDate: dt.DateTime, timeService: TimeService) {
    return {
        dateFrom,
        dateTo: timeService.addCtzDays(nextDate, -1)
    }
}

function generateDateRange(timeService: TimeService, aggregationPeriod: AggregationPeriod | undefined, sampleListRouterParams: Pick<SampleSearchFields, 'dateFrom' | 'dateTo'>) {
    const result: dt.DateTime[] = [sampleListRouterParams.dateFrom ?? timeService.now()]
        , dateFrom = getBucketStart(sampleListRouterParams.dateFrom ?? timeService.now(), timeService, aggregationPeriod)
        , dateTo = sampleListRouterParams.dateTo ?? timeService.now()

    if (aggregationPeriod === undefined)
        return [dateFrom, dateTo]

    let currentDate = dateFrom

    while (dt.greaterThanOrEqual(dateTo, currentDate)) {
        if (dt.greaterThan(currentDate, dateFrom))
            result.push(currentDate)

        currentDate = getNextDate(currentDate, timeService, aggregationPeriod)
    }

    return result
}

function formatDate(date: dt.DateTime, timeService: TimeService, aggregationPeriod: AggregationPeriod): string {
    switch(aggregationPeriod) {
        case DAY:
        case WEEK:
            return timeService.formatCtzDate(date)
        case MONTH:
            return timeService.formatCtzDate(date, true)
    }
}

interface CommonMetadataProps {
    analysisFilter: AnalysisFilter
    timeService: TimeService
    user: UserContext | undefined
    predefinedLists: PredefinedLists
    organisms: OrganismIdentification[]
    reportName?: string
}

interface FloorPlanMetadataProps extends CommonMetadataProps {
    floorPlan?: FloorPlan
}

function computeSubChartType(c: chartType.ChartType | undefined, sc: subChartType.SubChartType | undefined) {
    switch (c) {
        case chartType.ACTION_LIMIT_BREACHES:
            return isActionLimitBreachesSubChart(sc) ? sc : subChartType.NUMBER_OF_ACTION_LIMIT_BREACHES
        case chartType.ALERT_LIMIT_BREACHES:
            return isAlertLimitBreachesSubChart(sc) ? sc : subChartType.NUMBER_OF_ALERT_LIMIT_BREACHES
        case chartType.CONTAMINATED_SAMPLES:
            return isContaminatedSamplesSubChart(sc) ? sc : subChartType.NUMBER_OF_CONTAMINATED_SAMPLES
        default:
            return undefined
    }
}

function normalizeFields(fields: FilterFieldValue[], customFields: CustomField[]): FilterFieldValue[] {
    const getIndexes = (fields: (FilterFieldValue | CustomField)[]) => fields.map(_ => _.index).sort((a, b) => a - b)
        , areFieldsEqual = areArraysEqual(getIndexes(fields), getIndexes(customFields))

    if (!areFieldsEqual)
        return customFields.map(_ => ({ index: _.index, value: getFieldValue(fields, _.index), notRecorded: getFieldNotRecorded(fields, _.index) }))

    return fields
}

function normalizeFilteredFields(filter: AnalysisFilter, predefinedLists: PredefinedLists) {
    function getPredefinedListLength(index: fieldIndex.FieldIndex, predefinedLists: PredefinedLists) {
        switch (index) {
            case fieldIndex.EXPOSURE_LOCATION_ID:
                return fullNameLocationList(predefinedLists.exposureLocations).length - 1
            case fieldIndex.SAMPLE_TYPE_ID:
                return predefinedLists.sampleTypes.length
            case fieldIndex.SESSION_ID:
                return predefinedLists.sampleSessions.length
            case fieldIndex.OPERATORS_IDS:
                return predefinedLists.sampleOperators.length
            case fieldIndex.EXPOSURE_LOCATION_GRADE_ID:
                return predefinedLists.grades.length
            default:
                return getCustomFieldList(index, predefinedLists)
        }
    }
    function getCustomFieldList(index: fieldIndex.FieldIndex, predefinedLists: PredefinedLists) {
        const field = predefinedLists.customFields.find(_ => _.index === index)
        if (field?.fieldType !== SELECTION)
            return undefined

        return field.selectionFieldValues?.length
    }

    const ALL_ID = 'e2222c19-9e3c-4b47-b928-e8cf751bb1a1'

    function replaceValueWithAll(_: FilterFieldValue) {
        const listLength = getPredefinedListLength(_.index, predefinedLists)
        if (listLength === undefined)
            return _.value

        const isSelectedExcludedId = [NOT_RECORDED_ID, ANY_ID, NO_SESSION_ID].some(id => _.value?.some((_: string) => _ === id))
            , value = _.value && !isSelectedExcludedId && _.value.length === listLength
                ? _.value.concat([ALL_ID])
                : _.value

        return value
    }

    const fields = filter.fields?.map(_ => ({..._, value: replaceValueWithAll(_)}))
        , gradeIds = replaceValueWithAll({index: fieldIndex.EXPOSURE_LOCATION_GRADE_ID, value: filter.gradeIds})

    return {...filter, fields, gradeIds}
}

export {
    getAnalysisMetadata,
    getFloorPlanMetadata,
    computeFilters,
    computeExposureDateRange,
    computeSeriesFieldIndex,
    isAggregationAllowed,
    isExposureDateRangeModified,
    getLocationChildIds,
    graphSeriesName,
    formatExposureDateRange,
    isLinesMarkersChart,
    isFloorPlanChart,
    getFloorPlanSubtitle,
    getAllAvailableFloorPlanLocations,
    buildSampleListRouterParams,
    getFilteredFloorPlans,
    formatAggregationPeriodName,
    getChartTitle,
    isAnalysisChartData,
    isFloorPlanChartData,
    computeSubChartType,
    isChartTypeWithSubCharts,
    computeAuthorInfo,
    isRateChart,
    getBucketStart,
    getNextDate,
    getDateRange,
    generateDateRange,
    formatDate,
    getSampleListRouteParamsForFloorPlanGraph,
    normalizeFields,
    filteredLocationsExistsOnFloorPlan,
    normalizeFilteredFields,
}
