import type { Middleware } from 'redux'
import type AppState from '_/model/app-state'

declare const VERSION: string

const syncState: Middleware = api => next => action => {
    const result = next(action)
        , { auth } = api.getState()

    saveState({ auth })

    return result
}

const saveState = (state: any) => {
    try {
        sessionStorage.setItem('state', JSON.stringify({ version: VERSION, state }))
    }
    catch (_) {
    }
}

const restoreState = () => {
    try {
        const serializedState = sessionStorage.getItem('state')
        if (serializedState === null)
            return undefined

        const { version, state } = JSON.parse(serializedState)
        if (version !== VERSION)
            return undefined

        return state as Pick<AppState, 'auth'>
    }
    catch (_) {
    }

    return undefined
}

export { syncState as default, restoreState }
