import type { FormRenderProps, FieldRenderProps } from 'react-final-form'
import type { FormApi} from 'final-form'
import { getIn } from 'final-form'

function showFieldError(meta: FieldRenderProps<any>['meta']): boolean {
    return !!(meta.invalid && meta.touched && meta.error || !meta.submitting && !meta.dirtySinceLastSubmit && meta.submitError)
}

function formTouched(props: FormRenderProps): boolean {
    const touched = (props as any).touched
        , keys = Object.keys(touched)

    if (keys.length === 0)
        return false

    return keys.some(_ => touched[_] && getIn(props.errors ?? {}, _))
}

function submitDisabled(props: FormRenderProps<any>) {
    return props.submitting || props.invalid && formTouched(props) || !props.dirty
}

function resetForm<T, X>(form: FormApi<T, X | T>, pauseValidation = false): Promise<void> {
    return new Promise(resolve => {
        form.reset(form.getState().values)

        if (pauseValidation)
            form.pauseValidation()

        setTimeout(resolve, 0)
    })
}

export { submitDisabled, showFieldError, resetForm }
