import { useAction, useSelector } from '_/facade/react'

import { useOperators } from '_/hooks/shared-hooks'

import { EmptyTableMessage, Table } from '_/components/table'
import PageHeader from '_/components/page-header'

import Link from '_/components/link'

import * as routes from '_/constants/routes'

import SampleOperatorModal from './modal/sample-operator-modal'
import * as actions from './actions'

function SampleOperatorList() {
    useOperators()

    const predefinedLists = useSelector(_ => _.predefinedLists)
        , permissions = useSelector(_ => _.auth.permissions)
        , editMode = useSelector(
            _ => [routes.SETTINGS_OPERATORS_CREATE, routes.SETTINGS_OPERATORS_EDIT].some(it => _.router.route!.name === it)
        )
        , handleFilesChanged = useFileChanged()

    return (
        <>
            {editMode && <SampleOperatorModal />}

            <PageHeader title='Operators'>
                <div>
                    <Link
                        className='btn btn-primary'
                        routeName={routes.SETTINGS_OPERATORS_CREATE}
                        hasNoPermissions={!permissions.editSampleOperator}
                        testId='add-new-operator'
                    >
                        New operator
                    </Link>
                    {permissions.editSampleOperator &&
                        <>
                            <label
                                htmlFor='import-sample-operator'
                                className='btn btn-secondary ms-2 my-0'
                            >
                                Import
                            </label>
                            <input
                                onChange={handleFilesChanged}
                                accept='.csv'
                                value=''
                                type='file'
                                className='d-none'
                                autoComplete='off'
                                id='import-sample-operator'
                                data-testid='import-sample-operator'
                            />
                        </>
                    }
                </div>
            </PageHeader>

            <div className="overflow-auto flex-fill">
                <Table>
                    <thead className='thead table-header--sticky'>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody>
                        {predefinedLists.sampleOperators.length === 0
                            ? <EmptyTableMessage colSpan={3} message='There are no operators at the moment' />
                            : predefinedLists.sampleOperators.map(_ =>
                                <tr key={_.id}>
                                    <td data-testid='operator-name'>{_.name}</td>
                                    <td data-testid={`${_.name}-status`}>{_.isActive ? 'Active' : 'Inactive'}</td>
                                    <td className='text-end'>
                                        {permissions.editSampleOperator &&
                                            <Link
                                                className='btn-link'
                                                routeName={routes.SETTINGS_OPERATORS_EDIT}
                                                routeParams={{ id: _.id }}
                                                testId={`operator-${_.name}-edit`}
                                            >
                                                Edit
                                            </Link>
                                        }
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default SampleOperatorList

function useFileChanged() {
    const importSampleOperators = useAction(actions.importSampleOperators)

    function handleFilesChanged(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files)
            return

        const files = Array.from(e.target.files)
            , file = files[0]

        importSampleOperators(file)
    }

    return handleFilesChanged
}
