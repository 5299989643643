export const CHANGING_SETTINGS = 0
           , EDITING_SAMPLES = 1
           , EDITING_CUSTOM_REPORTS = 2
           , EDITING_SCHEDULING_GROUPS = 3
           , VERIFYING_CFU_COUNTS = 4
           , OVERRIDE_PHOTO_REQUIREMENT = 5
           , EDITING_TREND_INVESTIGATION_REFERENCES = 6

import type * as self from './electronic-signature-settings'
type ElectronicSignatureSettings = Extract<(typeof self)[keyof typeof self], number>

export type {
    ElectronicSignatureSettings,
}
