import { useCredentialExtractor } from '../hooks'
import { useAction, useEffect } from '_/facade/react'
import * as routes from '_/constants/routes'

import * as a from '../actions'
import { navigateTo } from '_/features/routing/actions'

import type { MsSsoResponse } from '_/model/auth/types'


function SsoSignUp() {
    const ssoToken = useCredentialExtractor()
        , signUpHandler = useSsoSignUp()

    useEffect(
        () => {
            signUpHandler(ssoToken)
        },
        [signUpHandler, ssoToken]
    )

    return null
}

function useSsoSignUp() {
    const signUp = useAction(a.ssoSignUp)
        , navigateToAction = useAction(navigateTo)

    function signUpHandler(response: MsSsoResponse) {
        if (response.type === 'success') {
            signUp({ idToken: response.idToken, sessionState: response.state, invitationId: response.invitationId })
                .then(() => navigateToAction(routes.CONTEXTS_INVITE_CONFIRMED))
                .catch(() => navigateToAction(routes.LOG_IN))
        }

        if (response.type === 'error') {
            navigateToAction(routes.LOG_IN)
        }
    }

    return signUpHandler
}

export default SsoSignUp
