import { classnames } from '_/facade/react'
import Button from '_/components/button'
import type ExposureLocation from '_/model/predefined-lists/exposure-location/exposure-location'
import { POSITION } from '_/model/predefined-lists/exposure-location/location-type'
import { formatActiveState } from '_/utils/format/common'
import FormattedText from '_/features/text/formatted-text'
import * as t from '_/model/text/text'

interface OwnProps {
    location: ExposureLocation
    expanded: boolean
    hasChildren: boolean
    nestingLevel: number
    onTrigger: (_: ExposureLocation) => void
    onClick: () => void
}

const ExposureLocationsRow = (props: OwnProps) => {
    const triggerClasses = classnames('exposure-locations__trigger', {
            'exposure-locations__trigger--collapsed': !props.expanded,
            'exposure-locations__trigger--visible': props.hasChildren,
        })
        , positionOnly = props.location.locationType === POSITION ? t.systemTextNode(' (Position only)') : t.emptyTextNode()
        , locationName = formatActiveState(props.location.name, props.location.isActive).concat(positionOnly)

    const offsets = []
    for (let i = props.nestingLevel; i > 0; i--)
        offsets.push(<div key={i} className='exposure-locations__offset' />)

    return (
        <div className='mt-2'>
            {offsets}
            {props.hasChildren
                ? <a className={triggerClasses} href='.' onClick={e => {e.preventDefault(); props.onTrigger(props.location)} } data-testid={`${props.location.name}-expand`} />
                : <div className={triggerClasses} />
            }
            <Button
                className='btn-link px-0'
                onClick={props.onClick}
                testId={`locations-report-${props.location.name}`}
            >
                <FormattedText text={locationName} />
            </Button>
        </div>
    )
}

export default ExposureLocationsRow
