import type { Auth } from '_/model/app-state'
import { reducer, handler } from '_/utils/redux/reducer'
import { shallowUpdate } from '_/utils/object'
import { replace, mergeAndReplaceEntity } from '_/utils/array'
import * as userActions from '_/features/users/actions'
import * as contextActions from '_/features/contexts/actions'

import * as actions from './actions'
import { calcPermissions } from '_/model/permissions/helpers'

const emptyAuth: Auth = {
    user: undefined,
    token: undefined,
    permissions: calcPermissions(),
    memberships: [],
    timeZoneName: undefined,
    electronicSignatureSettings: [],
}

export default reducer<Auth>(
    emptyAuth,

    handler(actions.loggedIn, (state, auth) =>
        shallowUpdate(state, auth, {
            permissions: calcPermissions(auth.user.membership),
            timeZoneName: auth.timeZoneName,
            electronicSignatureSettings: auth.electronicSignatureSettings,
        })
    ),

    handler(actions.loggedOut, () => emptyAuth),

    handler(actions.membershipsLoaded, (state, memberships) =>
        shallowUpdate(state, { memberships })
    ),

    handler(actions.contextChanged, (state, { id, context }) => {
        const memberships = mergeAndReplaceEntity(state.memberships, { id, role: context.role })
            , user = Object.assign(
                {},
                state.user,
                {
                    role: context.role,
                    membership: memberships.find(_ => _.id === id),
                },
            )
            , token = context.token
            , permissions = calcPermissions(user.membership)
            , electronicSignatureSettings = context.newContext.electronicSignatureSettings

        return shallowUpdate(state, { user, token, permissions, memberships, timeZoneName: context.timeZoneName, electronicSignatureSettings })
    }),

    handler(userActions.userChanged, (state, userData) => shallowUpdate(state, { user: shallowUpdate(state.user, userData) })),

    handler(contextActions.contextEdited, (state, context) => {
        const user = { membership: shallowUpdate(state.user!.membership, { description: context.description }) }
            , memberships = replace(
                state.memberships,
                _ => _.contextId === context.id,
                _ => shallowUpdate(_, { description: context.description }),
            )

        return context.id === state.user?.membership.contextId
            ? shallowUpdate(state, { user: shallowUpdate(state.user, user), timeZoneName: context.timeZoneName, electronicSignatureSettings: context.electronicSignatureSettings }, { memberships })
            : shallowUpdate(state, { memberships })
    }),

    handler(actions.userMembershipLoaded, (state, request) =>
        shallowUpdate(state, {
            memberships: mergeAndReplaceEntity(
                state.memberships,
                request
            ),
            permissions: calcPermissions(request),
            user: shallowUpdate(state.user, { membership: request }),
        })
    ),
)
