const DAY = 0
    , WEEK = 1
    , MONTH = 2

type AggregationPeriod = never
    | typeof DAY
    | typeof WEEK
    | typeof MONTH

const AGGREGATION_PERIOD = [
    {
        id: DAY,
        name: 'By day',
    },
    {
        id: WEEK,
        name: 'By week',
    },
    {
        id: MONTH,
        name: 'By month',
    },
]

type AGGREGATION_PERIOD = typeof AGGREGATION_PERIOD

export default AGGREGATION_PERIOD
export {
    DAY,
    WEEK,
    MONTH,
    AggregationPeriod,
}
