import { actionFactory } from '_/utils/redux'
import type { ObjectionableOrganismEffectiveView } from '_/model/objectionable-organisms/objectionable-organism'
import type ObjectionableOrganism from '_/model/objectionable-organisms/objectionable-organism'
import type PaginationState from '_/model/pagination-state'
import type Page from '_/model/page'
import type { ObjectionableOrganismEdit } from '_/model/objectionable-organisms/editor/types'
import type { AuditTrail } from '_/model/audit-trail/types'

const action = actionFactory('app.objectionable-organisms')

export const loadObjectionableOrganismList = action<PaginationState, Promise<Page<ObjectionableOrganism>>>('loadObjectionableOrganismList')
           , loadObjectionableOrganismsTrail = action<void, Promise<AuditTrail>>('loadObjectionableOrganismsTrail')
           , loadObjectionableOrganism = action<string, Promise<ObjectionableOrganism>>('loadObjectionableOrganism')
           , createObjectionableOrganism = action<ObjectionableOrganismEdit, Promise<void>>('createObjectionableOrganism')
           , saveObjectionableOrganism = action<{ id: string, oldObjectionableOrganism: ObjectionableOrganismEdit, newObjectionableOrganism: ObjectionableOrganismEdit }, Promise<void>>('saveObjectionableOrganism')
           , removeObjectionableOrganism = action<string, Promise<void>>('removeObjectionableOrganism')
           , loadObjectionableOrganisms = action<void, Promise<ObjectionableOrganism[]>>('loadObjectionableOrganisms')
           , loadObjectionableOrganismEffectiveList = action<void, Promise<ObjectionableOrganismEffectiveView[]>>('loadObjectionableOrganismEffectiveList')
