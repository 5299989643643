import { useSelector, useAction, useCallback, useEffect } from '_/facade/react'
import PageHeader from '_/components/page-header'
import Link from '_/components/link'
import * as m from '_/components/modal'
import { navigateTo } from '_/features/routing/actions'
import * as r from '_/constants/routes'

import { loadExposureLocationList } from '_/features/predefined-lists/exposure-locations/actions'
import Uploader from '../uploader/uploader'
import Editor from '../editor/editor'
import * as a from '../actions'
import FloorPlanItem from './floor-plan-item'
import { useContextSwitchObserver } from '_/components/context-observer'

function FloorPlans() {
    const [showUploader, closeUploader] = useModal(r.SETTINGS_FLOOR_PLANS_ADD)
        , [showEditor, closeEditor] = useModal(r.SETTINGS_FLOOR_PLANS_EDIT)
        , plans = useFloorPlans()
        , permissions = useSelector(_ => _.auth.permissions)

    return (
        <>
            <PageHeader title='Floor plans'>
                <Link
                    routeName={r.SETTINGS_FLOOR_PLANS_ADD}
                    className='btn btn-primary'
                    hasNoPermissions={!permissions.manageFloorPlans}
                    testId='add-new-floor-plan'
                >
                    Add a new floor plan
                </Link>
            </PageHeader>

            <div className='d-flex flex-wrap overflow-auto'>
                {plans.length > 0
                    ? plans.map(
                        plan => <FloorPlanItem key={plan.id} floorPlan={plan} hasNoPermissions={!permissions.manageFloorPlans} testId={`floor-plan-item-${plan.name}`} />
                    )
                    : <div className='w-100 text-center text-muted ps-4'>There are no floor plans at the moment</div>
                }
            </div>

            <m.Modal isOpen={showUploader} onClose={closeUploader} noDefaultContentWidth contentClassName='floor-plans-window py-4'>
                <Uploader plans={plans} />
            </m.Modal>

            <m.Modal isOpen={showEditor} onClose={closeEditor} noDefaultContentWidth contentClassName='floor-plans-window py-4'>
                <Editor />
            </m.Modal>
        </>
    )
}

export default FloorPlans

function useFloorPlans() {
    const load = useAction(a.loadFloorPlanList)
        , loadLocations = useAction(loadExposureLocationList)
        , plans = useSelector(_ => _.predefinedLists.floorPlans.plans)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => {
            load()
            loadLocations()
        },
        // eslint-disable-next-line
        [contextSwitch, load, loadLocations]
    )

    return plans
}

function useModal(name: r.RouteName) {
    const stateName = useSelector(_ => _.router.route?.name)
        , navigate = useAction(navigateTo)
        , handleClose = useCallback(
            () => navigate(r.SETTINGS_FLOOR_PLANS),
            [navigate],
        )

    return [stateName === name, handleClose] as const
}
