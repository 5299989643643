import { downscaleImage, loadImageFromBlob } from '_/utils/image'
import type { ImageTokens, ImageUploadRequest } from './image'

const MEDIUM_SIZE = 600
    , THUMBNAIL_SIZE = 75
    , FOUR_K_MEDIUM_RATIO = 4096 / MEDIUM_SIZE

function getImageUploadRequests(tokens: ImageTokens, image: Blob): Promise<ImageUploadRequest[]> {
    const result =
        Promise.all([
            downscaleImage(image, MEDIUM_SIZE),
            downscaleImage(image, THUMBNAIL_SIZE),
        ])
        .then(([medium, thumbnail]) => [
            { image, uploadUrl: tokens.original },
            { image: medium, uploadUrl: tokens.medium },
            { image: thumbnail, uploadUrl: tokens.thumbnail },
        ])

    return result
}

const FOUR_K = 0
    , FIVE_K = 1

function validateImageResolution(blob: Blob): Promise<typeof FOUR_K | typeof FIVE_K> {
    return loadImageFromBlob(blob).then(img => {
        const width = img.naturalWidth
            , height = img.naturalHeight

        if (width === 5472 && height === 3648)
            return FIVE_K

        if (width === 4096 && height === 2160)
            return FOUR_K

        return Promise.reject({ message: 'Wrong image dimensions, only 4096x2160 or 5472x3648 is supported' })
    })
}

export {
    getImageUploadRequests,
    validateImageResolution,
    FOUR_K_MEDIUM_RATIO,
    FOUR_K,
    FIVE_K,
}
