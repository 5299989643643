import type { EffectsFactory} from '_/facade/effect'
import { handler } from '_/facade/effect'
import type StatusService from '_/services/backend-status-service'
import * as actions from './actions'
import { noop } from '_/utils/function'

const factory = (service: StatusService): EffectsFactory => (dispatch) => [
    handler(actions.checkStatus, () => service.checkStatus()
        .then(_ => dispatch(actions.statusChecked(_)))
        .then(noop)),
]

export default factory
