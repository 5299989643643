import { NumberFieldInline } from '_/components/form'
import type { Guid } from '_/model/guid'

interface Props {
    index: number
    name: string
    hasNoPermissions: boolean
    typeName: string
    gradeId: Guid
    testId?: string
}

function LimitFields(props: Props) {
    return (
        <>
            <td className='pt-0 pe-1'>
                <NumberFieldInline
                    id={`alert-grade-${props.gradeId}-${props.typeName}-1`}
                    name={`${props.name}[${props.index}].inOperationAlertLimit`}
                    hasNoPermissions={props.hasNoPermissions}
                    testId={`field-${props.testId}-limit-${props.typeName}-alert`}
                />
            </td>
            <td className='pt-0'>
                <NumberFieldInline
                    id={`action-grade-${props.gradeId}-${props.typeName}-1`}
                    name={`${props.name}[${props.index}].inOperationActionLimit`}
                    hasNoPermissions={props.hasNoPermissions}
                    testId={`field-${props.testId}-limit-${props.typeName}-action`}
                />
            </td>

            <td className='pt-0 pe-1'>
                <NumberFieldInline
                    id={`alert-grade-${props.gradeId}-${props.typeName}-0`}
                    name={`${props.name}[${props.index}].atRestAlertLimit`}
                    hasNoPermissions={props.hasNoPermissions}
                    testId={`field-${props.testId}-limit-${props.typeName}-rest-alert`}
                />
            </td>
            <td className='pt-0'>
                <NumberFieldInline
                    id={`action-grade-${props.gradeId}-${props.typeName}-0`}
                    name={`${props.name}[${props.index}].atRestActionLimit`}
                    hasNoPermissions={props.hasNoPermissions}
                    testId={`field-${props.testId}-limit-${props.typeName}-rest-action`}
                />
            </td>
        </>
    )
}

export default LimitFields
