const HOUR = -1
    , NEVER = 0
    , DAYS90 = 1
    , DAYS180 = 2
    , YEAR = 3

type PasswordExpiration = typeof NEVER | typeof DAYS90 | typeof DAYS180 | typeof YEAR | typeof HOUR

const PasswordExpirations: { id: PasswordExpiration, name: string }[] = [
    {
        id: NEVER,
        name: 'Never',
    },
    {
        id: HOUR,
        name: 'Every 1 hour',
    },
    {
        id: DAYS90,
        name: 'Every 90 days',
    },
    {
        id: DAYS180,
        name: 'Every 180 days',
    },
    {
        id: YEAR,
        name: 'Every year',
    },
]

export {
    PasswordExpirations,
    PasswordExpiration,
    NEVER,
    HOUR,
}
