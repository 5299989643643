import { useAction, useSelector } from '_/facade/react'

import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import Button from '_/components/button'

import * as confirmationActions from './actions'

function WarningModal() {
    const cancel = useAction(confirmationActions.cancelAction)
        , message = useSelector(_ => _.confirmation.message)

    return(
        <Modal isOpen onClose={cancel}>
            <ModalHeader>Warning</ModalHeader>
            <ModalBody>
                <div className='user-formatted-text'>{message}</div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={cancel} className='btn-secondary' testId='warning-cancel'>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}

export default WarningModal
