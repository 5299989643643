import type { MiddlewareFactory } from 'router5/dist/types/router'
import type AppState from '_/model/app-state'
import type { MiddlewareAPI } from '_/utils/redux'
import permissionInterceptor from './transition-interceptor/permission-interceptor'
import unsavedChangesInterceptor from './transition-interceptor/unsaved-changes-interceptor'
import spinnerInterceptor from './transition-interceptor/spinner-interceptor'
import linkContextInterceptor from './transition-interceptor/link-context-interceptor'
import { firePageViewEvent } from '_/analytics/google'

const interceptors = [
        spinnerInterceptor,
        unsavedChangesInterceptor,
        permissionInterceptor,
        linkContextInterceptor,
    ]
    , middleware: MiddlewareFactory = (router, dependencies) => (toState, fromState) => {
        const store = dependencies.store as MiddlewareAPI<AppState>

        for (let i = 0; i < interceptors.length; i++) {
            const result = interceptors[i](router, store, toState, fromState)
            if (result !== true)
                return result
        }

        firePageViewEvent(toState.name, toState.path)
        return true
    }

export default middleware
