const SCHEDULED_NOT_BOOKED_IN = 0
    , BOOKED_IN = 1
//    , UNSCHEDULED_BOOKED_IN = 2 will be added in a separate feature
    , MISSING = 3
    , NOT_IN_USE = 4

type BOOKED_IN = typeof BOOKED_IN

type MonitoringOverviewSampleStatuses = never
    | typeof SCHEDULED_NOT_BOOKED_IN
    | typeof BOOKED_IN
    | typeof MISSING
    | typeof NOT_IN_USE

type NotBookedInStatuses =
    | typeof SCHEDULED_NOT_BOOKED_IN
    | typeof MISSING
    | typeof NOT_IN_USE

const MONITORING_OVERVIEW_SAMPLE_STATUSES: { id: MonitoringOverviewSampleStatuses, name: string }[] = [
    {
        id: SCHEDULED_NOT_BOOKED_IN,
        name: 'Scheduled, not booked in',
    },
    {
        id: BOOKED_IN,
        name: 'Booked in',
    },
    {
        id: MISSING,
        name: 'Missed',
    },
    {
        id: NOT_IN_USE,
        name: 'Not in use',
    },
]

const ALL_STATUSES = MONITORING_OVERVIEW_SAMPLE_STATUSES.map(_ => _.id)

export {
    MONITORING_OVERVIEW_SAMPLE_STATUSES,
    MonitoringOverviewSampleStatuses,
    NotBookedInStatuses,
    ALL_STATUSES,
    SCHEDULED_NOT_BOOKED_IN,
    BOOKED_IN,
    MISSING,
    NOT_IN_USE,
}
