import { actions as reduxActions } from 'redux-router5'
import * as routes from '_/constants/routes'
import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type SampleService from '_/services/sample-service'
import type SampleSearchFilterService from '_/services/sample-search-filter-service'
import type ReasonService from '_/model/reason/reason-service'
import type ExportService from '_/services/export-service'

import { checkPhotoRequirementSignatureTransaction, readTransaction, verifyCFUCountTransaction } from '_/model/sample/reading/transaction'
import * as editTransactions from '_/model/sample/edit/transaction'
import { paramsFilter } from '_/model/filters/helpers'

import { noop } from '_/utils/function'

import * as toastActions from '_/features/toasts/actions'
import * as spinnerActions from '_/features/spinner/actions'

import * as sampleMessages from './messages'
import { getNotNullQuery } from './filter/helpers'
import * as bulkOperation from '_/constants/bulk-operation'

import * as actions from './actions'
import { signatureFailure } from '_/model/error/error'
import { CSV } from '_/model/export/export-format'

const factory = (service: SampleService, sampleSearchFilterService: SampleSearchFilterService, reasonService: ReasonService, exportService: ExportService): EffectsFactory => (dispatch, getState) => [
    handler(actions.loadSampleList, query =>
        service.getSamplesWithTotal(query)
            .then(_ => dispatch(actions.sampleListLoaded(_)))
            .then(noop)
    ),

    handler(actions.loadSampleListStatistics, query =>
        service.getSamplesStatistics(query)
            .then(_ => dispatch(actions.sampleListStatisticsLoaded(_)))
            .then(noop)
    ),

    handler(actions.getSampleList, service.getSamplesWithTotal),
    handler(actions.getSampleListStatistics, service.getSamplesStatistics),

    handler(actions.navigateToSampleList, query =>
        Promise.resolve()
            .then(_ => dispatch(reduxActions.navigateTo(routes.SAMPLES, paramsFilter({ ...query }), { replace: true })))
            .then(noop)
    ),

    handler(actions.loadSample, service.get),

    handler(actions.createSample, sample => {
        return service.createSample(sample)
            .then(sampleId => {
                dispatch(toastActions.addSuccess(sampleMessages.SAMPLE_SAVED))
                return sampleId
            })
    }),

    handler(actions.saveChanges, sampleData => {
        dispatch(spinnerActions.showSpinner())

        const signatureSettings = getState().auth.electronicSignatureSettings
            , result = editTransactions
                .updateTransaction(
                    sampleData.sample,
                    sampleData.oldSampleEdit,
                    sampleData.newSampleEdit,
                    signatureSettings,
                    service,
                    reasonService,
                    sampleData.approvalInfo
                ).then(
                    () => {
                        dispatch(spinnerActions.hideSpinner())
                    },
                    _ => {
                        dispatch(spinnerActions.hideSpinner())
                        return Promise.reject(_)
                    }
                )
                .then(noop)

        return result
    }),

    handler(actions.readSample, payload =>
        readTransaction(payload.originalSample, payload.reading, payload.skipPhotoApprovalInfo, getState().auth.electronicSignatureSettings, service, reasonService, payload.compromisedData)
    ),

    handler(actions.checkOverridePhotoRequirementSignature, payload =>
        checkPhotoRequirementSignatureTransaction(payload.sampleId, payload.photoSkipped, payload.getReasonHandler, service, _ => {dispatch(toastActions.addError(_))})
    ),

    handler(actions.editBookedInSample, payload =>
        editTransactions.editBookedInSampleTransaction(payload.id, payload.oldSample, payload.newSample, service)
    ),

    handler(actions.loadSampleTrail, id =>
        service.getTrail(id)
            .then(_ => {
                dispatch(actions.sampleTrailLoaded(_))
                return _
            })
    ),

    handler(actions.loadSampleComments, id =>
        service.loadComments(id)
            .then(_ => {
                dispatch(actions.sampleCommentsLoaded(_))

                return _
            })
    ),

    handler(actions.addSampleComments, data =>
        service.addComments(data.sampleId, data.sampleComments)
            .then(_ => dispatch(actions.loadSampleComments(data.sampleId)))
            .then(() => dispatch(actions.loadSampleTrail(data.sampleId)))
            .then(noop)
    ),

    handler(actions.loadSampleByBarcode, data =>
        Promise.resolve()
            .then(() => service.getByBarcode(data.barcode, data.includeNullified))
            .then(sample => {
                dispatch(actions.sampleLoaded(sample))

                return sample
            })
            .catch(noop)
    ),

    handler(actions.loadSamplesByBarcode, data =>
        Promise.resolve()
            .then(() => service.searchSamplesByBarcode(data.barcode, data.includeNullified, data.matchExaclty))
            .then(searchResult => {
                if (searchResult.exactMatch)
                    dispatch(actions.sampleLoaded(searchResult.exactMatch))

                return searchResult
        })
    ),

    handler(actions.getVacantExpectations, service.getVacantExpectations),

    handler(actions.loadSamplesExports, exportService.getAllSamplesExports),

    handler(actions.exportSamples, payload => {
        return exportService.exportSamples({ ...payload, start: 0, count: 1000000 })
    }),

    handler(actions.exportLimitBreachTrendsRelatedSampleData, payload => {
        return service.downloadReportByLimitBreachTrends({ ...payload, format: CSV })
    }),

    handler(actions.exportLimitBreachesRelatedSampleData, payload => {

        return service.downloadReportByLimitBreaches({ ...payload, format: CSV })
    }),

    handler(actions.exportOperatorLimitBreachesRelatedSampleData, payload => {
        return service.downloadOperatorReportByLimitBreaches({ ...payload, format: CSV })
    }),

    handler(actions.exportLocationRelatedSampleData, payload => {
        return service.downloadReportByLocation({ ...payload, start: 0, count: 1000, format: CSV })
    }),

    handler(actions.exportOperatorRelatedSampleData, payload => {
        return service.downloadReportByOperator({ ...payload, start: 0, count: 1000, format: CSV })
    }),

    handler(actions.exportOrganismRelatedSampleData, payload => {
        return service.downloadReportByOrganism({ ...payload, start: 0, count: 1000, format: CSV })
    }),

    handler(actions.exportOperatorFingerdabsSampleData, payload => {
        return service.downloadReportByOperatorFingerdabs({ ...payload, start: 0, count: 1000, format: CSV })
    }),

    handler(actions.loadSampleSearchFilterList, () =>
        sampleSearchFilterService.list()
            .then(filters => {
                filters.forEach(filter => filter.filter = getNotNullQuery(filter.filter))
                return filters
            })
            .then(filters => dispatch(actions.sampleSearchFilterListLoaded(filters)))
            .then(noop)
    ),

    handler(actions.createSampleSearchFilter, sampleSearchFilter =>
        sampleSearchFilterService.create(sampleSearchFilter)
            .then(_ => dispatch(actions.loadSampleSearchFilterList()))
            .then(noop)
    ),

    handler(actions.removeSampleSearchFilter, id =>
        sampleSearchFilterService.remove(id)
            .then(_ => sampleSearchFilterService.list())
            .then(_ => dispatch(actions.sampleSearchFilterListLoaded(_)))
            .then(noop)
    ),

    handler(actions.loadPrintSamples, service.getPrintSamples),

    handler(actions.importSamples, files =>
        Promise.resolve()
            .then(() => service.import(files))
            .then(noop)
    ),

    handler(actions.importStatus, service.importStatus),

    handler(actions.bulkOperation, payload => {
        dispatch(spinnerActions.showSpinner())

        const isConfirmBookInBulkOperation = payload.operationId === bulkOperation.CONFIRM_BOOK_IN
            , promise = isConfirmBookInBulkOperation
                ? service.bulkConfirmBookIn(payload.sampleId)
                : service.bulkVerifyCFUCount(payload.sampleId)
            , successMessage = `Success! All selected samples have been ${isConfirmBookInBulkOperation ? 'confirmed booked in' : 'verified'}`

        return promise
            .then(
                () => {
                    dispatch(spinnerActions.hideSpinner())
                    dispatch(toastActions.addSuccess(successMessage))
                },
                _ => {
                    dispatch(spinnerActions.hideSpinner())
                    if (!_.isHandled && !signatureFailure(_))
                        dispatch(toastActions.addError('Failed Operation: Please try again'))
                    return Promise.reject(_)
                }
            )
    }),

    handler(actions.confirmBookIn, service.confirmBookIn),

    handler(actions.verifyCFUCount, payload => verifyCFUCountTransaction(payload.id, payload.approvalInfo, service)),

    handler(actions.changeCompromised, payload =>
        editTransactions.changeCompromisedTransaction(
            payload.id,
            payload.compromised,
            payload.checkBreach,
            payload.approvalInfo,
            service,
        )
    ),

    handler(actions.attachImages, data => service.attachImages(data.sampleId, data.images).then(noop)),

    handler(actions.loadListColumns, service.loadListColumns),
    handler(actions.saveListColumns, service.saveListColumns),
    handler(actions.getSampleEditedInfo, service.getSampleEditedInfo),
    handler(actions.loadSampleTrends, service.getSampleTrends),
    handler(actions.searchBatchNumbers, service.searchBatchNumbers),
]

export default factory
