import { classnames, useState, useSelector, useAction, useEffect, useCallback, useRef } from '_/facade/react'
import Legend from '_/components/legend'
import { LinkButton } from '_/components/link'
import Button from '_/components/button'

import * as tiers from '_/constants/tiers'

import { noop, memoize } from '_/utils/function'

import type CustomField from '_/model/predefined-lists/custom-field/types'

import * as toastActions from '_/features/toasts/actions'
import * as contextActions from '_/features/contexts/actions'

import { FINGERDAB_TWO_HANDS_PLATE, SETTLE_PLATE } from '_/constants/plate-type'
import * as routes from '_/constants/routes'
import * as fieldIndex from '_/constants/custom-field-index'

import Scanner from '../scanner/scanner'
import * as reasonActions from '_/features/critical-change-reason/actions'
import * as predefinedListsActions from '_/features/predefined-lists/redux/actions'
import * as actions from '../actions'
import * as messages from '../messages'

import {
    getFieldValue,
    getNotDefaultCustomFields,
    formatSampleStatus,
    getGeneratedBy,
    getEditedFieldInfo,
} from '../helpers'

import {
    convertSampleToSampleEdit,
    canChangeSampleTypeToSwab,
} from '../sample-edit/helpers'

import { usePredefinedLists } from './shared/shared-sample-hooks'

import SampleTypeForm from './sample-information/sample-type-form'
import SampleInvestigationRefsForm from './sample-investigation-refs-form'

import PlainText from './plain-text'
import ContaminationInfo from './contamination-info'
import CommentsForm from './comments-form'
import AuditTrailModal from '_/components/audit-trail-modal'
import PageHeader from './page-header'
import { breachLabelText } from '../helpers'
import { NULLIFIED } from '_/constants/sample-status'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import { useTimeService } from '_/components/time'
import type SampleDetails from '_/model/sample/sample-details'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import Attachments from './attachments/attachments'
import { checkCfuConfirmationRequiredByGrade } from '_/model/sample/reading/helpers'
import type { SampleEditedInfo } from '_/model/sample/sample'
import EditedLabel from './edited-label'
import SampleTrends from './sample-trends'
import TrendInvestigationRefs from './trend-investigation-refs'
import PhotoGallery from '_/features/smart-check/ui/photo-gallery'
import SampleField from './shared/sample-fields'
import NotDefaultSampleField from './shared/not-default-sample-fields'
import { useDialog } from '_/hooks/shared-hooks'
import MonitoringGroupForm from './sample-information/monitoring-group-form'
import type { MonitoringGroupProps, VacantExpectationDialogResult } from '../shared/vacant-expectation-dialog'
import VacantExpectationDialog, { getExpectationQueryProps, getMonitoringGroupId } from '../shared/vacant-expectation-dialog'
import { diffObject } from '_/utils/object'

function SampleEditPage() {
    const timeService = useTimeService()
        , [showAuditTrail, setShowAuditTrail] = useState(false)
        , [sampleId, sample, sampleEditedInfo, load] = useSample()
        , predefinedLists = usePredefinedLists(sample)
        , bookInConfirmationEnabled = useBookInConfirmationEnabled()
        , sampleEdit = useSampleEdit(sample, predefinedLists.customFields)
        , user = useSelector(_ => _.auth.user)
        , permissions = useSelector(_ => _.auth.permissions)
        , endTimeRequired = predefinedLists.customFields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)?.viableSettings.required
        , [getVacantExpectation, vacantExpectationDialogProps] = useDialog<VacantExpectationDialogResult, MonitoringGroupProps>()
        , handleSubmit = useSubmitHandler(sample, sampleEdit, predefinedLists.sampleTypes, !!endTimeRequired, load, getVacantExpectation)
        , handleConfirmBookingIn = useConfirmBookingInHandler(sampleId, load)
        , gradeId = getFieldValue(sampleEdit?.fields, fieldIndex.EXPOSURE_LOCATION_GRADE_ID)
        , grade = useSelector(_ => _.predefinedLists.grades.find(_ => _.id === gradeId))
        , cfuCountVerificationRequired = sample && !!sample.firstReadAt && grade && checkCfuConfirmationRequiredByGrade(sample, grade)
        , sampleIsNotNullified = sample ? !sample.nullified : false
        , userCanEditSamples = permissions.editSamples && sampleIsNotNullified
        , userCanReadSample = permissions.performSampleReading && sampleIsNotNullified
        , barcode = getFieldValue(sampleEdit?.fields, fieldIndex.BARCODE)
        , isFingerdabTwoHandsPlate = getFieldValue(sampleEdit?.fields, fieldIndex.PLATE_TYPE) === FINGERDAB_TWO_HANDS_PLATE
        , bookInByCurrentUser = user?.id === sample?.personBookingIn.id
        , context = useSelector(_ => _.contexts.contexts.find(ctx => ctx.id === user?.membership.contextId))
        , atLeastInsight = context && context.tier > tiers.COMPLIANCE

    function buildField(pos: number): React.ReactNode {
        const entity = sampleEdit!
            , field = predefinedLists.customFields[pos]

        if (field.index === fieldIndex.SAMPLE_TYPE_ID)
            return <div key={pos}>
                <SampleTypeForm
                    entity={entity}
                    field={field}
                    canChangeToSwab={canChangeSampleTypeToSwab(sample)}
                    sampleTypes={predefinedLists.sampleTypes}
                    onSubmit={handleSubmit}
                    hasNoPermissions={!userCanEditSamples}
                    editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.SAMPLE_TYPE_ID)}
                />
            </div>

        return (
            <SampleField
                sample={sample!}
                entity={entity}
                position={pos}
                predefinedLists={predefinedLists}
                hasNoPermissions={!userCanEditSamples}
                onSubmit={handleSubmit}
                sampleEditedInfo={sampleEditedInfo}
                isViable
                key={pos}
            />
        )
    }

    return (
        <div className='container-fluid'>
            <Scanner />
            {showAuditTrail &&
                <AuditTrailModal
                    id={sampleId}
                    onClose={() => setShowAuditTrail(false)}
                    loadAuditTrailAction={actions.loadSampleTrail}
                />
            }
            {vacantExpectationDialogProps &&
                <VacantExpectationDialog skipIfAvailableExpectationSelected {...vacantExpectationDialogProps} />
            }
            <div className='row justify-content-center d-print-block'>
                {sampleEdit && sample &&
                    <div className='col-9 width-print-100'>
                        <PageHeader
                            sample={sample}
                            entity={sampleEdit}
                            permissions={permissions}
                            onSubmit={handleSubmit}
                            onShowAuditTrail={() => setShowAuditTrail(true)}
                        />
                        <div className='row align-items-start d-print-block sample-edit__word_wrap'>
                            {user &&
                                <span className='d-none d-print-block ps-4'>
                                    {getGeneratedBy(timeService, user.name, user.email)}
                                </span>
                            }
                            <div className='col-5 width-print-100'>

                                <div className='block-border p-3 mb-3'>
                                    <fieldset>
                                        <div className='d-flex justify-content-between'>
                                            <div className='custom-legend'>Viable sample information</div>
                                            {bookInConfirmationEnabled &&
                                                <Button
                                                    className='btn-primary d-print-none'
                                                    onClick={handleConfirmBookingIn}
                                                    disabled={sample.bookInConfirmed || sample.status === NULLIFIED || bookInByCurrentUser}
                                                    title={sample.bookInConfirmed ? 'Booked in is confirmed' : ''}
                                                    hasNoPermissions={!permissions.confirmBookInForSample}
                                                    testId='confirm-booked-in'
                                                >
                                                    Confirm booked in
                                                </Button>
                                            }
                                        </div>
                                        <div className='d-none d-print-block'>
                                            <PlainText text={sampleEdit.nullified ? '' : breachLabelText(sample)} label='Limit breach'/>
                                        </div>
                                        <PlainText text={formatSampleStatus(sample)} label='Status' testId='sample-status' />
                                        <MonitoringGroupForm
                                            sample={sample}
                                            entity={sampleEdit}
                                            exposureLocations={predefinedLists.exposureLocations}
                                            getVacantExpectation={getVacantExpectation}
                                            onSubmit={handleSubmit}
                                            hasNoPermissions={!userCanEditSamples}
                                            editedInfo={
                                                getEditedFieldInfo(sampleEditedInfo, fieldIndex.MONITORING_GROUP_ID)
                                                    || getEditedFieldInfo(sampleEditedInfo, fieldIndex.MONITORING_LOCATION_ID)
                                            }
                                        />
                                        {predefinedLists.customFields.map((_, i) => buildField(i))}
                                        {getNotDefaultCustomFields(predefinedLists.customFields).map(field =>
                                            <NotDefaultSampleField
                                                key={field.id}
                                                entity={sampleEdit}
                                                field={field}
                                                sampleEditedInfo={sampleEditedInfo}
                                                predefinedLists={predefinedLists}
                                                hasNoPermissions={!userCanEditSamples}
                                                onSubmit={handleSubmit}
                                                isViable
                                            />
                                        )}
                                        {bookInConfirmationEnabled &&
                                            <PlainText
                                                text={sample.bookInConfirmedBy?.name ?? ''}
                                                label='Confirmed booked in by'
                                                title={sample.bookInConfirmedBy?.email}
                                            />
                                        }
                                        <PlainText
                                            text={sample.personBookingIn.name}
                                            label='Booked in by'
                                            title={sample.personBookingIn.email}
                                        />
                                        {cfuCountVerificationRequired && !sample.awaitingCfuConfirmation &&
                                            <PlainText
                                                text={
                                                    <span>
                                                        {sample.cfuConfirmedBy.map(_ =>
                                                            <span
                                                                key={_.id}
                                                                title={_.email}
                                                            >
                                                                {_.name}
                                                                <br />
                                                            </span>
                                                        )}
                                                    </span>
                                                }
                                                label='CFU count verified by'
                                            />
                                        }
                                        <PlainText
                                            text={
                                                <span>
                                                    {sample.editedBy.map(_ =>
                                                        <span
                                                            key={_.id}
                                                            title={_.email}
                                                        >
                                                            {_.name}
                                                            <br />
                                                        </span>
                                                    )}
                                                </span>
                                            }
                                            label='Edited by'
                                        />
                                    </fieldset>
                                </div>

                                <div className='block-border p-3 mb-3'>
                                    <fieldset>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <span className='custom-legend'>Contamination</span>
                                                {!isFingerdabTwoHandsPlate && <EditedLabel editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.GROWTHS)}/>}
                                            </div>
                                            <LinkButton
                                                routeName={routes.SAMPLES_READING}
                                                className='mt-2 btn-primary d-print-none'
                                                hasNoPermissions={!userCanReadSample}
                                                routeParams={{ barcode }}
                                                testId='go-to-read'
                                            >
                                                Read this viable sample
                                            </LinkButton>
                                        </div>

                                        <div>
                                            {isFingerdabTwoHandsPlate &&
                                                <Legend>
                                                    Left hand
                                                    <EditedLabel editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.GROWTHS)}/>
                                                </Legend>
                                            }
                                            <ContaminationInfo
                                                entity={sample}
                                                isOptionalGrowth={false}
                                            />
                                            {isFingerdabTwoHandsPlate &&
                                                <div>
                                                    <hr />
                                                    <Legend>
                                                        Right hand
                                                        <EditedLabel editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.OPTIONAL_GROWTHS)}/>
                                                    </Legend>
                                                    <ContaminationInfo
                                                        entity={sample}
                                                        isOptionalGrowth
                                                    />
                                                </div>
                                            }
                                            <PlainText
                                                text={
                                                    <span>
                                                        {sample.readBy.map(_ =>
                                                            <span
                                                                key={_.id}
                                                                title={_.email}
                                                            >
                                                                {_.name}
                                                                <br />
                                                            </span>
                                                        )}
                                                    </span>
                                                }
                                                label='Read by'
                                            />
                                        </div>
                                    </fieldset>
                                </div>

                                <div className='block-border p-3'>
                                    <Attachments sampleId={sample.id} canEdit={userCanEditSamples}/>
                                </div>

                                <div className='block-border p-3 my-3'>
                                    <fieldset>
                                        <Legend>Investigations</Legend>
                                        <SampleInvestigationRefsForm
                                            entity={sampleEdit}
                                            onSubmit={handleSubmit}
                                            hasNoPermissions={!userCanEditSamples}
                                            editedInfo={getEditedFieldInfo(sampleEditedInfo, fieldIndex.REFERENCE_NUMBERS)}
                                        />
                                        {atLeastInsight &&
                                            <TrendInvestigationRefs trendInvestigationRefs={sample.trendInvestigationReferences} />
                                        }
                                    </fieldset>
                                </div>

                                {atLeastInsight &&
                                    <div className='block-border p-3 my-3'>
                                        <SampleTrends sampleId={sample.id} />
                                    </div>
                                }

                            </div>

                            <div className='col-7 width-print-100'>
                                <div className='break-block'>
                                    <div className={classnames('block-border p-3 mb-3', {'d-print-none': sample.images.length === 0})}>
                                        <PhotoGallery attachedImages={sample.images} />
                                    </div>

                                    <div className={classnames('block-border p-3', {'mt-print-3': sample.images.length === 0 })}>
                                        <fieldset>
                                            <Legend>Comments</Legend>
                                            <CommentsForm
                                                sampleId={sampleId}
                                                hasNoPermissions={!userCanEditSamples}
                                            />
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default SampleEditPage

const memConvertSampleToSampleEdit = memoize(convertSampleToSampleEdit)

function useSampleEdit(sample: SampleDetails | undefined, editableFields: CustomField[]) {
    return sample && memConvertSampleToSampleEdit(sample, editableFields)
}

function useSample() {
    const sampleId = useSelector(_ => _.router.route!.params.id as string)
        , currentSampleId = useRef(sampleId)
        , loadSample = useAction(actions.loadSample)
        , loadPredefinedLists = useAction(predefinedListsActions.loadPredefinedLists)
        , loadSampleComments = useAction(actions.loadSampleComments)
        , [sample, setSample] = useState<SampleDetails>()
        , editSampleField = useAction(actions.editSampleField)
        , getSampleEditedInfo = useAction(actions.getSampleEditedInfo)
        , [sampleEditedInfo, setSampleEditedInfo] = useState<SampleEditedInfo[]>([])

    const load = useCallback(
        () => Promise.all([
                loadSample(sampleId),
                getSampleEditedInfo(sampleId),
                loadSampleComments(sampleId),
                loadPredefinedLists(),
            ]).then(([sample, editedInfo]) => {
                if (currentSampleId.current === sample.id) {
                    setSample(sample)
                    setSampleEditedInfo(editedInfo)
                }
            }
        ),
        [sampleId, loadSample, loadSampleComments, loadPredefinedLists, getSampleEditedInfo]
    )

    useEffect(
        () => {
            currentSampleId.current = sampleId

            load()

            return () => {
                editSampleField('')
            }
        },
        [sampleId, load, editSampleField]
    )

    return [sampleId, sample, sampleEditedInfo, load] as const
}

function useBookInConfirmationEnabled() {
    const contextId = useSelector(_ => _.auth.user?.membership.contextId)
        , loadContext = useAction(contextActions.loadContext)
        , context = useSelector(_ => _.contexts.contexts.find(_ => _.id === contextId))

    useEffect(
        () => {
            if (contextId)
                loadContext(contextId)
        },
        [contextId, loadContext]
    )

    return context?.bookInConfirmationEnabled ?? false
}

function useConfirmBookingInHandler(sampleId: string, load: () => Promise<void>) {
    const confirmBookIn = useAction(actions.confirmBookIn)

    function confirmBookingInHandler() {
        return confirmBookIn(sampleId).then(load)
    }

    return confirmBookingInHandler
}

function useSubmitHandler(
    sample: SampleDetails | undefined,
    sampleEdit: SampleDetailsEdit | undefined,
    sampleTypes: SampleType[],
    endTimeRequired: boolean,
    load: () => Promise<void>,
    getVacantExpectation: (_: MonitoringGroupProps) => Promise<VacantExpectationDialogResult>,
) {
    const sampleTypeChanged = useAction(reasonActions.sampleTypeChanged)
        , saveChanges = useAction(actions.saveChanges)
        , addSuccess = useAction(toastActions.addSuccess)

    if (sample == null || sampleEdit == null)
        return () => Promise.resolve()

    return function handleSubmit(newSampleEdit: SampleDetailsEdit, approvalInfo?: ApprovalInfo) {
        const oldSampleEdit = sampleEdit
            , newSampleTypeId = getFieldValue(newSampleEdit.fields, fieldIndex.SAMPLE_TYPE_ID)
            , oldSampleTypeId = getFieldValue(oldSampleEdit.fields, fieldIndex.SAMPLE_TYPE_ID)
            , newSampleType = sampleTypes.find(_ => _.id === newSampleTypeId)
            , oldSampleType = sampleTypes.find(_ => _.id === oldSampleTypeId)

        sampleTypeChanged({
            isSettlePlateChanged: !endTimeRequired && newSampleType?.sampleType !== SETTLE_PLATE && oldSampleType?.sampleType === SETTLE_PLATE,
            isPlateTypeChangedToSettlePlate: !endTimeRequired && newSampleType?.sampleType === SETTLE_PLATE && oldSampleType?.sampleType !== SETTLE_PLATE,
            isFingerdabTwoHandsPlateChanged: newSampleType?.sampleType !== FINGERDAB_TWO_HANDS_PLATE && oldSampleType?.sampleType === FINGERDAB_TWO_HANDS_PLATE,
        })

        const expectationFieldsChanged = !!diffObject(getExpectationQueryProps(oldSampleEdit.fields), getExpectationQueryProps(newSampleEdit.fields))
                , oldMonitoringGroupId = getMonitoringGroupId(oldSampleEdit.fields)
                , expectationPromise = expectationFieldsChanged
                    ? getVacantExpectation({ fields: newSampleEdit.fields, id: oldMonitoringGroupId, sampleId: sample.id })
                    : Promise.resolve({ fields: newSampleEdit.fields })

        return expectationPromise
            .then(_ =>
                saveChanges({ sample, oldSampleEdit, newSampleEdit: { ...newSampleEdit, fields: _.fields }, approvalInfo })
            )
            .then(load)
            .then(_ => addSuccess(messages.CHANGES_SAVED))
            .then(noop)
    }
}
