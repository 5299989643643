import { classnames, useMemo, useRef } from '_/facade/react'
import type { FormRenderProps} from 'react-final-form'
import { Field } from 'react-final-form'
import FieldForm from '../field-form'
import Error from '_/components/form/field-error'
import Select from '_/components/downshift-select'
import { CheckboxField, showFieldError } from '_/components/form'
import FormattedText from '_/features/text/formatted-text'
import InlineActions from '../inline-actions'
import EditedLabel from '../edited-label'

import * as t from '_/model/text/text'
import type { SampleEditedInfo } from '_/model/sample/sample'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type { SelectionFieldValue, FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import { notRecordedPath, valuePath, findFieldValuePos, getFieldValue, getFieldNotRecorded } from '../../helpers'
import type { ValidationResult} from '_/utils/form/validate'
import { required } from '_/utils/form/validate'
import { VOID_ID } from '_/utils/tree'
import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'
import { formatActiveState } from '_/utils/format/common'
import { shallowUpdate } from '_/utils/object'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'

interface Props {
    entity: SampleDetailsEdit | NonViableSampleEdit
    field: CustomField
    onSubmit: (sample: SampleDetailsEdit | NonViableSampleEdit) => Promise<void>
    hasNoPermissions: boolean
    editedInfo: SampleEditedInfo | undefined
    isViable?: boolean
}

function SelectionFieldForm(props: Props) {
    const position = findFieldValuePos(props.entity.fields, props.field.index)
        , fieldSettings = props.isViable ? props.field.viableSettings : props.field.nonViableSettings

    function tryRestoreInactiveSelectionItems() {
        const selectedValue = currentSelectedValue()
            , activeSelectionItems = props.field.selectionFieldValues?.filter(_ => _.isActive) ?? []
            , inactiveSelectionItems = props.field.selectionFieldValues?.filter(_ => !_.isActive)

        if (!selectedValue || selectedValue.isActive)
            return activeSelectionItems

        let missingSelectionItem = inactiveSelectionItems?.find(_ => _.id == selectedValue.id)
        if (!missingSelectionItem)
            return activeSelectionItems

        missingSelectionItem = shallowUpdate(missingSelectionItem, { name: t.plainText(formatActiveState(missingSelectionItem.name, missingSelectionItem.isActive)) })

        return activeSelectionItems.concat([missingSelectionItem])
    }

    function currentSelectedValue(): SelectionFieldValue | undefined {
        const { field, entity } = props
            , value = getFieldValue(entity.fields, field.index)

        return field.selectionFieldValues?.find(_ => _.id === value)
    }

    function validate(entity: Partial<SampleDetailsEdit>) {
        const existedFields = entity.fields || []
            , resultFields: Partial<FieldValues>[] = []
            , result: ValidationResult<SampleDetailsEdit> = {}
            , selectedValue = getFieldValue(entity.fields, props.field.index)
            , selectedValueNotRecorded = getFieldNotRecorded(entity.fields, props.field.index)
            , selectedValueId = selectedValue === VOID_ID ? undefined : selectedValue
            , customSelectionValue = !selectedValueNotRecorded && fieldSettings.required && required(props.field.fieldName)(selectedValueId)

        if (customSelectionValue)
            resultFields[findFieldValuePos(existedFields, props.field.index)] = { value: customSelectionValue }

        return Object.assign(result, { fields: resultFields })
    }

    function handleChange(sample: Partial<SampleDetailsEdit>, form: FormRenderProps) {
        const selectionField = sample.fields && sample.fields[position]

        if (selectionField && selectionField.notRecorded) {
            if (!fieldSettings.notRecorded) // in case when field was N\R but later admin changed settings and N/R is not allowed anymore
                form.form.change(notRecordedPath(position), false)

            if (fieldSettings.notRecorded && selectionField.value !== undefined)
                form.form.change(valuePath(position), undefined)
        }
    }

    function handleSubmit(subEntity: SampleDetailsEdit) {
        const fields = subEntity.fields
            , sampleEdit = { ...props.entity, fields }

        return props.onSubmit(sampleEdit)
    }

    function isNotRecorded(form: FormRenderProps, pos: number) {
        const notRecordedState = form.form.getFieldState(notRecordedPath(pos))
        return !!(notRecordedState && notRecordedState.value)
    }

    const selectedValue = currentSelectedValue()
        , selectedValueFormatted = selectedValue ? formatActiveState(selectedValue.name, selectedValue.isActive) : [t.emptyTextNode()]
        , selectionField = props.entity.fields[position]
        , initialValues = useMemo(
            () => ({
                fields: props.entity.fields,
            }),
            [props.entity.fields]
        )
        , selectionNotRecordedRef = useRef<HTMLInputElement>(null)

    return (
        <FieldForm
            formId={props.field.fieldName}
            label={props.field.fieldName}
            testId={`sample-field-${props.field.index}`}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onChange={handleChange}
            validate={validate}
            hasNoPermissions={props.hasNoPermissions}
            renderView={() =>
                <span className='form-control-plaintext fw-bold'>
                    <FormattedText text={selectionField.notRecorded ? [t.systemTextNode('Not recorded')] : selectedValueFormatted} />
                    <EditedLabel editedInfo={props.editedInfo}/>
                </span>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div className='d-flex align-items-start'>
                    <div className='flex-fill'>
                        <Field name={valuePath(position)} render={_ =>
                            <>
                                <Select
                                    autocomplete
                                    entities={tryRestoreInactiveSelectionItems()}
                                    calcId={_ => _.id}
                                    calcName={_ => _.name}
                                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                                    input={_.input}
                                    disabled={isNotRecorded(form, position)}
                                    onKeyDown={_ => focusElementOnAltNInput(selectionNotRecordedRef, _)}
                                    autoFocus
                                    testId={`field-${props.field.index}`}
                                />
                                <Error field={_} />
                            </>
                        } />
                        {fieldSettings.notRecorded &&
                            <CheckboxField
                                id={notRecordedPath(position)}
                                name={notRecordedPath(position)}
                                tabIndex={isNotRecorded(form, position) ? 0 : -1}
                                inputRef={selectionNotRecordedRef}
                            >
                                Not recorded
                            </CheckboxField>
                        }
                    </div>
                    <InlineActions form={form} onSave={handleSave} onCancel={handleCancel} testId={`sample-field-${props.field.index}`} />
                </div>
            }
        />
    )
}

export default SelectionFieldForm
