import * as ap from '_/model/analysis/aggregation-period'

import type { AnalysisFilter } from '_/model/analysis/filter/types'
import type TimeService from '_/services/time-service'
import type { FloorPlanSubFilterValue } from './floor-plan'
import { recalculateDynamicExposureDate } from '_/features/analysis/filter/helpers'
import type { DateTime } from '_/model/date-time'

function calculateDifference(from: DateTime, to: DateTime, aggregationPeriod: ap.AggregationPeriod | undefined, timeService: TimeService) {
    const fromDayEnd = timeService.ctzDayEnd(from)
        , diff = () => {
            switch (aggregationPeriod) {
                case ap.DAY:
                    return timeService.daysDifference(fromDayEnd, to)
                case ap.WEEK:
                    return timeService.weeksDifference(fromDayEnd, to)
                case ap.MONTH:
                default:
                    return timeService.monthsDifference(fromDayEnd, to)
            }
        }

    return Math.round(diff() + 1)
}

function calculateExportSliderData(filter: AnalysisFilter, subFilter: FloorPlanSubFilterValue, timeService: TimeService) {
    const { exposureStartDateTo, exposureStartDateFrom, aggregationPeriod } = {
            ...filter,
            ...recalculateDynamicExposureDate(filter, timeService),
        }
        , ticks = calculateDifference(exposureStartDateFrom, exposureStartDateTo, aggregationPeriod, timeService)

    return {
        ticks,
        position: (subFilter.timeSliderPosition ?? ticks) - 1,
        cumulativeView: subFilter.cumulativeView,
    }
}

function calculateTimeSliderDate(startDate: DateTime, endDate: DateTime, aggregationPeriod: ap.AggregationPeriod | undefined, timeService: TimeService, sliderPosition: number | undefined) {
    const max = calculateDifference(startDate, endDate, aggregationPeriod, timeService)
        , timeSliderPosition = sliderPosition ?? max
        , toDate = () => {
            switch (aggregationPeriod) {
                case ap.DAY:
                    return timeService.addCtzDays(startDate, timeSliderPosition - 1)
                case ap.WEEK: {
                    if (timeSliderPosition == max)
                        return endDate

                    const endWeek = timeService.ctzWeekEnd(startDate)

                    return timeService.addCtzDays(endWeek, (timeSliderPosition - 1) * 7)
                }
                case ap.MONTH:
                default: {
                    if (timeSliderPosition == max)
                        return endDate

                    const newMonth = timeService.addCtzMonths(startDate, timeSliderPosition - 1)
                        , { year, month } = timeService.ctzTimeStruct(newMonth)

                    return timeService.ctz(year, month, timeService.daysInMonth(year, month))
                }
            }
        }
    return timeService.ctzDayEnd(toDate())
}

function calculateStartDate(aggregationPeriod: ap.AggregationPeriod | undefined, startDate: DateTime, timeSliderDate: DateTime, cumulativeView: boolean, timeService: TimeService) {
    const fromDate = () => {
        switch (aggregationPeriod) {
            case ap.DAY: return timeSliderDate
            case ap.WEEK: {
                const timeSliderDateWeekDay = timeService.ctzWeekDay(timeSliderDate)

                if (timeService.daysDifference(startDate, timeSliderDate) < 7 && timeService.ctzWeekDay(startDate) <= timeSliderDateWeekDay)
                    return startDate

                return timeService.ctzWeekStart(timeSliderDate)
            }
            case ap.MONTH:
            default: {
                const {year, month} = timeService.ctzTimeStruct(timeSliderDate)
                    , startDateStruct = timeService.ctzTimeStruct(startDate)

                if (month === startDateStruct.month && year === startDateStruct.year)
                    return startDate

                return timeService.ctz(year, month, 1)
            }
        }
    }

    return cumulativeView
        ? startDate
        : timeService.ctzDayStart(fromDate())
}

function calculateTimeSliderMaxPosition(filter: AnalysisFilter, timeService: TimeService) {
    const computedExposureDateRange = recalculateDynamicExposureDate(filter, timeService)
        , dateFrom = filter.exposureStartDateFrom ?? computedExposureDateRange.exposureStartDateFrom
        , dateTo = filter.exposureStartDateTo ?? computedExposureDateRange.exposureStartDateTo

    return calculateDifference(dateFrom, dateTo, filter.aggregationPeriod, timeService)
}

function calculateLinkParams(filter: AnalysisFilter, timeService: TimeService) {
    const toDate = calculateTimeSliderDate(filter.exposureStartDateFrom!, filter.exposureStartDateTo!, filter.aggregationPeriod, timeService, filter.timeSliderPosition)
        , fromDate = calculateStartDate(filter.aggregationPeriod, filter.exposureStartDateFrom!, toDate, filter.cumulativeView!, timeService)

    return {
        exposureStartDateFrom: fromDate,
        exposureStartDateTo: toDate,
    }
}

function disableCumulativeView(from: DateTime | undefined, to: DateTime | undefined, aggregationPeriod: ap.AggregationPeriod | undefined, timeService: TimeService) {
    if (from === undefined || to === undefined)
        return true

    return calculateDifference(from, to, aggregationPeriod, timeService) <= 1
}

export {
    calculateDifference,
    calculateTimeSliderDate,
    calculateStartDate,
    calculateTimeSliderMaxPosition,
    calculateLinkParams,
    disableCumulativeView,
    calculateExportSliderData,
}
