import type OrganismsBreakdownSeries from '_/model/analysis/organisms-breakdown-series'
import { Table } from '_/components/table'

interface Props{
    series: OrganismsBreakdownSeries
}

function PieChartTabularView(props: Props) {
    return (
        <Table>
            <thead className='text-center'>
                <tr>
                    <th className='w-50'>Identifications</th>
                    <th className='w-50'>Value</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {props.series.series.map((s, i) =>
                    <tr key={i}>
                        <td>{s.identification.name}</td>
                        <td>{s.count}</td>
                    </tr>
                )}
            </tbody>
        </Table>
    )
}

export default PieChartTabularView
