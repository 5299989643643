import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import * as f from '_/components/form'
import FormChangesObserver from '_/components/form/form-changes-observer'

import { React, useState, useSelector, useRef, useAction, useEffect, useCallback } from '_/facade/react'
import { memoize, noop } from '_/utils/function'

import SampleSearch from './sample-search'
import type { PhotoGalleryRef } from '_/features/smart-check/ui/photo-gallery'
import PhotoGallery from '_/features/smart-check/ui/photo-gallery'

import type Sample from '_/model/sample/sample'
import type SampleReading from '_/model/sample/reading/sample-reading'
import type { PredefinedLists } from '_/model/app-state'
import type { ObjectionableOrganismEffectiveView } from '_/model/objectionable-organisms/objectionable-organism'

import * as a from '../actions'
import * as sca from '_/features/smart-check/actions'
import * as wa from '_/features/unsaved-changes/actions'
import * as ta from '_/features/toasts/actions'
import * as sa from '_/features/spinner/actions'
import * as oa from '_/features/predefined-lists/objectionable-organisms/actions'
import * as ia from '_/features/predefined-lists/identifications/actions'
import * as pa from '_/features/predefined-lists/redux/actions'
import * as ca from '_/features/contexts/actions'

import * as fi from '_/constants/custom-field-index'

import SampleInformation from './sample-information'
import Identifications from './identifications'
import validate, { isImageRequiredError, onlyCorrectionMpnValueSelected } from '_/model/sample/reading/validate'
import normalize from '_/model/sample/reading/normalize'
import * as h from '_/model/sample/reading/helpers'
import SampleAuditTrails from '_/components/sample-audit-trail-list'
import SampleComments from './sample-comments'
import * as sampleMessages from '../messages'
import { getEditableSampleCustomFields, getGeneratedBy, calculateTotalCFU, getFieldValue } from '../helpers'
import type { NewSampleComment } from '_/model/sample/comment'
import Button from '_/components/button'
import type { SmartInfo } from '_/model/smart-check/image'
import LimitBreachWarning from './limit-breach-warning'
import ImageField from './image-field'
import type ApprovalInfo from '_/model/critical-change-reason/types'
import type { AllIdentifications } from '_/model/predefined-lists/identifications/identifications'
import IdentificationsReference from './identifications-reference'
import OverridePhotoRequiredReasonModal from '_/features/samples/reading/override-photo-required-reason-modal'
import { electronicSignatureSettingsEnabled } from '_/model/critical-change-reason/helpers'
import * as ss from '_/model/context/electronic-signature-settings'
import type * as it from '_/model/sample/identification-type'
import { dropFields } from '_/utils/object'
import type { Guid } from '_/model/guid'
import type WebcamState from '_/model/smart-check/webcam-state'
import WebcamLifecycle from '_/features/smart-check/webcam/webcam-lifecycle'
import { useTimeService } from '_/components/time'
import { useDialog, useUnsavedChangesTracker } from '_/hooks/shared-hooks'
import type Context from '_/model/context/context'

interface State {
    pictureError: string | undefined
    captureInProgress: boolean

    sample: Sample | undefined
    detachedImages: SmartInfo[]
    objectionableOrganisms: ObjectionableOrganismEffectiveView[]

    newComment: string | undefined
    commentValid: boolean
    compromised: boolean
    compromisedPreviousValue: boolean

    userMissedGrowths: boolean
    showPatchSuggestedGrowthCount: boolean
    awaitedCFUCountVerification: boolean
}

function SampleReadingPage() {
    const [state, setState] = useReadingState()
        , [webcam, setWebcam] = useState<WebcamState>({ type: 'unknown' })
        , predefinedLists = useSelector(_ => memAugmentCustomFields(_.predefinedLists))
        , signatureSettings = useSelector(_ => _.auth.electronicSignatureSettings)
        , trail = useSelector(_ => _.samples.trail)
        , comments = useSelector(_ => _.samples.sampleComments)
        , user = useSelector(_ => _.auth.user)
        , context = useSelector(s => s.contexts.contexts.find(_ => _.id === s.auth.user?.membership.contextId))
        , [calcInitialValues] = useState(() => memoize(h.convertToReading))
        , timeService = useTimeService()
        , [handleAddComment, handleCommentChange] = useAddCommentHandler(state, setState)
        , [cfuVerificationRequired, canVerifyCfu] = useCfuVerification(state)
        , [inputRef, handlePhotoTaking, handlePhotoTaken, handlePhotoRemoved] = usePhoto(state, setState)
        , sampleLocationGrade = state.sample && getFieldValue(state.sample.fields, fi.EXPOSURE_LOCATION_GRADE_ID) as Guid
        , [galleryRef, overridePhotoRequirementProps, limitBreachWarningProps, handleSaveAndNext, handleSubmit, handleSave, submitDisabled, handleChange]
            = useForm(state, setState, sampleLocationGrade, context)
        , handleCancel = useCancelHandler(state, setState)
        , handleLoadSample = useLoadSampleHandler(setState)
        , organismTypeArguments = useInit(handleLoadSample)

    function isObjectionable(identificationType: it.IdentificationType, identificationValue: string) {
        return h.isObjectionable(identificationType, identificationValue, sampleLocationGrade, state.objectionableOrganisms)
    }

    const sample = state.sample
        , gradeLimitsValue = state.sample && h.getGradeLimitsValue(state.sample, predefinedLists.grades, predefinedLists.limits)
        // , automatedCFUCountVerificationEnabled = h.automatedCFUCountVerificationEnabled(state.sample, predefinedLists.grades)
        , automatedCFUCountVerificationEnabled = false
        , noIdRequiredEnabled = context?.noIdRequiredEnabled ?? false
        , sampleTypeId = getFieldValue(sample?.fields, fi.SAMPLE_TYPE_ID)
        , correctionalMpnValueEnabled = !!predefinedLists.sampleTypes.find(_ => _.id === sampleTypeId)?.allowCorrectionalMpnValueIdType
        , photoRequirementErrorShown = state.pictureError && state.pictureError.indexOf('Picture is required') > -1

    return (
        <div className='container-fluid h-100'>
            <WebcamLifecycle onInit={setWebcam} />
            {overridePhotoRequirementProps &&
                <OverridePhotoRequiredReasonModal
                    isSignatureRequired={electronicSignatureSettingsEnabled(ss.OVERRIDE_PHOTO_REQUIREMENT, signatureSettings)}
                    onCancel={overridePhotoRequirementProps.cancel}
                    onSubmit={overridePhotoRequirementProps.accept}
                    reason={overridePhotoRequirementProps.reason ?? ''}
                />
            }
            <div className='row h-100 d-print-block'>
                <div className='col-3 h-100 d-flex flex-column flex-fill width-print-100 p-0 m-0 height-print-auto'>
                    {user &&
                        <span className='d-none d-print-block'>
                            {getGeneratedBy(timeService, user.name, user.email)}
                        </span>
                    }
                    {!sample
                        ? <SampleSearch onLoad={handleLoadSample} />
                        : <Form
                            onSubmit={_ => handleSave(_)}
                            initialValues={calcInitialValues(sample)}
                            mutators={{...arrayMutators}}
                            validate={(_: SampleReading) => validate(_, state.sample)}
                            render={form =>
                                <>
                                    <FormChangesObserver target={READING_CONTAMINATION} form={form} onChange={handleChange} includePristineNotification />
                                    <form
                                        className='d-flex flex-column flex-fill h-0 height-print-auto'
                                        onSubmit={form.handleSubmit}
                                    >
                                        <ImageField detachedImages={state.detachedImages} />
                                        <div className='d-flex flex-column flex-fill p-4 overflow-auto'>
                                            <SampleInformation sample={sample} predefinedLists={predefinedLists} />
                                            <hr/>

                                            <IdentificationsReference isFingerdabTwoHandsPlate={h.isTwoHandsPlate(sample)}>
                                                <Identifications
                                                    isOptional={false}
                                                    defaultInputRef={inputRef}
                                                    isFingerdabTwoHandsPlate={h.isTwoHandsPlate(sample)}
                                                    isOrganismObjectionable={isObjectionable}
                                                    sampleCfuConfirmationEnabled={canVerifyCfu(form.values)}
                                                    organismTypeArguments={organismTypeArguments}
                                                    onCfuConfirmed={cfuVerificationRequired ? () => handleSave(form.values, true) : undefined}
                                                    noIdRequiredEnabled={noIdRequiredEnabled}
                                                    correctionalMpnValueEnabled={correctionalMpnValueEnabled}
                                                />
                                                {h.isTwoHandsPlate(sample) &&
                                                    <div>
                                                        <hr/>
                                                        <Identifications
                                                            isOptional
                                                            isFingerdabTwoHandsPlate
                                                            isOrganismObjectionable={isObjectionable}
                                                            sampleCfuConfirmationEnabled={false}
                                                            organismTypeArguments={organismTypeArguments}
                                                            noIdRequiredEnabled={noIdRequiredEnabled}
                                                            correctionalMpnValueEnabled={correctionalMpnValueEnabled}
                                                        />
                                                    </div>
                                                }
                                            </IdentificationsReference>

                                            <hr/>
                                            <SampleComments
                                                onAddComment={handleAddComment}
                                                onCommentChange={handleCommentChange}
                                                comments={comments}
                                                compromised={sample.compromised}
                                            />
                                            <hr/>
                                            <div className='mb-5'>
                                                <SampleAuditTrails trail={trail} />
                                            </div>
                                            <LimitBreachWarning
                                                isOpen={!!limitBreachWarningProps}
                                                onClose={limitBreachWarningProps?.cancel ?? noop}
                                                predefinedLists={predefinedLists}
                                                sample={sample}
                                                reading={form.values}
                                                limits={gradeLimitsValue && gradeLimitsValue.limits}
                                                grade={gradeLimitsValue && gradeLimitsValue.grade}
                                            />
                                        </div>
                                        {state.userMissedGrowths
                                            ? <div className='sample-reading__confirm-zero-growth mt-auto text-success border-top px-4 py-3 d-print-none'>
                                                {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                                                {automatedCFUCountVerificationEnabled
                                                    ? 'Double check the photo before continuing'
                                                    : 'Are you sure there are no growths? Double check the photo before continuing'}
                                                <br />
                                                <Button
                                                    className='btn-primary mt-3'
                                                    disabled={submitDisabled(form)}
                                                    onClick={() => handleSubmit(form)}
                                                >
                                                    Confirm reading and next
                                                </Button>
                                            </div>
                                            : <div className='d-flex flex-row justify-content-between mt-auto border-top px-4 py-3 d-print-none'>
                                                {photoRequirementErrorShown
                                                    ? <Button
                                                        className='btn-primary'
                                                        onClick={() => handleSubmit(form, true)}
                                                        testId='read-without-photo'
                                                    >
                                                        Save without photo
                                                    </Button>
                                                    : <Button
                                                        className='btn-primary'
                                                        disabled={submitDisabled(form)}
                                                        onClick={() => handleSaveAndNext(form)}
                                                        title={onlyCorrectionMpnValueSelected(form.values.identifications) ? 'Cannot save a sample with only correctional MPN identification' : ''}
                                                        testId='read-save'
                                                    >
                                                        Save and next
                                                    </Button>
                                                }
                                                <Button
                                                    className='btn-secondary'
                                                    onClick={handleCancel}
                                                    testId='read-cancel'
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        }
                                    </form>
                                </>
                            }
                        />
                    }
                </div>
                <div className='col-9 h-100 bg-light width-print-100 height-print-auto'>
                    <PhotoGallery
                        ref={galleryRef}
                        className='h-100'
                        blank={!sample}
                        webcam={webcam}
                        entityId={state.sample?.id}
                        plateType={getFieldValue(state.sample?.fields, fi.PLATE_TYPE)}
                        attachedImages={state.sample?.images}
                        detachedImages={state.detachedImages.map(_ => _.imageId)}
                        photoError={state.pictureError}
                        onPhotoTaking={handlePhotoTaking}
                        onPhotoTaken={handlePhotoTaken}
                        onPhotoRemoved={handlePhotoRemoved}
                        showGrowthCountOverPatch={state.showPatchSuggestedGrowthCount}
                        zeroGrowthCheckEnabled={!!context?.zeroGrowthVerificationEnabled}
                        automatedGrowthCountVerificationEnabled={automatedCFUCountVerificationEnabled}
                    />
                </div>
            </div>
        </div>
    )
}

export default SampleReadingPage

type SetState = (state: Partial<State>) => void

const DEFAULT_STATE: State = {
        pictureError: undefined,
        captureInProgress: false,

        sample: undefined,
        detachedImages: [],
        objectionableOrganisms: [],

        newComment: undefined,
        commentValid: true,
        compromised: false,
        compromisedPreviousValue: false,

        userMissedGrowths: false,
        showPatchSuggestedGrowthCount: false,
        awaitedCFUCountVerification: false,
    }
    , READING_COMMENT = 'reading-comment'
    , READING_CONTAMINATION = 'reading-contamination'

const memAugmentCustomFields = memoize((list: PredefinedLists): PredefinedLists => {
    const customFields = getEditableSampleCustomFields(list.customFields)
    return { ...list, customFields }
})

function useReadingState() {
    const [state, setStateDefault] = useState(DEFAULT_STATE)
        , [setState] = useState(() =>
            (state: Partial<State>) => setStateDefault(_ => state === DEFAULT_STATE ? DEFAULT_STATE : ({ ..._, ...state }))
        )

    return [state, setState] as const
}

function useLoadSampleHandler(setState: SetState) {
    const loadObjectionableOrganismEffectiveList = useAction(oa.loadObjectionableOrganismEffectiveList)
        , loadSampleComments = useAction(a.loadSampleComments)
        , loadSampleTrail = useAction(a.loadSampleTrail)
        , loadPredefinedLists = useAction(pa.loadPredefinedLists)
        , showSpinner = useAction(sa.showSpinner)
        , hideSpinner = useAction(sa.hideSpinner)

    function handleLoadSample(sample: Sample) {
        showSpinner()

        Promise
            .all([
                loadObjectionableOrganismEffectiveList(),
                loadSampleComments(sample.id),
                loadSampleTrail(sample.id),
                loadPredefinedLists(),
            ])
            .then(([objectionableOrganisms]) => {
                setState({
                    sample,
                    detachedImages: [],
                    objectionableOrganisms,
                    compromised: sample.compromised,
                    compromisedPreviousValue: sample.compromised,
                })
            })
            .finally(hideSpinner)
    }

    return handleLoadSample
}

function useInit(handleLoadSample: (sample: Sample) => void) {
    const contextId = useSelector(_ => _.auth.user?.membership.contextId)
        , barcode = useSelector(_ => _.router.route!.params.barcode)
        , [organismTypeArguments, setOrganismTypeArguments] = useState<AllIdentifications>({
            organismType: [],
            catalase: [],
            oxidase: [],
            oxidationFermentation: [],
            coagulase: [],
        })
        , loadContext = useAction(ca.loadContext)
        , loadAllIdentifications = useAction(ia.loadAllIdentifications)
        , loadSampleByBarcode = useAction(a.loadSampleByBarcode)

    useEffect(
        () => {
            if (!contextId)
                return

            loadContext(contextId)
            loadAllIdentifications(true).then(setOrganismTypeArguments)

            if (!barcode)
                return

            loadSampleByBarcode({ barcode, includeNullified: false, matchExactly: true })
                .then(_ => _ && handleLoadSample(_))
        },
        // eslint-disable-next-line
        []
    )

    return organismTypeArguments
}

function useAddCommentHandler(state: State, setState: SetState) {
    const addSampleComment = useAction(a.addSampleComments)
        , hasUnsavedChanges = useAction(wa.hasUnsavedChanges)
        , changeCompromisedAction = useAction(a.changeCompromised)
        , addSuccess = useAction(ta.addSuccess)

    function handleAddComment(comment: NewSampleComment) {
        changeCompromised({reason: comment.message})
            .then(() =>
                addSampleComment({ sampleId: state.sample!.id, sampleComments: [comment] }),
            )
            .then(() => addSuccess(sampleMessages.CHANGES_SAVED))
            .then(() => setState({ newComment: undefined }))

        hasUnsavedChanges(false, READING_COMMENT)
    }

    function changeCompromised(approvalInfo: ApprovalInfo) {
        if (state.compromisedPreviousValue !== state.compromised) {
            return changeCompromisedAction({
                id: state.sample!.id,
                compromised: state.compromised,
                checkBreach: true,
                approvalInfo,
            })
            .then(() => setState({ compromisedPreviousValue: state.compromised }))
        }

        return Promise.resolve()
    }

    function handleCommentChange(newComment: string, compromised: boolean, commentValid: boolean) {
        setState({ newComment, compromised, commentValid })

        if (!newComment && compromised === state.compromisedPreviousValue)
            hasUnsavedChanges(false, READING_COMMENT)
        else
            hasUnsavedChanges(true, READING_COMMENT)
    }

    return [handleAddComment, handleCommentChange] as const
}

function useCfuVerification(state: State) {
    const user = useSelector(_ => _.auth.user)
        , grades = useSelector(_ => _.predefinedLists.grades)
        , cfuVerificationRequired = h.cfuVerificationRequired(state.sample, grades)

    function canVerifyCfu(formValues: SampleReading) {
        if (!cfuVerificationRequired)
            return false

        const sample = state.sample
            , currentTotalCfu = calculateTotalCFU(formValues.identifications) + calculateTotalCFU(formValues.optionalIdentifications)
            , initialTotalCfu = calculateTotalCFU(sample?.identifications) + calculateTotalCFU(sample?.optionalIdentifications)

        return currentTotalCfu === initialTotalCfu && !h.isSampleLastReadByUser(state.sample, user)
    }

    return [cfuVerificationRequired, canVerifyCfu] as const
}

function usePhoto(state: State, setState: SetState) {
    const inputRef = useRef<HTMLInputElement>(null)

    function handlePhotoTaking(isPhotoTaking: boolean) {
        if (isPhotoTaking)
            setState({ captureInProgress: true, userMissedGrowths: false })
        else
            setState({ captureInProgress: false })
    }

    function handlePhotoTaken(info: SmartInfo) {
        setState({
            detachedImages: [info, ...state.detachedImages]
        })

        inputRef.current?.focus()

        return Promise.resolve()
    }

    function handlePhotoRemoved(imageId: Guid) {
        setState({
            detachedImages: state.detachedImages.filter(_ => _.imageId !== imageId)
        })
    }

    return [inputRef, handlePhotoTaking, handlePhotoTaken, handlePhotoRemoved] as const
}

function useForm(
    state: State,
    setState: SetState,
    sampleLocationGrade: Guid | undefined,
    context: Context | undefined,
) {
    const galleryRef = useRef<PhotoGalleryRef | null>(null)
        , previousValues = useRef<SampleReading>()
        , predefinedLists = useSelector(_ => memAugmentCustomFields(_.predefinedLists))
        , showSuggestedGrowthRegionsAndCountsChanged = useAction(sca.showSuggestedGrowthRegionsAndCountsChanged)
        , checkPhotoRequirementSignature = useAction(a.checkOverridePhotoRequirementSignature)
        , attachImages = useAction(a.attachImages)
        , addSampleComment = useAction(a.addSampleComments)
        , readSample = useAction(a.readSample)
        , verifyCFUCount = useAction(a.verifyCFUCount)
        , addSuccess = useAction(ta.addSuccess)
        , hasUnsavedChanges = useAction(wa.hasUnsavedChanges)
        , [showOverridePhotoRequirement, overridePhotoRequirementProps] = useDialog<ApprovalInfo, { reason: string | undefined }>()
        , [showLimitBreachWarning, limitBreachWarningProps] = useDialog<void>()

    function goToSampleSearchScreen() {
        setState(DEFAULT_STATE)
    }

    function handleSaveAndNext(form: FormRenderProps<SampleReading>) {
        if (!context?.zeroGrowthVerificationEnabled) {
            handleSubmit(form)
            return
        }

        const imageUploaded = state.detachedImages.length > 0
        if (!imageUploaded) {
            // Either photo not required or validation will trigger image upload
            handleSubmit(form)
            return
        }

        const mostSuspiciousResult = h.getMostSuspiciousImage(state.detachedImages)
            , totalCfu = calculateTotalCFU(form.values.identifications) + calculateTotalCFU(form.values.optionalIdentifications)
            // , automatedCFUCountEnabled = h.automatedCFUCountVerificationEnabled(state.sample, predefinedLists.grades)
            // , result = automatedCFUCountEnabled
            //     ? h.verifyGrowthCount(mostSuspiciousResult.growthCountResult!.growths, totalCfu)
            //     : h.verifyZeroGrowth(mostSuspiciousResult.growthCountResult!.growths, totalCfu)
            , result = h.verifyZeroGrowth(mostSuspiciousResult.growthCountResult!.growths, totalCfu)

        if (result?.userMissedGrowths)
            form.form.change('totalCfuAtLatestMismatch', totalCfu)

        if (result) {
            if (result.showSuggestedGrowthRegions)
                showSuggestedGrowthRegionsAndCountsChanged(true)

            setState(dropFields(result, 'showSuggestedGrowthRegions'))
            galleryRef.current?.focusImage(mostSuspiciousResult.imageId)
            return
        }

        handleSubmit(form)
    }

    function handleSubmit(form: FormRenderProps<SampleReading, Partial<SampleReading>>, photoSkipped = false) {
        form.form.change('photoSkipped', photoSkipped)
        form.form.submit()
    }

    function handleSave(formValues: SampleReading, verifyingCfu = false) {
        const sample = state.sample!

        function attachImage() {
            if (!formValues.detachedImages)
                return

            return attachImages({
                sampleId: sample.id,
                images: formValues.detachedImages.map(_ => _.id),
            })
        }

        function addComment() {
            if (!state.newComment?.trim())
                return

            return addSampleComment({
                    sampleId: sample.id,
                    sampleComments: [{ message: state.newComment, isRead: true }],
                })
                .then(() => setState({ newComment: undefined }))
        }

        function getReasonHandler(reason?: string) {
            return formValues.photoSkipped ? showOverridePhotoRequirement({ reason }) : Promise.resolve({ reason: '' })
        }

        const hasNewLimitBreach = h.hasNewLimitBreach(sample, formValues, predefinedLists.grades, predefinedLists.limits, state.compromised)
        const smartCheckEnabled = !!context?.smartCheckEnabled
            , compromisedData = state.compromisedPreviousValue !== state.compromised
                ? {
                    compromised: state.compromised,
                    compromisedReason: state.newComment!,
                }
                : undefined
            , pictureError = !formValues.photoSkipped && isImageRequiredError(sample, formValues, predefinedLists.grades, predefinedLists.limits, state.compromised)
                ? smartCheckEnabled
                    ? 'Picture is required. Smart Checks cannot be performed without a picture.'
                    : 'Picture is required.'
                : undefined

        if (pictureError) {
            setState({ pictureError, awaitedCFUCountVerification: verifyingCfu })
            return Promise.reject({ isHandled: true })
        }

        return checkPhotoRequirementSignature({ sampleId: sample.id, photoSkipped: !!formValues.photoSkipped, getReasonHandler })
            .then(skipPhotoApprovalInfo =>
                readSample({
                    originalSample: sample,
                    reading: formValues,
                    skipPhotoApprovalInfo,
                    compromisedData,
                })
                .then((approvalInfo = { reason: '' }) =>
                    Promise
                        .all([attachImage(), addComment()])
                        .then(() =>
                            verifyingCfu || state.awaitedCFUCountVerification && formValues.photoSkipped
                                ? verifyCFUCount({ id: sample.id, approvalInfo })
                                : Promise.resolve()
                        )
                        .then(() => {
                            addSuccess(sampleMessages.SAMPLE_SAVED)

                            hasUnsavedChanges(false, READING_COMMENT)
                            hasUnsavedChanges(false, READING_CONTAMINATION)

                            setState({ pictureError: undefined })

                            if (hasNewLimitBreach) {
                                // in case of navigation to other route ongoing promises will not be executed
                                return showLimitBreachWarning()
                                    .catch(goToSampleSearchScreen)
                            }

                            goToSampleSearchScreen()
                        })
                )
            )
    }

    function submitDisabled(form: FormRenderProps<SampleReading, Partial<any>>) {
        return f.submitDisabled(form)
            || onlyCorrectionMpnValueSelected(form.values.identifications)
            || !state.commentValid
            || state.captureInProgress
    }

    function handleChange(value: any, form: FormRenderProps) {
        const data = normalize(value, previousValues.current, sampleLocationGrade, state.objectionableOrganisms)

        setState({ pictureError: undefined })

        form.form.batch(
            () => data.paths.forEach(_ => form.form.change(_.path, _.value))
        )

        if (state.userMissedGrowths)
            setState({ userMissedGrowths: false })

        previousValues.current = value
    }

    return [galleryRef, overridePhotoRequirementProps, limitBreachWarningProps, handleSaveAndNext, handleSubmit, handleSave, submitDisabled, handleChange] as const
}

function useCancelHandler(state: State, setState: SetState) {
    const hasUnsavedChange = useSelector(_ => _.unsavedChange.unsavedChangeTargets.length > 0)
        , showWarning = useAction(wa.showWarning)
        , handleDiscardChanges = useCallback(
            function handleDiscardChanges() {
                setState(DEFAULT_STATE)
            },
            [setState]
        )

    useUnsavedChangesTracker(handleDiscardChanges)

    function handleCancel() {
        const noChanges = !hasUnsavedChange && !state.captureInProgress

        if (noChanges)
            handleDiscardChanges()
        else
            showWarning({ showWarning: true })
    }

    return handleCancel
}
