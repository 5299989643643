const MONTH_RECURRENCE_ON_DATE = 0
    , MONTH_RECURRENCE_ON_WEEK_DAY = 1

type MONTH_RECURRENCE_ON_DATE = typeof MONTH_RECURRENCE_ON_DATE
type MONTH_RECURRENCE_ON_WEEK_DAY = typeof MONTH_RECURRENCE_ON_WEEK_DAY

type MonthRecurrenceType = MONTH_RECURRENCE_ON_DATE | MONTH_RECURRENCE_ON_WEEK_DAY

const MONTH_RECURRENCE_TYPE = [MONTH_RECURRENCE_ON_DATE, MONTH_RECURRENCE_ON_WEEK_DAY]

export {
    MONTH_RECURRENCE_TYPE,
    MONTH_RECURRENCE_ON_DATE,
    MONTH_RECURRENCE_ON_WEEK_DAY,
    MonthRecurrenceType,
}
