interface Unsubscribe {
    (): void
}

interface OnBarcodeScanned {
    (barcode: string, currentElement: HTMLElement): void
}

/*
    Listens for the keydown events, after keys combination [Alt]+[S] was pressed
    it starts recording barcode sequence until [Tab] is pressed or untill barcode
    lenght is equal or longer than 100 chars
    There are two possible scenarios:
    - If a user is on the samples list page, or sample details page,
    it searchs for the barcode and navigates to its sample details page
    - If a user is on the booking in screen, AND the selected field is a barcode field,
    AND the barcode field is empty, the barcode is entered and the focus is moved
    to the next field
**/
function observeBarcodeScanner(barcodeObserver: OnBarcodeScanned, suppressInput = false): Unsubscribe {
    let active = false
    let barcode = ''

    function handleKeydown(event: KeyboardEvent) {
        if (event.altKey && event.key === 's') {
            // prevent firefox to show window menu on Alt+S
            event.preventDefault()
            barcode = ''
            active = true
        }
        else if (event.key === 'Tab' && active) {
            event.preventDefault()
            const scannedBarcode = barcode
            barcode = ''
            active = false
            barcodeObserver(scannedBarcode, event.target as HTMLElement)
        }
        else if (barcode.length > 100) {
            barcode = ''
            active = false
        }
    }

    function handleKeypress(event: KeyboardEvent) {
        if (!active)
            return

        barcode += event.key

        if (suppressInput)
            event.preventDefault()
    }

    window.addEventListener('keydown', handleKeydown)
    window.addEventListener('keypress', handleKeypress)

    return () => {
        window.removeEventListener('keydown', handleKeydown)
        window.removeEventListener('keypress', handleKeypress)
    }
}

export {
    observeBarcodeScanner,
}
