import { React, useState } from '_/facade/react'

import HoverMenu from '_/components/overlay/hover-menu'

import type { LimitBreach } from '_/model/reports/locations-report/types'
import * as breachType from '_/constants/sample-breach-type'
import Contaminations from '_/components/contaminations'

interface Props {
    limitBreaches: LimitBreach[]
}

function LimitBreachData(props: Props) {
    const [element, setElement] = useState<HTMLDivElement | null>(null)
        , hintContainerRef = React.createRef<HTMLDivElement>()
        , actionLimitBreaches = props.limitBreaches.filter(_ => _.sampleBreachType === breachType.ACTION_LIMIT)
        , alertLimitBreaches = props.limitBreaches.filter(_ => _.sampleBreachType === breachType.ALERT_LIMIT)

    return (
        <td className='text-end'>
            <div ref={setElement} className='d-inline-block'>
                <span className='me-2 numeric-font-tabular-nums' data-testid='limit-breaches-count'>
                    {props.limitBreaches.length}
                </span>

                <HoverMenu element={element} position='right' showTriangle>
                    <div className='legend-bubble text-white py-2 px-4 ms-1' ref={hintContainerRef}>
                        {props.limitBreaches.length === 0
                            ? <span data-testid='no-limit-breaches-section'>No limit breaches</span>
                            : <div>
                                {breachesFormat(actionLimitBreaches, 'Action limit breaches')}
                                {breachesFormat(alertLimitBreaches, 'Alert limit breaches')}
                            </div>
                        }
                    </div>
                </HoverMenu>
            </div>
        </td>
    )
}

function breachesFormat(breaches: LimitBreach[], title: string) {
    if (breaches.length === 0)
        return

    return (
        <div className='my-2'>
            <span className='fw-bold' data-testid='limit-breach-data-section-title'>{title}</span> <br/>
            {breaches.map((breach, index) => <Contaminations identifications={breach.growths} key={index} showOnlyIdentification /> )}
        </div>
    )
}

export default LimitBreachData
