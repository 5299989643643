import { React } from '_/facade/react'
import { formatFieldLabel } from '../helpers'
import * as fieldIndex from '_/constants/custom-field-index'

import * as c from '_/model/sample/list/columns'

import type CustomField from '_/model/predefined-lists/custom-field/types'
import type SampleListColumn from '_/model/sample/list/types'

interface HeaderCellProps {
    column: SampleListColumn
    fields: CustomField[]
}

function ColumnName(props: HeaderCellProps) {
    function columnName(fieldIndex: number) {
        const config = props.fields.find(_ => _.index === fieldIndex)
        return <>{config ? formatFieldLabel(config) : ''}</>
    }

    switch (props.column.type) {
        case c.EXPOSURE_DATE:
            return columnName(fieldIndex.EXPOSURE_DATE)

        case c.EXPOSURE_LOCATION:
            return columnName(fieldIndex.EXPOSURE_LOCATION_ID)

        case c.SAMPLE_NAME:
            return columnName(fieldIndex.SAMPLE_TYPE_ID)

        case c.SESSION:
            return columnName(fieldIndex.SESSION_ID)

        case c.EXPOSURE_TIME:
            return <>Exposure time</>

        case c.OPERATORS:
            return columnName(fieldIndex.OPERATORS_IDS)

        case c.BATCH_NUMBER:
            return <>Batch&nbsp;#</>

        case c.STATUS:
            return <>Status</>

        case c.TOTAL_CFU:
            return <>Total CFU / Limit breached</>

        case c.PHOTOS:
            return <>Photos</>

        case c.INVESTIGATION_REFERENCES:
            return <>Viable sample investigation refs</>

        case c.BARCODE:
            return columnName(fieldIndex.BARCODE)

        case c.USER_DEFINED:
            return columnName(props.column.fieldIndex)

        default:
            return null
    }
}

export default ColumnName
