import type { FormRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'

import { classnames, useMemo, useRef } from '_/facade/react'
import { focusElementOnAltNInput } from '_/utils/keyboard-navigation/keyboard-navigation'
import Select from '_/components/downshift-select'
import { showFieldError } from '_/components/form/helpers'
import { CheckboxField } from '_/components/form'
import type { ValidationResult } from '_/utils/form/validate'
import { required } from '_/utils/form/validate'
import { VOID_ID } from '_/utils/tree'
import FormattedText from '_/features/text/formatted-text'
import * as t from '_/model/text/text'
import * as f from '_/model/sample/format'

import type { PredefinedLists } from '_/model/app-state'
import type SampleDetails from '_/model/sample/sample-details'
import type { SampleDetailsEdit } from '_/model/sample/edit/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'
import type { SampleEditedInfo } from '_/model/sample/sample'
import EditedLabel from '../edited-label'

import * as behaviours from '_/constants/objectionable-limit-behaviour'
import * as fieldIndex from '_/constants/custom-field-index'

import FieldForm from '../field-form'
import Error from '_/components/form/field-error'
import InlineActions from '../inline-actions'
import { tryRestoreInactiveExposureLocation } from '../../sample-edit/helpers'
import { findFieldValuePos, valuePath, notRecordedPath, getFieldValue, getFieldNotRecorded } from '../../helpers'
import { hasLocationChildren, getBookableLocations, fullNameLocationList } from '_/utils/exposure-location'
import { AT_REST, IN_OPERATION } from '_/model/predefined-lists/action-alert-limit/monitoring-state'
import MonitoringStateField from '_/components/monitoring-state-field'
import { formatActiveState } from '_/utils/format/common'
import type NonViableSampleView from '_/model/non-viable-sample/types'
import type { NonViableSampleEdit } from '_/model/non-viable-sample/booking/types'

interface Props {
    sample: SampleDetails | NonViableSampleView
    entity: SampleDetailsEdit | NonViableSampleEdit
    field: CustomField
    onSubmit(sample: SampleDetailsEdit | NonViableSampleEdit): Promise<void>
    predefinedLists: PredefinedLists
    hasNoPermissions: boolean
    editedInfo: SampleEditedInfo | undefined
    isViable?: boolean
}

function LocationForm(props: Props) {
    const fieldSettings = props.isViable ? props.field.viableSettings : props.field.nonViableSettings
        , locations = tryRestoreInactiveExposureLocation(
            props.sample,
            fullNameLocationList(props.predefinedLists.exposureLocations)
        )

    const position = findFieldValuePos(props.entity.fields, fieldIndex.MONITORING_POSITION)
    let previousLocation = getFieldValue(props.entity.fields, fieldIndex.MONITORING_POSITION)

    function currentLocation(): ListExposureLocation {
        const { entity } = props
            , location = getFieldValue(entity.fields, fieldIndex.MONITORING_POSITION)

        return locations.find(_ => _.id === location) ?? {} as ListExposureLocation
    }

    function validate(entity: Partial<SampleDetailsEdit>) {
        const existedFields = entity.fields || []
            , resultFields: Partial<FieldValues>[] = []
            , result: ValidationResult<SampleDetailsEdit> = {}
            , location = getFieldValue(entity.fields, fieldIndex.MONITORING_POSITION)
            , locationNotRecorded = getFieldNotRecorded(entity.fields, fieldIndex.MONITORING_POSITION)

        const locationId = location === VOID_ID ? undefined : location
            , exposureLocation = !locationNotRecorded && required(props.field.fieldName)(locationId)

        if (exposureLocation)
            resultFields[findFieldValuePos(existedFields, fieldIndex.MONITORING_POSITION)] = { value: exposureLocation }

        return Object.assign(result, { fields: resultFields })
    }

    function breachText() {
        if (!props.isViable)
            return ''

        const behaviour = (props.sample as SampleDetails).behaviour

        if (behaviour === behaviours.BREACH_ALERT_LIMIT)
            return '(alert for objectionable organisms)'

        if (behaviour === behaviours.BREACH_ACTION_LIMIT)
            return '(action required for objectionable organisms)'

        return ''
    }

    function handleChange(sample: Partial<SampleDetailsEdit>, form: FormRenderProps) {
        const locationField = sample.fields && sample.fields[position]

        if (locationField?.value !== previousLocation)
            form.form.change('monitoringState', IN_OPERATION)

        if (locationField && locationField.notRecorded) {
            if (!fieldSettings.notRecorded) {// in case when field was N\R but later admin changed settings and N/R is not allowed anymore
                form.form.change(notRecordedPath(position), false)

                if (sample.monitoringState === undefined)
                    form.form.change('monitoringState', IN_OPERATION)
            }

            if (fieldSettings.notRecorded) {
                if (locationField.value !== undefined)
                    form.form.change(valuePath(position), undefined)
            }
        }
        previousLocation = locationField?.value
    }

    function handleSubmit(subEntity: SampleDetailsEdit) {
        const fields = subEntity.fields
            , sampleEdit = { ...props.entity, fields, monitoringState: subEntity.monitoringState }

        return props.onSubmit(sampleEdit)
    }

    function isNotRecorded(form: FormRenderProps, pos: number) {
        const notRecordedState = form.form.getFieldState(notRecordedPath(pos))
        return !!(notRecordedState && notRecordedState.value)
    }

    function getDisplayedLocations() {
        return getBookableLocations(locations)
    }

    const location = currentLocation()
        , locationField = props.entity.fields[position]
        , atRest = props.entity.monitoringState === AT_REST
        , locationName = ([] as t.Text).concat(
            locationField.notRecorded
                ? t.systemTextNode('Not recorded')
                : formatActiveState(location.pathName, location.isActive),
            atRest ? t.systemTextNode(' (At rest)') : []
        )
        , actionLimit = getFieldValue(props.entity.fields, fieldIndex.EXPOSURE_LOCATION_ACTION_LIMIT)
        , alertLimit = getFieldValue(props.entity.fields, fieldIndex.EXPOSURE_LOCATION_ALERT_LIMIT)
        , initialValues = useMemo(
            () => ({
                fields: props.entity.fields,
                monitoringState: props.entity.monitoringState,
            }),
            [props.entity.fields, props.entity.monitoringState]
        )
        , locationNotRecordedRef = useRef<HTMLInputElement>(null)
        , monitoringStateRef = useRef<HTMLInputElement>(null)

    return (
        <FieldForm
            formId='location'
            label={props.field.fieldName}
            testId={`sample-field-${props.field.index}`}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onChange={handleChange}
            validate={validate}
            hasNoPermissions={props.hasNoPermissions}
            renderView={() =>
                <span className='form-control-plaintext'>
                    <span className='fw-bold' data-testid='sample-location-info'>
                        <FormattedText text={locationName} />
                        <EditedLabel editedInfo={props.editedInfo}/>
                    </span>
                    <br />
                    <FormattedText
                        className='text-muted'
                        testId='sample-location-info'
                        text={[
                            t.defaultTextNode('Grade '),
                            ...f.formatExposureLocationGrade(props.sample.monitoringState, props.sample.fields, props.predefinedLists),
                            t.systemTextNode(' ' + breachText())
                        ]}
                    />
                    {props.isViable &&
                        <>
                            <br />
                            <span className='text-muted'>Alert limit {alertLimit}</span>
                            <br />
                            <span className='text-muted'>Action limit {actionLimit}</span>
                        </>
                    }
                </span>
            }
            renderEdit={(form, handleSave, handleCancel) =>
                <div className='d-flex align-items-start'>
                    <div className='flex-fill'>
                        <Field name={valuePath(position)} render={_ =>
                            <>
                                <Select
                                    autocomplete
                                    entities={getDisplayedLocations()}
                                    calcId={_ => _.id}
                                    calcName={_ => _.pathName}
                                    className={classnames('form-control', { 'is-invalid': showFieldError(_.meta) })}
                                    calcDisabled={_ => hasLocationChildren(_, getDisplayedLocations())}
                                    input={_.input}
                                    disabled={isNotRecorded(form, position)}
                                    onKeyDown={_ => focusElementOnAltNInput(monitoringStateRef, _)}
                                    autoFocus
                                    testId={`field-${props.field.index}`}
                                />
                                <Error field={_} />
                            </>
                        } />
                        <MonitoringStateField
                            id='monitoringState'
                            monitoringState={form.values.monitoringState}
                            inputRef={monitoringStateRef}
                            onKeyDown={_ => focusElementOnAltNInput(locationNotRecordedRef, _)}
                        />
                        {fieldSettings.notRecorded &&
                            <CheckboxField
                                id={notRecordedPath(position)}
                                name={notRecordedPath(position)}
                                tabIndex={isNotRecorded(form, position) ? 0 : -1}
                                inputRef={locationNotRecordedRef}
                                onKeyDown={_ => focusElementOnAltNInput(monitoringStateRef, _)}
                            >
                                Not recorded
                            </CheckboxField>
                        }
                    </div>
                    <InlineActions form={form} onSave={handleSave} onCancel={handleCancel} testId={`sample-field-${props.field.index}`} />
                </div>
            }
        />
    )
}

export default LocationForm
