import { useSelector, useAction } from '_/facade/react'
import * as toastType from '_/constants/toast-type'
import { Close } from '_/components/button'

import { deleteToast } from './actions'

function ToastsView() {
    const toasts = useSelector(_ => _.toasts)
        , handleDelete = useAction(deleteToast)
        , errorList = toasts.list.filter(_ => _.type === toastType.ERROR)
        , successList = toasts.list.filter(_ => _.type === toastType.SUCCESS)

    if (errorList.length + successList.length === 0)
        return null

    return (
        <div className='position-absolute d-print-none' id='toasts' style={{
            zIndex: 2000,
            top: '72px',
            left: '8px',
        }}>
            {errorList.length > 0 &&
                <div className='alert alert-danger'>
                    {errorList.map((_, index) =>
                        <div key={index} className='clearfix'>
                            <Close onClick={() => handleDelete(_)} testId='close-alert-danger' />
                            <span className='align-text-bottom user-formatted-text' data-testid='alert-danger-message'>{_.message}</span>
                        </div>
                    )}
                </div>
            }
            {successList.length > 0 &&
                <div className='alert alert-success'>
                    {successList.map((_, index) =>
                        <div key={index} className='clearfix'>
                            <Close onClick={() => handleDelete(_)} testId='close-alert-success'/>
                            <span className='align-text-bottom' data-testid='alert-success-message'>{_.message}</span>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default ToastsView
