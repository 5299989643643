const UNIT = 0
    , ROOM = 1
    , CABINET = 2
    , UNSPECIFIED_LOCATION = 3
    , POSITION = 4

type LocationType = never
   | typeof UNIT
   | typeof ROOM
   | typeof CABINET
   | typeof UNSPECIFIED_LOCATION
   | typeof POSITION

const LOCATION_TYPE: { id: LocationType, name: string }[] = [
    {
        id: UNIT,
        name: 'Unit',
    },
    {
        id: ROOM,
        name: 'Room',
    },
    {
        id: CABINET,
        name: 'Cabinet',
    },
    {
        id: UNSPECIFIED_LOCATION,
        name: 'Unspecified location',
    },
    {
        id: POSITION,
        name: 'Position',
    },
]

type LOCATION_TYPE = typeof LOCATION_TYPE

export {
    LOCATION_TYPE as default,
    UNIT,
    ROOM,
    CABINET,
    UNSPECIFIED_LOCATION,
    POSITION,
    LocationType,
}
