import { classnames } from '_/facade/react'

import type SampleDetails from '_/model/sample/sample-details'
import * as growthsStatus from '_/constants/growth-status'
import FormattedText from '_/features/text/formatted-text'

import { calculateTotalCFU, isBreachedByBehaviour, contaminations } from '../helpers'
import PlainText from './plain-text'
import { computeContaminationInfo } from './helpers'

interface Props {
    entity: SampleDetails
    isOptionalGrowth: boolean
}

function ContaminationInfo(props: Props) {
    const info = computeContaminationInfo(props)

    return (
        <div>
            <PlainText text={info.growthsStatusName} label='Growth status' testId='growth-status' />
            <PlainText
                text={
                    <div data-testid='total-cfu'>
                        {info.growthsStatusId === growthsStatus.UNIDENTIFIED_ID ? '' : calculateTotalCFU(info.growths)}
                        {(info.limitBreachedByTotalCfu.actionLimitBreached || info.limitBreachedByTotalCfu.alertLimitBreached) &&
                            <span className={classnames('ms-2 p-1 text-uppercase small fw-bold rounded text-white', {
                                'label-alert--color': info.limitBreachedByTotalCfu.alertLimitBreached,
                                'label-action--color': info.limitBreachedByTotalCfu.actionLimitBreached,
                            })}>
                                {info.limitBreachedByTotalCfu.actionLimitBreached ? 'Action' : 'Alert'}
                            </span>
                        }
                    </div>
                }
                label='Total CFUs'
            />
            <PlainText
                text={
                    <div className='user-formatted-text'>
                        {contaminations(info.growths).map((row, rowIdx) =>
                            <div key={rowIdx} className='my-1'>
                                {row.types.map((type, typeIdx) =>
                                    <div key={typeIdx} data-testid={`identification-${rowIdx}-${typeIdx}`}>
                                        <span className={typeIdx !== 0 ? 'invisible' : undefined}>{row.cfuCount} x </span>
                                        <FormattedText text={type} />
                                        {row.objectionable && typeIdx === 0 && (isBreachedByBehaviour(props.entity.behaviour)
                                            ? <span  className={`ms-2 p-1 text-uppercase small fw-bold rounded text-white ${info.alertBreached ? 'label-alert--color' : 'label-action--color'}`} data-testid={`objectionable-breach-${rowIdx}`}>
                                                Objectionable - {info.alertBreached ? 'Alert' : 'Action'}
                                            </span>
                                            : <span className='ms-2 p-1 text-uppercase small fw-bold rounded text-white bg-secondary' data-testid={`objectionable-breach-${rowIdx}`}>
                                                Objectionable
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                }
                label='Identification'
            />
        </div>
    )
}

export default ContaminationInfo
