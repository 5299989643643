import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import { updateTransaction } from '_/model/predefined-lists/trend-settings/transaction'

import type PredefinedListsService from '_/services/predefined-lists-service'

import * as actions from './actions'

const factory = (service: PredefinedListsService): EffectsFactory => () => [
    handler(actions.loadTrendSettingsList, service.trend.list),
    handler(actions.loadTrendSettings, service.trend.get),
    handler(actions.loadTrendSettingsTrail, service.trend.getTrail),
    handler(actions.createTrendSettings, service.trend.create),
    handler(actions.removeTrendSettings, service.trend.remove),
    handler(actions.saveTrendSettings, data =>
        updateTransaction(
            data.id,
            data.oldTrendSettings,
            data.newTrendSettings,
            service,
        )
    ),

]

export default factory
