import type { FormRenderProps } from 'react-final-form'
import { Form } from 'react-final-form'
import tzdata from 'tzdata'
import * as luxon from 'luxon'

import { classnames, useState, useSelector, useAction, useEffect, useCallback } from '_/facade/react'
import { noop } from '_/utils/function'
import * as tree from '_/utils/tree'

import { Modal, ModalHeader, ModalBody, ModalFooter } from '_/components/modal'
import { SelectField, TextField, Submit, submitDisabled, CheckboxField } from '_/components/form'
import AuditTrails from '_/components/audit-trail-list'
import Button, { Close } from '_/components/button'
import UnsavedChangesObserver from '_/components/form/form-changes-observer'
import TabNavbar from '_/components/tab-navbar'

import type Context from '_/model/context/context'
import CONTEXT_LEVEL, * as contextLevels from '_/constants/context-level'
import TIERS from '_/constants/tiers'
import * as tiers from '_/constants/tiers'
import * as routes from '_/constants/routes'

import type { ContextForm } from '_/model/context/edit/types'
import validateEdit from '_/model/context/edit/validate-edit'
import validateCreate from '_/model/context/edit/validate-create'

import * as actions from '../actions'
import * as routerActions from '_/features/routing/actions'
import * as warningActions from '_/features/unsaved-changes/actions'
import * as disableActions from '_/features/confirmation/actions'
import * as confirmationActions from '_/features/confirmation/actions'
import * as h from '_/model/context/edit/helpers'

import ElectronicSignatures from './electronic-signatures'
import Devices from './devices/devices'
import IpWhitelist from './ip-whitelist'
import { diffObject } from '_/utils/object'
import { PasswordExpirations } from '_/model/password-expiration/constants'
import type { IpRestrictionValidationResponse } from '_/model/context/edit/ip-restriction'
import { isProdEnvironment } from '_/model/environment/helpers'
import { HOUR } from '_/model/password-expiration/constants'
import { isSystemAdmin, isSiteAdmin } from '_/model/users/helpers'
import { isSmartControl } from '_/model/permissions/helpers'
import type { AuditTrail } from '_/model/audit-trail/types'

const TAB_DATA = 0
    , TAB_AUDIT_TRAIL = 1
    , TAB_SECURITY = 2
    , TAB_DEVICES = 3

type Tab = typeof TAB_DATA | typeof TAB_AUDIT_TRAIL | typeof TAB_SECURITY | typeof TAB_DEVICES

interface Props {
    contexts: Context[]
}

function ContextModal(props: Props) {
    const [context, contextTrail, reloadTrail] = useContext()
        , contexts = props.contexts.filter(_ => !_.isDeleted)
        , permissions = useSelector(_ => _.auth.permissions)
        , [activePage, setActivePage] = useState<Tab>(TAB_DATA)
        , initialValue = h.getFormValue(context)
        , navigateToList = useNavigateToList()
        , [handleSubmit, isSubmitting] = useSubmitHandler(context, navigateToList)
        , ipRestrictionValidator = useAction(actions.validateIpRestriction)
        , validate = getValidator(context, contexts, ipRestrictionValidator, isSubmitting)
        , handleChangeFormValues = formValuesChangeHandler(context ? undefined : contexts)
        , handleDisable = useDisableHandler(context, navigateToList)
        , handleRestore = useRestoreHandler(context, navigateToList)
        , disabled = !!context?.isDeleted
        , [timezones] = useState(() =>
            Object.keys(tzdata.zones)
                .filter(_ => luxon.DateTime.utc().setZone(_).isValid)
        )
        , user = useSelector(_ => _.auth.user)
        , showDeviceTab = user && isSystemAdmin(user) || isSiteAdmin(user)
        , editMode = !!context
        , atLeastInsight = context && context.tier > tiers.COMPLIANCE

    function disableTier(tier: tiers.Tier) {
        if (!editMode)
            return false

        return context.tier === tiers.COLONY_COUNTER
                ? tier !== tiers.COLONY_COUNTER
                : tier === tiers.COLONY_COUNTER
    }

    return (
        <Modal isOpen onClose={navigateToList}>
            <Form
                onSubmit={handleSubmit}
                validate={validate}
                initialValues={initialValue}
                render={form =>
                    <form onSubmit={form.handleSubmit}>
                        <UnsavedChangesObserver form={form} onChange={handleChangeFormValues} />

                        <ModalHeader className='pb-0 border-bottom-0'>
                            <div className='pt-2 flex-fill'>
                                <div className='d-flex justify-content-between'>
                                    <h4 data-testid='context-modal-title'>{editMode ? context.description : 'New context'}</h4>
                                    <Close onClick={navigateToList} testId='context-modal-close' />
                                </div>
                                <TabNavbar>
                                    <Button
                                        onClick={() => setActivePage(TAB_DATA)}
                                        className={classnames('btn-link navbar-tab me-4', { active: activePage === TAB_DATA })}
                                    >
                                        General
                                    </Button>
                                    {context && context.level === contextLevels.SITE && isSmartControl(context.tier) &&
                                        <Button
                                            onClick={() => setActivePage(TAB_SECURITY)}
                                            className={classnames('btn-link navbar-tab me-4', { active: activePage === TAB_SECURITY })}
                                            testId='context-security'
                                        >
                                            Security
                                        </Button>
                                    }
                                    {context?.level === contextLevels.SITE && showDeviceTab &&
                                        <Button
                                            onClick={() => setActivePage(TAB_DEVICES)}
                                            className={classnames('btn-link navbar-tab me-4', { active: activePage === TAB_DEVICES })}
                                            testId='context-security'
                                        >
                                            Devices
                                        </Button>
                                    }
                                    <Button
                                        onClick={() => setActivePage(TAB_AUDIT_TRAIL)}
                                        className={classnames('btn-link navbar-tab', { active: activePage === TAB_AUDIT_TRAIL })}
                                        disabled={!editMode}
                                        testId='context-audit-trail'
                                    >
                                        Audit trail
                                    </Button>
                                </TabNavbar>
                            </div>
                        </ModalHeader>
                        <ModalBody className='context-modal__body--max-height'>
                            <div className='context-modal__body-height'>
                                <div className={classnames(activePage !== TAB_DATA && 'd-none')}>
                                    <TextField
                                        name='name'
                                        disabled={disabled}
                                        testId='field-name'
                                    >
                                        Name
                                    </TextField>
                                    {!context &&
                                        <div>
                                            <SelectField
                                                name='level'
                                                entities={CONTEXT_LEVEL
                                                    .filter(_ => _.id !== contextLevels.SYSTEM && _.id !== contextLevels.NONE)}
                                                calcId={_ => _.id}
                                                calcName={_ => _.name}
                                                testId='field-level'
                                            >
                                                Select type
                                            </SelectField>
                                            {form.values.level === contextLevels.SITE &&
                                                <SelectField
                                                    name='parentId'
                                                    entities={contexts.filter(_ => _.level === contextLevels.ORGANISATION)}
                                                    calcId={_ => _.id}
                                                    calcName={_ => _.name}
                                                    testId='field-organisation'
                                                >
                                                    Select organisation
                                                </SelectField>
                                            }
                                        </div>
                                    }
                                    <SelectField
                                        name='timeZoneName'
                                        entities={timezones}
                                        calcId={_ => _}
                                        calcName={_ => _}
                                        autocomplete
                                        disabled={disabled}
                                        testId='field-time-zone'
                                    >
                                        Time setting
                                    </SelectField>
                                    {editMode && form.values.timeZoneName !== form.initialValues.timeZoneName &&
                                        <div className='alert alert-warning user-formatted-text' data-testid='timezone-warning'>
                                            Switching timezone may cause sample exposure date shift by one day (including samples with exposure start time N/A or N/R).<br/>
                                            All other users within the site will be automatically logged out after timezone change.
                                            Changes will be applied for them with new log in. Changes for you are applied immediately without starting a new session
                                        </div>
                                    }
                                    {form.values.level === contextLevels.SITE &&
                                        <SelectField
                                            name='passwordExpiration'
                                            entities={PasswordExpirations.filter(_ => isProdEnvironment() ? _.id !== HOUR : true)}
                                            calcId={_ => _.id}
                                            calcName={_ => _.name}
                                            disabled={disabled}
                                            testId='password-expiration'
                                        >
                                            Passwords should expire
                                        </SelectField>
                                    }
                                    {form.values.level === contextLevels.ORGANISATION &&
                                        <SelectField
                                            name='tier'
                                            entities={TIERS}
                                            calcId={_ => _.id}
                                            calcName={_ => _.name}
                                            calcDisabled={_ => disableTier(_.id)}
                                            autocomplete
                                            hasNoPermissions={!permissions.manageSiteTier}
                                            disabled={disabled}
                                            testId='field-tier'
                                        >
                                            Select tier
                                        </SelectField>
                                    }
                                    {editMode && form.values.tier !== form.initialValues.tier &&
                                        <div className='alert alert-warning user-formatted-text'>
                                            All users within the organisation will be automatically logged out after tier change
                                        </div>
                                    }
                                    {form.values.level === contextLevels.SITE &&
                                        <>
                                            {(form.values.tier === undefined || isSmartControl(form.values.tier)) &&
                                                <>
                                                    <CheckboxField
                                                        name='webcamEnabled'
                                                        id='webcamEnabled'
                                                        disabled={disabled}
                                                        hasNoPermissions={!permissions.manageSiteWebcam}
                                                        className='mb-3'
                                                        testId='field-webcam-enabled'
                                                    >
                                                        Enable webcam
                                                    </CheckboxField>
                                                    <CheckboxField
                                                        name='smartCheckEnabled'
                                                        id='smartCheckEnabled'
                                                        disabled={disabled || form.values.zeroGrowthVerificationEnabled}
                                                        className='mb-3'
                                                        testId='field-smart-check-enabled'
                                                    >
                                                        Enable image smart check
                                                    </CheckboxField>
                                                    {/* <CheckboxField
                                                        name='zeroGrowthVerificationEnabled'
                                                        id='zeroGrowthVerificationEnabled'
                                                        disabled={disabled || !form.values.smartCheckEnabled || form.values.moldPredictorEnabled}
                                                        hasNoPermissions={!permissions.changeZeroGrowthVerificationSetting || form.values.tier === undefined || form.values.tier < tiers.INSIGHT}
                                                        className='mb-3 ms-4'
                                                    >
                                                        Turn Automated zero growth verification on/off
                                                    </CheckboxField>
                                                    <div className='ps-4'>
                                                        <CheckboxField
                                                            name='moldPredictorEnabled'
                                                            id='moldPredictorEnabled'
                                                            disabled={disabled || !form.values.zeroGrowthVerificationEnabled}
                                                            hasNoPermissions={!permissions.changeMoldPredictorSetting || form.values.tier === undefined || form.values.tier < tiers.INSIGHT}
                                                            className='mb-3 ms-4'
                                                        >
                                                            Enable mould predictor
                                                        </CheckboxField>
                                                    </div> */}
                                                    <CheckboxField
                                                        name='bookInConfirmationEnabled'
                                                        id='bookInConfirmationEnabled'
                                                        disabled={disabled}
                                                        className='mb-3'
                                                        testId='field-book-in-confirmation-enabled'
                                                    >
                                                        Require book in confirmation
                                                    </CheckboxField>
                                                    <CheckboxField
                                                        name='noIdRequiredEnabled'
                                                        id='noIdRequiredEnabled'
                                                        disabled={disabled}
                                                        className='mb-3'
                                                        testId='field-no-id-required'
                                                    >
                                                        No ID required
                                                    </CheckboxField>
                                                </>
                                            }
                                            {/*form.values.tier === tiers.COLONY_COUNTER &&
                                                <CheckboxField
                                                    name='moldPredictorEnabled'
                                                    id='moldPredictorEnabled'
                                                    disabled={disabled || !form.values.zeroGrowthVerificationEnabled}
                                                    hasNoPermissions={!permissions.changeMoldPredictorSetting}
                                                    className='mb-3'
                                                >
                                                    Enable mould predictor
                                                </CheckboxField>
                                            */}
                                        </>
                                    }
                                </div>

                                <div className={classnames(activePage !== TAB_SECURITY && 'd-none')}>
                                    <ElectronicSignatures
                                        disabled={disabled}
                                        atLeastInsight={!!atLeastInsight}
                                        hasNoPermissions={!permissions.editElectronicSignatureSettings}
                                    />
                                    <br />
                                    <IpWhitelist
                                        disabled={disabled}
                                        whitelistDisabled={!form.values.ipWhitelistEnabled}
                                        hasNoPermissions={!permissions.editIpRestrictionSetting}
                                    />
                                </div>

                                {activePage === TAB_AUDIT_TRAIL && <AuditTrails trail={contextTrail} />}
                                {activePage === TAB_DEVICES && context && <Devices id={context.id} signatureSettings={context.electronicSignatureSettings} onReloadTrail={reloadTrail} />}
                            </div>
                        </ModalBody>
                        <ModalFooter className='border-top-0 modal-footer-height'>
                            {activePage !== TAB_AUDIT_TRAIL && activePage !== TAB_DEVICES &&
                                <div className={`d-flex flex-fill ${context && activePage === TAB_DATA && context.parentId !== tree.VOID_ID
                                                                        ? 'justify-content-between'
                                                                        : 'justify-content-end'}`}
                                >
                                    {activePage === TAB_DATA && context && context.parentId !== tree.VOID_ID &&
                                        <div>
                                            <Button
                                                className={classnames('text-danger bg-transparent border-0 me-2', {
                                                    disabled: !permissions.createRemoveContexts,
                                                })}
                                                onClick={handleDisable}
                                                disabled={context.isDeleted}
                                                hasNoPermissions={!permissions.createRemoveContexts}
                                                testId='disable-context'
                                            >
                                                Disable
                                            </Button>
                                            <Button
                                                className={classnames('bg-transparent border-0 me-2', {
                                                    disabled: !permissions.createRemoveContexts,
                                                })}
                                                onClick={handleRestore}
                                                disabled={!context.isDeleted}
                                                hasNoPermissions={!permissions.createRemoveContexts}
                                            >
                                                Restore
                                            </Button>
                                        </div>
                                    }
                                    <div>
                                        <Button className='text-muted bg-transparent border-0 me-2' onClick={navigateToList} testId='cancel-changes'>Cancel</Button>
                                        <Submit disabled={submitDisabled(form)} testId='save-context'>{editMode ? 'Save changes' : 'Create'}</Submit>
                                    </div>
                                </div>
                            }
                        </ModalFooter>
                    </form>
                }
            />
        </Modal>
    )
}

export default ContextModal


function useContext() {
    const loadContextTrail = useAction(actions.loadContextTrail)
        , loadContext = useAction(actions.loadContext)
        , contextId = useSelector(_ => _.router.route!.params.id)
        , [context, setContext] = useState<Context | undefined>()
        , [trail, setTrail] = useState<AuditTrail | undefined>()
        , loadTrail = useCallback(
            () => {
                if (contextId)
                    loadContextTrail(contextId).then(setTrail)
            },
            [loadContextTrail, contextId]
        )

    useEffect(
        () => {
            if (!contextId)
                return

            loadContext(contextId).then(setContext)
            loadTrail()
        },
        [contextId, loadTrail, loadContext]
    )

    return [context, trail, loadTrail] as const
}

function useNavigateToList() {
    const navigateTo = useAction(routerActions.navigateTo)
        , navigateToList = useCallback(() => navigateTo(routes.SETTINGS_CONTEXTS), [navigateTo])

    return navigateToList
}

function useDisableHandler(context: Context | undefined, onClose: () => void) {
    const showDisableWarning = useAction(disableActions.showDisableConfirmationModal)
        , disableContext = useAction(actions.disableContext)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , signatureSettings = context?.electronicSignatureSettings ?? []

    function handleDisable() {
        showDisableWarning(`Are you sure you want to disable ${context?.description}?`)
            .then(() => disableContext({ id: context!.id, signatureSettings }))
            .then(() => hasUnsavedChanges(false))
            .then(onClose)
    }

    return handleDisable
}

function useRestoreHandler(context: Context | undefined, onClose: () => void) {
    const showConfirmation = useAction(confirmationActions.showConfirmationModal)
        , resumeContext = useAction(actions.resumeContext)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , signatureSettings = context?.electronicSignatureSettings ?? []

    function handleRestore() {
        const restoreMessage = `Are you sure you want to restore ${context?.description}?`
            , restoreMessageBody = context!.level === contextLevels.SITE
                ? '\nThe organization context will also be restored if it has been marked as disabled'
                : ''

        return showConfirmation(restoreMessage + restoreMessageBody)
            .then(() => resumeContext({ id: context!.id, signatureSettings }))
            .then(() => hasUnsavedChanges(false))
            .then(onClose)
    }

    return handleRestore
}

function useSubmitHandler(context: Context | undefined, onClose: () => void) {
    const showConfirmation = useAction(confirmationActions.showConfirmationModal)
        , saveChanges = useAction(actions.saveChanges)
        , createContext = useAction(actions.createContext)
        , hasUnsavedChanges = useAction(warningActions.hasUnsavedChanges)
        , signatureSettings = context?.electronicSignatureSettings ?? []
        , ipInWhitelistValidator = useAction(actions.validateIpInWhitelist)
        // Used to suppress validation error about non-unique name
        // when context is created and context list is reloaded
        // but there some other operations still in progress.
        // Better to rework in future, but during regression I don't want
        // to introduce bigger changes.
        , [isSubmitting, setIsSubmitting] = useState(false)

    function handleSaveChanges(value: ContextForm) {
        const oldContext = h.getContextEditFromForm(h.getFormValue(context))
            , newContext = h.getContextEditFromForm(value)
            , diff = diffObject(oldContext, newContext)!

        setIsSubmitting(true)
        return h.getConfirmationInfo(diff, value, ipInWhitelistValidator)
            .then(_ => _ ? showConfirmation(_.message, _.warning) : Promise.resolve())
            .then(() => saveChanges({ id: context!.id, oldContext, newContext, signatureSettings }))
            .then(() => hasUnsavedChanges(false))
            .then(onClose)
            .then(noop)
            .finally(() => setIsSubmitting(false))
    }

    function handleCreate(value: ContextForm) {
        const augmentedValue = { ...value }
        if (value.tier === tiers.COLONY_COUNTER && value.level === contextLevels.SITE) {
            augmentedValue.smartCheckEnabled = true
            // augmentedValue.zeroGrowthVerificationEnabled = true
            augmentedValue.bookInConfirmationEnabled = false
            augmentedValue.noIdRequiredEnabled = false
        }

        setIsSubmitting(true)
        return createContext(h.getContextCreateFromForm(augmentedValue))
            .then(() => hasUnsavedChanges(false))
            .then(onClose)
            .then(noop)
            .finally(() => setIsSubmitting(false))
    }

    return [context ? handleSaveChanges : handleCreate, isSubmitting] as const
}

function getValidator(context: Context | undefined, contexts: Context[], ipRestrictionValidator: (ipRestriction: string) => Promise<IpRestrictionValidationResponse>, isSubmitting: boolean) {
    const otherContexts = contexts.filter(_ => _.id !== context?.id)

    return function (value: ContextForm) {
        const siblingContexts = value.level === contextLevels.SITE
                ? otherContexts.filter(_ => _.parentId === value.parentId)
                : value.level === contextLevels.ORGANISATION
                    ? otherContexts.filter(_ => _.level === value.level)
                    : []

        return context
            ? validateEdit(value, siblingContexts, ipRestrictionValidator)
            : validateCreate(value, siblingContexts, isSubmitting)
    }
}

function formValuesChangeHandler(contexts: Context[] | undefined) {
    return function handleChangeFormValues(values: ContextForm, form: FormRenderProps) {
        if (contexts !== undefined && values.level === contextLevels.SITE) {
            const orgTier = contexts.find(_ => _.id === values.parentId)?.tier
            form.form.change('tier', orgTier)
        }

        if (values.level !== contextLevels.SITE && values.parentId !== undefined) {
            form.form.change('parentId', undefined)
        }

        /* if (values.tier !== undefined && values.tier < tiers.INSIGHT)
            form.form.change('zeroGrowthVerificationEnabled', false) */
    }
}
