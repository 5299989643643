import type { EffectsFactory} from '_/facade/effect'
import { handler } from '_/facade/effect'
import type AnalysisService from '_/services/analysis-service'
import type AnalysisSearchFilterService from '_/services/analysis-search-filter-service'
import { noop } from '_/utils/function'

import * as actions from './actions'
import { getNotNullQuery } from '_/features/samples/filter/helpers'

const factory = (service: AnalysisService, analysisSearchFilterService: AnalysisSearchFilterService): EffectsFactory => (dispatch, getState) => [
    handler(actions.loadLinesMarkersChartSeries, _ => service.linesMarkersChartSeries(_, getState().predefinedLists.customFields)),
    handler(actions.loadOrganismsBreakdown, service.organismsBreakdown),
    handler(actions.loadOrganismTypeBreakdown, service.organismTypeBreakdown),
    handler(actions.loadStackedSampleName, service.stackedSampleName),
    handler(actions.loadContaminationFloorPlan, service.contaminationFloorPlan),
    handler(actions.loadLimitBreachFloorPlan, service.limitBreachFloorPlan),

    handler(actions.loadAnalysisSearchFilterList, () =>
        analysisSearchFilterService.list()
            .then(filters => {
                filters.forEach(filter => filter.filter = getNotNullQuery(filter.filter))
                return filters
            })
            .then(filters => dispatch(actions.analysisSearchFilterListLoaded(filters)))
            .then(noop)
    ),

    handler(actions.createAnalysisSearchFilter, sampleSearchFilter =>
        analysisSearchFilterService.create(sampleSearchFilter)
            .then(_ => dispatch(actions.loadAnalysisSearchFilterList()))
            .then(noop)
    ),

    handler(actions.removeAnalysisSearchFilter, id =>
        analysisSearchFilterService.remove(id)
            .then(_ => dispatch(actions.loadAnalysisSearchFilterList()))
            .then(noop)
    ),

    handler(actions.exportGraphCsv, service.exportGraphCsv),
    handler(actions.exportAnalysisGraphData, service.exportAnalysisGraphData)
]

export default factory
