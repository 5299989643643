import type { ContextCreate, ContextForm, ContextEdit } from './types'
import type Context from '_/model/context/context'
import { memoize } from '_/utils/function'
import { NEVER } from '_/model/password-expiration/constants'
import type { FieldsDiff } from '_/utils/object'
import type { IpInWhitelistValidationResponse } from '_/model/context/edit/ip-restriction'

function getContextCreateFromForm(form: ContextForm): ContextCreate {
    return {
        name: form.name!,
        timeZoneName: form.timeZoneName!,
        tier: form.tier!,
        parentId: form.parentId!,
        level: form.level!,

        webcamEnabled: !!form.webcamEnabled,
        smartCheckEnabled: !!form.smartCheckEnabled,
        bookInConfirmationEnabled: !!form.bookInConfirmationEnabled,
        moldPredictorEnabled: !!form.moldPredictorEnabled,
        zeroGrowthVerificationEnabled: !!form.zeroGrowthVerificationEnabled,
        passwordExpiration: form.passwordExpiration ?? NEVER,
        noIdRequiredEnabled: form.noIdRequiredEnabled ?? false,
    }
}

function getContextEditFromForm(form: ContextForm): ContextEdit {
    return {
        name: form.name!,
        timeZoneName: form.timeZoneName!,
        tier: form.tier!,

        webcamEnabled: !!form.webcamEnabled,

        smartCheckEnabled: form.smartCheckEnabled!,
        bookInConfirmationEnabled: form.bookInConfirmationEnabled!,
        zeroGrowthVerificationEnabled: form.zeroGrowthVerificationEnabled!,
        moldPredictorEnabled: form.moldPredictorEnabled!,

        electronicSignatureSettings: form.electronicSignatureSettings!,
        passwordExpiration: form.passwordExpiration ?? NEVER,

        ipRestriction: form.ipRestriction!,
        ipWhitelistEnabled: form.ipWhitelistEnabled!,
        noIdRequiredEnabled: form.noIdRequiredEnabled!
    }
}

const getFormValue = memoize(
    (context: Context | undefined): ContextForm => {
        if (!context)
            return {
                webcamEnabled: true,
            }

        return {
            name: context.name,
            timeZoneName: context.timeZoneName,
            tier: context.tier,
            parentId: context.parentId,
            level: context.level,

            webcamEnabled: !!context.webcamEnabled,
            smartCheckEnabled: context.smartCheckEnabled,
            bookInConfirmationEnabled: context.bookInConfirmationEnabled,
            zeroGrowthVerificationEnabled: context.zeroGrowthVerificationEnabled,
            moldPredictorEnabled: context.moldPredictorEnabled,
            passwordExpiration: context.passwordExpiration,

            electronicSignatureSettings: context.electronicSignatureSettings,

            ipRestriction: context.ipRestriction,
            ipWhitelistEnabled: context.ipWhitelistEnabled,
            noIdRequiredEnabled: context.noIdRequiredEnabled,
        }
    }
)

function getConfirmationInfo(diff: FieldsDiff<ContextEdit>, value: ContextForm, ipInWhitelistValidator: (whiteList: string) => Promise<IpInWhitelistValidationResponse>): Promise<{ message: string, warning?: string } | undefined> {
    const bookInConfirmationEditedAndDisabled = diff.bookInConfirmationEnabled === false
        , electronicSignatureEditedAndEnabled = !!diff.electronicSignatureSettings
        , ipWhitelistEditedAndDisabled = diff.ipWhitelistEnabled === false
        , ipConfigEdited = diff.hasOwnProperty('ipWhitelistEnabled') || diff.hasOwnProperty('ipRestriction')
        , ipRestrictionEnabled = value.ipWhitelistEnabled && value.ipRestriction !== ''
        , ipRestrictionValidationRequired = ipConfigEdited && ipRestrictionEnabled

    type MaybeMessage = false | string
    const concatMaybeMessages = (one: MaybeMessage, two: MaybeMessage): MaybeMessage =>
            one !== false && two !== false
                ? one + '\n\n' + two
                // no concatenation required, use first non false message if any
                : one || two

    const restrictionMessagePromise: Promise<MaybeMessage> = ipRestrictionValidationRequired
            ? ipInWhitelistValidator(value.ipRestriction!)
                .then(_ => !_.currentIpIsInWhitelist
                    ? `Your current IP address (${_.currentIpAddress}) is not in the list. You may not be able to access SmartControl after you save these changes.`
                    : false
                )
            : Promise.resolve(false)

    return restrictionMessagePromise.then(
        restrictionMessage => {
            const bookInConfirmationWarning = 'There might be samples awaiting book in confirmation. Are you sure you want to remove the "Awaiting book in confirmation" flag from those samples?'
                , electronicSignatureWarning = 'All active user sessions will be logged out.'
                , whiteListDisableWarning = 'Access to SmartControl will be allowed without any IP restrictions.'
                , whiteListEnableWarning = 'Access to SmartControl will be restricted to IP whitelist. All active sessions of users with unauthorized access to SmartControl will be logged out.'
                , confirmationWarning = 'Are you sure you want to continue?'

            const messages: MaybeMessage[] = [
                    bookInConfirmationEditedAndDisabled && bookInConfirmationWarning,
                    electronicSignatureEditedAndEnabled && electronicSignatureWarning,
                    ipWhitelistEditedAndDisabled && whiteListDisableWarning,
                    ipRestrictionValidationRequired! && restrictionMessage,
                    ipRestrictionValidationRequired! && whiteListEnableWarning,
                ]
                , aggregatedMessage = messages.reduce(concatMaybeMessages, false)

            const warning = bookInConfirmationEditedAndDisabled
                    ? 'Warning, this operation may take several minutes.'
                    : undefined

            if (aggregatedMessage === false)
                return undefined

            return {
                message: `${aggregatedMessage} ${confirmationWarning}`,
                warning
            }
        }
    )
}

export {
    getContextCreateFromForm,
    getContextEditFromForm,
    getFormValue,
    getConfirmationInfo,
}
