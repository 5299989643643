import { useSelector } from '_/facade/react'

import Link from '_/components/link'
import * as routes from '_/constants/routes'

import type { DayScheduleStatistic } from '_/model/scheduling/day-scheduler/types'
import { calculateScheduledBookedInSamplesCount } from '_/model/scheduling/monthly-scheduler/helpers'
import Donut from './donut'
import { paramsFilter } from '_/model/filters/helpers'

interface Props {
    dayPlanStatistic: DayScheduleStatistic
    testId?: string
}

interface IconProps {
    bgColor?: string
}

const Icon = (props: IconProps) => <div className={`day-plan-statistic_icon d-inline-block me-1 ${props.bgColor || ''}`}/>

const DayPlanStatistic = (props: Props) => {
    const { bookedInSamplesCount, missedSamplesCount, remainingSamplesCount, notInUseSamplesCount } = props.dayPlanStatistic
        , unscheduledSamplesCount = bookedInSamplesCount - calculateScheduledBookedInSamplesCount(props.dayPlanStatistic)
        , auth = useSelector(_ => _.auth)

    return (
        <div className='d-flex justify-content-between mx-2'>
            <div className='day-plan-donut--width'>
                <Donut stats={props.dayPlanStatistic} />
            </div>
            <Link
                routeName={routes.SCHEDULING_MONITORING_OVERVIEW}
                routeParams={paramsFilter({ date: props.dayPlanStatistic.date })}
                hasNoPermissions={!auth.permissions.readMonitoringOverview}
                className='day-plan-statistic--font-size text-secondary d-block'
                testId={props.testId}
            >
                <div title='May include unscheduled monitoring' data-testid={`${props.testId}-booked-in`}>
                    <Icon />
                    {bookedInSamplesCount} booked in
                </div>
                {unscheduledSamplesCount > 0 && <div className='ms-1'>({unscheduledSamplesCount} unscheduled)</div>}
                <div data-testid={`${props.testId}-not-in-use`}>
                    <Icon bgColor='day-plan-statistic_icon--not-in-use-color'/>
                    {notInUseSamplesCount} not in use
                </div>
                <div>
                    <Icon bgColor='day-plan-statistic_icon--missed'/>
                    {missedSamplesCount} missed
                </div>
                <div title='May include samples from locations not in use'>
                    <Icon bgColor='day-plan-statistic_icon--remaining-color'/>
                    {remainingSamplesCount} remaining
                </div>
            </Link>
        </div>
    )
}

export default DayPlanStatistic

interface TotalProps {
    dayPlanStatistic: DayScheduleStatistic | undefined
    testId?: string
}

function DayPlanStatisticTotal(props: TotalProps) {
    const canReadSchedule = useSelector(_ => _.auth.permissions.readSchedule)

    if (!props.dayPlanStatistic?.scheduledSamplesCount)
        return null

    return (
        <>
            <div>
                {props.dayPlanStatistic.scheduledSamplesCount} Scheduled
            </div>
            <Link
                routeName={routes.SCHEDULING_DAY_SCHEDULER}
                routeParams={{date: props.dayPlanStatistic.date}}
                className='m-1 ms-auto'
                title='Click to go to the Day scheduler view'
                hasNoPermissions={!canReadSchedule}
                testId={`${props.testId}-scheduled`}
            >
                <i className='material-icons md-18 text-primary'>create</i>
            </Link>
        </>
    )
}

export {
    DayPlanStatisticTotal,
}
