import type { MissedMonitoringByDayAndSession } from '_/model/reports/missed-monitoring-report/types'
import type TimeService from '_/services/time-service'
import type { ReportGraphData } from '_/model/reports/types'
import { CURRENT_PERIOD, SIX_PERIODS_AGO } from '_/model/reports/contextual-report-period'
import type { MissedSamples } from './types'
import type { DateRange } from '_/model/analysis/tabular-data'

function calculateMaxMissedSamplesCount(missedMonitoring: MissedMonitoringByDayAndSession[]) {
    const sessionMaxMissedSamplesCount = missedMonitoring.map(_ =>
        _.missedMonitoring.reduce((acc, missedMonitoring) => Math.max(missedMonitoring.averageMissedSamplesCount, acc), 0)
    )

    return Math.max(...sessionMaxMissedSamplesCount)
}

function missedMonitoringGraphSubtitle(graphData: ReportGraphData | undefined, timeService: TimeService) {
    if (!graphData)
        return ''

    const currentPeriod = graphData.series.find(_ => _.contextualReportPeriod === CURRENT_PERIOD)!
        , sixPeriodsAgo = graphData.series.find(_ => _.contextualReportPeriod === SIX_PERIODS_AGO)!
        , toDate = timeService.addUtcDays(currentPeriod.to, -1)

    return `${timeService.formatUtcDate(sixPeriodsAgo.from)} to ${timeService.formatUtcDate(toDate)}`
}

function areDatesInCurrentYear(dateRange: DateRange | undefined, timeService: TimeService) {
    if (!dateRange)
        return false

    const currentYear = timeService.ctzTimeStruct(timeService.now()).year

    return timeService.ctzTimeStruct(dateRange.from).year === currentYear
        && timeService.ctzTimeStruct(dateRange.to).year === currentYear
}

function formatDatePeriod(timeService: TimeService, dateRange: DateRange | undefined, omitYear?: boolean) {
    if (!dateRange)
        return ''

    const dateFrom = timeService.formatCtzDate(dateRange.from, false, omitYear)
        , dateTo = timeService.formatCtzDate(timeService.addCtzDays(dateRange.to, -1), false, omitYear)

    return `${dateFrom} - ${dateTo}`
}


function formatUtcDatePeriod(timeService: TimeService, dateRange: DateRange | undefined, omitYear?: boolean) {
    if (!dateRange)
        return ''

    const dateFrom = timeService.formatUtcDate(dateRange.from, false, omitYear)
        , dateTo = timeService.formatUtcDate(timeService.addCtzDays(dateRange.to, -1), false, omitYear)

    return `${dateFrom} - ${dateTo}`
}

function calculateTrend(missedSamples: MissedSamples | undefined) {
    if (!missedSamples || missedSamples.previousPeriodScheduledSamples === 0)
        return

    const { currentPeriodMissedSamples, previousPeriodMissedSamples } = missedSamples

    return Math.sign(currentPeriodMissedSamples - previousPeriodMissedSamples)
}

export {
    calculateMaxMissedSamplesCount,
    missedMonitoringGraphSubtitle,
    formatDatePeriod,
    formatUtcDatePeriod,
    calculateTrend,
    areDatesInCurrentYear,
}
