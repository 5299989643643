import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import { updateTransaction } from '_/model/scheduling/monitoring-groups/transaction'
import type MonitoringGroupService from '_/services/monitoring-group-service'

import * as actions from './actions'

const factory = (service: MonitoringGroupService): EffectsFactory => _ => [
    handler(actions.loadMonitoringGroupList, _ => service.list()),
    handler(actions.loadMonitoringGroup, service.get),
    handler(actions.loadMonitoringGroupTrail, service.getTrail),
    handler(actions.createMonitoringGroup, service.create),
    handler(actions.editMonitoringGroup, payload => updateTransaction(payload.id, payload.oldMonitoringGroup, payload.newMonitoringGroup, service)),
    handler(actions.removeMonitoringGroup, service.remove),
    handler(actions.loadAdHocGroupList, service.getAdHocGroups)
]

export default factory
