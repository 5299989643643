import { useAction, useState, classnames } from '_/facade/react'
import type { ImageUpload } from '_/model/floor-plan/upload/types'
import { showFieldError } from '_/components/form/helpers'
import { loadImage } from '_/utils/image'

import * as a from '../actions'
import * as toastActions from '_/features/toasts/actions'

import ImageField from './image-field'
import { useField } from 'react-final-form'

function Upload() {
    const handleNewFile = useNewFileHandler()
        , [isDropOver, setDropOver] = useState(false)
        , imageField = useField<ImageUpload | ''>('image')
        , image = imageField.input.value || undefined
        , showImageFieldError = showFieldError(imageField.meta) && !isDropOver

    function handlerDragLeave(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault()
        setDropOver(false)
    }

    function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
        setDropOver(true)
        event.preventDefault()
    }

    function handleDrop(event: React.DragEvent<HTMLDivElement>) {
        event.preventDefault()
        handleNewFile(event, true)
        setDropOver(false)
    }

    return (
        <div className='border rounded position-relative flex-fill'>
            {!image &&
                <div
                    className={classnames('h-100 w-100 position-absolute bg-light d-flex', {
                        'border border-primary': isDropOver,
                        'border border-danger': showImageFieldError,
                    })}
                    onDragLeave={handlerDragLeave}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <div className='text-center m-auto'>
                        Drag an image of floor plan here or
                        <label htmlFor='upload' className='fw-bold text-primary cursor-pointer ps-1' data-testid='browse-image'> browse your computer. </label>
                        <input id='upload' type='file' accept='image/png, image/jpg, image/jpeg' className='d-none' onChange={handleNewFile} data-testid='upload-image' />
                        <br/>
                        Your image file must be in JPEG/JPG or PNG format. Maximum file size is 50MB
                    </div>
                </div>
            }
            {showImageFieldError && <span className='floor-plans-uploader-error d-block invalid-feedback' data-testid='validation-error'>{imageField.meta.error}</span>}
            <ImageField />
        </div>
    )
}

export default Upload

function useNewFileHandler() {
    const uploadImage = useAction(a.uploadImage)
        , validate = useImageValidation()

    function handleNewFile(event: any, isFileDropped?: boolean): void {
        const files = isFileDropped
                ? event.dataTransfer.files
                : event.target.files
            , file = files?.[0]

        validate(files)
            .then(error => {
                if (error.length > 0)
                    return

                if (file)
                    uploadImage(file)
            })
    }

    return handleNewFile
}

function useImageValidation() {
    const addError = useAction(toastActions.addError)

    function validate(files: File[] | null) {
        const errors: string[] = []
            , file = files?.[0]
            , supportedFilesTypes = ['image/jpeg', 'image/jpg', 'image/png']
            , wrongFileError = 'There seems to be a problem with the file you uploaded. Try again, or use a different file'

        if (!file) {
            addError(wrongFileError)
            return Promise.resolve([wrongFileError])
        }

        if (files.length > 1) {
            const error = 'Only one file can be uploaded at a time'
            addError(error)
            return Promise.resolve([error])
        }

        if (file.size / 1024 / 1024 > 50)
            errors.push('Files must be 50MB or smaller')

        if (supportedFilesTypes.indexOf(file.type) === -1)
            errors.push('Only JPEG/JPG or PNG files can be uploaded')

        if (errors.length > 0) {
            errors.forEach(addError)
            return Promise.resolve(errors)
        }

        return loadImage(URL.createObjectURL(file))
            .then(() => Promise.resolve([]))
            .catch(() => {
                addError(wrongFileError)
                return [wrongFileError]
            })
    }

    return validate
}
