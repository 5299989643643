import type { EffectsFactory } from '_/facade/effect'
import { handler } from '_/facade/effect'
import type PlateService from '_/services/plate-service'
import type ExportService from '_/services/export-service'
import { diffObject } from '_/utils/object'
import * as a from './actions'

const factory = (service: PlateService, exportService: ExportService): EffectsFactory => _dispatch => [
    handler(a.createPlate, service.create),
    handler(a.loadPlate, service.get),
    handler(a.loadPlates, service.searchPlates),
    handler(a.searchPlatesByBarcode, service.searchPlatesByBarcode),
    handler(a.loadPlateTrail, service.getTrail),
    handler(a.savePlate, payload => {
        const diff = diffObject(payload.oldPlate, payload.newPlate)
        if (!diff)
            return Promise.resolve()

        const reasonRequired = payload.oldPlate.cfuCount !== undefined && diff.cfuCount !== undefined || diff.barcode !== undefined
            
        return service.save(payload.id, payload.newPlate, reasonRequired)
    }),
    handler(a.attachImage, service.attachImage),
    handler(a.downloadPlateImages, service.downloadPlateImages),
    handler(a.loadPlatesExports, exportService.getAllPlatesExports),
    handler(a.exportPlates, payload => exportService.exportPlates({ ...payload, start: 0, count: 1000000 })),
    handler(a.addPlateComment, payload => service.addComment(payload.plateId, payload.plateComment))
]

export default factory
