import { useState } from '_/facade/react'

import type { NewSampleComment, ExistedSampleComment } from '_/model/sample/comment'

import CommentList from '_/features/samples/sample-edit/comment'
import { validateComment } from '_/features/samples/validate'
import { CommentView } from '_/components/comment'
import Legend from '_/components/legend'

interface OwnProps {
    comments: ExistedSampleComment[]
    onAddComment(comment: NewSampleComment, compromised: boolean): void
    onCommentChange(comment: string, compromised: boolean, commentRequired: boolean): void
    compromised: boolean
}

function SampleComments(props: OwnProps) {
    const [state, setState] = useState({
            compromised: props.compromised,
            compromisedPreviousValue: props.compromised,
            comment: '',
        })
        , error = validateComment({ message: state.comment }, state.compromised !== state.compromisedPreviousValue)

    function handleChange(comment: string, compromised: boolean) {
        setState(_ => ({ ..._, comment, compromised }))

        const valid = !validateComment({ message: comment }, compromised !== state.compromisedPreviousValue)
        props.onCommentChange(comment, compromised, valid)
    }

    function handleAddComment() {
        props.onAddComment({ message: state.comment, isRead: true }, state.compromised)
        setState(_ => ({ ..._, comment: '', compromisedPreviousValue: state.compromised }))
    }

    return (
        <>
            <Legend className='block-header'>Comments</Legend>
            <CommentView
                value={state.comment}
                onChange={_ => handleChange(_, state.compromised)}
                error={error}
                onPost={handleAddComment}
                testId='field-read-comment'
            />
            <div className='position-relative d-print-none comments-padding-top'>
                <div className='form-check'>
                    <input
                        id='compromised'
                        type='checkbox'
                        onChange={() => handleChange(state.comment, !state.compromised)}
                        checked={state.compromised}
                        className='form-check-input'
                        data-testid='field-read-compromised'
                    />
                    <label
                        className='form-check-label'
                        htmlFor='compromised'
                    >
                        Mark viable sample as compromised
                    </label>
                </div>
            </div>
            <div className='d-none d-print-inline'>
                {state.compromised ? '☑' : '☐'}
                <span className='ms-1'>Mark viable sample as compromised</span>
            </div>
            <CommentList comments={props.comments} />
        </>
    )
}

export default SampleComments
