import type { FieldIndex } from '_/constants/custom-field-index'
import { getCustomFieldsDiff } from '_/features/samples/helpers'
import type { NonViableSampleEditRequest } from '_/model/non-viable-sample/booking/types'
import type NonViableSampleService from '_/services/non-viable-sample-service'
import { diffObject } from '_/utils/object'
import reasonRequired from './reason-rule'

function editTransaction(
    id: string,
    current: Partial<NonViableSampleEditRequest>,
    next: Partial<NonViableSampleEditRequest>,
    api: NonViableSampleService,
    editedFields?: FieldIndex[]
) {
    const diff = diffObject(current, next)

    if (!diff)
        return Promise.resolve()

    if (diff.fields)
        diff.fields = getCustomFieldsDiff(diff.fields, current.fields!, next.fields!)

    return !editedFields || reasonRequired(editedFields, diff)
        ? api.editNonViableSampleWithReason(id, diff)
        : api.editNonViableSample(id, diff)
}

export {
    editTransaction,
}
