import { useState, useAction, useSelector, useEffect, classnames } from '_/facade/react'
import { useFilter } from '_/hooks/shared-hooks'

import { Table, PaginatedFooter, EmptyTableMessage } from '_/components/table'
import ContextSwitchObserver, { useContextSwitchObserver } from '_/components/context-observer'
import { ITEMS_PER_PAGE } from '_/constants'
import * as routes from '_/constants/routes'

import type PaginationState from '_/model/pagination-state'
import type { GlobalAuditTrailsFilter, GlobalAuditTrailList } from '_/model/audit-trail/types'

import * as actions from '../actions'
import * as userActions from '../../users/actions'
import * as routerActions from '_/features/routing/actions'
import * as spinnerAction from '_/features/spinner/actions'

import Button from '_/components/button'

import { ENTITY_TYPE, SAMPLE, SAMPLE_TYPE, USER, CUSTOM_REPORT, PLATE, NON_VIABLE_SAMPLE } from '_/constants/entity-type'
import AUTOMATED_ACTION_USER_ID from '_/constants/users'

import Filter from './filter'
import TrailMessages from '_/components/trail-message'
import { useTimeService } from '_/components/time'

const UNREALISTIC_USER_COUNT = 1000
    , CFU_COUNT_MODIFIED = 'CFU counts modified'

function AuditTrailList()  {
    const timeService = useTimeService()
        , [filter, changeFilter] = useFilter<GlobalAuditTrailsFilter>('global-audit-trails-filter', (params = {}) => ({ ...params }))
        , [pagination, setPagination] = useFilter<PaginationState>('global-audit-trails-pagination', (params = {}) => ({ start: 0, count: ITEMS_PER_PAGE, ...params }))
        , [showFilter, setShowFilter] = useState(!isEmpty(filter))
        , [deletedEntities, setDeletedEntities] = useState<string[]>([])
        , trails = useTrailList(pagination, filter, setDeletedEntities)
        , permissions = useSelector(_ => _.auth.permissions)
        , users = useSelector(_ => _.users)
        , navigateTo = useNavigateTo()

    useUsers()

    function isEmpty(searchFields: GlobalAuditTrailsFilter): boolean {
        return (searchFields.entityTypes === undefined
            && searchFields.dateFrom === undefined
            && searchFields.dateTo === undefined
            && searchFields.users === undefined
            && searchFields.onlyReasonsIncluded === undefined
        )
    }

    function buildLink(entityType: number, entityId: string) {
        const entity = ENTITY_TYPE.find(_ => _.id === entityType)
        if (!entity)
            return 'Unknown'

        if (deletedEntities.some(_ => _ == entityId))
            return entity.name

        switch (entity.id) {
            case CUSTOM_REPORT:
            case SAMPLE:
            case SAMPLE_TYPE:
            case USER:
            case PLATE:
            case NON_VIABLE_SAMPLE: {
                const params = navigateToParams(entityType, entityId)
                return params?.permission
                    ? <Button className='btn-link align-baseline p-0 ms-0 border-0' onClick={params.navigateToEntity}>{entity.name}</Button>
                    : entity.name
            }
            default:
                return entity.name
        }
    }

    function handleFilterChange(newFilter: GlobalAuditTrailsFilter) {
        const actionQueryIsCfuCountModified = newFilter.actionQuery?.toUpperCase() === CFU_COUNT_MODIFIED.toUpperCase()
            , onlyReasonsIncluded = actionQueryIsCfuCountModified
                ? actionQueryIsCfuCountModified
                : newFilter.onlyReasonsIncluded ?? false

        changeFilter({...newFilter, onlyReasonsIncluded})
        setPagination({ start: 0, count: ITEMS_PER_PAGE })
    }

    function navigateToParams(entityType: number, id: string) {
        switch (entityType) {
            case SAMPLE:
                return {
                    permission: permissions.readSamples,
                    navigateToEntity: () => navigateTo(routes.SAMPLES_EDIT, {id}),
                }
            case SAMPLE_TYPE:
                return {
                    permission: permissions.readSampleType,
                    navigateToEntity: () => navigateTo(routes.SETTINGS_SAMPLE_TYPES),
                }
            case USER:
                return {
                    permission: permissions.readUsers,
                    navigateToEntity: () => navigateTo(routes.SETTINGS_USERS_MEMBERSHIPS, {id}),
                }
            case CUSTOM_REPORT:
                return {
                    permission: permissions.readCustomReports,
                    navigateToEntity: () => navigateTo(routes.CUSTOM_REPORTS_VIEW, {id}),
                }
            case PLATE:
                return {
                    permission: permissions.readColonyCounterPlates,
                    navigateToEntity: () => navigateTo(routes.PLATES_READING, {id}),
                }
            case NON_VIABLE_SAMPLE:
                return {
                    permission: permissions.readSamples,
                    navigateToEntity: () => navigateTo(routes.NON_VIABLE_SAMPLES_EDIT, {id}),
                }
        }
    }

    function reset() {
        handleFilterChange({})
    }

    return (
        <div className='position-relative d-flex flex-fill h-100'>
            <ContextSwitchObserver onChange={reset} />
            <Filter
                filterState={filter}
                userList={users.list.items}
                onChange={handleFilterChange}
                onClearFilter={reset}
                onClose={() => setShowFilter(false)}
                showFilter={showFilter}
            />
            {permissions.readGlobalTrails && <div className='container-fluid main-block'>
                <div className='row justify-content-center'>
                    <div className='col-9'>
                        <nav className='navbar page-header page-header--sticky py-3'>
                            <div className='d-flex'>
                                <Button
                                    className={classnames('btn-link me-2')}
                                    onClick={() => setShowFilter(!showFilter)}
                                    testId='open-filters'
                                >
                                    <i className='material-icons align-middle me-2'>tune</i>
                                    <span>Filters</span>
                                </Button>
                                <h4 className='d-flex align-items-center'> Global audit trail</h4>
                            </div>
                        </nav>
                        <Table>
                            <thead className='table-header--sticky global-audit-trail--sticky-offset'>
                                <tr>
                                    <th>Date</th>
                                    <th>User</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                    <th>Context</th>
                                    <th>Entity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trails.list.items.length === 0
                                    ? <EmptyTableMessage colSpan={6} message='No results found' />
                                    : trails.list.items.map((_, index) =>
                                        <tr data-testid='filters-row' key={index} className={classnames({ 'text-secondary': _.userId === AUTOMATED_ACTION_USER_ID })}>
                                            <td>
                                                {timeService.formatCtzDateTime(_.issued, true)}
                                            </td>
                                            <td className='audit-trail__user-name-column--word-wrap'>
                                                <span title={_.userId === AUTOMATED_ACTION_USER_ID ? '' : _.userEmail}>{_.user}</span>
                                            </td>
                                            <td>{_.userRoleName}</td>
                                            <td className='audit-trail__action-column--word-wrap'>
                                                <TrailMessages event={_}/>
                                            </td>
                                            <td>{_.context}</td>
                                            <td>
                                                {buildLink(_.entityInfo.entityType, _.entityInfo.id)}
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                            <PaginatedFooter
                                colSpan={6}
                                state={pagination}
                                onChange={setPagination}
                                totalCount={trails.list.totalCount}
                            />
                        </Table>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default AuditTrailList

function useNavigateTo() {
    const navigateTo = useAction(routerActions.navigateTo)
    return (routeName: routes.RouteName, routeParams?: any) => navigateTo(routeName, routeParams)
}

function useTrailList(pagination: PaginationState, filter: GlobalAuditTrailsFilter, setDeletedEntities: (_: string[]) => void) {
    const loadTrailList = useAction(actions.loadTrailList)
        , loadDeletedInfo = useAction(actions.getDeletedInfo)
        , showSpinner = useAction(spinnerAction.showSpinner)
        , hideSpinner = useAction(spinnerAction.hideSpinner)
        , [trails, setTrails] = useState<GlobalAuditTrailList>({list: {items: [], totalCount: 0}})

    useEffect(
        () => {
            let disposed = false

            const query = { ...pagination, ...filter }

            Promise.resolve()
                .then(showSpinner)
                .then(() => loadTrailList(query))
                .then(list => {
                    if (disposed)
                        return

                    const entityInfo = list.items.map(_ => _.entityInfo)
                    setTrails({ list })
                    loadDeletedInfo(entityInfo).then(setDeletedEntities)
                })
                .finally(hideSpinner)

            return () => {
                disposed = true
            }
        },
        [pagination, filter, loadTrailList, loadDeletedInfo, setDeletedEntities, showSpinner, hideSpinner]
    )

    return trails
}

function useUsers() {
    const loadUsers = useAction(userActions.loadUserList)
        , contextSwitch = useContextSwitchObserver()

    useEffect(
        () => { loadUsers({ start: 0, count: UNREALISTIC_USER_COUNT, sort: 'name:asc' }) },
        [contextSwitch, loadUsers]
    )
}
