import { Link } from '_/utils/router'

import * as fieldIndex from '_/constants/custom-field-index'
import PLATE_TYPE, { FINGERDAB_PLATE, FINGERDAB_TWO_HANDS_PLATE } from '_/constants/plate-type'
import { NON_VIABLE_SAMPLES_EDIT, SAMPLES_EDIT } from '_/constants/routes'
import type * as breachType from '_/constants/sample-breach-type'

import { useTimeService } from '_/components/time'

import { getFieldValue } from '../samples/helpers'
import type { NonViableSampleData, SampleData } from '_/model/reports/limit-breach-report/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import * as f from '_/model/sample/format'
import * as nvf from '_/model/non-viable-sample/format'
import * as t from '_/model/text/text'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import FormattedText from '../text/formatted-text'
import { formatLocationName } from '../monitoring-overview/helpers'
import LimitBreachIcon from './limit-breach-icon'
import { PARTICLE_STATE } from '_/model/predefined-lists/action-alert-limit/non-viable-limit-type'

interface Props {
    sampleData: SampleData | NonViableSampleData
    breachType: Exclude<breachType.BreachTypeWithCompromised, typeof breachType.NONE>
    customFields: CustomField[]
    locations: ListExposureLocation[]
    isNonViable?: boolean
}

function LimitBreachHintItem(props: Props){
    const timeService = useTimeService()
        , locationName = formatLocationName(props.sampleData, props.locations)
        , refNumber = getFieldValue(props.sampleData.fields, fieldIndex.REFERENCE_NUMBERS)
        , gradeName = getFieldValue(props.sampleData.fields, fieldIndex.EXPOSURE_LOCATION_GRADE_NAME)
        , exposureDuration = props.isNonViable
            ? nvf.formatExposureDurationByFields(props.sampleData.fields, timeService)
            : f.formatExposureDurationByFields(props.sampleData.fields, props.customFields, timeService)
        , time = t.plainText(exposureDuration)
        , id = getFieldValue(props.sampleData.fields, fieldIndex.ID)

    function plateName(sampleType: number) {
        const plate = PLATE_TYPE.find(_ => _.id === sampleType)
        return sampleType === FINGERDAB_PLATE || sampleType === FINGERDAB_TWO_HANDS_PLATE
            ? 'Fingerdab'
            : plate?.name
    }

    return (
        <div className='mx-3 my-2 d-flex'>
            <div className='flex-shrink-0 me-2'>
                <LimitBreachIcon breachType={props.breachType} withRef={!!refNumber} isNonViable={props.isNonViable} />
            </div>
            {props.isNonViable
                ? <div>
                    <Link
                        className='text-white'
                        routeName={NON_VIABLE_SAMPLES_EDIT}
                        routeParams={{ id }}
                    >
                        <FormattedText text={locationName}/>{' '}
                        {gradeName && `- ${gradeName} `}
                        - {formatParticleState(props.sampleData as NonViableSampleData)}{' '}
                    </Link>
                    <span className='text-white-50'>- {time}</span>
                </div>
                : <div>
                    <Link
                        className='text-white'
                        routeName={SAMPLES_EDIT}
                        routeParams={{ id }}
                        testId='icon-link'
                    >
                        {plateName(getFieldValue(props.sampleData.fields, fieldIndex.PLATE_TYPE))} (<FormattedText text={locationName} />
                        {gradeName && `, Grade ${gradeName}`})
                    </Link>
                    {getFieldValue(props.sampleData.fields, fieldIndex.CFU) > 0 && <span className='text-white-50' data-testid='total-cfu'>, {getFieldValue(props.sampleData.fields, fieldIndex.CFU)} CFUs</span>}
                    <span className='text-white-50' data-testid='exposure-duration'>, {time}</span>
                    <span className='text-white-50'>{refNumber && `, Inv ref: ${refNumber}`}</span>
                </div>
            }
        </div>
    )
}

export default LimitBreachHintItem

function formatParticleState(sampleData: NonViableSampleData) {
    return sampleData.particleStates
        .map(_ => PARTICLE_STATE.find(ps => ps.id === _)?.name)
        .join(', ')
}
