import type { ViableSampleBookingForm } from '_/model/sample/booking/types'
import type { ListExposureLocation } from '_/model/predefined-lists/exposure-location/exposure-location'
import type SampleType from '_/model/predefined-lists/sample-type/types'
import type { FieldValues } from '_/model/predefined-lists/custom-field/types'
import type CustomField from '_/model/predefined-lists/custom-field/types'

import { hasLocationChildren, getBookableLocations } from '_/utils/exposure-location'
import { isDefaultCustomFieldIndex } from '_/model/predefined-lists/custom-field/custom-field'

import * as tree from '_/utils/tree'
import * as string from '_/utils/string'

import * as fieldIndex from '_/constants/custom-field-index'
import { SETTLE_PLATE } from '_/constants/plate-type'
import { getSampleEditValuesFromCustomFields } from '../helpers'
import { IN_OPERATION } from '_/model/predefined-lists/action-alert-limit/monitoring-state'
import type { PredefinedLists } from '_/model/app-state'
import type { FormApi } from 'final-form'
import type { NonViableSampleForm } from '_/model/non-viable-sample/booking/types'
import type { SampleSession } from '_/model/predefined-lists/session/types'

function getDefaultFormValues(fields: CustomField[], fieldValues: FieldValues[] | undefined): ViableSampleBookingForm {
    return {
        fields: getSampleEditValuesFromCustomFields(fields)
            .map(f => {
                const field = (fieldValues ?? []).find(_ => _.index === f.index)
                return field ? {...f, value: field.value} : f
            }),
        monitoringState: IN_OPERATION,
        scheduledSample: true,
    }
}

function isSettleType(sampleTypes: SampleType[], sampleTypeId: string) {
    const sampleType = sampleTypes.find(_ => _.id === sampleTypeId)
    return sampleType && sampleType.sampleType === SETTLE_PLATE
}

function getAllLocationChildren(locations: ListExposureLocation[], allLocation: ListExposureLocation[]) {
    let result = locations
    locations.forEach(location => {
        const children = allLocation.filter(_ => _.parentId === location.id)

        if (children.some(_ => hasLocationChildren(_, allLocation)))
            result = result.concat(getAllLocationChildren(children, allLocation))
        else
            result = result.concat(children)
    })

    return result
}

function subLocationList(allLocations: ListExposureLocation[], rootLocationId: string): (ListExposureLocation & { shortName: string })[] {
    const rootLocation = allLocations.find(_ => _.id === rootLocationId)
    if (!rootLocation)
        return []

    const subLocations = getAllLocationChildren([rootLocation], allLocations)
        , locationTree = tree.tree(allLocations)

    return subLocations
        .filter(_ => !hasLocationChildren(_, subLocations))
        .map(location => {
            const shortName = location.id === rootLocationId
                    ? location.name
                    : '— ' + tree.path(locationTree, location, rootLocationId)
                        .map(_ => _.name)
                        .join(' - ')

            return { shortName, ...location }
        })
        .sort((one, two) => {
            if (one.id === rootLocation.id)
                return -1

            if (two.id === rootLocation.id)
                return 1

            return string.compare(one.shortName, two.shortName)
        })
}

function canEditExposureTime(sampleTypeId: string | undefined, sampleTypes: SampleType[], isRequired: boolean) {
    const currentSampleType =  sampleTypes.find(_ => _.id === sampleTypeId)

    return !!(currentSampleType && currentSampleType.sampleType === SETTLE_PLATE) || isRequired
}

function getEntities(
    relatedEntity: fieldIndex.FieldIndex,
    locations: ListExposureLocation[],
    locationChildren: (ListExposureLocation & { shortName: string })[],
    predefinedLists: PredefinedLists
) {
    const exposureLocations = getBookableLocations(locations)
        , locationIds = new Set(exposureLocations.map(_ => _.id))
        , rootIds = new Set(exposureLocations.filter(_ => !locationIds.has(_.parentId)).map(_ => _.id))
        , parentLocations = exposureLocations.filter(_ => hasLocationChildren(_, exposureLocations) || rootIds.has(_.id))

    if (relatedEntity === fieldIndex.EXPOSURE_LOCATION_ID)
        return parentLocations

    if (relatedEntity === fieldIndex.SESSION_ID)
        return predefinedLists.sampleSessions

    if (relatedEntity === fieldIndex.SAMPLE_TYPE_ID)
        return predefinedLists.sampleTypes

    if (relatedEntity === fieldIndex.MONITORING_POSITION)
        return locationChildren

    if (!isDefaultCustomFieldIndex(relatedEntity))
        return predefinedLists.customFields.find(_ => _.index === relatedEntity)?.selectionFieldValues?.filter(_ => _.isActive) ?? []

    return []
}

function isValueChanged(value: FieldValues | undefined, prevFieldValues: FieldValues[]) {
    const prevFields = prevFieldValues.filter(_ => _ .index === value?.index)
    return prevFields.length > 0 && prevFields[0].value !== value?.value
}

function resetViableForm(
    form: FormApi,
    entity: Pick<Partial<ViableSampleBookingForm>, 'fields' | 'monitoringState' | 'scheduledSample'>,
    predefinedLists: PredefinedLists
) {
    const fields = getCustomFieldsToPresereve(entity, predefinedLists, true)
        , { monitoringState, scheduledSample } = entity

    form.reset({ monitoringState, scheduledSample, fields })
}

function resetNonViableForm(
    form: FormApi,
    entity: Pick<Partial<NonViableSampleForm>, 'fields' | 'monitoringState'>,
    predefinedLists: PredefinedLists
) {
    const fields = getCustomFieldsToPresereve(entity, predefinedLists, false)
        , monitoringState = entity.monitoringState
        , lowerParticle = { notRecorded: false }
        , higherParticle = { notRecorded: false }

    form.reset({ monitoringState, fields, lowerParticle, higherParticle })
}

function getCustomFieldsToPresereve(
    entity: Pick<Partial<ViableSampleBookingForm | NonViableSampleForm>, 'fields' | 'monitoringState'>,
    predefinedLists: PredefinedLists,
    isViable: boolean
) {
    const persistedFields = predefinedLists.customFields.filter(_ => isViable ? _.viableSettings.persisted : _.nonViableSettings.persisted)
        , locationField = persistedFields.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ID)
        , endTimeField = predefinedLists.customFields.find(_ => _.index === fieldIndex.EXPOSURE_END_TIME)
        , endTimeRequired = isViable ? endTimeField?.viableSettings.required : endTimeField?.nonViableSettings.required
        , sampleType = entity.fields?.find(_ => _.index === fieldIndex.SAMPLE_TYPE_ID)
        , canEdit = canEditExposureTime(sampleType?.value, predefinedLists.sampleTypes, endTimeRequired ?? false)

    return entity.fields?.map(field => {
        const emptyField = { index: field.index, value: undefined, notRecorded: false }
            , isPersisted = persistedFields.some(_ => _.index === field.index)

        if (field.index === fieldIndex.MONITORING_POSITION && !locationField)
            return emptyField

        if (field.index === fieldIndex.MONITORING_POSITION && isPersisted)
            return field

        if (field.index === fieldIndex.MONITORING_POSITION) {
            const location = entity.fields?.find(_ => _.index === fieldIndex.EXPOSURE_LOCATION_ID)

            return { index: fieldIndex.MONITORING_POSITION, value: undefined, notRecorded: location?.notRecorded }
        }

        if (field.index === fieldIndex.EXPOSURE_END_TIME && !canEdit && !isPersisted)
            return emptyField

        if (field.index === fieldIndex.EXPOSURE_END_DATE && !canEdit && !isPersisted) {
            const startDate = entity.fields?.find(_ => _.index === fieldIndex.EXPOSURE_START_DATE)

            return { index: field.index, value: startDate?.value, notRecorded: false }
        }

        return !isPersisted ? emptyField : field
    })
}

function compareByExposureDate(fields1: FieldValues[], fields2: FieldValues[]) {
    const one = fields1.find(_ => _.index === fieldIndex.EXPOSURE_START_DATE)?.value ?? Number.MIN_SAFE_INTEGER
        , two = fields2.find(_ => _.index === fieldIndex.EXPOSURE_START_DATE)?.value ?? Number.MIN_SAFE_INTEGER

    return one - two
}

function compareBySession(fields1: FieldValues[], fields2: FieldValues[], sessions: SampleSession[]) {
    const idOne = fields1.find(_ => _.index === fieldIndex.SESSION_ID)?.value
        , idTwo = fields2.find(_ => _.index === fieldIndex.SESSION_ID)?.value
        , sessionOne = sessions.find(_ => _.id === idOne)?.name ?? ''
        , sessionTwo = sessions.find(_ => _.id === idTwo)?.name ?? ''

    return string.compare(sessionOne, sessionTwo)
}

export {
    isSettleType,
    getAllLocationChildren,
    subLocationList,
    canEditExposureTime,
    getEntities,
    getDefaultFormValues,
    isValueChanged,
    resetViableForm,
    resetNonViableForm,
    compareByExposureDate,
    compareBySession,
}
