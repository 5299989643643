const IN_OPERATION = 0
    , AT_REST = 1

type MonitoringState = never
    | typeof AT_REST
    | typeof IN_OPERATION

const MONITORING_STATE = [
    {
        id: IN_OPERATION,
        name: 'In operation',
    },
    {
        id: AT_REST,
        name: 'At rest',
    },
]

type MONITORING_STATE = typeof MONITORING_STATE

export default MONITORING_STATE
export {
    AT_REST,
    IN_OPERATION,
    MONITORING_STATE,
    MonitoringState,
}
