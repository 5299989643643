const collator = new Intl.Collator(undefined, {
    sensitivity: 'base',
    numeric: true,
})

function compare(first: string, second: string): number {
    return collator.compare(first, second)
}

export { compare }
