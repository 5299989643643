import type Page from '_/model/page'
import type { PlateEdit, PlateImageAttach, PlateSearchByBarcodeResult, PlateListRequest } from '_/model/plate/plate'
import type Plate from '_/model/plate/plate'
import type ApiService from '../api-service'
import type PlateService from '../plate-service'
import { downloadBlob } from '_/utils/blob'
import type { NewPlateComment } from '_/model/plate/comments'

function factory(api: ApiService): PlateService {
    const resource = api.resource(['plates'])

    return {
        ...resource,
        save,
        searchPlatesByBarcode,
        attachImage,
        downloadPlateImages,
        addComment,
        searchPlates,
    }

    function downloadPlateImages(id: string): Promise<void> {
        return api.getFile(['plates', id, 'images', 'export']).then(
            _ => downloadBlob(_.blob, _.filename))
    }

    function searchPlatesByBarcode(barcode: string): Promise<PlateSearchByBarcodeResult> {
        return api.post(['plates', 'barcode', 'search'], { barcode })
    }

    function save(id: string, newPlate: PlateEdit, reasonRequired: boolean): Promise<void> {
        return reasonRequired
            ? api.patchWithReason(['plates', id], undefined, newPlate)
            : api.patch(['plates', id], newPlate)
    }

    function attachImage(plateImage: PlateImageAttach): Promise<void> {
        return api.post(['plates', plateImage.plateId, 'attach-images'], plateImage)
    }

    function addComment(id: string, comment: NewPlateComment) {
        return api.post(['plates', id, 'comments'], { comment })
    }

    function searchPlates(query: PlateListRequest): Promise<Page<Plate>> {
        return api.post(['plates', 'search'], query)
    }
}

export default factory
